import React from "react";
import { Modal } from "react-bootstrap";
const ModalHeader = props => (
  <Modal.Header>
    <div
      id="cancel-modal-header"
      className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12"
    >
      <i
        id="csystem-level-cancel-button-lose-icon"
        onClick={props.onClose}
        className="fa fa-times-circle-o fa-lg"
      />
    </div>
  </Modal.Header>
);
export default ModalHeader;
