import { Fields } from "component-library/earnings-setup/fieldNames";
import {
  GROSS_EARNINGS,
  TAXABLE_EARNINGS,
  DISPOSABLE_EARNINGS,
  EARNINGS_TRACKING_DOLLARS,
  EARNINGS_TRACKING_HOURS
} from "./company-level/CompanyConstants";
import {
  HOURLY_RATE,
  NON_QUALIFIED_DEFERRED_COMPENSATION,
  GROUP_TERM_LIFE
} from "./Constants";

export function loadDefault(fieldName, data) {
  switch (fieldName) {
    case Fields.hasW2BoxCode: {
      let _yes = [10, 12];
      if (
        _yes.includes(data[Fields.subcategoryId]) &&
        data[Fields.paidToEmployee] === false &&
        data[Fields.earningsTaxable] === true
      ) {
        return true;
      } else if (data[Fields.w2Boxcode]) {
        return true;
      } else {
        return false;
      }
    }
    case Fields.w2Boxcode: {
      if (data[Fields.hasW2BoxCode]) {
        if (
          data[Fields.paidToEmployee] === false &&
          data[Fields.earningsTaxable] === true
        ) {
          if (data[Fields.subcategoryId] === 10) {
            return "AUTO";
          } else if (data[Fields.subcategoryId] === 12) {
            return "SCORP";
          } else {
            return data[Fields.w2Boxcode];
          }
        } else {
          return data[Fields.w2Boxcode];
        }
      } else {
        return null;
      }
    }
    case Fields.paidToEmployee: {
      let _yes = [
        1,
        4,
        5,
        6,
        7,
        8,
        9,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        28,
        29,
        30,
        32,
        35
      ];
      let _no = [
        2, // ? Added 2 (Allocated Tips)  [Bug 58020]
        10,
        GROUP_TERM_LIFE,
        12,
        13,
        14,
        NON_QUALIFIED_DEFERRED_COMPENSATION,
        16,
        27,
        31,
        33,
        34,
        36
      ];
      if (_yes.includes(data[Fields.subcategoryId])) {
        return true;
      } else if (_no.includes(data[Fields.subcategoryId])) {
        return false;
      } else {
        return null;
      }
    }
    case Fields.earningsTaxable: {
      let _no = [1, 2, 25, 26, 31, 28, 34]; // ? Added 2 (Allocated Tips) here AC Updated (28 Aug) Check in [US 2006]
      let _yes = [
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        GROUP_TERM_LIFE,
        12,
        13,
        14,
        NON_QUALIFIED_DEFERRED_COMPENSATION,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        27,
        33,
        35,
        36
      ];
      if (_yes.includes(data[Fields.subcategoryId])) {
        return true;
      } else if (_no.includes(data[Fields.subcategoryId])) {
        return false;
      } else {
        return null;
      }
    }

    case Fields.increaseFederalWages: {
      let _yes = [
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        GROUP_TERM_LIFE,
        12,
        13,
        14,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        34,
        36
      ];
      let _no = [NON_QUALIFIED_DEFERRED_COMPENSATION, 31];
      if (
        _yes.includes(data[Fields.subcategoryId]) &&
        data[Fields.earningsTaxable]
      ) {
        return true;
      } else if (_no.includes(data[Fields.subcategoryId])) {
        return false;
      } else {
        return false;
      }
    }
    case Fields.increaseSocialSecurityWages: {
      let _yes = [
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        GROUP_TERM_LIFE,
        13,
        NON_QUALIFIED_DEFERRED_COMPENSATION,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        32,
        33,
        34,
        35
      ];
      let _no = [3, 12, 31, 14];
      if (
        _yes.includes(data[Fields.subcategoryId]) &&
        data[Fields.earningsTaxable]
      ) {
        return true;
      } else if (_no.includes(data[Fields.subcategoryId])) {
        return false;
      } else {
        return false;
      }
    }
    case Fields.increaseMedicareWages: {
      let _yes = [
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        GROUP_TERM_LIFE,
        13,
        NON_QUALIFIED_DEFERRED_COMPENSATION,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35
      ];
      let _no = [12, 31, 14];
      if (
        _yes.includes(data[Fields.subcategoryId]) &&
        data[Fields.earningsTaxable]
      ) {
        return true;
      } else if (_no.includes(data[Fields.subcategoryId])) {
        return false;
      } else {
        return false;
      }
    }
    case Fields.increaseStateWages: {
      let _yes = [
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        GROUP_TERM_LIFE,
        12,
        13,
        14,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        32,
        34,
        36
      ];
      let _no = [NON_QUALIFIED_DEFERRED_COMPENSATION, 31];
      if (
        _yes.includes(data[Fields.subcategoryId]) &&
        data[Fields.earningsTaxable]
      ) {
        return true;
      } else if (_no.includes(data[Fields.subcategoryId])) {
        return false;
      } else {
        return false;
      }
    }
    case Fields.increaseLocalWages: {
      let _yes = [
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        GROUP_TERM_LIFE,
        12,
        13,
        14,

        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        32,
        34,
        36
      ];
      let _no = [NON_QUALIFIED_DEFERRED_COMPENSATION, 31];
      if (
        _yes.includes(data[Fields.subcategoryId]) &&
        data[Fields.earningsTaxable]
      ) {
        return true;
      } else if (_no.includes(data[Fields.subcategoryId])) {
        return false;
      } else {
        return false;
      }
    }
    case Fields.increaseSUTAWages: {
      //? 3 Sept --AC Change
      let _yes = [
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,

        13,
        NON_QUALIFIED_DEFERRED_COMPENSATION,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        32,
        33,
        34,
        35
      ];
      let _no = [1, GROUP_TERM_LIFE, 12, 14, 31];
      if (
        _yes.includes(data[Fields.subcategoryId]) &&
        data[Fields.earningsTaxable]
      ) {
        return true;
      } else if (_no.includes(data[Fields.subcategoryId])) {
        return false;
      } else {
        return false;
      }
    }
    case Fields.increaseFUTAWages: {
      //? 3 Sept --AC Change
      let _yes = [
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        13,
        NON_QUALIFIED_DEFERRED_COMPENSATION,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        32,
        33,
        34,
        35
      ];
      let _no = [1, GROUP_TERM_LIFE, 12, 14, 31];
      if (
        _yes.includes(data[Fields.subcategoryId]) &&
        data[Fields.earningsTaxable]
      ) {
        return true;
      } else if (_no.includes(data[Fields.subcategoryId])) {
        return false;
      } else {
        return false;
      }
    }
    case Fields.increaseSDIWages: {
      //? 3 Sept --AC Change
      let _yes = [
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        13,
        NON_QUALIFIED_DEFERRED_COMPENSATION,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        32,
        33,
        34,
        35
      ];
      let _no = [1, GROUP_TERM_LIFE, 12, 14, 31];
      if (
        _yes.includes(data[Fields.subcategoryId]) &&
        data[Fields.earningsTaxable]
      ) {
        return true;
      } else if (_no.includes(data[Fields.subcategoryId])) {
        return false;
      } else {
        return false;
      }
    }

    case Fields.excludeFromFederalTax: {
      let _yes = [GROUP_TERM_LIFE, 12]; //? 3 Sept --AC Change
      //let _no = [];

      if (data[Fields.increaseFederalWages] === true) {
        if (_yes.includes(data[Fields.subcategoryId])) {
          return true;
        }
        return false;
      } else if (data[Fields.increaseFederalWages] === false) {
        return false;
      } else {
        return null;
      }
    }
    case Fields.excludeFromStateTax: {
      let _yes = [GROUP_TERM_LIFE, 12]; //? 3 Sept --AC Change
      //let _no = [];
      if (data[Fields.increaseStateWages] === true) {
        if (_yes.includes(data[Fields.subcategoryId])) {
          return true;
        }
        return false;
      } else if (data[Fields.increaseStateWages] === false) {
        return false;
      } else {
        return null;
      }
    }
    case Fields.excludeFromLocalTax: {
      let _yes = [GROUP_TERM_LIFE, 12]; //? 3 Sept --AC Change
      if (data[Fields.increaseLocalWages] === true) {
        if (_yes.includes(data[Fields.subcategoryId])) {
          return true;
        }
        return false;
      } else if (data[Fields.increaseLocalWages] === false) {
        return false;
      } else {
        return null;
      }
    }
    case Fields.calculationMethodId: {
      if (data[Fields.earningsTrack] === EARNINGS_TRACKING_DOLLARS) {
        return 1;
      }
      return null;
    }
    case Fields.overtimeFactor: {
      return null;
    }
    case Fields.hourlyPay: {
      if (
        data[Fields.earningsTrack] === EARNINGS_TRACKING_HOURS &&
        data[Fields.calculationMethodId] === 2
      ) {
        return true;
      }
      return null;
    }
    case Fields.hourlyPayImported: {
      if (
        data[Fields.earningsTrack] === EARNINGS_TRACKING_HOURS &&
        data[Fields.calculationMethodId] === 2
      ) {
        if (!data[Fields.hourlyPay]) {
          return true;
        }
      }
      return null;
    }
    case Fields.customRate: {
      if (
        data[Fields.earningsTrack] === EARNINGS_TRACKING_HOURS &&
        data[Fields.calculationMethodId] === 2
      ) {
        if (data[Fields.hourlyPayImported] == null) {
          return null;
        }
      }
      return null;
    }
    case Fields.isPremium: {
      if (data[Fields.calculationMethodId] === 2) {
        return false;
      }
      return null;
    }
    case Fields.isDollarPremiumType: {
      if (
        data[Fields.earningsTrack] === EARNINGS_TRACKING_HOURS &&
        data[Fields.calculationMethodId] === 2
      ) {
        return false;
      }
      return null;
    }
    case Fields.dollarPremiumAmount: {
      if (
        data[Fields.earningsTrack] === EARNINGS_TRACKING_HOURS &&
        data[Fields.calculationMethodId] === 2
      ) {
        return "";
      }
      return "";
    }
    case Fields.isPercentPremiumType: {
      if (
        data[Fields.earningsTrack] === EARNINGS_TRACKING_HOURS &&
        data[Fields.calculationMethodId] === 2
      ) {
        return false;
      }
      return null;
    }
    case Fields.percentPremiumAmount: {
      if (
        data[Fields.earningsTrack] === EARNINGS_TRACKING_HOURS &&
        data[Fields.calculationMethodId] === 2
      ) {
        return "";
      }
      return "";
    }
    case Fields.earningsTrack: {
      if (data[Fields.paidToEmployee] || data[Fields.earningsTaxable]) {
        return null;
      } else if (
        !data[Fields.paidToEmployee] &&
        !data[Fields.earningsTaxable]
      ) {
        let _yes = [34, 2]; //Bug - 58023
        if (_yes.includes(data[Fields.subcategoryId])) {
          return "Dollars";
        }
        return null;
      } else {
        return null;
      }
    }
    case Fields.earningGroups: {
      let Options = {
        ...data[Fields.earningGroups],
        1: false,
        2: false,
        3: false
      };
      if (Options) {
        if (
          data[Fields.earningsTaxable] != null &&
          data[Fields.paidToEmployee] != null
        ) {
          if (data[Fields.paidToEmployee]) {
            if (data[Fields.categoryId] !== 4) {
              Options[DISPOSABLE_EARNINGS] = true;
              Options[GROSS_EARNINGS] = true;

              if (data[Fields.earningsTaxable]) {
                Options[TAXABLE_EARNINGS] = true;
              } else {
                Options[TAXABLE_EARNINGS] = false;
              }
            }
          } else {
            if (!data[Fields.earningsTaxable]) {
              Options[TAXABLE_EARNINGS] = false;
            } else {
              Options[TAXABLE_EARNINGS] = true;
            }
          }
        }

        return Options;
      }
      return null;
    }

    //?  Should this earnings be included in “Accrue Per Eligible Hours Worked” plans?
    case Fields.includeInAccrue: {
      let _yes = [1, 6]; //? Subcategory : 1099 or Labor
      //? For Calculation Method : Hourly & Piece Rate Hour
      if (
        data[Fields.calculationMethodId] === 2 ||
        data[Fields.calculationMethodId] === 4
      ) {
        if (_yes.includes(data[Fields.subcategoryId])) {
          return true;
        }
        return false;
      }
      return null;
    }
    //? Should employees see this earnings as an option when requesting time-off?
    case Fields.includeInTimeOff: {
      //? For Calculation Method : Hourly
      if (data[Fields.calculationMethodId] === 2) {
        return false;
      }
      return null;
    }
    //? Should employees only see this Request Time Off option if it has an Accrual Plan assigned?
    case Fields.includeIfAccrualPlanAssigned: {
      //? For Calculation Method : Hourly
      if (data[Fields.calculationMethodId] === 2) {
        return false;
      }
      return null;
    }
    //? Should this earnings be included in Time & Attendance?
    case Fields.includeInTimeAttendance: {
      let _yes = [1, 6]; //? Subcategory : 1099 or Labor
      if (_yes.includes(data[Fields.subcategoryId])) {
        return true;
      }
      return false;
    }
    //?  If an employee is on “Automatic Timesheets,” should this earnings subtract from “Normal Hours Per Pay Period” during Move to Pay?
    case Fields.subtractHoursPerPayPeriod: {
      let _yes = [3]; //? Category : Leave
      if (data[Fields.calculationMethodId] === 2) {
        //? For Calculation Method : Hourly
        if (data[Fields.includeInTimeAttendance]) {
          if (_yes.includes(data[Fields.categoryId])) {
            return true;
          } else {
            return false;
          }
        }
        return null;
      }
      return null;
    }
    //? Does this earnings count towards overtime?
    case Fields.countsTowardsOvertime: {
      let _no = [1]; //? Subcategory : 1099
      if (
        data[Fields.calculationMethodId] === 2 &&
        !data[Fields.overtimeType]
      ) {
        if (data[Fields.includeInTimeAttendance]) {
          ////? Subcategory : 1099 or Category : Leave
          if (
            _no.includes(data[Fields.subcategoryId]) ||
            data[Fields.categoryId] === 3
          ) {
            return false;
          } else {
            return true;
          }
        }
        return null;
      }

      return null;
    }
    //? Should this earnings be included in regular rate of pay calculations?
    case Fields.includeInRegularRate: {
      let _no = [3]; //? Category : Leave
      if (
        data[Fields.includeInTimeAttendance] ||
        (data[Fields.hasImportCode] && data[Fields.overtimeWeighted])
      ) {
        if (_no.includes(data[Fields.categoryId])) {
          return false;
        } else {
          return true;
        }
      }
      return null;
    }
    //? Should this earnings be paid at the employee’s regular rate of pay (e.g California sick leave)? (default : No)
    case Fields.regularRateOfPay: {
      if (
        data[Fields.includeInTimeAttendance] ||
        (data[Fields.hasImportCode] && data[Fields.overtimeWeighted])
      ) {
        //? For Calculation Method : Hourly

        if (data[Fields.calculationMethodId] === 2) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
    //? Are hours associated with this earnings considered productive (i.e. reported in Productivity Report)?
    case Fields.isProductiveHours: {
      //? For Calculation Method : Hourly and Piece Rate Hour

      if (
        data[Fields.calculationMethodId] === 2 ||
        data[Fields.calculationMethodId] === 4
      ) {
        //? Category : Leave
        if (data[Fields.categoryId] === 3) {
          return false;
        } else {
          return true;
        }
      }
      return null;
    }
    //? Should this earnings be included in workers’ compensation wage calculations (i.e. reported on the Workers Compensation Cost Report) ?
    case Fields.workersCompensation: {
      let _no = [GROUP_TERM_LIFE, 12];
      if (
        _no.includes(data[Fields.subcategoryId]) ||
        !data[Fields.earningsTaxable]
      ) {
        return false;
      }
      return true;
    }
    case Fields.includeInTipShortfall: {
      if (data[Fields.companyPayTipped]) {
        return true;
      }
      return null;
    }
    case Fields.overtimePaidTipped: {
      if (
        data[Fields.companyPayTipped] &&
        data[Fields.calculationMethodId] === HOURLY_RATE &&
        data[Fields.overtimeType]
      ) {
        return false;
      }
      return null;
    }
    default:
      return null;
  }
}
