import React, { Component } from "react";
import { connect } from "react-redux";

import { EarningsListPageContainer } from "component-library/earnings-list";
import { OverlayTrigger } from "react-bootstrap";
import { Badge } from "react-bootstrap";
import PubSub from "pubsub-js";
import NotificationPopover from "./../components/NotificationPopover";
import {
  getUnreadNotificationCount,
  setUnreadNotificationCount,
  resetUnreadNotificationCount
} from "app/navigation/actions/NotificationViewActions";
import { startCopy } from "./../network/companyActions";
import { subscribeNotification } from "./../../hubConnection";

import Proptypes from "prop-types";
class CopyEarningUsertest extends Component {
  state = { badgeCount: 0 };

  componentDidMount() {
    PubSub.subscribe("signalRNotify", (msg, data) => {
      this.setState({
        badgeCount: 1
      });
    });
  }
  render() {
    const { startCopy } = this.props;
    return (
      <EarningsListPageContainer>
        <h3>Copy Earning Usertest</h3>
        <div className="col-lg-4">
          <button
            className="nc-button outline-button"
            onClick={() => {
              startCopy();
              subscribeNotification();
            }}
          >
            <i className="fa fa-clone" aria-hidden="true" /> Copy Earnings
          </button>
        </div>
        <div className="col-lg-8 bell-container">
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={NotificationPopover(this.props.earningListNotification)}
            onEntered={() => {
              setTimeout(() => this.setState({ badgeCount: 0 }), 300);
            }}
            rootClose
          >
            <i
              id="notification-view-trigger"
              className="fa fa-bell-o"
              aria-hidden="true"
              style={{ fontSize: "20px" }}
            />
          </OverlayTrigger>
          {this.state.badgeCount > 0 && (
            <Badge bsClass="badge">{this.state.badgeCount}</Badge>
          )}
        </div>
      </EarningsListPageContainer>
    );
  }
}
const mapStateToProps = state => {
  return {
    unreadNotificationCount: state.unreadNotificationCount,
    earningListNotification: state.earningListNotification
  };
};

const mapDispatchToProps = {
  getUnreadNotificationCount,
  setUnreadNotificationCount,
  resetUnreadNotificationCount,
  startCopy
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyEarningUsertest);

CopyEarningUsertest.defaultProps = {
  unreadNotificationCount: 0,
  earningListNotification: []
};
CopyEarningUsertest.proptypes = {
  unreadNotificationCount: Proptypes.number,
  earningListNotification: Proptypes.array
};
