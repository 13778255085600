export const workEmailAddress = "WorkEmailAddress";
export const workPhoneNumber = "WorkPhoneNumber";
export const workPhoneNumberExt = "WorkPhoneNumberExt";
export const workFaxNumber = "WorkFaxNumber";
export const changeReasonName = "ChangeReasonName";
export const division = "Division";
export const changeReason = "ChangeReason";
export const businessUnit = "BusinessUnit";
export const changeDate = "ChangeDate";
export const department = "Department";
export const client = "Client";
export const project = "Project";
export const location = "Location";
export const newLocation = "NewLocation";
export const address = "Address";
export const addressLine1 = "AddressLine1";
export const addressLine2 = "AddressLine2";
export const primaryManager = "PrimaryManager";
export const secondaryManager = "SecondaryManager";
export const notes = "Notes";
