/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as utilities from "../../../../../app/common/utilities";
import SystemLevelStatusDropdown from "./SystemLevelStatusDropdown";
import SystemLevelCategoryDropdown from "./SystemLevelCategoryDropdown";
import SystemLevelDeductionDropdown from "./SystemLevelDeductionDropdown";
import { Modal } from "react-bootstrap";
import {
  getFilteredDeductionSetupList,
  setSelectedFilters,
  sortFilteredList
} from "../../../actions/systemlevel/DeductionSetupListingActions";
import Media from "react-media";
import * as Id from "app/common/CommonIds";
import $ from "jquery";
import Scrollbars from "react-custom-scrollbars";
class SystemLevelAdvanceFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategories: [],
      selectedStatus: [],
      selectedDeductions: [],
      sortItemList: [
        { id: 1, label: "Name : A to Z", sortTypeId: 1, sortKey: "Name" },
        { id: 2, label: "Name : Z to A", sortTypeId: 2, sortKey: "Name" },
        {
          id: 3,
          label: "Category : A to Z",
          sortTypeId: 1,
          sortKey: "DeductionCategoryName"
        },
        {
          id: 4,
          label: "Category : Z to A",
          sortTypeId: 2,
          sortKey: "DeductionCategoryName"
        },
        {
          id: 5,
          label: "Status : A to Z",
          sortTypeId: 1,
          sortKey: "CreateWorkFlowStateName"
        },
        {
          id: 6,
          label: "Status : Z to A",
          sortTypeId: 2,
          sortKey: "CreateWorkFlowStateName"
        }
      ],
      isResetToDefault: false,
      isInitialLoad: true
    };

    this.setCategories = this.setCategories.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.setDeductions = this.setDeductions.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);

    // this.closeMobileFilterPanel = this.closeMobileFilterPanel.bind(this);
  }

  closeMobileFilterPanel = () => {
    this.setState({ mobileFilterPanel: false }, () => {
      if (
        ["sm", "xs"].includes(this.state.device) &&
        $("html").hasClass("html-modal-open")
      ) {
        $("html").removeClass("html-modal-open");
      }
    });
  };

  openMobileFilterPanel = () => {
    this.setState({ mobileFilterPanel: true }, () => {
      if (
        ["sm", "xs"].includes(this.state.device) &&
        this.state.mobileFilterPanel
      ) {
        $("html").addClass("html-modal-open");
      } else {
        $("html").removeClass("html-modal-open");
      }
    });
  };

  closeMobileSortPanel = () => {
    this.setState({ mobileSortPanel: false }, () => {
      if (
        ["sm", "xs"].includes(this.state.device) &&
        $("html").hasClass("html-modal-open")
      ) {
        $("html").removeClass("html-modal-open");
      }
    });
  };

  openMobileSortPanel = () => {
    this.setState({ mobileSortPanel: true }, () => {
      if (
        ["sm", "xs"].includes(this.state.device) &&
        this.state.mobileSortPanel
      ) {
        $("html").addClass("html-modal-open");
      } else {
        $("html").removeClass("html-modal-open");
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedDeductions !== this.state.selectedDeductions ||
      prevState.selectedCategories !== this.state.selectedCategories ||
      prevState.selectedStatus !== this.state.selectedStatus
    ) {
      this.getFilteredList();
    } else if (
      this.props.systemDeductionMainList.DataCount > 0 &&
      this.state.isInitialLoad
    ) {
      this.getFilteredList();
    }

    //This code is added to resolve bug 48370
    if (
      prevProps.systemDeductionMainList !== this.props.systemDeductionMainList
    ) {
      if (Object.keys(this.props.systemDeductionMainList).length === 0) {
        this.setState({
          selectedDeductions: [],
          isInitialLoad: true
        });
      } else {
        this.getFilteredList();
      }
    }

    if (this.props.systemDeductionMainList) {
      if (this.props.systemDeductionMainList.DataCount > 1) {
        if (
          this.state.selectedDeductions.length === 0 &&
          this.state.isInitialLoad
        ) {
          let selectedDeductions = [0];
          this.props.systemDeductionMainList.Data.forEach(item => {
            selectedDeductions.push(item.SystemDeductionId);
          });

          this.setState({
            selectedDeductions
          });
        }
      }
    }
    if (this.props.systemLevelCategoryList) {
      if (this.props.systemLevelCategoryList.DataCount > 1) {
        if (
          this.state.selectedCategories.length === 0 &&
          this.state.isInitialLoad
        ) {
          let selectedCategories = [0];
          this.props.systemLevelCategoryList.Data.forEach(item => {
            selectedCategories.push(item.DeductionCategoryId);
          });
          this.setState({
            selectedCategories
          });
        }
      }
    }
    if (this.props.systemLevelStatusList) {
      if (this.props.systemLevelStatusList.DataCount > 1) {
        if (
          this.state.selectedStatus.length === 0 &&
          this.state.isInitialLoad
        ) {
          let selectedStatus = [];
          selectedStatus.push(this.props.systemLevelStatusList.Data[0].Id);
          selectedStatus.push(this.props.systemLevelStatusList.Data[2].Id);
          this.setState({
            selectedStatus
          });
        }
      }
    }
  }
  onResize = () => {
    let device = "lg";
    if (window.innerWidth <= 767) {
      device = "xs";
      if (this.state.mobileFilterPanel || this.state.mobileSortPanel) {
        if (!$("html").hasClass("html-modal-open")) {
          $("html").addClass("html-modal-open");
        }
      }
    }
    if (window.innerWidth > 767 && window.innerWidth < 991) {
      device = "sm";
      if (this.state.mobileFilterPanel || this.state.mobileSortPanel) {
        if (!$("html").hasClass("html-modal-open")) {
          $("html").addClass("html-modal-open");
        }
      }
    }
    if (window.innerWidth > 991 && window.innerWidth < 1200) {
      device = "md";
      if ($("html").hasClass("html-modal-open")) {
        $("html").removeClass("html-modal-open");
      }
    }
    if (window.innerWidth > 1200) {
      if ($("html").hasClass("html-modal-open")) {
        $("html").removeClass("html-modal-open");
      }
    }
    this.setState({ device });
  };
  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  getFilteredList() {
    if (
      this.props.systemDeductionMainList.DataCount > 0 &&
      this.props.systemLevelCategoryList.DataCount > 0 &&
      this.props.systemLevelStatusList.DataCount > 0
    ) {
      this.setState({
        isResetToDefault: false,
        isInitialLoad: false
      });
      this.props.setSelectedFilters(
        this.state.selectedDeductions,
        this.state.selectedCategories,
        this.state.selectedStatus
      );
      this.props.getFilteredDeductionSetupList(
        this.props.systemDeductionMainList.Data,
        this.state.selectedDeductions,
        this.state.selectedCategories,
        this.state.selectedStatus
      );
    }
  }

  applyFilter() {
    this.getFilteredList();
    this.closeMobileFilterPanel();
  }

  setCategories(selectedCategories) {
    this.setState({
      selectedCategories
    });
  }

  setStatus(selectedStatus) {
    this.setState({
      selectedStatus
    });
  }

  setInitialLoad() {
    this.setState({
      isInitialLoad: false
    });
  }
  setDeductions(selectedDeductions) {
    this.setState({
      selectedDeductions
    });
  }

  closeMobileFilterPanel() {
    this.refs.overlayMobileFilterPanel.hide();
  }

  closeMobileSortPanel() {
    this.refs.overlayMobileSortPanel.hide();
  }

  clearAllFilters() {
    this.setState({
      isResetToDefault: true
    });
  }

  sortColumnByKey(item) {
    if (this.props.systemDeductionFilteredList.Data) {
      let sortedFilteredList = utilities.sortByKey(
        this.props.systemDeductionFilteredList.Data,
        item.sortKey
      );
      if (item.sortTypeId === 2) {
        sortedFilteredList = sortedFilteredList.reverse();
      }

      let request = {
        data: {
          Data: sortedFilteredList,
          DataCount: sortedFilteredList.length
        }
      };

      this.props.sortFilteredList(request);
    }

    this.closeMobileSortPanel();
  }

  showSortListItem() {
    return this.state.sortItemList.map((item, index) => {
      return (
        <div
          key={index}
          className="mobile-sort-panel-item row remove-margin-left-right"
          onClick={this.sortColumnByKey.bind(this, item)}
        >
          <div className="col-sm-12 col-xs-12">{item.label}</div>
        </div>
      );
    });
  }

  render() {
    let filterCount = 0;

    if (this.props.systemDeductionMainList) {
      if (
        this.props.systemDeductionMainList.DataCount ===
        this.state.selectedDeductions.length - 1
      ) {
        filterCount = filterCount + 0;
      } else {
        filterCount = filterCount + this.state.selectedDeductions.length;
      }
    }
    if (this.props.systemLevelCategoryList) {
      if (
        this.props.systemLevelCategoryList.DataCount ===
        this.state.selectedCategories.length - 1
      ) {
        filterCount = filterCount + 0;
      } else {
        filterCount = filterCount + this.state.selectedCategories.length;
      }
    }
    if (this.props.systemLevelStatusList) {
      if (
        this.props.systemLevelStatusList.DataCount ===
        this.state.selectedStatus.length - 1
      ) {
        filterCount = filterCount + 0;
      } else {
        filterCount = filterCount + this.state.selectedStatus.length;
      }
    }
    const mobileFilterPanelNew = (
      <Modal
        id="mobile-filter"
        //bsClass="mobile-filter-modal"
        animation={false}
        backdrop={"static"}
        keyboard={false}
        show={this.state.mobileFilterPanel}
        onHide={this.closeMobileFilterPanel}
        onEnter={() => {
          document.body.style.overflowY = "hidden";
        }}
        onExit={() => {
          document.body.style.overflowY = "auto";
        }}
      >
        <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="header-container col-sm-12 col-xs-12 clearfix">
            <div className="close-icon col-sm-3 col-xs-3">
              <i
                id={Id.modalCloseIcon}
                onClick={this.closeMobileFilterPanel.bind(this)}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
            <div className="filter-label col-sm-6 col-xs-6">
              {" "}
              {filterCount > 0
                ? `Filters (${filterCount})`
                : "All Filters"}{" "}
            </div>
            <div className="clear-all-link col-sm-3 col-xs-3">
              <a onClick={this.clearAllFilters}>Clear All</a>
            </div>
          </div>
          <Scrollbars
            className="mobile-filter-content"
            style={{ height: window.innerHeight - 128 }}
          >
            <div
              id={Id.entityFilter}
              className="mobile-filter-select-dropdown no-padding col-sm-12 col-xs-12"
            >
              <SystemLevelDeductionDropdown
                onSelectedDeductions={selectedDeductions =>
                  this.setDeductions(selectedDeductions)
                }
                isResetToDefault={this.state.isResetToDefault}
              />
            </div>
            <div
              id={Id.categoryFilter}
              className="mobile-filter-select-dropdown no-padding col-sm-12 col-xs-12"
            >
              <SystemLevelCategoryDropdown
                onSelectedCategories={selectedCategories =>
                  this.setCategories(selectedCategories)
                }
                isResetToDefault={this.state.isResetToDefault}
              />
            </div>

            <div
              id={Id.statusFilter}
              className="mobile-filter-select-dropdown no-padding col-sm-12 col-xs-12"
            >
              <SystemLevelStatusDropdown
                onSelectedStatus={selectedStatus =>
                  this.setStatus(selectedStatus)
                }
                isResetToDefault={this.state.isResetToDefault}
                isInitialLoad={isInitialLoad => {
                  this.setInitialLoad();
                }}
              />
            </div>
          </Scrollbars>
        </Modal.Body>
        {/* </div> */}
        <Modal.Footer>
          <div className="mobile-filter-panel-button-section">
            <button
              type="button"
              id={Id.modalSuccessButton}
              className="apply-filter-button"
              onClick={this.applyFilter}
            >
              APPLY FILTERS
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
    const mobileSortPanelNew = (
      <Modal
        id="mobile-sort"
        animation={false}
        backdrop={"static"}
        keyboard={false}
        show={this.state.mobileSortPanel}
        onHide={this.closeMobileSortPanel}
      >
        <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="header-container col-sm-12 col-xs-12 clearfix">
            <div className="close-icon col-sm-3 col-xs-3">
              <i
                id={Id.modalCloseIcon}
                onClick={this.closeMobileSortPanel.bind(this)}
                className="fa fa-times-circle-o"
              />
            </div>
            <div className="sort-label col-sm-6 col-xs-6"> Sort By </div>
          </div>
          <hr />
          {this.showSortListItem()}
        </Modal.Body>
        <Modal.Footer>
          {/* <div className="mobile-filter-panel-button-section">
          <button type="button" id="mobile-apply-sort" className="nc-button apply-filter-button" >
               APPLY SORT
          </button>
      </div> */}
        </Modal.Footer>
      </Modal>
    );

    return (
      <Media query="(max-width: 991px)">
        {matches =>
          matches ? (
            <div className="nc-sort-filter-container hidden-md hidden-lg col-sm-12 col-xs-12">
              <button
                id={Id.sortButtonMobile}
                onClick={this.openMobileSortPanel.bind()}
                className=""
              >
                SORT BY
              </button>
              <button
                id={Id.filterButtonMobile}
                onClick={this.openMobileFilterPanel.bind()}
                className=""
              >
                FILTERS {filterCount === 0 ? null : "(" + filterCount + ")"}
              </button>
              {mobileFilterPanelNew}
              {mobileSortPanelNew}
            </div>
          ) : (
            <div className="nc-filter-container hidden-sm hidden-xs">
              <div className="showing-label">FILTER:</div>
              <div className="nc-filter-group" style={{ width: "100%" }}>
                <div className="nc-filter-row">
                  <div
                    id="select-deduction-dropdown"
                    className="nc-filter-dropdown"
                    style={{}}
                  >
                    <SystemLevelDeductionDropdown
                      onSelectedDeductions={selectedDeductions =>
                        this.setDeductions(selectedDeductions)
                      }
                    />
                  </div>
                  <div
                    id="select-category-dropdown"
                    className="nc-filter-dropdown"
                  >
                    <SystemLevelCategoryDropdown
                      onSelectedCategories={selectedCategories =>
                        this.setCategories(selectedCategories)
                      }
                    />
                  </div>

                  <div
                    id="select-status-dropdown"
                    className="nc-filter-dropdown"
                  >
                    <SystemLevelStatusDropdown
                      onSelectedStatus={selectedStatus =>
                        this.setStatus(selectedStatus)
                      }
                      isInitialLoad={isInitialLoad => {
                        this.setInitialLoad();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Media>
    );
  }
}

function mapStateToProps(state) {
  return {
    systemLevelCategoryList: state.systemLevelCategoryList,
    systemDeductionMainList: state.systemDeductionMainList,
    systemLevelStatusList: state.systemLevelStatusList,
    systemDeductionFilteredList: state.systemDeductionFilteredList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFilteredDeductionSetupList: getFilteredDeductionSetupList,
      setSelectedFilters: setSelectedFilters,
      sortFilteredList: sortFilteredList
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemLevelAdvanceFilter);
