export const NotPaidReadOnly = [16, 8, 9, 31];
export const NonTaxableEarningsReadOnly = [1, 3, 31];
export const DisabilityPayment = [29];
//Category IDS
export const STANDARD = 1;
export const FRINGE_BENEFITS = 2;
export const LEAVE = 3;
export const REIMBURSEMENTS = 4;
export const OTHER_EARNINGS = 5;

//Sub-Categories IDS
export const OTHER_PAID_FRINGE_BENEFIT = 9;
export const OTHER_NON_PAID_FRINGE_BENEFIT = 16;
export const NON_QUALIFIED_DEFERRED_COMPENSATION = 15;
export const GROUP_TERM_LIFE = 11;

//Calculation BASIS ID
export const DOLLAR_AMOUNT = 1;
export const HOURLY_RATE = 2;
export const PIECE_RATE = 3;
export const PIECE_RATE_HOUR = 4;

// MAX LIMITS FOR FIELDS
export const DOLLAR_AMOUNT_MIN = "0";
export const DOLLAR_AMOUNT_MAX = "922337203685477.5807";
export const PERCENT_AMOUNT_MAX = "49999899.99";
export const PERCENT_AMOUNT_MIN = "-100";

export const PERCENT_FIELD_VALUE_ERROR =
  "Percent should be in the range of -100.0000 - 49999899.99";
export const DOLLAR_FIELD_VALUE_ERROR =
  "Amount should be in the range of 0.0001 - 922,337,203,685,477.5807";

//Paycodes
//? (US 53851) : These Paycodes have some coditions to be checked on Edit and Delete
export const PAY_CODES = [
  "AUTDTOTPRM",
  "AUTMNWGSUP",
  "AUTPDBREAK",
  "AUTTHOTPRM",
  "MAKEUP",
  "GROUPLIFE"
];

export const EARNINGS = "EARNINGS";
export const EARNINGS_TEMPLATE = "EARNINGS_TEMPLATE";

//STEPS & UI DRAFT IDS
export const DESCRIPTION_STEP = 1;
export const CALCULATION_STEP = 2;
export const PAYCYCLE_STEP = 3;
export const REVIEW_STEP = 4;

//Payroll Frequency
export const FrequencyData = [
  { optionId: "IncludeInFirstScheduledPayroll", label: "1" },
  { optionId: "IncludeInSecondScheduledPayroll", label: "2" },
  { optionId: "IncludeInThirdScheduledPayroll", label: "3" },
  { optionId: "IncludeInFourthScheduledPayroll", label: "4" },
  { optionId: "IncludeInFifthScheduledPayroll", label: "5" }
];

//Page Links
export const DESCRIPTION_LINK = "/system-level-earnings/setup/description/";
export const NEW_DESCRIPTION_LINK = "/system-level-earnings/setup/";
export const CALCULATION_LINK = "/system-level-earnings/setup/calculation/";
export const PAYCYCLE_LINK = "/system-level-earnings/setup/paycycle/";
export const REVIEW_LINK = "/system-level-earnings/setup/reviewAndSave/";
export const LIST_PAGE_LINK = "/system-level-earnings";

//User roles
export const NET_ADMIN = 1;
export const CLIENT_ADMIN = 2;
export const MANAGER = 3;
export const EMPLOYEE = 4;

//NETWORK REQUESTS
export const PATCH = "update";
export const CREATE = "create";

//Toast Messages
export const ERROR_TOAST = "Please review errors below.";
export const SUCCESS_TOAST = "success";
export const RED_TOAST = "danger";

export const PERCENT_PREMIUM_TOASTER =
  "Contact your service representative with any questions";
export const UNSAVED_CHANGES =
  "You have unsaved changes. Are you sure you want to leave?";

export const SAVE_GO = "SAVE AND GO";
export const LEAVE_PAGE = "LEAVE THIS PAGE";
export const SAVE_EXIT = "SAVE AND EXIT";
export const EXIT = "EXIT SETUP";
export const DELETE_EARNINGS = "DELETE EARNINGS";

export const deleteMessage = {
  line1: "Are you sure you want to delete this earnings?",
  line2: "It will be gone for now, but you can add it back if you need it."
};
export const toggleBackMessage = {
  line1: "Do you want to save your changes before leaving this page?",
  line2: "",
  line3:
    "Are you sure you want to leave this page in the middle of making edits?",
  line4: "Changes made on this page will not be saved."
};
export const finishLaterMessage = {
  line1: "Do you want to save your changes before exiting setup?",
  line2: "",
  line3: "Are you sure you want to exit setup in the middle of making edits?",
  line4: "Changes made on this page will not be saved."
};
