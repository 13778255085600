import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ContactApproversActive } from "./../images";
const tooltip_decline = <Tooltip id="tooltip_decline">Decline</Tooltip>;
const tooltip_approve = <Tooltip id="tooltip_approve">Approve</Tooltip>;

const tooltip_contact_approver = (
  <Tooltip id="tooltip_contact_approver">Contact Approver</Tooltip>
);
const PtoApproveActions = ({
  onApprove = () => {},
  onBanOperation = () => {},
  onContactClick = () => {},
  hideApprove = false,
  hideBan = false,
  hideContact = false,
  noPadding = false,
  noMargin = false
}) => (
  <div
    className="row payroll-dashboard-row"
    style={{ padding: noPadding && 0, marginBottom: noMargin && 0 }}
  >
    {/* <div className="column-action col-sm-2" /> */}
    {!hideContact && (
      <div className="column-action">
        <OverlayTrigger placement="top" overlay={tooltip_contact_approver}>
          <span style={{ cursor: "pointer" }} onClick={onContactClick}>
            <ContactApproversActive width="22" height="22" />
          </span>
        </OverlayTrigger>
      </div>
    )}

    {!hideApprove && (
      <div className="column-action">
        <OverlayTrigger placement="top" overlay={tooltip_approve}>
          <i
            className="fa fa-check nc-green"
            aria-hidden="true"
            onClick={onApprove}
          />
        </OverlayTrigger>
      </div>
    )}

    {!hideBan && (
      <div className="column-action">
        <OverlayTrigger placement="top" overlay={tooltip_decline}>
          <i
            className="fa fa-ban nc-red"
            aria-hidden="true"
            onClick={onBanOperation}
          />
        </OverlayTrigger>
      </div>
    )}
  </div>
);

export default PtoApproveActions;
