import { combineReducers } from "redux";
import * as types from "../../actions/generalledger/index";

//for deduction drop down
function employerDeductionList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYER_EXPENCE_LIABILITY_ACCOUNTS_MAIN_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

////for expense account drop down
function employerExpenceAccountsList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYER_EXPENCE_ACCOUNTS_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

// employer main list reducer
function employerExpenceLiabilityAccountsList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYER_EXPENCE_LIABILITY_ACCOUNTS_MAIN_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

//employer filtered list reducer
function employerExpenceLiabilityAccountsFilteredList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYER_FILTERED_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

//for liability account drop down
function employerLiabilityAccountsList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYER_LIABILITY_ACCOUNTS_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

//employer-employee reducer for main list
function employeeExpenceLiabilityAccountsList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYEE_LIABILITY_ACCOUNTS_MAIN_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

//liability account
function employeeLiabilityAccountsList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYEE_LIABILITY_ACCOUNTS_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

//for deduction drop down
function employeeDeductionFilterList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYEE_LIABILITY_ACCOUNTS_MAIN_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

//filtered list employer-employee
function employeeExpenceLiabilityAccountsFilteredList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYEE_FILTERED_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

function UnMappedCheckBoxStateValue(state = null, action) {
  switch (action.type) {
    case types.UNMAPPED_CHECK_STATE_TYPE:
      return action.payload;
    default:
      return state;
  }
}

function setEmployeeData(state = [], action) {
  switch (action.type) {
    case types.EMPLOYEE_DATA:
      return action.payload;
    default:
      return state;
  }
}

function setSaveEmployeePage(state = [], action) {
  switch (action.type) {
    case types.EMPLOYEE_SAVED_PAGES:
      return action.payload;
    default:
      return state;
  }
}

//redux to store data
function EmployerExpensesData(state = [], action) {
  switch (action.type) {
    case types.SET_EMPLOYER_EXPENCE_DATA:
      return action.payload;
    default:
      return state;
  }
}

function setSelectedFiltersReducer(state = [], action) {
  switch (action.type) {
    case types.GENERAL_LEDGER_SELECTED_FILTERS:
      return action.payload;
    default:
      return state;
  }
}

const generalLedgerReducer = combineReducers({
  employerExpenceLiabilityAccountsList,
  employerExpenceLiabilityAccountsFilteredList,
  employerExpenceAccountsList,
  employerLiabilityAccountsList,
  employerDeductionList,

  employeeExpenceLiabilityAccountsList,
  employeeExpenceLiabilityAccountsFilteredList,
  employeeLiabilityAccountsList,
  employeeDeductionFilterList,

  setEmployeeData,
  setSaveEmployeePage,

  UnMappedCheckBoxStateValue,
  EmployerExpensesData,
  setSelectedFiltersReducer
});

export default generalLedgerReducer;
