import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import ReduxPromise from 'redux-promise'
import './polyfillFindIndex.js'
import RootRouter from './RootRouter'
import 'url-search-params-polyfill'
import './content/style.css'
import 'react-toggle/style.css'
import 'rc-drawer/assets/index.css'
import './main.css'
import reducers from './reducers'
import thunk from 'redux-thunk'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(ReduxPromise, thunk))
)
render(
  <Provider store={store}>
    <RootRouter />
  </Provider>,
  document.getElementById('root')
)
