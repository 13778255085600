/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Panel } from "react-bootstrap";
import PropTypes from "prop-types";
import "./Leftbar.css";
import { Link } from "react-scroll";
import Scroller from "./scroller";

const PanelBar = ({ id, name, active, onClick }) => (
  <a
    className="clearfix"
    id={`add_deduction_popup_panel_link-` + id}
    onClick={!active ? onClick : () => {}}
  >
    <div
      className="panel-header"
      style={{ backgroundColor: active ? "#abdeab" : "" }}
    >
      {name}
    </div>
  </a>
);

export default class Sidebar extends Component {
  render() {
    const {
      panelData = {},
      activePanelId,
      togglePanel,
      activeKey,
      employeeId,
      showStatusOverlay,
      showWorkplaceOverlay
    } = this.props;
    let showOverlay = showStatusOverlay || showWorkplaceOverlay ? true : false;

    return (
      <div
        id="left-bar"
        className="left-bar-container col-lg-3 col-md-3 hidden-sm hidden-xs"
        style={{ display: showOverlay ? "none" : "block" }}
      >
        <div style={{ padding: "20px 30px", borderBottom: "1px solid #ccc" }}>
          <a
            onClick={() =>
              this.props.history.replace(
                `/people/employees/dashboard/${employeeId}`
              )
            }
          >
            <i
              className="fa fa-chevron-left add_deduction_popup_panel_link"
              aria-hidden="true"
            />
            Back to Dashboard
          </a>{" "}
        </div>

        <div style={{ padding: "20px 30px", fontSize: "24px" }}>
          {panelData.CustomFieldGroupName}
        </div>
        {panelData.SubItemList &&
          panelData.SubItemList.map((item, i) => (
            <div key={i} className="panel-container">
              <PanelBar
                name={item.CustomFieldGroupName}
                id={item.CustomFieldGroupId}
                active={activePanelId === item.CustomFieldGroupId}
                onClick={() =>
                  togglePanel(
                    item.CustomFieldGroupId,
                    item.CustomFieldGroupName
                  )
                }
              />
              <Panel
                className={
                  "panel" +
                  (activePanelId !== item.CustomFieldGroupId
                    ? "inactive-panel"
                    : "")
                }
                collapsible
                expanded={activePanelId === item.CustomFieldGroupId}
              >
                <Panel.Collapse>
                  <Panel.Body>
                    {item.SubSubItemList.length > 0 && (
                      <div className="panel-body-container">
                        <Scroller id={item.CustomFieldGroupId} />

                        <ul className="entity-list">
                          {item.SubSubItemList.map((i, k) => (
                            <li
                              key={k}
                              className={
                                activeKey === i.Id
                                  ? "entity-list-item  active-item"
                                  : "entity-list-item"
                              }
                              // onClick={() => onPageSelect(i.title, i.id)}
                            >
                              <Link
                                activeClass="active-item"
                                to={i.Id}
                                spy={true}
                                smooth={true}
                                duration={500}
                                onSetActive={e =>
                                  this.props.handleSetActive(
                                    e,
                                    i.CustomFieldGroupName
                                  )
                                }
                              >
                                {i.CustomFieldGroupName}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>
            </div>
          ))}
      </div>
    );
  }
}

Sidebar.propTypes = {
  panelData: PropTypes.object,
  activePanelId: PropTypes.number,
  TogglePanel: PropTypes.func
};
Sidebar.defaultProps = {
  panelData: {
    SectionId: 1,
    SectionName: "Personal Info",
    SectionItemList: [
      {
        SectionItemId: 11,
        SectionItemName: "Demographics",
        SectionDetailList: [
          { Id: 111, Name: "Basic Info" },
          { Id: 112, Name: "Addresses" },
          { Id: 113, Name: "Contact Info" },
          { Id: 114, Name: "Additional Info" }
        ]
      },
      {
        SectionItemId: 12,
        SectionItemName: "Dependents/Beneficiaries",
        SectionDetailList: [
          { Id: 211, Name: "Basic Info" },
          { Id: 212, Name: "Addresses" },
          { Id: 213, Name: "Contact Info" },
          { Id: 214, Name: "Additional Info" }
        ]
      },

      {
        SectionItemId: 13,
        SectionItemName: "Emergency Contact",
        SectionDetailList: []
      }
    ]
  },
  activePanelId: 1,
  togglePanel: () => alert("Specify the onChange handler for panels")
};
