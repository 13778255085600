import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SearchBox from "./SearchBox";
import PropTypes from "prop-types";
const list_icon = <Tooltip id="tooltip_inactive_status_column">List</Tooltip>;
const search_icon = (
  <Tooltip id="tooltip_inactive_status_column">Search</Tooltip>
);
const filter_icon = (
  <Tooltip id="tooltip_inactive_status_column">Advanced Filters</Tooltip>
);

export default class BottomNavigationBar extends Component {
  state = { searchBoxFocused: false };
  handleBlur = () => {
    setTimeout(() => {
      this.setState({ searchBoxFocused: false });
    }, 200);
  };
  handleFocus = () => {
    this.setState({ searchBoxFocused: true });
    // document.getElementById("search-box").focus();
  };

  render() {
    const {
      previousEmployeeClick,
      nextEmployeeClick,
      nextEmployee,
      previousEmployee,
      onListViewClick,
      onAdvanceFilterClick,
      resetFilters,
      options,
      onSearchEmployeeClick,
      showAdvanceFilter,
      showClearFilters
    } = this.props;
    return (
      <div className="bottom-navigation-bar">
        <div className="left-link-section">
          {previousEmployee && (
            <div
              className="left-link"
              onClick={() => previousEmployeeClick(previousEmployee.Id)}
            >
              <div style={{ marginRight: "5px" }}>
                <i className="fa fa-angle-left" style={{ fontSize: "42px" }} />
              </div>
              <div style={{ fontSize: "12px" }}>
                PREVIOUS{" "}
                <div style={{ fontSize: "16px" }}>{previousEmployee.Name}</div>
              </div>
            </div>
          )}
        </div>

        <div className="icons-section">
          {!this.state.searchBoxFocused && (
            <OverlayTrigger placement="top" overlay={list_icon}>
              <div
                className="icon"
                style={{
                  visibility: !this.state.searchBoxFocused
                    ? "visible"
                    : "hidden"
                }}
                onClick={onListViewClick}
              >
                <i className="fa fa-list-ul" />
              </div>
            </OverlayTrigger>
          )}
          {!this.state.searchBoxFocused && (
            <OverlayTrigger placement="top" overlay={search_icon}>
              <div className="icon" onClick={this.handleFocus}>
                <i className="fa fa-search" />
              </div>
            </OverlayTrigger>
          )}
          <SearchBox
            searchBoxFocused={this.state.searchBoxFocused}
            handleBlur={this.handleBlur}
            options={options.employee}
            onSelect={id => onSearchEmployeeClick(id)}
          />

          {!this.state.searchBoxFocused && (
            <OverlayTrigger placement="top" overlay={filter_icon}>
              <div
                className="icon"
                style={{
                  visibility: !this.state.searchBoxFocused
                    ? "visible"
                    : "hidden"
                }}
                onClick={onAdvanceFilterClick}
              >
                <i className="fa fa-filter" />
              </div>
            </OverlayTrigger>
          )}
          {!this.state.searchBoxFocused &&
            !showAdvanceFilter &&
            showClearFilters && (
              <span className="clear-filters-link" onClick={resetFilters}>
                <i className="fa fa-trash" /> Clear All Filters
              </span>
            )}
        </div>

        <div className="right-link-section">
          {nextEmployee && (
            <div
              className="right-link"
              onClick={() => nextEmployeeClick(nextEmployee.Id)}
            >
              <div style={{ fontSize: "12px" }}>
                NEXT
                <div style={{ fontSize: "16px" }}>{nextEmployee.Name}</div>
              </div>
              <div style={{ marginLeft: "5px" }}>
                <i className="fa fa-angle-right" style={{ fontSize: "42px" }} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

BottomNavigationBar.propTypes = {
  nextEmployeeClick: PropTypes.func.isRequired,
  previousEmployeeClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  onListViewClick: PropTypes.func.isRequired,
  onAdvanceFilterClick: PropTypes.func.isRequired,
  onClearAdvanceFilters: PropTypes.func.isRequired
};
BottomNavigationBar.defaultProps = {
  nextEmployeeClick: () =>
    alert("This will take you to the next employee page"),
  previousEmployeeClick: () =>
    alert("This will take you to the previous employee page"),
  item: {
    PreviousEmployee: {
      Name: "Mark Cambre",
      Id: 4
    },
    NextEmployee: {
      Name: "Stephen Drew",
      Id: 3
    }
  },
  onListViewClick: () => alert("This will navigate to employee list page"),
  onAdvanceFilterClick: () =>
    alert("This will open the advance filter section"),
  onClearAdvanceFilters: () => alert("This will clear all advance filters")
};
