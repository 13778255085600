import React from "react";
import {
  BenefitsCobra,
  RetirementDetails
} from "component-library/employee-profile/employment-info/change-status/retired/index";
import {
  ScheduledDeductions,
  ReplacementManagers,
  ScheduledEarnings
} from "component-library/employee-profile/employment-info/change-status/common";
import FooterButtons from "../components/FooterButtons";
import { RETIRED_FOOTNOTE } from "../Constants";

const Retired = ({ data, onChange, onCloseOverlay }) => {
  return (
    <div>
      <RetirementDetails onChange={onChange} data={data} />
      <BenefitsCobra onChange={onChange} data={data} />
      <ScheduledEarnings onChange={onChange} data={data} />
      <ScheduledDeductions onChange={onChange} data={data} />
      <ReplacementManagers onChange={onChange} data={data} />

      <FooterButtons
        buttonLabel="Retire Employee"
        footNote={RETIRED_FOOTNOTE}
        onCancelClick={onCloseOverlay}
      />
    </div>
  );
};

export default Retired;
