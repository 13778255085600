import React from "react";
import { Modal } from "react-bootstrap";
const ModalBody = props => (
  <Modal.Body>
    <div id="cancel-modal-body" className="modal-row text-center">
      <div className="cancel-modal-line-1">{props.line1}</div>
      {props.line2 && (
        <div>
          <br />
          <div className="cancel-modal-line-1">{props.line2}</div>
        </div>
      )}
    </div>
  </Modal.Body>
);
export default ModalBody;
