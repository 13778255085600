export const SET_EMERGENCY_DATA = "SET_EMERGENCY_DATA";
export const CLEAR_EMERGENCY_DATA = "CLEAR_EMERGENCY_DATA";

const emergencyContactData = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_EMERGENCY_DATA:
      const { name, values } = payload;
      return { ...state, [name]: values };
    default:
      return state;
  }
};

export default emergencyContactData;

export const setEmergencyValues = (name, values) => ({
  type: SET_EMERGENCY_DATA,
  payload: { name, values }
});

export const clearEmergencyValues = () => ({
  type: CLEAR_EMERGENCY_DATA
});
