import * as types from "../../../EarningsGroup/actions/companylevel/index";
export default function(state = [], action) {
  switch (action.type) {
    case types.ADD_EARNINGS_PAGE_LISTING_EARNING_FILTERED_LIST:
      return action.payload.data;

    default:
      return state;
  }
}
