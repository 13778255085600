import * as types from "../actions/index";
export default function(state = null, action) {
  switch (action.type) {
    case types.TAX_PRIORITY_LIST:
      return action.payload;

    // no default
  }
  return state;
}
