import React, { Component } from "react";
import FilterPanel from "../filters/FilterPanel";
import Scrollbars from "react-custom-scrollbars";
import { Modal } from "react-bootstrap";
import { FILTER_LABELS, PAGE_FILTERS } from "./Constants";

const labelMaker = (noLabel, allLabel, someLabel, oneLabel) => (o, s, all) =>
  all
    ? allLabel
    : Object.keys(s).filter(k => s[k]).length === 0
      ? noLabel
      : Object.keys(s).filter(k => s[k]).length === 1
        ? oneLabel
        : someLabel;

export default class EntityListFilterModal extends Component {
  state = {
    setPanelID: 0
  };

  showPanelHandler = id => {
    if (this.state.setPanelID === id) {
      this.setState({ setPanelID: 0 });
    } else {
      this.setState({
        showPanel: !this.state.showPanel,
        setPanelID: id
      });
    }
  };

  render() {
    const {
      show,
      options,
      makeOnSelect,
      makeSelectAll,
      selectedOptions,
      closePanel,
      clearAll,
      page,
      filterData,
      showUnmapped,
      unmappedId,
      showUnmappedName
    } = this.props;
    const finalFilterData = filterData || PAGE_FILTERS[page];
    return (
      <Modal
        id="mobile-filter"
        animation={false}
        backdrop={"static"}
        keyboard={false}
        show={show}
      >
        <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="header-container col-sm-12 col-xs-12 clearfix">
            <div className="close-icon col-sm-3 col-xs-3">
              <i
                id="system-level-cancel-button-close-icon"
                onClick={closePanel}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
            <div className="filter-label col-sm-6 col-xs-6">
              {" All Filters "}
            </div>
            <div
              className="clear-all-link col-sm-3 col-xs-3"
              onClick={clearAll}
            >
              Clear All
            </div>
          </div>
          <Scrollbars style={{ height: window.innerHeight - 109 }}>
            {Object.keys(finalFilterData).map((key, i) => (
              <FilterPanel
                key={key}
                showPanelHandler={this.showPanelHandler.bind(this, i + 1)}
                showPanel={this.state.setPanelID === i + 1}
                showPanelName={labelMaker(
                  FILTER_LABELS[page][key][0],
                  FILTER_LABELS[page][key][1],
                  FILTER_LABELS[page][key][2],
                  FILTER_LABELS[page][key][3]
                )}
                options={options[key]}
                selectedItems={selectedOptions[key]}
                onSelect={makeOnSelect(key)}
                selectAll={makeSelectAll(key)}
                onCheckboxClick={makeOnSelect("unmapped")}
                selectedOptions={selectedOptions}
                showUnmapped={showUnmapped}
                unmappedId={unmappedId}
                showUnmappedName={showUnmappedName}
                {...finalFilterData[key]}
              />
            ))}
          </Scrollbars>
        </Modal.Body>
        <Modal.Footer>
          <div className="mobile-filter-panel-button-section">
            <button
              type="button"
              id="mobile-apply-filters"
              className="apply-filter-button"
              onClick={closePanel}
            >
              APPLY FILTERS
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
