import React from "react";

const TaxVerificationInTitle = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 41.3 46"
    style={{ enableBackground: "new 0 0 41.3 46" }}
    width={width}
    height={height}
  >
    <g>
      <g>
        <path
          className="st5"
          d="M7.3,16.1h18.8c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8H7.3c-0.4,0-0.8,0.3-0.8,0.8C6.6,15.7,6.9,16.1,7.3,16.1z"
        />
        <path
          className="st5"
          d="M7.3,10.1h18.8c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8H7.3c-0.4,0-0.8,0.3-0.8,0.8C6.6,9.7,6.9,10.1,7.3,10.1z"
        />
        <path
          className="st5"
          d="M21,32.6H7.3c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h13.3C20.7,33.6,20.9,33.1,21,32.6z"
        />
        <path
          className="st5"
          d="M7.3,22h18.8c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8H7.3c-0.4,0-0.8,0.3-0.8,0.8C6.6,21.7,6.9,22,7.3,22z"
        />
        <path
          className="st5"
          d="M7.3,28.1h16.5c0.6-0.6,1.2-1.1,2-1.4H7.3c-0.4,0-0.8,0.3-0.8,0.8C6.6,27.8,6.9,28.1,7.3,28.1z"
        />
      </g>
      <g>
        <g>
          <circle className="st1" cx="31.4" cy="36" r="7.9" />
        </g>
        <g>
          <path
            className="st5"
            d="M31.4,44.7c-4.8,0-8.6-3.9-8.6-8.6s3.9-8.6,8.6-8.6S40,31.2,40,36S36.2,44.7,31.4,44.7z M31.4,28.8c-4,0-7.1,3.2-7.1,7.1s3.2,7.1,7.1,7.1c4,0,7.1-3.2,7.1-7.1S35.3,28.8,31.4,28.8z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="st5"
            d="M30.7,39.2h-0.2c-0.2,0-0.4-0.1-0.6-0.2l-2.8-2.8c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0l2.2,2.2l3.8-4.5c0.2-0.3,0.8-0.3,1.1-0.1c0.3,0.2,0.3,0.8,0.1,1.1l-4.2,5.1C31.2,39.1,30.9,39.2,30.7,39.2z"
          />
        </g>
      </g>
      <path
        className="st5"
        d="M21.1,39.5H5.8c-1.6,0-2.9-1.3-2.9-2.9V6.2c0-1.6,1.3-2.9,2.9-2.9h22.1c1.6,0,2.9,1.3,2.9,2.9v19c0.2,0,0.4,0,0.8,0c0.2,0,0.6,0,0.8,0v-19c0-2.4-2-4.3-4.3-4.3H5.9c-2.4,0-4.3,2-4.3,4.3v30.4c0,2.4,2,4.3,4.3,4.3h16C21.4,40.5,21.2,39.9,21.1,39.5z"
      />
    </g>
  </svg>
);

export default TaxVerificationInTitle;
