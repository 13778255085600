import React, { Component } from "react";
import { PropTypes } from "prop-types";
import classNames from "classnames/bind";
import getInputComponent from "component-library/inputFields/getInputComponent";
import SingleCheckBox from "component-library/checkBoxGroup/SingleCheckBox";
import Toaster from "component-library/toasters";
import * as FIELDS from "../../utils/Constants";

const options = [
  {
    id: 1,
    label: "Yes",
    value: true,
    radioStyles: "radioStyles"
  },
  {
    id: 0,
    label: "No",
    value: false,
    radioStyles: "radioStyles"
  }
];

class Graduated extends Component {
  state = { showTooltip: false };
  showTooltipHandler = () => {
    this.setState({
      showTooltip: !this.state.showTooltip
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        showTooltip: false
      });
    }
  }

  render() {
    const { props } = this;
    let lgClass = "col-lg-3";
    let mdClass = "col-md-6";
    let smClass = "col-sm-12";
    let xsClass = "col-xs-12";
    if (props.Size) {
      if (props.Size.length > 0) {
        lgClass = props.Size[0] && "col-lg-" + props.Size[0];
        mdClass = props.Size[1] && "col-md-" + props.Size[1];
        smClass = props.Size[2] && "col-sm-" + props.Size[2];
        xsClass = props.Size[3] && "col-xs-" + props.Size[3];
      }
    }

    let wrapperClass;
    if (props.class === undefined) {
      wrapperClass = "nc-field-group clearfix";
    } else {
      wrapperClass = "nc-field-group " + props.class + " clearfix";
    }
    if (
      (props.value == null || props.value === "") &&
      props.required &&
      props.error
    ) {
      wrapperClass = wrapperClass + " has-error " + props.inputName;
    }
    const InputFieldClass = classNames(
      "input-field",
      props.className,
      lgClass,
      mdClass,
      smClass,
      xsClass
    );
    let Component = getInputComponent("radio");
    return (
      <div className={wrapperClass}>
        <div style={{ clear: "both", display: "flex", alignItems: "center" }}>
          {props.Toaster &&
            props.Toaster.show && (
              <Toaster
                show={props.Toaster.show}
                alertClassName={props.Toaster.type}
                message={props.Toaster.content}
              />
            )}
          <div
            id={"Label" + props.inputName}
            style={{ paddingRight: "15px" }}
            className={"col-lg-3 col-md-3 col-sm-3 field-name clearfix"}
          >
            Graduated?
          </div>
          {props.inputType === "Label" ? (
            <div style={{ marginBottom: "15px" }}>{props.value}</div>
          ) : (
            <div
              className={"col-lg-9 col-md-9 col-sm-9"}
              style={{ clear: "both", display: "flex", alignItems: "center" }}
            >
              <Component
                {...props}
                type="basic"
                radios={options}
                onChange={props.onChange}
                onSelect={props.onChange}
                name={FIELDS.GRADUATED}
                className={InputFieldClass}
                value={props.data[FIELDS.GRADUATED]}
              />
              <div className="clearfix" style={{ marginBottom: "20px" }}>
                <SingleCheckBox
                  inputName={FIELDS.CURRENTLY_ENROLLED}
                  value={props.data[FIELDS.CURRENTLY_ENROLLED]}
                  label="Currently Enrolled?"
                  onChange={value => {
                    props.onChange({
                      name: FIELDS.CURRENTLY_ENROLLED,
                      value: value
                    });
                  }}
                  disabled={
                    props.data[FIELDS.GRADUATED] !== undefined
                      ? props.data[FIELDS.GRADUATED]
                      : true
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Graduated;
Graduated.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string
};

Graduated.defaultProps = {
  descriptionStyle: "",
  hideTopdescription: false,
  colSpan: ""
};
