import React, { Component } from "react";
import classNames from "classnames/bind";
import { setStepState, setStepClass } from "./StepActions";
import ProgressItem from "./ProgressItemMobile";
const styles = {
  accessible: "accessible-step-sm", //2
  active: "active-step-sm", //1
  inaccessible: "inaccessible-step-sm" //0
};

export default class SetupProgressMobile extends Component {
  state = {};
  componentDidMount() {}
  goTopage = step => {
    this.props.switchSteps(step.link, step.id);
  };
  renderSteps = steps => {
    var cx = classNames.bind(styles);
    return steps.map(step => {
      let stepState = setStepState(
        step.id,
        this.props.activeStep,
        this.props.uiDraftId
      );
      let stepClass = setStepClass(stepState, cx);
      return (
        <ProgressItem
          key={step.id}
          id={step.id}
          active={this.props.uiDraftId >= step.id}
          className={stepClass}
          onClick={this.goTopage.bind(this, step)}
          stepName={step.shortName}
        />
      );
    });
  };
  render() {
    const { props } = this;
    return (
      <div className="step-mobile-inner-container visible-sm visible-xs">
        <div
          className="hbar"
          id="hbar"
          style={{
            marginLeft: props.HbarMarginLeft,
            marginRight: props.HbarMarginRight,
            width: props.widthHbar,
            minWidth: "initial"
          }}
        />
        <div className="add-deduction-step-mobile-table">
          <div className="add-deduction-step-sub-menu-mobile">
            {this.renderSteps(props.steps)}
          </div>
        </div>
      </div>
    );
  }
}
