import React, { Component } from "react";
import { TabContainer } from "component-library/employee-profile/entity-view";
import EmployeeListHeader from "component-library/employee-profile/list-view/EmployeeListHeader";
import DependentTable from "component-library/employee-profile/dependents/DependentTable";
//import { Element } from "react-scroll";
import {
  DEMOGRAPHIC_HEADER,
  //interests,
  dependentSampleList
} from "../Constants";

class DependentsList extends Component {
  state = {
    activePanel: {},
    errors: {},
    showToaster: false,
    toasterClass: "",
    toasterMessage: "",
    isAddModeOn: false
  };

  toggleToaster = () => {
    this.setState({
      showToaster: false
    });
  };

  setToaster = (toasterMessage, toasterClass) => {
    this.setState({
      toasterClass: toasterClass,
      toasterMessage: toasterMessage,
      showToaster: true
    });
  };
  render() {
    const {
      //ActivePanelId,
      EmployeeId
    } = this.props.match.params;
    //let activePanelId = parseInt(ActivePanelId, 10);
    return (
      <div>
        <TabContainer
          activePanelHeader={DEMOGRAPHIC_HEADER[3]}
          isAddModeOn={this.state.isAddModeOn}
          showToaster={this.state.showToaster}
          toasterClass={this.state.toasterClass}
          toasterMessage={this.state.toasterMessage}
          toggleToaster={this.toggleToaster}
        >
          <EmployeeListHeader
            onAddButtonClick={() =>
              this.props.history.push(
                `/people/employees/dashboard/${EmployeeId}/personalInfo/5`
              )
            }
          />
          <DependentTable data={dependentSampleList} />
        </TabContainer>
      </div>
    );
  }
}

export default DependentsList;
