import React from "react";
import "./svg.css";
const MapGLNoData = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 200 200"
    style={{ enableBackground: "new 0 0 200 200" }}
    width={width}
    height={height}
  >
    <g>
      <g>
        <circle className="st0" cx="100" cy="100" r="100" />
      </g>
    </g>
    <g>
      <path
        className="st1"
        d="M122.5,81.7H90.7c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h31.8c1,0,1.8-0.8,1.8-1.8S123.4,81.7,122.5,81.7z"
      />
      <path
        className="st1"
        d="M122.5,96.4H90.7c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h31.8c1,0,1.8-0.8,1.8-1.8S123.4,96.4,122.5,96.4z"
      />
      <path
        className="st1"
        d="M122.5,111H90.7c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h31.8c1,0,1.8-0.8,1.8-1.8S123.4,111,122.5,111z"
      />
      <path
        className="st1"
        d="M122.5,125.6H90.7c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h31.8c1,0,1.8-0.8,1.8-1.8S123.4,125.6,122.5,125.6z"
      />
      <path
        className="st1"
        d="M122.5,140.3H90.7c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h31.8c1,0,1.8-0.8,1.8-1.8S123.4,140.3,122.5,140.3z"
      />
      <path
        className="st1"
        d="M82.6,81.7h-5.3c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h5.3c1,0,1.8-0.8,1.8-1.8S83.6,81.7,82.6,81.7z"
      />
      <path
        className="st1"
        d="M82.6,96.4h-5.3c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h5.3c1,0,1.8-0.8,1.8-1.8S83.6,96.4,82.6,96.4z"
      />
      <path
        className="st1"
        d="M82.6,111h-5.3c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h5.3c1,0,1.8-0.8,1.8-1.8S83.6,111,82.6,111z"
      />
      <path
        className="st1"
        d="M82.6,125.6h-5.3c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h5.3c1,0,1.8-0.8,1.8-1.8S83.6,125.6,82.6,125.6z"
      />
      <path
        className="st1"
        d="M82.6,140.3h-5.3c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h5.3c1,0,1.8-0.8,1.8-1.8S83.6,140.3,82.6,140.3z"
      />
      <path
        className="st1"
        d="M127.2,60.6v-0.5c0-3.2-2.6-5.7-5.7-5.7h-10.1c-0.3-5.6-4.9-10.1-10.6-10.1s-10.3,4.5-10.6,10.1H79.8
		c-3.2,0-5.7,2.6-5.7,5.7v0.4h-1c-5.8,0-10.5,4.7-10.5,10.5v74.1c0,5.8,4.7,10.5,10.5,10.5h53.8c5.8,0,10.5-4.7,10.5-10.5V71.1
		C137.4,65.4,132.8,60.8,127.2,60.6z M77.6,60.2c0-1.2,1-2.2,2.2-2.2H92c0.5,0,1-0.2,1.3-0.6c0.3-0.4,0.5-0.9,0.4-1.4
		c-0.1-0.4-0.1-0.7-0.1-1c0-3.9,3.2-7.1,7.1-7.1s7.1,3.2,7.1,7.1c0,0.3,0,0.6-0.1,1c-0.1,0.5,0.1,1,0.4,1.4c0.3,0.4,0.8,0.6,1.3,0.6
		h11.9c1.2,0,2.2,1,2.2,2.2v2.2v5.1c0,1.2-1,2.2-2.2,2.2H79.8c-1.2,0-2.2-1-2.2-2.2v-5.1V60.2z M133.9,145.2c0,3.9-3.1,7-7,7H73.1
		c-3.9,0-7-3.1-7-7V71.1c0-3.9,3.1-7,7-7h1v3.4c0,3.2,2.6,5.7,5.7,5.7h41.6c3.2,0,5.7-2.6,5.7-5.7v-3.4c3.7,0.2,6.7,3.2,6.7,7V145.2
		z"
      />
    </g>
  </svg>
);
export default MapGLNoData;
