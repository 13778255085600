import React from "react";
import "./toasters.css";
//import default from "../../examples/toasters/index";

const Toaster = ({
  show,
  message,
  closeToasterMessage,
  alertClassName,
  icon,
  id,
  style,
  showCloseIcon = true
}) => {
  switch (alertClassName) {
    case "danger":
      alertClassName = "alert-danger";
      break;
    case "warning":
      alertClassName = "alert-warning";
      break;
    case "info":
      alertClassName = "alert-info";
      break;
    case "generic":
      alertClassName = "alert-generic";
      break;
    default:
      alertClassName = "alert-success";
  }
  switch (icon) {
    case "greatJob":
      icon = alertClassName === "alert-success" ? "fa fa-thumbs-o-up" : icon;
      break;
    case "check":
      icon = alertClassName === "alert-success" ? "fa fa-check" : icon;
      break;
    case "error":
      icon = "fa fa-exclamation-triangle";
      break;
    case "warning":
      icon =
        alertClassName === "alert-warning" ? "fa fa-exclamation-circle" : icon;
      break;
    default:
      icon = "fa fa-info-circle";
  }

  return (
    <div
      className="toaster"
      style={{
        display: show ? "block" : "none",
        width: "100%",
        textAlign: "center",
        clear: "both"
      }}
    >
      <div id={id} className={`alert ${alertClassName}  clearfix`}>
        <div className="col-xs-11 toaster-message" style={style}>
          <i className={icon ? icon : "fa fa-info-circle"} />
          {message}
        </div>

        {closeToasterMessage && showCloseIcon ? (
          <div className="col-xs-1 toaster-close-icon pull-right">
            <i
              id="system-level-cancel-button-close-icon"
              onClick={closeToasterMessage}
              className="fa fa-times"
              style={{ float: "right" }}
            />{" "}
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Toaster;
