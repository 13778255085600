import React from "react";
import UserDetails from "../../activity-history/UserDetails";
import InformationGroup from "./InformationGroup";
import Accordian from "component-library/accordians";
import classNames from "classnames/bind";
import FormatAddress from "./Address";
const WageTaxValidationListItem = ({
  data,
  showAccordian,
  currentColumn,
  suggestedColumn,
  updateAddress,
  addressNotFound,
  currentValue,
  suggestedValue,
  editOnClick,
  homeAddress,
  workAddress,
  checkListName = "",
  hr,
  sectionHr
}) => {
  const { EmployeeName, EmployeePictureUrl } = data;
  let className = classNames("row wage-tax-validation-item", sectionHr && "hr");
  return (
    <div
      className={className}
      //    style={{ display: "flex", alignItems: "center" }}
    >
      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
        <UserDetails
          parent="dashBoard"
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
          name={EmployeeName}
          picUrl={EmployeePictureUrl || "https://goo.gl/4AYQmG"}
        />
        {showAccordian && (
          <Accordian
            title="View Current Addresses"
            style={{ marginLeft: "52px", marginBottom: "0px" }}
          >
            <div className="accordian-information-group">
              <InformationGroup title="Home Address:">
                <FormatAddress {...homeAddress} />
              </InformationGroup>
              <InformationGroup title="Work Address:">
                <FormatAddress {...workAddress} />
              </InformationGroup>
            </div>
          </Accordian>
        )}
      </div>
      <div
        className="col-lg-4 col-md-3 col-sm-12 col-xs-12 ig-wrapper min-wage"
        style={{ display: "flex", paddingRight: "20px" }}
      >
        <InformationGroup
          title={currentColumn}
          hr={hr}
          hideOnDesktop
          titleStyle="bold"
        >
          <div className="clearfix">
            <div className="">{currentValue}</div>
          </div>
        </InformationGroup>
      </div>
      <div
        style={{ display: "flex", paddingRight: "20px" }}
        className="col-lg-3 col-md-3 col-sm-12 col-xs-12 ig-wrapper min-wage"
      >
        <InformationGroup
          title={suggestedColumn}
          style={{ paddingRight: "20px" }}
          hr={hr}
          hideOnDesktop
          titleStyle="bold"
        >
          <div className="clearfix">
            <div className="">{suggestedValue}</div>
          </div>
        </InformationGroup>
      </div>
      <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 min-wage-edit-button">
        <button
          id="edit-address-button"
          onClick={() => editOnClick && editOnClick(data)}
          className="nc-button outline-button"
        >
          <i className="fa fa-pencil" />
          Edit
        </button>
      </div>
    </div>
  );
};

export default WageTaxValidationListItem;
