/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import classNames from "classnames/bind";
import InputField from "component-library/inputFields/InputField";
import Toaster from "component-library/toasters";
import * as FIELDS from "../../utils/Constants";
import DropDown from "component-library/dropDown";

class Skills extends Component {
  state = { showTooltip: false };
  showTooltipHandler = () => {
    this.setState({
      showTooltip: !this.state.showTooltip
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        showTooltip: false
      });
    }
  }

  render() {
    const { props } = this;
    const { errors } = props;
    let lgClass = "col-lg-4";
    let mdClass = "col-md-4";
    let smClass = "col-sm-4";
    let xsClass = "col-xs-12";
    if (props.Size) {
      if (props.Size.length > 0) {
        lgClass = props.Size[0] && "col-lg-" + props.Size[0];
        mdClass = props.Size[1] && "col-md-" + props.Size[1];
        smClass = props.Size[2] && "col-sm-" + props.Size[2];
        xsClass = props.Size[3] && "col-xs-" + props.Size[3];
      }
    }

    let wrapperClass;
    let error = "";
    if (props.class === undefined) {
      wrapperClass = "nc-field-group clearfix";
    } else {
      wrapperClass = "nc-field-group " + props.class + " clearfix";
    }

    if (
      props.required &&
      (errors[FIELDS.SKILL_ID] || errors[FIELDS.SKILL_NAME])
    ) {
      wrapperClass = wrapperClass + " has-error " + props.inputName;
      error = "This is a required field.";
    }

    const InputFieldClass = classNames(
      props.className,
      lgClass,
      mdClass,
      smClass,
      xsClass
    );
    return (
      <div
        className={wrapperClass}
        style={{ clear: "both", display: "flex", alignItems: "center" }}
      >
        {props.Toaster && props.Toaster.show && (
          <Toaster
            show={props.Toaster.show}
            alertClassName={props.Toaster.type}
            message={props.Toaster.content}
          />
        )}
        <div
          id={"Label" + props.inputName}
          style={{ paddingRight: "15px" }}
          className={"col-lg-3 col-md-3 col-sm-3 field-name clearfix"}
        >
          {props.label}
          {props.optional && <i className="optional-field">(Optional) </i>}{" "}
        </div>
        {props.inputType === "Label" ? (
          <div style={{ marginBottom: "15px" }}>{props.value}</div>
        ) : (
          <div
            className={"col-lg-9 col-md-9 col-sm-9"}
            style={{ marginBottom: "20px" }}
          >
            <div className={"skill-section"} style={{ display: "flex" }}>
              <DropDown
                options={props.options}
                value={props.data[FIELDS.SKILL_ID]}
                id={"dd-from-months"}
                className={InputFieldClass}
                onSelect={e =>
                  props.onChange &&
                  props.onChange({
                    name: FIELDS.SKILL_ID,
                    value: e
                  })
                }
              />
              {props.data[FIELDS.SKILL_NAME] === "Other" && (
                <div style={{ marginLeft: "15px" }}>
                  <InputField
                    {...props}
                    onChange={e =>
                      props.onChange &&
                      props.onChange({
                        name: FIELDS.SKILL_NAME_OTHER,
                        value: e.value
                      })
                    }
                    className={"col-xs-12"}
                    value={props.data[FIELDS.SKILL_NAME_OTHER]}
                    id={props.inputName}
                  />
                </div>
              )}
            </div>
            {props.LinkText && props.showLink && (
              <div
                style={{
                  marginTop: "10px"
                }}
              >
                <a onClick={() => props.onCreateNewSkillLink()}>
                  <i className="fa fa-plus" /> {props.LinkText}
                </a>
              </div>
            )}
            {error && props.required && (
              <div
                style={{ marginTop: "5px", fontSize: "15px" }}
                className="has-error"
              >
                {error}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Skills;
