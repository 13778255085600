import React from "react";
import { Modal } from "react-bootstrap";

import "./PopUpModal.css";

const PopUpModal = ({
  show,
  closeModal = () => {},
  line1,
  line2,
  primaryButtonLabel,
  secondaryButtonLabel = "Continue Editing",
  buttonType = "primary",
  onCancel = () => {},
  onPrimaryActionHandler = () => {}
}) => (
  <Modal
    bsClass="list-page-delete modal"
    animation={false}
    backdrop={"static"}
    keyboard={false}
    show={show}
  >
    <Modal.Header>
      <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <i
          id="system-level-cancel-button-close-icon"
          onClick={closeModal}
          className="fa fa-times-circle-o fa-lg"
        />
      </div>
    </Modal.Header>
    <Modal.Body>
      <div className="modal-row text-center">
        <div className="modal-line-1">{line1}</div>
        <br />
        <div className="modal-line-2">{line2}</div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <div className="button-group">
        <button
          type="button"
          id="system-deduction-delete-modal-cancel"
          className={"nc-button " + buttonType + "-button"}
          onClick={onPrimaryActionHandler}
        >
          {primaryButtonLabel}
        </button>
        <button
          type="button"
          id="system-deduction-delete-modal-cancel"
          className="secondary-link-button"
          onClick={onCancel}
        >
          {secondaryButtonLabel}
        </button>
      </div>
    </Modal.Footer>
  </Modal>
);

export default PopUpModal;
