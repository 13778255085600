import * as types from "../../../actions/systemlevel/index";

export default function(state = [], action) {
  switch (action.type) {
    case types.FETCH_THIRD_PARTY_PAYEE_PRINT_SSN_ON_CHECK_OPTION:
      return action.payload.data;
    default:
      return state;
  }
}
