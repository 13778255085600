import axios from "axios";
import * as common from "app/common";
//const BASE_WEB_URL = common.WEB_API_URL;
const BASE_MOCK_URL = common.WEB_API_MOCK_URL;

const fetchNoteTypePath = "/v1/app/ui/company/";
const fetchEmployeeDataPath = `/v1/subscriber/company/employee/`;

export const postEmployeeData = (id, params) =>
  axios.post(
    BASE_MOCK_URL + fetchEmployeeDataPath + id,
    JSON.stringify(params),
    {
      headers: { "Content-Type": "application/json" }
    }
  );

export const patchEmployeeData = (params, id) =>
  axios.patch(
    BASE_MOCK_URL + fetchEmployeeDataPath + `/` + id + "?_mock_=manager",
    JSON.stringify(params),
    {
      headers: { "Content-Type": "application/json" }
    }
  );

export const postNotesType = (compId, params) =>
  axios.post(
    BASE_MOCK_URL + fetchNoteTypePath + compId + `/notetype`,
    JSON.stringify(params),
    {
      headers: { "Content-Type": "application/json" }
    }
  );

export const getNoteTypeData = id =>
  axios.get(BASE_MOCK_URL + fetchNoteTypePath + id + `/notetype`);

export const getEmployeeData = id =>
  axios.get(
    BASE_MOCK_URL + fetchEmployeeDataPath + `/` + id + "?_mock_=manager"
  );
