import React, { Component } from "react";
import {
  FormSection,
  FormFieldGroup
} from "component-library/formSections/index";
import * as FIELDS from "../utils/Constants";
import { BasicInfoName } from "./Components";

export default class BasicInfo extends Component {
  render() {
    const { getFromToValue, data = {}, onChange, filters, errors } = this.props;
    return (
      <div className="tab-container-add-sub-section licenses-basic-info">
        <div className="section-items">
          <FormSection title="Basic Info">
            <FormFieldGroup
              inputType="customRadioTab"
              inputName={FIELDS.BASIC_INFO_TYPE_Id}
              value={data[FIELDS.BASIC_INFO_TYPE_Id]}
              label="Type"
              onChange={onChange}
              Size={[12, 12, 12, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              options={filters[FIELDS.LicensesCertificationTypeFilter]}
              parentWidth="477px"
              childWidth="95px"
              error={errors[FIELDS.BASIC_INFO_TYPE_Id]}
              required={true}
            />

            <BasicInfoName
              data={data}
              label="Name"
              onChange={onChange}
              options={filters[FIELDS.LicensesAndCertificationNameList]}
              errors={errors}
              required={true}
            />

            <FormFieldGroup
              inputType="numeric"
              inputName={FIELDS.BASIC_INFO_NUMBER_ID}
              value={data[FIELDS.BASIC_INFO_NUMBER_ID]}
              label="Number / ID"
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              optional={true}
            />

            <FormFieldGroup
              inputType="singleDatePicker"
              inputName={FIELDS.BASIC_INFO_EXPIRATION_DATE}
              value={data[FIELDS.BASIC_INFO_EXPIRATION_DATE]}
              label="Expiration Date"
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              getFromToValue={getFromToValue}
              error={errors[FIELDS.BASIC_INFO_EXPIRATION_DATE]}
              required={true}
            />
          </FormSection>
        </div>
      </div>
    );
  }
}
