/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Modal } from "react-bootstrap";
import ModalHeader from "./../EmployeeProfileModal/ModalHeader";
import "./OverideModal.css";
const OverrideTerminationDateModal = ({
  show,
  onClose,
  overrideDateLabel = "Termination",
  onOverrideClick,
  onKeepClick,
  buttonLabel = "date",
  onJobHistoryClick
}) => (
  <Modal
    id="override-termination-modal"
    bsClass="override-termination-modal entity-page-cancel-modal modal"
    animation={false}
    backdrop={"static"}
    keyboard={false}
    show={show}
    onHide={onKeepClick}
    restoreFocus={false}
  >
    <ModalHeader onClose={onKeepClick} />
    <Modal.Body>
      <div id="override-termination-modal-body" className="modal-row">
        <div className="modal-header">
          <i className="fa fa-exclamation-triangle" />
          Override{" "}
          <span style={{ textTransform: "capitalize" }}>
            {overrideDateLabel}
          </span>{" "}
          Date
        </div>
        <div style={{ marginBottom: "20px" }}>
          This employee's{" "}
          <span style={{ textTransform: "capitalize" }}>
            {overrideDateLabel}
          </span>{" "}
          Date currently pulls from Job History
        </div>
        <div style={{ marginBottom: "20px" }}>
          Overridding this date means that your Job History will no longer be
          consistent with the data you are about to change.{" "}
          <span style={{ fontWeight: 600 }}>
            Netchex does not recommend overriding this information.
          </span>{" "}
          If this date is incorrect, it can be changed by editing{" "}
          <a onClick={onJobHistoryClick}>Job History</a> directly.
        </div>
        <div>Are you sure you want to continue?</div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <div
        id="cancel-modal-footer"
        className="button-group"
        style={{ textAlign: "left" }}
      >
        <button
          type="button"
          id="save-modal-button"
          className="nc-button primary-button"
          onClick={onOverrideClick}
        >
          Yes, Override {buttonLabel}
        </button>

        <button
          type="button"
          id="save-modal-button"
          className="nc-button secondary-button"
          onClick={onKeepClick}
        >
          No, Leave {buttonLabel}
        </button>
      </div>
    </Modal.Footer>
  </Modal>
);
export default OverrideTerminationDateModal;
