/** Actions */
const ADD_TO_EMPLOYEE_MENU = "people-employee-menu/ADD_TO_EMPLOYEE_MENU";
const CLEAR_EMPLOYEE_MENU = "people-employee-menu/CLEAR_EMPLOYEE_MENU";
const SET_EMPLOYEE_MENU_LOADING =
  "people-employee-menu/SET_EMPLOYEE_MENU_LOADING";

export const initialState = {
  loading: true,
  Data: [],
  DataCount: 0,
  NextUri: null
};

const filterNewData = (state, Data) => {
  const ids = state.Data.map(a => a.EmployeeId);
  return Data.filter(d => ids.indexOf(d.EmployeeId) === -1);
};

/** Reducer */
const employeeMenu = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_EMPLOYEE_MENU:
      return initialState;
    case ADD_TO_EMPLOYEE_MENU: {
      const { Data, DataCount, NextUri } = payload;
      const newData = [...state.Data, ...filterNewData(state, Data)];
      return {
        ...state,
        NextUri,
        Data: newData,
        DataCount: DataCount,
        loading: false
      };
    }
    case SET_EMPLOYEE_MENU_LOADING:
      return {
        ...state,
        loading: payload
      };
    default:
      return state;
  }
};

export default employeeMenu;

/** Action creators */
export const addToEmployeeMenu = (Data, DataCount, NextUri) => ({
  type: ADD_TO_EMPLOYEE_MENU,
  payload: { Data, DataCount, NextUri }
});

export const clearEmployeeMenu = () => ({
  type: CLEAR_EMPLOYEE_MENU
});

export const setEmployeeMenuLoading = loading => ({
  type: SET_EMPLOYEE_MENU_LOADING,
  payload: loading
});
