import React from "react";

const Checkbox = ({ value, onChange, label, disabled, classname, id }) => (
  <li className={classname} onClick={!disabled ? onChange : () => {}}>
    <input type="checkbox" checked={value || false} readOnly />
    <label id={id}>{label}</label>
  </li>
);

export default Checkbox;
