import formatTime from "./../activity-history/formatTime";
export function isNullOrEmpty(value) {
  if (value === null || value === "") {
    return true;
  } else {
    return false;
  }
}

export { formatTime };
