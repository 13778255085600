import React, { Component } from "react";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Random from "random-js";
import * as common from "../common/index";
import { getDeepLinkObject } from "./util";
import {
  setSessionContext,
  isInAuthProcess,
  setUserSession
} from "../actions/AppActions";
import {
  setMenuParentName,
  getAllMenus,
  setGlobalNavSetup,
  getTopLevelMenus,
  selectTopLevelMenu,
  setDisplaySideMenuPanel,
  getSideMenus,
  setDisplaySideMenuBar
} from "../navigation/actions/GlobalNavigationActions";
import { setLoader } from "../../app/actions/LoaderActions";
import { getGlobalNavSetupData } from "./menuData";
class AuthenticationPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.getParameter = this.getParameter.bind(this);
    this.saveAuthToken = this.saveAuthToken.bind(this);
  }

  componentDidMount() {
    this.props.setUserSession(false);
    let authToken = window.location.hash.substring(1).split("=")[1];
    let target = this.getParameter("target", window.location.href);
    if (target !== undefined) {
      var random = new Random(Random.engines.mt19937().autoSeed());
      var defaultNonce = random.integer(1, 100);

      var link = `https://${
        common.TENANT
        }.b2clogin.com/${
        common.TENANT
        }.onmicrosoft.com/oauth2/v2.0/authorize?p=${common.SignUpSignInPolicy}&client_id=${
        common.CLIENT_ID
        }&nonce=${defaultNonce}&redirect_uri=${
        common.UI_APP_URL
        }authenticate&scope=openid&response_type=id_token`;

      sessionStorage.setItem("RedirectItem", target);
      window.location.href = link;
    } else {
      if (authToken !== undefined) {
        this.saveAuthToken(authToken);
      } else {
        window.location.href =
          common.LEGACY_LOGIN_URL + "/n/NewToLegacy?target=1";
      }
    }
  }

  getParameter(name, url) {
    var results = new RegExp("[\\?&]" + name + "=([^&#]*)").exec(url);
    if (!results) {
      return undefined;
    }
    return results[1] || undefined;
  }

  saveAuthToken(token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    sessionStorage.setItem("Authorization", "Bearer " + token);
    localStorage.setItem("Authorization", "Bearer " + token);
    const url = `${common.WEB_API_URL}/api/v1/usersession/getsessioncontext`;
    this.props.isInAuthProcess(true);
    this.props.setLoader(true);
    axios
      .get(url)
      .then(response => {
        axios.defaults.headers.common["SessionContext"] = response.data;
        sessionStorage.setItem("SessionContext", response.data);
        localStorage.setItem("SessionContext", response.data);

        this.props.setSessionContext();
        if (!common.SelectedProductId) {
          this.props.getAllMenus(menuList => {
            let ssoRedirectItem = parseInt(
              sessionStorage.getItem("RedirectItem"),
              0
            );

            let deepLinkItem = parseInt(
              sessionStorage.getItem("DeepLinkItem"),
              0
            );
            let parameter = parseInt(sessionStorage.getItem("Parameter"), 0);

            if (!isNaN(ssoRedirectItem) && ssoRedirectItem !== 0) {
              let matchingSitemapItem = getGlobalNavSetupData(
                menuList.Data,
                ssoRedirectItem,
                []
              );
              this.props.history.push(matchingSitemapItem.Url);
              this.props.setGlobalNavSetup(matchingSitemapItem);
              this.props.setMenuParentName(matchingSitemapItem.parentName);
              sessionStorage.setItem(
                "MenuParentName",
                matchingSitemapItem.parentName
              );
              this.props.getTopLevelMenus(
                matchingSitemapItem.selectedProductId,
                menuList
              );
              let currentMenuState = {
                SelectedProductId: matchingSitemapItem.selectedProductId,
                SelectedMenuLevel1Id: matchingSitemapItem.selectedTopLevelId,
                SelectedMenuLevel2Id: matchingSitemapItem.selectedSidebarId,
                SelectedMenuLevel3Id: matchingSitemapItem.selectedSidebarLeafId
              };
              this.props.selectTopLevelMenu(
                true,
                matchingSitemapItem.selectedTopLevelId,
                currentMenuState,
                undefined,
                true
              );
              if (matchingSitemapItem.showSideBar) {
                this.props.getSideMenus(
                  matchingSitemapItem.selectedTopLevelId,
                  menuList
                );
                this.props.setDisplaySideMenuBar(true);
                if (window.GLOBALNAV) {
                  window.sessionStorage.removeItem('MenuState')
                  window.sessionStorage.setItem('MenuParentName', matchingSitemapItem.parentName)
                  setTimeout(() => {
                    window.GLOBALNAV.setMenu({ parentName: matchingSitemapItem.parentName, parentId: matchingSitemapItem.selectedProductId });
                    window.GLOBALNAV.setGlobalNav(matchingSitemapItem.selectedSidebarLeafId);
                  }, 3000);
                }
              }
              sessionStorage.removeItem("RedirectItem");
            } else if (!isNaN(deepLinkItem) && deepLinkItem !== 0) {
              let deepLinkNode = getDeepLinkObject(deepLinkItem, parameter);
              this.props.history.push(deepLinkNode.Route);
              this.props.setMenuParentName(deepLinkNode.HeaderName);
              sessionStorage.setItem("MenuParentName", deepLinkNode.HeaderName);
              let currentMenuState = {
                SelectedProductId: -1,
                SelectedMenuLevel1Id: 0,
                SelectedMenuLevel2Id: 0,
                SelectedMenuLevel3Id: 0
              };
              this.props.selectTopLevelMenu(
                true,
                0,
                currentMenuState,
                undefined,
                true
              );
              sessionStorage.removeItem("DeepLinkItem");
            } else {
              if (process.env.NODE_ENV === "development") {
                this.props.history.push("Dashboard");
                this.props.setMenuParentName("Dashboard");
                sessionStorage.setItem("MenuParentName", "Dashboard");
                let data = [];
                data[0] = { SelectedProductId: 1 };
                data[1] = { SelectedProductId: 1 };
                sessionStorage.setItem("MenuState", JSON.stringify(data));
              }
            }
          });
        } else {
          this.props.setLoader(true);
        }
      })
      .catch(error => {
        window.location.href =
          common.LEGACY_LOGIN_URL + "/n/NewToLegacy?target=1";
      });
  }

  render() {
    return <div className="page-container" />;
  }
}

function mapStateToProps(state) {
  return {
    allMenuList: state.navigationReducer.allMenuList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSessionContext: setSessionContext,
      setMenuParentName: setMenuParentName,
      setLoader: setLoader,
      isInAuthProcess: isInAuthProcess,
      getAllMenus,
      setGlobalNavSetup,
      getTopLevelMenus,
      setUserSession,
      selectTopLevelMenu: selectTopLevelMenu,
      setDisplaySideMenuPanel: setDisplaySideMenuPanel,
      getSideMenus: getSideMenus,
      setDisplaySideMenuBar: setDisplaySideMenuBar
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationPage);
