import React from "react";
import { makeId } from ".";

const DeductionCycle = ({
  NodeValues,
  NodeKey,
  Nodes,
  id = makeId(NodeKey, 0)
}) => {
  let payrollFrequency = [];
  let selectedPayrollFrequency = NodeValues[1]
    ? JSON.parse("[" + NodeValues[1] + "]")
    : [];
  for (let i = 1; i <= 5; i++) {
    let selected = selectedPayrollFrequency.indexOf(i) > -1 ? true : false;
    let item = {
      number: i,
      selected: selected
    };
    payrollFrequency.push(item);
  }
  return (
    <ul id={id} className="payroll-frequency no-hover">
      {payrollFrequency.map((item, index) => {
        return (
          <li key={index}>
            <input
              type="checkbox"
              className="hidden-checkbox"
              name={item.number}
              checked={item.selected}
              disabled
              value={item.number}
            />
            <label className="">{item.number}</label>
          </li>
        );
      })}
    </ul>
  );
};

export default DeductionCycle;
