import React, { Component } from "react";
import FieldSectionHeader from "./FieldSectionHeader";
import FieldSectionItems from "./FieldSectionItems";

const ColumnHeaders = [
  {
    label: "Field Name",
    field: "FieldName",
    className: "col-lg-6 col-md-6 field-item-header",
    noSort: true,
    id: "list-page-col1-header",
    style: { paddingLeft: "75px" }
  },
  {
    label: "Status",
    field: "Status",
    className: "col-lg-3 col-md-3",
    noSort: true,
    id: "list-page-col2-header"
  },
  {
    label: "",
    field: "EditOrDelete",
    className: "col-lg-3 col-md-3",
    noSort: true,
    id: "list-page-col3-header"
  }
];

export default class FieldSectionTable extends Component {
  state = {
    new: true
  };
  render() {
    const { data = [] } = this.props;
    return (
      <div
        id="section-table"
        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 section-table"
      >
        <FieldSectionHeader columns={ColumnHeaders} />
        {data.map(i => <FieldSectionItems data={i} />)}
      </div>
    );
  }
}
