import React from "react";
import "../svg.css";
const AddressInTitle = ({ width, height, style }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 40.4 43.2"
    style={{ enableBackground: "new 0 0 40.4 43.2" }}
    width={width}
    height={height}
  >
    <g>
      <path
        className="st5"
        d="M32,25.4l0.3,0v-9.5l0.1,0.1l2.2,2.2c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.3-0.7c0-0.2-0.1-0.4-0.3-0.5l-6.4-6.4V1.8c0-0.9-0.8-1.6-1.7-1.6h-3.4c-0.9,0-1.7,0.8-1.7,1.6v2l-0.1-0.1L18.8,0c-0.2-0.2-0.6-0.3-0.8-0.2l0,0c-0.1,0-0.3,0.1-0.4,0.2L0.5,17.1c-0.2,0.2-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3L4.2,16v16.2c0,1.9,1.6,3.4,3.4,3.4H23v-0.5c0-0.3,0-0.7,0.1-1v-0.2H7.5c-0.9-0.1-1.5-0.8-1.5-1.6V14.9h24.5v10.8l0.2-0.1c0.2-0.1,0.4-0.1,0.7-0.1C31.6,25.4,31.8,25.4,32,25.4z M24.3,1.9h3.3v7l-0.1-0.1l-3.2-3.2V1.9z M6.8,13.2L6.8,13.2L18,2l0,0l9.5,9.4l1.8,1.8H6.8z"
      />
      <path
        className="st5"
        d="M32.4,27.1c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8c0-2.2-0.8-4.2-2.3-5.7S34.6,27.1,32.4,27.1z M36.8,39.4c-1.2,1.2-2.7,1.8-4.4,1.8c-3.4,0-6.2-2.8-6.2-6.2s2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2C38.6,36.7,38,38.3,36.8,39.4z"
      />
      <path
        className="st5"
        d="M35.6,31.8c-0.3,0-0.5,0.1-0.6,0.3l-3.2,3.9L30,34.2c-0.2-0.2-0.4-0.3-0.6-0.3c0,0,0,0-0.1,0c-0.2,0-0.4,0.1-0.5,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7l2.5,2.5c0.2,0.2,0.4,0.3,0.7,0.3h0.2c0.3,0,0.5-0.1,0.7-0.3l3.8-4.6c0.2-0.2,0.2-0.5,0.2-0.7c0-0.2-0.1-0.4-0.3-0.5C36,31.9,35.7,31.8,35.6,31.8z"
      />
    </g>
  </svg>
);
export default AddressInTitle;
