export const SET_TOAST = "@app/commmon/SET_TOAST";
export const SET_MULTIPLE_TOAST = "@app/commmon/SET_MULTIPLE_TOAST";
export const EDIT_MULTIPLE_TOAST = "@app/commmon/EDIT_MULTIPLE_TOAST";
export const ADD_TOAST = "@app/common/ADD_TOAST";
export const CLEAR_TOAST = "@app/common/CLEAR_TOAST";

const initialState = {
  show: false
};

const toast = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TOAST:
      return {
        ...payload,
        show: true,
        theme: payload.type
      };
    case SET_MULTIPLE_TOAST: {
      return payload;
    }
    case EDIT_MULTIPLE_TOAST: {
      let newToasts = [...state];

      newToasts.splice(payload, 1);
      return newToasts;
    }
    case ADD_TOAST: {
      if (Array.isArray(state)) {
        return [...state, { ...payload, show: true }];
      } else {
        let toasts = [];
        toasts.push(state);
        toasts.push(payload);
        return toasts;
      }
    }
    case CLEAR_TOAST:
      return initialState;
    default:
      return state;
  }
};

export default toast;

export const showToast = (
  message,
  type = "success",
  link = false,
  action = "default",
  featureId = "EARNINGS",
  canDismiss = true
) => ({
  type: SET_TOAST,
  payload: { message, type, link, action, featureId, canDismiss }
});

export const showMultipleToast = payload => ({
  type: SET_MULTIPLE_TOAST,
  payload
});
/**
 *
 * @param {Message to display inside the toast} message
 * @param {Type of Toaster (success,warning,danger)} type
 * @param {Need to show the link} link
 * @param {Why the toaster is being shown} action
 * @param {Feature of the Toaster} featureId
 * @param {Whether the toaster can be dismissed or not.} canDismiss
 */
export const addToast = (
  message,
  type = "success",
  link = false,
  action = "default",
  featureId = "EARNINGS",
  canDismiss = true
) => ({
  type: ADD_TOAST,
  payload: { message, type, link, action, featureId, canDismiss }
});
export const editMultipleToast = payload => ({
  type: EDIT_MULTIPLE_TOAST,
  payload
});

export const clearToast = () => ({
  type: CLEAR_TOAST
});
