import React, { Component } from "react";
import { FormFieldGroup } from "../../formSections/index";
import { dailyPayPeriod } from "./Constants";

class DailyPayPeriod extends Component {
  render() {
    const { data, handleRadioChange } = this.props;
    const componentProps = dailyPayPeriod;

    let renderDescription = Object.keys(componentProps).map((key, i) => {
      return (
        <div key={key}>
          <FormFieldGroup
            {...componentProps[key]}
            value={parseInt(data[componentProps[key].inputName], 10)}
            onChange={handleRadioChange}
          />
        </div>
      );
    });
    return <div>{renderDescription}</div>;
  }
}
export default DailyPayPeriod;
