export function getFilterCount(
  selectedOptions = {},
  options = {},
  payrollTypeActiveTab
) {
  let count = 0;
  const {
    Employees,
    LeaveType,
    TimeGroups,
    TimePeriods,
    Pages,
    Fields
  } = selectedOptions;

  let e_count = 0;
  let l_count = 0;
  let tg_count = 0;
  let tp_count = 0;
  let p_count = 0;
  let f_count = 0;
  if (payrollTypeActiveTab[1] === 1) {
    const e = options.Employees;
    const l = options.LeaveType;
    if (Employees && LeaveType) {
      e.forEach(({ value }) => {
        if (Employees[value]) e_count++;
      });
      l.forEach(({ value }) => {
        if (LeaveType[value]) l_count++;
      });
      if (e.length !== e_count || e_count === 0) {
        count += e_count;
      }
      if (l.length !== l_count || l_count === 0) {
        count += l_count;
      }
    }
    return count;
  }
  if (payrollTypeActiveTab[1] === 2) {
    const e = options.Employees;
    const tg = options.TimeGroups;
    const tp = options.TimePeriods;
    if (Employees && TimeGroups && TimePeriods) {
      e.forEach(({ value }) => {
        if (Employees[value]) e_count++;
      });
      tg.forEach(({ value }) => {
        if (TimeGroups[value]) tg_count++;
      });
      tp.forEach(({ value }) => {
        if (TimePeriods[value]) tp_count++;
      });
      if (e.length !== e_count || e_count === 0) {
        count += e_count;
      }
      if (tg.length !== tg_count || tg_count === 0) {
        count += tg_count;
      }
      if (tp.length !== tp_count || tp_count === 0) {
        count += tp_count;
      }
    }
    return count;
  }
  if (payrollTypeActiveTab[1] === 3) {
    const e = options.Employees;
    const p = options.Pages;
    const f = options.Fields;
    if (Employees && Pages && Fields) {
      e.forEach(({ value }) => {
        if (Employees[value]) e_count++;
      });
      p.forEach(({ value }) => {
        if (Pages[value]) p_count++;
      });
      f.forEach(({ value }) => {
        if (Fields[value]) f_count++;
      });
      if (e.length !== e_count || e_count === 0) {
        count += e_count;
      }
      if (p.length !== p_count || p_count === 0) {
        count += p_count;
      }
      if (f.length !== f_count || f_count === 0) {
        count += f_count;
      }
    }
    return count;
  }
}

export function highlighter(newElem, oldElem) {
  var newText = newElem;
  var oldText = oldElem;

  var text = "";
  newText.split("").forEach(function(value, index) {
    if (value !== oldText.charAt(index))
      text += "<span class='highlight'>" + value + "</span>";
    else text += value;
  });
  return text;
}
