import React from "react";
import Media from "react-media";
const SaveAndContinue = onClick => (
  <button
    id="save-continue-button"
    onClick={onClick}
    className="nc-button secondary-button"
  >
    CONTINUE
  </button>
);
const SaveAndFinishLater = onClick => (
  <button
    id="save-finish-later-button"
    className="primary-link-button"
    onClick={onClick}
    onDoubleClick={e => {
      e.preventDefault();
    }}
  >
    Finish Later
  </button>
);
const Create = (onClick, label) => (
  <button
    id="create-button"
    className="nc-button primary-button"
    onClick={onClick}
  >
    {label}
  </button>
);
const Back = (onClick, label) => (
  <button
    id="back-button"
    className="nc-button outline-button"
    onClick={onClick}
  >
    <i className="fa fa-chevron-left"> </i> {label}
  </button>
);
const Cancel = onClick => (
  <button
    id="cancel-button"
    className="secondary-link-button"
    onClick={onClick}
  >
    Delete
  </button>
);
const RevertBack = onClick => (
  <button
    id="revert-back-button"
    className="secondary-link-button"
    onClick={onClick}
  >
    Revert Back to Last Saved
  </button>
);
const SaveModifiedChanges = onClick => (
  <button
    id="save-modified-button"
    onClick={onClick}
    className="nc-button secondary-button"
  >
    SAVE CHANGES
  </button>
);

const EarningsButtons = ({
  saveAndContinue,
  saveAndFinishLater,
  cancel,
  back,
  backButtonText,
  createLabel,
  createEarnings,
  revert,
  saveModifiedChanges
}) => (
  <div className="footer-button-container" style={{ clear: "both" }}>
    <div className="nc-button-group">
      <Media query="(min-width:768px)">
        {back && Back(back, backButtonText)}
      </Media>
      {createEarnings && Create(createEarnings, createLabel)}

      {saveAndContinue && SaveAndContinue(saveAndContinue)}
      <Media query="(max-width:767px)">
        {back && Back(back, backButtonText)}
      </Media>
      {saveModifiedChanges && SaveModifiedChanges(saveModifiedChanges)}
      <div
        className={
          revert ? "nc-link-group-mobile-single-button" : "nc-link-group-mobile"
        }
      >
        {revert && RevertBack(revert)}
        {saveAndFinishLater && SaveAndFinishLater(saveAndFinishLater)}
        {cancel && Cancel(cancel)}
      </div>
    </div>
  </div>
);

export default EarningsButtons;
