import React from "react";
import { NoEmployeeUpdates } from "../images";
const NoPayrollChecklistAlertsToCorrent = ({ checklistAlert }) => {
  return (
    <div className="payroll-dash-summary-container">
      <div className="clearfix no-payroll-container">
        <div className="clearfix text-center no-payroll-container-header-img">
          <NoEmployeeUpdates width="110" height="110" />
        </div>
        <div className="clearfix no-payroll-container-header">
          You have no <b>{checklistAlert}</b> to correct for this payroll
          schedule.
        </div>
      </div>
    </div>
  );
};
export default NoPayrollChecklistAlertsToCorrent;
