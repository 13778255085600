import React, { Component } from "react";
import CustomFieldItem from "./CustomFieldItem";
import CustomFieldSection from "./CustomFieldSection";

export default class CustomFieldsAccordian extends Component {
  render() {
    const { CustomFieldsMenus = [] } = this.props;
    return CustomFieldsMenus.map(i => (
      <CustomFieldItem
        id="custom-field-1"
        title={i.CustomFieldGroupName}
        hidetitle={false}
        hideTitleIcon={true}
        hideDeleteIcon={true}
      >
        <CustomFieldSection item={i.SubSubItemList} />
      </CustomFieldItem>
    ));
  }
}
