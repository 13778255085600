import React from "react";
import { makeId } from ".";

const ShowW2Item = item => {
  if (item.includes("#b#") && item.includes("#/b#")) {
    let htmlElementItem = item.replace("#b#", "");
    htmlElementItem = htmlElementItem.replace("#/b#", "");
    return <b>{htmlElementItem}</b>;
  } else {
    return item;
  }
};

const W2Summary = ({ Nodes, NodeValues, NodeKey }) => (
  <div>
    <div className="col-lg-7 col-md-7 hidden-sm hidden-xs">
      <img
        alt="w2image"
        className="img-responsive"
        src={require("../images/th-W2.png")}
      />
    </div>
    <div
      className="col-lg-5 col-md-5 col-sm-12 col-xs-12 "
      style={{ paddingLeft: "10px" }}
    >
      {Nodes.map(({ NodeValues }, i) => (
        <div key={i} id={makeId(NodeKey, i + 1)} className="row-simple-value">
          {ShowW2Item(NodeValues[0])}
        </div>
      ))}
    </div>
  </div>
);

export default W2Summary;
