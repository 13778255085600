/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GeneralLedgerDeductionDropdown from "./components/GeneralLedgerDeductionDropdown";
import GeneralLedgerReportDropDown from "./components/GeneralLedgerReportDropDown";
import { withRouter } from "react-router-dom";
import { setSelectedFilters } from "../actions/generalledger/EmployerExpenceLiabilityAccountsActions";
import {
  getEmployerExpenceLiabilityAccountsList,
  //getEmployeeExpenceLiabilityAccountsFilteredList,
  sortFilteredList,
  getEmployerExpenceLiabilityAccountsFilteredList
} from "../actions/generalledger/EmployerExpenceLiabilityAccountsActions";
import { Modal } from "react-bootstrap";
//import * as utilities from "../../../../app/common/utilities";
import * as utilities from "../../../app/common/utilities";
import Media from "react-media";

var ledgerReportDropDown = [
  {
    id: 0,
    value: true,
    label: (
      <span>
        <i
          className="report-dropdown-yes-mark fa fa-check-circle-o"
          aria-hidden="true"
        />{" "}
        Include in GL Report
      </span>
    )
  },
  {
    id: 1,
    value: false,
    label: (
      <span>
        <i
          className="report-dropdown-no-mark fa fa-times-circle-o"
          aria-hidden="true"
        />{" "}
        Exclude from GL Report
      </span>
    )
  }
];

class EmployerExpenseLiabilityAccountsAdvanceFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGLCategories: [],
      selectedDeductions: [],
      unmappedCheckState: false,
      isResetToDefault: false,
      mobileFilterPanel: false,
      mobileSortPanel: false,
      sortItemList: [
        {
          id: 1,
          label: "Deductions : A to Z",
          sortTypeId: 1,
          sortKey: "CompanyDeductionName"
        },
        {
          id: 2,
          label: "Deductions : Z to A",
          sortTypeId: 2,
          sortKey: "CompanyDeductionName"
        }
      ],
      isInitialLoad: true
    };

    this.setCategories = this.setCategories.bind(this);

    this.setDeductions = this.setDeductions.bind(this);

    this.handleChangeUnmappedCheckState = this.handleChangeUnmappedCheckState.bind(
      this
    );

    this.applyFilter = this.applyFilter.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
  }

  closeMobileFilterPanel = () => {
    this.setState({ mobileFilterPanel: false });
  };
  openMobileFilterPanel = () => {
    this.setState({ mobileFilterPanel: true });
  };

  closeMobileSortPanel = () => {
    this.setState({ mobileSortPanel: false });
  };
  openMobileSortPanel = () => {
    this.setState({ mobileSortPanel: true });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedDeductions !== this.state.selectedDeductions ||
      prevState.selectedGLCategories !== this.state.selectedGLCategories ||
      prevState.unmappedCheckState !== this.state.unmappedCheckState
    ) {
      this.getFilteredList();
    }

    if (ledgerReportDropDown) {
      if (
        this.state.selectedGLCategories.length === 0 &&
        this.state.isInitialLoad
      ) {
        let selectedGLCategories = [];
        ledgerReportDropDown.forEach(item => {
          selectedGLCategories.push(item.id);
        });

        this.setState({
          selectedGLCategories
        });
      }
    }
  }

  getFilteredList() {
    if (this.props.employerExpenceLiabilityAccountsList.DataCount > 0) {
      this.setState({
        isResetToDefault: false,
        isInitialLoad: false
      });
      // if (this.props.employerExpenceLiabilityAccountsList.DataCount > 0) {
      this.props.setSelectedFilters(
        this.state.selectedDeductions,
        this.state.selectedGLCategories,
        []
      );

      this.props.getEmployerExpenceLiabilityAccountsFilteredList(
        this.props.employerExpenceLiabilityAccountsList.Data,
        this.state.selectedDeductions,
        this.state.selectedGLCategories,
        this.state.unmappedCheckState
      );
      // }
    }
  }

  applyFilter() {
    this.getFilteredList();
    this.closeMobileFilterPanel();
  }

  setCategories(selectedGLCategories) {
    this.setState({
      selectedGLCategories
    });

    this.props.onSelectedCategories(selectedGLCategories);
  }

  setInitialLoad() {
    this.setState({
      isInitialLoad: false
    });
  }

  setDeductions(selectedDeductions) {
    this.setState({
      selectedDeductions
    });

    this.props.onSelectedDeductions(selectedDeductions);
  }

  handleChangeUnmappedCheckState() {
    let selectedCheckBox = !this.state.unmappedCheckState;

    this.setState({
      unmappedCheckState: selectedCheckBox
    });

    this.props.getUnmappedCheckState(selectedCheckBox);
  }
  closeMobileFilterPanel() {
    this.refs.overlayMobileFilterPanel.hide();
  }

  closeMobileSortPanel() {
    this.refs.overlayMobileSortPanel.hide();
  }

  clearAllFilters() {
    this.setState({
      isResetToDefault: true,
      unmappedCheckState: false
    });
  }

  sortColumnByKey(item) {
    if (this.props.employerExpenceLiabilityAccountsFilteredList.Data) {
      let sortedFilteredList = utilities.sortByKeyString(
        this.props.employerExpenceLiabilityAccountsFilteredList.Data,
        item.sortKey
      );
      // this.props.employerExpenceLiabilityAccountsFilteredList.Data = utilities.sortByKey(this.props.employerExpenceLiabilityAccountsFilteredList.Data, item.sortKey);
      if (item.sortTypeId === 2) {
        sortedFilteredList = sortedFilteredList.reverse();
      }

      let request = {
        data: {
          Data: sortedFilteredList,
          DataCount: sortedFilteredList.length
        }
      };

      this.props.sortFilteredList(request);
    }

    this.closeMobileSortPanel();
  }

  showSortListItem() {
    return this.state.sortItemList.map((item, index) => {
      return (
        <div
          key={index}
          className="mobile-sort-panel-item row remove-margin-left-right"
          onClick={this.sortColumnByKey.bind(this, item)}
        >
          <div className="col-sm-12 col-xs-12">{item.label}</div>
        </div>
      );
    });
  }

  render() {
    const mobileFilterPanelNew = (
      <Modal
        id="mobile-filter"
        //bsClass="mobile-filter-modal"
        animation={false}
        backdrop={"static"}
        keyboard={false}
        show={this.state.mobileFilterPanel}
        onHide={this.closeMobileFilterPanel}
      >
        {/* <div className="modal-content "> */}
        <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="header-container col-sm-12 col-xs-12 clearfix">
            <div className="close-icon col-sm-3 col-xs-3">
              <i
                id="system-level-cancel-button-close-icon"
                onClick={this.closeMobileFilterPanel.bind(this)}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
            <div className="filter-label col-sm-6 col-xs-6"> All Filters </div>
            <div className="clear-all-link col-sm-3 col-xs-3">
              <a onClick={this.clearAllFilters}>Clear All</a>
            </div>
          </div>

          <div
            id="mobile-select-deduction-dropdown"
            className="mobile-filter-select-dropdown no-padding col-sm-12 col-xs-12"
          >
            <GeneralLedgerDeductionDropdown
              onSelectedDeductions={selectedDeductions =>
                this.setDeductions(selectedDeductions)
              }
              isResetToDefault={this.state.isResetToDefault}
            />
          </div>
          <div
            id="mobile-select-status-dropdown"
            className="mobile-filter-select-dropdown no-padding col-sm-12 col-xs-12"
          >
            <GeneralLedgerReportDropDown
              onSelectedCategories={selectedGLCategories =>
                this.setCategories(selectedGLCategories)
              }
              isResetToDefault={this.state.isResetToDefault}
              isInitialLoad={isInitialLoad => {
                this.setInitialLoad();
              }}
            />
          </div>
          <div
            id="mobile-select-category-dropdown"
            className="mobile-filter-select-dropdown no-padding col-sm-12 col-xs-12"
          >
            <div className="mobile-filter-panel-list-section">
              <div className="mobile-filter-panel-list-panel-bar col-xs-12 col-sm-12">
                <input
                  type="checkbox"
                  name="unmappedCheckState"
                  checked={this.state.unmappedCheckState}
                  onClick={this.handleChangeUnmappedCheckState}
                  value="unmappedCheckState"
                  id="show-only-unmapped-box"
                />
                <label
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                  htmlFor="show-only-unmapped-box"
                >
                  &nbsp; Show Only Unmapped
                </label>
                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-icon" />

                <div className="col-xs-8 col-sm-8 mobile-filter-panel-list-label no-padding" />

                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-badge no-padding" />
              </div>

              <div className="mobile-filter-panel-list-panel-bar-section" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="mobile-filter-panel-button-section">
            <button
              type="button"
              id="mobile-apply-filters"
              className="apply-filter-button"
              onClick={this.applyFilter}
            >
              APPLY FILTERS
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
    const mobileSortPanelNew = (
      <Modal
        id="mobile-sort"
        animation={false}
        backdrop={"static"}
        keyboard={false}
        show={this.state.mobileSortPanel}
        onHide={this.closeMobileSortPanel}
      >
        <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="header-container col-sm-12 col-xs-12 clearfix">
            <div className="close-icon col-sm-3 col-xs-3">
              <i
                id="system-level-cancel-button-close-icon"
                onClick={this.closeMobileSortPanel.bind(this)}
                className="fa fa-times-circle-o"
              />
            </div>
            <div className="sort-label col-sm-6 col-xs-6"> Sort By </div>
          </div>
          <hr />
          {this.showSortListItem()}
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    );
    let filterCount = 0;

    if (this.props.employerExpenceLiabilityAccountsList) {
      if (
        this.props.employerExpenceLiabilityAccountsList.DataCount ===
        this.state.selectedDeductions.length - 1
      ) {
        filterCount = filterCount + 0;
      } else {
        filterCount = filterCount + this.state.selectedDeductions.length;
      }
    }

    if (ledgerReportDropDown) {
      filterCount = filterCount + this.state.selectedGLCategories.length;
    }

    const { Id } = this.props;
    return (
      <Media query="(max-width: 991px)">
        {matches =>
          matches ? (
            <div className="nc-sort-filter-container hidden-md hidden-lg col-sm-12 col-xs-12">
              <button onClick={this.openMobileSortPanel.bind()} className="">
                SORT BY
              </button>
              <button onClick={this.openMobileFilterPanel.bind()} className="">
                FILTERS ({filterCount})
              </button>
              {mobileFilterPanelNew}
              {mobileSortPanelNew}
            </div>
          ) : (
            <div
              id="employer-paid-deductions-grey-filter-section"
              className="nc-filter-container hidden-sm hidden-xs"
            >
              <div className="showing-label">FILTER:</div>
              <div className="nc-filter-group">
                <div className="nc-filter-row">
                  <div
                    id="select-deduction-dropdown"
                    className="nc-filter-dropdown"
                    style={{}}
                  >
                    <GeneralLedgerDeductionDropdown
                      onSelectedDeductions={selectedDeductions =>
                        this.setDeductions(selectedDeductions)
                      }
                      Id={Id.entityFilter}
                    />
                  </div>
                  <div
                    id="select-category-dropdown"
                    className="nc-filter-dropdown"
                  >
                    <GeneralLedgerReportDropDown
                      onSelectedCategories={selectedGLCategories =>
                        this.setCategories(selectedGLCategories)
                      }
                      isInitialLoad={isInitialLoad => {
                        this.setInitialLoad();
                      }}
                      Id={Id.categoryFilter}
                    />
                  </div>

                  <div
                    id="select-status-dropdown"
                    /* className="nc-filter-dropdown col-lg-3 col-md-3" */
                    style={{ float: "left" }}
                  >
                    <input
                      type="checkbox"
                      name="unmappedCheckState"
                      checked={this.state.unmappedCheckState}
                      onClick={this.handleChangeUnmappedCheckState}
                      value="unmappedCheckState"
                      id="employer-tab-show-unmapped-box"
                    />
                    <label
                      className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      style={{ marginTop: "8px" }}
                      htmlFor="employer-tab-show-unmapped-box"
                    >
                      &nbsp; Show Only Unmapped
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Media>
    );
  }
}

function mapStateToProps(state) {
  return {
    // categoryList: state.categoryList,
    // systemDeductionFilteredList: state.systemDeductionFilteredList,
    employerExpenceLiabilityAccountsList:
      state.generalLedgerReducer.employerExpenceLiabilityAccountsList,

    UnMappedCheckBoxStateValue:
      state.generalLedgerReducer.UnMappedCheckBoxStateValue,

    employerExpenceLiabilityAccountsFilteredList:
      state.generalLedgerReducer.employerExpenceLiabilityAccountsFilteredList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEmployerExpenceLiabilityAccountsFilteredList: getEmployerExpenceLiabilityAccountsFilteredList,
      setSelectedFilters: setSelectedFilters,
      getEmployerExpenceLiabilityAccountsList: getEmployerExpenceLiabilityAccountsList,
      // getEmployeeExpenceLiabilityAccountsFilteredList: getEmployeeExpenceLiabilityAccountsFilteredList,
      sortFilteredList
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    EmployerExpenseLiabilityAccountsAdvanceFilter
  )
);
