import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchAndSetMinimumWagesAlertsItems,
  fetchAndSetEmployeedata
} from "../network/actions";
import { makeOptions, makeSelectedOptions } from "app/common/reducers/filters";
import { MinimumWageAlerts } from "component-library/payroll-dashboard/wagetaxvalidation";
import { NoPayrollChecklistAlertsToCorrent } from "component-library/payroll-dashboard/address-verification";
import { setSortOrder, clearSortOrder } from "app/common/reducers/sortOrder";
import PayrollDashBoardModal from "component-library/payroll-dashboard/payroll-dashboard-modals/index";

class MinimumWagesAlertsContainer extends Component {
  state = {};

  componentDidMount() {
    const {
      fetchAndSetMinimumWagesAlertsItems,
      match: {
        params: { payrollId }
      }
    } = this.props;
    fetchAndSetMinimumWagesAlertsItems(parseInt(payrollId, 10));
  }

  componentWillUnmount() {
    this.props.clearSortOrder();
  }

  changeSortOrder = (field, sortOrder) => {
    const {
      fetchAndSetMinimumWagesAlertsItems,
      match: {
        params: { payrollId }
      },
      setSortOrder
    } = this.props;

    setSortOrder(field, sortOrder);
    fetchAndSetMinimumWagesAlertsItems(parseInt(payrollId, 10));
    setTimeout(
      function() {
        this.searchPostsUnapprovedPto();
      }.bind(this),
      10
    );
  };

  makeOnSelect = name => (option, selected) => {
    this.props.selectOption(name, option, selected);
  };

  makeSelectAll = name => selected => {
    this.props.selectAll(name, selected);
  };

  editMinimumWagesAlerts = (Id, data) => {
    const { fetchAndSetEmployeedata } = this.props;
    fetchAndSetEmployeedata(Id);
    this.setState({
      openEditModal: true
    });
  };

  modalBody = () => {
    //const { employeeData } = this.props.payrollTypeData.EmployeeData;
    return (
      <div className="modal-body-container">
        <div className="modal-body-header">Edit Minimum Wage Alerts</div>
        <div className="modal-body-content" />
      </div>
    );
  };

  closeFooterButton = () => {
    const modalButton = [
      {
        label: "Update Minimum Wages",
        buttonType: "secondary-button",
        onClick: this.closeModal
      },
      {
        label: "Cancel",
        buttonType: "secondary-link-button",
        onClick: this.closeModal
      }
    ];

    return modalButton;
  };

  closeModal = () => {
    this.setState({
      openEditModal: false
    });
  };

  render() {
    const { redirectToChecklist } = this.props;
    const dataCount = this.props.payrollTypeData.MinimumWagesAlerts.DataCount;
    return (
      <div>
        {dataCount > 0 ? (
          <div>
            <MinimumWageAlerts
              data={this.props.payrollTypeData.MinimumWagesAlerts.Data}
              editMinimumWagesAlerts={data =>
                this.editMinimumWagesAlerts(data.Id, data)
              }
              sectionHr
              redirectToChecklist={redirectToChecklist}
            />
          </div>
        ) : (
          <NoPayrollChecklistAlertsToCorrent checklistAlert="minimum wages alerts" />
        )}
        <PayrollDashBoardModal
          show={this.state.openEditModal}
          bodyContent={this.modalBody()}
          closeModal={this.closeModal}
          footerButtons={this.closeFooterButton()}
          buttonAlign="left"
        />
      </div>
    );
  }
}

const stateToProps = state => ({
  payrollTypeData: state.payrollDashboard.payrollTypeData,
  filterData: makeOptions(state.common.filters),
  selectedOptions: makeSelectedOptions(state.common.filters),
  sortOrder: state.common.sortOrder
});

const actionCreators = {
  fetchAndSetMinimumWagesAlertsItems,
  fetchAndSetEmployeedata,
  setSortOrder,
  clearSortOrder
};

export default withRouter(
  connect(stateToProps, actionCreators)(MinimumWagesAlertsContainer)
);
