import React, { Component } from "react";
import { TabContainer } from "component-library/employee-profile/entity-view";
import AboutMe from "component-library/employee-profile/profile/AboutMe";
import SocialMediaAccounts from "component-library/employee-profile/profile/SocialMediaAccounts";
import Interest from "component-library/employee-profile/profile/Interest";
import { Element } from "react-scroll";
import { DEMOGRAPHIC_HEADER, interests } from "../Constants";
import {
  ACTIVITIES,
  HOBBIES,
  MUSIC
} from "component-library/employee-profile/Constants";

class Profile extends Component {
  state = {
    activePanel: {},
    errors: {},
    showToaster: false,
    toasterClass: "",
    toasterMessage: "",
    isAddModeOn: false
  };

  componentDidMount() {
    const { EmployeeId } = this.props.match.params;
    this.props.setOptions(ACTIVITIES, interests[ACTIVITIES]);
    this.props.setOptions(HOBBIES, interests[HOBBIES]);
    this.props.setOptions(MUSIC, interests[MUSIC]);
    this.props.fetcheAndSetHobbies(EmployeeId);
  }

  toggleToaster = () => {
    this.setState({
      showToaster: false
    });
  };

  setToaster = (toasterMessage, toasterClass) => {
    this.setState({
      toasterClass: toasterClass,
      toasterMessage: toasterMessage,
      showToaster: true
    });
  };

  onChange = e => {
    let name = e.target ? e.target.name : e.name;
    let value = e.target ? e.target.value : e.value;
    this.setState({
      [name]: value
    });
  };

  makeOnSelect = name => (option, selected) => {
    this.props.selectOption(name, option, selected);
  };

  render() {
    return (
      <div>
        <TabContainer
          activePanelHeader={DEMOGRAPHIC_HEADER[1]}
          isAddModeOn={this.state.isAddModeOn}
          showToaster={this.state.showToaster}
          toasterClass={this.state.toasterClass}
          toasterMessage={this.state.toasterMessage}
          toggleToaster={this.toggleToaster}
        >
          <Element name="About Me" className="element" id="element1">
            <AboutMe data={this.state} onChange={this.onChange} />
          </Element>
          <Element
            name="Social Media Accounts"
            className="element"
            id="element2"
          >
            <SocialMediaAccounts data={this.state} onChange={this.onChange} />
          </Element>
          <Element name="Interest" className="element" id="element3">
            <Interest
              data={this.state}
              onChange={this.onChange}
              makeOnSelect={this.makeOnSelect}
              onSave={() => {}}
              {...this.props}
            />
          </Element>
        </TabContainer>
      </div>
    );
  }
}

export default Profile;
