/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import GeneralLedgerForm from "./GeneralLedgerForm";
import * as generalLedgerActions from "../actions/generalledger/GeneralLedgerActions";
import { withRouter } from "react-router-dom";
class GeneralLedgerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddGLModal: false,
      showAddGLLeaveConfirmModal: false,
      isGLPageEdited: false
    };

    this.saveGeneralLedgerForm = this.saveGeneralLedgerForm.bind(this);
    this.onLeaveClick = this.onLeaveClick.bind(this);
    this.onStayClick = this.onStayClick.bind(this);
    this.setGLFormEdited = this.setGLFormEdited.bind(this);
  }

  componentDidMount() {
    this.props.actions.getGeneralLedgerAccountTypeList();
  }

  saveGeneralLedgerForm(formValues) {
    // Do something with the form values
    this.props.actions.saveGeneralLedgerForm(formValues);
    this.props.history.push("/general-ledger-accounts");
  }

  closeAddGLModal() {
    if (this.state.isGLPageEdited) {
      this.setState({ showAddGLLeaveConfirmModal: true });
    } else {
      this.setState({ showAddGLModal: false });
    }
  }

  closeAddGLLeaveConfirmModal() {
    this.setState({ showAddGLLeaveConfirmModal: false });
  }

  onLeaveClick() {
    this.setState({ showAddGLModal: false, showAddGLLeaveConfirmModal: false });
  }

  onStayClick() {
    this.setState({ showAddGLLeaveConfirmModal: false });
  }

  setGLFormEdited(isGLPageEdited) {
    this.setState({
      isGLPageEdited
    });
  }

  setGLFormCancel(isGLPageEdited) {
    if (isGLPageEdited) {
      this.setState({ showAddGLLeaveConfirmModal: true });
    } else {
      this.setState({ showAddGLModal: false });
    }
  }

  render() {
    var generalLedgerAccountTypeList = null;
    if (!this.props.generalLedgerAccountTypeList.Data) {
      generalLedgerAccountTypeList = [];
    } else {
      generalLedgerAccountTypeList = this.props.generalLedgerAccountTypeList
        .Data;
    }
    return (
      <div className="page-container">
        <div className="modal-container" />
        <a onClick={() => this.setState({ showAddGLModal: true })}>
          Add General Ledger Account
        </a>

        <Modal
          show={this.state.showAddGLModal}
          onHide={this.closeAddGLModal.bind(this)}
          container={this}
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header className="account-gl-modal-header" closeButton>
            <Modal.Title id="contained-modal-title">
              Add General Ledger Account
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="general-ledger-modal">
            <GeneralLedgerForm
              onSubmit={this.saveGeneralLedgerForm}
              generalLedgerAccountTypeList={generalLedgerAccountTypeList}
              onGLFormEdited={isEdited => this.setGLFormEdited(isEdited)}
              onGLFormCancel={isEdited => this.setGLFormCancel(isEdited)}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showAddGLLeaveConfirmModal}
          container={this}
          aria-labelledby="contained-modal-title"
        >
          <Modal.Body className="general-ledger-leave-stay-modal">
            <div className="general-ledger-leave-stay-content">
              There are unsaved changes on the page. are you sure, you want to
              leave the page.
            </div>
            <div className="general-ledger-leave-stay-buttons">
              <button
                id="general-ledger-account-leave"
                onClick={this.onLeaveClick}
                className="btn btn-default general-ledger-save-btn"
                type="button"
              >
                Leave
              </button>
              <button
                id="general-ledger-account-stay"
                onClick={this.onStayClick}
                className="btn btn-default general-ledger-save-btn"
                type="button"
              >
                Stay
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    generalLedgerAccountTypeList: state.generalLedgerAccountTypeList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(generalLedgerActions, dispatch)
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneralLedgerPage)
);
