import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import ModalOption from "./../dropDown/ModalOption";
import renderFilterOption from "./renderFilterOptions";
import OptionsModalFooter from "./../dropDown/OptionsModalFooter";

export default class FilterOptions extends Component {
  renderOptions = options => {
    const {
      multiSelect,
      areAllSelected,
      selectAll,
      selectAllLabel,
      showSelectAll = true,
      searchText
    } = this.props;
    const allSelected = areAllSelected();
    return (
      <ul className="nc-dropdown-navigation-multiselect">
        {multiSelect &&
          showSelectAll && !searchText && (
          <ModalOption
            label={selectAllLabel ? selectAllLabel : "Select All"}
            showCheckbox={true}
            selected={allSelected}
            onSelect={() => selectAll(!allSelected)}
            description={options.description}
          />
        )}
        {options.map(renderFilterOption(this.props, ModalOption))}
      </ul>
    );
  };

  renderHeader = () => {
    const { onHide } = this.props;
    return (
      <div className="header-container col-sm-12 col-xs-12 clearfix">
        <div className="close-icon col-sm-3 col-xs-3">
          <i
            id="system-level-cancel-button-close-icon"
            onClick={onHide}
            className="fa fa-times-circle-o"
          />
        </div>
        <div className="sort-label col-sm-6 col-xs-6"> Select One </div>
      </div>
    );
  };

  renderFooter = () => {
    const { multiSelect, onHide } = this.props;
    if (!multiSelect) {
      return null;
    }
    return <OptionsModalFooter onHide={onHide} />;
  };

  render() {
    const { options = [], showSearch } = this.props;
    let autoHeightMax = window.outerHeight - 108;
    const panelTitleHeight = 43;
    autoHeightMax = autoHeightMax - panelTitleHeight;
    if (showSearch) {
      autoHeightMax = autoHeightMax - (44 + 30);
    }

    return (
      <Scrollbars autoHeight autoHeightMax={autoHeightMax}>
        {this.renderOptions(options)}{" "}
      </Scrollbars>
    );
  }
}
