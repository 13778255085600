import React, { Component } from "react";
import DropDown from "../../dropDown";
import {
  EMPLOYEE_FILTER_NAME,
  TIME_GROUPS_FILTER_NAME,
  TIME_PERIODS
} from "./Constants";

const labelMaker = (noLabel, allLabel, someLabel, oneLabel) => (o, s, all) =>
  all
    ? allLabel
    : Object.keys(s).filter(k => s[k]).length === 0
      ? noLabel
      : Object.keys(s).filter(k => s[k]).length === 1
        ? oneLabel
        : someLabel;

export default class FiltersDesktop extends Component {
  render() {
    const {
      options = {},
      selectedOptions = {},
      makeOnSelect = () => undefined,
      makeSelectAll = () => undefined
    } = this.props;
    return (
      <div className="nc-filter-container">
        <div className="showing-label" id="filter-label">
          {" "}
          FILTER:
        </div>
        <div className="nc-filter-group" id="filter-group">
          <div className="nc-filter-row" id="filter-row1">
            <div className="nc-filter-dropdown" style={{ minWidth: "170px" }}>
              <DropDown
                multiSelect
                showSearch
                showBadge
                options={options[EMPLOYEE_FILTER_NAME]}
                selectedItems={selectedOptions[EMPLOYEE_FILTER_NAME]}
                onSelect={makeOnSelect(EMPLOYEE_FILTER_NAME)}
                selectAll={makeSelectAll(EMPLOYEE_FILTER_NAME)}
                makeLabel={labelMaker(
                  "Select Employees",
                  "All Employees",
                  "Employees",
                  "Employee"
                )}
                selectAllLabel="All Employees"
                searchBoxId="employee-auto-suggestion-textbox"
                id="employee-list"
                onHide={this.props.onCloseFilter}
              />
            </div>
            <div className="nc-filter-dropdown" style={{ minWidth: "170px" }}>
              <DropDown
                multiSelect
                showBadge
                options={options[TIME_GROUPS_FILTER_NAME]}
                selectedItems={selectedOptions[TIME_GROUPS_FILTER_NAME]}
                onSelect={makeOnSelect(TIME_GROUPS_FILTER_NAME)}
                selectAll={makeSelectAll(TIME_GROUPS_FILTER_NAME)}
                makeLabel={labelMaker(
                  "Select Time Group",
                  "All Time Groups",
                  "Time Groups",
                  "Time Group"
                )}
                selectAllLabel="All Time Groups"
                searchBoxId="type-auto-suggestion-textbox"
                id="time-group-list"
                onHide={this.props.onCloseFilter}
              />
            </div>
            <div className="nc-filter-dropdown" style={{ minWidth: "170px" }}>
              <DropDown
                multiSelect
                showBadge
                options={options[TIME_PERIODS]}
                selectedItems={selectedOptions[TIME_PERIODS]}
                onSelect={makeOnSelect(TIME_PERIODS)}
                selectAll={makeSelectAll(TIME_PERIODS)}
                makeLabel={labelMaker(
                  "Select Time Period",
                  "All Time Periods",
                  "Time Periods",
                  "Time Period"
                )}
                selectAllLabel="All Time Periods"
                searchBoxId="type-auto-suggestion-textbox"
                id="time-group-list"
                onHide={this.props.onCloseFilter}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
