import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export default class CancelRequestModal extends Component {
  render() {
    const { show, message, closeModal } = this.props;
    return (
      <Modal
        id="cancel-request-modal"
        bsClass="list-page-cancel modal"
        animation={false}
        backdrop={"static"}
        keyboard={false}
        show={show}
        onHide={closeModal}
      >
        <Modal.Header>
          <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <i
              id="cancel-request-close-icon"
              onClick={closeModal}
              className="fa fa-lg fa-times-circle-o"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-row text-center">{message}</div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <button
              onClick={closeModal}
              type="button"
              id="cancel-request-ok-button"
              className="nc-button secondary-button"
            >
              OK
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
