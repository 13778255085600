/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import classNames from "classnames/bind";
import FormTooltip from "./FormTooltip";
import getInputComponent from "./getInputComponent";
import * as Constants from "./Constants";
import Toaster from "./../toasters/index";
import Media from "react-media";

export default class DefaultFormFieldGroup extends Component {
  state = { showTooltip: false };
  showTooltipHandler = () => {
    this.setState({
      showTooltip: !this.state.showTooltip
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { shouldToggleTooltip = false } = this.props;
    if (shouldToggleTooltip) {
      if (prevProps.toggleDataKey !== this.props.toggleDataKey) {
        this.setState({
          showTooltip: false
        });
      }
    }
  }
  render() {
    const { props } = this;
    let lgClass = "col-lg-4";
    let mdClass = "col-md-6";
    let smClass = "col-sm-12";
    let xsClass = "col-xs-12";
    if (props.Size) {
      if (props.Size.length > 0) {
        lgClass = props.Size[0] && "col-lg-" + props.Size[0];
        mdClass = props.Size[1] && "col-md-" + props.Size[1];
        smClass = props.Size[2] && "col-sm-" + props.Size[2];
        xsClass = props.Size[3] && "col-xs-" + props.Size[3];
      }
    }

    let wrapperClass;
    if (props.class === undefined) {
      wrapperClass = "nc-field-group clearfix";
    } else {
      wrapperClass = "nc-field-group " + props.class + " clearfix";
    }
    if (
      (props.value === null ||
        props.value === "" ||
        !props.value ||
        props.inputType === Constants.TEXT_INPUT ||
        props.inputType === Constants.CHECKBOX ||
        props.inputType === Constants.NUMERIC_INPUT ||
        props.inputType === Constants.FREQUENCY_INPUT ||
        props.inputType === Constants.TEXT_WITH_SIGN_INPUT ||
        props.inputType === Constants.RADIO_INPUT ||
        props.inputType === Constants.TEXTAREA_INPUT) &&
      props.required &&
      props.error
    ) {
      wrapperClass = wrapperClass + " has-error " + props.inputName;
    }
    const InputFieldClass = classNames(
      "input-field",
      props.className,
      lgClass,
      mdClass,
      smClass,
      xsClass
    );
    let DescriptionClass = classNames(
      "field-description",
      props.descriptionposition !== "top" && "hidden-lg hidden-md hidden-sm",
      props.hideTopdescription && "hidden-xs"
    );
    let Component = getInputComponent(props.inputType);

    return (
      <div
        className={wrapperClass}
        style={{ clear: "both", width: props.contentWidth }}
      >
        {props.Toaster && props.Toaster.show && (
          <Toaster
            show={props.Toaster.show}
            alertClassName={props.Toaster.type}
            message={props.Toaster.content}
          />
        )}
        <div
          id={"Label" + props.inputName}
          className={`${props.colSpan}${" field-name clearfix"}`}
        >
          <span style={{ fontWeight: props.labelFontWeight || "400" }}>
            {props.label}{" "}
          </span>
          {props.icon && (
            <span
              className={
                props.value === undefined || props.value === null
                  ? "disabled-description"
                  : "form-field-group-icon-description"
              }
            >
              <i className={props.icon} />
              {props.iconDescription}
            </span>
          )}
          {props.optional && <i className="optional-field">(Optional) </i>}{" "}
          {props.tooltip && (
            <a onClick={this.showTooltipHandler}>
              <i className="fa fa-info-circle" />
            </a>
          )}
          {props.descriptionWithTooltipIcon && (
            <Media query="(min-width:768px)">
              <i className="field-description">{props.description}</i>
            </Media>
          )}
          {props.descriptionWithTooltipIcon ? (
            <Media query="(max-width:767px)">
              {props.description && (
                <div className={DescriptionClass} style={props.style}>
                  {props.descriptionStyle === "italic" ? (
                    <i>{props.description}</i>
                  ) : (
                    props.description
                  )}
                </div>
              )}
            </Media>
          ) : (
            props.description && (
              <div className={DescriptionClass} style={props.style}>
                {props.descriptionStyle === "italic" ? (
                  <i>{props.description}</i>
                ) : (
                  props.description
                )}
              </div>
            )
          )}
        </div>
        {this.state.showTooltip && props.tooltip && (
          <FormTooltip
            onCloseHandler={this.showTooltipHandler}
            content={props.tooltip}
            width={props.tooltipwidth}
          />
        )}
        {props.inputType === Constants.LABEL ? (
          <div style={{ marginBottom: "15px" }}>{props.value}</div>
        ) : (
          <div>
            <Component
              {...props}
              onChange={props.onChange}
              onSelect={props.onChange}
              name={props.inputName}
              className={InputFieldClass}
              value={props.value}
              id={props.inputId}
              getFromToValue={props.getFromToValue}
              format={props.format}
              disabled={props.disabled}
            />
            {props.description &&
              props.descriptionposition !== "top" &&
              !props.hideTopdescription && (
                <div
                  className="inline-field-description hidden-xs"
                  style={props.style}
                >
                  {props.descriptionStyle === "italic" ? (
                    <i>{props.description}</i>
                  ) : (
                    props.description
                  )}
                </div>
              )}

            {props.description && props.hideTopdescription && (
              <div
                className="inline-field-description col-xs-7"
                style={{
                  marginLeft: "15px",
                  ...props.style
                }}
              >
                {props.descriptionStyle === "italic" ? (
                  <i>{props.description}</i>
                ) : (
                  props.description
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

DefaultFormFieldGroup.propTypes = {
  description: PropTypes.string
};
