import React from "react";
import "./svg.css";
const EarningsNoData = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 200 200"
    style={{ enableBackground: "new 0 0 200 200" }}
    width={width}
    height={height}
  >
    <g>
      <g>
        <circle className="st0" cx="100" cy="100" r="100" />
      </g>
    </g>
    <g>
      <g>
        <path
          className="st1"
          d="M149.4,71.7v56.5H50.6V71.7H149.4 M149.4,68.2H50.6c-2,0-3.5,1.6-3.5,3.5v56.5c0,2,1.6,3.5,3.5,3.5h98.9
			c2,0,3.5-1.6,3.5-3.5V71.7C153,69.8,151.4,68.2,149.4,68.2L149.4,68.2z"
        />
      </g>
      <g>
        <path
          className="st1"
          d="M130.8,80.6c1.2,4.5,5,8.1,9.7,9.3v20.2c-4.7,1.2-8.5,4.7-9.7,9.3H69.2c-1.2-4.5-5-8.1-9.7-9.3V89.9
			c4.7-1.2,8.5-4.7,9.7-9.3H130.8 M133.7,77.4H66.3c0,5.3-4.5,9.7-10.1,9.7v25.8c5.6,0,10.1,4.3,10.1,9.7h67.4
			c0-5.3,4.5-9.7,10.1-9.7V87.1C138.2,87.1,133.7,82.8,133.7,77.4L133.7,77.4z"
        />
      </g>
      <g>
        <path
          className="st1"
          d="M101.3,98.6v-7.7c1.6,0.2,3.1,0.8,4,1.7c0.5,0.5,1.4,0.5,1.9,0c0.5-0.5,0.5-1.4,0-1.9
			c-1.4-1.4-3.5-2.3-5.8-2.5c0,0-0.1,0-0.1,0v-1.9c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.1c-3.2,0.6-5.7,2.6-6,5.4
			c-0.2,1.5,0.3,3,1.4,4.3c1.1,1.4,2.7,2.3,4.6,2.8v7.5c-1.6-0.2-3.1-0.8-4-1.7c-0.5-0.5-1.4-0.5-1.9,0c-0.5,0.5-0.5,1.4,0,1.9
			c1.4,1.4,3.5,2.3,5.8,2.5c0,0,0.1,0,0.1,0v1.9c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-2.1c3.2-0.6,5.7-2.6,6-5.4
			c0.2-1.5-0.3-3.1-1.3-4.3C104.9,99.9,103.3,99,101.3,98.6z M98.7,98c-1-0.4-1.9-0.9-2.5-1.7c-0.6-0.7-0.9-1.6-0.8-2.3
			c0.1-1.4,1.5-2.4,3.3-2.9V98z M104.7,105.2c-0.1,1.4-1.5,2.4-3.3,2.9v-6.8c1.1,0.3,2,0.8,2.6,1.6
			C104.5,103.6,104.7,104.4,104.7,105.2z"
        />
      </g>
      <g>
        <path
          className="st1"
          d="M76.6,109.9c-4.2,0-7.6-4.1-7.6-9.2s3.4-9.2,7.6-9.2c4.2,0,7.6,4.1,7.6,9.2S80.8,109.9,76.6,109.9z M76.6,95
			c-2.2,0-4.1,2.6-4.1,5.7c0,3.1,1.9,5.7,4.1,5.7c2.2,0,4.1-2.6,4.1-5.7C80.7,97.6,78.8,95,76.6,95z"
        />
      </g>
      <g>
        <path
          className="st1"
          d="M125.1,109.9c-4.2,0-7.6-4.1-7.6-9.2s3.4-9.2,7.6-9.2c4.2,0,7.6,4.1,7.6,9.2S129.3,109.9,125.1,109.9z
			 M125.1,95c-2.2,0-4.1,2.6-4.1,5.7c0,3.1,1.9,5.7,4.1,5.7c2.2,0,4.1-2.6,4.1-5.7C129.2,97.6,127.3,95,125.1,95z"
        />
      </g>
    </g>
  </svg>
);
export default EarningsNoData;
