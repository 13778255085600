import React from "react";
import { Switch, Route } from "react-router-dom";
import { UnapprovedPto, TimecardAlerts, EmployeeUpdates } from "../";
import MinimumWageAlerts from "../MinimumWageAlerts";
import TaxVerifications from "../TaxVerifications";
import AddressVerifications from "../AddressVerifications";
import "./styles.css";

const Content = props => {
  return (
    <Switch>
      <Route
        exact
        path="/payroll/checklist/unapproved-pto/:payrollId"
        render={() => <UnapprovedPto {...props} />}
      />
      <Route
        exact
        path="/payroll/checklist/timecard-alerts/:payrollId"
        render={() => <TimecardAlerts {...props} />}
      />
      <Route
        exact
        path="/payroll/checklist/employee-updates/:payrollId"
        render={() => <EmployeeUpdates {...props} />}
      />
      <Route
        exact
        path="/payroll/checklist/minimum-wage-alerts/:payrollId"
        render={() => <MinimumWageAlerts {...props} />}
      />
      <Route
        exact
        path="/payroll/checklist/tax-verifications/:payrollId"
        render={() => <TaxVerifications {...props} />}
      />
      <Route
        exact
        path="/payroll/checklist/address-verifications/:payrollId"
        render={() => <AddressVerifications {...props} />}
      />
    </Switch>
  );
};

export default Content;
const styles = {};

styles.fill = {
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0
};

styles.content = {
  ...styles.fill,
  top: "40px",
  textAlign: "center"
};

styles.nav = {
  padding: 0,
  margin: 0,
  position: "absolute",
  top: 0,
  height: "40px",
  width: "100%",
  display: "flex"
};

styles.navItem = {
  textAlign: "center",
  flex: 1,
  listStyleType: "none",
  padding: "10px"
};

styles.hsl = {
  ...styles.fill,
  color: "white",
  paddingTop: "20px",
  fontSize: "30px"
};
