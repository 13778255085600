import React from "react";

const TaxVerificationOff = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 80.6 80.6"
    style={{ enableBackground: "new 0 0 80.6 80.6" }}
    width={width}
    height={height}
  >
    <g>
      <path
        className="st5"
        d="M40.3,80.6C18.1,80.6,0,62.5,0,40.3C0,18.1,18.1,0,40.3,0s40.3,18.1,40.3,40.3C80.6,62.5,62.5,80.6,40.3,80.6zM40.3,2C19.2,2,2,19.2,2,40.3s17.2,38.3,38.3,38.3s38.3-17.2,38.3-38.3S61.4,2,40.3,2z"
      />
    </g>
    <g>
      <g>
        <path
          className="st5"
          d="M28.2,28.7h17c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-17c-0.5,0-0.9,0.4-0.9,0.9S27.7,28.7,28.2,28.7z"
        />
        <path
          className="st5"
          d="M28.2,34.2h17c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-17c-0.5,0-0.9,0.4-0.9,0.9C27.3,33.7,27.7,34.2,28.2,34.2z"
        />
        <path
          className="st5"
          d="M40.7,48.5H28.3c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h12C40.3,49.8,40.4,49.1,40.7,48.5z"
        />
        <path
          className="st5"
          d="M28.2,39.6h17c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-17c-0.5,0-0.9,0.4-0.9,0.9C27.3,39.2,27.7,39.6,28.2,39.6z"
        />
        <path
          className="st5"
          d="M28.2,45h14.6c0.7-0.7,1.6-1.4,2.5-1.9h-0.1h-17c-0.5,0-0.9,0.4-0.9,0.9S27.7,45,28.2,45z"
        />
      </g>
      <g>
        <g>
          <path
            className="st5"
            d="M49.9,59.9c-4.4,0-8.1-3.6-8.1-8.1s3.6-8.1,8.1-8.1s8.1,3.6,8.1,8.1S54.4,59.9,49.9,59.9z M49.9,45.7c-3.4,0-6.2,2.8-6.2,6.2s2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2S53.3,45.7,49.9,45.7z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="st5"
            d="M49.3,55.1h-0.2c-0.3,0-0.5-0.1-0.7-0.3l-2.5-2.5c-0.4-0.4-0.4-1,0-1.3c0.4-0.4,1-0.4,1.3,0l1.8,1.8l3.2-3.9c0.3-0.4,0.9-0.5,1.3-0.1c0.4,0.3,0.5,0.9,0.1,1.3l-3.8,4.6C49.9,54.9,49.6,55.1,49.3,55.1z"
          />
        </g>
      </g>
      <path
        className="st5"
        d="M40.5,54.6H26.8c-1.3,0-2.3-1-2.3-2.3V24.9c0-1.3,1-2.3,2.3-2.3h19.9c1.3,0,2.3,1,2.3,2.3V42c0.3,0,0.6,0,0.9,0s0.6,0,0.9,0V24.9c0-2.3-1.9-4.2-4.2-4.2H26.7c-2.3,0-4.2,1.9-4.2,4.2v27.4c0,2.3,1.9,4.2,4.2,4.2h14.5C41,55.9,40.7,55.3,40.5,54.6z"
      />
    </g>
  </svg>
);

export default TaxVerificationOff;
