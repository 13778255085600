import React from "react";
import UserDetails from "../../../activity-history/UserDetails";
import InformationGroup from "../InformationGroup";
import Accordian from "component-library/accordians";
import NoAddressContainer from "../NoAddressContainer";
import classNames from "classnames/bind";
import FormatAddress from "../Address";

export function highlighter(newElem, oldElem) {
  var newText = String(newElem);
  var oldText = String(oldElem);

  if (newText) {
    return newText.split("").map((value, index) => {
      if (value !== oldText.charAt(index))
        return (
          <span
            key={index}
            style={{ backgroundColor: "#ffebcd" }}
            className="highlight"
          >
            {value}
          </span>
        );
      else return <span key={index}>{value}</span>;
    });
  }
  return <span>{oldText}</span>;
}
export function isAddressNull(suggestedValue) {
  let response = false;
  response = suggestedValue.AddressLine1 || response;
  response = suggestedValue.AddressLine2 || response;
  response = suggestedValue.City || response;
  response = suggestedValue.StateName || response;
  response = suggestedValue.ZipCode || response;
  return response;
}
const WageTaxValidationListItem = ({
  data,
  showAccordian,
  currentColumn = "Current Address",
  suggestedColumn = "Suggested Address",
  updateAddress,
  addressNotFound,
  currentValue,
  suggestedValue,
  editOnClick,
  homeAddress,
  workAddress,
  checkListName = "",
  hr,
  sectionHr,
  onEditAddressClick
}) => {
  const { EmployeeName, EmployeePictureUrl } = data;
  let className = classNames("row wage-tax-validation-item", sectionHr && "hr");
  return (
    <div className={className}>
      <div className="col-lg-4 col-md-3 col-sm-12 col-xs-12">
        <UserDetails
          parent="dashBoard"
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
          name={EmployeeName}
          picUrl={EmployeePictureUrl || "https://goo.gl/4AYQmG"}
        />
        {showAccordian && (
          <Accordian
            title="View Current Addresses"
            style={{ marginLeft: "52px", marginBottom: "0px" }}
          >
            <div className="accordian-information-group">
              <InformationGroup title="Home Address:">
                <FormatAddress {...homeAddress} />
              </InformationGroup>
              <InformationGroup title="Work Address:">
                <FormatAddress {...workAddress} />
              </InformationGroup>
            </div>
          </Accordian>
        )}
      </div>
      <div
        className="col-lg-4 col-md-4 col-sm-6 col-xs-12 ig-wrapper current-section"
        style={{ display: "flex" }}
      >
        <InformationGroup
          title={currentColumn}
          hr={hr}
          hideOnDesktop
          titleStyle="bold"
        >
          <div className="clearfix" style={{ marginBottom: "10px" }}>
            {" "}
            <FormatAddress
              line1={currentValue.AddressLine1}
              line2={currentValue.AddressLine2}
              city={currentValue.City}
              state={currentValue.StateName}
              zipcode={currentValue.ZipCode}
            />
          </div>
          {checkListName === "Address" && (
            <div className="clearfix">
              <button className="nc-button outline-button">
                Keep Current&nbsp;{checkListName}
              </button>
            </div>
          )}
        </InformationGroup>
      </div>
      <div className="col-lg-4 col-md-5 col-sm-6 col-xs-12 ig-wrapper">
        {!isAddressNull(suggestedValue) ? (
          <NoAddressContainer onEditAddressClick={onEditAddressClick} />
        ) : (
          <InformationGroup
            title={suggestedColumn}
            contentStyle="bold"
            style={{ paddingRight: "20px" }}
            titleStyle="bold"
            hr={hr}
            hideOnDesktop
          >
            <div className="clearfix" style={{ marginBottom: "10px" }}>
              <FormatAddress
                line1={highlighter(
                  suggestedValue.AddressLine1,
                  currentValue.AddressLine1
                )}
                line2={highlighter(
                  suggestedValue.AddressLine2,
                  currentValue.AddressLine2
                )}
                city={highlighter(suggestedValue.City, currentValue.City)}
                state={highlighter(
                  suggestedValue.StateName,
                  currentValue.StateName
                )}
                zipcode={highlighter(
                  suggestedValue.ZipCode,
                  currentValue.ZipCode
                )}
              />
            </div>
            {checkListName === "Address" && (
              <div className="clearfix">
                <button className="nc-button secondary-button">
                  Use Suggested&nbsp;{checkListName}
                </button>
              </div>
            )}
          </InformationGroup>
        )}
      </div>
    </div>
  );
};

export default WageTaxValidationListItem;
