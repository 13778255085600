import React from "react";
import Header from "./Header";
import Toaster from "./../../toasters";

const ChecklistSection = props => {
  const {
    data = {},
    yearEndRollOver,
    toastData = {},
    activeTab,
    sectionTabClickHandler,
    startPayroll,
    children
  } = props;
  return (
    <div className="payroll-dash-summary-footer-contents">
      <Header
        data={data}
        yearEndRollOver={yearEndRollOver}
        startPayroll={startPayroll}
        activeTab={activeTab}
        sectionTabClickHandler={sectionTabClickHandler}
      />
      {toastData.show ? (
        <div className="dashboard-toaster-container">
          <Toaster
            show={toastData.show}
            closeToasterMessage={this.closeToast}
            message={toastData.message}
            icon="check"
          />
        </div>
      ) : null}
      <div style={{}}>{children}</div>
    </div>
  );
};

export default ChecklistSection;
