import React from "react";

const Arrow = ({ width, height, style }) => (
  <svg
    version="1.1"
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 245.8 238.2"
    style={{ enableBackground: "new 0 0 245.8 238.2", ...style }}
    xmlSpace="preserve"
    width={width}
    height={height}
  >
    <style
      type="text/css"
      dangerouslySetInnerHTML={{ __html: "\n\t.st2{fill:#CCCCCC;}\n" }}
    />
    <path
      className="st2"
      d="M245.1,41.7c-9.7-14-20.8-27.6-32.1-40.4c-2.1-2.3-4.9-1.2-6.1,0.8c-0.7,0.1-1.4,0.4-2.1,1
	  c-15.2,12.8-28.6,27.4-43.8,40.2c-4,3.4,1.8,9.1,5.7,5.7c13.6-11.6,25.9-24.5,39.3-36.3c-0.3,43.8-11.6,87.7-34.7,125.1
	  c-22.5,36.5-56.5,65.5-96.7,80.7C52,227,28,231.1,3.9,229.9c-5.2-0.3-5.2,7.8,0,8.1c44.4,2.1,88.5-13.2,123.6-40
	  c36.1-27.6,61.6-66.7,75-110c7.4-23.6,11.1-48.4,11.5-73.1c8.5,9.9,16.6,20.2,24,30.9C241,50,248,46,245.1,41.7z"
    />
  </svg>
);

export default Arrow;
