import React from "react";

const TitleBand = ({ title }) => (
  <div className="title-band-container">
    <div className="title-band">
      <h3>{title}</h3>
    </div>
  </div>
);

export default TitleBand;
