export const FETCH_COMPANY_LIST = "FETCH_COMPANY_LIST";
export const COMPANY_SELECTED = "COMPANY_SELECTED";
export const FETCH_ROLE_VIEW_LIST = "FETCH_ROLE_VIEW_LIST";
export const ROLE_VIEW_SELECTED = "ROLE_VIEW_SELECTED";
export const FETCH_PREVIOUS_LOGIN_INFO = "FETCH_PREVIOUS_LOGIN_INFO";
export const FETCH_PRODUCTS_SUITE_LIST = "FETCH_PRODUCTS_SUITE_LIST";

export const FETCH_USER_PROFILE_PICTURE = "FETCH_USER_PROFILE_PICTURE";

export const FETCH_USER_PROFILE_INFO = "FETCH_USER_PROFILE_INFO";

export const FETCH_UNREAD_NOTIFICATION_COUNT =
  "FETCH_UNREAD_NOTIFICATION_COUNT";
export const RESET_UNREAD_NOTIFICATION_COUNT =
  "RESET_UNREAD_NOTIFICATION_COUNT";
export const FETCH_NOTIFICATION_LIST = "FETCH_NOTIFICATION_LIST";
export const CLEAR_NOTIFICATION_LIST = "CLEAR_NOTIFICATION_LIST";
export const FETCH_ALL_NOTIFICATION_LIST = "FETCH_ALL_NOTIFICATION_LIST";
export const FETCH_NOTIFICATION_SETTINGS = "FETCH_NOTIFICATION_SETTINGS";

export const FETCH_ALL_MENUS = "FETCH_ALL_MENUS";
export const FETCH_ROLE_BASED_QUICK_LINKS = "FETCH_ROLE_BASED_QUICK_LINKS";

export const SET_SIDE_OVERLAY_PANEL = "SET_SIDE_OVERLAY_PANEL";

export const MAIN_PAGE_OVERLAY = "MAIN_PAGE_OVERLAY";
//Menu State
export const SET_MENU_STATE = "SET_MENU_STATE";
export const NTH_SIDE_MENU_STATE = "NTH_SIDE_MENU_STATE";
export const VISITED_SIDE_MENU_STATE = "VISITED_SIDE_MENU_STATE";

//Security Groups
export const PRODUCT_SUITE_ICON = "PRODUCT_SUITE_ICON";

//Testing Nav
export const SET_QUICK_LINK_STATE = "SET_QUICK_LINK_STATE";
export const SET_TOP_LEVEL_STATE = "SET_TOP_LEVEL_STATE";
export const SET_SIDE_LEVEL_STATE = "SET_SIDE_LEVEL_STATE";

//Quick Links
export const FETCH_QUICK_LINKS = "FETCH_QUICK_LINKS";

export const MENU_PARENT_NAME = "MENU_PARENT_NAME";
export const CURRENT_MENU_NAME = "CURRENT_MENU_NAME";

//Top Level Menus
export const FETCH_TOP_LEVEL_MENUS = "FETCH_TOP_LEVEL_MENUS";

//Side Menus
export const FETCH_SIDE_MENUS = "FETCH_SIDE_MENUS";

export const SIDE_MENU_PANEL = "SIDE_MENU_PANEL";
export const SIDE_MENU_BAR = "SIDE_MENU_BAR";

export const MOBILE_PRODUCT_SUITE = "MOBILE_PRODUCT_SUITE";

export const FETCH_NOTIFICATION_VIEWALL_LISTING =
  "FETCH_NOTIFICATION_VIEWALL_LISTING"; //ACTION FOR NOTIFICATION VIEW ALL LISTING
export const FETCH_NOTIFICATION_VIEWALL_PRODUCT_LIST =
  "FETCH_NOTIFICATION_VIEWALL_PRODUCT_LIST";
export const FETCH_NOTIFICATION_VIEWALL_COMPANY_LIST =
  "FETCH_NOTIFICATION_VIEWALL_COMPANY_LIST";
export const FETCH_NOTIFICATION_VIEWALL_USER_LIST =
  "FETCH_NOTIFICATION_VIEWALL_USER_LIST";
export const FETCH_NOTIFICATION_VIEWALL_STATUS_LIST =
  "FETCH_NOTIFICATION_VIEWALL_STATUS_LIST";
export const FETCH_NOTIFICATION_VIEWALL_RESULT_LIST =
  "FETCH_NOTIFICATION_VIEWALL_RESULT_LIST";

//By JobId Sorting
export const FETCH_NOTIFICATION_VIEWALL_SUCCESS_LISTING =
  "FETCH_NOTIFICATION_VIEWALL_SUCCESS_LISTING";
export const FETCH_NOTIFICATION_VIEWALL_ERROR_LISTING =
  "FETCH_NOTIFICATION_VIEWALL_ERROR_LISTING";

//notifications by JobId
export const FETCH_NOTIFICATION_LISTING_BY_JOBID =
  "FETCH_NOTIFICATION_LISTING_BY_JOBID";
// Notification by product id

export const NOTIFICATION_BY_PRODUCT = "NOTIFICATION_BY_PRODUCT";
export const NOTIFICATION_BY_COMPANY = "NOTIFICATION_BY_COMPANY";
export const NOTIFICATION_BY_USER = "NOTIFICATION_BY_USER";
export const NOTIFICATION_FILTERED_LIST = "NOTIFICATION_FILTERED_LIST";
export const NOTIFICATION_PRODUCT_MAIN_LIST = "NOTIFICATION_PRODUCT_MAIN_LIST";
export const FETCH_PRODUCT_DELETE_ICON_CLICK_LIST =
  "FETCH_PRODUCT_DELETE_ICON_CLICK_LIST";
export const NOTIFICATION_PRODUCT_DATA = "NOTIFICATION_PRODUCT_DATA";
export const NOTIFICATION_PRODUCT_LIST_BY_JOB_ID =
  "NOTIFICATION_PRODUCT_LIST_BY_JOB_ID";

// Filters

export const NOTIFICATION_SELECTED_FILTERS = "NOTIFICATION_SELECTED_FILTERS";
export const NOTIFICATION_STATUS_LIST = "NOTIFICATION_STATUS_LIST";
export const NOTIFICATION_RESULT_LIST = "NOTIFICATION_RESULT_LIST";

export const EARNING_NOTIFICATION = "EARNING_NOTIFICATION";

//timezone offset
export const FETCH_TIMEZONE_OFFSET = "FETCH_TIMEZONE_OFFSET";

export const FETCH_TIMEZONE_ABBREVIATION = "FETCH_TIMEZONE_ABBREVIATION";
