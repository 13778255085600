import React, { Component } from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import {
  //CURRENT_ADDRESS,
  currentAddress,
  ZIPCODE
} from "../Constants";

class DependentAddress extends Component {
  state = {
    selected: false
  };
  renderAddresses = obj => {
    let componentProps = obj;
    return Object.keys(componentProps).map(k => {
      return (
        <FormFieldGroup
          key={k}
          {...componentProps[k]}
          block
          colSpan="col-md-5"
          value={this.props.data[k]}
          onChange={this.props.onChange}
        />
      );
    });
  };

  handleSelect = () => {
    this.setState({
      selected: !this.state.selected
    });
  };

  render() {
    const { data } = this.props;
    const { initialData } = data;
    let toggleCurrentAddressData = currentAddress;
    toggleCurrentAddressData.country.options = initialData.countries || [];
    toggleCurrentAddressData.State.options = initialData.currentStates || [];
    if (
      this.props.data[ZIPCODE] === undefined ||
      this.props.data[ZIPCODE] === ""
    ) {
      const { city, State, county, ...restOfAddress } = currentAddress;
      toggleCurrentAddressData = restOfAddress;
    }

    return (
      <FormSection
        title="Address"
        hr
        checkbox
        selected={this.state.selected}
        link
        label="Always use same address as emplyoee"
        onClick={this.handleSelect}
      >
        <div className="col-md-6" style={{ padding: "0px" }}>
          {this.renderAddresses(toggleCurrentAddressData)}
        </div>
      </FormSection>
    );
  }
}

export default DependentAddress;
