import React from "react";
import getInputComponent from "../../../../inputFields/getInputComponent";
import Checkbox from "../../../../entity-copy/Checkbox";
import * as Fields from "./../FieldNames";

const DropDown = getInputComponent("dropdown");

const ReplacementManager = props => {
  const { data = {} } = props;
  return (
    <div
      className="nc-field-group clearfix"
      style={{ clear: "both", display: "flex", alignItems: "center" }}
    >
      <div
        id={"Label" + props.inputName}
        style={{ paddingRight: "15px" }}
        className={`${
          props.colSpan
        } " " ${"col-lg-3 col-md-3 field-name clearfix"}`}
      >
        {props.label}{" "}
      </div>
      <div
        className="col-lg-9 col-md-9"
        style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}
      >
        <DropDown
          optional
          name={props.inputName}
          value={1}
          onChange={props.onChange}
          className="col-lg-4"
          options={[
            {
              value: 1,
              label: "Rebecca Milazzo",
              description: "DEMO ABC Company"
            }
          ]}
          showDescription
          disabledDropdown={data[Fields.manuallyAssignManagers]}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "10px"
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <i>or</i>
          </div>
          <Checkbox
            label={"Manually Assign Multiple Managers"}
            onChange={() =>
              props.onChange({
                name: Fields.manuallyAssignManagers,
                value: !data[Fields.manuallyAssignManagers]
              })
            }
            value={data[Fields.manuallyAssignManagers]}
          />
        </div>
      </div>
    </div>
  );
};

export default ReplacementManager;
