import React from "react";
import WageTaxValidationListItem from "./WageTaxValidationListItem";
import { MinimumWageOffInTitle } from "../images/WageAndTaxValidation";
import "./WageTaxValidation.css";
import Header from "../multi-section-table/MobileHeader";
import TableHeader from "./TableHeader";
const ColumnsLabel = {
  Current: "Current Minimum Wage",
  Suggested: "Suggested Minimum Wage"
};
const formatCurrency = amount => {
  return "$ " + amount;
};
const Columns = [
  {
    label: "Employee",
    field: "Employee",
    className: "col-lg-3 col-md-4"
  },
  {
    label: ColumnsLabel.Current,
    field: "CurrentMinimumWage",
    className: "col-lg-4 col-md-3",
    noSort: true
  },
  {
    label: ColumnsLabel.Suggested,
    field: "CorrectMinimumWage",
    className: "col-lg-3 col-md-3",
    noSort: true
  }
];

const MinimumWageAlerts = ({
  data = [],
  editMinimumWagesAlerts,
  changeSortOrder = () => {},
  sortOrder = {},
  sectionHr,
  redirectToChecklist
}) => (
  <div className="wage-tax-sub-section">
    <Header
      title="Minimum Wage Alerts"
      icon={<MinimumWageOffInTitle width={25} height={25} />}
      description="Based of employees validated work addresses, we are able to determine minimum wage as determined by state laws. 
      The employees below are not receiving the correct minimum wage amounts. "
      badgeCount={data.length}
      onClick={redirectToChecklist}
    />
    <TableHeader
      columns={Columns}
      changeSortOrder={changeSortOrder}
      sortOrder={sortOrder}
    />
    <div className="wage-table-container">
      {data.map((item, i) => (
        <WageTaxValidationListItem
          key={item.Id}
          data={item}
          currentColumn={ColumnsLabel.Current}
          suggestedColumn={ColumnsLabel.Suggested}
          currentValue={formatCurrency(item.EmployeeCurrentMinimumWage)}
          suggestedValue={formatCurrency(item.EmployeeSuggestedMinimumWage)}
          editOnClick={data => editMinimumWagesAlerts(data)}
          sectionHr={sectionHr}
        />
      ))}
    </div>
  </div>
);

export default MinimumWageAlerts;
