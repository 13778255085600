import React from "react";
import PayrollDashboardModal from "./../payroll-dashboard-modals";
const EmployeeInformationModal = props => {
  return (
    <PayrollDashboardModal
      closeModal={props.toggleInfoModal}
      show={props.show}
      bodyContent={
        <img
          src={require("./../images/employeeInfo.png")}
          alt="Employee Information"
        />
      }
      footerButtons={[
        {
          id: 1,
          buttonType: "secondary-button",
          onClick: props.toggleInfoModal,
          label: "Close"
        }
      ]}
    />
  );
};

export default EmployeeInformationModal;
