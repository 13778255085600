import React from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import * as Fields from "../FieldNames";
import { REPLACEMENT_MANAGER } from "../Tooltips";
import ReplacementManager from "../transferred/ReplacementManager";
const ReplacementManagers = ({ data = {}, onChange }) => {
  return (
    <FormSection title={"Replacement Managers"} hr>
      <div style={{ marginBottom: "15px" }}>
        We noticed Nancy Ann Christianson currently manages 20 employees in
        (DEMO) ABC Company
      </div>
      <FormFieldGroup
        inputType="radio"
        inputName={Fields.replaceManager}
        label="Do you want to replace Nancy with another manager for these employees ?"
        fieldType="inline"
        value={data[Fields.replaceManager]}
        radios={[
          { id: 1, value: true, label: "Yes" },
          { id: 2, value: false, label: "No" }
        ]}
        class="col-lg-12"
        colSpan="col-lg-3 col-md-3"
        Size={[6, 4, 12, 12]}
        onChange={onChange}
        tooltip={REPLACEMENT_MANAGER}
      />
      {data[Fields.replaceManager] && (
        <div className="clearfix">
          <ReplacementManager
            label="Select Replacement Manager"
            inputName={Fields.replacementManager}
            value={data[Fields.replacementManager]}
            data={data}
            onChange={onChange}
          />

          <FormFieldGroup
            inputType="radio"
            inputName={Fields.removeAsManager}
            label="Do you want to remove Nancy from all primary and secondary manager assignments?"
            fieldType="inline"
            value={data[Fields.removeAsManager]}
            radios={[
              { id: 1, value: true, label: "Yes" },
              { id: 2, value: false, label: "No" }
            ]}
            class="col-lg-12"
            colSpan="col-lg-3 col-md-3"
            Size={[6, 4, 12, 12]}
            onChange={onChange}
          />
        </div>
      )}

      {data[Fields.replaceManager] === false && (
        <FormFieldGroup
          inputType="radio"
          inputName={Fields.keepAsManager}
          label="Do you want to keep Nancy as their manager ?"
          fieldType="inline"
          value={data[Fields.keepAsManager]}
          radios={[
            { id: 1, value: true, label: "Yes" },
            { id: 2, value: false, label: "No" }
          ]}
          class="col-lg-12"
          colSpan="col-lg-3 col-md-3"
          Size={[6, 4, 12, 12]}
          onChange={onChange}
        />
      )}
    </FormSection>
  );
};

export default ReplacementManagers;
