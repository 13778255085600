import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import { Badge } from "nc-components";

import "./EarningsGroupsSnapshotPage.css";

function splitByClub(node = "") {
  var nodeArray = node.split("♣");
  return (
    <Fragment>
      {nodeArray[0]}
      {!String(nodeArray[1]).localeCompare("inactive") && (
        <div style={{ marginLeft: 10, position: "relative" }}>
          <Badge
            role="status"
            status="inactive"
            style={{ position: "absolute", top: -6.5 }}
          >
            Inactive
          </Badge>
        </div>
      )}
    </Fragment>
  );
}
const renderNodeContent = (node, i, showValueType) => (
  <div
    key={i}
    className="col-lg-6 col-md-6 col-xs-12 col-sm-6 table-row-content-list"
  >
    {splitByClub(node)}
  </div>
);

const EarningsGroupEntitySnapshotTable = ({ nodes = [] }) => {
  if (nodes === null || nodes.length === 0) {
    return (
      <div id="snapsht-no-data-id" className="earning-group-snapshot-no-data">
        There is no previous data saved for this earnings group.
      </div>
    );
  } else {
    return (
      <Modal.Body>
        <div className="">
          <div className="row">
            <div className="earning-group-snapshot-grid-table hidden-sm hidden-xs">
              <div className="col-lg-12 col-md-12 table-row">
                <span className="col-lg-4 col-md-4 column-header">
                  {nodes[0].NodeValues[0]}
                </span>
                <span className="col-lg-8 col-md-8">
                  {nodes[0].NodeValues[1]}
                </span>
              </div>
              <div className="col-lg-12 col-md-12 table-row">
                <span className="col-lg-4 col-md-4 column-header">
                  {nodes[1].NodeValues[0]}
                </span>
                <span className="col-lg-8 col-md-8">
                  {nodes[1].Nodes[0].NodeValues.map(renderNodeContent)}
                </span>
              </div>
            </div>
            <div>
              <div className="earning-group-snapshot-grid-table hidden-lg hidden-md">
                <div className="clearfix mobile-row">
                  <div className="col-xs-12 col-sm-4 column-header">
                    {nodes[0].NodeValues[0]}
                  </div>
                  <div className="col-xs-12 col-sm-8">
                    {nodes[0].NodeValues[1]}
                  </div>
                </div>
                <div className="clearfix mobile-row ">
                  <div className="col-xs-12 col-sm-4 column-header">
                    {nodes[1].NodeValues[0]}
                  </div>
                  <div className="col-xs-12 col-sm-8 table-row-content-list-wrapper">
                    {nodes[1].Nodes[0].NodeValues.map(renderNodeContent)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    );
  }
};

export default EarningsGroupEntitySnapshotTable;
