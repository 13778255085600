import React, { Component } from "react";
// import '../../../payrollsetup/PayrollSetup.css';
// import "../../../../content/listpage.css";
import "../../../../content/entitypage.css";
import SystemLevelDeductionSetupListingPage from "./SystemLevelDeductionSetupListingPage";
import { withRouter } from "react-router-dom";
class DeductionSetup extends Component {
  render() {
    return (
      <div>
        <SystemLevelDeductionSetupListingPage />
      </div>
    );
  }
}
export default withRouter(DeductionSetup);
