import React, { Component } from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import { connect } from "react-redux";

import {
  Active,
  Deceased,
  Inactive,
  OnLeave,
  Retired,
  Terminated,
  Transferred
} from "../../change-status/pages";
import EmployeeProfileModal from "component-library/employee-profile/EmployeeProfileModal";
import * as Fields from "component-library/employee-profile/employment-info/change-status/FieldNames";
import NewLeaveReasonModal from "component-library/employee-profile/employment-info/change-status/on-leave/NewLeaveReasonModal";
import {
  EligibilityPeriodModal,
  EditCompanyEligibilityModal
} from "component-library/employee-profile/employment-info/change-status/on-leave/index";
import { InactivationSuccessModal } from "component-library/employee-profile/employment-info/change-status/inactive/index";
import NewTerminationReasonModal from "component-library/employee-profile/employment-info/change-status/termination/NewTerminationReasonModal";
import { EmployeeCopiedModal } from "component-library/employee-profile/employment-info/change-status/transferred/index";
import {
  fetchAndSetFmlaReasons,
  fetchAndSetNonFmlaReasons
} from "./../../change-status/network/actions";
import { makeOptions, makeSelectedOptions } from "app/common/reducers/filters";

class ChangeStatus extends Component {
  state = {
    data: {
      Status: 2,
      LeaveIncludedInPayroll: true,
      AdditionalIncludedInPayroll: true,
      EmployeeCurrentStatus: 2,
      InactivationIncludedInPayroll: true,
      ReactivationIncludedInPayroll: true,
      TerminationIncludedInPayroll: true,
      RehireIncludedInPayroll: true
    },
    showLeaveReasonModal: false,
    showPeriodModal: false,
    showEditModal: false,
    copiedModal: false,
    showTerminationModal: false,
    showCancelModal: false,
    line1: "",
    line2: "",
    buttonLabel: "",
    newStatusId: ""
  };
  componentDidMount() {
    this.props.loadFmlaReasons();
    this.props.loadNonFmlaReasons();
  }

  onChangeStatus = e => {
    if (this.state.isFormUpdated) {
      this.setState({
        showCancelModal: true,
        line1:
          "Are you sure you want to leave this page in the middle of making edits?",
        line2: "Changes made on this page will not be saved.",
        buttonLabel: "Leave this page",
        newStatusId: e.value,
        buttonType: "Leave"
      });
    } else {
      this.setState({ data: { ...this.state.data, [e.name]: e.value } });
    }
  };

  onChange = e => {
    const data = { [e.name]: e.value };

    if (e.name === Fields.newStatus) {
      if (e.value === 1 && data[Fields.status] === 2) {
      }
    }
    if (
      e.name === Fields.selfServiceDefaultConfig ||
      e.name === Fields.inactivateBenefitsPlans ||
      e.name === Fields.rehireIncludedInPayroll ||
      e.name === Fields.hireIncludedInPayroll ||
      e.name === Fields.reactivationIncludedInPayroll ||
      e.name === Fields.reterminationIncludedInPayroll ||
      e.name === Fields.terminationIncludedInPayroll ||
      e.name === Fields.transferredIncludedInPayroll ||
      e.name === Fields.leaveIncludedInPayroll ||
      e.name === Fields.inactivationIncludedInPayroll ||
      e.name === Fields.retirementIncludedInPayroll ||
      e.name === Fields.deceasedIncludedInPayroll ||
      e.name === Fields.additionalIncludedInPayroll ||
      e.name === Fields.terminationType ||
      e.name === Fields.rehireEligibility ||
      e.name === Fields.grossTerminationMisconduct ||
      e.name === Fields.changeScheduledEarnings ||
      e.name === Fields.changeScheduledDeductions ||
      e.name === Fields.replaceManager ||
      e.name === Fields.replacementManager ||
      e.name === Fields.recordCobraEvent ||
      e.name === Fields.leaveReturnIncludedInPayroll ||
      e.name === Fields.fmlConsistentSchedule ||
      e.name === Fields.removeAsManager ||
      e.name === Fields.keepAsManager ||
      e.name === Fields.recordCOBRAEventNow
    ) {
      data[e.name] = e.value === "true" ? true : false;
    }
    if (
      e.name === Fields.leaveIncludedInPayroll ||
      e.name === Fields.additionalIncludedInPayroll
    ) {
      data.LeaveIncludedInPayrollEndDate = e.value === "false" && false;
      data.AdditionalIncludedInPayrollEndDate = e.value === "false" && false;
    }

    if (
      e.name === Fields.leaveType ||
      e.name === Fields.terminationType ||
      e.name === Fields.reterminationType
    ) {
      data[e.name] = parseInt(e.value, 10);
    }

    this.setState({
      isFormUpdated: true
    });
    this.editData(data);
  };

  onSave = () => {};

  showLeaveReasonModal = () => {
    this.setState({ showLeaveReasonModal: true });
  };
  showEditModal = () => {
    this.setState({ showEditModal: true });
  };
  showPeriodModal = () => {
    this.setState({ showPeriodModal: true });
  };
  createleaveReason = () => {
    //Call the action to validate and save the leave reason
  };

  onAddTerminationReason = () => {
    //Call the action to validate and save the leave reason
  };

  editData(edits) {
    this.setState({ data: { ...this.state.data, ...edits } });
  }

  onChangeModal = e => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value }
    });
  };

  toggleCancelModal = () => {
    this.setState({
      showCancelModal: false
    });
  };

  leaveThisPage = () => {
    this.setState({
      data: { ...this.state.data, Status: this.state.newStatusId },
      isFormUpdated: false,
      showCancelModal: false
    });
  };

  render() {
    const { options = {}, onCloseOverlay } = this.props;
    const { data } = this.state;
    return (
      <div className="nc-entity-page-container">
        <FormSection>
          <FormFieldGroup
            inputName="Status"
            label="What would you like to change the employee status to ?"
            inputType="dropdown"
            fieldType="inline"
            class="col-lg-12"
            colSpan="col-lg-3 col-md-4"
            Size={[6, 4, 12, 12]}
            options={options.status}
            value={data.Status}
            onChange={this.onChangeStatus}
            showStatusIcon
          />
        </FormSection>
        {data.Status === 1 && (
          <Active
            data={data}
            onChange={this.onChange}
            onCloseOverlay={onCloseOverlay}
          />
        )}
        {data.Status === 2 && (
          <Terminated
            data={data}
            onChange={this.onChange}
            onAddLinkClick={() => this.setState({ showTerminationModal: true })}
            onCloseOverlay={onCloseOverlay}
          />
        )}
        {data.Status === 3 && (
          <Inactive
            data={data}
            onChange={this.onChange}
            onCloseOverlay={onCloseOverlay}
            onPrimaryClick={this.save}
          />
        )}
        {data.Status === 4 && (
          <OnLeave
            data={data}
            onChange={this.onChange}
            onAddLinkClick={this.showLeaveReasonModal}
            onCloseOverlay={onCloseOverlay}
          />
        )}
        {data.Status === 5 && (
          <Transferred
            data={data}
            onChange={this.onChange}
            onCloseOverlay={onCloseOverlay}
          />
        )}
        {data.Status === 6 && (
          <Retired
            data={data}
            onChange={this.onChange}
            onCloseOverlay={onCloseOverlay}
          />
        )}
        {data.Status === 7 && (
          <Deceased
            data={data}
            onChange={this.onChange}
            onCloseOverlay={onCloseOverlay}
          />
        )}

        <NewLeaveReasonModal
          data={this.state.data}
          show={this.state.showLeaveReasonModal}
          onChangeModal={this.onChangeModal}
          onCancel={() => this.setState({ showLeaveReasonModal: false })}
          onCreate={this.createleaveReason}
        />
        <EligibilityPeriodModal
          show={this.state.showPeriodModal}
          onCancel={() => this.setState({ showPeriodModal: false })}
        />
        <EditCompanyEligibilityModal
          show={this.state.showEditModal}
          onCancel={() => this.setState({ showEditModal: false })}
        />
        <EmployeeCopiedModal
          show={this.state.copiedModal}
          onCancel={() => this.setState({ copiedModal: false })}
        />

        <NewTerminationReasonModal
          data={this.state.data}
          show={this.state.showTerminationModal}
          onChangeModal={this.onChangeModal}
          onCancel={() => this.setState({ showTerminationModal: false })}
          onAddTerminationReason={this.onAddTerminationReason}
        />

        <EmployeeProfileModal
          show={this.state.showCancelModal}
          onClose={this.toggleCancelModal}
          line1={this.state.line1}
          line2={this.state.line2}
          buttonLabel={this.state.buttonLabel}
          leaveThisPage={this.leaveThisPage}
          buttonType={this.state.buttonType}
        />

        <InactivationSuccessModal
          show={this.state.showSuccessModal}
          onCancel={() => this.setState({ showSuccessModal: false })}
        />
      </div>
    );
  }
}
const stateToProps = state => {
  return {
    filters: state.common.filters,
    options: makeOptions(state.common.filters),
    selectedOptions: makeSelectedOptions(state.common.filters)
  };
};
const actionCreators = {
  loadFmlaReasons: fetchAndSetFmlaReasons,
  loadNonFmlaReasons: fetchAndSetNonFmlaReasons
};
export default connect(stateToProps, actionCreators)(ChangeStatus);
