import { combineReducers } from "redux";
import sortOrder from "./sortOrder";
import filters from "./filters";
import fields from "./fields";
import entitySetup from "./entitySetup";
import currentStep from "./progressTracker";
import toast from "./toast";
import entityList from "./entityList";
import entitySnapshot from "./entitySnapshot";
import loggedUser from "./loggedUser";
import { countryState, country } from "./countryState";
import { loadingReducer } from "./../../reducers/LoaderReducer";
export default combineReducers({
  sortOrder,
  filters,
  fields,
  entityList,
  entitySetup,
  entitySnapshot,
  currentStep,
  toast,
  loggedUser,
  countryState,
  country,
  loadingReducer
});
