import React from "react";

const HolidayItem = ({ id, name, picUrl, imgName, width, height, hour }) => (
  <div className="user-icon-horizontal">
    <div className="holiday-image">
      <img src={imgName} alt={picUrl} width={width} height={height} />
      &nbsp;{name} &nbsp;{hour}
    </div>
  </div>
);

export default HolidayItem;
