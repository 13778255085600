import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import axios from "axios";
import { withRouter } from "react-router-dom";
import * as common from "../../../../app/common/index";
import { getEmployerEarningsExpenceAccountsList } from "../../actions/generalledger/earnings/EmployerPaidEarningsActions.js";

import { showToaster } from "../../../../app/actions/AppActions";
import * as validator from "../../../../app/common/validations";
import * as utilities from "../../../../app/common/utilities";

import TextInputWithoutLabel from "../../../../app/common/TextInputWithoutLabel";
const GLACCOUNT_TYPE_SALARY_EXPENSE_ACCOUNT = 4;

class AddEarningGeneralLedger extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isFormUpdated: false,
      showCancelModal: false,
      validationCount: 2,
      showToaster: false,
      messageToaster: "",
      errors: {},
      isDisable: false
    };

    this.redirectToGLEmployerTab = this.redirectToGLEmployerTab.bind(this);
    this.onChangeAccountNumber = this.onChangeAccountNumber.bind(this);
    this.onChangeAccountDescription = this.onChangeAccountDescription.bind(
      this
    );
    this.closeCancelModal = this.closeCancelModal.bind(this);
    this.openCancelModal = this.openCancelModal.bind(this);
    this.createGLAccount = this.createGLAccount.bind(this);
  }

  closeCancelModal() {
    this.setState({
      showCancelModal: false
    });
  }

  openCancelModal() {
    if (this.state.IsFormUpdated) {
      this.setState({
        showCancelModal: true
      });
    } else {
      this.redirectToGLEmployerTab();
    }
  }

  redirectToGLEmployerTab() {
    this.props.onPopOverCancel();
  }

  createGLAccount() {
    let self = this;
    let errors = {};
    this.setState({
      isDisable: true
    });

    errors = validator.validateRequiredField(
      this.state.AccountNumber,
      "AccountNumber",
      errors
    );

    errors = validator.validateRequiredField(
      this.state.AccountDescription,
      "AccountDescription",
      errors
    );

    if (
      this.state.AccountDescription !== null &&
      this.state.AccountDescription !== undefined
    ) {
      let newName = this.state.AccountDescription.trim();
      this.setState({
        AccountDescription: newName
      });

      if (newName === "") {
        errors["AccountDescription"] = "Invalid Name.";
      }
    }

    let postItem = {
      GLAccountTypeId: GLACCOUNT_TYPE_SALARY_EXPENSE_ACCOUNT,
      GLAccountNumber: this.state.AccountNumber,
      GLAccountName: this.state.AccountDescription
    };
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      const url = `${
        common.WEB_API_URL
      }/v1/subscriber/company/glaccount/action/exists/glaccountnumber`;

      axios
        .post(url, {
          Id: GLACCOUNT_TYPE_SALARY_EXPENSE_ACCOUNT,
          Name: self.state.AccountNumber
        })
        .then(function(response) {
          if (response.data.Data[0] === true) {
            errors["AccountNumber"] = "Account Number already exists";
            self.setState({
              isLoading: false,
              errors: errors,
              isDisable: false
            });
          } else {
            const url = `${
              common.WEB_API_URL
            }/v1/subscriber/company/glaccount/action/exists/glaccountname`;

            axios
              .post(url, {
                Id: GLACCOUNT_TYPE_SALARY_EXPENSE_ACCOUNT,
                Name: self.state.AccountDescription
              })
              .then(function(response) {
                if (response.data.Data[0] === true) {
                  errors["AccountDescription"] =
                    "Account Description already exists";
                  self.setState({
                    isLoading: false,
                    errors: errors,
                    isDisable: false
                  });
                } else {
                  const url = `${
                    common.WEB_API_URL
                  }/v1/subscriber/company/glaccount`;

                  axios
                    .post(url, postItem)
                    .then(function(response) {
                      if (response.data.ErrorCount > 0) {
                        response.data.Errors.forEach((item, index) => {
                          if (index === 0) {
                            alert(item.Messages);
                          }
                        });
                      } else {
                        self.redirectToGLEmployerTab();
                        self.props.showToaster(
                          true,
                          "You have successfully added a General Ledger Account"
                        );
                        self.props.getEmployerEarningsExpenceAccountsList();
                      }
                    })
                    .catch(function(error) {});
                }
              })
              .catch(function(error) {});
          }
        })
        .catch(function(error) {});
    }
  }

  onChangeAccountNumber(event) {
    let errors = this.state.errors;
    delete errors["AccountNumber"];
    this.setState({
      errors
    });

    this.setState({
      [event.target.name]: event.target.value,
      IsFormUpdated: true
    });

    if (
      this.state.AccountNumber == null ||
      this.state.AccountNumber === undefined
    ) {
      this.setState({
        validationCount: this.state.validationCount - 1
      });
    }

    if (event.target.value.length !== 0 && !this.state.AccountNumber) {
      this.setState({
        validationCount: this.state.validationCount - 1
      });
    }

    if (event.target.value.length === 0) {
      this.setState({
        validationCount: this.state.validationCount + 1
      });
    }
  }

  onChangeAccountDescription(event) {
    let errors = this.state.errors;
    delete errors["AccountDescription"];
    this.setState({
      errors
    });

    this.setState({
      [event.target.name]: event.target.value,
      IsFormUpdated: true
    });

    if (
      this.state.AccountDescription == null ||
      this.state.AccountDescription === undefined
    ) {
      if (this.state.validationCount > 0) {
        this.setState({
          validationCount: this.state.validationCount - 1
        });
      }
    }

    if (event.target.value.length !== 0 && !this.state.AccountDescription) {
      if (this.state.validationCount > 0) {
        this.setState({
          validationCount: this.state.validationCount - 1
        });
      }
    }

    if (event.target.value.length === 0) {
      this.setState({
        validationCount: this.state.validationCount + 1
      });
    }
  }

  render() {
    let AccountNumber = this.state.errors["AccountNumber"];
    let disabled = false;
    if (
      utilities.isUndefinedOrEmpty(this.state.AccountNumber) ||
      utilities.isUndefinedOrEmpty(this.state.AccountDescription) ||
      this.state.isDisable
    ) {
      disabled = true;
    }

    return (
      <div>
        <div className="modal-row">
          <h3> Add General Ledger Account</h3>
        </div>

        <div
          id="deduction-name"
          className="entity-form-section-secondary clearfix"
        >
          <div className="content">
            <div className="nc-field-group">
              <div className="field-name">
                <span
                  className={utilities.getErrorClassName(
                    this.state.errors["AccountNumber"]
                  )}
                >
                  Account Number
                </span>{" "}
              </div>
              <div className="input-field col-md-12 col-xs-12">
                <div className="col-lg-5 col-md-6 col-sm-8 col-xs-8">
                  <TextInputWithoutLabel
                    value={this.state.AccountNumber}
                    name="AccountNumber"
                    onChange={this.onChangeAccountNumber}
                    maxLength="10"
                  />
                </div>
                <div
                  id="company-deduction-name-input-validation"
                  className="field-description col-xs-12 col-sm-12 col-md-5 col-lg-5 hidden-xs hidden-sm"
                  style={{ paddingLeft: "20px" }}
                >
                  &nbsp;10 characters max
                </div>
                <div
                  id="company-deduction-name-input-validation"
                  className="field-description col-xs-12 col-sm-12 col-md-5 col-lg-5 hidden-lg hidden-md"
                >
                  &nbsp;10 characters max
                </div>
                <div className="col-xs-12" style={{ paddingTop: "5px" }}>
                  {AccountNumber && (
                    <p
                      id="third-party-payee-invalid-routing-number-text"
                      className="error-label"
                    >
                      {AccountNumber}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="deduction-name" className="entity-form-section-secondary">
          <div className="content">
            <div className="nc-field-group">
              <div className="field-name">
                <span
                  className={utilities.getErrorClassName(
                    this.state.errors["AccountDescription"]
                  )}
                >
                  Account Description
                </span>{" "}
              </div>

              <div className="input-field col-lg-7 col-md-6 col-sm-12 col-xs-12">
                <TextInputWithoutLabel
                  value={this.state.AccountDescription}
                  name="AccountDescription"
                  onChange={this.onChangeAccountDescription}
                  disabled={false}
                  error={this.state.errors["AccountDescription"]}
                  maxLength="50"
                />
              </div>
              <div
                id="company-deduction-name-input-validation"
                className="field-description col-xs-12 col-sm-12 col-md-5 col-lg-5 hidden-xs hidden-sm"
                style={{ paddingLeft: "20px" }}
              >
                50 characters max
              </div>
              <div
                id="company-deduction-name-input-validation"
                className="field-description col-xs-12 col-sm-12 col-md-5 col-lg-5 hidden-lg hidden-md"
              >
                50 characters max
              </div>
            </div>
          </div>
        </div>

        <Modal.Footer>
          <div className="button-group">
            <button
              type="button"
              id="create-button"
              className={
                disabled ? "disabled-button" : "nc-button primary-button"
              }
              onClick={this.createGLAccount}
              disabled={disabled}
            >
              ADD GL ACCOUNT
            </button>

            <button
              type="button"
              id="cancel-buttonlink"
              className="secondary-link-button"
              onClick={this.redirectToGLEmployerTab}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    employerEarningsExpenceAccountsList:
      state.generalLedgerEarningsReducer.employerEarningsExpenceAccountsList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToaster,
      getEmployerEarningsExpenceAccountsList
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddEarningGeneralLedger)
);
