import React, { Component } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import PropTypes from "prop-types";
export default class PayrollDashboardSummaryEmployeeNotPaid extends Component {
  render() {
    const { label, lvalue, EmployeesNotPaidList, visible } = this.props;
    const bottomPopover = (
      <Popover
        id="holiday-list-bottom"
        className="payroll-dash-summary-icon-list payroll-dash-summary-icon-list-bgcolor"
      >
        {EmployeesNotPaidList.length !== 0 && (
          <div className="payroll-dash-summary-icon-list-icon  clearfix">
            <i
              className="fa fa-times-circle icon"
              onClick={() => this.refs.overlayHolidayList.hide()}
            />
          </div>
        )}

        {EmployeesNotPaidList.map(item => {
          return <div key={item}>{item}</div>;
        })}
      </Popover>
    );
    if (visible)
      return (
        <div className="payroll-dash-summary-item clearfix">
          <div className="payroll-dash-summary-item-label">{label}</div>
          <div className="payroll-dash-summary-item-value">{lvalue}</div>
          <div className="payroll-dash-summary-item-icon">
            {lvalue !== 0 && (
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                ref="overlayHolidayList"
                overlay={bottomPopover}
                rootClose={true}
              >
                <i className="fa-information-blue fa fa-info-circle" />
              </OverlayTrigger>
            )}
          </div>
        </div>
      );
    else {
      return null;
    }
  }
}

PayrollDashboardSummaryEmployeeNotPaid.propTypes = {
  label: PropTypes.string,
  lvalue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  EmployeesNotPaidList: PropTypes.array,
  visible: PropTypes.bool
};
PayrollDashboardSummaryEmployeeNotPaid.defaultProps = {
  EmployeesNotPaidList: ["Susan Christopher", "Shawn Black"],
  visible: true
};
