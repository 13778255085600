import React from "react";
//import PropTypes from "prop-types";
import EntityListTableHeader from "component-library/entity-list/EntityListTableHeader";
import NotesListItem from "./NotesListItem";

const EmployeeNotesTable = props => {
  const { headerColumn = "EmployeeNotesList", data = [] } = props;
  return (
    <div className="list-page-grid-table col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <EntityListTableHeader
        headerColumn={headerColumn}
        size={[12, 12, 12, 12]}
      />
      {data.map((item, i) => (
        <NotesListItem key={item.Id} item={item} onClickEmployee={() => {}} />
      ))}
    </div>
  );
};

export default EmployeeNotesTable;
