export const CURRENT_ADDRESS = "Current Address";
export const FORMER_ADDRESS = "Former Address";
export const CURRENT_LINK = "Make Former Address";
export const FORMER_LINK = "Make Current Address";
export const TITLE = "title";
export const CONTACT_INFO = "Contact Info";
export const CELLPHONE_NUMBER = "Cell Phone Number";
export const CELLPHONE_CARRIER_TOOLTIP =
  "Cell Phone Carrier is necessary if you would like this employee to receive any Netchex text alerts.";
export const SSN = "SSN";
export const DOB = "DOB";
export const UNMASKED_SSN = "unmaskedSSN";
export const AGE = "age";
export const LABEL = "Label";
export const BASIC_INFO = "Basic Info";
export const ZIPCODE = "zipCode";
export const F_ZIPCODE = "fZipCode";
export const ADDITIONAL_INFO = "Additional Info";
export const DISABILITY = "disability";
export const DISABILITY_ACC = "disabilityAccomodation";
export const SAVE_DEMOGRAPHIC = "Save Demographic";
export const ACTIVITIES = "activities";
export const HOBBIES = "hobbies";
export const MUSIC = "music";
export const sampleDescription =
  "Lorem ipsum dolor sit amet, nunc nostra gravida id. Eu justo, magna habitant vitae neque sit cursus. Nunc sapien lacinia wisi integer";

export const USE_SAME_ADDRESS_LABEL = "Always use same address as employee";

export const SUFFIX_OPTIONS = [
  "Jr",
  "Sr",
  "I",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
  "X"
];

export const emergencyQuestions = {
  primaryContact: [
    "Who should we contact in case of an emergency?",
    "What is your primary contact's address?"
  ],
  secondaryContact: [
    "Who else should we contact in case of an emergency?",
    "What is your secondary contact's address?"
  ],
  disasterLocation: ["Where would you stay in the event of a natural disaster?"]
};

export const Age = {
  show: true,
  inputId: "age",
  label: "Age",
  inputName: "age",
  inputType: "Label",
  required: true
};
export const verification = {
  isVerified: {
    containerStyle: { clear: "none" },
    inputId: "isVerified",
    inlineStyles: { width: "125px", height: "38px", borderRadius: "0px" },
    label: "Have they been verified?",
    radios: [
      { id: 1, label: "Yes", value: "true" },
      { id: 2, label: "No", value: "false" }
    ],
    tooltip: "tooltip",
    inputName: "isVerified",
    type: "basic",
    optional: true,
    inputType: "radio",
    required: true
  },
  dateOfVerification: {
    containerStyle: { clear: "none" },
    inputId: "dateOfVerification",
    label: "When were they verified?",
    inputName: "dateOfVerification",
    inputType: "singleDatePicker",
    required: true
  },
  verifiedBy: {
    show: true,
    inputId: "verifiedBy",
    Size: [6, 6, 6, 6],
    label: "Verified By",
    inputName: "verifiedBy",
    inputType: "text",
    required: true,
    optional: true
  }
};

export const childBenefitsInfo = {
  smoker: {
    containerStyle: { clear: "none" },
    inputId: "smoker",
    inlineStyles: { width: "125px", height: "38px", borderRadius: "0px" },
    label: "Are they a smoker?",
    radios: [
      { id: 1, label: "Yes", value: "true" },
      { id: 2, label: "No", value: "false" }
    ],
    inputName: "smoker",
    type: "basic",
    optional: true,
    inputType: "radio",
    required: true
  },
  participantOfTCP: {
    containerStyle: { clear: "none" },
    inputId: "participantOfTCP",
    inlineStyles: { width: "125px", height: "38px", borderRadius: "0px" },
    label: "Are they a participant of the Tobacco Cessation Plant?",
    radios: [
      { id: 1, label: "Yes", value: "true" },
      { id: 2, label: "No", value: "false" }
    ],
    inputName: "participantOfTCP",
    type: "basic",
    optional: true,
    inputType: "radio",
    required: true
  },
  disability: {
    containerStyle: { clear: "none" },
    inputId: "disability",
    inlineStyles: { width: "125px", height: "38px", borderRadius: "0px" },
    label: "Are they disabled?",
    radios: [
      { id: 1, label: "Yes", value: "true" },
      { id: 2, label: "No", value: "false" }
    ],
    inputName: "disability",
    type: "basic",
    optional: true,
    inputType: "radio",
    required: true
  },
  isFullTimeStudent: {
    containerStyle: { clear: "none" },
    inputId: "isFullTimeStudent",
    inlineStyles: { width: "125px", height: "38px", borderRadius: "0px" },
    label: "Are they a full time student?",
    radios: [
      { id: 1, label: "Yes", value: "true" },
      { id: 2, label: "No", value: "false" }
    ],
    inputName: "isFullTimeStudent",
    type: "basic",
    optional: true,
    inputType: "radio",
    required: true
  },
  isAdopted: {
    containerStyle: { clear: "none" },
    inputId: "isAdopted",
    inlineStyles: { width: "125px", height: "38px", borderRadius: "0px" },
    label: "Are they adopted?",
    radios: [
      { id: 1, label: "Yes", value: "true" },
      { id: 2, label: "No", value: "false" }
    ],
    inputName: "isAdopted",
    type: "basic",
    inputType: "radio",
    required: true
  },
  dateOfAdoption: {
    containerStyle: { clear: "none" },
    inputId: "dateOfAdoption",
    label: "When were they adopted?",
    inputName: "dateOfAdoption",
    inputType: "singleDatePicker",
    required: true
  },
  isStepChild: {
    containerStyle: { clear: "none" },
    inputId: "isStepChild",
    inlineStyles: { width: "125px", height: "38px", borderRadius: "0px" },
    label: "Are they a stepchild?",
    radios: [
      { id: 1, label: "Yes", value: "true" },
      { id: 2, label: "No", value: "false" }
    ],
    inputName: "isStepChild",
    type: "basic",
    inputType: "radio",
    required: true
  },
  isLegalGuardian: {
    containerStyle: { clear: "none" },
    inputId: "isLegalGuardian",
    inlineStyles: { width: "125px", height: "38px", borderRadius: "0px" },
    label: "Is the employee their legal guardian?",
    radios: [
      { id: 1, label: "Yes", value: "true" },
      { id: 2, label: "No", value: "false" }
    ],
    inputName: "isLegalGuardian",
    type: "basic",
    inputType: "radio",
    required: true
  },
  allowMaxAgeOverride: {
    containerStyle: { clear: "none" },
    inputId: "allowMaxAgeOverride",
    inlineStyles: { width: "125px", height: "38px", borderRadius: "0px" },
    label: "Allow maximum age override?",
    radios: [
      { id: 1, label: "Yes", value: "true" },
      { id: 2, label: "No", value: "false" }
    ],
    inputName: "allowMaxAgeOverride",
    type: "basic",
    inputType: "radio",
    required: true
  }
};

export const spouseBenefitsInfo = {
  smoker: {
    containerStyle: { clear: "none" },
    inputId: "smoker",
    inlineStyles: { width: "125px", height: "38px", borderRadius: "0px" },
    label: "Are they a smoker?",
    radios: [
      { id: 1, label: "Yes", value: "true" },
      { id: 2, label: "No", value: "false" }
    ],
    inputName: "smoker",
    type: "basic",
    optional: true,
    inputType: "radio",
    required: true
  },
  participantOfTCP: {
    containerStyle: { clear: "none" },
    inputId: "participantOfTCP",
    inlineStyles: { width: "125px", height: "38px", borderRadius: "0px" },
    label: "Are they a participant of the Tobacco Cessation Plant?",
    radios: [
      { id: 1, label: "Yes", value: "true" },
      { id: 2, label: "No", value: "false" }
    ],
    inputName: "participantOfTCP",
    type: "basic",
    optional: true,
    inputType: "radio",
    required: true
  },
  disability: {
    containerStyle: { clear: "none" },
    inputId: "disability",
    inlineStyles: { width: "125px", height: "38px", borderRadius: "0px" },
    label: "Are they disabled?",
    radios: [
      { id: 1, label: "Yes", value: "true" },
      { id: 2, label: "No", value: "false" }
    ],
    inputName: "disability",
    type: "basic",
    optional: true,
    inputType: "radio",
    required: true
  },
  dateOfMarriage: {
    containerStyle: { clear: "none" },
    inputId: "dateOfMarriage",
    label: "What is the date of marriage?",
    inputName: "dateOfMarriage",
    optional: true,
    inputType: "singleDatePicker",
    required: true
  }
};

export const basicInfo = {
  title: {
    show: true,
    inputId: "title",
    inputType: "raisedRadio",
    inputName: "title",
    Size: [6, 6, 6, 6],
    label: "Title",
    optional: true,
    radio: ["Dr.", "Hr.", "Mr.", "Sir", "Ms.", "Mrs."]
  },
  firstName: {
    show: true,
    inputId: "firstName",
    Size: [6, 6, 6, 6],
    label: "First Name",
    inputName: "firstName",
    inputType: "text",
    required: true
  },
  middleName: {
    show: true,
    inputId: "middleName",
    Size: [6, 6, 6, 6],
    label: "Middle Name",
    inputName: "middleName",
    inputType: "text",
    required: true,
    optional: true
  },
  lastName: {
    show: true,
    inputId: "lastName",
    Size: [6, 6, 6, 6],
    label: "Last Name",
    inputName: "lastName",
    inputType: "text",
    required: true
  },
  suffix: {
    show: true,
    inputId: "suffix",
    label: "Suffix",
    inputName: "suffix",
    inputType: "dropdown",
    Size: [3, 3, 3, 3],
    options: [{ value: "Jr", label: "Jr" }],
    optional: true
  },
  previousLastName: {
    show: true,
    inputId: "previousLastName",
    label: "Previous Last Name",
    Size: [6, 6, 6, 6],
    inputName: "previousLastName",
    inputType: "text",
    optional: true,
    required: true
  },
  nickName: {
    show: true,
    inputId: "nickName",
    label: "Nickname",
    Size: [6, 6, 6, 6],
    inputName: "nickName",
    optional: true,
    inputType: "text",
    required: true
  },
  SSN: {
    show: true,
    inputId: "SSN",
    Size: [6, 6, 6, 6],
    label: "Social Security Number",
    inputName: "SSN",
    inputType: "labelWithIcon",
    labelIcon: "fa fa-eye",
    required: true
  },
  DOB: {
    show: true,
    inputId: "DOB",
    Size: [6, 6, 6, 6],
    label: "Birth Date",
    inputName: "DOB",
    inputType: "labelWithIcon",
    labelIcon: "fa fa-eye"
  }
};

export const dependentsBasicInfo = {
  relation: {
    show: true,
    inputId: "relation",
    inputType: "raisedRadio",
    inputName: "relation",
    Size: [6, 6, 6, 6],
    label: "Relation",
    radio: [
      { Id: 1, Name: "Spouse" },
      { Id: 2, Name: "Domestic Partner" },
      { Id: 3, Name: "Child" }
    ]
  },
  title: {
    show: true,
    inputId: "title",
    inputType: "raisedRadio",
    inputName: "title",
    Size: [6, 6, 6, 6],
    label: "Title",
    optional: true,
    radio: ["Dr.", "Hr.", "Mr.", "Sir", "Ms.", "Mrs."]
  },
  firstName: {
    show: true,
    inputId: "firstName",
    Size: [6, 6, 6, 6],
    label: "First Name",
    inputName: "firstName",
    inputType: "text",
    required: true
  },
  middleName: {
    show: true,
    inputId: "middleName",
    Size: [6, 6, 6, 6],
    label: "Middle Name",
    inputName: "middleName",
    inputType: "text",
    required: true,
    optional: true
  },
  lastName: {
    show: true,
    inputId: "lastName",
    Size: [6, 6, 6, 6],
    label: "Last Name",
    inputName: "lastName",
    inputType: "text",
    required: true
  },
  suffix: {
    show: true,
    inputId: "suffix",
    label: "Suffix",
    inputName: "suffix",
    inputType: "dropdown",
    Size: [3, 3, 3, 3],
    options: [
      {
        value: "Jr",
        label: "Jr"
      }
    ],
    optional: true
  },
  gender: {
    inputId: "gender",
    optional: true,
    label: "Gender",
    containerStyle: { clear: "none" },
    inlineStyles: { width: "125px", height: "38px", borderRadius: "0px" },
    radios: [
      { id: 1, label: "Male", value: "1" },
      { id: 2, label: "Female", value: "2" }
    ],
    inputName: "gender",
    type: "basic",
    inputType: "radio",
    required: true
  },
  SSN: {
    show: true,
    inputId: "SSN",
    Size: [6, 6, 6, 6],
    label: "Social Security Number",
    inputName: "SSN",
    inputType: "labelWithIcon",
    labelIcon: "fa fa-eye",
    required: true
  },
  DOB: {
    show: true,
    inputId: "DOB",
    Size: [6, 6, 6, 6],
    label: "Birth Date",
    inputName: "DOB",
    inputType: "labelWithIcon",
    labelIcon: "fa fa-eye"
  }
};

export const currentAddress = {
  country: {
    show: true,
    inputId: "country",
    inputType: "dropdown",
    inputName: "country",
    label: "Country",
    Size: [6, 6, 6, 6],
    options: [
      {
        value: "US",
        label: "United States"
      }
    ]
  },
  address1: {
    show: true,
    inputId: "address1",
    label: "Address Line 1",
    Size: [6, 6, 6, 6],
    inputName: "address1",
    inputType: "text",
    maxLength: "30",
    required: true
  },
  address2: {
    show: true,
    inputId: "address2",
    label: "Address Line 2",
    Size: [6, 6, 6, 6],
    inputName: "address2",
    inputType: "text",
    maxLength: "30",
    required: true,
    optional: true
  },
  zipCode: {
    show: true,
    inputId: "zipCode",
    label: "Zip Code",
    inputName: "zipCode",
    inputType: "text",
    required: true,
    Size: [3, 3, 3, 3]
  },
  city: {
    show: true,
    inputId: "city",
    label: "City",
    Size: [6, 6, 6, 6],
    inputName: "city",
    inputType: "text",
    required: true
  },
  State: {
    show: true,
    inputId: "State",
    label: "State",
    inputName: "State",
    inputType: "dropdown",
    Size: [3, 3, 3, 3],
    options: [
      {
        value: "LA",
        label: "LA"
      }
    ]
  },
  county: {
    show: true,
    inputId: "county",
    label: "County",
    inputName: "county",
    Size: [6, 6, 6, 6],
    inputType: "text",
    required: true,
    optional: true
  }
};

export const formerAddress = {
  fCountry: {
    show: true,
    inputId: "fCountry",
    inputType: "dropdown",
    inputName: "fCountry",
    label: "Country",
    Size: [6, 6, 6, 6],
    options: [
      {
        value: "US",
        label: "United States"
      }
    ]
  },
  fAddress1: {
    show: true,
    inputId: "fAddress1",
    label: "Address Line 1",
    Size: [6, 6, 6, 6],
    maxLength: "30",
    inputName: "fAddress1",
    inputType: "text",
    required: true
  },
  fAddress2: {
    show: true,
    inputId: "fAddress2",
    label: "Address Line 2",
    Size: [6, 6, 6, 6],
    maxLength: "30",
    inputName: "fAddress2",
    inputType: "text",
    required: true,
    optional: true
  },
  fZipCode: {
    show: true,
    inputId: "fZipCode",
    label: "Zip Code",
    inputName: "fZipCode",
    inputType: "text",
    required: true,
    Size: [3, 3, 3, 3]
  },
  fCity: {
    show: true,
    inputId: "fCity",
    label: "City",
    Size: [6, 6, 6, 6],
    inputName: "fCity",
    inputType: "text",
    required: true
  },
  fState: {
    show: true,
    inputId: "fState",
    label: "State",
    inputName: "fState",
    inputType: "dropdown",
    Size: [3, 3, 3, 3],
    options: [
      {
        value: "LA",
        label: "LA"
      }
    ]
  },
  fCounty: {
    show: true,
    inputId: "fCounty",
    label: "County",
    inputName: "fCounty",
    Size: [6, 6, 6, 6],
    inputType: "text",
    required: true,
    optional: true
  }
};

export const contactInfo = {
  personalEmail: {
    show: true,
    required: true,
    inputId: "personalEmail",
    inputType: "text",
    inputName: "personalEmail",
    Size: [3, 3, 3, 3],
    label: "Personal Email Address",
    optional: true,
    iconBeforeLabel: "fa fa-envelope",
    iconBeforeLabelStyle: { float: "left", fontSize: "25px", color: "#0c4f48" }
  },
  homePhone: {
    required: true,
    show: true,
    inputId: "homePhone",
    inputType: "text",
    placeholder: "504-888-0093",
    inputName: "homePhone",
    Size: [2, 2, 2, 2],
    label: "Home Phone Number",
    optional: true,
    iconBeforeLabel: "fa fa-phone-square",
    iconBeforeLabelStyle: { float: "left", fontSize: "30px", color: "#50b893" }
  }
};

export const additionalInfo = {
  race: {
    show: true,
    Size: [4, 4, 12, 12],
    inputId: "race",
    label: "Race",
    inputName: "race",
    optional: true,
    inputType: "dropdown",
    options: [{ value: "Caucasian", label: "Caucasian" }]
  },
  gender: {
    inputId: "gender",
    optional: true,
    label: "Gender",
    containerStyle: { clear: "none" },
    inlineStyles: { width: "125px", height: "38px", borderRadius: "0px" },
    radios: [
      { id: 1, label: "Male", value: "1" },
      { id: 2, label: "Female", value: "2" }
    ],
    inputName: "gender",
    type: "basic",
    inputType: "radio",
    required: true
  },
  martialStatus: {
    show: true,
    inputId: "martialStatus",
    inputType: "raisedRadio",
    inputName: "martialStatus",
    Size: [6, 6, 6, 6],
    label: "Martial Status",
    optional: true,
    radio: ["Single", "Married", "Divorced", "Widowed"]
  },
  protectedVeteran: {
    inputId: "protectedVeteran",
    inlineStyles: { width: "125px", height: "38px", borderRadius: "0px" },
    containerStyle: { clear: "none" },
    label: "Are you a Protected Veteran?",
    radios: [
      { id: 1, label: "Yes", value: "true" },
      { id: 2, label: "No", value: "false" }
    ],
    inputName: "protectedVeteran",
    type: "basic",
    tooltip:
      "(1) Disabled veterans (2) Other protected veterans (veterans who served on active duty in the U.S. military during a war or in a campaign or expedition for which a campaign badge is awarded) (3) Armed Forces service medal veterans (veterans who, while serving on active duty in the Armed Forces, participated in a United States military operation for which an Armed Forces service medal was awarded pursuant to Executive Order 12985) and (4) Recently separated veterans (veterans within 36 months from discharge or release from active duty)",
    inputType: "radio",
    required: true
  },
  disability: {
    containerStyle: { clear: "none" },
    inputId: "disability",
    inlineStyles: { width: "125px", height: "38px", borderRadius: "0px" },
    label: "Do you have a disability?",
    radios: [
      { id: 1, label: "Yes", value: "true" },
      { id: 2, label: "No", value: "false" }
    ],
    inputName: "disability",
    type: "basic",
    inputType: "radio",
    required: true
  },
  disabilityAccomodation: {
    inputId: "disabilityAccomodation",
    label: "Disability Accommodations",
    inputName: "disabilityAccomodation",
    inputType: "textarea",
    Size: [6, 6, 6, 6],
    height: "70px",
    description: "250 characters max",
    optional: true,
    required: true,
    descriptionStyle: "italic"
  }
};

export const socialAccounts = {
  facebook: {
    show: true,
    required: true,
    inputId: "facebook",
    placeholder: "ex. facebook.com/username",
    inputType: "text",
    inputName: "facebook",
    label: "Facebook",
    iconBeforeLabel: "fa fa-facebook-official",
    iconBeforeLabelStyle: { float: "left", fontSize: "35px", color: "#3b5998" },
    labelStyle: { marginTop: "10px" }
  },
  googlePlus: {
    required: true,
    show: true,
    inputId: "googlePlus",
    inputType: "text",
    placeholder: "ex. plus.goggle.com/userid",
    label: "Google+",
    iconBeforeLabel: "fa fa-google-plus-official",
    iconBeforeLabelStyle: { float: "left", fontSize: "35px", color: "#d23e62" },
    labelStyle: { marginTop: "10px" }
  },
  linkedIn: {
    required: true,
    show: true,
    inputId: "linkedIn",
    inputType: "text",
    placeholder: "ex. linkedin.com/pub/username",
    inputName: "linkedIn",
    label: "LinkedIn",
    iconBeforeLabel: "fa fa-linkedin-square",
    iconBeforeLabelStyle: { float: "left", fontSize: "35px", color: "#0775b7" },
    labelStyle: { marginTop: "10px" }
  },
  twitter: {
    required: true,
    show: true,
    inputId: "twitter",
    inputType: "text",
    placeholder: "ex. twitter.com/username",
    inputName: "twitter",
    label: "Twitter",
    iconBeforeLabel: "fa fa-twitter-square",
    iconBeforeLabelStyle: { float: "left", fontSize: "35px", color: "#49c9f2" },
    labelStyle: { marginTop: "10px" }
  }
};

export const SampleData = [
  {
    id: 1,
    name: "Activities/Sports",
    link: "menu1",
    active: true,
    icon: "fa fa-futbol-o"
  },
  { id: 2, name: "Hobbies", link: "menu1", icon: "fa fa-book" },
  { id: 3, name: "Music", link: "menu1", icon: "fa fa-music" }
];

export const primaryContact = {
  primaryContactName: {
    show: true,
    inputId: "primaryContactName",
    Size: [4, 4, 4, 4],
    label: "Name",
    inputName: "primaryContactName",
    inputType: "text",
    required: true,
    optional: true
  },
  primaryContactRelationship: {
    show: true,
    inputId: "primaryContactRelationship",
    label: "Relationalship",
    inputName: "primaryContactRelationship",
    inputType: "dropdown",
    Size: [4, 4, 4, 4],
    options: [{ value: "Father", label: "Father" }],
    optional: true
  },
  primaryContactPersonalEmail: {
    show: true,
    required: true,
    inputId: "primaryContactPersonalEmail",
    inputType: "text",
    inputName: "primaryContactPersonalEmail",
    Size: [4, 4, 4, 4],
    label: "Personal Email Address",
    optional: true
  },
  primaryContactHomePhone: {
    required: true,
    show: true,
    inputId: "primaryContactHomePhone",
    inputType: "text",
    placeholder: "504-888-0093",
    inputName: "primaryContactHomePhone",
    Size: [4, 4, 4, 4],
    label: "Home Phone Number",
    optional: true
  },
  primaryContactCellPhoneNumber: {
    required: true,
    show: true,
    inputId: "primaryContactCellPhoneNumber",
    inputType: "text",
    placeholder: "504-888-0093",
    inputName: "primaryContactCellPhoneNumber",
    Size: [4, 4, 4, 4],
    label: "Cell Phone Number",
    optional: true
  },
  primaryContactWorkPhoneNumber: {
    required: true,
    show: true,
    inputId: "primaryContactWorkPhoneNumber",
    inputType: "text",
    placeholder: "504-888-0093",
    inputName: "primaryContactWorkPhoneNumber",
    Size: [4, 4, 4, 4],
    label: "Work Phone Number",
    optional: true
  },
  primaryContactAddress1: {
    show: true,
    inputId: "primaryContactAddress1",
    label: "Address Line 1",
    Size: [4, 4, 4, 4],
    inputName: "primaryContactAddress1",
    inputType: "text",
    optional: true,
    maxLength: "30",
    required: true
  },
  primaryContactAddress2: {
    show: true,
    inputId: "primaryContactAddress2",
    label: "Address Line 2",
    Size: [4, 4, 4, 4],
    inputName: "primaryContactAddress2",
    inputType: "text",
    maxLength: "30",
    required: true,
    optional: true
  },
  primaryContactZipCode: {
    show: true,
    inputId: "primaryContactZipCode",
    label: "Zip Code",
    inputName: "primaryContactZipCode",
    inputType: "text",
    required: true,
    Size: [2, 2, 2, 2],
    optional: true
  },
  primaryContactCity: {
    show: true,
    inputId: "primaryContactCity",
    label: "City",
    Size: [4, 4, 4, 4],
    inputName: "primaryContactCity",
    inputType: "text",
    required: true,
    optional: true
  },
  primaryContactState: {
    show: true,
    inputId: "primaryContactState",
    label: "State",
    inputName: "primaryContactState",
    inputType: "dropdown",
    Size: [2, 2, 2, 2],
    options: [{ value: "LA", label: "LA" }],
    optional: true
  }
};

export const secondaryContact = {
  secContactName: {
    show: true,
    inputId: "secContactName",
    Size: [4, 4, 4, 4],
    label: "Name",
    inputName: "secContactName",
    inputType: "text",
    required: true,
    optional: true
  },
  secContactRelationship: {
    show: true,
    inputId: "secContactRelationship",
    label: "Relationalship",
    inputName: "secContactRelationship",
    inputType: "dropdown",
    Size: [4, 4, 4, 4],
    options: [{ value: "Father", label: "Father" }],
    optional: true
  },
  secContactPersonalEmail: {
    show: true,
    required: true,
    inputId: "secContactPersonalEmail",
    inputType: "text",
    inputName: "secContactPersonalEmail",
    Size: [4, 4, 4, 4],
    label: "Personal Email Address",
    optional: true
  },
  secContactHomePhone: {
    required: true,
    show: true,
    inputId: "secContactHomePhone",
    inputType: "text",
    placeholder: "504-888-0093",
    inputName: "secContactHomePhone",
    Size: [4, 4, 4, 4],
    label: "Home Phone Number",
    optional: true
  },
  secContactCellPhoneNumber: {
    required: true,
    show: true,
    inputId: "secContactCellPhoneNumber",
    inputType: "text",
    placeholder: "504-888-0093",
    inputName: "secContactCellPhoneNumber",
    Size: [4, 4, 4, 4],
    label: "Cell Phone Number",
    optional: true
  },
  secContactWorkPhoneNumber: {
    required: true,
    show: true,
    inputId: "secContactWorkPhoneNumber",
    inputType: "text",
    placeholder: "504-888-0093",
    inputName: "secContactWorkPhoneNumber",
    Size: [4, 4, 4, 4],
    label: "Work Phone Number",
    optional: true
  },
  secContactAddress1: {
    show: true,
    inputId: "secContactAddress1",
    label: "Address Line 1",
    Size: [4, 4, 4, 4],
    inputName: "secContactAddress1",
    inputType: "text",
    optional: true,
    maxLength: "30",
    required: true
  },
  secContactAddress2: {
    show: true,
    inputId: "secContactAddress2",
    label: "Address Line 2",
    Size: [4, 4, 4, 4],
    inputName: "secContactAddress2",
    inputType: "text",
    maxLength: "30",
    required: true,
    optional: true
  },
  secContactZipCode: {
    show: true,
    inputId: "secContactZipCode",
    label: "Zip Code",
    inputName: "secContactZipCode",
    inputType: "text",
    required: true,
    Size: [2, 2, 2, 2],
    optional: true
  },
  secContactCity: {
    show: true,
    inputId: "secContactCity",
    label: "City",
    Size: [4, 4, 4, 4],
    inputName: "secContactCity",
    inputType: "text",
    required: true,
    optional: true
  },
  secContactState: {
    show: true,
    inputId: "secContactState",
    label: "State",
    inputName: "secContactState",
    inputType: "dropdown",
    Size: [2, 2, 2, 2],
    options: [{ value: "LA", label: "LA" }],
    optional: true
  }
};

export const disasterLocation = {
  disasterAddress1: {
    show: true,
    inputId: "disasterAddress1",
    label: "Address Line 1",
    Size: [4, 4, 4, 4],
    inputName: "disasterAddress1",
    inputType: "text",
    optional: true,
    maxLength: "30",
    required: true
  },
  disasterAddress2: {
    show: true,
    inputId: "disasterAddress2",
    label: "Address Line 2",
    Size: [4, 4, 4, 4],
    inputName: "disasterAddress2",
    inputType: "text",
    maxLength: "30",
    required: true,
    optional: true
  },
  disasterZipCode: {
    show: true,
    inputId: "disasterZipCode",
    label: "Zip Code",
    inputName: "disasterZipCode",
    inputType: "text",
    required: true,
    Size: [2, 2, 2, 2],
    optional: true
  },
  disasterCity: {
    show: true,
    inputId: "disasterCity",
    label: "City",
    Size: [4, 4, 4, 4],
    inputName: "disasterCity",
    inputType: "text",
    required: true,
    optional: true
  },
  disasterState: {
    show: true,
    inputId: "disasterState",
    label: "State",
    inputName: "disasterState",
    inputType: "dropdown",
    Size: [2, 2, 2, 2],
    options: [{ value: "LA", label: "LA" }],
    optional: true
  }
};
export const EMPLOYMENT_HISTORY =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis sed tortor vel imperdiet. Praesent nec malesuada nulla. Suspendisse id dapibus felis. Aliquam a nisl id quam efficitur condimentum.";
export const interestCaseValue = {
  activities: 1,
  hobbies: 2,
  music: 3
};
export const BenefitsInfoCase = {
  spouse: "1",
  child: "3"
};
export const circularCheckboxOption = [
  { Id: 1, label: "Dependent", value: 1 },
  { Id: 2, label: "Beneficiary", value: 2 }
];
export const disasterRadioOption = [
  { Id: 1, Name: "home" },
  { Id: 2, Name: "cell" },
  { Id: 3, Name: "work" },
  { Id: 4, Name: "other" }
];
export const tooltipCase = {
  Dependent: "Dependent",
  Beneficiary: "Beneficiary"
};
export const OTHER_HEADER = ["Files", "Notes"];

export const NotesSampleList = [
  {
    Id: 1,
    EmployeeNotesType: "Employee Notes",
    EmployeeNotesDescription: "Small Description Goes Here"
  },
  {
    Id: 2,
    EmployeeNotesType: "Employee Notes",
    EmployeeNotesDescription: "Small Description Goes Here"
  }
];
