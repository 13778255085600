import axios from "axios";
import * as types from "../index";
import * as common from "../../../../../app/common/index";

export function getCategoryList() {
  const url = `${common.WEB_API_URL}/v1/system/thirdpartypayeecategory`;
  const request = axios.get(url);
  return {
    type: types.FETCH_THIRD_PARTY_PAYEE_CATEGORY_LIST,
    payload: request
  };
}

export function getStatusList() {
  // const url=`${common.WEB_API_URL}/v1/app/ui/createworkflowstatus `;
  const url = `${
    common.WEB_API_URL
  }/v1/app/ui/createworkflowstatus?from=2&to=3 `;
  const request = axios.get(url);
  return {
    type: types.FETCH_THIRD_PARTY_PAYEE_STATUS_LIST,
    // type : 'FETCH_STATUS_LIST',
    payload: request
  };
}

export function getThirdPartyPayeeList() {
  const url = `${common.WEB_API_URL}/v1/system/thirdpartypayee?filter=All`;
  const request = axios.get(url);
  return {
    type: types.SYSTEM_THIRD_PARTY_PAYEE_MAIN_LIST,
    payload: request
  };
}
