import React from "react";
import "./svg.css";
const DeductionProrityNoData = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 200 200"
    style={{ enableBackground: "new 0 0 200 200" }}
    width={width}
    height={height}
  >
    <g>
      <g>
        <circle className="st0" cx="100" cy="101.3" r="100" />
      </g>
    </g>
    <g>
      <path
        className="st1"
        d="M60.9,79.2c3.4,0,6.6-1.2,9.1-3.1h20c0.5,0,0.9-0.2,1.2-0.5l9.9-9.9c0.7-0.7,0.7-1.8,0-2.5l-9.9-9.9
		c-0.3-0.3-0.8-0.5-1.2-0.5H70c-2.5-2-5.7-3.1-9.1-3.1c-8.2,0-14.8,6.6-14.8,14.8C46,72.6,52.7,79.2,60.9,79.2z M97.4,64.4l-8.2,8.2
		h-16c1.6-2.3,2.5-5.2,2.5-8.2c0-3-0.9-5.8-2.5-8.2h16L97.4,64.4z M60.9,53.1c6.2,0,11.3,5.1,11.3,11.3s-5.1,11.3-11.3,11.3
		s-11.3-5.1-11.3-11.3S54.6,53.1,60.9,53.1z"
      />
      <path
        className="st1"
        d="M64.3,67.7H62v-9.8H60c-0.4,0.3-0.9,0.5-1.3,0.6s-1,0.3-1.7,0.5v1.7h2.3v7h-2.6V70h7.7V67.7z"
      />
      <path
        className="st1"
        d="M60.9,116.1c3.4,0,6.6-1.2,9.1-3.1h49.9c0.5,0,0.9-0.2,1.2-0.5l9.9-9.9c0.7-0.7,0.7-1.8,0-2.5l-9.9-9.9
		c-0.3-0.3-0.8-0.5-1.2-0.5H70c-2.5-2-5.7-3.1-9.1-3.1c-8.2,0-14.8,6.6-14.8,14.8C46,109.5,52.7,116.1,60.9,116.1z M127.3,101.3
		l-8.2,8.2H73.2c1.6-2.3,2.5-5.2,2.5-8.2c0-3-0.9-5.8-2.5-8.2h45.9L127.3,101.3z M60.9,90c6.2,0,11.3,5.1,11.3,11.3
		s-5.1,11.3-11.3,11.3s-11.3-5.1-11.3-11.3S54.6,90,60.9,90z"
      />
      <path
        className="st1"
        d="M59.3,97.4c0.3-0.2,0.7-0.4,1.2-0.4c0.5,0,0.9,0.2,1.3,0.5c0.3,0.3,0.5,0.8,0.5,1.4c0,0.4-0.1,0.9-0.4,1.4
		s-0.6,1-1.1,1.5c-0.5,0.6-1,1.1-1.7,1.8s-1.3,1.3-2.1,2v1.6h8.5v-2.4h-2.4c-0.3,0-0.6,0-1,0c-0.4,0-0.7,0.1-1,0.1
		c0.5-0.5,1-1,1.4-1.5c0.5-0.5,0.9-1.1,1.2-1.6c0.4-0.5,0.6-1.1,0.9-1.6c0.2-0.5,0.3-1,0.3-1.5c0-0.6-0.1-1.1-0.3-1.6
		c-0.2-0.5-0.5-0.9-0.8-1.2s-0.8-0.6-1.3-0.8s-1-0.3-1.6-0.3c-0.9,0-1.6,0.2-2.2,0.5c-0.6,0.3-1.2,0.8-1.8,1.4l1.5,1.5
		C58.7,98,59,97.7,59.3,97.4z"
      />
      <path
        className="st1"
        d="M63,137.4L63,137.4c0.6-0.4,1.1-0.7,1.5-1.1c0.3-0.4,0.5-1,0.5-1.6c0-0.5-0.1-1-0.3-1.4
		c-0.2-0.4-0.5-0.8-0.8-1c-0.4-0.3-0.8-0.5-1.3-0.6c-0.5-0.1-1.1-0.2-1.7-0.2c-0.7,0-1.4,0.1-2,0.4c-0.6,0.3-1.2,0.6-1.8,1.1
		l1.4,1.7c0.4-0.3,0.7-0.6,1.1-0.8c0.3-0.2,0.7-0.3,1.2-0.3c0.5,0,0.8,0.1,1.1,0.4c0.3,0.2,0.4,0.6,0.4,1c0,0.2,0,0.5-0.1,0.7
		s-0.3,0.4-0.5,0.5s-0.5,0.3-0.9,0.3c-0.4,0.1-0.8,0.1-1.4,0.1v2c0.7,0,1.2,0,1.6,0.1s0.7,0.2,1,0.3c0.2,0.1,0.4,0.3,0.5,0.5
		c0.1,0.2,0.1,0.5,0.1,0.7c0,0.5-0.2,0.8-0.5,1.1c-0.4,0.2-0.8,0.4-1.4,0.4c-0.5,0-1-0.1-1.4-0.3c-0.4-0.2-0.8-0.5-1.2-0.9l-1.3,1.8
		c0.4,0.5,1,0.9,1.7,1.2c0.7,0.3,1.5,0.5,2.5,0.5c0.6,0,1.2-0.1,1.7-0.2s1-0.4,1.4-0.7c0.4-0.3,0.7-0.7,1-1.1
		c0.2-0.4,0.4-0.9,0.4-1.5c0-0.8-0.2-1.4-0.7-1.9C64.3,138,63.7,137.6,63,137.4z"
      />
      <path
        className="st1"
        d="M153.4,136.7l-9.9-9.9c-0.3-0.3-0.8-0.5-1.2-0.5H69.6c0,0,0,0-0.1,0c-2.4-1.8-5.5-2.9-8.7-2.9
		c-8.2,0-14.8,6.6-14.8,14.8c0,8.2,6.6,14.8,14.8,14.8c3.6,0,6.9-1.3,9.5-3.4h72c0.5,0,0.9-0.2,1.2-0.5l9.9-9.9
		C154.1,138.5,154.1,137.4,153.4,136.7z M49.5,138.3c0-6.2,5.1-11.3,11.3-11.3s11.3,5.1,11.3,11.3s-5.1,11.3-11.3,11.3
		S49.5,144.5,49.5,138.3z M141.6,146.1H73.4c1.4-2.3,2.3-5,2.3-7.9c0-3.1-1-6.1-2.7-8.5h68.6l8.2,8.2L141.6,146.1z"
      />
    </g>
  </svg>
);
export default DeductionProrityNoData;
