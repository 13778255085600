import React from "react";
import DropDown from "../../dropDown";
import Media from "react-media";
import {
  PREVIOUS_MONTH,
  CURRENT_MONTH,
  CURRENT_MONTH_LABEL,
  PREVIOUS_MONTH_LABEL,
  SWITCH,
  SWITCH_LEFT,
  SWITCH_RIGHT,
  LAST_DAY,
  PAY_CHECK_DATE,
  PAY_CHECK_END_DATE,
  daySuffix
} from "./Constants";

const SemiMonthlyPayPeriodItems = props => {
  const { mapData } = props;
  return mapData.map((t, i) => {
    let monthDropDownData = [];
    let endMonthDropDownData = [];
    const dayCount = 31;
    for (let i = 1; i <= dayCount; i++) {
      let suffix = (i - 1) % 10 < 3 ? daySuffix[(i - 1) % 10] : "th";
      if (i > 10 && i < 20) {
        suffix = "th";
      }
      monthDropDownData.push({
        value: i,
        label: i + suffix
      });
      endMonthDropDownData.push({
        value: i,
        label: i + suffix
      });
    }
    monthDropDownData.push({
      value: 99,
      label: LAST_DAY
    });
    endMonthDropDownData.push({
      value: 99,
      label: LAST_DAY
    });
    return (
      <Media query="(min-width:768px)" key={t}>
        {matches =>
          matches ? (
            <div className="pay-period-Monthly" key={t}>
              <div
                style={{
                  fontWeight: "600",
                  marginBottom: "10px",
                  fontSize: "16px",
                  color: "#009966"
                }}
              >
                <span style={{ textTransform: "capitalize" }}>{t}</span> Payroll
                of the Month
              </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px"
                }}
              >
                <div>The paycheck date is on the</div>
                <div
                  className="drop-down-first"
                  style={{ fontWeight: "400", width: "105px" }}
                >
                  <DropDown
                    placeholder=""
                    options={monthDropDownData}
                    value={props.data[t + PAY_CHECK_DATE]}
                    onSelect={val =>
                      props.handleChangeMonth(t + PAY_CHECK_DATE, val)
                    }
                    id="drop-down-first"
                  />
                </div>
                <div className="" style={{ padding: "0px 5px" }}>
                  of the month
                </div>
              </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <div>The pay period end date is on the</div>
                <div
                  className="drop-down-sec"
                  style={{ fontWeight: "400", width: "105px" }}
                >
                  <DropDown
                    placeholder=""
                    options={endMonthDropDownData}
                    value={props.data[t + PAY_CHECK_END_DATE]}
                    onSelect={val =>
                      props.handleChangeMonth(t + PAY_CHECK_END_DATE, val)
                    }
                    id="drop-down-second"
                  />
                </div>
                <div>of the</div>
                {t + SWITCH === "secondSwitch" &&
                props.data["firstSwitch"] === "2" ? (
                  <div
                    className="switch-field select-month"
                    style={{ background: "transparent", border: "none" }}
                  >
                    <input
                      type="radio"
                      id={t + SWITCH_RIGHT}
                      name={t + SWITCH}
                      value={CURRENT_MONTH}
                      checked={props.data[t + SWITCH] === CURRENT_MONTH}
                      onChange={props.handleRadioChange}
                    />
                    <label htmlFor={t + SWITCH_RIGHT}>
                      {CURRENT_MONTH_LABEL}
                    </label>
                  </div>
                ) : (
                  <div className="switch-field select-month">
                    <input
                      type="radio"
                      id={t + SWITCH_LEFT}
                      name={t + SWITCH}
                      value={PREVIOUS_MONTH}
                      checked={props.data[t + SWITCH] === PREVIOUS_MONTH}
                      onChange={props.handleRadioChange}
                    />
                    <label htmlFor={t + SWITCH_LEFT}>
                      {PREVIOUS_MONTH_LABEL}
                    </label>

                    <input
                      type="radio"
                      id={t + SWITCH_RIGHT}
                      name={t + SWITCH}
                      value={CURRENT_MONTH}
                      checked={props.data[t + SWITCH] === CURRENT_MONTH}
                      onChange={props.handleRadioChange}
                    />
                    <label htmlFor={t + SWITCH_RIGHT}>
                      {CURRENT_MONTH_LABEL}
                    </label>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="pay-period-Monthly" key={t}>
              <div
                style={{
                  fontWeight: "600",
                  marginBottom: "10px",
                  fontSize: "16px",
                  color: "#009966"
                }}
              >
                <span style={{ textTransform: "capitalize" }}>{t}</span> Payroll
                of the Month
              </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px"
                }}
              >
                <div className="">The paycheck date is on the</div>
                <div className="drop-down-first" style={{ fontWeight: "400" }}>
                  <DropDown
                    placeholder=""
                    options={monthDropDownData}
                    value={props.data[t + PAY_CHECK_DATE]}
                    onSelect={val =>
                      props.handleChangeMonth(t + PAY_CHECK_DATE, val)
                    }
                    id="drop-down-first"
                  />
                </div>
                <div className="" style={{ padding: "0px 5px" }}>
                  of the month
                </div>
              </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px"
                }}
              >
                <div>The pay period end date is on the</div>
                <div className="drop-down-sec" style={{ fontWeight: "400" }}>
                  <DropDown
                    placeholder=""
                    options={endMonthDropDownData}
                    value={props.data[t + PAY_CHECK_END_DATE]}
                    onSelect={val =>
                      props.handleChangeMonth(t + PAY_CHECK_END_DATE, val)
                    }
                    id="drop-down-second"
                  />
                </div>
              </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <div>of the</div>

                <div className="switch-field select-month">
                  <input
                    type="radio"
                    id={t + SWITCH_LEFT}
                    name={t + SWITCH}
                    value={PREVIOUS_MONTH}
                    checked={props.data[t + SWITCH] === PREVIOUS_MONTH}
                    onChange={props.handleRadioChange}
                  />
                  <label htmlFor={t + SWITCH_LEFT}>
                    {PREVIOUS_MONTH_LABEL}
                  </label>
                  <input
                    type="radio"
                    id={t + SWITCH_RIGHT}
                    name={t + SWITCH}
                    value={CURRENT_MONTH}
                    checked={props.data[t + SWITCH] === CURRENT_MONTH}
                    onChange={props.handleRadioChange}
                  />
                  <label htmlFor={t + SWITCH_RIGHT}>
                    {CURRENT_MONTH_LABEL}
                  </label>
                </div>
              </div>{" "}
            </div>
          )
        }
      </Media>
    );
  });
};

export default SemiMonthlyPayPeriodItems;
