import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export default class NotificationModal extends Component {
  render() {
    const {
      show,
      closeModal,
      companyName,
      jobTypeName,
      error,
      close
    } = this.props;
    let modalMessage = "";
    if (error === "NO_MENU_ACCESS") {
      modalMessage = `You do not have permission to view ${jobTypeName} on ${companyName}. You will be redirected to the Netchex Dashboard.`;
    } else if (error === "NO_SUBMIT_ACCESS") {
      modalMessage = `You do not have permission to submit a new job for ${companyName}.`;
    } else {
      modalMessage = `You do not have permission to ${companyName}.`;
    }
    return (
      <Modal
        id="earning-cannotdelete-modal"
        bsClass="list-page-cancel modal"
        animation={false}
        backdrop={"static"}
        keyboard={false}
        show={show}
        onHide={close}
      >
        <Modal.Header>
          <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <i
              id="warning-modal-close-icon"
              onClick={close}
              className="fa fa-lg fa-times-circle-o"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-row text-center">
            <div id="warning-modal-line-1">{modalMessage}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <button
              onClick={closeModal}
              type="button"
              id="access-not-allowed-warning-modal"
              className="nc-button secondary-button"
            >
              OK
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
