export function getDeepLinkObject(deepLinkItem, parameter) {
  let item = {
    ParentName: "",
    Route: ""
  };
  if (deepLinkItem === 1) {
    item = {
      HeaderName: "Notifications",
      Route: "notifications"
    };
  }
  if (deepLinkItem === 2) {
    item = {
      HeaderName: "Notifications",
      Route: "view-job-history/" + parameter
    };
  }
  return item;
}
