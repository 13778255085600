import axios from "axios";
import * as types from "./index";
import * as common from "../../common/index";

export function getUnreadNotificationCount(data = []) {
  return {
    type: types.FETCH_UNREAD_NOTIFICATION_COUNT,
    payload: data
  };
}

export function setUnreadNotificationCount(data = 0) {
  return {
    type: types.FETCH_UNREAD_NOTIFICATION_COUNT,
    payload: data
  };
}

export function resetUnreadNotificationCount(data = []) {
  return {
    type: types.FETCH_UNREAD_NOTIFICATION_COUNT,
    payload: []
  };
}

// export function saveNotificationSettings(data){
//     return {
//         type : types.,
//         payload : data
//     };
// }

// Fetch the list of notifications displayed when the user clicks the bell icon
export function getNotificationList() {
  // const url=`${common.WEB_API_URL}/v1/app/ui/notificationsection`;
  // const url=`${common.WEB_API_MOCK_URL}/v1/app/ui/jobstatusnotification?filter=all&from=1&to=6&_mock_=success&datasize=6&userId=2`;
  const url = `${
    common.WEB_API_URL
    }/v1/app/ui/jobstatusnotification?filter=unread`;
  const request = axios.get(url);
  return {
    type: types.FETCH_NOTIFICATION_LIST,
    payload: request
  };
}
export function clearNotificationList() {
  return {
    type: types.CLEAR_NOTIFICATION_LIST
  };
}
