import React, { Component } from "react";
import NotificationDetailsContent from "../NotificationsFilters/NotificationDetailsContent";
import { ProgressTracker } from "../progress-tracker";
import { STATUS_COMPLETED_WITH_EXCEPTION } from "../Constants";

class ViewFullJobHistory extends Component {
  state = {
    exceptionList: [],
    exception: false
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      const { data = {} } = this.props;
      const { JobTaskList = [] } = data;

      let exceptionList = JobTaskList.filter(
        item => item.CurrentJobTaskStatusId === STATUS_COMPLETED_WITH_EXCEPTION
      );
      if (exceptionList.length > 0) {
        exceptionList.forEach(item => {
          if (!item.IsJobTaskResubmitted) {
            this.setState({
              isExceptionSubmitted: true
            });
          } else {
            this.setState({
              isExceptionSubmitted: false
            });
          }
        });
        this.setState({
          exceptionList,
          exception: true
        });
      } else {
        this.setState({
          exception: false,
          exceptionList,
          isExceptionSubmitted: false
        });
      }
    }
  }

  render() {
    const {
      data,
      onClickCompanyLink,
      userTimeZoneOffset,
      convertUserTimeZone,
      userTimeZoneAbbreviation,
      onClickCancelRequest,
      resubmitJobData = {},
      onClickSubmitNewJob,
      onChange
    } = this.props;
    let jobTypeName = "";
    if (data.JobTypeName === "CopyEarning") {
      jobTypeName = "Earnings";
    } else if (data.JobTypeName === "CopyDeduction") {
      jobTypeName = "Deductions";
    }
    return (
      <div className="row col-lg-12 col-md-12 nc-job-history-container">
        <div className="col-lg-4 col-md-4">
          <ProgressTracker
            data={data}
            userTimeZoneOffset={userTimeZoneOffset}
            convertUserTimeZone={convertUserTimeZone}
            userTimeZoneAbbreviation={userTimeZoneAbbreviation}
            onClickCancelRequest={onClickCancelRequest}
          />
        </div>
        <div className="col-lg-8 col-md-8">
          <div className="nc-display-toggle-exception-content">
            <NotificationDetailsContent
              jobDetails={data.JobDescription}
              showTitle={true}
              jobTypeName={jobTypeName}
              notification={data}
              viewFullJobHistory={true}
              exception={this.state.exception}
              resubmitJobData={resubmitJobData}
              exceptionList={this.state.exceptionList}
              onClickCompanyLink={onClickCompanyLink}
              isExceptionSubmitted={this.state.isExceptionSubmitted}
              onClickSubmitNewJob={onClickSubmitNewJob}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ViewFullJobHistory;
