import React, { Component } from "react";
import { connect } from "react-redux";
import { ListPageToaster } from "component-library/earnings-list";
import { clearToast } from "app/common/reducers/toast";

class ToasterContainer extends Component {
  render() {
    return <ListPageToaster {...this.props} />;
  }
}

const stateToProps = state => state.common.toast;

const actionCreators = {
  clearToast
};

export default connect(stateToProps, actionCreators)(ToasterContainer);
