import { connect } from "react-redux";
import EmergencyContact from "../containers/EmergencyContact";
import {
  updateEmployeeEmergencyData,
  fetchAndRelationShip,
  fetchAndSetEmergencyStates
} from "../network/actions";

const stateToProps = state => ({
  data: state.people.employeeDashboard,
  EmergencyContactData: state.personalInfo.emergencyContactData
});
const actionCreators = {
  updateEmployeeEmergencyData,
  fetchAndRelationShip,
  fetchAndSetEmergencyStates
};
export default connect(stateToProps, actionCreators)(EmergencyContact);
