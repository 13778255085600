import React from "react";
import { FormFieldGroup, FormSection } from "../../../formSections";
import * as Fields from "./FieldNames";
const labelMaker = (noLabel, allLabel, someLabel, oneLabel) => (o, s, all) =>
  all
    ? allLabel
    : Object.keys(s).filter(k => s[k]).length === 0
      ? noLabel
      : Object.keys(s).filter(k => s[k]).length === 1
        ? oneLabel
        : someLabel;

const Workplace = ({
  onChange,
  data,
  options = {},
  makeOnSelect,
  makeOnSelectAll,
  selectedOptions
}) => {
  return (
    <FormSection>
      <div className="column-title">Workplace Info</div>

      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.employeeCompany}
        value={data[Fields.employeeCompany]}
        options={options[Fields.employeeCompany]}
        label="Company"
        Size={[12, 12, 7, 12]}
        onChange={makeOnSelect(Fields.employeeCompany)}
        selectAll={makeOnSelectAll(Fields.employeeCompany)}
        selectedItems={selectedOptions[Fields.employeeCompany]}
        multiSelect
        makeLabel={labelMaker("Company", "Company", "Company", "Company")}
      />
      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.employeeDivision}
        value={data[Fields.employeeDivision]}
        options={options[Fields.employeeDivision]}
        label="Division"
        Size={[12, 12, 7, 12]}
        onChange={makeOnSelect(Fields.employeeDivision)}
        selectAll={makeOnSelectAll(Fields.employeeDivision)}
        selectedItems={selectedOptions[Fields.employeeDivision]}
        multiSelect
        makeLabel={labelMaker("Division", "Division", "Division", "Division")}
      />
      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.businessUnit}
        value={data[Fields.businessUnit]}
        options={options[Fields.businessUnit]}
        label="Bussiness Unit"
        Size={[12, 12, 7, 12]}
        onChange={makeOnSelect(Fields.businessUnit)}
        selectAll={makeOnSelectAll(Fields.businessUnit)}
        selectedItems={selectedOptions[Fields.businessUnit]}
        multiSelect
        makeLabel={labelMaker(
          "Bussiness Unit",
          "Bussiness Unit",
          "Bussiness Unit",
          "Bussiness Unit"
        )}
      />
      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.employeeDepartment}
        value={data[Fields.employeeDepartment]}
        options={options[Fields.employeeDepartment]}
        label="Department"
        Size={[12, 12, 7, 12]}
        onChange={makeOnSelect(Fields.employeeDepartment)}
        selectAll={makeOnSelectAll(Fields.employeeDepartment)}
        selectedItems={selectedOptions[Fields.employeeDepartment]}
        multiSelect
        makeLabel={labelMaker(
          "Department",
          "Department",
          "Department",
          "Department"
        )}
      />
      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.employeeLocation}
        value={data[Fields.employeeLocation]}
        options={options[Fields.employeeLocation]}
        label="Location"
        Size={[12, 12, 7, 12]}
        onChange={makeOnSelect(Fields.employeeLocation)}
        selectAll={makeOnSelectAll(Fields.employeeLocation)}
        selectedItems={selectedOptions[Fields.employeeLocation]}
        multiSelect
        makeLabel={labelMaker("Location", "Location", "Location", "Location")}
      />
      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.employeeClient}
        value={data[Fields.employeeClient]}
        options={options[Fields.employeeClient]}
        label="Client"
        Size={[12, 12, 7, 12]}
        onChange={makeOnSelect(Fields.employeeClient)}
        selectAll={makeOnSelectAll(Fields.employeeClient)}
        selectedItems={selectedOptions[Fields.employeeClient]}
        multiSelect
        makeLabel={labelMaker("Client", "Client", "Client", "Client")}
      />
      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.employeeProject}
        value={data[Fields.employeeProject]}
        options={options[Fields.employeeProject]}
        label="Project"
        Size={[12, 12, 7, 12]}
        onChange={makeOnSelect(Fields.employeeProject)}
        selectAll={makeOnSelectAll(Fields.employeeProject)}
        selectedItems={selectedOptions[Fields.employeeProject]}
        multiSelect
        makeLabel={labelMaker("Project", "Project", "Project", "Project")}
      />
    </FormSection>
  );
};

export default Workplace;
Workplace.defaultProps = {
  onChange: () => {},
  data: {},
  options: {}
};
