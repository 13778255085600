import React, { Component } from "react";
import PropTypes from "prop-types";
import EntityListTableHeader from "../../entity-list/EntityListTableHeader";
import {
  EmploymentHistoryList,
  LicensesCertificationList,
  EducationList,
  SkillsList
} from "./Components";
import * as FIELDS from "component-library/employee-profile/resume/utils/Constants";

class ResumeEntityList extends Component {
  render() {
    const {
      tabData = {},
      onEditClick,
      onDeleteClick,
      editSortOrder,
      loadingFirstTime,
      sortOrder,
      id,
      setToaster,
      toggleToaster,
      employeeName
    } = this.props;
    var headerColumn = this.props.headerColumn
      ? this.props.headerColumn
      : "EmploymentHistory";
    return (
      <div className="tab-container-list clearfix">
        <EntityListTableHeader
          editSortOrder={editSortOrder}
          loadingFirstTime={loadingFirstTime}
          sortOrder={sortOrder}
          headerColumn={headerColumn}
          size={[12, 12, 12, 12]}
        />
        {tabData.map((item, i) => {
          return (
            <div
              key={i}
              className="table-row col-lg-12 col-md-12 col-sm-12 col-xs-12"
            >
              {id === FIELDS.EMPLOYMENT_HISTORY_ID && (
                <EmploymentHistoryList
                  item={item}
                  onDeleteClick={onDeleteClick}
                  onEditClick={onEditClick}
                  setToaster={setToaster}
                />
              )}
              {id === FIELDS.LICENSES_CERTIFICATION_ID && (
                <LicensesCertificationList
                  tabData={tabData}
                  item={item}
                  onDeleteClick={onDeleteClick}
                  onEditClick={onEditClick}
                  setToaster={setToaster}
                  toggleToaster={toggleToaster}
                  employeeName={employeeName}
                />
              )}

              {id === FIELDS.EDUCATION_ID && (
                <EducationList
                  item={item}
                  onDeleteClick={onDeleteClick}
                  onEditClick={onEditClick}
                  setToaster={setToaster}
                />
              )}

              {id === FIELDS.SKILLS_ID && (
                <SkillsList
                  item={item}
                  onDeleteClick={onDeleteClick}
                  onEditClick={onEditClick}
                  setToaster={setToaster}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default ResumeEntityList;

ResumeEntityList.propTypes = {
  activePanel: PropTypes.object,
  editClick: PropTypes.func,
  deleteClick: PropTypes.func,
  editSortOrder: PropTypes.func.isRequired,
  sortOrder: PropTypes.object,
  headerColumn: PropTypes.string,
  loadingFirstTime: PropTypes.bool.isRequired
};

ResumeEntityList.defaultProps = {
  activePanel: {
    SectionItemId: 1,
    SectionItemName: "Section Item Name",
    SectionItemLink1: "Employeement History",
    SectionItemLink2: "Files",
    SectionItemLink3: ""
  },
  editClick: () => {},
  deleteClick: () => {},
  loadingFirstTime: false,
  editSortOrder: () => alert("Specify the function"),
  sortOrder: {},
  headerColumn: "EmploymentHistory"
};
