import React from "react";
import { Modal } from "react-bootstrap";
import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";
const EmployeeProfileModal = props => (
  <Modal
    id="cancel-modal"
    bsClass="entity-page-cancel-modal modal"
    animation={false}
    backdrop={"static"}
    keyboard={false}
    show={props.show}
    onHide={props.onClose}
    restoreFocus={false}
  >
    <ModalHeader onClose={props.onClose} />
    <ModalBody {...props} />
    <ModalFooter
      onSaveChanges={props.onSaveChanges}
      onClose={props.onClose}
      onDelete={props.onDelete}
      deleteLabel={props.deleteLabel}
      leaveThisPage={props.leaveThisPage}
      {...props}
    />
  </Modal>
);

export default EmployeeProfileModal;
