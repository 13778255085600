export const Fields = {
  stepID: "UIDraftCompletionStage",
  stepName: "UIDraftCompletionStageName",
  inUse: "IsUsedInPayroll",
  assignedToEmployee: "IsAssignedToEmployee",
  prevData: "PrevData",
  userRole: "UserRole",
  name: "SystemEarningName",
  SystemEarningCode: "SystemEarningCode",
  categoryId: "EarningCategoryId",
  categoryName: "EarningCategoryName",
  subcategoryId: "EarningSubCategoryId",
  subcategoryName: "EarningSubCategoryName",
  paidToEmployee: "PaidToEmployee",
  earningsTaxable: "Taxability",
  earningsTrack: "TrackingType",
  disabilityPaymentDirectlyByState: "DisabilityPaymentDirectlyByState",
  earningsChangeReason: "ReasonForChange",
  taxesModified: "TaxesModfied",
  //Wages
  increaseFederalWages: "IncreaseFederalWages",
  increaseSocialSecurityWages: "IncreaseSocialSecurityWages",
  increaseMedicareWages: "IncreaseMedicareWages",
  increaseStateWages: "IncreaseStateWages",
  increaseLocalWages: "IncreaseLocalWages",
  increaseSUTAWages: "IncreaseSUTAWages",
  increaseFUTAWages: "IncreaseFUTAWages",
  increaseSDIWages: "IncreaseSDIWages",
  excludeFromFederalTax: "ExcludeFromFederalTaxWitholdingCalculation",
  excludeFromStateTax: "ExcludeFromStateTaxWitholdingCalculation",
  excludeFromLocalTax: "ExcludeFromLocalTaxWitholdingCalculation",

  // Calculation Page

  calculationMethodId: "EarningCalculationBasisId",
  calculationMethodName: "EarningCalculationBasisName",
  isPremium: "IsPremium", // not included in service --Local State
  premiumType: "PremiumType",
  premiumFactor: "PremiumFactorValue",

  isPercentPremiumType: "IsPercentPremiumType",
  isDollarPremiumType: "IsDollarPremiumType",
  percentPremiumAmount: "PercentPremiumAmount",
  dollarPremiumAmount: "DollarPremiumAmount",
  hourlyPay: "IsHourlyPay",
  hourlyPayImported: "HourlyPayRate",
  customRate: "CompanyEarningHourlyRateId",
  customRateName: "CompanyEarningHourlyRateLabel",

  pieceRate: "PieceRateAmount",
  overtimeType: "IsOverOrDoubleTime", // not included in service --Local State
  overtimeFactor: "OvertimeFactor",
  rateName: "CompanyEarningHourlyRateName",
  //PayCycle
  earningCycleName: "EarningCycleName",
  earningCycleId: "EarningCycleId",
  earningSubcycleId: "EarningSubCycleId",
  earningSubcycleName: "EarningSubCycleName",
  payrollFrequency: "EarningPayrollSchedule",
  firstSchedulePayroll: "IncludeInFirstScheduledPayroll",
  secondSchedulePayroll: "IncludeInSecondScheduledPayroll",
  thirdSchedulePayroll: "IncludeInThirdScheduledPayroll",
  fourthSchedulePayroll: "IncludeInFourthScheduledPayroll",
  fifthSchedulePayroll: "IncludeInFifthScheduledPayroll",

  comp_name: "CompanyEarningName",
  companyEarningsCode: "CompanyEarningCode",
  comp_Id: "CompanyEarningId",
  subscriber: "SubscriberCompanyId",
  hasW2BoxCode: "W2BoxCode", //Only for UI
  w2Boxcode: "W2Box14ShortDescription",
  //Tipped Questions
  companyPayTipped: "CompanyPayTipped", //Only for UI
  includeInTipShortfall: "IncludeInTipShortfallCalculations",
  overtimePaidTipped: "OvertimePaidExclusivelyToTippedEmployees",
  //Accruals/Attendance
  includeInAccrue: "IncludeInAccrueEligibleHoursWorkedPlanCalculations",
  includeInTimeOff: "IncludeInRequestedTimeOff",
  includeIfAccrualPlanAssigned: "IncludeInTimeOffRequestWithAccrualPlan_Ind",
  //Import Options
  hasImportCode: "HasImportCode",
  referenceCodeImport: "ReferenceCodeInImportFile",
  //Time and Attendance
  includeInTimeAttendance: "IncludeInTimeAndAttendance", // Only for UI
  canChangeIncludeInTimeAttendance: "canChangeIncludeInTimeAttendance",
  subtractHoursPerPayPeriod: "SubtractEarningFromNormalHoursPerPayPeriod",
  countsTowardsOvertime: "EarningCountsTowardsOvertime",
  usedInAutoTimesheet: "UsedInAutoTimesheet",
  payrollGroup: "UsedInPayrollGroupZone",
  deptTransfer: "UsedInDepartmentZone",
  clientProjectTransfer: "TranferInClientOrProject",

  includeInRegularRate: "IncludeInRegularRateOfPayCalculations",
  regularRateOfPay: "EarningPaidAtRegularRateOfPay",
  overtimeWeighted: "IsOvertimeWeighted",
  earningGroups: "EarningGroupIdList",

  isProductiveHours: "IsProductiveHours",
  workersCompensation: "IncludeInWorkersCompensation",
  optionsNote: "ReasonForChange",
  optionsModified: "OptionsModified",
  advancedTaxOptions: "advancedTaxOptions"
};
