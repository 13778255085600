import React from "react";
import PayrollChecklist from "component-library/payroll-dashboard/payroll-checklist";
import Content from "./Content";
import Media from "react-media";
const PayrollChecklistDemo = props => {
  return (
    <PayrollChecklist
      {...props}
      redirectToChecklist={() => props.history.push("/PayrollChecklistConfig")}
    >
      {props.activeTab ? (
        <Media query="(min-width:768px)">
          <Content
            {...props}
            redirectToChecklist={() =>
              props.history.push("/PayrollChecklistConfig")
            }
          />
        </Media>
      ) : null}
    </PayrollChecklist>
  );
};

export default PayrollChecklistDemo;
