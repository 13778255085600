import * as types from "../../../EarningsGroup/actions/companylevel/index";
export default function(state = [], action) {
  switch (action.type) {
    case types.EARNINGS_LISTING_BY_EARNING_ID_LIST: {
      return action.payload.data;
    }
    default:
      return state;
  }
}
