import React from "react";
import getFormSection, {
  PRIMARY_FORM_SECTION,
  SECONDARY_FORM_SECTION
} from "./getFormSection";
import FormTitleWithIcon from "./FormTitleWithIcon";

const FormSection = props => {
  let className = getFormSection(
    props.title ? SECONDARY_FORM_SECTION : PRIMARY_FORM_SECTION
  ); // this methods gives the classname
  return (
    <div className={className + " clearfix"}>
      {props.hr && (
        <div className="seperator">
          <hr />
        </div>
      )}
      {props.title &&
        (props.link ? (
          <FormTitleWithIcon {...props} />
        ) : (
          props.title && <div className="title">{props.title}</div>
        ))}
      <div className="content" style={props.style}>
        {props.children}
      </div>
      {props.bottomHr && (
        <div className="seperator">
          <hr />
        </div>
      )}
    </div>
  );
};
export default FormSection;
