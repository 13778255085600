import React from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import * as Fields from "./../FieldNames";
import { INACTIVATE_BENEFITS } from "../Tooltips";

const Benefits = ({ data = {}, onChange }) => {
  return (
    <FormSection title={"Benefits"} hr>
      <FormFieldGroup
        inputType="radio"
        inputName={Fields.inactivateBenefitsPlans}
        label="Do you want to inactivate the employee's benefits plans ?"
        fieldType="inline"
        value={data[Fields.inactivateBenefitsPlans]}
        radios={[
          { id: 1, value: true, label: "Yes" },
          { id: 2, value: false, label: "No" }
        ]}
        class="col-lg-12"
        colSpan="col-lg-3 col-md-3"
        Size={[6, 4, 12, 12]}
        onChange={onChange}
        tooltip={INACTIVATE_BENEFITS}
      />
      {data[Fields.inactivateBenefitsPlans] === true && (
        <FormFieldGroup
          inputType="singleDatePicker"
          label="Benefit Inctivation Date"
          fieldType="inline"
          inputName={Fields.benefitInactivationDate}
          value={data[Fields.benefitInactivationDate]}
          colSpan="col-lg-3 col-md-3"
          Size={[4, 4, 12, 12]}
          onChange={onChange}
          style={{ marginBottom: "15px" }}
          getToFromValue={onChange}
          showNoDateSection
        />
      )}
    </FormSection>
  );
};

export default Benefits;
