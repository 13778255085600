import React, { Component } from "react";
import PropTypes from "prop-types";
import NetchexButton from "../buttons/NetchexButton";
import DateRangePicker from "../daterangepicker";
import DropDown from "../dropDown";
import SaveDetailButton from "./SaveDetailButton";
import { FieldTypes } from "./constants";

export default class EditJobDetail extends Component {
  state = {
    currentValue: null
  };

  renderInput = () => {
    const { type, value, options, onSelectOption } = this.props;
    switch (type) {
      case FieldTypes.DATE:
        return (
          <DateRangePicker
            getFromToValue={(name, fromToValue) => {}}
            onHide={() => {}}
          />
        );
      case FieldTypes.OPTION:
        return (
          <DropDown
            placeholder={this.state.currentValue || value}
            options={options}
            showSearch
            onSelect={onSelectOption}
            onHide={() => {}}
            selectedValue={value}
          />
        );
      case FieldTypes.NOTES:
        return (
          <textarea
            className="form-control"
            value={this.state.currentValue || value}
            onChange={e => this.setState({ currentValue: e.target.value })}
          />
        );
      case FieldTypes.TEXT:
      default:
        return (
          <input
            type="text"
            className="form-control"
            value={this.state.currentValue || value}
            onChange={e => this.setState({ currentValue: e.target.value })}
          />
        );
    }
  };

  render() {
    const { onCancelClick, show, type, onSaveClick, value } = this.props;
    if (!show) {
      return null;
    }
    return (
      <div
        className="tr col-sm-12"
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 12,
          paddingRight: 12,
          borderTop: "1px solid rgb(204, 204, 204)"
        }}
      >
        <div
          className={`${
            type === "Notes" ? "col-lg-12 col-md-12" : "col-lg-4 col-md-4"
          } col-sm-12 col-xs-12`}
          style={{ marginBottom: 16, marginTop: 16 }}
        >
          {this.renderInput()}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <SaveDetailButton
            disabled={false}
            onClick={() => onSaveClick(this.state.currentValue || value)}
          />
          <NetchexButton
            onClick={onCancelClick}
            className="nc-button outline-button"
            type="secondary-link"
            label="Cancel"
            faIcon=""
          />
        </div>
      </div>
    );
  }
}

EditJobDetail.propTypes = {
  type: PropTypes.oneOf(Object.keys(FieldTypes).map(k => FieldTypes[k])),
  value: PropTypes.any,
  show: PropTypes.bool,
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  )
};
