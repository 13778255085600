import React, { Component } from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import { notes } from "./Constants";

class AddNewNote extends Component {
  renderNotes = componentProps => {
    return Object.keys(componentProps).map(k => {
      return (
        <FormFieldGroup
          key={k}
          {...componentProps[k]}
          block
          colSpan="col-md-4"
          value={this.props.data[k]}
          onAdd={this.props.onAdd}
          onDateChange={this.props.handleDateChange}
          onChange={this.props.onChange}
        />
      );
    });
  };

  render() {
    return <FormSection>{this.renderNotes(notes)}</FormSection>;
  }
}

export default AddNewNote;
