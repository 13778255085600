/** Actions */
const SET_SORT_ORDER = "activity-history/SET_SORT_ORDER";
const CLEAR_SORT_ORDER = "activity-history/CLEAR_SORT_ORDER";

export const ORDER_ASC = "ASC";
export const ORDER_DESC = "DESC";

const initialState = {
  Field: null,
  Order: null
};

/** Reducer */
const sortOrder = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SORT_ORDER:
      return payload;
    case CLEAR_SORT_ORDER:
      return initialState;
    default:
      return state;
  }
};

export default sortOrder;

/** Action Creator */
export const setSortOrder = (Field, Order) => ({
  type: SET_SORT_ORDER,
  payload: { Field, Order }
});

export const toggleSortOrder = (Field, currentOrder) =>
  setSortOrder(Field, currentOrder === ORDER_ASC ? ORDER_DESC : ORDER_ASC);

export const clearSortOrder = () => ({
  type: CLEAR_SORT_ORDER
});

export const sortComparator = ({ Field, Order }) => {
  if (Order === ORDER_ASC) {
    return ({ [Field]: a }, { [Field]: b }) => {
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
      return 0;
    };
  }
  if (Order === ORDER_DESC) {
    return ({ [Field]: a }, { [Field]: b }) => {
      if (a.toLowerCase() < b.toLowerCase()) return 1;
      if (a.toLowerCase() > b.toLowerCase()) return -1;
      return 0;
    };
  }
};
