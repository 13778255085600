import React, { Component } from "react";
import EntityListFilters from "component-library/entity-list/EntityListFilters";
import { connect } from "react-redux";
import * as Filters from "component-library/employee-profile/list-view/advance-filter/FieldNames";
import * as FIELDS from "component-library/employee-profile/resume/utils/Constants";
class ListFilters extends Component {
  state = { data: {} };

  makeOnSelect = name => (option, selected) => {
    this.props.selectOption(name, option, selected);
  };

  makeOnSelectAll = name => selected => {
    this.props.selectAll(name, selected);
  };

  getAdvanceFiltersCount = () => {
    let filterCount = 0;
    const { selectedOptions = {}, advancedFilter = {} } = this.props;

    Object.keys(selectedOptions).forEach(item => {
      if (
        item !== Filters.employeeCompany &&
        item !== Filters.employeeStatus &&
        item !== Filters.basicFilterEmployeelist &&
        item !== FIELDS.EmploymentHistoryCompanyFilter &&
        item !== FIELDS.EmploymentHistoryPositionFilter &&
        item !== FIELDS.LicensesCertificationNameFilter &&
        item !== FIELDS.LicensesCertificationTypeFilter &&
        item !== FIELDS.EducationDegreeFilter &&
        item !== FIELDS.EducationSchoolFilter &&
        item !== FIELDS.SkillsSkillFilter &&
        item !== FIELDS.SkillsSkillLevelFilter &&
        item !== FIELDS.SkillsYearOfExperienceFilter
      ) {
        var value = selectedOptions[item];
        if (Object.keys(value).length > 0) {
          var count = Object.keys(value).filter(i => value[i] === true);
          filterCount = count.length > 0 ? filterCount + 1 : filterCount;
        }
      }
    });

    Object.keys(advancedFilter).forEach(item => {
      var count = 0;
      count = advancedFilter[item] !== "" ? count + 1 : count;
      filterCount = filterCount + count;
    });

    return filterCount;
  };

  render() {
    const {
      options,
      toggleAdvancedFilters,
      selectedOptions,
      resetFilters,
      showClearFilters,
      searchPosts
    } = this.props;
    return (
      <EntityListFilters
        page="EmployeeProfileList"
        options={options}
        selectedOptions={selectedOptions}
        toggleAdvancedFilters={toggleAdvancedFilters}
        showAdvancedLinks
        advancedFilterCount={this.getAdvanceFiltersCount()}
        makeOnSelect={this.makeOnSelect}
        makeSelectAll={this.makeOnSelectAll}
        clearAdvancedFilters={resetFilters}
        showClearFilters={showClearFilters}
        onClose={searchPosts}
        width={"65%"}
      />
    );
  }
}

export default connect()(ListFilters);
