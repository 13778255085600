import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TimeCardAlertsTable from "component-library/payroll-dashboard/multi-section-table/TimeCardAlertsTable";
import * as payrollConstants from "component-library/payroll-dashboard/PayrollDashboardConstants";
import { setPayrollActiveTab } from "../reducers/payrollTypeActiveTab";
import {
  loadAndSetMorePosts,
  fetchTimecardAlerts,
  loadMoreTimecardAlerts,
  approveTimecardAction,
  sendMessageToContactApprover,
  searchAndSetPostsTimecardAlerts
} from "../network/actions";
import {
  makeOptions,
  makeSelectedOptions,
  selectFilterOption,
  selectAllFilterOptions
} from "app/common/reducers/filters";
import ContactApproverModal from "component-library/payroll-dashboard/multi-section-table/ContactApproverModal";
import ContactAllApproverModal from "component-library/payroll-dashboard/multi-section-table/ContactAllApproverModal";
import ContactMissingInfoModal from "component-library/payroll-dashboard/multi-section-table/ContactMissingInfoModal";
import EmployeeInformationModal from "component-library/payroll-dashboard/timecard-alerts/EmployeeInformationModal";
import { setSortOrder, clearSortOrder } from "app/common/reducers/sortOrder";
class UnapprovedPtosTableContainer extends Component {
  state = {
    loadingMore: false,
    allSelected: false,
    modeOfOperation: payrollConstants.ADD_CONTACT_INFO,
    selectedEmployees: {},
    contactApproversModal: false,
    contactAllApproversModal: false,
    contactMissingInfoModal: false,
    showInfoModal: false
  };

  componentDidMount() {
    this.props.fetchTimecardAlerts(1, false);
  }

  componentWillUnmount() {
    this.props.clearSortOrder();
  }

  searchPostsTimecardAlerts = () => {
    const {
      searchAndSetPostsTimecardAlerts,
      searchParams,
      filters
    } = this.props;
    this.setState({
      loadingFirstTime: true
    });
    searchAndSetPostsTimecardAlerts(searchParams, "1", filters, () =>
      this.setState({ loadingFirstTime: false })
    );
  };

  changeSortOrder = (field, sortOrder) => {
    this.props.setSortOrder(field, sortOrder);
    this.props.fetchTimecardAlerts(1, false);
    setTimeout(
      function() {
        this.searchPostsTimecardAlerts();
      }.bind(this),
      10
    );
  };

  loadMorePosts = () => {
    const { loadAndSetMorePosts, nextUri } = this.props;
    if (nextUri !== "") {
      this.setState({
        loadingMore: true
      });
      loadAndSetMorePosts(nextUri, () => this.setState({ loadingMore: false }));
    }
  };

  makeOnSelect = name => (option, selected) => {
    this.props.selectOption(name, option, selected);
  };

  makeSelectAll = name => selected => {
    this.props.selectAll(name, selected);
  };

  onContactClick = EmployeeId => {
    let isManagerAssigned = true;
    let isManagerHasContact = true;

    if (isManagerAssigned && isManagerHasContact) {
      //Show Contact Approver
      this.setState({
        contactApproversModal: true
      });
    } else if (!isManagerAssigned) {
      this.setState({
        contactMissingInfoModal: true,
        modeOfOperation: payrollConstants.ASSIGN_MANAGER
      });
      //show Missing info popup stating that there is no contact person assigned.
    } else if (isManagerAssigned && !isManagerHasContact) {
      this.setState({
        contactMissingInfoModal: true,
        modeOfOperation: payrollConstants.ADD_CONTACT_INFO
      });
      //if Employee has manager assigned and the manager does not have contact information setup, show Missing Info Popup
    }
  };

  closeContactApproversModal = () => {
    this.setState({
      contactApproversModal: false
    });
  };

  onSendMessageClick = EmployeeId => {
    this.props.onSendMessageClick(EmployeeId);
  };

  onContactAllClick = () => {
    let isManagerAssigned = true;
    let isManagerHasContact = true;

    if (isManagerAssigned && isManagerHasContact) {
      //Show Contact Approver
      this.setState({
        contactAllApproversModal: true
      });
    } else if (!isManagerAssigned) {
      this.setState({
        contactMissingInfoModal: true,
        modeOfOperation: payrollConstants.ASSIGN_MANAGER
      });
      //show Missing info popup stating that there is no contact person assigned.
    } else if (isManagerAssigned && !isManagerHasContact) {
      this.setState({
        contactMissingInfoModal: true,
        modeOfOperation: payrollConstants.ADD_CONTACT_INFO
      });
      //if Employee has manager assigned and the manager does not have contact information setup, show Missing Info Popup
    }
  };

  closeContactAllApproversModal = () => {
    this.setState({
      contactAllApproversModal: false
    });
  };

  closeContactMissingInfoModal = () => {
    this.setState({
      contactMissingInfoModal: false
    });
  };

  toggleInfoModal = () => {
    this.setState({
      showInfoModal: !this.state.showInfoModal
    });
  };

  approveItem = item => {
    const { approveTimecardAction, UserTestId, data } = this.props;
    if (item.Id === 1) {
      approveTimecardAction(UserTestId, item.Name, data);
    }
  };

  render() {
    const missingContactInfoList = [
      {
        employeeName: "Summer Fiedler",
        missingItem: "email",
        primaryManager: "Susan Christopher",
        secondaryManager: ""
      },
      {
        employeeName: "Chris Michel",
        missingItem: "cellphone",
        primaryManager: "",
        secondaryManager: "Joe Walker's"
      }
    ];

    const missingApproverList = [
      // { employeeName: "Susan Chiristopher" },
      // { employeeName: "Patrik Ballard" }
    ];
    const { sortOrder, approve = () => {} } = this.props;
    return (
      <div>
        <TimeCardAlertsTable
          {...this.props}
          loadingMore={this.state.loadingMore}
          loadMorePosts={this.loadMorePosts}
          selectEmployee={this.selectEmployee}
          loadMoreTimecardAlerts={this.props.loadMoreTimecardAlerts}
          sendMessageToContactApprover={this.props.sendMessageToContactApprover}
          onSendMessageClick={this.onSendMessageClick}
          loadAndSetMorePosts={this.props.loadAndSetMorePosts}
          nextUri={this.props.nextUri}
          data={this.props.payrollTypeData.TimecardAlerts.Data}
          selectAllEmployees={this.selectAllEmployees}
          selectedEmployees={this.state.selectedEmployees}
          options={this.props.filterData.TimeCardAlerts}
          selectedOptions={this.props.selectedOptions.TimeCardAlerts}
          makeOnSelect={this.makeOnSelect}
          makeSelectAll={this.makeSelectAll}
          onContactClick={() =>
            this.props.onContactClick({
              source: payrollConstants.TIMECARD_ALERTS
            })
          }
          onContactAllClick={() =>
            this.props.onContactAllClick({
              source: payrollConstants.TIMECARD_ALERTS
            })
          }
          approve={approve}
          sortOrder={sortOrder}
          changeSortOrder={this.changeSortOrder}
          toggleInfoModal={this.toggleInfoModal}
          approveItem={this.approveItem}
        />

        <EmployeeInformationModal
          show={this.state.showInfoModal}
          toggleInfoModal={this.toggleInfoModal}
        />

        <ContactApproverModal
          source={payrollConstants.TIMECARD_ALERTS}
          contactApproversModal={this.state.contactApproversModal}
          onSendMessageClick={() => this.onSendMessageClick()}
          closeContactApproversModal={() => this.closeContactApproversModal()}
        />

        <ContactAllApproverModal
          source={payrollConstants.TIMECARD_ALERTS}
          contactAllApproversModal={this.state.contactAllApproversModal}
          onSendMessageClick={() => this.onSendMessageClick()}
          closeContactAllApproversModal={() =>
            this.closeContactAllApproversModal()
          }
          missingContactInfoList={missingContactInfoList}
          missingApproverList={missingApproverList}
        />

        <ContactMissingInfoModal
          contactMissingInfoModal={this.state.contactMissingInfoModal}
          modeOfOperation={this.state.modeOfOperation}
          closeContactMissingInfoModal={() =>
            this.closeContactMissingInfoModal()
          }
        />
      </div>
    );
  }
}

const stateToProps = state => ({
  payrollDashboardItems: state.payrollDashboard.payrollDashboardItems,
  payrollTypeData: state.payrollDashboard.payrollTypeData,
  // for table check boxes
  filterData: makeOptions(state.common.filters),
  selectedOptions: makeSelectedOptions(state.common.filters),
  sortOrder: state.common.sortOrder
});

const actionCreators = {
  fetchTimecardAlerts,
  loadMoreTimecardAlerts,
  approveTimecardAction,
  sendMessageToContactApprover,
  setPayrollActiveTab,
  loadAndSetMorePosts,
  // for table check boxes
  selectOption: selectFilterOption,
  selectAll: selectAllFilterOptions,
  setSortOrder,
  searchAndSetPostsTimecardAlerts,
  clearSortOrder
};

export default withRouter(
  connect(stateToProps, actionCreators)(UnapprovedPtosTableContainer)
);
