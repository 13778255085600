import React from "react";
import FrequencyItem from "./FrequencyItem";
import classNames from "classnames/bind";
import { Fields } from "../../payroll-holiday-calendar/AddCalendar/fieldNames";
const renderItem = (onChange, data = {}, name, isGroup = false, id) => item => {
  const { optionId, label } = item;
  return (
    <FrequencyItem
      key={optionId}
      id={id ? id + "-" + optionId : optionId}
      name={name}
      label={label}
      value={data[optionId]}
      onChange={() => {
        if (isGroup) {
          onChange({
            name,
            value: { ...data, [optionId]: !data[optionId] }
          });
        } else {
          onChange({
            name: optionId,
            value: !data[optionId]
          });
        }
      }}
    />
  );
};
const payrollFrequency = ({
  title,
  onChange,
  value,
  name,
  options = [],
  error,
  required,
  noHover,
  isGroup,
  onlyToOption,
  type,
  id
}) => {
  let baseClass = classNames(
    "payroll-frequency",
    "clearfix",
    noHover && "no-hover"
  );
  // const data = {
  //   1: false,
  //   2: false,
  //   3: false,
  //   4: false,
  //   5: false,
  //   6: false,
  //   7: false
  // };
  let ccData = {};
  let toData = {};

  const weekDays = [
    { optionId: 0, label: "Sun" },
    { optionId: 1, label: "Mon" },
    { optionId: 2, label: "Tue" },
    { optionId: 3, label: "Wed" },
    { optionId: 5, label: "Thu" },
    { optionId: 5, label: "Fri" },
    { optionId: 6, label: "Sat" }
  ];

  // if (name === Fields.includedDayOfWeeks) {
  //   value !== null &&
  //     value !== undefined &&
  //     value.forEach(element => {
  //       data[element] = true;
  //     });
  // }
  if (name.includes("EmailToCcBcc")) {
    value !== null &&
      value !== undefined &&
      value.forEach(element => {
        ccData = element;
      });
  }
  if (name.includes("EmailToCcBcc") && onlyToOption === true) {
    value !== null &&
      value !== undefined &&
      value.forEach(element => {
        toData = element;
      });
  }

  if (type === "weekDays") {
    options = weekDays;
  }
  return (
    <div>
      {title && (
        <div style={{ fontWeight: "600", marginBottom: "10px" }}>{title}</div>
      )}
      <ul className={baseClass}>
        {name === Fields.includedDayOfWeeks || type === "weekDays"
          ? options.map(renderItem(onChange, value, name, isGroup))
          : name.includes("EmailToCcBcc") &&
            (onlyToOption === undefined || onlyToOption === false)
            ? options.map(renderItem(onChange, ccData, name))
            : name.includes("EmailToCcBcc") && onlyToOption === true
              ? options.map(renderItem(onChange, toData, name))
              : options.map(renderItem(onChange, value, name))}
      </ul>

      {error && value && required && <div className="has-error">{error}</div>}
    </div>
  );
};

export default payrollFrequency;
