export const EDIT_ADDRESS_DATA = "EDIT_ADDRESS_DATA";
export const CLEAR_ADDRESS_DATA = "CLEAR_ADDRESS_DATA";

const addressVerificationData = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case EDIT_ADDRESS_DATA:
      const { name, values } = payload;
      return {
        ...state,
        [name]: values
      };
    case CLEAR_ADDRESS_DATA:
      return {};
    default:
      return state;
  }
};

export default addressVerificationData;

export const editAddressValues = (name, values) => ({
  type: EDIT_ADDRESS_DATA,
  payload: { name, values }
});

export const clearAddressValues = () => ({
  type: CLEAR_ADDRESS_DATA
});
