import React from "react";
const ModalHeader = props => (
  <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <i
      id="close-button-icon"
      onClick={props.onClose}
      className="fa fa-times-circle-o fa-lg"
    />
  </div>
);
export default ModalHeader;
