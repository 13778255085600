import React from "react";
import PreviewCalendarEditMode from "./PreviewCalendarEditMode";
import { PREVIEW_CALENDAR_TITLE } from "./Constants";

const PreviewPayrollCalendar = props => {
  const PreviewButtonSection = () => {
    return (
      <div
        className="previewButtonSection clearfix"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div className="">
          <img
            src={require("../images/Calendar.png")}
            alt="Preview Calendar"
            style={{ height: "100px" }}
          />
        </div>
        <div className="nc-button-group">
          <button
            className="nc-button secondary-button"
            type="button"
            style={{ fontSize: "15px", marginBottom: "0px" }}
            onClick={props.previewPayroll}
          >
            Preview<span className="hidden-xs">&nbsp;Calendar</span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="misc-container clearfix">
      <div className="title">{PREVIEW_CALENDAR_TITLE}</div>
      <div className="description" style={{ padding: "0 20%" }}>
        Based on your selection above, we can show you what your payroll
        calendar will look like for upcoming paycheck dates.
      </div>
      <PreviewCalendarEditMode {...props} />
      <PreviewButtonSection />
    </div>
  );
};

export default PreviewPayrollCalendar;
