import React, { Component } from "react";
import { FormFieldGroup } from "../../../formSections/index";
import {
  payrollCalender,
  CHANGES_TAKE_EFFECT,
  PAYROLL_SCHEDULE_TO_BEGIN
} from "../Constants";

class PayrollCalendarFormSection extends Component {
  state = {
    errors: {}
  };
  holidayCalenderItems = data =>
    data.map(d => ({
      id: d.Id,
      label: d.Name,
      value: d.Id
    }));

  render() {
    const { options, data } = this.props;
    if (data.edit) {
      payrollCalender.PayrollScheduleToBeginComponentProps.label = CHANGES_TAKE_EFFECT;
    }
    const mapData =
      options && options.holidayCalender ? options.holidayCalender : [];
    const { errors } = this.state;
    payrollCalender.ChooseHolidayCalendarComponentProps.radios =
      mapData.length > 0 ? this.holidayCalenderItems(mapData) : [];
    return (
      <div className="payrollCalendarFormSectionContainer">
        {Object.keys(payrollCalender).map((item, i) => {
          if (
            item === "ChooseHolidayCalendarComponentProps" &&
            data.ReduceSalaryAutomatically !== "1"
          ) {
            return null;
          } else {
            return (
              <FormFieldGroup
                key={item}
                {...payrollCalender[item]}
                value={
                  item !== PAYROLL_SCHEDULE_TO_BEGIN
                    ? parseInt(data[payrollCalender[item].inputName], 10)
                    : data[payrollCalender[item].inputName]
                }
                onChange={this.props.handleRadioChange}
                error={errors[payrollCalender[item].inputName]}
                contentWidth="62%"
              />
            );
          }
        })}
      </div>
    );
  }
}
export default PayrollCalendarFormSection;
