import React from "react";
import ColumnTitle, {
  ORDER_ASC,
  ORDER_DESC
} from "../../activity-history/ActivityHistoryColumnTitle";

const newSortOrder = (Field, Order, changeSortOrder) => {
  if ("Employee" !== Field) {
    changeSortOrder("Employee", ORDER_ASC);
  } else {
    changeSortOrder(
      "Employee",
      Order === ORDER_ASC ? ORDER_DESC : Order === ORDER_DESC ? ORDER_ASC : ""
    );
  }
};

const MultiSectionListHeader = ({
  sortOrder: { Field, Order } = {},
  changeSortOrder
}) => (
  <div className="row table-header visible-lg visible-md">
    <ColumnTitle
      label="Employee"
      className="col-lg-9 col-md-9 user-column-header"
      order={"Employee" === Field && Order}
      onClick={() => newSortOrder(Field, Order, changeSortOrder)}
    />
  </div>
);

export default MultiSectionListHeader;
