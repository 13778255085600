import axios from "axios";
import * as common from "app/common/index";
const BASE_URL = common.WEB_API_URL;
const config = { headers: { "Content-Type": "application/json" } };

export const getPayrollSchedule = () =>
  axios.get(BASE_URL + `/v1/subscriber/company/payrollschedule`);

export const getHolidayCalender = () =>
  axios.get(common.WEB_API_MOCK_URL + `/v1/subscriber/company/holidaycalendar`);

export const getWeekdays = () =>
  axios.get(common.WEB_API_MOCK_URL + `/v1/system/weekdays`);

export const getDirectDepositOptions = () =>
  axios.get(
    common.WEB_API_MOCK_URL + `/v1/system/depositpaymentoptionforholiday`
  );

export const getPayrollScheduleById = payrollscheduleId =>
  axios.get(
    BASE_URL + `/v1/subscriber/company/payrollschedule/${payrollscheduleId}`
  );

export const deletePayrollSchedule = payrollscheduleId =>
  axios.delete(
    BASE_URL + `/v1/subscriber/company/payrollschedule/${payrollscheduleId}`
  );

export const setPayrollScheduleActive = payrollscheduleId =>
  axios.post(
    common.WEB_API_MOCK_URL +
      `/v1/subscriber/company/payrollschedule/action/makeactive?_mock_=S`,
    JSON.stringify({ id: payrollscheduleId })
  );

export const setPayrollScheduleInactive = payrollscheduleId =>
  axios.post(
    common.WEB_API_MOCK_URL +
      `/v1/subscriber/company/payrollschedule/action/makeinactive?_mock_=S`,
    JSON.stringify({ id: payrollscheduleId })
  );

export const addPayrollSchedule = body =>
  axios.post(BASE_URL + `/v1/subscriber/company/payrollschedule/true`, body);

export const updatePayrollSchedule = (body, id) =>
  axios.patch(
    BASE_URL + `/v1/subscriber/company/payrollschedule/${id}`,
    body,
    config
  );

export const getPayrollFrequency = () =>
  axios.get(BASE_URL + `/v1/system/payrollfrequency`);

export const getPayrollStatus = () =>
  axios.get(BASE_URL + `/v1/app/ui/createworkflowstatus?from=2&to=3`);

export const postPayrollScheduleName = payrollscheduleName =>
  axios.post(
    BASE_URL + `/v1/subscriber/company/payrollschedule/action/exists`,
    { Id: 0, Name: payrollscheduleName }
  );

export const searchPayrolls = params =>
  axios.post(
    BASE_URL + "/v1/subscriber/company/payrollschedule/search",
    JSON.stringify(params),
    { headers: { "Content-Type": "application/json" } }
  );

export const loadMorePayrolls = NextUri => axios.get(NextUri);
export const getBillingTypeData = () =>
  axios.get(common.WEB_API_MOCK_URL + `/v1/subscriber/company/1`);

export const getPayrollCalendarPreview = payload =>
  axios.post(
    BASE_URL + `/v1/subscriber/company/payrollschedule/action/previewcalendar`,
    payload
  );

export const getEntitySnapshot = (entityId, activityInstanceId) =>
  axios.get(
    common.WEB_API_URL +
      `/v1/app/ui/activityhistory/${entityId}/activityinstance/${activityInstanceId}/snapshot`
  );
