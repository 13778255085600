import React, { Component } from "react";
import DropDown from "../dropDown";
import { PAGE_FILTER_NAME, STATUS_FILTER_NAME } from "./Constants";

const labelMaker = (noLabel, allLabel, someLabel, oneLabel) => (o, s, all) =>
  all
    ? allLabel
    : Object.keys(s).filter(k => s[k]).length === 0
      ? noLabel
      : Object.keys(s).filter(k => s[k]).length === 1
        ? oneLabel
        : someLabel;

export default class FiltersDesktop extends Component {
  getFromToValue = (name, fromToValue) => {
    this.props.makeDateRangeSelect &&
      this.props.makeDateRangeSelect(name, fromToValue);
  };

  render() {
    const {
      makeOnSelect = () => undefined,
      makeSelectAll = () => undefined
    } = this.props;

    const { options = [], selected = {} } = this.props.data || {};

    return (
      <div className="nc-filter-container">
        <div className="showing-label" id="filter-label">
          {" "}
          FILTER:
        </div>
        <div className="nc-filter-group" id="filter-group">
          <div className="nc-filter-row" id="filter-row1">
            <div className="nc-filter-dropdown">
              <DropDown
                multiSelect
                showSearch
                showBadge
                options={options}
                selectedItems={selected}
                onSelect={makeOnSelect(PAGE_FILTER_NAME)}
                selectAll={makeSelectAll(PAGE_FILTER_NAME)}
                makeLabel={labelMaker(
                  "Select Pages",
                  "All Pages",
                  "Pages",
                  "Page"
                )}
                selectAllLabel="All Pages"
                searchBoxId="auto-suggestion-textbox"
                id="list"
              />
            </div>
            <div className="nc-filter-dropdown">
              <DropDown
                multiSelect
                showSearch
                showBadge
                options={options}
                selectedItems={selected}
                onSelect={makeOnSelect(STATUS_FILTER_NAME)}
                selectAll={makeSelectAll(STATUS_FILTER_NAME)}
                makeLabel={labelMaker(
                  "Select Statuses",
                  "All Statuses",
                  "Statuses",
                  "Status"
                )}
                selectAllLabel="All Statuses"
                searchBoxId="status-auto-suggestion-textbox"
                id="statuses-list"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
