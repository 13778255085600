import React, { Component } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { Martin, MardiGrass } from "./images";
import PropTypes from "prop-types";

export default class PayrollDashboardSummaryHolidayHours extends Component {
  render() {
    const { label, lvalue, HolidayDates = [], visible = true } = this.props;
    const bottomPopover = (
      <Popover
        id="holiday-list-bottom"
        className="payroll-dash-summary-icon-list payroll-dash-summary-icon-list-bgcolor"
      >
        <div className="clearfix">
          <div className="col-lg-12 col-md-12">
            <div className="payroll-dash-summary-icon-list-icon  clearfix">
              <i
                className="fa fa-times-circle icon"
                onClick={() => this.refs.overlayHolidayList.hide()}
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            {HolidayDates === null || HolidayDates === []
              ? null
              : HolidayDates.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        padding: "5px 0px"
                      }}
                    >
                      {item.HolidayId === 3 ? (
                        <Martin
                          width="30"
                          height="30"
                          style={{ marginRight: "5px" }}
                        />
                      ) : (
                        <MardiGrass
                          width="30"
                          height="30"
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      <span>
                        {item.HolidayName} {" : "}&nbsp;
                      </span>
                      <span className="payroll-popover">
                        {" "}
                        {item.HolidayHour}
                      </span>
                    </div>
                  );
                })}
          </div>
        </div>
      </Popover>
    );
    if (visible)
      return (
        <div className="payroll-dash-summary-item clearfix">
          <div className="payroll-dash-summary-item-label">{label}</div>
          <div className="payroll-dash-summary-item-value">{lvalue}</div>
          <div className="payroll-dash-summary-item-icon">
            {HolidayDates.length > 0 &&
              lvalue !== 0 && (
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  ref="overlayHolidayList"
                  overlay={bottomPopover}
                  rootClose={true}
                >
                  <i className="fa-information-blue fa fa-info-circle" />
                </OverlayTrigger>
              )}
          </div>
        </div>
      );
    else {
      return null;
    }
  }
}
PayrollDashboardSummaryHolidayHours.propTypes = {
  label: PropTypes.string,
  lvalue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  HolidayDates: PropTypes.array,
  visible: PropTypes.bool
};
PayrollDashboardSummaryHolidayHours.defaultProps = {
  HolidayDates: [],
  visible: true
};
