import React from "react";
import ColumnTitle, { ORDER_ASC, ORDER_DESC } from "../filters/ColumnTitle";
import { TableColumns } from "./Constants";
import classNames from "classnames/bind";
import Media from "react-media";
import PropTypes from "prop-types";
const EntityListTableHeader = ({
  sortOrder: { Field, Order } = {},
  editSortOrder,
  headerColumn = "Earnings",
  size
}) => {
  let className = classNames(
    "row table-header visible-lg visible-md",
    "col-lg-" + size[0],
    "col-md-" + size[1],
    "col-sm-" + size[2],
    "col-xs-" + size[3]
  );
  return (
    <Media query="(min-width:992px)">
      {matches =>
        matches ? (
          <div className={className}>
            {TableColumns[headerColumn].map(
              ({ label, field, col, style, colMd, id, showSort }) => (
                <ColumnTitle
                  key={field}
                  label={label}
                  col={col}
                  colMd={colMd}
                  order={field === Field && Order}
                  style={style}
                  onClick={() => {
                    showSort &&
                      (field !== Field
                        ? editSortOrder(field, ORDER_ASC)
                        : editSortOrder(
                            field,
                            Order === ORDER_ASC
                              ? ORDER_DESC
                              : Order === ORDER_DESC
                                ? ""
                                : ORDER_ASC
                          ));
                  }}
                  id={id}
                  showSort={showSort}
                />
              )
            )}
          </div>
        ) : null
      }
    </Media>
  );
};

export default EntityListTableHeader;
EntityListTableHeader.propTypes = {
  size: PropTypes.array
};
EntityListTableHeader.defaultProps = {
  size: [9, 9, 12, 12]
};
