export const PAYROLL_SCHEDULE_FILTER_NAME = "payrollSchedule";
export const FREQUECY_FILTER_NAME = "frequency";
export const STATUS_FILTER_NAME = "status";
export const HOLIDAY_CALENDER = "holidayCalender";
export const PAYROLL_SCHEDULE_DATA = "payrollScheduleData";
export const WEEKDAYS = "weekdays";
export const DIRECT_DEPOSIT_OPTIONS = "directDepositOptions";
export const PAYROLL_SCHEDULE_NAME = "PayrollScheduleName";
export const FREQUENCY = "Frequency";
export const FEDRAL_BANKING_HOLIDAY = "federal banking holiday";
export const WEEKEND = "DirectDepositPaymentForWeekend";
export const BANKING_HOLIDAY = "DirectDepositPaymentForBankHolidays";
export const ADJUSTED = "AdjustPaycheckDate";
export const CURRENT_MONTH = "0";
export const PREVIOUS_MONTH = "-1";
export const BILLING_DATA = "BillingData";
export const CURRENT_MONTH_LABEL = "current month";
export const PREVIOUS_MONTH_LABEL = "previous month";
export const PAYROLL_BANNER_NAME = "Payroll Schedule Setup";
export const EDIT_PAYROLL_BANNER_NAME = "Edit Payroll Schedule Setup";
export const DESCRIPTION = "Description";
export const PAY_PERIOD = "Pay Period";
export const PAYROLL_CALENDER = "Payroll Calendar";
export const SWITCH = "Switch";
export const SWITCH_RIGHT = "switch_right";
export const SWITCH_LEFT = "switch_left";
export const PREVIEW_CALENDAR = "Preview Calendar";
export const PREVIEW_CALENDAR_TITLE = "Preview Your Payroll Calendar";
export const PAYROLL_CALENDAR_MODAL_TITLE = "Payroll Calendar Preview";
export const previewPayrollCalendarDescription =
  "Here is a Preview of upcoming paycheck dates based off your selections.";
export const TOTAL_HOURS = "TotalHours";
export const SWITCH_DAYS_COUNT = "SwitchDaysCount";
export const DAYS_COUNT = "DaysCount";
export const LAST_DAY = "Last Day";
export const PAY_CHECK_DATE = "PayCheckDate";
export const PAY_CHECK_END_DATE = "PayCheckEndDate";
export const PAY_CHECK_START_MONTH = "PayCheckStartMonth";
export const PAY_CHECK_END_MONTH = "PayCheckEndMonth";
export const BUSINESS_DAYS = "BusinessDays";
export const LABEL = "Label";
export const RADIO_WITH_ICON = "radiowithicon";
export const TEXT = "text";
export const HOURS = "hours";
export const CHANGES_TAKE_EFFECT =
  "When do you want these changes to take effect?";
export const PAYROLL_SCHEDULE_TO_BEGIN = "PayrollScheduleToBeginComponentProps";
export const INACTIVATE_SCHEDULE = "INACTIVATE SCHEDULE";
export const DELETE_SCHEDULE = "DELETE SCHEDULE";
export const billingTypeValidationMessage =
  "Payroll must be submitted to Netchex at least [n=billing type +1] business days prior to your check date in order to allow Netchex to debit your account timely for funding";
export const semiMonthlyInfoBoxDescription =
  "For paycheck and/or pay period end dates on the 29th, 30th or 31st that could be larger then the number of days in a given month, the last day of the month will be used.";

export const toggleFrequencyData = {
  hide: {
    label: "Hide frequencies",
    icon: "fa fa-angle-up"
  },
  show: {
    label: "Show more frequencies",
    icon: "fa fa-angle-down"
  }
};

export const TableColumns = [
  { label: "Name", field: "name" },
  { label: "Frequency", field: "frequency" },
  { label: "Status", field: "status", col: 2 }
];

export const daySuffix = ["st", "nd", "rd", "rth"];

export const description =
  "Building your payroll schedule is the first step in processing payroll. Your payroll schedule drives when you need to submit payroll to Netchex. It also determines how often and when your employees and/or contractors get paid.";
export const workingHours = {
  Daily: 8,
  Weekly: 40,
  "Semi-Monthly": 80,
  "Bi-Weekly": 86.6667,
  Monthly: 173.3333,
  Quarterly: 520,
  Annually: 2080
};

export const maxWorkingHours = {
  Daily: 24,
  Weekly: 168,
  "Semi-Monthly": 360,
  "Bi-Weekly": 360,
  Monthly: 744,
  Quarterly: 2280,
  Annually: 8760
};
export const monthlySampleData = [
  {
    value: 1,
    label: "1st"
  },
  {
    value: 2,
    label: "2nd"
  },
  {
    value: 3,
    label: "last day"
  }
];

export const WEEKLY = "Weekly";
export const BIWEEKLY = "Bi-Weekly";
export const SEMIMONTHLY = "Semi-Monthly";
export const MONTHLY = "Monthly";
export const DAILY = "Daily";
export const QUARTERLY = "Quarterly";
export const ANNUALLY = "Annually";
export const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

export const frequencies = ["Weekly", "Bi-Weekly", "Semi-Monthly", "Monthly"];

export const otherFrequencies = [
  "Weekly",
  "Bi-Weekly",
  "Semi-Monthly",
  "Monthly",
  "Quarterly",
  "Annually",
  "Daily"
];
export const semiMonthlyPayCheckCount = ["first", "second"];
export const monthlyPayCheckCount = ["first"];
export const annuallyPayCheckCount = ["first"];

export const directDepositRadioBtn = {
  DirectDepositPaymentForBankHolidays: [
    { Id: -1, Name: "Prior Business Day" },
    { Id: 1, Name: "Next Business Day" }
  ],
  AdjustPaycheckDate: [{ Id: 1, Name: "Yes" }, { Id: 2, Name: "No" }]
};

export const payrollDescription = {
  payrollName: {
    show: true,
    inputId: "PayrollScheduleName",
    inputType: "text",
    inputName: "PayrollScheduleName",
    label: "Give this new payroll schedule a name.",
    description: "50 characters",
    descriptionStyle: "italic",
    Size: [4, 6, 8, 7],
    hideTopdescription: true,
    maxLength: "50",
    error: "",
    required: true
  },
  frequency: {
    show: true,
    inputId: "Frequency",
    label: "How often do you want to pay employees assigned to this schedule?",
    radios: [],
    inputName: "Frequency",
    type: "basic",
    width: "150px",
    iconClassName: "fa fa-angle-down",
    linkLabel: "Show more frequencies",
    inputType: "radiowithicon",
    required: true
  },
  workingHours: {
    show: true,
    inputId: "TotalHours",
    inputType: "text",
    style: { color: "#555" },
    inputName: "TotalHours",
    label: "How many total working hours are in the pay period?",
    description: "hours",
    Size: [1, 1, 2, 2],
    required: true,
    hideTopdescription: true,
    tooltip: "This field determines normal hours per pay period"
  },
  scheduleDays: {
    show: true,
    inputId: "scheduleDays",
    inputType: "raisedRadio",
    inputName: "scheduleDays",
    label:
      "What day(s) of week do you want to pay employees assigned to this schedule?",
    Size: [6, 6, 6, 6],
    Style: { marginBottom: "30px" },
    radio: [
      { Id: 1, Name: "Mon" },
      { Id: 2, Name: "Tue" },
      { Id: 3, Name: "Wed" },
      { Id: 4, Name: "Thu" },
      { Id: 5, Name: "Fri" }
    ],
    multiple: true
  }
};

export const BiWeeklyPayPeriod = {
  week: {
    show: true,
    inputId: "WeekDayToPay",
    label:
      "What day of the week do you want to pay employees assigned to this schedule?",
    radios: [],
    inputName: "WeekDayToPay",
    type: "basic",
    width: "150px",
    inputType: "radio",
    required: true
  },
  endDate: {
    show: true,
    inputId: "PayPeriodEnd",
    inputType: "numeric",
    inputName: "PayPeriodEnd",
    label: "When does your pay period end?",
    description: "days prior to paycheck date",
    style: { color: "#555" },
    hideTopdescription: true,
    Size: [1, 1, 2, 2],
    required: true
  },
  submitPayroll: {
    show: true,
    inputId: "BusinessDays",
    inputType: "text",
    style: { color: "#555" },
    inputName: "BusinessDays",
    label: "When do you want to submit your payroll to Netchex?",
    description: "business days prior to paycheck date",
    hideTopdescription: true,
    Size: [1, 1, 2, 4],
    required: true,
    tooltip:
      "Payroll must be submitted to Netchex at least [n=billing type +1] business days prior to your check date in order to allow Netchex to debit your account timely for funding."
  }
};

export const payrollCalender = {
  AutomaticReduceHoursComponentProps: {
    inputId: "AutomaticReduceHours",
    label:
      "Do you want Netchex to automatically reduce your salaried employee's regular hours and replace these hours with holiday hours when your  pay period includes a holiday?",
    radios: [
      { id: 1, label: "Yes", value: 1 },
      { id: 2, label: "No", value: 2 }
    ],
    inputName: "ReduceSalaryAutomatically",
    type: "basic",
    inputType: "radio",
    required: true,
    tooltip: "Tooltip.IS_EARNING_TAXABLE"
  },
  ChooseHolidayCalendarComponentProps: {
    inputId: "ChooseHolidayCalendar",
    label: "Choose a holiday calendar.",
    radios: [{ id: 1, label: "Federal Banking Holidays", value: 1 }],
    inputName: "HolidayCalender",
    type: "two-line-title",
    inputType: "radioButtonWithAddLink",
    width: "230px",
    required: true,
    addLabel: "Add a New Calendar",
    addLabelId: "addNewCalendar"
  },
  PayrollScheduleToBeginComponentProps: {
    show: true,
    inputId: "PayrollScheduleStartDate",
    inputType: "numeric",
    inputName: "PayrollScheduleStartDate",
    label: "When do you want this payroll schedule to be begin?",
    description: "mm/yyyy",
    placeholder: "01/2017",
    descriptionStyle: "italic",
    hideTopdescription: true,
    Size: [2, 1, 2, 3],
    format: "##/####",
    required: true
  }
};

export const dailyPayPeriod = {
  endDate: {
    show: true,
    inputId: "PayPeriodEnd",
    inputType: "numeric",
    inputName: "PayPeriodEnd",
    label: "When does your pay period end?",
    description: "days prior to paycheck date",
    style: { color: "#555" },
    hideTopdescription: true,
    Size: [1, 1, 2, 2],
    required: true
  },
  submitPayroll: {
    show: true,
    inputId: "BusinessDays",
    inputType: "text",
    style: { color: "#555" },
    inputName: "BusinessDays",
    label: "When do you want to submit your payroll to Netchex?",
    description: "business days prior to paycheck date",
    hideTopdescription: true,
    Size: [1, 1, 2, 4],
    required: true
  }
};
