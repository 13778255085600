import { DropTarget } from "react-dnd";
import React, { Component } from "react";
import PropTypes from "prop-types";
const boxTarget = {
  drop(props, monitor) {
    console.log("drop");
    if (props.onDrop) {
      props.onDrop(props, monitor);
    }
  }
};

class ImageDrop extends Component {
  render() {
    const { canDrop, isOver, connectDropTarget, onManualUpload } = this.props;
    const isActive = canDrop && isOver;
    return connectDropTarget(
      <div className="draggable-area">
        <div
          style={{
            fontWeight: "400",
            fontSize: "32px",
            marginBottom: "10px"
          }}
        >
          <div className="image-icon">
            <i className="fa fa-image" style={{ marginRight: "10px" }} />
            {isActive ? "Drop a file here" : "Drag a file here"}
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>-- or --</div>
        <div style={{ fontSize: "16px" }}>
          <label style={{ fontWeight: "400", marginBottom: 0 }}>
            <a>Select a file from your computer</a>
            <input
              id="image-upload"
              style={{ display: "none" }}
              type="file"
              onChange={onManualUpload}
            />
          </label>
        </div>
      </div>
    );
  }
}

export default DropTarget(
  props => props.accepts,
  boxTarget,
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(ImageDrop);

ImageDrop.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool.isRequired,
  accepts: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDrop: PropTypes.func
};
