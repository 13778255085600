import * as types from "../index";

export function getToasterMessage(message) {
  return {
    type: types.SYSTEM_THIRD_PARTY_PAYEE_TOASTER_MESSAGE,
    payload: message
  };
}

export function getButtonTypeForToasterMessage(item) {
  return {
    type: types.SYSTEM_THIRD_PARTY_PAYEE_BUTTON_TYPE,
    payload: item
  };
}
