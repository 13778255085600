import React, { Component } from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import {
  disasterLocation,
  disasterRadioOption,
  emergencyQuestions
} from "../Constants";

class DisasterLocation extends Component {
  render() {
    const { data } = this.props;
    const componentProps = disasterLocation;
    componentProps.disasterState.options =
      data.initialData && data.initialData.disasterState
        ? data.initialData.disasterState
        : [];
    const disasterLocationNodes = Object.keys(componentProps).map(k => {
      return (
        <FormFieldGroup
          key={k}
          {...componentProps[k]}
          required={true}
          block
          colSpan="col-md-4"
          value={data[componentProps[k]["inputName"]]}
          onChange={this.props.onChange}
          error={data.errors[componentProps[k]["inputName"]]}
        />
      );
    });
    return (
      <FormSection title="Disaster Location" hr>
        <div className="col-md-9" style={{ padding: "0px" }}>
          <div
            style={{
              padding: "10px 15px",
              fontSize: "16px",
              fontWeight: "600"
            }}
          >
            {emergencyQuestions.disasterLocation[0]}
          </div>
          <FormFieldGroup
            label="Location Name"
            optional={true}
            Size={[4, 4, 4, 4]}
            colSpan="col-md-4"
            inputType="text"
            inputName="locationName"
            inputId="locationName"
            required={true}
            block
            value={data["locationName"]}
            onChange={this.props.onChange}
            error={data.errors["locationName"]}
          />
          <FormFieldGroup
            label="Phone Number"
            optional={true}
            Size={[3, 3, 3, 3]}
            colSpan="col-md-4"
            radio={disasterRadioOption}
            inputType="inputWithRadioButtons"
            required={true}
            block
            inputValue={data["disasterLocationPhone"]}
            value={data["disasterLocationPhoneType"]}
            onChange={this.props.onChange}
            error={data.errors["disasterLocationPhone"]}
          />
          {disasterLocationNodes}
        </div>
        <div className="col-md-12" style={{ padding: "0px" }}>
          <button
            id="Save emergencyContact"
            onClick={this.props.onSave}
            style={{ float: "right" }}
            className="nc-button primary-button"
          >
            Save
          </button>
        </div>
      </FormSection>
    );
  }
}

export default DisasterLocation;
