import React, { Component } from "react";
import NotificationUserItem from "./NotificationUserItem";
import { nnclogo } from "../../images";

const placeholderPic = "https://goo.gl/4AYQmG";

class ViewFullJobHistoryHeader extends Component {
  state = {};

  render() {
    const { data, viewId } = this.props;
    return (
      <div className="row col-lg-12 col-md-12 list-header-container">
        <div
          className="col-lg-9 col-md-9 list-header-job-title"
          id={`job-title-${data.UserId}`}
        >
          Full Job History - {data.JobDescription}{" "}
        </div>
        <div className="col-lg-3 col-md-3 list-header-user-details">
          <NotificationUserItem
            id={data.UserId}
            //name={data.UserDisplayName}
            //picUrl={data.UserProfilePictureUrl}
            name={
              data.IsNetchexUser === true && parseInt(viewId, 10) === 2
                ? "Netchex Support"
                : data.UserDisplayName
            }
            picUrl={
              data.IsNetchexUser === true && parseInt(viewId, 10) === 2
                ? nnclogo
                : data.UserProfilePictureUrl === null ||
                  data.UserProfilePictureUrl === ""
                  ? placeholderPic
                  : data.UserProfilePictureUrl
            }
          />
        </div>
      </div>
    );
  }
}

export default ViewFullJobHistoryHeader;
