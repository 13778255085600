import React from "react";
import DayPicker from "./src/DayPicker";
import Select from "react-select";
import Media from "react-media";
import "./src/style.css";
import { Overlay, Modal, Popover } from "react-bootstrap";
import Accordian from "./../accordians";
import "./DashboardCalendar.css";
const initialState = {
  from: null,
  to: null,
  enteredTo: null // Keep track of the last day for mouseEnter.
};

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const fromMonth = new Date(currentYear, currentMonth);
const toMonth = new Date(currentYear + 50, 11);

// function isSelectingFirstDay(from, to, day) {
//   const firstDayIsNotSelected = !from;
//   const selectedDayIsBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
//   const rangeIsSelected = from && to;
//   return (
//     firstDayIsNotSelected || selectedDayIsBeforeFirstDay || rangeIsSelected
//   );
// }

// Component will receive date, locale and localeUtils props
function YearMonthForm({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const logChange = function logChange(val) {
    let value = JSON.stringify(val.value);
    onChange(new Date(value, currentMonth));
  };
  function showCustomIcon() {
    return <i className="fa fa-chevron-down" aria-hidden="true" />;
  }
  let select_options = [];
  years.map((year, i) => select_options.push({ value: year, label: year }));

  return (
    <form className="DayPicker-Caption clearfix">
      <span className="month-name">{months[date.getMonth()]}</span>
      <Select
        name="year"
        className="year-selector"
        value={date.getFullYear()}
        options={select_options}
        onChange={logChange}
        arrowRenderer={showCustomIcon}
        clearable={false}
        searchable={false}
      />
    </form>
  );
}

export default class DateRangePicker extends React.Component {
  state = {
    from: null
  };
  handleYearMonthChange = month => {
    this.setState({ month });
    console.log("Month", month);
  };

  handleDayClick = day => {
    this.setState({
      from: day
    });

    let fromToValue = {
      from: this.state.from
    };
    this.props.getFromToValue("", fromToValue);
  };

  reset = () => {
    this.setState(initialState);
  };

  toggle = e => {
    this.setState({ show: !this.state.show, target: e.target });
  };

  render() {
    const { from } = this.state;
    const modifiers = {
      start: from,
      highlighted: new Date(2018, 1, 28),
      available: [new Date(2018, 1, 27), new Date(2018, 1, 20)],
      availableWire: [new Date(2018, 1, 24), new Date(2018, 1, 18)],
      unavailable: [new Date(2018, 1, 26), new Date(2018, 1, 16)],
      holiday: [new Date(2018, 1, 25), new Date(2018, 1, 11)]
    };
    const selectedDays = [from, { from }];
    const dayPickerButton = (
      <i
        ref={target => (this.target = target)}
        onClick={this.toggle}
        className="fa fa-pencil"
        aria-hidden="true"
        style={{ color: "#009999" }}
      />
    );
    return (
      <Media query="(min-width:320px) and (max-width:991px)">
        {matches =>
          matches ? (
            <div className="nc-select-container" id="filter-activity-entity">
              {/* {dayPickerButton} */}
              <Modal
                bsClass="calendar modal"
                animation={false}
                backdrop={"static"}
                keyboard={false}
                show={this.state.show}
                onHide={() => this.setState({ show: false })}
              >
                <Modal.Header>
                  <div className="hidden-md hidden-lg date-range-label">
                    <div className="close-icon" style={{ float: "left" }}>
                      <i
                        onClick={this.toggle}
                        className="fa fa-times-circle-o"
                      />
                    </div>
                    Select Date Range
                  </div>
                </Modal.Header>
                <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="YearNavigation">
                    <DayPicker
                      enableOutsideDays
                      month={this.state.month}
                      fromMonth={fromMonth}
                      toMonth={toMonth}
                      onDayClick={this.handleDayClick}
                      modifiers={modifiers}
                      selectedDays={selectedDays}
                      captionElement={
                        <YearMonthForm onChange={this.handleYearMonthChange} />
                      }
                    />
                    <div className="legend-container">
                      <Accordian title="Legend" type="calendar">
                        <ul className="legends" style={{ marginTop: "15px" }}>
                          <li className="legend-item">
                            <div className="legend-box today-legend">12</div>Today
                          </li>
                          <li className="legend-item">
                            <div className="legend-box available-wire-legend">
                              14
                            </div>Available, Wire Required
                          </li>
                          <li className="legend-item">
                            <div className="legend-box available-legend">
                              15
                            </div>Available, No Wire Required
                          </li>
                          <li className="legend-item">
                            <div className="legend-box unavailable-legend">
                              5
                            </div>Unavailable
                          </li>
                          <li className="legend-item">
                            <div className="legend-box holiday-legend">11</div>No
                            Holiday
                          </li>
                        </ul>
                      </Accordian>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          ) : (
            <div
              className="nc-select-container"
              id="filter-activity-darange-overlay"
              // style={{ position: "absolute" }}
            >
              {dayPickerButton}
              <Overlay
                show={this.state.show}
                onHide={() => this.setState({ show: false })}
                placement="bottom"
                container={this}
                rootClose={true}
                target={this.state.target}
                containerPadding={-60}
              >
                <Popover
                  id="popover-contained"
                  bsClass="dashboard-datepicker popover"
                >
                  <div className="YearNavigation-dashboard">
                    <DayPicker
                      enableOutsideDays
                      month={this.state.month}
                      fromMonth={fromMonth}
                      toMonth={toMonth}
                      onDayClick={this.handleDayClick}
                      //onDayMouseEnter={this.handleDayMouseEnter}
                      modifiers={modifiers}
                      selectedDays={selectedDays}
                      captionElement={
                        <YearMonthForm onChange={this.handleYearMonthChange} />
                      }
                    />
                    <div className="legend-container">
                      <Accordian title="Legend" type="calendar">
                        <ul className="legends" style={{ marginTop: "15px" }}>
                          <li className="legend-item">
                            <div className="legend-box today-legend">12</div>Today
                          </li>
                          <li className="legend-item">
                            <div className="legend-box available-wire-legend">
                              14
                            </div>Available, Wire Required
                          </li>
                          <li className="legend-item">
                            <div className="legend-box available-legend">
                              15
                            </div>Available, No Wire Required
                          </li>
                          <li className="legend-item">
                            <div className="legend-box unavailable-legend">
                              5
                            </div>Unavailable
                          </li>
                          <li className="legend-item">
                            <div className="legend-box holiday-legend">11</div>No
                            Holiday
                          </li>
                        </ul>
                      </Accordian>
                    </div>
                  </div>
                </Popover>
              </Overlay>
            </div>
          )
        }
      </Media>
    );
  }
}
