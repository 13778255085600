import axios from "axios";
import * as common from "../../app/common/index";

const BASE_URL = common.WEB_API_URL;
//const BASE_MOCK_URL = common.WEB_API_MOCK_URL;

const config = { headers: { "Content-Type": "application/json" } };
const makeGet = path => () => axios.get(BASE_URL + path);
//const makeMockGet = path => () => axios.get(BASE_MOCK_URL + path);

/* Use for product list */
const PRODUCTS_LIST_PATH = "/api/System/ProductSuite";
export const getProductsList = makeGet(PRODUCTS_LIST_PATH);

/* Use for company list */
const COMPANIES_LIST_PATH = "/v1/user/company?systemrole=";
export const getCompanyList = viewId =>
  axios.get(BASE_URL + COMPANIES_LIST_PATH + viewId);

/* Use for status list */
const STATUSES_LIST_PATH =
  "/v1/app/ui/notificationsection/notificationstatus?filter=all&from=0&to=0";
export const getStatusList = makeGet(STATUSES_LIST_PATH);

/* Use for user list */
const USERS_LIST_PATH =
  "/v1/app/ui/notificationsection/notificationuser?filter=all&from=0&to=0";
export const getUserList = makeGet(USERS_LIST_PATH);

/* Used to get notification list */
const GET_LIST_PATH = "/v1/app/ui/notificationsection";
export const getNotificationList = makeGet(GET_LIST_PATH);

const GET_USER_ACCESS_PATH = `/v1/app/ui/notificationsection/navigationurl/`;
export const getUserAccessForDestinationPage = (companyId, menuId) =>
  axios.get(BASE_URL + GET_USER_ACCESS_PATH + `${companyId}/${menuId}`);

/* Use to delete Holiday calendar */
export const cancelJobRequest = Id =>
  axios.post(BASE_URL + "/v1/job/action/canceljob", { Id }, config);

/* Use to get job by id*/
export const getFullJobHistory = params =>
  axios.post(
    BASE_URL + `/v1/app/ui/notificationsection/search`,
    JSON.stringify(params),
    config
  );

export const createNewJob = (data, entity) =>
  axios.post(
    BASE_URL + `/v1/job/action/${entity}?returnSavedEntity=true`,
    data,
    config
  );

export const searchPosts = params =>
  axios.post(
    BASE_URL + `/v1/app/ui/notificationsection/search`,
    JSON.stringify(params),
    config
  );

const GET_NOTIFICATION_DETAILS_BY_ID = `/v1/app/ui/notification/jobstatus/`;
export const getNotificationDetailsById = jobStatusNotificationId =>
  axios.get(
    BASE_URL + GET_NOTIFICATION_DETAILS_BY_ID + `${jobStatusNotificationId}`
  );

const GET_NOTIFICATION_BY_ID = `/v1/app/ui/notification/jobhistory/`;
export const getNotificationById = jobId =>
  axios.get(BASE_URL + GET_NOTIFICATION_BY_ID + `${jobId}`);

export const loadMorePosts = NextUri => axios.get(NextUri);

if (process.env.NODE_ENV === "development") {
  /* Exposing these globally for testing */
  window.notification = {
    getProductsList,
    getCompanyList,
    getStatusList,
    getUserList,
    searchPosts,
    getUserAccessForDestinationPage,
    getNotificationList
  };
}
