import React, { Component } from "react";
import { PropTypes } from "prop-types";
import {
  FormSection,
  FormFieldGroup
} from "component-library/formSections/index";
import * as FIELDS from "../utils/Constants";

export default class SchoolInfo extends Component {
  render() {
    const { stateList = [], data = {}, onChange, errors } = this.props;
    return (
      <div className="tab-container-add-sub-section">
        <div className="section-items">
          <FormSection title="School Info">
            <FormFieldGroup
              inputType="text"
              inputName={FIELDS.SCHOOL_INFO_NAME}
              value={data[FIELDS.SCHOOL_INFO_NAME]}
              label="Name"
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              error={errors[FIELDS.SCHOOL_INFO_NAME]}
              required={true}
            />

            <FormFieldGroup
              inputType="text"
              inputName={FIELDS.SCHOOL_INFO_CITY}
              value={data[FIELDS.SCHOOL_INFO_CITY]}
              label="City"
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              error={errors[FIELDS.SCHOOL_INFO_CITY]}
              required={true}
            />

            <FormFieldGroup
              inputType="dropdown"
              options={stateList}
              inputName={FIELDS.SCHOOL_INFO_STATE_ID}
              value={data[FIELDS.SCHOOL_INFO_STATE_ID]}
              label="State"
              onChange={onChange}
              Size={[3, 3, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              error={errors[FIELDS.SCHOOL_INFO_STATE_ID]}
              required={true}
            />
          </FormSection>
        </div>
      </div>
    );
  }
}

SchoolInfo.propTypes = {
  stateList: PropTypes.array
};

SchoolInfo.defaultProps = {
  stateList: []
};
