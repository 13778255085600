import Loadable from "react-loadable";
import ChunkLoading from "app/common/UIComponents/ChunkLoading";

const DeductionListingPage = Loadable({
  loader: () =>
    import("./DeductionListingPage" /* webpackChunkName: "deduction-list" */),
  loading: ChunkLoading
});

const CompanyLevelDeductionDescription = Loadable({
  loader: () =>
    import("./CompanyLevelDeductionDescription" /* webpackChunkName: "deduction-description" */),
  loading: ChunkLoading
});

const CompanyLevelDeductionCalculationMethod = Loadable({
  loader: () =>
    import("./CompanyLevelDeductionCalculationMethod" /* webpackChunkName: "deduction-calculation" */),
  loading: ChunkLoading
});

const CompanyLevelDeductionCycle = Loadable({
  loader: () =>
    import("./CompanyLevelDeductionCycle" /* webpackChunkName: "deduction-cycle" */),
  loading: ChunkLoading
});

const CompanyLevelDeductionDefaultValue = Loadable({
  loader: () =>
    import("./CompanyLevelDeductionDefaultValue" /* webpackChunkName: "deduction-default-value" */),
  loading: ChunkLoading
});

const CompanyDeductionOptions = Loadable({
  loader: () =>
    import("./CompanyDeductionOptions" /* webpackChunkName: "deduction-options" */),
  loading: ChunkLoading
});

const CompanyLevelReviewAndSave = Loadable({
  loader: () =>
    import("./CompanyLevelReviewAndSave" /* webpackChunkName: "deduction-review-save" */),
  loading: ChunkLoading
});

export {
  DeductionListingPage,
  CompanyLevelDeductionDescription,
  CompanyLevelDeductionCalculationMethod,
  CompanyLevelDeductionCycle,
  CompanyLevelDeductionDefaultValue,
  CompanyDeductionOptions,
  CompanyLevelReviewAndSave
};
