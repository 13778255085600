import React from "react";
import { Modal } from "react-bootstrap";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import FooterButtonSection from "../FooterButtonSection";
import "./AddNewNoteTypeModal.css";

const AddNewNoteTypeModal = props => {
  props.addNewNoteType();
  return (
    <Modal
      id="add-note-type-modal"
      bsClass="add-note-type-modal modal"
      animation={false}
      backdrop={"static"}
      keyboard={false}
      show={props.data.show}
      onHide={props.onClose}
      restoreFocus={false}
    >
      <Modal.Header>
        <div id="add-note-type-modal-header" className="close-icon">
          <i onClick={props.onClose} className="fa fa-times-circle-o fa-lg" />
        </div>
        <div className="title">Create a New Note Type</div>
      </Modal.Header>
      <Modal.Body>
        <div id="add-note-type-modal-body" className="modal-text">
          <div style={{ marginBottom: "30px" }}>
            Didn’t see the correct note type available for this employee? Add a
            new one below to your company.
          </div>
          <FormSection>
            <FormFieldGroup
              inputId="Name"
              label="Name this new note type"
              inputName="Name"
              inputType="text"
              Size={[5, 5, 5, 5]}
              description="Ex. Benefit Note, Disciplinary Note, etc"
              required={true}
              descriptionStyle="italic"
              labelStyle={{ marginTop: "30px" }}
              value={props.data["Name"]}
              onChange={props.onChange}
            />
          </FormSection>
          <FooterButtonSection
            primaryButtonLabel="Create Note Type"
            onCancelClick={props.onClose}
            onAddClick={() => {}}
            hr={false}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewNoteTypeModal;
