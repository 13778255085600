import React from "react";

const ReorderSectionAndFields = () => {
  return (
    <div className="button-group col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <span style={{ paddingRight: "10px" }}>
        <button
          type="button"
          id="reorder-sections-button"
          className="primary-link-button"
          onClick={() => {}}
        >
          <i className="fa fa-arrows" /> Reorder Sections
        </button>
      </span>
      <span style={{ borderRight: "1px solid #ccc", height: "20px" }} />
      <span style={{ paddingLeft: "10px" }}>
        <button
          type="button"
          id="reorder-fields-button"
          className="primary-link-button"
          onClick={() => {}}
        >
          <i className="fa fa-arrows" /> Reorder Fields
        </button>
      </span>
    </div>
  );
};
export default ReorderSectionAndFields;
