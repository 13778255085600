import React from "react";
import holidayIcon from "../holidayIcon";
import greyHolidayIcon from "../greyHolidayIcon";
import * as Constants from "../Constants";

const IconCheckbox = ({
  id,
  value = false,
  onChange,
  label,
  name,
  disabled = false,
  classname,
  inputId
}) => (
  <li
    className={
      id === Constants.SELECT_ALL_ID
        ? `common-holidays-item set-height ${classname}`
        : `common-holidays-item ${classname}`
    }
    onClick={!disabled ? onChange : () => {}}
  >
    <input type="checkbox" checked={value || false} readOnly />

    <label id={id} className="holiday-checkbox-label">
      <div>
        {id === Constants.SELECT_ALL_ID ? (
          <span />
        ) : value === false ? (
          <img src={greyHolidayIcon(id)} alt={label} width="50" height="50" />
        ) : value === true ? (
          <img src={holidayIcon(id)} alt={label} width="50" height="50" />
        ) : null}
      </div>
      <div className={id === Constants.SELECT_ALL_ID ? "add-padding" : null}>
        {label}
      </div>
    </label>
  </li>
);

export default IconCheckbox;
