import moment from "moment";

const formatTime = timeStr => {
  if (timeStr === "") {
    return "";
  }
  return moment(new Date(timeStr + "+00:00")).format("DD/MM/YYYY");
};

export default formatTime;
