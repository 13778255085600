import React from "react";
import * as STATE from "../../Constants";

const Check = ({ style }) => (
  <i className="fa fa-check-circle icon green" style={style} />
);

const Error = ({ style }) => (
  <i className="fa fa-exclamation-triangle icon red" style={style} />
);

const Circle = ({ style }) => (
  <i className="fa fa-circle-thin icon light-grey" style={style} />
);
const Failed = ({ style }) => (
  <i className="fa fa-minus-circle icon red" style={style} />
);

const Cancelled = ({ style }) => (
  <i className="fa fa-times-circle icon red" style={style} />
);
const Icon = ({ state, errorCount, stepId, stageId }) => {
  switch (state) {
    case true:
      if (stepId === 3) {
        if (stageId === STATE.STATUS_FAILED) {
          return <Failed />;
        } else if (stageId === STATE.STATUS_COMPLETED_WITH_ERRORS) {
          return <Error />;
        } else {
          return <Check />;
        }
      } else if (stepId === 2) {
        if (stageId === STATE.STATUS_CANCELLED) {
          return <Cancelled />;
        } else {
          return <Check />;
        }
      } else {
        return <Check />;
      }

    case false:
      return <Circle />;
    default:
      return <Circle />;
  }
};

export { Check, Error, Circle };
export default Icon;
