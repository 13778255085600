import React from "react";
import {
  STATUS_COMPLETED_WITH_ERRORS,
  STATUS_COMPLETED,
  STATUS_CANCELLED,
  STATUS_FAILED
} from "../Constants";

const JobTaskItemDesktop = ({ item, onClickCompanyLink, jobTypeName }) => (
  <div className="display-toggle-content-item">
    <div
      className="display-toggle-content-left col-lg-8 col-md-8"
      id={`job-task-description-title-${item.JobTaskId}`}
    >
      <div>
        {item.CurrentJobTaskStatusId === STATUS_COMPLETED_WITH_ERRORS && (
          <i className={`fa fa-exclamation-triangle orange-icon`} />
        )}
        {item.CurrentJobTaskStatusId === STATUS_COMPLETED && (
          <i className={`fa fa-check-circle green`} />
        )}
        {item.CurrentJobTaskStatusId === STATUS_CANCELLED && (
          <i className={`fa fa-times-circle-o orange-icon`} />
        )}
        {item.CurrentJobTaskStatusId === STATUS_FAILED && (
          <i className={`fa fa-minus-circle orange-icon`} />
        )}
      </div>
      <div className="display-toggle-content-name">
        {item.JobTaskDescription}
      </div>
    </div>
    {(item.CurrentJobTaskStatusId === STATUS_COMPLETED_WITH_ERRORS ||
      item.CurrentJobTaskStatusId === STATUS_COMPLETED ||
      item.CurrentJobTaskStatusId === STATUS_FAILED) && (
      <a
        className="display-toggle-content-right col-lg-4 col-md-4"
        id={`subscriber-company-name-${item.JobTaskId}`}
        onClick={onClickCompanyLink}
      >
        <span className="display-toggle-content-right-name">
          {" "}
          {item.SubscriberCompanyName}'s {jobTypeName}{" "}
        </span>
        <i className={`fa fa-caret-right`} />
      </a>
    )}
  </div>
);

export default JobTaskItemDesktop;
