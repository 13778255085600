export const SET_MENU_LIST = "FETCH_ALL_MENUS";
export const CLEAR_MENU_LIST = "@app/global-navigation/CLEAR_MENU_LIST";

const allMenuList = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_MENU_LIST:
      return payload.data;
    case CLEAR_MENU_LIST:
      return {};
    default:
      return state;
  }
};

export default allMenuList;

export const setAllMenuList = options => ({
  type: SET_MENU_LIST,
  payload: options
});

export const clearAllMenuList = () => ({
  type: CLEAR_MENU_LIST
});
