export const reFormatResubmitJob = (jobId, jobTaskList, entityData) => {
  return {
    AppUIProductId: entityData.AppUIProductId,
    AppUIMenuItemId: entityData.AppUIMenuItemId,
    SourceSubscriberId: entityData.SourceSubscriberId,
    SourceSubscriberName: entityData.SourceSubscriberName,
    SourceCompanyId: entityData.SourceCompanyId,
    SourceCompanyName: entityData.SourceCompanyName,
    SourceEntityIdList: reformatSourceEntityList(jobTaskList),
    DestinationCompanyList: [],
    SourceCompanyCode: "",
    IsJobTaskResubmitted: true,
    ResubmitEntityListToDestinationCompanyList: reFormatTaskList(jobTaskList),
    JobId: jobId
  };
};

const reFormatTaskList = jobTaskList => {
  let newJobTaskList = [];
  jobTaskList.forEach(item => {
    newJobTaskList.push({
      m_item1: item.JobTaskId,
      m_Item2: item.SubscriberId,
      m_item3: item.EntityId,
      m_item4: item.SubscriberCompanyId
    });
  });
  return newJobTaskList;
};

const reformatSourceEntityList = jobTaskList => {
  let newJobTaskList = [];
  jobTaskList.forEach(item => {
    if (!newJobTaskList.includes(item.EntityId)) {
      newJobTaskList.push(item.EntityId);
    }
  });
  return newJobTaskList;
};
