import { DropTarget } from "react-dnd";
import React, { Component } from "react";
import PropTypes from "prop-types";
const boxTarget = {
  drop(props, monitor) {
    console.log("drop");
    if (props.onDrop) {
      props.onDrop(props, monitor);
    }
  }
};

class ImageDrop extends Component {
  render() {
    const { canDrop, isOver, connectDropTarget, onManualUpload } = this.props;
    const isActive = canDrop && isOver;
    return connectDropTarget(
      <div className="draggable-area">
        <div
          id="drag-or-drop-profile-pic-label"
          style={{
            fontWeight: "300",
            fontSize: "36px",
            marginBottom: "20px",
            textAlign: "center"
          }}
        >
          <div className="image-icon" style={{ marginBottom: "20px" }}>
            <i className="fa fa-image" />
          </div>
          {isActive ? "Drop a profile photo here" : "Drag a profile photo here"}
        </div>
        <div style={{ marginBottom: "20px" }}>-- or --</div>
        <div style={{ fontSize: "16px" }}>
          <label
            style={{
              fontWeight: "400",
              marginBottom: 0,
              color: "#009999",
              cursor: "pointer"
            }}
          >
            {/*a tag is converted to div to resolve bug 37937*/}
            <div id="select-profile-pic-from-computer-link">
              Select a photo from your computer
            </div>
            <input
              id="image-upload"
              style={{ display: "none" }}
              type="file"
              onChange={onManualUpload}
            />
          </label>
        </div>
      </div>
    );
  }
}

export default DropTarget(
  props => props.accepts,
  boxTarget,
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(ImageDrop);

ImageDrop.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool.isRequired,
  accepts: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDrop: PropTypes.func
};
