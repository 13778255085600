import * as types from "./index";

export function setFormUpdate(isFormUpdated) {
  return {
    type: types.SET_FORM_UPDATED,
    payload: isFormUpdated
  };
}

export const setFormUpdateDispatch = isFormUpdated => dispatch => {
  dispatch({
    type: types.SET_FORM_UPDATED,
    payload: isFormUpdated
  });
  return Promise.resolve();
};

export function setGlobalNavClick(isLinkClick = false, linkItem, MenuLink) {
  let data = {
    isLinkClick: isLinkClick,
    LinkItem: linkItem,
    MenuLink: MenuLink
  };
  return {
    type: types.SET_GLOBALNAV_CLICK,
    payload: data
  };
}

export function CallbackToQuickLink(isCallBack) {
  return {
    type: types.CALL_BACK_TO_QUICK_LINK,
    payload: isCallBack
  };
}

export function isOverlayClickable(isClick) {
  return {
    type: types.OVERLAY_CLICKABLE,
    payload: isClick
  };
}
