import axios from "axios";
import * as types from "./index";
import * as common from "../../../../app/common/index";

export function getDeductionSetupList() {
  //const url=`${common.WEB_API_URL}/v1/system/deduction?from=1&to=1000&_mock_=success&datasize=1000`;
  const url = `${common.WEB_API_URL}/v1/system/deduction?filter=ALL`;
  const request = axios.get(url);
  return {
    type: types.SYSTEM_DEDUCTION_MAIN_LIST,
    payload: request
  };
}

export function updatedFilteredList(data) {
  let request = {
    data: data
  };
  return {
    type: types.SYSTEM_DEDUCTION_FILTERED_LIST,
    payload: request
  };
}

export function sortFilteredList(request) {
  return {
    type: types.SYSTEM_DEDUCTION_FILTERED_LIST,
    payload: request
  };
}

export function getFilteredDeductionSetupList(
  data,
  selectedDeductions,
  selectedCategories,
  selectedStatus
) {
  //Id -> selectedThirdPartyPayees
  //DeductionCategoryNameId -> selectedCategories
  //CreateWorkflowStateId -> selectedStatus

  let filterdByEntityList = [];
  let filterdByCategoryList = [];
  let filterdList = [];
  //we will need to use include method of javascript
  if (data !== undefined) {
    // Apply Entity Filter ...
    if (selectedDeductions === undefined || selectedDeductions.length === 0) {
      // No filter to apply ...
      // filterdByEntityList = data;
      filterdByEntityList = [];
    } else {
      // Apply filter ...
      data.forEach(item => {
        if (selectedDeductions.includes(item.Id)) {
          filterdByEntityList.push(item);
        }
      });
    }

    // Apply Category Filter ...
    if (selectedCategories === undefined || selectedCategories.length === 0) {
      // No filter to apply ...
      // filterdByCategoryList = filterdByEntityList;
      filterdByCategoryList = [];
    } else {
      // Apply filter ...
      filterdByEntityList.forEach(item => {
        if (selectedCategories.includes(item.DeductionCategoryId)) {
          filterdByCategoryList.push(item);
        }
      });
    }

    // Apply Status Filter ...
    if (selectedStatus === undefined || selectedStatus.length === 0) {
      // No filter to apply ...
      // filterdList = filterdByCategoryList;
      filterdList = [];
    } else {
      // Apply filter ...
      filterdByCategoryList.forEach(item => {
        if (selectedStatus.includes(item.CreateWorkFlowStateId)) {
          filterdList.push(item);
        }
      });
    }
  }

  let request = {
    data: {
      Data: filterdList,
      DataCount: filterdList.length
    }
  };

  return {
    type: types.SYSTEM_DEDUCTION_FILTERED_LIST,
    payload: request
  };
}

export function getDeductionDeleteIconClickList() {
  const url = `${common.WEB_API_URL}`;
  const request = axios.get(url);
  return {
    type: types.FETCH_DEDUCTION_DELETE_ICON_CLICK_LIST,
    payload: request
  };
}

export function setSystemDeductionData(data) {
  if (data === undefined) {
    data = [];
  }
  return {
    type: types.SYSTEM_DEDUCTION_DATA,
    payload: data
  };
}

export function setSaveSysDeductionPage(pageId, data) {
  data.push(pageId);
  return {
    type: types.SYSTEM_DEDUCTION_SAVED_PAGES,
    payload: data
  };
}

export function setSelectedFilters(
  selectedDeductions,
  selectedCategories,
  selectedStatus
) {
  let deductions = [];
  let categories = [];
  let status = [];
  selectedDeductions.forEach(dItem => {
    deductions.push(dItem);
  });

  selectedCategories.forEach(dItem => {
    categories.push(dItem);
  });

  selectedStatus.forEach(dItem => {
    status.push(dItem);
  });

  let data = {
    selectedDeductions: deductions,
    selectedCategories: categories,
    selectedStatus: status
  };

  return {
    type: types.SYSTEM_DEDUCTION_SELECTED_FILTERS,
    payload: data
  };
}
