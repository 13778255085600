import React from "react";

const CustomOverlay = props => {
  return (
    <div className="nc-edit-overlay" style={{ width: "500px" }}>
      {props.children}
    </div>
  );
};

export default CustomOverlay;
