export const EDIT_ENTITY_DATA = "@app/common/EDIT_ENTITY_DATA";
export const CLEAR_ENTITY_DATA = "@app/common/CLEAR_ENTITY_DATA";
export const SET_ENTITY_DATA = "@app/common/SET_ENTITY_DATA";

const entityData = (state = {}, { type, payload }) => {
  switch (type) {
    case EDIT_ENTITY_DATA:
      return {
        ...state,
        ...payload
      };
    case SET_ENTITY_DATA:
      return payload;
    case CLEAR_ENTITY_DATA:
      return {};
    default:
      return state;
  }
};

export default entityData;

export const editEntityData = edits => ({
  type: EDIT_ENTITY_DATA,
  payload: edits
});

export const setEntityData = edits => ({
  type: SET_ENTITY_DATA,
  payload: edits
});

export const clearEntityData = () => ({
  type: CLEAR_ENTITY_DATA
});
