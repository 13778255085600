export const SET_HOBBIES_DATA = "SET_HOBBIES_DATA";
export const CLEAR_HOBBIES_DATA = "CLEAR_HOBBIES_DATA";

const profileData = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_HOBBIES_DATA:
      const { name, values } = payload;
      return { ...state, [name]: values };
    case CLEAR_HOBBIES_DATA:
      return {};
    default:
      return state;
  }
};
export default profileData;

export const setHobbiesList = (name, values) => ({
  type: SET_HOBBIES_DATA,
  payload: { name, values }
});

export const clearHobbiesList = () => ({
  type: CLEAR_HOBBIES_DATA
});
