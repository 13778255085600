import React from "react";
import FooterButtons from "../components/FooterButtons";
import {
  LeaveReturnDetails,
  RehireDetails,
  ReactivationDetails
} from "component-library/employee-profile/employment-info/change-status/active";
import { SelfService } from "component-library/employee-profile/employment-info/change-status/common";
import * as FOOTNOTE from "../Constants";

const ON_LEAVE_CONSTANT = 4;
const REHIRE_CONSTANT = 2;
const REACTIVATE_CONSTANT = 3;

const Active = ({ data = {}, onChange, onCloseOverlay }) => {
  return (
    <div>
      {data.EmployeeCurrentStatus === ON_LEAVE_CONSTANT && (
        <div>
          <LeaveReturnDetails onChange={onChange} data={data} />
          <SelfService
            currentStatus="on-leave"
            onChange={onChange}
            data={data}
          />
          <FooterButtons
            buttonLabel="End Employee Leave"
            onCancelClick={onCloseOverlay}
            onPrimaryClick={() => {}}
          />
        </div>
      )}

      {data.EmployeeCurrentStatus === REHIRE_CONSTANT && (
        <div>
          <RehireDetails onChange={onChange} data={data} />
          <SelfService
            currentStatus="terminated"
            onChange={onChange}
            data={data}
          />
          <FooterButtons
            buttonLabel="Rehire Employee"
            footNote={FOOTNOTE.ACTIVE_FOOTNOTE}
            onCancelClick={onCloseOverlay}
          />
        </div>
      )}

      {data.EmployeeCurrentStatus === REACTIVATE_CONSTANT && (
        <div>
          <ReactivationDetails onChange={onChange} data={data} />
          <SelfService
            currentStatus="inactivate"
            onChange={onChange}
            data={data}
          />
          <FooterButtons
            buttonLabel="Reactivate Employee"
            onCancelClick={onCloseOverlay}
            onPrimaryClick={() => {}}
          />
        </div>
      )}
    </div>
  );
};

export default Active;
