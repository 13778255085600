import { connect } from "react-redux";
import NotificationsViewFullJobHistory from "../container/NotificationsViewFullJobHistory";
import { setLoader } from "app/actions/LoaderActions";
import {
  fetchAndSetUserAccessForDestinationPage,
  onClickDestinationCompanyLink,
  cancelProductJobRequest,
  fetchAndSetFullJobHistory,
  submitAsNewJob
} from "../network/actions";
import { setEntityName } from "app/actions/setEntityName";
import {
  setDisplaySideMenuPanel,
  selectQuickLink,
  selectTopLevelMenu,
  selectSideNavigationLevelMenu,
  setMenuParentName,
  getSideMenus,
  selectLeafSideNavigationLevelMenu,
  setDisplaySideMenuBar
} from "app/navigation/actions/GlobalNavigationActions";
import { selectCompany } from "app/navigation/actions/CompanySelectionActions";
import {
  editEntityData,
  clearEntityData
} from "app/common/reducers/entityData";
import { setSessionContext } from "app/actions/AppActions";
import { setJobException, clearJobException } from "../reducers/notifications";

const stateToProps = state => {
  const { entitySetup } = state.common;

  let companyStateList = [];
  let companySelectionList = [];
  if (
    state.navigationReducer.companySelectionList &&
    state.navigationReducer.companySelectionList.Data !== undefined
  ) {
    companySelectionList =
      state.navigationReducer.companySelectionList.Data[0].CompanySelectionList;

    if (companySelectionList.length > 0 || companySelectionList.DataCount > 0) {
      companyStateList = companySelectionList.map((item, index) => {
        return {
          value: item.UIId,
          label: item.SubscriberCompanyName,
          UIId: item.UIId,
          SubscriberCompanyId: item.SubscriberCompanyId,
          SubscriberId: item.SubscriberId
        };
      });
    }
  }
  return {
    allMenusList: state.allMenusList,
    menuState: state.menuState,
    companySelectionList: companyStateList,
    viewId: state.sessionContext.CurrentAppUiViewId,
    userTimeZoneOffset: state.navigationReducer.userTimeZoneOffset,
    userTimeZoneAbbreviation: state.navigationReducer.userTimeZoneAbbreviation,
    jobHistoryData: entitySetup.data,
    viewAllNotifications: state.viewAllNotifications
  };
};
const actionCreators = {
  fetchAndSetUserAccessForDestinationPage,
  setEntityName,
  setDisplaySideMenuPanel,
  selectQuickLink,
  selectTopLevelMenu,
  selectSideNavigationLevelMenu,
  setMenuParentName,
  getSideMenus,
  selectLeafSideNavigationLevelMenu,
  setDisplaySideMenuBar,
  selectCompany,
  onClickDestinationCompanyLink,
  cancelProductJobRequest,
  setLoader,
  editEntityData,
  clearEntityData,
  fetchAndSetFullJobHistory,
  submitAsNewJob,
  setSessionContext,
  setJobException,
  clearJobException
};
export default connect(
  stateToProps,
  actionCreators
)(NotificationsViewFullJobHistory);
