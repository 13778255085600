export const getAddNewNoteSchema = data => {
  const body = {
    EmployeeNotes: [
      {
        Note: data.note ? data.note : "",
        NoteDate: data.date ? data.date : "",
        NoteDiscription: data.description ? data.description : "",
        NoteTypeId: data.noteType ? data.noteType : ""
      }
    ]
  };
  return body;
};
