import axios from "axios";
import * as common from "../../app/common/index";
const config = { headers: { "Content-Type": "application/json" } };
const BASE_URL = common.WEB_API_MOCK_URL;

const getEmployeeFilesListPath = entityId =>
  `/v1/app/ui/activityhistory/${entityId}/search`;

export const getEmployeeFilesList = entityId =>
  axios.post(BASE_URL + getEmployeeFilesListPath(entityId), config);

//Change endpoint string when doing integration
export const deleteEmployeeFile = id =>
  axios.delete(
    BASE_URL + `/v1/subscriber/company/payrollschedule/${id}?_mock_=S`
  );

if (process.env.NODE_ENV === "development") {
  window.dashboard = {
    getEmployeeFilesList
  };
}
