import React from "react";
import { Modal } from "react-bootstrap";
import ModalHeader from "./../../../EmployeeProfileModal/ModalHeader";
import "./../../OverideModal.css";
import { FormSection, FormFieldGroup } from "component-library/formSections";

const EligibilityPeriodModal = ({ show, onCancel, onSave }) => {
  return (
    <Modal
      id="override-termination-modal"
      bsClass="override-termination-modal entity-page-cancel-modal modal"
      animation={false}
      backdrop={"static"}
      keyboard={false}
      show={show}
      onHide={onCancel}
      restoreFocus={false}
    >
      <ModalHeader onClose={onCancel} />
      <Modal.Body>
        <div id="override-termination-modal-body" className="modal-row">
          <div className="modal-header">Eligibility Determination Period</div>

          <div style={{ marginBottom: "20px" }}>
            You have not set an Eligibility Determination Period for your
            company which is needed for Netchex to perform FMLA leave
            calculations. Please note: The Deprtment of Labor states that
            employers should uniformly apply the same option to all employees
            taking FMLA leave.
          </div>
          <FormSection>
            <FormFieldGroup
              inputName="newLeaveReason"
              label="Name this new leave reason"
              description="Ex. Unqualified Leave, Personal Leave, etc."
              decriptionStyle="italic"
            />
          </FormSection>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          id="cancel-modal-footer"
          className="button-group"
          style={{ textAlign: "left" }}
        >
          <button
            type="button"
            id="save-modal-button"
            className="nc-button secondary-button"
            onClick={onSave}
          >
            Save
          </button>

          <button
            type="button"
            id="save-modal-button"
            className="nc-button secondary-link-button"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EligibilityPeriodModal;
