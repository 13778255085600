import React from "react";
import "./svg.css";
const EmployeeUpdates = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 38 49"
    style={{ enableBackground: "new 0 0 38 49" }}
    width={width}
    height={height}
  >
    <path
      className="st5"
      d="M22.3,40.7H5.4c-1.4,0-2.6-1.2-2.6-2.6V5.4C2.8,4,4,2.8,5.4,2.8h13.9V8c0,2.4,2,4.4,4.4,4.4h6.5v18.5c0.5-0.1,1-0.1,1.5-0.1c0.2,0,0.3,0,0.5,0V11.4c0-0.3-0.1-0.6-0.3-0.8L20.9,1.1c-0.2-0.2-0.4-0.2-0.7-0.2H5.4c-2.5,0-4.6,2.1-4.6,4.6v32.6c0,2.5,2.1,4.6,4.6,4.6h17.3C22.5,42,22.4,41.4,22.3,40.7z M21.3,4.1l7.2,6.3h-4.8c-1.3,0-2.4-1.1-2.4-2.4V4.1z"
    />
    <path
      className="st5"
      d="M35,36l-4-4.4l-0.6,1.9c-3.2,0.4-5.6,3.1-5.6,6.4c0,1.4,0.4,2.7,1.2,3.8c0.2,0.3,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2c0.4-0.3,0.5-0.9,0.2-1.4c-0.5-0.8-0.8-1.6-0.8-2.6c0-1.9,1.2-3.5,2.9-4.1l-0.5,1.6L35,36z"
    />
    <path
      className="st5"
      d="M37.5,39.8c0-1-0.3-2.1-0.7-3c-0.3-0.5-0.9-0.7-1.4-0.4c-0.5,0.3-0.7,0.9-0.4,1.4c0.3,0.6,0.5,1.3,0.5,2.1c0,1.7-1,3.2-2.5,4l0.3-1.7L27.7,44l4.5,3.9l0.4-2C35.5,45.4,37.5,42.8,37.5,39.8z"
    />
    <path
      className="st5"
      d="M18.1,24.2h-1c2.4-0.3,4.2-2.3,4.2-4.7c0-2.7-2.2-4.8-4.8-4.8s-4.8,2.2-4.8,4.8c0,2.4,1.8,4.4,4.2,4.7h-1c-4.1,0-7.5,3.4-7.5,7.5v3.5c0,0.6,0.4,1,1,1h14.8c0.6-1.2,1.4-2.3,2.4-3.2v-1.3C25.6,27.6,22.2,24.2,18.1,24.2z M13.7,19.5c0-1.5,1.3-2.8,2.8-2.8s2.8,1.3,2.8,2.8s-1.3,2.8-2.8,2.8S13.7,21,13.7,19.5z M23.6,34.2H9.4v-2.5c0-3,2.5-5.5,5.5-5.5h3.2c3,0,5.5,2.5,5.5,5.5V34.2z"
    />
  </svg>
);
export default EmployeeUpdates;
