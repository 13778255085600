import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import "./styles.css";
let treeviewSpanStyle = {
  width: "1rem",
  height: "1rem"
};

let treeviewSpanIndentStyle = treeviewSpanStyle;
treeviewSpanIndentStyle["marginLeft"] = "0px";
treeviewSpanIndentStyle["marginRight"] = "0px";

let treeviewSpanIconStyle = treeviewSpanStyle;
treeviewSpanIconStyle["marginLeft"] = "0px";
treeviewSpanIconStyle["marginRight"] = "0px";

class TreeView extends React.Component {
  constructor(props) {
    super(props);

    this.nodesQuantity = 0;

    /*this.state = {data: props.data};
     this.someData = _.clone(props.data);
     this.setNodeId({nodes: this.state.data});*/

    this.state = { data: this.setNodeId(_.clone({ nodes: props.data })) };

    this.findNodeById = this.findNodeById.bind(this);
    this.setChildrenState = this.setChildrenState.bind(this);
    this.setParentSelectable = this.setParentSelectable.bind(this);
    this.checkParentEmpty = this.checkParentEmpty.bind(this);
    this.nodeSelected = this.nodeSelected.bind(this);
    this.nodeDoubleClicked = this.nodeDoubleClicked.bind(this);
    this.addNode = this.addNode.bind(this);
    this.removeNode = this.removeNode.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: this.setNodeId(_.clone({ nodes: nextProps.data })) });
  }

  setNodeId(node) {
    if (!node.nodes) return;

    return node.nodes.map(childNode => {
      return {
        nodeId: this.nodesQuantity++,
        nodes: this.setNodeId(childNode),
        parentNode: node,
        nodeType: node.nodeType,
        state: {
          selected: childNode.state ? !!childNode.state.selected : false,
          expanded: childNode.state ? !!childNode.state.expanded : false
        },
        text: childNode.label,
        icon: childNode.icon
      };
    });
  }

  findNodeById(nodes, id) {
    let _this = this;
    let result;
    if (nodes)
      nodes.forEach(function(node) {
        if (node.nodeId === id) result = node;
        else {
          if (node.nodes) {
            result = _this.findNodeById(node.nodes, id) || result;
          }
        }
      });
    return result;
  }

  deleteById(obj, id) {
    if (!obj || obj.length <= 0) return [];
    let arr = [];
    _.each(obj, val => {
      if (val.nodes && val.nodes.length > 0)
        val.nodes = this.deleteById(val.nodes, id);

      if (val.nodeId !== id) {
        arr.push(val);
      }
    });
    return arr;
  }

  setChildrenState(nodes, state) {
    let _this = this;
    if (nodes)
      nodes.forEach(function(node) {
        node.state.selected = state;
        _this.setChildrenState(node.nodes, state);
      });
  }

  setParentSelectable(node) {
    if (!node.parentNode || !node.parentNode.state) return;
    node.parentNode.state.selected = true;
    this.setParentSelectable(node.parentNode);
  }

  checkParentEmpty(node) {
    let parent = node.parentNode;
    if (!parent.state || !parent.state.selected) return;
    if (parent.nodes.every(childNode => !childNode.state.selected)) {
      parent.state.selected = false;
      this.checkParentEmpty(parent);
    }
  }

  nodeSelected(nodeId, selected) {
    let node = this.findNodeById(this.state.data, nodeId);
    node.state.selected = selected;

    /*if (!selected)
     this.setParent(node);*/
    //this.setParentSelectable(node);
    /*else
     this.checkParentEmpty(node);*/

    this.setChildrenState(node.nodes, selected);
    this.setState({ data: this.state.data });

    if (this.props.onClick) this.props.onClick(this.state.data, node);
  }

  nodeDoubleClicked(nodeId, selected) {
    let node = this.findNodeById(this.state.data, nodeId);
    if (this.props.onDoubleClick)
      this.props.onDoubleClick(this.state.data, node);
  }

  convert(obj) {
    if (!obj || obj.length <= 0) return [];
    return _.map(obj, val => {
      let treeNodeData = {
        text: val.text,
        selected: val.state.selected
      };
      let children = this.convert(val.nodes);
      if (children.length > 0) treeNodeData.nodes = children;
      return treeNodeData;
    });
  }

  addNode(nodeId, text) {
    let node = this.findNodeById(this.state.data, nodeId);

    let newNode = {
      text: text,
      state: {},
      parentNode: node,
      nodeId: this.nodesQuantity++
    };

    if (node.nodes) {
      node.nodes.push(newNode);
    } else {
      node.nodes = [newNode];
    }

    console.log(this.convert(this.state.data));

    if (this.props.onNodeAdded) this.props.onNodeAdded(this.state.data);
  }

  removeNode(nodeId) {
    let newData = this.deleteById(_.clone(this.state.data), nodeId);
    if (newData.length === 0) return false;
    this.setState({ data: newData });
    if (this.props.onNodeRemoved) this.props.onNodeRemoved(newData);
  }

  render() {
    let data = this.state.data;
    let children = [];
    if (data) {
      let _this = this;
      data.forEach(function(node) {
        children.push(
          React.createElement(TreeNode, {
            node: node,
            key: node.nodeId,
            level: 1,
            visible: true,
            onSelectedStatusChanged: _this.nodeSelected,
            onNodeDoubleClicked: _this.nodeDoubleClicked,
            addNode: _this.addNode,
            removeNode: _this.removeNode,
            options: _this.props,
            nodes: _this.state.data,
            allowNew: _this.props.allowNew
          })
        );
      });
    }

    return (
      <div classID="treeview" className="treeview">
        <ul className="list-group">{children}</ul>
      </div>
    );
  }
}

TreeView.propTypes = {
  levels: PropTypes.number,
  expandIcon: PropTypes.string,
  selectable: PropTypes.bool,

  emptyIcon: PropTypes.string,
  nodeIcon: PropTypes.string,

  color: PropTypes.string,
  backColor: PropTypes.string,
  borderColor: PropTypes.string,
  onhoverColor: PropTypes.string,
  selectedColor: PropTypes.string,
  selectedBackColor: PropTypes.string,

  enableLinks: PropTypes.bool,
  highlightSelected: PropTypes.bool,
  showBorder: PropTypes.bool,
  showTags: PropTypes.bool,

  nodes: PropTypes.arrayOf(PropTypes.object)
};

TreeView.defaultProps = {
  levels: 2,
  selectable: true,

  expandIcon: "fa fa-plus-square-o",
  collapseIcon: "fa fa-minus-square-o",
  emptyIcon: "glyphicon",
  nodeIcon: "glyphicon glyphicon-stop",
  unselectedIcon: "glyphicon glyphicon-unchecked",
  selectedIcon: "glyphicon glyphicon-check",

  color: "#55555",
  backColor: undefined,
  borderColor: undefined,
  onhoverColor: "#F5F5F5",
  selectedColor: "#000000",
  selectedBackColor: "#FFFFFF",
  linkColor: "#009999",

  enableLinks: false,
  highlightSelected: true,
  showBorder: true,
  showTags: false,

  nodes: []
};

export class TreeNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = { node: props.node, expanded: true };
    /*this.expanded = (props.node.state && props.node.state.hasOwnProperty('expanded')) ?
     props.node.state.expanded :
     (this.props.level < this.props.options.levels);*/
    this.selected =
      props.node.state && props.node.state.hasOwnProperty("selected")
        ? props.node.state.selected
        : false;
    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.toggleSelected = this.toggleSelected.bind(this);
    this.doubleClicked = this.doubleClicked.bind(this);
    this.newNodeForm = this.newNodeForm.bind(this);
    this.addNode = this.addNode.bind(this);
    this.removeNode = this.removeNode.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ node: nextProps.node, expanded: true });
    /*this.expanded = (nextProps.node.state && nextProps.node.state.hasOwnProperty('expanded')) ?
     nextProps.node.state.expanded :
     (this.props.level < this.props.options.levels);*/
    this.selected =
      nextProps.node.state && nextProps.node.state.hasOwnProperty("selected")
        ? nextProps.node.state.selected
        : false;
  }

  toggleExpanded(event) {
    this.setState({ expanded: !this.state.expanded });
    event.stopPropagation();
  }

  toggleSelected(event) {
    let selected = !this.props.node.state.selected;
    this.props.onSelectedStatusChanged(this.state.node.nodeId, selected);
    event.stopPropagation();
  }

  doubleClicked(event) {
    let selected = !this.props.node.state.selected;
    this.props.onNodeDoubleClicked(this.state.node.nodeId, selected);
    event.stopPropagation();
  }

  newNodeForm(event) {
    this.setState({ addNode: !this.state.addNode });
    event.stopPropagation();
  }

  addNode(event) {
    if (!new RegExp("^[a-zA-Z0-9]+$").test(this.refs.newNodeName.value)) {
      this.refs.newNodeName.setCustomValidity("Incorrect format");
      return false;
    }
    this.setState({ addNode: false });
    console.log("ref", this.refs.newNodeName.value);
    this.props.addNode(this.state.node.nodeId, this.refs.newNodeName.value);
    this.setState({ expanded: true });
    event.stopPropagation();
  }

  removeNode(event) {
    this.props.removeNode(this.state.node.nodeId);
    event.stopPropagation();
  }

  render() {
    let node = _.clone(this.props.node);
    let options = _.clone(this.props.options);

    let style;

    if (this.props.options.selectable)
      node.icon = node.state.selected
        ? options.selectedIcon
        : options.unselectedIcon;

    if (!this.props.visible) {
      style = {
        display: "none"
      };
    } else {
      if (options.highlightSelected && node.state.selected) {
        style = {
          color: options.selectedColor,
          backgroundColor: options.selectedBackColor
        };
      } else {
        style = {
          color: node.color || options.color,
          backgroundColor: node.backColor || options.backColor
        };
      }

      if (!options.showBorder) {
        style.border = "none";
      } else if (options.borderColor) {
        style.border = "none";
      }
    }

    let indents = [];
    // for (let i = 0; i < this.props.level - 1; i++) {
    indents.push(
      <div className="indent-container">
        <div className={"indent"} />
        <div className={"indent"} />
      </div>
    );
    // }

    let expandCollapseIcon;
    if (node.nodes) {
      if (!this.state.expanded) {
        expandCollapseIcon = (
          <span onClick={this.toggleExpanded}>
            <i className={options.expandIcon}> </i>
          </span>
        );
      } else {
        expandCollapseIcon = (
          <span onClick={this.toggleExpanded}>
            <i className={options.collapseIcon} />
          </span>
        );
      }
    } else {
      expandCollapseIcon = <span className={options.emptyIcon}> </span>;
    }

    // let nodeIcon =
    //   node.icon || options.nodeIcon ? (
    //     <span
    //       className={"icon"}
    //       onClick={this.toggleSelected}
    //       style={treeviewSpanIconStyle}>
    //       {" "}
    //       <i className={node.icon || options.nodeIcon}> </i>{" "}
    //     </span>
    //   ) : (
    //     ""
    //   );

    let nodeText;
    if (!this.props.childCount) {
      if (this.props.level === 1) {
        let rootStyle = { fontWeight: "600", color: "#555" };

        nodeText = <span style={rootStyle}>{node.text}</span>;
      } else {
        nodeText = (
          <a href={node.href} style={{ color: "#009999" }}>
            {" "}
            {node.text}{" "}
          </a>
        );
      }
    } else {
      nodeText = (
        <span style={treeviewSpanStyle}>
          {" "}
          {node.text} ({this.props.childCount}){" "}
        </span>
      );
    }

    let badges;
    if (options.showTags && node.tags) {
      badges = node.tags.map(function(tag) {
        return (
          <span className={"badge"} style={treeviewSpanStyle}>
            {" "}
            {tag}{" "}
          </span>
        );
      });
    }

    let children = [];
    if (node.nodes) {
      let _this = this;
      node.nodes.forEach(node => {
        children.push(
          React.createElement(TreeNode, {
            node: node,
            key: node.nodeId,
            level: _this.props.level + 1,
            visible: _this.state.expanded && _this.props.visible,
            onSelectedStatusChanged: _this.props.onSelectedStatusChanged,
            onNodeDoubleClicked: _this.props.onNodeDoubleClicked,
            addNode: _this.props.addNode,
            removeNode: _this.props.removeNode,
            options: options,
            allowNew: _this.props.allowNew,
            childCount: node.nodes && node.nodes.length,
            nodeType: node.nodeType
          })
        );
      });
    }

    let addButton = this.props.allowNew ? (
      <span
        className="glyphicon glyphicon-plus addElement"
        style={{ float: "right", cursor: "pointer" }}
        onClick={this.newNodeForm}
      />
    ) : (
      ""
    );

    let removeButton = this.props.options.removable ? (
      <span
        className="glyphicon glyphicon-remove removeElement"
        style={{ cursor: "pointer" }}
        onClick={this.removeNode}
      />
    ) : (
      ""
    );

    let newNode;

    if (this.state.addNode) {
      newNode = (
        <div className="input-group">
          <input
            type="text"
            className="form-control nodeName"
            ref="newNodeName"
          />
          <span className="input-group-btn">
            <span className="btn btn-primary submitNode" onClick={this.addNode}>
              Add
            </span>
          </span>
        </div>
      );
    }

    style["cursor"] = "pointer";

    let treeNode = (
      <li
        className="tree-list-item"
        style={{
          ...style,
          paddingLeft:
            (this.props.level === 1 || this.props.level === 2) && "0px"
        }}
        onDoubleClick={this.doubleClicked}
        key={node.nodeId}
      >
        <div className="" style={{ paddingTop: "5px", paddingLeft: "10px" }}>
          {this.props.level !== 1 && indents}
          {expandCollapseIcon}
          {removeButton}
          {nodeText}
          {badges}
          {addButton}
          {newNode}
          {children}
        </div>
      </li>
    );
    return (
      <ul
        className="tree-list"
        style={{ borderLeft: this.props.level === 1 && "none" }}
      >
        {treeNode}
      </ul>
    );
  }
}

export default TreeView;
