/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { Button, Panel, Overlay } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { Badge } from "react-bootstrap";
import { getCategoryList } from "../../../actions/systemlevel/AdvanceFilterActions";
import { Checkbox, CheckboxGroup } from "react-checkbox-group";
import CustomOverlay from "component-library/dropDown/CustomOverlay";
import * as Id from "app/common/CommonIds";
import classnames from "classnames/bind";
const ALL_CATEGORIES = 0;
const CATEGORY_PANEL_ID = 2;

const garnishmentCategoryString =
  "Child Support, Bankruptcy, Student Loans, Tax Levy, etc.";
const insuranceCategory = "Medical, Dental, Vision, etc.";
const retirementCategoryString = "401(k), 403(b), SIMPLE IRA, etc.";
const retirementLoanCategoryString = "401(k) and 403(b) Loan Repayment";
const otherCategoryString =
  "Qualified Moving Expenses, Adoption Assistance Programs, Miscellaneous";

let isAllUncheckedZero = false;

class SystemLevelCategoryDropdown extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      selectedCategories: props.selectedCategories,
      selectCategoryLabel: "All Categories",
      isAllSelected: false
      //isAllUncheckedZero: false
    };

    this.onCloseCategoryDropdown = this.onCloseCategoryDropdown.bind(this);
    this.checkSelectCategory = this.checkSelectCategory.bind(this);
    this.renderCategorySelectionList = this.renderCategorySelectionList.bind(
      this
    );
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  checkSelectCategory(newCategory) {
    // let categoryLabel = (newCategory.length > 1 ? 'Categories' : 'Category');     this condition has been modified while working on bug-18403
    // let categoryLabel = (newCategory.length >= 1 ? 'Categories' : 'Select Category');

    let categoryLabel =
      newCategory.length > 1
        ? "Categories"
        : newCategory.length === 0
        ? "Select Category"
        : "Category";

    if (newCategory.includes(ALL_CATEGORIES)) {
      var indexOfAllCategory = newCategory.indexOf(ALL_CATEGORIES);
      if (
        indexOfAllCategory !== 0 ||
        this.state.selectedCategories.length === 0
      ) {
        newCategory = [];
        for (let i = 0; i < this.props.systemLevelCategoryList.length; i++) {
          let DeductionCategoryId = this.props.systemLevelCategoryList[i]
            .DeductionCategoryId;
          newCategory.push(DeductionCategoryId);
        }
        this.setState({
          selectedCategories: newCategory,
          selectCategoryLabel: "All Categories",
          isAllSelected: true
        });
      } else {
        newCategory.splice(0, 1);
        this.setState({
          selectedCategories: newCategory,
          selectCategoryLabel: categoryLabel,
          isAllSelected: false
        });
      }
    } else if (this.state.isAllSelected && !newCategory.includes(0)) {
      this.setState({
        selectedCategories: [],
        selectCategoryLabel: "Select Category",
        isAllSelected: false
      });
    } else {
      if (
        this.props.systemLevelCategoryList.length - 1 === newCategory.length &&
        !newCategory.includes(ALL_CATEGORIES)
      ) {
        newCategory = [];
        for (let i = 0; i < this.props.systemLevelCategoryList.length; i++) {
          let DeductionCategoryId = this.props.systemLevelCategoryList[i]
            .DeductionCategoryId;
          newCategory.push(DeductionCategoryId);
        }
        this.setState({
          selectedCategories: newCategory,
          selectCategoryLabel: "All Categories",
          isAllSelected: true
        });
      } else {
        this.setState({
          selectedCategories: newCategory,
          selectCategoryLabel: categoryLabel,
          isAllSelected: false
        });
      }
    }

    if (
      newCategory.length === 0 ||
      (this.state.isAllSelected === true &&
        newCategory.includes(ALL_CATEGORIES) === false &&
        newCategory.indexOf(ALL_CATEGORIES) === -1 &&
        newCategory.length === this.props.systemLevelCategoryList.length - 1)
    ) {
      isAllUncheckedZero = true;
    } else {
      isAllUncheckedZero = false;
    }
  }

  componentWillMount() {
    this.setState({
      isAllSelected: true
    });
    // this.props.getCategoryList();
    // this.props.onSelectedCategories(this.state.selectedCategories);
  }

  componentDidMount() {
    let selectedCategories = [];
    // selectedCategories[0] = 0;

    if (
      this.props.systemLevelSelectedFiltersList !== undefined &&
      this.props.systemLevelSelectedFiltersList !== null &&
      this.props.systemLevelSelectedFiltersList.length !== 0
    ) {
      if (
        this.props.systemLevelSelectedFiltersList.selectedCategories.length > 0
      ) {
        this.props.systemLevelSelectedFiltersList.selectedCategories.forEach(
          selectedItem => {
            selectedCategories.push(selectedItem);
          }
        );
      }
    } else {
      // selectedCategories[0] = 0;
      if (this.props.systemLevelCategoryOriginalList.Data) {
        selectedCategories[0] = 0;
        this.props.systemLevelCategoryOriginalList.Data.forEach(item => {
          selectedCategories.push(item.DeductionCategoryId);
        });
      }
    }

    let selectCategoryLabel = "Categories";
    let isAllSelected = false;

    if (
      this.props.systemLevelCategoryList.length === selectedCategories.length
    ) {
      selectCategoryLabel = "All Categories";
      isAllSelected = true;
    } else if (selectedCategories.length === 0) {
      selectCategoryLabel = "Select Category";
      isAllSelected = false;
    } else if (selectedCategories.length === 1) {
      selectCategoryLabel = "Category";
      isAllSelected = false;
    } else {
      selectCategoryLabel = "Categories";
      isAllSelected = false;
    }

    this.setState({
      selectedCategories,
      selectCategoryLabel,
      isAllSelected
    });

    this.props.onSelectedCategories(selectedCategories);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.systemLevelCategoryOriginalList !==
      this.props.systemLevelCategoryOriginalList
    ) {
      let selectedCategories = [];
      selectedCategories[0] = 0;
      if (nextProps.systemLevelCategoryOriginalList.Data) {
        nextProps.systemLevelCategoryOriginalList.Data.forEach(item => {
          selectedCategories.push(item.DeductionCategoryId);
        });
      }

      let selectCategoryLabel = "Categories";
      let isAllSelected = false;
      if (
        nextProps.systemLevelCategoryOriginalList.DataCount + 1 ===
        selectedCategories.length
      ) {
        selectCategoryLabel = "All Categories";
        isAllSelected = true;
      } else {
        selectCategoryLabel = "Categories";
        isAllSelected = false;
      }

      this.setState({
        selectedCategories,
        selectCategoryLabel,
        isAllSelected
      });
    }

    if (nextProps.isResetToDefault !== this.props.isResetToDefault) {
      if (nextProps.isResetToDefault) {
        let selectedCategories = [];
        selectedCategories[0] = 0;
        if (nextProps.systemLevelCategoryOriginalList.Data) {
          nextProps.systemLevelCategoryOriginalList.Data.forEach(item => {
            selectedCategories.push(item.DeductionCategoryId);
          });
        }

        let selectCategoryLabel = "Categories";
        let isAllSelected = false;

        if (
          nextProps.systemLevelCategoryOriginalList.DataCount + 1 ===
          selectedCategories.length
        ) {
          selectCategoryLabel = "All Categories";
          isAllSelected = true;
        } else {
          selectCategoryLabel = "Categories";
          isAllSelected = false;
        }

        this.setState({
          selectedCategories,
          selectCategoryLabel,
          isAllSelected
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedCategories !== this.state.selectedCategories) {
      this.props.onSelectedCategories(this.state.selectedCategories);
    }
  }

  onCloseCategoryDropdown() {
    this.props.onSelectedCategories(this.state.selectedCategories);
  }

  renderCategorySelectionListMobile() {
    if (!this.props.systemLevelCategoryList) {
      return <div />;
    } else {
      return (
        <Scrollbars
          autoHeight={true}
          autoHeightMax={320}
          renderThumbHorizontal={props => (
            <div style={{ overflowX: "hidden" }} className="thumb-horizontal" />
          )}
        >
          <CheckboxGroup
            name="select-category"
            value={this.state.selectedCategories}
            onChange={this.checkSelectCategory}
          >
            <ul className="nc-dropdown-navigation-multiselect">
              {this.renderCategorySelectionItem(
                this.props.systemLevelCategoryList
              )}
            </ul>
          </CheckboxGroup>
        </Scrollbars>
      );
    }
  }

  renderCategorySelectionList() {
    if (!this.props.systemLevelCategoryList) {
      return <div />;
    } else {
      return (
        <CustomOverlay>
          <Scrollbars
            autoHeight={true}
            autoHeightMax={320}
            renderThumbHorizontal={props => (
              <div
                style={{ overflowX: "hidden" }}
                className="thumb-horizontal"
              />
            )}
          >
            <CheckboxGroup
              name="select-category"
              value={this.state.selectedCategories}
              onChange={this.checkSelectCategory}
            >
              <ul className="nc-dropdown-navigation-multiselect">
                {this.renderCategorySelectionItem(
                  this.props.systemLevelCategoryList
                )}
              </ul>
            </CheckboxGroup>
          </Scrollbars>
        </CustomOverlay>
      );
    }
  }

  renderCategorySelectionItem(list) {
    return list.map((item, index) => {
      return (
        <li
          id={Id.categoryFilter + index}
          key={item.Name + item.DeductionCategoryId}
          className="list-item"
        >
          <Checkbox
            id={item.Name + item.DeductionCategoryId}
            value={item.DeductionCategoryId}
          />

          <label
            htmlFor={item.Name + item.DeductionCategoryId}
            id={item.Name.replace(/ /g, "-").toLowerCase() + "-category"}
          >
            {item.Name}
          </label>
          <div className="hidden-lg hidden-md tooltip-mobile-filter">
            {item.Name === "Garnishment" && garnishmentCategoryString}
            {item.Name === "Retirement Loan" && retirementLoanCategoryString}
            {item.Name === "Insurance" && insuranceCategory}
            {item.Name === "Retirement" && retirementCategoryString}
            {item.Name === "Other" && otherCategoryString}
          </div>
        </li>
      );
    });
  }

  openSubPanelById(id) {
    let update = {};

    if (id === this.state.subPanelId) {
      update["subPanelId"] = 0;
    } else {
      update["subPanelId"] = id;
    }

    this.setState(update);
  }

  render() {
    if (!this.props.systemLevelCategoryList) {
      return <div>No Records Found.</div>;
    }
    if (window.innerWidth <= 991) {
      const renderCategorySelectionListMobile = this.renderCategorySelectionListMobile();

      return (
        <div>
          <div className="mobile-filter-panel-list-section">
            {/* <OverlayTrigger placement="top" overlay={tooltip_edit_icon}> */}
            <a
              id=""
              onClick={this.openSubPanelById.bind(this, CATEGORY_PANEL_ID)}
            >
              <div className="mobile-filter-panel-list-panel-bar col-xs-12 col-sm-12">
                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-icon">
                  {this.state.subPanelId === CATEGORY_PANEL_ID ? (
                    <i
                      className="fa fa-chevron-up add_deduction_popup_panel_link"
                      aria-hidden="true"
                    />
                  ) : (
                    <i
                      className="fa fa-chevron-down add_deduction_popup_panel_link"
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="col-xs-8 col-sm-8 mobile-filter-panel-list-label no-padding">
                  {this.state.selectCategoryLabel}
                </div>
                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-badge no-padding">
                  {isAllUncheckedZero ? null : this.state
                      .isAllSelected ? null : (
                    <Badge
                      id={Id.filterCountMobile}
                      className="filter-select-badge"
                    >
                      {this.state.selectedCategories.length}
                    </Badge>
                  )}
                </div>
              </div>
            </a>
            {/* </OverlayTrigger> */}
            <div className="mobile-filter-panel-list-panel-bar-section">
              <Panel
                className="mobile-filter-panel-list-panel-default no-padding col-xs-12 col-sm-12 col-md-12 col-lg-12 "
                collapsible
                expanded={
                  this.state.subPanelId === CATEGORY_PANEL_ID ? true : false
                }
              >
                <Panel.Collapse>
                  <Panel.Body>{renderCategorySelectionListMobile}</Panel.Body>
                </Panel.Collapse>
              </Panel>
            </div>
          </div>
        </div>
      );
    } else {
      const categorySelectionPanel = this.renderCategorySelectionList();

      return (
        <div>
          <div className="nc-select-container hidden-sm hidden-xs">
            <Button
              id={Id.categoryFilter}
              onClick={this.toggle}
              className=""
              ref={button => (this.categoryFilter = button)}
            >
              <span className="selected-value">
                {this.state.selectCategoryLabel}
              </span>
              <i
                className={classnames(
                  "pull-right fa",
                  this.state.popoverOpen ? "fa-chevron-up" : "fa-chevron-down"
                )}
              />
              {isAllUncheckedZero ? null : this.state.isAllSelected ? null : (
                <Badge
                  id={Id.categoryFilter + Id.filterCountMobile}
                  className="filter-select-badge pull-right badge"
                >
                  {this.state.selectedCategories.length}
                </Badge>
              )}
            </Button>
          </div>
          <Overlay
            show={this.state.popoverOpen}
            onHide={() => this.setState({ popoverOpen: false })}
            placement="bottom"
            container={this}
            rootClose={true}
            target={this.categoryFilter}
          >
            {categorySelectionPanel}
          </Overlay>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  let list = [
    {
      DeductionCategoryId: 0,
      Name: "All Categories"
    }
  ];

  let selectedCategories = [];
  selectedCategories[0] = 0;
  if (state.systemLevelCategoryList.Data) {
    state.systemLevelCategoryList.Data.forEach(item => {
      list.push(item);
      selectedCategories.push(item.DeductionCategoryId);
    });
  }

  return {
    systemLevelCategoryList: list,
    systemLevelCategoryOriginalList: state.systemLevelCategoryList,
    selectedCategories: selectedCategories,
    systemLevelSelectedFiltersList: state.systemLevelSelectedFiltersList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getCategoryList: getCategoryList }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemLevelCategoryDropdown);
