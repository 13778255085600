/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Panel, Overlay } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Badge } from "react-bootstrap";
import { Checkbox, CheckboxGroup } from "react-checkbox-group";
import { Scrollbars } from "react-custom-scrollbars";
import CustomOverlay from "component-library/dropDown/CustomOverlay";
const DEDUCTION_PANEL_ID = 1;

let isAllUncheckedZero = false;

var ledgerReportDropDown = [
  {
    id: 0,
    title: "include-in-gl-report",
    value: true,
    label: (
      <span>
        <i
          className="report-dropdown-yes-mark fa fa-check-circle-o"
          aria-hidden="true"
        />{" "}
        Include in GL Report
      </span>
    )
  },
  {
    id: 1,
    title: "exclude-from-gl-report",
    value: false,
    label: (
      <span>
        <i
          className="report-dropdown-no-mark fa fa-times-circle-o"
          aria-hidden="true"
        />{" "}
        Exclude from GL Report
      </span>
    )
  }
];

class GeneralLedgerReportDropDown extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      popoverOpen: false,
      selectCategoryLabel: "Include in GL Report",
      isAllSelected: false,
      selectedGLCategories: [true, false]
    };

    this.renderCategorySelectionList = this.renderCategorySelectionList.bind(
      this
    );

    this.setGeneralLedgerReportContext = this.setGeneralLedgerReportContext.bind(
      this
    );
    this.toggle = this.toggle.bind(this);
    this.onCloseCategoryDropdown = this.onCloseCategoryDropdown.bind(this);
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  setGeneralLedgerReportContext(newValue) {
    this.setState({ selectedGLCategories: newValue });
  }

  componentWillMount() {
    this.props.onSelectedCategories(this.state.selectedGLCategories);
  }

  componentDidMount() {
    let selectedGLCategories = [];
    this.props.isInitialLoad(false);
    if (
      this.props.setSelectedFiltersReducer !== undefined &&
      this.props.setSelectedFiltersReducer !== null &&
      this.props.setSelectedFiltersReducer.length !== 0
    ) {
      if (
        this.props.setSelectedFiltersReducer.selectedGLCategories.length > 0
      ) {
        this.setState({
          selectedGLCategories: this.props.setSelectedFiltersReducer
            .selectedGLCategories
        });
        this.props.onSelectedCategories(selectedGLCategories);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isResetToDefault !== this.props.isResetToDefault) {
      if (nextProps.isResetToDefault) {
        this.setState({
          selectedGLCategories: [true, false]
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedGLCategories !== this.state.selectedGLCategories) {
      this.props.onSelectedCategories(this.state.selectedGLCategories);
    }
  }

  onCloseCategoryDropdown() {
    this.props.onSelectedCategories(this.state.selectedGLCategories);
  }

  renderCategorySelectionListMobile() {
    return (
      <Scrollbars
        autoHeight={true}
        autoHeightMax={320}
        renderThumbHorizontal={props => (
          <div style={{ overflowX: "hidden" }} className="thumb-horizontal" />
        )}
      >
        <CheckboxGroup
          name="select-category"
          value={this.state.selectedGLCategories}
          onChange={this.setGeneralLedgerReportContext}
          className=""
        >
          <ul className="nc-dropdown-navigation-multiselect">
            {this.renderCategorySelectionItem(ledgerReportDropDown)}
          </ul>
        </CheckboxGroup>
      </Scrollbars>
    );
    // }
  }

  renderCategorySelectionList() {
    return (
      <CustomOverlay>
        <Scrollbars
          autoHeight={true}
          autoHeightMax={320}
          renderThumbHorizontal={props => (
            <div style={{ overflowX: "hidden" }} className="thumb-horizontal" />
          )}
        >
          <CheckboxGroup
            name="select-category"
            value={this.state.selectedGLCategories}
            onChange={this.setGeneralLedgerReportContext}
            className=""
          >
            <ul className="nc-dropdown-navigation-multiselect">
              {this.renderCategorySelectionItem(ledgerReportDropDown)}
            </ul>
          </CheckboxGroup>
        </Scrollbars>
      </CustomOverlay>
    );
  }

  renderCategorySelectionItem(list) {
    return list.map(item => {
      return (
        <li key={item.label + item.value} className="list-item">
          <Checkbox id={item.value.toString()} value={item.value} />
          <label id={item.title} htmlFor={item.value.toString()}>
            {item.label}
          </label>
        </li>
      );
    });
  }

  openSubPanelById(id) {
    let update = {};

    if (id === this.state.subPanelId) {
      update["subPanelId"] = 0;
    } else {
      update["subPanelId"] = id;
    }

    this.setState(update);
  }

  onFilterOverlayHide = () => {
    this.setState({
      popoverOpen: false
    });
  };

  render() {
    const categorySelectionPanel = this.renderCategorySelectionList();

    if (!ledgerReportDropDown) {
      return <div>No Records Found.</div>;
    }
    if (window.innerWidth <= 991) {
      return (
        <div>
          <div className="mobile-filter-panel-list-section">
            <a
              id=""
              onClick={this.openSubPanelById.bind(this, DEDUCTION_PANEL_ID)}
            >
              <div className="mobile-filter-panel-list-panel-bar col-xs-12 col-sm-12">
                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-icon">
                  {this.state.subPanelId === DEDUCTION_PANEL_ID ? (
                    <i
                      className="fa fa-chevron-up add_deduction_popup_panel_link"
                      aria-hidden="true"
                    />
                  ) : (
                    <i
                      className="fa fa-chevron-down add_deduction_popup_panel_link"
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="col-xs-8 col-sm-8 mobile-filter-panel-list-label no-padding">
                  {this.state.selectCategoryLabel}
                </div>
                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-badge no-padding">
                  {isAllUncheckedZero ? null : this.state
                      .isAllSelected ? null : (
                    <Badge className="filter-select-badge">
                      {this.state.selectedGLCategories.length}
                    </Badge>
                  )}
                </div>
              </div>
            </a>
            <div className="mobile-filter-panel-list-panel-bar-section">
              <Panel
                className="mobile-filter-panel-list-panel-default no-padding col-xs-12 col-sm-12 col-md-12 col-lg-12 "
                collapsible
                expanded={
                  this.state.subPanelId === DEDUCTION_PANEL_ID ? true : false
                }
              >
                <Panel.Collapse>
                  <Panel.Body>
                    {this.renderCategorySelectionListMobile()}
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="nc-select-container hidden-sm hidden-xs">
            <Button
              id={this.props.Id}
              className="filter-select-button"
              onClick={this.toggle}
              ref={button => (this.reportFilter = button)}
            >
              <div
                style={{
                  width: isAllUncheckedZero
                    ? "90%"
                    : this.state.isAllSelected
                    ? "90%"
                    : "70%"
                }}
                className="filter-select-label col-md-8 col-lg-8"
              >
                {this.state.selectCategoryLabel}
              </div>
              {isAllUncheckedZero ? null : this.state
                  .isAllSelected ? null : this.state.selectedGLCategories !==
                undefined ? (
                <div style={{ width: "20%" }} className="col-md-2 col-lg-2">
                  <Badge className="filter-select-badge">
                    {this.state.selectedGLCategories.length}
                  </Badge>
                </div>
              ) : null}
              <div style={{ width: "10%" }} className="col-md-1 col-lg-1">
                <i className="filter-select-dropdown-icon fa fa-chevron-down" />
              </div>
            </Button>
          </div>
          <Overlay
            show={this.state.popoverOpen}
            onHide={() => this.setState({ popoverOpen: false })}
            placement="bottom"
            container={this}
            rootClose={true}
            target={this.reportFilter}
          >
            {categorySelectionPanel}
          </Overlay>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    setSelectedFiltersReducer:
      state.generalLedgerReducer.setSelectedFiltersReducer
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GeneralLedgerReportDropDown)
);
