import Loadable from "react-loadable";
import ChunkLoading from "app/common/UIComponents/ChunkLoading";

export const EarningsGroupListPage = Loadable({
  loader: () =>
    import("./pages" /* webpackChunkName: "earningsGroup" */).then(
      m => m.EarningsGroupListPage
    ),
  loading: ChunkLoading
});

export const EarningsGroupAddPage = Loadable({
  loader: () =>
    import("./pages" /* webpackChunkName: "earningsGroup" */).then(
      m => m.EarningsGroupAddPage
    ),
  loading: ChunkLoading
});
