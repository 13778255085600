import React from "react";
import { FormFieldGroup, FormSection } from "../../../formSections";
import * as Fields from "./FieldNames";

const EmployeeIdentification = ({ onChange, data, options = {} }) => {
  return (
    <FormSection>
      <div className="column-title">Alternate Employee Identification</div>

      <FormFieldGroup
        inputType="text"
        onChange={onChange}
        inputName={Fields.employeeBadgeNumber}
        value={data[Fields.employeeBadgeNumber]}
        label="Badge Number"
        Size={[12, 12, 7, 12]}
      />
      <FormFieldGroup
        inputType="text"
        onChange={onChange}
        inputName={Fields.clockNumber}
        value={data[Fields.clockNumber]}
        label="Clock Number"
        Size={[12, 12, 7, 12]}
      />
      <FormFieldGroup
        inputType="text"
        onChange={onChange}
        inputName={Fields.employeeNumber}
        value={data[Fields.employeeNumber]}
        label="Employee Number"
        Size={[12, 12, 7, 12]}
      />

      <FormFieldGroup
        inputType="text"
        onChange={onChange}
        inputName={Fields.employeeCode}
        value={data[Fields.employeeCode]}
        label="Employee Code"
        Size={[12, 12, 7, 12]}
      />
    </FormSection>
  );
};

export default EmployeeIdentification;
EmployeeIdentification.defaultProps = {
  onChange: () => {},
  data: {},
  options: {}
};
