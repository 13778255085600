import TitleBand from "./TitleBand";
import EarningsButtons from "./EarningsButtons";
import EarningsDescription from "./EarningsDescription";
import EarningsCalculationMethod from "./EarningsCalculationMethod";
import EarningsPayCycle from "./EarningsPayCycle";
import EarningsOptions from "./EarningsOptions";
import EarningsReviewandSave from "./EarningsReviewandSave";
import EarningsSetupProgress from "./../setupProgress/index";
import SetupPageContainer from "./../entity-setup/EntityPageContainer";
import EarningsSetup from "./EarningsSetup";
import Seperator from "./Seperator";
export {
  EarningsSetup,
  EarningsButtons,
  EarningsDescription,
  EarningsCalculationMethod,
  EarningsPayCycle,
  EarningsOptions,
  EarningsReviewandSave,
  TitleBand,
  EarningsSetupProgress,
  SetupPageContainer,
  Seperator
};

export const SUBCATEGORY_FILTER_NAME = "subcategory";
export const TAX_OPTIONS = "taxoptions";
export const TAX_OPTIONS_EXCLUDED = "taxoptionsexcluded";
export const CALCULATION_METHODS = "calculationMethods";
export const EARNING_CYCLES = "earningCycles";
export const EARNING_SUB_CYCLES = "earningSubcycles";
export const TEMPLATE = "template";
export const HOURLY_RATES = "hourlyRates";
export const EARNING_GROUPS = "earningGroup";
