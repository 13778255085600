import * as types from "../actions/index";
export default function(state = [], action) {
  switch (action.type) {
    case types.FETCH_ALL_MENUS: {
      return action.payload.data;
      // return action.payload;
    }
    default:
      return state;
  }
}
