export default function tabItemsList(data = {}) {
  return [
    {
      id: 1,
      url: require("../../component-library/payroll-dashboard/images/unapproved_pto.png"),
      Name: "Unappoved PTO",
      BadgeCount: data.CountOfUnapprovedPtos || 0,
      title: "01_unapproved-pto",
      link: "/payroll/checklist/unapproved-pto/",
      disabled: false
    },
    {
      id: 2,
      url: require("../../component-library/payroll-dashboard/images/timecard_alerts.png"),
      Name: "Timecard Alerts",
      BadgeCount: data.CountOfTimeCardAlerts || 0,
      title: "01_timecard-alerts",
      link: "/payroll/checklist/timecard-alerts/",
      disabled: false
    },
    {
      id: 3,
      url: require("../../component-library/payroll-dashboard/images/employee_updates.png"),
      Name: "Employee Updates",
      BadgeCount: data.CountOfEmployeeUpdates || 0,
      title: "01_employee-updates",
      link: "/payroll/checklist/employee-updates/",
      disabled: false
    },
    {
      id: 4,
      url: require("../../component-library/payroll-dashboard/images/timecard_alerts.png"),
      Name: "Address Verifications",
      BadgeCount: data.CountOfAddressVerifications || 0,
      title: "01_address-verifications",
      link: "/payroll/checklist/address-verifications/",
      disabled: false
    },
    {
      id: 5,
      url: require("../../component-library/payroll-dashboard/images/employee_updates.png"),
      Name: "Minimum Wage Alerts",
      BadgeCount: data.CountOfMinimumWageAlert || 0,
      title: "01_minimum-wage-alerts",
      link: "/payroll/checklist/minimum-wage-alerts/",
      disabled: false
    },
    {
      id: 6,
      url: require("../../component-library/payroll-dashboard/images/employee_updates.png"),
      Name: "Tax Verifications",
      BadgeCount: data.CountOfTaxVerification || 0,
      title: "01_tax-verifications",
      link: "/payroll/checklist/tax-verifications/",
      disabled: false
    }
  ];
}
