import React, { Component } from "react";
import "./DeductionStyles.css";
import "./newMobile.css";
import { withRouter } from "react-router-dom";
import * as Id from "app/common/CommonIds";
class DeductionSetupDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.redirectToSystemLevelDeductionDescription = this.redirectToSystemLevelDeductionDescription.bind(
      this
    );
  }

  redirectToSystemLevelDeductionDescription() {
    this.props.history.push("/system-level-deduction-description");
  }
  render() {
    // Added mobile page title class
    return (
      <div id="deduction-page-header-container">
        <div className="pageTitle-container row col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div
            id={Id.pageTitle}
            className="pageTitle col-lg-8 col-md-7 col-sm-8 col-xs-12"
          >
            Deduction Templates
          </div>

          <div className="nc-navigation-button-family hidden-sm hidden-xs col-lg-4 col-md-5 col-sm-12 col-xs-12">
            {this.props.isListPage ? (
              <div>
                <button
                  id={Id.historyButton}
                  className="nc-button outline-button-right pull-right"
                  onClick={() =>
                    this.props.history.push(
                      "/system-level-deduction-activity-history"
                    )
                  }
                >
                  <i className="fa fa-history" aria-hidden="true" />HISTORY
                </button>

                <button
                  id={Id.addButton}
                  type="button"
                  className="nc-button primary-button pull-right"
                  onClick={this.redirectToSystemLevelDeductionDescription}
                >
                  <i className="fa fa-plus"> </i> ADD
                </button>
              </div>
            ) : null}
          </div>
        </div>

        <div className="deduction-page-description-container">
          <div
            id={Id.pageDescription}
            className="page-description col-lg-7 col-md-7 col-sm-12 col-xs-12"
          >
            Deductions are withheld from employees' paychecks to allow an
            employer to remit payment for insurance premiums (e.g. medical,
            dental, vision), retirement (e.g. 401(k), 403(b), SIMPLE IRA),
            garnishments (e.g. unpaid child support, bankruptcy debts, defaulted
            student loans), etc. Deductions must be created at the company level
            and in an active state before they can be assigned to an employee.
          </div>
          {this.props.isListPage && (
            <div className="deduction-page-description-button col-lg-5 col-md-5 col-sm-12 col-xs-12">
              <div className="nc-navigation-button-family col-xs-12 hidden-lg hidden-md">
                <div className="primary-group">
                  <button
                    id={Id.addButton}
                    type="button"
                    className="nc-button primary-button"
                    onClick={this.redirectToSystemLevelDeductionDescription}
                  >
                    <i className="fa fa-plus" />
                    <span className="hidden-lg hidden-md ">&nbsp; ADD</span>
                  </button>
                </div>
                <div className="secondary-group">
                  <button
                    id={Id.historyButton}
                    className="nc-button outline-fa-button"
                    onClick={() =>
                      this.props.history.push(
                        "/system-level-deduction-activity-history"
                      )
                    }
                  >
                    <i className="fa fa-history" aria-hidden="true" />
                    <span className="hidden-lg hidden-md ">&nbsp; HISTORY</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(DeductionSetupDescription);
