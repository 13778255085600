export const SET_FROM_OPTIONS = "@copy-entity/SET_FROM_OPTIONS";
export const SELECT_FROM_OPTION = "@copy-entity/SELECT_FROM_OPTION";
export const CLEAR_FROM_OPTIONS = "@copy-entity/CLEAR_FROM_OPTIONS";
export const SET_TO_OPTIONS = "@copy-entity/SET_TO_OPTIONS";
export const SELECT_TO_OPTION = "@copy-entity/SELECT_TO_OPTION";
export const SELECT_ALL_TO_OPTIONS = "@copy-entity/SELECT_ALL_TO_OPTIONS";
export const CLEAR_TO_OPTIONS = "@copy-entity/CLEAR_TO_OPTIONS";
export const SET_ENTITY_OPTIONS = "@copy-entity/SET_ENTITY_OPTIONS";
export const SELECT_ENTITY_OPTION = "@copy-entity/SELECT_ENTITY_OPTION";
export const SELECT_ALL_ENTITY_OPTIONS =
  "@copy-entity/SELECT_ALL_ENTITY_OPTIONS";
export const CLEAR_ENTITY_OPTIONS = "@copy-entity/CLEAR_ENTITY_OPTIONS";
export const CLEAR_COPY_ENTITY = "@copy-entity/CLEAR_COPY_ENTITY";
export const SET_COPY_ENTITY_RESULT = "@copy-entity/SET_COPY_ENTITY_RESULT";
export const CLEAR_COPY_ENTITY_RESULT = "@copy-entity/CLEAR_COPY_ENTITY_RESULT";

const initialFromState = {
  fromLoading: true,
  fromOptions: [],
  fromValue: undefined
};

const initialToState = {
  toLoading: true,
  toOptions: [],
  toSelected: {}
};

const resetToState = {
  toLoading: true,
  toSelected: {}
};

const initialEntityState = {
  entityLoading: true,
  entityOptions: [],
  entitySelected: {}
};

const resetEntityState = {
  entityLoading: false,
  entitySelected: {}
};

const initialResultState = {
  hasResult: null,
  result: {}
};

export const initialState = {
  ...initialFromState,
  ...initialToState,
  ...initialEntityState,
  ...initialResultState
};

const makeAllSelected = (options = [], selected = true, fromValue = 0) =>
  options.reduce((res, op) => {
    if (fromValue !== op.value) {
      res[op.value] = selected;
    }
    return res;
  }, {});

const copyEntity = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FROM_OPTIONS:
      return {
        ...state,
        fromLoading: false,
        fromOptions: payload
      };

    case SELECT_FROM_OPTION:
      return {
        ...state,
        fromValue: payload
      };

    case CLEAR_FROM_OPTIONS:
      return {
        ...state,
        ...initialFromState
      };

    case SET_TO_OPTIONS:
      return {
        ...state,
        toLoading: false,
        toOptions: payload
      };

    case SELECT_TO_OPTION: {
      const { value, selected } = payload;
      return {
        ...state,
        toSelected: {
          ...state.toSelected,
          [value]: selected
        }
      };
    }

    case SELECT_ALL_TO_OPTIONS:
      return {
        ...state,
        toSelected: makeAllSelected(state.toOptions, payload, state.fromValue)
      };

    case CLEAR_TO_OPTIONS:
      return {
        ...state,
        ...resetToState
      };

    case SET_ENTITY_OPTIONS:
      return {
        ...state,
        entityLoading: false,
        entityOptions: payload
      };

    case SELECT_ENTITY_OPTION: {
      const { value, selected } = payload;
      return {
        ...state,
        entitySelected: {
          ...state.entitySelected,
          [value]: selected
        }
      };
    }

    case SELECT_ALL_ENTITY_OPTIONS:
      return {
        ...state,
        entitySelected: makeAllSelected(payload.options, payload.selected)
      };

    case CLEAR_ENTITY_OPTIONS:
      return {
        ...state,
        ...resetEntityState
      };

    case SET_COPY_ENTITY_RESULT:
      return {
        ...state,
        hasResult: payload.status === "failed" ? false : true,
        result: payload
      };

    case CLEAR_COPY_ENTITY_RESULT:
      return {
        ...state,
        ...initialResultState
      };

    case CLEAR_COPY_ENTITY:
      return initialState;

    default:
      return state;
  }
};

export default copyEntity;

export const setFromOptions = options => ({
  type: SET_FROM_OPTIONS,
  payload: options
});

export const selectFromOption = value => ({
  type: SELECT_FROM_OPTION,
  payload: value
});

export const clearFromOptions = () => ({
  type: CLEAR_FROM_OPTIONS
});

export const setToOptions = options => ({
  type: SET_TO_OPTIONS,
  payload: options
});

export const selectToOption = (value, selected) => ({
  type: SELECT_TO_OPTION,
  payload: { value, selected }
});

export const selectAllToOptions = selected => ({
  type: SELECT_ALL_TO_OPTIONS,
  payload: selected
});

export const clearToOptions = () => ({
  type: CLEAR_TO_OPTIONS
});

export const setEntityOptions = options => ({
  type: SET_ENTITY_OPTIONS,
  payload: options
});

export const selectEntityOption = (value, selected) => ({
  type: SELECT_ENTITY_OPTION,
  payload: { value, selected }
});

export const selectAllEntityOptions = (options, selected) => ({
  type: SELECT_ALL_ENTITY_OPTIONS,
  payload: { options, selected }
});

export const setCopyEntityResult = result => ({
  type: SET_COPY_ENTITY_RESULT,
  payload: result
});

export const clearCopyEntityResult = result => ({
  type: CLEAR_COPY_ENTITY_RESULT
});

export const clearCopyEntity = () => ({
  type: CLEAR_COPY_ENTITY
});

export const clearEntityOptions = () => ({
  type: CLEAR_ENTITY_OPTIONS
});
