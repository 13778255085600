import React from "react";
import { Modal } from "react-bootstrap";
import ModalHeader from "./../../../EmployeeProfileModal/ModalHeader";
import "./../../OverideModal.css";
import { FormSection, FormFieldGroup } from "component-library/formSections";

const NewLeaveReasonModal = ({
  show,
  onCancel,
  onCreate,
  data,
  onChangeModal
}) => (
  <Modal
    id="override-termination-modal"
    bsClass="override-termination-modal entity-page-cancel-modal modal"
    animation={false}
    backdrop={"static"}
    keyboard={false}
    show={show}
    onHide={onCancel}
    restoreFocus={false}
  >
    <ModalHeader onClose={onCancel} />
    <Modal.Body>
      <div id="override-termination-modal-body" className="modal-row">
        <div className="modal-header">Create a New Leave Reason</div>

        <div style={{ marginBottom: "20px" }}>
          Did'nt see the correct leave reason available for this employee? Add a
          new one below to your compnay.
        </div>
        <FormSection>
          <FormFieldGroup
            inputName="newLeaveReason"
            label="Name this new leave reason"
            description="Ex. Unqualified Leave, Personal Leave, etc."
            descriptionStyle="italic"
            labelFontWeight="600"
            value={data.newLeaveReason}
            onChange={onChangeModal}
          />
        </FormSection>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <div
        id="cancel-modal-footer"
        className="button-group"
        style={{ textAlign: "left" }}
      >
        <button
          type="button"
          id="save-modal-button"
          className={
            !data.newLeaveReason
              ? "disabled-button"
              : "nc-button primary-button"
          }
          onClick={onCreate}
        >
          Add Leave Reason
        </button>

        <button
          type="button"
          id="save-modal-button"
          className="nc-button secondary-link-button"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </Modal.Footer>
  </Modal>
);
export default NewLeaveReasonModal;
