import {
  Custom,
  IndependenceDay,
  ChristmasDay,
  MemorialDay,
  Thanksgiving,
  NewYearsDay,
  VeteransDay,
  MartinLuther,
  LaborDay,
  MardiGras,
  ColumbusDay,
  PresidentsDay,
  Easter,
  GoodFriday
} from "./Images";

const holidayIcon = id => {
  switch (id) {
    case 101:
      return NewYearsDay;
    case 102:
      return MartinLuther;
    case 103:
      return PresidentsDay;
    case 104:
      return MemorialDay;
    case 105:
      return IndependenceDay;
    case 106:
      return LaborDay;
    case 107:
      return ColumbusDay;
    case 108:
      return VeteransDay;
    case 109:
      return Thanksgiving;
    case 110:
      return ChristmasDay;
    case 201:
      return NewYearsDay;
    case 202:
      return MartinLuther;
    case 203:
      return PresidentsDay;
    case 204:
      return MemorialDay;
    case 205:
      return IndependenceDay;
    case 206:
      return LaborDay;
    case 207:
      return ColumbusDay;
    case 208:
      return VeteransDay;
    case 209:
      return Thanksgiving;
    case 210:
      return ChristmasDay;
    case 211:
      return Easter;
    case 212:
      return GoodFriday;
    case 213:
      return MardiGras;
    default:
      return Custom;
  }
};

export default holidayIcon;
