import React, { Component } from "react";
import { EmployeeTable } from "component-library/employee-profile";
export default class EmployeeTableContainer extends Component {
  componentDidMount() {
    this.props.setLoader(true);
    const { UserId } = this.props.sessionContext;
    this.props.searchPosts();
    this.props.fetchAndSetLoggedUser(UserId);
  }

  componentWillUnmount() {
    //this.props.clearEmployeeList();
  }

  render() {
    const {
      sessionContext,
      sortOrder,
      employeeList,
      loggedUser = {},
      auditViewSsnClick,
      viewSSN,
      loadMorePosts,
      onClickEmployee,
      changeSortOrder,
      loadingMore,
      loadingFirstTime
    } = this.props;
    return (
      <EmployeeTable
        headerColumn="EmployeeProfileList"
        data={employeeList.Data}
        dataCount={employeeList.DataCount}
        sessionContext={sessionContext}
        loadMorePosts={loadMorePosts}
        nextUri={employeeList.NextUri}
        loadingMore={loadingMore}
        loadingFirstTime={loadingFirstTime}
        editSortOrder={changeSortOrder}
        sortOrder={sortOrder}
        onClickEmployee={onClickEmployee}
        loggedUser={loggedUser.Data}
        viewSSN={viewSSN ? viewSSN : false}
        auditViewSsnClick={auditViewSsnClick}
      />
    );
  }
}
