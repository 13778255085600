import React, { Component } from "react";

export default class Scroller extends Component {
  state = { prevPos: 0, isDragging: false };
  componentDidMount() {
    // this.addListeners();
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  addListeners = () => {
    window.addEventListener("scroll", this.handleScroll);
    const scroller = document.getElementById("scroller" + this.props.id);
    scroller.addEventListener("mousedown", this.startDrag);
    window.addEventListener("mouseup", this.stopDrag);
    window.addEventListener("mousemove", this.drag);

    const container = document.getElementById("tab-container");
    container.addEventListener("scroll", this.moveScroller);
  };

  handleScroll = e => {
    const element = document.getElementById("tab-container");
    if (element) {
      const elementPos = element.offsetTop;
      const scroller = document.getElementById("scroller" + this.props.id);
      const scrollerHeight = scroller.clientHeight;
      const scrollHeight = element.scrollHeight;
      const scrollPosition = window.scrollY;
      let newPosition = "";
      if (scrollPosition > elementPos) {
        newPosition =
          String(
            (scrollPosition - elementPos) / scrollHeight * scrollerHeight
          ) + "px";
      } else {
        newPosition = "0px";
      }
      this.setState({
        scrollHandle: { transform: `translate(0px,${newPosition})` }
      });
    }
  };
  startDrag = e => {
    let normalizedPosition = e.pageY;
    this.setState({ isDragging: true, normalizedPosition });
    const container = document
      .getElementById("tab-container")
      .getBoundingClientRect();
    const elementPos = container.top;

    if (elementPos > 0) {
      console.log("Drag start", e.pageY, elementPos);

      window.scrollBy(0, elementPos);
    }
  };
  drag = e => {
    if (this.state.isDragging) {
      const { clientY } = e;
      const container = document.getElementById("tab-container");
      const elementPos = container.offsetTop;
      //const scrollTrack = this.scrollTrack.getBoundingClientRect();
      //var mouseDifferential = e.pageY - this.state.normalizedPosition;
      // var scrollEquivalent =
      //   mouseDifferential * (container.scrollHeight / container.offsetHeight);
      if (window.scrollY > elementPos) {
        console.log("Drag", window.scrollY, elementPos, clientY);

        window.scroll(0, clientY);
        this.setState({ normalizedPosition: e.pageY * 2 });
      } else {
        window.scrollBy(0, elementPos);
      }
    }
  };

  stopDrag = e => {
    this.setState({ isDragging: false });
  };
  moveScroller = e => {
    // var scrollPercentage = e.target.scrollTop / scrollContentWrapper.scrollHeight;
    //     var topPosition = scrollPercentage * (scrollContainer.offsetHeight - 5); // 5px arbitrary offset so scroll bar doesn't move too far beyond content wrapper bounding box
    //     scroller.style.top = topPosition + 'px';
  };
  render() {
    const {
      id
      //handleVerticalThumbMouseDown
    } = this.props;
    return (
      <div className="scroll-bar" id={"scroller" + id}>
        <div
          className="scroll-track"
          ref={handle => (this.scrollTrack = handle)}
        >
          {" "}
        </div>
        <div
          ref={handle => (this.thumbVertical = handle)}
          className="scroll-handle"
          style={this.state.scrollHandle}
        />
      </div>
    );
  }
}
