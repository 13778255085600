import React from "react";
const LoaderImage = () => (
  <img
    alt="loader"
    height="120"
    src={require("component-library/loaders/Sample11.gif")}
  />
);

export default LoaderImage;
