import React, { Component } from "react";
import {
  EntityListPageContainer,
  EntityListHeader
} from "component-library/entity-list";
import PayrollScheduleTableContainer from "./PayrollScheduleTableContainer";
import PayrollScheduleFilters from "./PayrollScheduleFilters";
import ToasterContainer from "../../../earnings/containers/ToasterContainer";
import * as util from "../../../app/common/utilities";

class PayrollSchedule extends Component {
  state = {
    loadingMore: false,
    loadingFirstTime: false
  };
  componentWillMount() {
    this.props.setLoader(true);
  }

  componentDidMount() {
    this.searchPosts();
  }
  componentWillUnmount() {
    this.props.clearHistory();
    this.props.clearToast();
    this.props.clearAllFilters();
  }
  searchPosts = () => {
    const { searchAndSetPayrolls, searchParams, filters } = this.props;
    this.setState({
      loadingFirstTime: true
    });
    searchAndSetPayrolls(searchParams, filters, () =>
      this.setState({ loadingFirstTime: false })
    );
  };

  loadMorePosts = () => {
    const { payrollHistory, loadAndSetMorePayrolls } = this.props;
    const { NextUri } = payrollHistory;
    if (!util.isNullOrEmpty(NextUri)) {
      this.setState({
        loadingMore: true
      });
      loadAndSetMorePayrolls(NextUri, () =>
        this.setState({ loadingMore: false })
      );
    } else {
      this.setState({
        loadingMore: false
      });
    }
  };
  render() {
    return (
      <EntityListPageContainer>
        <EntityListHeader
          title={this.props.title}
          page={this.props.page}
          hideCopy={false}
          onCopyEarning={() =>
            this.props.history.push("/copy-payroll-schedule")
          }
          addButtonLabel={this.props.addButtonLabel}
          onAddButtonClick={this.props.add}
          onHistoryButtonClick={() => {
            this.props.clearSortOrder();
            this.props.clearHistory();
            this.props.clearAllFilters();
            this.props.history.push("/company-level-payroll-activity-history");
          }}
        />
        <ToasterContainer />
        <PayrollScheduleFilters
          loadMorePosts={this.loadMorePosts}
          searchPosts={this.searchPosts}
          {...this.props}
        />
        <PayrollScheduleTableContainer
          loadMorePosts={this.loadMorePosts}
          searchPosts={this.searchPosts}
          loadingFirstTime={this.state.loadingFirstTime}
          loadingMore={this.state.loadingMore}
          {...this.props}
        />
      </EntityListPageContainer>
    );
  }
}

export default PayrollSchedule;
