import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as util from "app/common/utilities";
import {
  editEntityData,
  clearEntityData
} from "app/common/reducers/entityData";
import {
  clearEntityErrors,
  editEntityErrors
} from "app/common/reducers/entityErrors";
import {
  FooterSection,
  Skills
} from "component-library/employee-profile/resume/Skills";
import Toaster from "component-library/toasters/index";
import * as FIELDS from "component-library/employee-profile/resume/utils/Constants";
import AddNewSkillModal from "component-library/employee-profile/resume/Skills/Components/AddNewSkillModal";
import { createNewSkillAndReload } from "../network/actions";
import { SkillLevel } from "../utils/Constants";

class AddSkills extends Component {
  state = {
    toasterMessage: "",
    showToaster: false,
    toasterClass: "",
    showCloseIcon: true,
    data: {}
  };

  componentDidMount() {
    const { SkillsId } = this.props.match.params;
    const { resumeEntityData = {} } = this.props;
    if (
      !util.isUndefinedOrZero(SkillsId) &&
      Object.keys(resumeEntityData).length !== 0
    ) {
      this.props.fetchAndSetSkills(parseInt(SkillsId, 10), resumeEntityData);
    }
    this.props.toggleAddOnMode(true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { SkillsId } = this.props.match.params;
    const prevSkillsId = prevProps.match.params.SkillsId;
    if (
      (prevSkillsId !== SkillsId ||
        this.props.resumeEntityData !== prevProps.resumeEntityData) &&
      Object.keys(this.props.resumeEntityData).length !== 0 &&
      !util.isUndefinedOrZero(SkillsId)
    ) {
      this.props.fetchAndSetSkills(
        parseInt(SkillsId, 10),
        this.props.resumeEntityData
      );
    }
  }

  onChange = e => {
    const { editData, editErrors, filters } = this.props;
    const edits = { [e.name]: e.value };
    const errors = { [e.name]: null };

    if (e.name === FIELDS.SKILL_ID) {
      var filterData = filters.skillsSkill;
      var selectedOption = filterData.filter(item => item.id === e.value);
      edits[FIELDS.SKILL_NAME] = selectedOption[0].label;
    }
    editData(edits);
    editErrors(errors);
  };

  onAddClick = () => {
    const {
      saveSkillsRecordAndReload,
      setToaster,
      employeeName,
      data,
      onCancelClick
    } = this.props;
    saveSkillsRecordAndReload(data, employeeName, toasterMessage => {
      setToaster(toasterMessage, "success");
      onCancelClick();
    });
  };

  update = id => {
    const {
      updateSkillsRecordAndReload,
      setToaster,
      employeeName,
      data,
      onCancelClick
    } = this.props;
    updateSkillsRecordAndReload(id, data, employeeName, toasterMessage => {
      setToaster(toasterMessage, "success");
      onCancelClick();
    });
  };

  onCreateNewSkillLink = () => {
    this.setState({
      showModal: true
    });
  };

  onChangeModal = e => {
    this.setState({
      data: { ...this.state.data, [e.name]: e.value }
    });
  };

  onCreateNewSkill = () => {
    const { createNewSkillAndReload } = this.props;
    createNewSkillAndReload(data => {
      this.onClose();
    });
  };

  onClose = () => {
    this.setState({
      showModal: false,
      data: {}
    });
  };

  componentWillUnmount() {
    this.props.clearData();
    this.props.clearErrors();
    this.props.onCancelClick();
    this.props.toggleToaster();
    this.props.toggleAddOnMode(false);
  }

  render() {
    const {
      data = {},
      errors = {},
      onCancelClick,
      editMode,
      filters,
      userRole
    } = this.props;
    const showLink = userRole !== FIELDS.EMPLOYEE ? true : false;
    return (
      <div className="tab-container-add-section">
        <div id="add-skills" className="title-band-container">
          <div className="title-band">
            {editMode ? "Edit Skills" : "Add Skills"}
          </div>
          <Toaster
            id={"skillToaster"}
            show={this.state.showToaster}
            alertClassName={this.state.toasterClass}
            message={this.state.toasterMessage}
            closeToasterMessage={this.toggleToaster}
            showCloseIcon={this.state.showCloseIcon}
          />
          <Skills
            onChange={this.onChange}
            data={data}
            filters={filters}
            errors={errors}
            onCreateNewSkillLink={this.onCreateNewSkillLink}
            showLink={showLink}
            horizontalLabels={SkillLevel}
          />
          <FooterSection
            onAddClick={this.onAddClick}
            onCancelClick={onCancelClick}
            editMode={editMode}
            update={this.update}
          />
          <AddNewSkillModal
            show={this.state.showModal}
            onClose={this.onClose}
            onChange={this.onChangeModal}
            data={this.state.data}
            onCreateNewSkill={this.onCreateNewSkill}
          />
        </div>
      </div>
    );
  }
}

const stateToProps = state => {
  const { entitySetup } = state.common;
  const { data, errors = {} } = entitySetup;
  return {
    data,
    errors
  };
};

const actionCreators = {
  editData: editEntityData,
  clearData: clearEntityData,
  clearErrors: clearEntityErrors,
  editErrors: editEntityErrors,
  createNewSkillAndReload
};

export default withRouter(connect(stateToProps, actionCreators)(AddSkills));
