// Proxy object to map classnames when css modules are not used

export default {
  container: "DayPicker",
  wrapper: "DayPicker-wrapper",
  interactionDisabled: "DayPicker--interactionDisabled",
  month: "DayPicker-Month",

  navBar: "DayPicker-NavBar",
  navButtonPrev:
    "DayPicker-NavButton DayPicker-NavButton--prev fa fa-chevron-left",
  navButtonNext:
    "DayPicker-NavButton DayPicker-NavButton--next fa fa-chevron-right",
  navButtonInteractionDisabled: "DayPicker-NavButton--interactionDisabled",

  caption: "DayPicker-Caption",
  weekdays: "DayPicker-Weekdays",
  weekdaysRow: "DayPicker-WeekdaysRow clearfix",
  weekday: "DayPicker-Weekday",
  body: "DayPicker-Body",
  week: "DayPicker-Week",
  weekNumber: "DayPicker-WeekNumber",
  day: "DayPicker-Day",
  footer: "DayPicker-Footer",
  todayButton: "DayPicker-TodayButton",

  // default modifiers
  today: "today",
  selected: "selected",
  disabled: "disabled",
  outside: "outside"
};
