import React, { Component } from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import {
  primaryContact,
  USE_SAME_ADDRESS_LABEL,
  emergencyQuestions
} from "../Constants";
import EmergencyContactCheckbox from "./Checkbox";

class PrimaryContact extends Component {
  state = {
    selected: false
  };
  renderPrimaryContact = componentProps => {
    return Object.keys(componentProps).map(k => {
      return (
        <FormFieldGroup
          {...componentProps[k]}
          key={k}
          block
          colSpan="col-md-4"
          required={true}
          value={this.props.data[componentProps[k]["inputName"]]}
          onChange={this.props.onChange}
          error={this.props.data.errors[componentProps[k]["inputName"]]}
        />
      );
    });
  };
  render() {
    const { data } = this.props;
    const relationshipData =
      data.initialData && data.initialData.relationship
        ? data.initialData.relationship
        : [];
    let componentProps = primaryContact;
    componentProps.primaryContactRelationship.options = relationshipData;
    componentProps.primaryContactState.options =
      data.initialData && data.initialData.primaryContactState
        ? data.initialData.primaryContactState
        : [];
    const {
      primaryContactAddress1,
      primaryContactAddress2,
      primaryContactZipCode,
      primaryContactCity,
      primaryContactState,
      ...restOfAddress
    } = componentProps;

    const {
      primaryContactName,
      primaryContactRelationship,
      primaryContactPersonalEmail,
      primaryContactHomePhone,
      primaryContactCellPhoneNumber,
      primaryContactWorkPhoneNumber,
      ...restOfComponentProps
    } = componentProps;
    return (
      <FormSection title="Primary Contact">
        <div className="col-md-9" style={{ padding: "0px" }}>
          <div
            style={{
              padding: "10px 15px",
              fontSize: "16px",
              fontWeight: "600"
            }}
          >
            {emergencyQuestions.primaryContact[0]}
          </div>
          {this.renderPrimaryContact(restOfAddress)}
          <div
            style={{
              padding: "10px 15px",
              fontSize: "16px",
              fontWeight: "600"
            }}
          >
            {emergencyQuestions.primaryContact[1]}
          </div>
          <EmergencyContactCheckbox
            label={USE_SAME_ADDRESS_LABEL}
            selected={this.state.selected}
            onClick={() => {
              this.setState({
                selected: !this.state.selected
              });
            }}
          />
          {this.renderPrimaryContact(restOfComponentProps)}
        </div>
      </FormSection>
    );
  }
}

export default PrimaryContact;
