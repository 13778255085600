import Loadable from "react-loadable";
import ChunkLoading from "app/common/UIComponents/ChunkLoading";

export const MapGLTaxesSetup = Loadable({
  loader: () =>
    import("./pages" /* webpackChunkName: "mapgltaxes" */).then(
      m => m.MapGLTaxesSetup
    ),
  loading: ChunkLoading
});

export const MapGLDeductionsSetup = Loadable({
  loader: () =>
    import("./pages" /* webpackChunkName: "mapgldeductions" */).then(
      m => m.MapGLDeductionsSetup
    ),
  loading: ChunkLoading
});
