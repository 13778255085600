import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FormSection } from "../../../formSections";
import { FormFieldGroup } from "../../../formSections";
import * as Fields from "./FieldNames";

const componentProps = {
  show: true,
  inputType: "text",
  inputId: Fields.newLocation,
  inputName: Fields.newLocation,
  label: "Enter a name for the location",
  required: true
};

const Header = ({ closeModal }) => (
  <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <i
      id="add-new-location-modal-close-icon"
      onClick={closeModal}
      className="fa fa-times-circle-o"
    />
  </div>
);

const Footer = ({ onCancel, onCreate }) => (
  <div className="button-group">
    <button
      type="button"
      id="add-new-location-modal-create-button"
      className={"nc-button primary-button"}
      onClick={onCreate}
    >
      Add New Location
    </button>
    <button
      type="button"
      id="new-change-reason-modal-cancel-button"
      className="secondary-link-button"
      onClick={onCancel}
    >
      Cancel
    </button>
  </div>
);

class AddNewLocationModal extends Component {
  render() {
    const {
      show,
      closeModal,
      onCreate,
      data = {},
      onChange,
      errors = {}
    } = this.props;
    return (
      <Modal show={show} bsClass="deduction-add-modal modal">
        <Modal.Header>
          <Header closeModal={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <div className="modal-row">
            <div className="modal-title">Add a New Location</div>
            <FormSection>
              <FormFieldGroup
                {...componentProps}
                value={data[componentProps.inputName]}
                onChange={onChange}
                error={errors[componentProps.inputName]}
              />
            </FormSection>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Footer onCancel={closeModal} onCreate={onCreate} />
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddNewLocationModal;
