export const SET_PAYROLL_TYPE_ACTIVE_TAB =
  "payroll/dashboard/SET_PAYROLL_TYPE_ACTIVE_TAB";

const payrollTypeActiveTab = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_PAYROLL_TYPE_ACTIVE_TAB: {
      return payload;
    }
    default:
      return state;
  }
};
export default payrollTypeActiveTab;

//Actions
export const setPayrollActiveTab = activePayrollItem => ({
  type: SET_PAYROLL_TYPE_ACTIVE_TAB,
  payload: activePayrollItem
});

export const clearPayrollActiveTab = () => ({
  type: SET_PAYROLL_TYPE_ACTIVE_TAB,
  payload: {}
});
