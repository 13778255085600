/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Panel, Overlay } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { Badge } from "react-bootstrap";
import { Checkbox, CheckboxGroup } from "react-checkbox-group";
import { Scrollbars } from "react-custom-scrollbars";
import { getThirdPartyPayeeList } from "../../../actions/systemlevel/thirdpartypayee/AdvanceFilterActions";
import CustomOverlay from "component-library/dropDown/CustomOverlay";
import classnames from "classnames/bind";
const ALL_THIRDPARTYPAYEE = 0;
const TPP_PANEL_ID = 1;

let isAllUncheckedZero = false;
const ALL_SELECTED_LABEL = "All Third-Party Payees";
const SOME_SELECTED_LABEL = "Third-Party Payees";
const ONE_SELECTED_LABEL = "Third-Party Payee";
const NO_SELECTED_LABEL = "Select Third-Party Payee";

class ThirdPartyPayeeDropdown extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      autoSuggestionThirdPartyPayeeList: [],
      selectedThirdPartyPayees: props.selectedThirdPartyPayees,
      selectThirdPartyPayeeLabel: ALL_SELECTED_LABEL,
      isAllSelected: true,
      autoSuggestionTextBox: ""
    };

    this.checkSelectThirdPartyPayee = this.checkSelectThirdPartyPayee.bind(
      this
    );
    this.renderThirdPartyPayeeSelectionList = this.renderThirdPartyPayeeSelectionList.bind(
      this
    );
    this.handleChange = this.handleChange.bind(this);
    this.onCloseThirdPartyPayeeDropdown = this.onCloseThirdPartyPayeeDropdown.bind(
      this
    );
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  handleChange(event) {
    let strValue = event.target.value;
    let arrayList = [];
    this.setState({ autoSuggestionTextBox: strValue });
    this.props.thirdPartyPayeeList.forEach(item => {
      if (item.Name.toLowerCase().indexOf(strValue.toLowerCase()) >= 0) {
        arrayList.push(item);
      }
    });
    this.setState({ autoSuggestionThirdPartyPayeeList: arrayList });
  }

  checkSelectThirdPartyPayee(newThirdPartyPayee) {
    // let thirdPartyPayeeLabel = (newThirdPartyPayee.length > 1 ? 'All Third-Party Payees' : 'Third-Party Payee');
    let thirdPartyPayeeLabel =
      newThirdPartyPayee.length > 1
        ? SOME_SELECTED_LABEL
        : newThirdPartyPayee.length === 0
        ? NO_SELECTED_LABEL
        : ONE_SELECTED_LABEL;

    if (newThirdPartyPayee.includes(ALL_THIRDPARTYPAYEE)) {
      let indexOfAllThirdPartyPayee = newThirdPartyPayee.indexOf(
        ALL_THIRDPARTYPAYEE
      );

      if (
        indexOfAllThirdPartyPayee !== 0 ||
        this.state.selectedThirdPartyPayees.length === 0
      ) {
        newThirdPartyPayee = [];
        for (let i = 0; i < this.props.thirdPartyPayeeList.length; i++) {
          let Id = this.props.thirdPartyPayeeList[i].Id;
          newThirdPartyPayee.push(Id);
        }

        this.setState({
          selectedThirdPartyPayees: newThirdPartyPayee,
          selectThirdPartyPayeeLabel: ALL_SELECTED_LABEL,
          isAllSelected: true
        });
      } else {
        newThirdPartyPayee.splice(0, 1);
        this.setState({
          selectedThirdPartyPayees: newThirdPartyPayee,
          selectThirdPartyPayeeLabel: thirdPartyPayeeLabel,
          isAllSelected: false
        });
      }
    } else if (this.state.isAllSelected && !newThirdPartyPayee.includes(0)) {
      this.setState({
        selectedThirdPartyPayees: [],
        selectThirdPartyPayeeLabel: NO_SELECTED_LABEL,
        isAllSelected: false
      });
    } else {
      if (
        this.props.thirdPartyPayeeList.length - 1 ===
          newThirdPartyPayee.length &&
        !newThirdPartyPayee.includes(ALL_THIRDPARTYPAYEE)
      ) {
        newThirdPartyPayee = [];
        for (let i = 0; i < this.props.thirdPartyPayeeList.length; i++) {
          let Id = this.props.thirdPartyPayeeList[i].Id;
          newThirdPartyPayee.push(Id);
        }

        this.setState({
          selectedThirdPartyPayees: newThirdPartyPayee,
          selectThirdPartyPayeeLabel: ALL_SELECTED_LABEL,
          isAllSelected: true
        });
      } else {
        this.setState({
          selectedThirdPartyPayees: newThirdPartyPayee,
          selectThirdPartyPayeeLabel: thirdPartyPayeeLabel,
          isAllSelected: false
        });
      }
    }

    if (
      newThirdPartyPayee.length === 0 ||
      (this.state.isAllSelected === true &&
        newThirdPartyPayee.includes(ALL_THIRDPARTYPAYEE) === false &&
        newThirdPartyPayee.indexOf(ALL_THIRDPARTYPAYEE) === -1 &&
        newThirdPartyPayee.length === this.props.thirdPartyPayeeList.length - 1)
    ) {
      isAllUncheckedZero = true;
    } else {
      isAllUncheckedZero = false;
    }
  }

  componentDidMount() {
    let selectedThirdPartyPayees = [];

    if (
      this.props.systemLevelTPPSelectedFiltersList !== undefined &&
      this.props.systemLevelTPPSelectedFiltersList !== null &&
      this.props.systemLevelTPPSelectedFiltersList.length !== 0
    ) {
      if (
        this.props.systemLevelTPPSelectedFiltersList.selectedThirdPartyPayees
          .length > 0
      ) {
        this.props.systemLevelTPPSelectedFiltersList.selectedThirdPartyPayees.forEach(
          selectedItem => {
            selectedThirdPartyPayees.push(selectedItem);
          }
        );
      }
    } else {
      if (this.props.thirdPartyPayeeList.Data) {
        this.props.thirdPartyPayeeList.Data.forEach(item => {
          selectedThirdPartyPayees.push(item.Id);
        });
      }
    }

    let selectThirdPartyPayeeLabel = ONE_SELECTED_LABEL;
    let isAllSelected = false;

    if (
      this.props.thirdPartyPayeeList.length === selectedThirdPartyPayees.length
    ) {
      selectThirdPartyPayeeLabel = ALL_SELECTED_LABEL;
      isAllSelected = true;
    } else if (selectedThirdPartyPayees.length === 0) {
      selectThirdPartyPayeeLabel = NO_SELECTED_LABEL;
      isAllSelected = false;
    } else if (selectedThirdPartyPayees.length === 1) {
      selectThirdPartyPayeeLabel = ONE_SELECTED_LABEL;
      isAllSelected = false;
    } else {
      selectThirdPartyPayeeLabel = SOME_SELECTED_LABEL;
      isAllSelected = false;
    }

    let arrayList = [];
    this.props.thirdPartyPayeeList.forEach(item => {
      arrayList.push(item);
    });

    this.setState({
      selectedThirdPartyPayees,
      autoSuggestionThirdPartyPayeeList: arrayList,
      selectThirdPartyPayeeLabel,
      isAllSelected
    });

    this.props.onSelectedThirdPartyPayees(selectedThirdPartyPayees);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.thirdPartyPayeeOriginalList !==
      this.props.thirdPartyPayeeOriginalList
    ) {
      let selectedThirdPartyPayees = [];
      selectedThirdPartyPayees[0] = 0;

      if (nextProps.thirdPartyPayeeOriginalList.Data) {
        nextProps.thirdPartyPayeeOriginalList.Data.forEach(item => {
          selectedThirdPartyPayees.push(item.Id);
        });
      }

      let selectThirdPartyPayeeLabel = ONE_SELECTED_LABEL;
      let isAllSelected = false;

      if (
        nextProps.thirdPartyPayeeOriginalList.DataCount + 1 ===
        selectedThirdPartyPayees.length
      ) {
        selectThirdPartyPayeeLabel = ALL_SELECTED_LABEL;
        isAllSelected = true;
      } else {
        selectThirdPartyPayeeLabel = ONE_SELECTED_LABEL;
        isAllSelected = false;
      }

      let arrayList = [];
      this.props.thirdPartyPayeeList.forEach(item => {
        arrayList.push(item);
      });

      this.setState({
        selectedThirdPartyPayees,
        autoSuggestionThirdPartyPayeeList: arrayList,
        selectThirdPartyPayeeLabel,
        isAllSelected
      });
    }

    if (nextProps.isResetToDefault !== this.props.isResetToDefault) {
      if (nextProps.isResetToDefault) {
        let selectedThirdPartyPayees = [];
        selectedThirdPartyPayees[0] = 0;
        if (nextProps.thirdPartyPayeeOriginalList.Data) {
          nextProps.thirdPartyPayeeOriginalList.Data.forEach(item => {
            selectedThirdPartyPayees.push(item.Id);
          });
        }

        let selectThirdPartyPayeeLabel = ONE_SELECTED_LABEL;
        let isAllSelected = false;

        if (
          nextProps.thirdPartyPayeeOriginalList.DataCount + 1 ===
          selectedThirdPartyPayees.length
        ) {
          selectThirdPartyPayeeLabel = ALL_SELECTED_LABEL;
          isAllSelected = true;
        } else {
          selectThirdPartyPayeeLabel = ONE_SELECTED_LABEL;
          isAllSelected = false;
        }

        let arrayList = [];
        this.props.thirdPartyPayeeList.forEach(item => {
          arrayList.push(item);
        });
        this.setState({
          selectedThirdPartyPayees,
          autoSuggestionThirdPartyPayeeList: arrayList,
          selectThirdPartyPayeeLabel,
          isAllSelected
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedThirdPartyPayees !== this.state.selectedThirdPartyPayees
    ) {
      this.props.onSelectedThirdPartyPayees(
        this.state.selectedThirdPartyPayees
      );
    }
  }

  onCloseThirdPartyPayeeDropdown() {
    this.props.onSelectedThirdPartyPayees(this.state.selectedThirdPartyPayees);
  }

  renderThirdPartyPayeeSelectionListMobile() {
    const { id, thirdPartyPayeeList } = this.props;
    if (!thirdPartyPayeeList) {
      return <div />;
    } else {
      return (
        <Scrollbars
          autoHeight={true}
          autoHeightMax={320}
          renderThumbHorizontal={props => (
            <div style={{ overflowX: "hidden" }} className="thumb-horizontal" />
          )}
        >
          <CheckboxGroup
            name="select-ThirdPartyPayee"
            value={this.state.selectedThirdPartyPayees}
            onChange={this.checkSelectThirdPartyPayee}
            className=""
          >
            <ul
              id={id + "-options-list"}
              className="nc-dropdown-navigation-multiselect"
            >
              {this.renderThirdPartyPayeeSelectionItem(
                this.state.autoSuggestionThirdPartyPayeeList
              )}
            </ul>
          </CheckboxGroup>
        </Scrollbars>
      );
    }
  }

  renderThirdPartyPayeeSelectionList() {
    const { id } = this.props;
    if (!this.props.thirdPartyPayeeList) {
      return <div />;
    } else {
      return (
        <CustomOverlay>
          <div className="form-group">
            <div className="field">
              <input
                id={id + "-auto-suggestion-textbox"}
                type="text"
                onChange={this.handleChange}
                name="autoSuggestionTextBox"
                className="form-control"
                placeholder="&#xF002; Search"
                onFocus={e => (e.target.placeholder = "")}
                onBlur={e =>
                  (e.target.placeholder =
                    String.fromCharCode("0xF002") + " Search")
                }
                value={this.state.autoSuggestionTextBox}
              />
            </div>
          </div>
          <Scrollbars
            autoHeight={true}
            autoHeightMax={320}
            renderThumbHorizontal={props => (
              <div
                style={{ overflowX: "hidden" }}
                className="thumb-horizontal"
              />
            )}
          >
            <CheckboxGroup
              name="select-ThirdPartyPayee"
              value={this.state.selectedThirdPartyPayees}
              onChange={this.checkSelectThirdPartyPayee}
              className=""
            >
              <ul
                id={id + "-options-list"}
                className="nc-dropdown-navigation-multiselect"
              >
                {this.renderThirdPartyPayeeSelectionItem(
                  this.state.autoSuggestionThirdPartyPayeeList
                )}
              </ul>
            </CheckboxGroup>
          </Scrollbars>
        </CustomOverlay>
      );
    }
  }

  openSubPanelById(id) {
    let update = {};

    if (id === this.state.subPanelId) {
      update["subPanelId"] = 0;
    } else {
      update["subPanelId"] = id;
    }

    this.setState(update);
  }

  renderThirdPartyPayeeSelectionItem(thirdPartyPayeeDropDownList) {
    const { id } = this.props;
    return thirdPartyPayeeDropDownList.map((item, index) => {
      return (
        <li key={index} className="list-item">
          <Checkbox id={id + "-list-item-" + item.Id} value={item.Id} />
          <label
            htmlFor={id + "-list-item-" + item.Id}
            id={id + "-list-item-" + item.Id + "-label"}
          >
            {item.Name}
          </label>
        </li>
      );
    });
  }
  onFilterOverlayHide = () => {
    let arrayList = [];
    this.props.thirdPartyPayeeList.forEach(item => {
      arrayList.push(item);
    });
    this.setState({
      popoverOpen: false,
      autoSuggestionTextBox: "",
      autoSuggestionThirdPartyPayeeList: arrayList
    });
  };

  render() {
    const thirdPartyPayeeSelectionPanel = this.renderThirdPartyPayeeSelectionList();
    const { id } = this.props;
    if (!this.props.thirdPartyPayeeList) {
      return <div>No Records Found.</div>;
    }

    return (
      <div className="filter-dropdown-view-section">
        {window.innerWidth <= 991 ? (
          <div className="filter-mobile-dropdown-view">
            <div className="mobile-filter-panel-list-section">
              <a id="" onClick={this.openSubPanelById.bind(this, TPP_PANEL_ID)}>
                <div className="mobile-filter-panel-list-panel-bar col-xs-12 col-sm-12">
                  <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-icon">
                    {this.state.subPanelId === TPP_PANEL_ID ? (
                      <i
                        className="fa fa-chevron-up add_deduction_popup_panel_link"
                        aria-hidden="true"
                      />
                    ) : (
                      <i
                        className="fa fa-chevron-down add_deduction_popup_panel_link"
                        aria-hidden="true"
                      />
                    )}
                  </div>

                  <div className="col-xs-8 col-sm-8 mobile-filter-panel-list-label no-padding">
                    {this.state.selectThirdPartyPayeeLabel}
                  </div>

                  <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-badge no-padding">
                    {isAllUncheckedZero ? null : this.state
                        .isAllSelected ? null : (
                      <Badge className="filter-select-badge">
                        {this.state.selectedThirdPartyPayees.length}
                      </Badge>
                    )}
                  </div>
                </div>
              </a>

              <div className="mobile-filter-panel-list-panel-bar-section">
                <Panel
                  className="mobile-filter-panel-list-panel-default no-padding col-xs-12 col-sm-12 col-md-12 col-lg-12"
                  collapsible
                  expanded={
                    this.state.subPanelId === TPP_PANEL_ID ? true : false
                  }
                >
                  <Panel.Collapse>
                    <Panel.Body>
                      <div className="form-group">
                        <div className="field">
                          <input
                            id={id + "-auto-suggestion-textbox"}
                            type="text"
                            name="autoSuggestionTextBox"
                            className="form-control"
                            placeholder="&#xF002; Search"
                            onChange={this.handleChange}
                            onFocus={e => (e.target.placeholder = "")}
                            onBlur={e =>
                              (e.target.placeholder =
                                String.fromCharCode("0xF002") + " Search")
                            }
                            value={this.state.autoSuggestionTextBox}
                          />
                        </div>
                      </div>
                      {this.renderThirdPartyPayeeSelectionListMobile()}
                    </Panel.Body>
                  </Panel.Collapse>
                </Panel>
              </div>
            </div>
          </div>
        ) : (
          <div className="filter-desktop-dropdown-view">
            <div className="nc-select-container hidden-sm hidden-xs">
              <Button
                id={id + "-button"}
                className=""
                onClick={this.toggle}
                ref={button => (this.entityFilter = button)}
              >
                <span id={id + "-selected-value"} className="selected-value">
                  {this.state.selectThirdPartyPayeeLabel}
                </span>

                <i
                  className={classnames(
                    "pull-right",
                    "fa",
                    this.state.popoverOpen ? "fa-chevron-up" : "fa-chevron-down"
                  )}
                />

                {isAllUncheckedZero ? null : this.state.isAllSelected ? null : (
                  <Badge
                    id={id + "-count-badge"}
                    className="filter-badge pull-right"
                  >
                    {this.state.selectedThirdPartyPayees.length}
                  </Badge>
                )}
              </Button>
            </div>

            <Overlay
              show={this.state.popoverOpen}
              onHide={this.onFilterOverlayHide}
              placement="bottom"
              container={this}
              rootClose={true}
              target={this.entityFilter}
            >
              {thirdPartyPayeeSelectionPanel}
            </Overlay>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let thirdPartyPayeeDropDownList = [
    {
      Id: 0,
      Name: ALL_SELECTED_LABEL
    }
  ];

  let selectedThirdPartyPayees = [];
  selectedThirdPartyPayees[0] = 0;

  if (state.thirdPartyPayeeList.Data) {
    state.thirdPartyPayeeList.Data.forEach(item => {
      thirdPartyPayeeDropDownList.push(item);
      selectedThirdPartyPayees.push(item.Id);
    });
  }

  return {
    thirdPartyPayeeList: thirdPartyPayeeDropDownList,
    thirdPartyPayeeOriginalList: state.thirdPartyPayeeList,
    selectedThirdPartyPayees,
    systemLevelTPPSelectedFiltersList: state.systemLevelTPPSelectedFiltersList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getThirdPartyPayeeList
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThirdPartyPayeeDropdown);
