import React from "react";
import moment from "moment";
import * as payrollConstants from "./PayrollDashboardConstants";

//User Story 5345
const PayrollDashboardSummaryMarker = ({ data }) => {
  let todaysDate = moment().format();
  let diff = moment(data.SubmitDate)
    .startOf("day")
    .diff(moment(todaysDate).startOf("day"), "days", true);

  let maxSubmitDateDiff = moment(data.MaxSubmitDate)
    .startOf("day")
    .diff(moment(todaysDate).startOf("day"), "days", true);
  let maxWireDateDiff = moment(data.MaxWireDate)
    .startOf("day")
    .diff(moment(todaysDate).startOf("day"), "days", true);
  let markerMessage = "";
  let badgeColor = "#339966"; //2-3 days left
  if (data.PayrollStageId === payrollConstants.PAYROLL_SUBMITTED) {
    markerMessage = `Submitted`;
    badgeColor = "#0c4f48"; //Submitted
  } else if (data.PayrollStageId === payrollConstants.PAYROLL_PENDING) {
    markerMessage = `Pending`;
    badgeColor = "#d44646"; //Pending
  } else {
    if ((diff <= 0 && maxSubmitDateDiff > 0) || maxSubmitDateDiff === 0) {
      markerMessage = `Deadline Approaching`;
      badgeColor = "#d44646"; //No days left
    } else if (diff === 1) {
      markerMessage = `${diff} Day Left to Submit`;
      badgeColor = "#ed6b4e"; //1 Day left
    } else if (diff > 1) {
      markerMessage = `${diff} Days Left to Submit`;
    } else if (maxSubmitDateDiff <= 0) {
      if (maxWireDateDiff >= 0) {
        markerMessage = `Deadline Passed`;
        badgeColor = "#d44646"; //No days left
      } else if (maxWireDateDiff < 0) {
        markerMessage = `Deadline Passed`;
        badgeColor = "#d44646"; //No days left
      }
    }
  }

  return (
    <span className="badge nc-badge" style={{ backgroundColor: badgeColor }}>
      {markerMessage}
    </span>
  );
};

export default PayrollDashboardSummaryMarker;
