import React from "react";
import { Modal } from "react-bootstrap";
import QuarterWiseUpcomingPayrolls from "../../payroll-calendars/QuarterWiseUpcomingPayrolls";
import {
  PAYROLL_CALENDAR_MODAL_TITLE,
  previewPayrollCalendarDescription
} from "./Constants";
import "./CalenderPreview.css";

const PreviewCalendarEditMode = props => {
  const { isEditMode, calendarPreviewData = [], onHide = () => {} } = props;
  const payrollCalendarNodes =
    calendarPreviewData.length > 0 &&
    calendarPreviewData.map((item, i) => (
      <QuarterWiseUpcomingPayrolls
        key={item.Id}
        data={item}
        calendarPreviewData={calendarPreviewData}
        index={i}
        //badgeColor={(i + 1) % 2 === 0 ? "#26A977" : "#006973"}
        badgeColor={item.PayrollCalenderYear % 2 === 0 ? "#006973" : "#26A977"}
      />
    ));
  return (
    <Modal
      id="payroll-calendar"
      animation={true}
      bsSize="large"
      bsClass="modal"
      show={isEditMode}
    >
      <Modal.Header>
        <i
          id="payroll-modal-cancel-button-close-icon"
          onClick={onHide}
          className="fa fa-times-circle-o fa-lg"
        />
        <div className="preview-payroll-calendar-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="title text-center">
            {PAYROLL_CALENDAR_MODAL_TITLE}
          </div>
          <div className="content-section text-center">
            <span>{previewPayrollCalendarDescription}</span>
          </div>
        </div>
      </Modal.Header>
      <div className="preview-payroll-calendar-content">
        {payrollCalendarNodes}
      </div>
    </Modal>
  );
};

export default PreviewCalendarEditMode;
