import axios from "axios";
import * as types from "./index";
import * as common from "app/common/index";

export function getCategoryDeductionList() {
  const url = `${common.WEB_API_URL}/v1/system/deductioncategory`;
  const request = axios.get(url);
  return {
    type: types.FETCH_CATEGORY_OPTIONS_LIST,
    payload: request
  };
}

export function getSubCategoryDeductionList() {
  const url = `${common.WEB_API_URL}/v1/system/deductionsubcategory`;
  const request = axios.get(url);
  return {
    type: types.FETCH_DEDUCTION_VALUES_LIST,
    payload: request
  };
}

export function getTaxabilityValuesList() {
  const url = `${common.WEB_API_URL}/v1/system/deductiontaxoption`;
  const request = axios.get(url);
  return {
    type: types.FETCH_TAXABILITY_VALUES_LIST,
    payload: request
  };
}

export function getExcludeStatesValuesList() {
  const url = `${common.WEB_API_URL}/v1/system/state`;
  const request = axios.get(url);
  return {
    type: types.FETCH_EXCLUDE_STATES_VALUES_LIST,
    payload: request
  };
}

export function getDeductionAmountOnDebitCard(selectedeDeductionValue) {
  const url = `${common.WEB_API_URL}/v1/app/ui/appview`;
  const request = axios.get(url);
  return {
    type: types.FETCH_DEDUCTION_AMOUNT_DEBIT_CARD_VALUES_LIST,
    payload: request
  };
}

export function getSymmetryTaxCodeList() {
  const url = `${common.WEB_API_URL}/v1/system/symmetrytaxcode`;
  const request = axios.get(url);
  return {
    type: types.FETCH_SYMMETRY_TAX_CODE_LIST,
    payload: request
  };
}
