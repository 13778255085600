import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./GeneralLedger.css";
import { withRouter } from "react-router-dom";
import * as common from "app/common/index";
import axios from "axios";
import { setLoader } from "app/actions/LoaderActions";
import { Badge } from "react-bootstrap";
import "../../../content/listpage.css";
import "../../../content/entitypage.css";
import "../../../content/style.css";
import { showToaster } from "app/actions/AppActions";
import DropDown from "component-library/dropDown/DropDown";
import EmployeeLiabilityAccountsAdvanceFilter from "./EmployeeLiabilityAccountsAdvanceFilter";

import {
  setGlobalNavClick,
  CallbackToQuickLink,
  setFormUpdate,
  isOverlayClickable
} from "app/actions/FormUpdateAction";

import {
  getEmployeeExpenceLiabilityAccountsList,
  getEmployeeLiabilityAccountsList,
  updatedEmployeeAccountFilteredList,
  setEmployeeData,
  getEmployeeExpenceLiabilityAccountsFilteredList
} from "../actions/generalledger/EmployeeLiabilityAccountsActions.js";

import {
  setEmployerExpencesData,
  updatedFilteredList,
  getEmployerExpenceLiabilityAccountsList
} from "../actions/generalledger/EmployerExpenceLiabilityAccountsActions";

function sortByKey(array, key) {
  return array.sort(function(a, b) {
    let x = a[key].toLowerCase();
    let y = b[key].toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

let isInDidUpdate = false;

class EmployeeLiabilityAccounts extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showBackCancelModal: false,
      IsFormUpdated: false,
      isTableSorted: undefined,
      sortedKey: "",
      accountsTableList: [],
      selectedDeductions: [],
      selectedGLCategories: [],
      showToaster: false,
      key: 1,

      showDropdownSection: false,
      showUnMappedBadgeForExpenceDeduction: false,
      showUnmappedBadgeForLiabilityAccount: false,

      showUnMappedBadgeForDeduction2: false,
      showUnmappedBadgeForLiabilityAccount2: false,
      showDropDownsSection2: false,

      isUnmapped: false,
      unmappedCount: 0,
      unmappedList: [],
      unmappedCheckStateEmployeeTab: false
    };

    this.saveEmplyeeExpenceLiabilityAccounts = this.saveEmplyeeExpenceLiabilityAccounts.bind(
      this
    );

    this.showSortedIcon = this.showSortedIcon.bind(this);
    this.renderEmployeeLiabilityTable = this.renderEmployeeLiabilityTable.bind(
      this
    );
    this.handleSelect = this.handleSelect.bind(this);

    this.renderButtons = this.renderButtons.bind(this);
  }

  componentDidMount() {
    this.props.setEmployerExpencesData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isEmployerFormUpdated !== this.props.isEmployerFormUpdated) {
      this.setState({
        IsFormUpdated: nextProps.isEmployerFormUpdated
      });
    }
  }

  componentWillUnmount() {
    this.props.setFormUpdate(false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.employeeExpenceLiabilityAccountsList !==
      this.props.employeeExpenceLiabilityAccountsList
    ) {
      isInDidUpdate = true;
      this.props.updatedEmployeeAccountFilteredList(
        this.props.employeeExpenceLiabilityAccountsList
      );
      let unmappedList = [];
      if (this.props.employeeExpenceLiabilityAccountsList.Data) {
        this.props.employeeExpenceLiabilityAccountsList.Data.forEach(item => {
          if (
            item.EmployeeContributionLiabilityGlAccountId === null ||
            item.EmployeeContributionLiabilityGlAccountId === 0
          ) {
            unmappedList.push(item.CompanyDeductionId);
          }
        });
        this.setState({
          unmappedList,
          unmappedCount: unmappedList.length
        });
        isInDidUpdate = false;
      }
    }

    // if (prevState.unmappedCount !== this.state.unmappedCount) {
    //   isInDidUpdate = true;
    //   this.props.unamappedCount(this.state.unmappedCount);
    //   isInDidUpdate = false;
    // }

    if (prevProps.formUpdatedData !== this.props.formUpdatedData) {
      if (this.props.formUpdatedData || this.props.isEmployerFormUpdated) {
        this.setState({
          IsFormUpdated: true
        });
      } else {
        this.setState({
          IsFormUpdated: false
        });
      }
    }
  }

  getFilteredList() {
    if (this.props.employeeExpenceLiabilityAccountsList.DataCount > 0) {
      this.props.getEmployeeExpenceLiabilityAccountsFilteredList(
        this.props.employeeExpenceLiabilityAccountsList.Data,
        this.state.selectedDeductions,
        this.state.unmappedCheckStateEmployeeTab
      );
    }
  }

  setUnmappedState(unmappedCheckStateEmployeeTab) {
    this.setState({
      unmappedCheckStateEmployeeTab
    });
  }

  sortColumnByKey(key) {
    if (this.props.employeeExpenceLiabilityAccountsFilteredList.Data) {
      this.props.employeeExpenceLiabilityAccountsFilteredList.Data = sortByKey(
        this.props.employeeExpenceLiabilityAccountsFilteredList.Data,
        key
      );
      this.setState({
        sortedKey: key
      });
      if (this.state.isTableSorted) {
        this.setState({
          isTableSorted: false
        });
        this.props.employeeExpenceLiabilityAccountsFilteredList.Data = this.props.employeeExpenceLiabilityAccountsFilteredList.Data.reverse();
      }
      if (this.state.isTableSorted === undefined) {
        this.setState({
          isTableSorted: true
        });
      }
      if (this.state.isTableSorted === false) {
        this.setState({
          isTableSorted: true
        });
      }
    }
  }

  showSortedIcon() {
    if (this.state.isTableSorted) {
      return <i className="fa fa-sort-asc" aria-hidden="true" />;
    } else if (this.state.isTableSorted === false) {
      return <i className="fa fa-sort-desc" aria-hidden="true" />;
    } else {
      return <i className="fa fa-sort" aria-hidden="true" />;
    }
  }

  //used for drop down
  showCustomIcon() {
    return <i className="fa fa-angle-down" aria-hidden="true" />;
  }

  setEmployeeLiabilityAccountContext(
    deductionListItem,
    mapGLDeductionData,
    newValue
  ) {
    this.props.setEmployerFormUpdated(true);
    mapGLDeductionData.data.Data.forEach(item => {
      if (item.CompanyDeductionId === deductionListItem.CompanyDeductionId) {
        deductionListItem.EmployeeContributionLiabilityGlAccountId = newValue;
        item.EmployeeContributionLiabilityGlAccountId = newValue;
      }
    });

    let update = {};
    update[deductionListItem.CompanyDeductionId] = newValue;
    this.setState(update);
    if (isInDidUpdate === false) {
      this.setState({
        IsFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
    if (
      deductionListItem.EmployeeContributionLiabilityGlAccountId !== null ||
      deductionListItem.EmployeeContributionLiabilityGlAccountId !== 0
    ) {
      this.props.setFormUpdate(true);
      let unmappedList = this.state.unmappedList.slice();
      unmappedList = unmappedList.filter(function(removeItem) {
        return removeItem !== deductionListItem.CompanyDeductionId;
      });
      this.setState({
        unmappedList: unmappedList,
        unmappedCount: unmappedList.length
      });
      if (this.state.unmappedCheckStateEmployeeTab === true) {
        if (
          deductionListItem.EmployeeContributionLiabilityGlAccountId !== null ||
          deductionListItem.EmployeeContributionLiabilityGlAccountId !== 0
        ) {
          this.getFilteredList();
        }
      }
    }
  }

  setDeductions(selectedDeductions) {
    this.setState({
      selectedDeductions
    });
  }

  setDeductionFilter(selectedDeductions) {
    this.setState({ selectedDeductions });
  }

  saveEmplyeeExpenceLiabilityAccounts(mapGLDeductionData) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.props.setLoader(true);
    let data = this.state;
    var self = this;
    let patchAction = [];
    if (mapGLDeductionData.data.Data !== undefined) {
      mapGLDeductionData.data.Data.forEach(item => {
        let patchItem = {
          Operation: "update",
          PathOrKey: "DeductionGLAccountMapping",
          Value: [
            {
              GLAccountKey: "EmployerContributionExpenseGlAccount",
              PkId: item.EmployerContributionExpenseGlAccountPkId,
              CompanyDeductionId: item.CompanyDeductionId,
              GLAccountId: item.EmployerContributionExpenseGlAccountId,
              IsIncludeInGlReport: item.IsIncludeInGlReport
            },
            {
              GLAccountKey: "EmployeeContributionLiabilityGlAccount",
              PkId: item.EmployeeContributionLiabilityGlAccountPkId,
              CompanyDeductionId: item.CompanyDeductionId,
              GLAccountId: item.EmployeeContributionLiabilityGlAccountId,
              IsIncludeInGlReport: item.IsIncludeInGlReport
            },
            {
              GLAccountKey: "EmployerContributionLiabilityGlAccount",
              PkId: item.EmployerContributionLiabilityGlAccountPkId,
              CompanyDeductionId: item.CompanyDeductionId,
              GLAccountId: item.EmployerContributionLiabilityGlAccountId,
              IsIncludeInGlReport: item.IsIncludeInGlReport
            }
          ]
        };
        patchAction.push(patchItem);
      });
    }

    const url = `${
      common.WEB_API_URL
    }/v1/subscriber/company/deduction/action/mapglaccount`;
    axios
      .post(url, patchAction)
      .then(function(response) {
        self.props.setLoader(false);
        if (response.data.ErrorCount > 0) {
          response.data.Errors.forEach((item, index) => {
            if (index === 0) {
              alert(item.Messages);
            }
          });
        } else {
          self.props.setEmployeeData(data);
          self.props.setEmployerFormUpdated(false);
          self.props.showToaster(
            true,
            "General ledger accounts have been updated."
          );
          self.setState({
            IsFormUpdated: false
          });
          self.props.setFormUpdate(false);
          self.props.gotoNextTab(1);
        }
      })
      .catch(function(error) {});
  }

  renderButtons(isUnmappedAny, mapGLDeductionData, employeeList) {
    return (
      <div className="nc-button-group">
        <button
          type="button"
          id="save-map-gl-employee-paid-deductions"
          className={
            isUnmappedAny === true || this.state.IsFormUpdated === false
              ? "disabled-button"
              : "nc-button secondary-button"
          }
          onClick={this.saveEmplyeeExpenceLiabilityAccounts.bind(
            this,
            mapGLDeductionData
          )}
          disabled={isUnmappedAny || this.state.IsFormUpdated === false}
        >
          SAVE
        </button>
      </div>
    );
  }

  //for tabs at top

  handleSelect(key) {
    this.setState({ key });
  }

  renderEmployerExpenseContentRow(employeeList, mapGLDeductionData) {
    if (!employeeList) {
      return <div>No Records Found.</div>;
    }
    return employeeList.map((item, index) => {
      let isUnmapped = false;

      if (item.EmployeeContributionLiabilityGlAccountId === null) {
        isUnmapped = true;
      }

      return (
        <div
          key={index}
          className="table-row clearfix"
          style={{ paddingTop: "25px" }}
        >
          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 table-cell name-column"
            style={{ cursor: "default" }}
          >
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              {isUnmapped ? (
                <Badge className="unmapped-badge">Unmapped</Badge>
              ) : null}
            </div>
            <div
              id={"list-page-col5-" + item.CompanyDeductionId}
              className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            >
              {item.CompanyDeductionName}
            </div>
          </div>
          <div className="nc-field-group clearfix">
            <div className="field-name">
              <span className="hidden-lg hidden-md clearfix">
                Liability Account
              </span>
            </div>
            <div className="input-field employee-liability-account-dropdown col-lg-4 col-md-5 col-xs-12 col-sm-12">
              <DropDown
                id={"list-page-col6-" + item.CompanyDeductionId}
                placeholder="Select"
                options={this.props.employeeLiabilityAccountDropDown}
                value={item.EmployeeContributionLiabilityGlAccountId}
                onSelect={this.setEmployeeLiabilityAccountContext.bind(
                  this,
                  item,
                  mapGLDeductionData
                )}
              />
            </div>
          </div>
        </div>
      );
    });
  }

  renderEmployeeLiabilityTable(employeeList, mapGLDeductionData) {
    if (!this.props.employeeExpenceLiabilityAccountsFilteredList.Data) {
      return <div />;
    }
    let isUnmappedAny = false;
    if (this.state.unmappedCount > 0) {
      isUnmappedAny = true;
    }

    return (
      <div>
        <div className="">
          <div className="col-lg-12 col-md-12 row table-header visible-lg visible-md employee-tab-header">
            <div
              id="list-page-col5-header"
              onClick={this.sortColumnByKey.bind(
                this,
                "CompanyDeductionName",
                employeeList
              )}
              className="col-lg-5 col-md-5 user-column-header"
            >
              DEDUCTIONS{" "}
              {this.state.sortedKey === "CompanyDeductionName" ? (
                this.showSortedIcon()
              ) : (
                <i className="fa fa-sort" aria-hidden="true" />
              )}
            </div>
            <div
              id="list-page-col6-header"
              className="col-lg-4 col-md-5 column-header"
              style={{ textAlign: "center" }}
            >
              LIABILITY ACCOUNT{" "}
            </div>
          </div>
          {this.renderEmployerExpenseContentRow(
            employeeList,
            mapGLDeductionData
          )}
        </div>

        <div className="seperator">
          <hr />
        </div>
        <div className="footer-button-container">
          {isUnmappedAny ? (
            <div
              id="unmapped-count-section-employee-paid-deductions"
              className="unmapped-count-badge-section clearfix"
            >
              <Badge
                id="unmapped-count-badge-employee-paid-deductions"
                className="unmapped-count-badge"
              >
                {this.state.unmappedCount}
              </Badge>
              <span
                id="unmapped-count-description-employee-paid-deductions"
                className="unmapped-count-badge-description"
              >
                Unmapped deductions remaining. All deductions need to be mapped
                or excluded before continuing.
              </span>
            </div>
          ) : (
            <div id="employee-paid-deductions-footer-section-all-mapped" />
          )}
          {this.renderButtons(isUnmappedAny, mapGLDeductionData, employeeList)}
        </div>
      </div>
    );
  }

  showUnMappedBadgeSection() {
    return (
      <div>
        <div>
          <Badge className="unmapped-badge-for-non-selection">Unmapped</Badge>
        </div>
      </div>
    );
  }

  render() {
    let mapGLDeductionData = this.props.EmployerExpensesData;
    let employeeList = this.props.employeeExpenceLiabilityAccountsFilteredList
      .Data;
    const { Id } = this.props;
    return (
      <div className="">
        <EmployeeLiabilityAccountsAdvanceFilter
          onSelectedDeductions={selectedDeductions =>
            this.setDeductions(selectedDeductions)
          }
          getUnmappedCheckState={unmappedCheckState =>
            this.setUnmappedState(unmappedCheckState)
          }
          Id={Id}
        />

        <div
          className="list-page-grid-table col-lg-12 col-md-12 col-sm-12 col-xs-12"
          style={{ display: "block" }}
        >
          {this.props.employeeExpenceLiabilityAccountsFilteredList.length ===
          0 ? (
            <div
              id={Id.loadingCount + "-employee-tab"}
              className="nc-rowcount-results"
            >
              {"Loading..."}
            </div>
          ) : this.props.employeeExpenceLiabilityAccountsFilteredList
            .DataCount === 1 ? (
            <div
              id={Id.listCount + "-employee-tab"}
              className="nc-rowcount-results"
            >{`We found ${
              this.props.employeeExpenceLiabilityAccountsFilteredList.DataCount
            } result.`}</div>
          ) : (
            <div
              id={Id.listCount + "-employee-tab"}
              className="nc-rowcount-results"
            >{`We found ${
              this.props.employeeExpenceLiabilityAccountsFilteredList.DataCount
            } results.`}</div>
          )}

          {this.renderEmployeeLiabilityTable(employeeList, mapGLDeductionData)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let employeeLiabilityAccountDropDown = [];

  if (
    state.generalLedgerReducer.employeeLiabilityAccountsList.length > 0 ||
    state.generalLedgerReducer.employeeLiabilityAccountsList.DataCount > 0
  ) {
    employeeLiabilityAccountDropDown = state.generalLedgerReducer.employeeLiabilityAccountsList.Data.map(
      (item, index) => {
        return {
          value: item.GLAccountId,
          label: `${item.GLAccountNumber} - ${item.GLAccountName}`
        };
      }
    );
  }

  return {
    employeeLiabilityAccountsList: state.employeeLiabilityAccountsList,
    employeeLiabilityAccountDropDown: employeeLiabilityAccountDropDown,
    employeeExpenceLiabilityAccountsFilteredList:
      state.generalLedgerReducer.employeeExpenceLiabilityAccountsFilteredList,
    employerExpenceAccountsList:
      state.generalLedgerReducer.employerExpenceAccountsList,
    EmployerExpensesData: state.generalLedgerReducer.EmployerExpensesData,
    employeeExpenceLiabilityAccountsList:
      state.generalLedgerReducer.employeeExpenceLiabilityAccountsList,
    employerExpenceLiabilityAccountsFilteredList:
      state.generalLedgerReducer.employerExpenceLiabilityAccountsFilteredList,
    globalNavClick: state.globalNavClick,
    formUpdatedData: state.formUpdatedData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEmployeeExpenceLiabilityAccountsList,
      getEmployeeLiabilityAccountsList,
      updatedEmployeeAccountFilteredList,
      setEmployeeData,
      showToaster,
      setEmployerExpencesData,
      updatedFilteredList,
      getEmployerExpenceLiabilityAccountsList,
      setLoader: setLoader,
      setFormUpdate: setFormUpdate,
      getEmployeeExpenceLiabilityAccountsFilteredList,
      isOverlayClickable: isOverlayClickable,
      setGlobalNavClick: setGlobalNavClick,
      CallbackToQuickLink: CallbackToQuickLink
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployeeLiabilityAccounts)
);
