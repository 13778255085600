import { connect } from "react-redux";
import PayrollDashboard from "../containers/PayrollDashboard";
import {
  fetchPayrollDashboardSessionData,
  fetchDashboardScheduledPayrolls,
  makeClearPayrollDashboardItems,
  loadAndSetMorePosts,
  payrollStartOver,
  initiatePayroll,
  fetchAndSetTypes,
  searchAndSetPostsUnapprovedPto,
  searchAndSetPostsTimecardAlerts,
  searchAndSetPostsEmployeeUpdates
} from "../network/actions";
import {
  setPayrollActiveTab,
  clearPayrollActiveTab
} from "../reducers/payrollTypeActiveTab";
import { clearToast } from "app/common/reducers/toast";
import {
  makeOptions,
  makeSelectedOptions,
  makeCriterion,
  makeSortCriterion
} from "app/common/reducers/filters";

export const getSearchParams = state => {
  const defaultSearchParams = {
    Fields: ["*"],
    Criterion: [],
    Sort: [],
    PageSize: 5
  };
  const filters = state.common.filters;

  let Criterion = [];
  Criterion.push(makeCriterion("Employees", filters.Employees));
  Criterion.push(makeCriterion("LeaveType", filters.LeaveType));

  Criterion = Criterion.filter(c => c);

  defaultSearchParams.Criterion = Criterion;
  const sortOrder = state.common.sortOrder;
  let Sort = null;
  Sort = makeSortCriterion(sortOrder);
  defaultSearchParams.Sort = Sort === null ? [] : Sort;

  return defaultSearchParams;
};

const stateToProps = state => ({
  payrollDashboardItems: state.payrollDashboard.payrollDashboardItems, //table
  payrollDashboardSessionData:
    state.payrollDashboard.payrollDashboardSessionData,
  payrollTypeData: state.payrollDashboard.payrollTypeData, //table
  toastData: state.common.toast,
  payrollTypeActiveTab: state.payrollDashboard.payrollTypeActiveTab,
  filterData: makeOptions(state.common.filters),
  selectedOptions: makeSelectedOptions(state.common.filters),
  searchParams: getSearchParams(state)
});
const actionCreators = {
  fetchPayrollDashboardSessionData,
  makeClearPayrollDashboardItems,
  fetchDashboardScheduledPayrolls,
  loadAndSetMorePosts,
  setPayrollActiveTab,
  clearPayrollActiveTab,
  clearToast,
  initiatePayroll,
  payrollStartOver,
  fetchAndSetTypes,
  searchAndSetPostsUnapprovedPto,
  searchAndSetPostsTimecardAlerts,
  searchAndSetPostsEmployeeUpdates
};
export default connect(stateToProps, actionCreators)(PayrollDashboard);
