import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import classNames from "classnames/bind";

export default class CustomFieldItem extends Component {
  state = {
    open: false
  };

  onClick = () => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const { props, state } = this;
    let chevron = classNames(
      "accordian-icon fa",
      state.open ? "fa-chevron-up" : "fa-chevron-down"
    );

    let headerBackgroundClass = "";
    if (state.open) {
      headerBackgroundClass = "grey-color-background";
    } else {
      headerBackgroundClass = "white-color-background";
    }
    return (
      <div className="nc-accordian custom-field clearfix">
        <div
          className={`custom-field-accordian ${headerBackgroundClass} col-lg-12 col-md-12`}
        >
          <a
            className="accordian-arrow"
            onClick={this.onClick}
            style={{ width: "15%" }}
          >
            <i className={chevron} />
          </a>
          <a className="accordian-name" onClick={this.onClick}>
            {props.title}
          </a>
        </div>
        <div
          className="collapse-section"
          style={
            state.open
              ? { borderBottom: "1px solid #d6d6d6" }
              : { borderBottom: "none" }
          }
        >
          <Collapse in={state.open}>{props.children}</Collapse>
        </div>
      </div>
    );
  }
}
