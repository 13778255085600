/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import RadioButton from "./BasicRadioButton";
const RadioButtonWithLink = props => {
  const { radios = [] } = props;
  if (radios.length > 0) {
    return (
      <div className="button-type-container" style={props.style}>
        {radios.map(item => (
          <RadioButton
            {...item}
            id={item.id}
            key={item.id}
            onChange={props.onChange}
            name={props.name}
            width={props.width}
            type={props.type}
            checked={item.value === props.value}
            disabled={props.disabled}
          />
        ))}
        <div
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
          style={{ marginBottom: "20px", marginTop: "10px" }}
        >
          <a
            id={props.addLabelId}
            onClick={props.onClick}
            style={{ fontSize: "15px" }}
          >
            <i className={props.iconClassName} />
            {props.linkLabel}
          </a>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
export default RadioButtonWithLink;
