import React, { Component } from "react";
import axios from "axios";
import "component-library/notifications/Notifications.css";
import ViewFullJobHistoryHeader from "component-library/notifications/NotificationsViewFullJobHistory/ViewFullJobHistoryHeader";
import ViewFullJobHistory from "component-library/notifications/NotificationsViewFullJobHistory/ViewFullJobHistory";
import NotificationModal from "component-library/notifications/NotificationsFilters/NotificationModal";
import CancelRequestModal from "component-library/notifications/NotificationsFilters/CancelRequestModal";
import * as util from "app/common/utilities";
import { NO_MENU_ACCESS } from "../util/constants";
import * as common from "../../app/common";
import { convertUserTimeZone } from "../../app/navigation/utilities";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let previousMenuItem = {};
class NotificationsViewFullJobHistory extends Component {
  state = {
    showModal: false,
    companyName: "",
    jobTypeName: "",
    companyId: 0,
    error: ""
  };

  onClickBackButton = () => {
    this.props.history.push("/notifications");
  };

  componentWillMount() {
    this.fetchAndSetFullJobHistory();
  }

  componentWillUnmount() {
    this.props.clearEntityData();
  }

  fetchAndSetFullJobHistory = () => {
    const { JobId } = this.props.match.params;
    if (!util.isNullOrZero(parseInt(JobId, 10))) {
      this.props.fetchAndSetFullJobHistory(parseInt(JobId, 10), () => {
        this.props.setLoader(false);
      });
    }
  };

  onClickCompanyLink = (data, destinationCompanyId, destinationCompanyName) => {
    this.props.fetchAndSetUserAccessForDestinationPage(
      destinationCompanyId,
      data.AppUIMenuItemId,
      (state, error) => {
        if (state) {
          const company = this.props.companySelectionList.filter(function(
            item
          ) {
            return item.SubscriberCompanyId === destinationCompanyId;
          });
          this.props.setEntityName("notification");
          this.props.onClickDestinationCompanyLink(
            true,
            company[0].UIId,
            data.AppUIMenuItemId,
            data.AppUIProductId
          );
          this.props.clearEntityData();
        } else {
          let jobTypeName = "";
          if (data.JobTypeName === "CopyEarning") {
            jobTypeName = "Earnings";
          } else if (data.JobTypeName === "CopyDeduction") {
            jobTypeName = "Deductions";
          }
          this.setState({
            showModal: true,
            companyId: destinationCompanyId,
            companyName: destinationCompanyName,
            jobTypeName: jobTypeName,
            error: error
          });
        }
      }
    );
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
    this.props.setLoader(true);
    let destinationCompanyId = this.state.companyId;
    if (destinationCompanyId !== 0 && this.state.error === NO_MENU_ACCESS) {
      const company = this.props.companySelectionList.filter(function(item) {
        return item.SubscriberCompanyId === destinationCompanyId;
      });

      if (company.length === 0) {
        axios.defaults.headers.common["Authorization"] = null;
        axios.defaults.headers.common["SessionContext"] = null;
        this.props.setSessionContext();
        window.location.href = common.LEGACY_LOGIN_URL + "/n/Account/Logout";
      } else {
        let patchActions = [];
        patchActions.push({
          Operation: "UPDATE",
          PathOrKey: "CurrentSubscriberId",
          Value: company[0].SubscriberId
        });
        patchActions.push({
          Operation: "UPDATE",
          PathOrKey: "CurrentSubscriberCompanyId",
          Value: company[0].SubscriberCompanyId
        });

        var self = this;
        const url = `${common.WEB_API_URL}/api/v1/usersession/sessioncontext`;
        axios
          .patch(url, patchActions)
          .then(function(response) {
            axios.defaults.headers.common["SessionContext"] =
              response.data.data[0];
            sessionStorage.setItem("SessionContext", response.data.data[0]);
            self.props.setSessionContext();
            window.location.href =
              common.LEGACY_LOGIN_URL + "/n/NewToLegacy?target=1";
          })
          .catch(function(error) {});
      }
    } else {
      this.props.setLoader(false);
    }
  };

  close = () => {
    this.setState({
      showModal: false
    });
  };

  closeCancelRequestModal = () => {
    this.setState({
      openCancelRequestModal: false,
      cancelRequestMessage: ""
    });
  };

  onClickCancelRequest = id => {
    this.props.cancelProductJobRequest(id, (ErrorCount, message) => {
      if (ErrorCount > 0) {
        this.setState({
          cancelRequestMessage: message,
          openCancelRequestModal: true
        });
      } else if (ErrorCount === 0) {
        const { JobId } = this.props.match.params;
        if (!util.isNullOrZero(parseInt(JobId, 10))) {
          this.props.fetchAndSetFullJobHistory(parseInt(JobId, 10), () => {
            this.props.setLoader(false);
          });
        }
      }
    });
  };

  getParentId = id => {
    let parentItem = this.props.allMenusList.Data.filter(function(Item) {
      return Item.Id === id;
    });
    const { ParentId } = parentItem[0];
    if (ParentId !== 0) {
      previousMenuItem = parentItem[0];
      return this.getParentId(ParentId);
    } else {
      return previousMenuItem.Id;
    }
  };

  onChange = e => {
    const edits = {
      [e.name]: e.value
    };
    this.props.setJobException(edits);
  };

  onClickSubmitNewJob = id => {
    const { submitAsNewJob, viewAllNotifications, jobHistoryData } = this.props;
    const { expectionJobs = {} } = viewAllNotifications;

    let selectedTasks = expectionJobs[id];
    let key = Object.keys(selectedTasks).filter(function(trueItem) {
      return selectedTasks[trueItem];
    });
    key = key.map(Number);
    let resubmitTaskList = [];
    let entity = "";
    let AppUIProductId = 0;
    let AppUIMenuItemId = 0;
    let SourceSubscriberId = 0;
    let SourceSubscriberName = "";
    let SourceCompanyId = 0;
    let SourceCompanyName = "";
    let SubscriberCompanyId = 0;
    let SubscriberCompanyName = "";

    if (selectedTasks && jobHistoryData !== undefined) {
      key.forEach(item => {
        resubmitTaskList.push(
          ...jobHistoryData.JobTaskList.filter(i => i.JobTaskId === item)
        );
      });
    }
    if (resubmitTaskList.length > 0) {
      entity = resubmitTaskList[0].JobTaskDataType;
      AppUIProductId = jobHistoryData.AppUIProductId;
      AppUIMenuItemId = jobHistoryData.AppUIMenuItemId;
      SourceSubscriberId = jobHistoryData.SourceSubscriberId;
      SourceSubscriberName = jobHistoryData.SourceSubscriberName;
      SourceCompanyId = jobHistoryData.SourceCompanyId;
      SourceCompanyName = jobHistoryData.SourceCompanyName;
      SubscriberCompanyId = resubmitTaskList[0].SubscriberCompanyId;
      SubscriberCompanyName = resubmitTaskList[0].SubscriberCompanyName;
    }

    let data = {
      entity: entity,
      AppUIProductId: AppUIProductId,
      AppUIMenuItemId: AppUIMenuItemId,
      SourceSubscriberId: SourceSubscriberId,
      SourceSubscriberName: SourceSubscriberName,
      SourceCompanyId: SourceCompanyId,
      SourceCompanyName: SourceCompanyName
    };

    this.props.fetchAndSetUserAccessForDestinationPage(
      SubscriberCompanyId,
      AppUIMenuItemId,
      (state, error) => {
        if (state) {
          this.props.setLoader(true);
          submitAsNewJob(id, resubmitTaskList, data, () => {
            window.scrollTo(0, 0);
            this.props.clearJobException();
            this.fetchAndSetFullJobHistory();
          });
        } else {
          this.props.setLoader(false);
          this.setState({
            showModal: true,
            companyName: SubscriberCompanyName,
            error: "NO_SUBMIT_ACCESS"
          });
        }
      }
    );
  };

  render() {
    const {
      userTimeZoneOffset = {},
      userTimeZoneAbbreviation = "",
      jobHistoryData
    } = this.props;
    const expectionJobs = this.props.viewAllNotifications.expectionJobs;

    return (
      <div className="nc-list-page-container">
        <div className="back-button-link">
          <button
            className="primary-link-button"
            onClick={() => this.onClickBackButton()}
            id="back-to-all-notifications-button"
            style={{ textTransform: "none" }}
          >
            <FontAwesomeIcon icon={faChevronLeft} /> Back to All Notifications
          </button>
        </div>
        <div>
          <ViewFullJobHistoryHeader
            data={jobHistoryData}
            viewId={this.props.viewId}
          />
          <ViewFullJobHistory
            data={jobHistoryData}
            onClickCompanyLink={this.onClickCompanyLink}
            userTimeZoneOffset={userTimeZoneOffset.data}
            convertUserTimeZone={convertUserTimeZone}
            userTimeZoneAbbreviation={userTimeZoneAbbreviation.data}
            onClickCancelRequest={this.onClickCancelRequest}
            resubmitJobData={expectionJobs[jobHistoryData.JobId]}
            onClickSubmitNewJob={this.onClickSubmitNewJob}
            onChange={this.onChange}
          />
        </div>
        <NotificationModal
          show={this.state.showModal}
          closeModal={this.toggleModal}
          close={this.close}
          companyName={this.state.companyName}
          jobTypeName={this.state.jobTypeName}
          error={this.state.error}
        />{" "}
        <CancelRequestModal
          show={
            this.state.openCancelRequestModal &&
            this.state.cancelRequestMessage !== ""
          }
          message={this.state.cancelRequestMessage}
          closeModal={this.closeCancelRequestModal}
        />
      </div>
    );
  }
}
export default NotificationsViewFullJobHistory;
