import Loadable from "react-loadable";
import ChunkLoading from "app/common/UIComponents/ChunkLoading";

const Notifications = Loadable({
  loader: () =>
    import("./Notifications" /* webpackChunkName: "notifications" */),
  loading: ChunkLoading
});

export { Notifications };
