/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const EligibilityPeriod = ({ data = {}, onEditClick, onSetupClick }) => {
  return (
    <div
      className="clearfix"
      style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}
    >
      <div className="col-lg-3 col-md-3">Eligibility Determination Period</div>
      <div
        style={{ display: "flex", alignItems: "center" }}
        className="col-lg-9 col-md-9"
      >
        <div
          style={{
            marginRight: "10px",
            padding: "15px",
            backgroundColor: "#ccc",
            color: "#999"
          }}
        >
          <i>This has not been setup for your company.</i>
        </div>{" "}
        <a onClick={onSetupClick}>Set Up Now</a>
        {/* <span>Rolling Year <i onClick={onEditClick} className="fa fa-pencil" /></span>   */}
      </div>
    </div>
  );
};

export default EligibilityPeriod;
