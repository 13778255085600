import React from "react";
import { FormFieldGroup } from "component-library/formSections/index";
import * as FIELDS from "../../utils/Constants";

const componentProps = {
  inputId: FIELDS.DIFFERENT_NAME,
  label: FIELDS.DIFFERENT_NAME_LABEL,
  radios: [
    {
      id: 1,
      label: "Yes",
      value: true,
      radioStyles: "radioStyles"
    },
    {
      id: 0,
      label: "No",
      value: false,
      radioStyles: "radioStyles"
    }
  ],
  inputName: FIELDS.DIFFERENT_NAME,
  type: "basic",
  inputType: "radio",
  optional: true,
  colSpan: "col-lg-3 col-md-3 col-sm-3",
  fieldType: "inline"
};
const DifferentName = props => {
  const { data, onChange } = props;
  return (
    <FormFieldGroup
      {...componentProps}
      value={data[componentProps.inputName]}
      onChange={onChange}
    />
  );
};

export default DifferentName;
