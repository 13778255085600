import React from "react";

const UtilitiesSvg = ({ height, width, style }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 90.2 93"
      style={{ enableBackground: "new 0 0 90.2 93", ...style }}
      xmlSpace="preserve"
      height={height}
      width={width}
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: "\n\t.st0{fill:#555555;}\n\t.st1{fill:#E8D900;}\n"
        }}
      />
      <path
        className="st0"
        d="M77.4,67.4l-10.7-9.5c-0.4,0.9-0.8,1.7-1.3,2.5l10.2,9.1c1.2,1.1,1.9,2.6,2,4.3c0.1,1.6-0.5,3.2-1.6,4.4
	c-1.1,1.3-2.8,2-4.5,2l0,0l-0.2,0c-1.7,0-3.3-0.8-4.4-2l-9.1-10.2c-0.8,0.5-1.6,0.9-2.5,1.3L65,79.9c1.6,1.8,3.9,2.9,6.3,2.9h0.2
	c2.4,0,4.6-0.9,6.2-2.6c1.7-1.7,2.6-3.9,2.6-6.3C80.3,71.5,79.3,69.1,77.4,67.4z"
      />
      <g>
        <path
          className="st0"
          d="M46.6,68c-10.4,0-18.9-8.5-18.9-18.9s8.5-18.9,18.9-18.9c10.4,0,18.9,8.5,18.9,18.9S57.1,68,46.6,68z
		 M46.6,32.7c-9,0-16.4,7.3-16.4,16.4s7.3,16.4,16.4,16.4c9,0,16.4-7.3,16.4-16.4S55.7,32.7,46.6,32.7z"
        />
      </g>
      <g>
        <g>
          <polygon
            className="st1"
            points="46.6,38.2 49.9,44.9 57.3,46 52,51.2 53.2,58.5 46.6,55 40.1,58.5 41.3,51.2 36,46 43.4,44.9 		"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M38.4,60.8l1.6-9.2l-6.7-6.5l9.2-1.3l4.1-8.4l4.1,8.4l9.2,1.3l-6.7,6.5l1.6,9.2l-8.3-4.3L38.4,60.8z
			 M46.6,53.6l4.9,2.6l-0.9-5.5l4-3.9l-5.5-0.8l-2.5-5l-2.5,5l-5.5,0.8l4,3.9l-0.9,5.5L46.6,53.6z"
          />
        </g>
      </g>
      <path
        className="st0"
        d="M28.7,36.5c-3.5-3-8.7-3.1-12.4-0.1c-0.2,0.2-0.4,0.3-0.6,0.5c-2.9,2.9-3.8,7.2-2.4,11l0.1,0.3l-3,2.3l-0.3-0.3
	C3.7,42.7,4,31.4,11.1,24.3l8.5-8.5l2.2,0.5c1.4,0.3,2.9-0.1,4-1.2c1.1-1.1,1.5-2.6,1.2-4l-0.5-2.1l3-3c0.4-0.4,1.1-0.4,1.4,0
	l11.6,11.4c0.4,0.4,0.4,1,0,1.4l-3,3l-2.6-0.5c-1.4-0.3-2.9,0.3-3.7,1.4c-0.9,1.3-1.2,2.8-0.9,4.4c0.3,1.5,1.1,2.8,2.1,3.9
	c0.8-0.5,1.5-1,2.4-1.4c-0.9-0.8-1.5-1.9-1.8-3.1c-0.1-0.8,0.1-1.5,0.4-2.1c0.2-0.3,0.6-0.5,1-0.4l3.3,0.7c0.4,0.1,0.9,0,1.2-0.4
	l3.5-3.5c1.4-1.5,1.4-3.8,0-5.3L32.8,4c-0.7-0.7-1.6-1.1-2.6-1.1c0,0,0,0,0,0c-1,0-1.9,0.4-2.6,1.1L24,7.5c-0.3,0.3-0.5,0.8-0.4,1.2
	l0.6,2.9c0.1,0.6-0.1,1.2-0.5,1.6c-0.4,0.4-0.9,0.5-1.4,0.4L19.5,13c-0.2,0-0.4,0-0.6,0c-0.3,0.1-0.5,0.2-0.7,0.4l-9.1,9.1
	c-8.4,8.4-8.4,22,0,30.4l0.1,0.1c0.7,0.6,1.6,0.6,2.3,0.1l4.1-3.1c0.7-0.5,1-1.5,0.6-2.4c-1.5-3-0.9-6.5,1.5-8.9
	c0.1-0.1,0.3-0.3,0.4-0.4c2.7-2.2,6.7-2,9.2,0.4C27.7,38,28.2,37.2,28.7,36.5z"
      />
      <g>
        <path
          className="st0"
          d="M86.6,24.7c-0.1-0.7-0.4-1.4-0.9-1.9c-0.6-0.6-1.4-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9l-5.2,5.2
		c-1.3,1.2-3.3,1.2-4.5,0l-3.9-4c-1.2-1.3-1.2-3.3,0-4.5l5.2-5.2c0.8-0.8,1.1-2.1,0.7-3.2c-0.4-1.1-1.4-1.9-2.6-2
		c-1-0.1-2-0.1-3.1,0c-4.6,0.4-8.8,2.5-11.8,6c-3,3.5-4.5,8-4.1,12.6c0,0.1,0,0.1,0,0.2c0.9,0.2,1.8,0.5,2.7,0.9c0-0.4,0-0.8,0-1.3
		c-0.1-0.8-0.1-1.6,0-2.4c0.7-8,7.8-14,15.8-13.3l1,0.1l-5.8,5.8c-2.3,2.3-2.3,6.1,0,8.4l3.9,3.9c2.3,2.3,6.1,2.3,8.4,0l5.8-5.8
		l0.1,1c0,0.4,0.1,0.8,0.1,1.2c0,8-6.5,14.6-14.6,14.6h-1.1c-0.4,0-0.9,0-1.3,0c0.4,0.9,0.7,1.8,0.9,2.7c0.1,0,0.1,0,0.2,0
		c0.5,0.1,0.9,0.1,1.4,0.1c0.6,0,1.1,0,1.7-0.1c4.6-0.5,8.7-2.7,11.7-6.2C85.7,33.8,87.1,29.3,86.6,24.7z"
        />
        <path
          className="st0"
          d="M41.2,70.3c-0.9-0.2-1.9-0.5-2.7-0.9c0,0.4,0,0.9,0,1.3c0,0.4,0,0.7,0,1.1c0,3.9-1.5,7.6-4.3,10.3
		c-2.8,2.8-6.4,4.3-10.3,4.3c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0h-1.1l5.8-5.8c2.3-2.3,2.3-6.1,0-8.4l-3.9-3.9
		c-1.1-1.1-2.6-1.7-4.2-1.7c0,0,0,0,0,0c-1.6,0-3.1,0.6-4.2,1.7L9.4,74l-0.1-1c-0.1-0.8-0.1-1.6,0-2.4c0.3-3.9,2.1-7.4,5-10
		c3-2.5,6.7-3.8,10.6-3.5c0.4,0,0.9,0,1.3,0c-0.3-0.9-0.6-1.8-0.9-2.7c-0.1,0-0.1,0-0.2,0c-0.5-0.1-0.9,0-1.4-0.1
		c-0.6,0-1.1,0-1.7,0.1C12.7,55.5,5.8,64,6.7,73.5c0.1,0.7,0.4,1.4,0.9,1.9c1.2,1.2,3.2,1.2,4.3-0.1l5.2-5.2
		c0.6-0.6,1.5-0.9,2.3-0.9c0.8,0,1.6,0.3,2.3,0.9l3.9,4c1.3,1.3,1.3,3.3,0,4.5l-5.2,5.2c-0.8,0.8-1.1,2.1-0.7,3.2
		c0.4,1.1,1.4,1.9,2.6,2c0.6,0.1,1.2,0.1,1.7,0.1c0.5,0,0.9,0,1.4-0.1c9.5-0.7,16.6-9.1,15.9-18.6C41.2,70.4,41.2,70.4,41.2,70.3z"
        />
      </g>
    </svg>
  );
};

export default UtilitiesSvg;
