import React from "react";
import { FormSection, FormFieldGroup } from "../../../../formSections";
import IncludedInPayroll from "./IncludedInPayroll";
import * as Fields from "../FieldNames";
const LeaveReturnDetails = ({ data = {}, onChange }) => {
  return (
    <div>
      <FormSection title="Leave Return Details" hr>
        <FormFieldGroup
          inputType="singleDatePicker"
          label="Leave Return Date"
          fieldType="inline"
          inputName="rehireDate"
          value={data.rehireDate}
          colSpan="col-lg-3 col-md-3"
          Size={[4, 4, 12, 12]}
          onChange={onChange}
          style={{ marginBottom: "15px" }}
          getToFromValue={onChange}
          showNoDateSection
        />
        <IncludedInPayroll
          label="Should this employee be included in payroll?"
          colSpan="col-lg-3 col-md-3"
          data={data}
          inputName={Fields.leaveReturnIncludedInPayroll}
          value={data[Fields.leaveReturnIncludedInPayroll]}
          onChange={onChange}
          middleText="From which paycheck date"
        />
        <FormFieldGroup
          inputType="textarea"
          inputName="statusNotes"
          label="Notes"
          value={data.statusNotes}
          description="250 characters max"
          rows={6}
          descriptionStyle="italic"
          fieldType="inline"
          colSpan="col-lg-3 col-md-3"
          Size={[8, 8, 12, 12]}
          onChange={onChange}
          optional
        />
      </FormSection>
    </div>
  );
};

export default LeaveReturnDetails;
