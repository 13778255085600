export default {
  Id: 0,
  Status: "Completed",
  ErrorCount: 0,
  Errors: null,
  DataCount: 3,
  Data: [
    {
      EmployeeId: 1,
      Id: 1,
      EmployeeName: "Susan Christopher 1",
      Name: "Susan Christopher 1",
      EmployeePictureUrl:
        "https://sfiedlerappblobstore.blob.core.windows.net/profilepictures/PriyaRosen.jpg",
      EmployeeCurrentHomeAddress: "1524 Hackberry Ave. Metairie, LA 70001",
      EmployeeSuggestedHomeAddress: "1524 Hackberry Drive. Metairie, LA 70001",
      EmployeeCurrentMinimumWage: 0,
      EmployeeSuggestedMinimumWage: 0,
      ApproverList: [],
      LeaveRequestList: [],
      TimeCardAlertList: [],
      UpdateTimeStamp: "0001-01-01T00:00:00",
      EmployeeUpdateList: []
    },
    {
      EmployeeId: 2,
      Id: 2,
      EmployeeName: "Joe Walker 2",
      Name: "Joe Walker 2",
      EmployeePictureUrl:
        "https://sfiedlerappblobstore.blob.core.windows.net/profilepictures/JamesKapoor.jpg",
      EmployeeCurrentHomeAddress: "1234 Hwy. 190. Covington, LA 70089",
      EmployeeSuggestedHomeAddress: "1524 Hackberry Drive. Metairie, LA 70001",
      EmployeeCurrentMinimumWage: 0,
      EmployeeSuggestedMinimumWage: 0,
      ApproverList: [],
      LeaveRequestList: [],
      TimeCardAlertList: [],
      UpdateTimeStamp: "0001-01-01T00:00:00",
      EmployeeUpdateList: []
    },
    {
      EmployeeId: 3,
      Id: 3,
      EmployeeName: "Shawn Black 3",
      Name: "Shawn Black 3",
      EmployeePictureUrl:
        "https://sfiedlerappblobstore.blob.core.windows.net/profilepictures/AlexanderGeorge.jpg",
      EmployeeCurrentHomeAddress: "1524 Hackberry Drive. Metairie, LA 70001",
      EmployeeSuggestedHomeAddress: "",
      EmployeeCurrentMinimumWage: 0,
      EmployeeSuggestedMinimumWage: 0,
      ApproverList: [],
      LeaveRequestList: [],
      TimeCardAlertList: [],
      UpdateTimeStamp: "0001-01-01T00:00:00",
      EmployeeUpdateList: []
    }
  ],
  NextUri: "",
  PreviousUri: ""
};
