import React from "react";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";
import { HolidayCalendar, HolidayCalendarAddPage } from ".";
import { CopyEntityPage } from "entity-copy/pages";
const basePath = "/payroll/utilities/holidaycalendar/";
const HolidayCalendarRoutes = props => (
  <Switch>
    <Route
      exact
      path={basePath + "addcalendar/:HolidayCalendarId"}
      component={HolidayCalendarAddPage}
    />
    <Route
      exact
      path={basePath}
      render={() => <HolidayCalendar {...props} />}
    />

    <Route
      exact
      path="/payroll/utilities/copy/holidaycalendar"
      render={props => (
        <CopyEntityPage
          entityName="Holiday Calendar"
          entityPath="holidaycalendar"
          showFilters={false}
          selectEntityLabel="Calendar(s)"
          onCancelClick={() => props.history.push(basePath)}
        />
      )}
    />
  </Switch>
);

export default withRouter(HolidayCalendarRoutes);
