import React, { Component } from "react";
import PageHeader from "component-library/custom-fields/ListPageHeader";
// import NoFieldsPresent from "component-library/payroll-holiday-calendar/AddCalendar/NoHolidayFoundPage";
import CustomFieldsAccordian from "component-library/custom-fields/CustomFieldsAccordian";
import CustomFieldsFilterContainer from "./CustomFieldsFiltersContainer";
import { getCustomFieldsData, getBasicMenuSearchParams } from "../utils";
import { TITLE, DESCRIPTION } from "../Constants.js";
import * as Id from "app/common/CommonIds";

var CustomFieldsMenus = [];
class CustomFields extends Component {
  componentDidMount() {
    let searchParams = getBasicMenuSearchParams();
    this.props.searchAndSetPosts(searchParams, () => {
      CustomFieldsMenus = getCustomFieldsData(this.props.employeeMenu.Data);
    });
  }

  render() {
    // const { history } = this.props;
    return (
      <div className="nc-list-page-container custom-fields-container">
        <PageHeader
          backToLink={"/people/utilities"}
          title={TITLE}
          description={DESCRIPTION}
          addButtonLabel="Add Field"
          onAddButtonClick={() => alert("This will add a custom field!")}
          onCopyButtonClick={() => {
            alert("This will lead to copy page of custom fields!");
            // history.push("/people/utilities/copy/customfields");
          }}
          hideHistory={true}
          hideCopy={false}
          Id={Id}
        />
        <CustomFieldsFilterContainer {...this.props} />
        {/* <NoFieldsPresent
                    line1="Let's add a field!"
                    line2="Add custom fields like text boxes, date selectors, radio buttons and more."
                /> */}
        <CustomFieldsAccordian CustomFieldsMenus={CustomFieldsMenus} />
      </div>
    );
  }
}

export default CustomFields;
