import React from "react";
import "../list-view/Usericon.css";
import PropTypes from "prop-types";

const DependentsUserIcon = ({ EmployeeName, IsVerified, onClickEmployee }) => {
  return (
    <div className="user-icon">
      <div className="user-info">
        <div className="user-name" onClick={onClickEmployee}>
          {EmployeeName}
        </div>
        <div className="user-description">
          {IsVerified && (
            <span className="user-verified">
              <i className="fa fa-check-circle-o" />
              Verified
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default DependentsUserIcon;

DependentsUserIcon.propTypes = {
  onClickEmployee: PropTypes.func
};
