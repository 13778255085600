import React from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import * as Fields from "../FieldNames";

const SelfService = ({ currentStatus = "", data = {}, onChange }) => {
  return (
    <FormSection title={"Self-Service"} hr>
      <div style={{ fontSize: "15px", marginBottom: "20px" }}>
        When this employee was {currentStatus}, employee self-service user
        access was restricted.
      </div>
      <FormFieldGroup
        inputType="radio"
        inputName={Fields.selfServiceDefaultConfig}
        label="Do you want to apply the Employee Self-Service Default Configuration to this user?"
        fieldType="inline"
        value={data[Fields.selfServiceDefaultConfig]}
        radios="boolean"
        class="col-lg-12"
        colSpan="col-lg-3 col-md-3"
        Size={[6, 4, 12, 12]}
        onChange={onChange}
      />
    </FormSection>
  );
};

export default SelfService;
