import React from "react";

const CopyEntityOption = ({ name, selected, onSelect, icon, className }) => (
  <div className={className} style={{ padding: "0px" }}>
    <li
      className={`list-item ${selected ? "selected" : ""}`}
      onClick={onSelect}
    >
      <input type="checkbox" checked={selected || false} readOnly />
      <label>
        {icon && <i className={`fa ${icon}`} />}
        {name}
      </label>
    </li>
  </div>
);

const EmployeeProfileCheckbox = ({
  options = [],
  selected = {},
  onSelect,
  loading
}) => {
  if (loading) {
    return <div style={{ minHeight: 120 }} />;
  }
  return (
    <div className="list-page-grid-table col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <ul className="nc-dropdown-navigation-multiselect">
        <div className="row grid-container" style={{ width: "100%" }}>
          {options.map(({ value, ...rest }) => (
            <CopyEntityOption
              key={value}
              selected={selected[value]}
              {...rest}
              className="grid-option"
              onSelect={() => onSelect(value, !selected[value])}
            />
          ))}
        </div>
      </ul>
    </div>
  );
};

export default EmployeeProfileCheckbox;
