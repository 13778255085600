export const SET_ADD_PAYROLL_OPTIONS =
  "@payroll-schedule/SET_ADD_PAYROLL_OPTIONS";
export const CLEAR_ADD_PAYROLL_OPTIONS =
  "@payroll-schedule/CLEAR_ADD_PAYROLL_OPTIONS";
export const SET_PAYROLL_SCHEDULE_DATA = "SET_PAYROLL_SCHEDULE_DATA";
export const SET_BILLING_TYPE = "SET_BILLING_TYPE";

const addPayrollScheduleOptions = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ADD_PAYROLL_OPTIONS:
      const { name } = payload;
      return {
        ...state,
        [name]: payload.options
      };
    case SET_PAYROLL_SCHEDULE_DATA:
      const { payrollName } = payload;
      return {
        ...state,
        [payrollName]: payload.values
      };
    case SET_BILLING_TYPE:
      return {
        ...state,
        [payload.name]: payload.options
      };
    case CLEAR_ADD_PAYROLL_OPTIONS:
      return (state = {});
    default:
      return state;
  }
};

export default addPayrollScheduleOptions;

export const setAddPayrollScheduleOptions = (name, options) => ({
  type: SET_ADD_PAYROLL_OPTIONS,
  payload: { name, options }
});

export const validationBillingType = (name, options) => ({
  type: SET_BILLING_TYPE,
  payload: { name, options }
});

export const clearAddPayrollScheduleOptions = () => ({
  type: CLEAR_ADD_PAYROLL_OPTIONS
});

export const setPayrollScheduleById = (payrollName, values) => ({
  type: SET_PAYROLL_SCHEDULE_DATA,
  payload: { payrollName, values }
});
