import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setDisplayMainPageOverlay,
  setMenuParentName,
  setDisplaySideMenuBar,
  reshuffleMenus,
  setDisplaySideMenuPanel
} from "../../actions/GlobalNavigationActions";

class Overlay extends Component {
  overlayClickHandler() {
    this.props.setDisplayMainPageOverlay(false);
    let menuParentName = sessionStorage.getItem("MenuParentName");
    if (this.props.menuState !== undefined) {
      let menustate = [];
      menustate[0] = this.props.menuState[1];
      if (menustate[0] !== undefined && menustate[0].SelectedProductId === -1) {
        this.props.setMenuParentName("Notifications");
        sessionStorage.setItem("MenuParentName", "Notifications");
        menuParentName = sessionStorage.getItem("MenuParentName");
        this.props.setDisplaySideMenuPanel(false);
      }
      if (
        this.props.allMenusList !== null &&
        this.props.allMenusList.ErrorCount === 0
      ) {
        this.props.allMenusList.Data.forEach(menuItem => {
          if (menuItem.Id === menustate[0].SelectedProductId) {
            this.props.setMenuParentName(menuItem.MenuName);
          }
        });
      }
    }

    this.props.reshuffleMenus(this.props.menuState, this.props.allMenusList);
    if (menuParentName === "Notifications") {
      this.props.setDisplaySideMenuBar(false);
    } else {
      this.props.setDisplaySideMenuBar(true);
    }
  }

  render() {
    const { show, children } = this.props;
    return (
      <div
        className="main-page-overlay-panel root-main-page-overlay"
        onClick={
          this.props.isOverlayClickable
            ? this.overlayClickHandler.bind(this)
            : () => {}
        }
      >
        {show && <div className="default-overlay" />}
        {children}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    menuState: state.menuState,
    allMenusList: state.allMenusList,
    isDisplayMainPageOverlayPanel:
      state.menuReducer.isDisplayMainPageOverlayPanel,
    isOverlayClickable: state.isOverlayClickable,
    currentMenuName: state.menuReducer.currentMenuName
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setDisplayMainPageOverlay: setDisplayMainPageOverlay,
      reshuffleMenus: reshuffleMenus,
      setDisplaySideMenuBar: setDisplaySideMenuBar,
      setMenuParentName: setMenuParentName,
      setDisplaySideMenuPanel
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overlay);
