import React, { Component } from "react";
import { EntityListFiltersDesktop } from "component-library/employee-profile";
import { withRouter } from "react-router-dom";
import TreeView from "component-library/tree-view";
import { connect } from "react-redux";
import {
  fetchAndSetCompanyList,
  fetchAndSetStatusList,
  fetchAndSetEmployeeTreeViewData
} from "../network/actions";
import { makeOptions } from "../utils";
class TreeViewDemo extends Component {
  state = { tree: [] };

  componentDidMount = () => {
    const { props } = this;
    props.loadStatusList();
    props.loadCompanyList();
  };

  makeOnSelect = () => () => alert("Specify on Select");
  makeOnSelectAll = () => () => alert("Specify on Select All");

  render() {
    const { data = {} } = this.props;
    return (
      <div>
        <EntityListFiltersDesktop
          page="EmployeeProfileTree"
          options={this.props.filterData}
          toggleAdvanceFilter={() =>
            this.setState({
              showAdvanceFilters: !this.state.showAdvanceFilters
            })
          }
          makeOnSelect={this.makeOnSelect}
          makeSelectAll={this.makeOnSelectAll}
        />
        <TreeView data={data} selectable={false} />
      </div>
    );
  }
}

const stateToProps = state => {
  return { filters: makeOptions(state.common.filters) };
};
const actionCreators = {
  loadEmployeeTreeView: fetchAndSetEmployeeTreeViewData,
  loadCompanyList: fetchAndSetCompanyList,
  loadStatusList: fetchAndSetStatusList
};
export default withRouter(connect(stateToProps, actionCreators)(TreeViewDemo));
