import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
//import formatTime from "./../FormatTime";
import DependentsUserIcon from "./DependentsUserIcon";
import PropTypes from "prop-types";
import "./dependentListItem.css";
import { tooltipCase } from "../Constants";
const tooltipDependentStatusColumn = (
  <Tooltip id="tooltipDependentStatusIcon">Employee is Dependent</Tooltip>
);
const tooltipBeneficiaryStatusColumn = (
  <Tooltip id="tooltipBeneficiaryStatusColumn">Employee is Beneficiary</Tooltip>
);
const tooltipThrashIcon = (
  <Tooltip id="tooltipThrashIcon">Delete/Inactive</Tooltip>
);
const tooltipEditIcon = <Tooltip id="tooltipEditIcon">Edit</Tooltip>;

function getStatusTooltip(status) {
  switch (status) {
    case tooltipCase.Dependent:
      return tooltipDependentStatusColumn;
    case tooltipCase.Beneficiary:
      return tooltipBeneficiaryStatusColumn;
    default:
      return null;
  }
}
const DependentListItem = ({
  item,
  onClickEmployee,
  onDeleteEmployee,
  onEditEmployee
}) => {
  return (
    <div
      className="table-row col-lg-12 col-md-12 col-sm-12 col-xs-12"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 name-column table-cell">
        <DependentsUserIcon {...item} />
      </div>
      <div className="col-lg-4 col-md-2 col-sm-12 col-xs-12 table-cell">
        <div style={{ width: "fit-content", float: "left", marginRight: "5%" }}>
          <OverlayTrigger
            placement="top"
            overlay={getStatusTooltip("Dependent")}
          >
            <span className="field-value type-tag dependent">
              {item.EmployeeType}
            </span>
          </OverlayTrigger>
        </div>
        {item.IsEmployerBeneficiary && (
          <div style={{ width: "fit-content", float: "left" }}>
            <OverlayTrigger
              placement="top"
              overlay={getStatusTooltip("Beneficiary")}
            >
              <span className="field-value type-tag beneficiary">
                <i className="fa fa-star" />Beneficiary
              </span>
            </OverlayTrigger>
          </div>
        )}
      </div>
      <div className="status-container col-lg-2 col-md-2 col-sm-12 col-xs-12">
        <span className="hidden-lg hidden-md field-header">Relation : </span>
        {item.EmployerRelationship}
      </div>
      <div className="col-lg-2 col-md-2 col-sm-3 col-xs-3 list-page-grid-table-cell-actions">
        <div>
          <div className="list-page-delete-section">
            <OverlayTrigger placement="top" overlay={tooltipThrashIcon}>
              <a href="." onClick={e => onDeleteEmployee}>
                <i
                  id="entity-delete-1"
                  className="list-page-delete-icon fa fa-trash-o"
                />
              </a>
            </OverlayTrigger>
          </div>
          <div className="list-page-edit-section">
            <OverlayTrigger placement="top" overlay={tooltipEditIcon}>
              <a href="." onClick={() => onEditEmployee}>
                <i
                  id="entity-edit-1"
                  className="list-page-edit-icon fa fa-pencil"
                />
              </a>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DependentListItem;

DependentListItem.propTypes = {
  item: PropTypes.object,
  onClickEmployee: PropTypes.func
};
