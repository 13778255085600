import { makeCriterion } from "../../../employee/base/utils";

export const getCustomFieldsData = employeeMenu => {
  var newEmployeeMenu = [];
  var newEmployeeSubMenu = [];
  var newEmployeeSubSubMenu = [];

  employeeMenu.forEach(item => {
    if (item.ParentCustomFieldGroupId === 1) {
      var booleanValue = employeeMenu.filter(function(obj) {
        return item.CustomFieldGroupId === obj.ParentCustomFieldGroupId;
      });

      booleanValue.forEach(i => {
        newEmployeeSubSubMenu = employeeMenu.filter(function(obj) {
          return i.CustomFieldGroupId === obj.ParentCustomFieldGroupId;
        });

        newEmployeeSubMenu.push({
          CustomFieldGroupId: i.CustomFieldGroupId,
          CustomFieldGroupName: i.CustomFieldGroupName,
          ParentCustomFieldGroupId: i.ParentCustomFieldGroupId,
          SubSubItemList: newEmployeeSubSubMenu,
          CustomField: item.CustomField
        });
      });

      newEmployeeMenu.push({
        CustomFieldGroupId: item.CustomFieldGroupId,
        CustomFieldGroupName: item.CustomFieldGroupName,
        Discription: item.Discription,
        ImageUrl: item.ImageUrl,
        ParentCustomFieldGroupId: item.ParentCustomFieldGroupId,
        CustomField: item.CustomField,
        SubItemList: newEmployeeSubMenu
      });

      newEmployeeSubMenu = [];
      newEmployeeSubSubMenu = [];
    }
  });

  let array = [];
  newEmployeeMenu.map(item =>
    item.SubItemList.map(i =>
      i.SubSubItemList.map(s => s.CustomField !== null && array.push(i))
    )
  );
  return array;
};

export const getBasicMenuSearchParams = () => {
  const defaultSearchParams = {
    Fields: ["*"],
    Criterion: [],
    Sort: [],
    PageSize: 5
  };
  let Criterion = [];
  Criterion.push(makeCriterion("CustomFieldGroupId", [1]));
  Criterion = Criterion.filter(c => c);
  defaultSearchParams.Criterion = Criterion;

  return defaultSearchParams;
};
