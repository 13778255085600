import React, { Component } from "react";
import {
  CurrentStatus,
  HireRehire,
  TerminationIndex,
  Leave,
  Inactive,
  Deceased,
  Transferred,
  Retired
} from "component-library/employee-profile/employment-info/change-status";
import Toaster from "component-library/toasters/index";
import OverrideDateModal from "component-library/employee-profile/employment-info/OverrideDateModal";
import OverrideEligibility from "component-library/employee-profile/employment-info/OverrideEligibility";
import PreJobHistoryModal from "component-library/employee-profile/employment-info/PreJobHistoryModal";
import * as Fields from "component-library/employee-profile/employment-info/change-status/FieldNames";

export default class StatusInfo extends Component {
  state = {
    data: {
      IncludedInPayrollStartDate:
        "Thu May 10 2018 12:00:00 GMT+0530 (India Standard Time)",
      OverrideTerminationDate: false,
      OverrideTerminationRehire: false,
      TerminationRehireEligiblity: true,
      TerminationDate:
        "Thu Jan 10 2018 12:00:00 GMT+0530 (India Standard Time)",
      LeaveStartDate: "Thu Jan 10 2018 12:00:00 GMT+0530 (India Standard Time)",
      ExpectedLeaveReturnDate:
        "Thu Jan 18 2018 12:00:00 GMT+0530 (India Standard Time)",
      RehireDate: "Thu Jan 15 2008 12:00:00 GMT+0530 (India Standard Time)"
    },
    showTerminationModal: false,
    showPreHistoryModal: false,
    showSuccessModal: false,
    showEligibilityModal: false
  };

  onChange = e => {
    const data = { [e.name]: e.value };
    if (
      e.name === Fields.includedInPayroll ||
      e.name === Fields.terminationrehireEligibility ||
      e.name === Fields.reterminationrehireEligibility
    ) {
      data[e.name] = e.value === "true" ? true : false;
    }
    if (e.name === Fields.overrideHireDate) {
      if (e.value) {
        this.setState({
          showTerminationModal: true,
          overrideDateName: e.name,
          overrideDateValue: e.value,
          overrideDateLabel: "Hire"
        });
        data[e.name] = this.state.data[e.name];
      }
    }
    if (e.name === Fields.overrideRehireDate) {
      if (e.value) {
        this.setState({
          showTerminationModal: true,
          overrideDateName: e.name,
          overrideDateValue: e.value,
          overrideDateLabel: "Re-hire"
        });
        data[e.name] = this.state.data[e.name];
      }
    }
    if (e.name === Fields.overrideTerminationDate) {
      if (e.value) {
        this.setState({
          showTerminationModal: true,
          overrideDateName: e.name,
          overrideDateValue: e.value,
          overrideDateLabel: "Termination"
        });
        data[e.name] = this.state.data[e.name];
      }
    }
    if (e.name === Fields.overrideReterminationDate) {
      if (e.value) {
        this.setState({
          showTerminationModal: true,
          overrideDateName: e.name,
          overrideDateValue: e.value,
          overrideDateLabel: "Re-Termination"
        });
        data[e.name] = this.state.data[e.name];
      }
    }
    if (e.name === Fields.overrideDeceasedDate) {
      if (e.value) {
        this.setState({
          showTerminationModal: true,
          overrideDateName: e.name,
          overrideDateValue: e.value,
          overrideDateLabel: "Deceased"
        });
        data[e.name] = this.state.data[e.name];
      }
    }
    if (e.name === Fields.overrideInactivationDate) {
      if (e.value) {
        this.setState({
          showTerminationModal: true,
          overrideDateName: e.name,
          overrideDateValue: e.value,
          overrideDateLabel: "Inactivation"
        });
        data[e.name] = this.state.data[e.name];
      }
    }
    if (e.name === Fields.overrideLeaveReturnDate) {
      if (e.value) {
        this.setState({
          showTerminationModal: true,
          overrideDateName: e.name,
          overrideDateValue: e.value,
          overrideDateLabel: "Leave Return"
        });
        data[e.name] = this.state.data[e.name];
      }
    }
    if (e.name === Fields.overrideLeaveStartDate) {
      if (e.value) {
        this.setState({
          showTerminationModal: true,
          overrideDateName: e.name,
          overrideDateValue: e.value,
          overrideDateLabel: "Leave Start"
        });
        data[e.name] = this.state.data[e.name];
      }
    }
    if (e.name === Fields.overrideTransferredDate) {
      if (e.value) {
        this.setState({
          showTerminationModal: true,
          overrideDateName: e.name,
          overrideDateValue: e.value,
          overrideDateLabel: "Transferred"
        });
        data[e.name] = this.state.data[e.name];
      }
    }
    if (e.name === Fields.overrideRetirementDate) {
      if (e.value) {
        this.setState({
          showTerminationModal: true,
          overrideDateName: e.name,
          overrideDateValue: e.value,
          overrideDateLabel: "Retirement"
        });
        data[e.name] = this.state.data[e.name];
      }
    }
    if (e.name === Fields.overrideTerminationRehire) {
      if (e.value) {
        this.setState({
          showEligibilityModal: true,
          overrideDateName: e.name,
          overrideDateValue: e.value,
          overrideEligibilityLabel: "Termination"
        });
        data[e.name] = this.state.data[e.name];
      }
    }
    if (e.name === Fields.overrideReterminationRehire) {
      if (e.value) {
        this.setState({
          showEligibilityModal: true,
          overrideDateName: e.name,
          overrideDateValue: e.value,
          overrideEligibilityLabel: "Re-termination"
        });
        data[e.name] = this.state.data[e.name];
      }
    }
    this.editData(data);
  };
  onOverrideClick = () => {
    const data = {
      [this.state.overrideDateName]: this.state.overrideDateValue
    };
    this.editData(data);
    this.setState({
      showTerminationModal: false,
      showEligibilityModal: false,
      overrideDateName: null,
      overrideDateValue: null
    });
  };
  onKeepClick = () => {
    this.setState({
      showTerminationModal: false,
      showEligibilityModal: false,
      overrideDateName: null,
      overrideDateValue: null
    });
  };
  onDiscard = () => {
    this.setState({
      showPreHistoryModal: false
    });
  };
  onSave = () => {
    this.setState({
      showPreHistoryModal: false,
      showToaster: true,
      toasterClass: "success",
      toasterMessage: "Your Employee's Status have been saved.",
      showCloseIcon: true
    });
  };
  showLeaveModal = () => {
    this.setState({
      showTerminationModal: false,
      showEligibilityModal: false,
      showPreHistoryModal: true
    });
  };

  toggleToaster = () => {
    this.setState({
      showToaster: false
    });
  };

  editData(edits) {
    this.setState({ data: { ...this.state.data, ...edits } });
  }
  render() {
    return (
      <div>
        <div style={{ marginBottom: "15px" }}>
          <button
            className="nc-button secondary-button"
            onClick={this.props.toggleOverlay}
          >
            <i className="fa fa-pencil" />Change Status
          </button>
        </div>

        <Toaster
          id={"employeeStatusToaster"}
          show={this.state.showToaster}
          alertClassName={this.state.toasterClass}
          message={this.state.toasterMessage}
          closeToasterMessage={this.toggleToaster}
          showCloseIcon={this.state.showCloseIcon}
        />

        <CurrentStatus onChange={this.onChange} data={this.state.data} />
        <HireRehire onChange={this.onChange} data={this.state.data} />
        <TerminationIndex onChange={this.onChange} data={this.state.data} />
        <Leave onChange={this.onChange} data={this.state.data} />
        <Inactive onChange={this.onChange} data={this.state.data} />
        <Transferred onChange={this.onChange} data={this.state.data} />
        <Retired onChange={this.onChange} data={this.state.data} />
        <Deceased onChange={this.onChange} data={this.state.data} />

        <div style={{ position: "fixed", bottom: "100px", right: "30px" }}>
          <button
            style={{
              //marginBottom: "20px",
              boxShadow: "2px 2px 15px #999"
            }}
            className="nc-button primary-button"
            onClick={this.onSave}
          >
            Save
          </button>
        </div>

        <OverrideEligibility
          show={this.state.showEligibilityModal}
          onClose={() => this.setState({ showEligibilityModal: false })}
          onOverrideClick={this.onOverrideClick}
          onKeepClick={this.onKeepClick}
          OverrideEligibilityLabel={this.state.OverrideEligibilityLabel}
          onJobHistoryClick={this.showLeaveModal}
        />
        <OverrideDateModal
          show={this.state.showTerminationModal}
          onClose={() => this.setState({ showTerminationModal: false })}
          onOverrideClick={this.onOverrideClick}
          onKeepClick={this.onKeepClick}
          overrideDateLabel={this.state.overrideDateLabel}
          onJobHistoryClick={this.showLeaveModal}
        />
        <PreJobHistoryModal
          show={this.state.showPreHistoryModal}
          onClose={() => this.setState({ showPreHistoryModal: false })}
          onSave={this.onSave}
          onDiscard={this.onDiscard}
        />
      </div>
    );
  }
}
