import React from "react";
import FormAccordian from "component-library/accordians/FormAccordian";
import * as Fields from "./FieldNames";
import Termination from "./Termination";
import Retermination from "./Retermination";
import { FormSection } from "component-library/formSections";
import { NoStatusChanges } from "./common";

const TerminationIndex = ({ onChange, data = {} }) => {
  return (
    <FormAccordian title="Termination / Re-termination" id="3">
      <div>
        <FormSection>
          {data[Fields.terminationDate] !== undefined && (
            <Termination onChange={onChange} data={data} />
          )}
          {data[Fields.reterminationDate] !== undefined && (
            <Retermination onChange={onChange} data={data} />
          )}
          {data[Fields.terminationDate] !== undefined &&
            data[Fields.reterminationDate] !== undefined && (
              <NoStatusChanges entity="Termination / Re-termination" />
            )}
        </FormSection>
      </div>
    </FormAccordian>
  );
};

export default TerminationIndex;
