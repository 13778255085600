/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import Media from "react-media";
import * as utilities from "app/common/utilities";
import ThirdPartyPayeeStatusDropdown from "./SystemLevelThirdPartyPayeeStatusDropdown";
import ThirdPartyPayeeCategoryDropdown from "./SystemLevelThirdPartyPayeeCategoryDropdown";
import ThirdPartyPayeeDropdown from "./SystemLevelThirdPartyPayeeDropdown";
import {
  getFilteredThirdPartyPayeeSetupList,
  setSelectedFilters,
  sortFilteredList
} from "../../../actions/systemlevel/thirdpartypayee/ThirdPartyPayeeSetupListingActions";
import * as Id from "app/common/CommonIds";
import $ from "jquery";
import Scrollbars from "react-custom-scrollbars";
import classnames from "classnames/bind";
class ThirdPartyPayeeAdvanceFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategories: [],
      selectedStatus: [],
      selectedThirdPartyPayees: [],
      sortItemList: [
        { id: 1, label: "Name : A to Z", sortTypeId: 1, sortKey: "Name" },
        { id: 2, label: "Name : Z to A", sortTypeId: 2, sortKey: "Name" },
        {
          id: 3,
          label: "Category : A to Z",
          sortTypeId: 1,
          sortKey: "ThirdPartyPayeeCategoryName"
        },
        {
          id: 4,
          label: "Category : Z to A",
          sortTypeId: 2,
          sortKey: "ThirdPartyPayeeCategoryName"
        },
        {
          id: 5,
          label: "Status : A to Z",
          sortTypeId: 1,
          sortKey: "CreateWorkflowStateName"
        },
        {
          id: 6,
          label: "Status : Z to A",
          sortTypeId: 2,
          sortKey: "CreateWorkflowStateName"
        }
      ],
      isResetToDefault: false,
      mobileFilterPanel: false,
      mobileSortPanel: false,
      isInitialLoad: true
    };

    this.setCategories = this.setCategories.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.setThirdPartyPayees = this.setThirdPartyPayees.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
  }

  onResize = () => {
    let device = "lg";
    if (window.innerWidth <= 767) {
      device = "xs";
      if (this.state.mobileFilterPanel || this.state.mobileSortPanel) {
        if (!$("html").hasClass("html-modal-open")) {
          $("html").addClass("html-modal-open");
        }
      }
    }
    if (window.innerWidth > 767 && window.innerWidth < 991) {
      device = "sm";
      if (this.state.mobileFilterPanel || this.state.mobileSortPanel) {
        if (!$("html").hasClass("html-modal-open")) {
          $("html").addClass("html-modal-open");
        }
      }
    }
    if (window.innerWidth > 991 && window.innerWidth < 1200) {
      device = "md";
      if ($("html").hasClass("html-modal-open")) {
        $("html").removeClass("html-modal-open");
      }
    }
    if (window.innerWidth > 1200) {
      if ($("html").hasClass("html-modal-open")) {
        $("html").removeClass("html-modal-open");
      }
    }
    this.setState({ device });
  };
  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  closeMobileFilterPanel = () => {
    this.setState({ mobileFilterPanel: false }, () => {
      if (
        ["sm", "xs"].includes(this.state.device) &&
        $("html").hasClass("html-modal-open")
      ) {
        $("html").removeClass("html-modal-open");
      }
    });
  };
  openMobileFilterPanel = () => {
    this.setState({ mobileFilterPanel: true }, () => {
      if (
        ["sm", "xs"].includes(this.state.device) &&
        this.state.mobileFilterPanel
      ) {
        $("html").addClass("html-modal-open");
      } else {
        $("html").removeClass("html-modal-open");
      }
    });
  };

  closeMobileSortPanel = () => {
    this.setState({ mobileSortPanel: false }, () => {
      if (
        ["sm", "xs"].includes(this.state.device) &&
        $("html").hasClass("html-modal-open")
      ) {
        $("html").removeClass("html-modal-open");
      }
    });
  };
  openMobileSortPanel = () => {
    this.setState({ mobileSortPanel: true }, () => {
      if (
        ["sm", "xs"].includes(this.state.device) &&
        this.state.mobileSortPanel
      ) {
        $("html").addClass("html-modal-open");
      } else {
        $("html").removeClass("html-modal-open");
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.thirdPartyPayeeMainList) {
      if (
        this.props.thirdPartyPayeeMainList.DataCount > 0
        // prevProps.thirdPartyPayeeMainList.DataCount !==
        //   this.props.thirdPartyPayeeMainList.DataCount
      ) {
        if (
          (this.state.selectedThirdPartyPayees.length === 0 &&
            this.state.isInitialLoad) ||
          this.props.isInitialLoad
        ) {
          let selectedThirdPartyPayees = [0];
          this.props.thirdPartyPayeeMainList.Data.forEach(item => {
            selectedThirdPartyPayees.push(item.SystemThirdPartyPayeeId);
          });
          this.setState(
            {
              selectedThirdPartyPayees
            },
            () => this.props.setInitialState(false)
          );

          // if (
          //   prevProps.thirdPartyPayeeMainList.DataCount !==
          //   this.props.thirdPartyPayeeMainList.DataCount
          // ) {
          //   this.setState(
          //     {
          //       selectedThirdPartyPayees
          //     }
          //     // () => this.props.setInitialState(false)
          //   );
          // }
        }
      }
    }

    if (this.props.thirdPartyPayeeCategoryList) {
      if (this.props.thirdPartyPayeeCategoryList.DataCount > 0) {
        if (
          this.state.selectedCategories.length === 0 &&
          this.state.isInitialLoad
        ) {
          let selectedCategories = [0];
          this.props.thirdPartyPayeeCategoryList.Data.forEach(item => {
            selectedCategories.push(item.ThirdPartyPayeeCategoryId);
          });
          this.setState({
            selectedCategories
          });
        }
      }
    }

    if (this.props.thirdPartyPayeeStatusList) {
      if (this.props.thirdPartyPayeeStatusList.DataCount > 0) {
        if (
          this.state.selectedStatus.length === 0 &&
          this.state.isInitialLoad
        ) {
          let selectedStatus = [];
          selectedStatus.push(this.props.thirdPartyPayeeStatusList.Data[0].Id);
          this.setState({
            selectedStatus
          });
        }
      }
    }

    //This code is added to resolve bug 48370
    if (
      prevProps.thirdPartyPayeeMainList !== this.props.thirdPartyPayeeMainList
    ) {
      this.getFilteredList();
    }

    if (
      prevState.selectedThirdPartyPayees !==
        this.state.selectedThirdPartyPayees ||
      prevState.selectedCategories !== this.state.selectedCategories ||
      prevState.selectedStatus !== this.state.selectedStatus
    ) {
      this.getFilteredList();
    } else if (
      this.props.thirdPartyPayeeMainList.DataCount > 0 &&
      this.state.isInitialLoad
    ) {
      this.getFilteredList();
    }
  }

  getFilteredList() {
    if (
      this.props.thirdPartyPayeeMainList.DataCount > 0 &&
      this.props.thirdPartyPayeeStatusList.DataCount > 0 &&
      this.props.thirdPartyPayeeCategoryList.DataCount > 0
    ) {
      this.setState({
        isResetToDefault: false,
        isInitialLoad: false
      });
      this.props.setSelectedFilters(
        this.state.selectedThirdPartyPayees,
        this.state.selectedCategories,
        this.state.selectedStatus
      );
      this.props.getFilteredThirdPartyPayeeSetupList(
        this.props.thirdPartyPayeeMainList.Data,
        this.state.selectedThirdPartyPayees,
        this.state.selectedCategories,
        this.state.selectedStatus
      );
    }
  }

  applyFilter() {
    this.getFilteredList();
    this.closeMobileFilterPanel();
  }

  setCategories(selectedCategories) {
    this.setState({
      selectedCategories
    });
  }

  setStatus(selectedStatus) {
    this.setState({
      selectedStatus
    });
  }

  setThirdPartyPayees(selectedThirdPartyPayees) {
    this.setState({
      selectedThirdPartyPayees
    });
  }

  setInitialLoad() {
    this.setState({
      isInitialLoad: false
    });
  }

  closeMobileFilterPanel() {
    this.refs.overlayMobileFilterPanel.hide();
  }

  closeMobileSortPanel() {
    this.refs.overlayMobileSortPanel.hide();
  }

  clearAllFilters() {
    this.setState({
      isResetToDefault: true
    });
  }

  sortColumnByKey(item) {
    if (this.props.thirdPartyPayeeFilteredList.Data) {
      let sortedFilteredList = utilities.sortByKeyString(
        this.props.thirdPartyPayeeFilteredList.Data,
        item.sortKey
      );
      if (item.sortTypeId === 2) {
        sortedFilteredList = sortedFilteredList.reverse();
      }

      let request = {
        data: {
          Data: sortedFilteredList,
          DataCount: sortedFilteredList.length
        }
      };

      this.props.sortFilteredList(request);
      //? Below method is written to preserve the sort order and highlight the option on Sort Modal
      this.props.setActiveSortOrder(item);
    }

    this.closeMobileSortPanel();
  }

  showSortListItem() {
    const { activeSortOrder = {} } = this.props;
    return this.state.sortItemList.map((item, index) => {
      return (
        <div
          key={index}
          className={classnames(
            "mobile-sort-panel-item row remove-margin-left-right",
            activeSortOrder.sortKey === item.sortKey &&
              activeSortOrder.sortTypeId === item.sortTypeId &&
              "__active"
          )}
          onClick={this.sortColumnByKey.bind(this, item)}
        >
          <div className="col-sm-12 col-xs-12">{item.label}</div>
        </div>
      );
    });
  }

  render() {
    //eslint-disable-next-line
    let mobileFilterContentStyle = {
      maxHeight: window.innerHeight - 108 + "px",
      overflowY: "auto"
    };
    let filterCount = 0;

    if (this.props.thirdPartyPayeeMainList) {
      if (
        this.props.thirdPartyPayeeMainList.DataCount ===
        this.state.selectedThirdPartyPayees.length - 1
      ) {
        filterCount = filterCount + 0;
      } else {
        filterCount = filterCount + this.state.selectedThirdPartyPayees.length;
      }
    }
    if (this.props.thirdPartyPayeeCategoryList) {
      if (
        this.props.thirdPartyPayeeCategoryList.DataCount ===
        this.state.selectedCategories.length - 1
      ) {
        filterCount = filterCount + 0;
      } else {
        filterCount = filterCount + this.state.selectedCategories.length;
      }
    }
    if (this.props.thirdPartyPayeeStatusList) {
      if (
        this.props.thirdPartyPayeeStatusList.DataCount ===
        this.state.selectedStatus.length - 1
      ) {
        filterCount = filterCount + 0;
      } else {
        filterCount = filterCount + this.state.selectedStatus.length;
      }
    }
    const mobileFilterPanelNew = (
      <Modal
        id="mobile-filter"
        //bsClass="mobile-filter-modal"
        animation={false}
        backdrop={"static"}
        keyboard={false}
        show={this.state.mobileFilterPanel}
        onHide={this.closeMobileFilterPanel}
        onEnter={() => {
          document.body.style.overflowY = "hidden";
        }}
        onExit={() => {
          document.body.style.overflowY = "auto";
        }}
      >
        {/* <div className="modal-content "> */}
        <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="header-container col-sm-12 col-xs-12 clearfix">
            <div className="close-icon col-sm-3 col-xs-3">
              <i
                id="system-level-cancel-button-close-icon"
                onClick={this.closeMobileFilterPanel.bind(this)}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
            <div className="filter-label col-sm-6 col-xs-6">
              {filterCount ? `Filters (${filterCount})` : "All Filters"}{" "}
            </div>
            <div className="clear-all-link col-sm-3 col-xs-3">
              <a onClick={this.clearAllFilters}>Clear All</a>
            </div>
          </div>
          <Scrollbars
            className="mobile-filter-content"
            style={{ height: window.innerHeight - 109 }}
          >
            {/* <div
            className="mobile-filter-content col-sm-12 col-xs-12 clearfix"
            style={mobileFilterContentStyle}
          > */}
            <div
              id="mobile-select-deduction-dropdown"
              className="mobile-filter-select-dropdown no-padding col-sm-12 col-xs-12"
            >
              <ThirdPartyPayeeDropdown
                id={Id.entityFilter}
                onSelectedThirdPartyPayees={selectedThirdPartyPayees =>
                  this.setThirdPartyPayees(selectedThirdPartyPayees)
                }
                isResetToDefault={this.state.isResetToDefault}
              />
            </div>
            <div
              id="mobile-select-category-dropdown"
              className="mobile-filter-select-dropdown no-padding col-sm-12 col-xs-12"
            >
              <ThirdPartyPayeeCategoryDropdown
                id={Id.categoryFilter}
                onSelectedCategories={selectedCategories =>
                  this.setCategories(selectedCategories)
                }
                isResetToDefault={this.state.isResetToDefault}
              />
            </div>

            <div
              id="mobile-select-status-dropdown"
              className="mobile-filter-select-dropdown no-padding col-sm-12 col-xs-12"
            >
              <ThirdPartyPayeeStatusDropdown
                id={Id.statusFilter}
                onSelectedStatus={selectedStatus =>
                  this.setStatus(selectedStatus)
                }
                isResetToDefault={this.state.isResetToDefault}
                isInitialLoad={isInitialLoad => {
                  this.setInitialLoad();
                }}
              />
            </div>
          </Scrollbars>
        </Modal.Body>
        {/* </div> */}
        <Modal.Footer>
          <div className="mobile-filter-panel-button-section">
            <button
              type="button"
              id="mobile-apply-filters"
              className="apply-filter-button"
              onClick={this.applyFilter}
            >
              APPLY FILTERS
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );

    const mobileSortPanelNew = (
      <Modal
        id="mobile-sort"
        animation={false}
        backdrop={"static"}
        keyboard={false}
        show={this.state.mobileSortPanel}
        onHide={this.closeMobileSortPanel}
        onEnter={() => {
          document.body.style.overflowY = "hidden";
        }}
        onExit={() => {
          document.body.style.overflowY = "auto";
        }}
      >
        <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="header-container col-sm-12 col-xs-12 clearfix">
            <div className="close-icon col-sm-3 col-xs-3">
              <i
                id="system-level-cancel-button-close-icon"
                onClick={this.closeMobileSortPanel.bind(this)}
                className="fa fa-times-circle-o"
              />
            </div>
            <div className="sort-label col-sm-6 col-xs-6"> Sort By </div>
          </div>
          <hr />
          {this.showSortListItem()}
        </Modal.Body>
      </Modal>
    );

    return (
      <Media query="(max-width: 991px)">
        {matches =>
          matches ? (
            <div className="nc-sort-filter-container visible-sm visible-xs col-sm-12 col-xs-12">
              <button
                id={Id.sortButtonMobile}
                onClick={this.openMobileSortPanel.bind()}
              >
                SORT BY
              </button>
              <button
                id={Id.filterButtonMobile}
                onClick={this.openMobileFilterPanel.bind()}
              >
                {/* FILTERS ({filterCount}) */}
                FILTERS{" "}
                {filterCount === 0 ? null : (
                  <span id={Id.filterCountMobile}>
                    {"(" + filterCount + ")"}
                  </span>
                )}
              </button>
              {mobileFilterPanelNew}
              {mobileSortPanelNew}
            </div>
          ) : (
            <div className="nc-filter-container third-party-listing-page-filter">
              <div className="showing-label">FILTER:</div>
              <div className="nc-filter-group" style={{ width: "100%" }}>
                <div className="nc-filter-row">
                  <div id={Id.entityFilter} className="nc-filter-dropdown">
                    <ThirdPartyPayeeDropdown
                      id={Id.entityFilter}
                      onSelectedThirdPartyPayees={selectedThirdPartyPayees =>
                        this.setThirdPartyPayees(selectedThirdPartyPayees)
                      }
                    />
                  </div>
                  <div id={Id.categoryFilter} className="nc-filter-dropdown">
                    <ThirdPartyPayeeCategoryDropdown
                      id={Id.categoryFilter}
                      onSelectedCategories={selectedCategories =>
                        this.setCategories(selectedCategories)
                      }
                    />
                  </div>

                  <div id={Id.statusFilter} className="nc-filter-dropdown">
                    <ThirdPartyPayeeStatusDropdown
                      id={Id.statusFilter}
                      onSelectedStatus={selectedStatus =>
                        this.setStatus(selectedStatus)
                      }
                      isInitialLoad={isInitialLoad => {
                        this.setInitialLoad();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Media>
    );
  }
}

function mapStateToProps(state) {
  return {
    thirdPartyPayeeMainList: state.thirdPartyPayeeMainList,
    thirdPartyPayeeFilteredList: state.thirdPartyPayeeFilteredList,
    thirdPartyPayeeCategoryList: state.thirdPartyPayeeCategoryList,
    thirdPartyPayeeStatusList: state.thirdPartyPayeeStatusList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFilteredThirdPartyPayeeSetupList,
      setSelectedFilters,
      sortFilteredList
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThirdPartyPayeeAdvanceFilter);
