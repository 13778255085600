import React from "react";
import "./svg.css";
const NoEntityContainer = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 200 200"
    style={{ enableBackground: "new 0 0 200 200" }}
    width={width}
    height={height}
  >
    <g>
      <g>
        <circle className="st0" cx="100" cy="100" r="100" />
      </g>
    </g>
    <g>
      <path
        className="st1"
        d="M130.6,100c-3.9,0-7.1,3.8-7.1,8.6s3.2,8.6,7.1,8.6c3.9,0,7.1-3.8,7.1-8.6S134.5,100,130.6,100z M130.6,113.6
		c-1.9,0-3.6-2.3-3.6-5.1s1.6-5.1,3.6-5.1s3.6,2.3,3.6,5.1S132.5,113.6,130.6,113.6z"
      />
      <path
        className="st1"
        d="M86.2,117.1c3.9,0,7.1-3.8,7.1-8.6s-3.2-8.6-7.1-8.6c-3.9,0-7.1,3.8-7.1,8.6S82.3,117.1,86.2,117.1z
		 M86.2,103.5c1.9,0,3.6,2.3,3.6,5.1s-1.6,5.1-3.6,5.1s-3.6-2.3-3.6-5.1S84.2,103.5,86.2,103.5z"
      />
      <path
        className="st1"
        d="M154.4,80.1h-5.9v-5.9c0-1.8-1.4-3.2-3.2-3.2h-5.9V65c0-1.8-1.4-3.2-3.2-3.2h-30.5H85.8H45.6
		c-1.8,0-3.2,1.4-3.2,3.2v51.7c0,1.8,1.4,3.2,3.2,3.2h5.9v5.9c0,1.8,1.4,3.2,3.2,3.2h5.9v5.9c0,1.8,1.4,3.2,3.2,3.2H100h19.8h34.6
		c1.8,0,3.2-1.4,3.2-3.2V83.3C157.6,81.5,156.2,80.1,154.4,80.1z M145.3,74.1v5.9h-27l-4.1-5.9H145.3z M119.8,91.4h17.5
		c1.1,4.1,4.5,7.4,8.9,8.5v18.5c-4.3,1.1-7.8,4.3-8.9,8.5h-17.5V91.4z M136.1,65v5.9h-24.2l-4.1-5.9H136.1z M103.8,65.2l9.3,13.5
		h-11.3l-9.3-13.5H103.8z M51.5,74.1v42.6h-5.9V65h42.5l4.1,5.9H54.7C53,70.9,51.5,72.4,51.5,74.1z M60.7,83.3v4.9
		c-0.3,0-0.5,0-0.8,0v23.6c0.3,0,0.5,0,0.8,0v14h-5.9V74.1h39.6l4.1,5.9H63.9C62.1,80.1,60.7,81.5,60.7,83.3z M100,126.9H81
		c-1.1-4.1-4.5-7.4-8.9-8.5V99.9c4.3-1.1,7.8-4.3,8.9-8.5h19V126.9z M100,88.5H78.3c0,4.9-4.1,8.9-9.3,8.9V121c5.1,0,9.3,4,9.3,8.9
		H100v5.2H63.9V83.3H100V88.5z M116.3,134.9h-12.8V84.5h12.8V134.9z M154.4,135h-34.6v-5.2H140c0-4.9,4.1-8.9,9.3-8.9V97.3
		c-5.1,0-9.3-4-9.3-8.9h-20.2v-5.2h34.6V135z"
      />
    </g>
  </svg>
);
export default NoEntityContainer;
