import React from "react";

export const CALCULATION = (
  <div>
    How would you like this earnings to calculate?<br />
    <br />
    <ul style={{ marginBottom: "0px" }}>
      <li>Pay a set dollar amount (e.g. $50, $200)</li>
      <li>Pay an hourly rate (e.g. $10 per hour)</li>
      <li>Pay a rate per unit completed (e.g. $5 per widget produced)</li>
      <li>Track hours worked while being paid a rate per unit completed.</li>
    </ul>
  </div>
);
export const PAY_CYCLE =
  "The pay cycle determines how often this earnings will be issued to your employees.";
export const REVIEW = "Let's review what you've set up so far.";

export const OPTIONS = "Select all options that apply.";
