import React from "react";
import { Modal } from "react-bootstrap";
import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";
import "./payrollDashboardModal.css";

const PayrollDashBoardModal = props => {
  return props.show ? (
    <Modal
      bsClass="entity-page-cancel-modal modal"
      animation={false}
      backdrop={"static"}
      keyboard={false}
      show={props.show}
      onHide={props.closeModal}
      restoreFocus={false}
    >
      <Modal.Header>
        <ModalHeader onClose={props.closeModal} />
      </Modal.Header>
      <Modal.Body>
        <ModalBody bodyContent={props.bodyContent} />
      </Modal.Body>
      <Modal.Footer>
        <ModalFooter {...props} />
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default PayrollDashBoardModal;
