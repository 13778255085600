import React, { Component } from "react";
import { FormFieldGroup } from "../../formSections/index";
import {
  payrollDescription,
  frequencies,
  otherFrequencies,
  toggleFrequencyData,
  PAYROLL_SCHEDULE_NAME,
  FREQUENCY,
  LABEL,
  RADIO_WITH_ICON,
  TEXT,
  HOURS,
  DAILY
} from "./Constants";

class PayrollDescriptionSection extends Component {
  onChange = (e, multi) => {
    if (multi === "scheduleDays") {
      this.props.onMultiChange(e);
    } else if (e.name === PAYROLL_SCHEDULE_NAME || e.name === FREQUENCY) {
      this.props.handleRadioChange(e);
    } else {
      this.props.handleChange(e);
    }
  };

  frequencyRadioData = data => {
    let radioButton = [];
    let mapData = data.showAll ? otherFrequencies : frequencies;
    mapData.map((f, i) => radioButton.push({ id: i, value: f, label: f }));
    return radioButton;
  };

  render() {
    const { data, payrollScheduleError } = this.props;
    const { scheduleDays, ...restPayrolldescription } = payrollDescription;
    const componentProps =
      data.Frequency === DAILY ? payrollDescription : restPayrolldescription;
    componentProps.frequency.radios = this.frequencyRadioData(data);
    componentProps.payrollName.error =
      payrollScheduleError && payrollScheduleError.error;
    if (data.showAll) {
      componentProps.frequency.linkLabel = toggleFrequencyData.hide.label;
      componentProps.frequency.iconClassName = toggleFrequencyData.hide.icon;
    } else {
      componentProps.frequency.linkLabel = toggleFrequencyData.show.label;
      componentProps.frequency.iconClassName = toggleFrequencyData.show.icon;
    }
    if (data.edit) {
      componentProps.frequency.inputType = LABEL;
      componentProps.workingHours.inputType = LABEL;
      componentProps.workingHours.description = "";
    } else {
      componentProps.frequency.inputType = RADIO_WITH_ICON;
      componentProps.workingHours.inputType = TEXT;
      componentProps.workingHours.description = HOURS;
    }
    const descriptionNodes = Object.keys(componentProps).map((key, i) => {
      return (
        <div key={key}>
          <FormFieldGroup
            {...componentProps[key]}
            value={data[componentProps[key].inputName]}
            onChange={this.onChange}
            onClick={this.props.handleShow}
            contentWidth="62%"
          />
        </div>
      );
    });
    return <div>{descriptionNodes}</div>;
  }
}
export default PayrollDescriptionSection;
