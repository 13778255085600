import React from "react";
import { FormFieldGroup } from "../../../formSections";
import SampleImage from "../../../payroll-holiday-calendar/Images/NoHoliday";

const componentProps = {
  show: true,
  inputType: "numeric",
  inputId: "WorkFaxNumber",
  inputName: "WorkFaxNumber",
  label: "",
  required: true
};

const WorkFaxNumber = props => {
  const { data = {}, onChange } = props;
  return (
    <div className="contact-info">
      <div className="contact-info col-lg-3">
        <SampleImage width={45} height={45} />
        <div
          style={{ paddingLeft: "15px", fontSize: "15px", marginTop: "15px" }}
        >
          <div>Work Fax Number</div>
          <i style={{ color: "#999" }}>(Optional)</i>
        </div>
      </div>
      <FormFieldGroup
        {...componentProps}
        value={data[componentProps.inputName]}
        onChange={onChange}
        className={"work-ph-no-textbox"}
        placeholder="504-274-8587"
        format="###-###-####"
      />
    </div>
  );
};

export default WorkFaxNumber;
