/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import SingleCheckBox from "./../../checkBoxGroup/SingleCheckBox";
import JobTaskItem from "./JobTaskItem";
import JobTaskExceptionItem from "./JobTaskExceptionItem";

import {
  STATUS_COMPLETED_WITH_EXCEPTION,
  STATUS_COMPLETED_WITH_ERRORS,
  STATUS_COMPLETED,
  STATUS_FAILED
} from "../Constants";
import * as STATE from "../Constants";

class NotificationDetailsContent extends Component {
  state = {
    selectAllExpection: false,
    selectException: false
  };

  toggleSelectAll = value => {
    const { exceptionList = [], onChange, notification = {} } = this.props;
    let selectedList = {};
    exceptionList.forEach(item => (selectedList[item.JobTaskId] = value));
    onChange({ name: notification.JobId, value: selectedList });
  };

  areAllExceptionsSelected() {
    const { exceptionList = [], resubmitJobData = {} } = this.props;
    let areAllSelected = true;
    let exceptionListWithoutResubmitted = exceptionList.filter(
      item => item.IsJobTaskResubmitted === false
    );
    exceptionListWithoutResubmitted.forEach(
      item =>
        (areAllSelected = resubmitJobData[item.JobTaskId] && areAllSelected)
    );
    return areAllSelected;
  }
  makeTitle(notification) {
    const { JobStatusId } = notification;
    if (
      [STATE.STATUS_COMPLETED, STATUS_COMPLETED_WITH_ERRORS].includes(
        JobStatusId
      )
    ) {
      return "Complete Details";
    }
    if (JobStatusId === STATE.STATUS_FAILED) {
      return "Failure Details";
    }
    if (
      [STATE.STATUS_INPROGRESS, STATE.STATUS_CURRENTLY_INPROGRESS].includes(
        JobStatusId
      )
    ) {
      return "In-Progress Details";
    }
    if (
      [STATE.STATUS_CURRENTLY_INQUEUE, STATE.STATUS_INQUEUE].includes(
        JobStatusId
      )
    ) {
      return "In-Queue Details";
    }

    return "Details";
  }

  render() {
    const {
      showTitle,
      jobTypeName,
      onClickCompanyLink,
      notification = {},
      exception,
      onChange,
      onClickSubmitNewJob = () => {},
      resubmitJobData = {},
      isExceptionSubmitted,
      viewFullJobHistory
    } = this.props;
    const { JobTaskList = [], JobId } = notification;
    const resubmitJobDataLength = Object.keys(resubmitJobData).filter(
      i => resubmitJobData[i] === true
    ).length;
    return (
      <div className="display-toggle-content col-lg-12 col-md-12 clearfix">
        {showTitle === true && (
          <div className="display-toggle-content-title" id="details-title">
            {this.makeTitle(notification)}
          </div>
        )}

        {exception ? (
          <div
            className="display-toggle-exception-item"
            style={{ paddingTop: viewFullJobHistory ? "15px" : "0" }}
          >
            {isExceptionSubmitted && (
              <div className="exception-item-select-all">
                {" "}
                <SingleCheckBox
                  id="select-all-exceptions-checkbox"
                  inputName={"selectAllExpection"}
                  value={this.areAllExceptionsSelected()}
                  label="Select All"
                  onChange={this.toggleSelectAll}
                />
                <div className="exception-item-submit">
                  <a
                    id="resubmit-job-link"
                    className={
                      resubmitJobDataLength
                        ? "secondary-link-button"
                        : "disabled"
                    }
                    onClick={
                      resubmitJobDataLength
                        ? () => onClickSubmitNewJob(JobId)
                        : () => {}
                    }
                  >
                    <i className={`fa fa-repeat`} />
                    Submit as New Job
                  </a>
                </div>
              </div>
            )}
            <div className="exception-item-container">
              {JobTaskList.filter(
                item =>
                  item.CurrentJobTaskStatusId ===
                  STATUS_COMPLETED_WITH_EXCEPTION
              ).map((row, i) => (
                <JobTaskExceptionItem
                  key={i}
                  item={row}
                  value={resubmitJobData[row.JobTaskId]}
                  onChange={() => {
                    onChange({
                      name: row.JobId,
                      value: {
                        ...resubmitJobData,
                        [row.JobTaskId]: !resubmitJobData[row.JobTaskId]
                      }
                    });
                  }}
                />
              ))}
            </div>
          </div>
        ) : null}

        {JobTaskList.filter(
          item => item.CurrentJobTaskStatusId === STATUS_COMPLETED_WITH_ERRORS
        ).map((row, i) => (
          <JobTaskItem
            key={i}
            item={row}
            jobTypeName={jobTypeName}
            onClickCompanyLink={() =>
              onClickCompanyLink(
                notification,
                row.SubscriberCompanyId,
                row.SubscriberCompanyName
              )
            }
          />
        ))}

        {JobTaskList.filter(
          item => item.CurrentJobTaskStatusId === STATUS_FAILED
        ).map((row, i) => (
          <JobTaskItem
            key={i}
            item={row}
            jobTypeName={jobTypeName}
            onClickCompanyLink={() =>
              onClickCompanyLink(
                notification,
                row.SubscriberCompanyId,
                row.SubscriberCompanyName
              )
            }
          />
        ))}

        {JobTaskList.filter(
          item => item.CurrentJobTaskStatusId === STATUS_COMPLETED
        ).map((row, i) => (
          <JobTaskItem
            key={i}
            item={row}
            jobTypeName={jobTypeName}
            onClickCompanyLink={() =>
              onClickCompanyLink(
                notification,
                row.SubscriberCompanyId,
                row.SubscriberCompanyName
              )
            }
          />
        ))}

        {JobTaskList.filter(
          item =>
            item.CurrentJobTaskStatusId !== STATUS_COMPLETED &&
            item.CurrentJobTaskStatusId !== STATUS_FAILED &&
            item.CurrentJobTaskStatusId !== STATUS_COMPLETED_WITH_ERRORS &&
            item.CurrentJobTaskStatusId !== STATUS_COMPLETED_WITH_EXCEPTION
        ).map((row, i) => (
          <JobTaskItem
            key={i}
            item={row}
            jobTypeName={jobTypeName}
            onClickCompanyLink={() =>
              onClickCompanyLink(
                notification,
                row.SubscriberCompanyId,
                row.SubscriberCompanyName
              )
            }
          />
        ))}
      </div>
    );
  }
}

export default NotificationDetailsContent;
