import React from "react";
const InProgress = ({ width, height, style }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 39.3 39.3"
    style={{ enableBackground: "new 0 0 39.3 39.3", ...style }}
    width={width}
    height={height}
  >
    <g>
      <path
        className="st8"
        d="M19.6,0.9C9.3,0.9,0.9,9.3,0.9,19.6c0,10.3,8.4,18.7,18.7,18.7c10.3,0,18.7-8.4,18.7-18.7C38.4,9.3,30,0.9,19.6,0.9z M35.5,19.6c0,8.7-7.1,15.8-15.8,15.8c-8.7,0-15.8-7.1-15.8-15.8S10.9,3.8,19.6,3.8C28.4,3.8,35.5,10.9,35.5,19.6z"
      />
      <path
        className="st8"
        d="M19.6,7.4v12.2L8.9,25.5c2.1,3.9,6.3,6.3,10.7,6.3c6.7,0,12.2-5.5,12.2-12.2S26.3,7.4,19.6,7.4z"
      />
    </g>
  </svg>
);
export default InProgress;
