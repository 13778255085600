import React from "react";
const FrequencyItem = ({ value, onChange, label, id }) => (
  <li
    className={value ? `frequency-item checked` : `frequency-item`}
    style={{ color: "#555" }}
    onClick={onChange}
  >
    <input type="checkbox" checked={value || false} readOnly />
    <label id={id}>{label}</label>
  </li>
);

export default FrequencyItem;
