import React from "react";
import PayrollDashboardSummaryItem from "../../component-library/payroll-dashboard/PayrollDashboardSummaryItem";
import CalendarPayrollItem from "../../component-library/payroll-calendars/CalendarPayrollItem";
import PayrollCalendarHolidayHourItem from "../../component-library/payroll-calendars/PayrollCalendarHolidayHourItem";
import PayrollCalendarPayCheckDateItem from "../../component-library/payroll-calendars/PayrollCalendarPayCheckDateItem";

import moment from "moment";

const convertDatetoMonthDay = date => {
  return moment(date).format("MMM D");
};

const QuarterWiseUpcomingPayrollsItems = ({ data }) => {
  let paycheckInfo = "";
  if (
    data.CalculatedPaycheckDate === data.AdjustedPaycheckDate &&
    data.CalculatedPaycheckDate !== data.PaycheckDate
  )
    paycheckInfo = "PayCheck date has been changed from the payroll dashboard";
  else if (
    data.CalculatedPaycheckDate !== data.AdjustedPaycheckDate &&
    data.AdjustedPaycheckDate === data.PaycheckDate
  )
    paycheckInfo =
      "PayCheck date has been changed from the payroll schedule settings";
  else if (
    data.CalculatedPaycheckDate !== data.AdjustedPaycheckDate &&
    data.AdjustedPaycheckDate !== data.PaycheckDate
  )
    paycheckInfo = "PayCheck date has been changed from the payroll dashboard";

  return (
    <div className="payroll-calendar-summary-item-container clearfix">
      <CalendarPayrollItem
        label="submit date"
        lvalue={convertDatetoMonthDay(data.PayrollSubmitDate)}
        className="payroll-calendar-summary-item"
        info="false"
      />

      <PayrollCalendarPayCheckDateItem
        label="paycheck date"
        lvalue={convertDatetoMonthDay(data.PaycheckDate)}
        paycheckInfo={paycheckInfo}
      />

      <PayrollDashboardSummaryItem
        label="pay period"
        lvalue={convertDatetoMonthDay(data.PayPeriodStartDate)}
        rvalue={convertDatetoMonthDay(data.PayPeriodEndDate)}
        groupIcon="long-arrow-right"
        className="payroll-calendar-summary-item pay-period"
        width="25%"
      />
      <PayrollDashboardSummaryItem
        label="monthly run"
        lvalue={
          data.MonthlyPayrollRunNumber > 0 ? data.MonthlyPayrollRunNumber : "--"
        }
        rvalue={data.TotalMonthlyRun}
        customIcon={data.MonthlyPayrollRunNumber > 0 ? "/" : ""}
        className="payroll-calendar-summary-item"
        width="17%"
      />

      <PayrollCalendarHolidayHourItem
        label="holiday hours"
        lvalue={data.HolidayHours}
        HolidayDates={data.HolidayDates}
      />
    </div>
  );
};

export default QuarterWiseUpcomingPayrollsItems;
