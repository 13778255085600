//call actions related to PTO's here
import React from "react";
import { connect } from "react-redux";
import UnapprovedPtoFilters from "../containers/UnapprovedPtoFilters";
import UnapprovedPtosTableContainer from "../containers/UnapprovedPtosTableContainer";
import {
  searchAndSetPostsUnapprovedPto,
  fetchUnapprovedPtoItems,
  loadMoreUnapprovedPtoItems,
  approvePtosAction,
  approveAllPtosAction,
  fetchAndSetEmployees,
  fetchAndSetTypes
} from "../network/actions";
import {
  selectAllFilterOptions,
  makeCriterion,
  makeSortCriterion
} from "app/common/reducers/filters";
import MobileHeader from "component-library/payroll-dashboard/multi-section-table/MobileHeader";
import { UnapprovedPto as UnapprovedIcon } from "component-library/payroll-dashboard/images";

export const getSearchParams = state => {
  const defaultSearchParams = {
    Fields: ["*"],
    Criterion: [],
    Sort: [],
    PageSize: 5
  };
  const filters = state.common.filters;

  let Criterion = [];
  Criterion.push(makeCriterion("Employees", filters.Employees));
  Criterion.push(makeCriterion("LeaveType", filters.LeaveType));

  Criterion = Criterion.filter(c => c);

  defaultSearchParams.Criterion = Criterion;
  const sortOrder = state.common.sortOrder;
  let Sort = null;
  Sort = makeSortCriterion(sortOrder);
  defaultSearchParams.Sort = Sort === null ? [] : Sort;
  return defaultSearchParams;
};

const UnapprovedPto = props => (
  <div className="transition-item">
    <MobileHeader
      title="Unapproved PTO"
      onClick={() => {
        props.history.replace("/payroll");
        props.clearPayrollActiveTab();
      }}
      redirectToChecklist={props.redirectToChecklist}
      icon={<UnapprovedIcon width={25} height={25} />}
      badgeCount={props.payrollTypeData.CountOfUnapprovedPtos}
    />
    <UnapprovedPtoFilters {...props} />
    <UnapprovedPtosTableContainer
      onContactClick={props.onContactClick}
      onContactAllClick={props.onContactAllClick}
    />
  </div>
);

const stateToProps = state => ({
  searchParams: getSearchParams(state),
  payrollTypeData: state.payrollDashboard.payrollTypeData //table
});
const actionCreators = {
  searchAndSetPostsUnapprovedPto,
  fetchUnapprovedPtoItems,
  loadMoreUnapprovedPtoItems,
  approvePtosAction,
  approveAllPtosAction,
  fetchAndSetEmployees,
  fetchAndSetTypes,
  selectAll: selectAllFilterOptions
};

export default connect(stateToProps, actionCreators)(UnapprovedPto);
