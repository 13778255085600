/*Resume Entity Descriptions*/
export const EmploymentHistory =
  "Lorem consectetur adipiscing elit. Sed lobortis sed tortor vel imperdiet. Praesent nec malesuada nulla. Suspendisse id dapibus felis. Aliquam a nisl id quam efficitur condimentum.";
export const Licenses =
  "Lorem ipsum dolor sit amet, Sed lobortis sed tortor vel imperdiet. Praesent nec malesuada nulla. Suspendisse id dapibus felis. Aliquam a nisl id quam efficitur condimentum.";
export const Education =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent nec malesuada nulla. Suspendisse id dapibus felis. Aliquam a nisl id quam efficitur condimentum.";
export const Skills =
  "Lorem ipsum dolor sit amet, Praesent nec malesuada nulla. Suspendisse id dapibus felis. Aliquam a nisl id quam efficitur condimentum.";

/*UserRole*/
export const NET_ADMIN = 1;
export const CLIENT_ADMIN = 2;
export const MANAGER = 3;
export const EMPLOYEE = 4;

/*Resume Entity Id*/
export const EMPLOYMENT_HISTORY_ID = 5;
export const LICENSES_CERTIFICATION_ID = 6;
export const EDUCATION_ID = 7;
export const SKILLS_ID = 8;

/*Resume Filters Fields*/
export const EmploymentHistoryCompanyFilter = "employmentCompany";
export const EmploymentHistoryPositionFilter = "employmentPosition";
export const EmploymentHistoryDateFilter = "employmentDate";
export const LicensesCertificationNameFilter = "licensesName";
export const LicensesCertificationTypeFilter = "licensesType";
export const LicensesCertificationDateFilter = "licensesExpirationDate";
export const EducationDegreeFilter = "educationDegree";
export const EducationSchoolFilter = "educationSchool";
export const EducationGraducationDateFilter = "educationGraducationDate";
export const SkillsSkillFilter = "skillsSkill";
export const SkillsSkillLevelFilter = "skillsSkillLevel";
export const SkillsYearOfExperienceFilter = "skillsYearOfExperience";
export const LicensesAndCertificationNameList = "licensesNames";

/*Employment History Fields*/
export const COMPANY_NAME = "CompanyName";
export const CITY = "City";
export const STATE = "StateId";
export const PHONE_NUMBER = "EmployerCellPhoneNumber";
export const POSITION = "Position";
export const EMPLOYMENT_FORM_MONTH = "EmploymentFromMonth";
export const EMPLOYMENT_FROM_YEAR = "EmploymentFromYear";
export const EMPLOYMENT_TO_MONTH = "EmploymentToMonth";
export const EMPLOYMENT_TO_YEAR = "EmploymentToYear";
export const RESPONSIBILITIES = "Responsibilities";
export const HOURLY_RATE = "HourlyRateOrAnnualSalary";
export const REASON_FOR_LEAVING = "ReasonForLeaving";
export const DIFFERENT_NAME = "DifferentName";
export const PREVIOUS_NAME = "PreviosName";
export const SUPERVISOR_NAME = "SupervisorName";
export const SUPERVISOR_TITLE = "SupervisorTitle";
export const SUPERVISOR_PHONE_NUMBER = "SupervisorCellPhoneNumber";
export const SUPERVISOR_EMAIL_ADDRESS = "SupervisorEmailAddress";
export const IS_VERIFIED = "IsVerified";
export const VERIFICATION_DATE = "VerificationDate";
export const VERIFIED_WITH = "VerifiedWith";
export const VERIFIED_WITH_TITLE = "VerifiedWithTitle";
export const VERIFIED_WITH_PHONE_NUMBER = "VerifiedWithCellPhoneNumber";
export const VERIFIED_WITH_EMAIL = "VerifiedWithEmailAddress";
export const VERIFIED_BY_ID = "VerifiedById";
export const VERIFIED_BY_NAME = "VerifiedByName";
export const VERIFIED_BY_OTHER = "VerifiedByOther";
export const DIFFERENT_NAME_LABEL = "Did you go by a different name?";
export const EMPLOYMENT_DATES_LABEL = "Employment Dates";
export const RESPONSIBILITIES_LABEL = "Responsibilities / Achivements";
export const HOURLY_RATE_LABEL = "Hourly Rate / Annual Salary";
export const HOURLY_RATE_INPUT = "HourlyRateOrAnnualSalary";
export const SALARY_TYPE_ID = "SalaryTypeId";
export const HOURLY_RATE_PER_HOUR = 1;
export const HOURLY_RATE_PER_YEAR = 2;
export const REASON_FOR_LEAVING_LABEL = "Reason for Leaving";
export const PREVIOUS_NAME_LABEL = "Privious Name";
export const SUPERVISOR_PHONE_NUMBER_TYPE = "SupervisorCellPhoneTypeId";
export const SUPERVISOR_PHONE_NUMBER_HOME = 1;
export const SUPERVISOR_PHONE_NUMBER_CELL = 2;
export const SUPERVISOR_PHONE_NUMBER_WORK = 3;
export const SUPERVISOR_PHONE_NUMBER_OTHER = 4;
export const EMPLOYMENT_DATES_DD_LABELS = {
  months: ["Month", "Month", "Month", "Month"],
  year: ["Year", "Year", "Year", "Year"]
};

/*Licenses Certification Fields */
export const BASIC_INFO_TYPE_Id = "TypeId";
export const BASIC_INFO_TYPE_Name = "TypeName";
export const BASIC_INFO_AWARD = 1;
export const BASIC_INFO_CERTIFICATION = 2;
export const BASIC_INFO_LICENSES = 3;
export const BASIC_INFO_MEMBERSHIP = 4;
export const BASIC_INFO_OTHER = 5;
export const BASIC_INFO_NAME = "Name";
export const BASIC_INFO_ID = "Id";
export const BASIC_INFO_NAME_OTHER = "Description";
export const BASIC_INFO_NUMBER_ID = "NumberOrId";
export const BASIC_INFO_EXPIRATION_DATE = "ExpirationDate";
export const ISSUANCE_INFO_ISSUE_BY = "IssuedBy";
export const ISSUANCE_INFO_ISSUED_DATE = "IssuedDate";
export const ISSUANCE_INFO_COUNTRY_ISSUED_IN = "CountryIssuedInId";
export const ISSUANCE_INFO_COUNTRY_ISSUED_NAME = "CountryIssuedInName";
export const ISSUANCE_INFO_STATE_ISSUED_IN = "StateIssuedInId";
export const ISSUANCE_INFO_CITY_ISSUED_IN = "CityIssuedInId";
export const ISSUANCE_INFO_STATE_ISSUED_NAME = "StateIssuedInName";

/*Education Fields*/
export const SCHOOL_INFO_NAME = "Name";
export const SCHOOL_INFO_CITY = "City";
export const SCHOOL_INFO_STATE = "State";
export const SCHOOL_INFO_STATE_ID = "StateId";
export const START_MONTH = "StartMonth";
export const START_YEAR = "StartYear";
export const GRADUATED = "Graduated";
export const CURRENTLY_ENROLLED = "CurrentlyEnrolled";
export const EXPECTED_GRADUATION_MONTH = "ExpectedGraduationMonth";
export const EXPECTED_GRADUATION_YEAR = "ExpectedGraduationYear";
export const END_DATE_MONTH = "EndDateMonth";
export const END_DATE_YEAR = "EndDateYear";
export const GRADUATION_MONTH = "GraduationMonth";
export const GRADUATION_YEAR = "GraduationYear";
export const DEGREE = "Degree";
export const MAJORS = "Majors";
export const MINORS = "Minors";
export const CURRENT_GPA = "CurrentGpa";
export const CURRENT_SCALE = "CurrentScale";
export const HONORS_AWARDS_ACHIVEMENTS = "HonorsAwardsAchivements";
export const RELEVANT_COURSEWORK = "RelevantCoursework";

/*Skills Fields*/
export const SKILL_NAME = "Skill";
export const SKILL_NAME_OTHER = "Description";
export const SKILL_ID = "SkillId";
export const SKILL_LEVEL = "SkillLevel";
export const SKILL_LEVEL_ID = "SkillLevelId";
export const YEARS_EXPERIENCE = "YearsExperience";
export const NOTES = "Notes";
export const NEW_SKILL_NAME = "SkillName";
