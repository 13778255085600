/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DeductionSetupDescription from "./DeductionSetupDescription";
import { getEmployeeAmountValueList } from "../../actions/systemlevel/SystemLevelDefaultValueActions";
import { setSystemDeductionData } from "../../actions/systemlevel/DeductionSetupListingActions";
import { Modal } from "react-bootstrap";
import SystemLevelDeductionDescriptionStep from "./SystemLevelDeductionDescriptionStep";
import { withRouter, Prompt } from "react-router-dom";
import Toaster from "../../../../app/common/UIComponents/Toaster";
import * as message from "../../../../app/common/messages";
import { showToaster } from "../../../../app/actions/AppActions";
import "../../../../content/entitypage.css";
import "./DeductionStyles.css";
import * as validator from "../../../../app/common/validations";
import * as common from "../../../../app/common/index";
import * as utilities from "../../../../app/common/utilities";
import RadioButton from "component-library/radioButton/BasicRadioButton";
import axios from "axios";
import { setLoader } from "../../../../app/actions/LoaderActions";
import {
  setGlobalNavClick,
  CallbackToQuickLink,
  setFormUpdate,
  isOverlayClickable
} from "../../../../app/actions/FormUpdateAction";

const SAVE_AND_CONTINUE = 1;
const SAVE_AND_FINISH_LATER = 2;
const SAVE_TO_SUMMERY_PAGE = 3;
const SAVE_AND_BACK_PAGE = 4;
const SAVE_CHANGES = 5;
const TRACK_YOUR_PROGRESS = 6;
const GLOBAL_NAV = 7;

var employeesYtdAmountList = [
  {
    id: "emp-Ytd-Amount-yes-radio",
    name: "SetEmployeeMaximumToBalanceOnRollover",
    value: true,
    label: "Yes"
  },
  {
    id: "emp-Ytd-Amount-no-radio",
    name: "SetEmployeeMaximumToBalanceOnRollover",
    value: false,
    label: "No"
  }
];

class DefaultValuePage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showBackCancelModal: false,
      SetEmployeeMaximumToBalanceOnRollover: this.props.systemDeductionData
        .SetEmployeeMaximumToBalanceOnRollover,

      openBackButtonModal: false,
      showDeleteButtonModal: false,
      closeLeavingModal: false,
      openTrackYourProgressModal: false,
      openTrackYourProgressModal2: false,
      showFinishLaterModalWithoutMandatoryField: false,
      showFinishLaterModalWithMandatoryField: false,

      isFormUpdated: false,
      openDeductionBalanceAmountTooltip: false,
      SystemDeductionId: this.props.systemDeductionData.SystemDeductionId,
      pageId: 4,
      errors: {},
      showToaster: false,
      messageToaster: "",
      modalNavLink: ""
    };

    this.onChangeEmployeeYtdAmount = this.onChangeEmployeeYtdAmount.bind(this);
    this.renderEmployeeYtdListItem = this.renderEmployeeYtdListItem.bind(this);
    this.onOpenBackButtonModal = this.onOpenBackButtonModal.bind(this);
    this.onCloseBackButtonModal = this.onCloseBackButtonModal.bind(this);
    this.close = this.close.bind(this);
    this.onOpenDeleteButtonModal = this.onOpenDeleteButtonModal.bind(this);
    this.onCloseDeleteButtonModal = this.onCloseDeleteButtonModal.bind(this);
    //this.showBackButtonMessage = this.showBackButtonMessage.bind(this);
    this.leavingCancelModal = this.leavingCancelModal.bind(this);
    this.showDeductionBalanceAmountInfo = this.showDeductionBalanceAmountInfo.bind(
      this
    );
    this.redirectToSystemLevelDeductionCycle = this.redirectToSystemLevelDeductionCycle.bind(
      this
    );
    this.redirectToSummarygPage = this.redirectToSummarygPage.bind(this);
    this.deleteDeduction = this.deleteDeduction.bind(this);
    this.deleteDeductionForGlobalNav = this.deleteDeductionForGlobalNav.bind(
      this
    );
    this.saveAndContinueDefaultValue = this.saveAndContinueDefaultValue.bind(
      this
    );
    this.onOpenTrackYourProgressModal = this.onOpenTrackYourProgressModal.bind(
      this
    );
    this.onCloseTrackYourProgressModal = this.onCloseTrackYourProgressModal.bind(
      this
    );
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.props.getEmployeeAmountValueList();
    this.getDataById();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.globalNavClick === null &&
      this.props.globalNavClick !== null
    ) {
      if (this.props.globalNavClick.isLinkClick) {
        this.setState({
          showBackCancelModal: true,
          IsFormUpdated: false
        });
        this.props.setFormUpdate(false);
      }
    } else {
      if (
        prevProps.globalNavClick !== null &&
        this.props.globalNavClick !== null
      ) {
        if (
          prevProps.globalNavClick.isLinkClick !==
          this.props.globalNavClick.isLinkClick
        ) {
          if (this.props.globalNavClick.isLinkClick) {
            this.setState({
              showBackCancelModal: true,
              IsFormUpdated: false
            });
            this.props.setFormUpdate(false);
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.setFormUpdate(false);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.systemDeductionData !== this.props.systemDeductionData) {
      this.setState({
        SetEmployeeMaximumToBalanceOnRollover:
          nextProps.systemDeductionData.SetEmployeeMaximumToBalanceOnRollover,
        SystemDeductionId: nextProps.systemDeductionData.SystemDeductionId
      });
    }
  }

  redirectToSystemLevelDeductionOptions() {
    this.props.history.push("/system-level-deduction-options");
  }

  redirectToSystemLevelDeductionSetupListingPage() {
    this.props.history.push("/system-level-deduction-setup-listing-page");
  }

  getDataById() {
    let self = this;
    let Id = this.props.match.params.SystemDeductionId;
    if (Id !== undefined) {
      // Id = window.atob(Id);
      const url = `${common.WEB_API_URL}/v1/system/deduction/${Id}`;
      axios
        .get(url)
        .then(function(response) {
          if (response.data.ErrorCount > 0) {
            response.data.Errors.forEach(item => {
              if (item.Tag === "") {
              }
            });
          } else {
            let deductionData = response.data.Data[0];
            self.props.setSystemDeductionData(deductionData);
          }
        })
        .catch(function(error) {});
    }
  }

  redirectToSystemLevelDeductionCycle() {
    let self = this;
    let redirectURL = `/system-level-deduction-cycle/${
      this.props.match.params.SystemDeductionId
    }`;

    self.setState({ isFormUpdated: false });
    this.props.setFormUpdate(false);
    const dataUrl = `${common.WEB_API_URL}/v1/system/deduction/${
      self.state.SystemDeductionId
    }`;
    axios
      .get(dataUrl)
      .then(function(response) {
        if (response.data.ErrorCount > 0) {
          self.setState({ isFormUpdated: true });
          this.props.setFormUpdate(true);
          response.data.Errors.forEach((item, index) => {
            if (index === 0) {
              alert(item.Messages);
            }
          });
        } else {
          let deductionData = response.data.Data[0];
          self.props.setSystemDeductionData(deductionData);
          self.props.history.push(redirectURL);
        }
      })
      .catch(function(error) {});
  }

  redirectToSummarygPage() {
    this.setState({ isFormUpdated: false });
    this.props.setFormUpdate(false);
    this.props.showToaster(false);
    setTimeout(
      function() {
        this.props.history.push(
          `/system-level-review-and-save/${
            this.props.match.params.SystemDeductionId
          }`
        );
      }.bind(this),
      100
    );
  }

  saveAndContinueDefaultValue(actionType, navLink) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let redirectPageUrl = "";
    let data = this.state;

    this.setState({
      isFormUpdated: false,
      showFinishLaterModalWithMandatoryField: false,
      openTrackYourProgressModal2: false
    });
    this.props.setFormUpdate(false);

    if (actionType === SAVE_AND_CONTINUE) {
      redirectPageUrl = `/system-level-deduction-options/${
        this.props.match.params.SystemDeductionId
      }`;
    } else if (actionType === SAVE_CHANGES) {
      redirectPageUrl = "/system-level-deduction-setup-listing-page";
      this.props.showToaster(
        true,
        `'${
          this.props.systemDeductionData.SystemDeductionName
        }' has been saved.We’ve marked this deduction as in progress for you to work on later.`,
        "inprogress"
      );
    } else if (actionType === SAVE_AND_FINISH_LATER) {
      redirectPageUrl = "/system-level-deduction-setup-listing-page";
      this.props.showToaster(
        true,
        `'${
          this.props.systemDeductionData.SystemDeductionName
        }' has been saved. We’ve marked this deduction as in progress for you to work on later.`,
        "inprogress"
      );
    } else if (actionType === SAVE_TO_SUMMERY_PAGE) {
      redirectPageUrl = `/system-level-review-and-save/${
        this.props.match.params.SystemDeductionId
      }`;
      this.props.showToaster(
        true,
        `'${
          this.props.systemDeductionData.SystemDeductionName
        }' has been updated.`,
        "success"
      );
    } else if (actionType === SAVE_AND_BACK_PAGE) {
      redirectPageUrl = `/system-level-deduction-cycle/${
        this.props.match.params.SystemDeductionId
      }`;
    } else if (actionType === TRACK_YOUR_PROGRESS) {
      redirectPageUrl = navLink;
    }

    let errors = {};
    data.UiDraftCompletionStage = data.pageId;

    if (
      data.SetEmployeeMaximumToBalanceOnRollover === null ||
      data.SetEmployeeMaximumToBalanceOnRollover === undefined
    ) {
      errors = validator.validateRequiredField(
        data.SetEmployeeMaximumToBalanceOnRollover,
        "SetEmployeeMaximumToBalanceOnRollover",
        errors
      );
    }
    // if (data.SetEmployeeMaximumToBalanceOnRollover === true) {
    //   errors = validator.validateRequiredField(
    //     data.SetEmployeeMaximumToBalanceOnRollover,
    //     "SetEmployeeMaximumToBalanceOnRollover",
    //     errors
    //   );
    // }

    this.setState({
      errors: errors,
      openBackButtonModal: false,
      showDeleteButtonModal: false,
      closeLeavingModal: false,
      openTrackYourProgressModal: false,
      openTrackYourProgressModal2: false
    });

    if (Object.keys(errors).length !== 0) {
      this.setState({
        showToaster: true,
        messageToaster: message.ERROR_ON_PAGE,
        isFormUpdated: true
      });
      this.setState({
        IsFormUpdated: true,
        showBackCancelModal: false
      });
      this.props.setFormUpdate(true);
      this.props.setGlobalNavClick(false);
    } else {
      if (actionType === GLOBAL_NAV) {
        this.setState({
          isFormUpdated: false,
          showBackCancelModal: false
        });
        this.props.setFormUpdate(false);
        this.props.setGlobalNavClick(
          false,
          this.props.globalNavClick.LinkItem,
          this.props.globalNavClick.MenuLink
        );
        //this.props.CallbackToQuickLink(true);
        this.setState({
          showBackCancelModal: false
        });
        this.props.isOverlayClickable(false);
        redirectPageUrl = "";
      }
    }

    if (Object.keys(errors).length === 0) {
      this.props.setLoader(true);
      this.setState({
        isFormUpdated: false
      });
      let UIDraftPageId = 0;

      if (
        this.props.systemDeductionData.UiDraftCompletionStage >
        this.state.pageId
      ) {
        UIDraftPageId = this.props.systemDeductionData.UiDraftCompletionStage;
      } else {
        UIDraftPageId = this.state.pageId;
      }

      var self = this;
      let patchActions = [
        {
          Operation: "update",
          PathOrKey: "SetEmployeeMaximumToBalanceOnRollover",
          Value: data.SetEmployeeMaximumToBalanceOnRollover
        },
        {
          Operation: "update",
          PathOrKey: "UiDraftCompletionStage",
          Value: UIDraftPageId
        }
      ];

      const url = `${common.WEB_API_URL}/v1/system/deduction/${
        data.SystemDeductionId
      }`;
      axios
        .patch(url, patchActions)
        .then(function(response) {
          self.props.setLoader(false);
          const dataUrl = `${common.WEB_API_URL}/v1/system/deduction/${
            data.SystemDeductionId
          }`;
          axios
            .get(dataUrl)
            .then(function(response) {
              if (response.data.ErrorCount > 0) {
                response.data.Errors.forEach((item, index) => {
                  if (index === 0) {
                    alert(item.Messages);
                  }
                });
              } else {
                let deductionData = response.data.Data[0];
                self.props.setSystemDeductionData(deductionData);
                if (actionType === GLOBAL_NAV) {
                  self.props.setFormUpdate(false);
                  self.props.CallbackToQuickLink(true);
                } else {
                  self.props.history.push(redirectPageUrl);
                }
              }
            })
            .catch(function(error) {});
        })
        .catch(function(error) {});
    }
  }

  deleteDeduction() {
    var self = this;
    this.setState({
      isFormUpdated: false,
      showDeleteButtonModal: false
    });
    this.props.setFormUpdate(false);
    self.props.setLoader(true);
    const url = `${common.WEB_API_URL}/v1/system/deduction/${
      this.state.SystemDeductionId
    }`;
    axios
      .delete(url)
      .then(function(response) {
        self.props.setLoader(false);
        if (response.data.ErrorCount > 0) {
          self.setState({
            isFormUpdated: true
          });
          this.props.setFormUpdate(true);
          response.data.Errors.forEach((item, index) => {
            if (index === 0) {
              alert(item.Messages);
            }
          });
        } else {
          self.props.showToaster(
            true,
            `'${
              self.props.systemDeductionData.SystemDeductionName
            }' has been deleted.`,
            "delete"
          );
          self.props.history.push("/system-level-deduction-setup-listing-page");
        }
      })
      .catch(function(error) {});
  }

  deleteDeductionForGlobalNav() {
    var self = this;
    this.setState({
      isFormUpdated: false,
      showDeleteButtonModal: false
    });
    this.props.setFormUpdate(false);
    self.props.setLoader(true);
    const url = `${common.WEB_API_URL}/v1/system/deduction/${
      this.state.SystemDeductionId
    }`;
    axios
      .delete(url)
      .then(function(response) {
        self.props.setLoader(false);
        if (response.data.ErrorCount > 0) {
          self.setState({
            isFormUpdated: true
          });
          this.props.setFormUpdate(true);
          response.data.Errors.forEach((item, index) => {
            if (index === 0) {
              alert(item.Messages);
            }
          });
        } else {
          self.props.setFormUpdate(false);
          self.props.setGlobalNavClick(
            false,
            self.props.globalNavClick.LinkItem,
            self.props.globalNavClick.MenuLink
          );
          self.props.CallbackToQuickLink(true);
          self.props.isOverlayClickable(false);
        }
      })
      .catch(function(error) {});
  }

  onOpenFinishLaterButtonModal = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let errorCount = this.validateRequiredField();
    if (errorCount === 0) {
      this.setState({
        showFinishLaterModalWithMandatoryField: true,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
    } else {
      this.setState({
        showFinishLaterModalWithoutMandatoryField: true,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
    }
  };

  onCloseFinishLaterModal = () => {
    this.setState({
      isFormUpdated: true,
      showFinishLaterModalWithoutMandatoryField: false,
      showFinishLaterModalWithMandatoryField: false
    });
    this.props.setFormUpdate(true);
    this.props.setGlobalNavClick(false);
  };

  exitSetup = (actionType, navLink) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (actionType === "EXIT_SETUP") {
      this.setState({
        showFinishLaterModalWithoutMandatoryField: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.history.push("/system-level-deduction-setup-listing-page");
    } else if (actionType === "LEAVE_THIS_PAGE") {
      this.setState({
        openTrackYourProgressModal: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.history.push(navLink);
    } else if (actionType === "GLOBAL_NAV") {
      this.setState({
        showBackCancelModal: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.setGlobalNavClick(
        false,
        this.props.globalNavClick.LinkItem,
        this.props.globalNavClick.MenuLink
      );
      this.props.CallbackToQuickLink(true);
    } else if (actionType === SAVE_AND_BACK_PAGE) {
      this.setState({
        openBackButtonModal2: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.history.push(
        `/system-level-deduction-cycle/${
          this.props.match.params.SystemDeductionId
        }`
      );
    }
  };

  validateRequiredField = () => {
    let errors = {};
    let data = this.state;
    if (
      data.SetEmployeeMaximumToBalanceOnRollover === null ||
      data.SetEmployeeMaximumToBalanceOnRollover === undefined
    ) {
      errors = validator.validateRequiredField(
        data.SetEmployeeMaximumToBalanceOnRollover,
        "SetEmployeeMaximumToBalanceOnRollover",
        errors
      );
    }
    return Object.keys(errors).length;
  };

  onChangeEmployeeYtdAmount(event, value = 0) {
    let update = {};
    let errors = this.state.errors;
    delete errors["SetEmployeeMaximumToBalanceOnRollover"];
    this.setState({
      errors,
      isFormUpdated: true
    });
    this.props.setFormUpdate(true);

    let selectedButton = event.value === "true";

    // this.setState({ isFormUpdated: true });

    // if (selectedButton) {
    //   this.setState({
    //     isFormUpdated: true
    //   });
    // } else {
    //   this.setState({
    //     isFormUpdated: true
    //   });
    // }

    update[event.name] = selectedButton;
    this.setState(update);
  }

  renderEmployeeYtdListItem(list) {
    return list.map(item => {
      return (
        <div
          key={item.id}
          id="radio-button-space"
          className={`single-radio-button`}
        >
          <RadioButton
            id={item.id}
            name={item.name}
            value={item.value}
            type="title"
            label={item.label}
            checked={
              this.state.SetEmployeeMaximumToBalanceOnRollover === item.value
            }
            onChange={this.onChangeEmployeeYtdAmount}
          />
        </div>
      );
    });
    // }
  }

  onOpenBackButtonModal() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let errorCount = this.validateRequiredField();
    if (errorCount === 0) {
      this.setState({ openBackButtonModal: true, isFormUpdated: false });
    } else {
      this.setState({ openBackButtonModal2: true, isFormUpdated: false });
    }
    this.props.setFormUpdate(false);
  }

  onCloseBackButtonModal() {
    this.setState({
      openBackButtonModal: false,
      openBackButtonModal2: false,
      isFormUpdated: true
    });
    this.props.setFormUpdate(true);
  }

  close() {
    this.setState({
      openBackButtonModal: false,
      showDeleteButtonModal: false,
      showCancelModal: false,
      closeLeavingModal: false,
      isFormUpdated: true,
      showBackCancelModal: false,
      openTrackYourProgressModal: false
    });
    this.props.setFormUpdate(true);
    this.props.setGlobalNavClick(false);
  }

  onOpenTrackYourProgressModal = navLink => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({
      openTrackYourProgressModal: true,
      modalNavLink: navLink,
      isFormUpdated: false
    });
  };

  onOpenTrackYourProgressModal2 = navLink => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({
      openTrackYourProgressModal2: true,
      modalNavLink: navLink,
      isFormUpdated: false
    });
  };

  onCloseTrackYourProgressModal() {
    this.setState({ openTrackYourProgressModal: false });
    this.setState({
      openTrackYourProgressModal: false,
      openTrackYourProgressModal2: false,
      isFormUpdated: true
    });
  }

  onOpenDeleteButtonModal() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({ showDeleteButtonModal: true });
  }

  onCloseDeleteButtonModal() {
    this.setState({ showDeleteButtonModal: false });
  }

  leavingCancelModal() {
    this.setState({ closeLeavingModal: false });
  }

  showDeductionBalanceAmountInfo() {
    return (
      <div className="open-deduction-based-popover-for-information-extra-small-type col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a
          id="deduction-tooltip-info-tooltip-icon"
          className="open-deduction-based-popover-button-close"
          onClick={() =>
            this.setState({ openDeductionBalanceAmountTooltip: false })
          }
        >
          <i className="fa fa-times-circle fa-lg" />
        </a>
        <div
          id="deduction-balance-amount-tooltip-popover-data"
          className="deduction-popover-information-data col-xs-12 col-sm-12 col-md-12 col-lg-12"
        >
          <p>
            For example, John has a loan deduction with a maximum amount of
            $1,000. His remaining balance at the end of the year is $350 (i.e.
            he has had $650 deducted year-to-date).
          </p>
          <p>
            Yes: If we make the remaining balance become the new maximum amount,
            when rolling over to a new calendar year, his new year-to-date
            amount will become $0 and his new remaining balance $350 (i.e.
            maximum amount of $350).
          </p>
          <p>
            No: If we don't make the remaining balance become the new maximum
            amount, when rolling over to a new calendar year, his new
            year-to-date amount will become $0 and his new remaining balance
            $1,000 (i.e. maximum amount of $1,000).
          </p>
        </div>
      </div>
    );
  }

  onClickTrackYourProgress(navLink) {
    let errorCount = this.validateRequiredField();
    if (this.state.isFormUpdated) {
      if (errorCount === 0) {
        this.onOpenTrackYourProgressModal2(navLink);
      } else {
        this.onOpenTrackYourProgressModal(navLink);
      }
    } else {
      this.props.history.push(navLink);
    }
  }

  closeToasterMessage = () => {
    this.setState({ showToaster: false });
    this.props.showToaster(false, "");
  };

  render() {
    let isReviewSaveEditPage = false;
    if (this.props.systemDeductionData.UiDraftCompletionStage === 6) {
      isReviewSaveEditPage = true;
    }

    return (
      <div className="nc-entity-page-container">
        <Prompt
          when={this.state.isFormUpdated}
          message={message.UNSAVED_CHANGES}
        />
        <DeductionSetupDescription />
        <div id="toaster-message" className="toaster-container">
          {this.state.showToaster ? (
            <Toaster
              show={this.state.showToaster}
              message={this.state.messageToaster}
              closeToasterMessage={this.closeToasterMessage}
              alertClassName="danger"
            />
          ) : null}
        </div>
        <div className="clearfix" />

        <div
          id="deduction-description-page-title1"
          className="title-band-container"
        >
          <div className="title-band">Deduction Setup</div>
        </div>

        {/* Added for the mobile view */}
        <div
          id="deduction-track-progress"
          className="progress-tracker-container-mobile hidden-lg hidden-md"
        >
          <SystemLevelDeductionDescriptionStep
            pageId={this.state.pageId}
            onClickTrackYourProgress={(navLink, id) =>
              id !== 4 ? this.onClickTrackYourProgress(navLink) : null
            }
          />
        </div>

        <div
          id="deduction-description-page-title2"
          className="entity-page-content col-lg-9 col-md-8"
        >
          <div className="step-title-container clearfix">
            <span className="step-number badge badge-default">4</span>
            <div id="system-level-default-value-page" className="step-title">
              Default Values
            </div>
          </div>
          <div className="description-content">
            <div
              className="entity-form-section-primary"
              style={{ marginBottom: "20px" }}
            >
              <div className="content">
                <div id="deduction-default-value-page-description">
                  Default amounts will auto-populate when a deduction is added
                  to an employee, but can be overridden.
                </div>
              </div>
            </div>

            <div className="seperator">
              <hr />
            </div>

            <div id="" className="entity-form-section-secondary">
              <div className="title">Employee Amounts</div>
              <div className="content">
                <div className="nc-field-group clearfix">
                  <div
                    id="deduction-default-value-page-question"
                    className="field-name"
                  >
                    <span
                      className={utilities.getErrorClassName(
                        this.state.errors[
                          "SetEmployeeMaximumToBalanceOnRollover"
                        ]
                      )}
                    >
                      When rolling over to a new calendar year, should an
                      employee's remaining balance become the new maximum
                      amount?{" "}
                      <a
                        id="default-value-max-amount-tooltip-info-icon"
                        onClick={() =>
                          this.setState({
                            openDeductionBalanceAmountTooltip: !this.state
                              .openDeductionBalanceAmountTooltip
                          })
                        }
                      >
                        <i
                          id="default-value-max-amount-tooltip-icon"
                          className="fa-information-blue fa fa-info-circle"
                        />
                      </a>
                    </span>
                  </div>

                  <div className="clearfix" />
                  {this.state.openDeductionBalanceAmountTooltip
                    ? this.showDeductionBalanceAmountInfo()
                    : null}
                  <div className="clearfix" />

                  <div
                    className="radio-button-group"
                    style={{ marginBottom: "10px" }}
                  >
                    {this.renderEmployeeYtdListItem(employeesYtdAmountList)}
                  </div>
                  {this.state.errors[
                    "SetEmployeeMaximumToBalanceOnRollover"
                  ] && (
                    <p
                      className="error-label"
                      style={{
                        marginTop: "-15px",
                        marginBottom: "20px"
                      }}
                    >
                      {
                        this.state.errors[
                          "SetEmployeeMaximumToBalanceOnRollover"
                        ]
                      }
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="clearfix" />
          </div>
        </div>
        <div
          id="deduction-track-progress"
          className="col-lg-3 col-md-4 hidden-sm hidden-xs"
          style={{ marginBottom: "20px" }}
        >
          <SystemLevelDeductionDescriptionStep
            pageId={this.state.pageId}
            onClickTrackYourProgress={(navLink, id) =>
              id !== 4 ? this.onClickTrackYourProgress(navLink) : null
            }
          />
        </div>
        <div className="footer-button-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="seperator">
            <hr />
          </div>

          {isReviewSaveEditPage ? (
            <div className="nc-button-group">
              <button
                type="button"
                id="value-page-save-changes-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDefaultValue.bind(
                  this,
                  SAVE_TO_SUMMERY_PAGE,
                  ""
                )}
              >
                SAVE CHANGES
              </button>

              <div className="nc-link-group-mobile-single-button">
                <button
                  type="button"
                  id="description-page-revert-back-button"
                  className="secondary-link-button"
                  onClick={this.redirectToSummarygPage}
                >
                  Revert back to last saved
                </button>
              </div>
            </div>
          ) : (
            <div className="nc-button-group">
              <button
                type="button"
                id="default-value-back-button"
                className="nc-button outline-button hidden-xs"
                onClick={
                  !this.state.isFormUpdated
                    ? this.redirectToSystemLevelDeductionCycle
                    : this.onOpenBackButtonModal
                }
              >
                <i className="fa fa-chevron-left"> </i> BACK
              </button>
              <button
                type="button"
                id="deduction-save-and-continue-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDefaultValue.bind(
                  this,
                  SAVE_AND_CONTINUE,
                  ""
                )}
              >
                CONTINUE
              </button>
              <button
                type="button"
                id="default-value-back-button-mobile"
                className="nc-button outline-button visible-xs"
                onClick={
                  !this.state.isFormUpdated
                    ? this.redirectToSystemLevelDeductionCycle
                    : this.onOpenBackButtonModal
                }
              >
                <i className="fa fa-chevron-left"> </i> BACK
              </button>
              <div className="nc-link-group-mobile">
                <button
                  type="button"
                  id="deduction-save-and-finish-later-buttonlink"
                  className="primary-link-button"
                  onClick={this.onOpenFinishLaterButtonModal.bind(this)}
                >
                  Finish Later
                </button>

                <button
                  type="button"
                  id="deduction-cancel-buttonlink"
                  className="secondary-link-button"
                  onClick={this.onOpenDeleteButtonModal}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="clearfix" />

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          id="track-progress-modal"
          show={this.state.openTrackYourProgressModal}
          onHide={this.onCloseTrackYourProgressModal}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="track-progress-modal-close-icon"
                onClick={this.onCloseTrackYourProgressModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="track-progress-modal-line1">
                Are you sure you want to leave this page in the middle of making
                edits?
              </div>
              <br />
              <div id="track-progress-modal-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="track-progress-modal-save-button"
                className="nc-button secondary-button"
                onClick={this.exitSetup.bind(
                  this,
                  "LEAVE_THIS_PAGE",
                  this.state.modalNavLink
                )}
              >
                LEAVE THIS PAGE
              </button>

              <button
                type="button"
                id="continue-editing-leaving-modal-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseTrackYourProgressModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          id="track-progress-modal-2"
          show={this.state.openTrackYourProgressModal2}
          onHide={this.onCloseTrackYourProgressModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="track-progress-modal-2-close-icon"
                onClick={this.onCloseTrackYourProgressModal.bind(this)}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="track-progress-modal-2-line1">
                Do you want to save your changes before leaving this page?
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="track-progress-modal-2-save-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDefaultValue.bind(
                  this,
                  TRACK_YOUR_PROGRESS,
                  this.state.modalNavLink
                )}
              >
                SAVE AND GO
              </button>

              <button
                type="button"
                id="track-progress-modal-2-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseTrackYourProgressModal.bind(this)}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="finish-later-modal-1"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showFinishLaterModalWithoutMandatoryField}
          onHide={this.onCloseFinishLaterModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="finish-later-modal-1-close-icon"
                onClick={this.onCloseFinishLaterModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="finish-later-modal-1-line1">
                Are you sure you want to exit setup in the middle of making
                edits?
              </div>
              <br />
              <div id="finish-later-modal-1-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="finish-later-modal-1-exit-button"
                className="nc-button secondary-button"
                onClick={this.exitSetup.bind(this, "EXIT_SETUP", "")}
              >
                EXIT SETUP
              </button>

              <button
                type="button"
                id="finish-later-modal-1-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseFinishLaterModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="finish-later-modal-2"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showFinishLaterModalWithMandatoryField}
          onHide={this.onCloseFinishLaterModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="finish-later-modal-2-close-icon"
                onClick={this.onCloseFinishLaterModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="finish-later-modal-2-line1">
                Do you want to save your changes before exiting setup?
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="finish-later-modal-1-exit-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDefaultValue.bind(
                  this,
                  SAVE_AND_FINISH_LATER,
                  ""
                )}
              >
                SAVE AND EXIT
              </button>

              <button
                type="button"
                id="finish-later-modal-1-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseFinishLaterModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="delete-deduction-modal"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showDeleteButtonModal}
          onHide={this.onCloseDeleteButtonModal}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="delete-deduction-modal-close-icon"
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="delete-deduction-modal-line1">
                Are you sure you want to delete this deduction?
              </div>
              <br />
              <div id="delete-deduction-modal-line2">
                It will be gone for now, but you can add it back if you need it.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="delete-deduction-modal-delete-button"
                className="nc-button secondary-button"
                onClick={this.deleteDeduction}
              >
                DELETE DEDUCTION
              </button>

              <button
                type="button"
                id="delete-deduction-modal-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.close}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          id="back-button-modal"
          show={this.state.openBackButtonModal2}
          onHide={this.onCloseBackButtonModal}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="back-button-modal-close-icon"
                onClick={this.onCloseBackButtonModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="back-button-modal-line1">
                Are you sure you want to leave this page in the middle of making
                edits?
              </div>
              <br />
              <div id="back-button-modal-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="back-button-modal-save-button"
                className="nc-button secondary-button"
                onClick={this.exitSetup.bind(this, SAVE_AND_BACK_PAGE, "")}
              >
                LEAVE THIS PAGE
              </button>

              <button
                type="button"
                id="back-button-modal-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseBackButtonModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          id="back-button-modal-2"
          show={this.state.openBackButtonModal}
          onHide={this.onCloseBackButtonModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="back-button-modal-2-close-icon"
                onClick={this.onCloseBackButtonModal.bind(this)}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="back-button-modal-2-line1">
                Do you want to save your changes before leaving this page?
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="back-button-modal-2-save-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDefaultValue.bind(
                  this,
                  SAVE_AND_BACK_PAGE,
                  ""
                )}
              >
                SAVE AND GO
              </button>

              <button
                type="button"
                id="track-progress-modal-2-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseBackButtonModal.bind(this)}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showBackCancelModal}
          onHide={() => {
            this.setState({
              showBackCancelModal: !this.state.showBackCancelModal
            });
          }}
          id="deduction-unsaved-modal"
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="deduction-unsaved-modal-close-icon"
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="track-progress-modal-line1">
                Are you sure you want to leave this page in the middle of making
                edits?
              </div>
              <br />
              <div id="track-progress-modal-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="deduction-unsaved-modal-save-button"
                className="nc-button secondary-button"
                onClick={this.exitSetup.bind(this, "GLOBAL_NAV", "")}
              >
                LEAVE THIS PAGE
              </button>

              <button
                type="button"
                id="deduction-unsaved-modal-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.close}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    defaultValueEmployeeAmountList: state.defaultValueEmployeeAmountList,
    systemDeductionData: state.systemDeductionData,
    globalNavClick: state.globalNavClick
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEmployeeAmountValueList: getEmployeeAmountValueList,
      showToaster: showToaster,
      setSystemDeductionData: setSystemDeductionData,
      setLoader: setLoader,
      setFormUpdate: setFormUpdate,
      isOverlayClickable: isOverlayClickable,
      setGlobalNavClick: setGlobalNavClick,
      CallbackToQuickLink: CallbackToQuickLink
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DefaultValuePage)
);
