/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import SingleRoundedTab from "./Tab";

import "./styles.css";
const badgeStyle = {
  textAlign: "right",
  position: "relative",
  top: "10px",
  left: "8px"
};
export const TAB_PADDING = 45;
export const TAB_PADDING_XS = 18;

class RoundedTabsSection extends Component {
  state = {
    needToScroll: false,
    disableRightScroll: false,
    disableLeftScroll: true,
    scrollPosition: 0,
    scrollCount: 1,
    opacity: {},
    tabPositions: {},
    scrollTolerance: 45,
    totalTabs: 6,
    tabsToAccomodate: 6,
    firstTabInFrame: 1,
    lastTabInFrame: 6
  };

  componentDidMount() {
    //window.addEventListener("resize", this.updateDimensions);
    this.setState({ screenWidth: window.innerWidth });
    window.addEventListener("resize", this.resizeListener);
    this.calculateMinimumTabWidth(() => this.calculateTabPositions());
  }
  resizeListener = () => {
    if (this.state.screenWidth !== window.innerWidth) {
      this.calculateTabPositions();
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }
  calculateMinimumTabWidth = callback => {
    //216+45 = 216
    //216+18 = 236
    let tabWidth = 216;

    if (window.innerWidth < 992) {
      tabWidth = 216;
    }
    this.setState({ minTabWidth: tabWidth }, callback);
  };
  calculateTabPositions = () => {
    const { minTabWidth } = this.state;
    const screenWidth = window.innerWidth;
    var scrollPosition = 0;
    let totalWidth = 0;
    let tabWidth = minTabWidth;
    let tabsToAccomodate = 6;
    const { tabItemsList = [], data } = this.props;
    const section = document.getElementById(
      "rounded-tab-section-" + data.PayrollId
    );
    const sectionWidth = section.offsetWidth;
    totalWidth = (minTabWidth + TAB_PADDING) * tabItemsList.length;
    const needToScroll = totalWidth > sectionWidth ? true : false;

    if (window.innerWidth > 991) {
      tabsToAccomodate = Math.floor(sectionWidth / (minTabWidth + TAB_PADDING));
      tabWidth =
        (sectionWidth - TAB_PADDING * tabsToAccomodate) / tabsToAccomodate;
      totalWidth = (tabWidth + TAB_PADDING) * tabItemsList.length;
    } else {
      //   scrollPosition =
      //     this.state.scrollPosition / this.state.sectionWidth * screenWidth;
      totalWidth = (tabWidth + TAB_PADDING_XS) * tabItemsList.length;
      tabsToAccomodate = 6;
      totalWidth -= TAB_PADDING_XS;
    }
    const scrollEndPoint = -(totalWidth - sectionWidth + 20);
    this.setState(
      {
        totalWidth,
        tabWidth: minTabWidth,
        needToScroll,
        tabsToAccomodate: 2,
        firstTabInFrame: 1,
        lastTabInFrame: tabsToAccomodate,
        sectionWidth,
        scrollPosition,
        screenWidth,
        scrollEndPoint
      },
      () => {
        this.updateOpacity();
        this.scrollToActive();
        this.toggleScrollButtons();
      }
    );
  };
  toggleScrollButtons = () => {
    let disableRightScroll = false;
    let disableLeftScroll = false;
    const { scrollPosition, scrollEndPoint } = this.state;
    if (scrollPosition >= 0) {
      disableLeftScroll = true;
    }
    if (scrollPosition < scrollEndPoint) {
      disableRightScroll = true;
    }
    this.setState({ disableRightScroll, disableLeftScroll });
  };

  getscrollFactor = () => {
    const { totalTabs, tabsToAccomodate } = this.state;
    var scrollFactor = 1;
    if (totalTabs > tabsToAccomodate) {
      const tabsOutOfFrame = totalTabs - tabsToAccomodate;
      if (tabsOutOfFrame >= tabsToAccomodate) {
        scrollFactor = tabsToAccomodate;
      } else {
        scrollFactor = tabsOutOfFrame;
      }
    }
    return scrollFactor;
  };

  scrollEquivalent(c, f) {
    return c * f;
  }
  scrolleft = () => {
    const {
      scrollPosition,
      tabWidth,
      scrollTolerance,
      tabsToAccomodate,
      firstTabInFrame,
      scrollCount
    } = this.state;
    const scrollFactor = this.getscrollFactor();
    const scrollEquivalent = this.scrollEquivalent(scrollCount, scrollFactor);
    // const scrollEquivalent = scrollCount * scrollFactor;
    let newScrollPosition =
      scrollPosition + scrollEquivalent * (tabWidth + scrollTolerance);
    this.setState(
      {
        scrollPosition: newScrollPosition,
        firstTabInFrame: firstTabInFrame - scrollEquivalent,
        lastTabInFrame:
          firstTabInFrame + tabsToAccomodate - scrollEquivalent - 1,
        scrollCount: 1
      },
      () => {
        this.updateOpacity();
        this.toggleScrollButtons();
      }
    );
  };
  scrollToActive = () => {
    const { activeId } = this.props;
    const scrollFactor = this.getscrollFactor();
    const { firstTabInFrame, lastTabInFrame, tabsToAccomodate } = this.state;
    let reqFirstTab = firstTabInFrame;
    let reqLastTab = lastTabInFrame;
    let scrollDirection = "";
    if (activeId) {
      while (activeId < reqFirstTab || activeId > reqLastTab) {
        if (activeId < reqFirstTab) {
          reqFirstTab = reqFirstTab - scrollFactor;
          reqLastTab = reqFirstTab + tabsToAccomodate - 1;
          scrollDirection = "left";
        } else if (activeId > lastTabInFrame) {
          reqFirstTab = reqFirstTab + scrollFactor;
          reqLastTab = reqFirstTab + tabsToAccomodate - 1;
          scrollDirection = "right";
        }
      }
      if (scrollDirection) {
        const scrollCount = Math.ceil(
          Math.abs((firstTabInFrame - reqFirstTab) / tabsToAccomodate)
        );
        this.setState({ scrollCount }, () => {
          if (scrollDirection === "left") {
            this.scrolleft();
          } else if (scrollDirection === "right") {
            this.scrollRight();
          }
        });
      }
    }
  };

  scrollRight = () => {
    const {
      scrollPosition,
      tabWidth,
      scrollTolerance,
      tabsToAccomodate,
      firstTabInFrame,
      scrollCount
    } = this.state;

    const scrollFactor = this.getscrollFactor();
    const scrollEquivalent = this.scrollEquivalent(scrollCount, scrollFactor);
    // const scrollEquivalent = scrollCount * scrollFactor;
    let newScrollPosition =
      scrollPosition - scrollEquivalent * (tabWidth + scrollTolerance);
    this.setState(
      {
        scrollPosition: newScrollPosition,
        firstTabInFrame: firstTabInFrame + scrollEquivalent,
        lastTabInFrame:
          firstTabInFrame + tabsToAccomodate + scrollEquivalent - 1,
        scrollCount: 1
      },
      () => {
        this.updateOpacity();
        this.toggleScrollButtons();
      }
    );
  };

  updateOpacity = () => {
    let opacity = {};
    const { firstTabInFrame, lastTabInFrame } = this.state;
    this.props.tabItemsList.forEach(item => {
      let id = item.id;
      if (id >= firstTabInFrame && id <= lastTabInFrame) {
        opacity[id] = 1;
      } else {
        opacity[id] = 0.4;
      }
    });
    this.setState({ opacity });
  };
  onTouchStart = e => {
    if (window.innerWidth <= 991) {
      const Touches = e.targetTouches[0];
      this.setState({ initialStart: Touches.pageX });
    }
  };
  onTouchMove = e => {
    if (window.innerWidth <= 991) {
      const Touches = e.targetTouches[0];
      let scrollPosition = Touches.pageX - this.state.initialStart;
      scrollPosition = this.state.scrollPosition + scrollPosition;
      const scrollEndPoint = -(
        this.state.totalWidth -
        this.state.sectionWidth +
        20
      );
      if (scrollPosition > 0) {
        scrollPosition = 0;
      } else if (scrollPosition < scrollEndPoint) {
        scrollPosition = scrollEndPoint;
      }
      this.setState({ initialStart: Touches.pageX, scrollPosition });
    }
  };
  onTouchEnd = e => {
    //console.log("Touch Move",Touches)
  };

  render() {
    const { tabItemsList = [], data, onClick, activeId } = this.props;
    const { disableRightScroll, disableLeftScroll } = this.state;
    return (
      <div className="dashboard-tabs col-xs-12 col-md-12 col-sm-12">
        <span
          className="hidden-xs hidden-sm"
          style={{
            backgroundColor: disableLeftScroll ? "#e9e9e980" : "#fff",
            padding: "4px 7px",
            borderRadius: "50%",
            border: disableLeftScroll ? "1px solid #ccc" : "",
            boxShadow: disableLeftScroll
              ? "none"
              : "rgba(162, 162, 162, 0.69) 1px 1px 4px",
            position: "absolute",
            left: "0px",
            zIndex: "2",
            marginRight: "10px"
          }}
        >
          <a onClick={!disableLeftScroll ? this.scrolleft : () => {}}>
            <i
              className="fa fa-chevron-left"
              style={{
                marginRight: "1px",
                color: disableLeftScroll ? "#ccc" : "#777"
              }}
            />
          </a>
        </span>
        <div
          // id="rounded-tab-section"
          className=" col-lg-12 col-md-12 col-sm-12 col-xs-12 rounded-tab-section-wrapper"
          style={{ overflowX: "hidden" }}
        >
          <div
            id={"rounded-tab-section-" + data.PayrollId}
            className="rounded-tab-section"
            onTouchStart={this.onTouchStart}
            onTouchMove={this.onTouchMove}
            onTouchEnd={this.onTouchEnd}
            style={{
              transform: "translateX(" + this.state.scrollPosition + "px)"
            }}
          >
            {tabItemsList.map((item, i) => (
              <SingleRoundedTab
                payrollId={data.PayrollId}
                key={item.id}
                tabItem={item}
                onClick={() => onClick(item, data.PayrollId, true)}
                active={item.id === activeId}
                badgeStyle={badgeStyle}
                width={this.state.tabWidth}
                // opacity={this.state.opacity[item.id]}
              />
            ))}
          </div>
        </div>
        <span
          className="hidden-xs hidden-sm"
          style={{
            backgroundColor: disableRightScroll ? "#e9e9e980" : "#fff",
            padding: "4px 7px",
            borderRadius: "50%",
            border: disableRightScroll ? "1px solid #ccc" : "",
            boxShadow: disableRightScroll
              ? "none"
              : "rgba(162, 162, 162, 0.69) 1px 1px 4px",
            position: "absolute",
            right: "0px",
            zIndex: "2",
            marginLeft: "10px"
          }}
        >
          <a onClick={!disableRightScroll ? this.scrollRight : () => {}}>
            <i
              className="fa fa-chevron-right"
              style={{
                marginRight: "1px",
                color: disableRightScroll ? "#ccc" : "#777"
              }}
            />
          </a>
        </span>
      </div>
    );
  }
}

export default RoundedTabsSection;
