import React, { Component } from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import {
  contactInfo,
  CONTACT_INFO,
  CELLPHONE_NUMBER,
  CELLPHONE_CARRIER_TOOLTIP
} from "../Constants";

class ContactInfo extends Component {
  render() {
    const { data, onChange } = this.props;
    const { initialData } = data;

    const componentProps = contactInfo;
    const contactInfoNodes = Object.keys(componentProps).map(k => {
      return (
        <FormFieldGroup
          key={k}
          {...componentProps[k]}
          block
          colSpan="col-md-3"
          value={data[k]}
          onChange={onChange}
          error={data.errors[componentProps[k]["inputName"]]}
        />
      );
    });
    return (
      <FormSection title={CONTACT_INFO} hr>
        <div>
          {contactInfoNodes}
          <div
            className="row m-0"
            style={{ position: "relative", bottom: "20px" }}
          >
            <div className="col-md-6" style={{ padding: "0px" }}>
              <FormFieldGroup
                show={true}
                inputId="cellPhoneNumber"
                inputType="text"
                inputName="cellPhoneNumber"
                Size={[4, 4, 4, 4]}
                label={CELLPHONE_NUMBER}
                optional={true}
                iconBeforeLabel="fa fa-mobile"
                iconBeforeLabelStyle={{
                  float: "left",
                  fontSize: "38px",
                  color: "#0077a1",
                  margin: "0px 7px"
                }}
                block
                colSpan="col-md-6"
                value={data["cellPhoneNumber"]}
                onChange={onChange}
                error={data.errors["cellPhoneNumber"]}
                required={true}
              />
            </div>
            <div className="col-md-6" style={{ padding: "0px" }}>
              <FormFieldGroup
                show={true}
                inputId="cellPhoneCarrier"
                inputType="dropdown"
                inputName="cellPhoneCarrier"
                Size={[5, 5, 5, 5]}
                label="Cell Phone Carrier"
                tooltip={CELLPHONE_CARRIER_TOOLTIP}
                options={initialData.cellphoneCarrier || []}
                block
                colSpan="col-md-5"
                value={data["cellPhoneCarrier"]}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </FormSection>
    );
  }
}

export default ContactInfo;
