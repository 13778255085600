import React from "react";
const Pending = ({ download, cancelReopen }) => (
  <div className="payroll-dash-summary-footer-buttons nc-button-group">
    <button
      id="payroll-dash-download-wire-instructions-button"
      className="nc-button secondary-button"
      onClick={download}
    >
      DOWNLOAD WIRE INSTRUCTIONS
    </button>
    <button
      id="payroll-dash-reopen-payroll-button"
      className="nc-button primary-button"
      onClick={cancelReopen}
    >
      CANCEL WIRE/REOPEN PAYROLL
    </button>
    {/* 
    <PayrollDashBoardModal
        show={this.state.cancelWireReopenModal}
        bodyContent={this.cancelWireReopenModalContent()}
        closeModal={this.closeCancelWireReopenModal}
        footerButtons={this.cancelWireReopenModalButtons()}
        buttonAlign="center"
    /> */}
  </div>
);
export default Pending;
