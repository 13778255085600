export const EARNINGS_LISTING_EARNING_MAIN_LIST =
  "EARNINGS_LISTING_EARNING_MAIN_LIST";

export const EARNINGS_LISTING_EARNING_FILTERED_LIST =
  "EARNINGS_LISTING_EARNING_FILTERED_LIST";

export const EARNINGS_LISTING_BY_EARNING_ID_LIST =
  "EARNINGS_LISTING_BY_EARNING_ID_LIST";

// add earning page
export const ADD_EARNINGS_GROUP_PAGE_MAIN_LIST =
  "ADD_EARNINGS_GROUP_PAGE_MAIN_LIST";

export const ADD_EARNINGS_ALL_EARNING_GROUP_DROPDOWN_LIST =
  "ADD_EARNINGS_ALL_EARNING_GROUP_DROPDOWN_LIST";

export const ADD_EARNINGS_ALL_EARNING_DROPDOWN_MAIN_LIST =
  "ADD_EARNINGS_ALL_EARNING_DROPDOWN_MAIN_LIST";

export const ADD_EARNINGS_ALL_EARNING_DROPDOWN_LIST =
  "ADD_EARNINGS_ALL_EARNING_DROPDOWN_LIST";

export const ADD_EARNINGS_PAGE_LISTING_EARNING_FILTERED_LIST =
  "ADD_EARNINGS_PAGE_LISTING_EARNING_FILTERED_LIST";

export const EARNING_PAGE_DATA = "EARNING_PAGE_DATA";

export const FETCH_STATUS_LIST = "FETCH_STATUS_LIST";

// toster

export const TOASTER_MESSAGE = "TOASTER_MESSAGE";

export const CREATE_BUTTON_TOASTER_MESSAGE = "CREATE_BUTTON_TOASTER_MESSAGE";
