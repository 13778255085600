import React from "react";
import { Modal } from "react-bootstrap";
import ModalHeader from "./../../../EmployeeProfileModal/ModalHeader";

const InactivationSuccessModal = ({ show, onCancel, onSave }) => {
  return (
    <Modal
      id="inactivation-success-modal"
      bsClass="inactivation-success-modal entity-page-cancel-modal modal"
      animation={false}
      backdrop={"static"}
      keyboard={false}
      show={show}
      onHide={onCancel}
      restoreFocus={false}
    >
      <ModalHeader onClose={onCancel} />
      <Modal.Body>
        <div
          id="inactivation-success-modal-body"
          className="modal-row"
          style={{ textAlign: "center" }}
        >
          <div>
            <i className="fa fa-check" />
          </div>
          <div
            style={{ fontSize: "18px", fontWeight: 600, marginBottom: "15px" }}
          >
            You have successfully changed Nancy Ann Christianson's status to
            inactive
          </div>
          <div style={{ marginBottom: "10px", fontSize: "18px" }}>
            Don't forget to bulk assign your replacement managers.{" "}
          </div>
          <div>
            You can always do it later by visitng People>Self
            Service>Primary/Secondary Manager Assignment.
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div id="cancel-modal-footer" className="button-group">
          <button
            type="button"
            id="save-modal-button"
            className="nc-button primary-button"
            onClick={onSave}
          >
            Bulk Assign
          </button>

          <button
            type="button"
            id="save-modal-button"
            className="nc-button secondary-link-button"
            onClick={onCancel}
          >
            I'll Do it Later
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default InactivationSuccessModal;
