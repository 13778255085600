export const activePanelDefault = {
  // EmploymentHistory: [
  //   {
  //     EmploymentHistoryId: 2,
  //     CompanyName: "Bob's Burgers",
  //     City: "Covington",
  //     StateId: 1,
  //     StateName: null,
  //     EmployerCellPhoneNumber: 123456789,
  //     Position: "Graphic Designer",
  //     EmploymentFromMonth: "10",
  //     EmploymentFromYear: "2011",
  //     EmploymentToMonth: "10",
  //     EmploymentToYear: "2016",
  //     Responsibilities: "",
  //     HourlyRateOrAnnualSalary: "40.00",
  //     SalaryTypeId: 1,
  //     SalaryTypeName: null,
  //     ReasonForLeaving: "",
  //     PreviosName: "",
  //     SupervisorName: "Bob",
  //     SupervisorTitle: "Manager",
  //     SupervisorCellPhoneNumber: 123456789,
  //     SupervisorCellPhoneTypeId: 1,
  //     SupervisorCellPhoneTypeName: null,
  //     SupervisorEmailAddress: "bob@gmail.com",
  //     IsVerified: true,
  //     VerificationDate: "2015-01-01T00:00:00",
  //     VerifiedWith: "",
  //     VerifiedWithTitle: "",
  //     VerifiedWithCellPhoneNumber: 123456789,
  //     VerifiedWithEmailAddress: "",
  //     VerifiedById: 1,
  //     VerifiedByName: null
  //   },
  //   {
  //     EmploymentHistoryId: 1,
  //     CompanyName: "Creative Space",
  //     City: "",
  //     StateId: 1,
  //     StateName: null,
  //     EmployerCellPhoneNumber: 123456789,
  //     Position: "Graphic Designer",
  //     EmploymentFromMonth: "",
  //     EmploymentFromYear: "2010",
  //     EmploymentToMonth: "",
  //     EmploymentToYear: "2011",
  //     Responsibilities: "",
  //     HourlyRateOrAnnualSalary: "35.00",
  //     SalaryTypeId: 1,
  //     SalaryTypeName: null,
  //     ReasonForLeaving: "",
  //     PreviosName: "",
  //     SupervisorName: "Bob",
  //     SupervisorTitle: "Manager",
  //     SupervisorCellPhoneNumber: 123456789,
  //     SupervisorCellPhoneTypeId: 1,
  //     SupervisorCellPhoneTypeName: null,
  //     SupervisorEmailAddress: "bob@gmail.com",
  //     IsVerified: false,
  //     VerificationDate: null,
  //     VerifiedWith: null,
  //     VerifiedWithTitle: null,
  //     VerifiedWithCellPhoneNumber: null,
  //     VerifiedWithEmailAddress: null,
  //     VerifiedById: null,
  //     VerifiedByName: null
  //   }
  // ],
  // LicensesAndCertification: [
  //   {
  //     Id: 6,
  //     TypeId: 1,
  //     TypeName: "Awards",
  //     Name: "Certified Payroll Professional",
  //     NumberId: 1,
  //     ExpirationDate: "2018-05-21T00:00:00",
  //     IssuedBy: "",
  //     IssuedDate: "2015-01-01T00:00:00",
  //     CountryIssuedInId: 1,
  //     StateIssuedInId: 5,
  //     CityIssuedIn: "Austan",
  //     IsVerified: false,
  //     VerificationDate: "",
  //     VerifiedWith: "",
  //     VerifiedWithTitle: "",
  //     VerifiedWithCellPhoneNumber: null,
  //     VerifiedWithEmailAddress: "",
  //     VerifiedById: null,
  //     VerifiedByName: ""
  //   },
  //   {
  //     Id: 5,
  //     TypeId: 4,
  //     TypeName: "Mebership",
  //     Name: "UI Designer of the Month",
  //     NumberId: 1,
  //     ExpirationDate: "2018-04-19T00:00:00",
  //     IssuedBy: "",
  //     IssuedDate: "2015-01-01T00:00:00",
  //     CountryIssuedInId: 1,
  //     StateIssuedInId: 5,
  //     CityIssuedIn: "Austan",
  //     IsVerified: false,
  //     VerificationDate: "",
  //     VerifiedWith: "",
  //     VerifiedWithTitle: "",
  //     VerifiedWithCellPhoneNumber: null,
  //     VerifiedWithEmailAddress: "",
  //     VerifiedById: null,
  //     VerifiedByName: ""
  //   },
  //   {
  //     Id: 4,
  //     TypeId: 5,
  //     TypeName: "Other",
  //     Name: "Certified Payroll Professional",
  //     NumberId: 1,
  //     ExpirationDate: "2018-07-21T00:00:00",
  //     IssuedBy: "",
  //     IssuedDate: "2015-01-01T00:00:00",
  //     CountryIssuedInId: 1,
  //     StateIssuedInId: 5,
  //     CityIssuedIn: "Austan",
  //     IsVerified: true,
  //     VerificationDate: "2015-01-01T00:00:00",
  //     VerifiedWith: "Netchex Employee",
  //     VerifiedWithTitle: "Technical",
  //     VerifiedWithCellPhoneNumber: 123456789,
  //     VerifiedWithEmailAddress: "employee@gmail.com",
  //     VerifiedById: 1,
  //     VerifiedByName: "Ashish Kankal"
  //   },
  //   {
  //     Id: 3,
  //     TypeId: 4,
  //     TypeName: "Membership",
  //     Name: "Certified Internal Auditor",
  //     NumberId: 1,
  //     ExpirationDate: "2018-06-19T00:00:00",
  //     IssuedBy: "",
  //     IssuedDate: "2015-01-01T00:00:00",
  //     CountryIssuedInId: 1,
  //     StateIssuedInId: 5,
  //     CityIssuedIn: "Austan",
  //     IsVerified: false,
  //     VerificationDate: "",
  //     VerifiedWith: "",
  //     VerifiedWithTitle: "",
  //     VerifiedWithCellPhoneNumber: null,
  //     VerifiedWithEmailAddress: "",
  //     VerifiedById: null,
  //     VerifiedByName: ""
  //   },
  //   {
  //     Id: 2,
  //     TypeId: 3,
  //     TypeName: "Licenses",
  //     Name: "UI Designer of the Month",
  //     NumberId: 1,
  //     ExpirationDate: "2018-05-19T00:00:00",
  //     IssuedBy: "",
  //     IssuedDate: "2015-01-01T00:00:00",
  //     CountryIssuedInId: 1,
  //     StateIssuedInId: 5,
  //     CityIssuedIn: "Austan",
  //     IsVerified: true,
  //     VerificationDate: "2015-01-01T00:00:00",
  //     VerifiedWith: "Netchex Employee",
  //     VerifiedWithTitle: "Technical",
  //     VerifiedWithCellPhoneNumber: 123456789,
  //     VerifiedWithEmailAddress: "employee@gmail.com",
  //     VerifiedById: 1,
  //     VerifiedByName: "Ashish Kankal"
  //   },
  //   {
  //     Id: 1,
  //     TypeId: 2,
  //     TypeName: "Certification",
  //     Name: "Google Ad Word",
  //     NumberId: 1,
  //     ExpirationDate: "2018-06-20T00:00:00",
  //     IssuedBy: "",
  //     IssuedDate: "2017-02-20T00:00:00",
  //     CountryIssuedInId: 1,
  //     StateIssuedIn: 5,
  //     CityIssuedIn: "Austan",
  //     IsVerified: false,
  //     VerificationDate: "",
  //     VerifiedWith: "",
  //     VerifiedWithTitle: "",
  //     VerifiedWithCellPhoneNumber: null,
  //     VerifiedWithEmailAddress: null,
  //     VerifiedById: null,
  //     VerifiedByName: null
  //   }
  // ],
  Education: [
    {
      EducationId: 1,
      Name: "Lousiana State Univercity",
      City: "New Orleans",
      StateId: 5,
      State: "Lousiana",
      StartYear: "2010",
      StartMonth: "5",
      Graduated: false,
      GraduationMonth: "",
      GraduationYear: "",
      ExpectedGraduationMonth: "8",
      ExpectedGraduationYear: "2016",
      EndDateMonth: "",
      EndDateYear: "",
      CurrentlyEnrolled: true,
      DegreeId: 1,
      Degree: "Master's Degree",
      Majors: [{ Name: "Digital Design" }, { Name: "Graphic Motion" }],
      Minors: null,
      CurrentGPA: 8,
      HonorsAwards: "",
      RelevantCoursework: "",
      IsVerified: true,
      VerificationDate: "2015-01-01T00:00:00",
      VerifiedWith: "Netchex Employee",
      VerifiedWithTitle: "Technical",
      VerifiedWithCellPhoneNumber: 123456789,
      VerifiedWithEmailAddress: "employee@gmail.com",
      VerifiedById: 1,
      VerifiedByName: "Ashish Kankal"
    },
    {
      EducationId: 2,
      Name: "SCAD",
      City: "New Orleans",
      StateId: 5,
      State: "Lousiana",
      StartYear: "2010",
      StartMonth: "5",
      Graduated: false,
      GraduationMonth: "",
      GraduationYear: "",
      ExpectedGraduationMonth: "",
      ExpectedGraduationYear: "",
      EndDateMonth: "8",
      EndDateYear: "2016",
      CurrentlyEnrolled: false,
      DegreeId: 1,
      Degree: "Graduated Degree",
      Majors: [{ Name: "Visual Design & Motion" }],
      Minors: null,
      CurrentGpaScale: 8,
      HonorsAwardsAchivements: "",
      RelevantCoursework: "",
      IsVerified: false,
      VerificationDate: "",
      VerifiedWith: "",
      VerifiedWithTitle: "",
      VerifiedWithCellPhoneNumber: null,
      VerifiedWithEmailAddress: "",
      VerifiedById: 1,
      VerifiedByName: ""
    },
    {
      EducationId: 3,
      Name: "Lousiana State Univercity",
      City: "New Orleans",
      StateId: 5,
      State: "Lousiana",
      StartYear: "2010",
      StartMonth: "6",
      Graduated: true,
      GraduationMonth: "5",
      GraduationYear: "2015",
      ExpectedGraduationMonth: "",
      ExpectedGraduationYear: "",
      EndDateMonth: "8",
      EndDateYear: "2016",
      CurrentlyEnrolled: false,
      DegreeId: 3,
      Degree: "Bachelor's Degree",
      Majors: [{ Name: "Visual Design & Motion" }],
      Minors: null,
      CurrentGPA: 8,
      HonorsAwards: "",
      RelevantCoursework: "",
      IsVerified: true,
      VerificationDate: "2015-01-01T00:00:00",
      VerifiedWith: "Netchex Employee",
      VerifiedWithTitle: "Technical",
      VerifiedWithCellPhoneNumber: 123456789,
      VerifiedWithEmailAddress: "employee@gmail.com",
      VerifiedById: 1,
      VerifiedByName: "Ashish Kankal"
    },
    {
      EducationId: 4,
      Name: "DeSoto Parish School Board",
      City: "New Orleans",
      StateId: 5,
      State: "Lousiana",
      StartYear: "2010",
      StartMonth: "05",
      Graduated: true,
      GraduationMonth: "9",
      GraduationYear: "2011",
      ExpectedGraduationMonth: "",
      ExpectedGraduationYear: "",
      EndDateMonth: "8",
      EndDateYear: "2016",
      CurrentlyEnrolled: false,
      DegreeId: 3,
      Degree: "Graduate Degree",
      Majors: [{ Name: "Digital Design" }],
      Minors: null,
      CurrentGPA: 8,
      HonorsAwards: "",
      RelevantCoursework: "",
      IsVerified: false,
      VerificationDate: "2015-01-01T00:00:00",
      VerifiedWith: "Netchex Employee",
      VerifiedWithTitle: "Technical",
      VerifiedWithCellPhoneNumber: 123456789,
      VerifiedWithEmailAddress: "employee@gmail.com",
      VerifiedById: 1,
      VerifiedByName: "Ashish Kankal"
    },
    {
      EducationId: 5,
      Name: "Evangeline Parish School Board",
      City: "New Orleans",
      StateId: 5,
      State: "Lousiana",
      StartYear: "2010",
      StartMonth: "5",
      Graduated: true,
      GraduationMonth: "5",
      GraduationYear: "2014",
      ExpectedGraduationMonth: "",
      ExpectedGraduationYear: "",
      EndDateMonth: "8",
      EndDateYear: "2016",
      CurrentlyEnrolled: false,
      DegreeId: 3,
      Degree: "Bachelor's Degree",
      Majors: [{ Name: "Visual Design & Motion" }],
      Minors: null,
      CurrentGPA: 8,
      HonorsAwards: "",
      RelevantCoursework: "",
      IsVerified: false,
      VerificationDate: "2015-01-01T00:00:00",
      VerifiedWith: "Netchex Employee",
      VerifiedWithTitle: "Technical",
      VerifiedWithCellPhoneNumber: 123456789,
      VerifiedWithEmailAddress: "employee@gmail.com",
      VerifiedById: 1,
      VerifiedByName: "Ashish Kankal"
    },
    {
      EducationId: 6,
      Name: "DeSoto Parish School Board",
      City: "New Orleans",
      StateId: 5,
      State: "Lousiana",
      StartYear: "2010",
      StartMonth: "5",
      Graduated: true,
      GraduationMonth: "6",
      GraduationYear: "2014",
      ExpectedGraduationMonth: "",
      ExpectedGraduationYear: "",
      EndDateMonth: "8",
      EndDateYear: "2016",
      CurrentlyEnrolled: false,
      DegreeId: 3,
      Degree: "Graduate Degree",
      Majors: [{ Name: "Digital Design" }],
      Minors: null,
      CurrentGPA: 8,
      HonorsAwards: "",
      RelevantCoursework: "",
      IsVerified: false,
      VerificationDate: "2015-01-01T00:00:00",
      VerifiedWith: "Netchex Employee",
      VerifiedWithTitle: "Technical",
      VerifiedWithCellPhoneNumber: 123456789,
      VerifiedWithEmailAddress: "employee@gmail.com",
      VerifiedById: 1,
      VerifiedByName: "Ashish Kankal"
    }
  ],
  Skill: [
    {
      Id: 1,
      SkillId: 1,
      Skill: "Data Entry Experiance",
      SkillLevelId: 1,
      SkillLevelName: "Beginner",
      YearsExperience: 5,
      Notes: ""
    },
    {
      Id: 2,
      SkillId: 2,
      Skill: "Phone Skills",
      SkillLevelId: 4,
      SkillLevelName: "Expert",
      YearsExperience: 10,
      Notes: ""
    }
  ]
};
