import React from "react";
import UserDetails from "../../../activity-history/UserDetails";
import InformationGroup from "../InformationGroup";
import Accordian from "component-library/accordians";
import classNames from "classnames/bind";
import FormatAddress from "../Address";

export function highlighter(newElem, oldElem) {
  var newText = newElem;
  var oldText = oldElem;
  if (newText) {
    return newText.split("").map((value, index) => {
      if (value !== oldText.charAt(index))
        return (
          <span style={{ backgroundColor: "#ffebcd" }} className="highlight">
            {value}
          </span>
        );
      else return <span>{value}</span>;
    });
  }
  return null;
}

export function isAddressNull(suggestedValue) {
  let response = false;
  response = suggestedValue.AddressLine1 || response;
  response = suggestedValue.AddressLine2 || response;
  response = suggestedValue.City || response;
  response = suggestedValue.StateName || response;
  response = suggestedValue.ZipCode || response;
  return response;
}
const WageTaxValidationListItem = ({
  data,
  showAccordian,
  currentColumn,
  suggestedColumn,
  updateAddress,
  addressNotFound,
  currentValue,
  suggestedValue,
  editOnClick,
  homeAddress,
  workAddress,
  checkListName = "",
  hr,
  sectionHr
}) => {
  const { EmployeeName, EmployeePictureUrl } = data;
  let className = classNames("row wage-tax-validation-item", sectionHr && "hr");
  return (
    <div className={className}>
      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <UserDetails
          parent="dashBoard"
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
          name={EmployeeName}
          picUrl={EmployeePictureUrl || "https://goo.gl/4AYQmG"}
        />
        {showAccordian && (
          <Accordian title="View Current Addresses">
            <div className="accordian-information-group">
              <InformationGroup title="Home Address:">
                <FormatAddress
                  line1={homeAddress.AddressLine1}
                  line2={homeAddress.AddressLine2}
                  city={homeAddress.City}
                  state={homeAddress.StateName}
                  country={homeAddress.CountryName}
                  zipcode={homeAddress.ZipCode}
                />
              </InformationGroup>
              <InformationGroup title="Work Address:">
                <FormatAddress
                  line1={workAddress.AddressLine1}
                  line2={workAddress.AddressLine2}
                  city={workAddress.City}
                  state={workAddress.StateName}
                  country={workAddress.CountryName}
                  zipcode={workAddress.ZipCode}
                />
              </InformationGroup>
            </div>
          </Accordian>
        )}
      </div>
      <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 current-section">
          <div
            className="col-lg-12 col-md-12 col-xs-12 ig-wrapper tax-verification"
            style={{ display: "flex", paddingRight: "20px" }}
          >
            <InformationGroup
              title={"Current State Withholding"}
              hr={hr}
              titleStyle="bold"
            >
              <div className="clearfix" style={{ marginBottom: "10px" }}>
                {data.EmployeeCurrentStateWithHolding}
              </div>
            </InformationGroup>
          </div>
          <div
            className="col-lg-12 col-md-12 col-xs-12 ig-wrapper tax-verification"
            style={{ display: "flex", paddingRight: "20px" }}
          >
            <InformationGroup
              title={"Current State Disability"}
              hr={hr}
              titleStyle="bold"
            >
              <div className="clearfix" style={{ marginBottom: "10px" }}>
                {data.EmployeeCurrentStateDisablity}
              </div>
            </InformationGroup>
          </div>
          <div
            className="col-lg-12 col-md-12 col-xs-12 ig-wrapper tax-verification"
            style={{ display: "flex", paddingRight: "20px" }}
          >
            <InformationGroup
              title={"Current State Unemployment"}
              hr={hr}
              titleStyle="bold"
            >
              <div className="clearfix" style={{ marginBottom: "10px" }}>
                {data.EmployeeCurrentStateUnemployment}
              </div>
            </InformationGroup>
          </div>
          <div
            className="col-lg-12 col-md-12 col-xs-12 ig-wrapper tax-verification"
            style={{ display: "flex", paddingRight: "20px" }}
          >
            <InformationGroup
              title={"Current Local Withholding"}
              hr={false}
              titleStyle="bold"
            >
              <div className="clearfix" style={{ marginBottom: "10px" }}>
                {data.EmployeeCurrentLocalWithHolding}
              </div>
            </InformationGroup>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="clearfix">
              <button className="nc-button outline-button">
                Keep Current Taxes
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div
            style={{ display: "flex", paddingRight: "20px" }}
            className="col-lg-12 col-md-12 ig-wrapper tax-verification"
          >
            <InformationGroup
              title={"Suggested State Withholding"}
              style={{ paddingRight: "20px" }}
              hr={hr}
              titleStyle="bold"
            >
              <div className="clearfix" style={{ marginBottom: "10px" }}>
                {data.EmployeeSuggestedStateWithHolding}
              </div>
            </InformationGroup>
          </div>
          <div
            style={{ display: "flex", paddingRight: "20px" }}
            className="col-lg-12 col-md-12 ig-wrapper tax-verification"
          >
            <InformationGroup
              title={"Suggested State Disability"}
              style={{ paddingRight: "20px" }}
              hr={hr}
              titleStyle="bold"
            >
              <div className="clearfix" style={{ marginBottom: "10px" }}>
                {data.EmployeeSuggestedStateDisablity}
              </div>
            </InformationGroup>
          </div>
          <div
            style={{ display: "flex", paddingRight: "20px" }}
            className="col-lg-12 col-md-12 ig-wrapper tax-verification"
          >
            <InformationGroup
              title={"Suggested State Unemployment"}
              style={{ paddingRight: "20px" }}
              hr={hr}
              titleStyle="bold"
            >
              <div className="clearfix" style={{ marginBottom: "10px" }}>
                {data.EmployeeSuggestedStateUnemployment}
              </div>
            </InformationGroup>
          </div>
          <div
            style={{ display: "flex", paddingRight: "20px" }}
            className="col-lg-12 col-md-12 ig-wrapper tax-verification"
          >
            <InformationGroup
              title={"Suggested Local Withholding"}
              style={{ paddingRight: "20px" }}
              hr={false}
              titleStyle="bold"
            >
              <div className="clearfix" style={{ marginBottom: "10px" }}>
                {data.EmployeeSuggestedLocalWithHolding}
              </div>
            </InformationGroup>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="clearfix">
              <button className="nc-button secondary-button">
                Use Suggested Taxes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WageTaxValidationListItem;
