import { connect } from "react-redux";
import CustomFields from "../containers/CustomFields";
import {
  clearAllFilters,
  makeCriterion,
  makeSortCriterion
} from "app/common/reducers/filters";
import {
  editEntityData,
  clearEntityData
} from "app/common/reducers/entityData";
import {
  clearEntityErrors,
  editEntityErrors
} from "app/common/reducers/entityErrors";
import { setSortOrder, clearSortOrder } from "app/common/reducers/sortOrder";
import { clearToast } from "app/common/reducers/toast";
import { setLoader } from "app/actions/LoaderActions";
import { searchAndSetPostsMenu } from "../../../employee/base/network/actions";

export const getSearchParams = state => {
  const defaultSearchParams = {
    Fields: ["*"],
    Criterion: [],
    Sort: [],
    PageSize: 5
  };
  const filters = state.common.filters;

  let Criterion = [];
  Criterion.push(makeCriterion("Pages", filters.pages));
  Criterion.push(makeCriterion("Statuses", filters.status));
  Criterion = Criterion.filter(c => c);

  defaultSearchParams.Criterion = Criterion;

  const sortOrder = state.common.sortOrder;
  let Sort = null;
  Sort = makeSortCriterion(sortOrder);
  defaultSearchParams.Sort = Sort === null ? [] : Sort;

  return defaultSearchParams;
};

const stateToProps = state => {
  const { entityList, filters, sortOrder, toast, entitySetup } = state.common;
  return {
    entityList,
    filters,
    searchParams: getSearchParams(state),
    sortOrder,
    toast,
    data: entitySetup.data,
    employeeMenu: state.people.employeeMenu
  };
};
const actionCreators = {
  searchAndSetPosts: searchAndSetPostsMenu,
  clearAllFilters,
  setSortOrder,
  clearSortOrder,
  clearToast,
  setLoader,
  clearData: clearEntityData,
  editData: editEntityData,
  editErrors: editEntityErrors,
  clearErrors: clearEntityErrors
};
export default connect(stateToProps, actionCreators)(CustomFields);
