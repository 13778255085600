import { connect } from "react-redux";
import PayrollSchedule from "../containers/PayrollSchedule";
import {
  makeOptions,
  makeSelectedOptions,
  selectFilterOption,
  selectAllFilterOptions,
  clearAllFilters,
  makeCriterion,
  makeSortCriterion
} from "app/common/reducers/filters";
import { setSortOrder, clearSortOrder } from "app/common/reducers/sortOrder";
import { clearEntitySnapshot } from "app/common/reducers/entitySnapshot";
import { clearHistory } from "../reducers/payrollHistory";
import { clearToast } from "app/common/reducers/toast";
import {
  fetchAndSetPayrollSchedule,
  fetchAndSetStatus,
  fetchAndSetFrequency,
  removePayrollSchedule,
  postPayrollScheduleToActive,
  postPayrollScheduleToInactive,
  fetchEntitySnapshot,
  searchAndSetPayrolls,
  loadAndSetMorePayrolls
} from "../network/actions";
import { getPayrollTableData } from "../util";
import {
  deletePayrollScheduleAndContinue,
  inactivatePayrollScheduleAndContinue,
  reactivatePayrollScheduleAndContinue
} from "../network/async";
import { setLoader } from "app/actions/LoaderActions";

export const getSearchParams = state => {
  const searchParams = {
    Fields: ["*"],
    Criterion: [],
    Sort: [],
    PageSize: 5
  };
  const filters = state.common.filters;

  let Criterion = [];
  Criterion.push(
    makeCriterion("CompanyPayrollScheduleDefinitionId", filters.entity)
  );
  Criterion.push(makeCriterion("PayrollFrequencyId", filters.frequency));
  Criterion.push(makeCriterion("IsActive", filters.status));
  Criterion = Criterion.filter(c => c);

  searchParams.Criterion = Criterion;

  const sortOrder = state.common.sortOrder;
  let Sort = null;
  Sort = makeSortCriterion(sortOrder);
  searchParams.Sort = Sort === null ? [] : Sort;

  return searchParams;
};

const stateToProps = state => ({
  filters: state.common.filters,
  tableData: getPayrollTableData(state.payrollHistory),
  searchParams: getSearchParams(state),
  sortOrder: state.common.sortOrder,
  payrollHistory: state.payrollHistory,
  snapData: state.common.entitySnapshot,
  options: makeOptions(state.common.filters),
  selectedOptions: makeSelectedOptions(state.common.filters)
});
const actionCreators = {
  selectOption: selectFilterOption,
  selectAll: selectAllFilterOptions,
  removePayrollSchedule,
  fetchAndSetPayrollSchedule,
  fetchAndSetStatus,
  fetchAndSetFrequency,
  clearAllFilters,
  editSortOrder: setSortOrder,
  clearSortOrder,
  postPayrollScheduleToActive,
  postPayrollScheduleToInactive,
  inactivatePayrollScheduleAndContinue,
  reactivatePayrollScheduleAndContinue,
  clearToast,
  deletePayrollScheduleAndContinue,
  fetchEntitySnapshot,
  clearSnapshotData: clearEntitySnapshot,
  setLoader,
  searchAndSetPayrolls,
  loadAndSetMorePayrolls,
  clearHistory
};
export default connect(stateToProps, actionCreators)(PayrollSchedule);
