import React from "react";
import { FieldsId } from "./../earnings-setup/fieldIDs";
import { description } from "./Constants";

const EntityListHeader = ({
  title,
  page = "PayrollSchedule",
  addButtonLabel = "ADD",
  onAddButtonClick,
  onHistoryButtonClick,
  showButtons = true,
  onCopyEarning,
  hideCopy = true
}) => (
  <div id="deduction-page-header-container" className="clearfix">
    <div className="pageTitle-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div
        id={FieldsId.pageTitleId}
        className="pageTitle col-lg-6 col-md-7 col-sm-8 col-xs-12"
      >
        {title}
      </div>

      {showButtons && (
        <div className="nc-navigation-button-family hidden-sm hidden-xs col-lg-6 col-md-5 col-sm-12 col-xs-12">
          <span className="secondary-group">
            <button
              id={FieldsId.activityHistoryButtonId}
              className="nc-button outline-button-right pull-right"
              onClick={onHistoryButtonClick}
            >
              <i
                className="fa fa-history"
                style={{ marginRight: "5px" }}
                aria-hidden="true"
              />
              <span className="hidden-sm hidden-xs">HISTORY</span>
            </button>
            {hideCopy ? (
              ""
            ) : (
              <button
                id={FieldsId.activityHistoryButtonId}
                className="nc-button outline-button-right pull-right"
                onClick={onCopyEarning}
              >
                <i
                  className="fa fa-clone"
                  style={{ marginRight: "5px" }}
                  aria-hidden="true"
                />
                <span className="hidden-sm hidden-xs">COPY</span>
              </button>
            )}
          </span>
          <button
            id={FieldsId.addButtonId}
            type="button"
            onClick={onAddButtonClick}
            className="nc-button primary-button pull-right"
          >
            <i className="fa fa-plus"> </i> {addButtonLabel}
          </button>
        </div>
      )}
    </div>

    <div className="deduction-page-description-container">
      <div className="page-description col-lg-7 col-md-7 col-sm-12 col-xs-12">
        <p style={{ margin: "0px" }}>{description[page]}</p>
      </div>

      {showButtons && (
        <div className="deduction-page-description-button col-lg-6 col-md-5 col-sm-12 col-xs-12">
          <div className="nc-navigation-button-family col-xs-12 hidden-lg hidden-md">
            <div className="primary-group">
              <button
                id="add-deduction-button"
                type="button"
                onClick={onAddButtonClick}
                className="nc-button primary-button"
              >
                <i className="fa fa-plus" />
                <span className="hidden-lg hidden-md ">
                  &nbsp; {addButtonLabel}
                </span>
              </button>
            </div>
            <div className="secondary-group">
              <button
                className="nc-button outline-fa-button pull-right"
                id={FieldsId.activityHistoryButtonId}
                onClick={onHistoryButtonClick}
                style={{
                  borderBottomLeftRadius: "0",
                  borderTopLeftRadius: "0"
                }}
              >
                <i className="fa fa-history" aria-hidden="true" />
                {hideCopy && (
                  <span className="" style={{ marginLeft: "5px" }}>
                    HISTORY
                  </span>
                )}
              </button>
              {hideCopy ? (
                ""
              ) : (
                <button
                  className="nc-button outline-fa-button pull-right"
                  id={FieldsId.copyEarningButtonId}
                  onClick={onCopyEarning}
                  style={
                    !hideCopy
                      ? {
                          marginRight: "0",
                          borderRight: "none",
                          borderBottomRightRadius: "0",
                          borderTopRightRadius: "0"
                        }
                      : {}
                  }
                >
                  <i className="fa fa-clone" aria-hidden="true" />
                  <span
                    style={{ marginLeft: "5px" }}
                    className="hidden-sm hidden-xs"
                  >
                    COPY
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default EntityListHeader;
