import { connect } from "react-redux";
import PersonalInfo from "../containers/PersonalInfo";
import {
  fetchAndSetDemographicData,
  setEmployeeData,
  updateEmployeeData,
  fetchAndSetNameTitle,
  fetchAndSetSuffix,
  fetchAndSetMartialStatus,
  fetchAndSetRace,
  fetchAndSetGender,
  fetchAndSetCellPhoneCarrier,
  fetchAndSetCountry,
  fetchAndSetState
} from "../network/actions";
import { setLoader } from "app/actions/LoaderActions";
import { clearDemographicValues } from "../reducers/demographicData";

const stateToProps = state => ({
  data: state.personalInfo.demographicData,
  employeeDemographicData: state.people.employeeDashboard
});
const actionCreators = {
  fetchAndSetDemographicData,
  setEmployeeData,
  updateEmployeeData,
  fetchAndSetNameTitle,
  fetchAndSetSuffix,
  fetchAndSetMartialStatus,
  fetchAndSetRace,
  fetchAndSetGender,
  fetchAndSetCellPhoneCarrier,
  fetchAndSetCountry,
  fetchAndSetState,
  clearDemographicValues,
  setLoader
};
export default connect(stateToProps, actionCreators)(PersonalInfo);
