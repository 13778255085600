import {
  postEmployeeData,
  //patchEmployeeData,
  postNotesType,
  getNoteTypeData,
  getEmployeeData
} from ".";
//import { setLoader } from "app/actions/LoaderActions";
import { setEmployeeNotesValues } from "../reducers/employeeNotes";
import { setAddEmployeeNotesValues } from "../reducers/addEmployeeNotes";
//import addEmployeeNotes from "../reducers/addEmployeeNotes";
import { getAddNewNoteSchema } from "../utils/schema";

export const fetchEmployeeData = id => dispatch =>
  getEmployeeData(id).then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.length > 0 && Data[0].EmployeeNotes;
    dispatch(setEmployeeNotesValues("notes", dispatchData));
    return dispatchData;
  });

export const fetchTypeOfNote = id => dispatch =>
  getNoteTypeData(id).then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      Id: item.Id,
      value: item.Id,
      label: item.Name
    }));
    dispatch(setAddEmployeeNotesValues("addNotes", dispatchData));
    return dispatchData;
  });

export const addNewNoteType = (id, params) => dispatch =>
  postNotesType(id, params).then(res => {});

export const addNewNote = (id, params) => dispatch => {
  let payload = getAddNewNoteSchema(params);
  return postEmployeeData(id, payload).then(res => {});
};
