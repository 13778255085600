import React from "react";
import { sampleDescription } from "../Constants";

const EmployeeListHeader = ({
  addButtonLabel = "ADD",
  onAddButtonClick = () => {},
  description = sampleDescription
}) => (
  <div
    id="deduction-page-header-container"
    className="clearfix"
    style={{ margin: "25px 0px" }}
  >
    <div className="pageTitle-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="deduction-page-description-container">
        <div className="page-description col-lg-8 col-md-7 col-sm-12 col-xs-12">
          <p style={{ margin: "0px" }}>{description}</p>
        </div>
      </div>
      <div className="nc-navigation-button-family hidden-sm hidden-xs col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <button
          id="add"
          type="button"
          onClick={onAddButtonClick}
          className="nc-button secondary-button pull-right"
        >
          <i className="fa fa-plus"> </i> {addButtonLabel}
        </button>
      </div>
    </div>
  </div>
);

export default EmployeeListHeader;
