import React from "react";
import moment from "moment";
import TimeCardAlertActions from "./TimeCardAlertActions";
// const formatTime = timeStr => {
//   return moment(new Date(timeStr + "+00:00")).format("MMM DD");
// };

const convertDatetoMonthDay = date => {
  return moment(date).format("MMM D");
};
const TypeStyle = {
  fontWeight: 600
};
const TimeCardSubitem = ({
  TimeCardAlertId,
  TimeCardAlertName,
  TimeGroupId,
  TimeGroupName,
  TimeCardFromDateTime,
  TimeCardToDateTime,
  Hours,
  IsEmailSent = false
}) => (
  <div
    className="row payroll-dashboard-row"
    style={{
      backgroundColor: "rgb(233,233,233)",
      padding: 15
    }}
  >
    <div className="column-item leave-type col-md-3 col-lg-3 clearfix">
      <span className="field-value  hidden-sm hidden-xs">
        {TimeCardAlertName}
      </span>
      <span style={TypeStyle} className="field-value hidden-lg hidden-md">
        {TimeCardAlertName}
      </span>
      <span className="hidden-lg hidden-md">
        <TimeCardAlertActions
          TimeCardAlertName={TimeCardAlertName}
          hideContactApprove
          noPadding
          IsEmailSent={IsEmailSent}
          noMargin
        />
      </span>
    </div>

    <div className="column-item col-md-3 col-lg-3 clearfix">
      <span className="field-name hidden-md hidden-lg">Time Group:&nbsp;</span>
      <span className="field-value">{TimeGroupName}</span>
    </div>
    <div className="column-item col-md-3 col-lg-3 clearfix">
      <span className="field-name hidden-md hidden-lg">Time Period:&nbsp;</span>
      <span className="field-value">
        {convertDatetoMonthDay(TimeCardFromDateTime)} -{" "}
        {convertDatetoMonthDay(TimeCardToDateTime)}
      </span>
    </div>
    <div className="column-item col-md-1 col-lg-1 clearfix">
      <span className="field-name hidden-md hidden-lg">Hours:&nbsp;</span>
      <span className="field-value">{Hours}</span>
    </div>
    <div className="column-item hidden-sm hidden-xs col-md-2 col-lg-2 clearfix">
      <TimeCardAlertActions
        TimeCardAlertName={TimeCardAlertName}
        hideContactApprove
        noPadding
        noMargin
        IsEmailSent={IsEmailSent}
      />
    </div>
  </div>
);

export default TimeCardSubitem;
