import React, { Component } from "react";
import { connect } from "react-redux";
import BasicFilters from "../containers/ListFilters";
import EmployeeTable from "../containers/EmployeeTable";
import { withRouter } from "react-router-dom";
import {
  selectFilterOption,
  selectAllFilterOptions,
  clearAllFilters,
  makeSortCriterion
} from "app/common/reducers/filters";
import { setSortOrder, clearSortOrder } from "app/common/reducers/sortOrder";
import {
  searchAndSetPosts,
  loadAndSetMorePosts,
  fetchAndSetLoggedUser,
  auditViewSsnClick,
  clearAdvancedFilters
} from "../network/actions";
import { clearEmployeeList } from "./../reducers/employeeList";
import { setLoader } from "app/actions/LoaderActions";
import {
  makeOptions,
  makeSelectedOptions,
  getFilterCount,
  makeCriterion
} from "../utils";
import * as Filters from "component-library/employee-profile/list-view/advance-filter/FieldNames";

import * as util from "app/common/utilities";

const getSearchParams = state => {
  const { filters = {} } = state.common;
  const defaultSearchParams = {
    Fields: ["*"],
    Criterion: [],
    Sort: [],
    PageSize: 5
  };

  let Criterion = [];
  Criterion.push(
    makeCriterion(
      Filters.basicFilterEmployeelist,
      filters[Filters.basicFilterEmployeelist]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.basicFilterStatuslist,
      filters[Filters.basicFilterStatuslist]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.basicFilterCompanylist,
      filters[Filters.basicFilterCompanylist]
    )
  );

  Criterion = Criterion.filter(c => c);

  defaultSearchParams.Criterion = Criterion;

  const sortOrder = state.common.sortOrder;
  let Sort = null;
  Sort = makeSortCriterion(sortOrder);
  defaultSearchParams.Sort = Sort === null ? [] : Sort;

  return defaultSearchParams;
};
class EmployeeList extends Component {
  state = {
    loadingMore: false,
    loadingFirstTime: false
  };

  componentWillUnmount() {
    this.props.clearSortOrder();
    this.props.clearAllFilters();
  }

  searchPosts = () => {
    const { searchAndSetPosts, searchParams, filters } = this.props;
    this.setState({
      loadingFirstTime: true
    });
    searchAndSetPosts(searchParams, filters, () =>
      this.setState({ loadingFirstTime: false })
    );
  };

  changeSortOrder = (field, sortOrder) => {
    this.props.setSortOrder(field, sortOrder);
    setTimeout(
      function() {
        this.searchPosts();
      }.bind(this),
      10
    );
  };

  loadMorePosts = () => {
    const { employeeList, loadAndSetMorePosts } = this.props;
    const { NextUri } = employeeList;
    if (!util.isNullOrEmpty(NextUri)) {
      this.setState({
        loadingMore: true
      });
      loadAndSetMorePosts(NextUri, () => this.setState({ loadingMore: false }));
    } else {
      this.setState({
        loadingMore: false
      });
    }
  };

  onClickEmployee = id => {
    this.props.history.push("/people/employees/dashboard/" + id);
  };

  resetFilters = () => {
    const {
      clearAdvancedFiltersAndNavigate,
      clearFiltersFields,
      filters
    } = this.props;
    let searchParams = this.getBasicFilterSearchParams();

    clearFiltersFields();
    clearAdvancedFiltersAndNavigate(filters, searchParams, employeeData => {
      if (Object.keys(employeeData).length > 0) {
        this.props.history.push(
          "/people/employees/dashboard/" + employeeData.EmployeeId
        );
      } else {
        this.props.history.push("/people/employees/list-view");
      }
    });
    this.props.setShowClearFilters(false);
  };

  getBasicFilterSearchParams = () => {
    const defaultSearchParams = {
      Fields: ["*"],
      Criterion: [],
      Sort: [],
      PageSize: 5
    };
    const { defaultFilters } = this.props;
    let Criterion = [];
    Criterion.push(
      makeCriterion(
        Filters.basicFilterEmployeelist,
        defaultFilters[Filters.basicFilterEmployeelist]
      )
    );
    Criterion.push(
      makeCriterion(
        Filters.basicFilterStatuslist,
        defaultFilters[Filters.basicFilterStatuslist]
      )
    );
    Criterion.push(
      makeCriterion(
        Filters.basicFilterCompanylist,
        defaultFilters[Filters.basicFilterCompanylist]
      )
    );
    Criterion = Criterion.filter(c => c);
    defaultSearchParams.Criterion = Criterion;

    return defaultSearchParams;
  };

  render() {
    return (
      <div>
        <BasicFilters
          {...this.props}
          toggleAdvancedFilters={this.props.toggleAdvancedFilters}
          options={this.props.options}
          filters={this.props.filters}
          resetFilters={this.resetFilters}
          searchPosts={this.searchPosts}
        />
        <EmployeeTable
          {...this.props}
          searchPosts={this.searchPosts}
          changeSortOrder={this.changeSortOrder}
          loadMorePosts={this.loadMorePosts}
          onClickEmployee={this.onClickEmployee}
          loadingMore={this.state.loadingMore}
          loadingFirstTime={this.state.loadingFirstTime}
        />
      </div>
    );
  }
}

const stateToProps = state => ({
  employeeList: state.people.employeeList,
  sortOrder: state.common.sortOrder,
  sessionContext: state.sessionContext,
  searchParams: getSearchParams(state),
  loggedUser: state.common.loggedUser,
  viewSSN: state.people.viewSSN.state,
  options: makeOptions(state.common.filters),
  selectedOptions: makeSelectedOptions(state.common.filters),
  defaultFilters: state.common.filters
});

const actionCreators = {
  searchAndSetPosts,
  loadAndSetMorePosts,
  clearAllFilters,
  setSortOrder,
  clearSortOrder,
  clearEmployeeList,
  setLoader,
  fetchAndSetLoggedUser,
  auditViewSsnClick,
  getFilterCount,
  selectOption: selectFilterOption,
  selectAll: selectAllFilterOptions,
  clearAdvancedFilters
};

export default withRouter(connect(stateToProps, actionCreators)(EmployeeList));
