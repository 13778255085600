import React from "react";
import Media from "react-media";
import OptionsModal from "./OptionsModal";
import OptionsPopover from "./OptionsPopover";

const DropDownOptions = props => (
  <Media query="(max-width: 991px)">
    {isMobile => {
      const Options =
        isMobile && !props.showDesktopDropdownFor768
          ? OptionsModal
          : OptionsPopover;
      return <Options {...props} />;
    }}
  </Media>
);

export default DropDownOptions;
