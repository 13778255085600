import {
  setFilterOptions,
  selectAllFilterOptions
  //selectFilterOption
} from "app/common/reducers/filters";
import {
  getStatesList,
  getCountryList,
  //getDegreeList,
  deleteEmployementHistory,
  deleteLicensesCertification,
  deleteEducation,
  deleteSkill,
  // fetchResumeEntityData,
  // fetchEmploymentHistory,
  // getEmploymentHistoryCompanyList,
  // getEmploymentHistoryPositionList,
  getLicensesCertificationNameList,
  getLicensesCertificationTypeList,
  // getSkillsSkillList,
  // getSkillsSkillLevelsList,
  // getSkillsYearOfExperienceList,
  // getEducationDegreeList,
  // getEducationSchoolList,
  saveEmploymentHistoryData,
  saveLicensesCertificationData,
  saveEducationData,
  saveSkillsData,
  updateEducationRecord,
  updateLicensesAndCertificationRecord,
  updateEmploymentHistoryRecord,
  updateSkillRecord,
  createNewSkill
} from ".";
import {
  setCountryStateOptions,
  setCountryOptions
} from "app/common/reducers/countryState";
import {
  editEntityData,
  clearEntityData
} from "app/common/reducers/entityData";
import {
  editEntityErrors,
  clearEntityErrors
} from "app/common/reducers/entityErrors";
import { setLoader } from "app/actions/LoaderActions";
import {
  setResumeEntityData,
  clearResumeEntityData
} from "../../base/reducers/resumeEntityDataReducer";
import * as FILTERS from "component-library/employee-profile/resume/utils/Constants";
import * as Constants from "../utils/data";
import * as FIELDS from "component-library/employee-profile/resume/utils/Constants";

const STATE_LIST = "States";
const COUNTRY_LIST = "Countries";

const EMPLOYMENT_HISTORY_ID = 5;
const LICENSES_AND_CETIFICATIONS_ID = 6;
const EDUCATION_ID = 7;
const SKILL_ID = 8;

const defaultSearchParams = {
  Fields: ["*"],
  Criterion: [],
  Sort: [],
  PageSize: 5
};

export const fetchAndSetStates = (name = STATE_LIST) => dispatch => {
  return getStatesList().then(res => {
    const { Data } = res.data;
    let dispatchData = [];
    Data.forEach(item =>
      dispatchData.push({
        id: item.CountryStateId,
        value: item.CountryStateId,
        label: item.ShortName
      })
    );
    dispatch(setCountryStateOptions(name, dispatchData));
  });
};

export const fetchAndSetCountry = (name = COUNTRY_LIST) => dispatch => {
  return getCountryList().then(res => {
    const { Data } = res.data;
    let dispatchData = [];
    Data.forEach(item =>
      dispatchData.push({
        id: item.CountryId,
        value: item.CountryId,
        label: item.LongName
      })
    );
    dispatch(setCountryOptions(name, dispatchData));
  });
};

export const deleteEmploymentHistoryRecordAndReload = (
  id,
  name,
  successCallback,
  params = defaultSearchParams
) => dispatch =>
  deleteEmployementHistory(id).then(() => {
    successCallback();
    dispatch(fetchAndSetResumeEntityData(EMPLOYMENT_HISTORY_ID, params));
  });

export const deleteLicensesRecordAndReload = (
  id,
  name,
  successCallback,
  params = defaultSearchParams
) => dispatch =>
  deleteLicensesCertification(id).then(() => {
    successCallback();
    dispatch(
      fetchAndSetResumeEntityData(LICENSES_AND_CETIFICATIONS_ID, params)
    );
  });

export const deleteEducationRecordAndReload = (
  id,
  name,
  successCallback,
  params = defaultSearchParams
) => dispatch =>
  deleteEducation(id).then(() => {
    successCallback();
    dispatch(fetchAndSetResumeEntityData(EDUCATION_ID, params));
  });

export const deleteSkillRecordAndReload = (
  id,
  name,
  successCallback,
  params = defaultSearchParams
) => dispatch =>
  deleteSkill(id).then(() => {
    successCallback();
    dispatch(fetchAndSetResumeEntityData(SKILL_ID, params));
  });

export const fetchAndSetResumeEntityData = (
  id,
  params = defaultSearchParams,
  filters = {},
  data,
  callback = () => {}
) => dispatch => {
  dispatch(clearResumeEntityData());
  let Data = [];
  if (id === EMPLOYMENT_HISTORY_ID) {
    Data = data.EmploymentHistory;
  } else if (id === LICENSES_AND_CETIFICATIONS_ID) {
    Data = data.LicensesAndCertifications;
  } else if (id === EDUCATION_ID) {
    Data = Constants.activePanelDefault.Education;
  } else if (id === SKILL_ID) {
    Data = Constants.activePanelDefault.Skill;
  }
  if (Data !== undefined) {
    dispatch(setResumeEntityData(Data === null ? [] : Data, Data.length, ""));
    dispatch(setLoader(false));
    callback();
  } else {
    dispatch(setResumeEntityData([], 0, ""));
  }
};

export const fetchAndSetEmploymentHistory = (
  id,
  employmentHistorydata = []
) => dispatch => {
  dispatch(clearEntityData());
  dispatch(clearEntityErrors());
  let Data = employmentHistorydata.filter(
    item => item.EmploymentHistoryId === id
  );
  let data = Data[0];
  if (Data[0].PreviosName !== "") {
    data[FIELDS.DIFFERENT_NAME] = true;
  } else {
    data[FIELDS.DIFFERENT_NAME] = false;
  }
  if (data[FIELDS.VERIFIED_WITH_PHONE_NUMBER] === null) {
    data[FIELDS.VERIFIED_WITH_PHONE_NUMBER] = "";
  }
  if (data[FIELDS.PHONE_NUMBER] === null) {
    data[FIELDS.PHONE_NUMBER] = "";
  }
  if (data[FIELDS.HOURLY_RATE_INPUT] === null) {
    data[FIELDS.HOURLY_RATE_INPUT] = "";
  }
  if (data[FIELDS.SUPERVISOR_PHONE_NUMBER] === null) {
    data[FIELDS.SUPERVISOR_PHONE_NUMBER] = "";
  }

  dispatch(editEntityData(data));
  dispatch(setLoader(false));
};

export const fetchAndSetLicensesCertification = (
  id,
  licensesAndCertificationData = []
) => dispatch => {
  dispatch(clearEntityData());
  dispatch(clearEntityErrors());
  let Data = licensesAndCertificationData.filter(item => item.Id === id);
  let data = Data[0];
  if (data[FIELDS.BASIC_INFO_NUMBER_ID] === null) {
    data[FIELDS.BASIC_INFO_NUMBER_ID] = "";
  }

  if (data[FIELDS.VERIFIED_WITH_PHONE_NUMBER] === null) {
    data[FIELDS.VERIFIED_WITH_PHONE_NUMBER] = "";
  }

  if (
    data[FIELDS.BASIC_INFO_TYPE_Id] !== null ||
    data[FIELDS.BASIC_INFO_TYPE_Id] !== 0
  ) {
    dispatch(setTypeNameDropdown(data[FIELDS.BASIC_INFO_TYPE_Id]));
  }

  dispatch(editEntityData(data));
  dispatch(setLoader(false));
};

export const fetchAndSetEducation = id => dispatch => {
  dispatch(clearEntityData());
  dispatch(clearEntityErrors());
  let Data = Constants.activePanelDefault.Education.filter(
    item => item.EducationId === id
  );
  let data = Data[0];
  if (data.Majors === null) {
    data.Majors = [];
    data.Majors.push({
      Name: ""
    });
  } else if (data.Minors === null) {
    data.Minors = [];
    data.Minors.push({
      Name: ""
    });
  }
  if (data[FIELDS.VERIFIED_WITH_PHONE_NUMBER] === null) {
    data[FIELDS.VERIFIED_WITH_PHONE_NUMBER] = "";
  }
  dispatch(editEntityData(data));
  dispatch(setLoader(false));
};

export const fetchAndSetSkills = id => dispatch => {
  dispatch(clearEntityData());
  dispatch(clearEntityErrors());
  let Data = Constants.activePanelDefault.Skill.filter(item => item.Id === id);
  let data = Data[0];

  dispatch(editEntityData(data));
  dispatch(setLoader(false));
};

export const saveEmploymentHistoryRecordAndReload = (
  data,
  employeeName,
  callback
) => dispatch => {
  let errors = validateEmploymentHistorySetup(data);
  if (Object.keys(errors).length > 0) {
    dispatch(editEntityErrors(errors));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    dispatch(setLoader(false));
    return;
  } else {
    let toasterMessage = "";
    return saveEmploymentHistoryData(data).then(res => {
      const { Data, ErrorCount } = res.data;
      if (ErrorCount < 0) {
        if (Data[0] === 1) {
          toasterMessage = `${employeeName} Employment History has been successfully added and verified.`;
        } else if (Data[0] === 2) {
          toasterMessage = `${employeeName} Employment History has been successfully added.`;
        } else if (Data[0] === 3) {
          toasterMessage = `${employeeName} Employment History has been successfully verified.`;
        }
        callback(toasterMessage);
      }
    });
  }
};

export const updateEmploymentHistoryRecordAndReload = (
  id,
  data,
  employeeName,
  callback
) => dispatch => {
  let errors = validateEmploymentHistorySetup(data);
  if (Object.keys(errors).length > 0) {
    dispatch(editEntityErrors(errors));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    dispatch(setLoader(false));
    return;
  } else {
    let toasterMessage = "";
    return updateEmploymentHistoryRecord(id, data).then(res => {
      const { Data, ErrorCount } = res.data;
      if (ErrorCount < 0) {
        if (Data[0] === 1) {
          toasterMessage = `${employeeName} Employment History has been successfully updated and verified.`;
        } else if (Data[0] === 2) {
          toasterMessage = `${employeeName} Employment History has been successfully updated.`;
        } else if (Data[0] === 3) {
          toasterMessage = `${employeeName} Employment History has been successfully verified.`;
        }
        callback(toasterMessage);
      }
    });
  }
};

const validateEmploymentHistorySetup = data => {
  let errors = {};
  if (!data[FIELDS.COMPANY_NAME]) {
    errors[FIELDS.COMPANY_NAME] = "This is a required field.";
  } else {
    let value = data[FIELDS.COMPANY_NAME].trim();
    if (!value) {
      errors[FIELDS.COMPANY_NAME] = "This is a required field.";
    }
  }
  if (!data[FIELDS.CITY]) {
    errors[FIELDS.CITY] = "This is a required field.";
  } else {
    let value = data[FIELDS.CITY].trim();
    if (!value) {
      errors[FIELDS.CITY] = "This is a required field.";
    }
  }

  if (!data[FIELDS.STATE]) {
    errors[FIELDS.STATE] = "This is a required field.";
  }

  if (!data[FIELDS.POSITION]) {
    errors[FIELDS.POSITION] = "This is a required field.";
  }

  if (
    data[FIELDS.EMPLOYMENT_FORM_MONTH] &&
    !data[FIELDS.EMPLOYMENT_FROM_YEAR]
  ) {
    errors[FIELDS.EMPLOYMENT_FROM_YEAR] = "This is a required field.";
  }
  if (!data[FIELDS.EMPLOYMENT_FROM_YEAR]) {
    errors[FIELDS.EMPLOYMENT_FROM_YEAR] = "This is a required field.";
  }
  if (data[FIELDS.EMPLOYMENT_TO_MONTH] && !data[FIELDS.EMPLOYMENT_TO_YEAR]) {
    errors[FIELDS.EMPLOYMENT_TO_YEAR] = "This is a required field.";
  }
  if (!data[FIELDS.EMPLOYMENT_TO_YEAR]) {
    errors[FIELDS.EMPLOYMENT_TO_YEAR] = "This is a required field.";
  }

  if (!data[FIELDS.IS_VERIFIED]) {
    errors[FIELDS.IS_VERIFIED] = "This is a required field.";
  }

  return errors;
};

export const saveLicensCertificationRecordAndReload = (
  data,
  employeeName,
  callback
) => dispatch => {
  let errors = validateLicenseCertificationSetup(data);
  if (Object.keys(errors).length > 0) {
    dispatch(editEntityErrors(errors));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    dispatch(setLoader(false));
    return;
  } else {
    let toasterMessage = "";
    let type = data[FIELDS.BASIC_INFO_TYPE_Name];
    return saveLicensesCertificationData(data).then(res => {
      const { Data, ErrorCount } = res.data;
      if (ErrorCount < 0) {
        if (Data[0] === 1) {
          toasterMessage = `${employeeName} ${type} has been successfully added and verified.`;
        } else if (Data[0] === 2) {
          toasterMessage = `${employeeName} ${type} has been successfully added.`;
        } else if (Data[0] === 3) {
          toasterMessage = `${employeeName} ${type} has been successfully verified.`;
        }
        callback(toasterMessage);
      }
    });
  }
};

export const updateLicensCertificationRecordAndReload = (
  id,
  data,
  employeeName,
  callback
) => dispatch => {
  let errors = validateLicenseCertificationSetup(data);
  if (Object.keys(errors).length > 0) {
    dispatch(editEntityErrors(errors));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    dispatch(setLoader(false));
    return;
  } else {
    let toasterMessage = "";
    let type = data[FIELDS.BASIC_INFO_TYPE_Name];
    return updateLicensesAndCertificationRecord(id, data).then(res => {
      const { Data, ErrorCount } = res.data;
      if (ErrorCount < 0) {
        if (Data[0] === 1) {
          toasterMessage = `${employeeName} ${type} has been successfully updated and verified.`;
        } else if (Data[0] === 2) {
          toasterMessage = `${employeeName} ${type} has been successfully updated.`;
        } else if (Data[0] === 3) {
          toasterMessage = `${employeeName} ${type} has been successfully verified.`;
        }
        callback(toasterMessage);
      }
    });
  }
};

const validateLicenseCertificationSetup = data => {
  let errors = {};
  if (!data[FIELDS.BASIC_INFO_TYPE_Id]) {
    errors[FIELDS.BASIC_INFO_TYPE_Id] = "This is a required field.";
  }

  if (!data[FIELDS.BASIC_INFO_ID]) {
    errors[FIELDS.BASIC_INFO_ID] = "This is a required field.";
  } else if (
    data[FIELDS.BASIC_INFO_NAME] === "Other" &&
    !data[FIELDS.BASIC_INFO_NAME_OTHER]
  ) {
    errors[FIELDS.BASIC_INFO_ID] = "This is a required field.";
  }

  if (!data[FIELDS.BASIC_INFO_EXPIRATION_DATE]) {
    errors[FIELDS.BASIC_INFO_EXPIRATION_DATE] = "This is a required field.";
  }

  if (!data[FIELDS.IS_VERIFIED]) {
    errors[FIELDS.IS_VERIFIED] = "This is a required field.";
  }

  return errors;
};

export const saveEducationRecordAndReload = (
  data,
  employeeName,
  callback
) => dispatch => {
  let errors = validateEducationSetup(data);
  if (Object.keys(errors).length > 0) {
    dispatch(editEntityErrors(errors));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    dispatch(setLoader(false));
    return;
  } else {
    let toasterMessage = "";
    return saveEducationData(data).then(res => {
      const { Data, ErrorCount } = res.data;
      if (ErrorCount < 0) {
        if (Data[0] === 1) {
          toasterMessage = `${employeeName} Education has been successfully added and verified.`;
        } else if (Data[0] === 2) {
          toasterMessage = `${employeeName} Education has been successfully added.`;
        } else if (Data[0] === 3) {
          toasterMessage = `${employeeName} Education has been successfully verified.`;
        }
        callback(toasterMessage);
      }
    });
  }
};

export const updateEducationRecordAndReload = (
  id,
  data,
  employeeName,
  callback
) => dispatch => {
  let errors = validateEducationSetup(data);
  if (Object.keys(errors).length > 0) {
    dispatch(editEntityErrors(errors));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    dispatch(setLoader(false));
    return;
  } else {
    let toasterMessage = "";
    return updateEducationRecord(id, data).then(res => {
      const { Data, ErrorCount } = res.data;
      if (ErrorCount < 0) {
        if (Data[0] === 1) {
          toasterMessage = `${employeeName} Education has been successfully updated and verified.`;
        } else if (Data[0] === 2) {
          toasterMessage = `${employeeName} Education has been successfully updated.`;
        } else if (Data[0] === 3) {
          toasterMessage = `${employeeName} Education has been successfully verified.`;
        }
        callback(toasterMessage);
      }
    });
  }
};

const validateEducationSetup = data => {
  let errors = {};
  if (!data[FIELDS.SCHOOL_INFO_NAME]) {
    errors[FIELDS.SCHOOL_INFO_NAME] = "This is a required field.";
  } else {
    let value = data[FIELDS.SCHOOL_INFO_NAME].trim();
    if (!value) {
      errors[FIELDS.SCHOOL_INFO_NAME] = "This is a required field.";
    }
  }
  if (!data[FIELDS.SCHOOL_INFO_CITY]) {
    errors[FIELDS.SCHOOL_INFO_CITY] = "This is a required field.";
  } else {
    let value = data[FIELDS.SCHOOL_INFO_CITY].trim();
    if (!value) {
      errors[FIELDS.SCHOOL_INFO_CITY] = "This is a required field.";
    }
  }

  if (!data[FIELDS.SCHOOL_INFO_STATE_ID]) {
    errors[FIELDS.SCHOOL_INFO_STATE_ID] = "This is a required field.";
  }

  if (!data[FIELDS.START_YEAR]) {
    errors[FIELDS.START_YEAR] = "This is a required field.";
  }

  if (data[FIELDS.START_MONTH] && !data[FIELDS.START_YEAR]) {
    errors[FIELDS.START_YEAR] = "This is a required field.";
  }

  if (data[FIELDS.END_DATE_MONTH] && !data[FIELDS.END_DATE_YEAR]) {
    errors[FIELDS.END_DATE_YEAR] = "This is a required field.";
  }

  if (
    (data[FIELDS.CURRENT_GPA] && !data[FIELDS.CURRENT_SCALE]) ||
    (!data[FIELDS.CURRENT_GPA] && data[FIELDS.CURRENT_SCALE])
  ) {
    errors[FIELDS.CURRENT_GPA] = "This is a required field.";
  }

  if (!data[FIELDS.GRADUATED] && data[FIELDS.CURRENTLY_ENROLLED]) {
    if (
      data[FIELDS.EXPECTED_GRADUATION_MONTH] &&
      !data[FIELDS.EXPECTED_GRADUATION_YEAR]
    ) {
      errors[FIELDS.EXPECTED_GRADUATION_YEAR] = "This is a required field.";
    }
    if (!data[FIELDS.EXPECTED_GRADUATION_YEAR]) {
      errors[FIELDS.EXPECTED_GRADUATION_YEAR] = "This is a required field.";
    }
  } else if (data[FIELDS.GRADUATED]) {
    if (data[FIELDS.GRADUATION_MONTH] && !data[FIELDS.GRADUATION_YEAR]) {
      errors[FIELDS.GRADUATION_YEAR] = "This is a required field.";
    }
    if (!data[FIELDS.GRADUATION_YEAR]) {
      errors[FIELDS.GRADUATION_YEAR] = "This is a required field.";
    }
  }

  if (!data[FIELDS.DEGREE]) {
    errors[FIELDS.DEGREE] = "This is a required field.";
  }

  if (!data[FIELDS.MAJORS]) {
    errors[FIELDS.MAJORS] = "This is a required field.";
  } else {
    let value = data[FIELDS.MAJORS].filter(item => item.Name.trim() !== "");
    if (value.length === 0 || !value) {
      errors[FIELDS.MAJORS] = "This is a required field.";
    }
  }

  if (!data[FIELDS.IS_VERIFIED]) {
    errors[FIELDS.IS_VERIFIED] = "This is a required field.";
  }

  return errors;
};

export const saveSkillsRecordAndReload = (
  id,
  data,
  employeeName,
  callback
) => dispatch => {
  let errors = validateSkillsSetup(data);
  if (Object.keys(errors).length > 0) {
    dispatch(editEntityErrors(errors));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    dispatch(setLoader(false));
    return;
  } else {
    let toasterMessage = "";
    let skillName = data[FIELDS.SKILL_NAME];
    return saveSkillsData(data).then(res => {
      const { Data, ErrorCount } = res.data;
      if (ErrorCount < 0) {
        if (Data[0] === true) {
          toasterMessage = `${employeeName} Skill ${skillName} has been successfully added.`;
        }
        callback(toasterMessage);
      }
    });
  }
};

export const updateSkillsRecordAndReload = (
  id,
  data,
  employeeName,
  callback
) => dispatch => {
  let errors = validateSkillsSetup(data);
  if (Object.keys(errors).length > 0) {
    dispatch(editEntityErrors(errors));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    dispatch(setLoader(false));
    return;
  } else {
    let toasterMessage = "";
    let skillName = data[FIELDS.SKILL_NAME];
    return updateSkillRecord(data).then(res => {
      const { Data, ErrorCount } = res.data;
      if (ErrorCount < 0) {
        if (Data[0] === true) {
          toasterMessage = `${employeeName} Skill ${skillName} has been successfully updated.`;
        }
        callback(toasterMessage);
      }
    });
  }
};

const validateSkillsSetup = data => {
  let errors = {};

  if (!data[FIELDS.SKILL_NAME]) {
    errors[FIELDS.SKILL_NAME] = "This is a required field.";
  }

  if (!data[FIELDS.SKILL_LEVEL]) {
    errors[FIELDS.SKILL_LEVEL] = "This is a required field.";
  }

  if (!data[FIELDS.YEARS_EXPERIENCE]) {
    errors[FIELDS.YEARS_EXPERIENCE] = "This is a required field.";
  }

  return errors;
};

export const setTypeNameDropdown = (
  id,
  callback = () => {},
  name = FILTERS.LicensesAndCertificationNameList
) => dispatch => {
  return getLicensesCertificationNameList().then(res => {
    const { Data } = res.data;
    let dispatchData = [];
    let filterData = Data.filter(item => item.TypeId === id);
    filterData.forEach(item =>
      dispatchData.push({
        id: item.Id,
        value: item.Id,
        label: item.Name
      })
    );
    dispatch(setFilterOptions(name, dispatchData));
    callback(filterData);
  });
};

export const fetchAndSetEmploymentHistoryCompanyList = (
  selectAll = false,
  name = FILTERS.EmploymentHistoryCompanyFilter
) => dispatch => {
  let Data = [
    {
      id: 1,
      value: 1,
      label: "Bob's Burgers"
    },
    {
      id: 2,
      value: 2,
      label: "Creativ Space"
    },
    {
      id: 3,
      value: 3,
      label: "Apple"
    },
    {
      id: 4,
      value: 4,
      label: "McKesson Corporation"
    }
  ];
  dispatch(setFilterOptions(name, Data));
  if (selectAll) {
    dispatch(selectAllFilterOptions(name, true));
  }
  // return getEmploymentHistoryCompanyList().then(res => {
  //   const { Data } = res.data;
  //   let dispatchData = [];
  //   Data.forEach(item =>
  //     dispatchData.push({
  //       id: item.CompanyId,
  //       value: item.CompanyId,
  //       label: item.CompanyName
  //     })
  //   );
  //   dispatch(setFilterOptions(name, dispatchData));
  //   if (selectAll) {
  //     dispatch(selectAllFilterOptions(name, true));
  //   }
  // });
};

export const fetchAndSetEmploymentHistoryPositionList = (
  selectAll = false,
  name = FILTERS.EmploymentHistoryPositionFilter
) => dispatch => {
  let Data = [
    {
      id: 1,
      value: 1,
      label: "Graphic Designer"
    },
    {
      id: 2,
      value: 2,
      label: "Certification"
    },
    {
      id: 3,
      value: 3,
      label: "Junior Designer"
    },
    {
      id: 4,
      value: 4,
      label: "Toolmaker"
    },
    {
      id: 5,
      value: 5,
      label: "Designer"
    }
  ];
  dispatch(setFilterOptions(name, Data));
  if (selectAll) {
    dispatch(selectAllFilterOptions(name, true));
  }
  // return getEmploymentHistoryPositionList().then(res => {
  //   const { Data } = res.data;
  //   let dispatchData = [];
  //   Data.forEach(item =>
  //     dispatchData.push({
  //       id: item.PositionId,
  //       value: item.PositionId,
  //       label: item.Position
  //     })
  //   );
  //   dispatch(setFilterOptions(name, dispatchData));
  //   if (selectAll) {
  //     dispatch(selectAllFilterOptions(name, true));
  //   }
  // });
};

export const fetchAndSetLicensesCerificationNameList = (
  selectAll = false,
  name = FILTERS.LicensesCertificationNameFilter
) => dispatch => {
  return getLicensesCertificationNameList().then(res => {
    const { Data } = res.data;
    let dispatchData = [];
    Data.forEach(item =>
      dispatchData.push({
        id: item.Id,
        value: item.Id,
        label: item.Name
      })
    );
    dispatch(setFilterOptions(name, dispatchData));
    if (selectAll) {
      dispatch(selectAllFilterOptions(name, true));
    }
  });
};

export const fetchAndSetLicensesCerificationTypeList = (
  selectAll = false,
  name = FILTERS.LicensesCertificationTypeFilter
) => dispatch => {
  return getLicensesCertificationTypeList().then(res => {
    const { Data } = res.data;
    let dispatchData = [];
    Data.forEach(item =>
      dispatchData.push({
        id: item.Id,
        value: item.Id,
        label: item.Name
      })
    );
    dispatch(setFilterOptions(name, dispatchData));
    if (selectAll) {
      dispatch(selectAllFilterOptions(name, true));
    }
  });
};

export const fetchAndSetEducationDegreeList = (
  selectAll = false,
  name = FILTERS.EducationDegreeFilter
) => dispatch => {
  let Data = [
    {
      id: 1,
      value: 1,
      label: "High School / GED"
    },
    {
      id: 2,
      value: 2,
      label: "Technical Training"
    },
    {
      id: 3,
      value: 3,
      label: "Associate Degree"
    },
    {
      id: 4,
      value: 4,
      label: "Bachelor's Degree"
    },
    {
      id: 5,
      value: 5,
      label: "Master's Degree"
    },
    {
      id: 6,
      value: 6,
      label: "Doctoral Degree"
    }
  ];
  dispatch(setFilterOptions(name, Data));
  if (selectAll) {
    dispatch(selectAllFilterOptions(name, true));
  }
  // return getEducationDegreeList().then(res => {
  //   const { Data } = res.data;
  //   let dispatchData = [];
  //   Data.forEach(item =>
  //     dispatchData.push({
  //       id: item.DegreeId,
  //       value: item.DegreeId,
  //       label: item.Degree
  //     })
  //   );
  //   dispatch(setFilterOptions(name, dispatchData));
  //   if (selectAll) {
  //     dispatch(selectAllFilterOptions(name, true));
  //   }
  // });
};

export const fetchAndSetEducationSchoolList = (
  selectAll = false,
  name = FILTERS.EducationSchoolFilter
) => dispatch => {
  let Data = [
    {
      id: 1,
      value: 1,
      label: "Louisiana State Univercity"
    },
    {
      id: 2,
      value: 2,
      label: "SCAD"
    },
    {
      id: 3,
      value: 3,
      label: "DeSoto Parish School Board"
    },
    {
      id: 4,
      value: 4,
      label: "Evangeline Parish School Board"
    }
  ];
  dispatch(setFilterOptions(name, Data));
  if (selectAll) {
    dispatch(selectAllFilterOptions(name, true));
  }
  // return getEducationSchoolList().then(res => {
  //   const { Data } = res.data;
  //   let dispatchData = [];
  //   Data.forEach(item =>
  //     dispatchData.push({
  //       id: item.SchoolId,
  //       value: item.SchoolId,
  //       label: item.School
  //     })
  //   );
  //   dispatch(setFilterOptions(name, dispatchData));
  //   if (selectAll) {
  //     dispatch(selectAllFilterOptions(name, true));
  //   }
  // });
};

export const fetchAndSetSkillsSkillList = (
  selectAll = false,
  name = FILTERS.SkillsSkillFilter
) => dispatch => {
  let Data = [
    {
      id: 1,
      value: 1,
      label: "Data Entry Experiance"
    },
    {
      id: 2,
      value: 2,
      label: "Phone Skills"
    },
    {
      id: 3,
      value: 3,
      label: "Other"
    }
  ];
  dispatch(setFilterOptions(name, Data));
  if (selectAll) {
    dispatch(selectAllFilterOptions(name, true));
  }
  // return getSkillsSkillList().then(res => {
  //   const { Data } = res.data;
  //   let dispatchData = [];
  //   Data.forEach(item =>
  //     dispatchData.push({
  //       id: item.SkillId,
  //       value: item.SkillId,
  //       label: item.Skill
  //     })
  //   );
  //   dispatch(setFilterOptions(name, dispatchData));
  //   if (selectAll) {
  //     dispatch(selectAllFilterOptions(name, true));
  //   }
  // });
};

export const fetchAndSetSkillsSkillLevelList = (
  selectAll = false,
  name = FILTERS.SkillsSkillLevelFilter
) => dispatch => {
  let Data = [
    {
      id: 1,
      value: 1,
      label: "Beginner"
    },
    {
      id: 2,
      value: 2,
      label: "Intermediate"
    },
    {
      id: 3,
      value: 3,
      label: "Advanced"
    },
    {
      id: 4,
      value: 4,
      label: "Expert"
    }
  ];
  dispatch(setFilterOptions(name, Data));
  if (selectAll) {
    dispatch(selectAllFilterOptions(name, true));
  }
};

export const fetchAndSetSkillsYearOfExperienceList = (
  selectAll = false,
  name = FILTERS.SkillsYearOfExperienceFilter
) => dispatch => {
  let Data = [];
  for (let i = 1; i <= 20; i++) {
    Data.push({
      id: i,
      value: i,
      label: `${i}`
    });
  }
  dispatch(setFilterOptions(name, Data));
  if (selectAll) {
    dispatch(selectAllFilterOptions(name, true));
  }
};

export const createNewSkillAndReload = (data, callback) => dispatch =>
  createNewSkill(data).then(res => {
    const { Data = [] } = res.data;
    if (Data[0].Status === "success") {
      dispatch(fetchAndSetSkillsSkillList());
      callback();
    }
  });
