import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Badge } from "react-bootstrap";
import moment from "moment";

const tooltip_thrash_icon = <Tooltip id="tooltip_thrash_icon">Delete</Tooltip>;
const tooltip_edit_icon = <Tooltip id="tooltip_edit_icon">Edit</Tooltip>;

const reformatDate = dateStr => {
  let currentDate = moment(new Date());
  let expirationDate = moment(new Date(dateStr));
  let dateDiff = expirationDate.diff(currentDate, "months", true);
  if (dateDiff <= 1 && dateDiff > 0) {
    return (
      <Badge className="expiration-date-badge">
        Expires on {moment(new Date(dateStr + "+00:00")).format("MMM DD, YYYY")}
      </Badge>
    );
  } else if (dateDiff < 0) {
    return (
      <Badge className="expired-date-badge">
        Expired on {moment(new Date(dateStr + "+00:00")).format("MMM DD, YYYY")}
      </Badge>
    );
  } else {
    return (
      <div>{moment(new Date(dateStr + "+00:00")).format("MMM DD, YYYY")}</div>
    );
  }
};
class LicensesCertificationList extends Component {
  componentDidMount() {
    const { tabData, setToaster, employeeName } = this.props;
    let count = 0;
    let message = "";
    tabData.forEach(item => {
      let currentDate = moment(new Date());
      let expirationDate = moment(new Date(item.ExpirationDate));
      let dateDiff = expirationDate.diff(currentDate, "months", true);
      if (dateDiff <= 1 && dateDiff > 0) {
        count = count + 1;
      }
    });
    if (count > 1) {
      message = `${employeeName} has ${count} licenses that are about to expire.`;
      setToaster(message, "warning");
    } else if (count !== 0) {
      message = `${employeeName} has ${count} license that is about to expire.`;
      setToaster(message, "warning");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { tabData, setToaster, employeeName } = this.props;
    if (prevProps.tabData !== tabData) {
      let count = 0;
      let message = "";
      tabData.forEach(item => {
        let currentDate = moment(new Date());
        let expirationDate = moment(new Date(item.ExpirationDate));
        let dateDiff = expirationDate.diff(currentDate, "months", true);
        if (dateDiff <= 1 && dateDiff > 0) {
          count = count + 1;
        }
      });
      if (count > 1) {
        message = `${employeeName} has ${count} licenses that are about to expire.`;
        setToaster(message, "warning");
      } else if (count !== 0) {
        message = `${employeeName} has ${count} license that is about to expire.`;
        setToaster(message, "warning");
      }
    }
  }

  componentWillUnmount() {
    this.props.toggleToaster();
  }

  render() {
    const { item, onEditClick, onDeleteClick } = this.props;
    return (
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 table-cell">
          {item.Name}
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 table-cell">
          {item.TypeName}
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12  col-xs-12">
          {reformatDate(item.ExpirationDate)}
        </div>
        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
          <div className="tab-container-list-icon-section pull-right">
            <div className="tab-container-list-edit-section">
              <OverlayTrigger placement="top" overlay={tooltip_edit_icon}>
                <a
                  href="."
                  onClick={e => {
                    e.preventDefault();
                    onEditClick && onEditClick(item.Id);
                  }}
                >
                  <i
                    id={"edit-icon-" + item.Id}
                    className="tab-container-list-edit-icon fa fa-pencil"
                  />
                </a>
              </OverlayTrigger>
            </div>
            <div className="tab-container-list-delete-section">
              <OverlayTrigger placement="top" overlay={tooltip_thrash_icon}>
                <a
                  href="."
                  onClick={e => {
                    e.preventDefault();
                    onDeleteClick && onDeleteClick(item.Id, item.Name);
                  }}
                >
                  <i
                    id={"delete-icon-" + item.Id}
                    className="tab-container-list-delete-icon fa fa-trash-o"
                  />
                </a>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LicensesCertificationList;

LicensesCertificationList.propTypes = {
  item: PropTypes.object
};

LicensesCertificationList.defaultProps = {
  item: {
    SectionItemDescription: "",
    Verified: false,
    SectionItemTitle: "",
    SectionItemDescriptionLine: ""
  }
};
