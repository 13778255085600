import React from "react";
import RaisedRadioButton from "./RaisedRadioButton";

const InputWithRaisedRadioButtons = props => (
  <div>
    <div className={props.className} style={{ marginRight: "15px" }}>
      <input
        id="disasterLocationPhone"
        type="text"
        name="disasterLocationPhone"
        className="form-control"
        placeholder="303-300-3899"
        value={props.inputValue || ""}
        onChange={e => props.onChange && props.onChange(e.target)}
        maxLength={props.maxLength}
        pattern={props.pattern}
      />
      {props.error &&
        props.required && (
          <div
            style={{ marginTop: "5px", fontSize: "15px" }}
            className="has-error"
          >
            {props.error}
          </div>
        )}
    </div>
    <RaisedRadioButton inputName="disasterLocationPhoneType" {...props} />
  </div>
);
export default InputWithRaisedRadioButtons;
