import * as types from "../../actions/systemlevel/index";

export default function(state = [], action) {
  switch (action.type) {
    case types.FETCH_ENTERPRISE_PAYEE_DROPDOWN_LIST:
      return action.payload.data;
    default:
      return state;
  }
}
