/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const tooltip_thrash_icon = (
  <Tooltip id="tooltip_thrash_icon">Delete/Inactive</Tooltip>
);
const tooltip_calender_icon = (
  <Tooltip id="tooltip_thrash_icon">View Payroll Calender</Tooltip>
);
const tooltip_edit_icon = <Tooltip id="tooltip_edit_icon">Edit</Tooltip>;

const tooltip_standard_category_column = (
  <Tooltip id="tooltip_standard_category_column">Frequecy</Tooltip>
);
const tooltip_fringe_category_column = (
  <Tooltip id="tooltip_fringe_category_column">Frequecy</Tooltip>
);
const tooltip_active_status_column = (
  <Tooltip id="tooltip_active_status_column">Status</Tooltip>
);

const tooltip_inactive_status_column = (
  <Tooltip id="tooltip_inactive_status_column">Status</Tooltip>
);

function getCategoryTooltip(category) {
  switch (category) {
    case "Weekly":
      return tooltip_standard_category_column;
    case "Semi-Monthly":
      return tooltip_fringe_category_column;
    default:
      return tooltip_fringe_category_column;
  }
}
function getStatusTooltip(status) {
  switch (status) {
    case "Active":
      return tooltip_active_status_column;
    case "Inactive":
      return tooltip_inactive_status_column;
    default:
      return null;
  }
}

const PayrollScheduleItem = ({
  item,
  onEditClick,
  onDeleteClick,
  onReactivateClick,
  onItemClick,
  handleCalender
}) => (
  <div
    className="table-row col-lg-12 col-md-12 col-sm-12 col-xs-12"
    style={{
      lineHeight:
        item.status === "inactive" && window.innerWidth > 991 ? "1.5" : null
    }}
  >
    <div
      className={
        `col-lg-10 col-md-10 col-sm-9` + item.status === "inactive"
          ? "col-xs-12"
          : "col-xs-9"
      }
    >
      <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 name-column table-cell">
        <a
          onClick={
            item.status === "Active" || item.status === "Inactive"
              ? onItemClick
              : () => {}
          }
          id={"entity-name-" + item.id}
        >
          {item.name}
        </a>
      </div>
      <div className="category-container col-lg-5 col-md-5 col-sm-12 col-xs-12 table-cell">
        <span className="hidden-lg hidden-md field-header">Category : </span>
        <OverlayTrigger
          placement="top"
          overlay={getCategoryTooltip(item.frequency)}
        >
          <span
            id={"entity-category-" + item.frequencyId}
            className="field-value"
          >
            {item.frequency}
          </span>
        </OverlayTrigger>
      </div>
      <div className="status-container col-lg-2 col-md-2 col-sm-12 col-xs-12">
        <span className="hidden-lg hidden-md field-header">Status : </span>
        <OverlayTrigger placement="top" overlay={getStatusTooltip(item.status)}>
          <span
            id={"entity-status-" + item.statusId}
            className={`field-value status-tag ` + item.status.toLowerCase()}
          >
            {item.status}
          </span>
        </OverlayTrigger>
      </div>
      {item.status.toLowerCase() === "inactive" ? (
        <div className="col-xs-12 col-sm-12 reactivate-mobile-button hidden-sm hidden-md hidden-lg">
          <button
            id={"entity-reactivate-" + item.id}
            className="outline-button-right"
            onClick={onReactivateClick}
          >
            REACTIVATE
          </button>
        </div>
      ) : null}
    </div>
    <div className="col-lg-2 col-md-2 col-sm-3 col-xs-3 list-page-grid-table-cell-actions">
      {item.status.toLowerCase() === "inactive" ? (
        <button
          id={"entity-reactivate-" + item.id}
          className="special-outline-button hidden-xs pull-right"
          onClick={onReactivateClick}
        >
          REACTIVATE
        </button>
      ) : (
        <div>
          <div className="list-page-delete-section">
            <OverlayTrigger placement="top" overlay={tooltip_thrash_icon}>
              <a
                href="."
                onClick={e => {
                  e.preventDefault();
                  if (!item.isPayrollScheduleStarted) {
                    onDeleteClick && onDeleteClick();
                  }
                }}
              >
                <i
                  id={"entity-delete-" + item.id}
                  className={
                    item.isPayrollScheduleStarted
                      ? "list-page-disable-delete-icon fa fa-trash-o"
                      : "list-page-delete-icon fa fa-trash-o"
                  }
                />
              </a>
            </OverlayTrigger>
          </div>
          <div className="list-page-edit-section">
            <OverlayTrigger placement="top" overlay={tooltip_edit_icon}>
              <a
                href="."
                onClick={e => {
                  e.preventDefault();
                  if (!item.isPayrollScheduleStarted) {
                    onEditClick && onEditClick();
                  }
                }}
              >
                <i
                  id={"entity-edit-" + item.id}
                  className={
                    item.isPayrollScheduleStarted
                      ? "list-page-disable-edit-icon fa fa-pencil"
                      : "list-page-edit-icon fa fa-pencil"
                  }
                />
              </a>
            </OverlayTrigger>
          </div>
          <div className="list-page-calender-section">
            <OverlayTrigger placement="top" overlay={tooltip_calender_icon}>
              <a
                href="."
                onClick={e => {
                  e.preventDefault();
                  handleCalender && handleCalender();
                }}
              >
                <i
                  id={"entity-calender-" + item.id}
                  className="list-page-calender-icon fa fa-calendar"
                />
              </a>
            </OverlayTrigger>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default PayrollScheduleItem;
