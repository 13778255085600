import axios from "axios";
import * as types from "../index";
import * as common from "../../../../../app/common/index";

export function getZipCode(address) {
  const url = `${common.WEB_API_URL}/v1/system/zipcode/${address}`;
  const request = axios.get(url);
  return {
    type: types.ZIP_CODE,
    payload: request
  };
}
