export const PAYROLL_UTILITIES = "Utilities";

export const pageData = {
  title: PAYROLL_UTILITIES,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco. "
};

export const utilityItems = {
  GLOBAL: {
    id: "global",
    icon: "fa fa-calendar",
    items: [
      {
        id: "email-text-alert",
        title: "Email/Text Alerts",
        description:
          "Set up payroll related reminders and alerts including contact approver messages, payroll submit reminders and more.",
        route: "/email-text-alerts"
      },
      {
        id: "holiday-calendar",
        title: "Holiday Calendar",
        description: "Create and manage your company's holiday calendar.",
        route: "/payroll/utilities/holidaycalendar"
      }
    ]
  },
  "PAYROLL DASHBOARD": {
    id: "payroll-dashboard",
    icon: "fa fa-tachometer",
    items: [
      {
        id: "payroll-schedule",
        title: "Payroll Schedule",
        description:
          "Building a payroll schedule is the first step to processing payroll. Your Payroll schedule determines when you need to submit payroll to Netchex and how often your employees get paid.",
        route: "/PayrollSchedules"
      },
      {
        id: "payroll-checklist",
        title: "Payroll Checklist",
        description:
          "Customize the information you see in your payroll checklist on your payroll dashboard.",
        route: "/PayrollChecklistConfig"
      }
    ]
  },
  "IMPORTING TIME": {
    id: "importing-time",
    icon: "fa fa-clock-o",
    items: [
      {
        id: "time-import-mapping",
        title: "Time Import Mapping",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore"
      },
      {
        id: "T&A-group-configuration",
        title: "T&A Group Configuration",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore"
      },
      {
        id: "grid-configuration",
        title: "Grid Configuration",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore"
      }
    ]
  }
};
