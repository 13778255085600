export const HIRE_DATE =
  "Selecting override allows you to input a date in this field directly. Overriding this date means that your Job History will no longer be consistent with the date you are about to change. Netchex does not recommend overriding this information. If this date is incorrect, it can be changed by editing Job History directly. Deselecting override means that date will be derived from Job History and will not be editable.";
export const TERMINATION_DATE =
  "Selecting override allows you to input a date in this field directly. Overriding this date means that your Job History will no longer be consistent with the date you are about to change. Netchex does not recommend overriding this information. If this date is incorrect, it can be changed by editing Job History directly. Deselecting override means that date will be derived from Job History and will not be editable.";
export const ELIGIBLE_FOR_HIRE =
  "Selecting override allows you to input a date in this field directly. Overriding this date means that your Job History will no longer be consistent with the date you are about to change. Netchex does not recommend overriding this information. If this date is incorrect, it can be changed by editing Job History directly. Deselecting override means that date will be derived from Job History and will not be editable.";

export const LEAVE_DATE =
  "Selecting override allows you to input a date in this field directly. Overriding this date means that your Job History will no longer be consistent with the date you are about to change. Netchex does not recommend overriding this information. If this date is incorrect, it can be changed by editing Job History directly. Deselecting override means that date will be derived from Job History and will not be editable.";

export const OVERRIDE_LEAVE_DATE =
  "Selecting override allows you to input a date in this field directly. Overriding this date means that your Job History will no longer be consistent with the date you are about to change. Netchex does not recommend overriding this information. If this date is incorrect, it can be changed by editing Job History directly. Deselecting override means that date will be derived from Job History and will not be editable.";

export const INACTIVE_DATE =
  "Selecting override allows you to input a date in this field directly. Overriding this date means that your Job History will no longer be consistent with the date you are about to change. Netchex does not recommend overriding this information. If this date is incorrect, it can be changed by editing Job History directly. Deselecting override means that date will be derived from Job History and will not be editable.";

export const TRANSFERRED_DATE =
  "Selecting override allows you to input a date in this field directly. Overriding this date means that your Job History will no longer be consistent with the date you are about to change. Netchex does not recommend overriding this information. If this date is incorrect, it can be changed by editing Job History directly. Deselecting override means that date will be derived from Job History and will not be editable.";
export const RETIREMENT_DATE =
  "Selecting override allows you to input a date in this field directly. Overriding this date means that your Job History will no longer be consistent with the date you are about to change. Netchex does not recommend overriding this information. If this date is incorrect, it can be changed by editing Job History directly. Deselecting override means that date will be derived from Job History and will not be editable.";
export const DECEASED_DATE =
  " Selecting override allows you to input a date in this field directly. Overriding this date means that your Job History will no longer be consistent with the date you are about to change. Netchex does not recommend overriding this information. If this date is incorrect, it can be changed by editing Job History directly. Deselecting override means that date will be derived from Job History and will not be editable.";
