export const mapEventTypeData = e => ({
  value: e.Id,
  label: e.Name
});

export const mapUserData = u => ({
  value: u.UserId,
  label: u.UserDisplayName
});

export const mapFieldData = f => ({
  value: f.ActivityEntityFieldId,
  label: f.ActivityEntityFieldName
});
