import React from "react";
import { FormFieldGroup } from "../../../formSections";
import SampleImage from "../../../payroll-holiday-calendar/Images/NoHoliday";

const phoneNumberProps = {
  show: true,
  inputType: "numeric",
  inputId: "WorkPhoneNumber",
  inputName: "WorkPhoneNumber",
  label: "",
  required: true
};

const extensionProps = {
  show: true,
  inputType: "numeric",
  inputId: "WorkPhoneNumberExt",
  inputName: "WorkPhoneNumberExt",
  label: "",
  required: true
};

const WorkPhoneNumber = props => {
  const { data = {}, onChange } = props;
  return (
    <div className="contact-info">
      <div className="contact-info col-lg-3">
        <SampleImage width={45} height={45} />
        <div
          style={{ paddingLeft: "15px", fontSize: "15px", marginTop: "15px" }}
        >
          <div>Work Phone Number</div>
          <i style={{ color: "#999" }}>(Optional)</i>
        </div>
      </div>
      <FormFieldGroup
        {...phoneNumberProps}
        value={data[phoneNumberProps.inputName]}
        onChange={onChange}
        className={"work-ph-no-textbox"}
        placeholder={"504-888-0093"}
        format="###-###-####"
      />
      <FormFieldGroup
        {...extensionProps}
        value={data[extensionProps.inputName]}
        onChange={onChange}
        className={"work-ph-no-ext-textbox"}
        placeholder={"ext."}
        format="######"
      />
    </div>
  );
};

export default WorkPhoneNumber;
