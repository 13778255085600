import React, { Component } from "react";
import { PropTypes } from "prop-types";
import getInputComponent from "component-library/inputFields/getInputComponent";
import CustomRadioTab from "component-library/inputFields/CustomRadioTab";
import Toaster from "component-library/toasters";
import * as FIELDS from "../../utils/Constants";

const options = [
  { id: "perHour", value: FIELDS.HOURLY_RATE_PER_HOUR, label: "per Hour" },
  {
    id: "perYear",
    value: FIELDS.HOURLY_RATE_PER_YEAR,
    label: "per Year"
  }
];
class HourlyRate extends Component {
  state = { showTooltip: false };
  showTooltipHandler = () => {
    this.setState({
      showTooltip: !this.state.showTooltip
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        showTooltip: false
      });
    }
  }

  render() {
    const { props } = this;
    let wrapperClass;
    if (props.class === undefined) {
      wrapperClass = "nc-field-group clearfix";
    } else {
      wrapperClass = "nc-field-group " + props.class + " clearfix";
    }
    if (
      (props.value == null || props.value === "") &&
      props.required &&
      props.error
    ) {
      wrapperClass = wrapperClass + " has-error " + props.inputName;
    }

    let Component = getInputComponent("textWithSign");
    return (
      <div
        className={wrapperClass}
        style={{ clear: "both", display: "flex", alignItems: "center" }}
      >
        {props.Toaster &&
          props.Toaster.show && (
            <Toaster
              show={props.Toaster.show}
              alertClassName={props.Toaster.type}
              message={props.Toaster.content}
            />
          )}
        <div
          id={"Label" + props.inputName}
          style={{ paddingRight: "15px" }}
          className={"col-lg-3 col-md-3 col-sm-3 field-name clearfix"}
        >
          {FIELDS.HOURLY_RATE_LABEL}{" "}
          <i className="optional-field">(Optional) </i>
        </div>
        {props.inputType === "Label" ? (
          <div style={{ marginBottom: "15px" }}>{props.value}</div>
        ) : (
          <div
            className={"col-lg-9 col-md-9 col-sm-9"}
            style={{ clear: "both", display: "flex" }}
          >
            <Component
              {...props}
              onChange={props.onChange}
              onSelect={props.onChange}
              inputName={FIELDS.HOURLY_RATE_INPUT}
              className={"input-field col-xs-4"}
              value={props.data[FIELDS.HOURLY_RATE_INPUT]}
              id={FIELDS.HOURLY_RATE_INPUT}
              sign={"$"}
            />

            <CustomRadioTab
              options={options}
              onChange={props.onChange}
              name={FIELDS.SALARY_TYPE_ID}
              className={"input-field col-xs-8"}
              value={props.data[FIELDS.SALARY_TYPE_ID]}
              customMarginLeft={"20px"}
              childWidth={"70px"}
            />
          </div>
        )}
      </div>
    );
  }
}

export default HourlyRate;
HourlyRate.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string
};

HourlyRate.defaultProps = {
  descriptionStyle: "",
  hideTopdescription: false,
  colSpan: ""
};
