import React from "react";
import { PropTypes } from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Badge } from "react-bootstrap";

const tooltip_thrash_icon = <Tooltip id="tooltip_thrash_icon">Delete</Tooltip>;
const tooltip_edit_icon = <Tooltip id="tooltip_edit_icon">Edit</Tooltip>;

const getSkillsLevel = (skillLevelId, skillLevelName) => {
  if (skillLevelId === 4) {
    return <Badge className="expert-skill-badge">{skillLevelName}</Badge>;
  } else {
    return <Badge className="other-skill-badge">{skillLevelName}</Badge>;
  }
};

const SkillsList = props => {
  const { item, onEditClick, onDeleteClick } = props;

  return (
    <div
      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 table-cell">
        {item.Skill}
      </div>
      <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 table-cell">
        {getSkillsLevel(item.SkillLevelId, item.SkillLevelName)}
      </div>
      <div className="col-lg-3 col-md-3 col-sm-12  col-xs-12">
        {item.YearsExperience} years
      </div>
      <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
        <div className="tab-container-list-icon-section pull-right">
          <div className="tab-container-list-edit-section">
            <OverlayTrigger placement="top" overlay={tooltip_edit_icon}>
              <a
                href="."
                onClick={e => {
                  e.preventDefault();
                  onEditClick && onEditClick(item.Id);
                }}
              >
                <i
                  id={"edit-icon-" + item.Id}
                  className="tab-container-list-edit-icon fa fa-pencil"
                />
              </a>
            </OverlayTrigger>
          </div>
          <div className="tab-container-list-delete-section">
            <OverlayTrigger placement="top" overlay={tooltip_thrash_icon}>
              <a
                href="."
                onClick={e => {
                  e.preventDefault();
                  onDeleteClick && onDeleteClick(item.Id, item.Name);
                }}
              >
                <i
                  id={"delete-icon-" + item.Id}
                  className="tab-container-list-delete-icon fa fa-trash-o"
                />
              </a>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsList;

SkillsList.propTypes = {
  item: PropTypes.object
};

SkillsList.defaultProps = {
  item: {
    SectionItemDescription: "",
    Verified: false,
    SectionItemTitle: "",
    SectionItemDescriptionLine: ""
  }
};
