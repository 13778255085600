import EmployeeId from "./EmployeeId";
import PayrollSchedule from "./PayrollSchedule";
import Position from "./Position";
import PrimaryManager from "./PrimaryManager";
import SecondaryManager from "./SecondarManager";
import TimeGroup from "./TimeGroup";
import Location from "./Location";
import DivBusUnit from "./DivBusUnit";
import EmployeeStatusDate from "./EmployeeStatusDate";
import * as c from "./Constants";
import React from "react";
import PropTypes from "prop-types";
const HEIGHT = "25px";
const WIDTH = "25px";
const getSvg = id => {
  switch (id) {
    case c.EMPLOYEE_ID:
      return <EmployeeId height={HEIGHT} width={WIDTH} />;
    case c.PAYROLL_SCHEDULE:
      return <PayrollSchedule height={HEIGHT} width={WIDTH} />;
    case c.POSITION:
      return <Position height={HEIGHT} width={WIDTH} />;
    case c.PRIMARY_MANAGER:
      return <PrimaryManager height={HEIGHT} width={WIDTH} />;
    case c.SECONDARY_MANAGER:
      return <SecondaryManager height={HEIGHT} width={WIDTH} />;
    case c.TIMEGROUP:
      return <TimeGroup height={HEIGHT} width={WIDTH} />;
    case c.LOCATION:
      return <Location height={HEIGHT} width={WIDTH} />;
    case c.DIV_UNIT:
      return <DivBusUnit height={HEIGHT} width={WIDTH} />;
    case c.HIRE_DATE:
      return <EmployeeStatusDate height={HEIGHT} width={WIDTH} />;
    default:
      return null;
  }
};

export default getSvg;
getSvg.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
};
getSvg.defaultProps = {
  height: "25px",
  width: "25px"
};
