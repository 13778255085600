import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import classNames from "classnames/bind";
export default class Accordian extends Component {
  state = { open: this.props.defaultOpen || false };
  onClick = () => {
    this.setState({
      open: !this.state.open
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.HideShowAccordian !== this.props.HideShowAccordian) {
      this.setState({
        open: false
      });
    }
  }

  render() {
    const { props, state } = this;
    let title = state.open ? props.hidetitle : props.title;
    let chevronDefault = classNames(
      "fa",
      state.open ? "fa-angle-up" : "fa-angle-down"
    );
    let chevronLeft = classNames(
      "fa fa-angle-down",
      "arrow",
      state.open ? "arrow-active" : "arrow-inactive"
    );
    let chevron =
      props.chevronPosition === "left" ? chevronLeft : chevronDefault;
    return (
      <div className="nc-accordian" style={props.style}>
        <a
          id={props.id}
          className="primary-link accordian-title"
          onClick={this.onClick}
        >
          {props.chevronPosition === "left" && <i className={chevron} />}{" "}
          {props.hidetitle ? title : props.title}{" "}
          {props.chevronPosition !== "left" && <i className={chevron} />}
        </a>
        {props.additionalLink}
        <Collapse in={state.open}>{props.children}</Collapse>
      </div>
    );
  }
}
