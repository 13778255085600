import React, { Component } from "react";
import PayrollDashboardSummaryHeader from "./PayrollDashboardSummaryHeader";
import PayrollDashboardSummaryBanner from "./PayrollDashboardSummaryBanner";
import PayrollDashboardSummaryItems from "./PayrollDashboardSummaryItems";
export default class PayrollDashboardSummary extends Component {
  static defaultProps = {
    clearToast: () => {},
    tableData: {}
  };
  render() {
    const { data, children } = this.props;
    return (
      <div className="payroll-dash-summary-container">
        <PayrollDashboardSummaryHeader data={data} />
        <PayrollDashboardSummaryBanner data={data} />
        <PayrollDashboardSummaryItems data={data} />

        <div className="payroll-dash-summary-footer-section">{children}</div>
      </div>
    );
  }
}
