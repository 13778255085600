import { getEmployeeFilesList, deleteEmployeeFile } from ".";
import { setEmployeeFilesList } from "../reducers/employeeFilesList";
import { showToast } from "app/common/reducers/toast";
import { SUCCESS_TOAST, RED_TOAST } from "../EmployeeFilesConstants";

export const searchEmployeeFilesList = (
  entityId,
  callback = () => {}
) => dispatch => {
  getEmployeeFilesList(entityId).then(res => {
    const { Data, DataCount, NextUri, Type } = res.data;
    dispatch(
      setEmployeeFilesList(Data === null ? [] : Data, DataCount, NextUri, Type)
    );
    callback();
  });
};

export const deleteEmployeeFileAndReload = (
  id,
  entityId,
  successCallback = () => {}
) => dispatch => {
  return deleteEmployeeFile(id).then(res => {
    const { ErrorCount } = res.data;
    if (ErrorCount === 0) {
      successCallback();
      let ToastMessage = "Your File has been deleted.";
      dispatch(
        showToast(
          ToastMessage,
          SUCCESS_TOAST,
          false,
          "delete",
          "EMPLOYEE_FILES"
        )
      );
      dispatch(searchEmployeeFilesList(entityId));
      Promise.resolve();
    } else {
      let ToastMessage =
        "Something went wrong, unable to delete the selected file.";
      dispatch(
        showToast(ToastMessage, RED_TOAST, false, "delete", "EMPLOYEE_FILES")
      );
      Promise.resolve();
    }
  });
};
