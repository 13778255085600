import React, { Component } from "react";
import AddressVerificationContainer from "../containers/AddressVerificationContainer";
import { withRouter } from "react-router";

class AddressVerificationPage extends Component {
  render() {
    return (
      <div className="transition-item detail-page clearfix">
        <AddressVerificationContainer
          redirectToChecklist={() => {
            this.props.history.replace("/payroll");
            this.props.clearPayrollActiveTab();
          }}
        />
      </div>
    );
  }
}
export default withRouter(AddressVerificationPage);
