import React from "react";
import "./CircularCheckbox.css";

const CheckboxList = ({ label, selected, onSelect, className }) => (
  <div className={"row" + className} style={{ padding: "0px", width: "45%" }}>
    <div className="col-md-4" style={{ padding: "0px" }} onClick={onSelect}>
      {selected ? (
        <i
          className="fa fa-check-circle-o"
          style={{ fontSize: "35px", color: "#339966", cursor: "pointer" }}
        />
      ) : (
        <div className="uncheck" />
      )}
    </div>
    <div className="col-md-8" style={{ padding: "0px", marginTop: "5px" }}>
      {label}
    </div>
  </div>
);

const DependentCircularCheckbox = ({
  options = [],
  selected = {},
  onSelect,
  loading
}) => {
  if (loading) {
    return <div style={{ minHeight: 120 }} />;
  }
  return (
    <div className="col-md-7" style={{ padding: "0px" }}>
      <ul className="nc-dropdown-navigation-multiselect">
        <div
          className="row grid-container"
          style={{ width: "100%", display: "-webkit-box" }}
        >
          {options.map(({ value, ...rest }) => (
            <CheckboxList
              key={value}
              selected={selected[value]}
              {...rest}
              className="grid-option"
              onSelect={() => onSelect(value, !selected[value])}
            />
          ))}
        </div>
      </ul>
    </div>
  );
};

export default DependentCircularCheckbox;
