export const SELECT_ALL_ID = 9999;

export const HOLIDAY_FILTER_NAME = "CompanyHolidayInstanceName";

export const TableColumns = [
  {
    label: "Holiday",
    field: "HolidayName",
    ascOrder: "A to Z",
    descOrder: "Z to A"
  },
  {
    label: "Date(s)",
    field: "Dates",
    ascOrder: "Ascending",
    descOrder: "Descending"
  },
  {
    label: "Work Hours Per Day",
    field: "WorkHoursPerDay",
    ascOrder: "Ascending",
    descOrder: "Descending"
  }
];

export const COMMON_HOLIDAY_NAME = "commonHoliday";

export const HOLIDAY_TYPE_NAME = "holidayType";

export const FEDERAL_BANKING_HOLIDAY_ID = 1;

export const MONTH_LIST = [
  { id: 1, value: 1, label: "January" },
  { id: 2, value: 2, label: "February" },
  { id: 3, value: 3, label: "March" },
  { id: 4, value: 4, label: "April" },
  { id: 5, value: 5, label: "May" },
  { id: 6, value: 6, label: "June" },
  { id: 7, value: 7, label: "July" },
  { id: 8, value: 8, label: "August" },
  { id: 9, value: 9, label: "September" },
  { id: 10, value: 10, label: "October" },
  { id: 11, value: 11, label: "November" },
  { id: 12, value: 12, label: "December" }
];

export const DAY_LIST = [
  { id: 1, value: 1, label: "1" },
  { id: 2, value: 2, label: "2" },
  { id: 3, value: 3, label: "3" },
  { id: 4, value: 4, label: "4" },
  { id: 5, value: 5, label: "5" },
  { id: 6, value: 6, label: "6" },
  { id: 7, value: 7, label: "7" },
  { id: 8, value: 8, label: "8" },
  { id: 9, value: 9, label: "9" },
  { id: 10, value: 10, label: "10" },
  { id: 11, value: 11, label: "11" },
  { id: 12, value: 12, label: "12" },
  { id: 13, value: 13, label: "13" },
  { id: 14, value: 14, label: "14" },
  { id: 15, value: 15, label: "15" },
  { id: 16, value: 16, label: "16" },
  { id: 17, value: 17, label: "17" },
  { id: 18, value: 18, label: "18" },
  { id: 19, value: 19, label: "19" },
  { id: 20, value: 20, label: "20" },
  { id: 21, value: 21, label: "21" },
  { id: 22, value: 22, label: "22" },
  { id: 23, value: 23, label: "23" },
  { id: 24, value: 24, label: "24" },
  { id: 25, value: 25, label: "25" },
  { id: 26, value: 26, label: "26" },
  { id: 27, value: 27, label: "27" },
  { id: 28, value: 28, label: "28" },
  { id: 29, value: 29, label: "29" },
  { id: 30, value: 30, label: "30" },
  { id: 31, value: 31, label: "31" }
];

export const DAYS_OF_WEEK = [
  { optionId: 1, value: 1, label: "Sunday" },
  { optionId: 2, value: 2, label: "Monday" },
  { optionId: 3, value: 3, label: "Tuesday" },
  { optionId: 4, value: 4, label: "Wednesday" },
  { optionId: 5, value: 5, label: "Thursday" },
  { optionId: 6, value: 6, label: "Friday" },
  { optionId: 7, value: 7, label: "Saturday" }
];

export const OCCURRENCE = [
  { id: 1, value: 1, label: "First" },
  { id: 2, value: 2, label: "Second" },
  { id: 3, value: 3, label: "Third" },
  { id: 4, value: 4, label: "Fourth" },
  { id: 5, value: 5, label: "Fifth" }
];

export const START_YEAR = 2018;

export const END_YEAR = 2050;

export const START_NO = 1;

export const END_NO = 50;

export const FIXED = 1;

export const FLOATING = 2;

export const COMMON_HOLIDAY_DEFINITION_ID = 1;

export const CUSTOM_HOLIDAY_DEFINITION_ID = 2;

export const COMMON_HOLIDAY_COUNT = 13;

export const DELETE_HOLIDAY = "delete holiday";

export const Col3 = "HolidayCalendarCol3";

export const Col4 = "HolidayCalendarCol4";

export const NO_COL = "HolidayCalendarNoCol";
