/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Panel, Overlay } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { bindActionCreators } from "redux";
import { Badge } from "react-bootstrap";
import { getStatusList } from "../../../actions/systemlevel/AdvanceFilterActions";
import { Checkbox, CheckboxGroup } from "react-checkbox-group";
import CustomOverlay from "component-library/dropDown/CustomOverlay";
import * as Id from "app/common/CommonIds";
import classnames from "classnames/bind";
const ALL_STATUS = 0;
const STATUS_PANEL_ID = 3;

let isAllUncheckedZero = false;

const activeStatusString =
  "Deduction can be assigned to an employee and can be used in payroll.";
const inactiveStatusString =
  "Deduction has been previously assigned to an employee or used in payroll but is currently not active. Deduction cannot be assigned to an employee or used in payroll unless made active.";
const inprogressStatusString =
  "Deduction creation has started but has not been completed. Deduction cannot be assigned to an employee or used in payroll.";

class SystemLevelStatusDropdown extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      selectedStatus: [1, 2],
      // selectedStatus: props.selectedStatus,
      selectStatusLabel: "Statuses",
      isAllSelected: false
      //isAllUncheckedZero: false
    };

    this.onCloseStatusDropdown = this.onCloseStatusDropdown.bind(this);
    this.checkSelectStatus = this.checkSelectStatus.bind(this);
    this.renderStatusSelectionList = this.renderStatusSelectionList.bind(this);
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  checkSelectStatus(newStatus) {
    // let statusLabel = (newStatus.length > 1 ? 'Statuses' : 'Status');  this condition has been modified while working on bug-18403
    // let statusLabel = (newStatus.length >= 1 ? 'Statuses' : 'Select Status');

    let statusLabel =
      newStatus.length > 1
        ? "Statuses"
        : newStatus.length === 0
        ? "Select Status"
        : "Status";

    if (newStatus.includes(ALL_STATUS)) {
      var indexOfAllStatus = newStatus.indexOf(ALL_STATUS);

      if (indexOfAllStatus !== 0 || this.state.selectedStatus.length === 0) {
        newStatus = [];

        for (var i = 0; i < this.props.systemLevelStatusList.length; i++) {
          var Id = this.props.systemLevelStatusList[i].Id;
          newStatus.push(Id);
        }

        this.setState({
          selectedStatus: newStatus,
          selectStatusLabel: "All Statuses",
          isAllSelected: true
        });
      } else {
        newStatus.splice(0, 1);
        this.setState({
          selectedStatus: newStatus,
          selectStatusLabel: statusLabel,
          isAllSelected: false
        });
      }
    } else if (this.state.isAllSelected && !newStatus.includes(0)) {
      this.setState({
        selectedStatus: [],
        selectStatusLabel: "Select Status",
        isAllSelected: false
      });
    } else {
      if (
        this.props.systemLevelStatusList.length - 1 === newStatus.length &&
        !newStatus.includes(ALL_STATUS)
      ) {
        newStatus = [];
        for (i = 0; i < this.props.systemLevelStatusList.length; i++) {
          Id = this.props.systemLevelStatusList[i].Id;
          newStatus.push(Id);
        }
        this.setState({
          selectedStatus: newStatus,
          selectStatusLabel: "All Statuses",
          isAllSelected: true
        });
      } else {
        this.setState({
          selectedStatus: newStatus,
          selectStatusLabel: statusLabel,
          isAllSelected: false
        });
      }
    }

    if (
      newStatus.length === 0 ||
      (this.state.isAllSelected === true &&
        newStatus.includes(ALL_STATUS) === false &&
        newStatus.indexOf(ALL_STATUS) === -1 &&
        newStatus.length === this.props.systemLevelStatusList.length - 1)
    ) {
      isAllUncheckedZero = true;
    } else {
      isAllUncheckedZero = false;
    }
  }

  componentWillMount() {
    // this.setState({
    //   isAllSelected: true
    // });
    // let selectedStatus = [];
    // if (this.props.systemLevelStatusOrigionalList.Data) {
    //   selectedStatus.push(
    //     this.props.systemLevelStatusOrigionalList.Data[0].Id
    //   );
    //   selectedStatus.push(
    //     this.props.systemLevelStatusOrigionalList.Data[1].Id
    //   );
    // }
    // this.setState({
    //   selectedStatus,
    //   selectStatusLabel: "Statuses",
    //   isAllSelected: false
    // });
    // this.props.onSelectedStatus(selectedStatus);
    // this.props.getStatusList();
    // this.props.onSelectedStatus(this.state.selectedStatus);
  }

  componentDidMount() {
    let selectedStatus = [];
    this.props.isInitialLoad(false);
    if (
      this.props.systemLevelSelectedFiltersList !== undefined &&
      this.props.systemLevelSelectedFiltersList !== null &&
      this.props.systemLevelSelectedFiltersList.length !== 0
    ) {
      if (this.props.systemLevelSelectedFiltersList.selectedStatus.length > 0) {
        this.props.systemLevelSelectedFiltersList.selectedStatus.forEach(
          selectedItem => {
            selectedStatus.push(selectedItem);
          }
        );
      }
    } else {
      if (this.props.systemLevelStatusOrigionalList.Data) {
        selectedStatus.push(
          this.props.systemLevelStatusOrigionalList.Data[0].Id
        );
        selectedStatus.push(
          this.props.systemLevelStatusOrigionalList.Data[1].Id
        );
      }
    }

    let selectStatusLabel = "Status";
    let isAllSelected = false;

    if (this.props.systemLevelStatusList.length === selectedStatus.length) {
      selectStatusLabel = "All Statuses";
      isAllSelected = true;
    } else if (selectedStatus.length === 0) {
      selectStatusLabel = "Select Status";
      isAllSelected = false;
    } else if (selectedStatus.length === 1) {
      selectStatusLabel = "Status";
      isAllSelected = false;
    } else {
      selectStatusLabel = "Statuses  ";
      isAllSelected = false;
    }

    this.setState({
      selectedStatus,
      selectStatusLabel,
      isAllSelected
    });

    this.props.onSelectedStatus(selectedStatus);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.systemLevelStatusOrigionalList !==
      this.props.systemLevelStatusOrigionalList
    ) {
      let selectedStatus = [];
      // selectedStatus[0] = 0;
      if (nextProps.systemLevelStatusOrigionalList.Data) {
        selectedStatus.push(
          nextProps.systemLevelStatusOrigionalList.Data[0].Id
        );
        selectedStatus.push(
          nextProps.systemLevelStatusOrigionalList.Data[2].Id
        );
      }

      this.setState({
        selectedStatus,
        selectStatusLabel: "Statuses",
        isAllSelected: false
      });
    }

    if (nextProps.isResetToDefault !== this.props.isResetToDefault) {
      if (nextProps.isResetToDefault) {
        let selectedStatus = [];
        if (nextProps.systemLevelStatusOrigionalList.Data !== undefined) {
          selectedStatus.push(
            this.props.systemLevelStatusOrigionalList.Data[0].Id
          );
          selectedStatus.push(
            this.props.systemLevelStatusOrigionalList.Data[2].Id
          );
        }

        isAllUncheckedZero = false;

        this.setState({
          selectedStatus,
          selectStatusLabel: "Statuses",
          isAllSelected: false
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedStatus !== this.state.selectedStatus) {
      this.props.onSelectedStatus(this.state.selectedStatus);
    }
  }

  onCloseStatusDropdown() {
    this.props.onSelectedStatus(this.state.selectedStatus);
  }

  renderStatusSelectionList() {
    if (!this.props.systemLevelStatusList) {
      return <div />;
    } else {
      return (
        <CustomOverlay>
          <Scrollbars
            autoHeight={true}
            autoHeightMax={320}
            renderThumbHorizontal={props => (
              <div
                style={{ overflowX: "hidden" }}
                className="thumb-horizontal"
              />
            )}
          >
            <CheckboxGroup
              name="select-status"
              value={this.state.selectedStatus}
              onChange={this.checkSelectStatus}
            >
              <ul className="nc-dropdown-navigation-multiselect">
                {this.renderStatusSelectionItem(
                  this.props.systemLevelStatusList
                )}
              </ul>
            </CheckboxGroup>
          </Scrollbars>
        </CustomOverlay>
      );
    }
  }
  renderStatusSelectionListMobile() {
    if (!this.props.systemLevelStatusList) {
      return <div />;
    } else {
      return (
        <Scrollbars
          autoHeight={true}
          autoHeightMax={320}
          renderThumbHorizontal={props => (
            <div style={{ overflowX: "hidden" }} className="thumb-horizontal" />
          )}
        >
          <CheckboxGroup
            name="select-status"
            value={this.state.selectedStatus}
            onChange={this.checkSelectStatus}
          >
            <ul className="nc-dropdown-navigation-multiselect">
              {this.renderStatusSelectionItem(this.props.systemLevelStatusList)}
            </ul>
          </CheckboxGroup>
        </Scrollbars>
      );
    }
  }
  renderStatusSelectionItem(list) {
    return list.map((item, index) => {
      return (
        <li
          id={Id.statusFilter + index}
          key={item.Name + item.Id}
          className="list-item"
        >
          <Checkbox id={item.Name + item.Id} value={item.Id} />
          <label
            htmlFor={item.Name + item.Id}
            id={item.Name.replace(/ /g, "-").toLowerCase() + "-status"}
          >
            {item.Name}
          </label>
          <div className="hidden-lg hidden-md tooltip-mobile-filter">
            {item.Name === "Active" && activeStatusString}
            {item.Name === "Inactive" && inactiveStatusString}
            {item.Name === "In Progress" && inprogressStatusString}
          </div>
        </li>
      );
    });
  }

  openSubPanelById(id) {
    let update = {};

    if (id === this.state.subPanelId) {
      update["subPanelId"] = 0;
    } else {
      update["subPanelId"] = id;
    }

    this.setState(update);
  }

  render() {
    const statusSelectionPanel = this.renderStatusSelectionList();
    if (!this.props.systemLevelStatusList) {
      return <div>No Records Found.</div>;
    }
    if (window.innerWidth <= 991) {
      return (
        <div>
          <div className="nc-select-container hidden-sm hidden-xs">
            <Button
              id={Id.statusFilter}
              className="filter-select-button"
              onClick={this.toggle}
            >
              <div
                style={{ width: "70%" }}
                className="filter-select-label col-md-8 col-lg-8"
              >
                {this.state.selectStatusLabel}
              </div>
              <div style={{ width: "20%" }} className="col-md-2 col-lg-2">
                {isAllUncheckedZero ? null : this.state
                    .isAllSelected ? null : this.state.selectedStatus !==
                  undefined ? (
                  <Badge
                    id={Id.statusFilter + Id.filterCountMobile}
                    className="filter-select-badge"
                  >
                    {this.state.selectedStatus.length}
                  </Badge>
                ) : null}
              </div>
              <div style={{ width: "10%" }} className="col-md-1 col-lg-1">
                <i className="filter-select-dropdown-icon fa fa-chevron-down" />
              </div>
            </Button>
          </div>
          <Overlay
            show={this.state.popoverOpen}
            onHide={() => this.setState({ popoverOpen: false })}
            placement="bottom"
            container={this}
            rootClose={true}
          >
            {statusSelectionPanel}
          </Overlay>

          <div className="mobile-filter-panel-list-section">
            <a
              id=""
              onClick={this.openSubPanelById.bind(this, STATUS_PANEL_ID)}
            >
              <div className="mobile-filter-panel-list-panel-bar col-xs-12 col-sm-12">
                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-icon">
                  {this.state.subPanelId === STATUS_PANEL_ID ? (
                    <i
                      className="fa fa-chevron-up add_deduction_popup_panel_link"
                      aria-hidden="true"
                    />
                  ) : (
                    <i
                      className="fa fa-chevron-down add_deduction_popup_panel_link"
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="col-xs-8 col-sm-8 mobile-filter-panel-list-label no-padding">
                  {this.state.selectStatusLabel}
                </div>
                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-badge no-padding">
                  {isAllUncheckedZero ? null : this.state
                      .isAllSelected ? null : this.state.selectedStatus !==
                    undefined ? (
                    <Badge
                      id={Id.statusFilter + Id.filterCountMobile}
                      className="filter-select-badge"
                    >
                      {this.state.selectedStatus.length}
                    </Badge>
                  ) : null}
                </div>
              </div>
            </a>
            <div className="mobile-filter-panel-list-panel-bar-section">
              <Panel
                className="mobile-filter-panel-list-panel-default no-padding col-xs-12 col-sm-12 col-md-12 col-lg-12 "
                collapsible
                expanded={
                  this.state.subPanelId === STATUS_PANEL_ID ? true : false
                }
              >
                <Panel.Collapse>
                  <Panel.Body>
                    {this.renderStatusSelectionListMobile()}
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="nc-select-container hidden-sm hidden-xs">
            <Button
              id={Id.statusFilter}
              className="filter-select-button"
              onClick={this.toggle}
              ref={button => (this.statusFilter = button)}
            >
              <span className="selected-value">
                {this.state.selectStatusLabel}
              </span>
              <i
                className={classnames(
                  "pull-right fa",
                  this.state.popoverOpen ? "fa-chevron-up" : "fa-chevron-down"
                )}
              />
              {isAllUncheckedZero ? null : this.state
                  .isAllSelected ? null : this.state.selectedStatus !==
                undefined ? (
                <Badge
                  id={Id.statusFilter + Id.filterCountMobile}
                  className="filter-select-badge pull-right"
                >
                  {this.state.selectedStatus.length}
                </Badge>
              ) : null}
            </Button>
          </div>
          <Overlay
            show={this.state.popoverOpen}
            onHide={() => this.setState({ popoverOpen: false })}
            placement="bottom"
            container={this}
            rootClose={true}
            target={this.statusFilter}
          >
            {statusSelectionPanel}
          </Overlay>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  let list = [
    {
      Id: 0,
      Name: "All Statuses"
    }
  ];

  if (state.systemLevelStatusList.Data) {
    state.systemLevelStatusList.Data.forEach(item => {
      list.push(item);
    });
  }
  return {
    systemLevelStatusList: list,
    systemLevelStatusOrigionalList: state.systemLevelStatusList,
    systemLevelSelectedFiltersList: state.systemLevelSelectedFiltersList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getStatusList: getStatusList }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemLevelStatusDropdown);
