/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Panel } from "react-bootstrap";
import RadioButton from "../../component-library/radioButton/BasicRadioButton";
import PropTypes from "prop-types";
import classnames from "classnames/bind";
const PanelBar = ({ id, name, active, onClick }) => (
  <a id={`add_deduction_popup_panel_link-` + id} onClick={onClick}>
    <div className="add_deduction_popup_panel_header col-xs-12 col-sm-12 col-md-12 col-lg-12">
      {active ? (
        <i
          id={`chevron-up-` + id}
          className="fa fa-chevron-up add_deduction_popup_panel_link"
          aria-hidden="true"
        />
      ) : (
        <i
          id={`chevron-down-` + id}
          className="fa fa-chevron-down add_deduction_popup_panel_link"
          aria-hidden="true"
        />
      )}
      {name}
    </div>
  </a>
);

const SubcategoryPanel = props => {
  const {
    name,
    data,
    id,
    active,
    onSelect,
    activeEarning,
    onSelectEarning
  } = props;
  return (
    <div key={id} id={`sub-panel-` + id} className="sub-panels">
      <PanelBar name={name} id={id} active={active} onClick={onSelect} />
      <div className="panel-body-table">
        <Panel
          className={classnames(
            "add_deduction_popup_panel_body col-xs-12 col-sm-12 col-md-12 col-lg-12 ",
            !active && "inactive-panel"
          )}
          onToggle={() => {}}
          expanded={active}
          defaultExpanded
        >
          <Panel.Collapse>
            <Panel.Body>
              {data.map(earning => (
                <RadioButton
                  id={earning.id}
                  name="SystemDeductionId"
                  value={earning.id}
                  type="two-line-title"
                  label={earning.name}
                  key={earning.id}
                  checked={earning.id === activeEarning}
                  width="230px"
                  onChange={(e, value) =>
                    onSelectEarning(parseInt(e.value, 10))
                  }
                />
              ))}
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </div>
    </div>
  );
};
export default SubcategoryPanel;
SubcategoryPanel.propTypes = {
  name: PropTypes.string,
  data: PropTypes.array,
  id: PropTypes.number,
  active: PropTypes.bool,
  onSelect: PropTypes.func,
  activeEarning: PropTypes.number,
  onSelectEarning: PropTypes.func
};
SubcategoryPanel.defaultProps = {
  onSelectEarning: () => {},
  onSelect: () => {},
  data: []
};
