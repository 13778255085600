import React, { Component } from "react";
import FilterPanel from "./../../filters/FilterPanel";
import Scrollbars from "react-custom-scrollbars";
import { Modal } from "react-bootstrap";
import { EMPLOYEE_FILTER_NAME, TYPE_FILTER_NAME } from "./Constants";
const labelMaker = (noLabel, allLabel, someLabel, oneLabel) => (o, s, all) =>
  all
    ? allLabel
    : Object.keys(s).filter(k => s[k]).length === 0
      ? noLabel
      : Object.keys(s).filter(k => s[k]).length === 1
        ? oneLabel
        : someLabel;

export default class FilterModal extends Component {
  state = {
    setPanelID: 0
  };

  showPanelHandler = id => {
    if (this.state.setPanelID === id) {
      this.setState({ setPanelID: 0 });
    } else {
      this.setState({
        showPanel: !this.state.showPanel,
        setPanelID: id
      });
    }
  };

  render() {
    const {
      show,
      options = [],
      makeOnSelect,
      makeSelectAll,
      selectedOptions = {},
      closePanel,
      clearAll
    } = this.props;
    return (
      <Modal
        id="mobile-filter"
        animation={false}
        backdrop={"static"}
        keyboard={false}
        show={show}
      >
        <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="header-container col-sm-12 col-xs-12 clearfix">
            <div className="close-icon col-sm-3 col-xs-3">
              <i
                id="system-level-cancel-button-close-icon"
                onClick={closePanel}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
            <div className="filter-label col-sm-6 col-xs-6">
              {" All Filters "}
            </div>
            <div
              className="clear-all-link col-sm-3 col-xs-3"
              onClick={clearAll}
            >
              Clear All
            </div>
          </div>
          <Scrollbars style={{ height: window.innerHeight - 103 }}>
            <FilterPanel
              showPanelHandler={this.showPanelHandler.bind(this, 1)}
              showPanel={this.state.setPanelID === 1}
              showPanelName={labelMaker(
                "Select Employees",
                "All Employees",
                "Employees",
                "Employee"
              )}
              multiSelect={true}
              options={options[EMPLOYEE_FILTER_NAME]}
              onSelect={makeOnSelect(EMPLOYEE_FILTER_NAME)}
              selectedItems={selectedOptions[EMPLOYEE_FILTER_NAME]}
              selectAll={makeSelectAll(EMPLOYEE_FILTER_NAME)}
              selectAllLabel="All Earnings"
              showBadge
              showSearch
            />
            {/* <FilterPanel
              showPanelHandler={this.showPanelHandler.bind(this, 2)}
              showPanel={this.state.setPanelID === 2}
              showPanelName={labelMaker(
                "Select Page",
                "All Pages",
                "Pages",
                "Page"
              )}
              multiSelect={true}
              options={options[TYPE_FILTER_NAME]}
              onSelect={makeOnSelect(TYPE_FILTER_NAME)}
              selectedItems={selectedOptions[TYPE_FILTER_NAME]}
              selectAll={makeSelectAll(TYPE_FILTER_NAME)}
              showBadge
            /> */}
            <FilterPanel
              showPanelHandler={this.showPanelHandler.bind(this, 3)}
              showPanel={this.state.setPanelID === 3}
              showPanelName={labelMaker(
                "Select Type",
                "All Types",
                "Types",
                "Type"
              )}
              multiSelect={true}
              options={options[TYPE_FILTER_NAME]}
              onSelect={makeOnSelect(TYPE_FILTER_NAME)}
              selectedItems={selectedOptions[TYPE_FILTER_NAME]}
              selectAll={makeSelectAll(TYPE_FILTER_NAME)}
              showBadge
            />
          </Scrollbars>
        </Modal.Body>
        <Modal.Footer>
          <div className="mobile-filter-panel-button-section">
            <button
              type="button"
              id="mobile-apply-filters"
              className="apply-filter-button"
              onClick={closePanel}
            >
              APPLY FILTERS
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
