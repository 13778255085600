import React, { Component } from "react";
import {
  DragFileSection,
  EmployeeFilesListSection
} from "component-library/employee-files";
import ToasterContainer from "./ToasterContainer";
import { Modal } from "react-bootstrap";
import {
  FormSection,
  FormFieldGroup
} from "../../component-library/formSections/index";
import { ProgressBar } from "react-bootstrap";

const componentProps = {
  show: true,
  inputType: "text",
  inputId: "FileDescription",
  inputName: "FileDescription",
  label: "Please enter a name or short discription for the file",
  description: "100 characters max",
  descriptionStyle: "italic",
  Size: [8, 7, 6, 12],
  maxLength: "100",
  required: true
};

var fileName = "";

export default class EmployeeFiles extends Component {
  state = {
    droppedFiles: [],
    imageLoading: false,
    src: "",
    showUploadedModal: false,
    showUploadingModal: false
  };

  componentDidMount() {
    //this.searchAndSetPost(this.props.entityId);
    //console.log("this.props in didmount", this.props.entityId);
  }

  searchAndSetPost = entityId => {
    const { searchEmployeeFilesList } = this.props;
    this.setState({
      loadingMore: true
    });
    searchEmployeeFilesList(entityId, () =>
      this.setState({ loadingMore: false })
    );
  };

  setImageState = imageState => {
    this.setState({ imageState });
  };

  setRef = ref => (this.image = ref);

  onImageLoaded = () => {
    this.setImageState("uploaded");
  };

  onDeleteClick = employeeFileId => {
    this.setState({
      showUploadedModal: false
    });
    const { entityId, deleteEmployeeFileAndReload } = this.props;
    deleteEmployeeFileAndReload(employeeFileId, entityId, () => {});
  };

  onClickAddFile = () => {
    this.setState({
      showUploadedModal: false
    });
  };

  closeUploadedModal = () => {
    this.setState({
      showUploadedModal: false
    });
  };

  closeUploadedingModal = () => {
    this.setState({
      showUploadingModal: false
    });
  };

  setSrc = src => this.setState({ src });

  handleFileDrop = (item, monitor) => {
    if (monitor) {
      const droppedFiles = monitor.getItem().files;
      if (
        droppedFiles[0].type === "image/png" ||
        droppedFiles[0].type === "image/jpeg" ||
        droppedFiles[0].type === "application/pdf" ||
        droppedFiles[0].type === "text/plain" ||
        droppedFiles[0].type === "image/jpg" ||
        droppedFiles[0].type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        droppedFiles[0].type === "image/gif" ||
        droppedFiles[0].type === "image/bmp" ||
        droppedFiles[0].type === "application/msword" ||
        droppedFiles[0].type === "application/vnd.ms-excel" ||
        droppedFiles[0].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        droppedFiles[0].type === "application/vnd.ms-powerpoint" ||
        droppedFiles[0].type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        this.createImage(droppedFiles);
        this.setState({
          showUploadingModal: true
        });
      } else {
        let ToastMessage =
          "Please Upload a file of type jpg, png ,gif, bmp, doc, docx, rtf, txt ,csv, xls, xlsx, ppt, pptx";
        this.props.showToast(
          ToastMessage,
          "danger",
          false,
          "default",
          "EMPLOYEE_FILES"
        );
        this.setImageState("fileTypeError");
      }
    }
  };

  handleFileUpload = e => {
    var x = document.getElementById("image-upload");
    const selectedFiles = x.files;
    if (
      selectedFiles[0].type === "image/png" ||
      selectedFiles[0].type === "image/jpeg" ||
      selectedFiles[0].type === "application/pdf" ||
      selectedFiles[0].type === "text/plain" ||
      selectedFiles[0].type === "image/jpg" ||
      selectedFiles[0].type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      selectedFiles[0].type === "image/gif" ||
      selectedFiles[0].type === "image/bmp" ||
      selectedFiles[0].type === "application/msword" ||
      selectedFiles[0].type === "application/vnd.ms-excel" ||
      selectedFiles[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      selectedFiles[0].type === "application/vnd.ms-powerpoint" ||
      selectedFiles[0].type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      this.createImage(selectedFiles);
      this.setState({
        showUploadingModal: true
      });
    } else {
      let ToastMessage =
        "Please Upload a file of type jpg, png ,gif, bmp, doc, docx, rtf, txt ,csv, xls, xlsx, ppt, pptx";
      this.props.showToast(
        ToastMessage,
        "danger",
        false,
        "default",
        "EMPLOYEE_FILES"
      );
      this.setImageState("fileTypeError");
    }
  };

  createImage = droppedFiles => {
    if (droppedFiles[0].size <= 15728640) {
      console.log("droppedFiles : ", droppedFiles[0].name);
      fileName = droppedFiles[0].name;
      this.setState({ droppedFiles });
      const image = new Image();
      image.src = URL.createObjectURL(droppedFiles[0]);
      //image.src=droppedFiles[0]
      image.onload = () => {
        this.setState({
          src: image.src,
          imageLoading: false
        });
        this.setSrc(image.src);
      };
      image.onerror = err => {
        this.setState({
          src: "",
          imageLoading: false
        });
        this.setSrc("");
      };
      image.onprogress = (e, request, progress) => {
        console.log("Progress", e, request, progress);
      };
    } else {
      let ToastMessage = "Please Upload a file up to 15 MB.";
      this.props.showToast(
        ToastMessage,
        "danger",
        false,
        "default",
        "EMPLOYEE_FILES"
      );
      this.setImageState("fileSizeError");
    }
  };

  render() {
    const {
      employeeProfileData = {},
      entityId,
      toast,
      onChange,
      errors = {}
    } = this.props;
    const { Data = {} } = employeeProfileData;
    const { FilesDictionary = [] } = Data;
    return (
      <div className="col-lg-9 col-md-9 tab-container">
        {toast.featureId === "EMPLOYEE_FILES" && (
          <ToasterContainer Id="toaster" />
        )}
        <DragFileSection
          setRef={this.setRef}
          setImageState={this.setImageState}
          imageState={this.state.imageState}
          onImageLoaded={this.onImageLoaded}
          handleFileDrop={this.handleFileDrop}
          handleFileUpload={this.handleFileUpload}
        />
        <EmployeeFilesListSection
          data={FilesDictionary[entityId]}
          onDeleteClick={this.onDeleteClick}
        />
        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showUploadedModal}
          onHide={this.closeUploadedModal}
          id=""
        >
          <Modal.Header>
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              style={{ fontSize: "20px", paddingBottom: "15px" }}
            >
              <i
                className="fa fa-check-circle"
                style={{ marginRight: "5px", color: "#009999" }}
              />{" "}
              Upload Completed - {fileName}
            </div>
          </Modal.Header>
          <Modal.Body>
            <div id="" className="">
              <FormSection>
                {" "}
                <FormFieldGroup
                  {...componentProps}
                  //value={}
                  onChange={onChange}
                  class="name-holiday-calendar"
                  error={errors[componentProps.inputName]}
                />
              </FormSection>
              <div className="popover-button-group">
                <button
                  type="button"
                  id="popover-save-button"
                  className="nc-button secondary-button"
                  onClick={this.onClickAddFile}
                >
                  ADD FILE
                </button>
                <button
                  type="button"
                  id="popover-cancel-button"
                  className="secondary-link-button"
                  onClick={this.onDeleteClick}
                  style={{ marginLeft: "30px" }}
                >
                  Delete
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showUploadingModal}
          onHide={this.closeUploadedingModal}
          id=""
        >
          <Modal.Header>
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center file-title"
              style={{ paddingBottom: "15px" }}
            >
              Uploading {fileName}...
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="nc-progress-bar">
              <ProgressBar
                bsStyle="success"
                bsClass="nc-progress progress-bar"
                now={40}
              />
              <div className="progress-percentage">40% Completed</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div class="employee-file-timestamp">
              The maximum file size is 15 mb.
            </div>
            <button
              type="button"
              id="popover-upload-cancle-button"
              className="primary-link-button"
              onClick={this.closeUploadedingModal}
            >
              Cancel Upload
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
