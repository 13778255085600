import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Header,
  PageContainer,
  BottomNavigationBar,
  ProfileUploadModal
} from "component-library/employee-profile/profile-view";
import EmployeeProfileModal from "component-library/employee-profile/EmployeeProfileModal";
import EmployeeProfileCard from "./EmployeeProfileCard";
import EmployeeProfileInformation from "./EmployeeProfileInformation";
import { Switch, Route } from "react-router-dom";
import { AdvanceFilter } from "component-library/employee-profile";
import { makeOptions, makeSelectedOptions, makeCriterion } from "../utils";
import * as util from "app/common/utilities";
import {
  fetchAndSetPrimaryManager,
  fetchAndSetSecondaryManager,
  fetchAndSetEmployeeType,
  fetchAndSetEmployeeClassification,
  fetchAndSetPosition,
  fetchAndSetPayrollSchedule,
  fetchAndSetPayrollFrequency,
  fetchAndSetTimeGroup,
  fetchAndSetGender,
  fetchAndSetEmployeeProfileData,
  setEmployeeProfilePicture,
  fetchAndSetCompanyList,
  fetchAndSetStatusList,
  fetchAndSetEmployeeFilteredList,
  setSideBarIdAndActivePanelId,
  onApplyAdvanceFilter,
  searchAndSetPostsMenu
} from "../network/actions";
import { setLoader } from "app/actions/LoaderActions";
import {
  setGlobalNavClick,
  CallbackToQuickLink,
  setFormUpdate,
  isOverlayClickable
} from "app/actions/FormUpdateAction";
import * as Filters from "component-library/employee-profile/list-view/advance-filter/FieldNames";
import { getEmployeeMenuData } from "../utils";

const placeholderPic =
  "https://cdn3.iconfinder.com/data/icons/rcons-user-action/32/boy-512.png";

const appear = {
  transform: "translateY(-420px)"
};

const getSearchParams = state => {
  const { filters = {} } = state.common;
  const { advancedFilterFields } = state.people;
  const defaultSearchParams = {
    Fields: ["*"],
    Criterion: [],
    Sort: [],
    PageSize: 5
  };

  let Criterion = [];
  Criterion.push(
    makeCriterion(Filters.lastName, advancedFilterFields[Filters.lastName])
  );
  Criterion.push(
    makeCriterion(Filters.firstName, advancedFilterFields[Filters.firstName])
  );
  Criterion.push(
    makeCriterion(Filters.birthDate, advancedFilterFields[Filters.birthDate])
  );
  Criterion.push(
    makeCriterion(Filters.ageTo, advancedFilterFields[Filters.ageTo])
  );
  Criterion.push(
    makeCriterion(Filters.ageFrom, advancedFilterFields[Filters.ageFrom])
  );
  Criterion.push(makeCriterion(Filters.gender, filters[Filters.gender]));
  Criterion.push(
    makeCriterion(Filters.employeeStatus, filters[Filters.employeeStatus])
  );
  Criterion.push(
    makeCriterion(Filters.hireDate, advancedFilterFields[Filters.hireDate])
  );
  Criterion.push(
    makeCriterion(
      Filters.terminationDate,
      advancedFilterFields[Filters.terminationDate]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.reTerminationDate,
      advancedFilterFields[Filters.reTerminationDate]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.inactiveStartDate,
      advancedFilterFields[Filters.inactiveStartDate]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.inactiveEndDate,
      advancedFilterFields[Filters.inactiveEndDate]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.leaveStartDate,
      advancedFilterFields[Filters.leaveStartDate]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.leaveEndDate,
      advancedFilterFields[Filters.leaveEndDate]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.transferDate,
      advancedFilterFields[Filters.transferDate]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.retiredDate,
      advancedFilterFields[Filters.retiredDate]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.deceasedDate,
      advancedFilterFields[Filters.deceasedDate]
    )
  );
  Criterion.push(
    makeCriterion(Filters.primaryManager, filters[Filters.primaryManager])
  );
  Criterion.push(
    makeCriterion(Filters.secondaryManager, filters[Filters.secondaryManager])
  );
  Criterion.push(
    makeCriterion(Filters.employeeType, filters[Filters.employeeType])
  );
  Criterion.push(
    makeCriterion(Filters.employeePosition, filters[Filters.employeePosition])
  );
  Criterion.push(
    makeCriterion(Filters.payrollSchedule, filters[Filters.payrollSchedule])
  );
  Criterion.push(
    makeCriterion(Filters.payrollFrequency, filters[Filters.payrollFrequency])
  );
  Criterion.push(
    makeCriterion(Filters.employeeCompany, filters[Filters.employeeCompany])
  );
  Criterion.push(
    makeCriterion(Filters.employeeDivision, filters[Filters.employeeDivision])
  );
  Criterion.push(
    makeCriterion(
      Filters.employeeDepartment,
      filters[Filters.employeeDepartment]
    )
  );
  Criterion.push(
    makeCriterion(Filters.employeeLocation, filters[Filters.employeeLocation])
  );
  Criterion.push(
    makeCriterion(Filters.timeGroup, filters[Filters.deceasedDate])
  );
  Criterion.push(
    makeCriterion(
      Filters.employeeBadgeNumber,
      advancedFilterFields[Filters.employeeBadgeNumber]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.employeeNumber,
      advancedFilterFields[Filters.employeeNumber]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.clockNumber,
      advancedFilterFields[Filters.clockNumber]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.employeeSsn,
      advancedFilterFields[Filters.employeeSsn]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.employeeCode,
      advancedFilterFields[Filters.employeeCode]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.employeeClient,
      advancedFilterFields[Filters.employeeClient]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.employeeProject,
      advancedFilterFields[Filters.employeeProject]
    )
  );
  Criterion = Criterion.filter(c => c);

  defaultSearchParams.Criterion = Criterion;

  return defaultSearchParams;
};
var NewEmployeeMenu = [];
class EmployeeDashboard extends Component {
  state = {
    showProfileModal: false,
    showAdvanceFilter: false,
    line1: "",
    line2: "",
    buttonLabel: "",
    showCancelModal: false,
    isFormUpdated: false,
    data: {}
  };

  toggleCancelModal = () => {
    this.setState({
      isFormUpdated: !this.state.isFormUpdated,
      showCancelModal: !this.state.showCancelModal
    });
    this.props.setGlobalNavClick(false);
    this.props.setFormUpdate(!this.state.isFormUpdated);
  };

  toggleProfileModal = () =>
    this.setState({
      showProfileModal: !this.state.showProfileModal,
      imageState: "",
      isUploaded: false
    });

  setProfilePhoto = () => {
    this.props.setLoader(true);
    const formData = new FormData();
    var objectURL = new File([this.image.crop()], "EmployeeProfile", {
      type: "image/jpeg"
    });
    formData.append("file", objectURL);
    this.setState({
      croppedImage: this.image.crop()
    });

    //TODO: Service need to integrate
    // setEmployeeProfilePicture(formData => {
    //   this.setState({
    //     showProfileModal: false,
    //     imageState: ""
    //   });
    // });

    this.setState({
      showProfileModal: false,
      croppedImage: this.image.crop()
    });
    this.props.setLoader(false);
  };

  setImageState = imageState => {
    if (imageState === "uploaded") {
      this.setState({
        isUploaded: true
      });
    } else if (imageState === "") {
      this.setState({
        isUploaded: false
      });
    }

    this.setState({ imageState });
  };

  setRef = ref => (this.image = ref);
  edits = data => this.setState({ data: { ...this.state.data, ...data } });
  onChange = e => {
    const edits = { [e.name]: e.value };
    const { props } = this;
    if (e.name === Filters.employeeCompany) {
      props.loadDivisions();
      props.loadLocations();
      props.loadClients();
    }
    if (e.name === Filters.employeeDivision) {
      props.loadBussinessUnits();
    }
    if (e.name === Filters.businessUnit) {
      props.loadDepartments();
    }
    if (e.name === Filters.employeeDepartment) {
    }
    if (e.name === Filters.employeeClient) {
      props.loadProjects();
    }
    this.editData(edits);
    this.props.selectTextfield(e.name, e.value);
    this.props.setShowClearFilters(true);
  };

  onImageLoaded = () => {
    this.setImageState("uploaded");
  };

  setSrc = src => this.setState({ src });

  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    const { props } = this;
    const { CurrentSubscriberCompanyId } = props.sessionContext;
    props.loadPrimaryManager();
    props.loadSecondaryManager();
    props.loadEmployeeType();
    props.loadEmployeeClassification();
    props.loadEmployeePosition();
    props.loadPayrollSchedule();
    props.loadPayrollFrequency();
    props.loadTimeGroup();
    props.loadGender();
    props.loadStatusList(true);
    props.loadCompanyList(true, CurrentSubscriberCompanyId);

    const { EmployeeId } = this.props.match.params;
    if (!util.isNullOrEmpty(EmployeeId)) {
      this.props.setLoader(true);
      this.props.loadEmployeeProfileData(parseInt(EmployeeId, 10));
    }

    if (
      this.props.employeeProfileData.Data &&
      this.props.employeeProfileData.Data !== undefined &&
      this.props.employeeProfileData.Data.length > 0
    ) {
      const userProfileInfo = this.props.employeeProfileData.Data;
      if (util.isNullOrEmpty(userProfileInfo[0].EmployeeProfileUrl))
        this.setState({
          src: placeholderPic
        });
      else {
        this.setState({
          src: userProfileInfo[0].EmployeeProfileUrl
        });
      }
    } else {
      this.setState({
        src: placeholderPic
      });
    }

    const { loadEmployeeMenu } = this.props;
    this.props.clearFiltersFields();
    let searchParams = this.getBasicMenuSearchParams();
    loadEmployeeMenu(searchParams, () => {
      NewEmployeeMenu = getEmployeeMenuData(this.props.employeeMenu.Data);
    });
  };

  getBasicMenuSearchParams = () => {
    const defaultSearchParams = {
      Fields: ["*"],
      Criterion: [],
      Sort: [],
      PageSize: 5
    };
    let Criterion = [];
    Criterion.push(makeCriterion("CustomFieldGroupId", [1]));
    Criterion = Criterion.filter(c => c);
    defaultSearchParams.Criterion = Criterion;

    return defaultSearchParams;
  };

  onClick = (sideBar, sectionItem) => {
    const EmployeeId = this.props.match.params.EmployeeId;
    this.props.history.push(
      `/people/employees/dashboard/${EmployeeId}/${sideBar.CustomFieldGroupName.replace(
        / /g,
        ""
      ).toLowerCase()}/${sectionItem.CustomFieldGroupId}`
    );
    this.props.setSideBarIdAndActivePanelId(
      sideBar.CustomFieldGroupId,
      sectionItem.CustomFieldGroupId
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params.EmployeeId !== this.props.match.params.EmployeeId
    ) {
      const { EmployeeId } = this.props.match.params;
      if (!util.isNullOrEmpty(EmployeeId)) {
        this.props.setLoader(true);
        this.props.loadEmployeeProfileData(parseInt(EmployeeId, 10));
      }
    }
  }

  navigateToListPage = () => {
    if (this.state.isFormUpdated) {
      this.setState({
        showCancelModal: !this.state.showCancelModal,
        line1:
          "Are you sure you want to leave this page in the middle of making edits?",
        line2: "Changes made on this page will not be saved?",
        buttonLabel: "LEAVE THIS PAGE",
        buttonType: "LEAVE"
      });
    } else {
      this.props.history.push("/people/employees/list-view");
    }
  };

  navigateToEmployeeDashboard = id => {
    if (this.state.isFormUpdated) {
      this.setState({
        showCancelModal: !this.state.showCancelModal,
        line1:
          "Are you sure you want to leave this page in the middle of making edits?",
        line2: "Changes made on this page will not be saved?",
        buttonLabel: "LEAVE THIS PAGE",
        buttonType: "LEAVE"
      });
    } else {
      this.props.history.push("/people/employees/dashboard/" + id);
    }
  };

  onApply = () => {
    const { onApplyAdvanceFilter, searchParams } = this.props;
    onApplyAdvanceFilter(searchParams, employeeData => {
      if (Object.keys(employeeData).length > 0) {
        this.props.history.push(
          "/people/employees/dashboard/" + employeeData.EmployeeId
        );
      } else {
        this.props.history.push("/people/employees/list-view");
      }
      this.setState({
        showAdvanceFilter: !this.state.showAdvanceFilter
      });
    });
  };

  resetFilters = () => {
    const { clearAdvancedFiltersAndNavigate, filters } = this.props;
    this.props.clearFiltersFields();
    let searchParams = this.getBasicFilterSearchParams();
    clearAdvancedFiltersAndNavigate(filters, searchParams, employeeData => {
      if (Object.keys(employeeData).length > 0) {
        this.props.history.push(
          "/people/employees/dashboard/" + employeeData.EmployeeId
        );
      } else {
        this.props.history.push("/people/employees/list-view");
      }
    });
    this.props.setShowClearFilters(false);
  };

  getBasicFilterSearchParams = () => {
    const defaultSearchParams = {
      Fields: ["*"],
      Criterion: [],
      Sort: [],
      PageSize: 5
    };
    const { defaultFilters } = this.props;
    let Criterion = [];
    Criterion.push(
      makeCriterion(
        Filters.basicFilterEmployeelist,
        defaultFilters[Filters.basicFilterEmployeelist]
      )
    );
    Criterion.push(
      makeCriterion(
        Filters.basicFilterStatuslist,
        defaultFilters[Filters.basicFilterStatuslist]
      )
    );
    Criterion.push(
      makeCriterion(
        Filters.basicFilterCompanylist,
        defaultFilters[Filters.basicFilterCompanylist]
      )
    );
    Criterion = Criterion.filter(c => c);
    defaultSearchParams.Criterion = Criterion;

    return defaultSearchParams;
  };

  makeOnSelect = name => (option, selected) => {
    this.props.selectOption(name, option, selected);
    this.props.setShowClearFilters(true);
  };

  makeOnSelectAll = name => selected => {
    this.props.selectAll(name, selected);
    this.props.setShowClearFilters(true);
  };

  makeDateRangeSelect = e => {
    this.props.selectTextfield(e.name, e.value);
    this.props.setShowClearFilters(true);
  };

  render() {
    let { employeeProfileData } = this.props;
    let data = {};
    if (employeeProfileData.Data.length !== 0) {
      data = employeeProfileData.Data;
    }
    let previousEmployee = data.length !== 0 ? data.PreviousEmployee : [];
    let nextEmployee = data.length !== 0 ? data.NextEmployee : [];
    return (
      <PageContainer>
        <Header
          item={data}
          onEditProfileClick={this.toggleProfileModal}
          image={this.state.croppedImage}
        />

        <Switch>
          <Route
            exact
            path="/people/employees/dashboard/:EmployeeId"
            render={() => (
              <EmployeeProfileCard
                data={data}
                onClick={this.onClick}
                NewEmployeeMenu={NewEmployeeMenu}
              />
            )}
          />
          <Route
            path="/people/employees/dashboard/:EmployeeId/:CardId/:ActivePanelId"
            render={() => (
              <EmployeeProfileInformation
                {...this.props}
                //NewEmployeeMenu={NewEmployeeMenu}
                setFormUpdate={() => {}}
              />
            )}
          />
        </Switch>
        <div
          className="bottom-navigation-container"
          style={this.state.showAdvanceFilter ? appear : {}}
        >
          <BottomNavigationBar
            onListViewClick={this.navigateToListPage}
            onAdvanceFilterClick={() =>
              this.setState({
                showAdvanceFilter: !this.state.showAdvanceFilter
              })
            }
            onSearchEmployeeClick={this.navigateToEmployeeDashboard}
            options={this.props.filters}
            nextEmployee={nextEmployee}
            previousEmployee={previousEmployee}
            nextEmployeeClick={this.navigateToEmployeeDashboard}
            previousEmployeeClick={this.navigateToEmployeeDashboard}
            showAdvanceFilter={this.state.showAdvanceFilter}
            showClearFilters={this.props.showClearFilters}
            resetFilters={this.resetFilters}
          />
          {this.state.showAdvanceFilter && (
            <AdvanceFilter
              {...this.props}
              onClose={() =>
                this.setState({
                  showAdvanceFilter: !this.state.showAdvanceFilter
                })
              }
              options={this.props.filters}
              data={this.state.data}
              onChange={this.onChange}
              onClear={this.resetFilters}
              makeOnSelect={this.makeOnSelect}
              makeOnSelectAll={this.makeOnSelectAll}
              onApply={this.onApply}
            />
          )}
        </div>

        <ProfileUploadModal
          show={this.state.showProfileModal}
          closeModal={this.toggleProfileModal}
          onSetClick={this.setProfilePhoto}
          setRef={this.setRef}
          setImageState={this.setImageState}
          imageState={this.state.imageState}
          onImageLoaded={this.onImageLoaded}
          isUploaded={this.state.isUploaded}
          src={this.state.src}
          setSrc={this.setSrc}
        />

        <EmployeeProfileModal
          show={this.state.showCancelModal}
          onClose={this.toggleCancelModal}
          line1={this.state.line1}
          line2={this.state.line2}
          onSaveChanges={() => {}}
          buttonLabel={this.state.buttonLabel}
          leaveThisPage={() =>
            this.props.history.push("/people/employees/list-view")
          }
        />
      </PageContainer>
    );
  }
}

const stateToProps = state => {
  return {
    data: state.people,
    filters: makeOptions(state.common.filters),
    selectedOptions: makeSelectedOptions(state.common.filters),
    employeeProfileData: state.people.employeeDashboard,
    sessionContext: state.sessionContext,
    employeeList: state.people.employeeList,
    employeeMenu: state.people.employeeMenu,
    searchParams: getSearchParams(state),
    defaultFilters: state.common.filters
  };
};

const actionCreators = {
  loadPrimaryManager: fetchAndSetPrimaryManager,
  loadSecondaryManager: fetchAndSetSecondaryManager,
  loadEmployeeType: fetchAndSetEmployeeType,
  loadEmployeeClassification: fetchAndSetEmployeeClassification,
  loadEmployeePosition: fetchAndSetPosition,
  loadPayrollSchedule: fetchAndSetPayrollSchedule,
  loadPayrollFrequency: fetchAndSetPayrollFrequency,
  loadTimeGroup: fetchAndSetTimeGroup,
  loadGender: fetchAndSetGender,
  loadEmployeeProfileData: fetchAndSetEmployeeProfileData,
  setLoader,
  setSideBarIdAndActivePanelId,
  setEmployeeProfilePicture,
  loadCompanyList: fetchAndSetCompanyList,
  loadStatusList: fetchAndSetStatusList,
  loadEmployeeFilteredList: fetchAndSetEmployeeFilteredList,
  setFormUpdate,
  isOverlayClickable,
  setGlobalNavClick,
  CallbackToQuickLink,
  onApplyAdvanceFilter,
  loadEmployeeMenu: searchAndSetPostsMenu
};

export default withRouter(
  connect(stateToProps, actionCreators)(EmployeeDashboard)
);
