import React, { Component } from "react";
import { JobHistoryActions } from "component-library/job-history";

export default class JobHistoryActionsContainer extends Component {
  onExcelClick = () => {
    // TODO: Call the right page/action here
  };

  onPrintClick = () => {
    // TODO: Call the right page/action here
  };

  render() {
    return (
      <JobHistoryActions
        fullReportPath="/" // TODO: Fix this path
        onExcelClick={this.onExcelClick}
        onPrintClick={this.onPrintClick}
      />
    );
  }
}
