/** Actions */
const ADD_TO_HOLIDAY = "holidaycalendar/ADD_TO_HOLIDAY";
const CLEAR_HOLIDAY = "holidaycalendar/CLEAR_HOLIDAY";
const SET_HOLIDAY_LOADING = "holidaycalendar/SET_HOLIDAY_LOADING";

export const initialState = {
  loading: true,
  Data: [],
  DataCount: 0,
  NextUri: null
};

/** Reducer */
const holiday = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_HOLIDAY:
      return initialState;
    case ADD_TO_HOLIDAY: {
      const { Data, DataCount, NextUri } = payload;
      const newData = [...state.Data, ...Data];
      return {
        ...state,
        NextUri,
        Data: newData,
        DataCount: DataCount,
        loading: false
      };
    }
    case SET_HOLIDAY_LOADING:
      return {
        ...state,
        loading: payload
      };
    default:
      return state;
  }
};

export default holiday;

/** Action creators */
export const addToHolidayList = (Data, DataCount, NextUri) => ({
  type: ADD_TO_HOLIDAY,
  payload: { Data, DataCount, NextUri }
});

export const clearHolidayList = () => ({
  type: CLEAR_HOLIDAY
});

export const setHolidayListLoading = loading => ({
  type: SET_HOLIDAY_LOADING,
  payload: loading
});
