import React from "react";
import { PropTypes } from "prop-types";
import InlineFormFieldGroup from "./InlineFormFieldGroup";
import DefaultFormFieldGroup from "./DefaultFormFieldGroup";

const FormFieldGroup = props => {
  const FormFieldGroup =
    props.fieldType === "inline" ? InlineFormFieldGroup : DefaultFormFieldGroup;
  return <FormFieldGroup {...props} />;
};
export default FormFieldGroup;
FormFieldGroup.propTypes = {
  fieldType: PropTypes.oneOf(["inline", "default"])
};

FormFieldGroup.defaultProps = {
  fieldType: "default"
};
