import React from "react";
import "./CustomFields.css";

const ListPageHeader = ({
  backToLink,
  title,
  description,
  addButtonLabel = "ADD",
  onAddButtonClick,
  onHistoryButtonClick,
  showButtons = true,
  onCopyButtonClick,
  hideCopy = true,
  hideHistory = true,
  Id
}) => (
  <div id="page-header-container" className="clearfix">
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div id={Id.backToPageLink} style={{ paddingBottom: "20px" }}>
        <a href={backToLink}>
          <i className="fa fa-angle-left" /> Back to Utilities
        </a>
      </div>

      <div
        id={Id.pageTitle}
        className="pageTitle col-lg-6 col-md-7 col-sm-8 col-xs-12"
      >
        {title}
      </div>

      {showButtons && (
        <div className="nc-navigation-button-family hidden-sm hidden-xs col-lg-6 col-md-5 col-sm-12 col-xs-12">
          {hideHistory ? (
            ""
          ) : (
            <button
              id={Id.historyButton}
              className="nc-button outline-button-right pull-right"
              onClick={onHistoryButtonClick}
              style={
                !hideCopy
                  ? {
                      borderBottomLeftRadius: "0",
                      borderTopLeftRadius: "0"
                    }
                  : {}
              }
            >
              <i className="fa fa-history" aria-hidden="true" />
              <span className="hidden-sm hidden-xs">HISTORY</span>
            </button>
          )}
          {hideCopy ? (
            ""
          ) : (
            <button
              id={Id.copyButton}
              className="nc-button outline-button-right pull-right"
              onClick={onCopyButtonClick}
              style={
                !hideHistory
                  ? {
                      marginRight: "0",
                      borderRight: "none",
                      borderBottomRightRadius: "0",
                      borderTopRightRadius: "0"
                    }
                  : {
                      borderBottomLeftRadius: "0",
                      borderTopLeftRadius: "0",
                      borderBottomRightRadius: "0",
                      borderTopRightRadius: "0"
                    }
              }
            >
              <i className="fa fa-clone" aria-hidden="true" />
              <span className="hidden-sm hidden-xs">COPY</span>
            </button>
          )}
          <button
            id={Id.addButton}
            type="button"
            onClick={onAddButtonClick}
            className="nc-button primary-button pull-right"
          >
            <i className="fa fa-plus"> </i> {addButtonLabel}
          </button>
        </div>
      )}
    </div>

    <div className="deduction-page-description-container">
      <div className="page-description col-lg-7 col-md-7 col-sm-10 col-xs-10">
        <p
          id={Id.pageDescription}
          style={{ margin: "0px", paddingBottom: "10px" }}
        >
          {description}
        </p>
      </div>

      {showButtons && (
        <div className="deduction-page-description-button col-lg-6 col-md-5 col-sm-12 col-xs-12">
          <div className="nc-navigation-button-family col-xs-12 hidden-lg hidden-md">
            <div className="primary-group">
              <button
                id="add-deduction-button"
                type="button"
                onClick={onAddButtonClick}
                className="nc-button primary-button"
              >
                <i className="fa fa-plus" />
                <span className="hidden-lg hidden-md ">
                  &nbsp; {addButtonLabel}
                </span>
              </button>
            </div>
            <div className="secondary-group">
              {hideHistory ? (
                ""
              ) : (
                <button
                  className="nc-button outline-fa-button pull-right"
                  id=""
                  onClick={onHistoryButtonClick}
                  style={
                    !hideCopy
                      ? {
                          borderBottomLeftRadius: "0",
                          borderTopLeftRadius: "0"
                        }
                      : {}
                  }
                >
                  <i className="fa fa-history" aria-hidden="true" />
                  {hideCopy && <span className="">HISTORY</span>}
                </button>
              )}

              {hideCopy ? (
                ""
              ) : (
                <button
                  className="nc-button outline-fa-button pull-right"
                  id=""
                  onClick={onCopyButtonClick}
                  style={{
                    marginRight: "0",
                    borderRight: "none",
                    borderBottomRightRadius: "0",
                    borderTopRightRadius: "0"
                  }}
                >
                  <i className="fa fa-clone" aria-hidden="true" />
                  <span className="hidden-sm hidden-xs">COPY</span>
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default ListPageHeader;
