import axios from "axios";
import * as types from "./index";
import * as common from "../../../../app/common/index";

//main table list
export function getEmployerExpenceLiabilityAccountsList() {
  const url = `${common.WEB_API_URL}/v1/subscriber/company/deduction/glaccount`;
  //const url=`${common.WEB_API_MOCK_URL}/v1/subscriber/company/deduction/mapglaccount?filter=all&_mock_=Success`;
  let request = axios.get(url);
  return {
    type: types.FETCH_EMPLOYER_EXPENCE_LIABILITY_ACCOUNTS_MAIN_LIST,
    payload: request
  };
}

export function getEmployerExpenceLiabilityAccountsFilteredList(
  data,
  selectedDeductions,
  selectedGLCategories,
  unmappedCheckState
) {
  let filterdByEntityList = [];
  let filterdByCategoryList = [];
  let filterdList = [];
  // let deductionLength = selectedDeductions.length;
  // let categoryLength = selectedGLCategories.length;

  if (data !== undefined) {
    if (selectedDeductions === undefined || selectedDeductions.length === 0) {
      filterdByEntityList = [];
    } else {
      data.forEach(item => {
        if (selectedDeductions.includes(item.CompanyDeductionId)) {
          filterdByEntityList.push(item);
        }
      });
    }

    if (
      selectedGLCategories === undefined ||
      selectedGLCategories.length === 0
    ) {
      filterdByCategoryList = [];
    } else {
      filterdByEntityList.forEach(item => {
        if (selectedGLCategories.includes(item.IsIncludeInGlReport)) {
          filterdByCategoryList.push(item);
        }
      });
    }

    if (unmappedCheckState === true) {
      filterdList = [];
      filterdByCategoryList.forEach(item => {
        if (
          item.IsIncludeInGlReport &&
          (item.EmployerContributionExpenseGlAccountId === null ||
            item.EmployerContributionLiabilityGlAccountId === null)
        ) {
          filterdList.push(item);
        }
      });
    } else {
      filterdList = filterdByCategoryList;
    }
  }

  let request = {
    data: {
      Data: filterdList,
      DataCount: filterdList.length
    }
  };

  return {
    type: types.FETCH_EMPLOYER_FILTERED_LIST,
    payload: request
  };
}

// export function getEmployeeExpenceLiabilityAccountsFilteredList(
//   data,
//   selectedDeductions,
//   unmappedCheckState
// ) {
//   let filterdByEntityList = [];
//   let filterdList = [];

//   if (data !== undefined) {
//     if (selectedDeductions === undefined || selectedDeductions.length === 0) {
//       filterdByEntityList = [];
//     } else {
//       data.forEach(item => {
//         if (selectedDeductions.includes(item.CompanyDeductionId)) {
//           filterdByEntityList.push(item);
//         }
//       });
//     }

//     if (unmappedCheckState === true) {
//       filterdList = [];
//       filterdByEntityList.forEach(item => {
//         if (item.EmployerContributionExpenseGlAccountId === 0) {
//           filterdList.push(item);
//         }
//       });
//     } else {
//       filterdList = filterdByEntityList;
//     }
//   }

//   let request = {
//     data: {
//       Data: filterdList,
//       DataCount: filterdList.length
//     }
//   };

//   return {
//     type: types.FETCH_EMPLOYEE_FILTERED_LIST,
//     payload: request
//   };
// }

export function setEmployerExpencesData(employerList) {
  let request = {
    data: {
      Data: employerList
      // DataCount: employerList.length
    }
  };

  return {
    type: types.SET_EMPLOYER_EXPENCE_DATA,
    payload: request
  };
}

//for expense account drop down
export function getEmployerExpenceAccountsList() {
  const url = `${common.WEB_API_URL}/v1/subscriber/company/glaccount/2`;
  // const url=`${common.WEB_API_MOCK_URL}/v1/app/ui/createworkflowstatus `;
  // const url = `${common.WEB_API_MOCK_URL}/v1/subscriber/company/glaccount `;
  let request = axios.get(url);
  return {
    type: types.FETCH_EMPLOYER_EXPENCE_ACCOUNTS_LIST,
    payload: request
  };
}

//for liability account drop down
export function getEmployerLiabilityAccountsList() {
  const url = `${common.WEB_API_URL}/v1/subscriber/company/glaccount/3`;
  // const url=`${common.WEB_API_MOCK_URL}/v1/app/ui/createworkflowstatus `;
  //const url = `${common.WEB_API_MOCK_URL}/v1/subscriber/company/glaccount `;
  let request = axios.get(url);
  return {
    type: types.FETCH_EMPLOYER_LIABILITY_ACCOUNTS_LIST,
    payload: request
  };
}

export function saveEmployerExpenceLiabilityAccountsList(
  employerExpenceLiabilityAccounts
) {
  const url = `${
    common.WEB_API_URL
  }/v1/System/Deduction/AddDeductionDescription`;
  axios
    .post(url, employerExpenceLiabilityAccounts)
    .then(function(response) {})
    .catch(function(error) {});

  return {
    type: types.SAVE_EMPLOYER_EXPENCE_LIABILITY_ACCOUNTS
  };
}

//for deduction filter drop down -- should not use this
export function getDeductionList() {
  const url = `${common.WEB_API_URL}/v1/subscriber/company/deduction/glaccount`;
  //const url=`${common.WEB_API_MOCK_URL}/v1/system/deduction?_mock_=success&datasize=10`;
  // const url=`${common.WEB_API_MOCK_URL}/v1/subscriber/company/deduction/mapglaccount?filter=all&_mock_=Success`;
  let request = axios.get(url);
  return {
    type: types.FETCH_EMPLOYER_EXPENCE_LIABILITY_ACCOUNTS_MAIN_LIST,
    payload: request
  };
}

export function getIncludeGLReportList() {
  // const url=`${common.WEB_API_URL}/v1/System/Deduction/CategoryList`;
  const url = `${common.WEB_API_URL}/v1/system/deductioncategory`;
  let request = axios.get(url);
  return {
    type: types.FETCH_GL_REPORT_FILTER_LIST,
    payload: request
  };
}

// export function getEmployeeLiabilityAccountsGridList(){
//     const url=`${common.WEB_API_URL}/v1/app/ui/company`;
//     let request=axios.get(url);
//     return {
//         type : types.FETCH_EMPLOYEE_LIABILITY_ACCOUNTS_GRID_LIST,
//         payload : request
//     };
// }

//for saving data
export function setEmployerLiabilityAccountsData(data) {
  if (data === undefined) {
    data = [];
  }

  return {
    type: types.FETCH_EMPLOYER_LIABILITY_ACCOUNTS_DATA,
    payload: data
  };
}

export function updatedFilteredList(data) {
  let request = {
    data: data
  };
  return {
    type: types.FETCH_EMPLOYER_FILTERED_LIST,
    payload: request
  };
}

export function getUnmappedCheckState(item) {
  return {
    type: types.UNMAPPED_CHECK_STATE_TYPE,
    payload: item
  };
}

// added after story change on 23-8-2017

export function setSelectedFilters(
  selectedDeductions,
  selectedCategories,
  selectedStatus
) {
  let deductions = [];
  let categories = [];
  let status = [];
  selectedDeductions.forEach(dItem => {
    deductions.push(dItem);
  });

  selectedCategories.forEach(dItem => {
    categories.push(dItem);
  });

  selectedStatus.forEach(dItem => {
    status.push(dItem);
  });

  let data = {
    selectedDeductions: deductions,
    selectedGLCategories: categories,
    selectedStatus: status
  };

  return {
    type: "GENERAL_LEDGER_SELECTED_FILTERS",
    payload: data
  };
}

export function sortFilteredList(request) {
  return {
    type: types.FETCH_EMPLOYER_FILTERED_LIST,
    payload: request
  };
}
