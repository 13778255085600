import axios from "axios";
import * as types from "./index";
import * as common from "../../../../app/common/index";

// export function getEmployeeLiabilityAccountsGridList(){
//     const url=`${common.WEB_API_URL}/v1/app/ui/company`;
//     let request=axios.get(url);
//     return {
//         type : types.FETCH_EMPLOYEE_LIABILITY_ACCOUNTS_MAIN_LIST,
//         payload : request
//     };
// }

//main table list
// export function getEmployeeExpenceLiabilityAccountsList(data) {
//     // const url = `${common.WEB_API_URL}/v1/subscriber/company/deduction/glaccount`;
//     //const url=`${common.WEB_API_MOCK_URL}/v1/subscriber/company/deduction/mapglaccount?filter=all&_mock_=Success`;
//     // let request = axios.get(url);

//     let employeeData = [];
//     data.map((item) =>{
//         if(item.IsIncludeInGlReport){
//             employeeData.push(item);
//         }
//     });

//     let request = {
//         data: {
//             Data: employeeData,
//             DataCount: employeeData.length
//         }
//     };

//     return {
//        type : types.FETCH_EMPLOYEE_LIABILITY_ACCOUNTS_MAIN_LIST,
//         payload : request
//     };
// }

export function getEmployeeExpenceLiabilityAccountsList() {
  const url = `${common.WEB_API_URL}/v1/subscriber/company/deduction/glaccount`;
  //const url = `${common.WEB_API_MOCK_URL}/v1/subscriber/company/deduction/mapglaccount?filter=all&_mock_=Success`;
  let request = axios.get(url);
  return {
    type: types.FETCH_EMPLOYEE_LIABILITY_ACCOUNTS_MAIN_LIST,
    payload: request
  };
}

export function getEmployeeExpenceLiabilityAccountsFilteredList(
  data,
  selectedDeductions,
  unmappedCheckStateEmployeeTab
) {
  let filterdByEntityList = [];
  let filterdList = [];

  if (data !== undefined) {
    if (selectedDeductions === undefined || selectedDeductions.length === 0) {
      filterdByEntityList = [];
    } else {
      data.forEach(item => {
        if (selectedDeductions.includes(item.CompanyDeductionId)) {
          filterdByEntityList.push(item);
        }
      });
    }

    if (unmappedCheckStateEmployeeTab === true) {
      filterdList = [];
      filterdByEntityList.forEach(item => {
        if (
          item.EmployeeContributionLiabilityGlAccountId === 0 ||
          item.EmployeeContributionLiabilityGlAccountId === null
        ) {
          filterdList.push(item);
        }
      });
    } else {
      filterdList = filterdByEntityList;
    }
  }

  let request = {
    data: {
      Data: filterdList,
      DataCount: filterdList.length
    }
  };
  return {
    type: types.FETCH_EMPLOYEE_FILTERED_LIST,
    payload: request
  };
}

// liability account drop down
export function getEmployeeLiabilityAccountsList() {
  //const url = `${common.WEB_API_MOCK_URL}/v1/subscriber/company/glaccount `;
  const url = `${common.WEB_API_URL}/v1/subscriber/company/glaccount/3`;
  let request = axios.get(url);

  return {
    type: types.FETCH_EMPLOYEE_LIABILITY_ACCOUNTS_LIST,
    payload: request
  };
}

//for expense account drop down
export function getEmployerExpenceAccountsList() {
  const url = `${common.WEB_API_URL}/v1/subscriber/company/glaccount/2`;
  // const url=`${common.WEB_API_MOCK_URL}/v1/app/ui/createworkflowstatus `;
  // const url = `${common.WEB_API_MOCK_URL}/v1/subscriber/company/glaccount `;
  let request = axios.get(url);
  return {
    type: types.FETCH_EMPLOYER_EXPENCE_ACCOUNTS_LIST,
    payload: request
  };
}

export function saveEmployeeLiabilityAccountsList(
  employeeLiabilityAccountsGridList
) {
  const url = `${
    common.WEB_API_URL
  }/v1/System/Deduction/AddDeductionDescription`;
  axios
    .post(url, employeeLiabilityAccountsGridList)
    .then(function(response) {})
    .catch(function(error) {});

  return {
    type: types.SAVE_EMPLOYEE_LIABILITY_ACCOUNTS
  };
}

// for deduction dropdown
export function getEmployeeDeductionFilterList() {
  const url = `${common.WEB_API_URL}/v1/subscriber/company/deduction/glaccount`;
  //const url = `${common.WEB_API_MOCK_URL}/v1/subscriber/company/deduction/mapglaccount?filter=all&_mock_=Success`;
  let request = axios.get(url);
  return {
    type: types.FETCH_EMPLOYEE_LIABILITY_ACCOUNTS_MAIN_LIST,
    payload: request
  };
}

export function updatedEmployeeAccountFilteredList(data) {
  let request = {
    data: data
  };
  return {
    type: types.FETCH_EMPLOYEE_FILTERED_LIST,
    payload: request
  };
}

export function setEmployeeData(data) {
  if (data === undefined) {
    data = [];
  }

  return {
    type: types.EMPLOYEE_DATA,
    payload: data
  };
}

export function setSaveEmployeePage(pageId, data) {
  data.push(pageId);
  return {
    type: types.EMPLOYEE_SAVED_PAGES,
    payload: data
  };
}

// export function setSelectedFilters(
//   selectedDeductions,
//   selectedCategories,
//   selectedStatus
// ) {
//   let deductions = [];
//   let categories = [];
//   let status = [];
//   selectedDeductions.forEach(dItem => {
//     deductions.push(dItem);
//   });

//   selectedCategories.forEach(dItem => {
//     categories.push(dItem);
//   });

//   selectedStatus.forEach(dItem => {
//     status.push(dItem);
//   });

//   let data = {
//     selectedDeductions: deductions,
//     selectedGLCategories: categories
//   };

//   return {
//     type: "SYSTEM_DEDUCTION_SELECTED_FILTERS",
//     payload: data
//   };
// }

export function setSelectedFilters(selectedDeductions, selectedCategories) {
  let deductions = [];
  let categories = [];
  selectedDeductions.forEach(dItem => {
    deductions.push(dItem);
  });

  selectedCategories.forEach(dItem => {
    categories.push(dItem);
  });

  // selectedStatus.map( dItem =>{
  //     status.push(dItem);
  // });

  let data = {
    selectedDeductions: deductions,
    selectedGLCategories: categories
  };

  return {
    type: "GENERAL_LEDGER_SELECTED_FILTERS",
    payload: data
  };
}

export function sortFilteredList(request) {
  return {
    type: types.FETCH_EMPLOYEE_FILTERED_LIST,
    payload: request
  };
}
