import React, { Component } from "react";
import { FormSection } from "component-library/formSections";
import NavTab from "component-library/navTabs/index";
import { SampleData, HOBBIES, interestCaseValue } from "../Constants";
import EmployeeProfileCheckbox from "./Checkbox";
import filter from "lodash/filter";

class Interest extends Component {
  state = { activeTab: 1 };
  onChange = id => {
    this.setState({
      activeTab: id
    });
  };
  profileInterest() {
    const { Hobbies } = this.props.profileData && this.props.profileData;
    return Hobbies
      ? Hobbies.map(InterestData => ({
          id: InterestData.Id,
          name: InterestData.Name,
          value: InterestData.Id.toString(),
          GroupId: InterestData.GroupId
        }))
      : [];
  }
  componentWillUnmount() {
    this.props.clearHobbiesList();
  }
  renderInterests = () => {
    const { activeTab } = this.state;
    const Interest = this.profileInterest();
    let Interests;
    switch (activeTab) {
      case interestCaseValue.hobbies:
        Interests = filter(Interest, { GroupId: 2 });
        return (
          <EmployeeProfileCheckbox
            options={Interests}
            selected={this.props.selectedOptions[HOBBIES]}
            onSelect={this.props.makeOnSelect(HOBBIES)}
          />
        );

      case interestCaseValue.music:
        Interests = filter(Interest, { GroupId: 3 });
        return (
          <EmployeeProfileCheckbox
            options={Interests}
            selected={this.props.selectedOptions[Interests]}
            onSelect={this.props.makeOnSelect(Interests)}
          />
        );

      default:
        Interests = filter(Interest, { GroupId: 1 });
        return (
          <EmployeeProfileCheckbox
            options={Interests}
            selected={this.props.selectedOptions[Interests]}
            onSelect={this.props.makeOnSelect(Interests)}
          />
        );
    }
  };

  render() {
    return (
      <FormSection title="Interests" hr>
        <NavTab
          data={SampleData}
          width="500px"
          activeTab={this.state.activeTab}
          onChange={this.onChange}
        />
        {this.renderInterests()}
        <div className="col-md-12">
          <button
            id="Save demographic"
            onClick={this.props.onSave}
            style={{ float: "right" }}
            className="nc-button primary-button"
          >
            Save
          </button>
        </div>
      </FormSection>
    );
  }
}

export default Interest;
