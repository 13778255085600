export const FETCH_GL_ACCOUNT_TYPE_LIST = "FETCH_GL_ACCOUNT_TYPE_LIST";
export const SAVE_GENERAL_LEDGER_ACCOUNT = "SAVE_GENERAL_LEDGER_ACCOUNT";

export const FETCH_EMPLOYER_EXPENCE_LIABILITY_ACCOUNTS_MAIN_LIST =
  "FETCH_EMPLOYER_EXPENCE_LIABILITY_ACCOUNTS_MAIN_LIST";
export const FETCH_EMPLOYER_EXPENCE_ACCOUNTS_LIST =
  "FETCH_EMPLOYER_EXPENCE_ACCOUNTS_LIST";
export const FETCH_EMPLOYER_LIABILITY_ACCOUNTS_LIST =
  "FETCH_EMPLOYER_LIABILITY_ACCOUNTS_LIST";
export const SET_EMPLOYER_EXPENCE_DATA = "SET_EMPLOYER_EXPENCE_DATA";
export const SAVE_EMPLOYER_EXPENCE_LIABILITY_ACCOUNTS =
  "SAVE_EMPLOYER_EXPENCE_LIABILITY_ACCOUNTS";

export const FETCH_EMPLOYER_DEDUCTION_FILTER_LIST =
  "FETCH_EMPLOYER_DEDUCTION_FILTER_LIST";
//
export const FETCH_EMPLOYER_FILTERED_LIST = "FETCH_EMPLOYER_FILTERED_LIST";
export const FETCH_EMPLOYER_LIABILITY_ACCOUNTS_DATA =
  "FETCH_EMPLOYER_LIABILITY_ACCOUNTS_DATA";

export const GENERAL_LEDGER_SELECTED_FILTERS =
  "GENERAL_LEDGER_SELECTED_FILTERS";
//
export const UNMAPPED_CHECK_STATE_TYPE = "UNMAPPED_CHECK_STATE_TYPE";
export const FETCH_GL_REPORT_FILTER_LIST = "FETCH_GL_REPORT_FILTER_LIST";

// for employee section
export const FETCH_EMPLOYEE_LIABILITY_ACCOUNTS_GRID_LIST =
  "FETCH_EMPLOYEE_LIABILITY_ACCOUNTS_GRID_LIST";

export const FETCH_EMPLOYEE_LIABILITY_ACCOUNTS_MAIN_LIST =
  "FETCH_EMPLOYEE_LIABILITY_ACCOUNTS_MAIN_LIST"; //main list

export const FETCH_EMPLOYEE_FILTERED_LIST = "FETCH_EMPLOYEE_FILTERED_LIST"; //filtered list

export const FETCH_EMPLOYEE_LIABILITY_ACCOUNTS_LIST =
  "FETCH_EMPLOYEE_LIABILITY_ACCOUNTS_LIST"; // liability account drop down list

export const EMPLOYEE_DATA = "EMPLOYEE_DATA";

export const EMPLOYEE_SAVED_PAGES = "EMPLOYEE_SAVED_PAGES";

export const SAVE_EMPLOYEE_LIABILITY_ACCOUNTS =
  "SAVE_EMPLOYEE_LIABILITY_ACCOUNTS";

export const FETCH_EMPLOYEE_DEDUCTION_FILTER_LIST =
  "FETCH_EMPLOYEE_DEDUCTION_FILTER_LIST";
