import * as types from "../../../actions/systemlevel/index";
export default function(state = [], action) {
  switch (action.type) {
    case types.SYSTEM_GET_TPP_REVIEW_SAVE_SNAPSHOT:
      return action.payload.data;

    default:
      return state;
  }
}
