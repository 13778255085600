/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import componentForKey from "./componentForKey";
import Media from "react-media";
let hideOptionSection = false;

const renderPencil = (item, node, navigateToUrl, i) => {
  if (item.nodeKey === node.NodeKey) {
    return (
      <a key={i} id={item.id} onClick={() => navigateToUrl(item.navigationUrl)}>
        <i className="fa fa-pencil" style={{ fontSize: "15px" }} />
      </a>
    );
  } else {
    return null;
  }
};

const renderNode = (nodes, navigationNode, navigateToUrl, isClientAdmin) => (
  node,
  i
) => {
  const NodeComponent = componentForKey(node.NodeKey);

  if (
    ["Advanced Tax Option", "Advanced Tax"].includes(node.NodeValues[0]) &&
    isClientAdmin
  ) {
    return null;
  }

  return (
    <div key={i} className="nc-field-group clearfix">
      {node.NodeType === "section" && (
        <div className="seperator">
          <hr />
        </div>
      )}
      <div
        id={"review-save-page-label-" + (i + 1)}
        className="field-name col-lg-3 col-md-4 col-sm-11 col-xs-11"
      >
        {node.NodeValues[0] !== "" && node.NodeValues[0] + `:`}
      </div>
      <Media query="(min-width:992px)">
        <div className="field-information col-lg-8 col-md-7 col-sm-12 col-xs-12 hidden-xs hidden-sm">
          <NodeComponent {...node} />
        </div>
      </Media>

      {node.NodeType === "section" && navigationNode && (
        <div className="edit-icon col-lg-1 col-md-1 col-sm-1 col-xs-1">
          {navigationNode.map(item => {
            return renderPencil(item, node, navigateToUrl, i);
          })}
        </div>
      )}
      <Media query="(max-width:991px)">
        <div
          className="field-information col-lg-8 col-md-8 col-sm-12 col-xs-12 visible-sm visible-xs"
          style={{ wordBreak: "break-word" }}
        >
          <NodeComponent {...node} />
        </div>
      </Media>
    </div>
  );
};

const EntitySnapshotTable = ({
  nodes = [],
  navigationNode,
  navigateToUrl,
  isClientAdmin
}) => (
  <div>
    {nodes.map(renderNode(nodes, navigationNode, navigateToUrl, isClientAdmin))}
  </div>
);

export default EntitySnapshotTable;
