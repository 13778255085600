import React from "react";

const MultiSelectFrequencyTypeRadioButton = ({
  value,
  onChange,
  label,
  id,
  buttonIcon
}) => (
  <li
    className={`frequency-item-${id}`}
    style={{ color: "#555" }}
    onClick={onChange}
  >
    <input type="checkbox" checked={value || false} readOnly />
    <label className="title" id={id}>
      <span className="button-icon">
        <i className={buttonIcon} />
      </span>
      {label}
    </label>
  </li>
);

export default MultiSelectFrequencyTypeRadioButton;
