import React from "react";
import { Switch, Route } from "react-router-dom";

import {
  EmailTextAlertsPage,
  EmailTextAlertsEditPage
} from "../emailtextalerts/pages";

const EmailTextAlertsRoutes = () => (
  <Switch>
    <Route path="/email-text-alerts" component={EmailTextAlertsPage} />
    <Route
      path="/email-text-alerts-page/:PageId"
      component={EmailTextAlertsEditPage}
    />
  </Switch>
);

export default EmailTextAlertsRoutes;
