import { combineReducers } from "redux";
import * as types from "../actions/index";
import allMenuList from "./allMenuList";

function activeCompany(state = null, action) {
  switch (action.type) {
    case types.COMPANY_SELECTED:
      return action.payload;

    default:
      return state;
  }
}

function companySelectionList(state = [], action) {
  switch (action.type) {
    case types.FETCH_COMPANY_LIST:
      return action.payload.data;

    default:
      return state;
  }
}

function activeRoleView(state = null, action) {
  switch (action.type) {
    case types.ROLE_VIEW_SELECTED:
      return action.payload;

    default:
      return state;
  }
}

function roleViewSelectionList(state = [], action) {
  switch (action.type) {
    case types.FETCH_ROLE_VIEW_LIST:
      return action.payload;

    default:
      return state;
  }
}

function previousLoginInfo(state = [], action) {
  switch (action.type) {
    case types.FETCH_PREVIOUS_LOGIN_INFO:
      return action.payload.data;

    default:
      return state;
  }
}

function productsSuiteList(state = [], action) {
  switch (action.type) {
    case types.FETCH_PRODUCTS_SUITE_LIST:
      return action.payload.data;

    default:
      return state;
  }
}

function userProfileInfo(state = [], action) {
  switch (action.type) {
    case types.FETCH_USER_PROFILE_INFO:
      return action.payload.data;

    default:
      return state;
  }
}

function sideOverlayPanel(state = null, action) {
  switch (action.type) {
    case types.SET_SIDE_OVERLAY_PANEL:
      return action.payload;

    default:
      return state;
  }
}

function productSuiteIcon(state = null, action) {
  switch (action.type) {
    case types.PRODUCT_SUITE_ICON:
      return action.payload;

    default:
      return state;
  }
}

function nthSideMenuList(state = [], action) {
  switch (action.type) {
    case types.NTH_SIDE_MENU_STATE:
      return action.payload;

    default:
      return state;
  }
}

function visitedSideMenuList(state = [], action) {
  switch (action.type) {
    case types.VISITED_SIDE_MENU_STATE:
      return action.payload;

    default:
      return state;
  }
}

function userTimeZoneOffset(state = {}, action) {
  switch (action.type) {
    case types.FETCH_TIMEZONE_OFFSET:
      return action.payload;
    default:
      return state;
  }
}

function userTimeZoneAbbreviation(state = "", action) {
  switch (action.type) {
    case types.FETCH_TIMEZONE_ABBREVIATION:
      return action.payload;
    default:
      return state;
  }
}

const navigationReducer = combineReducers({
  companySelectionList,
  activeCompany,
  roleViewSelectionList,
  activeRoleView,
  previousLoginInfo,
  productsSuiteList,
  userProfileInfo,
  sideOverlayPanel,
  productSuiteIcon,
  nthSideMenuList,
  visitedSideMenuList,
  allMenuList,
  userTimeZoneOffset,
  userTimeZoneAbbreviation
});

export default navigationReducer;
