import React, { Component } from "react";
import { connect } from "react-redux";
import * as Fields from "component-library/employee-profile/employment-info/alternate-emp-identification/FieldNames";
import ValidationModal from "component-library/employee-profile/employment-info/alternate-emp-identification/ValidationModal";
import EmpIdentificationSection from "component-library/employee-profile/employment-info/alternate-emp-identification/EmpIdentificationSection";
import { validateAndSaveAltEmpIdentificationInfo } from "../network/actions";

class AlternateEmpIdentification extends Component {
  state = {
    data: {},
    showValidationModal: false
  };

  componentDidMount() {
    const { errors = {} } = this.props;
    if (errors !== null) {
      alert(
        "An error has occurred when trying to view/change this employee's Alternate Employee Identification information. Please contact your Netchex representative for assistance."
      );
    }
  }

  onChange = e => {
    const data = { [e.name]: e.value };

    if (e.name === Fields.primaryEmployeeID) {
      data[e.name] = e.value;
    } else if (e.name === Fields.secondaryEmployeeID) {
      data[e.name] = e.value;
    } else if (e.name === Fields.formerEmployeeID) {
      data[e.name] = e.value;
    } else if (e.name === Fields.badgeNo) {
      data[e.name] = e.value;
    } else if (e.name === Fields.clockNo) {
      data[e.name] = e.value;
    }
    this.editData(data);
  };

  onSave = (data = {}) => {
    validateAndSaveAltEmpIdentificationInfo(data, () =>
      this.setState({
        showValidationModal: true
      })
    );
  };

  editData(edits) {
    this.setState({ data: { ...this.state.data, ...edits } });
  }

  render() {
    const { errors = {} } = this.props;
    const { AccessPermission } = this.props.data.InfoSection;

    return (
      <div className="alternate-employee-identification-container">
        <EmpIdentificationSection
          onChange={this.onChange}
          data={this.props.data}
          stateData={this.state.data}
          errors={errors}
        />
        <div style={{ position: "sticky", bottom: 0, float: "right" }}>
          <button
            style={{
              marginBottom: "20px",
              boxShadow: "2px 2px 15px #999"
            }}
            className={
              !AccessPermission ? `nc-button primary-button` : `disabled-button`
            }
            onClick={() => {
              !AccessPermission && this.onSave(this.state.data);
            }}
          >
            Save
          </button>
        </div>
        <ValidationModal
          show={this.state.showValidationModal}
          closeModal={() => {
            this.setState({
              showValidationModal: false
            });
          }}
          onSave={() => {
            this.setState({
              showValidationModal: false
            });
          }}
          errors={errors}
        />
      </div>
    );
  }
}
const stateToProps = state => {
  return {};
};

const actionCreators = {
  validateAndSaveAltEmpIdentificationInfo
};
export default connect(stateToProps, actionCreators)(
  AlternateEmpIdentification
);
