/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
const Toaster = ({
  id = "list-page-toaster",
  show,
  message,
  closeToasterMessage,
  alertClassName = "success",
  linkId,
  linkName,
  link,
  onClick,
  Id
}) => {
  let icon = "check";
  if (alertClassName === "danger") {
    alertClassName = "alert-danger";
    icon = "exclamation-triangle";
  } else if (alertClassName === "warning") {
    alertClassName = "alert-warning";
    icon = "exclamation-circle";
  } else if (alertClassName === "delete") {
    alertClassName = "alert-success";
    icon = "trash";
  } else if (alertClassName === "inactivate") {
    alertClassName = "alert-success";
    icon = "minus-circle";
  } else if (alertClassName === "inprogress") {
    alertClassName = "alert-success";
    icon = "clock-o";
  } else {
    alertClassName = "alert-success";
    icon = "check";
  }
  if (show) {
    return (
      <div
        className="toaster"
        style={{
          display: show ? "block" : "none",
          width: "100%",
          textAlign: "center"
        }}
      >
        <div
          id={Id ? Id : id}
          className={`alert ${alertClassName}`}
          style={{ display: "flex" }}
        >
          <div className="clearfix" style={{ flexGrow: 1 }}>
            <i className={`fa fa-${icon}`} />
            {message}

            {linkId && linkName && link ? (
              <span
                id={linkName}
                onClick={onClick}
                style={{ whiteSpace: "nowrap" }}
              >
                <a id={id + "-link"} className="active">
                  {" "}
                  {linkName}{" "}
                  {<i className="fa fa-caret-right" aria-hidden="true" />}
                </a>
              </span>
            ) : null}
          </div>
          {alertClassName !== "alert-warning" ? (
            <div className="pull-right close-icon">
              <i
                id={id + "-close-icon"}
                onClick={closeToasterMessage}
                className="fa fa-times"
              />{" "}
            </div>
          ) : null}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Toaster;
