import React from "react";
import PropTypes from "prop-types";

const ToggleExpandButton = ({ show = false, expanded = false, onClick }) =>
  show && (
    <div className="more-filter-link" style={{ marginLeft: 0 }}>
      <a href="." onClick={onClick}>
        {expanded ? (
          <button className="primary-link-button">
            <i className="fa fa-chevron-up" />Show Less
          </button>
        ) : (
          <button className="primary-link-button">
            <i className="fa fa-chevron-down" />Show More
          </button>
        )}
      </a>
    </div>
  );

ToggleExpandButton.propTypes = {
  show: PropTypes.bool,
  expanded: PropTypes.bool,
  onClick: PropTypes.func
};

export default ToggleExpandButton;
