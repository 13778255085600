import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import "./EarningsDeleteModal.css";

const EarningsDeleteModal = ({
  show,
  closeModal,
  name,
  entityType,
  submit,
  Id
}) => (
  <Modal
    bsClass="list-page-cancel modal"
    animation={false}
    backdrop={"static"}
    keyboard={false}
    show={show}
    restoreFocus={false}
  >
    <Modal.Header>
      <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <i
          id={Id.modalCloseIcon}
          onClick={closeModal}
          className="fa fa-times-circle-o fa-lg"
        />
      </div>
    </Modal.Header>
    <Modal.Body>
      <div className="clearfix">
        <div
          id={Id.modalMessage}
          className="modal-row row col-xs-12 col-sm-12 col-md-12 col-lg-12"
        >
          Are you sure you want to delete your '{name}' earnings? It will be
          gone for now, but you can add it back if you need it.
        </div>

        <div className="message-footer col-xs-12 col-sm-12 col-md-12 col-lg-12" />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <div className="button-group">
        <button
          type="button"
          id={Id.modalSuccessButton}
          className="nc-button primary-button"
          onClick={submit}
        >
          {"DELETE " + entityType}
        </button>
        <button
          type="button"
          id={Id.modalCancelButton}
          className="secondary-link-button"
          onClick={closeModal}
        >
          Cancel
        </button>
      </div>
    </Modal.Footer>
  </Modal>
);

export default EarningsDeleteModal;
EarningsDeleteModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  name: PropTypes.string,
  entityType: PropTypes.string,
  submit: PropTypes.func
};
EarningsDeleteModal.defaultProps = {
  submit: () => {},
  closeModal: () => {}
};
