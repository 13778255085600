import { connect } from "react-redux";
import AddDependentsContainer from "../containers/AddDependents";
import {
  fetchAndSetNameTitle,
  fetchAndSetSuffix,
  fetchAndSetGender,
  fetchAndSetCellPhoneCarrier,
  fetchAndSetCountry,
  fetchAndSetState
} from "../network/actions";
import { clearDemographicValues } from "../reducers/demographicData";

const stateToProps = state => ({
  data: state.personalInfo.demographicData,
  employeeDemographicData: state.people.employeeDashboard
});
const actionCreators = {
  fetchAndSetNameTitle,
  fetchAndSetSuffix,
  fetchAndSetGender,
  fetchAndSetCellPhoneCarrier,
  fetchAndSetCountry,
  fetchAndSetState,
  clearDemographicValues
};
export default connect(stateToProps, actionCreators)(AddDependentsContainer);
