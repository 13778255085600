import AddressVerificationOff from "./AddressVerificationOff";
import AddressVerificationOn from "./AddressVerificationOn";
import MinimumWageOff from "./MinimumWageOff";
import MinimumWageOn from "./MinimumWageOn";
import TaxVerificationOff from "./TaxVerificationOff";
import TaxVerificationOn from "./TaxVerificationOn";
import AddressInTitle from "./AddressInTitle";
import MinimumWageOffInTitle from "./MinimumWageOffInTitle";
import TaxVerificationInTitle from "./TaxVerificationInTitle";

export {
  AddressVerificationOff,
  AddressVerificationOn,
  MinimumWageOff,
  MinimumWageOn,
  TaxVerificationOff,
  TaxVerificationOn,
  AddressInTitle,
  MinimumWageOffInTitle,
  TaxVerificationInTitle
};
