/** Actions */
// export const ADD_TO_HISTORY = "activity-history/ADD_TO_HISTORY";
// export const CLEAR_HISTORY = "activity-history/CLEAR_HISTORY";
// export const SET_HISTORY_LOADING = "activity-history/SET_HISTORY_LOADING";

export const SET_ENTITY_LIST = "@app/common/SET_ENTITY_LIST";
export const CLEAR_ENTITY_LIST = "@app/common/CLEAR_ENTITY_LIST";
export const ADD_TO_ENTITY_LIST = "@app/common/ADD_TO_ENTITY_LIST";

export const initialState = {
  loading: true,
  Data: [],
  DataCount: 0,
  NextUri: null
};

// const filterNewData = (state, Data) => {
//   const ids = state.Data.map(a => a.ActivityInstanceId);
//   return Data.filter(d => ids.indexOf(d.ActivityInstanceId) === -1);
// };

/** Reducer */
const payrollHistory = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_ENTITY_LIST:
      return initialState;
    case ADD_TO_ENTITY_LIST: {
      const { Data, DataCount, NextUri } = payload;
      //const newData = [...state.Data, ...filterNewData(state, Data)];
      const newData = [...state.Data, ...Data];
      return {
        ...state,
        NextUri,
        Data: newData,
        DataCount: DataCount,
        loading: false
      };
    }
    case SET_ENTITY_LIST:
      return {
        ...state,
        loading: payload
      };
    default:
      return state;
  }
};

export default payrollHistory;

/** Action creators */
export const addToHistory = (Data, DataCount, NextUri) => ({
  type: ADD_TO_ENTITY_LIST,
  payload: { Data, DataCount, NextUri }
});

export const clearHistory = () => ({
  type: CLEAR_ENTITY_LIST
});

export const setHistoryLoading = loading => ({
  type: SET_ENTITY_LIST,
  payload: loading
});
