/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import GeneralLedgerDeductionDropdownEmployeeTab from "./components/GeneralLedgerDeductionDropdownEmployeeTab";
// import {getCategoryList} from '../../../actions/systemlevel/AdvanceFilterActions.js';
import {
  getEmployeeExpenceLiabilityAccountsFilteredList,
  setSelectedFilters,
  updatedEmployeeAccountFilteredList,
  getEmployeeExpenceLiabilityAccountsList
} from "../actions/generalledger/EmployeeLiabilityAccountsActions";
import {
  getEmployerExpenceLiabilityAccountsList,
  sortFilteredList
} from "../actions/generalledger/EmployerExpenceLiabilityAccountsActions";

import { Modal } from "react-bootstrap";
import * as utilities from "../../../app/common/utilities";

class EmployeeLiabilityAccountsAdvanceFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDeductions: [],
      unmappedCheckStateEmployeeTab: false,
      isResetToDefault: false,
      mobileFilterPanel: false,
      mobileSortPanel: false,
      sortItemList: [
        {
          id: 1,
          label: "Deductions : A to Z",
          sortTypeId: 1,
          sortKey: "CompanyDeductionName"
        },
        {
          id: 2,
          label: "Deductions : Z to A",
          sortTypeId: 2,
          sortKey: "CompanyDeductionName"
        }
      ]
    };
    this.setDeductions = this.setDeductions.bind(this);
    this.handleChangeUnmappedCheckStateEmployeeTab = this.handleChangeUnmappedCheckStateEmployeeTab.bind(
      this
    );
    this.applyFilter = this.applyFilter.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);

    this.props.getEmployeeExpenceLiabilityAccountsFilteredList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedDeductions !== this.state.selectedDeductions ||
      prevState.unmappedCheckStateEmployeeTab !==
        this.state.unmappedCheckStateEmployeeTab
    ) {
      this.getFilteredList();
    }
  }

  getFilteredList() {
    if (this.props.employeeExpenceLiabilityAccountsList.DataCount > 0) {
      this.setState({
        isResetToDefault: false
      });
      // if (this.props.employeeExpenceLiabilityAccountsList.DataCount > 0) {
      this.props.setSelectedFilters(this.state.selectedDeductions, []);
      this.props.getEmployeeExpenceLiabilityAccountsFilteredList(
        this.props.employeeExpenceLiabilityAccountsList.Data,
        this.state.selectedDeductions,
        this.state.unmappedCheckStateEmployeeTab
      );
      // }
    }
  }

  applyFilter() {
    this.getFilteredList();
    this.closeMobileFilterPanel();
  }

  closeMobileFilterPanel = () => {
    this.setState({ mobileFilterPanel: false });
  };
  openMobileFilterPanel = () => {
    this.setState({ mobileFilterPanel: true });
  };

  closeMobileSortPanel = () => {
    this.setState({ mobileSortPanel: false });
  };
  openMobileSortPanel = () => {
    this.setState({ mobileSortPanel: true });
  };

  setDeductions(selectedDeductions) {
    this.setState({
      selectedDeductions
    });

    this.props.onSelectedDeductions(selectedDeductions);
  }

  closeMobileFilterPanel() {
    this.refs.overlayMobileFilterPanel.hide();
  }

  closeMobileSortPanel() {
    this.refs.overlayMobileSortPanel.hide();
  }

  clearAllFilters() {
    this.setState({
      isResetToDefault: true,
      unmappedCheckStateEmployeeTab: false
    });
  }

  handleChangeUnmappedCheckStateEmployeeTab() {
    let selectedCheckBox = !this.state.unmappedCheckStateEmployeeTab;

    this.setState({
      unmappedCheckStateEmployeeTab: selectedCheckBox
    });
    this.props.getUnmappedCheckState(selectedCheckBox);
  }

  sortColumnByKey(item) {
    if (this.props.employeeExpenceLiabilityAccountsFilteredList.Data) {
      let sortedFilteredList = utilities.sortByKey(
        this.props.employeeExpenceLiabilityAccountsFilteredList.Data,
        item.sortKey
      );
      // this.props.employeeExpenceLiabilityAccountsFilteredList.Data = utilities.sortByKey(this.props.employeeExpenceLiabilityAccountsFilteredList.Data, item.sortKey);
      if (item.sortTypeId === 2) {
        sortedFilteredList = sortedFilteredList.reverse();
      }

      let request = {
        data: {
          Data: sortedFilteredList,
          DataCount: sortedFilteredList.length
        }
      };

      this.props.sortFilteredList(request);
    }

    this.closeMobileSortPanel();
  }

  showSortListItem() {
    return this.state.sortItemList.map((item, index) => {
      return (
        <div
          key={index}
          className="mobile-sort-panel-item row remove-margin-left-right"
          onClick={this.sortColumnByKey.bind(this, item)}
        >
          <div className="col-sm-12 col-xs-12">{item.label}</div>
        </div>
      );
    });
  }

  render() {
    const mobileFilterPanelNew = (
      <Modal
        id="mobile-filter"
        //bsClass="mobile-filter-modal"
        animation={false}
        backdrop={"static"}
        keyboard={false}
        show={this.state.mobileFilterPanel}
        onHide={this.closeMobileFilterPanel}
      >
        {/* <div className="modal-content "> */}
        <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="header-container col-sm-12 col-xs-12 clearfix">
            <div className="close-icon col-sm-3 col-xs-3">
              <i
                id="system-level-cancel-button-close-icon"
                onClick={this.closeMobileFilterPanel.bind(this)}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
            <div className="filter-label col-sm-6 col-xs-6"> All Filters </div>
            <div className="clear-all-link col-sm-3 col-xs-3">
              <a onClick={this.clearAllFilters}>Clear All</a>
            </div>
          </div>

          <div
            id="mobile-select-deduction-dropdown"
            className="mobile-filter-select-dropdown no-padding col-sm-12 col-xs-12"
          >
            <GeneralLedgerDeductionDropdownEmployeeTab
              onSelectedDeductions={selectedDeductions =>
                this.setDeductions(selectedDeductions)
              }
              isResetToDefault={this.state.isResetToDefault}
            />
          </div>
          <div
            id="mobile-select-category-dropdown"
            className="mobile-filter-select-dropdown no-padding col-sm-12 col-xs-12"
          >
            <div className="mobile-filter-panel-list-section">
              <div className="mobile-filter-panel-list-panel-bar col-xs-12 col-sm-12">
                <input
                  type="checkbox"
                  name="unmappedCheckStateEmployeeTab"
                  checked={this.state.unmappedCheckStateEmployeeTab}
                  onClick={this.handleChangeUnmappedCheckStateEmployeeTab}
                  value="unmappedCheckStateEmployeeTab"
                  id="show-only-unmapped-box"
                />
                <label
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                  htmlFor="show-only-unmapped-box"
                >
                  &nbsp; Show Only Unmapped
                </label>
                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-icon" />

                <div className="col-xs-8 col-sm-8 mobile-filter-panel-list-label no-padding" />

                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-badge no-padding" />
              </div>

              <div className="mobile-filter-panel-list-panel-bar-section" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="mobile-filter-panel-button-section">
            <button
              type="button"
              id="mobile-apply-filters"
              className="apply-filter-button"
              onClick={this.applyFilter}
            >
              APPLY FILTERS
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
    const mobileSortPanelNew = (
      <Modal
        id="mobile-sort"
        animation={false}
        backdrop={"static"}
        keyboard={false}
        show={this.state.mobileSortPanel}
        onHide={this.closeMobileSortPanel}
      >
        <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="header-container col-sm-12 col-xs-12 clearfix">
            <div className="close-icon col-sm-3 col-xs-3">
              <i
                id="system-level-cancel-button-close-icon"
                onClick={this.closeMobileSortPanel.bind(this)}
                className="fa fa-times-circle-o"
              />
            </div>
            <div className="sort-label col-sm-6 col-xs-6"> Sort By </div>
          </div>
          <hr />
          {this.showSortListItem()}
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    );
    let filterCount = 0;

    if (this.props.employeeExpenceLiabilityAccountsFilteredList) {
      if (
        this.props.employeeExpenceLiabilityAccountsFilteredList.DataCount ===
        this.state.selectedDeductions.length - 1
      ) {
        filterCount = filterCount + 0;
      } else {
        filterCount = filterCount + this.state.selectedDeductions.length;
      }
    }
    if (window.innerWidth <= 991) {
      return (
        <div>
          <div className="nc-sort-filter-container hidden-md hidden-lg col-sm-12 col-xs-12">
            <button onClick={this.openMobileSortPanel.bind()} className="">
              SORT BY
            </button>
            <button onClick={this.openMobileFilterPanel.bind()} className="">
              FILTERS ({filterCount})
            </button>
            {mobileFilterPanelNew}
            {mobileSortPanelNew}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div
            id="employee-paid-deductions-grey-filter-section"
            className="nc-filter-container hidden-sm hidden-xs"
          >
            <div className="showing-label">FILTER:</div>
            <div className="nc-filter-group">
              <div className="nc-filter-row">
                <div
                  id="select-deduction-dropdown"
                  className="nc-filter-dropdown"
                  style={{}}
                >
                  <GeneralLedgerDeductionDropdownEmployeeTab
                    onSelectedDeductions={selectedDeductions =>
                      this.setDeductions(selectedDeductions)
                    }
                    Id={"dd-list-page-filter-3"}
                  />
                </div>
                <div
                  id="employee-tab-deductions-show-only-unmapped-filter"
                  className="nc-filter-dropdown"
                  style={{ float: "left" }}
                >
                  <input
                    type="checkbox"
                    name="unmappedCheckStateEmployeeTab"
                    checked={this.state.unmappedCheckStateEmployeeTab}
                    onClick={this.handleChangeUnmappedCheckStateEmployeeTab}
                    value="unmappedCheckStateEmployeeTab"
                    id="employee-tab-show-only-unmapped"
                  />
                  <label
                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    style={{ marginTop: "8px" }}
                    htmlFor="employee-tab-show-only-unmapped"
                  >
                    &nbsp; Show Only Unmapped
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    // categoryList: state.categoryList,
    // systemLevelDeductionSetupList: state.systemLevelDeductionSetupList
    employeeExpenceLiabilityAccountsList:
      state.generalLedgerReducer.employeeExpenceLiabilityAccountsList,

    employeeExpenceLiabilityAccountsFilteredList:
      state.generalLedgerReducer.employeeExpenceLiabilityAccountsFilteredList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEmployeeExpenceLiabilityAccountsFilteredList: getEmployeeExpenceLiabilityAccountsFilteredList,
      setSelectedFilters: setSelectedFilters,
      updatedEmployeeAccountFilteredList,
      getEmployerExpenceLiabilityAccountsList,
      sortFilteredList,
      getEmployeeExpenceLiabilityAccountsList
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    EmployeeLiabilityAccountsAdvanceFilter
  )
);
