/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import StringMask from "string-mask";
import { Hooray } from "./images";
import PropTypes from "prop-types";
const contactNumber = () => {
  let formatter = new StringMask("(000)000-0000");
  let result = formatter.apply(CONTACT_NUMBER);
  return result;
};
const SERVICE_REPRESENTATIVE_EMAIL = "servicerepresentative@netchexonline.com";
const CONTACT_NUMBER = "7894561230";
const PayrollDashboardYearEndNoPayroll = ({
  onEmailClick,
  email,
  onLogCaseClick,
  onDownloadClick,
  onVisitYearEndClick
}) => (
  <div className="payroll-dash-summary-container">
    <div className="clearfix no-payroll-container">
      <div className="clearfix text-center no-payroll-container-header-img">
        <Hooray width="110" height="110" />
      </div>
      <div className="clearfix no-payroll-container-header">
        Horray! You have no more scheduled payrolls left in 2017.
      </div>
      <div className="clearfix no-payroll-container-body">
        Contact your service representative at {contactNumber()} or
        <a className="primary-link-button" onClick={onEmailClick}>
          {" "}
          {email}
        </a>{" "}
        to begin the rollover process or{" "}
        <a className="primary-link-button" onClick={onLogCaseClick}>
          log a case
        </a>{" "}
        in NetCommunity.
      </div>
      <hr />
      <div className="clearfix no-payroll-container-footer">
        <div className="clearfix">
          <a className="primary-link-button" onClick={onDownloadClick}>
            <i className="fa fa-download" /> Download the Year End Checklist
          </a>
        </div>
        <a className="primary-link-button" onClick={onVisitYearEndClick}>
          Visit Year End Central{" "}
        </a>
        for more information on the year end process.
      </div>
    </div>
  </div>
);
export default PayrollDashboardYearEndNoPayroll;
PayrollDashboardYearEndNoPayroll.propTypes = {
  onEmailClick: PropTypes.func.isRequired,
  onLogCaseClick: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  onVisitYearEndClick: PropTypes.func.isRequired
};
PayrollDashboardYearEndNoPayroll.defaultProps = {
  onEmailClick: () => alert("on Email Click"),
  onLogCaseClick: () => alert("on Log case Click"),
  onDownloadClick: () => alert("on Download Click"),
  onVisitYearEndClick: () => alert("on Visit Year End Click"),
  email: SERVICE_REPRESENTATIVE_EMAIL
};
