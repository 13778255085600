export const SET_PAYROLL_DASHBOARD_SESSION_DATA =
  "payroll/dashboard/SET_PAYROLL_DASHBOARD_SESSION_DATA";

const initialState = {
  loading: true,
  Data: [],
  DataCount: 0,
  NextUri: null
};

//Actions
export const setPayrollDashboardSessionData = Data => ({
  type: SET_PAYROLL_DASHBOARD_SESSION_DATA,
  payload: { Data }
});

// Reducers
const payrollDashboardSessionData = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case SET_PAYROLL_DASHBOARD_SESSION_DATA: {
      const { Data } = payload;
      const newData = [...state.Data, ...Data];
      return {
        ...state,
        Data: newData
      };
    }
    default:
      return state;
  }
};

export default payrollDashboardSessionData;
