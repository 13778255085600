import React from "react";
import PropTypes from "prop-types";
import Media from "react-media";
const HistoryButton = ({ onClick, style, iconStyle, showTitle, id }) => (
  <button
    id={id}
    className="nc-button outline-button-right pull-right"
    onClick={onClick}
    style={style}
  >
    <i style={iconStyle} className="fa fa-history" aria-hidden="true" />
    {showTitle ? "HISTORY" : <span className="hidden-xs">HISTORY</span>}
  </button>
);

const CopyButton = ({ onClick, style, iconStyle, id }) => (
  <button
    id={id}
    className="nc-button outline-button-right pull-right"
    onClick={onClick}
    style={style}
  >
    <i className="fa fa-clone" style={iconStyle} aria-hidden="true" />
    <span className="hidden-xs">COPY</span>
  </button>
);

const AddButton = ({ onClick, label, id }) => (
  <button
    id={id}
    type="button"
    onClick={onClick}
    className="nc-button primary-button pull-right"
  >
    <i className="fa fa-plus"> </i> {label}
  </button>
);

const EarningsHeader = ({
  title,
  description,
  addButtonLabel,
  onAddButtonClick,
  onLegacyAddButtonClick,
  onHistoryButtonClick,
  showButtons,
  onCopyEarning,
  hideCopy,
  showHistoryButton,
  Id
}) => {
  return (
    <div id="deduction-page-header-container">
      <div className="pageTitle-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div
          id={Id.pageTitle}
          className="pageTitle col-lg-4 col-md-4 col-sm-12 col-xs-12"
        >
          {title}
        </div>

        {showButtons && (
          <div className="nc-navigation-button-family hidden-sm hidden-xs col-lg-8 col-md-8 col-sm-12 col-xs-12">
            {hideCopy ? (
              showHistoryButton && (
                <HistoryButton
                  onClick={onHistoryButtonClick}
                  id={Id.historyButton}
                />
              )
            ) : (
                <span className="secondary-group">
                  {showHistoryButton && (
                    <HistoryButton
                      onClick={onHistoryButtonClick}
                      id={Id.historyButton}
                    />
                  )}
                  <CopyButton onClick={onCopyEarning} id={Id.copyButton} />
                </span>
              )}
            <AddButton
              onClick={onAddButtonClick}
              label={addButtonLabel}
              id={Id.addButton}
            />
            {onLegacyAddButtonClick &&
              <button
                id={Id.addButtonLegacy}
                type="button"
                onClick={onLegacyAddButtonClick}
                className="nc-button primary-link-button pull-right"
              >
                <i className="fa fa-plus"> </i> LEGACY ADD
            </button>
            }
          </div>
        )}
      </div>

      <div className="deduction-page-description-container">
        <div className="page-description col-lg-7 col-md-7 col-sm-12 col-xs-12">
          <p id={Id.pageDescription} style={{ margin: "0px" }}>
            {description}
          </p>
        </div>

        {showButtons && (
          <div className="deduction-page-description-button col-lg-6 col-md-5 col-sm-12 col-xs-12">
            <div className="nc-navigation-button-family col-xs-12 hidden-lg hidden-md">
              <div className="primary-group">
                <AddButton onClick={onAddButtonClick} label={addButtonLabel} />
                {onLegacyAddButtonClick &&
                  <button
                    id={Id.addButtonLegacy}
                    type="button"
                    onClick={onLegacyAddButtonClick}
                    className="nc-button primary-link-button pull-right"
                  >
                    <i className="fa fa-plus"> </i> LEGACY ADD
                </button>}
              </div>
              <div className="secondary-group">
                {showHistoryButton && (
                  <Media query="(max-width:767px)">
                    {isMobile =>
                      isMobile ? (
                        <HistoryButton
                          onClick={onHistoryButtonClick}
                          iconStyle={{ marginRight: hideCopy ? "" : "0px" }}
                          showTitle={hideCopy}
                          style={
                            !hideCopy
                              ? {
                                borderBottomLeftRadius: "0",
                                borderTopLeftRadius: "0"
                              }
                              : {}
                          }
                        />
                      ) : (
                          <HistoryButton
                            onClick={onHistoryButtonClick}
                            iconStyle={{ marginRight: "5px" }}
                            showTitle={hideCopy}
                            style={
                              !hideCopy
                                ? {
                                  borderBottomLeftRadius: "0",
                                  borderTopLeftRadius: "0"
                                }
                                : {}
                            }
                          />
                        )
                    }
                  </Media>
                )}

                {hideCopy ? (
                  ""
                ) : (
                    <Media query="(max-width:767px)">
                      {isMobile =>
                        isMobile ? (
                          <CopyButton
                            onClick={onCopyEarning}
                            iconStyle={{ marginRight: "0px" }}
                            style={{
                              marginRight: "0",
                              borderRight: "none",
                              borderBottomRightRadius: "0",
                              borderTopRightRadius: "0"
                            }}
                          />
                        ) : (
                            <CopyButton
                              onClick={onCopyEarning}
                              iconStyle={{ marginRight: "5px" }}
                              style={{
                                marginRight: "0",
                                borderRight: "none",
                                borderBottomRightRadius: "0",
                                borderTopRightRadius: "0"
                              }}
                            />
                          )
                      }
                    </Media>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EarningsHeader;
EarningsHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  addButtonLabel: PropTypes.string,
  onAddButtonClick: PropTypes.func,
  onLegacyAddButtonClick: PropTypes.func,
  onHistoryButtonClick: PropTypes.func,
  showButtons: PropTypes.bool,
  onCopyEarning: PropTypes.func,
  hideCopy: PropTypes.bool
};
EarningsHeader.defaultProps = {
  addButtonLabel: "ADD",
  onAddButtonClick: () => { },
  onHistoryButtonClick: () => { },
  showButtons: true,
  hideCopy: true,
  Id: ""
};
