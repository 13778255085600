import * as types from "../../../actions/companylevel/index";
import { combineReducers } from "redux";
function deductionTaxPriorityList(state = null, action) {
  switch (action.type) {
    case types.DEDUCTION_TAX_PRIORITY_LIST:
      return action.payload;
    case types.CLEAR_DEDUCTION_TAX_PRIORITY_LIST:
      return {};
    default:
      return state;
  }
}

const deductionTaxPriorityReducer = combineReducers({
  deductionTaxPriorityList
});
export default deductionTaxPriorityReducer;
