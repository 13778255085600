import React, { Component } from "react";
import { TabContainer } from "component-library/employee-profile/entity-view";
import StatusOverlay from "component-library/employee-profile/employment-info/StatusOverlay";
import ChangeStatus from "./ChangeStatus";
import ChangeWorkplaceInfo from "./ChangeWorkplaceInfo";
import { Switch, Route } from "react-router-dom";
import StatusInfo from "./StatusInfo";
import WorkplaceInfo from "./WorkplaceInfo";
import AlternateEmpIdentification from "./AlternateEmpIdentification";
const activePanelDefault = [
  {
    SectionItemId: 1,
    SectionItemName: "Employment History",
    SectionItemLink: [
      {
        Type: "badge",
        Name: "Employment History"
      },
      {
        Type: "text",
        Name: "Files"
      }
    ],
    SubSection: [
      {
        SubSectionTitle: "Prior Employment",
        SubSectionItemData: [
          {
            SectionItemId: 1,
            SectionItemTitle: "Graphic Designer",
            SectionItemDescription: "BOBS BURGERS - LOUISIANA",
            SectionItemDescriptionLine: "Oct 12, 2011 - Oct 17, 2016",
            SectionItemDescriptionLine2: "2 yrs 2 mos."
          },
          {
            SectionItemId: 2,
            SectionItemTitle: "Graphic Designer",
            SectionItemDescriptionLine: "July 29, 2011 - Oct 9, 2011",
            SectionItemDescriptionLine2: "2 yrs 2 mos.",
            SectionItemDescription: "Creativ space - Metairie, Louisiana"
          }
        ]
      }
    ]
  },
  {
    SectionItemId: 2,
    SectionItemName: "Workplace Info",
    SectionItemLink: [
      {
        Type: "badge",
        Name: "Workplace Info"
      },
      {
        Type: "chart",
        Name: "Job History"
      },
      {
        Type: "text",
        Name: "Files"
      }
    ],
    WorkplaceInfoSection: {
      CompanyName: "132 - (DEMO) ABC Company",
      Division: "102 - DivTest",
      BusinessUnit: "102 - BUTest",
      Department: "102 - DeptTest",
      Client: "Client Name",
      Project: "Web Redesign",
      Location: "BL - Netchex",
      AddressLine1: "1516 Huckbury Dr.",
      AddressLine2: "Metairie, LA 70001",
      PrimaryManager: "Jason Schock",
      SecondaryManager: "Summer Fiedler",
      WorkEmailAddress: "nancychristianson@gmail.com",
      WorkPhoneNumber: "1111111111",
      WorkPhoneNumberExt: "0123",
      WorkFaxNumber: "5042748587"
    }
  },
  {
    SectionItemId: 3,
    SectionItemName: "Alternate Employee Identification",
    SectionItemLink: [
      {
        Type: "badge",
        Name: "Alternate Employee Identification"
      },
      {
        Type: "text",
        Name: "Files"
      }
    ],
    InfoSection: {
      EmployeeCode: "nachristianson2",
      AccessPermission: false
    }
  }
];

class EmploymentInfo extends Component {
  state = {
    activePanel: {},
    isAddModeOn: false,
    showStatusOverlay: false,
    showWorkplaceOverlay: false,
    ActivePanelId: null,
    data: {}
  };

  componentDidMount() {
    this.props.setLoader(true);
  }

  render() {
    const { match, data, filters = {} } = this.props;

    const { ActivePanelId } = match.params;
    let activePanelId = parseInt(ActivePanelId, 10);
    let entityTitle = "";
    if (activePanelId === 17) {
      entityTitle = "Employee Status";
    } else if (activePanelId === 18) {
      entityTitle = "Workplace Info";
    } else if (activePanelId === 19) {
      entityTitle = "I-9 / Citizenship";
    } else if (activePanelId === 20) {
      entityTitle = "Alternate Employee Identification";
    } else if (activePanelId === 21) {
      entityTitle = "OSHA / Workers Compensation";
    } else if (activePanelId === 22) {
      entityTitle = "Company Property";
    }

    if (this.props.showStatusOverlay) {
      return (
        <StatusOverlay onCloseOverlay={this.props.toggleOverlay}>
          <ChangeStatus
            filters={filters}
            onAddLinkClick={this.onAddLinkClick}
            onCloseOverlay={this.props.toggleOverlay}
          />
        </StatusOverlay>
      );
    } else if (this.props.showWorkplaceOverlay) {
      return (
        <StatusOverlay
          title="Change Workplace Info"
          onCloseOverlay={this.props.toggleWorkplaceOverlay}
        >
          <ChangeWorkplaceInfo
            filters={filters}
            onAddLinkClick={this.onAddLinkClick}
            onCloseOverlay={this.props.toggleWorkplaceOverlay}
          />
        </StatusOverlay>
      );
    } else {
      return (
        <div>
          <TabContainer
            activePanel={data}
            activePanelHeader={entityTitle}
            onScroll={() => console.log("Scrolled")}
            isAddModeOn={this.state.isAddModeOn}
          >
            <Switch>
              <Route
                exact
                path="/people/employees/dashboard/:EmployeeId/employmentinfo"
                render={() => (
                  <StatusInfo toggleOverlay={this.props.toggleOverlay} />
                )}
              />
              <Route
                path="/people/employees/dashboard/:EmployeeId/employmentinfo/17"
                render={() => (
                  <StatusInfo toggleOverlay={this.props.toggleOverlay} />
                )}
              />
              <Route
                path="/people/employees/dashboard/:EmployeeId/employmentinfo/18"
                render={() => (
                  <WorkplaceInfo
                    toggleOverlay={this.props.toggleWorkplaceOverlay}
                    data={activePanelDefault[1]}
                  />
                )}
              />
              <Route
                path="/people/employees/dashboard/:EmployeeId/employmentinfo/20"
                render={() => (
                  <AlternateEmpIdentification data={activePanelDefault[2]} />
                )}
              />
            </Switch>
          </TabContainer>
        </div>
      );
    }
  }
}

export default EmploymentInfo;
