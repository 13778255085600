export const lastName = "LastName";
export const firstName = "FirstName";
export const birthDate = "BirthDate";
export const ageTo = "AgeTo";
export const ageFrom = "AgeFrom";
export const gender = "EmployeeGender";
export const employeeStatus = "status";
export const hireDate = "HireDate";
export const rehireDate = "RehireDate";
export const terminationDate = "TerminationDate";
export const reTerminationDate = "ReterminationDate";
export const inactiveStartDate = "InactiveStartDate";
export const inactiveEndDate = "InactiveEndDate";
export const leaveStartDate = "LeaveStartDate";
export const leaveEndDate = "LeaveEndDate";
export const transferDate = "TransferDate";
export const retiredDate = "RetiredDate";
export const deceasedDate = "DeceasedDate";
export const primaryManager = "PrimaryManager";
export const secondaryManager = "SecondaryManager";
export const employeeClassification = "EmployeeClassification";
export const employeeType = "EmployeeType";
export const employeePosition = "EmployeePosition";
export const payrollSchedule = "PayrollSchedule";
export const payrollFrequency = "PayrollFrequency";
export const employeeCompany = "company";
export const employeeDivision = "EmployeeDivision";
export const businessUnit = "EmployeeBussinessUnit";
export const employeeDepartment = "EmployeeDepartment";
export const employeeLocation = "EmployeeLocation";
export const timeGroup = "EmployeeTimeGroup";
export const employeeBadgeNumber = "EmployeeBadgeNumber";
export const employeeNumber = "EmployeeNumber";
export const clockNumber = "ClockNumber";
export const employeeSsn = "EmployeeSSN";
export const employeeCode = "EmployeeCode";
export const employeeClient = "EmployeeCode";
export const employeeProject = "EmployeeProject";
export const employeeFiltersList = "EmployeeFiltersList";

//basicFilters
export const basicFilterEmployeelist = "employee";
export const basicFilterStatuslist = "status";
export const basicFilterCompanylist = "company";
