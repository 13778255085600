import axios from "axios";
import * as common from "../../app/common/index";
const config = { headers: { "Content-Type": "application/json" } };
const BASE_WEB_URL = common.WEB_API_URL;
const BASE_URL = common.WEB_API_MOCK_URL;
const PAYROLL_DASHBOARD_ITEMS_PATH = "/app/ui/payroll/dashboard";
const mockTestDataPath = id => `usertest%2Fpayroll%2Fdashboard%2F${id}`;
const getPtoPath = id =>
  `/v1/subscriber/company/scheduledpayrollinstance/${id}/pto/search?_mock_=${mockTestDataPath(
    id
  )}`;

const getSendMessageApproverPath = id =>
  `/v1/subscriber/company/scheduledpayrollinstance/${id}/pto/search?_mock_=${mockTestDataPath(
    id
  )}`;

const getTimecardPath = id =>
  `/v1/subscriber/company/scheduledpayrollinstance/${id}/timecard/alert/search?_mock_=${mockTestDataPath(
    id
  )}`;
const getEmployeeUpdatesPath = id =>
  `/v1/subscriber/company/scheduledpayrollinstance/${id}/employee/alert/search?_mock_=${mockTestDataPath(
    id
  )}`;

const getEmployeeDataPath = id => ``;

const getapprovePtosPath = id =>
  `/v1/subscriber/company/scheduledpayrollinstance/${id}/pto/action/approve`;

const getaddressVerificationPath = id =>
  `/v1/subscriber/company/scheduledpayrollinstance/${id}/addressverification/search`;

const getminimumwagesalertPath = id =>
  `/v1/subscriber/company/scheduledpayrollinstance/${id}/minimumwagealert/search`;

const gettaxverificationPath = id =>
  `/v1/subscriber/company/scheduledpayrollinstance/${id}/taxverification/search`;

//Need to change this path when service gets ready
const getPayrollStartOverPath = id =>
  `/v1/subscriber/company/scheduledpayrollinstance/${id}/pto/action/approve`;

const getapproveTimecardPath = id =>
  `/v1/subscriber/company/scheduledpayrollinstance/${id}/timecard/action/approve?_mock_=${mockTestDataPath(
    id
  )}`;
const getStartPayrollPath = id =>
  `/v1/subscriber/company/scheduledpayrollinstance/${id}/action/startpayroll?_mock_=${mockTestDataPath(
    id
  )}`;

// for employees drop-down
const EMPLOYEE_LIST =
  "/v1/subscriber/company/scheduledpayrollinstance/payrolldashboardemployees/";
export const getEmployeeList = (id, PayPeriodToDate, PayPeriodFromDate) =>
  axios.get(
    BASE_URL +
      EMPLOYEE_LIST +
      `${id}?PayPeriodToDate=${PayPeriodToDate}&PayPeriodFromDate=${PayPeriodFromDate}`
  );

// for Types drop-down
const TYPES_LIST =
  "/v1/subscriber/company/scheduledpayrollinstance/unapprovedptotypes";
export const getTypesList = (filter = "ALL") =>
  axios.get(BASE_URL + TYPES_LIST + "?filter=" + filter);

// for Time Groups drop-down
const TIME_GROUPS_LIST =
  "/v1/subscriber/company/scheduledpayrollinstance/timecardalertstimegroup";
export const getTimeGroupsList = () => axios.get(BASE_URL + TIME_GROUPS_LIST);

// for Time Periods drop-down
const TIME_PERIODS_LIST =
  "/v1/subscriber/company/scheduledpayrollinstance/timecardalertstimeperiod";
export const getTimePeriodsList = () => axios.get(BASE_URL + TIME_PERIODS_LIST);

// for Pages drop-down
const PAGES_LIST =
  "/v1/subscriber/company/scheduledpayrollinstance/employeeupdatepages";
export const getPagesList = () => axios.get(BASE_URL + PAGES_LIST);

// for Fields drop-down
const FIELDS_LIST =
  "/v1/subscriber/company/scheduledpayrollinstance/employeeupdatefields/";
export const getFieldsList = id => axios.get(BASE_URL + FIELDS_LIST + `/${id}`);

//for states drop-down
export const getState = countryId =>
  axios.get(BASE_WEB_URL + `/v1/country/` + countryId + `/state`);

//search post for un-approved PTO tab
export const searchPostPathUnapprovedPto = `/v1/subscriber/company/scheduledpayrollinstance/`;

export const searchPostsUnapprovedPto = (params, id) =>
  axios.post(
    BASE_URL + searchPostPathUnapprovedPto + `${id}/pto/search`,
    JSON.stringify(params),
    { config }
  );

//search post for time card alerts tab
export const searchPostPathTimecardAlerts = `/v1/subscriber/company/scheduledpayrollinstance/`;

export const searchPostsTimecardAlerts = (params, id) =>
  axios.post(
    BASE_URL +
      searchPostPathTimecardAlerts +
      `${id}/timecard/alert/search?_mock_=${mockTestDataPath(id)}`,
    JSON.stringify(params),
    { config }
  );

//search post for employee update tab
export const searchPostPathEmployeeUpdates = `/v1/subscriber/company/scheduledpayrollinstance/`;

export const searchPostsEmployeeUpdates = (params, id) =>
  axios.post(
    BASE_URL +
      searchPostPathEmployeeUpdates +
      `${id}/employee/alert/search?_mock_=${mockTestDataPath(id)}`,
    JSON.stringify(params),
    { config }
  );

export const getFilterOptions = (pathGetter, params = {}, baseUrl = BASE_URL) =>
  axios.get(BASE_URL + pathGetter, {
    params: {
      ...params
    }
  });

const value = {
  SearchId: 0,
  SessionContextId: 0,
  SubscriberId: 0,
  SubscriberCompanyId: 0,
  AppRoleId: 0,
  SearchRequestJson: "string",
  SearchRequest: {
    Fields: ["string"],
    Criterion: [
      {
        Field: "string",
        Operator: "string",
        Value: {}
      }
    ],
    Sort: [
      {
        Field: "string",
        Order: "string"
      }
    ],
    PageSize: 0
  },
  IsActive: true,
  IsNew: true,
  IsDeleted: true,
  LastModifiedDate: "2017-12-13T07:37:48.328Z",
  LastModifiedBy: 0,
  LastModifiedByUserId: 0,
  LastModifiedViaSource: "string",
  ActivityInstanceId: 0,
  RowAction: 0,
  DeleteTag: "string"
};
export const getPayrollDashboardItems = payrollDashboardUri =>
  axios.get(
    BASE_URL + PAYROLL_DASHBOARD_ITEMS_PATH + "?_mock_=" + payrollDashboardUri
  );

const PAYROLL_DASHBOARD_SESSION_DATA_PATH = "/app/ui/payroll/dashboard";
export const getPayrollDashboardSessionData = () =>
  axios.get(BASE_URL + PAYROLL_DASHBOARD_SESSION_DATA_PATH + "?_mock_");
// Payroll Summary Items
const SCEDULED_INSTANCE_PATH =
  "/v1/subscriber/company/scheduledpayrollinstance/search";

// I have written unschedule service endpoint but we need to change this
// when actual service provided by service team.
const getUnScheduledInstancePath = id =>
  `/v1/subscriber/company/unscheduledpayrollinstance/search?_mock_=${mockTestDataPath(
    id
  )}`;

export const getScheduledPayrollInstanceData = () =>
  axios.post(BASE_URL + SCEDULED_INSTANCE_PATH, { value }, config);

export const getUnScheduledPayrollInstanceData = (id = 1) =>
  axios.post(BASE_URL + getUnScheduledInstancePath(id), { value }, config);

export const getMorePayrollDashboardItems = NextUri => axios.get(NextUri);
// Unapproved Ptos
export const getUnapprovedPtoItems = (id = 1) =>
  axios.post(BASE_URL + getPtoPath(id), { value }, config);
export const getMoreUnapprovedPtoItems = NextUri => axios.get(NextUri);

export const sendMessageToApprover = (id = 1) =>
  axios.post(BASE_URL + getSendMessageApproverPath(id), { value }, config);

//Timecard Alerts
export const getTimecardAlerts = (id = 1) =>
  axios.post(BASE_URL + getTimecardPath(id), { value }, config);

export const getMoreTimecardAlerts = NextUri => axios.get(NextUri);
// Employee Updates
export const getEmployeeUpdates = (id = 1) =>
  axios.post(BASE_URL + getEmployeeUpdatesPath(id), { value }, config);
export const getMoreEmployeeUpdates = NextUri => axios.get(NextUri);

export const getEmployeeData = (id = 1) =>
  axios.post(BASE_URL + getEmployeeDataPath(id), { value }, config);

export const approvePtos = id =>
  axios.post(BASE_URL + getapprovePtosPath(id), { value }, config);

export const getaddressverificationItems = id =>
  axios.post(BASE_URL + getaddressVerificationPath(id), { value }, config);

export const getminimumwagesalertItems = id =>
  axios.post(BASE_URL + getminimumwagesalertPath(id), { value }, config);

export const gettaxverificationItems = id =>
  axios.post(BASE_URL + gettaxverificationPath(id), { value }, config);

export const makePayrollStartOver = id =>
  axios.post(BASE_URL + getPayrollStartOverPath(id), { value }, config);

export const approveTimecard = id =>
  axios.post(BASE_URL + getapproveTimecardPath(id), { value }, config);

export const startPayroll = id =>
  axios.post(BASE_URL + getStartPayrollPath(id), { value }, config);
if (process.env.NODE_ENV === "development") {
  window.dashboard = {
    getScheduledPayrollInstanceData,
    getUnScheduledPayrollInstanceData,
    getPayrollDashboardSessionData,
    getPayrollDashboardItems,
    getUnapprovedPtoItems,
    getTimecardAlerts,
    getEmployeeUpdates,
    getaddressverificationItems,
    getminimumwagesalertItems,
    gettaxverificationItems,
    approvePtos,
    sendMessageToApprover,
    getEmployeeList, //employee filter drop-down
    getTypesList,
    getTimeGroupsList,
    getTimePeriodsList,
    getPagesList,
    getFieldsList,
    getEmployeeData
  };
}
