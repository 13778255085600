export const SET_ACTIVITY_INSTANCE_SNAPSHOT = "SET_ACTIVITY_INSTANCE_SNAPSHOT";
export const CLEAR_ACTIVITY_INSTANCE_SNAPSHOT =
  "CLEAR_ACTIVITY_INSTANCE_SNAPSHOT";

const initialState = {
  loading: true
};

const activityInstanceSnapshot = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACTIVITY_INSTANCE_SNAPSHOT:
      return payload;
    case CLEAR_ACTIVITY_INSTANCE_SNAPSHOT:
      return initialState;
    default:
      return state;
  }
};

export default activityInstanceSnapshot;

export const setActivityInstanceSnapshot = data => ({
  type: SET_ACTIVITY_INSTANCE_SNAPSHOT,
  payload: data
});

export const clearActivityInstanceSnapshot = () => ({
  type: CLEAR_ACTIVITY_INSTANCE_SNAPSHOT
});
