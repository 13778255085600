import React from "react";
import PropTypes from "prop-types";
const BasicRadioButton = ({
  id,
  wrapperId,
  label,
  description,
  name,
  onChange,
  onPress,
  value,
  checked,
  disabled,
  radioStyles,
  inlineStyles,
  type,
  AddressLine1,
  AddressLine2,
  AddressCity,
  AddressState,
  ZipCode,
  CategoryType,
  faIcon,
  PaymentType,
  width,
  buttonIcon
}) => {
  var style = " ";
  let disabledValue = disabled ? disabled : false;
  if (type === "description") {
    style = "nc-radio-description-button";
  } else if (type === "descriptionLongTitle") {
    style = "nc-radio-description-long-title-button";
  } else if (type === "one-line-title") {
    style = "nc-radio-title-button";
  } else if (type === "two-line-title") {
    style = "nc-radio-two-title-button";
  } else if (type === "three-line-title") {
    style = "nc-radio-three-title-button";
  } else if (type === "longDescription") {
    style = "nc-radio-long-description-button";
  } else if (type === "heavy") {
    style = "nc-radio-heavy-button";
  } else if (type === "descriptionTitle") {
    style = "nc-radio-description-title-button";
  } else if (type === "basicLong") {
    style = "nc-radio-basic-long-button";
  }
  if (!checked) {
    var className = "rb-unselected";
  } else {
    className = "rb-selected";
  }
  if (disabledValue) {
    className = className + "  __disabled-radio-button";
  }

  if (type === "basic") {
    return (
      <div className="single-radio-button" id={wrapperId}>
        <div
          className={
            `nc-radio-button ` + className + ` ` + style + ` ` + radioStyles
          }
          style={{ width: width, ...inlineStyles }}
        >
          <label id={id} className="nc-radio-button-row">
            <div className="input-radio">
              <input
                checked={checked}
                className="radio"
                name={name}
                onChange={e => onChange && onChange(e.target, value)}
                type="radio"
                value={value}
                disabled={disabledValue}
              />
              <span>
                <span className="checked" />
              </span>
            </div>
            {type === "heavy" ? (
              <div className="radio-content title">
                <div className="titl">{label}</div>
                <div className="">{AddressLine1}</div>
                <div className="">{AddressLine2}</div>
                <div className="">
                  {AddressCity}, {AddressState} {ZipCode}
                </div>
                <div className="indent25">{CategoryType}</div>
                <div className="fa-icon" id="faicon">
                  <i className={`fa fa-` + faIcon} /> {PaymentType}
                </div>
              </div>
            ) : (
              <div className="radio-content">
                {buttonIcon && (
                  <div className="button-icon">
                    <i className={buttonIcon} />
                  </div>
                )}
                <div className="title">{label}</div>
                <div className="description">{description}</div>
              </div>
            )}
          </label>
        </div>
      </div>
    );
  } else {
    return (
      <div
        id={wrapperId}
        className={`nc-radio-button ` + className + ` ` + style}
        style={{ width: width }}
      >
        <label id={id} className="nc-radio-button-row">
          <div className="input-radio">
            <input
              checked={checked}
              className="radio"
              name={name}
              onChange={e => onChange && onChange(e.target)}
              type="radio"
              value={value}
              disabled={disabledValue}
              title={label}
            />
            <span>
              <span className="checked" />
            </span>
          </div>
          {type === "heavy" ? (
            <div className="radio-content title">
              <div className="titl">{label}</div>
              <div className="">{AddressLine1}</div>
              <div className="">{AddressLine2}</div>
              <div className="">
                {AddressCity}, {AddressState} {ZipCode}
              </div>
              <div className="indent25">{CategoryType}</div>
              <div className="fa-icon" id="faicon">
                <i className={`fa fa-` + faIcon} /> {PaymentType}
              </div>
            </div>
          ) : (
            <div className="radio-content">
              <div className="title">{label}</div>
              <div className="description">{description}</div>
            </div>
          )}
        </label>
      </div>
    );
  }
};
export default BasicRadioButton;

BasicRadioButton.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  label: PropTypes.string,
  description: PropTypes.string,
  disabledValue: PropTypes.bool,
  type: PropTypes.string,
  AddressLine1: PropTypes.string,
  AddressLine2: PropTypes.string,
  AddressCity: PropTypes.string,
  AddressState: PropTypes.string,
  ZipCode: PropTypes.string,
  CategoryType: PropTypes.string,
  faIcon: PropTypes.string,
  PaymentType: PropTypes.string
};
