/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import * as Id from "./FieldsId";
import PropTypes from "prop-types";
import "./styles.css";

const AddButton = ({ onClick, label }) => (
  <button
    id={Id.AddButton}
    type="button"
    onClick={onClick}
    className="nc-button primary-button pull-right"
  >
    <i className="fa fa-plus"> </i> {label}
  </button>
);
const OnboardButton = ({ onClick, style, iconStyle, showTitle }) => (
  <button
    id={Id.OnboardButton}
    className="nc-button outline-button-right pull-right"
    onClick={onClick}
    style={style}
  >
    <i style={iconStyle} className="fa fa-paper-plane" aria-hidden="true" />
    {showTitle ? "Onboard" : <span className="hidden-xs">Onboard</span>}
  </button>
);

const ImportButton = ({ onClick, style, iconStyle }) => (
  <button
    id={Id.ImportButton}
    className="nc-button outline-button-right pull-right"
    onClick={onClick}
    style={style}
  >
    <i className="fa fa-upload" style={iconStyle} aria-hidden="true" />
    <span className="hidden-xs">IMPORT</span>
  </button>
);
class Header extends Component {
  state = { showAddButtonDropdown: false };
  handleToggle = () => {
    this.setState({ showAddButtonDropdown: !this.state.showAddButtonDropdown });
  };
  render() {
    const {
      title,
      showButtons,
      switchView,
      activeView,
      onImportClick,
      onBoardClick,
      onAddClick
    } = this.props;
    return (
      <div className="clearfix" id="employee-page-header-container">
        <div className="pageTitle-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div
            id={Id.pageTitleId}
            className="pageTitle col-lg-6 col-md-7 col-sm-8 col-xs-12"
          >
            {title}
          </div>

          {showButtons && (
            <div className="nc-navigation-button-family hidden-sm hidden-xs col-lg-6 col-md-5 col-sm-12 col-xs-12">
              <span className="secondary-group">
                <ImportButton onClick={onImportClick} />
                <OnboardButton onClick={onBoardClick} />
              </span>

              <AddButton onClick={onAddClick} label="Add" />
            </div>
          )}
        </div>
        <div className="deduction-page-description-container">
          <div className="page-description col-lg-7 col-md-7 col-sm-12 col-xs-12">
            <p style={{ margin: "0px" }}>
              {" "}
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
        </div>
        <div className="view-selector-bar col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="view-label">View:</div>
          <div className="view-icons">
            <a
              className={
                activeView === "list"
                  ? "primary-link-button"
                  : "secondary-link-button"
              }
              onClick={() => switchView("list-view", "list")}
            >
              <i className="fa fa-list-ul" />
              List
            </a>
            <a
              className={
                activeView === "tree"
                  ? "primary-link-button"
                  : "secondary-link-button"
              }
              onClick={() => switchView("tree-view", "tree")}
            >
              {" "}
              <i className="fa fa-sitemap fa-rotate-270" />
              Tree
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
Header.propTypes = {
  title: PropTypes.string.isRequired,
  showButtons: PropTypes.bool.isRequired,
  switchView: PropTypes.func,
  activeView: PropTypes.string
};
Header.defaultProps = {
  title: "Employees",
  showButtons: true,
  switchView: () => {},
  activeView: "list",
  onBoardClick: () => alert("Specify the onBoard Click Handler"),
  onImportClick: () => alert("Specify the Import Click Handler"),
  onAddClick: () => alert("Specify the add click handler")
};
