/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { INACTIVE, INPROGRESS } from "./Constants";
import classnames from "classnames/bind";
const tooltip_thrash_icon = (
  <Tooltip id="tooltip_thrash_icon">Delete/Inactivate</Tooltip>
);
const tooltip_edit_icon = (
  <Tooltip id="tooltip_edit_icon">Edit Earnings</Tooltip>
);
const tooltip_edit_icon_legacy = (
  <Tooltip id="tooltip_edit_icon_legacy">Edit Earnings (Legacy)</Tooltip>
);

const tooltip_standard_category_column = (
  <Tooltip id="tooltip_standard_category_column">
    Labor, Tips, Bonus, etc.
  </Tooltip>
);
const tooltip_fringe_category_column = (
  <Tooltip id="tooltip_fringe_category_column">
    Group Term Life, Allowances, etc.
  </Tooltip>
);
const tooltip_leave_category_column = (
  <Tooltip id="tooltip_leave_category_column">
    Sick, Personal, Vacation, Holiday, etc.
  </Tooltip>
);
const tooltip_reimbursements_category_column = (
  <Tooltip id="tooltip_reimbursements_category_column">
    Business Expenses, Moving Expenses, etc.
  </Tooltip>
);
const tooltip_other_category_column = (
  <Tooltip id="tooltip_other_category_column">
    Third-Party Sick, Draws, etc.
  </Tooltip>
);

const tooltip_inprogress_status_column = (
  <Tooltip id="tooltip_inprogress_status_icon">
    Earnings creation has started but has not been completed. Earnings cannot be
    assigned to an employee or used in payroll.
  </Tooltip>
);
const tooltip_active_status_column = (
  <Tooltip id="tooltip_active_status_column">
    Earnings can be assigned to an employee and can be used in payroll.
  </Tooltip>
);

const tooltip_inactive_status_column = (
  <Tooltip id="tooltip_inactive_status_column">
    Earnings has been previously assigned to an employee or used in payroll but
    is currently not active. Earnings cannot be assigned to an employee or used
    in payroll unless made active.
  </Tooltip>
);

function getCategoryTooltip(category) {
  switch (category) {
    case "Standard":
      return tooltip_standard_category_column;
    case "Fringe Benefits":
      return tooltip_fringe_category_column;
    case "Leave":
      return tooltip_leave_category_column;
    case "Reimbursements":
      return tooltip_reimbursements_category_column;
    case "Other":
      return tooltip_other_category_column;
    default:
      return null;
  }
}
function getStatusTooltip(status) {
  switch (status) {
    case "Active":
      return tooltip_active_status_column;
    case "Inactive":
      return tooltip_inactive_status_column;
    case "In Progress":
      return tooltip_inprogress_status_column;
    default:
      return null;
  }
}

const EarningsItem = ({
  item,
  onEditClick,
  onLegacyEditClick,
  onDeleteClick,
  onReactivateClick,
  onItemClick,
  Id,
  entityType
}) => (
    <div
      className="table-row col-lg-12 col-md-12 col-sm-12 col-xs-12"
      style={{
        lineHeight:
          item.status === "inactive" && window.innerWidth > 991 ? "1.5" : null
      }}
    >
      <div
        className={
          "col-lg-8 col-md-8 col-sm-7 " + (item.statusId === INACTIVE
            ? "col-xs-12"
            : "col-xs-7")
        }
      >
        <div
          className={classnames(
            "col-lg-4 col-md-4 col-sm-12 col-xs-12 name-column table-cell",
            item.statusId === INPROGRESS && "__no-hover"
          )}
        >
          <a onClick={onItemClick} id={Id.nameColumnItem + item.id}>
            {item.name}
          </a>
          <div className="name-code-column">
            {item.code}
          </div>
        </div>
        <div className="category-container col-lg-3 col-md-3 col-sm-12 col-xs-12 table-cell">
          <span className="hidden-lg hidden-md field-header">Category : </span>
          <OverlayTrigger
            placement="top"
            overlay={getCategoryTooltip(item.category)}
          >
            <span
              id={Id.categoryColumnItem + item.categoryId}
              className="field-value"
            >
              {item.category}
            </span>
          </OverlayTrigger>
        </div>
        <div className="status-container col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <span className="hidden-lg hidden-md field-header">Calculation Method : </span>
          <span
            id={Id.calculationMethodColumnItem + item.statusId}
            className={`field-value`}
          >
            {item.calculationMethod}
          </span>
        </div>
        <div className="status-container col-lg-2 col-md-2 col-sm-12 col-xs-12">
          <span className="hidden-lg hidden-md field-header">Status : </span>
          <OverlayTrigger placement="top" overlay={getStatusTooltip(item.status)}>
            <span
              id={Id.statusColumnItem + item.statusId}
              className={`field-value status-tag ` + item.status.toLowerCase()}
            >
              {item.status}
            </span>
          </OverlayTrigger>
        </div>
        {item.status.toLowerCase() === "inactive" ? (
          <div className="col-xs-12 col-sm-12 reactivate-mobile-button hidden-sm hidden-md hidden-lg">
            <button
              id={"entity-reactivate-" + item.id}
              className="nc-button outline-button reactivate-button"
              onClick={onReactivateClick}
            >
              REACTIVATE
          </button>
          </div>
        ) : null}
      </div>
      <div className="col-lg-4 col-md-4 col-sm-5 col-xs-5 list-page-grid-table-cell-actions">
        {item.status.toLowerCase() === "inactive" ? (
          <button
            id={Id.reactiveItemButton + item.id}
            className="nc-button outline-button reactivate-button hidden-xs pull-right"
            onClick={onReactivateClick}
          >
            REACTIVATE
        </button>
        ) : (
            <div>
              <div className="list-page-delete-section">
                {item.canDelete ? (
                  <OverlayTrigger placement="top" overlay={tooltip_thrash_icon}>
                    <a
                      href="."
                      onClick={e => {
                        e.preventDefault();
                        onDeleteClick && onDeleteClick();
                      }}
                    >
                      <i
                        id={Id.deleteItemIcon + item.id}
                        className="list-page-delete-icon fa fa-trash-o"
                      />
                    </a>
                  </OverlayTrigger>
                ) : (
                    <a>
                      <i
                        id={Id.deleteItemIcon + item.id}
                        className="list-page-delete-icon fa fa-trash-o fa-icon-disable"
                      />
                    </a>
                  )}
              </div>
              <div className="list-page-edit-section">
                {item.canEdit ? (
                  <OverlayTrigger placement="top" overlay={tooltip_edit_icon}>
                    <a
                      href="."
                      onClick={e => {
                        e.preventDefault();
                        onEditClick && onEditClick();
                      }}
                    >
                      <i
                        id={Id.editItemIcon + item.id}
                        className="list-page-edit-icon fa fa-pencil"
                      />
                    </a>
                  </OverlayTrigger>
                ) : (
                    <a>
                      <i
                        id={Id.editItemIcon + item.id}
                        className="list-page-edit-icon fa fa-pencil fa-icon-disable"
                      />
                    </a>
                  )}
              </div>
            </div>
          )}
        {onLegacyEditClick &&
          <div className="list-page-edit-section">
            {item.canEdit ? (
              <OverlayTrigger placement="top" overlay={tooltip_edit_icon_legacy}>
                <a
                  href="."
                  onClick={e => {
                    e.preventDefault();
                    onLegacyEditClick(item.code);
                  }}
                >
                  <i
                    id={Id.editItemIconLegacy + item.id}
                    className="list-page-edit-icon fa fa-pencil"
                  />
                  <span className="list-page-edit-icon">&nbsp; Legacy Edit</span>
                </a>
              </OverlayTrigger>
            ) : (
                <a>
                  <i
                    id={Id.editItemIconLegacy + item.id}
                    className="list-page-edit-icon fa fa-pencil fa-icon-disable"
                  />
                  <span className="list-page-edit-icon fa-icon-disable">&nbsp; Legacy Edit</span>
                </a>
              )}
          </div>
        }
      </div>
    </div>
  );

export default EarningsItem;
