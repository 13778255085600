import React, { Component } from "react";
import moment from "moment";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import {
  basicInfo,
  BASIC_INFO,
  LABEL,
  Age,
  TITLE,
  SSN,
  DOB,
  UNMASKED_SSN,
  AGE
} from "../Constants";

class BasicInfo extends Component {
  state = {
    showSSN: false,
    showDOB: false
  };

  handleChange = k => {
    const name = k.target && k.target.id;
    if (name === SSN && !this.state.showSSN) {
      this.setState({
        showSSN: true
      });
    } else if (name === DOB) {
      this.setState({ showDOB: true });
    } else {
      this.props.onChange(k);
    }
  };
  render() {
    const { data } = this.props;
    const { initialData } = data;
    const componentProps = basicInfo;
    componentProps[TITLE].radio = initialData.nameTitle || [];
    componentProps.suffix.options = initialData.nameSuffix || [];
    if (this.state.showSSN) {
      componentProps[SSN].inputName = UNMASKED_SSN;
      componentProps[SSN].inputId = UNMASKED_SSN;
      componentProps[SSN].inputType = "text";
    }
    if (this.state.showDOB) {
      data[DOB] = moment(initialData.demographic.BirthDate).format(
        "MM/DD/YYYY"
      );
      componentProps[DOB].inputType = LABEL;
      data[AGE] =
        moment().diff(moment(initialData.demographic.BirthDate), "year") +
        "yrs. old";
      componentProps[AGE] = Age;
    }
    const basicInfoNodes = Object.keys(componentProps).map(k => {
      return (
        <FormFieldGroup
          key={k}
          {...componentProps[k]}
          block
          colSpan="col-md-5"
          value={data[componentProps[k]["inputName"]]}
          onChange={this.handleChange}
          error={data.errors[componentProps[k]["inputName"]]}
        />
      );
    });
    return (
      <FormSection title={BASIC_INFO}>
        <div className="col-md-6" style={{ padding: "0px" }}>
          {basicInfoNodes}
        </div>
      </FormSection>
    );
  }
}

export default BasicInfo;
