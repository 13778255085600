/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import DropDown from "../dropDown";
import { FILTER_LABELS, PAGE_FILTERS } from "./Constants";
import { Badge } from "react-bootstrap";
import DateRangePicker from "../daterangepicker";
import FilterUnmappedOption from "./FilterUnmappedOption";

const labelMaker = (noLabel, allLabel, someLabel, oneLabel) => (o, s, all) =>
  all
    ? allLabel
    : Object.keys(s).filter(k => s[k]).length === 0
    ? noLabel
    : Object.keys(s).filter(k => s[k]).length === 1
    ? oneLabel
    : someLabel;

export default class EntityListFiltersDesktop extends Component {
  render() {
    const {
      options = {},
      selectedOptions = {},
      makeOnSelect = () => undefined,
      makeSelectAll = () => undefined,
      page = "Earnings",
      toggleAdvancedFilters,
      showAdvancedLinks = false,
      onClose = () => undefined,
      filterData,
      clearAdvancedFilters,
      advancedFilterCount,
      showClearFilters,
      getFromToValue,
      width,
      showUnmapped,
      unmappedId,
      showUnmappedName
    } = this.props;
    const finalFilterData = filterData || PAGE_FILTERS[page];
    return (
      <div
        className="nc-filter-container hidden-sm hidden-xs"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="showing-label" id="filter-label">
          {" "}
          FILTER:
        </div>
        <div
          className="nc-filter-group"
          id="filter-group"
          style={{ width: width ? width : "55%" }}
        >
          <div className="nc-filter-row" id="filter-row1">
            {Object.keys(finalFilterData).map(
              (key, i) =>
                key !== "unmapped" && (
                  <div
                    className="nc-filter-dropdown"
                    id="filter-activity-entity"
                    key={key}
                    style={{ width: finalFilterData[key].width }}
                  >
                    {finalFilterData[key].type === "datePicker" ? (
                      <DateRangePicker
                        getFromToValue={(name, fromToValue) =>
                          getFromToValue(key, fromToValue)
                        }
                        onHide={onClose}
                        selectedItems={selectedOptions[key]}
                        value={selectedOptions[key]}
                      />
                    ) : (
                      finalFilterData[key].type !== "checkbox" && (
                        <DropDown
                          options={options[key]}
                          selectedItems={selectedOptions[key]}
                          onSelect={makeOnSelect(key)}
                          onHide={onClose}
                          selectAll={makeSelectAll(key)}
                          makeLabel={labelMaker(
                            FILTER_LABELS[page][key][0],
                            FILTER_LABELS[page][key][1],
                            FILTER_LABELS[page][key][2],
                            FILTER_LABELS[page][key][3]
                          )}
                          searchBoxId={key + "-auto-suggestion-textbox"}
                          id={
                            finalFilterData[key].id ||
                            "dd-list-page-filter-" + (i + 1)
                          }
                          //overlayWidth="310px"
                          {...finalFilterData[key]}
                        />
                      )
                    )}
                  </div>
                )
            )}
            <FilterUnmappedOption
              unmappedId={unmappedId}
              show={showUnmapped}
              name={showUnmappedName}
              value={
                selectedOptions["unmapped"] !== undefined
                  ? selectedOptions["unmapped"][showUnmappedName]
                  : false
              }
              onChange={makeOnSelect("unmapped")}
            />
          </div>
        </div>

        {showAdvancedLinks && (
          <div className="advanced-filter-links">
            <a className="" id="" onClick={toggleAdvancedFilters}>
              {" "}
              <i className="fa fa-filter" />
              Advanced
              <span className="hidden-md">&nbsp;Filters</span>
              {advancedFilterCount > 0 && (
                <Badge className="filter-select-badge">
                  {advancedFilterCount}
                </Badge>
              )}
            </a>
            {showClearFilters && (
              <a onClick={clearAdvancedFilters} className="" id="">
                {" "}
                <i className="fa fa-trash" /> Clear All
                <span className="hidden-md"> &nbsp;Filters </span>
              </a>
            )}{" "}
          </div>
        )}
      </div>
    );
  }
}
