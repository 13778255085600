import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { LeftSidebar } from "component-library/employee-profile/entity-view";
import { makeOptions, makeCriterion } from "../utils";
import * as util from "app/common/utilities";

import {
  fetchAndSetPrimaryManager,
  fetchAndSetSecondaryManager,
  fetchAndSetEmployeeType,
  fetchAndSetEmployeeClassification,
  fetchAndSetPosition,
  fetchAndSetPayrollSchedule,
  fetchAndSetPayrollFrequency,
  fetchAndSetTimeGroup,
  fetchAndSetGender,
  searchAndSetPostsMenu
} from "../network/actions";
import { setLoader } from "app/actions/LoaderActions";
import {
  setGlobalNavClick,
  CallbackToQuickLink,
  setFormUpdate,
  isOverlayClickable
} from "app/actions/FormUpdateAction";
import { Switch, Route } from "react-router-dom";
import Resume from "../../resume/pages/Resume";
import EmploymentInfo from "../../employement-info/base/pages/EmploymentInfo";
import * as CardIds from "../Constants";
import EmployeeFiles from "employee-files/pages/EmployeeFiles";
import PersonalInfo from "../../personalInfo/pages/PersonalInfo";
import Profile from "../../personalInfo/pages/Profile";
import EmergencyContact from "../../personalInfo/pages/EmergencyContact";
import DependentsList from "../../personalInfo/pages/DependentsList";
import AddDependentsContainer from "../../personalInfo/pages/AddDependents";
import EmployeeNotes from "../../other/pages/EmployeeNotes";
import AddEmployeeNotes from "../../other/pages/AddEmployeeNotes";
import * as constants from "../../../../employee-files/EmployeeFilesConstants";
import { getEmployeeMenuData } from "../utils";

var SidePanelData = [];
var NewEmployeeMenu = [];

class EmployeeProfileInformation extends Component {
  state = {
    tabKey: 1,
    pageTitle: "Demographics",
    activeSubPanelKey: 111,
    dragHandleState: "0px",
    activePanelId: 1,
    showStatusOverlay: false,
    showWorkplaceOverlay: false
  };

  componentDidMount() {
    const { props } = this;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    props.loadPrimaryManager();
    props.loadSecondaryManager();
    props.loadEmployeeType();
    props.loadEmployeeClassification();
    props.loadEmployeePosition();
    props.loadPayrollSchedule();
    props.loadPayrollFrequency();
    props.loadTimeGroup();
    props.loadGender();

    const { CardId, ActivePanelId } = this.props.match.params;
    let SideBarId = 0;

    if (CardId === "time&attendance") SideBarId = CardIds["timeandattendance"];
    else if (CardId === "benefits&cobra")
      SideBarId = CardIds["benefitsandcobra"];
    else SideBarId = CardIds[CardId];
    if (!util.isNullOrZero(SideBarId)) {
      //this.props.setLoader(true);
      //this.props.loadEmployeeInformarionData(SideBarId);
      const { loadEmployeeMenu } = this.props;
      this.props.clearFiltersFields();
      let searchParams = this.getBasicMenuSearchParams();
      loadEmployeeMenu(searchParams, () => {
        NewEmployeeMenu = getEmployeeMenuData(this.props.employeeMenu.Data);
        SidePanelData = NewEmployeeMenu.filter(function(obj) {
          return SideBarId === obj.CustomFieldGroupId;
        });
      });
    }

    if (!util.isNullOrZero(ActivePanelId)) {
      this.setState({
        activePanelId: parseInt(ActivePanelId, 10)
      });
    }
  }

  getBasicMenuSearchParams = () => {
    const defaultSearchParams = {
      Fields: ["*"],
      Criterion: [],
      Sort: [],
      PageSize: 5
    };
    let Criterion = [];
    Criterion.push(makeCriterion("CustomFieldGroupId", [1]));
    Criterion = Criterion.filter(c => c);
    defaultSearchParams.Criterion = Criterion;

    return defaultSearchParams;
  };

  togglePanel = id => {
    const { EmployeeId, CardId } = this.props.match.params;
    if (id === this.state.activePanelId) {
      this.setState({ activePanelId: 0 });
    } else {
      this.setState({ activePanelId: id });
      this.props.history.push(
        `/people/employees/dashboard/${EmployeeId}/${CardId}/${id}`
      );
    }
  };

  handleTabSelect = tabKey => {
    this.setState({ tabKey });
  };

  onPageSelect = (pageTitle, activeSubPanelKey) => {
    this.setState({ pageTitle, activeSubPanelKey });
  };

  handleSetActive = (e, title) => {
    // var element = document.getElementById("cont123");
    // element.scrollIntoView();
  };

  render() {
    const { employeeProfileData } = this.props;
    const EmployeeId = this.props.match.params.EmployeeId;
    return (
      <div id="cont123" className="clearfix">
        <LeftSidebar
          {...this.props}
          panelData={SidePanelData[0]}
          togglePanel={this.togglePanel}
          activePanelId={this.state.activePanelId}
          onPageSelect={this.onPageSelect}
          activeKey={this.state.activeSubPanelKey}
          handleSetActive={this.handleSetActive}
          scrollHandleStyle={this.state.scrollHandle}
          handleVerticalThumbMouseDown={this.handleVerticalThumbMouseDown}
          employeeId={EmployeeId}
          showStatusOverlay={this.state.showStatusOverlay}
          showWorkplaceOverlay={this.state.showWorkplaceOverlay}
        />
        <Switch>
          <Route
            path="/people/employees/dashboard/:EmployeeId/personalInfo/:entityId/files"
            render={props =>
              employeeProfileData && (
                <EmployeeFiles
                  {...props}
                  employeeProfileData={employeeProfileData}
                  entityId={constants.DEMOGRAPHICSENTITYID}
                />
              )
            }
          />
          <Route
            path="/people/employees/dashboard/:EmployeeId/personalInfo/9"
            render={props => <PersonalInfo {...props} />}
          />
          <Route
            path="/people/employees/dashboard/:EmployeeId/personalInfo/10"
            render={props => <Profile {...props} />}
          />
          <Route
            path="/people/employees/dashboard/:EmployeeId/personalInfo/11"
            render={props => <EmergencyContact {...props} />}
          />
          <Route
            path="/people/employees/dashboard/:EmployeeId/personalInfo/12"
            render={props => <DependentsList {...props} />}
          />
          <Route
            path="/people/employees/dashboard/:EmployeeId/personalInfo/5"
            render={props => <AddDependentsContainer {...props} />}
          />
          <Route
            path="/people/employees/dashboard/:EmployeeId/other/30"
            render={props => <EmployeeNotes {...props} />}
          />
          <Route
            path="/people/employees/dashboard/:EmployeeId/addnotes/30"
            render={props => <AddEmployeeNotes {...props} />}
          />
          <Route
            path="/people/employees/dashboard/:EmployeeId/resume/:ActivePanelId"
            render={() => (
              <Resume
                {...this.props}
                panelData={SidePanelData[0]}
                togglePanel={this.togglePanel}
                activePanelId={this.state.activePanelId}
                onPageSelect={this.onPageSelect}
                activeKey={this.state.activeSubPanelKey}
                handleSetActive={this.handleSetActive}
                scrollHandleStyle={this.state.scrollHandle}
                handleVerticalThumbMouseDown={this.handleVerticalThumbMouseDown}
                employeeId={EmployeeId}
              />
            )}
          />
          <Route
            path="/people/employees/dashboard/:EmployeeId/employmentinfo/:ActivePanelId"
            render={() => (
              <EmploymentInfo
                {...this.props}
                togglePanel={this.togglePanel}
                activePanelId={this.state.activePanelId}
                onPageSelect={this.onPageSelect}
                activeKey={this.state.activeSubPanelKey}
                handleSetActive={this.handleSetActive}
                scrollHandleStyle={this.state.scrollHandle}
                handleVerticalThumbMouseDown={this.handleVerticalThumbMouseDown}
                employeeId={EmployeeId}
                panelData={SidePanelData[0]}
                showStatusOverlay={this.state.showStatusOverlay}
                showWorkplaceOverlay={this.state.showWorkplaceOverlay}
                toggleOverlay={() =>
                  this.setState({
                    showStatusOverlay: !this.state.showStatusOverlay
                  })
                }
                toggleWorkplaceOverlay={() =>
                  this.setState({
                    showWorkplaceOverlay: !this.state.showWorkplaceOverlay
                  })
                }
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

const stateToProps = state => {
  return {
    data: state.people,
    filters: makeOptions(state.common.filters),
    sectionList: state.people.employeeInformation.Data,
    entityHeader: state.people.employeeInformation.EntityHeader,
    activePanel: state.people.activePanel
  };
};

const actionCreators = {
  loadPrimaryManager: fetchAndSetPrimaryManager,
  loadSecondaryManager: fetchAndSetSecondaryManager,
  loadEmployeeType: fetchAndSetEmployeeType,
  loadEmployeeClassification: fetchAndSetEmployeeClassification,
  loadEmployeePosition: fetchAndSetPosition,
  loadPayrollSchedule: fetchAndSetPayrollSchedule,
  loadPayrollFrequency: fetchAndSetPayrollFrequency,
  loadTimeGroup: fetchAndSetTimeGroup,
  loadGender: fetchAndSetGender,
  setLoader,
  setFormUpdate,
  isOverlayClickable,
  setGlobalNavClick,
  CallbackToQuickLink,
  loadEmployeeMenu: searchAndSetPostsMenu
};

EmployeeProfileInformation.propTypes = {
  activePanel: PropTypes.object,
  sectionList: PropTypes.object
};
EmployeeProfileInformation.defaultProps = {
  activePanel: { SideBarId: 0, ActivePanelId: 0 },
  sectionList: {}
};

export default withRouter(
  connect(stateToProps, actionCreators)(EmployeeProfileInformation)
);
