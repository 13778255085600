import * as types from "../actions/index";
export default function(state = {}, action = {}) {
  switch (action.type) {
    case types.EARNING_NOTIFICATION:
      return [...state, action.payload];

    default:
      return state;
  }
}
