import React, { Component } from "react";
import DropDown from "../../dropDown";
import { EMPLOYEE_FILTER_NAME, TYPE_FILTER_NAME } from "./Constants";

const labelMaker = (noLabel, allLabel, someLabel, oneLabel) => (o, s, all) =>
  all
    ? allLabel
    : Object.keys(s).filter(k => s[k]).length === 0
      ? noLabel
      : Object.keys(s).filter(k => s[k]).length === 1
        ? oneLabel
        : someLabel;

export default class FiltersDesktop extends Component {
  render() {
    const {
      options = { [EMPLOYEE_FILTER_NAME]: [], [TYPE_FILTER_NAME]: [] },
      selectedOptions = {},
      makeOnSelect = () => undefined,
      makeSelectAll = () => undefined
    } = this.props;
    return (
      <div className="nc-filter-container">
        <div className="showing-label" id="filter-label">
          {" "}
          FILTER:
        </div>
        <div className="nc-filter-group" id="filter-group">
          <div className="nc-filter-row" id="filter-row1">
            <div className="nc-filter-dropdown">
              <DropDown
                multiSelect
                showSearch
                showBadge
                options={options[EMPLOYEE_FILTER_NAME]}
                selectedItems={selectedOptions[EMPLOYEE_FILTER_NAME]}
                onSelect={makeOnSelect(EMPLOYEE_FILTER_NAME)}
                selectAll={makeSelectAll(EMPLOYEE_FILTER_NAME)}
                makeLabel={labelMaker(
                  "Select Employees",
                  "All Employees",
                  "Employees",
                  "Employee"
                )}
                selectAllLabel="All Employees"
                searchBoxId="employee-auto-suggestion-textbox"
                id="employee-list"
                onHide={this.props.onCloseFilter}
              />
            </div>
            <div className="nc-filter-dropdown">
              <DropDown
                multiSelect
                showBadge
                options={options[TYPE_FILTER_NAME]}
                selectedItems={selectedOptions[TYPE_FILTER_NAME]}
                onSelect={makeOnSelect(TYPE_FILTER_NAME)}
                selectAll={makeSelectAll(TYPE_FILTER_NAME)}
                makeLabel={labelMaker(
                  "Select Type",
                  "All Types",
                  "Types",
                  "Type"
                )}
                selectAllLabel="All Types"
                searchBoxId="type-auto-suggestion-textbox"
                id="time-group-list"
                onHide={this.props.onCloseFilter}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
