import React, { Component } from "react";
import "./TabContainer.css";

import Toaster from "component-library/toasters/index";
import * as FIELDS from "component-library/employee-profile/resume/utils/Constants";

export default class TabContainer extends Component {
  render() {
    const {
      activePanelHeader,
      isAddModeOn,
      showToaster,
      toasterClass,
      toasterMessage,
      toggleToaster,
      showCloseIcon,
      userRole,
      onFileClick
    } = this.props;
    return (
      <div
        className="col-lg-9 col-md-9 tab-container"
        id="tab-container"
        onScroll={this.props.onScroll}
      >
        <Toaster
          id={"resumeToaster"}
          show={showToaster}
          alertClassName={toasterClass}
          message={toasterMessage}
          closeToasterMessage={toggleToaster}
          showCloseIcon={showCloseIcon}
        />
        <div className="tab-title-container">
          <div className="tab-title">{activePanelHeader}</div>
          {!isAddModeOn ? (
            <div className="links">
              <span>
                <i className="fa fa-id-badge" /> {activePanelHeader}
              </span>
              {userRole !== FIELDS.EMPLOYEE && (
                <a onClick={() => onFileClick()}>
                  <i className="fa fa-file-text-o" /> File
                </a>
              )}
            </div>
          ) : null}
        </div>
        {this.props.children}
      </div>
    );
  }
}
