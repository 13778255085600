import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ContactApproversActive, ContactApproversInactive } from "./../images";
const tooltip_edit = <Tooltip id="tooltip_edit">Edit</Tooltip>;
const tooltip_approve = <Tooltip id="tooltip_approve">Approve</Tooltip>;

const tooltip_contact_approver = (
  <Tooltip id="tooltip_contact_approver">Contact Approver</Tooltip>
);
const TimeCardAlertActions = ({
  TimeCardAlertName,
  toggleInfoModal = () => {}, // for edit icon
  approveAction = () => {}, // for green tick mark
  onContactClick = () => {}, // for contact approver icon
  name = "Employee",
  hideApprove = false,
  hideEdit = false,
  hideContactApprove = false,
  noPadding = false,
  noMargin = false,
  IsEmailSent = false,
  disableApprove = true
}) => (
  <div
    className="row"
    style={{ padding: noPadding ? 0 : 15, marginBottom: noMargin ? 0 : 8 }}
  >
    {/* <div className="column-action col-sm-2" /> */}
    {!hideContactApprove && (
      <div className="column-action">
        <OverlayTrigger placement="top" overlay={tooltip_contact_approver}>
          <span style={{ cursor: "pointer" }} onClick={onContactClick}>
            {IsEmailSent ? (
              <ContactApproversInactive
                width="22"
                height="22"
                style={{ fill: "#0099996e" }}
              />
            ) : (
              <a>
                <ContactApproversActive width="22" height="22" />
              </a>
            )}
          </span>
        </OverlayTrigger>
      </div>
    )}

    {!hideEdit && (
      <div className="column-action">
        <OverlayTrigger placement="top" overlay={tooltip_edit}>
          <i
            className="fa fa-pencil"
            aria-hidden="true"
            onClick={() => toggleInfoModal()}
          />
        </OverlayTrigger>
      </div>
    )}

    {!hideApprove && (
      <div className="column-action">
        {TimeCardAlertName === "Unapproved Timecard" ? (
          !disableApprove ? (
            <OverlayTrigger placement="top" overlay={tooltip_approve}>
              <i
                className="fa fa-check nc-green"
                aria-hidden="true"
                onClick={approveAction}
              />
            </OverlayTrigger>
          ) : (
            <i
              className="fa fa-check"
              aria-hidden="true"
              style={{ color: "#ccc" }}
            />
          )
        ) : null}
      </div>
    )}
  </div>
);

export default TimeCardAlertActions;
