import axios from "axios";
import * as common from "../../../../../app/common/index";
import * as types from "../index";

export const fetchAndSetEntityData = Id => dispatch =>
  getEntityData(Id).then(res => {
    if (res.data.Data.length !== 0) {
      dispatch(setEntityData(res.data.Data[0]));
    }
  });

export const getEntityData = Id =>
  axios.get(`${common.WEB_API_URL}/v1/system/thirdpartypayee/${Id}`);

export const setEntityData = data => ({
  type: types.SYSTEM_THIRD_PARTY_PAYEE_DATA,
  payload: data
});
