import React from "react";

const AddressSection = props => {
  return (
    <div
      className="nc-field-group clearfix"
      style={{ clear: "both", display: "flex", alignItems: "center" }}
    >
      <div
        id={"Label" + props.inputName}
        style={{ paddingRight: "15px" }}
        className={`${props.colSpan} " " ${"col-lg-3 field-name clearfix"}`}
      >
        {props.label}{" "}
      </div>

      <div className="col-lg-9" style={{ paddingBottom: "15px" }}>
        <div style={{ paddingBottom: "5px" }}>{props.addressLine1}</div>
        <div>{props.addressLine2}</div>
      </div>
    </div>
  );
};

export default AddressSection;
