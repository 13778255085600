/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import classNames from "classnames/bind";
import FormTooltip from "./FormTooltip";
import getInputComponent from "./getInputComponent";
import * as Constants from "./Constants";

export default class InlineFormFieldGroup extends Component {
  state = { showTooltip: false };
  showTooltipHandler = () => {
    this.setState({
      showTooltip: !this.state.showTooltip
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { shouldToggleTooltip = false } = this.props;
    if (shouldToggleTooltip) {
      if (prevProps.toggleDataKey !== this.props.toggleDataKey) {
        this.setState({
          showTooltip: false
        });
      }
    }
  }
  render() {
    const { props } = this;
    let lgClass = "col-lg-4";
    let mdClass = "col-md-6";
    let smClass = "col-sm-12";
    let xsClass = "col-xs-12";
    if (props.Size) {
      if (props.Size.length > 0) {
        lgClass = props.Size[0] && "col-lg-" + props.Size[0];
        mdClass = props.Size[1] && "col-md-" + props.Size[1];
        smClass = props.Size[2] && "col-sm-" + props.Size[2];
        xsClass = props.Size[3] && "col-xs-" + props.Size[3];
      }
    }

    let wrapperClass;
    if (props.class === undefined) {
      wrapperClass = "nc-field-group clearfix";
    } else {
      wrapperClass = "nc-field-group " + props.class + " clearfix";
    }
    if (
      (props.value == null ||
        props.value === "" ||
        props.inputType === Constants.TEXT_INPUT ||
        props.inputType === Constants.NUMERIC_INPUT ||
        props.inputType === Constants.FREQUENCY_INPUT) &&
      props.required &&
      props.error
    ) {
      wrapperClass = wrapperClass + " has-error " + props.inputName;
    }
    const InputFieldClass = classNames(
      "input-field",
      props.className,
      lgClass,
      mdClass,
      smClass,
      xsClass
    );
    let DescriptionClass = classNames(
      "field-description",
      props.descriptionposition !== "top" && "hidden-lg hidden-md hidden-sm",
      props.hideTopdescription && "hidden-xs"
    );
    let Component = getInputComponent(props.inputType);

    return (
      <div className={wrapperClass}>
        <div style={{ clear: "both", display: "flex", alignItems: "center" }}>
          <div
            id={"Label-" + props.inputName}
            style={{ paddingRight: "15px" }}
            className={`${props.colSpan} ${"field-name clearfix"}`}
          >
            {props.label}{" "}
            {props.icon && (
              <span
                className={
                  props.value === undefined || props.value === null
                    ? "disabled-description"
                    : "form-field-group-icon-description"
                }
              >
                {props.iconDescription}
              </span>
            )}
            {props.tooltip && props.tooltipPosition === "label" && (
              <a
                onClick={this.showTooltipHandler}
                style={{
                  marginBottom: props.checkedValue ? "10px" : "0px",
                  marginRight: "5px"
                }}
              >
                <i className="fa fa-info-circle" />
              </a>
            )}
            {props.optional && <i className="optional-field">(Optional) </i>}{" "}
            {props.description && (
              <div className={DescriptionClass} style={props.style}>
                {props.descriptionStyle === "italic" ? (
                  <i>{props.description}</i>
                ) : (
                  props.description
                )}
              </div>
            )}
          </div>
          {props.inputType === Constants.LABEL ? (
            <div style={{ marginBottom: "15px" }}>{props.value}</div>
          ) : (
            <div
              className="col-lg-9 col-md-9 col-sm-9 col-xs-12"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Component
                {...props}
                onChange={props.onChange}
                onSelect={props.onChange}
                name={props.inputName}
                className={InputFieldClass}
                value={props.value}
                id={props.inputId}
                getFromToValue={props.getFromToValue}
              />
              {props.tooltip && props.tooltipPosition === "field" && (
                <a
                  onClick={this.showTooltipHandler}
                  style={{
                    marginBottom: props.checkedValue ? "10px" : "0px"
                  }}
                >
                  <i className="fa fa-info-circle" />
                </a>
              )}
              {props.description &&
                props.descriptionposition !== "top" &&
                !props.hideTopdescription && (
                  <div
                    className="inline-field-description hidden-xs"
                    style={{
                      marginTop: "0px",
                      marginBottom: "25px",
                      ...props.style
                    }}
                  >
                    {props.descriptionStyle === "italic" ? (
                      <i>{props.description}</i>
                    ) : (
                      props.description
                    )}
                  </div>
                )}
            </div>
          )}

          {/* The below portion is added for payrolll schedule 
              TODO (Ashish) : Refactor this properly
            */}
          {props.description && props.hideTopdescription && (
            <div
              className="inline-field-description"
              style={{
                marginLeft: "15px",
                marginTop: "10px",
                ...props.style
              }}
            >
              {props.descriptionStyle === "italic" ? (
                <i>{props.description}</i>
              ) : (
                props.description
              )}
            </div>
          )}
        </div>
        {this.state.showTooltip && props.tooltip && (
          <FormTooltip
            onCloseHandler={this.showTooltipHandler}
            content={props.tooltip}
            width={props.tooltipwidth}
            style={{ marginTop: "0", marginBottom: "15px" }}
          />
        )}
      </div>
    );
  }
}
InlineFormFieldGroup.propTypes = {
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.oneOf(["label", "field"]),
  description: PropTypes.string
};
InlineFormFieldGroup.defaultProps = {
  tooltipPosition: "label"
};
