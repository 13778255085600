import {
  //getPayrollDashboardItems,
  getPayrollDashboardSessionData,
  getScheduledPayrollInstanceData,
  getUnScheduledPayrollInstanceData,
  getMorePayrollDashboardItems,
  getUnapprovedPtoItems,
  getMoreUnapprovedPtoItems,
  getTimecardAlerts,
  getMoreTimecardAlerts,
  getEmployeeUpdates,
  getMoreEmployeeUpdates,
  approvePtos,
  makePayrollStartOver,
  approveTimecard,
  startPayroll,
  sendMessageToApprover,
  getEmployeeList, //employee filter drop-down
  getTypesList,
  getTimeGroupsList,
  getTimePeriodsList,
  getPagesList,
  getFieldsList,
  searchPostsUnapprovedPto,
  searchPostsTimecardAlerts,
  searchPostsEmployeeUpdates,
  getFilterOptions,
  getaddressverificationItems,
  getminimumwagesalertItems,
  gettaxverificationItems,
  getState
} from ".";
import {
  setPayrollDashboardItems,
  clearPayrollDashboardItems,
  addToPayrollDashboardItems
} from "../reducers/payrollDashboardItems";
import {
  setPayrollTypeData,
  clearPayrollTypeData,
  addToPayrollTypeData
} from "../reducers/payrollTypeData";

import { editAddressValues } from "../reducers/addressVerifications";

import { setSendMessageToApprover } from "../reducers/contactApprovers";

import { setPayrollStartOver } from "../reducers/InProgressPayroll";

import { setPayrollDashboardSessionData } from "../reducers/payrollDashboardSessionData";
import { showToast } from "app/common/reducers/toast";
import * as payrollConstants from "../../component-library/payroll-dashboard/PayrollDashboardConstants";

import {
  EMPLOYEE_FILTER_NAME,
  TYPE_FILTER_NAME,
  UN_APPROVED_PTO
} from "component-library/payroll-dashboard/unapproved-pto/Constants";
import {
  TIME_GROUPS_FILTER_NAME,
  TIME_PERIODS,
  TIME_CARD_ALERTS
} from "component-library/payroll-dashboard/timecard-alerts/Constants";
import {
  PAGES_FILTER_NAME,
  FIELDS_FILTER_NAME
} from "component-library/payroll-dashboard/employee-updates/Constants";

import { setEntityList, clearEntityList } from "app/common/reducers/entityList";
import {
  setFilterOptions,
  selectAllFilterOptions
} from "app/common/reducers/filters";
import { setLoader } from "app/actions/LoaderActions";

export const makeClearPayrollDashboardItems = () => dispatch => {
  dispatch(clearPayrollDashboardItems());
};

export const fetchPayrollDashboardSessionData = () => dispatch => {
  getPayrollDashboardSessionData().then(res => {
    const { Data } = res.data;
    dispatch(setPayrollDashboardSessionData(Data === null ? [] : Data));
  });
};
export const fetchDashboardScheduledPayrolls = (
  payrollDashboardUri,
  selectedTabId,
  callback
) => dispatch => {
  // When user clicks on Tab, we will send selectedTabId and based on this we can check which service to call
  // Currently we have only Schedule payroll services and data,
  // for now we will only use same reducer for both schedule and unschedule payroll.
  if (selectedTabId === payrollConstants.SCHEDULE_PAYROLL) {
    dispatch(clearPayrollDashboardItems());
    getScheduledPayrollInstanceData(payrollDashboardUri)
      .then(res => {
        const { Data, DataCount, NextUri } = res.data;
        dispatch(
          setPayrollDashboardItems(
            Data === null ? [] : Data,
            DataCount,
            NextUri
          )
        );
        callback();
      })
      .catch(function(error) {
        callback();
      });
  } else if (selectedTabId === payrollConstants.UNSCHEDULE_PAYROLL) {
    dispatch(clearPayrollDashboardItems());
    getUnScheduledPayrollInstanceData(payrollDashboardUri)
      .then(res => {
        const { Data, DataCount, NextUri } = res.data;
        dispatch(
          setPayrollDashboardItems(
            Data === null ? [] : Data,
            DataCount,
            NextUri
          )
        );
        callback();
      })
      .catch(function(error) {
        callback();
      });
  }
};

export const loadAndSetMorePosts = (nextUri, callback) => dispatch => {
  getMorePayrollDashboardItems(nextUri)
    .then(res => {
      const { Data, DataCount, NextUri } = res.data;
      dispatch(addToPayrollDashboardItems(Data, DataCount, NextUri));
      callback();
    })
    .catch(function(error) {
      callback();
    });
};

export const payrollStartOver = PayrollId => dispatch => {
  makePayrollStartOver(PayrollId)
    .then(res => {
      const { Data } = res.data;
      dispatch(setPayrollStartOver(Data === null ? [] : Data));
    })
    .catch(function(error) {
      dispatch(setPayrollStartOver([]));
    });
};

export const fetchUnapprovedPtoItems = (
  userTestId,
  selectAll = false,
  filterName = UN_APPROVED_PTO
) => dispatch => {
  dispatch(clearPayrollTypeData());
  getUnapprovedPtoItems(userTestId).then(res => {
    const { Data, DataCount, NextUri } = res.data;
    dispatch(
      setPayrollTypeData(
        Data === null ? [] : Data,
        DataCount,
        NextUri,
        "UnapprovedPtos"
      )
    );
    const tableData = res.data.Data;
    const options = tableData.map(e => ({
      value: e.EmployeeId,
      label: e.EmployeeName,
      id: e.EmployeeId
    }));
    // for check boxes
    dispatch(setFilterOptions(filterName, options));
    if (selectAll) {
      dispatch(selectAllFilterOptions(filterName, false));
    }
  });
};
export const loadMoreUnapprovedPtoItems = (nextUri, callback) => dispatch => {
  getMoreUnapprovedPtoItems(nextUri).then(res => {
    const { Data, DataCount, NextUri } = res.data;
    dispatch(
      addToPayrollTypeData(
        Data === null ? [] : Data,
        DataCount,
        NextUri,
        "UnapprovedPtos"
      )
    );
    callback();
  });
};

export const fetchTimecardAlerts = (
  userTestId,
  selectAll = false,
  filterName = TIME_CARD_ALERTS
) => dispatch => {
  dispatch(clearPayrollTypeData());
  getTimecardAlerts(userTestId).then(res => {
    const { Data, DataCount, NextUri } = res.data;
    dispatch(
      setPayrollTypeData(
        Data === null ? [] : Data,
        DataCount,
        NextUri,
        "TimecardAlerts"
      )
    );
    const tableData = res.data.Data;
    const options = tableData.map(e => ({
      value: e.EmployeeId,
      label: e.EmployeeName,
      id: e.EmployeeId
    }));
    // for check boxes
    dispatch(setFilterOptions(filterName, options));
    if (selectAll) {
      dispatch(selectAllFilterOptions(filterName, false));
    }
  });
};
export const loadMoreTimecardAlerts = () => dispatch => {
  getMoreTimecardAlerts().then(res => {
    const { Data, DataCount, NextUri } = res.data;
    dispatch(
      addToPayrollTypeData(
        Data === null ? [] : Data,
        DataCount,
        NextUri,
        "TimecardAlerts"
      )
    );
  });
};
export const fetchEmployeeUpdates = userTestId => dispatch => {
  //dispatch(clearPayrollTypeData());
  getEmployeeUpdates(userTestId).then(res => {
    // const { Data, DataCount, NextUri } = res.data;
    const { DataCount, NextUri } = res.data;
    let Data = [
      {
        EmployeeId: 1,
        EmployeeName: "Karen Jones",
        EmployeePictureUrl:
          "https://sfiedlerappblobstore.blob.core.windows.net/profilepictures/OliviaStewart.jpg",
        UpdateTimeStamp: "2017-11-19T13:15:23",
        EmployeeUpdateList: [
          {
            EmployeeUpdateId: 1,
            EmployeeUpdateTimeStamp: "2017-11-15T11:55:32",
            EmployeeUpdateMadeByUserId: 4,
            EmployeeUpdateMadeByUserName: "John Smith",
            EmployeeUpdateCategoryId: 1,
            EmployeeUpdateCategoryName: "Wage & Tax",
            EmployeeUpdateFieldId: 1,
            EmployeeUpdateFieldName: "Hourly Pay",
            EmployeeUpdateFieldValueBeforeUpdate: "15.15",
            EmployeeUpdateFieldValueAfterUpdate: "12.15"
          }
        ]
      },
      {
        EmployeeId: 2,
        EmployeeName: "Shawn Black",
        EmployeePictureUrl:
          "https://sfiedlerappblobstore.blob.core.windows.net/profilepictures/BenjaminWilliams.jpg",
        UpdateTimeStamp: "2017-11-19T13:15:23",
        EmployeeUpdateList: [
          {
            EmployeeUpdateId: 1,
            EmployeeUpdateTimeStamp: "2017-11-15T11:55:32",
            EmployeeUpdateMadeByUserId: 4,
            EmployeeUpdateMadeByUserName: "John Smith",
            EmployeeUpdateCategoryId: 1,
            EmployeeUpdateCategoryName: "Wage & Tax",
            EmployeeUpdateFieldId: 1,
            EmployeeUpdateFieldName: "Hourly Pay",
            EmployeeUpdateFieldValueBeforeUpdate: "15.15",
            EmployeeUpdateFieldValueAfterUpdate: "12.15"
          }
        ]
      }
    ];
    dispatch(
      setPayrollTypeData(
        Data === null ? [] : Data,
        DataCount,
        NextUri,
        "EmployeeUpdates"
      )
    );
  });
};

export const fetchAndSetEmployeedata = id => dispatch => {
  //Commited because of dont have service url.
  // getEmployeeData(id).then(res => {
  //   const { Data, DataCount, NextUri } = res.data;
  //   dispatch(
  //     setEmployeeData(
  //       Data === null ? [] : Data,
  //       DataCount,
  //       NextUri,
  //       "EmployeeData"
  //     )
  //   );
  // });
};

export const loadMoreEmployeeUpdates = () => dispatch => {
  getMoreEmployeeUpdates().then(res => {
    const { Data, DataCount, NextUri } = res.data;
    dispatch(
      addToPayrollTypeData(
        Data === null ? [] : Data,
        DataCount,
        NextUri,
        "EmployeeUpdates"
      )
    );
  });
};
export const approveAllPtosAction = (id = 1, data = 5) => dispatch => {
  dispatch(clearPayrollDashboardItems());
  approvePtos(id).then(res => {
    const { Data, DataCount, NextUri } = res.data;
    dispatch(clearPayrollTypeData());

    dispatch(
      showToast("You have successfully approved PTO for " + data + " employees")
    );
    dispatch(addToPayrollDashboardItems(Data, DataCount, NextUri));
    //callback();
  });
};

export const fetchAndSetAddressVerificationItems = (
  payrollId,
  clearExisting = true
) => dispatch => {
  if (clearExisting) {
    dispatch(clearPayrollTypeData());
  }
  dispatch(setLoader(true));
  getaddressverificationItems(payrollId).then(res => {
    const { Data, DataCount, NextUri, Id } = res.data;
    var addressNotFoundData = [];
    var suggestedData = [];
    Data.forEach(item => {
      if (item.EmployeeSuggestedHomeAddress !== "") {
        suggestedData.push(item);
      } else {
        addressNotFoundData.push(item);
      }
    });
    dispatch(
      setPayrollTypeData(
        Data === null ? [] : Data,
        DataCount,
        NextUri,
        "AddressVerification",
        suggestedData,
        addressNotFoundData,
        Id
      )
    );
    dispatch(setLoader(false));
  });
};

export const updateEmployeeAddress = (id, data) => dispatch => {
  dispatch(clearPayrollTypeData());
  var Data = data.Data;
  var addressNotFoundData = data.AddressNotFoundData;
  var suggestedData = data.SuggestedData;
  if (id !== 0) {
    suggestedData.forEach((item, i) => {
      if (item.EmployeeId === id) {
        suggestedData.splice(i, 1);
      }
    });
  } else {
    suggestedData = [];
  }
  dispatch(
    addToPayrollTypeData(
      Data === null ? [] : Data,
      data.DataCount,
      data.NextUri,
      "AddressVerification",
      suggestedData,
      addressNotFoundData
    )
  );
};

export const fetchAndSetMinimumWagesAlertsItems = (
  payrollId,
  clearExisting = true
) => dispatch => {
  if (clearExisting) {
    dispatch(clearPayrollTypeData());
  }
  dispatch(setLoader(true));
  getminimumwagesalertItems(payrollId).then(res => {
    const { Data, DataCount, NextUri, Id } = res.data;
    var suggestedData = [];
    Data.forEach(item => {
      if (item.EmployeeSuggestedMinimumWage !== null) {
        suggestedData.push(item);
      }
    });
    dispatch(
      setPayrollTypeData(
        Data === null ? [] : Data,
        DataCount,
        NextUri,
        "MinimumWagesAlerts",
        suggestedData,
        Id
      )
    );
    dispatch(setLoader(false));
  });
};

export const fetchAndSetTaxVerificationItems = (
  payrollId,
  clearExisting = true
) => dispatch => {
  if (clearExisting) {
    dispatch(clearPayrollTypeData());
  }
  dispatch(setLoader(true));
  gettaxverificationItems(payrollId).then(res => {
    const { Data, DataCount, NextUri, Id } = res.data;
    var suggestedData = [];
    Data.forEach(item => {
      if (item.EmployeeSuggestedStateWithHolding !== null) {
        suggestedData.push(item);
      }
    });
    dispatch(
      setPayrollTypeData(
        Data === null ? [] : Data,
        DataCount,
        NextUri,
        "TaxVerification",
        suggestedData,
        Id
      )
    );
    dispatch(setLoader(false));
  });
};

export const approvePtosAction = (
  userTestId = 1,
  name = "Employee",
  selectedEmployees = [],
  unapprovedData = [],
  payrollData,
  callBack = () => {}
) => dispatch => {
  dispatch(clearPayrollTypeData());
  dispatch(clearPayrollDashboardItems());

  let postOperationData = unapprovedData;
  selectedEmployees.forEach(item => {
    postOperationData = postOperationData.filter(
      flterItem => flterItem.EmployeeId !== item
    );
  });
  callBack();
  dispatch(
    setPayrollTypeData(
      postOperationData === null ? [] : postOperationData,
      postOperationData.length,
      "",
      "UnapprovedPtos"
    )
  );

  payrollData.CountOfUnapprovedPtos -=
    unapprovedData.length - postOperationData.length;
  dispatch(setPayrollDashboardItems([payrollData], 1, ""));
  dispatch(
    showToast(
      `You have successfully approved PTO for 
        ${unapprovedData.length - postOperationData.length}
        ${
          unapprovedData.length - postOperationData.length > 1
            ? "employees"
            : "employee"
        }`
    )
  );
};

export const sendMessageToContactApprover = id => dispatch => {
  sendMessageToApprover(id).then(res => {
    const { Data } = res.data;
    dispatch(setSendMessageToApprover(Data === null ? false : Data));
  });
};

export const approveTimecardAction = (
  userTestId = 1,
  name = "Employee",
  TimecardData = []
) => dispatch => {
  dispatch(clearPayrollDashboardItems());
  approveTimecard(userTestId).then(res => {
    const { Data, DataCount, NextUri } = res.data;
    dispatch(
      showToast(
        "You have successfully approved " + name + "'s Unapproved Timecard"
      )
    );
    dispatch(addToPayrollDashboardItems(Data, DataCount, NextUri));
    dispatch(
      setPayrollTypeData(TimecardData.splice(1, 1), 1, "", "TimecardAlerts")
    );
  });
};
export const initiatePayroll = userTestId => dispatch => {
  dispatch(clearPayrollDashboardItems());
  startPayroll(userTestId).then(res => {
    const { Data, DataCount, NextUri } = res.data;
    dispatch(
      setPayrollDashboardItems(Data === null ? [] : Data, DataCount, NextUri)
    );
  });
};

//employee filter drop-down
export const fetchAndSetEmployees = (
  clearExisting = true,
  filtersOnly = true,
  selectAll = true,
  filterName = EMPLOYEE_FILTER_NAME,
  id,
  PayPeriodToDate,
  PayPeriodFromDate,
  successCallback = () => {}
) => dispatch => {
  if (clearExisting) {
    dispatch(clearEntityList());
  }
  dispatch(setLoader(true));
  getEmployeeList(id, PayPeriodToDate, PayPeriodFromDate).then(res => {
    const { Data = [] } = res.data;
    const options = Data.map(e => ({
      value: e.Id,
      label: e.Name,
      id: e.Id
    }));
    successCallback();
    dispatch(setFilterOptions(filterName, options));
    if (!filtersOnly) {
      dispatch(setEntityList(Data));
    }
    if (selectAll) {
      dispatch(selectAllFilterOptions(filterName, true));
    }
    dispatch(setLoader(false));
  });
};

//Types drop-down
export const fetchAndSetTypes = (
  clearExisting = true,
  filtersOnly = true,
  selectAll = false,
  filterName = TYPE_FILTER_NAME,
  successCallback = () => {}
) => dispatch => {
  if (clearExisting) {
    dispatch(clearEntityList());
  }
  dispatch(setLoader(true));
  getTypesList().then(res => {
    const { Data = [] } = res.data;
    const options = Data.map(e => ({
      value: e.Id,
      label: e.Name,
      id: e.Id
    }));
    successCallback();
    dispatch(setFilterOptions(filterName, options));
    if (!filtersOnly) {
      dispatch(setEntityList(Data));
    }
    if (selectAll) {
      dispatch(selectAllFilterOptions(filterName, true));
    }
    dispatch(setLoader(false));
  });
};

//Time Groups drop-down
export const fetchAndSetTimeGroups = (
  clearExisting = true,
  filtersOnly = true,
  selectAll = false,
  filterName = TIME_GROUPS_FILTER_NAME,
  successCallback = () => {}
) => dispatch => {
  if (clearExisting) {
    dispatch(clearEntityList());
  }
  dispatch(setLoader(true));
  getTimeGroupsList().then(res => {
    const { Data = [] } = res.data;
    const options = Data.map(e => ({
      value: e.Id,
      label: e.Name,
      id: e.Id
    }));

    successCallback();
    dispatch(setFilterOptions(filterName, options));
    if (!filtersOnly) {
      dispatch(setEntityList(Data));
    }
    if (selectAll) {
      dispatch(selectAllFilterOptions(filterName, true));
    }
    dispatch(setLoader(false));
  });
};

//Time Periods drop-down
export const fetchAndSetTimePeriods = (
  clearExisting = true,
  filtersOnly = true,
  selectAll = false,
  filterName = TIME_PERIODS,
  successCallback = () => {}
) => dispatch => {
  if (clearExisting) {
    dispatch(clearEntityList());
  }
  dispatch(setLoader(true));
  getTimePeriodsList().then(res => {
    const { Data = [] } = res.data;
    // let todaysDate = moment().format();
    const options = Data.map(e => ({
      value: e.Id,
      label: e.Name,
      id: e.Id
    }));

    successCallback();
    dispatch(setFilterOptions(filterName, options));
    if (!filtersOnly) {
      dispatch(setEntityList(Data));
    }
    if (selectAll) {
      dispatch(selectAllFilterOptions(filterName, true));
    }
    dispatch(setLoader(false));
  });
};

//Pages drop-down
export const fetchAndSetPagesList = (
  clearExisting = true,
  filtersOnly = true,
  selectAll = false,
  filterName = PAGES_FILTER_NAME,
  successCallback = () => {}
) => dispatch => {
  if (clearExisting) {
    dispatch(clearEntityList());
  }
  dispatch(setLoader(true));
  getPagesList().then(res => {
    const { Data = [] } = res.data;
    const options = Data.map(e => ({
      value: e.Id,
      label: e.Name,
      id: e.Id
    }));

    successCallback();
    dispatch(setFilterOptions(filterName, options));
    if (!filtersOnly) {
      dispatch(setEntityList(Data));
    }
    if (selectAll) {
      dispatch(selectAllFilterOptions(filterName, true));
    }
    dispatch(setLoader(false));
  });
};

//Fields drop-down
export const fetchAndSetFieldsList = (
  clearExisting = true,
  filtersOnly = true,
  selectAll = false,
  filterName = FIELDS_FILTER_NAME,
  id,
  successCallback = () => {}
) => dispatch => {
  if (clearExisting) {
    dispatch(clearEntityList());
  }
  dispatch(setLoader(true));
  getFieldsList(id).then(res => {
    const { Data = [] } = res.data;
    const options = Data.map(e => ({
      value: e.Id,
      label: e.Name,
      id: e.Id
    }));

    successCallback();
    dispatch(setFilterOptions(filterName, options));
    if (!filtersOnly) {
      dispatch(setEntityList(Data));
    }
    if (selectAll) {
      dispatch(selectAllFilterOptions(filterName, true));
    }
    dispatch(setLoader(false));
  });
};

export const fetchAndSetState = countryID => dispatch =>
  getState(countryID).then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      countryId: item.CountryId,
      id: item.CountryStateId,
      value: item.CountryStateId,
      label: item.ShortName
    }));
    dispatch(setLoader(false));
    dispatch(editAddressValues("statesList", dispatchData));
  });

const defaultSearchParams = {
  Fields: ["*"],
  Criterion: [],
  Sort: [],
  PageSize: 5
};

export const fetchAndSetFilterOptions = (
  name,
  pathGetter,
  reformat,
  isSelectAll
) => dispatch =>
  getFilterOptions(pathGetter).then(res => {
    dispatch(setFilterOptions(name, (res.data.Data || []).map(reformat)));
    if (isSelectAll) {
      dispatch(selectAllFilterOptions(name, true));
    }
  });

export function checkIfContainsTrue(list) {
  let count = 0;
  let keyList = Object.keys(list);
  keyList.forEach(item => {
    if (list[item] === true) {
      count = count + 1;
    }
  });
  if (count > 0) {
    return true;
  } else {
    return false;
  }
}

export function DoesAnyFilterHasZeroSelectedItems(filters) {
  let isZeroSelected = false;
  if (Object.keys(filters).length > 0) {
    if (
      !checkIfContainsTrue(filters.Employees["selected"]) ||
      !checkIfContainsTrue(filters.LeaveType["selected"]) ||
      !checkIfContainsTrue(filters.TimeGroups["selected"]) ||
      !checkIfContainsTrue(filters.TimePeriods["selected"]) ||
      !checkIfContainsTrue(filters.Pages["selected"]) ||
      !checkIfContainsTrue(filters.Fields["selected"])
    ) {
      isZeroSelected = true;
    }
  }
  return isZeroSelected;
}

// search post action for un-approved pto's tab
export const searchAndSetPostsUnapprovedPto = (
  searchParams = defaultSearchParams,
  id,
  filters = {},
  callback = () => {}
) => dispatch => {
  dispatch(clearPayrollTypeData());
  if (DoesAnyFilterHasZeroSelectedItems(filters)) {
    dispatch(setPayrollTypeData([], 0, "", UN_APPROVED_PTO));
    callback();
    return Promise.resolve();
  } else {
    searchPostsUnapprovedPto(searchParams, id).then(res => {
      const { Data, DataCount, NextUri, Type } = res.data;
      dispatch(
        setPayrollTypeData(Data === null ? [] : Data, DataCount, NextUri, Type)
      );
      callback();
    });
  }
};

//loadAndSetMorePosts refer line 115 to 125

// search post action for time card alerts tab
export const searchAndSetPostsTimecardAlerts = (
  searchParams = defaultSearchParams,
  id,
  filters = {},
  callback = () => {}
) => dispatch => {
  dispatch(clearPayrollTypeData());
  if (DoesAnyFilterHasZeroSelectedItems(filters)) {
    dispatch(setPayrollTypeData([], 0, "", TIME_CARD_ALERTS));
    callback();
    return Promise.resolve();
  } else {
    searchPostsTimecardAlerts(searchParams, id).then(res => {
      const { Data, DataCount, NextUri, Type } = res.data;
      dispatch(
        setPayrollTypeData(Data === null ? [] : Data, DataCount, NextUri, Type)
      );
      callback();
    });
  }
};

// search post action for empoyee updates tab
export const searchAndSetPostsEmployeeUpdates = (
  searchParams = defaultSearchParams,
  id,
  filters = {},
  callback = () => {}
) => dispatch => {
  dispatch(clearPayrollTypeData());
  if (DoesAnyFilterHasZeroSelectedItems(filters)) {
    dispatch(setPayrollTypeData([], 0, "", "EmployeeUpdates"));
    callback();
    return Promise.resolve();
  } else {
    searchPostsEmployeeUpdates(searchParams, id).then(res => {
      const { Data, DataCount, NextUri, Type } = res.data;
      dispatch(
        setPayrollTypeData(Data === null ? [] : Data, DataCount, NextUri, Type)
      );
      callback();
    });
  }
};
