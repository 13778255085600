/** Actions */
const CLEAR_EMPLOYEE_DASHBOARD =
  "people-employee-dashboard/CLEAR_EMPLOYEE_DASHBOARD";
const SET_EMPLOYEE_DASHBOARD =
  "people-employee-dashboard/SET_EMPLOYEE_DASHBOARD";
const SET_VIEW_SSN = "people-employee-list/SET_VIEW_SSN";

export const initialState = {
  loading: true,
  Data: [],
  DataCount: 0
};

/** Reducer */
export const employeeDashboard = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_EMPLOYEE_DASHBOARD:
      return initialState;
    case SET_EMPLOYEE_DASHBOARD: {
      const { Data, DataCount } = payload;
      return {
        ...state,
        Data: Data,
        DataCount: DataCount,
        loading: false
      };
    }
    default:
      return state;
  }
};

/** Action creators */
export const clearEmployeeDashboardData = () => ({
  type: CLEAR_EMPLOYEE_DASHBOARD
});

export const setEmployeeDashboardData = (Data, DataCount) => ({
  type: SET_EMPLOYEE_DASHBOARD,
  payload: { Data, DataCount }
});

export const viewSSN = (state = { state: false }, { type, payload }) => {
  switch (type) {
    case SET_VIEW_SSN:
      return { ...state, state: payload };
    default:
      return state;
  }
};

export const setViewSSN = state => ({
  type: SET_VIEW_SSN,
  payload: state
});
