import React from "react";

const TextInputWithoutLabel = ({
  id,
  name,
  placeholder,
  value,
  onChange,
  error,
  disabled,
  maxLength,
  onBlur,
  width
}) => {
  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }
  if (disabled) {
    wrapperClass += " __disabled-textbox";
  }

  return (
    <div className={wrapperClass}>
      <div className="field">
        <input
          id={id}
          type="text"
          name={name}
          className="form-control"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          maxLength={maxLength}
          onKeyUp={e => {
            if (e.keyCode === 13) {
              e.stopPropagation();
              e.target.blur();
            }
          }}
          onBlur={onBlur}
          style={{ width: width }}
        />
        {error && (
          <p
            className="error-label"
            id="error-label"
            style={{
              marginTop: "5px",
              marginBottom: "0px"
            }}
          >
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default TextInputWithoutLabel;
