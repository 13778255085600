import React, { Component } from "react";
import PayrollDashboardTabs from "./PayrollDashboardTabs";
import PropTypes from "prop-types";

export default class PayrollDashboardHeader extends Component {
  state = {
    selectedTab: 1
  };

  onClick = value => {
    this.setState({
      selectedTab: value
    });
    this.props.selectedTab(value);
  };

  render() {
    const { payrollTitle, payrollStageMessage, payrollTabsData } = this.props;

    return (
      <div className="payroll-dash-header-container">
        <div className="payroll-dash-header-content row">
          <div className="payroll-dash-header-title col-lg-4 col-md-4 col-sm-4 hidden-xs">
            {payrollTitle}
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
            <div className="payroll-dash-header-tab-section clearfix">
              <PayrollDashboardTabs
                payrollTabsData={payrollTabsData}
                onClick={this.onClick}
                selectedTab={this.state.selectedTab}
              />
            </div>
          </div>
          <div className="payroll-dash-header-title col-lg-4 col-md-4 col-sm-4 hidden-sm hidden-lg hidden-md">
            {payrollTitle}
          </div>

          <div className="payroll-dash-header-message col-lg-12 col-md-12 col-sm-12 col-xs-12">
            {payrollStageMessage && (
              <div>
                <span className="payroll-dash-header-stage-message">
                  {payrollStageMessage}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
PayrollDashboardHeader.propTypes = {
  payrollTitle: PropTypes.string,
  payrollStageMessage: PropTypes.node,
  payrollTabsData: PropTypes.array
};
PayrollDashboardHeader.defaultProps = {
  payrollTabsData: []
};
