import React from "react";
import "./NcTab.css";
const NcTab = ({
  id,
  label,
  value,
  badgeNumber,
  width,
  onClick,
  tabType,
  active,
  disabled
}) => {
  let borderColor = "#339966";
  let badgeColor = "#fb6b5b";
  let activeClassName = "";
  let disableClassName = "";
  let style = {
    width: width
  };
  if (tabType === "payroll-dashboard") {
    borderColor = "#339966";
    badgeColor = "#fb6b5b";
  }

  if (active) {
    activeClassName = "nc-tab-active";
    style = {
      width: width,
      borderBottom: "3px solid",
      borderBottomColor: borderColor
    };
  }

  if (badgeNumber === 0) {
    disableClassName = "nc-tab-disable";
    badgeColor = "#999";
  }

  return (
    <div
      id={id}
      className={`nc-tabs ${activeClassName} ${disableClassName}`}
      style={style}
      onClick={() => onClick(value)}
    >
      <div className="nc-tab-label">{label}</div>
      <div className="nc-tab-badge">
        <span
          className="badge-count badge"
          style={{ backgroundColor: badgeColor }}
        >
          {badgeNumber}
        </span>
      </div>
    </div>
  );
};

export default NcTab;
