import React, { Component } from "react";
import { connect } from "react-redux";
import FiltersDesktop from "component-library/custom-fields/FiltersDesktop";
import FiltersMobile from "component-library/custom-fields/FiltersMobile";
import {
  selectFilterOption,
  selectAllFilterOptions,
  makeOptions,
  makeSelectedOptions
} from "app/common/reducers/filters";
import Media from "react-media";

class CustomFieldsFiltersContainer extends Component {
  state = {};

  makeOnSelect = name => (option, selected) => {
    this.props.selectOption(name, option, selected);
  };

  makeSelectAll = name => selected => {
    this.props.selectAll(name, selected);
  };

  makeDateRangeSelect = (name, fromToValue) => {
    this.props.selectDateRange(name, fromToValue);
  };

  render() {
    return (
      <Media query="(min-width:320px) and (max-width:991px)">
        {matches =>
          matches ? (
            <FiltersMobile
              options={this.props.options}
              selectedOptions={this.props.selectedOptions}
              makeOnSelect={this.makeOnSelect}
              makeSelectAll={this.makeSelectAll}
              onCloseFilter={this.props.searchPosts}
              editSortOrder={this.props.editSortOrder}
              sortOrder={this.props.sortOrder}
              filterCount={this.getFilterCount(
                this.props.selectedOptions,
                this.props.options
              )}
              resetFilters={this.resetFilters}
              activityEntityId={this.props.activityEntityId}
              Id={this.props.Id}
            />
          ) : (
            <FiltersDesktop
              options={this.props.options}
              selectedOptions={this.props.selectedOptions}
              makeOnSelect={this.makeOnSelect}
              makeSelectAll={this.makeSelectAll}
              onCloseFilter={this.props.searchPosts}
              activityEntityId={this.props.activityEntityId}
              Id={this.props.Id}
            />
          )
        }
      </Media>
    );
  }
}
const stateToProps = state => ({
  options: makeOptions(state.common.filters),
  selectedOptions: makeSelectedOptions(state.common.filters)
});

const actionCreators = {
  selectOption: selectFilterOption,
  selectAll: selectAllFilterOptions
};

export default connect(stateToProps, actionCreators)(
  CustomFieldsFiltersContainer
);
