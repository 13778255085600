import React, { Component } from "react";
import SortFilterButton from "../filters/SortFilterButton";
import EntityListFilterModal from "./EntityListFilterModal";
import EntityListSortModal from "./EntityListSortModal";

export default class EntityListFiltersMobile extends Component {
  state = {
    showFilterModal: false,
    showSortModal: false
  };

  toggleFilterModal = showFilterModal => {
    this.setState({ showFilterModal });
  };

  toggleSortModal = showSortModal => {
    this.setState({ showSortModal });
  };

  render() {
    const {
      options = {},
      selectedOptions = {},
      makeOnSelect = () => undefined,
      makeSelectAll = () => undefined,
      sortOrder = {},
      editSortOrder,
      clearAll,
      page = "Earnings",
      filterCount,
      Id,
      filterData,
      showUnmapped,
      unmappedId,
      showUnmappedName
    } = this.props;
    const { showFilterModal, showSortModal } = this.state;
    return (
      <div className="hidden-lg hidden-md">
        <SortFilterButton
          sortHandler={() => this.toggleSortModal(true)}
          filterHandler={() => this.toggleFilterModal(true)}
          filterCount={filterCount}
          Id={Id}
        />
        <EntityListFilterModal
          show={showFilterModal}
          closePanel={() => this.toggleFilterModal(false)}
          options={options}
          selectedOptions={selectedOptions}
          makeOnSelect={makeOnSelect}
          makeSelectAll={makeSelectAll}
          clearAll={clearAll}
          page={page}
          Id={Id}
          filterData={filterData}
          showUnmapped={showUnmapped}
          unmappedId={unmappedId}
          showUnmappedName={showUnmappedName}
        />
        <EntityListSortModal
          page={page}
          show={showSortModal}
          closePanel={() => this.toggleSortModal(false)}
          editSortOrder={editSortOrder}
          sortOrder={sortOrder}
          Id={Id}
        />
      </div>
    );
  }
}
