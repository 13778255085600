/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
const ProgressItemMobile = props => (
  <div key={props.id} className="deduction-circle-icons">
    <div className="visible-sm visible-xs steps-item-section">
      <div className={props.className} onClick={props.onClick}>
        <i
          className={`fa fa-check`}
          aria-hidden="true"
          style={{
            fontSize: "20px",
            visibility: props.active ? "inherit" : "hidden"
          }}
        />
      </div>
      <div className="step-name align-center">
        <a
          className="add-deduction-step-sub-menu-link-name"
          onClick={props.onClick}
        >
          {props.stepName}
        </a>
      </div>
    </div>
  </div>
);
export default ProgressItemMobile;
