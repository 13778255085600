//STEPS
export const DESCRIPTION_STEP = 1;
export const CALCULATION_STEP = 2;
export const PAYCYCLE_STEP = 3;
export const OPTIONS_STEP = 4;
export const REVIEW_STEP = 5;

//Page Links
export const DESCRIPTION_LINK = "/company-level-earnings/setup/description/";
export const NEW_DESCRIPTION_LINK = "/company-level-earnings/setup/";
export const CALCULATION_LINK = "/company-level-earnings/setup/calculation/";
export const PAYCYCLE_LINK = "/company-level-earnings/setup/paycycle/";
export const OPTIONS_LINK = "/company-level-earnings/setup/options/";
export const REVIEW_LINK = "/company-level-earnings/setup/reviewAndSave/";

export const LIST_PAGE_LINK = "/company-level-earnings";

export const NET_ADMIN = 1;
export const CLIENT_ADMIN = 2;
export const MANAGER = 3;
export const EMPLOYEE = 4;

// Calculation Method Page

export const EARNINGS_TRACKING_DOLLARS = "Dollars";
export const EARNINGS_TRACKING_HOURS = "Hours";

export const TRACKING_OPTIONS = [
  { id: 2, label: "Hours", value: EARNINGS_TRACKING_HOURS },
  { id: 1, label: "Dollars", value: EARNINGS_TRACKING_DOLLARS }
];

export const CALCULATION_METHOD_TRACK_DOLLARS_TOASTER =
  "Since this earnings is set up for tracking dollars, only Dollar Amount is available as Calculation Method.";

export const CALCULATION_METHOD_TRACK_HOURS_TOASTER =
  "Since this earnings is set up for tracking hours, only Hourly Rate and Piece Rate Hour are available as Calculation Methods.";

export const CALCULATION_METHOD_TRACK_HOURS_TOASTER_CLIENT_ADMIN =
  "Since this earnings is set up for tracking hours, only Hourly Rate is available as Calculation Method.";

export const EARNINGS_PAID_NEGATIVE100_ERROR =
  "The percent premium currently set (-100%) is not supported for earnings that are paid.";

export const PREMIUM_PERCENT_PAID_NEGATIVE100_ERROR =
  "The percent premium (-100%) is not supported for earnings that are paid.";

export const OPTIONS_MODIFIED_TOASTER =
  "There are additional options available for review.";

//Earning Groups
export const GROSS_EARNINGS = "1";
export const TAXABLE_EARNINGS = "2";
export const DISPOSABLE_EARNINGS = "3";
export const RETIREMENT_EARNINGS = "4";
export const PERCENTAGE_EARNINGS = "5";
export const HOUR_BASED_EARNINGS_1 = "6";
export const HOUR_BASED_EARNINGS_2 = "7";
export const HOUR_BASED_EARNINGS_3 = "8";
export const HOUR_BASED_EARNINGS_4 = "9";
export const HOUR_BASED_EARNINGS_5 = "10";

export const SAVE_GO = "SAVE AND GO";
export const LEAVE_PAGE = "LEAVE THIS PAGE";
export const SAVE_EXIT = "SAVE AND EXIT";
export const EXIT = "EXIT SETUP";
export const DELETE_EARNINGS = "DELETE EARNINGS";
export const MAX_HOURLY_RATE_NUMBER = 98;

export const deleteMessage = {
  line1: "Are you sure you want to delete this earnings?",
  line2: "It will be gone for now, but you can add it back if you need it."
};
export const toggleBackMessage = {
  line1: "Do you want to save your changes before leaving this page?",
  line2: "",
  line3:
    "Are you sure you want to leave this page in the middle of making edits?",
  line4: "Changes made on this page will not be saved."
};
export const finishLaterMessage = {
  line1: "Do you want to save your changes before exiting setup?",
  line2: "",
  line3: "Are you sure you want to exit setup in the middle of making edits?",
  line4: "Changes made on this page will not be saved."
};
