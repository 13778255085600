import React from "react";
import PropTypes from "prop-types";
const NoDataDropDownSection = ({ id, children, isMobile }) => (
  <div
    id={id}
    className="ncl-dropdown-no-data-section"
    style={{ width: isMobile && "100%" }}
  >
    {children}
  </div>
);
export default NoDataDropDownSection;
NoDataDropDownSection.propTypes = {
  id: PropTypes.string,
  isMobile: PropTypes.bool
};
