import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./JobHistoryActions.css";

const JobHistoryActions = ({ fullReportPath, onExcelClick, onPrintClick }) => (
  <div className="job-history-actions">
    <Link to={fullReportPath}>
      Go to Full Job History Report <i className="fa fa-chevron-right" />
    </Link>

    <div>
      <i
        className="fa fa-print"
        style={{ marginRight: 32 }}
        onClick={onExcelClick}
      />
      <i className="fa fa-print" onClick={onPrintClick} />
    </div>
  </div>
);

JobHistoryActions.propTypes = {
  onExcelClick: PropTypes.func,
  onPrintClick: PropTypes.func
};

export default JobHistoryActions;
