/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import PropTypes from "prop-types";
import EntityListTableHeader from "../../entity-list/EntityListTableHeader";
import EmployeeListItem from "./EmployeeListItem";
import InfiniteScroll from "react-infinite-scroller";
import * as util from "../../util";

const loader = <div className="loader">Loading ...</div>;
export default class EmployeeTable extends Component {
  toggleSSN = () => {
    this.props.auditViewSsnClick(!this.props.viewSSN);
  };

  render() {
    const {
      loadingFirstTime,
      data,
      dataCount,
      editSortOrder,
      sortOrder,
      headerColumn,
      onClickEmployee,
      onConfigureClick,
      loadMorePosts,
      nextUri,
      loadingMore,
      loggedUser = {},
      viewSSN
    } = this.props;
    return (
      <div className="list-page-grid-table col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="employee-results-bar">
          {loadingFirstTime ? (
            <div className="nc-rowcount-results hidden-sm hidden-xs">
              Loading...
            </div>
          ) : (
            <div className="nc-rowcount-results hidden-sm hidden-xs">
              We {dataCount === 0 && "have "}
              found {dataCount} {dataCount === 1 ? "result." : "results."}
            </div>
          )}
          <div>
            {loggedUser.allowedToShowSSN === true ? (
              viewSSN ? (
                <a onClick={this.toggleSSN}>
                  <i className="fa fa-eye-slash" />
                  Hide SSN
                </a>
              ) : (
                <a onClick={this.toggleSSN}>
                  <i className="fa fa-eye" />
                  View SSN
                </a>
              )
            ) : null}
            <a onClick={onConfigureClick} style={{ marginLeft: "20px" }}>
              <i className="fa fa-cog" />
              Configure List
            </a>
          </div>
        </div>

        <EntityListTableHeader
          editSortOrder={editSortOrder}
          loadingFirstTime={loadingFirstTime}
          sortOrder={sortOrder}
          headerColumn={headerColumn}
          size={[12, 12, 12, 12]}
        />

        <InfiniteScroll
          pageStart={0}
          loadMore={!loadingMore ? loadMorePosts : () => {}}
          hasMore={!util.isNullOrEmpty(nextUri) ? true : false}
          loader={loader}
        >
          {data.map((item, i) => (
            <EmployeeListItem
              item={item}
              key={i}
              onClickEmployee={() => onClickEmployee(item.EmployeeId)}
              showSSN={viewSSN}
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  }
}

EmployeeTable.propTypes = {
  loadingFirstTime: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  editSortOrder: PropTypes.func.isRequired,
  sortOrder: PropTypes.object,
  headerColumn: PropTypes.string,
  onConfigureClick: PropTypes.func,
  loggedUser: PropTypes.object
};
EmployeeTable.defaultProps = {
  loadingFirstTime: false,
  data: [],
  editSortOrder: () => alert("Specify the function"),
  sortOrder: {},
  headerColumn: "EmployeeProfile",
  onConfigureClick: () => alert("Specify the configure list Click"),
  loggedUser: {
    allowedToShowSSN: true
  }
};
