import Loadable from "react-loadable";
import ChunkLoading from "app/common/UIComponents/ChunkLoading";

const DeductionTaxPriority = Loadable({
  loader: () =>
    import("./DeductionTaxPriority" /* webpackChunkName: "deduction-tax-priority" */),
  loading: ChunkLoading
});

export { DeductionTaxPriority };
