import * as types from "../actions/index";
import { SET_FILTER_OPTIONS } from "./../common/reducers/filter";
import { EDIT_ENTITY_DATA } from "app/common/reducers/entityData";
import { ENTITY_SNAPSHOT } from "app/common/reducers/entitySnapshot";

export default function(state = { state: false, label: "Loading" }, action) {
  switch (action.type) {
    case types.SET_LOADER:
      return { ...state, ...action.payload };
    // no default
  }
  return state;
}

// api/loadingReducer.js
export const loadingReducer = (state = {}, action) => {
  const { type, payload } = action;
  if (type === SET_FILTER_OPTIONS) {
    const { name, status } = payload;
    return {
      ...state,
      [name]: status
    };
  }
  if (type === EDIT_ENTITY_DATA || type === ENTITY_SNAPSHOT) {
    return { ...state, entityData: payload.status };
  }
  if (type === CLEAR_FETCH_REDUCER) {
    return false;
  }
  return state;
};

export const dataFetchLoader = (state = false, action) => {
  switch (action.type) {
    case "FETCHING_DATA":
      return action.payload;

    case "CLEAR_FETCH_REDUCER":
      return false;
    // no default
  }
  return state;
};

export const setDataFetchLoader = data => ({
  type: "FETCHING_DATA",
  payload: data
});

export const clearDataFetchLoader = () => ({
  type: CLEAR_FETCH_REDUCER,
  payload: {}
});

const CLEAR_FETCH_REDUCER = "CLEAR_FETCH_REDUCER";
