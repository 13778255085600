import moment from "moment";

export const setAndFetchEditPayrollScheduleData = data => {
  const payload = {
    id1: data.CompanyPayrollScheduleDefinitionId,
    Frequency:
      data.PayrollFrequencyId === 1
        ? "Weekly"
        : data.PayrollFrequencyId === 2
          ? "Bi-Weekly"
          : data.PayrollFrequencyId === 3
            ? "Semi-Monthly"
            : data.PayrollFrequencyId === 4
              ? "Monthly"
              : data.PayrollFrequencyId === 5
                ? "Quarterly"
                : data.PayrollFrequencyId === 6
                  ? "Annually"
                  : "Daily",
    firstPayCheckDate: data.FirstPaycheckDayOfMonth,
    secondPayCheckDate: data.SecondPaycheckDayOfMonth,

    firstSwitch: data.FirstPayPeriodEndOffset.toString(),
    secondSwitch: data.SecondPayPeriodEndOffset.toString(),

    firstPayCheckStartMonth: data.FirstPaycheckMonth,
    firstPayCheckEndMonth: data.FirstPayPeriodEndMonth,

    TotalHours: data.PayPeriodWorkHours,
    PayrollScheduleName: data.CompanyPayrollScheduleDefinitionName,
    firstPayCheckEndDate: data.FirstPayPeriodEndDayOfMonth,
    secondPayCheckEndDate: data.SecondPayPeriodEndDayOfMonth,
    BusinessDays: data.PayrollSubmitBusinessDaysPriorToPaycheckDate,
    DirectDepositPaymentForWeekend:
      data.DayOptionForDirectDepositIfPaycheckDateIsOnWeekend,
    DirectDepositPaymentForBankHolidays:
      data.DayOffsetForDirectDepositIfPaycheckDateIsOnHoliday,
    AdjustPaycheckDate:
      data.IsMatchPaycheckDateAndDirectDepositDate === true ? 1 : 2,

    ReduceSalaryAutomatically: data.CompanyHolidayCalendarId > 0 ? "1" : "2",
    //HolidayCalender: data.CompanyHolidayCalendarId,

    HolidayCalender: 1,
    PayPeriodEnd: data.PayPeriodEndDaysPriorToPaycheckDate,
    WeekDayToPay: data.DayOfWeekToPayForWeeklyBiweeklyFrequency,
    PayrollScheduleStartDate: moment(data.PayrollScheduleStartDate).format(
      "MM/YYYY"
    ),
    scheduleDays:
      data.WeekDaysToPayForDailyFrequency.length > 0
        ? JSON.parse("[" + data.WeekDaysToPayForDailyFrequency + "]")
        : []
  };
  return payload;
};
