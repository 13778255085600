import React, { Component } from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import { socialAccounts } from "../Constants";

class SocialMediaAccounts extends Component {
  render() {
    const { data, onChange } = this.props;
    //const { initialData } = data;
    const componentProps = socialAccounts;
    const contactInfoNodes = Object.keys(componentProps).map(k => {
      return (
        <FormFieldGroup
          key={k}
          {...componentProps[k]}
          block
          colSpan="col-md-2"
          value={data[k]}
          onChange={onChange}
          error={data.errors[componentProps[k]["inputName"]]}
        />
      );
    });
    return (
      <FormSection title="Social Media Accounts" hr>
        <div>{contactInfoNodes}</div>
      </FormSection>
    );
  }
}

export default SocialMediaAccounts;
