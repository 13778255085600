import React from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import Table from "./earnings-table/Table";
import * as Fields from "../FieldNames";

const ScheduledEarnings = ({ data = {}, onChange }) => {
  return (
    <FormSection title={"Scheduled Earnings"} hr>
      <FormFieldGroup
        inputType="radio"
        inputName={Fields.changeScheduledEarnings}
        label="Do you want to make any changes to the employee's scheduled earnings ?"
        fieldType="inline"
        value={data[Fields.changeScheduledEarnings]}
        radios={[
          { id: 1, value: true, label: "Yes" },
          { id: 2, value: false, label: "No" }
        ]}
        class="col-lg-12"
        colSpan="col-lg-3 col-md-3"
        Size={[6, 4, 12, 12]}
        onChange={onChange}
      />
      {data[Fields.changeScheduledEarnings] && (
        <Table entity="earnings" data={data} onChange={onChange} />
      )}
    </FormSection>
  );
};

export default ScheduledEarnings;
