/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Panel, Overlay } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { bindActionCreators } from "redux";
import { Badge } from "react-bootstrap";
import { Checkbox, CheckboxGroup } from "react-checkbox-group";
import CustomOverlay from "component-library/dropDown/CustomOverlay";
import { getCategoryList } from "../../../actions/systemlevel/thirdpartypayee/AdvanceFilterActions";
import classnames from "classnames/bind";
const ALL_CATEGORIES = 0;
const CATEGORY_PANEL_ID = 2;

let isAllUncheckedZero = false;

class ThirdPartyPayeeCategoryDropdown extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      selectedCategories: props.selectedCategories,
      selectCategoryLabel: "All Categories",
      isAllSelected: true
    };

    this.onCloseCategoryDropdown = this.onCloseCategoryDropdown.bind(this);
    this.checkSelectCategory = this.checkSelectCategory.bind(this);
    this.renderCategorySelectionList = this.renderCategorySelectionList.bind(
      this
    );
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  checkSelectCategory(newCategory) {
    // let categoryLabel = (newCategory.length > 1 ? 'Categories' : 'Category');
    let categoryLabel =
      newCategory.length > 1
        ? "Categories"
        : newCategory.length === 0
        ? "Select Category"
        : "Category";

    if (newCategory.includes(ALL_CATEGORIES)) {
      let indexOfAllCategory = newCategory.indexOf(ALL_CATEGORIES);

      if (
        indexOfAllCategory !== 0 ||
        this.state.selectedCategories.length === 0
      ) {
        newCategory = [];
        for (
          let i = 0;
          i < this.props.thirdPartyPayeeCategoryList.length;
          i++
        ) {
          let ThirdPartyPayeeCategoryId = this.props
            .thirdPartyPayeeCategoryList[i].ThirdPartyPayeeCategoryId;
          newCategory.push(ThirdPartyPayeeCategoryId);
        }
        this.setState({
          selectedCategories: newCategory,
          selectCategoryLabel: "All Categories",
          isAllSelected: true
        });
      } else {
        newCategory.splice(0, 1);
        this.setState({
          selectedCategories: newCategory,
          selectCategoryLabel: categoryLabel,
          isAllSelected: false
        });
      }
    } else if (this.state.isAllSelected && !newCategory.includes(0)) {
      this.setState({
        selectedCategories: [],
        selectCategoryLabel: "Select Category",
        isAllSelected: false
      });
    } else {
      if (
        this.props.thirdPartyPayeeCategoryList.length - 1 ===
          newCategory.length &&
        !newCategory.includes(ALL_CATEGORIES)
      ) {
        newCategory = [];
        for (
          let i = 0;
          i < this.props.thirdPartyPayeeCategoryList.length;
          i++
        ) {
          let ThirdPartyPayeeCategoryId = this.props
            .thirdPartyPayeeCategoryList[i].ThirdPartyPayeeCategoryId;
          newCategory.push(ThirdPartyPayeeCategoryId);
        }
        this.setState({
          selectedCategories: newCategory,
          selectCategoryLabel: "All Categories",
          isAllSelected: true
        });
      } else {
        this.setState({
          selectedCategories: newCategory,
          selectCategoryLabel: categoryLabel,
          isAllSelected: false
        });
      }
    }

    if (
      newCategory.length === 0 ||
      (this.state.isAllSelected === true &&
        newCategory.includes(ALL_CATEGORIES) === false &&
        newCategory.indexOf(ALL_CATEGORIES) === -1 &&
        newCategory.length ===
          this.props.thirdPartyPayeeCategoryList.length - 1)
    ) {
      isAllUncheckedZero = true;
    } else {
      isAllUncheckedZero = false;
    }
  }

  componentDidMount() {
    let selectedCategories = [];
    const { systemLevelTPPSelectedFiltersList } = this.props;
    if (
      systemLevelTPPSelectedFiltersList !== undefined &&
      systemLevelTPPSelectedFiltersList !== null &&
      systemLevelTPPSelectedFiltersList.length !== 0
    ) {
      if (
        this.props.systemLevelTPPSelectedFiltersList.selectedCategories.length >
        0
      ) {
        this.props.systemLevelTPPSelectedFiltersList.selectedCategories.forEach(
          selectedItem => {
            selectedCategories.push(selectedItem);
          }
        );
      }
    } else {
      // selectedCategories[0] = 0;
      if (this.props.thirdPartyPayeeCategoryList) {
        this.props.thirdPartyPayeeCategoryList.forEach(item => {
          selectedCategories.push(item.ThirdPartyPayeeCategoryId);
        });
      }
    }

    let selectCategoryLabel = "Categories";
    let isAllSelected = false;

    if (
      this.props.thirdPartyPayeeCategoryList.length ===
      selectedCategories.length
    ) {
      selectCategoryLabel = "All Categories";
      isAllSelected = true;
    } else if (selectedCategories.length === 0) {
      selectCategoryLabel = "Select Category";
      isAllSelected = false;
    } else if (selectedCategories.length === 1) {
      selectCategoryLabel = "Category";
      isAllSelected = false;
    } else {
      selectCategoryLabel = "Categories";
      isAllSelected = false;
    }

    this.setState({
      selectedCategories,
      selectCategoryLabel,
      isAllSelected
    });

    this.props.onSelectedCategories(selectedCategories);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.systemLevelCategoryOriginalList !==
      this.props.systemLevelCategoryOriginalList
    ) {
      let selectedCategories = [];
      selectedCategories[0] = 0;

      if (nextProps.systemLevelCategoryOriginalList.Data) {
        nextProps.systemLevelCategoryOriginalList.Data.forEach(item => {
          selectedCategories.push(item.ThirdPartyPayeeCategoryId);
        });
      }

      let selectCategoryLabel = "Categories";
      let isAllSelected = false;

      if (
        nextProps.systemLevelCategoryOriginalList.DataCount + 1 ===
        selectedCategories.length
      ) {
        selectCategoryLabel = "All Categories";
        isAllSelected = true;
      } else {
        selectCategoryLabel = "Categories";
        isAllSelected = false;
      }

      this.setState({
        selectedCategories,
        selectCategoryLabel,
        isAllSelected
      });
    }

    if (nextProps.isResetToDefault !== this.props.isResetToDefault) {
      if (nextProps.isResetToDefault) {
        let selectedCategories = [];
        selectedCategories[0] = 0;

        if (nextProps.systemLevelCategoryOriginalList.Data) {
          nextProps.systemLevelCategoryOriginalList.Data.forEach(item => {
            selectedCategories.push(item.ThirdPartyPayeeCategoryId);
          });
        }

        let selectCategoryLabel = "Categories";
        let isAllSelected = false;

        if (
          nextProps.systemLevelCategoryOriginalList.DataCount + 1 ===
          selectedCategories.length
        ) {
          selectCategoryLabel = "All Categories";
          isAllSelected = true;
        } else {
          selectCategoryLabel = "Categories";
          isAllSelected = false;
        }

        this.setState({
          selectedCategories,
          selectCategoryLabel,
          isAllSelected
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedCategories !== this.state.selectedCategories) {
      this.props.onSelectedCategories(this.state.selectedCategories);
    }
  }

  onCloseCategoryDropdown() {
    this.props.onSelectedCategories(this.state.selectedCategories);
  }

  renderCategorySelectionListMobile() {
    const { id, thirdPartyPayeeCategoryList } = this.props;
    if (!thirdPartyPayeeCategoryList) {
      return <div />;
    } else {
      return (
        <Scrollbars
          autoHeight={true}
          autoHeightMax={320}
          renderThumbHorizontal={props => (
            <div style={{ overflowX: "hidden" }} className="thumb-horizontal" />
          )}
        >
          <CheckboxGroup
            name="select-category"
            value={this.state.selectedCategories}
            onChange={this.checkSelectCategory}
          >
            <ul
              id={id + "-options-list"}
              className="nc-dropdown-navigation-multiselect"
            >
              {this.renderCategorySelectionItem(
                this.props.thirdPartyPayeeCategoryList
              )}
            </ul>
          </CheckboxGroup>
        </Scrollbars>
      );
    }
  }

  renderCategorySelectionList() {
    const { id, thirdPartyPayeeCategoryList } = this.props;
    if (!thirdPartyPayeeCategoryList) {
      return <div />;
    } else {
      return (
        <CustomOverlay>
          <Scrollbars
            autoHeight={true}
            autoHeightMax={320}
            renderThumbHorizontal={props => (
              <div
                style={{ overflowX: "hidden" }}
                className="thumb-horizontal"
              />
            )}
          >
            <CheckboxGroup
              name="select-category"
              value={this.state.selectedCategories}
              onChange={this.checkSelectCategory}
            >
              <ul
                id={id + "-options-list"}
                className="nc-dropdown-navigation-multiselect"
              >
                {this.renderCategorySelectionItem(
                  this.props.thirdPartyPayeeCategoryList
                )}
              </ul>
            </CheckboxGroup>
          </Scrollbars>
        </CustomOverlay>
      );
    }
  }

  renderCategorySelectionItem(list) {
    const { id } = this.props;
    return list.map(item => {
      return (
        <li
          key={item.Name + item.ThirdPartyPayeeCategoryId}
          className="list-item"
        >
          <Checkbox
            id={id + "-list-item-" + item.ThirdPartyPayeeCategoryId}
            value={item.ThirdPartyPayeeCategoryId}
          />
          <label
            htmlFor={id + "-list-item-" + item.ThirdPartyPayeeCategoryId}
            id={id + "-list-item-" + item.ThirdPartyPayeeCategoryId + "-label"}
          >
            {item.Name}
          </label>
        </li>
      );
    });
  }

  openSubPanelById(id) {
    let update = {};

    if (id === this.state.subPanelId) {
      update["subPanelId"] = 0;
    } else {
      update["subPanelId"] = id;
    }

    this.setState(update);
  }

  render() {
    const { id } = this.props;
    const categorySelectionPanel = this.renderCategorySelectionList();

    if (!this.props.thirdPartyPayeeCategoryList) {
      return <div>No Records Found.</div>;
    }
    if (window.innerWidth <= 991) {
      return (
        <div>
          <div className="mobile-filter-panel-list-section">
            <a
              id=""
              onClick={this.openSubPanelById.bind(this, CATEGORY_PANEL_ID)}
            >
              <div className="mobile-filter-panel-list-panel-bar col-xs-12 col-sm-12">
                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-icon">
                  {this.state.subPanelId === CATEGORY_PANEL_ID ? (
                    <i
                      className="fa fa-chevron-up add_deduction_popup_panel_link"
                      aria-hidden="true"
                    />
                  ) : (
                    <i
                      className="fa fa-chevron-down add_deduction_popup_panel_link"
                      aria-hidden="true"
                    />
                  )}
                </div>

                <div className="col-xs-8 col-sm-8 mobile-filter-panel-list-label no-padding">
                  {this.state.selectCategoryLabel}
                </div>

                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-badge no-padding">
                  {isAllUncheckedZero ? null : this.state
                      .isAllSelected ? null : (
                    <Badge className="filter-select-badge">
                      {this.state.selectedCategories.length}
                    </Badge>
                  )}
                </div>
              </div>
            </a>

            <div className="mobile-filter-panel-list-panel-bar-section">
              <Panel
                className="mobile-filter-panel-list-panel-default no-padding col-xs-12 col-sm-12 col-md-12 col-lg-12 "
                collapsible
                expanded={
                  this.state.subPanelId === CATEGORY_PANEL_ID ? true : false
                }
              >
                <Panel.Collapse>
                  <Panel.Body>
                    {this.renderCategorySelectionListMobile()}
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="nc-select-container hidden-sm hidden-xs">
            <Button
              id={id + "-button"}
              onClick={this.toggle}
              className="filter-select-button"
              ref={button => (this.categoryFilter = button)}
            >
              <span className="selected-value">
                {this.state.selectCategoryLabel}
              </span>

              <i
                className={classnames(
                  "pull-right",
                  "fa",
                  this.state.popoverOpen ? "fa-chevron-up" : "fa-chevron-down"
                )}
              />

              {isAllUncheckedZero ? null : this.state.isAllSelected ? null : (
                <Badge
                  id={id + "-count-badge"}
                  className="filter-badge pull-right"
                >
                  {this.state.selectedCategories.length}
                </Badge>
              )}
            </Button>
          </div>

          <Overlay
            show={this.state.popoverOpen}
            onHide={() => this.setState({ popoverOpen: false })}
            placement="bottom"
            container={this}
            rootClose={true}
            target={this.categoryFilter}
          >
            {categorySelectionPanel}
          </Overlay>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  let list = [
    {
      ThirdPartyPayeeCategoryId: 0,
      Name: "All Categories"
    }
  ];

  let selectedCategories = [];
  selectedCategories[0] = 0;

  if (state.thirdPartyPayeeCategoryList.Data) {
    state.thirdPartyPayeeCategoryList.Data.forEach(item => {
      list.push(item);
      selectedCategories.push(item.ThirdPartyPayeeCategoryId);
    });
  }

  return {
    thirdPartyPayeeCategoryList: list,
    systemLevelCategoryOriginalList: state.thirdPartyPayeeCategoryList,
    selectedCategories,
    systemLevelTPPSelectedFiltersList: state.systemLevelTPPSelectedFiltersList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCategoryList
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThirdPartyPayeeCategoryDropdown);
