/** Actions */
export const SET_FIELD_OPTIONS = "activity-history/SET_FIELD_OPTIONS";

export const SELECT_FIELD_OPTION = "activity-history/SELECT_FIELD_OPTION";
export const SELECT_DATERANGE_OPTION =
  "activity-history/SELECT_DATERANGE_OPTION";
export const SELECT_ALL_FIELD_OPTIONS =
  "activity-history/SELECT_ALL_FIELD_OPTIONS";

export const initialState = {
  options: [],
  selected: {}
};

/** Reducer */
export const field = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FIELD_OPTIONS: {
      const { options } = payload;
      return {
        ...state,
        options
      };
    }
    case SELECT_FIELD_OPTION: {
      const { option, name, value } = payload;
      let newSelected = {};
      if (name === "toCompany") {
        newSelected = { ...state.selected, [option]: value };
        return {
          ...state,
          selected: { ...newSelected }
        };
      } else {
        newSelected = {
          ...state.selected,
          [name]: option
        };
        return {
          ...state,
          selected: newSelected
        };
      }
    }
    case SELECT_ALL_FIELD_OPTIONS: {
      const newSelected = {};
      state.options.forEach(
        ({ value }) => (newSelected[value] = payload.value)
      );
      return {
        ...state,
        selected: newSelected
      };
    }

    case SELECT_DATERANGE_OPTION: {
      const { fromToValue } = payload;
      return {
        ...state,
        selected: fromToValue
      };
    }
    default:
      return state;
  }
};

export default field;

/** Selector */
export const getDataField = state => state || initialState;
