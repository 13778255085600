/** Actions */
const CLEAR_EMPLOYEE_INFORMATION =
  "people-employee-dashboard/CLEAR_EMPLOYEE_INFORMATION";
const SET_EMPLOYEE_INFORMATION =
  "people-employee-dashboard/SET_EMPLOYEE_INFORMATION";

export const initialState = {
  loading: true,
  Data: {},
  DataCount: 0
};

/** Reducer */
const employeeInformation = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_EMPLOYEE_INFORMATION:
      return initialState;
    case SET_EMPLOYEE_INFORMATION: {
      const { Data, DataCount } = payload;
      return {
        Data: Data,
        DataCount: DataCount,
        loading: false
      };
    }
    default:
      return state;
  }
};

export default employeeInformation;

/** Action creators */
export const clearEmployeeInformationData = () => ({
  type: CLEAR_EMPLOYEE_INFORMATION
});

export const setEmployeeInformationData = (Data, DataCount) => ({
  type: SET_EMPLOYEE_INFORMATION,
  payload: { Data, DataCount }
});
