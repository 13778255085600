import React from "react";
import ListItem from "./tax-verification/ListItem";
import "./WageTaxValidation.css";
import Header from "../multi-section-table/MobileHeader";
import { TaxVerificationInTitle } from "../images/WageAndTaxValidation";
import TableHeader from "./TableHeader";
const PAGE_TITLE = "Tax Verification";
const PAGE_DESCRIPTION =
  "We have identified the correct payroll state and local withholding taxes based off an employee's home and work addresses.";
const ColumnsLabel = {
  Current: "Current Taxes",
  Suggested: "Suggested Taxes"
};
const Columns = [
  {
    label: "Employee",
    field: "Employee",
    className: "col-lg-4 col-md-4"
  },
  {
    label: ColumnsLabel.Current,
    field: "CurrentMinimumWage",
    className: "col-lg-4 col-md-4",
    noSort: true
  },
  {
    label: ColumnsLabel.Suggested,
    field: "CorrectMinimumWage",
    className: "col-lg-4 col-md-4",
    noSort: true
  }
];
const TaxVerification = ({
  data = [],
  hr,
  redirectToChecklist,
  icon,
  changeSortOrder,
  sortOrder
}) => (
  <div className="wage-tax-sub-section">
    <Header
      title={PAGE_TITLE}
      icon={icon}
      badgeCount={data.length}
      description={PAGE_DESCRIPTION}
      onClick={redirectToChecklist}
    />
    <TableHeader
      columns={Columns}
      changeSortOrder={changeSortOrder}
      sortOrder={sortOrder}
    />

    <div className="wage-table-container">
      {data.map((item, i) => (
        <ListItem
          key={item.Id}
          data={item}
          showAccordian
          currentValue={item.EmployeeCurrentStateWithHolding}
          suggestedValue={item.EmployeeSuggestedStateWithHolding}
          homeAddress={item.EmployeeCurrentHomeAddress}
          workAddress={item.EmployeeWorkAddress}
          checkListName="Taxes"
          hr={hr}
        />
      ))}
    </div>
  </div>
);

export default TaxVerification;

TaxVerification.defaultProps = {
  data: [],
  hr: true,
  redirectToChecklist: () => alert("Should redirect to Checklist page"),
  icon: <TaxVerificationInTitle width={25} height={25} />
};
