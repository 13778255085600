import React from "react";

const EmployeeStatusDate = ({ height, width, style }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      height={height}
      width={width}
      viewBox="0 0 56.5 49.7"
      style={{ enableBackground: "new 0 0 56.5 49.7", ...style }}
      xmlSpace="preserve"
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{ __html: "\n\t.st0{fill:#555555;}\n" }}
      />
      <path
        className="st0"
        d="M33,40.9H5.1c-1.3,0-2.4-1.1-2.4-2.4V11.6c0-1.3,1.1-2.4,2.4-2.4H10C9.9,9.3,9.8,9.6,9.8,9.8v8.5
	c0,0.8,0.7,1.5,1.5,1.5h2.4c0.8,0,1.5-0.7,1.5-1.5V9.8c0-0.2-0.1-0.5-0.2-0.7h18.6c-0.1,0.2-0.2,0.4-0.2,0.7v8.5
	c0,0.8,0.7,1.5,1.5,1.5h2.4c0.8,0,1.5-0.7,1.5-1.5V9.8c0-0.2-0.1-0.5-0.2-0.7h4.6c1.3,0,2.4,1.1,2.4,2.4v14.2c0,0,0,0,0.1,0
	c0.7,0,1.3,0.1,1.9,0.2V11.6c0-2.4-2-4.4-4.4-4.4h-6.8c0,0,0-0.1,0-0.1V2.4c0-1.2-0.9-2.1-2.1-2.1H14.1c-1.2,0-2.1,0.9-2.1,2.1v4.6
	c0,0,0,0.1,0,0.1H5.1c-2.4,0-4.4,2-4.4,4.4v26.9c0,2.4,2,4.4,4.4,4.4h28.3C33.2,42.3,33.1,41.6,33,40.9z M35.4,10.3h1.4v7.5h-1.4
	V10.3z M14,2.4C14,2.4,14,2.3,14,2.4l20.4-0.1c0,0,0.1,0,0.1,0.1v4.6c0,0,0,0.1-0.1,0.1H14.1c0,0-0.1,0-0.1-0.1V2.4z M11.8,10.3h1.4
	v7.5h-1.4V10.3z"
      />
      <g>
        <g>
          <path
            className="st0"
            d="M45.7,49.4c-5.8,0-10.6-4.8-10.6-10.6s4.8-10.6,10.6-10.6S56.4,33,56.4,38.8S51.6,49.4,45.7,49.4z M45.7,30
			c-4.9,0-8.8,4-8.8,8.8s4,8.8,8.8,8.8s8.9-4,8.9-8.8S50.7,30,45.7,30z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="st0"
            d="M51.8,39.7h-6.1c-0.5,0-0.9-0.3-0.9-0.9v-6.5c0-0.5,0.3-0.9,0.9-0.9s0.9,0.3,0.9,0.9v5.6h5.2
			c0.5,0,0.9,0.3,0.9,0.9C52.7,39.3,52.3,39.7,51.8,39.7z"
          />
        </g>
      </g>
    </svg>
  );
};

export default EmployeeStatusDate;
