import axios from "axios";
import * as common from "app/common";
const BASE_WEB_URL = common.WEB_API_URL;
const BASE_MOCK_URL = common.WEB_API_MOCK_URL;

const SearchPath = "/v1/subscriber/company/employee/search";
const fetchEmployeeDataPath = `/v1/subscriber/company/employee`;
const setEmployeeProfilePicturePic = `/`;

const makeGet = path => () => axios.get(BASE_MOCK_URL + path);
const makeWebApiGet = path => () => axios.get(BASE_WEB_URL + path);

export const getFilterOptions = (
  pathGetter,
  activityEntityId,
  params = {},
  baseUrl = BASE_MOCK_URL
) =>
  axios.get(BASE_MOCK_URL + pathGetter(activityEntityId), {
    params: {
      ...params
      //_mock_: true,
      //datasize: 100
    }
  });

export const searchPosts = params =>
  axios.post(BASE_MOCK_URL + SearchPath, JSON.stringify(params), {
    headers: { "Content-Type": "application/json" }
  });

const PRIMARY_MANAGER_PATH = "/v1/app/ui/user";
export const getPrimaryManager = makeWebApiGet(PRIMARY_MANAGER_PATH);

const SECONDARY_MANAGER_PATH = "/v1/app/ui/user";
export const getSecondaryManager = makeWebApiGet(SECONDARY_MANAGER_PATH);

const EMPLOYEE_CLASSIFICATION_PATH = "/v1/system/employeeclassification";
export const getEmployeeClassification = makeGet(EMPLOYEE_CLASSIFICATION_PATH);

const EMPLOYEE_TYPE = (companyid = 1) =>
  `/v1/subscriber/company/${companyid}/employee/type`;
export const getEmployeeType = companyid =>
  axios.get(BASE_MOCK_URL + EMPLOYEE_TYPE(companyid));

const EMPLOYEE_POSITION = companyid =>
  `/v1/subscriber/company/${companyid}/employee/position`;
export const getEmployeePosition = companyid =>
  axios.get(BASE_MOCK_URL + EMPLOYEE_POSITION(companyid));

const EMPLOYEE_PAYROLL_SCHEDULE = "/v1/subscriber/company/payrollschedule";
export const getEmployeePayrollSchedule = makeGet(EMPLOYEE_PAYROLL_SCHEDULE);

const EMPLOYEE_PAYROLL_FREQUENCY = "/v1/system/payrollfrequency";
export const getEmployeePayrollFrequency = makeWebApiGet(
  EMPLOYEE_PAYROLL_FREQUENCY
);

const EMPLOYEE_TIME_GROUP = "/v1/subscriber/company/timegroup";
export const getEmployeeTimeGroup = makeGet(EMPLOYEE_TIME_GROUP);

const EMPLOYEE_WORKPLACE_INFO = "/v1/system/workplaceinfo";
export const getWorkplaceInfo = makeGet(EMPLOYEE_WORKPLACE_INFO);

export const getDivisionList = (companyId = 1) =>
  axios.get(BASE_MOCK_URL + `/v1/app/ui/company/${companyId}/division`);

export const getBusinessUnitList = (companyId = 1, divisionId = 1) =>
  axios.get(
    BASE_MOCK_URL +
      `/v1/app/ui/company/${companyId}/division/${divisionId}/businessunit`
  );

export const getDepartmentList = (
  companyId = 1,
  divisionId = 1,
  businessUnitId = 1
) =>
  axios.get(
    BASE_MOCK_URL +
      `/v1/app/ui/company/${companyId}/division/${divisionId}/businessunit/${businessUnitId}/department`
  );

export const getLocationList = (companyId = 1) =>
  axios.get(BASE_MOCK_URL + `/v1/app/ui/company/${companyId}/location`);

export const getClientList = (companyId = 1) =>
  axios.get(BASE_MOCK_URL + `/v1/app/ui/company/${companyId}/client`);

export const getProjectList = (companyId, clientId) =>
  axios.get(
    BASE_MOCK_URL + `/v1/app/ui/company/${companyId}/client/${clientId}/project`
  );

const COMPANIES_PATH = "/v1/user/company?systemrole=";
export const getEmployeeCompany = (systemRoleId = 1) =>
  axios.get(BASE_WEB_URL + COMPANIES_PATH + systemRoleId);

const GENDER_PATH = "/v1/system/gender";
export const getEmployeeGender = makeGet(GENDER_PATH);

const COMPANY_LIST_PATH = "/v1/app/ui/company";
export const getCompanyList = makeWebApiGet(COMPANY_LIST_PATH);

const STATUS_LIST_PATH = "/v1/system/employeestatus";
export const getStatusList = makeGet(STATUS_LIST_PATH);

const EMPLOYEE_LIST_PATH = "/v1/app/ui/company/1/users";
export const getEmployeeList = makeGet(EMPLOYEE_LIST_PATH);

export const loadMorePosts = NextUri => axios.get(NextUri);

export const setLoggedUser = userId =>
  axios.post(BASE_MOCK_URL + "", JSON.stringify(userId), {
    headers: { "Content-Type": "application/json" }
  });

export const auditViewSsn = () => axios.post(BASE_WEB_URL + "");

export const fetchEmployeeDashboardData = id =>
  axios.get(BASE_MOCK_URL + fetchEmployeeDataPath + "/" + id + "?_mock_=admin");

export const saveAndSetEmployeeProfilePicture = data =>
  axios.get(BASE_MOCK_URL + setEmployeeProfilePicturePic, data, {
    headers: { "content-type": "multipart/form-data" }
  });

const MenuSearchPath = "/v1/app/ui/company/customfield/search?_mock_=admin";
export const menuSearchPosts = params =>
  axios.post(BASE_MOCK_URL + MenuSearchPath, JSON.stringify(params), {
    headers: { "Content-Type": "application/json" }
  });

window.people = {
  getFilterOptions,
  getPrimaryManager,
  getSecondaryManager,
  getEmployeeClassification,
  getEmployeeType,
  getEmployeePosition,
  getEmployeePayrollSchedule,
  getEmployeePayrollFrequency,
  getEmployeeTimeGroup,
  getWorkplaceInfo,
  searchPosts,
  fetchEmployeeDashboardData,
  getEmployeeCompany,
  getDivisionList,
  getBusinessUnitList,
  getCompanyList,
  getStatusList,
  getDepartmentList,
  getLocationList,
  getClientList,
  getProjectList
};
