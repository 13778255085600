import axios from "axios";
import * as common from "app/common";
//const BASE_WEB_URL = common.WEB_API_URL;
const BASE_MOCK_URL = common.WEB_API_MOCK_URL;

const makeGet = path => () => axios.get(BASE_MOCK_URL + path);

const GET_LEAVE_TYPES = "/v1/system/leavetype";
export const getLeaveTypes = makeGet(GET_LEAVE_TYPES);

const GET_FMLA_PATH = "/v1/system/fmlaleavereason";
export const getFmlaReasons = makeGet(GET_FMLA_PATH);

const GET_NON_FMLA_PATH = "/v1/system/nonfmlaleavereason";
export const getNonFmlaReasons = makeGet(GET_NON_FMLA_PATH);

const GET_TERMINATION_TYPES = "/v1/system/terminationtype";
export const getTerminationTypes = makeGet(GET_TERMINATION_TYPES);

const GET_TERMINATION_REASONS = "/v1/system/terminationreason";
export const getTerminationReasons = makeGet(GET_TERMINATION_REASONS);

export const ADD_TERMINATION_REASON = "/v1/system/terminationreason";

window.employeeStatus = {
  getLeaveTypes,
  getFmlaReasons,
  getNonFmlaReasons,
  getTerminationTypes,
  getTerminationReasons
};
