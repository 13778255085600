import React from "react";
import PropTypes from "prop-types";
import ActivityHistoryColumnTitle, {
  ORDER_ASC,
  ORDER_DESC
} from "../activity-history/ActivityHistoryColumnTitle";

const Columns = [
  {
    label: "CHANGED BY",
    field: "ChangedBy",
    className: "col-lg-9 col-md-9 user-column-header"
  },
  {
    label: "EFFECTIVE DATE",
    field: "EffectiveDate",
    className: "col-lg-3 col-md-3 timestamp-column-header"
  }
];

const JobHistoryTableHeader = ({
  sortOrder: { Field, Order } = {},
  changeSortOrder
}) => (
  <div style={{ paddingBottom: 10 }}>
    <div className="row table-header visible-lg visible-md">
      {Columns.map(({ label, field, className }) => (
        <ActivityHistoryColumnTitle
          key={field}
          label={label}
          className={className}
          order={field === Field && Order}
          onClick={() => {
            if (field !== Field) {
              changeSortOrder(field, ORDER_ASC);
            } else {
              changeSortOrder(
                field,
                Order === ORDER_ASC
                  ? ORDER_DESC
                  : Order === ORDER_DESC
                    ? ORDER_ASC
                    : ""
              );
            }
          }}
        />
      ))}
    </div>
  </div>
);

JobHistoryTableHeader.propTypes = {
  sortOrder: PropTypes.shape({
    Field: PropTypes.string,
    Order: PropTypes.oneOf([ORDER_ASC, ORDER_DESC])
  }),
  changeSortOrder: PropTypes.func
};

export default JobHistoryTableHeader;
