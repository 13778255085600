import React, { Component } from "react";
import { PropTypes } from "prop-types";
import classNames from "classnames/bind";
import * as FIELDS from "../../utils/Constants";
import NumberField from "component-library/inputFields/NumberField";

class CurrentGPAScale extends Component {
  state = { showTooltip: false };
  showTooltipHandler = () => {
    this.setState({
      showTooltip: !this.state.showTooltip
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        showTooltip: false
      });
    }
  }

  render() {
    const { props } = this;
    let lgClass = "col-lg-2";
    let mdClass = "col-md-2";
    let smClass = "col-sm-2";
    let xsClass = "col-xs-12";
    if (props.Size) {
      if (props.Size.length > 0) {
        lgClass = props.Size[0] && "col-lg-" + props.Size[0];
        mdClass = props.Size[1] && "col-md-" + props.Size[1];
        smClass = props.Size[2] && "col-sm-" + props.Size[2];
        xsClass = props.Size[3] && "col-xs-" + props.Size[3];
      }
    }

    let wrapperClass;
    if (props.class === undefined) {
      wrapperClass = "nc-field-group clearfix";
    } else {
      wrapperClass = "nc-field-group " + props.class + " clearfix";
    }
    if (props.required && props.errors[FIELDS.CURRENT_GPA]) {
      wrapperClass = wrapperClass + " has-error current-gpa-scale";
    }
    const InputFieldClass = classNames(
      props.className,
      lgClass,
      mdClass,
      smClass,
      xsClass
    );
    return (
      <div className={wrapperClass}>
        <div style={{ clear: "both", display: "flex", alignItems: "center" }}>
          <div
            id="label-current-gpa-scale"
            style={{ paddingRight: "15px" }}
            className={"col-lg-3 col-md-3 col-sm-3 field-name clearfix"}
          >
            Current GPA / Scale <i className="optional-field">(Optional) </i>
          </div>
          <div
            className={"col-lg-9 col-md-9 col-sm-9"}
            style={{ marginBottom: "20px" }}
          >
            <div
              style={{ clear: "both", display: "flex", alignItems: "center" }}
            >
              <NumberField
                {...props}
                onChange={props.onChange}
                inputName={FIELDS.CURRENT_GPA}
                className={InputFieldClass}
                value={props.data[FIELDS.CURRENT_GPA]}
                format="#######"
              />
              <div style={{ padding: "0px 15px", color: "#555" }}>/</div>
              <NumberField
                {...props}
                onChange={props.onChange}
                inputName={FIELDS.CURRENT_SCALE}
                className={InputFieldClass}
                value={props.data[FIELDS.CURRENT_SCALE]}
                format="#######"
              />
            </div>
            {props.errors[FIELDS.CURRENT_GPA] &&
              props.required && (
                <div
                  style={{ marginTop: "5px", fontSize: "15px" }}
                  className="has-error"
                >
                  {props.errors[FIELDS.CURRENT_GPA]}
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default CurrentGPAScale;
CurrentGPAScale.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string
};

CurrentGPAScale.defaultProps = {
  descriptionStyle: "",
  hideTopdescription: false,
  colSpan: ""
};
