import React from "react";
const W2Image = props => (
  <img
    src={require("./th-W2.png")}
    width={props.width}
    alt="W2-summary"
    style={{ boxShadow: props.shadow ? "10px 10px #a4d6c4" : "none" }}
  />
);

export default W2Image;
