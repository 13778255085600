import React from "react";
import SingleCheckBox from "./../../checkBoxGroup/SingleCheckBox";

const JobTaskExceptionItem = ({
  item,
  onChange,
  value,
  viewFullJobHistory
}) => (
  <div className="exception-items">
    {!viewFullJobHistory && (
      <SingleCheckBox
        inputName={"ExceptionItem"}
        value={value}
        onChange={onChange}
        visibility={item.IsJobTaskResubmitted}
      />
    )}

    <div className="exception-item-content">
      <div className="content-issue">
        <div>
          <i className={`fa fa-exclamation-triangle yellow-icon`} />
        </div>
        <div>{item.JobTaskDescription}</div>
      </div>
      {item.jobTaskStatusNotificationErrorList.map((row, i) => {
        return (
          <div key={i} className="content-expection">
            <div className="content-expection-bullet text-center">
              <i className={`fa fa-circle`} />
            </div>
            <div>{row.ErrorDescription}</div>
          </div>
        );
      })}
    </div>
  </div>
);

export default JobTaskExceptionItem;
