import React from "react";
import PropTypes from "prop-types";

const FooterButtons = ({
  buttonLabel,
  onPrimaryClick,
  onCancelClick,
  footNote
}) => {
  return (
    <div className="footer-button-container" style={{ clear: "both" }}>
      <div style={{ marginBottom: "15px" }}>
        <div className="seperator">
          <hr />
        </div>
        {footNote && (
          <span style={{ fontWeight: 600 }}>
            <i
              style={{ color: "rgb(249, 108, 95)", fontSize: 15 }}
              className="fa fa-exclamation-triangle"
            />{" "}
            <i>{footNote}</i>
          </span>
        )}
      </div>
      <div className="nc-button-group">
        <button
          id="create-button"
          className="nc-button primary-button"
          onClick={onPrimaryClick}
        >
          {buttonLabel}
        </button>
        <div className={"nc-link-group-mobile"}>
          <button
            id="cancel-button"
            className="secondary-link-button"
            onClick={onCancelClick}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default FooterButtons;
FooterButtons.propTypes = {
  /**This will perform the action of primary click */
  onPrimaryClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  buttonLabel: PropTypes.string
};
FooterButtons.defaultProps = {
  onPrimaryClick: () => alert("on Primary Button Click"),
  onCancelClick: () => alert("onCancel Click"),
  buttonLabel: "Rehire Employee"
};
