import Loadable from "react-loadable";
import ChunkLoading from "app/common/UIComponents/ChunkLoading";

const EmailTextAlertsPage = Loadable({
  loader: () =>
    import("./emailTextAlertsPage" /* webpackChunkName: "email-text-alerts" */),
  loading: ChunkLoading
});

const EmailTextAlertsEditPage = Loadable({
  loader: () =>
    import("./emailTextAlertsEditPage" /* webpackChunkName: "email-text-alerts-edit" */),
  loading: ChunkLoading
});

export { EmailTextAlertsPage, EmailTextAlertsEditPage };
