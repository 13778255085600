import React, { Component } from "react";
import {
  FormSection,
  FormFieldGroup
} from "component-library/formSections/index";
import * as FIELDS from "../utils/Constants";

export default class IssuanceInfo extends Component {
  render() {
    const {
      stateList = [],
      countryList = [],
      data = {},
      onChange,
      getFromToValue
    } = this.props;
    return (
      <div className="tab-container-add-sub-section">
        <div className="section-items">
          <FormSection title="Issuance Info">
            <FormFieldGroup
              inputType="text"
              inputName={FIELDS.ISSUANCE_INFO_ISSUE_BY}
              value={data[FIELDS.ISSUANCE_INFO_ISSUE_BY]}
              label="Issued By"
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              optional={true}
            />

            <FormFieldGroup
              inputType="singleDatePicker"
              inputName={FIELDS.ISSUANCE_INFO_ISSUED_DATE}
              value={data[FIELDS.ISSUANCE_INFO_ISSUED_DATE]}
              label="Issued Date"
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              optional={true}
              getFromToValue={getFromToValue}
            />

            <FormFieldGroup
              inputType="dropdown"
              options={countryList}
              inputName={FIELDS.ISSUANCE_INFO_COUNTRY_ISSUED_IN}
              value={data[FIELDS.ISSUANCE_INFO_COUNTRY_ISSUED_IN]}
              label="Country Issued In"
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              optional={true}
            />

            <FormFieldGroup
              inputType="dropdown"
              options={stateList}
              inputName={FIELDS.ISSUANCE_INFO_STATE_ISSUED_IN}
              value={data[FIELDS.ISSUANCE_INFO_STATE_ISSUED_IN]}
              label="State"
              onChange={onChange}
              Size={[2, 2, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              optional={true}
            />

            <FormFieldGroup
              inputType="text"
              inputName={FIELDS.ISSUANCE_INFO_CITY_ISSUED_IN}
              value={data[FIELDS.ISSUANCE_INFO_CITY_ISSUED_IN]}
              label="City Issued In"
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              optional={true}
            />
          </FormSection>
        </div>
      </div>
    );
  }
}
