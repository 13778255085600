import React, { Component } from "react";
import MinimumWagesAlertsContainer from "../containers/MinimumWagesAlertsContainer";
import { withRouter } from "react-router";

class MinimumWageAlertsPage extends Component {
  render() {
    return (
      <div className="transition-item detail-page clearfix">
        <MinimumWagesAlertsContainer
          redirectToChecklist={() => {
            this.props.history.replace("/payroll");
            this.props.clearPayrollActiveTab();
          }}
        />
      </div>
    );
  }
}
export default withRouter(MinimumWageAlertsPage);
