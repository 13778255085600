const mapPayroll = (item, entityName) => ({
  id: item.CompanyPayrollScheduleDefinitionId,
  name: item.CompanyPayrollScheduleDefinitionName,
  frequency:
    item.PayrollFrequencyId === 1
      ? "Weekly"
      : item.PayrollFrequencyId === 2
        ? "Bi-Weekly"
        : item.PayrollFrequencyId === 3
          ? "Semi-Monthly"
          : item.PayrollFrequencyId === 4
            ? "Monthly"
            : item.PayrollFrequencyId === 5
              ? "Quarterly"
              : item.PayrollFrequencyId === 6
                ? "Yearly"
                : "Daily",
  frequencyId: item.PayrollFrequencyId,
  statusId: item.IsActive,
  status: item.IsActive ? "Active" : "Inactive",
  isPayrollScheduleStarted: false,
  ActivityInstanceId: item.ActivityInstanceId
});

export const getPayrollTableData = state => {
  const allData =
    state.Data && state.Data.length > 0 ? state.Data.map(mapPayroll) : [];

  return allData;
};
