/** Actions */
const ADD_TO_EMPLOYEE_LIST = "people-employee-list/ADD_TO_EMPLOYEE_LIST";
const CLEAR_EMPLOYEE_LIST = "people-employee-list/CLEAR_EMPLOYEE_LIST";
const SET_EMPLOYEE_LIST_LOADING =
  "people-employee-list/SET_EMPLOYEE_LIST_LOADING";

export const initialState = {
  loading: true,
  Data: [],
  DataCount: 0,
  NextUri: null
};

const filterNewData = (state, Data) => {
  const ids = state.Data.map(a => a.EmployeeId);
  return Data.filter(d => ids.indexOf(d.EmployeeId) === -1);
};

/** Reducer */
const employeeList = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_EMPLOYEE_LIST:
      return initialState;
    case ADD_TO_EMPLOYEE_LIST: {
      const { Data, DataCount, NextUri } = payload;
      const newData = [...state.Data, ...filterNewData(state, Data)];
      return {
        ...state,
        NextUri,
        Data: newData,
        DataCount: DataCount,
        loading: false
      };
    }
    case SET_EMPLOYEE_LIST_LOADING:
      return {
        ...state,
        loading: payload
      };
    default:
      return state;
  }
};

export default employeeList;

/** Action creators */
export const addToEmployeeList = (Data, DataCount, NextUri) => ({
  type: ADD_TO_EMPLOYEE_LIST,
  payload: { Data, DataCount, NextUri }
});

export const clearEmployeeList = () => ({
  type: CLEAR_EMPLOYEE_LIST
});

export const setEmployeeListLoading = loading => ({
  type: SET_EMPLOYEE_LIST_LOADING,
  payload: loading
});
