import Loadable from "react-loadable";
import ChunkLoading from "app/common/UIComponents/ChunkLoading";

const CopyEntityPage = Loadable({
  loader: () =>
    import("./CopyEntityPage" /* webpackChunkName: "copy-entity" */),
  loading: ChunkLoading
});

export { CopyEntityPage };
