import React, { Component } from "react";
import { connect } from "react-redux";
import { ListPageToaster } from "component-library/earnings-list";
import { clearToast } from "app/common/reducers/toast";
import { setEntityMapItLinkSideMenuItem } from "./../../app/actions/sideBar";
import { getMenuItemByName } from "app/common/menus/utils";
import { getMenuIdByName } from "app/common/menus/utils";

class ToasterContainer extends Component {
  /**
   * Redirects the user to Map GL Earnings Page
   */
  onMapGLLinkClick = () => {
    const { mapGlEarningsMenuItem = {}, history = {} } = this.props;
    this.props.setEntityMapItLinkSideMenuItem(mapGlEarningsMenuItem);
    history.push("/map-general-ledger-accounts-earnings")
  };
  render() {
    const { toast, clearToast } = this.props;
    return (
      <ListPageToaster
        {...toast}
        clearToast={clearToast}
        onLinkClick={this.onMapGLLinkClick}
      />
    );
  }
}

const stateToProps = state => {
  return {
    toast: state.common.toast,
    mapGlEarningsMenuItem: getMapGlEarningsMenuItem(state)
  };
};

const actionCreators = {
  clearToast,
  setEntityMapItLinkSideMenuItem
};

export default connect(
  stateToProps,
  actionCreators
)(ToasterContainer);

const getMapGlEarningsMenuItem = state => {
  const Item = getMenuItemByName("Map Earnings");
  //? Maintenance (361) -> Payroll (364) -> Manage Expense and Payable Accounts (520) -> Map Earnings (515)
  const SelectedProductId = getMenuIdByName("Maintenance"); // Maintenance -> Expected Id 361
  const SelectedMenuLevel1Id = getMenuIdByName("Payroll"); // Payroll -> Expected Id 364
  const SelectedMenuLevel2Id = getMenuIdByName(
    "Manage Expense and Payable Accounts"
  ); // Manage Expense and Payable Accounts -> Expected Id 520
  const SelectedMenuLevel3Id = Item.Id; // Map Earnings -> Expected Id 515

  return {
    navigationItems: Item,
    menuState: [
      {
        SelectedProductId,
        SelectedMenuLevel1Id,
        SelectedMenuLevel2Id,
        SelectedMenuLevel3Id
      },
      {
        SelectedProductId,
        SelectedMenuLevel1Id,
        SelectedMenuLevel2Id,
        SelectedMenuLevel3Id
      }
    ]
  };
};
