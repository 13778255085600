/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import RadioButton from "./BasicRadioButton";
import classNames from "classnames/bind";
const HourlyRate = props => {
  const { radios = [] } = props;
  if (radios.length > 0) {
    return (
      <div className="button-type-container">
        {radios.map(item => (
          <RadioButton
            {...item}
            id={item.id}
            key={item.id}
            onChange={props.onChange}
            name={props.name}
            width={props.width}
            type={props.type}
            checked={item.value === props.value}
            disabled={props.disabled}
          />
        ))}
        <div
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
          style={{ padding: "10px 0px 20px 0px" }}
        >
          <a
            id="addNewHourlyRate"
            className={classNames(
              (props.disabled || props.disabledLink) && "disabled-link"
            )}
            onClick={
              props.disabled || props.disabledLink ? () => {} : props.rateModal
            }
          >
            <i className="fa fa-plus" />
            Add a New Hourly Rate
          </a>
        </div>
        {props.error && props.value == null && props.required && (
          <div style={{ marginTop: "5px" }} className="has-error">
            {props.error}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="button-type-container">
        <div className="well col-lg-6" style={{ paddingLeft: "15px" }}>
          {" "}
          <i>You have not created any hourly rate yet</i>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <a onClick={props.rateModal}>
            <i className="fa fa-plus" />
            Name a new hourly rate
          </a>
        </div>
      </div>
    );
  }
};
export default HourlyRate;
