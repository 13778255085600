import React, { Component } from "react";
import { FormFieldGroup } from "../../formSections/index";
import { BiWeeklyPayPeriod, billingTypeValidationMessage } from "./Constants";

class WeeklyPayPeriod extends Component {
  frequencyRadioData = val =>
    val.map((f, i) => ({ id: f.Id, value: f.Id, label: f.Name }));

  render() {
    const { data, options, handleRadioChange } = this.props;

    const weekdays = options && options.weekdays ? options.weekdays : [];
    const componentProps = BiWeeklyPayPeriod;
    componentProps.week.radios =
      weekdays.length > 0 ? this.frequencyRadioData(weekdays) : [];
    if (data.billingTypeValidation) {
      componentProps.submitPayroll.error = billingTypeValidationMessage;
    } else if (!data.billingTypeValidation) {
      componentProps.submitPayroll.error = "";
    }
    let renderDescription = Object.keys(componentProps).map((key, i) => {
      return (
        <div key={key}>
          <FormFieldGroup
            {...componentProps[key]}
            value={parseInt(data[componentProps[key].inputName], 10)}
            onChange={handleRadioChange}
          />
        </div>
      );
    });
    return <div>{renderDescription}</div>;
  }
}
export default WeeklyPayPeriod;
