import React from "react";

const MardiGrass = ({ width, height, style }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 85 85"
      style={{ enableBackground: "new 0 0 85 85", ...style }}
      xmlSpace="preserve"
      height={height}
      width={width}
    >
      <style type="text/css" />
      <g>
        <circle className="st10" cx="42.5" cy="42.5" r="42.5" />
      </g>
      <g>
        <g>
          <path
            className="st1"
            d="M29.5,51.8c-2.9,0-6.9-1.4-9.8-8.2c-0.1-0.3-0.1-0.7,0-1c0.1-0.3,0.4-0.6,0.7-0.7c0.1,0,2-0.8,4.7-0.8
			c3.3,0,7.9,1.2,11.3,6.7c0.3,0.5,0.2,1.2-0.3,1.6C36,49.6,33.1,51.8,29.5,51.8z M22.7,43.9c1.8,3.6,4.1,5.4,6.9,5.4
			c1.6,0,3.1-0.6,4-1.2c-2.1-3-5-4.5-8.4-4.5C24.2,43.7,23.3,43.8,22.7,43.9z"
          />
        </g>
        <g>
          <path
            className="st1"
            d="M25.9,60.8c-8.4,0-14-8.7-17.1-26.5c-0.1-0.7,0.3-1.3,0.9-1.4c0.2,0,4.8-1.2,10.9-1.2
			c6.6,0,15.6,1.4,21.8,7.7c6.2-6.4,15.2-7.7,21.8-7.7c6.1,0,10.7,1.1,10.9,1.2c0.6,0.2,1.1,0.8,0.9,1.4
			c-3.1,17.8-8.7,26.5-17.1,26.5c0,0,0,0,0,0c-4,0-7.7-1.9-11.1-3.5c-2.2-1.1-4.2-2.1-5.5-2.1c-1.3,0-3.3,1-5.5,2.1
			C33.6,58.9,29.9,60.8,25.9,60.8z M42.5,52.6c1.9,0,4.1,1.1,6.6,2.4c3.1,1.5,6.6,3.3,10,3.3c0,0,0,0,0,0c6.8,0,11.5-7.6,14.3-23.2
			c-1.7-0.3-5.1-0.9-9.2-0.9c-6.4,0-15.2,1.4-20.8,7.9c-0.5,0.6-1.4,0.6-1.9,0c-5.6-6.5-14.4-7.9-20.8-7.9c-4.1,0-7.5,0.5-9.2,0.9
			c2.9,15.6,7.6,23.2,14.3,23.2c3.4,0,6.9-1.7,10-3.3C38.4,53.7,40.6,52.6,42.5,52.6z"
          />
        </g>
        <g>
          <path
            className="st1"
            d="M55.5,51.8L55.5,51.8c-3.6,0-6.4-2.2-6.6-2.3c-0.5-0.4-0.6-1.1-0.3-1.6c3.3-5.6,8-6.7,11.3-6.7
			c2.7,0,4.6,0.8,4.7,0.8c0.3,0.1,0.6,0.4,0.7,0.7c0.1,0.3,0.1,0.7,0,1C62.4,50.4,58.3,51.8,55.5,51.8z M51.5,48.1
			c0.9,0.5,2.4,1.2,4,1.2c2.7,0,5-1.8,6.9-5.4c-0.6-0.1-1.5-0.3-2.5-0.3C56.4,43.7,53.6,45.2,51.5,48.1z"
          />
        </g>
      </g>
    </svg>
  );
};

export default MardiGrass;
