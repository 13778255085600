import React, { Component } from "react";
import BasicRadioButton from "../../radioButton/BasicRadioButton";
import {
  directDepositRadioBtn,
  FEDRAL_BANKING_HOLIDAY,
  WEEKEND,
  BANKING_HOLIDAY,
  ADJUSTED
} from "./Constants";
import { FormSection } from "../../formSections";
class DirectDeposit extends Component {
  renderWeeklyRadioBtn = (name, width, type = "basic") => {
    const { options, data, handleRadioChange } = this.props;
    if (name === WEEKEND) {
      directDepositRadioBtn[name] =
        (options && options.directDepositOptions) || [];
    }
    const mapData = directDepositRadioBtn[name];
    const buttons = mapData.map((opt, i) => {
      return (
        // <div className="single-radio-button" >
        <BasicRadioButton
          key={opt.Name + i}
          label={opt.Name}
          checked={parseInt(data[name], 10) === opt.Id}
          name={name}
          onChange={handleRadioChange}
          value={opt.Id}
          width={width}
          type={type}
        />
        // </div>
      );
    });
    return buttons;
  };

  render() {
    const { monthly } = this.props;
    return (
      <div>
        <FormSection title="Direct Deposit Payments">
          {monthly && (
            <div
              className="nc-field-group clearfix"
              style={{ marginBottom: "10px" }}
            >
              <div className="field-name clearfix">
                If your paycheck date falls on a{" "}
                <span style={{ fontWeight: "600" }}> weekend</span>, when you do
                want your employees to receive their direct deposit payments?
                <i
                  style={{ marginLeft: "5px" }}
                  className="fa fa-info-circle"
                />
              </div>
              <div className="button-type-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {this.renderWeeklyRadioBtn(
                  WEEKEND,
                  "240px",
                  "three-line-title"
                )}
              </div>
            </div>
          )}
          <div
            className="nc-field-group clearfix"
            style={{ marginBottom: "10px", width: "62%" }}
          >
            <div className="field-name clearfix">
              If your paycheck date falls on a{" "}
              <span style={{ fontWeight: "600" }}>
                {FEDRAL_BANKING_HOLIDAY}
              </span>, when you do want your employees to receive their direct
              deposit payments?
              <i style={{ marginLeft: "5px" }} className="fa fa-info-circle" />
            </div>
            <div className="button-type-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
              {this.renderWeeklyRadioBtn(
                BANKING_HOLIDAY,
                "200px",
                "one-line-title"
              )}
            </div>
          </div>
          <div
            className="nc-field-group clearfix"
            style={{ marginBottom: "10px", width: "62%" }}
          >
            <div className="field-name clearfix">
              If your paycheck date falls on a{" "}
              <span style={{ fontWeight: "600" }}>
                {FEDRAL_BANKING_HOLIDAY} {monthly && "or weekend"}
              </span>, do you want your paycheck date to be adjusted to match
              when your employees receive their direct deposit payments?
              <i style={{ marginLeft: "5px" }} className="fa fa-info-circle" />
            </div>
            <div className="button-type-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
              {this.renderWeeklyRadioBtn(ADJUSTED, "")}
            </div>
          </div>
        </FormSection>
      </div>
    );
  }
}
export default DirectDeposit;
