import React from "react";
const NetchexButton = ({
  id,
  style = {},
  faIcon,
  label,
  name,
  onClick,
  value,
  disabled,
  type
}) => {
  var className = " ";
  if (type === "primary") {
    className = "primary-button";
  }
  if (type === "secondary") {
    className = "secondary-button";
  }
  if (type === "outline") {
    className = "outline-button";
  }
  if (type === "link") {
    className = "primary-link-button";
  }
  if (type === "secondary-link") {
    className = "secondary-link-button";
  }
  return (
    <button
      style={style}
      id={id}
      className={`nc-button ` + className}
      type="button"
      onClick={onClick}
    >
      {faIcon !== "" ? <i className={`fa ` + faIcon} /> : null}
      {label}
    </button>
  );
};
export default NetchexButton;
