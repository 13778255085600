export const PAYROLL_NOT_STARTED = 1;
export const PAYROLL_IN_PROGRESS = 2;
export const PAYROLL_SUBMITTED = 3;
export const PAYROLL_PENDING = 4;

export const SCHEDULE_PAYROLL = 1;
export const UNSCHEDULE_PAYROLL = 2;

export const UNAPPROVED_PTO = "UNAPPROVED_PTO";
export const TIMECARD_ALERTS = "TIMECARD_ALERTS";
export const EMPLOYEE_UPDATES = "EMPLOYEE_UPDATES";

export const ASSIGN_MANAGER = "ASSIGN_MANAGER";
export const ADD_CONTACT_INFO = "ADD_CONTACT_INFO";

export const PAYROLL_CHECKLIST_DESCRIPTION =
  "Make payroll faster with less errors by reviewing and addressing all alerts before starting your payroll.";
