import * as types from "../actions/index";
const initialState = { show: false, message: "" };
export default function(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_TOASTER:
      return action.payload;

    // no default
  }
  return state;
}
