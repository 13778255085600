import React from "react";
import PayrollDashboardSummaryMarker from "./PayrollDashboardSummaryMarker";
import PayrollDashboardSummaryHeaderMessage from "./PayrollDashboardSummaryHeaderMessage";
import PropTypes from "prop-types";
const PayrollDashboardSummaryHeader = ({ data }) => {
  return (
    <div className="payroll-dash-summary-header">
      <div className="payroll-dash-summary-header-content clearfix">
        <div className="payroll-dash-summary-header-cell">
          <PayrollDashboardSummaryMarker data={data} />
        </div>
        <div className="payroll-dash-summary-header-cell">
          <div className="payroll-dash-summary-header-title">
            {data.PayrollScheduleName}
          </div>
        </div>
      </div>
      <PayrollDashboardSummaryHeaderMessage data={data} />
    </div>
  );
};

export default PayrollDashboardSummaryHeader;
PayrollDashboardSummaryHeader.propTypes = {
  data: PropTypes.object
};
PayrollDashboardSummaryHeader.defaultProps = {
  data: {}
};
