export const EDIT_DEMOGRAPHIC_DATA = "EDIT_DEMOGRAPHIC_DATA";
export const CLEAR_DEMOGRAPHIC_DATA = "CLEAR_DEMOGRAPHIC_DATA";

const demographicData = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case EDIT_DEMOGRAPHIC_DATA:
      const { name, values } = payload;
      return {
        ...state,
        [name]: values
      };
    case CLEAR_DEMOGRAPHIC_DATA:
      return {};
    default:
      return state;
  }
};

export default demographicData;

export const editDemographicValues = (name, values) => ({
  type: EDIT_DEMOGRAPHIC_DATA,
  payload: { name, values }
});

export const clearDemographicValues = () => ({
  type: CLEAR_DEMOGRAPHIC_DATA
});
