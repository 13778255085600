import React from "react";
import getInputComponent from "../../../../inputFields/getInputComponent";

const DropDown = getInputComponent("dropdown");
const TerminationReason = props => {
  return (
    <div
      className="nc-field-group clearfix"
      style={{ clear: "both", display: "flex", alignItems: "center" }}
    >
      <div
        id={"Label" + props.inputName}
        style={{ paddingRight: "15px" }}
        className={`${
          props.colSpan
        } " " ${"col-lg-3 col-md-3 field-name clearfix"}`}
      >
        {props.label}{" "}
      </div>
      <div
        className="col-lg-9 col-md-9"
        style={{ display: "flex", alignItems: "center" }}
      >
        <DropDown
          options={props.options}
          optional
          name={props.inputName}
          value={props.value}
          onChange={props.onChange}
          className="input-field col-lg-6 col-md-4 col-sm-12 col-xs-12"
        />
        <div style={{ marginLeft: "10px", marginBottom: "15px" }}>
          <a onClick={props.onAddLinkClick}>
            <i className="fa fa-plus" /> Add New Termination Reason
          </a>
        </div>
      </div>
    </div>
  );
};

export default TerminationReason;
