import React, { Component } from "react";
//import { Switch, Route } from "react-router-dom";
import { TabContainer } from "component-library/employee-profile/entity-view";
import EmployeeListHeader from "component-library/employee-profile/list-view/EmployeeListHeader";
import EmployeeNotesTable from "component-library/employee-profile/employee-note/NotesTable";
import {
  OTHER_HEADER
  //NotesSampleList
} from "component-library/employee-profile/Constants";

class EmployeeNotes extends Component {
  state = {
    activePanel: {},
    showToaster: false,
    toasterClass: "",
    toasterMessage: "",
    isAddModeOn: false
  };

  componentDidMount = () => {
    const { EmployeeId } = this.props.match.params;
    this.props.fetchEmployeeData(EmployeeId);
  };

  hideToaster = () => {
    this.setState({
      showToaster: false
    });
  };

  setToaster = (toasterMessage, toasterClass) => {
    this.setState({
      toasterClass: toasterClass,
      toasterMessage: toasterMessage,
      showToaster: true
    });
  };
  render() {
    const { employeeNotes } = this.props.data;
    const { EmployeeId } = this.props.match.params;
    return (
      <TabContainer
        activePanelHeader={OTHER_HEADER[1]}
        isAddModeOn={this.state.isAddModeOn}
        showToaster={this.state.showToaster}
        toasterClass={this.state.toasterClass}
        toasterMessage={this.state.toasterMessage}
        toggleToaster={this.hideToaster}
      >
        <EmployeeListHeader
          onAddButtonClick={() =>
            this.props.history.push(
              `/people/employees/dashboard/${EmployeeId}/addnotes/30`
            )
          }
        />
        <EmployeeNotesTable data={employeeNotes.notes || []} />
      </TabContainer>
    );
  }
}

export default EmployeeNotes;
