import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./GeneralLedger.css";
import { withRouter } from "react-router-dom";
import { Badge } from "react-bootstrap";
import Toggle from "react-toggle";

import "../../../content/listpage.css";

import "../../../content/entitypage.css";

import "../../../content/style.css";

import EmployerExpenseLiabilityAccountsAdvanceFilter from "./EmployerExpenseLiabilityAccountsAdvanceFilter";
import {
  getEmployerExpenceLiabilityAccountsList,
  getEmployerExpenceAccountsList,
  getEmployerLiabilityAccountsList,
  updatedFilteredList,
  saveEmployerExpenceLiabilityAccountsList,
  setEmployerExpencesData,
  getEmployerExpenceLiabilityAccountsFilteredList
} from "../actions/generalledger/EmployerExpenceLiabilityAccountsActions";
import DropDown from "component-library/dropDown/DropDown";
import {
  setGlobalNavClick,
  CallbackToQuickLink,
  setFormUpdate,
  isOverlayClickable
} from "./../../../app/actions/FormUpdateAction";
import { setSelectedFilters } from "../actions/generalledger/EmployerExpenceLiabilityAccountsActions";

function sortByKey(array, key) {
  return array.sort(function(a, b) {
    var x = a[key].toLowerCase();
    var y = b[key].toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

let isInDidUpdate = false;

class EmployerExpenseLiabilityAccounts extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showEmployerExpenseBackCancelModal: false,
      IsFormUpdated: false,
      isTableSorted: undefined,
      sortedKey: "",
      accountsTableList: [],
      selectedDeductions: [],
      selectedGLCategories: [],
      key: 1,

      showDropdownSection: false,
      showUnMappedBadgeForExpenceDeduction: false,
      showUnmappedBadgeForLiabilityAccount: false,

      showUnMappedBadgeForDeduction2: false,
      showUnmappedBadgeForLiabilityAccount2: false,
      showDropDownsSection2: false,

      isUnmapped: false,
      unmappedCount: 0,
      unmappedList: [],
      showCancelModal: false,
      unmappedCheckState: false
    };

    this.saveEmplyerExpenceLiabilityAccounts = this.saveEmplyerExpenceLiabilityAccounts.bind(
      this
    );

    this.showSortedIcon = this.showSortedIcon.bind(this);
    this.renderEmployeeLiabilityTable = this.renderEmployeeLiabilityTable.bind(
      this
    );
    this.handleSelect = this.handleSelect.bind(this);
    this.toggleIncludeGeneralLedger = this.toggleIncludeGeneralLedger.bind(
      this
    );
    this.redirectToEmployeeLiabilityAccounts = this.redirectToEmployeeLiabilityAccounts.bind(
      this
    );

    this.gotoNextTab = this.gotoNextTab.bind(this);

    this.renderButtons = this.renderButtons.bind(this);
    this.onModalCancelChangesMethod = this.onModalCancelChangesMethod.bind(
      this
    );
    this.setUnmappedState = this.setUnmappedState.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.employerExpenceLiabilityAccountsList !==
      this.props.employerExpenceLiabilityAccountsList
    ) {
      isInDidUpdate = true;

      this.props.updatedFilteredList(
        this.props.employerExpenceLiabilityAccountsList
      );

      let unmappedList = [];

      if (this.props.employerExpenceLiabilityAccountsList) {
        this.props.employerExpenceLiabilityAccountsList.Data.forEach(item => {
          if (item.IsIncludeInGlReport === true) {
            if (
              item.EmployerContributionExpenseGlAccountId === null ||
              item.EmployerContributionLiabilityGlAccountId === null
            ) {
              this.setState({
                IsFormUpdated: false
              });
              this.props.setFormUpdate(false);
              unmappedList.push(item.CompanyDeductionId);
            }
          }
        });

        this.setState({
          unmappedList,
          unmappedCount: unmappedList.length
        });
      }
      isInDidUpdate = false;
    }

    if (prevState.unmappedCount !== this.state.unmappedCount) {
      isInDidUpdate = true;
      this.props.unamappedCount(this.state.unmappedCount);
      isInDidUpdate = false;
    }

    if (prevProps.formUpdatedData !== this.props.formUpdatedData) {
      if (this.props.formUpdatedData) {
        this.setState({
          IsFormUpdated: true
        });
      } else {
        this.setState({
          IsFormUpdated: false
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.setFormUpdate(false);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isEmployerFormUpdated === this.props.isEmployerFormUpdated) {
      this.setState({
        IsFormUpdated: nextProps.isEmployerFormUpdated
      });
    }
  }

  getFilteredList() {
    if (this.props.employerExpenceLiabilityAccountsList.DataCount > 0) {
      this.props.getEmployerExpenceLiabilityAccountsFilteredList(
        this.props.employerExpenceLiabilityAccountsList.Data,
        this.state.selectedDeductions,
        this.state.selectedGLCategories,
        this.state.unmappedCheckState
      );
    }
  }

  toggleIncludeGeneralLedger(item) {
    var toggleValue = false;
    this.props.setEmployerFormUpdated(true);
    if (!item.IsIncludeInGlReport) {
      toggleValue = true;
    }

    item.IsIncludeInGlReport = toggleValue;

    let update = {};
    update[item.CompanyDeductionName] = toggleValue;
    this.setState(update);

    let unmappedList = this.state.unmappedList.slice();
    if (item.IsIncludeInGlReport === false) {
      this.setState({
        IsFormUpdated: true
      });
      this.props.setFormUpdate(true);
      item.EmployerContributionExpenseGlAccountId = null;
      item.EmployerContributionLiabilityGlAccountId = null;
      unmappedList = unmappedList.filter(function(removeItem) {
        return removeItem !== item.CompanyDeductionId;
      });
    } else {
      if (isInDidUpdate === false) {
        this.setState({
          IsFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
      if (
        item.IsIncludeInGlReport &&
        (item.EmployerContributionExpenseGlAccountId === null ||
          item.EmployerContributionLiabilityGlAccountId === null)
      ) {
        unmappedList.push(item.CompanyDeductionId);
      }
    }
    this.setState({
      unmappedList: unmappedList,
      unmappedCount: unmappedList.length
    });

    //Bug 32372, 32642
    if (
      this.state.selectedGLCategories.includes(true) &&
      item.IsIncludeInGlReport === false
    ) {
      this.getFilteredList();
    } else if (
      this.state.selectedGLCategories.includes(false) &&
      item.IsIncludeInGlReport === true
    ) {
      this.getFilteredList();
    }
  }

  redirectToEmployeeLiabilityAccounts() {
    this.props.history.push("/employee-liability-accounts");
  }

  sortColumnByKey(key) {
    if (this.props.employerExpenceLiabilityAccountsFilteredList.Data) {
      this.props.employerExpenceLiabilityAccountsFilteredList.Data = sortByKey(
        this.props.employerExpenceLiabilityAccountsFilteredList.Data,
        key
      );
      this.setState({
        sortedKey: key
      });
      if (this.state.isTableSorted) {
        this.setState({
          isTableSorted: false
        });
        this.props.employerExpenceLiabilityAccountsFilteredList.Data = this.props.employerExpenceLiabilityAccountsFilteredList.Data.reverse();
      }
      if (this.state.isTableSorted === undefined) {
        this.setState({
          isTableSorted: true
        });
      }
      if (this.state.isTableSorted === false) {
        this.setState({
          isTableSorted: true
        });
      }
    }
  }

  showSortedIcon() {
    if (this.state.isTableSorted) {
      return <i className="fa fa-sort-asc" aria-hidden="true" />;
    } else if (this.state.isTableSorted === false) {
      return <i className="fa fa-sort-desc" aria-hidden="true" />;
    } else {
      return <i className="fa fa-sort" aria-hidden="true" />;
    }
  }

  //used for drop down
  showCustomIcon() {
    return <i className="fa fa-angle-down" aria-hidden="true" />;
  }

  onModalCancelChangesMethod() {
    this.props.getEmployerExpenceLiabilityAccountsList();

    if (isInDidUpdate === false) {
      this.setState({
        IsFormUpdated: false
      });
      this.props.setEmployerFormUpdated(false);
      this.props.setFormUpdate(false);
    }
    this.setState({
      showCancelModal: false
    });
  }

  setEmployerExpenceAccountContext(item, newValue) {
    this.props.setEmployerFormUpdated(true);
    item.EmployerContributionExpenseGlAccountId = newValue;
    let update = {};
    update[item.CompanyDeductionId] = newValue;
    this.setState(update);
    if (isInDidUpdate === false) {
      this.setState({
        IsFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }

    if (
      item.EmployerContributionExpenseGlAccountId !== null &&
      item.EmployerContributionLiabilityGlAccountId !== null
    ) {
      let unmappedList = this.state.unmappedList.slice();
      unmappedList = unmappedList.filter(function(removeItem) {
        return removeItem !== item.CompanyDeductionId;
      });
      this.setState({
        unmappedList: unmappedList,
        unmappedCount: unmappedList.length
      });

      if (this.state.unmappedCheckState === true) {
        if (item.IsIncludeInGlReport === true) {
          this.getFilteredList();
        }
      }
    }
  }

  setEmployerLiabilityAccountContext(item, newValue) {
    this.props.setEmployerFormUpdated(true);
    item.EmployerContributionLiabilityGlAccountId = newValue;
    let update = {};
    update[item.CompanyDeductionId] = newValue;
    this.setState(update);
    if (isInDidUpdate === false) {
      this.setState({
        IsFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }

    if (
      item.EmployerContributionExpenseGlAccountId !== null &&
      item.EmployerContributionLiabilityGlAccountId !== null
    ) {
      let unmappedList = this.state.unmappedList.slice();
      unmappedList = unmappedList.filter(function(removeItem) {
        return removeItem !== item.CompanyDeductionId;
      });
      this.setState({
        unmappedList: unmappedList,
        unmappedCount: unmappedList.length
      });

      if (this.state.unmappedCheckState === true) {
        if (item.IsIncludeInGlReport === true) {
          this.getFilteredList();
        }
      }
    }
  }

  setCategories(selectedGLCategories) {
    this.setState({
      selectedGLCategories
    });
  }

  setDeductions(selectedDeductions) {
    this.setState({
      selectedDeductions
    });
  }

  setUnmappedState(unmappedCheckState) {
    this.setState({
      unmappedCheckState
    });
  }

  setDeductionFilter(selectedDeductions) {
    this.setState({ selectedDeductions });
  }

  saveEmplyerExpenceLiabilityAccounts(employerExpenceLiabilityAccountsList) {
    this.props.saveEmployerExpenceLiabilityAccountsList(
      employerExpenceLiabilityAccountsList
    );
  }

  renderButtons(isUnmappedAny, employerList) {
    return (
      <div className="nc-button-group">
        <button
          type="button"
          id="next-map-gl-employer-paid-deductions"
          className={
            isUnmappedAny === true
              ? "disabled-button"
              : "nc-button secondary-button"
          }
          onClick={this.gotoNextTab.bind(this)}
          disabled={isUnmappedAny === true}
        >
          NEXT
        </button>
      </div>
    );
  }

  handleSelect(key) {
    this.setState({ key });
  }

  gotoNextTab() {
    const employerList = this.props.employerExpenceLiabilityAccountsList.Data;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.props.gotoNextTab(2);
    this.props.setEmployerExpencesData(employerList);
    if (this.state.IsFormUpdated) {
      this.props.setEmployerFormUpdated(true);
    }
    if (isInDidUpdate === false) {
      this.setState({
        IsFormUpdated: false
      });
      this.props.setFormUpdate(false);
    }
  }

  renderEmployerExpenseContentRow(employerList) {
    if (!employerList) {
      return <div>No Records Found.</div>;
    }
    return employerList.map((item, index) => {
      let isUnmapped = false;
      if (
        item.IsIncludeInGlReport &&
        (item.EmployerContributionExpenseGlAccountId === null ||
          item.EmployerContributionLiabilityGlAccountId === null)
      ) {
        isUnmapped = true;
      }
      return (
        <div
          key={index}
          className="table-row clearfix"
          style={{ paddingTop: "25px" }}
        >
          <div
            className="col-lg-2 col-md-3 col-sm-12 col-xs-12 table-cell name-column"
            style={{ cursor: "default" }}
          >
            <div className="col-lg-12 col-md-12 col-sm-11 col-xs-11">
              {isUnmapped ? (
                <Badge className="unmapped-badge">Unmapped</Badge>
              ) : null}
            </div>
            <div
              id={"list-page-col1-" + item.CompanyDeductionId}
              className="col-lg-12 col-md-12 col-sm-11 col-xs-9"
              style={{ wordWrap: "break-word" }}
            >
              {item.CompanyDeductionName}
            </div>
            <div
              className="hidden-lg hidden-md col-sm-1 col-xs-3 table-cell"
              style={{ textAlign: "center" }}
            >
              <Toggle
                id={"include-general-ledger-" + item.CompanyDeductionId}
                className="react-toggle"
                icons={true}
                checked={item.IsIncludeInGlReport}
                defaultChecked={item.IsIncludeInGlReport}
                onChange={this.toggleIncludeGeneralLedger.bind(this, item)}
              />
            </div>
          </div>
          <div
            className="col-lg-2 col-md-1 hidden-sm hidden-xs table-cell"
            style={{ textAlign: "center" }}
          >
            <Toggle
              id={"list-page-col2-" + item.CompanyDeductionId}
              className="react-toggle"
              icons={true}
              checked={item.IsIncludeInGlReport}
              defaultChecked={item.IsIncludeInGlReport}
              onChange={this.toggleIncludeGeneralLedger.bind(this, item)}
            />
          </div>
          {item.IsIncludeInGlReport ? (
            <div>
              <div className="nc-field-group">
                <div className="field-name">
                  <span className="hidden-lg hidden-md">Expense Account</span>
                </div>
                <div className="input-field temporary-class-for-dropdown expense-account-dropdown col-lg-4 col-md-4">
                  <DropDown
                    id={"list-page-col3-" + item.CompanyDeductionId}
                    placeholder="Select"
                    options={this.props.employerExpenceAccountDropDown}
                    value={item.EmployerContributionExpenseGlAccountId}
                    onSelect={this.setEmployerExpenceAccountContext.bind(
                      this,
                      item
                    )}
                    className="employer-expence-account-dropdown"
                  />
                </div>
              </div>
              <div className="clearfix hidden-lg hidden-md" />

              <div className="nc-field-group">
                <div className="field-name">
                  <span
                    className="hidden-lg hidden-md"
                    style={{ marginTop: "5px" }}
                  >
                    Liability Account
                  </span>
                </div>
                <div className="input-field temporary-class-for-dropdown liability-account-dropdown col-lg-4 col-md-4">
                  <DropDown
                    id={"list-page-col4-" + item.CompanyDeductionId}
                    placeholder="Select"
                    options={this.props.employerLiabilityAccountDropDown}
                    value={item.EmployerContributionLiabilityGlAccountId}
                    onSelect={this.setEmployerLiabilityAccountContext.bind(
                      this,
                      item
                    )}
                    className="employer-liability-account-dropdown"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="employer-paid-deduction-dropdowns">
              {/* added this div automation */}
            </div>
          )}
        </div>
      );
    });
  }

  renderEmployeeLiabilityTable(employerList, Id) {
    if (!this.props.employerExpenceLiabilityAccountsFilteredList.Data) {
      return <div />;
    }
    let isUnmappedAny = false;
    if (this.state.unmappedCount > 0) {
      isUnmappedAny = true;
    }

    return (
      <div>
        <div className="">
          <div className="col-lg-12 col-md-12 row table-header visible-lg visible-md employer-tab-header">
            <div
              id="list-page-col1-header"
              onClick={this.sortColumnByKey.bind(
                this,
                "CompanyDeductionName",
                employerList
              )}
              className="col-lg-2 col-md-3 user-column-header"
            >
              DEDUCTIONS{" "}
              {this.state.sortedKey === "CompanyDeductionName" ? (
                this.showSortedIcon()
              ) : (
                <i className="fa fa-sort" aria-hidden="true" />
              )}
            </div>
            <div
              id="list-page-col2-header"
              onClick={this.sortColumnByKey.bind(
                this,
                "IsIncludeInGlReport",
                employerList
              )}
              className="col-lg-2 col-md-1 user-column-header"
              style={{ textAlign: "center" }}
            >
              INCLUDE IN GL REPORT
            </div>
            <div
              id="list-page-col3-header"
              className="col-lg-4 col-md-4 column-header"
              style={{ textAlign: "center" }}
            >
              EXPENCE ACCOUNT{" "}
            </div>
            <div
              id="list-page-col4-header"
              className="col-lg-4 col-md-4 column-header"
              style={{ textAlign: "center" }}
            >
              LIABILITY ACCOUNT{" "}
            </div>
          </div>
          {this.renderEmployerExpenseContentRow(employerList)}
        </div>

        <div className="seperator">
          <hr />
        </div>
        <div className="footer-button-container">
          {isUnmappedAny ? (
            <div
              id="unmapped-count-section-employer-paid-deductions"
              className="unmapped-count-badge-section clearfix"
            >
              <Badge id="unmapped-count-badge" className="unmapped-count-badge">
                {this.state.unmappedCount}
              </Badge>
              <span
                id="unmapped-count-description-employer-paid-deductions"
                className="unmapped-count-badge-description"
              >
                Unmapped deductions remaining. All deductions need to be mapped
                or excluded before continuing.
              </span>
            </div>
          ) : (
            <div id="employer-paid-deductions-footer-section-all-mapped" />
          )}
          {this.renderButtons(isUnmappedAny, employerList)}
        </div>
      </div>
    );
  }

  showUnMappedBadgeSection() {
    return (
      <div>
        <div>
          <Badge className="unmapped-badge-for-non-selection">Unmapped</Badge>
        </div>
      </div>
    );
  }

  render() {
    let employerList = this.props.employerExpenceLiabilityAccountsFilteredList
      .Data;
    const { Id } = this.props;
    return (
      <div className="">
        <EmployerExpenseLiabilityAccountsAdvanceFilter
          onSelectedDeductions={selectedDeductions =>
            this.setDeductions(selectedDeductions)
          }
          onSelectedCategories={selectedGLCategories =>
            this.setCategories(selectedGLCategories)
          }
          getUnmappedCheckState={unmappedCheckState =>
            this.setUnmappedState(unmappedCheckState)
          }
          Id={Id}
        />

        <div
          className="list-page-grid-table col-lg-12 col-md-12 col-sm-12 col-xs-12"
          style={{ display: "block" }}
        >
          {this.props.employerExpenceLiabilityAccountsFilteredList.length ===
          0 ? (
            <div
              id={Id.loadingCount + "-employer-tab"}
              className="nc-rowcount-results"
            >
              {"Loading..."}
            </div>
          ) : this.props.employerExpenceLiabilityAccountsFilteredList
            .DataCount === 1 ? (
            <div
              id={Id.listCount + "-employer-tab"}
              className="nc-rowcount-results"
            >{`We found ${
              this.props.employerExpenceLiabilityAccountsFilteredList.DataCount
            } result.`}</div>
          ) : (
            <div
              id={Id.listCount + "-employer-tab"}
              className="nc-rowcount-results"
            >
              {`We found ${
                this.props.employerExpenceLiabilityAccountsFilteredList
                  .DataCount
              } results.`}
            </div>
          )}

          {this.renderEmployeeLiabilityTable(employerList, Id)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let employerExpenceAccountDropDown = [];

  if (
    state.generalLedgerReducer.employerExpenceAccountsList.length > 0 ||
    state.generalLedgerReducer.employerExpenceAccountsList.DataCount > 0
  ) {
    employerExpenceAccountDropDown = state.generalLedgerReducer.employerExpenceAccountsList.Data.map(
      (item, index) => {
        return {
          value: item.GLAccountId,
          label: `${item.GLAccountNumber} - ${item.GLAccountName}`
        };
      }
    );
  }

  let employerLiabilityAccountDropDown = [];

  if (
    state.generalLedgerReducer.employerLiabilityAccountsList.length > 0 ||
    state.generalLedgerReducer.employerLiabilityAccountsList.DataCount > 0
  ) {
    employerLiabilityAccountDropDown = state.generalLedgerReducer.employerLiabilityAccountsList.Data.map(
      (item, index) => {
        return {
          value: item.GLAccountId,
          label: `${item.GLAccountNumber} - ${item.GLAccountName}`
        };
      }
    );
  }

  return {
    employerExpenceAccountsList: state.employerExpenceAccountsList,
    employerExpenceAccountDropDown: employerExpenceAccountDropDown,
    employerLiabilityAccountsList: state.employerLiabilityAccountsList,
    employerLiabilityAccountDropDown: employerLiabilityAccountDropDown,
    employerExpenceLiabilityAccountsList:
      state.generalLedgerReducer.employerExpenceLiabilityAccountsList,
    employerExpenceLiabilityAccountsFilteredList:
      state.generalLedgerReducer.employerExpenceLiabilityAccountsFilteredList,
    UnMappedCheckBoxStateValue:
      state.generalLedgerReducer.UnMappedCheckBoxStateValue,
    toasterData: state.toasterData,
    globalNavClick: state.globalNavClick
    //  formUpdatedData: state.formUpdatedData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEmployerExpenceLiabilityAccountsList,
      getEmployerExpenceAccountsList,
      getEmployerLiabilityAccountsList,
      updatedFilteredList,
      saveEmployerExpenceLiabilityAccountsList,
      setEmployerExpencesData,
      setFormUpdate: setFormUpdate,
      isOverlayClickable: isOverlayClickable,
      setSelectedFilters: setSelectedFilters,
      getEmployerExpenceLiabilityAccountsFilteredList,
      setGlobalNavClick: setGlobalNavClick,
      CallbackToQuickLink: CallbackToQuickLink
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployerExpenseLiabilityAccounts)
);
