import React from "react";
import { FormFieldGroup, FormSection } from "../../../formSections";
import * as Fields from "./FieldNames";
const labelMaker = (noLabel, allLabel, someLabel, oneLabel) => (o, s, all) =>
  all
    ? allLabel
    : Object.keys(s).filter(k => s[k]).length === 0
      ? noLabel
      : Object.keys(s).filter(k => s[k]).length === 1
        ? oneLabel
        : someLabel;
const Demographics = ({
  onChange,
  data,
  options,
  makeOnSelect,
  makeOnSelectAll,
  selectedOptions,
  makeDateRangeSelect
}) => {
  return (
    <FormSection>
      <div className="column-title">Demographics</div>
      <FormFieldGroup
        inputType="text"
        inputName={Fields.lastName}
        value={data[Fields.lastName]}
        label="Last Name"
        onChange={onChange}
        Size={[12, 12, 7, 12]}
      />
      <FormFieldGroup
        inputType="text"
        inputName={Fields.firstName}
        value={data[Fields.firstName]}
        label="First Name"
        onChange={onChange}
        Size={[12, 12, 7, 12]}
      />
      <FormFieldGroup
        inputType="text"
        onChange={onChange}
        inputName={Fields.employeeSsn}
        value={data[Fields.employeeSsn]}
        label="SSN"
        Size={[12, 12, 7, 12]}
      />
      <FormFieldGroup
        inputType="singleDatePicker"
        inputName={Fields.birthDate}
        value={data[Fields.birthDate]}
        label="Birth Date"
        getFromToValue={makeDateRangeSelect}
        onHide={() => {}}
        Size={[12, 12, 7, 12]}
        style={{ marginBottom: "20px" }}
      />
      <div className="col-lg-12">
        <div className="col-lg-6" style={{ paddingRight: "5px" }}>
          <FormFieldGroup
            inputType="dropdown"
            inputName={Fields.ageTo}
            value={data[Fields.ageTo]}
            label="Age"
            onChange={onChange}
            Size={[12, 12, 7, 12]}
          />
        </div>
        <div className="col-lg-6" style={{ paddingLeft: "5px" }}>
          <FormFieldGroup
            inputType="text"
            inputName={Fields.ageFrom}
            value={data[Fields.ageFrom]}
            label="Age"
            onChange={onChange}
            Size={[12, 12, 7, 12]}
          />{" "}
        </div>
      </div>
      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.gender}
        value={data[Fields.gender]}
        label="Gender"
        multiSelect
        options={options[Fields.gender]}
        selectedItems={selectedOptions[Fields.gender]}
        makeLabel={labelMaker("Gender", "Gender", "Gender", "Gender")}
        onChange={makeOnSelect(Fields.gender)}
        selectAll={makeOnSelectAll(Fields.gender)}
        Size={[12, 12, 7, 12]}
      />
    </FormSection>
  );
};

export default Demographics;
Demographics.defaultProps = {
  onChange: () => {},
  data: {}
};
