import React from "react";
import EarningItem from "./EarningItem";
import DeductionItem from "./DeductionItem";

import "./styles.css";
import Footer from "./Footer";
const earningColumns = [
  { fieldName: "NAME", bsClass: "col-lg-3 col-md-3" },
  { fieldName: "STATUS", bsClass: "col-lg-3 col-md-3" },
  { fieldName: "AMOUNT", bsClass: "col-lg-3 col-md-3" },
  { fieldName: "END DATE", bsClass: "col-lg-3 col-md-3" }
];
const deductionColumns = [
  { fieldName: "NAME", bsClass: "col-lg-4 col-md-4" },
  { fieldName: "STATUS", bsClass: "col-lg-2 col-md-2" },
  { fieldName: "EMPLOYEE CONTRIBUTION", bsClass: "col-lg-2 col-md-2" },
  { fieldName: "EMPLOYER CONTRIBUTION", bsClass: "col-lg-2 col-md-2" },
  { fieldName: "END DATE", bsClass: "col-lg-2 col-md-2" }
];

const Table = ({ entity, data, onChange }) => {
  const tableColumns =
    entity === "earnings" ? earningColumns : deductionColumns;
  return (
    <div className="employee-info-entity-table clearfix">
      <div className="entity-info-item-header clearfix">
        {tableColumns.map((item, index) => (
          <div key={index} className={item.bsClass}>
            {item.fieldName}
          </div>
        ))}
      </div>
      {entity === "earnings" ? (
        <EarningItem data={data} onChange={onChange} />
      ) : (
        <DeductionItem data={data} onChange={onChange} />
      )}
      <Footer entity={entity} />
    </div>
  );
};

export default Table;
