import Loadable from "react-loadable";
import ChunkLoading from "app/common/UIComponents/ChunkLoading";

const CompanyThirdPartyPayeeListingPage = Loadable({
  loader: () =>
    import("./CompanyThirdPartyPayeeListingPage" /* webpackChunkName: "tpp-list" */),
  loading: ChunkLoading
});

const CompanyThirdPartyPayeeAddPage = Loadable({
  loader: () =>
    import("./CompanyThirdPartyPayeeAddPage" /* webpackChunkName: "tpp-add" */),
  loading: ChunkLoading
});

export { CompanyThirdPartyPayeeListingPage, CompanyThirdPartyPayeeAddPage };
