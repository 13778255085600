import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as util from "app/common/utilities";
import { Verification } from "component-library/employee-profile/resume/Components";
import {
  editEntityData,
  clearEntityData
} from "app/common/reducers/entityData";
import {
  clearEntityErrors,
  editEntityErrors
} from "app/common/reducers/entityErrors";
import {
  SchoolInfo,
  FooterSection,
  EducationDetails
} from "component-library/employee-profile/resume/Education";
import * as FIELDS from "component-library/employee-profile/resume/utils/Constants";
import { makeOptions, makeSelectedOptions } from "app/common/reducers/filters";
import Toaster from "component-library/toasters/index";

class AddEducation extends Component {
  state = {
    toasterMessage: "",
    showToaster: false,
    toasterClass: "",
    showCloseIcon: true
  };

  componentDidMount() {
    const { EducationId } = this.props.match.params;
    const { editData, data } = this.props;
    if (!util.isUndefinedOrZero(EducationId)) {
      this.props.fetchAndSetEducation(parseInt(EducationId, 10));
    } else {
      if (data[FIELDS.MAJORS] === undefined) {
        const edits = {
          [FIELDS.MAJORS]: [{ Name: "" }]
        };
        editData(edits);
      }

      if (data[FIELDS.MINORS] === undefined) {
        const edits = {
          [FIELDS.MINORS]: [{ Name: "" }]
        };
        editData(edits);
      }

      if (data[FIELDS.GRADUATED] === undefined) {
        const edits = {
          [FIELDS.GRADUATED]: false
        };
        editData(edits);
      }

      if (data[FIELDS.IS_VERIFIED] === undefined) {
        const edits = {
          [FIELDS.IS_VERIFIED]: false
        };
        editData(edits);
      }
    }

    this.props.loadStateList();
    this.props.loadCountryList();
    this.props.toggleAddOnMode(true);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   const { EducationId } = this.props.match.params;
  //   const prevEducationId = prevProps.match.params.LicenseCertificationId;
  //   if (
  //     (prevEducationId !== EducationId ||
  //       this.props.resumeEntityData !== prevProps.resumeEntityData) &&
  //     Object.keys(this.props.resumeEntityData).length !== 0 &&
  //     !util.isUndefinedOrZero(EducationId)
  //   ) {
  //     this.props.fetchAndSetEducation(
  //       parseInt(EducationId, 10),
  //       this.props.resumeEntityData
  //     );
  //   }

  //   if (
  //     this.props.editMode &&
  //     this.props.data !== prevProps.data &&
  //     Object.keys(this.props.data).length > 0
  //   ) {
  //     if (this.props.data[FIELDS.IS_VERIFIED]) {
  //       let toasterMessage =
  //         "You're updating a previously-verified education. Please update the verification section if necessary.";
  //       this.setState({
  //         showToaster: true,
  //         toasterMessage: toasterMessage,
  //         toasterClass: "warning",
  //         showCloseIcon: false
  //       });
  //     }
  //   }
  // }

  onChange = e => {
    const { editData, editErrors } = this.props;
    const edits = { [e.name]: e.value };
    const errors = { [e.name]: null };
    if (e.name === FIELDS.IS_VERIFIED || e.name === FIELDS.GRADUATED) {
      edits[e.name] = e.value === "true" ? true : false;
    }

    if (e.name === FIELDS.GRADUATED && e.value) {
      edits[FIELDS.CURRENTLY_ENROLLED] = false;
    }
    editData(edits);
    editErrors(errors);
  };

  onAddEducation = () => {
    const {
      saveEducationRecordAndReload,
      setToaster,
      employeeName,
      data,
      onCancelClick
    } = this.props;
    saveEducationRecordAndReload(data, employeeName, toasterMessage => {
      setToaster(toasterMessage, "success");
      onCancelClick();
    });
  };

  update = id => {
    const {
      updateEducationRecordAndReload,
      setToaster,
      employeeName,
      data,
      onCancelClick
    } = this.props;
    updateEducationRecordAndReload(id, data, employeeName, toasterMessage => {
      setToaster(toasterMessage, "success");
      onCancelClick();
    });
  };

  onAddAdditionalFields = fieldName => {
    const { editData, data = [] } = this.props;
    const edits = { [fieldName]: data[fieldName] || [] };
    edits[fieldName].push({
      Name: ""
    });
    editData(edits);
  };

  componentWillUnmount() {
    this.props.clearData();
    this.props.clearErrors();
    this.props.onCancelClick();
    this.props.toggleToaster();
    this.props.toggleAddOnMode(false);
  }

  render() {
    const {
      data,
      errors,
      getFromToValue,
      editMode,
      stateList = [],
      onCancelClick,
      options,
      selectedOptions
    } = this.props;
    let states = stateList.length > 0 ? stateList : [];

    return (
      <div className="tab-container-add-section">
        <div id="add-licenses" className="title-band-container">
          <div className="title-band">
            {editMode ? "Edit Education" : "Add Education"}
          </div>
          <Toaster
            id={"educationToaster"}
            show={this.state.showToaster}
            alertClassName={this.state.toasterClass}
            message={this.state.toasterMessage}
            closeToasterMessage={this.toggleToaster}
            showCloseIcon={this.state.showCloseIcon}
          />
          <SchoolInfo
            stateList={states}
            data={data}
            onChange={this.onChange}
            errors={errors}
          />

          <EducationDetails
            options={options}
            selectedOptions={selectedOptions}
            data={data}
            onChange={this.onChange}
            onAddAdditionalFields={this.onAddAdditionalFields}
            errors={errors}
          />

          <Verification
            data={data}
            onChange={this.onChange}
            getFromToValue={getFromToValue}
            errors={errors}
          />

          <FooterSection
            onCancelClick={onCancelClick}
            onAddClick={this.onAddEducation}
            editMode={editMode}
            update={this.update}
          />
        </div>
      </div>
    );
  }
}

const stateToProps = state => {
  const { entitySetup } = state.common;
  const { data, errors = {} } = entitySetup;
  return {
    data,
    errors,
    stateList: state.common.countryState.States,
    countryList: state.common.country.Countries,
    options: makeOptions(state.common.filters),
    selectedOptions: makeSelectedOptions(state.common.filters)
  };
};

const actionCreators = {
  editData: editEntityData,
  clearData: clearEntityData,
  clearErrors: clearEntityErrors,
  editErrors: editEntityErrors
};

export default withRouter(connect(stateToProps, actionCreators)(AddEducation));
