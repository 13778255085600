import React from "react";
import { Switch, Route } from "react-router-dom";
import HolidayCalendarRoutes from "holidaycalendar/HolidayCalendarRoutes";

const PayrollUtilitiesRoutes = () => (
  <Switch>
    <Route
      path="/payroll/utilities/holidaycalendar/"
      component={HolidayCalendarRoutes}
    />
  </Switch>
);

export default PayrollUtilitiesRoutes;
