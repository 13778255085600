import React from "react";

const FormatAddress = ({ line1, line2, city, state, zipcode }) => {
  return (
    <div>
      {line1 && (
        <div>
          {line1} {line2 && <span>&nbsp;{line2},</span>}
        </div>
      )}
      <div>
        {city && <span>{city},</span>}
        {/* {state && <span>{state}</span>} */}
        {zipcode && <span>{zipcode}</span>}
      </div>
    </div>
  );
};

export default FormatAddress;
