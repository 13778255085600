import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import * as validator from '../../../app/common/validations';
import TextInputWithoutLabel from "../../../app/common/TextInputWithoutLabel";
import SelectInputDropdown from "../../../app/common/SelectInputDropdown";
class GeneralLedgerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormUpdated: false,
      errors: {},
      accountNumber: "",
      accountDescription: "",
      selectedAccountType: 0
    };

    this.handleChange = this.handleChange.bind(this);
    this.saveFormValues = this.saveFormValues.bind(this);
    this.cancelFormValues = this.cancelFormValues.bind(this);
    this.setDropdownContext = this.setDropdownContext.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ isFormUpdated: true });
    this.props.onGLFormEdited(true);
  }

  saveFormValues(event) {
    event.preventDefault();
    let errors = {};
    // errors['accountNumber'] = validator.validateRequired(this.state.accountNumber);
    // errors['accountNumber'] = validator.validateNumeric(this.state.accountNumber);
    // errors['accountDescription'] = validator.validateMaxCharacter(this.state.accountDescription, 10);
    this.setState({ errors: errors });
    if (errors == null) {
      alert("no errors");
    }
    alert("You submitted, " + JSON.stringify(this.state));
    this.setState({ isFormUpdated: false });
  }

  cancelFormValues(event) {
    event.preventDefault();
    this.props.onGLFormCancel(this.state.isFormUpdated);
  }

  setDropdownContext(newValue) {
    this.setState({ selectedAccountType: newValue });
  }

  showCustomIcon() {
    return <i className="fa fa-chevron-down cstm555icon" aria-hidden="true" />;
  }

  render() {
    let accountSelectionList = [
      {
        value: 1,
        label: "Account 1"
      },
      {
        value: 2,
        label: "Account 2"
      },
      {
        value: 3,
        label: "Account 3"
      }
    ];
    return (
      <div>
        <div
          className="row account-gl-type-section"
          style={{ marginLeft: "2px" }}
        >
          <label className="general-ledger-form-label" htmlFor="accountType">
            What type of account are you wanting to create?
          </label>
          <div className="general-ledger-account-type-section col-sm-8">
            <SelectInputDropdown
              id="account-selection-dropdown"
              options={accountSelectionList}
              selectedValue={this.state.selectedAccountType}
              onChange={this.setDropdownContext}
              searchable={false}
              arrowRenderer={this.showCustomIcon}
            />
          </div>
        </div>

        <div className="row account-gl-input-section">
          <div className="col-lg-4">
            <label
              className="general-ledger-form-label"
              htmlFor="accountNumber"
            >
              Account Number
            </label>
            <div>
              <TextInputWithoutLabel
                id="general-ledger-account-number"
                name="accountNumber"
                value={this.state.accountNumber}
                onChange={this.handleChange}
                disabled={false}
                error={this.state.errors["accountNumber"]}
              />
            </div>
          </div>
          <div className="col-lg-7">
            <label
              className="general-ledger-form-label"
              htmlFor="accountDescription"
            >
              Account Description
            </label>
            <div>
              <TextInputWithoutLabel
                id="general-ledger-account-description"
                name="accountDescription"
                value={this.state.accountDescription}
                onChange={this.handleChange}
                disabled={false}
                error={this.state.errors["accountDescription"]}
              />
            </div>
          </div>
          {/*<div className="form-group col-sm-4">
                         
                </div>*/}

          {/*<div className="col-sm-7 col-xs-7 col-md-7 col-lg-7 col-sm-offset-1 col-xs-offset-1 col-md-offset-1 col-lg-offset-1">
                   
                </div>        */}
        </div>

        <div className="form-group">
          <div className="general-ledger-footer">
            <button
              id="general-ledger-account-submit"
              className="btn btn-info general-ledger-save-btn"
              disabled={!this.state.isFormUpdated}
              onClick={this.saveFormValues}
            >
              ADD GENERAL LEDGER ACCOUNT
            </button>
            <button
              id="general-ledger-account-clear"
              className="btn btn-default general-ledger-save-btn"
              type="button"
              onClick={this.cancelFormValues}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(GeneralLedgerForm);
