import React, { Component } from "react";
import EarningsSetupHeader from "./EarningsSetupHeader";

export default class EarningsSetup extends Component {
  state = {};
  render() {
    return (
      <div>
        <EarningsSetupHeader />
        {this.props.children}
      </div>
    );
  }
}
