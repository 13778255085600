import React from "react";

const DivBusUnit = ({ height, width, style }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      height={height}
      width={width}
      viewBox="0 0 55.3 58"
      style={{ enableBackground: "new 0 0 55.3 58", fill: "#555555", ...style }}
      xmlSpace="preserve"
    >
      <path
        className="st0"
        d="M47.4,38.3v-6.3H28.9v6.3c0,0.7-0.5,1.2-1.2,1.2c-0.7,0-1.2-0.5-1.2-1.2v-6.3H7.9v6.3c0,0.7-0.5,1.2-1.2,1.2
	S5.6,39,5.6,38.3v-6.8c0-1,0.8-1.8,1.8-1.8h19.1v-6.3c0-0.7,0.5-1.2,1.2-1.2c0.7,0,1.2,0.5,1.2,1.2v6.3H48c1,0,1.8,0.8,1.8,1.8v6.8
	c0,0.7-0.5,1.2-1.2,1.2C48,39.5,47.4,39,47.4,38.3z M13.2,46.9v7.2c0,1.6-1.3,2.8-2.8,2.8H3.1c-1.6,0-2.8-1.3-2.8-2.8v-7.2
	c0-1.6,1.3-2.8,2.8-2.8h7.2C11.9,44.1,13.2,45.4,13.2,46.9z M10.8,48.8c0-1.3-1.1-2.4-2.4-2.4H5.1c-1.3,0-2.4,1.1-2.4,2.4v3.4
	c0,1.3,1.1,2.4,2.4,2.4h3.4c1.3,0,2.4-1.1,2.4-2.4V48.8z M34.1,46.9v7.2c0,1.6-1.3,2.8-2.8,2.8h-7.2c-1.6,0-2.8-1.3-2.8-2.8v-7.2
	c0-1.6,1.3-2.8,2.8-2.8h7.2C32.8,44.1,34.1,45.4,34.1,46.9z M31.7,48.8c0-1.3-1.1-2.4-2.4-2.4H26c-1.3,0-2.4,1.1-2.4,2.4v3.4
	c0,1.3,1.1,2.4,2.4,2.4h3.4c1.3,0,2.4-1.1,2.4-2.4V48.8z M55,46.9v7.2c0,1.6-1.3,2.8-2.8,2.8H45c-1.6,0-2.8-1.3-2.8-2.8v-7.2
	c0-1.6,1.3-2.8,2.8-2.8h7.2C53.8,44.1,55,45.4,55,46.9z M52.7,48.8c0-1.3-1.1-2.4-2.4-2.4h-3.4c-1.3,0-2.4,1.1-2.4,2.4v3.4
	c0,1.3,1.1,2.4,2.4,2.4h3.4c1.3,0,2.4-1.1,2.4-2.4V48.8z M7.6,49.6H5.9C5.4,49.6,5,50,5,50.5s0.4,0.9,0.9,0.9h1.7
	c0.5,0,0.9-0.4,0.9-0.9S8.1,49.6,7.6,49.6z M28.5,49.6h-1.7c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h1.7c0.5,0,0.9-0.4,0.9-0.9
	S29,49.6,28.5,49.6z M49.5,49.6h-1.7c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h1.7c0.5,0,0.9-0.4,0.9-0.9C50.4,50,50,49.6,49.5,49.6z
	 M40.2,4.2v10c0,1.9-1.6,3.5-3.5,3.5H18.6c-1.9,0-3.5-1.6-3.5-3.5v-10c0-1.9,1.6-3.5,3.5-3.5h18.1C38.7,0.7,40.2,2.3,40.2,4.2z
	 M37.8,5.5c0-1.3-1.1-2.4-2.4-2.4H19.9c-1.3,0-2.4,1.1-2.4,2.4v7.4c0,1.3,1.1,2.4,2.4,2.4h15.6c1.3,0,2.4-1.1,2.4-2.4V5.5z
	 M32.6,6.3h-9.9c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h9.9c0.5,0,0.9-0.4,0.9-0.9S33.1,6.3,32.6,6.3z M31.3,10.2h-7.2
	c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h7.2c0.5,0,0.9-0.4,0.9-0.9C32.2,10.6,31.8,10.2,31.3,10.2z"
      />
    </svg>
  );
};

export default DivBusUnit;
