import React from "react";
import { Switch, Route } from "react-router-dom";

import { PayrollDashboard } from "../payrolldashboard/pages";

import UnapprovedPto from "../payrolldashboard/pages/UnapprovedPto";

import TimecardAlerts from "../payrolldashboard/pages/TimecardAlerts";

import WageTaxValidation from "../payrolldashboard/containers/WageTaxValidation";
import PayrollUtilitiesRoutes from "payroll/payrollUtiltiesRoutes";

const PayrollDashboardRoutes = () => (
  <Switch>
    <Route path="/payroll/unapprovedpto" component={UnapprovedPto} />
    <Route path="/payroll/time-card-alerts" component={TimecardAlerts} />
    <Route
      path="/payroll/wage-and-tax-validation"
      component={WageTaxValidation}
    />
    <Route path="/payroll/utilities" component={PayrollUtilitiesRoutes} />
    <Route path="/payroll" component={PayrollDashboard} />
  </Switch>
);

export default PayrollDashboardRoutes;
