import axios from "axios";
import * as common from "app/common";
const BASE_WEB_URL = common.WEB_API_URL;
const BASE_MOCK_URL = common.WEB_API_MOCK_URL;
const demographicPath = "/v1/subscriber/company/employee";
const fetchEmployeeDataPath = `/v1/system/`;
const fetchIntrestData = "/v1/system/employeeinterests";

export const postDemographicData = params =>
  axios.post(BASE_MOCK_URL + demographicPath, JSON.stringify(params), {
    headers: { "Content-Type": "application/json" }
  });

export const patchDemographicData = (params, id) =>
  axios.patch(
    BASE_MOCK_URL + demographicPath + `/` + id,
    JSON.stringify(params),
    {
      headers: { "Content-Type": "application/json" }
    }
  );

export const getDemographicData = id =>
  axios.get(BASE_MOCK_URL + demographicPath + `/` + id);

export const getEmpData = param =>
  axios.get(BASE_MOCK_URL + fetchEmployeeDataPath + param);

export const getCountry = () => axios.get(BASE_WEB_URL + `/v1/system/country`);

export const getState = countryId =>
  axios.get(BASE_WEB_URL + `/v1/country/` + countryId + `/state`);

export const getHobbiesData = id => axios.get(BASE_MOCK_URL + fetchIntrestData);
