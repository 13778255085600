import React from "react";
import PropTypes from "prop-types";

const SaveDetailButton = ({ disabled, onClick }) => (
  <button
    type="button"
    className={`nc-button ${disabled ? "disabled-button" : "primary-button"}`}
    onClick={disabled ? undefined : onClick}
    style={{ marginRight: 16 }}
  >
    Save
  </button>
);

SaveDetailButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default SaveDetailButton;
