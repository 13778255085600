import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UnapprovedPtosTable from "component-library/payroll-dashboard/multi-section-table/UanpprovedPtosTable";
import * as payrollConstants from "component-library/payroll-dashboard/PayrollDashboardConstants";
import {
  fetchUnapprovedPtoItems,
  loadMoreUnapprovedPtoItems,
  approvePtosAction,
  approveAllPtosAction,
  searchAndSetPostsUnapprovedPto
} from "../network/actions";
import {
  makeOptions,
  makeSelectedOptions,
  selectFilterOption,
  selectAllFilterOptions
} from "app/common/reducers/filters";
import { setSortOrder, clearSortOrder } from "app/common/reducers/sortOrder";
class UnapprovedPtosTableContainer extends Component {
  state = {
    allSelected: false,
    modeOfOperation: payrollConstants.ADD_CONTACT_INFO,
    selectedEmployees: {}
  };

  componentDidMount() {
    this.props.fetchUnapprovedPtoItems(1, false);
  }

  componentWillUnmount() {
    this.props.clearSortOrder();
  }

  searchPostsUnapprovedPto = () => {
    const {
      searchAndSetPostsUnapprovedPto,
      searchParams,
      filters
    } = this.props;
    this.setState({
      loadingFirstTime: true
    });
    searchAndSetPostsUnapprovedPto(searchParams, "1", filters, () =>
      this.setState({ loadingFirstTime: false })
    );
  };

  changeSortOrder = (field, sortOrder) => {
    this.props.setSortOrder(field, sortOrder);
    this.props.fetchUnapprovedPtoItems(1, false);
    setTimeout(
      function() {
        this.searchPostsUnapprovedPto();
      }.bind(this),
      10
    );
  };

  makeOnSelect = name => (option, selected) => {
    this.props.selectOption(name, option, selected);
  };

  makeSelectAll = name => selected => {
    this.props.selectAll(name, selected);
  };

  onContactClick = EmployeeId => {
    let isManagerAssigned = true;
    let isManagerHasContact = true;

    if (isManagerAssigned && isManagerHasContact) {
      //Show Contact Approver
      this.setState({
        contactApproversModal: true
      });
    } else if (!isManagerAssigned) {
      this.setState({
        contactMissingInfoModal: true,
        modeOfOperation: payrollConstants.ASSIGN_MANAGER
      });
      //show Missing info popup stating that there is no contact person assigned.
    } else if (isManagerAssigned && !isManagerHasContact) {
      this.setState({
        contactMissingInfoModal: true,
        modeOfOperation: payrollConstants.ADD_CONTACT_INFO
      });
      //if Employee has manager assigned and the manager does not have contact information setup, show Missing Info Popup
    }
  };

  onSendMessageClick = EmployeeId => {
    this.props.onSendMessageClick(EmployeeId);
  };

  onContactAllClick = () => {
    let isManagerAssigned = true;
    let isManagerHasContact = true;

    if (isManagerAssigned && isManagerHasContact) {
      //Show Contact Approver
      this.setState({
        contactAllApproversModal: true
      });
    } else if (!isManagerAssigned) {
      this.setState({
        contactMissingInfoModal: true,
        modeOfOperation: payrollConstants.ASSIGN_MANAGER
      });
      //show Missing info popup stating that there is no contact person assigned.
    } else if (isManagerAssigned && !isManagerHasContact) {
      this.setState({
        contactMissingInfoModal: true,
        modeOfOperation: payrollConstants.ADD_CONTACT_INFO
      });
      //if Employee has manager assigned and the manager does not have contact information setup, show Missing Info Popup
    }
  };

  bulkApprovePto = () => {
    const {
      data = [],
      UserTestId,
      approve = () => {},
      payrollData
    } = this.props;
    const { selectedEmployees } = this.state;
    let bulkEmployeeData = [];
    Object.keys(selectedEmployees).filter(
      item =>
        selectedEmployees[item] === true &&
        bulkEmployeeData.push(parseInt(item, 10))
    );

    approve(UserTestId, null, bulkEmployeeData, data, payrollData, () => {
      this.setState({ selectedEmployees: {}, allSelected: false });
    });
  };

  render() {
    const { sortOrder, approve = () => {} } = this.props;

    return (
      <div>
        <UnapprovedPtosTable
          {...this.props}
          loadingMore={this.state.loadingMore}
          selectEmployee={this.selectEmployee}
          loadMoreUnapprovedPtoItems={this.props.loadMoreUnapprovedPtoItems}
          approveAllPtosAction={this.props.approveAllPtosAction}
          approvePto={this.props.approvePtosAction}
          onSendMessageClick={this.onSendMessageClick}
          nextUri={this.props.nextUri}
          data={this.props.payrollTypeData.UnapprovedPtos.Data}
          selectAllEmployees={this.selectAllEmployees}
          selectedEmployees={this.state.selectedEmployees}
          options={this.props.filterData.unApprovedPTO} // UPTO table data
          selectedOptions={this.props.selectedOptions.unApprovedPTO}
          makeOnSelect={this.makeOnSelect}
          makeSelectAll={this.makeSelectAll}
          onContactClick={() =>
            this.props.onContactClick({
              source: payrollConstants.UNAPPROVED_PTO
            })
          }
          onContactAllClick={() =>
            this.props.onContactAllClick({
              source: payrollConstants.UNAPPROVED_PTO
            })
          }
          approve={approve}
          sortOrder={sortOrder}
          changeSortOrder={this.changeSortOrder}
        />
      </div>
    );
  }
}

const stateToProps = state => ({
  payrollTypeData: state.payrollDashboard.payrollTypeData,
  // for table check boxes
  filterData: makeOptions(state.common.filters),
  selectedOptions: makeSelectedOptions(state.common.filters),
  sortOrder: state.common.sortOrder
});

const actionCreators = {
  fetchUnapprovedPtoItems,
  loadMoreUnapprovedPtoItems,
  approvePtosAction,
  approveAllPtosAction,
  // for table check boxes
  selectOption: selectFilterOption,
  selectAll: selectAllFilterOptions,
  setSortOrder,
  searchAndSetPostsUnapprovedPto,
  clearSortOrder
};

export default withRouter(
  connect(stateToProps, actionCreators)(UnapprovedPtosTableContainer)
);

UnapprovedPtosTableContainer.defaultProps = {
  onSendMessageClick: id => alert("Send Alert action")
};
