import React from "react";
import "./entitypage.css";

const EntityListBanner = props => {
  const {
    title = "Payroll Schedules",
    bannerName,
    description,
    feature
  } = props;

  return (
    <div>
      <div className="pageTitle-container row">
        <div className="pageTitle" id="entity-pagetitle">
          {title}
        </div>
      </div>
      {description && (
        <div className="page-description col-lg-7 col-md-7 col-sm-12 col-xs-12">
          <p>{description}</p>
        </div>
      )}
      <div
        className="title-band-container"
        style={{ marginBottom: feature === "payrollSchedule" ? "20px" : "" }}
      >
        <div className="title-band">{bannerName}</div>
      </div>
    </div>
  );
};
export default EntityListBanner;
