import { combineReducers } from "redux";
import checkUserInSession from "../app/reducers/AppReducer";
import sessionContext from "../app/reducers/SessionReducer";
import toasterData from "../app/reducers/ShowToaster";
import isInAuthProcess from "../app/reducers/AuthenticationReducer";
import formUpdatedData from "../app/reducers/FormUpdatedReducer";
import isOverlayClickable from "../app/reducers/OverlayClickableReducer";
import globalNavClick from "../app/reducers/GlobalNavLinkClickReducer";
import CallBackToQuickLink from "../app/reducers/CallBackToQuickLinkReducer";
import showTimeoutPopup from "../app/reducers/ShowTimeoutPopupReducer";
import activityHistory from "../activity-history/reducers";
import netchexLoader, { dataFetchLoader } from "../app/reducers/LoaderReducer";

import loaderCounter from "app/reducers/LoaderCounter";
import notificationLoader from "../app/reducers/NotificationLoaderReducer";
import unreadNotificationCount from "../app/navigation/reducers/NotificationViewReducer";
import earningListNotification from "../app/navigation/reducers/NotificationEarningsReducer";
import allMenusList from "../app/navigation/reducers/GlobalNavigationAllMenusReducer";
import menuState from "../app/navigation/reducers/GlobalNavigationSetMenuStateReducer";
import globalNavigation from "app/navigation/reducers/globalNavigation";
import companyLevelSelectedFiltersList from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelSelectedFiltersListReducer";
import companyLevelCategoryList from "../payroll/payrollsetup/reducers/companylevel/AdvanceFilterCategoryListReducer";
import companyLevelStatusList from "../payroll/payrollsetup/reducers/companylevel/AdvanceFilterStatusListReducer";
import companyLevelDeductionList from "../payroll/payrollsetup/reducers/companylevel/AdvanceFilterDeductionListReducer";

import companyDeductionFilteredList from "../payroll/payrollsetup/reducers/companylevel/companyDeductionFilteredListReducer";
import companyDeductionMainList from "../payroll/payrollsetup/reducers/companylevel/companyDeductionMainListReducer";

import companyDeductionData from "../payroll/payrollsetup/reducers/companylevel/AllDeductionPagesData"; //two times imports
import SaveCompanyDeductionPage from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelDeductionSavedPagesReducer";

import deductionCategoryList from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelDescriptionCategaryList";
import deductionSubCategoryList from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelDescriptionDropdownCategaryList";
import deductionContributionList from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelDescriptionContributionList";
import deductionTaxabilityList from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelDescriptionTaxabilityList";
import deductionReportedTaxList from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelDescriptionReportedTaxList";
import deductionReportedWagesList from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelDescriptionReportedWagesList";
import deductionW2box14List from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelDescriptionW2box14List";
import companyExcludeStatesValuesList from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelDeductionDescriptionExcludeStatesReducer";

import companyCalculationMethodRadioButtonList from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelCalculationListReducer";
import companylevelearningPercentValuesList from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelCalculationPercentListReducer";
import companyleveladvancedPreTaxValuesList from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelCalculationPertaxListReducer";
import companyleveladvancedRetirementValuesList from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelCalculationRetirementListReducer";
import companyLevelAcaRadioButtonValue from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelAcaDeductionRadioButtonReducer";
import companyLevelAcaDropDown from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelAcaDeductionDropDownListReducer";
import companylevelcustomEarningsList from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelCalculationCustomEarningListReducer";

import CompanypayrollDeductionRadioButtonList from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelDeductionCyclePayrollDeductionListReducer";
import CompanyscheduledPayrollsWitheldDeductionOtherRadioButtonList from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelDeductionCycleOtherWitheldDeductionListReducer";
import CompanyscheduledPayrollsWitheldDeductionScheduledPayrollRadioButtonList from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelDeductionCycleScheduledPayrollWitheldDeductionListReducer";

//SYSTEM LEVEL LISTING PAGE AND ADVANCE FILTER
import systemLevelSelectedFiltersList from "../payroll/payrollsetup/reducers/systemlevel/SelectedFiltersReducer";
import systemLevelCategoryList from "../payroll/payrollsetup/reducers/systemlevel/AdvanceFilterCategoryListReducer";
import systemLevelStatusList from "../payroll/payrollsetup/reducers/systemlevel/AdvanceFilterStatusListReducer";
import systemLevelDeductionList from "../payroll/payrollsetup/reducers/systemlevel/AdvanceFilterDeductionListReducer";
import systemDeductionFilteredList from "../payroll/payrollsetup/reducers/systemlevel/SystemDeductionFilteredListReducer";
import systemDeductionMainList from "../payroll/payrollsetup/reducers/systemlevel/SystemDeductionMainListReducer";
import systemLevelDeductionDeleteIconClickList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelDeductionListingDeleteIconClickReducer";

import systemDeductionData from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelDeductionDataReducer";
import setSaveSysDeductionPage from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelDeductionSavedPagesReducer";

import symmetryTaxCodeList from "../payroll/payrollsetup/reducers/systemlevel/SymmetryTaxCodeListReducer";

//System Third-Party Payee LISTING PAGE AND ADVANCE FILTER
import countryStateList from "../payroll/payrollsetup/reducers/systemlevel/thirdpartypayee/CountryStateListReducer.js";
import thirdPartyPayeeCategoryList from "../payroll/payrollsetup/reducers/systemlevel/thirdpartypayee/AdvanceFilterCategoryListReducer";
import thirdPartyPayeeStatusList from "../payroll/payrollsetup/reducers/systemlevel/thirdpartypayee/AdvanceFilterStatusListReducer";
import thirdPartyPayeeList from "../payroll/payrollsetup/reducers/systemlevel/thirdpartypayee/AdvanceFilterThirdPartyPayeeListReducer";
import thirdPartyPayeeFilteredList from "../payroll/payrollsetup/reducers/systemlevel/thirdpartypayee/ThirdPartyPayeeFilteredListReducer";
import thirdPartyPayeeMainList from "../payroll/payrollsetup/reducers/systemlevel/thirdpartypayee/ThirdPartyPayeeMainListReducer";
import systemThirdPartyPayeeData from "../payroll/payrollsetup/reducers/systemlevel/thirdpartypayee/ThirdPartyPartyDataReducer";
import systemLevelTPPSelectedFiltersList from "../payroll/payrollsetup/reducers/systemlevel/thirdpartypayee/systemLevelTPPSelectedFiltersList";

import toasterMessage from "../payroll/payrollsetup/reducers/systemlevel/thirdpartypayee/ToasterMessageReducer";
import buttonTypeForToasterMessage from "../payroll/payrollsetup/reducers/systemlevel/thirdpartypayee/ButtonTypeForToasterMessageReducer";

//Third-Party Payee ADD PAGE
import thirdPartyPayeePaymentType from "../payroll/payrollsetup/reducers/systemlevel/thirdpartypayee/ThirdPartyPayeePaymentTypeReducer";
import thirdPartyPayeeAccountType from "../payroll/payrollsetup/reducers/systemlevel/thirdpartypayee/ThirtPartyPayeeAccountTypeReducer";
import thirdPartyPayeePrintSsnOnCheckOption from "../payroll/payrollsetup/reducers/systemlevel/thirdpartypayee/ThirdPartyPayeePrintSsnOnCheckOptionReducer";
import zipCode from "../payroll/payrollsetup/reducers/systemlevel/thirdpartypayee/ZipCodeReducer";

import categoryDeductionList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelDeductionDescriptionCategoryListReducer";
import subCategoryDeductionList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelDeductionDescriptionSubCategoryListReducer";
import taxabilityValuesList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelDeductionDescriptionTaxabilitytValuesListReducer";
import excludeStatesValuesList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelDeductionDescriptionExcludeStateReducer";
import generalLedgerAccountTypeList from "../payroll/payrollsetup/reducers/generalledger/GeneralLedgerAccountTypeListReducer";
import deductionAmountDebitCard from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelDeductionDescriptionDebitAmountValuesListReducer";

import calculationMethodRadioButtonList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelCalculationMethodCalculationListReducer";
import GarnishmentPercentRadioButtonsList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelCalculationMethodEarningValueListReducer";
import advancedPreTaxValuesList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelCalculationMethodPretaxValueListReducer";
import advancedRetirementValuesList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelCalculationMethodRetirementValueListReducer";
//import garnishmentValuesList from '../payroll/payrollsetup/reducers/systemlevel/SystemLevelCalculationMethodGarnishmentValueListReducer';
import acaDeductionValuesList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelCalculationMethodAcaValueistReducer";

import payrollDeductionRadioButtonList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelDeductionCyclePayrollDeductionRadioButtonListReducer";
import scheduledPayrollsWitheldDeductionOtherRadioButtonList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelDeductionCycleOtherWitheldRadioButtonListReducer";
import scheduledPayrollsWitheldDeductionScheduledPayrollRadioButtonList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelDeductionCycleScheduledPayrollWitheldDeductionRadioButtonListReducer";
import addDeductionPopupTabMainList from "../payroll/payrollsetup/reducers/companylevel/AddDeductionPopupTabMainListReducer";
import addDeductionPopupTabSubList from "../payroll/payrollsetup/reducers/companylevel/AddDeductionPopupTabSubListReducer";
import addDeductionPopupTabSubRadioBtnList from "../payroll/payrollsetup/reducers/companylevel/AddDeductionPopupTabSubRadioBtnListReducer";
import addDeductionPopupMedicalTypeList from "../payroll/payrollsetup/reducers/companylevel/AddDeductionPopupMedicalTypeListReducer";

import defaultValueEmployeeAmountList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelDefaultValueReducer";
import addDeductionPopupDentalTypeList from "../payroll/payrollsetup/reducers/companylevel/AddDeductionPopupDentalTypeListReducer";
import addDeductionPopupVisionTypeList from "../payroll/payrollsetup/reducers/companylevel/AddDeductionPopupVisionTypeListReducer";
import addDeductionPopupSupplementalTypeList from "../payroll/payrollsetup/reducers/companylevel/AddDeductionPopupSupplementalTypeListReducer";
import addDeductionPopupCustomTypeList from "../payroll/payrollsetup/reducers/companylevel/AddDeductionPopupCustomTypeListReducer";

import systemLevelDeductionOptionsList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelDeductionOptionsListReducer";
import systemLevelDeductionOptionsDropdownList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelDeductionOptionsDropdownListReducer";
import vendorEmployeeDeductionList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelOptionsVendorEmployeeDeductionListReducer";
import vendorEmployerDeductionList from "../payroll/payrollsetup/reducers/systemlevel/SystemLevelOptionsVendorEmployerDeductionListReducer";

import navigationReducer from "../app/navigation/reducers";
import menuReducer from "../app/navigation/reducers/MenuReducer";
import entityNameReducer from "../app/reducers/EntityNameReducer";

//import getDeductionDeleteIconClickList from '../payroll/payrollsetup/reducers/companylevel/DeductionListingDeleteIconClickReducer';

//Employer Expence and Liability Accounts
//Employee Liability Accounts
import generalLedgerReducer from "../payroll/payrollsetup/reducers/generalledger";

//general ledger reducer for earnings
import generalLedgerEarningsReducer from "../payroll/payrollsetup/reducers/generalledger/earnings/index.js";

import employerDeductionList from "../payroll/payrollsetup/reducers/generalledger/EmployerDeductionFilterListReducer";

import includeGLReportList from "../payroll/payrollsetup/reducers/generalledger/IncludeGLReportFilterReducer";

//company level
import vendorEmployeeCategoryAmtDropdown from "../payroll/payrollsetup/reducers/companylevel/OptionEmployeeVendorCategoryAmtDropdown";
import vendorEmployerCategoryAmtDropdown from "../payroll/payrollsetup/reducers/companylevel/OptionEmployerVendorCategoryAmtDropdown";
import enterprisePayeeDropdown from "../payroll/payrollsetup/reducers/companylevel/OptionEnterprisePayeeDropdown";
import employerDeductionEarningDropdown from "../payroll/payrollsetup/reducers/companylevel/OptionEmployerDeductionEarningDropdown";
import OptionAmountReduction from "../payroll/payrollsetup/reducers/companylevel/OptionAmountReduction";
import companyReviewandSaveReducer from "../payroll/payrollsetup/reducers/companylevel/ReviewandSaveReducer";
import systemReviewandSaveReducer from "../payroll/payrollsetup/reducers/systemlevel/ReviewandSaveReducer";
import companyTppReviewandSaveReducer from "../payroll/payrollsetup/reducers/companylevel/thirdpartypayee/ReviewandSaveReducer";
import systemTppReviewandSaveReducer from "../payroll/payrollsetup/reducers/systemlevel/thirdpartypayee/ReviewandSaveReducer";
//Added on 11/08
import deductionTaxPriorityReducer from "../payroll/payrollsetup/reducers/companylevel/deductiontaxpriority/index";
// Default Value
import employerFixedCalculatedContributionRadio from "../payroll/payrollsetup/reducers/companylevel/ValueEmployerContributionRadio";
import defaultValueEmployerThirdPartyPayeeDropDown from "../payroll/payrollsetup/reducers/companylevel/CompanyLevelDefaultValueEmployerThirdPartyPaymentListReducer";
//TaxPriority
import taxPriorityList from "../app/reducers/TaxPriorityReducer";
import companyThirdPartyPayeeReducer from "../payroll/payrollsetup/reducers/companylevel/thirdpartypayee";
//AddPayrollSchedule
import addPayrollScheduleOptions from "../payroll/payrollschedule/reducers/addPayrollScheduleOptions";
import payrollScheduleError from "../payroll/payrollschedule/reducers/payrollScheduleError";
import payrollHistory from "../payroll/payrollschedule/reducers/payrollHistory";
// Earning Group
import EarningsGroupMainList from "../payroll/payrollsetup/EarningsGroup/reducers/companylevel/AllEarningListDropdown.js";
import EarningsGroupList from "../payroll/payrollsetup/EarningsGroup/reducers/companylevel/AdvanceFilterEarningsGroupListReducer";
import EarningsGroupFilteredList from "../payroll/payrollsetup/EarningsGroup/reducers/companylevel/AllEarningTableList.js";
import AddEarningGroupDropdownList from "../payroll/payrollsetup/EarningsGroup/reducers/companylevel/AddEarningTaxableDropdown.js";
import AddAllEarningMainList from "../payroll/payrollsetup/EarningsGroup/reducers/companylevel/AddAllEarningMainList.js";
import AllEarningDropdownList from "../payroll/payrollsetup/EarningsGroup/reducers/companylevel/AddAllEarningDropdown.js";
import AddEarningGroupsPageMainList from "../payroll/payrollsetup/EarningsGroup/reducers/companylevel/AddEarningGroupsPageMainList.js";
import AddEarningPageFilteredList from "../payroll/payrollsetup/EarningsGroup/reducers/companylevel/AddAllEarningCheckboxList.js";
import earningPageData from "../payroll/payrollsetup/EarningsGroup/reducers/companylevel/AllEarningPagesDataReducer.js";
import EarningsByEarningGroupId from "../payroll/payrollsetup/EarningsGroup/reducers/companylevel/EarningListByEarningGroupIdReducer.js";
import EarningGroupListPageFilterStatusList from "../payroll/payrollsetup/EarningsGroup/reducers/companylevel/EarningGroupListPageFilterStatusListReducer.js";

import EarningSelectedFiltersList from "../payroll/payrollsetup/EarningsGroup/reducers/companylevel/SelectedFiltersReducer.js";
import EntityListingPageMapIt from "../app/reducers/MapItLinkNavigationReducer";

import payrollDashboard from "../payrolldashboard/reducers";
//payroll-calendar
import payrollCalendar from "../payroll-calendar/reducers";

//payroll-checklist
import payrollChecklistOptions from "../payroll/payrollchecklist/reducers/payrollChecklistOptions";

// Common reducers
import common from "app/common/reducers";
import copyEntity from "entity-copy/reducers";
import jobHistory from "job-history/reducers";

//ViewAllNotifications
import viewAllNotifications from "../notifications/reducers";
import emailTextAlert from "../emailtextalerts/reducers";

//People
import people from "../people/employee/base/reducers";
import personalInfo from "../people/employee/personalInfo/reducers";
import Others from "../people/employee/other/reducers";

//Employee Files
import employeeFiles from "../employee-files/reducers";

//custom Fields
import customFieldsMenu from "../people/utilities/custom-fields/reducers";

//HolidayCalendar
import systemHolidayList from "../holidaycalendar/reducers/systemHolidayList";
import commonHolidayList from "../holidaycalendar/reducers/commonHolidayList";
import holiday from "../holidaycalendar/reducers/holiday";

import { navigateToDestination } from "../notifications/reducers/navigateToDestination";

const rootReducer = combineReducers({
  checkUserInSession,
  sessionContext,
  toasterData,
  isInAuthProcess,
  showTimeoutPopup,
  unreadNotificationCount,
  allMenusList,
  menuState,
  systemDeductionData,
  setSaveSysDeductionPage,

  companyLevelSelectedFiltersList,
  companyLevelCategoryList,
  companyLevelStatusList,
  companyLevelDeductionList,
  companyDeductionFilteredList,
  companyDeductionMainList,

  companyDeductionData,
  SaveCompanyDeductionPage,

  calculationMethodRadioButtonList,
  companyCalculationMethodRadioButtonList,
  companylevelearningPercentValuesList,
  companyleveladvancedPreTaxValuesList,
  companyleveladvancedRetirementValuesList,
  companyLevelAcaRadioButtonValue,
  companyLevelAcaDropDown,
  companylevelcustomEarningsList,
  systemLevelSelectedFiltersList,
  systemLevelCategoryList,
  systemLevelStatusList,
  systemLevelDeductionList,
  systemDeductionFilteredList,
  systemDeductionMainList,
  systemLevelDeductionDeleteIconClickList,
  symmetryTaxCodeList,

  deductionContributionList,

  generalLedgerAccountTypeList,

  systemLevelDeductionOptionsList,
  systemLevelDeductionOptionsDropdownList,
  deductionTaxabilityList,
  deductionReportedTaxList,
  deductionReportedWagesList,
  categoryDeductionList,
  subCategoryDeductionList,
  taxabilityValuesList,
  deductionAmountDebitCard,
  deductionW2box14List,
  excludeStatesValuesList,
  companyExcludeStatesValuesList,

  GarnishmentPercentRadioButtonsList,
  advancedPreTaxValuesList,
  advancedRetirementValuesList,

  acaDeductionValuesList,

  payrollDeductionRadioButtonList,
  scheduledPayrollsWitheldDeductionOtherRadioButtonList,
  scheduledPayrollsWitheldDeductionScheduledPayrollRadioButtonList,

  addDeductionPopupTabMainList,
  addDeductionPopupTabSubList,
  addDeductionPopupTabSubRadioBtnList,
  addDeductionPopupMedicalTypeList,
  addDeductionPopupDentalTypeList,
  addDeductionPopupVisionTypeList,
  addDeductionPopupSupplementalTypeList,
  addDeductionPopupCustomTypeList,
  defaultValueEmployeeAmountList,

  navigationReducer,
  menuReducer,
  generalLedgerReducer,
  generalLedgerEarningsReducer,

  deductionSubCategoryList,
  deductionCategoryList,
  vendorEmployeeCategoryAmtDropdown,
  vendorEmployerCategoryAmtDropdown,
  enterprisePayeeDropdown,
  employerDeductionEarningDropdown,
  OptionAmountReduction,
  companyReviewandSaveReducer,
  systemReviewandSaveReducer,
  companyTppReviewandSaveReducer,
  systemTppReviewandSaveReducer,
  employerFixedCalculatedContributionRadio,
  defaultValueEmployerThirdPartyPayeeDropDown,

  CompanypayrollDeductionRadioButtonList,
  CompanyscheduledPayrollsWitheldDeductionOtherRadioButtonList,
  CompanyscheduledPayrollsWitheldDeductionScheduledPayrollRadioButtonList,

  vendorEmployeeDeductionList,
  vendorEmployerDeductionList,
  EntityListingPageMapIt,

  addPayrollScheduleOptions,
  payrollScheduleError,
  payrollHistory,

  EarningsGroupMainList,
  EarningsGroupFilteredList,
  AddEarningGroupDropdownList,
  AllEarningDropdownList,
  AddAllEarningMainList,
  AddEarningPageFilteredList,
  AddEarningGroupsPageMainList,
  earningPageData,
  EarningsByEarningGroupId,
  EarningGroupListPageFilterStatusList,
  EarningSelectedFiltersList,
  EarningsGroupList,

  deductionTaxPriorityReducer,
  dataFetchLoader,
  employerDeductionList,
  includeGLReportList,
  entityNameReducer,

  countryStateList,
  thirdPartyPayeeCategoryList,
  thirdPartyPayeeStatusList,
  thirdPartyPayeeList,
  thirdPartyPayeeFilteredList,
  thirdPartyPayeeMainList,
  thirdPartyPayeePaymentType,
  thirdPartyPayeeAccountType,
  thirdPartyPayeePrintSsnOnCheckOption,
  zipCode,
  systemThirdPartyPayeeData,
  toasterMessage,
  buttonTypeForToasterMessage,
  systemLevelTPPSelectedFiltersList,

  taxPriorityList,

  companyThirdPartyPayeeReducer,

  activityHistory,
  common,
  copyEntity,
  jobHistory,

  netchexLoader,
  loaderCounter,
  notificationLoader,

  formUpdatedData,
  globalNavClick,
  CallBackToQuickLink,
  isOverlayClickable,
  payrollDashboard,
  earningListNotification,
  payrollCalendar,
  viewAllNotifications,
  payrollChecklistOptions,
  emailTextAlert,
  people,
  personalInfo,
  Others,
  employeeFiles,
  customFieldsMenu,
  systemHolidayList,
  commonHolidayList,
  holiday,
  globalNavigation,
  navigateToDestination
});

export default rootReducer;
