import React from "react";
import PropTypes from "prop-types";
import * as STATE from "../Constants";
const VerticalLine = ({ stageId, state, stepId }) => {
  if (stepId === 1) {
    const Condition = [
      STATE.STATUS_COMPLETED,
      STATE.STATUS_FAILED,
      STATE.STATUS_COMPLETED_WITH_ERRORS,
      STATE.STATUS_INPROGRESS,
      STATE.STATUS_CURRENTLY_INPROGRESS,
      STATE.STATUS_CANCELLED
    ].includes(stageId)
      ? true
      : false;
    if (Condition) {
      return (
        <span className="line-container">
          <span className="solid-line" style={{ height: "100px" }} />
        </span>
      );
    } else {
      return (
        <span className="line-container">
          <span className="dotted-line" style={{ height: "100px" }} />
        </span>
      );
    }
  } else if (stepId === 2) {
    let Condition = [
      STATE.STATUS_COMPLETED,
      STATE.STATUS_FAILED,
      STATE.STATUS_COMPLETED_WITH_ERRORS
    ].includes(stageId)
      ? true
      : false;
    if (Condition) {
      return (
        <span className="line-container">
          <span className="solid-line" style={{ height: "100px" }} />
        </span>
      );
    } else {
      let Condition = [
        STATE.STATUS_INPROGRESS,
        STATE.STATUS_CURRENTLY_INPROGRESS
      ].includes(stageId);
      if (Condition) {
        return (
          <span className="line-container">
            <span className="solid-line" style={{ height: "30px" }} />
            <span className="dashed-line" style={{ height: "70px" }} />
          </span>
        );
      } else {
        return (
          <span className="line-container">
            <span className="dotted-line" style={{ height: "100px" }} />
          </span>
        );
      }
    }
  } else {
    return null;
  }
};

export default VerticalLine;
VerticalLine.propTypes = {
  stageId: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8]),
  stepId: PropTypes.oneOf([1, 2, 3])
};
