export const IN_PROGRESS_START_OVER =
  "payroll/dashboard/IN_PROGRESS_START_OVER";

const initialState = {
  loading: true,
  Data: [],
  DataCount: 0,
  NextUri: null
};

//Actions
export const setPayrollStartOver = Data => ({
  type: IN_PROGRESS_START_OVER,
  payload: { Data }
});

// Reducers
const InProgressPayroll = (state = initialState, { type, payload }) => {
  switch (type) {
    case IN_PROGRESS_START_OVER: {
      const { Data } = payload;
      const newData = [...state.Data, ...Data];
      return {
        ...state,
        Data: newData
      };
    }
    default:
      return state;
  }
};

export default InProgressPayroll;
