import React from "react";
import ActivityHistoryColumnTitle, {
  ORDER_ASC,
  ORDER_DESC
} from "../../activity-history/ActivityHistoryColumnTitle";
import classNames from "classnames/bind";

const getContainerClass = (payrollType, column = "") => {
  return column === "first-column"
    ? classNames(
        "col-lg-2",
        payrollType === "unapprovedPtos" &&
          "col-md-2 unapproved-pto-row-header-first-column",
        payrollType === "timecardAlerts" &&
          "col-md-3 timecard-row-header-first-column"
      )
    : classNames(
        "column-header-row",
        "col-lg-9",

        payrollType === "unapprovedPtos" &&
          "col-md-9 unapproved-pto-row-header",
        payrollType === "timecardAlerts" && "col-md-8"
      );
};
const MultiSectionTableHeader = ({
  columns,
  sortOrder: { Field, Order } = {},
  changeSortOrder,
  payrollType
}) => (
  <div className="row table-header hidden-sm hidden-xs">
    <div className={getContainerClass(payrollType, "first-column")}>
      <ActivityHistoryColumnTitle
        key={columns[0].field}
        label={columns[0].label}
        className={columns[0].className}
        order={columns[0].field === Field && Order}
        noSort={columns[0].noSort}
        onClick={() => {
          if (columns[0].field !== Field) {
            changeSortOrder(columns[0].field, ORDER_ASC);
          } else {
            changeSortOrder(
              columns[0].field,
              Order === ORDER_ASC
                ? ORDER_DESC
                : Order === ORDER_DESC
                  ? ORDER_ASC
                  : ""
            );
          }
        }}
      />
    </div>
    <div className={getContainerClass(payrollType)}>
      {columns.slice(1).map(({ label, field, className, noSort }) => (
        <ActivityHistoryColumnTitle
          key={field}
          label={label}
          className={className}
          order={field === Field && Order}
          noSort={noSort}
          onClick={() => {
            if (noSort) {
              return;
            }
            if (field !== Field) {
              changeSortOrder(field, ORDER_ASC);
            } else {
              changeSortOrder(
                field,
                Order === ORDER_ASC
                  ? ORDER_DESC
                  : Order === ORDER_DESC
                    ? ORDER_ASC
                    : ""
              );
            }
          }}
        />
      ))}
    </div>
  </div>
);

export default MultiSectionTableHeader;
