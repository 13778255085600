import moment from "moment-timezone";

export function convertUserTimeZone(timeZoneOffset, time, dateFormat) {
  let convertedDateTime;
  if (dateFormat === "") {
    convertedDateTime = moment(time)
      .tz(timeZoneOffset)
      .format("MM/D/YYYY");
  } else if (time === "") {
    convertedDateTime = "";
  } else if (timeZoneOffset) {
    convertedDateTime = moment(time)
      .tz(timeZoneOffset)
      .format(dateFormat);
  } else {
    convertedDateTime = moment(time)
      //.tz(timeZoneOffset)
      .format(dateFormat);
  }
  return convertedDateTime;
}

export const modifyUrl = Url => {
  if (Url !== null) {
    if (Url.charAt(0) === "/") {
      return Url;
    } else {
      return "/" + Url;
    }
  }
};
