import React, { Component } from "react";
//import { TabContainer } from "component-library/employee-profile/entity-view";
import AddNewNotes from "component-library/employee-profile/employee-note/AddNewNotes";
import FooterButtonSection from "component-library/employee-profile/FooterButtonSection";
import EntityListBanner from "component-library/entity-list/EntityListBanner";
import AddNewNoteTypeModal from "component-library/employee-profile/employee-note/AddNewNoteTypeModal";
//import { description } from "component-library/payroll-dashboard/payrollSchedule/Constants";

class AddEmployeeNotes extends Component {
  state = {
    activePanel: {},
    errors: {},
    show: false
  };

  onChange = e => {
    let name = e.target ? e.target.name : e.name;
    let value = e.target ? e.target.value : e.value;
    this.setState({
      [name]: value
    });
  };
  componentDidMount() {
    const { EmployeeId } = this.props.match.params;
    this.props.fetchTypeOfNote(EmployeeId);
  }
  handleDateChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleCloseModal = () => {
    this.setState({
      show: false
    });
  };

  onAdd = () => {
    this.setState({
      show: true
    });
  };

  render() {
    const { CurrentSubscriberCompanyId } = this.props.sessionContext;
    const { EmployeeId } = this.props.match.params;
    const {
      data: {
        addEmployeeNotes: { addNotes }
      }
    } = this.props;
    const { description, date, noteType, note } = this.state;
    return (
      <div>
        <div
          className="tab-container nc-entity-page-container col-lg-9"
          id="tab-container"
        >
          <AddNewNoteTypeModal
            onClose={this.handleCloseModal}
            data={this.state}
            onChange={this.onChange}
            CompanyId={CurrentSubscriberCompanyId}
            addNewNoteType={this.props.addNewNoteType}
          />
          <EntityListBanner title="Notes" bannerName="Add New Note" />
          <AddNewNotes
            data={this.state}
            onChange={this.onChange}
            handleDateChange={this.handleDateChange}
            onAdd={this.onAdd}
            addNotes={addNotes}
          />
          <FooterButtonSection
            primaryButtonLabel="Add New Note"
            onCancelClick={() =>
              this.props.history.push(
                `/people/employees/dashboard/${EmployeeId}/other/30`
              )
            }
            onAddClick={() => {
              this.props.addNewNote(CurrentSubscriberCompanyId, {
                description,
                note,
                date,
                noteType
              });
            }}
          />
        </div>
      </div>
    );
  }
}

export default AddEmployeeNotes;
