import * as systemConstantsId from "../../../../app/common/SystemConstants";

export const getTaxabilityDefaultValue = data => {
  let AdvancedTaxOption = {};

  if (
    String(data.DeductionCategoryId) ===
    String(systemConstantsId.DEDUCTION_DESCRIPTION_INSURANCE_ID)
  ) {
    AdvancedTaxOption = {
      ReduceFUTAWages: true,
      ReduceFederalWages: true,
      ReduceLocalWages: true,
      ReduceMedicareWages: true,
      ReduceSDIWages: true,
      ReduceSUTAWages: true,
      ReduceSocialSecurityWages: true,
      ReduceStateWages: true
    };
  } else if (
    String(data.DeductionCategoryId) ===
    String(systemConstantsId.DEDUCTION_DESCRIPTION_RETIREMENT_ID)
  ) {
    if (
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_DEFERRED_COMPENSATION ||
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_STATE_EMPLOYEE_RETIREMENT_SYSTEM_WITHOLDING
    ) {
      AdvancedTaxOption = {
        ReduceFUTAWages: false,
        ReduceFederalWages: true,
        ReduceLocalWages: true,
        ReduceMedicareWages: false,
        ReduceSDIWages: false,
        ReduceSUTAWages: false,
        ReduceSocialSecurityWages: false,
        ReduceStateWages: true
      };
    } else if (
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K ||
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K_CATCH_UP ||
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B ||
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B_CATCH_UP ||
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_408K_6 ||
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_408P_SIMPLE ||
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_409A ||
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_457B ||
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_457B_CATCH_UP ||
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_501C_18_D ||
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_SIMPLE_IRA_SIMPLE_401K ||
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_SIMPLE_IRA_SIMPLE_401K_CATCH_UP
    ) {
      AdvancedTaxOption = {
        ReduceFUTAWages: false,
        ReduceFederalWages: true,
        ReduceLocalWages: true,
        ReduceMedicareWages: false,
        ReduceSDIWages: false,
        ReduceSUTAWages: false,
        ReduceSocialSecurityWages: false,
        ReduceStateWages: true
      };
    }
  } else if (
    String(data.DeductionCategoryId) ===
    String(systemConstantsId.DEDUCTION_DESCRIPTION_OTHER_ID)
  ) {
    AdvancedTaxOption = {
      ReduceFUTAWages: true,
      ReduceFederalWages: true,
      ReduceLocalWages: true,
      ReduceMedicareWages: true,
      ReduceSDIWages: true,
      ReduceSUTAWages: true,
      ReduceSocialSecurityWages: true,
      ReduceStateWages: true
    };
  }
  return AdvancedTaxOption;
};
