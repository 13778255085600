import React from "react";
import FormAccordian from "component-library/accordians/FormAccordian";
import * as Fields from "./FieldNames";
import * as Description from "../FieldDescriptions";
import { FormFieldGroup, FormSection } from "component-library/formSections";
import { NoStatusChanges } from "./common";
const labelClass = "col-lg-3 col-md-3";

const HireRehire = ({ onChange, data = {} }) => {
  return (
    <FormAccordian title="Hire/Rehire" id="2">
      <div>
        <FormSection>
          {data[Fields.hireDate] !== undefined && (
            <FormFieldGroup
              label="Hire Date"
              fieldType="inline"
              inputType="overrideWithComponent"
              componentType="singleDatePicker"
              btnStyle={{ height: "44px" }}
              getFromToValue={onChange}
              onChange={onChange}
              onHide={() => {}}
              name={Fields.hireDate}
              value={data[Fields.hireDate]}
              checkedName={Fields.overrideHireDate}
              checkedValue={data[Fields.overrideHireDate]}
              colSpan={labelClass}
              tooltip={Description.HIRE_DATE}
            />
          )}
          {data[Fields.rehireDate] !== undefined && (
            <FormFieldGroup
              label="Re-Hire Date"
              fieldType="inline"
              inputType="overrideWithComponent"
              componentType="singleDatePicker"
              btnStyle={{ height: "44px" }}
              getFromToValue={onChange}
              onChange={onChange}
              onHide={() => {}}
              name={Fields.rehireDate}
              value={data[Fields.rehireDate]}
              checkedName={Fields.overrideRehireDate}
              checkedValue={data[Fields.overrideRehireDate]}
              colSpan={labelClass}
              tooltip={Description.HIRE_DATE}
            />
          )}
          {data[Fields.hireDate] !== undefined &&
            data[Fields.rehireDate] !== undefined && (
              <NoStatusChanges entity="Hire / Re-Hire" />
            )}
        </FormSection>
      </div>
    </FormAccordian>
  );
};

export default HireRehire;
