/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Overlay, Panel } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { bindActionCreators } from "redux";
import { Badge } from "react-bootstrap";
import { Checkbox, CheckboxGroup } from "react-checkbox-group";
import CustomOverlay from "component-library/dropDown/CustomOverlay";
import { getStatusList } from "../../../actions/systemlevel/thirdpartypayee/AdvanceFilterActions";
import classnames from "classnames/bind";
const ALL_STATUS = 0;
const STATUS_PANEL_ID = 3;

let isAllUncheckedZero = false;
const activeStatusString = "Third-party payee can be assigned to a deduction.";
const inactiveStatusString =
  "Third-party payee has been previously assigned to a deduction but is currently not active. Third-party payee cannot be assigned to a deduction unless made active.";

class ThirdPartyPayeeStatusDropdown extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      selectedStatus: props.selectedStatus,
      selectStatusLabel: "Status",
      isAllSelected: false
    };

    this.onCloseStatusDropdown = this.onCloseStatusDropdown.bind(this);
    this.checkSelectStatus = this.checkSelectStatus.bind(this);
    this.renderStatusSelectionList = this.renderStatusSelectionList.bind(this);
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  checkSelectStatus(newStatus) {
    // let statusLabel = (newStatus.length > 1 ? 'Statuses' : 'Status');
    let statusLabel =
      newStatus.length > 1
        ? "Status"
        : newStatus.length === 0
        ? "Select Status"
        : "Status";

    if (newStatus.includes(ALL_STATUS)) {
      let indexOfAllStatus = newStatus.indexOf(ALL_STATUS);

      if (indexOfAllStatus !== 0 || this.state.selectedStatus.length === 0) {
        newStatus = [];
        for (let i = 0; i < this.props.thirdPartyPayeeStatusList.length; i++) {
          let Id = this.props.thirdPartyPayeeStatusList[i].Id;
          newStatus.push(Id);
        }
        this.setState({
          selectedStatus: newStatus,
          selectStatusLabel: "All Statuses",
          isAllSelected: true
        });
      } else {
        newStatus.splice(0, 1);
        this.setState({
          selectedStatus: newStatus,
          selectStatusLabel: statusLabel,
          isAllSelected: false
        });
      }
    } else if (this.state.isAllSelected && !newStatus.includes(0)) {
      this.setState({
        selectedStatus: [],
        selectStatusLabel: "Select Status",
        isAllSelected: false
      });
    } else {
      if (
        this.props.thirdPartyPayeeStatusList.length - 1 === newStatus.length &&
        !newStatus.includes(ALL_STATUS)
      ) {
        newStatus = [];
        for (let i = 0; i < this.props.thirdPartyPayeeStatusList.length; i++) {
          let Id = this.props.thirdPartyPayeeStatusList[i].Id;
          newStatus.push(Id);
        }
        this.setState({
          selectedStatus: newStatus,
          selectStatusLabel: "All Statuses",
          isAllSelected: true
        });
      } else {
        this.setState({
          selectedStatus: newStatus,
          selectStatusLabel: statusLabel,
          isAllSelected: false
        });
      }
    }

    if (
      newStatus.length === 0 ||
      (this.state.isAllSelected === true &&
        newStatus.includes(ALL_STATUS) === false &&
        newStatus.indexOf(ALL_STATUS) === -1 &&
        newStatus.length === this.props.thirdPartyPayeeStatusList.length - 1)
    ) {
      isAllUncheckedZero = true;
    } else {
      isAllUncheckedZero = false;
    }
  }

  componentDidMount() {
    let selectedStatus = [];
    this.props.isInitialLoad(false);
    if (
      this.props.systemLevelTPPSelectedFiltersList !== undefined &&
      this.props.systemLevelTPPSelectedFiltersList !== null &&
      this.props.systemLevelTPPSelectedFiltersList.length !== 0
    ) {
      if (
        this.props.systemLevelTPPSelectedFiltersList.selectedStatus.length > 0
      ) {
        this.props.systemLevelTPPSelectedFiltersList.selectedStatus.forEach(
          selectedItem => {
            selectedStatus.push(selectedItem);
          }
        );
      }
    } else {
      if (
        this.props.thirdPartyPayeeStatusList !== undefined &&
        this.props.thirdPartyPayeeStatusList !== null &&
        this.props.thirdPartyPayeeStatusList.length > 1
      ) {
        selectedStatus.push(this.props.thirdPartyPayeeStatusList[1].Id);
      }
    }

    let selectStatusLabel = "Status";
    let isAllSelected = false;

    if (this.props.thirdPartyPayeeStatusList.length === selectedStatus.length) {
      selectStatusLabel = "All Statuses";
      isAllSelected = true;
    } else if (selectedStatus.length === 0) {
      selectStatusLabel = "Select Status";
      isAllSelected = false;
    } else if (selectedStatus.length === 1) {
      selectStatusLabel = "Status";
      isAllSelected = false;
    } else {
      selectStatusLabel = "Status";
      isAllSelected = false;
    }

    this.setState({
      selectedStatus,
      selectStatusLabel,
      isAllSelected
    });

    this.props.onSelectedStatus(selectedStatus);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.thirdPartyPayeeStatusOriginalList !==
      this.props.thirdPartyPayeeStatusOriginalList
    ) {
      let selectedStatus = [];

      if (nextProps.thirdPartyPayeeStatusOriginalList.Data) {
        selectedStatus.push(
          nextProps.thirdPartyPayeeStatusOriginalList.Data[0].Id
        );
      }

      this.setState({
        selectedStatus,
        selectStatusLabel: "Status",
        isAllSelected: false
      });
    }

    if (nextProps.isResetToDefault !== this.props.isResetToDefault) {
      if (nextProps.isResetToDefault) {
        let selectedStatus = [];

        if (nextProps.thirdPartyPayeeStatusOriginalList.Data !== undefined) {
          selectedStatus.push(
            this.props.thirdPartyPayeeStatusOriginalList.Data[0].Id
          );
        }

        isAllUncheckedZero = false;

        this.setState({
          selectedStatus,
          selectStatusLabel: "Status",
          isAllSelected: false
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedStatus !== this.state.selectedStatus) {
      this.props.onSelectedStatus(this.state.selectedStatus);
    }
  }

  onCloseStatusDropdown() {
    this.props.onSelectedStatus(this.state.selectedStatus);
  }

  renderStatusSelectionList() {
    const { id, thirdPartyPayeeStatusList } = this.props;
    if (!thirdPartyPayeeStatusList) {
      return <div />;
    } else {
      return (
        <CustomOverlay>
          <Scrollbars
            autoHeight={true}
            autoHeightMax={320}
            renderThumbHorizontal={props => (
              <div
                style={{ overflowX: "hidden" }}
                className="thumb-horizontal"
              />
            )}
          >
            <CheckboxGroup
              name="select-status"
              value={this.state.selectedStatus}
              onChange={this.checkSelectStatus}
            >
              <ul
                id={id + "-options-list"}
                className="nc-dropdown-navigation-multiselect"
              >
                {this.renderStatusSelectionItem(
                  this.props.thirdPartyPayeeStatusList
                )}
              </ul>
            </CheckboxGroup>
          </Scrollbars>
        </CustomOverlay>
      );
    }
  }

  renderStatusSelectionListMobile() {
    const { id, thirdPartyPayeeStatusList } = this.props;
    if (!thirdPartyPayeeStatusList) {
      return <div />;
    } else {
      return (
        <Scrollbars
          autoHeight={true}
          autoHeightMax={320}
          renderThumbHorizontal={props => (
            <div style={{ overflowX: "hidden" }} className="thumb-horizontal" />
          )}
        >
          <CheckboxGroup
            name="select-status"
            value={this.state.selectedStatus}
            onChange={this.checkSelectStatus}
          >
            <ul
              id={id + "-options-list"}
              className="nc-dropdown-navigation-multiselect"
            >
              {this.renderStatusSelectionItem(thirdPartyPayeeStatusList)}
            </ul>
          </CheckboxGroup>
        </Scrollbars>
      );
    }
  }

  renderStatusSelectionItem(list) {
    const { id } = this.props;
    return list.map(item => {
      return (
        <li key={item.Name + item.Id} className="list-item">
          <Checkbox id={id + "-list-item-" + item.Id} value={item.Id} />
          <label
            htmlFor={id + "-list-item-" + item.Id}
            id={id + "-list-item-" + item.Id + "-label"}
          >
            {item.Name}
          </label>
          <div
            className="hidden-lg hidden-md tooltip-mobile-filter"
            style={{ wordBreak: "break-word" }}
          >
            {item.Name === "Active" && activeStatusString}
            {item.Name === "Inactive" && inactiveStatusString}
          </div>
        </li>
      );
    });
  }

  openSubPanelById(id) {
    let update = {};

    if (id === this.state.subPanelId) {
      update["subPanelId"] = 0;
    } else {
      update["subPanelId"] = id;
    }

    this.setState(update);
  }

  render() {
    const { id } = this.props;
    const statusSelectionPanel = this.renderStatusSelectionList();
    if (!this.props.thirdPartyPayeeStatusList) {
      return <div>No Records Found.</div>;
    }
    if (window.innerWidth <= 991) {
      return (
        <div>
          <div className="mobile-filter-panel-list-section">
            <a
              id=""
              onClick={this.openSubPanelById.bind(this, STATUS_PANEL_ID)}
            >
              <div className="mobile-filter-panel-list-panel-bar col-xs-12 col-sm-12">
                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-icon">
                  {this.state.subPanelId === STATUS_PANEL_ID ? (
                    <i
                      className="fa fa-chevron-up add_deduction_popup_panel_link"
                      aria-hidden="true"
                    />
                  ) : (
                    <i
                      className="fa fa-chevron-down add_deduction_popup_panel_link"
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="col-xs-8 col-sm-8 mobile-filter-panel-list-label no-padding">
                  {this.state.selectStatusLabel}
                </div>
                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-badge no-padding">
                  {isAllUncheckedZero ? null : this.state
                      .isAllSelected ? null : this.state.selectedStatus !==
                    undefined ? (
                    <Badge className="filter-select-badge">
                      {this.state.selectedStatus.length}
                    </Badge>
                  ) : null}
                </div>
              </div>
            </a>

            <div className="mobile-filter-panel-list-panel-bar-section">
              <Panel
                className="mobile-filter-panel-list-panel-default no-padding col-xs-12 col-sm-12 col-md-12 col-lg-12 "
                collapsible
                expanded={
                  this.state.subPanelId === STATUS_PANEL_ID ? true : false
                }
              >
                <Panel.Collapse>
                  <Panel.Body>
                    {this.renderStatusSelectionListMobile()}
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="nc-select-container hidden-sm hidden-xs">
            <Button
              id={id + "-button"}
              className="filter-select-button"
              onClick={this.toggle}
              ref={button => (this.statusFilter = button)}
            >
              <span className="selected-value">
                {this.state.selectStatusLabel}
              </span>

              <i
                className={classnames(
                  "pull-right",
                  "fa",
                  this.state.popoverOpen ? "fa-chevron-up" : "fa-chevron-down"
                )}
              />

              {isAllUncheckedZero ? null : this.state
                  .isAllSelected ? null : this.state.selectedStatus !==
                undefined ? (
                <Badge
                  id={id + "-count-badge"}
                  className="filter-select-badge pull-right"
                >
                  {this.state.selectedStatus.length}
                </Badge>
              ) : null}
            </Button>
          </div>

          <Overlay
            show={this.state.popoverOpen}
            onHide={() => this.setState({ popoverOpen: false })}
            placement="bottom"
            container={this}
            rootClose={true}
            target={this.statusFilter}
          >
            {statusSelectionPanel}
          </Overlay>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  let list = [
    {
      Id: 0,
      Name: "All Statuses"
    }
  ];

  if (state.thirdPartyPayeeStatusList.Data) {
    state.thirdPartyPayeeStatusList.Data.forEach(item => {
      list.push(item);
    });
  }
  return {
    thirdPartyPayeeStatusList: list,
    thirdPartyPayeeStatusOriginalList: state.thirdPartyPayeeStatusList,
    systemLevelTPPSelectedFiltersList: state.systemLevelTPPSelectedFiltersList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getStatusList }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThirdPartyPayeeStatusDropdown);
