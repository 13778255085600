import React, { Component } from "react";
import { DragDropContext, DragDropContextProvider } from "react-dnd";
import HTML5Backend, { NativeTypes } from "react-dnd-html5-backend";
import TargetBox from "./DropTarget";
import { ProgressBar } from "react-bootstrap";
import Cropper from "./Cropper";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      droppedFiles: [],
      crop: {
        x: 20,
        y: 10,
        width: 30,
        height: 10,
        aspect: 1 / 1
      },
      imageLoading: true,
      src: "",
      displaySpinner: false
    };
  }

  render() {
    const { FILE } = NativeTypes;
    const {
      handleFileDrop,
      handleFileUpload,
      src,
      imageLoading,
      droppedFiles,
      imgWidth,
      imgHeight,
      onChange,
      Spinner,
      imgType = "image/png"
    } = this.props;

    if (droppedFiles.length > 0 || src !== "") {
      if (imageLoading) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: "300px",
              border: "1px dashed #ccc"
            }}
          >
            <div
              style={{ fontSize: "16px", marginBottom: "10px" }}
              className="progress-label"
            >
              Uploading...
            </div>
            <div className="nc-progress-bar">
              <ProgressBar
                bsStyle="success"
                bsClass="nc-progress progress-bar"
                now={40}
              />
              <div className="progress-percentage">40% Completed</div>
            </div>
          </div>
        );
      } else {
        return (
          <div
            id="profile-pic-cropper-container"
            className="profile-pic-cropper"
            style={{
              backgroundColor: `${this.state.displaySpinner ? "#fff" : "#ccc"}`
            }}
          >
            <Cropper
              ref={cropper => this.props.setRef(cropper)}
              src={src}
              crop={this.props.onSetClick}
              originX={50}
              onImgLoad={this.props.onImageLoaded}
              imgWidth={imgWidth}
              imgHeight={imgHeight}
              onChange={onChange}
              Spinner={Spinner}
              isSpinner={displaySpinner => {
                this.setState({ displaySpinner });
              }}
              imgType={imgType}
            />
          </div>
        );
      }
    } else {
      return (
        <DragDropContextProvider backend={HTML5Backend}>
          <div id="profile-pic-drop-container">
            <TargetBox
              accepts={[FILE]}
              onDrop={handleFileDrop}
              onManualUpload={handleFileUpload}
            />
          </div>
        </DragDropContextProvider>
      );
    }
  }
}
export default DragDropContext(HTML5Backend)(Container);

Container.defaultProps = {
  beforeSend: request => request,
  onProgress: (e, request, progress) =>
    console.log("Progress", e, request, progress),
  onLoad: (e, request) => {},
  onError: (e, request) => {},
  onAbort: (e, request) => {}
};
