import { combineReducers } from "redux";
import employeeList from "./employeeList";
import { employeeDashboard, viewSSN } from "./employeeDashboard";
import employeeTreeView from "./employeeTreeView";
import employeeInformation from "./employeeInformation";
import activePanel from "./activePanel";
import advancedFilterFields from "./advancedFilter";
import resumeEntityData from "./resumeEntityDataReducer";
import employeeMenu from "./employeeMenu";

const people = combineReducers({
  employeeList,
  viewSSN,
  employeeDashboard,
  employeeTreeView,
  employeeInformation,
  activePanel,
  advancedFilterFields,
  resumeEntityData,
  employeeMenu
});

export default people;
