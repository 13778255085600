import React from "react";
import BulkActions from "./BulkActions";
import { ContactApproversActive } from "./../images";
const MultiSectionTableActionLinks = ({
  onSelectAll,
  areAllSelected,
  onActionClick,
  UserTestId,
  showBulkActions,
  disabled,
  onContactAllClick
}) => (
  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 action-link-container">
    <div className="col-lg-8 col-md-8 col-sm-6 col-xs-8">
      <span style={{ paddingRight: "20px" }}>
        <input
          type="checkbox"
          id="select-all-payroll-item"
          checked={
            areAllSelected === undefined
              ? (areAllSelected = false)
              : areAllSelected === true || false
                ? areAllSelected
                : (areAllSelected = false)
          }
          readOnly
        />
        <label
          htmlFor="select-all-payroll-item"
          id="select=all-label"
          className="payroll-table-header-actions"
          onClick={() => onSelectAll(!areAllSelected)}
        >
          Select All
        </label>
      </span>
      <span className="bulk-actions-container">
        {showBulkActions ? (
          <BulkActions
            onActionClick={onActionClick}
            UserTestId={UserTestId}
            disabled={disabled}
            type="unapprovedPto"
          />
        ) : null}
      </span>
    </div>
    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-4 contact-all-approvers">
      <span
        className="primary-link-button payroll-table-header-actions"
        style={{
          float: "right",
          display: "flex",
          alignItems: "center"
        }}
        onClick={onContactAllClick}
      >
        <ContactApproversActive
          style={{ marginRight: "5px" }}
          width="22"
          height="22"
        />
        Contact All{" "}
        <span className="hidden-xs" style={{ textTransform: "none" }}>
          &nbsp;Approvers
        </span>
      </span>
    </div>
  </div>
);

export default MultiSectionTableActionLinks;
