import {
  setFilterOptions,
  selectAllFilterOptions,
  selectFilterOption
} from "app/common/reducers/filters";
import {
  getPrimaryManager,
  getSecondaryManager,
  getEmployeeClassification,
  getEmployeeType,
  getEmployeePosition,
  getEmployeePayrollSchedule,
  getEmployeePayrollFrequency,
  getEmployeeTimeGroup,
  loadMorePosts,
  getEmployeeGender,
  searchPosts,
  fetchEmployeeDashboardData,
  getCompanyList,
  getEmployeeList,
  getDivisionList,
  getBusinessUnitList,
  getDepartmentList,
  getLocationList,
  getClientList,
  getProjectList,
  getStatusList,
  menuSearchPosts
} from ".";
import { setLoader } from "app/actions/LoaderActions";
import * as Filters from "component-library/employee-profile/list-view/advance-filter/FieldNames";
import {
  setEmployeeListLoading,
  addToEmployeeList,
  clearEmployeeList
} from "../reducers/employeeList";
import {
  addToEmployeeTreeView,
  clearEmployeeTreeView
} from "../reducers/employeeTreeView";
import {
  setEmployeeDashboardData,
  clearEmployeeDashboardData,
  setViewSSN
} from "../reducers/employeeDashboard";
import {
  setSideBarAndActivePanelId,
  clearSideBarAndActivePanelId
} from "../reducers/activePanel";
import { addLoogedUSer } from "app/common/reducers/loggedUser";
import { addToEmployeeMenu, clearEmployeeMenu } from "../reducers/employeeMenu";

const defaultSearchParams = {
  Fields: ["*"],
  Criterion: [],
  Sort: [],
  PageSize: 5
};
/** Actions for Advanced Filters */
export const fetchAndSetPrimaryManager = (
  name = Filters.primaryManager,
  isSelectAll
) => dispatch =>
  getPrimaryManager().then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      id: item.UserId,
      value: item.UserId,
      label: item.DisplayName
    }));
    dispatch(setFilterOptions(name, dispatchData));
  });

export const fetchAndSetSecondaryManager = (
  name = Filters.secondaryManager,
  isSelectAll
) => dispatch =>
  getSecondaryManager().then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      id: item.UserId,
      value: item.UserId,
      label: item.DisplayName
    }));
    dispatch(setFilterOptions(name, dispatchData));
    if (isSelectAll) {
      dispatch(selectAllFilterOptions(name, true));
    }
  });

export const fetchAndSetEmployeeType = (
  name = Filters.employeeType,
  isSelectAll
) => dispatch =>
  getEmployeeType().then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      id: item.Id,
      value: item.Id,
      label: item.Name
    }));
    dispatch(setFilterOptions(name, dispatchData));
    if (isSelectAll) {
      dispatch(selectAllFilterOptions(name, true));
    }
  });

export const fetchAndSetEmployeeClassification = (
  name = Filters.employeeClassification,
  isSelectAll
) => dispatch =>
  getEmployeeClassification().then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      id: item.Id,
      value: item.Id,
      label: item.Name
    }));
    dispatch(setFilterOptions(name, dispatchData));
    if (isSelectAll) {
      dispatch(selectAllFilterOptions(name, true));
    }
  });
export const fetchAndSetPosition = (
  name = Filters.employeePosition,
  isSelectAll
) => dispatch =>
  getEmployeePosition().then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      id: item.Id,
      value: item.Id,
      label: item.Name
    }));
    dispatch(setFilterOptions(name, dispatchData));
    if (isSelectAll) {
      dispatch(selectAllFilterOptions(name, true));
    }
  });

export const fetchAndSetPayrollSchedule = (
  name = Filters.payrollSchedule,
  isSelectAll
) => dispatch =>
  getEmployeePayrollSchedule().then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      id: item.PayrollScheduleId,
      value: item.PayrollScheduleId,
      label: item.PayrollScheduleName
    }));
    dispatch(setFilterOptions(name, dispatchData));
    if (isSelectAll) {
      dispatch(selectAllFilterOptions(name, true));
    }
  });

export const fetchAndSetPayrollFrequency = (
  name = Filters.payrollFrequency,
  isSelectAll
) => dispatch =>
  getEmployeePayrollFrequency().then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      id: item.PayrollFrequencyId,
      value: item.PayrollFrequencyId,
      label: item.Name
    }));
    dispatch(setFilterOptions(name, dispatchData));
    if (isSelectAll) {
      dispatch(selectAllFilterOptions(name, true));
    }
  });
export const fetchAndSetTimeGroup = (
  name = Filters.timeGroup,
  isSelectAll
) => dispatch => {
  getEmployeeTimeGroup().then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      id: item.Id,
      value: item.Id,
      label: item.Name
    }));
    dispatch(setFilterOptions(name, dispatchData));
    if (isSelectAll) {
      dispatch(selectAllFilterOptions(name, true));
    }
  });
};

export const fetchAndSetGender = (
  name = Filters.gender,
  isSelectAll
) => dispatch => {
  getEmployeeGender().then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      id: item.Id,
      value: item.Id,
      label: item.Name
    }));
    dispatch(setFilterOptions(name, dispatchData));
    if (isSelectAll) {
      dispatch(selectAllFilterOptions(name, true));
    }
  });
};

export const fetchAndSetCompanyList = (
  selectAll = false,
  currentCompanyId,
  name = Filters.employeeCompany
) => dispatch => {
  getCompanyList().then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data[0].CompanySelectionList.map(item => ({
      id: item.SubscriberCompanyId,
      value: item.SubscriberCompanyId,
      label: item.SubscriberCompanyName
    }));
    dispatch(setFilterOptions(name, dispatchData));
    if (selectAll) {
      dispatch(selectFilterOption(name, currentCompanyId, true));
    }
  });
};
export const getStatusIcon = id => {
  switch (id) {
    case 2:
    case 6:
    case 7:
      return "fa-square terminated-red-status";
    case 1:
      return "fa-square active-green-status";
    case 3:
    case 4:
      return "fa-square inactive-grey-status";
    case 5:
      return "fa-square inprogress-yellow-status";
    default:
      return "fa-square";
  }
};
export const fetchAndSetStatusList = (
  selectAll = false,
  name = Filters.employeeStatus
) => dispatch => {
  getStatusList().then(res => {
    const {
      data: { Data = [] }
    } = res;
    let dispatchData = [];
    Data.forEach(item =>
      dispatchData.push({
        id: item.Id,
        value: item.Id,
        label: item.Name,
        statusIcon: getStatusIcon(item.Id)
      })
    );
    dispatch(setFilterOptions(name, dispatchData));
    if (selectAll) {
      dispatch(selectFilterOption(name, 1, true));
      dispatch(selectFilterOption(name, 2, false));
      dispatch(selectFilterOption(name, 3, false));
      dispatch(selectFilterOption(name, 4, true));
      dispatch(selectFilterOption(name, 5, false));
      dispatch(selectFilterOption(name, 6, false));
      dispatch(selectFilterOption(name, 7, false));
      dispatch(selectFilterOption(name, 8, false));
    }
  });
};

export const fetchAndSetEmployeeDivision = companyId => dispatch =>
  getDivisionList(companyId).then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.forEach(item => ({
      id: item.Id,
      value: item.Id,
      label: item.Name
    }));
    dispatch(setFilterOptions(Filters.employeeDivision, dispatchData || []));
  });
export const fetchAndSetEmployeeBusinessUnit = (
  companyId,
  divisionId
) => dispatch =>
  getBusinessUnitList(companyId, divisionId).then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.forEach(item => ({
      id: item.Id,
      value: item.Id,
      label: item.Name
    }));
    dispatch(setFilterOptions(Filters.businessUnit, dispatchData || []));
  });
export const fetchAndSetEmployeeDepartment = (
  companyId,
  divisionId,
  businessUnitId
) => dispatch =>
  getDepartmentList(companyId, divisionId, businessUnitId).then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.forEach(item => ({
      id: item.Id,
      value: item.Id,
      label: item.Name
    }));
    dispatch(setFilterOptions(Filters.employeeDepartment, dispatchData || []));
  });

export const fetchAndSetEmployeeLocation = companyId => dispatch =>
  getLocationList(companyId).then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      id: item.Id,
      value: item.Id,
      label: item.Name
    }));
    dispatch(setFilterOptions(Filters.employeeLocation, dispatchData || []));
  });

export const fetchAndSetEmployeeClient = companyId => dispatch =>
  getClientList(companyId).then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.forEach(item => ({
      id: item.Id,
      value: item.Id,
      label: item.Name
    }));
    dispatch(setFilterOptions(Filters.employeeClient, dispatchData || []));
  });

export const fetchAndSetEmployeeProject = (companyId, clientId) => dispatch =>
  getProjectList(companyId, clientId).then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.forEach(item => ({
      id: item.Id,
      value: item.Id,
      label: item.Name
    }));
    dispatch(setFilterOptions(Filters.employeeProject, dispatchData || []));
  });

export const loadAndSetMorePosts = (nextUri, callback) => dispatch => {
  dispatch(setEmployeeListLoading(true));
  loadMorePosts(nextUri).then(res => {
    const { Data, DataCount, NextUri } = res.data;
    dispatch(addToEmployeeList(Data, DataCount, NextUri));
    callback();
  });
};

export const searchAndSetPosts = (
  params = defaultSearchParams,
  filters = {},
  callback = () => {}
) => dispatch => {
  dispatch(clearEmployeeList());
  return searchPosts(params).then(res => {
    const { Data, DataCount, NextUri } = res.data;
    dispatch(addToEmployeeList(Data === null ? [] : Data, DataCount, NextUri));
    dispatch(setLoader(false));
    callback();
  });
};

export const fetchAndSetLoggedUser = userId => dispatch => {
  // Todo: will get changes once service end point is completed for Logged user security access
  if (userId === "1") {
    const Data = {
      userId: 1,
      allowedToShowSSN: true
    };
    const DataCount = 1;
    dispatch(addLoogedUSer(Data === null ? [] : Data, DataCount));
    dispatch(setLoader(false));
  }

  // return setLoggedUser(userId).then(res => {
  //   const { Data, DataCount } = res.data;
  //   dispatch(addLoogedUSer(Data === null ? [] : Data, DataCount));
  //   dispatch(setLoader(false));
  //   })
};

export const auditViewSsnClick = showSSN => dispatch => {
  if (showSSN) {
    // return auditViewSsn().then(res => {
    //   if (res.data.Data) {
    //      dispatch(setViewSSN(true));
    //   }
    // });
    dispatch(setViewSSN(true));
  } else {
    dispatch(setViewSSN(false));
  }
};

export const fetchAndSetEmployeeProfileData = id => dispatch => {
  dispatch(clearEmployeeDashboardData());
  return fetchEmployeeDashboardData(id).then(res => {
    const { Data, DataCount } = res.data;
    dispatch(setEmployeeDashboardData(Data === null ? [] : Data[0], DataCount));
    dispatch(setLoader(false));
  });
};

export const setEmployeeProfilePicture = data => dispatch => {
  dispatch(setLoader(false));
  //TODO: Integrated with services.
  // return saveAndSetEmployeeProfilePicture(data).then(res => {
  //   if (res.data.ErrorCount === 0) {
  //     dispatch(setLoader(false));
  //   }
  // });
};

export const fetchAndSetEmployeeFilteredList = (
  selectAll = false,
  params = defaultSearchParams,
  filters = {},
  name = Filters.basicFilterEmployeelist
) => dispatch => {
  return getEmployeeList().then(res => {
    const { Data } = res.data;
    const dispatchData = Data.map(item => ({
      id: item.UserId,
      value: item.UserId,
      label: item.DisplayName
    }));
    dispatch(setFilterOptions(name, dispatchData));
    if (selectAll) {
      dispatch(selectAllFilterOptions(name, true));
    }
  });
};

export const fetchAndSetEmployeeTreeViewData = (
  params = defaultSearchParams,
  filters = {},
  callback = () => {}
) => dispatch => {
  dispatch(clearEmployeeTreeView());
  return searchPosts(params).then(res => {
    const { Data, DataCount, NextUri } = res.data;
    dispatch(
      addToEmployeeTreeView(Data === null ? [] : Data, DataCount, NextUri)
    );
    dispatch(setLoader(false));
    callback();
  });
};

export const setSideBarIdAndActivePanelId = (
  sideBarId,
  activePanelId
) => dispatch => {
  dispatch(clearSideBarAndActivePanelId());
  dispatch(setSideBarAndActivePanelId(sideBarId, activePanelId));
};

export const onApplyAdvanceFilter = (
  params = defaultSearchParams,
  callback = () => {}
) => dispatch => {
  dispatch(clearEmployeeList());
  return searchPosts(params).then(res => {
    const { Data, DataCount, NextUri } = res.data;
    dispatch(addToEmployeeList(Data === null ? [] : Data, DataCount, NextUri));
    dispatch(setLoader(false));
    callback(Data === null ? [] : Data[0]);
  });
};

export const clearAdvancedFilters = filters => dispatch => {
  if (Object.keys(filters).length > 0) {
    Object.keys(filters).forEach(key => {
      if (
        key !== Filters.employeeCompany &&
        key !== Filters.employeeStatus &&
        key !== Filters.basicFilterEmployeelist
      ) {
        dispatch(selectAllFilterOptions(key, false));
      }
    });
  }
};

export const clearAdvancedFiltersAndNavigate = (
  filters,
  searchParams = defaultSearchParams,
  callback = () => {}
) => dispatch => {
  dispatch(clearAdvancedFilters(filters));
  dispatch(clearEmployeeList());
  return searchPosts(searchParams).then(res => {
    const { Data, DataCount, NextUri } = res.data;
    dispatch(addToEmployeeList(Data === null ? [] : Data, DataCount, NextUri));
    dispatch(setLoader(false));
    callback(Data === null ? [] : Data[0]);
  });
};

export const searchAndSetPostsMenu = (
  params = defaultSearchParams,
  callback = () => {}
) => dispatch => {
  dispatch(clearEmployeeMenu());
  return menuSearchPosts(params).then(res => {
    const { Data, DataCount, NextUri } = res.data;
    dispatch(addToEmployeeMenu(Data === null ? [] : Data, DataCount, NextUri));
    dispatch(setLoader(false));
    callback();
  });
};
