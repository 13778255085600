import React, { Component } from "react";
import { ContactApproversActive } from "./../images";
import { OverlayTrigger, Popover } from "react-bootstrap";
import "./BulkActions.css";

export default class BulkActions extends Component {
  state = { showOptions: false };
  onClickBulkAction = type => {
    this.props.onActionClick(this.props.UserTestId);
  };
  toggleBulkActions = () => {
    this.setState({
      showOptions: !this.state.showOptions
    });
  };
  showPtoApproveActions = () => {
    return (
      <Popover
        id="popover-positioned-bottom"
        bsClass="approve-actions-popover popover"
        //positionLeft="10"
      >
        <div className="bulk-actions-list">
          <div
            className="bulk-item"
            onClick={() => this.onClickBulkAction("pto")}
          >
            <i className="fa fa-check nc-green" />Approve Timecards
          </div>
          <div className="bulk-item" onClick={() => {}}>
            <i className="fa fa-ban nc-red" />Edit Timecards
          </div>
          <div className="bulk-item" onClick={() => {}}>
            <ContactApproversActive
              style={{ paddingRight: "5px" }}
              width="18"
              height="18"
            />Contact Approvers
          </div>
        </div>
      </Popover>
    );
  };
  showTimecardActions = () => {
    return (
      <Popover
        id="popover-positioned-bottom"
        bsClass="approve-actions-popover popover"
      >
        <div className="bulk-actions-list">
          <div
            className="bulk-item"
            onClick={() => this.onClickBulkAction("pto")}
          >
            <i className="fa fa-check nc-green" />Approve PTO
          </div>
          <div className="bulk-item" onClick={() => {}}>
            <i className="fa fa-ban nc-red" />Decline PTO
          </div>
          <div className="bulk-item" onClick={() => {}}>
            <ContactApproversActive
              style={{ paddingRight: "5px" }}
              width="18"
              height="18"
            />Contact Approvers
          </div>
        </div>
      </Popover>
    );
  };
  getPopover = type => {
    if (type === "unapprovedPto") {
      return this.showPtoApproveActions();
    } else if (type === "timecardAlerts") {
      return this.showTimecardActions();
    } else {
      return null;
    }
  };
  render() {
    return (
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={this.getPopover(this.props.type)}
        positionLeft="10"
      >
        <span
          className="primary-link-button payroll-table-header-actions"
          onClick={!this.props.disabled ? this.toggleBulkActions : () => {}}
          style={{ color: this.state.showOptions ? "#0c4f48" : "#009999" }}
        >
          Bulk Actions{" "}
          <i
            className={
              this.state.showOptions ? "fa fa-caret-up" : "fa fa-caret-down"
            }
            aria-hidden="true"
          />
        </span>
      </OverlayTrigger>
    );
  }
}
