export const Fields = {
  //Holiday Calendar Add Page

  id: "Id",
  holidayCalendarId: "HolidayCalendarId",
  name: "Name",
  holidayCalendarName: "HolidayCalendarName",
  holidayInstanceList: "HolidayInstanceList",
  holidayDefinition: "HolidayDefinition",
  holidayDefinitionList: "HolidayDefinitionList",
  holidayId: "HolidayId",
  startDate: "StartDate",
  endDate: "EndDate",
  holidayName: "HolidayDefinitionName",
  isPayrollInProgress: "IsPayrollInProgress",
  workHours: "WorkHoursPerDayForAutoPayEmployees",
  isSourceHolidayCalendarSystemCalendar:
    "IsSourceHolidayCalendarSystemCalendar",
  holidayGroupId: "HolidayGroupId",
  holidayGroupName: "HolidayGroupName",
  repeatsEvery: "RepeatsEvery",
  beginYear: "BeginYear",
  endYear: "EndYear",
  holidayTypeId: "HolidayTypeId",
  holidayTypeName: "HolidayTypeName",
  sourceHolidayCalendarId: "SourceHolidayCalendarId",
  includedDayOfWeeks: "IncludedDayOfWeeks",
  occuranceNumber: "OccuranceNumber",
  occuranceDayOfWeek: "OccuranceDayOfWeek",
  occuranceMonth: "OccuranceMonth",
  occuranceDay: "OccuranceDay",
  occuranceYear: "OccuranceYear",
  isObservedHoliday: "IsObservedHoliday",
  useCount: "UseCount",

  //On UI
  customHolidayName: "HolidayDefinitionName",
  customHolidayRepeat: "CustomHolidayRepeat",
  hourlyEmployeesPay: "HourlyEmployeesPay",
  holidayStartsMidnight: "HolidayStartsOtherThanMidnight",
  startTime: "StartTime",
  endTime: "EndTime",
  previousDay: "PreviousDay",
  nextDay: "NextDay",
  occuranceMonthFixed: "OccuranceMonthFixed",
  occuranceMonthFloating: "OccuranceMonthFloating",
  commonHolidays: "CommonHolidays",
  customHolidayData: "CustomHolidayData",
  holidayDate: "HolidayDate",
  startDayTime: "StartDayTime",
  endDayTime: "EndDayTime",
  startDaySelect: "StartDaySelect",
  endDaySelect: "EndDaySelect",
  weekDaySun: "7",
  weekDayMon: "1",
  weekDayTue: "2",
  weekDayWed: "3",
  weekDayThu: "4",
  weekDayFri: "5",
  weekDaySat: "6",
  dateSectionData: "DateSectionData",
  inputName: "inputName",
  common: "999"
};
