import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import { TabContainer } from "component-library/employee-profile/entity-view";
import {
  EmploymentHistory,
  LicensesCertification,
  Education,
  Skills
} from "../containers";
import ResumeList from "../components/ResumeList";
import * as util from "app/common/utilities";
import {
  saveEmploymentHistoryRecordAndReload,
  saveLicensCertificationRecordAndReload,
  saveSkillsRecordAndReload,
  saveEducationRecordAndReload,
  updateEmploymentHistoryRecordAndReload,
  updateLicensCertificationRecordAndReload,
  updateSkillsRecordAndReload,
  updateEducationRecordAndReload,
  deleteLicensesRecordAndReload,
  deleteEducationRecordAndReload,
  deleteSkillRecordAndReload,
  deleteEmploymentHistoryRecordAndReload,
  fetchAndSetStates,
  fetchAndSetCountry,
  fetchAndSetResumeEntityData,
  fetchAndSetEmploymentHistory,
  fetchAndSetLicensesCertification,
  fetchAndSetEducation,
  fetchAndSetSkills,
  fetchAndSetEmploymentHistoryCompanyList,
  fetchAndSetEmploymentHistoryPositionList,
  fetchAndSetLicensesCerificationNameList,
  fetchAndSetLicensesCerificationTypeList,
  fetchAndSetEducationDegreeList,
  fetchAndSetEducationSchoolList,
  fetchAndSetSkillsSkillList,
  fetchAndSetSkillsSkillLevelList,
  fetchAndSetSkillsYearOfExperienceList,
  setTypeNameDropdown
} from "../network/actions";
import { makeCriterion } from "../../base/utils";
import { makeSortCriterion } from "app/common/reducers/filters";
import * as FIELDS from "component-library/employee-profile/resume/utils/Constants";
import { setSortOrder, clearSortOrder } from "app/common/reducers/sortOrder";
import EmployeeProfileModal from "component-library/employee-profile/EmployeeProfileModal";

const getSearchParams = state => {
  const { filters = {} } = state.common;
  const defaultSearchParams = {
    Fields: ["*"],
    Criterion: [],
    Sort: [],
    PageSize: 5
  };

  let Criterion = [];
  Criterion.push(
    makeCriterion(
      FIELDS.EmploymentHistoryCompanyFilter,
      filters[FIELDS.EmploymentHistoryCompanyFilter]
    )
  );
  Criterion.push(
    makeCriterion(
      FIELDS.EmploymentHistoryPositionFilter,
      filters[FIELDS.EmploymentHistoryPositionFilter]
    )
  );
  Criterion.push(
    makeCriterion(
      FIELDS.LicensesCertificationNameFilter,
      filters[FIELDS.LicensesCertificationNameFilter]
    )
  );
  Criterion.push(
    makeCriterion(
      FIELDS.LicensesCertificationTypeFilter,
      filters[FIELDS.LicensesCertificationTypeFilter]
    )
  );
  Criterion.push(
    makeCriterion(
      FIELDS.EducationDegreeFilter,
      filters[FIELDS.EducationDegreeFilter]
    )
  );
  Criterion.push(
    makeCriterion(
      FIELDS.EducationSchoolFilter,
      filters[FIELDS.EducationSchoolFilter]
    )
  );
  Criterion.push(
    makeCriterion(FIELDS.SkillsSkillFilter, filters[FIELDS.SkillsSkillFilter])
  );
  Criterion.push(
    makeCriterion(
      FIELDS.SkillsSkillLevelFilter,
      filters[FIELDS.SkillsSkillLevelFilter]
    )
  );
  Criterion.push(
    makeCriterion(
      FIELDS.SkillsYearOfExperienceFilter,
      filters[FIELDS.SkillsYearOfExperienceFilter]
    )
  );
  Criterion.push(
    makeCriterion(
      FIELDS.EmploymentHistoryDateFilter,
      filters[FIELDS.EmploymentHistoryDateFilter]
    )
  );
  Criterion.push(
    makeCriterion(
      FIELDS.LicensesCertificationDateFilter,
      filters[FIELDS.LicensesCertificationDateFilter]
    )
  );

  Criterion = Criterion.filter(c => c);

  defaultSearchParams.Criterion = Criterion;

  const sortOrder = state.common.sortOrder;
  let Sort = null;
  Sort = makeSortCriterion(sortOrder);
  defaultSearchParams.Sort = Sort === null ? [] : Sort;

  return defaultSearchParams;
};
class Resume extends Component {
  state = {
    activePanel: {},
    isAddModeOn: false,
    showToaster: false,
    toasterClass: "",
    toasterMessage: "",
    showCloseIcon: true
  };

  componentDidMount() {
    const { ActivePanelId } = this.props.match.params;
    this.searchPosts(
      parseInt(ActivePanelId, 10),
      this.props.employeeProfileData
    );
    const { props } = this;
    props.loadCompanyFilterList(true);
    props.loadPositionFilterList(true);
    props.loadNameFilterList(true);
    props.loadTypeFilterList(true);
    props.loadDegreeFilterList(true);
    props.loadSchoolFilterList(true);
    props.loadSkillFilterList(true);
    props.loadSkillLevelFilterList(true);
    props.loadYearofExperianceFilterList(true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { ActivePanelId } = this.props.match.params;
    const prevActivePanelId = prevProps.match.params.ActivePanelId;
    if (
      (prevActivePanelId !== ActivePanelId ||
        this.props.employeeProfileData !== prevProps.employeeProfileData) &&
      this.props.employeeProfileData.length !== 0
    ) {
      this.searchPosts(
        parseInt(ActivePanelId, 10),
        this.props.employeeProfileData
      );
    }
  }

  searchPosts = (id, employeeProfileData) => {
    const { loadResumeEntityData, searchParams, filters } = this.props;
    this.setState({
      loadingFirstTime: true
    });
    if (!util.isNullOrZero(id)) {
      loadResumeEntityData(id, searchParams, filters, employeeProfileData, () =>
        this.setState({ loadingFirstTime: false })
      );
    }
  };

  toggleAddListSection = () => {
    const { EmployeeId, ActivePanelId } = this.props.match.params;
    this.props.history.push(
      `/people/employees/dashboard/${EmployeeId}/resume/${ActivePanelId}/setup`
    );
    this.setState({
      isAddModeOn: true,
      showToaster: false
    });
  };

  toggleAddOnMode = value => {
    this.setState({
      isAddModeOn: value
    });
  };

  onCancelClick = () => {
    const { EmployeeId, ActivePanelId } = this.props.match.params;
    this.props.history.push(
      `/people/employees/dashboard/${EmployeeId}/resume/${ActivePanelId}`
    );
    this.setState({
      isAddModeOn: false
    });
  };

  onEditClick = id => {
    const { EmployeeId, ActivePanelId } = this.props.match.params;
    this.props.history.push(
      `/people/employees/dashboard/${EmployeeId}/resume/${ActivePanelId}/${id}`
    );
  };

  toggleToaster = () => {
    this.setState({
      showToaster: false
    });
  };

  setToaster = (toasterMessage, toasterClass, showCloseIcon = true) => {
    this.setState({
      toasterClass: toasterClass,
      toasterMessage: toasterMessage,
      showToaster: true,
      showCloseIcon: showCloseIcon
    });
  };

  onDeleteClick = (id, name, sectionId) => {
    let type = "";

    if (sectionId === 5) {
      type = ` Employment History?`;
      this.setState({
        buttonLabel: "DELETE EMPLOYMENT HISTORY"
      });
    } else if (sectionId === 6) {
      type = ` License/Certification ${name}?`;
      this.setState({
        buttonLabel: "DELETE LICENSE/CERTIFICATION"
      });
    } else if (sectionId === 7) {
      type = ` Education?`;
      this.setState({
        buttonLabel: "DELETE EDUCATION"
      });
    } else if (sectionId === 8) {
      type = ` Skill?`;
      this.setState({
        buttonLabel: "DELETE SKILL"
      });
    }

    let modalMessage = "Are you sure you want to delete" + type;
    this.setState({
      showModal: true,
      line1: modalMessage,
      sectionId: sectionId,
      buttonType: "DELETE",
      itemName: name,
      itemId: id
    });
  };

  onClose = () => {
    this.setState({
      showModal: false
    });
  };

  delete = id => {
    const {
      deleteEmploymentHistoryRecord,
      deleteLicensesRecord,
      deleteEducationRecord,
      deleteSkillsRecord,
      employeeName
    } = this.props;
    if (this.state.sectionId === 5) {
      deleteEmploymentHistoryRecord(id => {
        let toasterMessage = `${employeeName}'s Employment History for ${
          this.state.itemName
        } has been successfully deleted.`;
        this.setState({
          toasterClass: "success",
          toasterMessage: toasterMessage,
          showToaster: true
        });
        this.onClose();
      });
    } else if (this.state.sectionId === 6) {
      deleteLicensesRecord(id => {
        let toasterMessage = `${employeeName}'s License/Certification ${
          this.state.itemName
        } has been successfully deleted.`;
        this.setState({
          toasterClass: "success",
          toasterMessage: toasterMessage,
          showToaster: true
        });
        this.onClose();
      });
    } else if (this.state.sectionId === 7) {
      deleteEducationRecord(id => {
        let toasterMessage = `${employeeName}'s Education for ${
          this.state.itemName
        } has been successfully deleted.`;
        this.setState({
          toasterClass: "success",
          toasterMessage: toasterMessage,
          showToaster: true
        });
        this.onClose();
      });
    } else if (this.state.sectionId === 8) {
      deleteSkillsRecord(id => {
        let toasterMessage = `${employeeName}'s Skill ${
          this.state.itemName
        } has been successfully deleted.`;
        this.setState({
          toasterClass: "success",
          toasterMessage: toasterMessage,
          showToaster: true
        });
        this.onClose();
      });
    }
  };

  changeSortOrder = (field, sortOrder) => {
    this.props.setSortOrder(field, sortOrder);
    const { ActivePanelId } = this.props.match.params;
    setTimeout(
      function() {
        this.searchPosts(
          parseInt(ActivePanelId, 10),
          this.props.employeeProfileData
        );
      }.bind(this),
      10
    );
  };

  render() {
    const { ActivePanelId } = this.props.match.params;
    let activePanelId = parseInt(ActivePanelId, 10);
    const { resumeEntityData, userRole } = this.props;
    let entityHeader = "";
    let entityTitle = "";
    if (activePanelId === 5) {
      entityHeader = "EmploymentHistory";
      entityTitle = "Employment History";
    } else if (activePanelId === 6) {
      entityHeader = "Licenses";
      entityTitle = "Licenses & Certification";
    } else if (activePanelId === 7) {
      entityHeader = "Education";
      entityTitle = "Education";
    } else if (activePanelId === 8) {
      entityHeader = "Skills";
      entityTitle = "Skills";
    }
    return (
      <div>
        <TabContainer
          userRole={userRole}
          activePanel={resumeEntityData}
          activePanelHeader={entityTitle}
          onScroll={() => console.log("Scrolled")}
          isAddModeOn={this.state.isAddModeOn}
          showToaster={this.state.showToaster}
          toasterClass={this.state.toasterClass}
          toasterMessage={this.state.toasterMessage}
          toggleToaster={this.toggleToaster}
          showCloseIcon={this.state.showCloseIcon}
        >
          <Switch>
            <Route
              exact
              path="/people/employees/dashboard/:EmployeeId/resume/:ActivePanelId"
              render={() => (
                <ResumeList
                  {...this.props}
                  userRole={userRole}
                  activePanel={resumeEntityData}
                  activePanelId={activePanelId}
                  entityHeader={entityHeader}
                  toggleAddListSection={this.toggleAddListSection}
                  onEditClick={this.onEditClick}
                  onDeleteClick={this.onDeleteClick}
                  setToaster={this.setToaster}
                  toggleToaster={this.toggleToaster}
                  changeSortOrder={this.changeSortOrder}
                />
              )}
            />
            <Route
              path="/people/employees/dashboard/:EmployeeId/resume/5"
              render={() => (
                <EmploymentHistory
                  {...this.props}
                  userRole={userRole}
                  onCancelClick={this.onCancelClick}
                  editMode={false}
                  getFromToValue={this.getFromToValue}
                  setToaster={this.setToaster}
                  toggleToaster={this.toggleToaster}
                  toggleAddOnMode={this.toggleAddOnMode}
                />
              )}
            />

            <Route
              path="/people/employees/dashboard/:EmployeeId/resume/6"
              render={() => (
                <LicensesCertification
                  {...this.props}
                  userRole={userRole}
                  onCancelClick={this.onCancelClick}
                  editMode={false}
                  getFromToValue={this.getFromToValue}
                  setToaster={this.setToaster}
                  toggleToaster={this.toggleToaster}
                  toggleAddOnMode={this.toggleAddOnMode}
                />
              )}
            />

            <Route
              path="/people/employees/dashboard/:EmployeeId/resume/7"
              render={() => (
                <Education
                  {...this.props}
                  userRole={userRole}
                  onCancelClick={this.onCancelClick}
                  editMode={false}
                  getFromToValue={this.getFromToValue}
                  setToaster={this.setToaster}
                  toggleToaster={this.toggleToaster}
                  toggleAddOnMode={this.toggleAddOnMode}
                />
              )}
            />

            <Route
              path="/people/employees/dashboard/:EmployeeId/resume/8"
              render={() => (
                <Skills
                  {...this.props}
                  userRole={userRole}
                  onCancelClick={this.onCancelClick}
                  editMode={false}
                  setToaster={this.setToaster}
                  toggleToaster={this.toggleToaster}
                  toggleAddOnMode={this.toggleAddOnMode}
                />
              )}
            />
          </Switch>
        </TabContainer>

        <EmployeeProfileModal
          show={this.state.showModal}
          line1={this.state.line1}
          onClose={this.onClose}
          onDelete={() => {
            this.delete(this.state.itemId);
          }}
          buttonLabel={this.state.buttonLabel}
          buttonType={this.state.buttonType}
          cancel={true}
        />
      </div>
    );
  }
}

const stateToProps = state => {
  let employeeName = "";
  const { CurrentAppUiViewId } = state.sessionContext;
  const { Data } = state.people.employeeDashboard;
  if (Object.keys(Data).length !== 0) {
    employeeName = Data.Demographics.FirstName;
  }
  return {
    employeeProfileData: state.people.employeeDashboard.Data,
    userRole: parseInt(CurrentAppUiViewId, 10),
    resumeEntityData: state.people.resumeEntityData.Data,
    employeeName: employeeName,
    searchParams: getSearchParams(state)
  };
};
const actionCreators = {
  loadStateList: fetchAndSetStates,
  loadCountryList: fetchAndSetCountry,
  deleteEmploymentHistoryRecord: deleteEmploymentHistoryRecordAndReload,
  deleteLicensesRecord: deleteLicensesRecordAndReload,
  deleteEducationRecord: deleteEducationRecordAndReload,
  deleteSkillsRecord: deleteSkillRecordAndReload,
  loadResumeEntityData: fetchAndSetResumeEntityData,
  fetchAndSetEmploymentHistory,
  fetchAndSetLicensesCertification,
  fetchAndSetEducation,
  fetchAndSetSkills,
  saveEmploymentHistoryRecordAndReload,
  saveLicensCertificationRecordAndReload,
  saveSkillsRecordAndReload,
  saveEducationRecordAndReload,
  updateEmploymentHistoryRecordAndReload,
  updateLicensCertificationRecordAndReload,
  updateSkillsRecordAndReload,
  updateEducationRecordAndReload,
  setSortOrder,
  clearSortOrder,
  setTypeNameDropdown,
  loadCompanyFilterList: fetchAndSetEmploymentHistoryCompanyList,
  loadPositionFilterList: fetchAndSetEmploymentHistoryPositionList,
  loadNameFilterList: fetchAndSetLicensesCerificationNameList,
  loadTypeFilterList: fetchAndSetLicensesCerificationTypeList,
  loadDegreeFilterList: fetchAndSetEducationDegreeList,
  loadSchoolFilterList: fetchAndSetEducationSchoolList,
  loadSkillFilterList: fetchAndSetSkillsSkillList,
  loadSkillLevelFilterList: fetchAndSetSkillsSkillLevelList,
  loadYearofExperianceFilterList: fetchAndSetSkillsYearOfExperienceList
};
export default withRouter(connect(stateToProps, actionCreators)(Resume));
