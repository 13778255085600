import React from "react";
import { EmployeeUpdates } from "../../payroll-dashboard/images";
import PropTypes from "prop-types";
const BussinessComponent = ({ fieldLabel, fieldValue, image }) => {
  return (
    <div className="bussiness-component">
      <div className="icon">{image}</div>
      <div className="content">
        <div className="field-label">{fieldLabel}</div>
        <div style={{ fontSize: "18px" }} className="field-value">
          {fieldValue}
        </div>
      </div>
    </div>
  );
};
export default BussinessComponent;
BussinessComponent.propTypes = {
  fieldLabel: PropTypes.string,
  fieldValue: PropTypes.string,
  image: PropTypes.node
};
BussinessComponent.defaultprops = {
  fieldLabel: "Hire Date",
  fieldValue: "01/01/2015",
  image: <EmployeeUpdates height="30px" width="30px" />
};
