import React from "react";

const EarningsSetupHeader = () => (
  <div>
    <h1>Earnings Templates</h1>
    <span>
      Earnings codes determine how pay is calculated and where pay is expensed
      to in the general ledger. Earnings codes determine how pay is calculated
      and where pay is expensed to in the general ledger.
    </span>
    <h3>Earnings Setup</h3>
  </div>
);

export default EarningsSetupHeader;
