import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import { withRouter, Prompt } from "react-router-dom";
import * as common from "../../../../app/common/index";
import axios from "axios";
import { showToaster } from "../../../../app/actions/AppActions";
import Toaster from "../../../../app/common/UIComponents/Toaster";
import * as message from "../../../../app/common/messages";
import * as utilities from "../../../../app/common/utilities";
import { setLoader } from "../../../../app/actions/LoaderActions";
import DeductionSetupDescription from "./DeductionSetupDescription";
import SystemLevelDeductionDescriptionStep from "./SystemLevelDeductionDescriptionStep";
import DropDown from "component-library/dropDown/DropDown";
import RadioButton from "component-library/radioButton/BasicRadioButton";
import {
  setGlobalNavClick,
  CallbackToQuickLink,
  setFormUpdate,
  isOverlayClickable
} from "../../../../app/actions/FormUpdateAction";

import {
  getEnterprisePayeeOptionsList,
  getVendorEmployerDeductionList,
  getVendorEmployeeDeductionList
} from "../../actions/systemlevel/SystemLevelDeductionOptionsAction";
import { setSystemDeductionData } from "../../actions/systemlevel/DeductionSetupListingActions";

let isInDidUpdate = false;

const SAVE_AND_CONTINUE = 1;
const SAVE_AND_FINISH_LATER = 2;
const SAVE_TO_SUMMERY_PAGE = 3;
const SAVE_AND_BACK_PAGE = 4;
const SAVE_CHANGES = 5;
const TRACK_YOUR_PROGRESS = 6;
const GLOBAL_NAV = 7;

class OptionPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showBackCancelModal: false,
      searchable: false,
      SystemDeductionId: this.props.systemDeductionData.SystemDeductionId,

      SystemEnterprisePayee: this.props.systemDeductionData
        .SystemEnterprisePayee,
      SystemEnterprisePayeeName: this.props.systemDeductionData
        .SystemEnterprisePayeeName,
      EmployeeVendorCategoryName: this.props.systemDeductionData
        .EmployeeVendorCategoryName,
      EmployerVendorCategoryName: this.props.systemDeductionData
        .EmployerVendorCategoryName,

      RemitPaymentOnBehalfOfClient: this.props.systemDeductionData
        .RemitPaymentOnBehalfOfClient,
      SystemEnterprisePayeeId: this.props.systemDeductionData
        .SystemEnterprisePayeeId,
      EmployeeVendorCategoryId: this.props.systemDeductionData
        .EmployeeVendorCategoryId,
      EmployerVendorCategoryId: this.props.systemDeductionData
        .EmployerVendorCategoryId,

      openEnterprisePayeeDropdown: false,
      openBackButtonModal: false,
      showDeleteButtonModal: false,
      closeLeavingModal: false,
      openTrackYourProgressModal: false,
      showFinishLaterModalWithoutMandatoryField: false,
      showFinishLaterModalWithMandatoryField: false,

      isFormUpdated: false,
      pageId: 5,
      errors: {},
      showToaster: false,
      messageToaster: "",
      modalNavLink: ""
    };

    this.onChangeDeductionOptions = this.onChangeDeductionOptions.bind(this);
    this.setDeductionOptionsDropdownContext = this.setDeductionOptionsDropdownContext.bind(
      this
    );
    this.setVendorEmployeeDeductionDropdownContext = this.setVendorEmployeeDeductionDropdownContext.bind(
      this
    );
    this.setVendorEmployerDeductionDropdownContext = this.setVendorEmployerDeductionDropdownContext.bind(
      this
    );
    this.onOpenBackButtonModal = this.onOpenBackButtonModal.bind(this);
    this.onCloseBackButtonModal = this.onCloseBackButtonModal.bind(this);
    //this.showBackButtonMessage = this.showBackButtonMessage.bind(this);
    this.close = this.close.bind(this);
    this.onOpenDeleteButtonModal = this.onOpenDeleteButtonModal.bind(this);
    this.onCloseDeleteButtonModal = this.onCloseDeleteButtonModal.bind(this);
    this.leavingCancelModal = this.leavingCancelModal.bind(this);
    this.redirectToSystemLevelDefaultValue = this.redirectToSystemLevelDefaultValue.bind(
      this
    );
    this.redirectToSummarygPage = this.redirectToSummarygPage.bind(this);
    this.deleteDeduction = this.deleteDeduction.bind(this);
    this.deleteDeductionForGlobalNav = this.deleteDeductionForGlobalNav.bind(
      this
    );
    this.saveAndContinueDefaultOption = this.saveAndContinueDefaultOption.bind(
      this
    );
    this.checkIsPageBack = this.checkIsPageBack.bind(this);
    this.onOpenTrackYourProgressModal = this.onOpenTrackYourProgressModal.bind(
      this
    );
    this.onCloseTrackYourProgressModal = this.onCloseTrackYourProgressModal.bind(
      this
    );

    this.props.getEnterprisePayeeOptionsList();
    this.props.getVendorEmployeeDeductionList();
    this.props.getVendorEmployerDeductionList();
  }

  componentWillMount() {
    let enterprisePayeeEvent = {
      name: "RemitPaymentOnBehalfOfClient",
      value: "false"
    };

    this.onChangeDeductionOptions(enterprisePayeeEvent);
    this.setState({
      isFormUpdated: false
    });
    this.props.setFormUpdate(false);
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.getDataById();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.systemDeductionData !== this.props.systemDeductionData) {
      isInDidUpdate = true;

      if (
        this.props.systemDeductionData.SystemEnterprisePayeeId !== 0 &&
        this.props.systemDeductionData.SystemEnterprisePayeeId !== null
      ) {
        this.setState({
          openEnterprisePayeeDropdown: true,
          RemitPaymentOnBehalfOfClient: true,
          SystemEnterprisePayeeId: this.props.systemDeductionData
            .SystemEnterprisePayeeId
        });
      }
      isInDidUpdate = false;
    }
    if (
      prevProps.globalNavClick === null &&
      this.props.globalNavClick !== null
    ) {
      if (this.props.globalNavClick.isLinkClick) {
        this.setState({
          showBackCancelModal: true,
          IsFormUpdated: false
        });
        this.props.setFormUpdate(false);
      }
    } else {
      if (
        prevProps.globalNavClick !== null &&
        this.props.globalNavClick !== null
      ) {
        if (
          prevProps.globalNavClick.isLinkClick !==
          this.props.globalNavClick.isLinkClick
        ) {
          if (this.props.globalNavClick.isLinkClick) {
            this.setState({
              showBackCancelModal: true,
              IsFormUpdated: false
            });
            this.props.setFormUpdate(false);
          }
        }
      }
    }
    this.checkIsPageBack();
  }

  componentWillUnmount() {
    this.props.setFormUpdate(false);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.systemDeductionData !== this.props.systemDeductionData) {
      this.setState({
        SystemDeductionId: nextProps.systemDeductionData.SystemDeductionId,

        SystemEnterprisePayee:
          nextProps.systemDeductionData.SystemEnterprisePayee,
        SystemEnterprisePayeeName:
          nextProps.systemDeductionData.SystemEnterprisePayeeName,
        EmployeeVendorCategoryName:
          nextProps.systemDeductionData.EmployeeVendorCategoryName,
        EmployerVendorCategoryName:
          nextProps.systemDeductionData.EmployerVendorCategoryName,

        RemitPaymentOnBehalfOfClient:
          nextProps.systemDeductionData.RemitPaymentOnBehalfOfClient,
        SystemEnterprisePayeeId:
          nextProps.systemDeductionData.SystemEnterprisePayeeId,
        EmployeeVendorCategoryId:
          nextProps.systemDeductionData.EmployeeVendorCategoryId,
        EmployerVendorCategoryId:
          nextProps.systemDeductionData.EmployerVendorCategoryId
      });
    }
  }

  getDataById() {
    let self = this;
    let Id = self.props.match.params.SystemDeductionId;
    if (Id !== undefined) {
      // Id = window.atob(Id);
      const url = `${common.WEB_API_URL}/v1/system/deduction/${Id}`;
      axios
        .get(url)
        .then(function(response) {
          if (response.data.ErrorCount > 0) {
            response.data.Errors.forEach(item => {
              if (item.Tag === "") {
              }
            });
          } else {
            let deductionData = response.data.Data[0];
            self.props.setSystemDeductionData(deductionData);
          }
        })
        .catch(function(error) {});
    }
  }

  redirectToSystemLevelDefaultValue() {
    let self = this;
    let redirectURL = `/system-level-deafult-value/${
      this.props.match.params.SystemDeductionId
    }`;

    self.setState({ isFormUpdated: false });
    this.props.setFormUpdate(false);
    const dataUrl = `${common.WEB_API_URL}/v1/system/deduction/${
      self.state.SystemDeductionId
    }`;
    axios
      .get(dataUrl)
      .then(function(response) {
        if (response.data.ErrorCount > 0) {
          self.setState({ isFormUpdated: true });
          this.props.setFormUpdate(true);
          response.data.Errors.forEach((item, index) => {
            if (index === 0) {
              alert(item.Messages);
            }
          });
        } else {
          let deductionData = response.data.Data[0];
          self.props.setSystemDeductionData(deductionData);
          self.props.history.push(redirectURL);
        }
      })
      .catch(function(error) {});
  }

  redirectToSummarygPage() {
    this.setState({ isFormUpdated: false });
    this.props.setFormUpdate(false);
    this.props.showToaster(false);
    setTimeout(
      function() {
        this.props.history.push(
          `/system-level-review-and-save/${
            this.props.match.params.SystemDeductionId
          }`
        );
      }.bind(this),
      100
    );
  }

  checkIsPageBack() {
    if (this.state.isFormUpdated === false) {
    } else {
    }
  }

  saveAndContinueDefaultOption(actionType, navLink) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let redirectPageUrl = "";
    let data = this.state;
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: false,
        showFinishLaterModalWithMandatoryField: false
      });
      this.props.setFormUpdate(false);
    }

    if (actionType === SAVE_AND_CONTINUE) {
      redirectPageUrl = `/system-level-review-and-save/${
        this.props.match.params.SystemDeductionId
      }`;
    } else if (actionType === SAVE_CHANGES) {
      redirectPageUrl = "/system-level-deduction-setup-listing-page";
      this.props.showToaster(
        true,
        `'${
          this.state.SystemDeductionName
        }' has been saved. We’ve marked this deduction as in progress for you to work on later.`,
        "inprogress"
      );
    } else if (actionType === SAVE_AND_FINISH_LATER) {
      redirectPageUrl = "/system-level-deduction-setup-listing-page";
      this.props.showToaster(
        true,
        `'${
          this.props.systemDeductionData.SystemDeductionName
        }' has been saved. We’ve marked this deduction as in progress for you to work on later.`,
        "inprogress"
      );
    } else if (actionType === SAVE_TO_SUMMERY_PAGE) {
      redirectPageUrl = `/system-level-review-and-save/${
        this.props.match.params.SystemDeductionId
      }`;
      this.props.showToaster(
        true,
        `'${
          this.props.systemDeductionData.SystemDeductionName
        }' has been updated.`,
        "success"
      );
    } else if (actionType === SAVE_AND_BACK_PAGE) {
      redirectPageUrl = `/system-level-deafult-value/${
        this.props.match.params.SystemDeductionId
      }`;
    } else if (actionType === TRACK_YOUR_PROGRESS) {
      redirectPageUrl = navLink;
    }

    let errors = {};

    data.UiDraftCompletionStage = data.pageId;

    // if (
    //   data.RemitPaymentOnBehalfOfClient === null ||
    //   data.RemitPaymentOnBehalfOfClient === undefined
    // ) {
    //   errors = validator.validateRequiredField(
    //     data.RemitPaymentOnBehalfOfClient,
    //     "RemitPaymentOnBehalfOfClient",
    //     errors
    //   );
    // }

    // errors = validator.validateRequiredField(
    //   data.EmployeeVendorCategoryId,
    //   "EmployeeVendorCategoryId",
    //   errors
    // );
    // errors = validator.validateRequiredField(
    //   data.EmployerVendorCategoryId,
    //   "EmployerVendorCategoryId",
    //   errors
    // );

    // if (data.RemitPaymentOnBehalfOfClient === true) {
    //   errors = validator.validateRequiredField(
    //     data.SystemEnterprisePayeeId,
    //     "SystemEnterprisePayeeId",
    //     errors
    //   );
    // }

    this.setState({
      errors: errors,
      openBackButtonModal: false,
      showDeleteButtonModal: false,
      closeLeavingModal: false,
      openTrackYourProgressModal: false
    });

    if (Object.keys(errors).length !== 0) {
      this.setState({
        showToaster: true,
        messageToaster: message.ERROR_ON_PAGE,
        isFormUpdated: true
      });
      this.setState({
        IsFormUpdated: true,
        showBackCancelModal: false
      });
      this.props.setFormUpdate(true);
      this.props.setGlobalNavClick(false);
    } else {
      if (actionType === GLOBAL_NAV) {
        this.setState({
          isFormUpdated: false,
          showBackCancelModal: false
        });
        this.props.setFormUpdate(false);
        this.props.setGlobalNavClick(
          false,
          this.props.globalNavClick.LinkItem,
          this.props.globalNavClick.MenuLink
        );
        //this.props.CallbackToQuickLink(true);
        this.setState({
          showBackCancelModal: false
        });
        this.props.isOverlayClickable(false);
        redirectPageUrl = "";
      }
    }

    if (Object.keys(errors).length === 0) {
      this.props.setLoader(true);
      this.setState({
        isFormUpdated: false
      });
      let UIDraftPageId = 0;

      if (
        this.props.systemDeductionData.UiDraftCompletionStage >
        this.state.pageId
      ) {
        UIDraftPageId = this.props.systemDeductionData.UiDraftCompletionStage;
      } else {
        UIDraftPageId = this.state.pageId;
      }

      var self = this;

      let patchActions = [
        {
          Operation: "update",
          PathOrKey: "EmployeeVendorCategoryId",
          Value:
            data.EmployeeVendorCategoryId === 0
              ? null
              : data.EmployeeVendorCategoryId
        },
        {
          Operation: "update",
          PathOrKey: "EmployerVendorCategoryId",
          Value:
            data.EmployerVendorCategoryId === 0
              ? null
              : data.EmployerVendorCategoryId
        },
        {
          Operation: "update",
          PathOrKey: "UiDraftCompletionStage",
          Value: UIDraftPageId
        }
      ];

      const url = `${common.WEB_API_URL}/v1/system/deduction/${
        data.SystemDeductionId
      }`;
      axios
        .patch(url, patchActions)
        .then(function(response) {
          //
          self.props.setLoader(false);
          const dataUrl = `${common.WEB_API_URL}/v1/system/deduction/${
            data.SystemDeductionId
          }`;
          axios
            .get(dataUrl)
            .then(function(response) {
              if (response.data.ErrorCount > 0) {
                response.data.Errors.forEach((item, index) => {
                  if (index === 0) {
                    alert(item.Messages);
                  }
                });
              } else {
                let deductionData = response.data.Data[0];
                self.props.setSystemDeductionData(deductionData);
                if (actionType === GLOBAL_NAV) {
                  self.props.setFormUpdate(false);
                  self.props.CallbackToQuickLink(true);
                } else {
                  self.props.history.push(redirectPageUrl);
                }
              }
            })
            .catch(function(error) {});
        })
        .catch(function(error) {});
    }
  }

  deleteDeduction() {
    var self = this;
    this.setState({
      isFormUpdated: false,
      showDeleteButtonModal: false
    });
    this.props.setFormUpdate(false);
    self.props.setLoader(true);
    const url = `${common.WEB_API_URL}/v1/system/deduction/${
      this.state.SystemDeductionId
    }`;
    axios
      .delete(url)
      .then(function(response) {
        self.props.setLoader(false);
        if (response.data.ErrorCount > 0) {
          self.setState({
            isFormUpdated: true
          });
          this.props.setFormUpdate(true);
          response.data.Errors.forEach((item, index) => {
            if (index === 0) {
              alert(item.Messages);
            }
          });
        } else {
          self.props.showToaster(
            true,
            `'${
              self.props.systemDeductionData.SystemDeductionName
            }' has been deleted.`,
            "delete"
          );
          self.props.history.push("/system-level-deduction-setup-listing-page");
        }
      })
      .catch(function(error) {});
  }

  deleteDeductionForGlobalNav() {
    var self = this;
    this.setState({
      isFormUpdated: false,
      showDeleteButtonModal: false
    });
    this.props.setFormUpdate(false);
    self.props.setLoader(true);
    const url = `${common.WEB_API_URL}/v1/system/deduction/${
      this.state.SystemDeductionId
    }`;
    axios
      .delete(url)
      .then(function(response) {
        self.props.setLoader(false);
        if (response.data.ErrorCount > 0) {
          self.setState({
            isFormUpdated: true
          });
          this.props.setFormUpdate(true);
          response.data.Errors.forEach((item, index) => {
            if (index === 0) {
              alert(item.Messages);
            }
          });
        } else {
          self.props.setFormUpdate(false);
          self.props.setGlobalNavClick(
            false,
            self.props.globalNavClick.LinkItem,
            self.props.globalNavClick.MenuLink
          );
          self.props.CallbackToQuickLink(true);
          self.props.isOverlayClickable(false);
        }
      })
      .catch(function(error) {});
  }

  onOpenFinishLaterButtonModal = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({
      showFinishLaterModalWithMandatoryField: true,
      isFormUpdated: false
    });
    this.props.setFormUpdate(false);
  };

  onCloseFinishLaterModal = () => {
    this.setState({
      isFormUpdated: true,
      showFinishLaterModalWithMandatoryField: false
    });
    this.props.setFormUpdate(true);
    this.props.setGlobalNavClick(false);
  };

  exitSetup = (actionType, navLink) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (actionType === "EXIT_SETUP") {
      this.setState({
        showFinishLaterModalWithoutMandatoryField: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.history.push("/system-level-deduction-setup-listing-page");
    } else if (actionType === "LEAVE_THIS_PAGE") {
      this.setState({
        openTrackYourProgressModal: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.history.push(navLink);
    } else if (actionType === "GLOBAL_NAV") {
      this.setState({
        showBackCancelModal: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.setGlobalNavClick(
        false,
        this.props.globalNavClick.LinkItem,
        this.props.globalNavClick.MenuLink
      );
      this.props.CallbackToQuickLink(true);
    }
  };

  onOpenBackButtonModal() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({ openBackButtonModal: true, isFormUpdated: false });
    this.props.setFormUpdate(false);
  }

  onCloseBackButtonModal() {
    this.setState({
      openBackButtonModal: false,
      isFormUpdated: true
    });
    this.props.setFormUpdate(true);
  }

  close() {
    this.setState({
      openBackButtonModal: false,
      showDeleteButtonModal: false,
      showCancelModal: false,
      closeLeavingModal: false,
      openTrackYourProgressModal: false,
      isFormUpdated: true,
      showBackCancelModal: false
    });
    this.props.setFormUpdate(true);
    this.props.setGlobalNavClick(false);
  }

  onOpenTrackYourProgressModal = navLink => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({
      openTrackYourProgressModal: true,
      modalNavLink: navLink,
      isFormUpdated: false
    });
  };

  onCloseTrackYourProgressModal() {
    this.setState({ openTrackYourProgressModal: false });
    this.setState({
      openTrackYourProgressModal: false,
      isFormUpdated: true
    });
  }

  onOpenDeleteButtonModal() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({ showDeleteButtonModal: true });
  }

  onCloseDeleteButtonModal() {
    this.setState({ showDeleteButtonModal: false });
  }

  leavingCancelModal() {
    this.setState({ closeLeavingModal: false });
  }

  showCustomIcon() {
    return <i className="fa fa-chevron-down cstm555icon" aria-hidden="true" />;
  }

  renderEnterprisePayeeOptionsList(list) {
    return list.map(item => {
      return (
        <div
          key={item.id}
          id="radio-button-space"
          className={`single-radio-button`}
        >
          <RadioButton
            id={item.id}
            name={item.name}
            value={item.value}
            type="title"
            label={item.label}
            checked={this.state.RemitPaymentOnBehalfOfClient === item.value}
            onChange={this.onChangeDeductionOptions}
          />
        </div>
      );
    });
  }

  onChangeDeductionOptions(event, value = 0) {
    let update = {};
    let errors = this.state.errors;
    delete errors["RemitPaymentOnBehalfOfClient"];
    this.setState({
      errors
    });
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }

    let selectedButton = event.value === "true";

    if (selectedButton) {
      this.setState({
        openEnterprisePayeeDropdown: true
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
    } else {
      this.setState({
        openEnterprisePayeeDropdown: false,
        SystemEnterprisePayeeId: null
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }

      let errors = this.state.errors;
      this.setState({
        errors
      });
    }

    update[event.name] = selectedButton;
    this.checkIsPageBack();
    this.setState(update);
  }

  setDeductionOptionsDropdownContext(newValue) {
    let errors = this.state.errors;
    this.setState({
      errors
    });
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
    this.setState({
      SystemEnterprisePayeeId: newValue
    });
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
    // this.checkIsPageBack();
  }

  setVendorEmployeeDeductionDropdownContext(newValue) {
    let errors = this.state.errors;
    // delete errors["EmployeeVendorCategoryId"];
    this.setState({
      errors
    });
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
    this.setState({
      EmployeeVendorCategoryId: newValue
    });
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
    // this.checkIsPageBack();
  }

  setVendorEmployerDeductionDropdownContext(newValue) {
    let errors = this.state.errors;
    // delete errors["EmployerVendorCategoryId"];
    this.setState({
      errors
    });
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
    this.setState({
      EmployerVendorCategoryId: newValue
    });
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
    // this.checkIsPageBack();
  }

  showEnterprisePayeeDropdown() {
    const systemLevelDeductionOptions = this.props.systemLevelDeductionOptions;
    return (
      <div className="entity-form-section-secondary">
        <div className="content">
          <div id="" className="nc-field-group clearfix">
            <div className="field-name">
              <span>
                Select Enterprise Payee{" "}
                <span className="short-description-details">(Optional)</span>
              </span>
            </div>

            <div
              id="select-enterprise-payee-dropdown-container"
              className="input-field col-md-10 col-lg-8"
            >
              <DropDown
                id="select-enterprise-payee-dropdown"
                placeholder="Select"
                options={utilities.getSelectValueList(
                  systemLevelDeductionOptions,
                  "value",
                  "label"
                )}
                value={this.state.SystemEnterprisePayeeId}
                onSelect={this.setDeductionOptionsDropdownContext}
                scrollBy={false}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  onClickTrackYourProgress(navLink) {
    if (this.state.isFormUpdated) {
      this.onOpenTrackYourProgressModal(navLink);
    } else {
      this.props.history.push(navLink);
    }
  }

  closeToasterMessage = () => {
    this.setState({ showToaster: false });
    this.props.showToaster(false, "");
  };

  render() {
    let isReviewSaveEditPage = false;
    if (this.props.systemDeductionData.UiDraftCompletionStage === 6) {
      isReviewSaveEditPage = true;
    }
    return (
      <div className="nc-entity-page-container">
        <Prompt
          when={this.state.isFormUpdated}
          message={message.UNSAVED_CHANGES}
        />
        <DeductionSetupDescription />
        <div id="toaster-message" className="toaster-container">
          {this.state.showToaster ? (
            <Toaster
              show={this.state.showToaster}
              message={this.state.messageToaster}
              closeToasterMessage={this.closeToasterMessage}
              alertClassName="danger"
            />
          ) : null}
        </div>
        <div className="clearfix" />

        <div
          id="deduction-description-page-title1"
          className="title-band-container"
        >
          <div className="title-band">
            <h3>Deduction Setup</h3>
          </div>
        </div>

        {/* Added for the mobile view */}
        <div
          id="deduction-track-progress"
          className="progress-tracker-container-mobile hidden-lg hidden-md"
        >
          <SystemLevelDeductionDescriptionStep
            pageId={this.state.pageId}
            onClickTrackYourProgress={(navLink, id) =>
              id !== 5 ? this.onClickTrackYourProgress(navLink) : null
            }
          />
        </div>

        <div
          id="deduction-description-page-title2"
          className="entity-page-content col-lg-9 col-md-8"
        >
          <div className="step-title-container clearfix">
            <span className="step-number badge badge-default">5</span>
            <div
              id="system-level-calculations-method-page"
              className="step-title"
            >
              Options
            </div>
          </div>

          <div
            id="deduction-cycle-page-description"
            className="entity-form-section-primary"
            style={{ marginBottom: "20px" }}
          >
            <div id="deduction-cycle-page-description" className="content">
              <div id="deduction-options-page-description">
                Select all options that apply.
              </div>
            </div>
          </div>

          <div className="description-content">
            <div className="seperator">
              <hr />
            </div>

            <div
              id="deduction-taxability-section"
              className="entity-form-section-secondary"
            >
              <div className="title" id="third-party-payee-mailing-address">
                Vendor Category
              </div>
              <div className="content">
                <div className="nc-field-group clearfix">
                  <div
                    id="deduction-options-page-vendor-category-question"
                    className="field-name"
                  >
                    <span>
                      Vendor Category (Employee Deduction Amount){" "}
                      <span className="short-description-details">
                        (Optional)
                      </span>
                    </span>
                  </div>

                  <div
                    id="select-Vendor-Employee-payee-dropdown-container"
                    className="input-field col-md-7 col-lg-5"
                  >
                    <DropDown
                      id="select-Vendor-Employee-payee-dropdown"
                      placeholder="Select"
                      options={utilities.getSelectValueList(
                        this.props.vendorEmployeeDeduction,
                        "value",
                        "label"
                      )}
                      value={this.state.EmployeeVendorCategoryId}
                      onSelect={this.setVendorEmployeeDeductionDropdownContext}
                      scrollBy={false}
                    />
                  </div>
                </div>&nbsp;
                <div className="nc-field-group clearfix">
                  <div className="field-name">
                    <span>
                      Vendor Category (Employer Deduction Amount){" "}
                      <span className="short-description-details">
                        (Optional)
                      </span>
                    </span>
                  </div>

                  <div
                    id="select-Vendor-Employer-payee-dropdown-container"
                    className="input-field col-md-7 col-lg-5"
                  >
                    <DropDown
                      id="select-Vendor-Employer-payee-dropdown"
                      placeholder="Select"
                      options={utilities.getSelectValueList(
                        this.props.vendorEmployerDeduction,
                        "value",
                        "label"
                      )}
                      value={this.state.EmployerVendorCategoryId}
                      onSelect={this.setVendorEmployerDeductionDropdownContext}
                      scrollBy={true}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="clearfix" />
          </div>
        </div>

        <div
          id="deduction-track-progress"
          className="col-lg-3 col-md-4 hidden-sm hidden-xs"
          style={{ marginBottom: "20px" }}
        >
          <SystemLevelDeductionDescriptionStep
            pageId={this.state.pageId}
            onClickTrackYourProgress={(navLink, id) =>
              id !== 5 ? this.onClickTrackYourProgress(navLink) : null
            }
          />
        </div>
        <div className="footer-button-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="seperator">
            <hr />
          </div>

          {isReviewSaveEditPage ? (
            <div className="nc-button-group">
              <button
                type="button"
                id="option-page-save-changes-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDefaultOption.bind(
                  this,
                  SAVE_TO_SUMMERY_PAGE,
                  ""
                )}
              >
                SAVE CHANGES
              </button>

              <div className="nc-link-group-mobile-single-button">
                <button
                  type="button"
                  id="description-page-revert-back-button"
                  className="secondary-link-button"
                  onClick={this.redirectToSummarygPage}
                >
                  Revert back to last saved
                </button>
              </div>
            </div>
          ) : (
            <div className="nc-button-group">
              <button
                type="button"
                id="default-value-back-button"
                className="nc-button outline-button hidden-xs"
                onClick={
                  !this.state.isFormUpdated
                    ? this.redirectToSystemLevelDefaultValue
                    : this.onOpenBackButtonModal
                }
              >
                <i className="fa fa-chevron-left"> </i> BACK
              </button>

              <button
                type="button"
                id="deduction-option-save-and-continue-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDefaultOption.bind(
                  this,
                  SAVE_AND_CONTINUE,
                  ""
                )}
              >
                CONTINUE
              </button>
              <button
                type="button"
                id="default-value-back-button"
                className="nc-button outline-button visible-xs"
                onClick={
                  !this.state.isFormUpdated
                    ? this.redirectToSystemLevelDefaultValue
                    : this.onOpenBackButtonModal
                }
              >
                <i className="fa fa-chevron-left"> </i> BACK
              </button>
              <div className="nc-link-group-mobile">
                <button
                  type="button"
                  id="deduction-option-save-and-finish-later-buttonlink"
                  className="primary-link-button"
                  onClick={this.onOpenFinishLaterButtonModal.bind(this)}
                >
                  Finish Later
                </button>

                <button
                  type="button"
                  id="deduction-option-cancel-buttonlink"
                  className="secondary-link-button"
                  onClick={this.onOpenDeleteButtonModal}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="clearfix" />

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="finish-later-modal-2"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showFinishLaterModalWithMandatoryField}
          onHide={this.onCloseFinishLaterModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="finish-later-modal-2-close-icon"
                onClick={this.onCloseFinishLaterModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="finish-later-modal-2-line1">
                Do you want to save your changes before exiting setup?
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="finish-later-modal-1-exit-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDefaultOption.bind(
                  this,
                  SAVE_AND_FINISH_LATER,
                  ""
                )}
              >
                SAVE AND EXIT
              </button>

              <button
                type="button"
                id="finish-later-modal-1-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseFinishLaterModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          id="track-progress-modal-2"
          show={this.state.openTrackYourProgressModal}
          onHide={this.onCloseTrackYourProgressModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="track-progress-modal-2-close-icon"
                onClick={this.onCloseTrackYourProgressModal.bind(this)}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="track-progress-modal-2-line1">
                Do you want to save your changes before leaving this page?
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="track-progress-modal-2-save-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDefaultOption.bind(
                  this,
                  TRACK_YOUR_PROGRESS,
                  this.state.modalNavLink
                )}
              >
                SAVE AND GO
              </button>

              <button
                type="button"
                id="track-progress-modal-2-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseTrackYourProgressModal.bind(this)}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          id="back-button-modal-2"
          show={this.state.openBackButtonModal}
          onHide={this.onCloseBackButtonModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="back-button-modal-2-close-icon"
                onClick={this.onCloseBackButtonModal.bind(this)}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="back-button-modal-2-line1">
                Do you want to save your changes before leaving this page?
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="back-button-modal-2-save-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDefaultOption.bind(
                  this,
                  SAVE_AND_BACK_PAGE,
                  ""
                )}
              >
                SAVE AND GO
              </button>

              <button
                type="button"
                id="track-progress-modal-2-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseBackButtonModal.bind(this)}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showBackCancelModal}
          onHide={() => {
            this.setState({
              showBackCancelModal: !this.state.showBackCancelModal
            });
          }}
          id="deduction-unsaved-modal"
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="deduction-unsaved-modal-close-icon"
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="track-progress-modal-line1">
                Are you sure you want to leave this page in the middle of making
                edits?
              </div>
              <br />
              <div id="track-progress-modal-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="deduction-unsaved-modal-leave-button"
                className="nc-button secondary-button"
                onClick={this.exitSetup.bind(this, "GLOBAL_NAV", "")}
              >
                LEAVE THIS PAGE
              </button>

              <button
                type="button"
                id="deduction-unsaved-modal-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.close}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="delete-deduction-modal"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showDeleteButtonModal}
          onHide={this.onCloseDeleteButtonModal}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="delete-deduction-modal-close-icon"
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="delete-deduction-modal-line1">
                Are you sure you want to delete this deduction?
              </div>
              <br />
              <div id="delete-deduction-modal-line2">
                It will be gone for now, but you can add it back if you need it.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="delete-deduction-modal-delete-button"
                className="nc-button secondary-button"
                onClick={this.deleteDeduction}
              >
                DELETE DEDUCTION
              </button>

              <button
                type="button"
                id="delete-deduction-modal-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.close}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let systemLevelDeductionOptions = [];
  let vendorEmployeeDeduction = [];
  let vendorEmployerDeduction = [];

  if (
    state.systemLevelDeductionOptionsList.length > 0 ||
    state.systemLevelDeductionOptionsList.DataCount > 0
  ) {
    systemLevelDeductionOptions = state.systemLevelDeductionOptionsList.Data.map(
      (item, index) => {
        return {
          value: item.SystemEnterprisePayeeId,
          label: item.SystemEnterprisePayeeName
        };
      }
    );
  }

  if (
    state.vendorEmployeeDeductionList.length > 0 ||
    state.vendorEmployeeDeductionList.DataCount > 0
  ) {
    vendorEmployeeDeduction = state.vendorEmployeeDeductionList.Data.map(
      (item, index) => {
        return {
          value: item.EmployeeVendorCategoryId,
          label: item.Name
        };
      }
    );
  }

  if (
    state.vendorEmployerDeductionList.length > 0 ||
    state.vendorEmployerDeductionList.DataCount > 0
  ) {
    vendorEmployerDeduction = state.vendorEmployerDeductionList.Data.map(
      (item, index) => {
        return {
          value: item.EmployerVendorCategoryId,
          label: item.Name
        };
      }
    );
  }
  return {
    systemLevelDeductionOptionsList: state.systemLevelDeductionOptionsList,
    systemLevelDeductionOptions: systemLevelDeductionOptions,
    vendorEmployeeDeductionList: state.vendorEmployeeDeductionList,
    vendorEmployeeDeduction: vendorEmployeeDeduction,
    vendorEmployerDeductionList: state.vendorEmployerDeductionList,
    vendorEmployerDeduction: vendorEmployerDeduction,
    systemDeductionData: state.systemDeductionData,
    globalNavClick: state.globalNavClick
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEnterprisePayeeOptionsList: getEnterprisePayeeOptionsList,
      showToaster: showToaster,
      getVendorEmployeeDeductionList: getVendorEmployeeDeductionList,
      getVendorEmployerDeductionList: getVendorEmployerDeductionList,
      setSystemDeductionData: setSystemDeductionData,
      setLoader: setLoader,
      setFormUpdate: setFormUpdate,
      isOverlayClickable: isOverlayClickable,
      setGlobalNavClick: setGlobalNavClick,
      CallbackToQuickLink: CallbackToQuickLink
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OptionPage)
);
