import { combineReducers } from "redux";
import * as types from "../../../actions/companylevel/index";

function companyThirdPartyPayeeList(state = [], action) {
  switch (action.type) {
    case types.COMPANY_THIRD_PARTY_PAYEE_MAIN_LIST:
      return action.payload.data;

    default:
      return state;
  }
}

function companyThirdPartyPayeeData(state = [], action) {
  switch (action.type) {
    case types.COMPANY_THIRD_PARTY_PAYEE_DATA:
      return action.payload;

    default:
      return state;
  }
}

function companyThirdPartyPayeeFilteredList(state = [], action) {
  switch (action.type) {
    case types.COMPANY_THIRD_PARTY_PAYEE_FILTERED_LIST: {
      return action.payload.data;
    }

    default:
      return state;
  }
}

function companyThirdPartyPayeeMainList(state = [], action) {
  switch (action.type) {
    case types.COMPANY_THIRD_PARTY_PAYEE_MAIN_LIST: {
      return action.payload.data;
    }

    default:
      return state;
  }
}

function activeSystemThirdPartyPayeeList(state = [], action) {
  switch (action.type) {
    case types.FETCH_ACTIVE_SYSTEM_TPP_LIST:
      return action.payload.data;

    default:
      return state;
  }
}

function activeThirdPartyPayeeCategoryList(state = [], action) {
  switch (action.type) {
    case types.FETCH_ACTIVE_TPP_CATEGORY_LIST:
      return action.payload.data;

    default:
      return state;
  }
}

function activeTPPByCategoryIdStateId(state = [], action) {
  switch (action.type) {
    case types.FETCH_ACTIVE_TPP_BY_CATEGORYID_STATEID:
      return action.payload.data;

    default:
      return state;
  }
}

function activeTPPListByCategoryId(state = [], action) {
  switch (action.type) {
    case types.FETCH_ACTIVE_TPP_BY_CATEGORYID:
      return action.payload.data;

    default:
      return state;
  }
}

function stateListOfActiveTPPByCategoryId(state = [], action) {
  switch (action.type) {
    case types.FETCH_STATES_LIST_OF_ACTIVE_TPP_BY_CATEGORYID:
      return action.payload.data;

    default:
      return state;
  }
}

function companyLevelTPPSelectedFiltersList(state = [], action) {
  switch (action.type) {
    case "COMPANY_TPP_SELECTED_FILTERS":
      return action.payload;

    default:
      return state;
  }
}

const companyThirdPartyPayeeReducer = combineReducers({
  companyThirdPartyPayeeList,
  companyThirdPartyPayeeFilteredList,
  companyThirdPartyPayeeMainList,
  companyThirdPartyPayeeData,
  activeSystemThirdPartyPayeeList,
  activeThirdPartyPayeeCategoryList,
  activeTPPByCategoryIdStateId,
  activeTPPListByCategoryId,
  stateListOfActiveTPPByCategoryId,
  companyLevelTPPSelectedFiltersList
});

export default companyThirdPartyPayeeReducer;
