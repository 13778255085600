import StringMask from "string-mask";

export const getDemographicSchema = data => {
  const body = {
    FormerAddress: {
      CountryId: data.fCountry ? data.fCountry : "",
      CountryName: "",
      AddressLine1: data.fAddress1 ? data.fAddress1 : "",
      AddressLine2: data.fAddress2 ? data.fAddress2 : "",
      ZipCode: data.fZipCode ? data.fZipCode : "",
      City: data.fCity ? data.fCity : "",
      StateId: data.fState ? data.fState : "",
      StateName: "",
      Parish: data.fCounty ? data.fCounty : ""
    },
    CurrentAddress: {
      CountryId: data.country ? data.country : "",
      CountryName: "",
      AddressLine1: data.addressLine1 ? data.addressLine1 : "",
      AddressLine2: data.addressLine2 ? data.addressLine2 : "",
      ZipCode: data.zipCode ? data.zipCode : "",
      City: data.city ? data.city : "",
      StateId: data.State ? data.State : "",
      StateName: "",
      Parish: data.county ? data.county : ""
    },
    Demographics: {
      Id: 0,
      Name: "string",
      EmployeeId: 0,
      EmployeeName: "string",
      TitleId: data.title ? data.title : "",
      TitleName: "",
      SuffixId: data.suffix ? data.suffix : "",
      SuffixName: "",
      FirstName: data.firstName ? data.firstName : "",
      MiddleName: data.middleName ? data.middleName : "",
      LastName: data.lastName ? data.lastName : "",
      PreviousLastName: data.previousLastName ? data.previousLastName : "",
      Nickname: data.nickName ? data.nickName : "",
      SSN: data.unmaskedSSN ? data.unmaskedSSN : "",
      BirthDate: "2018-04-03T17:12:44.409Z",
      Age: data.Age ? data.Age : "",
      EmailId: data.personalEmail ? data.personalEmail : "",
      HomePhoneNumber: data.homePhone ? data.homePhone : "",
      CellPhoneNumber: data.cellPhoneNumber ? data.cellPhoneNumber : "",
      CarrierId: data.cellPhoneCarrier ? data.cellPhoneCarrier : "",
      CarrierName: "",
      RaceId: data.race ? data.race : "",
      RaceName: "",
      GenderId: data.gender ? data.gender : "",
      GenderName: "",
      MaritalStatusId: data.martialStatus ? data.martialStatus : "",
      MaritalStatusName: "",
      IsProtectedVeteran: data.protectedVeteran ? data.protectedVeteran : "",
      IsDisable: data.disability ? data.disability : "",
      DisabilityAccomodations: data.disabilityAccomodation
        ? data.disabilityAccomodation
        : ""
    }
  };
  return body;
};

export const getStateSchema = data => {
  let formatter = new StringMask("000-000-0000");
  const body = {
    SSN: "Show Social Security Number",
    DOB: "Show Birth Date & Age",
    State: data.CurrentAddress.StateId ? data.CurrentAddress.StateId : "",
    address1: data.CurrentAddress.AddressLine1
      ? data.CurrentAddress.AddressLine1
      : "",
    address2: data.CurrentAddress.AddressLine2
      ? data.CurrentAddress.AddressLine2
      : "",
    cellPhoneCarrier: data.CarrierId ? data.CarrierId : "",
    cellPhoneNumber: data.CellPhoneNumber
      ? formatter.apply(data.CellPhoneNumber)
      : "",
    city: data.CurrentAddress.City ? data.CurrentAddress.City : "",
    country: data.CurrentAddress.CountryId ? data.CurrentAddress.CountryId : "",
    county: data.CurrentAddress.Parish ? data.CurrentAddress.Parish : "",
    disability: data.IsDisable ? data.IsDisable.toString() : "false",
    fState: data.FormerAddress.StateId ? data.FormerAddress.StateId : "",
    fAddress1: data.FormerAddress.AddressLine1
      ? data.FormerAddress.AddressLine1
      : "",
    fAddress2: data.FormerAddress.AddressLine2
      ? data.FormerAddress.AddressLine2
      : "",
    fCity: data.FormerAddress.City ? data.FormerAddress.City : "",
    fCountry: data.FormerAddress.CountryId ? data.FormerAddress.CountryId : "",
    fCounty: data.FormerAddress.Parish ? data.FormerAddress.Parish : "",
    fZipCode: data.FormerAddress.ZipCode ? data.FormerAddress.ZipCode : "",
    firstName: data.FirstName ? data.FirstName : "",
    gender: "1",
    homePhone: data.HomePhoneNumber
      ? formatter.apply(data.HomePhoneNumber)
      : "",
    lastName: data.LastName ? data.LastName : "",
    martialStatus: data.MaritalStatusId ? data.MaritalStatusId.toString() : "1",
    middleName: data.MiddleName ? data.MiddleName : "",
    nickName: data.Nickname ? data.Nickname : "",
    personalEmail: data.EmailId ? data.EmailId : "",
    previousLastName: data.PreviousLastName ? data.PreviousLastName : "",
    protectedVeteran: data.IsProtectedVeteran
      ? data.IsProtectedVeteran.toString()
      : "true",
    race: data.RaceId ? data.RaceId : "",
    suffix: data.SuffixId ? data.SuffixId : "",
    title: data.TitleId ? data.TitleId.toString() : "1",
    unmaskedSSN: data.SSN ? formatter.apply(data.SSN) : "",
    zipCode: data.CurrentAddress.ZipCode ? data.CurrentAddress.ZipCode : "",
    disabilityAccomodation: data.DisabilityAccomodations
  };
  return body;
};

export const getEmergencyDataSchema = data => {
  const body = {
    EmergencyPrimaryContact: {
      Name: data.primaryContactName ? data.primaryContactName : "",
      RelationshipId: data.primaryContactRelationship
        ? data.primaryContactRelationship
        : "",
      RelationshipName: "",
      EmailId: data.primaryContactPersonalEmail
        ? data.primaryContactPersonalEmail
        : "",
      HomePhoneNumber: data.primaryContactHomePhone
        ? data.primaryContactHomePhone
        : "",
      CellPhoneNumber: data.primaryContactCellPhoneNumber
        ? data.primaryContactCellPhoneNumber
        : "",
      WorkPhoneNumber: data.primaryContactWorkPhoneNumber
        ? data.primaryContactWorkPhoneNumber
        : "",
      UseSameAsEmployeeAddress: true,
      EmergencyAddress: {
        CountryId: 0,
        CountryName: "",
        AddressLine1: data.primaryContactAddress1
          ? data.primaryContactAddress1
          : "",
        AddressLine2: data.primaryContactAddress2
          ? data.primaryContactAddress2
          : "",
        ZipCode: data.primaryContactZipCode ? data.primaryContactZipCode : "",
        City: data.primaryContactCity ? data.primaryContactCity : "",
        StateId: data.primaryContactState ? data.primaryContactState : "",
        StateName: "",
        Parish: ""
      }
    },
    EmergencySecondaryContact: {
      Name: data.secConatctName ? data.secConatctName : "",
      RelationshipId: data.secConatctRelationship
        ? data.secConatctRelationship
        : "",
      RelationshipName: "",
      EmailId: data.secContactPersonalEmail ? data.secContactPersonalEmail : "",
      HomePhoneNumber: data.secContactHomePhone ? data.secContactHomePhone : "",
      CellPhoneNumber: data.secContactCellPhoneNumber
        ? data.secContactCellPhoneNumber
        : "",
      WorkPhoneNumber: data.secContactWorkPhoneNumber
        ? data.secContactWorkPhoneNumber
        : "",
      UseSameAsEmployeeAddress: true,
      EmergencyAddress: {
        CountryId: 0,
        CountryName: "",
        AddressLine1: data.secContactAddress1 ? data.secContactAddress1 : "",
        AddressLine2: data.secContactAddress2 ? data.secContactAddress2 : "",
        ZipCode: data.secContactZipCode ? data.secContactZipCode : "",
        City: data.secContactCity ? data.secContactCity : "",
        StateId: data.secContactState ? data.secContactState : "",
        StateName: "",
        Parish: ""
      }
    },
    DisasterLocation: {
      LocationName: data.locationName ? data.locationName : "",
      CellPhoneNumber: data.disasterLocationPhone
        ? data.disasterLocationPhone
        : "",
      CellPhoneTypeId: data.disasterLocationPhoneType
        ? data.disasterLocationPhoneType
        : "",
      CellPhoneTypeName: "",
      EmergencyAddress: {
        CountryId: 0,
        CountryName: "",
        AddressLine1: data.disasterAddress1 ? data.disasterAddress1 : "",
        AddressLine2: data.disasterAddress2 ? data.disasterAddress2 : "",
        ZipCode: data.disasterZipCode ? data.disasterZipCode : "",
        City: data.disasterCity ? data.disasterCity : "",
        StateId: data.disasterState ? data.disasterState : "",
        StateName: "",
        Parish: ""
      }
    }
  };
  return body;
};

export const getEmergencyStateSchema = data => {
  let formatter = new StringMask("000-000-0000");
  const {
    EmergencyPrimaryContact,
    EmergencySecondaryContact,
    DisasterLocation
  } = data;
  const body = {
    disasterLocationPhone: DisasterLocation.CellPhoneNumber
      ? formatter.apply(DisasterLocation.CellPhoneNumber)
      : "",
    disasterLocationPhoneType: DisasterLocation.CellPhoneTypeId
      ? DisasterLocation.CellPhoneTypeId.toString()
      : "",
    disasterState: DisasterLocation.EmergencyAddress.StateId
      ? DisasterLocation.EmergencyAddress.StateId
      : "",
    disasterAddress1: DisasterLocation.EmergencyAddress.AddressLine1
      ? DisasterLocation.EmergencyAddress.AddressLine1
      : "",
    disasterAddress2: DisasterLocation.EmergencyAddress.AddressLine2
      ? DisasterLocation.EmergencyAddress.AddressLine2
      : "",
    disasterCity: DisasterLocation.EmergencyAddress.City
      ? DisasterLocation.EmergencyAddress.City
      : "",
    disasterZipCode: DisasterLocation.EmergencyAddressZipCode
      ? DisasterLocation.EmergencyAddressZipCode
      : "",
    locationName: DisasterLocation.LocationName
      ? DisasterLocation.LocationName
      : "",
    primaryContactState: EmergencyPrimaryContact.EmergencyAddress.StateId
      ? EmergencyPrimaryContact.EmergencyAddress.StateId
      : "",
    primaryContactAddress1: EmergencyPrimaryContact.EmergencyAddress
      .AddressLine1
      ? EmergencyPrimaryContact.EmergencyAddress.AddressLine1
      : "",
    primaryContactAddress2: EmergencyPrimaryContact.EmergencyAddress
      .AddressLine2
      ? EmergencyPrimaryContact.EmergencyAddress.AddressLine2
      : "",
    primaryContactCellPhoneNumber: EmergencyPrimaryContact.CellPhoneNumber
      ? formatter.apply(EmergencyPrimaryContact.CellPhoneNumber)
      : "",
    primaryContactCity: EmergencyPrimaryContact.EmergencyAddress.City
      ? EmergencyPrimaryContact.EmergencyAddress.City
      : "",
    primaryContactHomePhone: EmergencyPrimaryContact.HomePhoneNumber
      ? formatter.apply(EmergencyPrimaryContact.HomePhoneNumber)
      : "",
    primaryContactName: EmergencyPrimaryContact.Name
      ? EmergencyPrimaryContact.Name
      : "",
    primaryContactPersonalEmail: EmergencyPrimaryContact.EmailId
      ? EmergencyPrimaryContact.EmailId
      : "",
    primaryContactRelationship: EmergencyPrimaryContact.RelationshipId
      ? EmergencyPrimaryContact.RelationshipId
      : "",
    primaryContactWorkPhoneNumber: EmergencyPrimaryContact.WorkPhoneNumber
      ? formatter.apply(EmergencyPrimaryContact.WorkPhoneNumber)
      : "",
    primaryContactZipCode: EmergencyPrimaryContact.EmergencyAddressZipCode
      ? EmergencyPrimaryContact.EmergencyAddressZipCode
      : "",
    secContactState: EmergencySecondaryContact.EmergencyAddress.StateId
      ? EmergencySecondaryContact.EmergencyAddress.StateId
      : "",
    secContactAddress1: EmergencySecondaryContact.EmergencyAddress.AddressLine1
      ? EmergencySecondaryContact.EmergencyAddress.AddressLine1
      : "",
    secContactAddress2: EmergencySecondaryContact.EmergencyAddress.AddressLine2
      ? EmergencySecondaryContact.EmergencyAddress.AddressLine2
      : "",
    secContactCellPhoneNumber: formatter.apply(
      EmergencySecondaryContact.CellPhoneNumber
    ),
    secContactCity: EmergencySecondaryContact.EmergencyAddress.City
      ? EmergencySecondaryContact.EmergencyAddress.City
      : "",
    secContactHomePhone: EmergencySecondaryContact.HomePhoneNumber
      ? formatter.apply(EmergencySecondaryContact.HomePhoneNumber)
      : "",
    secConatctName: EmergencySecondaryContact.Name
      ? EmergencySecondaryContact.Name
      : "",
    secContactPersonalEmail: EmergencySecondaryContact.EmailId
      ? EmergencySecondaryContact.EmailId
      : "",
    secConatctRelationship: EmergencySecondaryContact.RelationshipId,
    secContactWorkPhoneNumber: formatter.apply(
      EmergencySecondaryContact.WorkPhoneNumber
    ),
    secContactZipCode: EmergencySecondaryContact.EmergencyAddressZipCode
      ? EmergencySecondaryContact.EmergencyAddressZipCode
      : ""
  };
  return body;
};
