export const SET_ADD_NOTES_DATA = "SET_ADD_NOTES_DATA";
export const CLEAR_ADD_NOTES_DATA = "CLEAR_ADD_NOTES_DATA";

const addEmployeeNotes = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ADD_NOTES_DATA:
      const { name, values } = payload;
      return { ...state, [name]: values };
    default:
      return state;
  }
};

export default addEmployeeNotes;

export const setAddEmployeeNotesValues = (name, values) => ({
  type: SET_ADD_NOTES_DATA,
  payload: { name, values }
});

export const clearAddEmployeeNotesValues = () => ({
  type: CLEAR_ADD_NOTES_DATA
});
