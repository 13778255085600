export default function tabItemsList(data = {}) {
  return [
    {
      label: "Address Verification",
      value: 1,
      picUrl: require("../images/unapproved_pto.png"),
      svgKey: "Address Verification",
      badgeCount: data.CountOfAddressVerification || 0
    },
    {
      label: "Minimum Wage Alerts",
      value: 2,
      picUrl: require("../images/unapproved_pto.png"),
      svgKey: "Minimum Wage",
      badgeCount: data.CountOfMinimumWageAlert || 0
    },
    {
      label: "Tax Verification",
      value: 3,
      picUrl: require("../images/unapproved_pto.png"),
      svgKey: "Tax Verification",
      badgeCount: data.CountOfTaxVerification || 0
    }
  ];
}
