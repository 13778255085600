import React, { Component } from "react";
import {
  FormSection,
  FormFieldGroup
} from "component-library/formSections/index";
import * as FIELDS from "../utils/Constants";
import VerifiedBy from "./VerifiedBy";

const componentProps = {
  inputId: FIELDS.IS_VERIFIED,
  label: "Has this employment been verified?",
  radios: [
    {
      id: 1,
      label: "Yes",
      value: true,
      radioStyles: "radioStyles"
    },
    {
      id: 0,
      label: "No",
      value: false,
      radioStyles: "radioStyles"
    }
  ],
  inputName: FIELDS.IS_VERIFIED,
  type: "basic",
  inputType: "radio",
  required: true,
  colSpan: "col-lg-3 col-md-3 col-sm-3",
  fieldType: "inline"
};

export default class Verification extends Component {
  render() {
    const { data = {}, onChange, getFromToValue, errors } = this.props;
    return (
      <div className="tab-container-add-sub-section">
        <div className="section-items">
          <FormSection title="Verification">
            <FormFieldGroup
              {...componentProps}
              value={data[FIELDS.IS_VERIFIED]}
              onChange={onChange}
              error={errors[FIELDS.IS_VERIFIED]}
            />
            {data[FIELDS.IS_VERIFIED] && (
              <div>
                <FormFieldGroup
                  inputType="singleDatePicker"
                  inputName={FIELDS.VERIFICATION_DATE}
                  value={data[FIELDS.VERIFICATION_DATE]}
                  label="When was it Verified?"
                  onChange={onChange}
                  Size={[3, 3, 7, 12]}
                  fieldType="inline"
                  colSpan="col-lg-3 col-md-3 col-sm-3"
                  getFromToValue={getFromToValue}
                  optional={true}
                />

                <FormFieldGroup
                  inputType="text"
                  inputName={FIELDS.VERIFIED_WITH}
                  value={data[FIELDS.VERIFIED_WITH]}
                  label={"Verified With"}
                  onChange={onChange}
                  Size={[4, 4, 7, 12]}
                  fieldType="inline"
                  colSpan="col-lg-3 col-md-3 col-sm-3"
                  optional={true}
                />

                <FormFieldGroup
                  inputType="text"
                  inputName={FIELDS.VERIFIED_WITH_TITLE}
                  value={data[FIELDS.VERIFIED_WITH_TITLE]}
                  label={"Title"}
                  onChange={onChange}
                  Size={[4, 4, 7, 12]}
                  fieldType="inline"
                  colSpan="col-lg-3 col-md-3 col-sm-3"
                  optional={true}
                />

                <FormFieldGroup
                  inputType="numeric"
                  inputName={FIELDS.VERIFIED_WITH_PHONE_NUMBER}
                  value={data[FIELDS.VERIFIED_WITH_PHONE_NUMBER]}
                  label="Phone Number"
                  onChange={onChange}
                  Size={[3, 3, 7, 12]}
                  fieldType="inline"
                  colSpan="col-lg-3 col-md-3 col-sm-3"
                  optional={true}
                  placeholder={"504-888-0093"}
                  format="###-###-####"
                />

                <FormFieldGroup
                  inputType="text"
                  inputName={FIELDS.VERIFIED_WITH_EMAIL}
                  value={data[FIELDS.VERIFIED_WITH_EMAIL]}
                  label={"Email Address"}
                  onChange={onChange}
                  Size={[4, 4, 7, 12]}
                  fieldType="inline"
                  colSpan="col-lg-3 col-md-3 col-sm-3"
                  optional={true}
                />

                <VerifiedBy
                  data={data}
                  label="Verified By"
                  onChange={onChange}
                  optional={true}
                />
              </div>
            )}
          </FormSection>
        </div>
      </div>
    );
  }
}
