import React, { Fragment } from "react";
import Checkbox from "./CheckBox";
import "./checkboxes.css";
import classNames from "classnames/bind";

const renderOption = (
  onChange,
  data = {},
  name,
  dualColumn,
  threeColumn,
  isGroup
) => item => {
  const { optionId, label, disabled } = item;
  let className = classNames(
    dualColumn && "CheckboxGroupList col-lg-6 col-md-6 col-sm-6 col-xs-6",
    threeColumn && "CheckboxGroupList col-lg-4 col-md-4 col-sm-4 col-xs-12",
    data[optionId] && "selected",
    disabled && "disabled-checkbox"
  );
  return (
    <Checkbox
      key={optionId}
      id={name + "-" + optionId}
      classname={className}
      value={data[optionId]}
      label={label}
      disabled={disabled}
      onChange={
        onChange
          ? () => {
            if (isGroup) {
              onChange({
                name,
                value: { ...data, [optionId]: !data[optionId] }
              });
            } else {
              onChange({
                name: optionId,
                value: !data[optionId]
              });
            }
          }
          : () => { }
      }
    />
  );
};

const CheckboxGroup = ({
  onChange,
  value,
  name,
  options = [],
  dualColumn,
  threeColumn,
  isGroup,
  disabled,
  error
}) => {
  let className = classNames(
    "checkbox-list",
    dualColumn && "col-lg-6 col-md-8 col-sm-10 col-xs-12",
    threeColumn && "col-lg-10 col-md-12 col-sm-12 col-xs-12",
    disabled && "disabled-checkbox"

  );
  return (
    <Fragment>
      <ul className={className}>
        {options.map(
          renderOption(onChange, value, name, dualColumn, threeColumn, isGroup)
        )}
      </ul>
      {error && (
        <div
          style={{ marginTop: "-5px", marginBottom: "20px" }}
          className="has-error"
        >
          {error}
        </div>
      )}
    </Fragment>
  );
};

export default CheckboxGroup;
