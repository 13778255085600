// import React from "react";
// import { Popover, OverlayTrigger } from "react-bootstrap";

// const CalendarPayrollItem = ({
//   label,
//     lvalue,
//     rvalue,
//     ldate,
//     rdate,
//     groupIcon,
//     customIcon,
//     dollarIcon,
//     holidayCalendarIcon,
//     info,
//     className,
//     visible = true
// }) => {
//     const CalendarPayrollPopOver = (
//         <Popover
//             id="holiday-list-bottom"
//             className="payroll-dash-summary-icon-list"
//         >
//             <div className="payroll-dash-summary-icon-list-icon clearfix">
//                 <i
//                     className="fa fa-times-circle-o icon"
//                     onClick={() => this.refs.overlayCalendarPayrollPopOver.hide()}
//                 />
//             </div>
//         </Popover>
//     );
//     if (visible)
//         return (
//             <div className={`payroll-dash-summary-item ${className} clearfix`}>
//                 {label ? <div className="payroll-dash-summary-item-label">{label}</div> : null}

//                 <div className="payroll-dash-summary-item-value">
//                     {dollarIcon && (
//                         <i
//                             className="fa fa-usd payroll-dash-summary-item-dollarIcon"
//                             aria-hidden="true"
//                         />
//                     )}
//                     {lvalue}
//                 </div>
//                 {groupIcon && (
//                     <div>
//                         <i
//                             className={`fa fa-${
//                                 groupIcon
//                                 } payroll-dash-summary-item-groupIcon`}
//                             aria-hidden="true"
//                         />
//                         <div className="payroll-dash-summary-item-value">{rvalue}</div>
//                     </div>
//                 )}

//                 {customIcon && (
//                     <div>
//                         <div className="payroll-dash-summary-item-customIcon">
//                             {customIcon}
//                         </div>
//                         <div className="payroll-dash-summary-item-value">{rvalue}</div>
//                     </div>
//                 )}

//                 {ldate && (
//                     <div className="holiday-calendar-summary-item-section">
//                         <div className="payroll-dash-summary-item-value holiday-calendar-summary-item-value">{ldate}</div>
//                         <div className="payroll-dash-summary-item-customIcon holiday-calendar-summary-item-holidayCalendarIcon">
//                             {holidayCalendarIcon}
//                         </div>
//                         <div className="payroll-dash-summary-item-value holiday-calendar-summary-item-value">{rdate}</div>
//                     </div>
//                 )}

//                 {info && (
//                     <div className="payroll-dash-summary-item-icon">
//                         <OverlayTrigger
//                             trigger="click"
//                             placement="bottom"
//                             ref="overlayPaycheckDateCalendar"
//                             overlay={CalendarPayrollPopOver}
//                             rootClose={true}
//                         >
//                             <i className="fa-information-blue fa fa-info-circle" />
//                         </OverlayTrigger>
//                     </div>
//                 )}
//             </div>
//         );
//     else {
//         return null;
//     }
// };

// export default CalendarPayrollItem;

/////////////////////////////////////////////////////////////
import React, { Component } from "react";
//import { Popover, OverlayTrigger } from "react-bootstrap";

export default class CalendarPayrollItem extends Component {
  render() {
    const {
      label,
      lvalue,
      rvalue,
      ldate,
      rdate,
      groupIcon,
      customIcon,
      dollarIcon,
      holidayCalendarIcon,
      //info,
      className,
      visible = true
    } = this.props;
    // const CalendarPayrollPopOver = (
    //   <Popover
    //     id="holiday-list-bottom"
    //     className="payroll-dash-summary-icon-list payroll-dash-summary-icon-list-bgcolor"
    //   >
    //     <div className="payroll-dash-summary-icon-list-icon clearfix">
    //       <i
    //         className="fa fa-times-circle-o icon"
    //         onClick={() => this.refs.overlayCalendarPayrollPopOver.hide()}
    //       />
    //     </div>
    //   </Popover>
    // );
    if (visible)
      return (
        <div
          className={`payroll-dash-summary-item ${className} clearfix`}
          style={{ width: "17%" }}
        >
          {label ? (
            <div className="payroll-dash-summary-item-label">{label}</div>
          ) : null}

          <div className="payroll-dash-summary-item-value">
            {dollarIcon && (
              <i
                className="fa fa-usd payroll-dash-summary-item-dollarIcon"
                aria-hidden="true"
              />
            )}
            {lvalue}
          </div>
          {groupIcon && (
            <div>
              <i
                className={`fa fa-${groupIcon} payroll-dash-summary-item-groupIcon`}
                aria-hidden="true"
              />
              <div className="payroll-dash-summary-item-value">{rvalue}</div>
            </div>
          )}

          {customIcon && (
            <div>
              <div className="payroll-dash-summary-item-customIcon">
                {customIcon}
              </div>
              <div className="payroll-dash-summary-item-value">{rvalue}</div>
            </div>
          )}

          {ldate && (
            <div className="holiday-calendar-summary-item-section">
              <div className="payroll-dash-summary-item-value holiday-calendar-summary-item-value">
                {ldate}
              </div>
              <div className="payroll-dash-summary-item-customIcon holiday-calendar-summary-item-holidayCalendarIcon">
                {holidayCalendarIcon}
              </div>
              <div className="payroll-dash-summary-item-value holiday-calendar-summary-item-value">
                {rdate}
              </div>
            </div>
          )}

          {/* {info && (
            <div className="payroll-dash-summary-item-icon">
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                ref="overlayPaycheckDateCalendar"
                overlay={CalendarPayrollPopOver}
                rootClose={true}
              >
                <i className="fa-information-blue fa fa-info-circle" />
              </OverlayTrigger>
            </div>
          )} */}
        </div>
      );
    else {
      return null;
    }
  }
}
