import React from "react";
import getInputComponent from "../../../../inputFields/getInputComponent";
const RadioButton = getInputComponent("radio");

const IncludedInPayroll = props => {
  return (
    <div
      className="nc-field-group clearfix"
      style={{ clear: "both", display: "flex", alignItems: "center" }}
    >
      <div
        id={"Label" + props.inputName}
        style={{ paddingRight: "15px" }}
        className={`${
          props.colSpan
        } " " ${"col-lg-3 col-md-3 field-name clearfix"}`}
      >
        {props.label}{" "}
      </div>
      <div
        className="col-lg-9 col-md-9"
        style={{ display: "flex", alignItems: "center" }}
      >
        <RadioButton
          inputType="radio"
          radios="boolean"
          optional
          name={props.inputName}
          value={props.value}
          onChange={props.onChange}
        />
        {props.value &&
          !props.showLink && (
            <div
              style={{
                marginBottom: "20px",
                marginRight: "10px",
                color: "#009999"
              }}
              onClick={() => {
                props.onChange({
                  name: props.linkName,
                  value: true
                });
              }}
            >
              {props.link}
            </div>
          )}
      </div>
    </div>
  );
};

export default IncludedInPayroll;
