import React from "react";
import { Modal } from "react-bootstrap";

import EntitySnapshotTable from "./../../entity-snapshot/EntitySnapshotTable";
import EntitySnapshotHeader from "./../../entity-snapshot/EntitySnapshotHeader";

import "./PopUpModal.css";

const SnapshotModal = ({ show, closeModal, snapData = () => {} }) => (
  <Modal
    bsClass="list-page-delete modal"
    id="activity-history"
    animation={false}
    backdrop={"static"}
    keyboard={false}
    show={show}
  >
    <EntitySnapshotHeader data={snapData} onHide={closeModal} />
    <Modal.Body>
      <div className="nc-enity-snapshot-summary">
        <EntitySnapshotTable nodes={snapData.CurrentValueSnapshot} />
      </div>
    </Modal.Body>
  </Modal>
);

export default SnapshotModal;
