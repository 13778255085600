import React, { Component } from "react";
import MultiSectionTableHeader from "./MultiSectionTableHeader";
import MultiSectionTableActionLinks from "./MultiSectionTableActionLinks";
import UnapprovedPtosItem from "./UnapprovedPtosItem";
import InfiniteScroll from "react-infinite-scroller";
import { UN_APPROVED_PTO } from "../unapproved-pto/Constants";
const UnapprovedPtosColumns = [
  {
    label: "Employee",
    field: "Employee",
    className: "col-lg-12 col-md-12 pto-employee-header"
  },
  {
    label: "Type",
    field: "Type",
    className: "col-lg-2 col-md-2 pto-type-header"
  },
  {
    label: "Date Range",
    field: "DateRange",
    className: "col-lg-2 col-md-2 pto-date-range-header",
    noSort: true
  },
  {
    label: "Time Requested",
    field: "TimeRequested",
    className: "col-lg-2 col-md-2 pto-time-requested-header",
    noSort: true
  },
  {
    label: "Available",
    field: "Available",
    className: "col-lg-2 col-md-2 pto-available-header",
    noSort: true
  },
  {
    label: "Future Approved",
    field: "FutureApproved",
    className: "col-lg-1 col-md-1 pto-future-approved",
    noSort: true
  },
  {
    label: "Adjusted Available",
    field: "AdjustedAvailable",
    className: "col-lg-1 col-md-1 pto-adjusted-available-header",
    noSort: true
  }
];

export default class UnapprovedPtosTable extends Component {
  state = {
    loadingMore: false
  };
  areAllSelected = () => {
    const { selectedOptions = {}, data } = this.props;
    let all = true;
    data.forEach(
      ({ EmployeeId }) => (all = all && selectedOptions[EmployeeId])
    );
    return all;
  };
  shouldShowBulkActions = () => {
    const { selectedOptions = {}, data } = this.props;
    let some = false;
    data.forEach(({ EmployeeId }) => {
      if (selectedOptions[EmployeeId]) {
        some = true;
      }
    });
    return some;
  };
  render() {
    const {
      data = [],
      sortOrder,
      changeSortOrder = () => {},
      UserTestId,
      approve = () => {},
      bulkApprovePto = () => {},
      onContactAllClick = () => {},
      onContactClick = () => {},
      loadMorePosts = () => {},
      options = {},
      selectedOptions = {},
      makeOnSelect = () => undefined,
      makeSelectAll = () => undefined
    } = this.props;
    return (
      <div className="clearfix">
        <MultiSectionTableActionLinks
          onSelectAll={makeSelectAll(UN_APPROVED_PTO)}
          showBulkActions={this.shouldShowBulkActions()}
          onActionClick={bulkApprovePto}
          UserTestId={UserTestId}
          areAllSelected={this.areAllSelected()}
          onContactAllClick={() => onContactAllClick()}
        />
        <MultiSectionTableHeader
          columns={UnapprovedPtosColumns}
          sortOrder={sortOrder}
          changeSortOrder={changeSortOrder}
          payrollType={"unapprovedPtos"}
        />
        <InfiniteScroll
          pageStart={0}
          loadMore={
            !this.state.loadingMore
              ? loadMorePosts
              : () => console.log("no more items")
          }
          hasMore={false}
          loader={this.state.loadingMore ? <span>Loading...</span> : null}
        >
          {data.map((row, i) => (
            <UnapprovedPtosItem
              key={i}
              onSelect={makeOnSelect(UN_APPROVED_PTO)}
              selected={selectedOptions[row.EmployeeId]}
              options={options}
              onApprove={() => {
                let bulkData = [];
                bulkData.push(row.EmployeeId);
                approve(
                  UserTestId,
                  null,
                  bulkData,
                  this.props.payrollTypeData.UnapprovedPtos.Data,
                  this.props.payrollData,
                  () => {
                    this.setState({
                      selectedOptions: !selectedOptions,
                      allSelected: false
                    });
                  }
                );
              }}
              onContactClick={() => onContactClick(row.EmployeeId)}
              {...row}
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  }
}
