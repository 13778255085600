import React from "react";

const TimeGroup = ({ width, height, style }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 48.4 54.3"
      style={{ enableBackground: "new 0 0 48.4 54.3", ...style }}
      height={height}
      width={width}
      xmlSpace="preserve"
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{ __html: "\n\t.st0{fill:#555555;}\n" }}
      />
      <g>
        <g>
          <g>
            <path
              className="st0"
              d="M23.2,27c-5.8,0-10.6-4.8-10.6-10.6S17.4,5.8,23.2,5.8s10.7,4.8,10.7,10.6S29,27,23.2,27z M23.2,7.6
				c-4.9,0-8.8,4-8.8,8.8s4,8.8,8.8,8.8s8.9-4,8.9-8.8S28.1,7.6,23.2,7.6z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="st0"
              d="M29.2,17.3h-6.1c-0.5,0-0.9-0.3-0.9-0.9V9.9c0-0.5,0.3-0.9,0.9-0.9C23.6,9,24,9.3,24,9.9v5.6h5.2
				c0.5,0,0.9,0.3,0.9,0.9C30.1,17,29.7,17.3,29.2,17.3z"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          className="st0"
          d="M24.9,38.9h-1c2.4-0.3,4.2-2.3,4.2-4.7c0-2.7-2.2-4.8-4.8-4.8s-4.8,2.1-4.8,4.8c0,2.4,1.8,4.4,4.2,4.7h-1
		c-4.1,0-7.5,3.4-7.5,7.5v1.3v2.2c0,0.6,0.4,1,1,1h1.4H30h1.4c0.6,0,1-0.4,1-1v-2.2v-1.3C32.4,42.3,29,38.9,24.9,38.9z M20.5,34.2
		c0-1.5,1.3-2.8,2.8-2.8s2.8,1.3,2.8,2.8S24.8,37,23.3,37S20.5,35.7,20.5,34.2z M30.4,48.9H16.2v-2.5c0-3,2.5-5.5,5.5-5.5h3.2
		c3,0,5.5,2.5,5.5,5.5C30.4,46.4,30.4,48.9,30.4,48.9z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M12.7,46.4c0-0.3,0-0.6,0.1-0.9H5.5V43c0-3,2.5-5.5,5.5-5.5h3.2c1.2,0,2.3,0.4,3.2,1.1c0.3-0.2,0.6-0.3,1-0.5
		c-0.5-0.6-0.8-1.2-1-1.9c-1-0.5-2-0.7-3.2-0.7h-1c2.4-0.3,4.2-2.3,4.2-4.7c0-2.7-2.2-4.8-4.8-4.8s-4.8,2.1-4.8,4.8
		c0,2.4,1.8,4.4,4.2,4.7h-1c-4.1,0-7.5,3.4-7.5,7.5v1.3v2.2c0,0.6,0.4,1,1,1h1.4h6.8C12.7,47.5,12.7,46.4,12.7,46.4z M9.8,30.8
		c0-1.5,1.3-2.8,2.8-2.8s2.8,1.3,2.8,2.8s-1.3,2.8-2.8,2.8S9.8,32.3,9.8,30.8z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M35.5,35.5h-1c2.4-0.3,4.2-2.3,4.2-4.7c0-2.7-2.2-4.8-4.8-4.8s-4.8,2.1-4.8,4.8c0,2.4,1.8,4.4,4.2,4.7h-1
		c-1.1,0-2.2,0.3-3.2,0.7c-0.2,0.7-0.6,1.3-1,1.9c0.3,0.1,0.7,0.3,1,0.5c0.9-0.7,2-1.1,3.2-1.1h3.2c3,0,5.5,2.5,5.5,5.5v2.5h-7.3
		c0,0.3,0.1,0.6,0.1,0.9v1.1h6.8H42c0.6,0,1-0.4,1-1v-2.2V43C43,38.9,39.6,35.5,35.5,35.5z M31.1,30.8c0-1.5,1.3-2.8,2.8-2.8
		s2.8,1.3,2.8,2.8s-1.3,2.8-2.8,2.8S31.1,32.3,31.1,30.8z"
        />
      </g>
    </svg>
  );
};

export default TimeGroup;
