import React from "react";
import IconCheckbox from "./IconCheckbox";
import * as Constants from "../Constants";
import classNames from "classnames/bind";

const renderOption = (
  id,
  onChange,
  data = {},
  name,
  dualColumn,
  threeColumn,
  isGroup
) => item => {
  const { optionId, label, id, disabled } = item;
  let className = classNames(
    dualColumn && "col-lg-6 col-md-6 col-sm-6 col-xs-6",
    threeColumn && "col-lg-3 col-md-4 col-sm-4 col-xs-12",
    data[optionId] && "selected",
    disabled && "disabled-checkbox"
  );
  return (
    <IconCheckbox
      key={optionId}
      id={id}
      classname={className}
      value={data[optionId]}
      label={label}
      disabled={disabled}
      onChange={
        onChange
          ? () => {
              if (isGroup) {
                onChange({
                  name: "CommonHolidays",
                  value: { ...data, [optionId]: !data[optionId] }
                });
              } else {
                onChange({
                  name: optionId,
                  value: !data[optionId]
                });
              }
            }
          : () => {}
      }
    />
  );
};

const areAllSelected = (options, data = {}) => {
  let all = true;
  options.forEach(({ value }) => (all = all && data[value]));
  return all;
};
const onSelectAll = (options, data = {}, onChange) => {
  const allSelected = areAllSelected(options, data);
  let selectedOptions = {};
  options.forEach(({ value }) => (selectedOptions[value] = !allSelected));
  onChange({
    name: "CommonHolidays",
    value: selectedOptions
  });
};

const IconCheckboxGroup = ({
  id,
  onChange,
  value,
  name,
  options = [],
  dualColumn,
  threeColumn,
  isGroup
}) => {
  let className = classNames(
    "checkbox-list holiday-checkbox-list",
    dualColumn && "col-lg-6 col-md-8 col-sm-10 col-xs-12",
    threeColumn && "col-lg-12 col-md-12 col-sm-12 col-xs-12"
  );
  return (
    <ul className={className}>
      <IconCheckbox
        id={Constants.SELECT_ALL_ID}
        classname="col-lg-3 col-md-4 col-sm-4 col-xs-12"
        value={areAllSelected(options, value)}
        label="Select All"
        disabled={false}
        onChange={() => onSelectAll(options, value, onChange)}
      />
      {options.map(
        renderOption(
          id,
          onChange,
          value,
          name,
          dualColumn,
          threeColumn,
          isGroup
        )
      )}
    </ul>
  );
};

export default IconCheckboxGroup;
