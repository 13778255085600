import React from "react";
import { FormFieldGroup, FormSection } from "../../../formSections";
import * as Fields from "./FieldNames";
const labelMaker = (noLabel, allLabel, someLabel, oneLabel) => (o, s, all) =>
  all
    ? allLabel
    : Object.keys(s).filter(k => s[k]).length === 0
      ? noLabel
      : Object.keys(s).filter(k => s[k]).length === 1
        ? oneLabel
        : someLabel;

const EmployeeStatus = ({
  onChange,
  data,
  options = {},
  makeOnSelect,
  makeOnSelectAll,
  selectedOptions,
  makeDateRangeSelect
}) => {
  return (
    <FormSection>
      <div className="column-title">Employee Status</div>
      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.employeeStatus}
        value={data[Fields.employeeStatus]}
        options={options[Fields.employeeStatus]}
        selectedItems={selectedOptions[Fields.employeeStatus]}
        multiSelect
        makeLabel={labelMaker("Status", "Status", "Status", "Status")}
        onChange={makeOnSelect(Fields.employeeStatus)}
        selectAll={makeOnSelectAll(Fields.employeeStatus)}
        placeholder="Select"
        label="Status"
        Size={[12, 12, 7, 12]}
      />
      <div className="col-lg-12">
        <div className="col-lg-6" style={{ paddingRight: "5px" }}>
          <FormFieldGroup
            inputType="dateRangePicker"
            inputName={Fields.hireDate}
            value={data[Fields.hireDate]}
            onChange={makeDateRangeSelect}
            label="Hire Date"
            onHide={() => {}}
            style={{ marginBottom: "20px" }}
            placeholder="Select Range"
            Size={[12, 12, 7, 12]}
          />
        </div>
        <div className="col-lg-6" style={{ paddingLeft: "5px" }}>
          <FormFieldGroup
            inputType="dateRangePicker"
            inputName={Fields.rehireDate}
            value={data[Fields.rehireDate]}
            onChange={makeDateRangeSelect}
            label="Re-Hire Date"
            onHide={() => {}}
            style={{ marginBottom: "20px" }}
            placeholder="Select Range"
            Size={[12, 12, 7, 12]}
          />
        </div>
      </div>
      <div className="col-lg-12">
        <div className="col-lg-6" style={{ paddingRight: "5px" }}>
          <FormFieldGroup
            inputType="dateRangePicker"
            inputName={Fields.terminationDate}
            value={data[Fields.terminationDate]}
            onChange={makeDateRangeSelect}
            label="Termination Date"
            placeholder="Select Range"
            onHide={() => {}}
            style={{ marginBottom: "20px" }}
            Size={[12, 12, 7, 12]}
          />
        </div>
        <div className="col-lg-6" style={{ paddingLeft: "5px" }}>
          <FormFieldGroup
            inputType="dateRangePicker"
            inputName={Fields.reTerminationDate}
            value={data[Fields.reTerminationDate]}
            onChange={makeDateRangeSelect}
            label="Re-Termination Date"
            onHide={() => {}}
            style={{ marginBottom: "20px" }}
            placeholder="Select Range"
            Size={[12, 12, 7, 12]}
          />
        </div>
      </div>
      <div className="col-lg-12">
        <div className="col-lg-6" style={{ paddingRight: "5px" }}>
          <FormFieldGroup
            inputType="dateRangePicker"
            inputName={Fields.inactiveStartDate}
            value={data[Fields.inactiveStartDate]}
            onChange={makeDateRangeSelect}
            label="Inactive Start Date"
            placeholder="Select Range"
            onHide={() => {}}
            style={{ marginBottom: "20px" }}
            Size={[12, 12, 7, 12]}
          />
        </div>
        <div className="col-lg-6" style={{ paddingLeft: "5px" }}>
          <FormFieldGroup
            inputType="dateRangePicker"
            inputName={Fields.inactiveEndDate}
            value={data[Fields.inactiveEndDate]}
            onChange={makeDateRangeSelect}
            label="Inactive End Date"
            onHide={() => {}}
            style={{ marginBottom: "20px" }}
            placeholder="Select Range"
            Size={[12, 12, 7, 12]}
          />
        </div>
      </div>
      <div className="col-lg-12">
        <div className="col-lg-6" style={{ paddingRight: "5px" }}>
          <FormFieldGroup
            inputType="dateRangePicker"
            inputName={Fields.leaveStartDate}
            value={data[Fields.leaveStartDate]}
            onChange={makeDateRangeSelect}
            label="Leave Start Date"
            placeholder="Select Range"
            onHide={() => {}}
            style={{ marginBottom: "20px" }}
            Size={[12, 12, 7, 12]}
          />
        </div>
        <div className="col-lg-6" style={{ paddingLeft: "5px" }}>
          <FormFieldGroup
            inputType="dateRangePicker"
            inputName={Fields.leaveEndDate}
            value={data[Fields.leaveEndDate]}
            onChange={makeDateRangeSelect}
            label="Leave End Date"
            onHide={() => {}}
            style={{ marginBottom: "20px" }}
            placeholder="Select Range"
            Size={[12, 12, 7, 12]}
          />
        </div>
      </div>
      <FormFieldGroup
        inputType="dateRangePicker"
        inputName={Fields.transferDate}
        value={data[Fields.transferDate]}
        onChange={makeDateRangeSelect}
        label="Transfer Date"
        placeholder="Select Range"
        onHide={() => {}}
        style={{ marginBottom: "20px" }}
        Size={[12, 12, 7, 12]}
      />
      <FormFieldGroup
        inputType="dateRangePicker"
        inputName={Fields.retiredDate}
        value={data[Fields.retiredDate]}
        onChange={makeDateRangeSelect}
        label="Retired Date"
        placeholder="Select Range"
        onHide={() => {}}
        style={{ marginBottom: "20px" }}
        Size={[12, 12, 7, 12]}
      />
      <FormFieldGroup
        inputType="dateRangePicker"
        inputName={Fields.deceasedDate}
        value={data[Fields.deceasedDate]}
        onChange={makeDateRangeSelect}
        label="Deceased Date"
        placeholder="Select Range"
        onHide={() => {}}
        style={{ marginBottom: "20px" }}
        Size={[12, 12, 7, 12]}
      />
    </FormSection>
  );
};

export default EmployeeStatus;
EmployeeStatus.defaultProps = {
  onChange: () => {},
  data: {},
  options: {}
};
