import React, { Component } from "react";
import { TabContainer } from "component-library/employee-profile/entity-view";
import BasicInfo from "component-library/employee-profile/demographic/BasicInfo";
import Addresses from "component-library/employee-profile/demographic/Addresses";
import ConatctInfo from "component-library/employee-profile/demographic/ContactInfo";
import AdditionalInfo from "component-library/employee-profile/demographic/AdditionalInfo";
import {
  validateForPhone,
  validateFromEmptyValues,
  validateEmail
} from "../utils/index";
import {
  currentAddress,
  formerAddress,
  CURRENT_ADDRESS
} from "component-library/employee-profile/Constants";
import { Element } from "react-scroll";
import { DEMOGRAPHIC_HEADER } from "../Constants";
import { getStateSchema } from "../utils/schema";
import * as constants from "../../../../employee-files/EmployeeFilesConstants";

class PersonalInfo extends Component {
  state = {
    activePanel: {},
    initialData: this.props.data && this.props.data,
    errors: {},
    employeeId: this.props.match.params.EmployeeId,
    showToaster: false,
    toasterClass: "",
    toasterMessage: "",
    isAddModeOn: false
  };
  componentDidMount() {
    this.props.setLoader(true);
    if (this.props.employeeDemographicData.Data.length > 0) {
      let {
        CurrentAddress,
        FormerAddress
      } = this.props.employeeDemographicData.Data[0];
      this.props.fetchAndSetState(CurrentAddress.CountryId, "currentStates");
      this.props.fetchAndSetState(FormerAddress.CountryId, "formerStates");
    }
    this.props.fetchAndSetCountry();
    this.props.fetchAndSetNameTitle();
    this.props.fetchAndSetSuffix();
    this.props.fetchAndSetMartialStatus();
    this.props.fetchAndSetRace();
    this.props.fetchAndSetGender();
    this.props.fetchAndSetCellPhoneCarrier();
  }

  componentWillUnmount() {
    this.props.clearDemographicValues();
  }

  static getDerivedStateFromProps(props, prevState) {
    if (props.employeeDemographicData.Data.length > 0) {
      let demographic = props.employeeDemographicData.Data[0].Demographics;
      demographic["CurrentAddress"] =
        props.employeeDemographicData.Data[0].CurrentAddress;
      demographic["FormerAddress"] =
        props.employeeDemographicData.Data[0].FormerAddress;
      let body = getStateSchema(demographic);
      props.data["demographic"] = demographic;
      return { ...body, initialData: props.data };
    } else {
      return {
        initialData: props.data,
        SSN: "Show Social Security Number",
        DOB: "Show Birth Date & Age",
        protectedVeteran: "true",
        disability: "false"
      };
    }
  }

  performValidation = () => {
    let values = this.state;
    let errors = {};
    if (
      !validateFromEmptyValues(values, "unmaskedSSN") ||
      !validateForPhone(values.unmaskedSSN)
    ) {
      errors["unmaskedSSN"] = "*value cannot be empty or in wrong format";
    }
    if (values.personalEmail && !validateEmail(values.personalEmail)) {
      errors["personalEmail"] = "*Please input correct email.";
    }
    if (values.homePhone && !validateForPhone(values.homePhone)) {
      errors["homePhone"] =
        "*Input the value in correct format eg. 333-433-2223";
    }
    if (values.cellPhoneNumber && !validateForPhone(values.cellPhoneNumber)) {
      errors["cellPhoneNumber"] =
        "*Input the value in correct format eg. 333-433-2223";
    }
    if (Object.keys(errors).length === 0) {
      this.setState({ errors }, () =>
        this.props.updateEmployeeData(this.state, this.state.employeeId)
      );
    } else {
      this.setState({ errors });
    }
  };

  handleLink = k => {
    let data =
      k === CURRENT_ADDRESS
        ? Object.keys(currentAddress)
        : Object.keys(formerAddress);
    let mapData =
      k === CURRENT_ADDRESS
        ? Object.keys(formerAddress)
        : Object.keys(currentAddress);

    let addressObject = {};
    mapData.map((k, i) => (addressObject[k] = this.state[data[i]]));
    this.setState({
      ...addressObject
    });
  };

  toggleToaster = () => {
    this.setState({
      showToaster: false
    });
  };

  setToaster = (toasterMessage, toasterClass) => {
    this.setState({
      toasterClass: toasterClass,
      toasterMessage: toasterMessage,
      showToaster: true
    });
  };

  onChange = e => {
    let name = e.target ? e.target.name : e.name;
    let value = e.target ? e.target.value : e.value;
    if (name === "country") {
      this.props.fetchAndSetState(value, "currentStates");
    } else if (name === "f_country") {
      this.props.fetchAndSetState(value, "formerStates");
    }
    this.setState({
      [name]: value
    });
  };

  onFileClick = () => {
    const EmployeeId = this.props.match.params.EmployeeId;
    this.props.history.push(
      `/people/employees/dashboard/${EmployeeId}/personalInfo/${
        constants.DEMOGRAPHICSENTITYID
      }/files`
    );
  };

  render() {
    // const { ActivePanelId } = this.props.match.params;
    // let activePanelId = parseInt(ActivePanelId, 10);
    return (
      <div>
        <TabContainer
          activePanelHeader={DEMOGRAPHIC_HEADER[0]}
          isAddModeOn={this.state.isAddModeOn}
          showToaster={this.state.showToaster}
          toasterClass={this.state.toasterClass}
          toasterMessage={this.state.toasterMessage}
          toggleToaster={this.toggleToaster}
          userRole={1}
          onFileClick={this.onFileClick}
        >
          <Element name="Basic Info" className="element" id="element1">
            <BasicInfo data={this.state} onChange={this.onChange} />
          </Element>
          <Element
            name="Addresses"
            className="element"
            id="element2"
            style={{ marginBottom: "15px" }}
          >
            <div className="seperator">
              <hr />
            </div>
            <Addresses
              data={this.state}
              onChange={this.onChange}
              handleLink={k => this.handleLink(k)}
            />
          </Element>
          <Element name="Contact Info" className="element" id="element3">
            <ConatctInfo data={this.state} onChange={this.onChange} />
          </Element>
          <Element name="Additional Info" className="element" id="element4">
            <AdditionalInfo
              data={this.state}
              onChange={this.onChange}
              onSave={this.performValidation}
            />
          </Element>
        </TabContainer>
      </div>
    );
  }
}

export default PersonalInfo;
