import React from "react";
import { Switch, Route } from "react-router-dom";

import PayrollDashboard from "../payrolldashboard/pages/PayrollDashboard";
import CopyEarningUsertest from "../earnings/pages/CopyEarningUsertest";
const UserTestPayrollRoutes = () => (
  <Switch>
    <Route
      path="/usertest/payroll/dashboard/:UserTestId"
      component={PayrollDashboard}
    />
    <Route
      path="/usertest/company/copyEarning/:UserTestId"
      component={CopyEarningUsertest}
    />
  </Switch>
);

export default UserTestPayrollRoutes;
