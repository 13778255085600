import React from "react";
import Media from "react-media";
import SetupProgressDesktop from "./SetupProgressDesktop";
import SetupProgressMobile from "./SetupProgressMobile";
const SetupProgress = props => {
  return (
    <Media query="(max-width: 991px)">
      {matches =>
        matches ? (
          <SetupProgressMobile {...props} />
        ) : (
          <SetupProgressDesktop {...props} />
        )
      }
    </Media>
  );
};
export default SetupProgress;
