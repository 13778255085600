import React from "react";

import "./EmailTextAlertsEntitySnapshotTable.css";

const renderNode = (nodes, navigationNode, navigateToUrl, isClientAdmin) => (
  node,
  i
) => {
  return (
    <div key={i} className="email-text-alerts-snapshot-grid-table row">
      {node.NodeType === "section" && (
        <div className="section-seperator col-xs-12" />
      )}
      <div className="hidden-sm hidden-xs">
        {node.NodeKey !== "" && (
          <div className="col-lg-12 col-md-12 column-section-header">
            <span>
              {node.NodeKey}
              {node.NodeValues[0] ? <span> - {node.NodeValues[0]}</span> : null}
            </span>
          </div>
        )}
        <div className="col-lg-12 col-md-12 column-section-content table-row">
          <span className="col-lg-4 col-md-4 column-header">
            {node.NodeValues[1] !== "" && node.NodeValues[1] + `:`}
          </span>
          <span className="col-lg-8 col-md-8">
            {node.NodeValues[2] !== "" && node.NodeValues[2]}
          </span>
        </div>
      </div>
    </div>
  );
};

const EmailTextAlertsEntitySnapshotTable = ({
  nodes = [],
  navigationNode,
  navigateToUrl,
  isClientAdmin
}) => (
  <div>
    {nodes.map(renderNode(nodes, navigationNode, navigateToUrl, isClientAdmin))}
  </div>
);

export default EmailTextAlertsEntitySnapshotTable;
