import React from "react";
import W2Image from "./W2Image";
import W2Content from "./W2content";
import Media from "react-media";

const W2Box = props => {
  const {
    xsWidth,
    smWidth,
    mdWidth,
    lgWidth,
    shadow,
    entityName = "earnings"
  } = props;
  return (
    <div className="misc-container clearfix">
      <div className="title"> W-2 Summary</div>
      <div className="description">
        {" "}
        How this {entityName} will impact the W-2:
      </div>
      <div
        className="image-container col-lg-6 col-md-6 col-sm-6 col-xs-12"
        style={{ paddingBottom: "10px" }}
      >
        <Media
          query="(max-width:767px)"
          render={() => (
            <W2Image width={xsWidth ? xsWidth : ""} shadow={shadow} />
          )}
        />
        <Media
          query="(min-width:768px) and (max-width:991px)"
          render={() => (
            <W2Image width={smWidth ? smWidth : ""} shadow={shadow} />
          )}
        />
        <Media
          query="(min-width:992px) and (max-width:1199px)"
          render={() => (
            <W2Image width={mdWidth ? mdWidth : ""} shadow={shadow} />
          )}
        />
        <Media
          query="(min-width:1200px)"
          render={() => (
            <W2Image width={lgWidth ? lgWidth : ""} shadow={shadow} />
          )}
        />
      </div>
      <div className="w2-content col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <W2Content
          w2data={props.w2BoxContent}
          associatedData={props.associatedAmounts}
          entityName={entityName}
        />
      </div>
    </div>
  );
};

export default W2Box;
