import * as types from "./index";

export function setLoader(state, label = "Loading") {
  return {
    type: types.SET_LOADER,
    payload: { state, label }
  };
}

export function setLoadCount(payload = 0) {
  return {
    type: types.SET_LOAD_COUNT,
    payload
  };
}
