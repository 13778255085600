import React from "react";
import Modal from "react-bootstrap/lib/Modal";

import "./EarningsDeleteModal.css";

const EarningsNoActionModal = ({ show, closeModal,modalTitle, message = "", Id }) => (
  <Modal
    bsClass="list-page-cancel modal"
    animation={false}
    backdrop={"static"}
    keyboard={false}
    show={show}
  >
    <Modal.Header>
      <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <i
          id="system-level-cancel-button-close-icon"
          onClick={closeModal}
          className="fa fa-times-circle-o fa-lg"
        />
      </div>
    </Modal.Header>
    <Modal.Body>
      <div>
        <div
          id={Id.modalMessage}
          className="modal-row row header-text col-xs-12 col-sm-12 col-md-12 col-lg-12"
        >
          {modalTitle}
        </div>

        <div className="message-footer col-xs-12 col-sm-12 col-md-12 col-lg-12">
          {message()}
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <div className="button-group">
        <button
          type="button"
          id={Id.modakOkButton}
          className="nc-button primary-button"
          onClick={closeModal}
        >
          {"OK"}
        </button>
      </div>
    </Modal.Footer>
  </Modal>
);

export default EarningsNoActionModal;

EarningsNoActionModal.defaultProps={
  modalTitle:"We cannot perform the action at this time due to the following reason(s):"
}
