import axios from "axios";
import * as types from "../index";
import * as common from "../../../../../app/common/index";

export function getThirdPartyPayeeSetupList() {
  const url = `${common.WEB_API_URL}/v1/system/thirdpartypayee?filter=All`;
  const request = axios.get(url);
  return {
    type: types.SYSTEM_THIRD_PARTY_PAYEE_MAIN_LIST,
    payload: request
  };
}
export function clearThirdPartyPayeeSetupList() {
  let initialState = {
    data: {}
  };
  return {
    type: types.SYSTEM_THIRD_PARTY_PAYEE_MAIN_LIST,
    payload: initialState
  };
}

export function updatedFilteredList(data) {
  let request = {
    data: data
  };
  return {
    type: types.SYSTEM_THIRD_PARTY_PAYEE_FILTERED_LIST,
    payload: request
  };
}

export function sortFilteredList(request) {
  return {
    type: types.SYSTEM_THIRD_PARTY_PAYEE_FILTERED_LIST,
    payload: request
  };
}

export function getFilteredThirdPartyPayeeSetupList(
  data,
  selectedThirdPartyPayees,
  selectedCategories,
  selectedStatus
) {
  //Id -> selectedThirdPartyPayees
  //ThirdPartyPayeeCategoryId -> selectedCategories
  //CreateWorkflowStateId -> selectedStatus

  let filterdByEntityList = [];
  let filterdByCategoryList = [];
  let filterdList = [];
  //we will need to use include method of javascript
  if (data !== undefined) {
    // Apply Entity Filter ...
    if (
      selectedThirdPartyPayees === undefined ||
      selectedThirdPartyPayees.length === 0
    )
      // No filter to apply ...
      filterdByEntityList = [];
    else {
      // Apply filter ...
      data.forEach(item => {
        if (selectedThirdPartyPayees.includes(item.Id)) {
          filterdByEntityList.push(item);
        }
      });
    }

    // Apply Category Filter ...
    if (selectedCategories === undefined || selectedCategories.length === 0)
      // No filter to apply ...
      filterdByCategoryList = [];
    else {
      // Apply filter ...
      filterdByEntityList.forEach(item => {
        if (selectedCategories.includes(item.ThirdPartyPayeeCategoryId)) {
          filterdByCategoryList.push(item);
        }
      });
    }

    // Apply Status Filter ...
    if (selectedStatus === undefined || selectedStatus.length === 0)
      // No filter to apply ...
      filterdList = [];
    else {
      // Apply filter ...
      filterdByCategoryList.forEach(item => {
        if (selectedStatus.includes(item.CreateWorkflowStateId)) {
          filterdList.push(item);
        }
      });
    }
  }

  let request = {
    data: {
      Data: filterdList,
      DataCount: filterdList.length
    }
  };

  return {
    type: types.SYSTEM_THIRD_PARTY_PAYEE_FILTERED_LIST,
    payload: request
  };
}

export function setThirdPartyPayeeData(data) {
  if (data === undefined) {
    data = [];
  }

  return {
    type: types.SYSTEM_THIRD_PARTY_PAYEE_DATA,
    payload: data
  };
}

export function getCountryStateList() {
  const url = `${common.WEB_API_URL}/v1/system/state`;
  const request = axios.get(url);
  return {
    type: types.COUNTRY_STATE_LIST,
    payload: request
  };
}

// export function setSaveSysDeductionPage(data){
//     return {
//         type : types.SYSTEM_DEDUCTION_SAVED_PAGES,
//         payload : data
//     };
// }

// export function getThirdPartyPayeeDeleteIconClickList(){
//     const url=`${common.WEB_API_MOCK_URL}`;
//     const request=axios.get(url);
//     return {
//         type : types.FETCH_THIRD_PARTY_PAYEE_DELETE_ICON_CLICK_LIST,
//         payload : request
//     };
// }

export function setSelectedFilters(
  selectedDeductions,
  selectedCategories,
  selectedStatus
) {
  let deductions = [];
  let categories = [];
  let status = [];
  selectedDeductions.forEach(dItem => {
    deductions.push(dItem);
  });

  selectedCategories.forEach(dItem => {
    categories.push(dItem);
  });

  selectedStatus.forEach(dItem => {
    status.push(dItem);
  });

  let data = {
    selectedDeductions: deductions,
    selectedThirdPartyPayees: deductions,
    selectedCategories: categories,
    selectedStatus: status
  };

  return {
    type: "SYSTEM_TPP_SELECTED_FILTERS",
    payload: data
  };
}
