import React from "react";
import { Modal } from "react-bootstrap";
import * as Fields from "./FieldNames";

const Header = ({ closeModal }) => (
  <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <i
      id="validation-modal-close-icon"
      onClick={closeModal}
      className="fa fa-times-circle-o fa-lg"
    />
  </div>
);

const Footer = ({ onCancel, onSave }) => (
  <div className="button-group">
    <button
      onClick={onSave}
      type="button"
      id="validation-modal-confirm"
      className="nc-button primary-button "
    >
      SAVE
    </button>
    <button
      onClick={onCancel}
      type="button"
      id="validation-modal-cancel"
      className="secondary-link-button "
    >
      Cancel
    </button>
  </div>
);

const ValidationModal = ({ show, closeModal, onSave, errors = {} }) => {
  return (
    <Modal
      id="validation-modal"
      bsClass="list-page-cancel modal"
      animation={false}
      backdrop={"static"}
      keyboard={false}
      show={show}
      restoreFocus={false}
    >
      <Modal.Header>
        <Header closeModal={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <div
          id="validation-modal-massege"
          className="netchex-modal-message-body col-xs-12 col-sm-12 col-md-12 col-lg-12"
          style={{ textAlign: "center", fontSize: "18px" }}
        >
          {errors[Fields.formerEmployeeID] &&
            !errors[Fields.clockNo] && (
              <span>
                {" "}
                Former Employee ID is currently being used by another employee
                in your company. Do you want to save the changes?
              </span>
            )}
          {errors[Fields.clockNo] &&
            !errors[Fields.formerEmployeeID] && (
              <span>
                {" "}
                Clock Number is currently being used by another employee in your
                company. Do you want to save the changes?{" "}
              </span>
            )}
          {errors[Fields.clockNo] &&
            errors[Fields.formerEmployeeID] && (
              <span>
                {" "}
                Former Employee ID and Clock Number is currently being used by
                another employee in your company. Do you want to save the
                changes?{" "}
              </span>
            )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Footer onCancel={closeModal} onSave={onSave} />
      </Modal.Footer>
    </Modal>
  );
};

export default ValidationModal;
