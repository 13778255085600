import {
  setFilterOptions,
  selectAllFilterOptions
} from "app/common/reducers/filters";
import {
  clearJobHistory,
  addToJobHistory,
  setJobHistoryLoading
} from "../reducers/jobHistoryItems";
import {
  searchJobHistory,
  loadMoreJobHistory,
  editJobDetail,
  getFilterOptions,
  userPath,
  fieldPath,
  eventTypePath
} from ".";
import { DEFAULT_SEARCH_PARAMS } from "../util";
import {
  sampleEventTypesResponse,
  sampleFieldsResponse,
  sampleUsersResponse
} from "sample-data/job-history/filterOptions";

// TODO (arao): Remove this after endpoints are fixed
const _patchNullOptionsResponse = (res, name) => {
  if (!res.data) {
    switch (name) {
      case "field":
        res.data = sampleFieldsResponse;
        return;
      case "user":
        res.data = sampleUsersResponse;
        return;
      case "eventType":
        res.data = sampleEventTypesResponse;
        return;
      default:
        res.data = { Data: [] };
    }
  }
};

export const fetchAndSetFilterOptions = (
  entityTypeId,
  name,
  pathConstructor,
  reformat = x => x,
  { selectAll = false } = {}
) => dispatch =>
  getFilterOptions(entityTypeId, pathConstructor).then(
    res => {
      _patchNullOptionsResponse(res, name);
      dispatch(setFilterOptions(name, (res.data.Data || []).map(reformat)));
      if (selectAll) {
        dispatch(selectAllFilterOptions(name, true));
      }
      return true;
    },
    error => {
      // TODO: Handle the error properly
      console.error(error);
      return false;
    }
  );

export const searchAndSetJobHistory = (
  entityTypeId,
  params = DEFAULT_SEARCH_PARAMS
) => dispatch => {
  dispatch(clearJobHistory());
  return searchJobHistory(entityTypeId, params).then(
    res => {
      const { Data, NextUri } = res.data;
      dispatch(addToJobHistory(Data, NextUri));
      dispatch(setJobHistoryLoading(false));
      return true;
    },
    error => {
      // TODO: Handle the error properly
      console.error(error);
      dispatch(setJobHistoryLoading(false));
      return false;
    }
  );
};

export const loadMoreAndSetJobHistory = NextUri => dispatch => {
  dispatch(setJobHistoryLoading(true));
  return loadMoreJobHistory(NextUri).then(
    res => {
      const { Data, NextUri } = res.data;
      dispatch(addToJobHistory(Data, NextUri));
      dispatch(setJobHistoryLoading(false));
      return true;
    },
    error => {
      // TODO: Handle the error properly
      console.error(error);
      dispatch(setJobHistoryLoading(false));
      return false;
    }
  );
};

export const editJobDetailAndReload = (
  entityTypeId,
  jobId,
  params
) => dispatch => {
  dispatch(setJobHistoryLoading(true)); // TODO: Set global loader here
  return editJobDetail(entityTypeId, jobId, params).then(
    res => {
      dispatch(setJobHistoryLoading(false));
      return true;
    },
    error => {
      // TODO: Handle the error properly
      console.error(error);
      dispatch(setJobHistoryLoading(false));
      return false;
    }
  );
};

if (process.env.NODE_ENV === "development") {
  window.jobHistory = {
    searchJobHistory,
    loadMoreJobHistory,
    editJobDetail,
    getFilterOptions,
    userPath,
    fieldPath,
    eventTypePath,
    fetchAndSetFilterOptions,
    searchAndSetJobHistory,
    loadMoreAndSetJobHistory,
    editJobDetailAndReload
  };
}
