import React, { Component } from "react";
import {
  FormSection,
  FormFieldGroup
} from "component-library/formSections/index";
import { SupervisorPhoneNumber } from "./Components";
import * as FIELDS from "../utils/Constants";

export default class FormerSupervisor extends Component {
  render() {
    const { data = {}, onChange } = this.props;
    return (
      <div className="tab-container-add-sub-section">
        <div className="section-items">
          <FormSection title="Former Supervisor">
            <FormFieldGroup
              inputType="text"
              inputName={FIELDS.SUPERVISOR_NAME}
              value={data[FIELDS.SUPERVISOR_NAME]}
              label="Name"
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              optional={true}
              colSpan="col-lg-3 col-md-3 col-sm-3"
            />

            <FormFieldGroup
              inputType="text"
              inputName={FIELDS.SUPERVISOR_TITLE}
              value={data[FIELDS.SUPERVISOR_TITLE]}
              label="Title"
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              optional={true}
              colSpan="col-lg-3 col-md-3 col-sm-3"
            />

            <SupervisorPhoneNumber
              inputName={FIELDS.SUPERVISOR_PHONE_NUMBER}
              data={data}
              label="Phone Number"
              onChange={onChange}
              format="###-###-####"
            />

            <FormFieldGroup
              inputType="text"
              inputName={FIELDS.SUPERVISOR_EMAIL_ADDRESS}
              value={data[FIELDS.SUPERVISOR_EMAIL_ADDRESS]}
              label="Email Address"
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              optional={true}
              colSpan="col-lg-3 col-md-3 col-sm-3"
            />
          </FormSection>
        </div>
      </div>
    );
  }
}
