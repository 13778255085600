export const seniorityDate = "SeniorityDate";
export const serviceTime = "ServiceTime";
export const includedInPayroll = "IncludedInPayroll";
export const includedInPayrollStartDate = "IncludedInPayrollStartDate";
export const includedInPayrollEndDate = "IncludedInPayrollEndDate";
export const status = "Status";
export const newStatus = "NewStatus";
//HIRE and RE-HIRE
export const hireDate = "HireDate";
export const overrideHireDate = "OverrideHireDate";
export const rehireIncludedInPayroll = "RehireIncludedInPayroll";
export const hireIncludedInPayroll = "HireIncludedInPayroll";

export const rehireDate = "RehireDate";
export const rehireNotes = "RehireNotes";
export const overrideRehireDate = "OverrideRehireDate";

//REACTIVATION
export const reactivationDate = "ReactivationDate";
export const reactivatioNotes = "ReactivatioNotes";
export const reactivationIncludedInPayroll = "ReactivationIncludedInPayroll";

//TERMINATION and RE-TERMINATION
export const terminationDate = "TerminationDate";
export const overrideTerminationDate = "OverrideTerminationDate";
export const overrideTerminationRehire = "OverrideTerminationRehire";
export const terminationrehireEligibility = "TerminationRehireEligiblity";
export const terminationType = "TerminationType";
export const terminationReason = "TerminationReason";
export const terminationNotes = "TerminationNotes";
export const reterminationDate = "ReterminationDate";
export const overrideReterminationDate = "OverrideReterminationDate";
export const overrideReterminationRehire = "OverrideReterminationRehire";
export const reterminationrehireEligibility = "ReterminationRehireEligiblity";
export const reterminationType = "ReterminationType";
export const reterminationReason = "ReterminationReason";
export const reterminationNotes = "ReterminationNotes";
export const reterminationIncludedInPayroll = "ReterminationIncludedInPayroll";
export const terminationIncludedInPayroll = "TerminationIncludedInPayroll";

//TRANSFERRED
export const transferredDate = "TransferredDate";
export const overrideTransferredDate = "OverrideTransferredDate";
export const transferredNotes = "TransferredNotes";
export const rehireEligibility = "RehireEligiblity";
export const transferredIncludedInPayroll = "TransferredIncludedInPayroll";
//LEAVE
export const leaveStartDate = "LeaveStartDate";
export const overrideLeaveStartDate = "OverrideLeaveStartDate";
export const leaveIncludedInPayroll = "LeaveIncludedInPayroll";
export const leaveReturnIncludedInPayroll = "LeaveReturnIncludedInPayroll";

export const leaveType = "LeaveType";
export const leaveReason = "LeaveReason";
export const expectedLeaveReturnDate = "ExpectedLeaveReturnDate";
export const overrideLeaveReturnDate = "OverrideLeaveReturnDate";
export const leaveReturnDate = "LeaveReturnDate";
export const leaveReturnNotes = "LeaveReturnNotes";
export const selfServiceDefaultConfig = "SelfServiceDefaultConfig";
export const fmlaLeaveReason = "FmlaLeaveReason";
export const fmlConsistentSchedule = "FmlConsistentSchedule";
export const inactivationDate = "InactivationDate";
export const overrideInactivationDate = "OverrideInactivationDate";
//export const noEndDate

export const inactivationIncludedInPayroll = "InactivationIncludedInPayroll";
//RETIREMENT
export const retirementDate = "RetirementDate";
export const overrideRetirementDate = "OverrideRetirementDate";
export const retirementNotes = "RetirementNotes";
export const inactivateBenefitsPlans = "InactivateBenefitPlans";
export const benefitsInactivationDate = "BenefitsInactivationDate";
export const benefitActivationDate = "BenefitsActivationDate";
export const benefitInactivationDate = "BenefitsInactivationDate";
export const recordCobraEvent = "RecordCobraEvent";
export const benefitsCobraNotes = "BenefitsCobraNotes";
export const retirementIncludedInPayroll = "RetirementIncludedInPayroll";
//SCheduled Earnings- Deductions
export const changeScheduledEarnings = "ChangeScheduledEarnings";
export const changeScheduledDeductions = "ChangeScheduledDeductions";
//COBRA
export const grossTerminationMisconduct = "GrossTerminationMisconduct";
export const cobraEventDate = "CobraEventDate";
export const recordCOBRAEventNow = "RecordCOBRAEventNow";
export const replaceManager = "ReplaceManager";
export const replacementManager = "ReplacementManager";
export const removeAsManager = "RemoveAsManager";
export const keepAsManager = "KeepAsManager";

export const deceasedDate = "DeceasedDate";
export const overrideDeceasedDate = "OverrideDeceasedDate";
export const deceasedIncludedInPayroll = "DeceasedIncludedInPayroll";

//Additional Notes
export const additionalNotes = "AdditionalNotes";
export const inactivationNotes = "InactivationNotes";
export const additionalIncludedInPayroll = "AdditionalIncludedInPayroll";

export const manuallyAssignManagers = "ManuallyAssignManagers";
