export const menus = [
  {
    parentUrl: "/company-level-deduction-listingPage",
    menuUrl: "/company-level-deduction-activity-history"
  },
  {
    parentUrl: "/company-level-deduction-listingPage",
    menuUrl: "/company-level-copy-deduction"
  },
  {
    parentUrl: "/company-level-deduction-listingPage",
    menuUrl: "/company-level-deduction-description"
  },
  {
    parentUrl: "/company-level-deduction-listingPage",
    menuUrl: "/company-level-deduction-calculation-method"
  },
  {
    parentUrl: "/company-level-deduction-listingPage",
    menuUrl: "/company-level-deduction-deduction-cycle"
  },
  {
    parentUrl: "/company-level-deduction-listingPage",
    menuUrl: "/company-level-deduction-default-value"
  },
  {
    parentUrl: "/company-level-deduction-listingPage",
    menuUrl: "/company-level-deduction-option"
  },
  {
    parentUrl: "/company-level-deduction-listingPage",
    menuUrl: "/company-level-review-and-save"
  },
  {
    parentUrl: "/company-third-party-payee-listing-page",
    menuUrl: "/company-third-party-payee-activity-history"
  },
  {
    parentUrl: "/company-third-party-payee-listing-page",
    menuUrl: "/company-third-party-payee-add-page"
  },
  {
    parentUrl: "/system-level-deduction-setup-listing-page",
    menuUrl: "/system-level-deduction-activity-history"
  },
  {
    parentUrl: "/system-level-deduction-setup-listing-page",
    menuUrl: "/system-level-deduction-description"
  },
  {
    parentUrl: "/system-level-deduction-setup-listing-page",
    menuUrl: "/system-level-calculation-method"
  },
  {
    parentUrl: "/system-level-deduction-setup-listing-page",
    menuUrl: "/system-level-deduction-cycle"
  },
  {
    parentUrl: "/system-level-deduction-setup-listing-page",
    menuUrl: "/system-level-deduction-options"
  },
  {
    parentUrl: "/system-level-deduction-setup-listing-page",
    menuUrl: "/system-level-review-and-save"
  },
  {
    parentUrl: "/system-level-deduction-setup-listing-page",
    menuUrl: "/system-level-deafult-value"
  },
  {
    parentUrl: "/system-third-party-payee-listing-page",
    menuUrl: "/system-third-party-payee-activity-history"
  },
  {
    parentUrl: "/system-third-party-payee-listing-page",
    menuUrl: "/system-third-party-payee-add-page"
  },
  {
    parentUrl: "/system-level-earnings",
    menuUrl: "/system-level-earnings-activity-history"
  },
  {
    parentUrl: "/system-level-earnings",
    menuUrl: "/system-level-earnings/setup"
  },
  {
    parentUrl: "/system-level-earnings",
    menuUrl: "/system-level-earnings/setup/reviewAndSave"
  },
  {
    parentUrl: "/system-level-earnings",
    menuUrl: "/system-level-earnings/setup/description"
  },
  {
    parentUrl: "/system-level-earnings",
    menuUrl: "/system-level-earnings/setup/calculation"
  },
  {
    parentUrl: "/system-level-earnings",
    menuUrl: "/system-level-earnings/setup/paycycle"
  },
  {
    parentUrl: "/company-level-earnings",
    menuUrl: "/company-level-earnings-activity-history"
  },
  {
    parentUrl: "/company-level-earnings",
    menuUrl: "/company-level-copy-earnings"
  },
  {
    parentUrl: "/company-level-earnings",
    menuUrl: "/company-level-earnings/setup"
  },
  {
    parentUrl: "/company-level-earnings",
    menuUrl: "/company-level-earnings/setup/description"
  },
  {
    parentUrl: "/company-level-earnings",
    menuUrl: "/company-level-earnings/setup/calculation"
  },
  {
    parentUrl: "/company-level-earnings",
    menuUrl: "/company-level-earnings/setup/paycycle"
  },
  {
    parentUrl: "/company-level-earnings",
    menuUrl: "/company-level-earnings/setup/options"
  },
  {
    parentUrl: "/company-level-earnings",
    menuUrl: "/company-level-earnings/setup/reviewAndSave"
  },
  {
    parentUrl: "/earning-group-list-page",
    menuUrl: "/edit-earnings-group-page"
  },
  {
    parentUrl: "/earning-group-list-page",
    menuUrl: "/earnings-group-activity-history"
  },
  {
    parentUrl: "/company-third-party-payee-listing-page",
    menuUrl: "/notifications"
  },
  {
    parentUrl: "/company-third-party-payee-listing-page",
    menuUrl: "/view-job-history"
  },
  {
    parentUrl: "/map-general-ledger-accounts-taxes",
    menuUrl: "/gl-account-Taxes-activity-history"
  },
  
  {
    parentUrl: "/map-general-ledger-accounts-deductions",
    menuUrl: "/gl-account-deductions-activity-history"
  },
  {
    parentUrl: "/map-general-ledger-accounts-earnings",
    menuUrl: "/gl-account-earnings-activity-history"
  },
  {
    parentUrl: "/deduction-tax-priority",
    menuUrl: "/deduction-and-tax-priority-activity-history"
  },
];
