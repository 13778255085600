import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import EntitySnapshotHeader from "./EntitySnapshotHeader";
import EntitySnapshotTable from "./EntitySnapshotTable";
import MapGLEntitySnapshotTable from "./MapGLEntitySnapshotTable";
import TaxPrioritySnapshotTable from "./../TaxPrioritySnapshot/SnapshotTable";
import EarningsGroupEntitySnapshotTable from "./../earnings-groups-snapshot/EarningsGroupEntitySnapshotTable";
import EmailTextAlertsEntitySnapshotTable from "./../email-text-alerts-snapshot/EmailTextAlertsEntitySnapshotTable";
import HolidayCalendarSnapshotTable from "../payroll-holiday-calendar/ActivityHistory/Snapshot";

import "../activity-history/ActivityHistory.css";
import classnames from "classnames/bind";
import "./../TaxPrioritySnapshot/snapshot.scss";
import "./mapGlSnapshot.scss";
import { NoMoreData } from "nc-components";
import { ENTITY_TYPE } from "./../activity-history/Constants";
export default class ActivityInstance extends Component {
  state = {
    currentLink: this.props.beforeLink,
    showValueType: this.props.showValueType
  };
  toggleBeforeAfter = () => {
    let beforeLink = this.props.beforeLink;
    let afterLink = this.props.afterLink;
    let currentLink =
      this.state.showValueType === "before" ? beforeLink : afterLink;

    let showValueType =
      this.state.showValueType === "current" ? "before" : "current";
    this.setState({ currentLink, showValueType });
  };
  render() {
    let { ActivityEntityId } = this.props;
    const { props } = this;
    const { showValueType } = this.state;
    const {
      data,
      convertUserTimeZone,
      userTimeZoneOffset,
      userTimeZoneAbbreviation,
      sessionContext
    } = this.props;
    let nodes =
      showValueType === "before"
        ? data.BeforeValueSnapshot
        : data.CurrentValueSnapshot;
    let isBeforeValueSnapshot =
      data.BeforeValueSnapshot !== null ? true : false;
    return (
      <Modal
        id={"activity-history"}
        animation={false}
        bsSize="large"
        className={classnames(
          ActivityEntityId === ENTITY_TYPE.DEDUCTIONS_TAX &&
            "tax-priority-snapshot",
          [
            ENTITY_TYPE.MAP_GL_DEDUCTIONS,
            ENTITY_TYPE.MAP_GL_EARNINGS,
            ENTITY_TYPE.MAP_GL_TAXES
          ].includes(parseInt(ActivityEntityId, 10)) && "map-gl-snapshot"
        )}
        bsClass="modal"
        show={props.show}
      >
        <EntitySnapshotHeader
          data={data}
          onHide={props.onHide}
          onToggleBeforeAfter={this.toggleBeforeAfter}
          linkText={this.state.currentLink}
          showValueType={showValueType}
          userTimeZoneOffset={userTimeZoneOffset}
          userTimeZoneAbbreviation={userTimeZoneAbbreviation}
          convertUserTimeZone={convertUserTimeZone}
          isBeforeValueSnapshot={isBeforeValueSnapshot}
        />

        {ActivityEntityId === ENTITY_TYPE.MAP_GL_DEDUCTIONS ||
        ActivityEntityId === ENTITY_TYPE.MAP_GL_EARNINGS ||
        ActivityEntityId === ENTITY_TYPE.MAP_GL_TAXES ? (
          nodes !== null ? (
            <MapGLEntitySnapshotTable nodes={nodes} />
          ) : (
            <NoMoreData
              type={getIcon(ActivityEntityId)}
              description={makeDescription(ActivityEntityId)}
              iconHeight="90"
              iconWidth="90"
            />
          )
        ) : ActivityEntityId === ENTITY_TYPE.DEDUCTIONS_TAX ? (
          nodes !== null ? (
            <TaxPrioritySnapshotTable nodes={nodes} />
          ) : (
            <NoMoreData
              type={getIcon(ActivityEntityId)}
              description={makeDescription(ActivityEntityId)}
              iconHeight="90"
              iconWidth="90"
            />
          )
        ) : ActivityEntityId === ENTITY_TYPE.EARNINGS_GROUP ? (
          nodes !== null ? (
            <EarningsGroupEntitySnapshotTable nodes={nodes} />
          ) : (
            <NoMoreData
              type={getIcon(ActivityEntityId)}
              description={makeDescription(ActivityEntityId)}
              iconHeight="90"
              iconWidth="90"
            />
          )
        ) : ActivityEntityId === ENTITY_TYPE.HOLIDAY_CALENDAR ? (
          nodes !== null ? (
            <HolidayCalendarSnapshotTable nodes={nodes} />
          ) : (
            <NoMoreData
              type={getIcon(ActivityEntityId)}
              description={makeDescription(ActivityEntityId)}
              iconHeight="90"
              iconWidth="90"
            />
          )
        ) : ActivityEntityId === ENTITY_TYPE.EMAIL_TEXT_ALERTS ? (
          <Modal.Body>
            <div className="nc-enity-snapshot-summary">
              {nodes !== null ? (
                <EmailTextAlertsEntitySnapshotTable nodes={nodes} />
              ) : (
                <NoMoreData
                  type={getIcon(ActivityEntityId)}
                  description={makeDescription(ActivityEntityId)}
                  iconHeight="90"
                  iconWidth="90"
                />
              )}
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body>
            <div className="nc-enity-snapshot-summary">
              {nodes !== null ? (
                <EntitySnapshotTable
                  nodes={nodes}
                  isClientAdmin={
                    (sessionContext || {}).CurrentAppUiViewId === String(2)
                  }
                />
              ) : (
                <NoMoreData
                  type={getIcon(ActivityEntityId)}
                  description={makeDescription(ActivityEntityId)}
                  iconHeight="90"
                  iconWidth="90"
                />
              )}
            </div>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

export function makeDescription(id) {
  switch (id) {
    case ENTITY_TYPE.DEDUCTIONS:
    case ENTITY_TYPE.DEDUCTIONS_SYS:
      return "This Deduction has no prior history.";
    case ENTITY_TYPE.DEDUCTIONS_TAX:
      return "This Deduction & Tax Priorities has no prior history.";
    case ENTITY_TYPE.EARNINGS:
    case ENTITY_TYPE.EARNINGS_SYS:
      return "This Earnings has no prior history.";
    case ENTITY_TYPE.EARNINGS_GROUP:
      return "This Earnings Group has no prior history.";
    case ENTITY_TYPE.MAP_GL_DEDUCTIONS:
      return "This Map General Ledger Accounts - Deductions has no prior history.";
    case ENTITY_TYPE.MAP_GL_EARNINGS:
      return "This Map General Ledger Accounts - Earnings has no prior history.";
    case ENTITY_TYPE.MAP_GL_TAXES:
      return "This Map General Ledger Accounts - Taxes has no prior history.";
    case ENTITY_TYPE.TPP:
    case ENTITY_TYPE.TPP_SYS:
      return "This Third Party Payee has no prior history.";

    default:
      return "This Entity has no prior history.";
  }
}

export function getIcon(id) {
  switch (id) {
    case ENTITY_TYPE.DEDUCTIONS:
    case ENTITY_TYPE.DEDUCTIONS_SYS:
      return "Deductions";
    case ENTITY_TYPE.DEDUCTIONS_TAX:
      return "DeductionPriority";
    case ENTITY_TYPE.EARNINGS:
    case ENTITY_TYPE.EARNINGS_SYS:
      return "Earnings";
    case ENTITY_TYPE.EARNINGS_GROUP:
      return "EarningsGroup";
    case ENTITY_TYPE.MAP_GL_DEDUCTIONS:
    case ENTITY_TYPE.MAP_GL_EARNINGS:
    case ENTITY_TYPE.MAP_GL_TAXES:
      return "MapGl";
    case ENTITY_TYPE.TPP:
    case ENTITY_TYPE.TPP_SYS:
      return "ThirdPartyPayee";
    default:
      return "Hooray";
  }
}
