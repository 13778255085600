import React from "react";
import { Modal } from "react-bootstrap";
import {
  FormSection,
  FormFieldGroup
} from "component-library/formSections/index";
import * as FIELDS from "../../utils/Constants";
const AddNewSkillModal = props => {
  const { data = {} } = props;
  return (
    <Modal
      id="add-new-skill-modal"
      bsClass="entity-page-cancel-modal modal"
      animation={false}
      backdrop={"static"}
      keyboard={false}
      show={props.show}
      onHide={props.onClose}
      restoreFocus={false}
    >
      <Modal.Header>
        <div
          id="add-new-skill-modal-header"
          className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12"
        >
          <i
            id="add-new-skill-close-icon"
            onClick={props.onClose}
            className="fa fa-times-circle-o fa-lg"
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-container">
          <div className="modal-body-header">Create a New Skill</div>
          <div className="modal-body-content">
            <div
              className="content-section clearfix"
              style={{ marginBottom: "20px" }}
            >
              Didn't see the correct skill available? Add a new one below to
              your company.
            </div>
            <FormSection>
              <FormFieldGroup
                inputType="text"
                inputName={[FIELDS.NEW_SKILL_NAME]}
                value={data[FIELDS.NEW_SKILL_NAME]}
                onChange={props.onChange}
                label={"Name this new skill"}
                labelFontWeight="600"
                Size={[4, 4, 4, 6]}
                colSpan="col-sm-12"
                description="Ex. People Skills, Phone Skills, etc."
                descriptionStyle="italic"
              />
            </FormSection>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          id="add-new-skill-modal-footer"
          className="button-group"
          style={{ textAlign: "left" }}
        >
          <button
            type="button"
            id="save-modal-button "
            className={
              !data[FIELDS.NEW_SKILL_NAME]
                ? "disabled-button"
                : "nc-button primary-button"
            }
            onClick={props.onCreateNewSkill}
          >
            CREATE SKILL
          </button>

          <button
            type="button"
            id="close-modal-button"
            className="nc-button secondary-link-button"
            onClick={props.onClose}
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewSkillModal;
