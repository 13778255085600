import React, { Component } from "react";
import EarningsItem from "./EarningsItem";
import EarningsDeleteModal from "./EarningsDeleteModal";
import EarningsInactivateModal from "./EarningsInactivateModal";
import EarningsReactivateModal from "./EarningsReactivateModal";
import EarningsNoActionModal from "./EarningsNoActionModal";
import EarningsEditContactModal from "./EarningsEditContactModal";
import { EntityListTableHeader } from "../entity-list";
import { Modal } from "react-bootstrap";
import EntitySnapshotTable from "./../entity-snapshot/EntitySnapshotTable";
import EntitySnapshotHeader from "./../entity-snapshot/EntitySnapshotHeader";
import * as DefaultId from "./DefaultIds";
export default class EarningsTable extends Component {
  state = {
    showDeleteModal: false,
    itemForDeletion: {}
  };

  toggleDeleteModal = (visibility, item = {}) => {
    this.setState({
      showDeleteModal: visibility,
      itemForDeletion: item
    });
  };
  toggleReactivateModal = (visiblity, item = {}) => {
    this.setState({
      showReactivateModal: visiblity,
      itemForReactivation: item
    });
  };
  toggleNoActionModal = visiblity => {
    this.setState(
      {
        showNoActionModal: visiblity
      },
      () => !this.state.showNoActionModal && this.props.clearErrors()
    );
  };
  openSnapshot = item => {
    if (item.statusId !== 1) {
      this.openSnapShotSummary(item);
    }
  };
  openSnapShotSummary(item) {
    if (this.props.entityId === 6) {
      this.props.loadSnapshotData(
        this.props.entityId,
        item.activityInstanceId,
        () => {
          this.setState({
            snapshotSummaryModal: true
          });
        }
      );
    } else {
      this.props.loadSnapshotData(this.props.entityId, item.id, () => {
        this.setState({
          snapshotSummaryModal: true
        });
      });
    }
  }

  closeSnapShotSummary = () => {
    this.setState({
      snapshotSummaryModal: false
    });
    this.props.clearSnapshotData();
  };
  handleDeleteClick = item => {
    const { entityType, checkIfEarningIsUsed } = this.props;
    if (entityType === "Template") {
      this.toggleDeleteModal(true, item);
    } else {
      checkIfEarningIsUsed(item.id, isUsed => {
        if (isUsed) {
          this.toggleNoActionModal(true);
        } else {
          this.toggleDeleteModal(true, item);
        }
      });
    }
  };

  render() {
    const {
      data = [],
      snapData = [],
      errors = [],
      deleteEntity,
      onEditClick,
      onLegacyEditClick,
      reactivateEntity,
      inactivateEntity,
      editSortOrder,
      sortOrder,
      entityType = "Template",
      loadingFirstTime,
      hasReactivateModal = false,
      Id = DefaultId,
      userTimeZoneOffset,
      userTimeZoneAbbreviation,
      convertUserTimeZone,
      showEditContactModal,
      toggleEditContactModal,
      canEdit,
      isClientAdmin
    } = this.props;
    const {
      showDeleteModal,
      itemForDeletion,
      itemForReactivation,
      showReactivateModal,
      showNoActionModal
    } = this.state;
    return (
      <div className="list-page-grid-table col-lg-12 col-md-12 col-sm-12 col-xs-12">
        {loadingFirstTime ? (
          <div id={Id.loadingCount} className="nc-rowcount-results">
            Loading...
          </div>
        ) : (
            <div id={Id.listCount} className="nc-rowcount-results">
              We found {data.length}{" "}
              {data.length === 1 ? "result." : "results."}
            </div>
          )}
        <EntityListTableHeader
          editSortOrder={editSortOrder}
          sortOrder={sortOrder}
          Id={Id}
        />
        {data.map(item => (
          <EarningsItem
            item={item}
            key={item.id}
            onDeleteClick={() => this.handleDeleteClick(item)}
            onEditClick={() =>
              onEditClick({
                id: item.id,
                step: item.redirectionStep,
                isSystemSetupEarning: item.isSystemSetupEarning
              })
            }
            onLegacyEditClick={onLegacyEditClick}
            onReactivateClick={() => {
              if (hasReactivateModal) {
                this.toggleReactivateModal(true, item);
              } else {
                reactivateEntity(item.id, item.name);
              }
            }}
            onItemClick={() => this.openSnapshot(item)}
            Id={Id}
            entityType={entityType}
            canEdit={canEdit}
          />
        ))}
        <EarningsDeleteModal
          show={showDeleteModal && itemForDeletion.useCount === 0}
          closeModal={() => this.toggleDeleteModal(false)}
          name={itemForDeletion.name}
          entityType={entityType}
          submit={() => {
            deleteEntity(itemForDeletion.id, itemForDeletion.name, () => { });
            this.toggleDeleteModal(false);
          }}
          Id={Id}
        />
        <EarningsInactivateModal
          show={showDeleteModal && itemForDeletion.useCount > 0}
          closeModal={() => this.toggleDeleteModal(false)}
          name={itemForDeletion.name}
          entityType={entityType}
          submit={() => {
            inactivateEntity(itemForDeletion.id, itemForDeletion.name);
            this.toggleDeleteModal(false);
          }}
          Id={Id}
        />
        <EarningsReactivateModal
          show={showReactivateModal}
          closeModal={() => this.toggleReactivateModal(false)}
          submit={() => {
            reactivateEntity(itemForReactivation.id, itemForReactivation.name);
            this.toggleReactivateModal(false);
          }}
          Id={Id}
        />
        <EarningsNoActionModal
          show={showNoActionModal}
          closeModal={() => this.toggleNoActionModal(false)}
          message={() =>
            errors.length > 0 && (
              <ul className="nc-earnings-error-list">
                {errors.map((error, index) => (
                  <li key={index} className="nc-earnings-error error-list">
                    {error}
                  </li>
                ))}
              </ul>
            )
          }
          Id={Id}
        />
        <EarningsEditContactModal
          show={showEditContactModal}
          closeModal={toggleEditContactModal}
          submit={toggleEditContactModal}
          Id={Id}
        />
        <Modal
          id="activity-history"
          animation={false}
          bsSize="large"
          bsClass="modal"
          show={this.state.snapshotSummaryModal}
          restoreFocus={true}
        >
          <EntitySnapshotHeader
            data={snapData}
            onHide={this.closeSnapShotSummary}
            userTimeZoneOffset={userTimeZoneOffset}
            userTimeZoneAbbreviation={userTimeZoneAbbreviation}
            convertUserTimeZone={convertUserTimeZone}
          />
          <Modal.Body>
            <div className="nc-enity-snapshot-summary">
              {snapData.CurrentValueSnapshot ? (
                <EntitySnapshotTable
                  nodes={snapData.CurrentValueSnapshot}
                  isClientAdmin={isClientAdmin}
                />
              ) : (
                  "No Data Available"
                )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
