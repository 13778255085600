import { combineReducers } from "redux";
import payrollDashboardItems from "./payrollDashboardItems";
import payrollDashboardSessionData from "./payrollDashboardSessionData";
import payrollTypeData from "./payrollTypeData";
import payrollTypeActiveTab from "./payrollTypeActiveTab";
import addressVerifications from "./addressVerifications";
const payrollDashboard = combineReducers({
  payrollDashboardItems,
  payrollDashboardSessionData,
  payrollTypeData,
  payrollTypeActiveTab,
  addressVerifications
});

export default payrollDashboard;
