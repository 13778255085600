import React from "react";
import { PropTypes } from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as util from "../utils";

const tooltip_thrash_icon = <Tooltip id="tooltip_thrash_icon">Delete</Tooltip>;
const tooltip_edit_icon = <Tooltip id="tooltip_edit_icon">Edit</Tooltip>;

const getEmploymentDate = data => {
  let months = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "June",
    7: "July",
    8: "Aug",
    9: "Sept",
    10: "Oct",
    11: "Nov",
    12: "Dec",
    "": ""
  };
  if (data.CurrentlyEnrolled) {
    return "Currently Enrolled";
  } else if (
    !data.CurrentlyEnrolled &&
    util.isNullOrEmpty(data.GraduationYear) &&
    util.isNullOrEmpty(data.ExpectedGraduationYear)
  ) {
    return "Incomplete";
  } else {
    return `${months[data.GraduationMonth]} ${data.GraduationYear}`;
  }
};

const EducationList = props => {
  const { item, onEditClick, onDeleteClick } = props;
  const { Majors = {} } = item;
  let description = [];
  if (Majors !== null && Majors.length > 0) {
    Majors.map(item => {
      return description.push(item.Name);
    });
  }

  return (
    <div
      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 table-cell">
        {item.Degree}
        {description.length > 0 && (
          <div className="clearfix" style={{ paddingRight: "15px" }}>
            {description.map((item, i) => (
              <span key={i}>
                {item}
                {i < description.length - 1 && ", "}
              </span>
            ))}
          </div>
        )}
        {item.IsVerified && (
          <div className="clearfix">
            <span>
              <i className="fa fa-check-circle-o" />
              Verified
            </span>
          </div>
        )}
      </div>
      <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 table-cell">
        {item.Name}
      </div>
      <div className="col-lg-3 col-md-3 col-sm-12  col-xs-12">
        {getEmploymentDate(item)}
      </div>
      <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
        <div className="tab-container-list-icon-section pull-right">
          <div className="tab-container-list-edit-section">
            <OverlayTrigger placement="top" overlay={tooltip_edit_icon}>
              <a
                href="."
                onClick={e => {
                  e.preventDefault();
                  onEditClick && onEditClick(item.EducationId);
                }}
              >
                <i
                  id={"edit-icon-" + item.EducationId}
                  className="tab-container-list-edit-icon fa fa-pencil"
                />
              </a>
            </OverlayTrigger>
          </div>
          <div className="tab-container-list-delete-section">
            <OverlayTrigger placement="top" overlay={tooltip_thrash_icon}>
              <a
                href="."
                onClick={e => {
                  e.preventDefault();
                  onDeleteClick && onDeleteClick(item.EducationId, item.Name);
                }}
              >
                <i
                  id={"delete-icon-" + item.EducationId}
                  className="tab-container-list-delete-icon fa fa-trash-o"
                />
              </a>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationList;

EducationList.propTypes = {
  item: PropTypes.object
};

EducationList.defaultProps = {
  item: {
    SectionItemDescription: "",
    Verified: false,
    SectionItemTitle: "",
    SectionItemDescriptionLine: ""
  }
};
