//EMPLOYER SECTION
export const FETCH_EMPLOYER_EARNINGS_ACCOUNTS_MAIN_LIST =
  "FETCH_EMPLOYER_EARNINGS_ACCOUNTS_MAIN_LIST";

export const FETCH_EMPLOYER_EARNINGS_FILTERED_LIST =
  "FETCH_EMPLOYER_EARNINGS_FILTERED_LIST";

export const SET_EMPLOYER_EARNINGS_DATA = "SET_EMPLOYER_EARNINGS_DATA";

export const FETCH_EMPLOYER_EARNINGS_EXPENCE_ACCOUNTS_LIST =
  "FETCH_EMPLOYER_EARNINGS_EXPENCE_ACCOUNTS_LIST";

export const FETCH_EMPLOYER_EARNINGS_LIABILITY_ACCOUNTS_LIST =
  "FETCH_EMPLOYER_EARNINGS_LIABILITY_ACCOUNTS_LIST";

export const SAVE_EMPLOYER_EARNINGS_EXPENCE_LIABILITY_ACCOUNTS =
  "SAVE_EMPLOYER_EARNINGS_EXPENCE_LIABILITY_ACCOUNTS";

export const FETCH_EMPLOYER_EARNINGS_LIABILITY_ACCOUNTS_DATA =
  "FETCH_EMPLOYER_EARNINGS_LIABILITY_ACCOUNTS_DATA";

export const UNMAPPED_CHECK_STATE_TYPE = "UNMAPPED_CHECK_STATE_TYPE";

//EMPLOYEE SECTION
export const FETCH_EMPLOYEE_EARNINGS_ACCOUNTS_MAIN_LIST =
  "FETCH_EMPLOYEE_EARNINGS_ACCOUNTS_MAIN_LIST";

export const FETCH_EMPLOYEE_EARNINGS_FILTERED_LIST =
  "FETCH_EMPLOYEE_EARNINGS_FILTERED_LIST";

export const FETCH_EMPLOYEE_EARNINGS_LIABILITY_ACCOUNTS_LIST =
  "FETCH_EMPLOYEE_EARNINGS_LIABILITY_ACCOUNTS_LIST";

export const SAVE_EMPLOYEE_EARNINGS_LIABILITY_ACCOUNTS =
  "SAVE_EMPLOYEE_EARNINGS_LIABILITY_ACCOUNTS";

export const EMPLOYEE_EARNINGS_DATA = "EMPLOYEE_EARNINGS_DATA";

export const EMPLOYEE_EARNINGS_SAVED_PAGES = "EMPLOYEE_EARNINGS_SAVED_PAGES";

//Filters
export const GENERAL_LEDGER_SELECTED_FILTERS =
  "GENERAL_LEDGER_SELECTED_FILTERS";

export const EARNINGS_DEDUCTION_FILTERED_LIST =
  "EARNINGS_DEDUCTION_FILTERED_LIST";
