import React, { Component } from "react";
import { connect } from "react-redux";
import * as Fields from "component-library/employee-profile/employment-info/workplace-info/FieldNames";
import WorkplaceInfoSection from "component-library/employee-profile/employment-info/workplace-info/WorkplaceInfoSection";
import { validateAndSaveWorkplaceInfo } from "../network/actions";

class WorkplaceInfo extends Component {
  state = {
    data: {},
    showTerminationModal: false,
    showPreHistoryModal: false
  };

  onChange = e => {
    const data = { [e.name]: e.value };

    if (
      e.name === Fields.workEmailAddress ||
      e.name === Fields.workPhoneNumber ||
      e.name === Fields.workPhoneNumberExt ||
      e.name === Fields.workFaxNumber
    ) {
      data[e.name] = e.value;
    }
    this.editData(data);
  };

  onSave = (data = {}) => {
    validateAndSaveWorkplaceInfo(data);
  };

  editData(edits) {
    this.setState({ data: { ...this.state.data, ...edits } });
  }

  render() {
    return (
      <div>
        <div style={{ marginBottom: "20px" }}>
          <button
            className="nc-button secondary-button"
            onClick={this.props.toggleOverlay}
          >
            <i className="fa fa-pencil" />Change Workplace Info
          </button>
        </div>
        <WorkplaceInfoSection
          onChange={this.onChange}
          data={this.props.data}
          stateData={this.state.data}
        />
        <div style={{ position: "fixed", bottom: "100px", right: "30px" }}>
          <button
            style={{
              //marginBottom: "20px",
              boxShadow: "2px 2px 15px #999"
            }}
            className="nc-button primary-button"
            onClick={() => {
              this.onSave(this.state.data);
            }}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}
const stateToProps = state => {
  return {};
};

const actionCreators = {
  validateAndSaveWorkplaceInfo
};
export default connect(stateToProps, actionCreators)(WorkplaceInfo);
