import React, { Component } from "react";
import FilterPanelBar from "./FilterPanelBar";
import FilterOptions from "./FilterOptions";
import { Panel } from "react-bootstrap";
import OptionsSearch from "./../dropDown/OptionsSearch";
import CustomPanelBar from "./CustomPanelBar";
import FilterUnmappedOption from "../entity-list/FilterUnmappedOption";
import { DatePicker } from "nc-components";

export default class FilterPanel extends Component {
  state = {
    showOptions: false,
    searchText: "",
    showState: false
  };

  editSearchText = searchText => this.setState({ searchText });

  toggleOptions = () => {
    const { showOptions } = this.state;
    this.setState({ showOptions: !showOptions, searchText: "" });
  };

  hideOptions = () => {
    setTimeout(
      function() {
        this.setState({ showOptions: false, searchText: "" });
        const { onHide } = this.props;
        onHide && onHide();
      }.bind(this),
      1000
    );
  };

  getFilteredOptions = () => {
    const { options } = this.props;
    const { searchText } = this.state;
    if (searchText) {
      return options.filter(
        ({ label }) =>
          label.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      );
    }
    return options;
  };

  areAllSelected = () => {
    const { multiSelect, selectedItems = {}, options = [] } = this.props;
    if (!multiSelect) return false;

    let all = true;
    options.forEach(({ value }) => (all = all && selectedItems[value]));
    return all;
  };

  areNoneSelected = () => {
    const { multiSelect, selectedItems = {}, options } = this.props;
    if (!multiSelect) return false;

    let any = false;
    options.forEach(({ value }) => (any = any || selectedItems[value]));
    return !any;
  };

  shouldShowBadge = () => {
    const { showBadge, multiSelect, showSelectAll = true } = this.props;
    return (
      showBadge &&
      multiSelect &&
      (!this.areAllSelected() || !showSelectAll) &&
      !this.areNoneSelected()
    );
  };

  showPanelHandler = () => {
    this.setState({
      showState: !this.state.showState
    });
  };

  render() {
    const {
      onSelect,
      selectedItems = {},
      selectAll,
      showPanel,
      showPanelHandler,
      showSearch,
      isDateRange,
      getFromToValue,
      selectAllLabel,
      activityHistory,
      isCheckbox = false,
      unmappedId,
      onCheckboxClick,
      showUnmapped,
      showUnmappedName,
      selectedOptions = {},
      showSelectAll
    } = this.props;
    let showPanelName = "Select";
    let options = this.getFilteredOptions();
    if (this.props.showPanelName && !activityHistory) {
      showPanelName = this.props.showPanelName(
        options,
        selectedItems,
        this.areAllSelected()
      );
    }

    if (isDateRange) {
      return (
        <div>
          <FilterPanelBar
            showPanelHandler={showPanelHandler}
            showPanel={showPanel}
            showPanelName={
              activityHistory ? this.props.showPanelName : showPanelName
            }
          />
          <Panel
            className="mobile-filter-panel-list-panel-default no-padding col-xs-12 col-sm-12 col-md-12 col-lg-12 "
            collapsible="true"
            expanded={showPanel}
            onToggle={() => {}}
          >
            {" "}
            <Panel.Collapse>
              <Panel.Body>
                <DatePicker
                  type="daterange"
                  onChange={({ value }) => getFromToValue("daterange", value)}
                  value={selectedItems}
                  theme="custom"
                />
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
        </div>
      );
    } else if (isCheckbox) {
      return (
        <CustomPanelBar singleLineFilter>
          <FilterUnmappedOption
            onChange={onCheckboxClick}
            unmappedId={unmappedId}
            show={showUnmapped}
            name={showUnmappedName}
            value={
              selectedOptions["unmapped"] !== undefined
                ? selectedOptions["unmapped"][showUnmappedName]
                : false
            }
          />
        </CustomPanelBar>
      );
    } else {
      return (
        <div>
          <FilterPanelBar
            showPanelHandler={showPanelHandler}
            showPanel={showPanel}
            showPanelName={
              activityHistory ? this.props.showPanelName : showPanelName
            }
            showBadge={this.shouldShowBadge()}
            selectedValuesCount={
              Object.keys(selectedItems).filter(k => selectedItems[k]).length
            }
          />

          <Panel
            className="mobile-filter-panel-list-panel-default no-padding col-xs-12 col-sm-12 col-md-12 col-lg-12 "
            collapsible="true"
            expanded={showPanel}
            onToggle={() => {}}
          >
            <Panel.Collapse>
              <Panel.Body>
                <OptionsSearch
                  searchText={this.state.searchText}
                  editSearchText={this.editSearchText}
                  show={showSearch}
                />

                <FilterOptions
                  multiSelect={true}
                  searchText={this.state.searchText}
                  onHide={this.hideOptions}
                  areAllSelected={this.areAllSelected}
                  options={this.getFilteredOptions()}
                  onSelect={onSelect}
                  selectedItems={selectedItems}
                  selectAll={selectAll}
                  selectAllLabel={selectAllLabel}
                  showSelectAll={showSelectAll}
                  showSearch={showSearch}
                />
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
        </div>
      );
    }
  }
}
