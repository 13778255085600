import { store } from "index";
export const getMenuIdByName = menuName => {
  const state = store.getState();
  const { allMenusList = {} } = state;
  const { Data = [] } = allMenusList;
  const Item = Data.find(i => i.MenuName === menuName) || {};
  return Item.Id;
};

export const getMenuItemByName = menuName => {
  const state = store.getState();
  const { allMenusList = {} } = state;
  const { Data = [] } = allMenusList;
  return Data.find(i => i.MenuName === menuName) || {};
};

export const getMenuIdByUrl = menuUrl => {
  const state = store.getState();
  const { allMenusList = {} } = state;
  const { Data = [] } = allMenusList;
  const Item = Data.find(i => i.Url === menuUrl) || {};
  return Item.Id;
};
