import React from "react";
import { FormFieldGroup, FormSection } from "../../../formSections";
import * as Fields from "./FieldNames";
const labelMaker = (noLabel, allLabel, someLabel, oneLabel) => (o, s, all) =>
  all
    ? allLabel
    : Object.keys(s).filter(k => s[k]).length === 0
      ? noLabel
      : Object.keys(s).filter(k => s[k]).length === 1
        ? oneLabel
        : someLabel;

const TimeAttendance = ({
  onChange,
  data,
  options = {},
  makeOnSelect,
  makeOnSelectAll,
  selectedOptions
}) => {
  return (
    <FormSection hr>
      <div className="column-title">Time & Attendance</div>

      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.timeGroup}
        value={data[Fields.timeGroup]}
        options={options[Fields.timeGroup]}
        label="Time Group"
        Size={[12, 12, 7, 12]}
        onChange={makeOnSelect(Fields.timeGroup)}
        selectAll={makeOnSelectAll(Fields.timeGroup)}
        selectedItems={selectedOptions[Fields.timeGroup]}
        multiSelect
        makeLabel={labelMaker(
          "Time Group",
          "Time Group",
          "Time Group",
          "Time Group"
        )}
      />
    </FormSection>
  );
};

export default TimeAttendance;
TimeAttendance.defaultProps = {
  onChange: () => {},
  data: {},
  options: {}
};
