import axios from "axios";
import * as common from "app/common/index";
import { setLoader } from "./LoaderActions";
import * as message from "../common/messages";
import { earningsRequests } from "earnings/network";
let alerted = false;
export const authCheck = error => dispatch => {
  earningsRequests.cancel();
  if (axios.isCancel(error) && !alerted) {
    alert(message.TIMEOUT_MESSAGE);
    alerted = true;
  }
  window.location.href = common.LEGACY_LOGIN_URL + "/n/Account/Logout";
  dispatch(setLoader(true));
};
