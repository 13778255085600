/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Badge } from "react-bootstrap";
const FilterPanelBar = ({
  showPanelHandler,
  showPanel,
  subPanelId,
  showPanelName,
  isAllSelected,
  selectedValuesCount,
  showBadge
}) => {
  let chevronClassName = showPanel ? "fa-chevron-up" : "fa-chevron-down";
  return (
    <div className="mobile-filter-select-dropdown no-padding col-sm-12 col-xs-12">
      <div className="mobile-filter-panel-list-section">
        <a id="" onClick={showPanelHandler}>
          <div className="mobile-filter-panel-list-panel-bar col-xs-12 col-sm-12">
            <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-icon">
              <i
                className={
                  `fa add_deduction_popup_panel_link ` + chevronClassName
                }
                aria-hidden="true"
              />
            </div>
            <div className="col-xs-8 col-sm-8 mobile-filter-panel-list-label no-padding">
              {showPanelName}
            </div>
            <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-badge no-padding">
              {showBadge && (
                <Badge className="filter-select-badge">
                  {selectedValuesCount}
                </Badge>
              )}
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};
export default FilterPanelBar;
