import React, { Component } from "react";
import getInputComponent from "./getInputComponent";
import SingleCheckbox from "../checkBoxGroup/SingleCheckBox";
import moment from "moment";
export default class OverrideWithComponent extends Component {
  state = {};
  showTooltipHandler = () => {};
  onChange = () => {
    this.props.onChange({
      name: this.props.checkedName,
      value: !this.props.checkedValue
    });
  };
  render() {
    const { checkedValue, value, componentType } = this.props;
    const Component = getInputComponent(componentType);
    return (
      <div
        className={"col-xs-9"}
        style={{ display: "flex", alignItems: "center" }}
      >
        {checkedValue ? (
          <Component {...this.props} />
        ) : (
          <div className={"input-field"}>
            {componentType === "radio" && (value ? "Yes" : "No")}
            {componentType === "singleDatePicker" &&
              moment(new Date(value + "+00:00")).format("MM/DD/YYYY")}
          </div>
        )}
        <div
          style={{
            marginLeft: "20px",
            marginBottom: "20px"
          }}
        >
          <SingleCheckbox
            onChange={this.onChange}
            value={checkedValue}
            label="Override"
            labelStyle="italic"
          />
        </div>
      </div>
    );
  }
}
