import React from "react";
import PropTypes from "prop-types";
import UserDetails from "../activity-history/UserDetails";
import ActivityTimestamp from "../activity-history/ActivityTimestamp";
import JobDetails from "./JobDetails";
import { PLACEHOLDER_PIC_URL } from "./constants";

import "./JobHistoryItem.css";

const JobHistoryItem = ({ data }) => (
  <div className="job-history-item table-row col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <UserDetails
      name={data.UserDisplayName}
      picUrl={data.UserProfilePictureUrl || PLACEHOLDER_PIC_URL}
    />
    <JobDetails data={data} />
    <ActivityTimestamp timestamp={data.ActivityTimeStamp.split(".")[0]} />
  </div>
);

JobHistoryItem.propTypes = {
  data: PropTypes.object
};

export default JobHistoryItem;
