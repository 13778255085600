export const notes = {
  noteType: {
    label: "What type of note are you adding?",
    show: true,
    inputId: "noteType",
    inputName: "noteType",
    inputType: "dropdownWithLink",
    linkLabel: "Add New Note Type",
    Size: [3, 3, 3, 3],
    options: [
      {
        value: "Employee Notes1",
        label: "Employee Notes1"
      }
    ]
  },
  date: {
    containerStyle: { clear: "none" },
    Size: [2, 6, 6, 6],
    inputId: "date",
    label: "Date",
    inputName: "date",
    inputType: "singleDatePicker",
    required: true
  },
  description: {
    show: true,
    inputId: "description",
    label: "Description",
    Size: [3, 6, 6, 6],
    inputName: "description",
    inputType: "text",
    maxLength: "35",
    required: true,
    description: "35 characters max",
    labelStyle: { marginTop: "10px" }
  },
  note: {
    show: true,
    inputId: "note",
    label: "Note",
    Size: [6, 6, 6, 6],
    inputName: "note",
    inputType: "textarea",
    maxLength: "250",
    required: true,
    description: "250 characters max",
    height: "80px",
    labelStyle: { marginTop: "30px" },
    descriptionStyle: "italic",
    style: { marginTop: "25px" }
  }
};
