import React from "react";

const SingleCheckBox = ({
  value,
  onChange,
  label,
  labelStyle = "normal",
  disabled,
  classname,
  id,
  visibility = false
}) => (
  <span
    className={classname}
    onClick={() => !disabled && onChange(!value)}
    style={{
      visibility: visibility === true ? "hidden" : "visible"
    }}
  >
    <input type="checkbox" checked={value || false} readOnly />
    <label
      id={id}
      className={disabled ? "disabled" : ""}
      style={{ marginBottom: 0, color: "#555" }}
    >
      {labelStyle === "italic" ? <i>{label}</i> : label}
    </label>
  </span>
);

export default SingleCheckBox;
