import * as types from "../../actions/systemlevel/index";

export default function(state = [], action) {
  switch (action.type) {
    case types.SYSTEM_DEDUCTION_FILTERED_LIST: {
      return action.payload.data;
    }
    default:
      return state;
  }
}
