import React from "react";
import DropDown from "component-library/dropDown";
import InputFieldWithSign from "../../../../../inputFields/InputFieldWithSign";
import getInputComponent from "component-library/inputFields/getInputComponent";
const DatePicker = getInputComponent("singleDatePicker");
const DeductionItem = ({ item = {}, data, onChange }) => {
  return (
    <div className="entity-info-item clearfix">
      <div className="entity-column col-lg-4 col-md-4">{item.enityName}</div>
      <div className="entity-column col-lg-2 col-md-2">
        <DropDown
          inputName="DeductionStatus"
          value={data.DeductionStatus}
          onChange={onChange}
        />
      </div>

      <div className="entity-column col-lg-2 col-md-2">
        <InputFieldWithSign
          sign="$"
          className="input-field"
          inputName="DeductionEmployeeAmount"
          value={data.DeductionEmployeeAmount}
          onChange={onChange}
        />
      </div>
      <div className="entity-column col-lg-2 col-md-2">
        <InputFieldWithSign
          sign="$"
          className="input-field"
          inputName="DeductionEmployerAmount"
          value={data.DeductionEmployerAmount}
          onChange={onChange}
        />
      </div>
      <div className="entity-column col-lg-2 col-md-2">
        <DatePicker
          name="DeductionEndDate"
          onChange={onChange}
          getFromToValue={onChange}
          value={data.DeductionEndDate}
          showNoDateSection
        />
      </div>
    </div>
  );
};
export default DeductionItem;
