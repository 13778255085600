import React from "react";
import "./NoAddress.css";
const NoAddressContainer = ({ onEditAddressClick, onContactClick }) => {
  return (
    <div>
      <div
        style={{ fontWeight: 600, marginBottom: "5px", fontSize: "15px" }}
        className="hidden-lg hidden-md"
      >
        Suggested Address:
      </div>
      <div className="no-address-component">
        <div className="title">
          <i className="fa fa-exclamation-triangle" />
          <i>Address Not Recognized</i>
        </div>
        <div className="buttons-container">
          <button
            className="nc-button outline-button"
            onClick={onEditAddressClick}
          >
            <i className="fa fa-pencil" />Edit
          </button>
          <button className="nc-button outline-button" onClick={onContactClick}>
            Contact Employee
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoAddressContainer;
NoAddressContainer.defaultProps = {
  onEditAddressClick: () => alert("Should edit the address"),
  onContactClick: () => alert("Should Contact the employee")
};
