import React from "react";
import Media from "react-media";
import JobTaskItemDesktop from "./JobTaskItemDesktop";
import JobTaskItemMobile from "./JobTaskItemMobile";

const JobTaskItem = props => (
  <Media query="(max-width: 991px)">
    {isMobile => {
      const Options = isMobile ? JobTaskItemMobile : JobTaskItemDesktop;
      return <Options {...props} />;
    }}
  </Media>
);

export default JobTaskItem;
