import React, { Component } from "react";
import SortFilterButton from "./../../filters/SortFilterButton";
import FiltersModal from "./FiltersModal";
import SortModal from "./SortModal";

export default class FiltersMobile extends Component {
  state = {
    showFilterModal: false,
    showSortModal: false
  };

  toggleFilterModal = showFilterModal => {
    this.setState({ showFilterModal });
    if (this.state.showFilterModal === true) {
      this.props.onCloseFilter();
    }
  };

  toggleSortModal = showSortModal => {
    this.setState({ showSortModal });
    if (this.state.showSortModal === true) {
      this.props.onCloseFilter();
    }
  };

  render() {
    const {
      options = {},
      selectedOptions = {},
      makeOnSelect = () => undefined,
      makeSelectAll = () => undefined,
      sortOrder,
      editSortOrder,
      clearAll,
      filterCount
    } = this.props;
    const { showFilterModal, showSortModal } = this.state;
    return (
      <div className="nc-filter-container hidden-lg hidden-md">
        <SortFilterButton
          sortHandler={() => this.toggleSortModal(true)}
          filterHandler={() => this.toggleFilterModal(true)}
          filterCount={filterCount}
          style={{ marginBottom: 0 }}
        />
        <FiltersModal
          show={showFilterModal}
          closePanel={() => this.toggleFilterModal(false)}
          options={options}
          selectedOptions={selectedOptions}
          makeOnSelect={makeOnSelect}
          makeSelectAll={makeSelectAll}
          clearAll={clearAll}
        />
        <SortModal
          show={showSortModal}
          closePanel={() => this.toggleSortModal(false)}
          editSortOrder={editSortOrder}
          sortOrder={sortOrder}
        />
      </div>
    );
  }
}
