import React from "react";
import DayPicker from "./src/DayPicker";
import DateUtils from "./src/DateUtils";
import moment from "moment";
import Select from "react-select";
import Media from "react-media";
import PropTypes from "prop-types";
import "./src/style.css";
import { Overlay, Modal } from "react-bootstrap";
const initialState = {
  from: null,
  to: null,
  enteredTo: null // Keep track of the last day for mouseEnter.
};

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const fromMonth = new Date(currentYear, currentMonth);
const toMonth = new Date(currentYear + 50, 11);

function isSelectingFirstDay(from, to, day) {
  const firstDayIsNotSelected = !from;
  const selectedDayIsBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
  const rangeIsSelected = from && to;
  return (
    firstDayIsNotSelected || selectedDayIsBeforeFirstDay || rangeIsSelected
  );
}

// Component will receive date, locale and localeUtils props
function YearMonthForm({ date, localeUtils, onChange, showCurrentYearOnly }) {
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const logChange = function logChange(val) {
    let value = JSON.stringify(val.value);
    onChange(new Date(value, currentMonth));
  };
  function showCustomIcon() {
    return <i className="fa fa-chevron-down" aria-hidden="true" />;
  }
  let select_options = [];
  years.map((year, i) => select_options.push({ value: year, label: year }));

  return (
    <form className="DayPicker-Caption">
      {/* <select name="month" onChange={handleChange} value={date.getMonth()} disabled>
        {months.map((month, i) => <option key={i} value={i}>{month}</option>)}
      </select> */}
      <span className="month-name">{months[date.getMonth()]}</span>
      {/* <select className="year-selector" name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map((year, i) =>
          <option key={i} value={year}>
            {year}
          </option>
        )}
      </select> */}
      <Select
        name="year"
        className="year-selector"
        value={date.getFullYear()}
        options={select_options}
        onChange={showCurrentYearOnly ? null : logChange}
        arrowRenderer={showCurrentYearOnly ? null : showCustomIcon}
        clearable={false}
        searchable={false}
      />
    </form>
  );
}

export default class DateRangePicker extends React.Component {
  state = {
    month: fromMonth,
    from: null,
    to: null,
    enteredTo: null
  };

  componentWillReceiveProps(props) {
    if (props.selectedItems) {
      this.setState({
        from: props.selectedItems.from,
        to: props.selectedItems.to,
        enteredTo: props.selectedItems.to
      });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props !== nextProps) {
      if (nextProps.value === undefined) {
        this.setState({
          from: null,
          to: null,
          enteredTo: null
        });
      } else {
        this.setState({
          from: nextProps.value.from,
          to: nextProps.value.to,
          enteredTo: nextProps.value.to
        });
      }
    }
  }

  handleYearMonthChange = month => {
    this.setState({ month });
  };

  handleDayClick = day => {
    const { from, to } = this.state;
    if (from && to && day >= from && day <= to) {
      this.reset();
      return;
    }

    if (isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: null,
        enteredTo: null
      });
    } else {
      this.setState({
        to: day,
        enteredTo: day
      });
    }
  };

  handleDayMouseEnter = day => {
    const { from, to } = this.state;

    if (!isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day
      });
    }
  };

  reset = () => {
    this.setState(initialState);
  };
  toggle = () => {
    this.setState({ show: !this.state.show });
  };

  onHideOverlay = () => {
    let fromToValue = {
      from: this.state.from,
      to: this.state.to
    };
    console.log("fromtovalue", fromToValue);
    if (this.props.onChange) {
      this.props.onChange({ name: this.props.inputName, value: fromToValue });
    } else {
      this.props.getFromToValue("", fromToValue);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedItems !== this.props.selectedItems) {
      if (this.props.selectedItems) {
        if (
          this.props.selectedItems.from === undefined &&
          this.props.selectedItems.to === undefined
        ) {
          // this.reset();
        }
      }
    }
  }

  render() {
    const { showCurrentYearOnly = false, placeholder, Id } = this.props;
    const { from, to, enteredTo } = this.state;
    const modifiers = { start: from, end: enteredTo };
    const selectedDays = [from, { from, to: enteredTo }];
    const dayPickerButton = (
      <button
        id={Id}
        ref={button => (this.target = button)}
        className=""
        onClick={this.toggle}
      >
        <i className="fa fa-calendar calendar-icon" aria-hidden="true" />
        {placeholder &&
        (!this.state.from || !this.state.to || !this.state.enteredTo) ? (
          placeholder
        ) : (
          <span>
            <Media query="(min-width:992px) and (max-width:1400px)">
              {matches =>
                matches ? (
                  <span style={{ fontSize: "13.5px" }}>
                    {from ? moment(from).format("MM/DD") : "From"}
                  </span>
                ) : (
                  <span>{from ? moment(from).format("L") : "From"}</span>
                )
              }
            </Media>

            <span className="date-seperator">-</span>
            <Media query="(min-width:992px) and (max-width:1400px)">
              {matches =>
                matches ? (
                  <span style={{ fontSize: "13.5px" }}>
                    {to ? moment(enteredTo).format("L") : "To"}
                  </span>
                ) : (
                  <span>{to ? moment(enteredTo).format("L") : "To"}</span>
                )
              }
            </Media>
          </span>
        )}
      </button>
    );
    let sDate = "";
    let eDate = "";
    const formatDate = timeStr => {
      return moment(new Date(timeStr + "+00:00")).format("DD/MM/YYYY");
    };
    sDate = formatDate(this.state.from);
    eDate = formatDate(this.state.to);
    return (
      <Media query="(min-width:992px)">
        {matches =>
          matches ? (
            <div
              className="nc-select-container"
              id="filter-activity-darange-overlay"
              style={{ position: "relative", ...this.props.style }}
            >
              {dayPickerButton}

              <Overlay
                show={this.state.show}
                onHide={() => {
                  this.setState({ show: false });
                  this.onHideOverlay();
                  this.props.onHide();
                }}
                placement="bottom"
                target={this.target}
                container={this}
                rootClose={true}
              >
                <div className="YearNavigation">
                  <DayPicker
                    enableOutsideDays
                    month={this.state.month}
                    fromMonth={fromMonth}
                    toMonth={toMonth}
                    onDayClick={this.handleDayClick}
                    onDayMouseEnter={this.handleDayMouseEnter}
                    modifiers={modifiers}
                    selectedDays={selectedDays}
                    captionElement={
                      <YearMonthForm
                        onChange={this.handleYearMonthChange}
                        showCurrentYearOnly={showCurrentYearOnly}
                      />
                    }
                  />
                  <div className="calendar-button-group-wrapper">
                    <div className="calendar-button-group">
                      <button
                        className="nc-button primary-button"
                        onClick={() => {
                          this.setState({ show: false });
                          this.onHideOverlay();
                          this.props.onHide();
                        }}
                      >
                        Apply
                      </button>
                      <button
                        className="secondary-link-button pull-right"
                        onClick={this.reset}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Overlay>
            </div>
          ) : (
            <div className="nc-select-container" id="filter-activity-entity">
              {dayPickerButton}
              <Modal
                bsClass="calendar modal"
                animation={false}
                backdrop={"static"}
                keyboard={false}
                show={this.state.show}
                onHide={() => {
                  this.setState({ show: false });
                  this.onHideOverlay();
                }}
              >
                <Modal.Header>
                  <div className="hidden-md hidden-lg date-range-label">
                    <div className="close-icon">
                      <i
                        onClick={() => {
                          this.setState({ show: false });
                          this.onHideOverlay();
                        }}
                        className="fa fa-times-circle-o"
                      />
                    </div>
                    <div className="date-picker-header">Select Date Range</div>
                  </div>
                </Modal.Header>
                <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div
                    className="clearfix"
                    style={{
                      height: `${window.innerHeight - 191}px`,
                      overflowY: "scroll"
                    }}
                  >
                    <DayPicker
                      enableOutsideDays
                      month={this.state.month}
                      fromMonth={fromMonth}
                      toMonth={toMonth}
                      onDayClick={this.handleDayClick}
                      onDayMouseEnter={this.handleDayMouseEnter}
                      modifiers={modifiers}
                      selectedDays={selectedDays}
                      // captionElement={
                      //   <YearMonthForm
                      //     onChange={this.handleYearMonthChange}
                      //     showCurrentYearOnly={showCurrentYearOnly}
                      //   />
                      // }
                    />
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <div className="calendar-button-group-wrapper">
                    <div className="calendar-button-group">
                      <div className="set-display">
                        <div
                          style={{
                            display: "flex",
                            width: "50%",
                            marginRight: "5px"
                          }}
                        >
                          <div className="start-date"> Start </div>
                          <div className="start-date">
                            {sDate !== "Invalid date" ? (
                              sDate
                            ) : (
                              <i>SelectDate</i>
                            )}{" "}
                          </div>
                        </div>
                        <div style={{ display: "flex", width: "50%" }}>
                          <div className="end-date"> End </div>
                          <div className="end-date">
                            {eDate !== "Invalid date" ? (
                              eDate
                            ) : (
                              <i>SelectDate</i>
                            )}
                          </div>
                        </div>
                      </div>
                      <button
                        className="nc-button primary-button"
                        onClick={() => {
                          this.setState({ show: false });
                          this.onHideOverlay();
                          // this.props.onHide();
                        }}
                      >
                        Apply
                      </button>
                      <button
                        className="secondary-link-button pull-right"
                        onClick={this.reset}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
          )
        }
      </Media>
    );
  }
}

DateRangePicker.propTypes = {
  getFromToValue: PropTypes.func,
  onHide: PropTypes.func
};

DateRangePicker.defaultProps = {
  getFromToValue: () => {},
  onHide: () => {}
};
