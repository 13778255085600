import React from "react";
import "./svg.css";
const NoEmployeeUpdates = ({ width, height }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 244 232"
      style={{ enableBackground: "new 0 0 244 232" }}
      width={width}
      height={height}
    >
      <circle className="st0" cx="118" cy="114" r="100" />
      <g>
        <path
          className="st1"
          d="M166.7,81.6l-31.9-27.9c-0.5-0.4-1-0.6-1.6-0.6H89.5c-7.2,0-13.1,5.9-13.1,13.1v95.8c0,7.2,5.9,13.1,13.1,13.1h64.9c7.2,0,13.1-5.9,13.1-13.1V83.5C167.5,82.8,167.2,82.1,166.7,81.6z M135.7,61L158.4,81h-15.2c-4.1,0-7.5-3.4-7.5-7.5V61zM154.5,170H89.5c-4.5,0-8.1-3.6-8.1-8.1V66.1c0-4.5,3.6-8.1,8.1-8.1h41.1v15.5c0,6.9,5.6,12.5,12.5,12.5h19.4v75.9C162.5,166.3,158.9,170,154.5,170z"
        />
        <path
          className="st1"
          d="M122,120.9c7.5,0,13.7-6.1,13.7-13.7c0-7.5-6.1-13.7-13.7-13.7c-7.5,0-13.7,6.1-13.7,13.7C108.3,114.8,114.5,120.9,122,120.9z M122,98.5c4.8,0,8.7,3.9,8.7,8.7c0,4.8-3.9,8.7-8.7,8.7s-8.7-3.9-8.7-8.7C113.3,102.4,117.2,98.5,122,98.5z"
        />
        <path
          className="st1"
          d="M126.7,121.6h-9.4c-11.9,0-21.6,9.7-21.6,21.6v10.2c0,1.4,1.1,2.5,2.5,2.5h47.6c1.4,0,2.5-1.1,2.5-2.5v-10.2C148.3,131.3,138.6,121.6,126.7,121.6z M143.3,150.9h-42.6v-7.7c0-9.2,7.4-16.6,16.6-16.6h9.4c9.2,0,16.6,7.4,16.6,16.6V150.9z"
        />
      </g>
    </svg>
  );
};
export default NoEmployeeUpdates;
