import React, { Component } from "react";
import PropTypes from "prop-types";
import EditJobDetail from "./EditJobDetail";

const mapOption = ({ Id, Name }) => ({ value: Id, label: Name });

export default class JobDetailsItem extends Component {
  state = {
    editMode: false
  };

  toggleEditMode = v => {
    this.setState({ editMode: !this.state.editMode });
  };

  render() {
    const { data, onSave } = this.props;
    const [field, before, after] = data.ValueNode.NodeValues;
    return (
      <div className="table">
        <div className="tbody">
          <div
            className="tr col-sm-12"
            style={{ paddingTop: 10, paddingBottom: 10 }}
          >
            <div className="td col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="field-header hidden-md hidden-lg">
                Field :&nbsp;
              </div>
              <div className="field-value">{field}</div>
            </div>
            <div className="td col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="field-header hidden-md hidden-lg">
                Before :&nbsp;
              </div>
              <div className="field-value">{before}</div>
            </div>
            <div className="td col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="col-xs-10">
                <div className="field-header hidden-md hidden-lg">
                  After :&nbsp;
                </div>
                <div className="field-value">{after}</div>
              </div>
              {data.IsFieldEditable && (
                <div className="col-xs-2">
                  <i
                    className="fa fa-pencil float-right"
                    style={{ cursor: "pointer" }}
                    onClick={this.toggleEditMode}
                  />
                </div>
              )}
            </div>
          </div>
          <EditJobDetail
            show={this.state.editMode}
            onCancelClick={() => this.setState({ editMode: false })}
            type={data.FieldType}
            value={after}
            onSaveClick={onSave}
            options={(data.EntityFieldOptions || []).map(mapOption)}
          />
        </div>
      </div>
    );
  }
}

JobDetailsItem.propTypes = {
  data: PropTypes.object,
  onSave: PropTypes.func
};
