import React from "react";
import Media from "react-media";

const StartOverButton = ({ onClick }) => (
  <button
    id="payroll-dash-start-over-button"
    className="secondary-link-button hidden-xs"
    onClick={onClick}
  >
    Start Over
  </button>
);
const AddeditButton = ({ onClick }) => (
  <button
    id="payroll-dash-add-edit-button"
    className="nc-button secondary-button"
  >
    ADD/EDIT PAYROLL DATA
  </button>
);

const ReviewButton = ({ onClick }) => (
  <button id="payroll-dash-review-button" className="nc-button primary-button">
    Review Details
  </button>
);

const InProgress = ({
  onStartOver = () => {},
  addEdit = () => {},
  onReview = () => {}
}) => (
  <div className="payroll-dash-summary-footer-buttons nc-button-group">
    <StartOverButton onClick={onStartOver} />
    <AddeditButton onClick={addEdit} />
    <ReviewButton onClick={onReview} />
    <Media query="(max-width:767px)">
      <button
        id="payroll-dash-start-over-button"
        className="secondary-link-button hidden-sm hidden-md hidden-lg"
        onClick={() => this.setState({ startOverModal: true })}
      >
        Start Over
      </button>
    </Media>
    {/* 
    <PayrollDashBoardModal
      show={this.state.startOverModal}
      bodyContent={this.startOverModalContent()}
      closeModal={this.closeStartOverModal}
      footerButtons={this.startOverModalButtons()}
      buttonAlign="center"
    /> */}
  </div>
);

export default InProgress;
