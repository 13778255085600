import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchAndSetAddressVerificationItems,
  updateEmployeeAddress,
  fetchAndSetState
} from "../network/actions";
import {
  editEntityData,
  clearEntityData
} from "app/common/reducers/entityData";
import {
  clearEntityErrors,
  editEntityErrors
} from "app/common/reducers/entityErrors";
import { clearAddressValues } from "../reducers/addressVerifications";
import { makeOptions, makeSelectedOptions } from "app/common/reducers/filters";
import { AddressVerification } from "component-library/payroll-dashboard/wagetaxvalidation";
import { NoPayrollChecklistAlertsToCorrent } from "component-library/payroll-dashboard/address-verification";
import { setSortOrder, clearSortOrder } from "app/common/reducers/sortOrder";
import PayrollDashBoardModal from "component-library/payroll-dashboard/payroll-dashboard-modals/index";
import { FormSection, FormFieldGroup } from "component-library/formSections";
class AddressVerificationContainer extends Component {
  state = {
    openEditModal: false,
    editId: ""
  };

  componentDidMount() {
    const {
      fetchAndSetAddressVerificationItems,
      match: {
        params: { payrollId }
      }
    } = this.props;

    fetchAndSetAddressVerificationItems(parseInt(payrollId, 10));
  }

  componentWillUnmount() {
    this.props.clearSortOrder();
    this.props.clearAddressValues();
  }

  changeSortOrder = (field, sortOrder) => {
    const {
      fetchAndSetAddressVerificationItems,
      match: {
        params: { payrollId }
      },
      setSortOrder
    } = this.props;

    setSortOrder(field, sortOrder);
    fetchAndSetAddressVerificationItems(parseInt(payrollId, 10));
    setTimeout(
      function() {
        this.searchPostsUnapprovedPto();
      }.bind(this),
      10
    );
  };

  makeOnSelect = name => (option, selected) => {
    this.props.selectOption(name, option, selected);
  };

  makeSelectAll = name => selected => {
    this.props.selectAll(name, selected);
  };

  updateAddress = (Id = 0, data) => {
    const { updateEmployeeAddress } = this.props;
    const data1 = this.props.payrollTypeData.AddressVerification;
    updateEmployeeAddress(Id, data1);
  };

  editAddress = (Id, data) => {
    const { editData } = this.props;
    const { EmployeeCurrentHomeAddress = {} } = data;
    this.setState({
      openEditModal: true,
      editId: Id
    });
    this.props.fetchAndSetState(EmployeeCurrentHomeAddress.CountryId);
    editData(EmployeeCurrentHomeAddress);
  };

  onChange = e => {
    const { editErrors, editData, statesList = [] } = this.props;
    const edits = { [e.name]: e.value };
    const errors = { [e.name]: null };

    if (e.name === "AddressLine1") {
      edits[e.name] = e.value;
    } else if (e.name === "AddressLine2") {
      edits[e.name] = e.value;
    } else if (e.name === "ZipCode") {
      edits[e.name] = e.value;
    } else if (e.name === "City") {
      edits[e.name] = e.value;
    } else if (e.name === "StateId") {
      edits[e.name] = e.value;
      const { label } =
        statesList.find(option => "" + option.value === "" + e.value) || {};
      edits[e.name] = label;
    }

    editErrors(errors);
    editData(edits);
  };

  modalBody = () => {
    const { errors = {}, data = {}, statesList = [] } = this.props;
    return (
      <div className="modal-body-container">
        <div className="modal-body-header">
          <span style={{ fontSize: "20px", marginRight: "5px" }}>
            <i className="fa fa-pencil" />
          </span>
          Edit Current Home Address
        </div>
        <div style={{ marginBottom: "5px" }} className="modal-body-content">
          <FormSection>
            <FormFieldGroup
              show={true}
              inputType="text"
              inputName={"AddressLine1"}
              label="Address Line 1"
              Size={[6, 6, 8, 12]}
              required={true}
              value={data.AddressLine1}
              onChange={this.onChange}
              error={errors.AddressLine1}
            />
            <FormFieldGroup
              show={true}
              inputType="text"
              inputName={"AddressLine2"}
              label="Address Line 2"
              Size={[6, 6, 8, 12]}
              optional
              value={data.AddressLine2}
              onChange={this.onChange}
              error={errors.AddressLine2}
            />
            <FormFieldGroup
              show={true}
              inputType="numeric"
              inputName={"ZipCode"}
              label="Zip Code"
              Size={[3, 3, 8, 12]}
              required={true}
              value={data.ZipCode}
              onChange={this.onChange}
              error={errors.ZipCode}
            />
            <FormFieldGroup
              show={true}
              inputType="text"
              inputName={"City"}
              label="City"
              Size={[6, 6, 8, 12]}
              required={true}
              value={data.City}
              onChange={this.onChange}
              error={errors.City}
            />
            <FormFieldGroup
              show={true}
              inputType="dropdown"
              inputName={"StateId"}
              label="State"
              Size={[2, 2, 6, 12]}
              onChange={this.onChange}
              value={data.StateId}
              options={statesList}
              required={true}
              error={errors.StateId}
            />
          </FormSection>
        </div>
      </div>
    );
  };

  closeFooterButton = () => {
    const modalButton = [
      {
        label: "Save Changes",
        buttonType: "secondary-button",
        onClick: this.onSaveClick
      },
      {
        label: "Back",
        buttonType: "secondary-link-button",
        onClick: this.closeModal
      }
    ];

    return modalButton;
  };

  closeModal = () => {
    this.setState({
      openEditModal: false
    });
  };

  onSaveClick = () => {
    this.setState({
      openEditModal: false
    });
  };

  highlighter = (newElem, oldElem) => {
    var newText = newElem;
    var oldText = oldElem;
    var text = "";
    newText.split("").forEach(function(value, index) {
      if (value !== oldText.charAt(index))
        text += "<span class='highlight'>" + value + "</span>";
      else text += value;
    });
    return text;
  };

  render() {
    const { redirectToChecklist } = this.props;
    const dataCount = this.props.payrollTypeData.AddressVerification.DataCount;
    return (
      <div>
        {dataCount > 0 ? (
          <div>
            <AddressVerification
              data={this.props.payrollTypeData.AddressVerification.Data}
              updateAddressForEmployee={data =>
                this.updateAddress(data.Id, data)
              }
              editAddress={data => this.editAddress(data.Id, data)}
              sectionHr
              redirectToChecklist={redirectToChecklist}
            />
          </div>
        ) : (
          <NoPayrollChecklistAlertsToCorrent checklistAlert="address(es)" />
        )}
        <PayrollDashBoardModal
          show={this.state.openEditModal}
          bodyContent={this.modalBody()}
          closeModal={this.closeModal}
          footerButtons={this.closeFooterButton()}
          buttonAlign="left"
        />
      </div>
    );
  }
}

const stateToProps = state => ({
  payrollTypeData: state.payrollDashboard.payrollTypeData,
  filterData: makeOptions(state.common.filters),
  selectedOptions: makeSelectedOptions(state.common.filters),
  sortOrder: state.common.sortOrder,
  data: state.common.entitySetup.data,
  statesList: state.payrollDashboard.addressVerifications.statesList
});

const actionCreators = {
  fetchAndSetAddressVerificationItems,
  setSortOrder,
  clearSortOrder,
  updateEmployeeAddress: updateEmployeeAddress,
  editData: editEntityData,
  editErrors: editEntityErrors,
  clearData: clearEntityData,
  clearErrors: clearEntityErrors,
  fetchAndSetState,
  clearAddressValues
};

export default withRouter(
  connect(stateToProps, actionCreators)(AddressVerificationContainer)
);
