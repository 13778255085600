import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeOptions } from "../../../base/utils";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import * as Tooltip from "component-library/employee-profile/employment-info/workplace-info/Tooltips";
import NewChangeReasonModal from "component-library/employee-profile/employment-info/workplace-info/NewChangeReasonModal";
import "component-library/employee-profile/employment-info/workplace-info/WorkplaceInfo.css";
import * as Fields from "component-library/employee-profile/employment-info/workplace-info/FieldNames";
import OverlayChangeReasonSection from "component-library/employee-profile/employment-info/workplace-info/OverlayChangeReasonSection";
import OverlayLocationSection from "component-library/employee-profile/employment-info/workplace-info/OverlayLocationSection";
import AddressSection from "component-library/employee-profile/employment-info/workplace-info/AddressSection";
import { fetchAndSetDropdownList } from "../network/actions";
import FooterButtons from "../../change-status/components/FooterButtons";
import AddNewLocationModal from "component-library/employee-profile/employment-info/workplace-info/AddNewLocationModal";
import ChangeInfoWarningModal from "component-library/employee-profile/employment-info/workplace-info/ChangeInfoWarningModal";

class ChangeWorkplaceInfo extends Component {
  state = {
    data: {},
    showChangeReasonModal: false,
    showAddNewLocationModal: false,
    showChangeInfoWarningModal: false,
    primaryManagerChanged: false,
    secondaryManagerChanged: false
  };
  onChange = e => {
    const data = { [e.name]: e.value };
    let FilterData = this.props.filters;
    const {
      employee = [],
      PrimaryManager = [],
      SecondaryManager = []
    } = FilterData;
    if (e.name === Fields.changeReasonName) {
      data[e.name] = e.value;
    } else if (e.name === Fields.newLocation) {
      data[e.name] = e.value;
    } else if (e.name === Fields.changeDate) {
      data[e.name] = e.value;
    } else if (e.name === Fields.changeReason) {
      const { label } =
        employee.find(option => "" + option.value === "" + e.value) || {};
      data[e.name] = label;
    } else if (e.name === Fields.division) {
      const { label } =
        employee.find(option => "" + option.value === "" + e.value) || {};
      data[e.name] = label;
    } else if (e.name === Fields.businessUnit) {
      const { label } =
        employee.find(option => "" + option.value === "" + e.value) || {};
      data[e.name] = label;
    } else if (e.name === Fields.department) {
      const { label } =
        employee.find(option => "" + option.value === "" + e.value) || {};
      data[e.name] = label;
    } else if (e.name === Fields.client) {
      const { label } =
        employee.find(option => "" + option.value === "" + e.value) || {};
      data[e.name] = label;
    } else if (e.name === Fields.project) {
      const { label } =
        employee.find(option => "" + option.value === "" + e.value) || {};
      data[e.name] = label;
    } else if (e.name === Fields.location) {
      const { label } =
        employee.find(option => "" + option.value === "" + e.value) || {};
      data[e.name] = label;
    } else if (e.name === Fields.primaryManager) {
      const { label } =
        PrimaryManager.find(option => "" + option.value === "" + e.value) || {};
      data[e.name] = label;
      this.setState({
        primaryManagerChanged: true
      });
    } else if (e.name === Fields.secondaryManager) {
      const { label } =
        SecondaryManager.find(option => "" + option.value === "" + e.value) ||
        {};
      data[e.name] = label;
      this.setState({
        secondaryManagerChanged: true
      });
    }
    this.editData(data);
  };

  editData(edits) {
    this.setState({ data: { ...this.state.data, ...edits } });
  }

  onCancelClick = () => {};

  onPrimaryClick = () => {
    this.setState({
      showChangeInfoWarningModal: true
    });
  };

  render() {
    const { filters = {} } = this.props;
    const { data } = this.state;
    return (
      <div className="nc-entity-page-container change-info-modal">
        <FormSection title="Change Details" bottomHr>
          <FormFieldGroup
            inputName={Fields.changeDate}
            label="Change Date"
            inputType="singleDatePicker"
            fieldType="inline"
            colSpan="col-lg-3"
            onChange={this.onChange}
            tooltip={Tooltip.CHANGE_DATE}
            required={true}
          />
          <OverlayChangeReasonSection
            inputName={[Fields.changeReason]}
            label="Change Reason"
            inputType="dropdown"
            fieldType="inline"
            onChange={this.onChange}
            value={data[Fields.changeReason]}
            options={filters !== null && filters.employee}
            colSpan="col-lg-3"
            Size={[4, 6, 8, 12]}
            class={"set-width"}
            onAddLinkClick={() => {
              this.setState({
                showChangeReasonModal: true
              });
            }}
            required={true}
          />
        </FormSection>
        <FormSection title="New Workplace" bottomHr>
          <FormFieldGroup
            inputName={[Fields.division]}
            label="Division"
            inputType="dropdown"
            fieldType="inline"
            colSpan="col-lg-3"
            Size={[4, 6, 8, 12]}
            onChange={this.onChange}
            value={data[Fields.division]}
            options={filters !== null && filters.employee}
            required={true}
          />
          <FormFieldGroup
            inputName={[Fields.businessUnit]}
            label="Business Unit"
            inputType="dropdown"
            fieldType="inline"
            colSpan="col-lg-3"
            Size={[4, 6, 8, 12]}
            onChange={this.onChange}
            value={data[Fields.businessUnit]}
            options={filters !== null && filters.employee}
            required={true}
          />
          <FormFieldGroup
            inputName={[Fields.department]}
            label="Department"
            inputType="dropdown"
            fieldType="inline"
            colSpan="col-lg-3"
            Size={[4, 6, 8, 12]}
            onChange={this.onChange}
            value={data[Fields.department]}
            options={filters !== null && filters.employee}
            required={true}
          />
          <FormFieldGroup
            inputName={[Fields.client]}
            label="Client"
            inputType="dropdown"
            fieldType="inline"
            colSpan="col-lg-3"
            Size={[4, 6, 8, 12]}
            onChange={this.onChange}
            value={data[Fields.client]}
            options={filters !== null && filters.employee}
            optional
          />
          <FormFieldGroup
            inputName={[Fields.project]}
            label="Project"
            inputType="dropdown"
            fieldType="inline"
            colSpan="col-lg-3"
            Size={[4, 6, 8, 12]}
            onChange={this.onChange}
            value={data[Fields.project]}
            options={filters !== null && filters.employee}
            optional
          />
        </FormSection>
        <FormSection title="Location" bottomHr>
          <OverlayLocationSection
            inputName={[Fields.location]}
            label="Location"
            inputType="dropdown"
            colSpan="col-lg-3"
            Size={[4, 6, 8, 12]}
            fieldType="inline"
            onChange={this.onChange}
            value={data[Fields.location]}
            options={filters !== null && filters.employee}
            tooltip={Tooltip.LOCATION}
            tooltipPosition={"label"}
            onAddLinkClick={() => {
              this.setState({
                showAddNewLocationModal: true
              });
            }}
            required={true}
          />
          <AddressSection
            inputName={[Fields.address]}
            label="Address"
            colSpan="col-lg-3"
            Size={[4, 6, 8, 12]}
            onChange={this.onChange}
            addressLine1={data[Fields.addressLine1]}
            addressLine2={data[Fields.addressLine2]}
          />
        </FormSection>
        <FormSection title="Management" bottomHr>
          <FormFieldGroup
            inputName={[Fields.primaryManager]}
            label="Primary Manager"
            inputType="dropdown"
            fieldType="inline"
            colSpan="col-lg-3"
            Size={[4, 6, 8, 12]}
            onChange={this.onChange}
            value={data[Fields.primaryManager]}
            options={filters !== null && filters.PrimaryManager}
            optional
          />
          <FormFieldGroup
            inputName={[Fields.secondaryManager]}
            label="Secondary Manager"
            inputType="dropdown"
            fieldType="inline"
            colSpan="col-lg-3"
            Size={[4, 6, 8, 12]}
            onChange={this.onChange}
            value={data[Fields.secondaryManager]}
            options={filters !== null && filters.SecondaryManager}
            optional
          />
        </FormSection>
        <FormSection title="Additional Details">
          <FormFieldGroup
            inputName={Fields.notes}
            label="Notes"
            inputType="textarea"
            rows={5}
            description={"250 characters max"}
            descriptionStyle={"italic"}
            maxLength={"250"}
            fieldType="inline"
            colSpan="col-lg-3"
            Size={[6, 8, 12, 12]}
            onChange={this.onChange}
            value={data[Fields.notes]}
            optional
          />
        </FormSection>
        <FooterButtons
          buttonLabel="Change Workplace Info"
          onCancelClick={this.props.onCloseOverlay}
          onPrimaryClick={this.props.onAddLinkClick}
        />
        {this.state.showChangeReasonModal && (
          <NewChangeReasonModal
            show={this.state.showChangeReasonModal}
            closeModal={() =>
              this.setState({
                showChangeReasonModal: false
              })
            }
            onCreate={() =>
              this.setState({
                showChangeReasonModal: false
              })
            }
            data={data}
            onChange={this.onChange}
          />
        )}
        {this.state.showAddNewLocationModal && (
          <AddNewLocationModal
            show={this.state.showAddNewLocationModal}
            closeModal={() =>
              this.setState({
                showAddNewLocationModal: false
              })
            }
            onCreate={() =>
              this.setState({
                showAddNewLocationModal: false
              })
            }
            data={data}
            onChange={this.onChange}
          />
        )}
        {this.state.showChangeInfoWarningModal &&
          (this.state.primaryManagerChanged ||
            this.state.secondaryManagerChanged) && (
            <ChangeInfoWarningModal
              show={this.state.showChangeInfoWarningModal}
              closeModal={() =>
                this.setState({
                  showChangeInfoWarningModal: false
                })
              }
              onPrimaryActionHandler={() =>
                this.setState({
                  showChangeInfoWarningModal: false,
                  primaryManagerChanged: false,
                  secondaryManagerChanged: false
                })
              }
              data={data}
              primaryManagerChanged={this.state.primaryManagerChanged}
              secondaryManagerChanged={this.state.secondaryManagerChanged}
            />
          )}
      </div>
    );
  }
}

const stateToProps = state => {
  return {
    filters: makeOptions(state.common.filters)
  };
};

const actionCreators = {
  loadDropdownList: fetchAndSetDropdownList
};

export default withRouter(
  connect(stateToProps, actionCreators)(ChangeWorkplaceInfo)
);
