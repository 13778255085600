import React from "react";
const renderTab = (props, Component) => item => {
  let className = item.id === props.activeTab ? "active" : " ";
  return (
    <Component
      key={item.id}
      className={className}
      {...item}
      onSelect={props.onChange}
    />
  );
};

export default renderTab;
