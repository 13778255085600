import React, { Component } from "react";
import moment from "moment";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import {
  dependentsBasicInfo,
  BASIC_INFO,
  LABEL,
  Age,
  TITLE,
  SSN,
  DOB,
  UNMASKED_SSN,
  AGE,
  circularCheckboxOption
} from "../Constants";

class DependentBasicInfo extends Component {
  state = {
    showSSN: false,
    showDOB: false,
    selected: {}
  };
  onSelect = (key, value) => {
    const data = { ...this.state.selected, [key]: value };
    this.setState({
      selected: data
    });
  };

  handleChange = k => {
    const name = k.target && k.target.id;
    if (name === SSN && !this.state.showSSN) {
      this.setState({
        showSSN: true
      });
    } else if (name === DOB) {
      this.setState({ showDOB: true });
    } else {
      this.props.onChange(k);
    }
  };
  render() {
    const { data } = this.props;
    const { initialData } = data;

    let componentProps = dependentsBasicInfo;
    componentProps[TITLE].radio = initialData.nameTitle || [];
    componentProps.suffix.options = initialData.nameSuffix || [];
    if (this.state.showSSN) {
      componentProps[SSN].inputName = UNMASKED_SSN;
      componentProps[SSN].inputId = UNMASKED_SSN;
      componentProps[SSN].inputType = "text";
    }
    if (this.state.showDOB) {
      data[DOB] = moment("03/20/1992").format("MM/DD/YYYY");
      componentProps[DOB].inputType = LABEL;
      data[AGE] = moment().diff(moment("03/20/1992"), "year") + "yrs. old";
      componentProps[AGE] = Age;
    }
    const basicInfoNodes = Object.keys(componentProps).map(k => {
      return (
        <FormFieldGroup
          key={k}
          {...componentProps[k]}
          block
          colSpan="col-md-5"
          value={data[componentProps[k]["inputName"]]}
          onChange={this.handleChange}
          error={data.errors[componentProps[k]["inputName"]]}
        />
      );
    });
    return (
      <FormSection title={BASIC_INFO}>
        <div className="col-md-6" style={{ padding: "0px" }}>
          <FormFieldGroup
            show={true}
            inputId="Type"
            inputType="circularCheckbox"
            inputName="Type"
            label="Type"
            optional={true}
            block
            options={circularCheckboxOption}
            selected={this.state.selected}
            colSpan="col-md-5"
            value={data["Type"]}
            onChange={this.onSelect}
            required={true}
          />
          {basicInfoNodes}
        </div>
      </FormSection>
    );
  }
}

export default DependentBasicInfo;
