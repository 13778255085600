import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const SelectInputDropdown = ({
  id,
  options,
  onChange,
  onOpen,
  arrowRenderer,
  selectedValue,
  searchable,
  error,
  formFieldStyle,
  disabled
}) => {
  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass = wrapperClass + " has-error";
  }
  return (
    <div className={wrapperClass} style={formFieldStyle}>
      <div className="field">
        <Select
          id={id}
          options={options}
          simpleValue
          clearable={false}
          value={selectedValue}
          onChange={onChange}
          onOpen={onOpen}
          searchable={searchable}
          arrowRenderer={arrowRenderer}
          autofocus={false}
          disabled={disabled}
        />
        {error && <p className="text-danger">{error}</p>}
      </div>
    </div>
  );
};

SelectInputDropdown.propTypes = {
  id: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  selectedValue: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  arrowRenderer: PropTypes.func,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool
};

export default SelectInputDropdown;
