import React, { Component } from "react";
import getInputComponent from "component-library/inputFields/getInputComponent";
import CustomRadioTab from "component-library/inputFields/CustomRadioTab";
import Toaster from "component-library/toasters";
import * as FIELDS from "../../utils/Constants";

const options = [
  { id: 1, value: 1, label: "home" },
  { id: 2, value: 2, label: "cell" },
  { id: 3, value: 3, label: "work" },
  { id: 4, value: 4, label: "other" }
];
class SupervisorPhoneNumber extends Component {
  state = { showTooltip: false };
  showTooltipHandler = () => {
    this.setState({
      showTooltip: !this.state.showTooltip
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        showTooltip: false
      });
    }
  }

  render() {
    const { props } = this;

    let wrapperClass;
    if (props.class === undefined) {
      wrapperClass = "nc-field-group clearfix";
    } else {
      wrapperClass = "nc-field-group " + props.class + " clearfix";
    }
    if (
      (props.value == null || props.value === "") &&
      props.required &&
      props.error
    ) {
      wrapperClass = wrapperClass + " has-error " + props.inputName;
    }

    let Component = getInputComponent("numeric");
    return (
      <div
        className={wrapperClass}
        style={{ clear: "both", display: "flex", alignItems: "center" }}
      >
        {props.Toaster &&
          props.Toaster.show && (
            <Toaster
              show={props.Toaster.show}
              alertClassName={props.Toaster.type}
              message={props.Toaster.content}
            />
          )}
        <div
          id={"Label" + props.inputName}
          style={{ paddingRight: "15px" }}
          className={"col-lg-3 col-md-3 col-sm-3 field-name clearfix"}
        >
          {props.label} <i className="optional-field">(Optional) </i>
        </div>
        {props.inputType === "Label" ? (
          <div style={{ marginBottom: "15px" }}>{props.value}</div>
        ) : (
          <div
            className={"col-lg-9 col-md-9 col-sm-9"}
            style={{ clear: "both", display: "flex" }}
          >
            <Component
              {...props}
              onChange={props.onChange}
              onSelect={props.onChange}
              inputName={props.inputName}
              className={"input-field col-xs-4"}
              value={props.data[props.inputName]}
              id={props.inputName}
              placeholder={"504-888-0093"}
            />

            <CustomRadioTab
              options={options}
              onChange={props.onChange}
              name={FIELDS.SUPERVISOR_PHONE_NUMBER_TYPE}
              className={"input-field col-xs-8"}
              value={props.data[FIELDS.SUPERVISOR_PHONE_NUMBER_TYPE]}
              customMarginLeft={"20px"}
              childWidth={"70px"}
            />
          </div>
        )}
      </div>
    );
  }
}

export default SupervisorPhoneNumber;
