import {
  mapEventTypeData,
  mapFieldData,
  mapUserData
} from "component-library/job-history/util";

export const sampleEventTypesResponse = {
  Id: 0,
  Status: "Completed",
  ErrorCount: 0,
  Errors: null,
  DataCount: 3,
  Data: [
    { Id: 1, Name: "Insurance Dental PreTax" },
    { Id: 12, Name: "Retirement401(k)-PreTax" },
    { Id: 34, Name: "Garnishment Child Support" }
  ],
  NextUri: null,
  PreviousUri: null
};

export const sampleUsersResponse = {
  Id: 0,
  Status: "Completed",
  ErrorCount: 0,
  Errors: null,
  DataCount: 3,
  Data: [
    { UserId: 1, UserDisplayName: "Jane Smith" },
    { UserId: 2, UserDisplayName: "Netchex Support" },
    { UserId: 3, UserDisplayName: "John Doe" }
  ],
  NextUri: null,
  PreviousUri: null
};

export const sampleFieldsResponse = {
  Id: 0,
  Status: "Completed",
  ErrorCount: 0,
  Errors: null,
  DataCount: 28,
  Data: [
    {
      ActivityEntityFieldId: 1,
      ActivityEntityFieldKey: "DeductionName",
      ActivityEntityFieldShortName: null,
      ActivityEntityFieldName: "Deduction Name",
      ActivityEntityFieldLongName: null,
      ActivityEntityFieldGroupId: null,
      ActivityEntityId: 1,
      DisplayOrder: 1,
      IsActive: true,
      IsNew: true,
      IsDeleted: false,
      LastModifiedByUserId: 0,
      LastModifiedDate: "2017-08-17T10:32:33",
      LastModifiedViaSource: "NETCHEX_SYSTEM",
      ActivityEntityName: null,
      ActivityEntityFieldGroupName: null
    },
    {
      ActivityEntityFieldId: 2,
      ActivityEntityFieldKey: "DeductionType",
      ActivityEntityFieldShortName: null,
      ActivityEntityFieldName: "Deduction Type",
      ActivityEntityFieldLongName: null,
      ActivityEntityFieldGroupId: null,
      ActivityEntityId: 1,
      DisplayOrder: 2,
      IsActive: true,
      IsNew: true,
      IsDeleted: false,
      LastModifiedByUserId: 0,
      LastModifiedDate: "2017-08-17T10:32:33",
      LastModifiedViaSource: "NETCHEX_SYSTEM",
      ActivityEntityName: null,
      ActivityEntityFieldGroupName: null
    },
    {
      ActivityEntityFieldId: 3,
      ActivityEntityFieldKey: "ReduceW2Box1",
      ActivityEntityFieldShortName: null,
      ActivityEntityFieldName:
        "Reduces W-2 Box 1 : Wages, Tips, Other Compensation",
      ActivityEntityFieldLongName: null,
      ActivityEntityFieldGroupId: 1,
      ActivityEntityId: 1,
      DisplayOrder: 3,
      IsActive: true,
      IsNew: true,
      IsDeleted: false,
      LastModifiedByUserId: 0,
      LastModifiedDate: "2017-08-17T10:32:33",
      LastModifiedViaSource: "NETCHEX_SYSTEM",
      ActivityEntityName: null,
      ActivityEntityFieldGroupName: null
    },
    {
      ActivityEntityFieldId: 4,
      ActivityEntityFieldKey: "ReduceW2Box3",
      ActivityEntityFieldShortName: null,
      ActivityEntityFieldName: "Reduces W-2 Box 3 : Social Security Wages",
      ActivityEntityFieldLongName: null,
      ActivityEntityFieldGroupId: 1,
      ActivityEntityId: 1,
      DisplayOrder: 4,
      IsActive: true,
      IsNew: true,
      IsDeleted: false,
      LastModifiedByUserId: 0,
      LastModifiedDate: "2017-08-17T10:32:33",
      LastModifiedViaSource: "NETCHEX_SYSTEM",
      ActivityEntityName: null,
      ActivityEntityFieldGroupName: null
    }
  ],
  NextUri: null,
  PreviousUri: null
};

export default {
  eventType: sampleEventTypesResponse.Data.map(mapEventTypeData),
  field: sampleFieldsResponse.Data.map(mapFieldData),
  user: sampleUsersResponse.Data.map(mapUserData)
};
