export const FieldTypes = {
  DATE: "Date",
  OPTION: "Dropdown",
  NOTES: "Notes",
  TEXT: "Text"
};

export const PLACEHOLDER_PIC_URL =
  "https://cdn3.iconfinder.com/data/icons/rcons-user-action/32/boy-512.png";

export const COLLAPSED_SIZE = 3;
