import React, { Component } from "react";
import PropTypes from "prop-types";
import DropDown from "../dropDown";
import DateRangePicker from "../daterangepicker";

const noop = () => {};

const labelMaker = (noLabel, allLabel, someLabel, oneLabel) => (o, s, all) =>
  all
    ? allLabel
    : Object.keys(s).filter(k => s[k]).length === 0
      ? noLabel
      : Object.keys(s).filter(k => s[k]).length === 1
        ? oneLabel
        : someLabel;

export default class JobHistoryFilters extends Component {
  state = {
    displayExtendedFilters: false
  };

  renderShowHideLink = () => {
    const { displayExtendedFilters } = this.state;
    let content = "";
    let arrow_className = "";
    if (!displayExtendedFilters) {
      content = "Show More Filters";
      arrow_className = "down";
    } else {
      content = "Hide Filters";
      arrow_className = "up";
    }

    return (
      <button className="primary-link-button">
        <i className={`fa fa-chevron-` + arrow_className} />
        {content}
      </button>
    );
  };

  toggleExtendedFilters = event => {
    event.preventDefault();
    const { displayExtendedFilters } = this.state;
    this.setState({ displayExtendedFilters: !displayExtendedFilters });
  };

  render() {
    const {
      options = {},
      selectedOptions = {},
      makeOnSelect = () => noop,
      makeSelectAll = () => noop,
      onCloseFilter = noop,
      hasSecondRow = false
    } = this.props;
    return (
      <div className="nc-filter-container">
        <div className="showing-label"> FILTERS:</div>

        <div className="nc-filter-group">
          <div className="nc-filter-row">
            <div className="nc-filter-dropdown">
              <DateRangePicker
                getFromToValue={(name, fromToValue) => {}}
                onHide={() => {}}
              />
            </div>

            <div className="nc-filter-dropdown">
              <DropDown
                makeLabel={labelMaker(
                  "Select Event Types",
                  "All Event Types",
                  "Event Types",
                  "Event Type"
                )}
                options={options.eventType || []}
                multiSelect
                showSearch
                showBadge
                onSelect={makeOnSelect("eventType")}
                selectedItems={selectedOptions.eventType || {}}
                selectAll={makeSelectAll("eventType")}
                onHide={onCloseFilter}
              />
            </div>

            <div className="nc-filter-dropdown">
              <DropDown
                makeLabel={labelMaker(
                  "Select Fields",
                  "All Fields",
                  "Fields",
                  "Field"
                )}
                multiSelect
                showSearch
                showBadge
                options={options.field || []}
                onSelect={makeOnSelect("field")}
                selectedItems={selectedOptions.field || {}}
                selectAll={makeSelectAll("field")}
                onHide={onCloseFilter}
              />
            </div>

            {hasSecondRow && (
              <div className="more-filter-link" id="filter-more">
                <a href="." onClick={this.toggleExtendedFilters}>
                  {this.renderShowHideLink()}
                </a>
              </div>
            )}
          </div>
          {hasSecondRow && (
            <div
              className="nc-filter-row"
              style={{
                display: this.state.displayExtendedFilters ? "block" : "none"
              }}
            >
              <div className="nc-filter-dropdown">
                <DropDown
                  makeLabel={labelMaker(
                    "Select Users",
                    "All Users",
                    "Users",
                    "User"
                  )}
                  multiSelect
                  showSearch
                  showBadge
                  options={options.user || []}
                  onSelect={makeOnSelect("user")}
                  selectedItems={selectedOptions.user || {}}
                  selectAll={makeSelectAll("user")}
                  onHide={onCloseFilter}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

JobHistoryFilters.propTypes = {
  options: PropTypes.objectOf(PropTypes.array),
  selectedOptions: PropTypes.objectOf(PropTypes.object),
  makeOnSelect: PropTypes.func,
  makeSelectAll: PropTypes.func,
  onCloseFilter: PropTypes.func,
  hasSecondRow: PropTypes.bool
};
