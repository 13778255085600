/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Popover } from "react-bootstrap";
import Proptypes from "prop-types";
import moment from "moment";
import "./Notification.css";
const title = (
  <div
    style={{
      padding: "15px",
      fontWeight: "600"
    }}
  >
    NOTIFICATIONS
    <i style={{ float: "right" }} className="fa fa-cog" />
  </div>
);
const NotificationPopover = (data = []) => {
  return (
    <Popover
      bsClass="notification-popover popover"
      id="popover-positioned-bottom"
      title={title}
    >
      <div style={{ width: "450px", padding: "5px 0px" }}>
        {data.length > 0
          ? data.map((item, index) => (
              <div
                className="notification-item clearfix"
                style={{}}
                key={index}
              >
                <div className="notification-image">
                  <img
                    alt="img-responsive"
                    className="img-responsive"
                    width="50"
                    src={require("../../images/product-images/icon-1.png")}
                  />
                </div>
                <div className="notification-item-content">
                  <div>
                    <i className="fa fa-check" />
                    {item.JobStatus}
                  </div>
                </div>
                <div className="notification-time">
                  {moment(item.NotificationTime).fromNow()}
                </div>
                <div />
              </div>
            ))
          : "No Notifications "}
      </div>
      <div style={{}} className="popover-footer">
        <a className="primary-link">View all Notifications</a>
      </div>
    </Popover>
  );
};

export default NotificationPopover;
NotificationPopover.propTypes = {
  data: Proptypes.array
};
NotificationPopover.defaultProps = {
  data: []
};
moment.updateLocale("en", {
  relativeTime: {
    future: "%s",
    past: "%s",
    s: "Just Now",
    ss: "%d secs ago",
    m: "1 min ago",
    mm: "%d mins ago",
    h: "an hour ago",
    hh: "%d hours ago",
    d: "a day ago",
    dd: "%d days ago",
    M: "a month ago",
    MM: "%d months ago",
    y: "a year ago",
    yy: "%d years ago"
  }
});
