import React, { Component } from "react";
import { EntityListTableHeader } from "../.././entity-list";
import PayrollScheduleItem from "./PayrollScheduleItem";
import PopUpModal from "./PopUpModal";
import SnapshotModal from "./SnapshotModal";
import { PAYROLL_SCHEDULE } from "../.././entity-list/Constants";
import { INACTIVATE_SCHEDULE, DELETE_SCHEDULE } from "./Constants";
import Media from "react-media";
import InfiniteScroll from "react-infinite-scroller";
import * as util from "../../util";

const loader = <div className="loader">Loading ...</div>;

export default class PayrollScheduleTable extends Component {
  state = {
    show: false,
    msg: "",
    itemData: "",
    label: "DELETE SCHEDULE",
    showSnapshot: false
  };

  openSnapshot = item => {
    if (item.CreateWorkFlowStateId !== 1) {
      this.openSnapShotSummary(item);
    }
  };
  openSnapShotSummary(item) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.props.setLoader(true);
    this.props.fetchEntitySnapshot(13, item.ActivityInstanceId, () => {
      this.setState({
        showSnapshot: true
      });
    });
  }

  closeSnapShotSummary = () => {
    this.setState({
      showSnapshot: false
    });
    this.props.clearSnapshotData();
  };

  changeSortOrder = (field, sortOrder) => {
    this.props.editSortOrder(field, sortOrder);
    setTimeout(
      function() {
        this.props.searchPosts();
      }.bind(this),
      10
    );
  };

  render() {
    const { show, itemData, label, showSnapshot } = this.state;
    const {
      data = [],
      onEditClick,
      reactivateEntity,
      //editSortOrder,
      sortOrder,
      loadingFirstTime,
      hasReactivateModal = false,
      handleCalender,
      headerColumn = PAYROLL_SCHEDULE,
      loadMorePosts,
      nextUri,
      loadingMore,
      snapData
    } = this.props;
    return (
      <div className="list-page-grid-table col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <Media query="(min-width:992px)">
          {matches =>
            matches ? (
              <div>
                {loadingFirstTime ? (
                  <div className="nc-rowcount-results hidden-sm hidden-xs">
                    Loading...
                  </div>
                ) : (
                  <div className="nc-rowcount-results hidden-sm hidden-xs">
                    We {data.length === 0 && "have "}
                    found {data.length}{" "}
                    {data.length === 1 ? "result." : "results."}
                  </div>
                )}
              </div>
            ) : null
          }
        </Media>
        <EntityListTableHeader
          //editSortOrder={editSortOrder}
          editSortOrder={this.changeSortOrder}
          sortOrder={sortOrder}
          headerColumn={headerColumn}
        />
        <SnapshotModal
          show={showSnapshot}
          closeModal={this.closeSnapShotSummary}
          snapData={snapData}
        />
        <PopUpModal
          show={show}
          line1={
            "Are you sure you want to delete your '" +
            itemData.name +
            "' Payroll Schedule?"
          }
          primaryButtonLabel={"DELETE SCHEDULE"}
          secondaryButtonLabel={"Cancel"}
          label={label}
          closeModal={() => {
            this.setState({
              show: false
            });
          }}
          onPrimaryActionHandler={() =>
            this.setState(
              {
                show: false
              },
              () => {
                if (label === DELETE_SCHEDULE) {
                  this.props.onDeleteClick(itemData);
                } else {
                  this.props.inactivateEntity(itemData.id, itemData.name);
                }
              }
            )
          }
          onCancel={() => {
            this.setState({
              show: false
            });
          }}
        />
        <InfiniteScroll
          pageStart={0}
          loadMore={!loadingMore ? loadMorePosts : () => {}}
          hasMore={!util.isNullOrEmpty(nextUri) ? true : false}
          loader={loader}
        >
          {data.length > 0 ? (
            data.map(item => (
              <PayrollScheduleItem
                item={item}
                key={item.id}
                onDeleteClick={() => {
                  if (item.isPayrollScheduleStarted) {
                    this.setState({
                      show: true,
                      msg:
                        "This payroll schedule has been used before. We will mark it as inactive to preserve payment history. You can reactivate this payroll schedule if you change your mind.",
                      itemData: item,
                      label: INACTIVATE_SCHEDULE
                    });
                  } else {
                    this.setState({
                      show: true,
                      msg:
                        "Are you sure you want to delete your " +
                        item.name +
                        " Payroll Schedule ? ",
                      itemData: item,
                      label: DELETE_SCHEDULE
                    });
                  }
                }}
                onEditClick={() => onEditClick(item)}
                handleCalender={() => handleCalender(item.id)}
                onReactivateClick={() => {
                  if (hasReactivateModal) {
                    this.toggleReactivateModal(true, item);
                  } else {
                    reactivateEntity(item.id, item.name);
                  }
                }}
                onItemClick={() => this.openSnapshot(item)}
              />
            ))
          ) : (
            <div />
          )}
        </InfiniteScroll>
      </div>
    );
  }
}
