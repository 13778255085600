import React from "react";
import moment from "moment";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import PropTypes from "prop-types";
import "./notesListItem.css";

const NotesListItem = ({ item }) => {
  return (
    <div
      className="table-row col-lg-12 col-md-12 col-sm-12 col-xs-12 note-Item-container"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div className="col-lg-7 col-md-2 col-sm-12 col-xs-12 table-cell">
        <div
          className="col-lg-6 col-md-2 col-sm-12 col-xs-12"
          style={{ float: "left", marginRight: "6%" }}
        >
          <span className="field-value note-type-tag type">
            {item.NoteTypeName}
          </span>
        </div>

        <div
          className="col-lg-6 col-md-2 col-sm-12 col-xs-12"
          style={{ width: "fit-content", float: "left" }}
        >
          <span className="field-value type-tag date">
            {moment().format("MMMM Do, YYYY")}
          </span>
        </div>
      </div>
      <div className="status-container col-lg-3 col-md-2 col-sm-12 col-xs-12">
        <span className="hidden-lg hidden-md field-header">Description : </span>
        {item.NoteDiscription}
      </div>
      <div className="col-lg-2 col-md-2 col-sm-3 col-xs-3 list-page-grid-table-cell-actions">
        <div>
          <div className="list-page-delete-section">
            <i
              id="entity-delete-1"
              className="list-page-delete-icon fa fa-trash-o"
            />
          </div>
          <div className="list-page-edit-section">
            <i
              id="entity-edit-1"
              className="list-page-edit-icon fa fa-pencil"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesListItem;
