/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Media from "react-media";

class SystemLevelDeductionDescriptionStep extends Component {
  state = {
    selectedValue: 0
  };

  renderDeductionSteps = list => {
    return list.map((item, index) => {
      let isSavedPage = false;
      let isCurrentPage = false;
      let activeStyle = "inaccessible-step";
      let currentPage =
        this.props.systemDeductionData.UiDraftCompletionStage + 1;

      if (
        this.props.systemDeductionData.UiDraftCompletionStage >= item.PageId
      ) {
        isSavedPage = true;
        activeStyle = "accessible-step";
      }

      if (currentPage >= item.PageId) {
        isCurrentPage = true;
      }

      if (this.props.pageId === item.PageId) {
        activeStyle = "active-step";
      }

      if (isSavedPage) {
        return (
          <li
            id={"track-progress-step-menu-" + item.PageId}
            key={index}
            className={`step-menu-item ` + activeStyle}
            onClick={this.goToPage.bind(this, item.NavLink, item.PageId)}
          >
            <i
              id="add-deduction-step-sub-menu-badge"
              className="fa fa-check-circle"
            />

            {item.Name}
          </li>
        );
      } else if (isCurrentPage) {
        return (
          <li
            id={"track-progress-step-menu-" + item.PageId}
            key={index}
            className={`step-menu-item ` + activeStyle}
            onClick={this.goToPage.bind(this, item.NavLink, item.PageId)}
          >
            <i
              id="add-deduction-step-sub-menu-badge"
              className="fa fa-check-circle"
              style={{ visibility: "hidden" }}
            />
            {item.Name}
          </li>
        );
      } else {
        return (
          <li
            id={"track-progress-step-menu-" + item.PageId}
            key={index}
            className={`step-menu-item ` + activeStyle}
          >
            <i
              id="add-deduction-step-sub-menu-badge"
              className="fa fa-check-circle"
              style={{ visibility: "hidden" }}
            />
            {item.Name}
          </li>
        );
      }
    });
  };

  renderDeductionStepsSmallScreen = list => {
    return list.map(item => {
      let isSavedPage = false;
      let activeStepStyle = "bg-grey";
      let isCurrentPage = false;
      let currentPage =
        this.props.systemDeductionData.UiDraftCompletionStage + 1;

      if (currentPage >= item.PageId) {
        isCurrentPage = true;
      }

      if (
        this.props.systemDeductionData.UiDraftCompletionStage >= item.PageId
      ) {
        isSavedPage = true;
      }
      if (this.props.pageId === item.PageId) {
        activeStepStyle = "bg-green";
      }
      if (isSavedPage) {
        return (
          <div key={item.Name} className="deduction-circle-icons">
            <div className="visible-sm visible-xs deduction-steps-item-section">
              <div className="bg-green">
                <i className="fa fa-check" />
              </div>
              <div className="step-name align-center">
                <a
                  className="add-deduction-step-sub-menu-link-name"
                  onClick={this.goToPage.bind(this, item.NavLink, item.PageId)}
                >
                  {item.ShortName}
                </a>
              </div>
            </div>
          </div>
        );
      } else if (isCurrentPage) {
        return (
          <div key={item.Name} className="deduction-circle-icons">
            <div className="visible-sm visible-xs deduction-steps-item-section">
              <div className="bg-grey">
                <i className="fa fa-check invisible" />
              </div>
              <div className="step-name align-center">
                <a
                  className="add-deduction-step-sub-menu-link-name"
                  onClick={this.goToPage.bind(this, item.NavLink, item.PageId)}
                >
                  {item.ShortName}
                </a>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div key={item.Name} className="deduction-circle-icons">
            <div className="visible-sm visible-xs deduction-steps-item-section">
              <div className={activeStepStyle}>
                <i className="fa fa-check invisible" />
              </div>
              <div className="step-name align-center">
                <a className="add-deduction-step-sub-menu-link-name">
                  {item.ShortName}
                </a>
              </div>
            </div>
          </div>
        );
      }
    });
  };

  goToPage(navLink, id) {
    this.props.onClickTrackYourProgress(navLink, id);
  }

  showDeductionLink(item) {
    let activeStyle = "";
    let activeMobileStyle = "bg-grey";
    let currentVisiblity = "invisible";
    if (this.props.pageId === item.PageId) {
      activeStyle = "selected-deduction-page";
      activeMobileStyle = "bg-green";
    }

    let isSavedPage = false;
    if (this.props.systemDeductionData.UiDraftCompletionStage >= item.PageId) {
      isSavedPage = true;
    }

    if (isSavedPage) {
      return (
        <div>
          <div
            id={item.Name}
            className={
              `add-deduction-step-sub-menu-with-checked hidden-sm hidden-xs ` +
              activeStyle
            }
          >
            <i
              id="add-deduction-step-sub-menu-badge"
              className="fa fa-check-circle"
            />
            <span
              className="add-deduction-step-sub-menu-link-name"
              onClick={this.goToPage.bind(this, item.NavLink, item.PageId)}
            >
              {item.Name}
            </span>
          </div>
          <div
            id={item.name}
            className="visible-sm visible-xs deduction-steps-item-section"
          >
            <div className="bg-green">
              <i
                className={`fa fa-check`}
                aria-hidden="true"
                style={{ fontSize: "20px" }}
              />
            </div>
            <div className="step-name align-center">
              <span
                className="add-deduction-step-sub-menu-link-name"
                onClick={this.goToPage.bind(this, item.NavLink, item.PageId)}
              >
                {item.Name}
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div
            id={item.Name}
            className={
              `add-deduction-step-sub-menu-link-name add-deduction-step-sub-menu-without-checked hidden-xs hidden-sm ` +
              activeStyle
            }
          >
            {item.Name}
          </div>
          {/* for Mobile */}
          <div
            id={item.Name}
            className="visible-sm visible-xs deduction-steps-item-section"
          >
            <div className={activeMobileStyle}>
              <i
                className={`fa fa-check` + currentVisiblity}
                aria-hidden="true"
              />
            </div>
            <div className="step-name align-center">
              <a className="add-deduction-step-sub-menu-link-name">
                {item.Name}
              </a>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    let summaryLabel = "";
    if (this.props.systemDeductionData.UiDraftCompletionStage === 6) {
      summaryLabel = "Summary";
    } else {
      summaryLabel = "Review and Save";
    }

    const AddDeductionStepList = [
      {
        PageId: 1,
        Name: "Description",
        ShortName: "Description",
        Islink: false,
        NavLink: `/system-level-deduction-description/${
          this.props.match.params.SystemDeductionId
        }`
      },
      {
        PageId: 2,
        Name: "Calculation Method",
        ShortName: "Calculation",
        Islink: false,
        NavLink: `/system-level-calculation-method/${
          this.props.match.params.SystemDeductionId
        }`
      },
      {
        PageId: 3,
        Name: "Deduction Cycle",
        ShortName: "Cycle",
        Islink: false,
        NavLink: `/system-level-deduction-cycle/${
          this.props.match.params.SystemDeductionId
        }`
      },
      {
        PageId: 4,
        Name: "Default Values",
        ShortName: "Values",
        Islink: false,
        NavLink: `/system-level-deafult-value/${
          this.props.match.params.SystemDeductionId
        }`
      },
      {
        PageId: 5,
        Name: "Options",
        ShortName: "Options",
        Islink: false,
        NavLink: `/system-level-deduction-options/${
          this.props.match.params.SystemDeductionId
        }`
      },
      {
        PageId: 6,
        Name: summaryLabel,
        ShortName: "Review",
        Islink: false,
        NavLink: `/system-level-review-and-save/${
          this.props.match.params.SystemDeductionId
        }`
      }
    ];
    return (
      <Media query="(max-width:991px)">
        {matches =>
          matches ? (
            <div className="add-deduction-step-mobile visible-sm visible-xs">
              <div className="hbar" />
              <div className="add-deduction-step-mobile-table">
                <div className="add-deduction-step-sub-menu-mobile">
                  {this.renderDeductionStepsSmallScreen(AddDeductionStepList)}
                </div>
              </div>
            </div>
          ) : (
            <div className="progress-tracker">
              <div className="title">Track Your Progress</div>
              <ul className="step-menu">
                {this.renderDeductionSteps(AddDeductionStepList)}
              </ul>
            </div>
          )
        }
      </Media>
    );
  }
}

function mapStateToProps(state) {
  return {
    savedSysDeductionPages: state.savedSysDeductionPages,
    systemDeductionData: state.systemDeductionData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    SystemLevelDeductionDescriptionStep
  )
);
