import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./JobHistoryHeader.css";

const SectionLink = ({ label, icon, to = "#" }) => (
  <Link to={to}>
    <i className={icon} /> {label}
  </Link>
);

const JobHistoryHeader = ({ sectionTitle, sectionPath, filesPath }) => (
  <div>
    <div className="job-history-pagetitle">Job History</div>

    <div className="job-history-section-links">
      <SectionLink
        label={sectionTitle}
        icon="fa fa-clipboard"
        to={sectionPath}
      />
      <div className="job-history-section-link-separator" />
      <SectionLink label="Job History" icon="fa fa-clipboard" to="#" />
      <div className="job-history-section-link-separator" />
      <SectionLink label="Files" icon="fa fa-clipboard" to={filesPath} />
    </div>
  </div>
);

JobHistoryHeader.propTypes = {
  sectionTitle: PropTypes.string,
  sectionPath: PropTypes.string,
  filesPath: PropTypes.string
};

export default JobHistoryHeader;
