/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import PayrollDashBoardModal from "./payroll-dashboard-modals/index";

export default class PayrollDashboardYearEndRollOver extends Component {
  state = {
    contactServiceRepresentativeModal: false
  };

  serviceRepresentativeSection = () => {
    const {
      representativeName,
      representativePhoneNumber,
      representativeEmail
    } = this.props;
    return (
      <div className="modal-body-container">
        <div className="modal-body-header">
          Contact Your Service Representative
        </div>
        <div className="modal-body-content">
          <div className="modal-body-gray-section">
            <div className="clearfix text-center">
              <div className="two-line-modal-content">
                Your Representative is
              </div>
              <div className="secondary-header">
                <div className="two-line-modal-content">
                  {representativeName}
                </div>
                <div className="two-line-modal-content">
                  {representativePhoneNumber}
                </div>
                <div className="primary-link-button two-line-modal-content">
                  {representativeEmail}
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix content-section text-center">
            Or, <span className="primary-link-button">log a case</span> in
            NetCommunity.
          </div>
        </div>
      </div>
    );
  };

  serviceRepresentativeButton = () => {
    const modalButton = [
      {
        label: "Close",
        buttonType: "secondary-button",
        onClick: this.closeServiceRepresentativeModal
      }
    ];

    return modalButton;
  };

  closeServiceRepresentativeModal = () => {
    this.setState({
      contactServiceRepresentativeModal: false
    });
  };

  render() {
    const { show } = this.props;
    if (show) {
      return (
        <div className="toaster-container">
          <div className="toaster">
            <div className="alert alert-warning clearfix">
              <div id="toaster-message" style={{ width: "92%", float: "left" }}>
                {" "}
                <div>
                  <i className="fa fa-exclamation-circle" />
                  Your account is being rolled over into 2018. You will not be
                  able to run or edit payrolls until this process is complete.
                </div>
                <div>
                  <a
                    onClick={() =>
                      this.setState({ contactServiceRepresentativeModal: true })
                    }
                  >
                    Contact your service representative
                  </a>{" "}
                  with any questions.
                </div>
              </div>
            </div>
          </div>

          <PayrollDashBoardModal
            show={this.state.contactServiceRepresentativeModal}
            bodyContent={this.serviceRepresentativeSection()}
            closeModal={this.closeServiceRepresentativeModal}
            footerButtons={this.serviceRepresentativeButton()}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}
