export const ENTITY_TYPE = {
    DEDUCTIONS: 1,
    TPP:2,
    DEDUCTIONS_SYS: 3,
    TPP_SYS:4,
    EARNINGS_GROUP: 5,
    EARNINGS_SYS: 6,
    EARNINGS: 7,
    DEDUCTIONS_TAX: 8,
    MAP_GL_DEDUCTIONS: 9,
    MAP_GL_EARNINGS:10,
    HOLIDAY_CALENDAR:11,
    EMAIL_TEXT_ALERTS :12,
    PAYROLL_SCHEDULE :13,
    MAP_GL_TAXES:14

  };
  
export const PAYROLL_SCHEDULE_ID = ENTITY_TYPE.PAYROLL_SCHEDULE;
export const ALERT_ID = ENTITY_TYPE.EMAIL_TEXT_ALERTS;
export const EARNING_GROUPS_ID = ENTITY_TYPE.EARNINGS_GROUP;
export const HOLIDAY_CALENDAR_ID = ENTITY_TYPE.HOLIDAY_CALENDAR;



  