/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Panel, Overlay } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { Badge } from "react-bootstrap";
import {
  getEarningsList,
  setSelectedFilters
} from "../../../actions/generalledger/earnings/EmployerPaidEarningsActions.js";
import { Checkbox, CheckboxGroup } from "react-checkbox-group";
import { Scrollbars } from "react-custom-scrollbars";

import CustomOverlay from "component-library/dropDown/CustomOverlay";
import classnames from "classnames/bind";
const ALL_DEDUCTIONS = 0;
const DEDUCTION_PANEL_ID = 1;

let isAllUncheckedZero = false;

class GeneralLedgerEarningsDropdown extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      autoSuggestionDeductionList: [],
      selectedDeductions: props.selectedDeductions,
      selectDeductionLabel: "All Earnings",
      isAllSelected: true,
      autoSuggestionTextBox: ""
    };
    this.checkSelectDeduction = this.checkSelectDeduction.bind(this);
    this.renderDeductionSelectionList = this.renderDeductionSelectionList.bind(
      this
    );
    this.handleChange = this.handleChange.bind(this);
    this.onCloseDeductionDropdown = this.onCloseDeductionDropdown.bind(this);
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  handleChange(event) {
    let strValue = event.target.value;
    let arrayList = [];
    this.setState({ autoSuggestionTextBox: strValue });
    this.props.employerEarningsList.forEach(item => {
      if (
        item.CompanyEarningName.toLowerCase().indexOf(strValue.toLowerCase()) >=
        0
      ) {
        arrayList.push(item);
      }
    });
    this.setState({ autoSuggestionDeductionList: arrayList });
  }

  checkSelectDeduction(newDeduction) {
    let deductionLabel =
      newDeduction.length > 1
        ? "Earnings"
        : newDeduction.length === 0
        ? "Select Earnings"
        : "Earnings";

    if (newDeduction.includes(ALL_DEDUCTIONS)) {
      let indexOfAllDeduction = newDeduction.indexOf(ALL_DEDUCTIONS);
      if (
        indexOfAllDeduction !== 0 ||
        this.state.selectedDeductions.length === 0
      ) {
        newDeduction = [];
        for (let i = 0; i < this.props.employerEarningsList.length; i++) {
          let CompanyEarningId = this.props.employerEarningsList[i]
            .CompanyEarningId;
          newDeduction.push(CompanyEarningId);
        }

        this.setState({
          selectedDeductions: newDeduction,
          selectDeductionLabel: "All Earnings",
          isAllSelected: true
        });
      } else {
        newDeduction.splice(0, 1);
        this.setState({
          selectedDeductions: newDeduction,
          selectDeductionLabel: deductionLabel,
          isAllSelected: false
        });
      }
    } else if (this.state.isAllSelected && !newDeduction.includes(0)) {
      this.setState({
        selectedDeductions: [],
        selectDeductionLabel: "Select Earnings",
        isAllSelected: false
      });
    } else {
      if (
        this.props.employerEarningsList.length - 1 === newDeduction.length &&
        !newDeduction.includes(ALL_DEDUCTIONS)
      ) {
        newDeduction = [];
        for (let i = 0; i < this.props.employerEarningsList.length; i++) {
          let CompanyEarningId = this.props.employerEarningsList[i]
            .CompanyEarningId;
          newDeduction.push(CompanyEarningId);
        }
        this.setState({
          selectedDeductions: newDeduction,
          selectDeductionLabel: "All Earnings",
          isAllSelected: true
        });
      } else {
        this.setState({
          selectedDeductions: newDeduction,
          selectDeductionLabel: deductionLabel,
          isAllSelected: false
        });
      }
    }

    if (
      newDeduction.length === 0 ||
      (this.state.isAllSelected === true &&
        newDeduction.includes(ALL_DEDUCTIONS) === false &&
        newDeduction.indexOf(ALL_DEDUCTIONS) === -1 &&
        newDeduction.length === this.props.employerEarningsList.length - 1)
    ) {
      isAllUncheckedZero = true;
    } else {
      isAllUncheckedZero = false;
    }
    this.setState({ popoverOpen: true });
  }

  componentDidMount() {
    //this.props.getEarningsList();
    let selectedDeductions = [];
    if (
      this.props.setSelectedFiltersReducer !== undefined &&
      this.props.setSelectedFiltersReducer !== null &&
      this.props.setSelectedFiltersReducer.length !== 0
    ) {
      if (this.props.setSelectedFiltersReducer.selectedDeductions.length > 0) {
        this.props.setSelectedFiltersReducer.selectedDeductions.forEach(
          selectedItem => {
            selectedDeductions.push(selectedItem);
          }
        );
      }
    } else {
      if (this.props.employerEarningsOriginalList.length !== 0) {
        selectedDeductions[0] = 0;
        this.props.employerEarningsOriginalList.Data.forEach(item => {
          selectedDeductions.push(item.CompanyEarningId);
        });
      }
    }

    let selectDeductionLabel = "Earnings";
    let isAllSelected = false;

    if (this.props.employerEarningsList.length === selectedDeductions.length) {
      selectDeductionLabel = "All Earnings";
      isAllSelected = true;
    } else if (selectedDeductions.length === 0) {
      selectDeductionLabel = "Select Earnings";
      isAllSelected = false;
    } else if (selectedDeductions.length === 1) {
      selectDeductionLabel = "Earnings";
      isAllSelected = false;
    } else {
      selectDeductionLabel = "Earnings";
      isAllSelected = false;
    }

    let arrayList = [];
    this.props.employerEarningsList.forEach(item => {
      arrayList.push(item);
    });
    this.setState({
      selectedDeductions,
      autoSuggestionDeductionList: arrayList,
      selectDeductionLabel,
      isAllSelected
    });

    this.props.onSelectedDeductions(selectedDeductions);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.employerEarningsOriginalList !==
      this.props.employerEarningsOriginalList
    ) {
      let selectedDeductions = [];
      selectedDeductions[0] = 0;
      if (nextProps.employerEarningsOriginalList.Data) {
        nextProps.employerEarningsOriginalList.Data.forEach(item => {
          selectedDeductions.push(item.CompanyEarningId);
        });
      }

      let selectDeductionLabel = "Earnings";
      let isAllSelected = false;

      if (
        nextProps.employerEarningsOriginalList.DataCount + 1 ===
        selectedDeductions.length
      ) {
        selectDeductionLabel = "All Earnings";
        isAllSelected = true;
      } else {
        selectDeductionLabel = "Earnings";
        isAllSelected = false;
      }

      let arrayList = [];
      this.props.employerEarningsList.forEach(item => {
        arrayList.push(item);
      });
      this.setState({
        selectedDeductions,
        autoSuggestionDeductionList: arrayList,
        selectDeductionLabel,
        isAllSelected
      });
    }

    if (nextProps.isResetToDefault !== this.props.isResetToDefault) {
      if (nextProps.isResetToDefault) {
        let selectedDeductions = [];
        selectedDeductions[0] = 0;
        if (nextProps.employerEarningsOriginalList.Data) {
          nextProps.employerEarningsOriginalList.Data.forEach(item => {
            selectedDeductions.push(item.CompanyEarningId);
          });
        }

        let selectDeductionLabel = "Earnings";
        let isAllSelected = false;

        if (
          nextProps.employerEarningsOriginalList.DataCount + 1 ===
          selectedDeductions.length
        ) {
          selectDeductionLabel = "All Earnings";
          isAllSelected = true;
        } else {
          selectDeductionLabel = "Earnings";
          isAllSelected = false;
        }
        let arrayList = [];
        this.props.employerEarningsList.forEach(item => {
          arrayList.push(item);
        });
        this.setState({
          selectedDeductions,
          autoSuggestionDeductionList: arrayList,
          selectDeductionLabel,
          isAllSelected
        });
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedDeductions !== this.state.selectedDeductions) {
      this.props.onSelectedDeductions(this.state.selectedDeductions);
    }
  }

  onCloseDeductionDropdown() {
    this.props.onSelectedDeductions(this.state.selectedDeductions);
  }

  renderDeductionSelectionListMobile() {
    if (!this.props.employerEarningsList) {
      return <div />;
    } else {
      return (
        <Scrollbars
          autoHeight={true}
          autoHeightMax={320}
          renderThumbHorizontal={props => (
            <div style={{ overflowX: "hidden" }} className="thumb-horizontal" />
          )}
        >
          <CheckboxGroup
            name="select-Earning"
            value={this.state.selectedDeductions}
            onChange={this.checkSelectDeduction}
            className=""
          >
            <ul className="nc-dropdown-navigation-multiselect">
              {this.renderDeductionSelectionItem(
                this.state.autoSuggestionDeductionList
              )}
            </ul>
          </CheckboxGroup>
        </Scrollbars>
      );
    }
  }

  renderDeductionSelectionList() {
    if (!this.props.employerEarningsList) {
      return <div />;
    } else {
      return (
        <CustomOverlay>
          <div className="form-group">
            <div className="field">
              <input
                id="deduction-auto-suggestion-textbox"
                type="text"
                name="autoSuggestionTextBox"
                className="form-control"
                placeholder="&#xF002; Search"
                onChange={this.handleChange}
                onFocus={e => (e.target.placeholder = "")}
                onBlur={e =>
                  (e.target.placeholder =
                    String.fromCharCode("0xF002") + " Search")
                }
                value={this.state.autoSuggestionTextBox}
              />
            </div>
          </div>
          <Scrollbars
            autoHeight={true}
            autoHeightMax={320}
            renderThumbHorizontal={props => (
              <div
                style={{ overflowX: "hidden" }}
                className="thumb-horizontal"
              />
            )}
          >
            <CheckboxGroup
              name="select-Earning"
              value={this.state.selectedDeductions}
              onChange={this.checkSelectDeduction}
            >
              <ul className="nc-dropdown-navigation-multiselect">
                {this.renderDeductionSelectionItem(
                  this.state.autoSuggestionDeductionList
                )}
              </ul>
            </CheckboxGroup>
          </Scrollbars>
        </CustomOverlay>
      );
    }
  }

  renderDeductionSelectionItem(earningsDropDownList) {
    return earningsDropDownList.map((item, index) => {
      return (
        <li key={index} className="list-item">
          <Checkbox id={item.CompanyEarningId} value={item.CompanyEarningId} />
          <label
            htmlFor={item.CompanyEarningId}
            id={
              item.CompanyEarningName.replace(/ /g, "-").toLowerCase() +
              "-deduction"
            }
          >
            {item.CompanyEarningName}
          </label>
        </li>
      );
    });
  }

  openSubPanelById(id) {
    let update = {};

    if (id === this.state.subPanelId) {
      update["subPanelId"] = 0;
    } else {
      update["subPanelId"] = id;
    }

    this.setState(update);
  }

  render() {
    const deductionSelectionPanel = this.renderDeductionSelectionList();
    const Id = this.props.Id;

    if (!this.props.employerEarningsList) {
      return <div>No Records Found.</div>;
    }
    if (window.innerWidth <= 991) {
      return (
        <div>
          <div className="mobile-filter-panel-list-section">
            <a
              id=""
              onClick={this.openSubPanelById.bind(this, DEDUCTION_PANEL_ID)}
            >
              <div className="mobile-filter-panel-list-panel-bar col-xs-12 col-sm-12">
                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-icon">
                  {this.state.subPanelId === DEDUCTION_PANEL_ID ? (
                    <i
                      className="fa fa-chevron-up add_deduction_popup_panel_link"
                      aria-hidden="true"
                    />
                  ) : (
                    <i
                      className="fa fa-chevron-down add_deduction_popup_panel_link"
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="col-xs-8 col-sm-8 mobile-filter-panel-list-label no-padding">
                  {this.state.selectDeductionLabel}
                </div>
                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-badge no-padding">
                  {isAllUncheckedZero ? null : this.state
                      .isAllSelected ? null : (
                    <Badge className="filter-select-badge">
                      {this.state.selectedDeductions.length}
                    </Badge>
                  )}
                </div>
              </div>
            </a>
            <div className="mobile-filter-panel-list-panel-bar-section">
              <Panel
                className="mobile-filter-panel-list-panel-default no-padding col-xs-12 col-sm-12 col-md-12 col-lg-12 "
                collapsible
                expanded={
                  this.state.subPanelId === DEDUCTION_PANEL_ID ? true : false
                }
              >
                {" "}
                <Panel.Collapse>
                  <Panel.Body>
                    <div className="form-group">
                      <div className="field">
                        <input
                          id="deduction-auto-suggestion-textbox"
                          type="text"
                          name="autoSuggestionTextBox"
                          className="form-control"
                          placeholder="&#xF002; Search"
                          onChange={this.handleChange}
                          onFocus={e => (e.target.placeholder = "")}
                          onBlur={e =>
                            (e.target.placeholder =
                              String.fromCharCode("0xF002") + " Search")
                          }
                          value={this.state.autoSuggestionTextBox}
                        />
                      </div>
                    </div>
                    {this.renderDeductionSelectionListMobile()}
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="nc-select-container hidden-sm hidden-xs">
            <Button
              id={Id.entityFilter}
              className=""
              onClick={this.toggle}
              ref={button => (this.entityFilter = button)}
            >
              <span className="selected-value">
                {this.state.selectDeductionLabel}
              </span>
              <i
                className={classnames(
                  "pull-right fa",
                  this.state.popoverOpen ? "fa-chevron-up" : "fa-chevron-down"
                )}
              />
              {isAllUncheckedZero ? null : this.state.isAllSelected ? null : (
                <Badge className="filter-select-badge pull-right badge">
                  {this.state.selectedDeductions.length}
                </Badge>
              )}
            </Button>
          </div>
          <Overlay
            show={this.state.popoverOpen}
            onHide={() => this.setState({ popoverOpen: false })}
            placement="bottom"
            container={this}
            rootClose={true}
            target={this.entityFilter}
          >
            {deductionSelectionPanel}
          </Overlay>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  let earningsDropDownList = [
    {
      CompanyEarningId: 0,
      CompanyEarningName: "All Earnings"
    }
  ];

  let selectedDeductions = [];
  selectedDeductions[0] = 0;
  if (state.generalLedgerEarningsReducer.employerEarningsList.Data) {
    state.generalLedgerEarningsReducer.employerEarningsList.Data.forEach(
      item => {
        earningsDropDownList.push(item);
        selectedDeductions.push(item.CompanyEarningId);
      }
    );
  }
  return {
    employerEarningsList: earningsDropDownList,
    selectedDeductions: selectedDeductions,
    employerEarningsOriginalList:
      state.generalLedgerEarningsReducer.employerEarningsList,
    setSelectedFiltersReducer:
      state.generalLedgerEarningsReducer.setSelectedFiltersReducer
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getEarningsList, setSelectedFilters }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralLedgerEarningsDropdown);
