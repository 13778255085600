import React from "react";
import FormAccordian from "component-library/accordians/FormAccordian";
import * as Fields from "./FieldNames";
import * as Description from "../FieldDescriptions";

import { FormFieldGroup, FormSection } from "component-library/formSections";
import { NoStatusChanges } from "./common";
const labelClass = "col-lg-3 col-md-3";
// const commonProps = {
//   fieldType: "inline",
//   colSpan: labelClass
// };

const HireRehire = ({ onChange, data = {} }) => {
  return (
    <FormAccordian title="Leave" id="4">
      <div>
        <FormSection>
          {data[Fields.leaveStartDate] !== undefined ? (
            <div>
              <FormFieldGroup
                label="Leave Start Date"
                fieldType="inline"
                inputType="overrideWithComponent"
                componentType="singleDatePicker"
                colSpan={labelClass}
                inputName={Fields.leaveStartDate}
                value={data[Fields.leaveStartDate]}
                checkedName={Fields.overrideLeaveStartDate}
                checkedValue={data[Fields.overrideLeaveStartDate]}
                btnStyle={{ height: "44px" }}
                getFromToValue={onChange}
                onChange={onChange}
                onHide={() => {}}
                tooltip={Description.LEAVE_DATE}
              />
              <FormFieldGroup
                label="Leave Type"
                fieldType="inline"
                inputType="Label"
                value="FMLA"
                inputName={Fields.leaveType}
                //value={data[Fields.leaveType]}
                colSpan={labelClass}
                btnStyle={{ height: "44px" }}
                getFromToValue={onChange}
                onHide={() => {}}
              />
              <FormFieldGroup
                label="Leave Reason"
                fieldType="inline"
                inputType="Label"
                value="The birth of the employee's son or daughter"
                inputName={Fields.leaveReason}
                //value={data[Fields.leaveReason]}
                colSpan={labelClass}
                btnStyle={{ height: "44px" }}
                getFromToValue={onChange}
                onHide={() => {}}
              />
              <FormFieldGroup
                label="Expected Leave Return Date"
                fieldType="inline"
                inputType="overrideWithComponent"
                componentType="singleDatePicker"
                inputName={Fields.expectedLeaveReturnDate}
                value={data[Fields.expectedLeaveReturnDate]}
                checkedName={Fields.overrideLeaveReturnDate}
                checkedValue={data[Fields.overrideLeaveReturnDate]}
                colSpan={labelClass}
                btnStyle={{ height: "44px" }}
                getFromToValue={onChange}
                onChange={onChange}
                onHide={() => {}}
                tooltip={Description.OVERRIDE_LEAVE_DATE}
              />
            </div>
          ) : (
            <NoStatusChanges entity="Leave" />
          )}
        </FormSection>
      </div>
    </FormAccordian>
  );
};

export default HireRehire;
