import React from "react";
import * as payrollConstants from "./PayrollDashboardConstants";
import PropTypes from "prop-types";

const PayrollDashboardNoMorePayrolls = ({
  loadingMore,
  payrollDashboardItems,
  selectedTabId
}) => {
  if (loadingMore === false && payrollDashboardItems.DataCount === 0) {
    let message = "";
    if (selectedTabId === payrollConstants.SCHEDULE_PAYROLL) {
      message = "You have no scheduled payrolls open or ready to be started.";
    } else if (selectedTabId === payrollConstants.UNSCHEDULE_PAYROLL) {
      message = "You have no unscheduled payrolls open or ready to be started.";
    }
    return (
      <div className="payroll-dash-summary-container">
        <div className="clearfix no-payroll-container">
          <div className="clearfix text-center no-payroll-container-header-img">
            <img
              src={require("../payroll-dashboard/images/horray_img.png")}
              style={{ height: "150px" }}
              alt="Payroll Completed"
            />
          </div>
          <div className="clearfix no-payroll-container-header">{message}</div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};

export default PayrollDashboardNoMorePayrolls;
PayrollDashboardNoMorePayrolls.propTypes = {
  loadingMore: PropTypes.bool,
  payrollDashboardItems: PropTypes.object,
  selectedTabId: PropTypes.number
};
PayrollDashboardNoMorePayrolls.defaultProps = {
  payrollDashboardItems: {}
};
