import React, { Component } from "react";
import {
  PayrollDashboardHeader,
  PayrollDashboardSummary,
  PayrollDashboardYearEndNoPayroll,
  PayrollDashboardNoMorePayrolls
} from "component-library/payroll-dashboard";
import * as messageGenerator from "component-library/payroll-dashboard/messageGenerator";
import Accordian from "component-library/accordians/index";
import SummaryFooter from "../pages/summary-footer";
import Media from "react-media";
import ChecklistContent from "../pages/payroll-checklist/Content";
import * as payrollConstants from "component-library/payroll-dashboard/PayrollDashboardConstants";
import InfiniteScroll from "react-infinite-scroller";
import ContactApproverModal from "component-library/payroll-dashboard/multi-section-table/ContactApproverModal";
import ContactAllApproverModal from "component-library/payroll-dashboard/multi-section-table/ContactAllApproverModal";

export default class PayrollDashboardDemoPage extends Component {
  state = {
    selectedTabName: "Scheduled Payroll",
    openModal: false,
    openInfoModal: false,
    twoLineModal: false,
    graySectionModal: false,
    contactApproversModal: false,
    contactAllApproversModal: false,
    contactMissingInfoModal: false,
    activeTab: {},
    currentSource: payrollConstants.UNAPPROVED_PTO,
    //
    selectedTabId: payrollConstants.SCHEDULE_PAYROLL,
    loadingMore: false,
    loadingFirstTime: false,
    yearEndRollOver: true
  };

  componentDidMount() {
    this.searchAndSetPost(payrollConstants.SCHEDULE_PAYROLL);
  }

  selectedTab = value => {
    if (value === payrollConstants.SCHEDULE_PAYROLL) {
      this.setState({
        selectedTabId: payrollConstants.SCHEDULE_PAYROLL,
        selectedTabName: "Scheduled Payrolls"
      });
      this.searchAndSetPost(payrollConstants.SCHEDULE_PAYROLL);
    } else if (value === payrollConstants.UNSCHEDULE_PAYROLL) {
      this.setState({
        selectedTabId: payrollConstants.UNSCHEDULE_PAYROLL,
        selectedTabName: "Unscheduled Payrolls"
      });
      this.searchAndSetPost(payrollConstants.UNSCHEDULE_PAYROLL);
    }
  };

  closeModal = () => {
    this.setState({
      openModal: false,
      openInfoModal: false,
      twoLineModal: false,
      graySectionModal: false,
      missingContactInfoModal: false,
      missingAssignmentInfoModal: false,
      contactAllApproversModal: false
    });
  };

  secondaryButtonOnClick = () => {
    alert("Secondary Button OnClick");
  };

  primaryButtonOnClick = () => {
    alert("Primary Button OnClick");
  };

  contactAllApproversModal = () => {
    const empployeeInfo = [
      {
        employeeName: "Summer Fiedler",
        missingItem: "email",
        primaryManager: "Susan Christopher",
        secondaryManager: ""
      },
      {
        employeeName: "Chris Michel",
        missingItem: "cellphone",
        primaryManager: "",
        secondaryManager: "Joe Walker's"
      }
    ];
    const employeeList = [
      { employeeName: "Susan Chiristopher" },
      { employeeName: "Patrik Ballard" }
    ];
    return (
      <div className="modal-body-container">
        <div className="modal-body-header">
          {/* Removed the Icon as per P Dash review by Rebecca */}
          Contact All Approvers.
        </div>
        <div className="modal-body-content">
          <div className="clearfix content-section">
            Primary managers, secondary managers, and Rhonda Jackson will be
            received e-mails and texts.
          </div>
          <div className="clearfix content-section-item alert-infomation-section">
            <div className="clearfix" style={{ paddingBottom: "5px" }}>
              <i className="fa fa-exclamation-circle" />{" "}
              <span className="secondary-header">
                2 approvers will not receive e-mail and/or texts.
              </span>
            </div>
            <Accordian title="Show" hidetitle="Hide" chevronPosition="left">
              <div className="clearfix">
                {empployeeInfo.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="modal-body-gray-section inside-accordian clearfix"
                    >
                      <div className="col-lg-10 col-md-10 col-sm-8 col-xs-7">
                        <div className="secondary-header">
                          {item.missingItem === "email"
                            ? item.employeeName +
                              " does not have a work email address."
                            : item.missingItem === "cellphone"
                              ? item.employeeName +
                                " does not have a cell phone number."
                              : null}
                        </div>
                        <div>
                          {item.primaryManager
                            ? item.primaryManager + " primary manager"
                            : item.secondaryManager + " secondary manager"}
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-4 col-xs-5 text-right">
                        <button className="nc-button outline-button modal-content-button">
                          Add <i className="fa fa-angle-right" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Accordian>
          </div>
          <div className="clearfix content-section-item alert-infomation-section">
            <div className="clearfix" style={{ paddingBottom: "5px" }}>
              <i className="fa fa-exclamation-circle" />{" "}
              <span className="secondary-header">
                2 employees do not have approvers.
              </span>
            </div>
            <Accordian title="Show" hidetitle="Hide" chevronPosition="left">
              <div className="clearfix">
                {employeeList.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="modal-body-gray-section  inside-accordian  clearfix"
                    >
                      <div className="col-lg-9 col-md-9 col-sm-7 col-xs-5">
                        <div className="secondary-header">
                          {item.employeeName +
                            " does not have an assigned manager."}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-5 col-xs-7 text-right">
                        <button className="nc-button outline-button modal-content-button">
                          Assign <i className="fa fa-angle-right" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Accordian>
          </div>
          <div className="clearfix content-section-item alert-infomation-section">
            <Accordian
              title="Review Alerts before sending"
              additionalLink={
                <div style={{ float: "right" }}>
                  <i className="fa fa-pencil" /> Edit Alerts{" "}
                  <i className="fa fa-angle-right" />
                </div>
              }
            >
              <div className="clearfix">
                <div className="modal-body-gray-section clearfix">
                  <div className="content-section clearfix">
                    <div className="col-lg-9 col-md-9 col-sm-8 col-xs-6">
                      <span className="secondary-header">E-mail Alert</span>
                    </div>
                  </div>
                  <div className="content-section clearfix">
                    <span className="secondary-header">Subject: </span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </div>
                  <div className="content-section clearfix">
                    <span className="secondary-header">Body: </span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin vitae ipsum magna. In fringilla rhoncus purus sed
                    semper. Phasellus ultricies hendrerit consequat. Cras
                    varius, massa a suscipit sagittis, neque dolor scelerisque
                    massa, vitae rutrum mi quam nec metus. Sed ornare augue
                    molestie egestas eleifend. Quisque fringilla velit sit amet
                    sapien egestas auctor. Curabitur quis laoreet velit. Fusce
                    condimentum leo diam, ac ornare orci malesuada quis.
                    Praesent at mattis purus.<br />
                    <br /> Vivamus lobortis accumsan odio, sed tristique arcu
                    sodales at. In nec venenatis dui. Vestibulum ante ipsum
                    primis in faucibus orci luctus et ultrices posuere cubilia
                    Curae; Fusce in viverra turpis, id consectetur ligula. Nulla
                    sodales turpis sed lorem ultricies congue. Morbi vestibulum
                    hendrerit massa, ut rhoncus enim tincidunt ut. Cras aliquet
                    malesuada mi. Vestibulum sit amet feugiat mi. Aliquam eros
                    neque, fermentum in sapien vel, volutpat porttitor eros. Ut
                    blandit arcu tellus, non consectetur eros vehicula non. Ut
                    pulvinar sit amet ex efficitur iaculis. Praesent tempus
                    aliquet libero sed euismod. Cras at lectus posuere, varius
                    turpis ac, blandit elit. Aenean ultricies nulla sed lorem
                    lacinia, at finibus sapien consectetur. Quisque vestibulum
                    turpis a condimentum ullamcorper. Pellentesque nec fermentum
                    sapien.
                  </div>
                </div>
                <div className="modal-body-gray-section content-section-item clearfix">
                  <div className="content-section clearfix">
                    <div className="col-lg-9 col-md-9 col-sm-8 col-xs-6">
                      <span className="secondary-header">Text Alert</span>
                    </div>
                  </div>
                  <div className="content-section clearfix">
                    <span className="secondary-header">Body: </span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin vitae ipsum magna. In fringilla rhoncus purus sed
                    semper. Phasellus ultricies hendrerit consequat. Cras
                    varius, massa a suscipit sagittis, neque dolor scelerisque
                    massa, vitae rutrum mi quam nec metus. Sed ornare augue
                    molestie egestas eleifend. Quisque fringilla velit sit amet
                    sapien egestas auctor. Curabitur quis laoreet velit. Fusce
                    condimentum leo diam, ac ornare orci malesuada quis.
                    Praesent at mattis purus.
                  </div>
                </div>
              </div>
            </Accordian>
          </div>
        </div>
      </div>
    );
  };

  setPayrollActiveTab = (tab, payroll, push = false) => {
    const activeTab = this.props.payrollTypeActiveTab[payroll];
    if (activeTab !== tab.id) {
      this.props.setPayrollActiveTab({ [payroll]: tab.id });
    } else {
      this.props.setPayrollActiveTab({ [payroll]: 0 });
    }
    if (push) {
      this.props.history.push(tab.link + payroll);
    }
  };
  clearPayrollActiveTab = () => {
    this.props.clearPayrollActiveTab();
  };

  searchAndSetPost = selectedTabId => {
    const { match, fetchDashboardScheduledPayrolls } = this.props;
    let UserTestId = match.params.UserTestId;
    this.setState({
      loadingMore: true
    });
    fetchDashboardScheduledPayrolls(
      parseInt(UserTestId, 10),
      selectedTabId,
      () => this.setState({ loadingMore: false })
    );
  };

  loadMorePosts = () => {
    const { loadAndSetMorePosts, payrollDashboardItems } = this.props;
    const { NextUri } = payrollDashboardItems;
    if (NextUri !== "") {
      this.setState({
        loadingMore: true
      });
      loadAndSetMorePosts(NextUri, () => this.setState({ loadingMore: false }));
    }
  };

  closeContactAllApproversModal = () => {
    this.setState({
      contactAllApproversModal: false
    });
  };
  closeContactApproversModal = () => {
    this.setState({
      contactApproversModal: false
    });
  };
  onSendMessageClick = () => {};

  onContactClick = e => {
    this.setState({ currentSource: e.source }, () =>
      this.setState({ contactApproversModal: true })
    );
  };

  onContactAllClick = e => {
    this.setState({ currentSource: e.source }, () =>
      this.setState({ contactAllApproversModal: true })
    );
  };

  render() {
    const { payrollDashboardItems, payrollTypeActiveTab } = this.props;
    const payrollTabsData = messageGenerator.generatePayrollHeaderTab(
      payrollDashboardItems
    );
    const missingContactInfoList = [
      {
        employeeName: "Summer Fiedler",
        missingItem: "email",
        primaryManager: "Susan Christopher",
        secondaryManager: ""
      },
      {
        employeeName: "Chris Michel",
        missingItem: "cellphone",
        primaryManager: "",
        secondaryManager: "Joe Walker's"
      }
    ];

    const missingApproverList = [
      { employeeName: "Susan Chiristopher" },
      { employeeName: "Patrik Ballard" }
    ];
    return (
      <div>
        <Media query="(max-width:767px)">
          {matches =>
            matches && Object.keys(payrollTypeActiveTab).length > 0 ? (
              <div className="nc-list-page-container">
                <ChecklistContent
                  {...this.props}
                  sectionTabClickHandler={this.setPayrollActiveTab}
                  clearPayrollActiveTab={this.clearPayrollActiveTab}
                />
              </div>
            ) : (
              <div className="nc-payroll-dashboard-container">
                <PayrollDashboardHeader
                  selectedTab={this.selectedTab}
                  payrollTabsData={payrollTabsData}
                  payrollTitle={this.state.selectedTabName}
                  payrollStageMessage={messageGenerator.generatePayrollStageMessage(
                    payrollDashboardItems
                  )}
                />

                <PayrollDashboardNoMorePayrolls
                  loadingMore={this.state.loadingMore}
                  payrollDashboardItems={payrollDashboardItems}
                  selectedTabId={this.state.selectedTabId}
                />

                <InfiniteScroll
                  pageStart={0}
                  loadMore={
                    !this.state.loadingMore
                      ? this.loadMorePosts
                      : () => console.log("no more items")
                  }
                  hasMore={
                    payrollDashboardItems.NextUri !== null ? true : false
                  } // false , give a single payroll
                  loader={
                    this.state.loadingMore ? <span>Loading...</span> : null
                  }
                >
                  {payrollDashboardItems.Data.map((item, i) => {
                    return (
                      <PayrollDashboardSummary
                        {...this.props}
                        key={i}
                        data={item}
                      >
                        {" "}
                        <SummaryFooter
                          {...this.props}
                          activeTab={
                            this.props.payrollTypeActiveTab[item.PayrollId]
                          }
                          PayrollStageId={item.PayrollStageId}
                          data={item}
                          sectionTabClickHandler={this.setPayrollActiveTab}
                          clearPayrollActiveTab={this.clearPayrollActiveTab}
                          onContactClick={this.onContactClick}
                          onContactAllClick={this.onContactAllClick}
                        />
                      </PayrollDashboardSummary>
                    );
                  })}
                </InfiniteScroll>

                <PayrollDashboardYearEndNoPayroll />
              </div>
            )
          }
        </Media>
        <ContactApproverModal
          source={payrollConstants.UNAPPROVED_PTO}
          contactApproversModal={this.state.contactApproversModal}
          onSendMessageClick={() => this.onSendMessageClick()}
          closeContactApproversModal={this.closeContactApproversModal}
        />

        <ContactAllApproverModal
          source={payrollConstants.UNAPPROVED_PTO}
          contactAllApproversModal={this.state.contactAllApproversModal}
          onSendMessageClick={() => this.onSendMessageClick()}
          closeContactAllApproversModal={this.closeContactAllApproversModal}
          missingContactInfoList={missingContactInfoList}
          missingApproverList={missingApproverList}
        />
      </div>
    );
  }
}
