import React, { Component } from "react";
import holidayIcon from "../payroll-holiday-calendar/holidayIcon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const tooltip_thrash_icon = <Tooltip id="tooltip_thrash_icon">Delete</Tooltip>;
const tooltip_edit_icon = <Tooltip id="tooltip_edit_icon">Edit</Tooltip>;

export default class FieldSectionItems extends Component {
  render() {
    const { data = {} } = this.props;
    return (
      <div className="field-item col-lg-12 col-md-12">
        <div className="col-lg-6 col-md-6 field-name-section">
          <div className="field-image">
            <img
              src={holidayIcon(1)}
              alt={data.FieldName}
              width="45"
              height="45"
            />
          </div>
          <div className="field-name">{data.FieldName}</div>
        </div>
        <div className="col-lg-3 col-md-3">{data.StatusName}</div>

        <div
          id={"list-page-col3-" + data.FieldId}
          className=" col-md-3 col-lg-3 edit-and-delete-icons"
        >
          <span>
            <OverlayTrigger placement="top" overlay={tooltip_edit_icon}>
              <i
                id={"entity-edit-" + data.FieldId}
                className={"list-page-edit-icon fa fa-pencil"}
                style={{ cursor: "pointer" }}
              />
            </OverlayTrigger>
          </span>

          <span className="icon">
            <OverlayTrigger placement="top" overlay={tooltip_thrash_icon}>
              <i
                id={"entity-delete-" + data.FieldId}
                className={"list-page-delete-icon fa fa-trash-o"}
                style={{ cursor: "pointer" }}
              />
            </OverlayTrigger>
          </span>
        </div>
      </div>
    );
  }
}
