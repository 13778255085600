import React from "react";
import { Switch, Route } from "react-router-dom";
import GeneralLedgerPage from "./payrollsetup/generalledger/GeneralLedgerPage";
import ProcessPayroll from "./processpayroll/ProcessPayroll";

import EmployeeLiabilityAccounts from "./payrollsetup/generalledger/EmployeeLiabilityAccounts";
import EmployeeLiabilityAccountsAdvanceFilter from "./payrollsetup/generalledger/EmployeeLiabilityAccountsAdvanceFilter";
import EmployerExpenseLiabilityAccounts from "./payrollsetup/generalledger/EmployerExpenseLiabilityAccounts";
import EmployerExpenseLiabilityAccountsAdvanceFilter from "./payrollsetup/generalledger/EmployerExpenseLiabilityAccountsAdvanceFilter";
import GeneralLedgerForm from "./payrollsetup/generalledger/GeneralLedgerForm";
import GeneralLedgerDescription from "./payrollsetup/generalledger/GeneralLedgerDescription";
import {
  MapGLTaxesSetup,
  MapGLDeductionsSetup
} from "../map-gl-ledger/MapGLEntityRoutes";

//Company-Level
import {
  DeductionListingPage,
  CompanyLevelDeductionDescription,
  CompanyLevelDeductionCalculationMethod,
  CompanyLevelDeductionCycle,
  CompanyLevelDeductionDefaultValue,
  CompanyDeductionOptions,
  CompanyLevelReviewAndSave
} from "../payroll/payrollsetup/companylevel/deductionsetup";

import CustomEarningGroup from "./payrollsetup/companylevel/deductionsetup/CustomEarningGroup";

import SystemThirdPartyPayeeListingPage from "../payroll/payrollsetup/systemlevel/thirdpartypayee/SystemThirdPartyPayeeListingPage";
import SystemThirdPartyPayeeAddPage from "../payroll/payrollsetup/systemlevel/thirdpartypayee/SystemThirdPartyPayeeAddPage";

import {
  CompanyThirdPartyPayeeListingPage,
  CompanyThirdPartyPayeeAddPage
} from "../payroll/payrollsetup/companylevel/thirdpartypayee";
import { DeductionTaxPriority } from "../payroll/payrollsetup/companylevel/deductiontaxpriority";

import { EarningsGroupListPage, EarningsGroupAddPage } from "earnings-group";
import { ActivityHistory } from "../activity-history/pages";
import {
  SystemEarningsListPage,
  SystemEarningsSetupPage,
  CompanyEarningsListPage,
  CompanyEarningsSetupPage
} from "earnings/pages";
import { PayrollSchedule, AddPayrollSchedule } from "./payrollschedule";
import { PayrollChecklist } from "./payrollchecklist";
import PayrollUtilities from "../component-library/payroll-utilities/PayrollUtilities";
import { CopyEntityPage } from "entity-copy/pages";
import JobHistoryPage from "job-history/pages/JobHistoryPage";
import { getMenuIdByUrl } from "app/common/menus/utils";

const PayrollRoutes = () => (
  <Switch>
    <Route
      path="/company-level-deduction-activity-history"
      render={props => (
        <ActivityHistory
          {...props}
          activityEntityId={1}
          title="Deductions"
          filterPlaceholder="Deductions"
          showActionFilter={true}
          showFieldsFilter={true}
          showStatusFilter={true}
          goBack={() =>
            props.history.push("/company-level-deduction-listingPage")
          }
          beforeLink={"Show Deduction Prior to Update"}
          afterLink={"Show Deduction After Update"}
          showValueType="current"
        />
      )}
    />
    <Route
      path="/company-third-party-payee-activity-history"
      render={props => (
        <ActivityHistory
          {...props}
          activityEntityId={2}
          title="Third-Party Payees"
          filterPlaceholder="Third-Party Payees"
          goBack={() =>
            props.history.push("/company-third-party-payee-listing-page")
          }
          beforeLink={"Show Third-Party Payee Prior to Update"}
          afterLink={"Show Third-Party Payee After Update"}
          showValueType="current"
          showActionFilter={true}
          showFieldsFilter={true}
        />
      )}
    />
    <Route
      path="/system-level-deduction-activity-history"
      render={props => (
        <ActivityHistory
          {...props}
          activityEntityId={3}
          title="Deduction Templates"
          filterPlaceholder="Deduction Templates"
          showActionFilter={true}
          showFieldsFilter={true}
          showStatusFilter={true}
          goBack={() =>
            props.history.push("/system-level-deduction-setup-listing-page")
          }
          beforeLink={"Show Deduction Template Prior to Update"}
          afterLink={"Show Deduction Template After Update"}
          showValueType="current"
        />
      )}
    />
    <Route
      path="/system-third-party-payee-activity-history"
      render={props => (
        <ActivityHistory
          {...props}
          activityEntityId={4}
          title="Third-Party Payee Templates"
          filterPlaceholder="Template Third-Party Payees"
          goBack={() =>
            props.history.push("/system-third-party-payee-listing-page")
          }
          beforeLink={"Show Third-Party Payee Template Prior to Update"}
          afterLink={"Show Third-Party Payee Template After Update"}
          showValueType="current"
          showActionFilter={true}
          showFieldsFilter={true}
        />
      )}
    />
    <Route
      path="/earnings-group-activity-history"
      render={props => (
        <ActivityHistory
          {...props}
          activityEntityId={5}
          title="Earnings Group"
          filterPlaceholder="Earnings Group"
          goBack={() => props.history.push("/earning-group-list-page")}
          beforeLink={"Show Earnings Group Prior to Update"}
          afterLink={"Show Earnings Group After Update"}
          showValueType="current"
          showActionFilter={true}
          showFieldsFilter={true}
        />
      )}
    />
    <Route
      path="/system-level-earnings-activity-history"
      render={props => (
        <ActivityHistory
          {...props}
          activityEntityId={6}
          title="Earnings Templates"
          filterPlaceholder="Earnings Template"
          showActionFilter={true}
          showFieldsFilter={true}
          showStatusFilter={true}
          goBack={() => props.history.push("/system-level-earnings")}
          beforeLink={"Show Earnings Template Prior to Update"}
          afterLink={"Show Earnings Template After Update"}
          showValueType="current"
        />
      )}
    />
    <Route
      path="/company-level-earnings-activity-history"
      render={props => (
        <ActivityHistory
          {...props}
          activityEntityId={7}
          title="Earnings"
          filterPlaceholder="Earnings"
          showActionFilter={true}
          showFieldsFilter={true}
          showStatusFilter={true}
          goBack={() => props.history.push("/company-level-earnings")}
          beforeLink={"Show Earnings Prior to Update"}
          afterLink={"Show Earnings After Update"}
          showValueType="current"
        />
      )}
    />
    <Route
      path="/company-level-payroll-activity-history"
      render={props => (
        <ActivityHistory
          {...props}
          activityEntityId={13}
          title="Payroll Schedules"
          filterPlaceholder="Payroll Schedules"
          showActionFilter={true}
          showFieldsFilter={true}
          showStatusFilter={true}
          goBack={() => props.history.push("/PayrollSchedules")}
          beforeLink={"Show Payroll Schedule Prior to Update"}
          afterLink={"Show Payroll Schedule After Update"}
          showValueType="current"
        />
      )}
    />
    <Route
      path="/deduction-and-tax-priority-activity-history"
      render={props => (
        <ActivityHistory
          {...props}
          activityEntityId={8}
          title="Deduction & Tax Priorities"
          filterPlaceholder="Deduction & Tax Priorities"
          goBack={() => props.history.push("/deduction-tax-priority")}
          beforeLink={"Show Priority Order Prior to Update"}
          afterLink={"Show Priority Order After Update"}
          showValueType="current"
        />
      )}
    />
    <Route
      path="/gl-account-deductions-activity-history"
      render={props => (
        <ActivityHistory
          {...props}
          activityEntityId={9}
          title="Map General Ledger Accounts - Deductions"
          filterPlaceholder="General Ledger"
          showFieldsFilter={true}
          goBack={() =>
            props.history.push("/map-general-ledger-accounts-deductions")
          }
          beforeLink={"Show General Ledger Prior to Update"}
          afterLink={"Show General Ledger After Update"}
          showValueType="current"
        />
      )}
    />
    <Route
      path="/gl-account-earnings-activity-history"
      render={props => (
        <ActivityHistory
          {...props}
          activityEntityId={10}
          title="Map General Ledger Accounts - Earnings"
          filterPlaceholder="General Ledger"
          goBack={() =>
            props.history.push("/map-general-ledger-accounts-earnings")
          }
          beforeLink={"Show General Ledger Prior to Update"}
          afterLink={"Show General Ledger After Update"}
          showValueType="current"
        />
      )}
    />
    <Route
      path="/gl-account-Taxes-activity-history"
      render={props => (
        <ActivityHistory
          {...props}
          activityEntityId={14}
          title="Map General Ledger Accounts - Taxes"
          filterPlaceholder="General Ledger"
          showFieldsFilter={true}
          goBack={() =>
            props.history.push("/map-general-ledger-accounts-taxes")
          }
          beforeLink={"Show General Ledger Prior to Update"}
          afterLink={"Show General Ledger After Update"}
          showValueType="current"
        />
      )}
    />
    <Route
      exact
      path="/payroll/utilities/activity-history/holidaycalendar"
      render={props => (
        <ActivityHistory
          {...props}
          activityEntityId={11}
          title="Holiday Calendar"
          filterPlaceholder="Holiday Calendar"
          showActionFilter={true}
          showFieldsFilter={true}
          showStatusFilter={true}
          goBack={() =>
            props.history.push("/payroll/utilities/holidaycalendar")
          }
          beforeLink={"Show Holiday Calendar Prior to Update"}
          afterLink={"Show Holiday Calendar After Update"}
          showValueType="current"
        />
      )}
    />

    <Route
      path="/system-level-earnings"
      exact
      render={props => (
        <SystemEarningsListPage
          {...props}
          onEditClick={id =>
            props.history.push(
              "/system-level-earnings/setup/reviewAndSave/" + id
            )
          }
        />
      )}
    />
    <Route
      path="/email-text-alert-activity-history"
      render={props => (
        <ActivityHistory
          {...props}
          activityEntityId={11}
          title="Email/Text Alerts"
          filterPlaceholder="Email Text alerts"
          goBack={() => props.history.push("/email-text-alerts")}
          showActionFilter={true}
          showFieldsFilter={true}
          beforeLink={"Show Alert Prior to Update"}
          afterLink={"Show Alert After Update"}
          showValueType="current"
        />
      )}
    />
    <Route
      path="/system-level-earnings/setup/"
      component={SystemEarningsSetupPage}
    />
    <Route
      path="/company-level-earnings"
      exact
      component={CompanyEarningsListPage}
    />
    <Route
      path="/company-level-earnings/setup/"
      component={CompanyEarningsSetupPage}
    />
    <Route path="/process-payroll" component={ProcessPayroll} />
    <Route path="/general-ledger-accounts" component={GeneralLedgerPage} />
    {/*Company Level*/}
    <Route
      path="/company-level-deduction-listingPage"
      component={DeductionListingPage}
    />
    <Route
      path="/company-level-deduction-description/:DeductionId"
      component={CompanyLevelDeductionDescription}
    />
    <Route
      path="/company-level-deduction-calculation-method/:DeductionId"
      component={CompanyLevelDeductionCalculationMethod}
    />
    <Route
      path="/company-level-deduction-deduction-cycle/:DeductionId"
      component={CompanyLevelDeductionCycle}
    />
    <Route
      path="/company-level-deduction-default-value/:DeductionId"
      component={CompanyLevelDeductionDefaultValue}
    />
    <Route
      path="/company-level-deduction-option/:DeductionId"
      component={CompanyDeductionOptions}
    />
    <Route
      path="/company-level-review-and-save/:DeductionId"
      component={CompanyLevelReviewAndSave}
    />
    <Route
      path="/company-level-deduction-description"
      component={CompanyLevelDeductionDescription}
    />
    <Route
      path="/company-level-deduction-calculation-method"
      component={CompanyLevelDeductionCalculationMethod}
    />
    <Route
      path="/company-level-deduction-deduction-cycle"
      component={CompanyLevelDeductionCycle}
    />
    <Route
      path="/company-level-deduction-default-value"
      component={CompanyLevelDeductionDefaultValue}
    />
    <Route
      path="/company-level-deduction-option"
      component={CompanyDeductionOptions}
    />
    <Route
      path="/company-level-review-and-save"
      component={CompanyLevelReviewAndSave}
    />
    <Route
      path="/company-level-custom-earning-group"
      component={CustomEarningGroup}
    />
    {/*System Level*/}
    <Route
      path="/system-third-party-payee-listing-page"
      component={SystemThirdPartyPayeeListingPage}
    />
    <Route
      path="/system-third-party-payee-add-page/:SystemThirdPartyPayeeId"
      component={SystemThirdPartyPayeeAddPage}
    />
    <Route
      path="/system-third-party-payee-add-page"
      component={SystemThirdPartyPayeeAddPage}
    />
    <Route
      path="/company-third-party-payee-listing-page"
      component={CompanyThirdPartyPayeeListingPage}
    />
    <Route
      path="/company-third-party-payee-add-page/:ThirdPartyPayeeId"
      component={CompanyThirdPartyPayeeAddPage}
    />
    <Route
      path="/company-third-party-payee-add-page"
      component={CompanyThirdPartyPayeeAddPage}
    />
    <Route path="/general-ledger-page" component={GeneralLedgerPage} />
    <Route
      path="/employee-liability-accounts"
      component={EmployeeLiabilityAccounts}
    />
    <Route
      path="/employee-liability-accounts-advance-filter"
      component={EmployeeLiabilityAccountsAdvanceFilter}
    />
    <Route
      path="/employer-expenses-liability-accounts"
      component={EmployerExpenseLiabilityAccounts}
    />
    <Route
      path="/employer-expense-liability-accounts-advance-filter"
      component={EmployerExpenseLiabilityAccountsAdvanceFilter}
    />
    <Route path="/general-ledger-form" component={GeneralLedgerForm} />
    <Route
      path="/map-general-ledger-accounts-taxes"
      component={MapGLTaxesSetup}
    />
    <Route
      path="/map-general-ledger-accounts-deductions"
      component={MapGLDeductionsSetup}
    />
    <Route
      path="/general-ledger-description"
      component={GeneralLedgerDescription}
    />
    <Route path="/deduction-tax-priority" component={DeductionTaxPriority} />
    <Route
      path="/earning-group-list-page"
      render={props => <EarningsGroupListPage {...props} />}
    />
    <Route
      path="/edit-earnings-group-page/:id/:systemId"
      render={props => (
        <EarningsGroupAddPage
          {...props}
          onHistoryButtonClick={() =>
            props.history.push("/earnings-group-activity-history")
          }
        />
      )}
    />
    <Route path="/add-payroll-schedule" component={AddPayrollSchedule} />
    <Route path="/edit-payroll-schedule/:id" component={AddPayrollSchedule} />
    <Route
      path="/PayrollSchedules"
      render={props => (
        <PayrollSchedule
          {...props}
          title="Payroll Schedules"
          page="PayrollSchedule"
          addButtonLabel="ADD"
          add={() => props.history.push("/add-payroll-schedule")}
        />
      )}
    />
    <Route
      exact
      path="/payroll/utilities"
      render={props => <PayrollUtilities {...props} />}
    />
    <Route
      path="/PayrollChecklistConfig"
      render={props => <PayrollChecklist {...props} />}
    />
    <Route
      path="/company-level-copy-deduction"
      render={props => (
        <CopyEntityPage
          entityName="Deductions"
          entityPath="deduction"
          showFilters
          selectEntityLabel="deduction(s)"
          filterPageLabel="CopyDeductions"
          appUIMenuItemId={getMenuIdByUrl(
            "company-level-deduction-listingPage"
          )}
          appUIProductId="1"
          onCancelClick={() =>
            props.history.push("/company-level-deduction-listingPage")
          }
        />
      )}
    />
    <Route
      path="/copy-payroll-schedule"
      render={props => (
        <CopyEntityPage
          entityName="Payroll Schedule"
          entityPath="payrollschedule"
          selectEntityLabel="Schedule(s)"
          filterPageLabel="PayrollSchedule"
          appUIMenuItemId="57"
          appUIProductId="1"
          onCancelClick={() => props.history.replace("/PayrollSchedules")}
        />
      )}
    />
    <Route
      path="/company-level-copy-earnings"
      render={props => (
        <CopyEntityPage
          entityName="Earnings"
          entityPath="earning"
          showFilters
          selectEntityLabel="Earnings"
          filterPageLabel="CopyEarnings"
          appUIMenuItemId={getMenuIdByUrl("company-level-earnings")}
          appUIProductId="1"
          onCancelClick={() => props.history.push("/company-level-earnings")}
        />
      )}
    />
    <Route
      path="/workplace-info-job-history"
      render={props => (
        <JobHistoryPage
          {...props}
          activityEntityId={18}
          entityNamePlural="Deductions"
        />
      )}
    />
  </Switch>
);

export default PayrollRoutes;
