import React, { Component } from "react";
import { connect } from "react-redux";
import Random from "random-js";
import * as common from "../common/index";
import axios from "axios";
import { setUserSession, setSessionContext } from "app/actions/AppActions";
class DevLogin extends Component {
  state = {};

  componentDidMount() {
    axios.defaults.headers.common["Authorization"] = null;
    axios.defaults.headers.common["SessionContext"] = null;
    sessionStorage.removeItem("Authorization");
    sessionStorage.removeItem("SessionContext");
    this.props.setSessionContext();
    this.props.setUserSession(false);
  }

  redirectToBC = () => {
    let random = new Random(Random.engines.mt19937().autoSeed());
    let defaultNonce = random.integer(1, 100);

    let link = `https://${
      common.TENANT
      }.b2clogin.com/${
      common.TENANT
    }.onmicrosoft.com/oauth2/v2.0/authorize?p=${common.SignUpSignInPolicy}&client_id=${
      common.CLIENT_ID
    }&nonce=${defaultNonce}&redirect_uri=${
      common.UI_APP_URL
    }authenticate&scope=openid&response_type=id_token`;
    window.location.href = link;
  };
  render() {
    return (
      <button
        onClick={this.redirectToBC}
        id="btn-cstm-textupper"
        className="btn btn-success btn-lg"
      >
        Login to Netchex
      </button>
    );
  }
}

const stateToProps = state => ({});
const actionCreators = {
  setUserSession,
  setSessionContext
};
export default connect(stateToProps, actionCreators)(DevLogin);
