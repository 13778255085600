import React from "react";
import "./svg.css";
const UnapprovedPto = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 40 52"
    style={{ enableBackground: "new 0 0 40 52" }}
    width={width}
    height={height}
  >
    <g>
      <path
        className="st5"
        d="M25.3,18.1H12.2c-0.6,0-1-0.4-1-1s0.4-1,1-1h13.1c0.6,0,1,0.4,1,1S25.8,18.1,25.3,18.1z"
      />
    </g>
    <g>
      <path
        className="st5"
        d="M25.3,24.2H12.2c-0.6,0-1-0.4-1-1s0.4-1,1-1h13.1c0.6,0,1,0.4,1,1S25.8,24.2,25.3,24.2z"
      />
    </g>
    <g>
      <path
        className="st5"
        d="M25.3,30.2H12.2c-0.6,0-1-0.4-1-1s0.4-1,1-1h13.1c0.6,0,1,0.4,1,1S25.8,30.2,25.3,30.2z"
      />
    </g>
    <g>
      <path
        className="st5"
        d="M8.9,18.1H6.7c-0.6,0-1-0.4-1-1s0.4-1,1-1h2.2c0.6,0,1,0.4,1,1S9.4,18.1,8.9,18.1z"
      />
    </g>
    <g>
      <path
        className="st5"
        d="M8.9,24.2H6.7c-0.6,0-1-0.4-1-1s0.4-1,1-1h2.2c0.6,0,1,0.4,1,1S9.4,24.2,8.9,24.2z"
      />
    </g>
    <g>
      <path
        className="st5"
        d="M8.9,30.2H6.7c-0.6,0-1-0.4-1-1s0.4-1,1-1h2.2c0.6,0,1,0.4,1,1S9.4,30.2,8.9,30.2z"
      />
    </g>
    <g>
      <path
        className="st5"
        d="M8.9,36.2H6.7c-0.6,0-1-0.4-1-1s0.4-1,1-1h2.2c0.6,0,1,0.4,1,1S9.4,36.2,8.9,36.2z"
      />
    </g>
    <g>
      <path
        className="st5"
        d="M8.9,42.2H6.7c-0.6,0-1-0.4-1-1s0.4-1,1-1h2.2c0.6,0,1,0.4,1,1S9.4,42.2,8.9,42.2z"
      />
    </g>
    <path
      className="st5"
      d="M32.1,41.9l3-3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-3,3l-3-3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l3,3l-3,3c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3l3,3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L32.1,41.9z"
    />
    <path
      className="st5"
      d="M19.8,41.9c0-0.6,0.1-1.1,0.1-1.7h-7.7c-0.6,0-1,0.4-1,1s0.4,1,1,1h7.6C19.8,42.1,19.8,42,19.8,41.9z"
    />
    <path
      className="st5"
      d="M23,34.2H12.2c-0.6,0-1,0.4-1,1s0.4,1,1,1h9.2C21.9,35.5,22.4,34.8,23,34.2z"
    />
    <path
      className="st5"
      d="M27.5,7.5c0-1.4-1.2-2.6-2.6-2.6H21c-0.2-2.3-2.2-4.2-4.6-4.2c-2.4,0-4.4,1.8-4.6,4.2h-4C6.3,4.9,5.2,6,5.1,7.5H5c-2.5,0-4.6,2.1-4.6,4.6v30.5c0,2.5,2.1,4.6,4.6,4.6h16.1c-0.3-0.6-0.6-1.3-0.8-2H5c-1.4,0-2.6-1.2-2.6-2.6V12.1c0-1.4,1.2-2.6,2.6-2.6h0.1v1.1c0,1.5,1.2,2.6,2.6,2.6h17.1c1.5,0,2.6-1.2,2.6-2.6V9.5c1.2,0.2,2.2,1.3,2.2,2.6v19c0.3,0,0.7-0.1,1-0.1s0.7,0,1,0.1v-19C31.7,9.7,29.8,7.7,27.5,7.5z M25.5,10.6c0,0.3-0.3,0.6-0.6,0.6H7.7c-0.3,0-0.6-0.3-0.6-0.6v-3c0-0.4,0.3-0.6,0.6-0.6h5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.2-0.8c0-0.1,0-0.2,0-0.4c0-1.5,1.2-2.7,2.7-2.7c1.5,0,2.6,1.2,2.6,2.7c0,0.1,0,0.2,0,0.4c0,0.3,0,0.6,0.2,0.8s0.5,0.3,0.8,0.3h4.9c0.4,0,0.6,0.3,0.6,0.6V10.6z"
    />
    <path
      className="st5"
      d="M30.7,50.9c-4.9,0-8.9-4-8.9-8.9s4-8.9,8.9-8.9s8.9,4,8.9,8.9S35.6,50.9,30.7,50.9z M30.7,35c-3.8,0-6.9,3.1-6.9,6.9s3.1,6.9,6.9,6.9s6.9-3.1,6.9-6.9S34.5,35,30.7,35z"
    />
  </svg>
);
export default UnapprovedPto;
