import React from "react";
import { Overlay } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import OptionsSearch from "./OptionsSearch";
import PopoverOption from "./PopoverOption";
import renderOption from "./renderOption";
import CustomOverlay from "./CustomOverlay";
import PropTypes from "prop-types";
const OptionsPopover = props => {
  const allSelected = props.multiSelect && props.areAllSelected();
  return (
    <Overlay
      show={props.show}
      placement={props.position}
      container={props.container}
      onHide={props.onHide}
      rootClose={true}
      target={props.target}
      shouldUpdatePosition={props.shouldUpdatePosition}
    >
      <CustomOverlay
        className={props.className}
        width={props.overlayWidth}
        style={props.style}
      >
        <OptionsSearch
          searchText={props.searchText}
          editSearchText={props.editSearchText}
          show={props.showSearch}
          searchBoxId={props.searchBoxId}
          onSearchClicked={props.onSearchClicked && props.onSearchClicked}
          showSearchButton={props.showSearchButton && props.showSearchButton}
        />
        <Scrollbars
          autoHeight={true}
          autoHeightMax={
            props.template === "companySelection" ? 400 : undefined
          }
        >
          <ul className="nc-dropdown-navigation">
            {props.multiSelect &&
              props.showSelectAll && !props.searchText && !props.removeSelectAllOption && (
                <PopoverOption
                  label={
                    props.selectAllLabel ? props.selectAllLabel : "Select All"
                  }
                  showCheckbox={true}
                  selected={allSelected}
                  onSelect={() => {
                    if (props.hasForm) {
                      props.selectAll(!allSelected);
                    } else {
                      props.selectAll(!allSelected);
                    }
                  }}
                />
              )}
            {props.options.map(renderOption(props, PopoverOption))}
          </ul>
        </Scrollbars>
      </CustomOverlay>
    </Overlay>
  );
};

export default OptionsPopover;
OptionsPopover.propTypes = {
  position: PropTypes.string,
  shouldUpdatePosition: PropTypes.bool,
  onHide: PropTypes.func
};

OptionsPopover.defaultProps = {
  position: "bottom",
  shouldUpdatePosition: false,
  target: undefined
};
