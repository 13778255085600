import axios from "axios";
import * as types from "./index";
import * as common from "../../../../app/common/index";

export function getEmployeeAmountValueList() {
  const url = `${common.WEB_API_URL}/v1/app/ui/appview`;
  const request = axios.get(url);
  return {
    type: types.FETCH_EMPLOYEE_AMOUNT_VALUE_LIST,
    payload: request
  };
}
