import React from "react";
import FormAccordian from "component-library/accordians/FormAccordian";
import * as Fields from "./FieldNames";

import { FormFieldGroup, FormSection } from "component-library/formSections";
import { NoStatusChanges } from "./common";
import { Element } from "react-scroll";
import * as Description from "../FieldDescriptions";

const labelClass = "col-lg-3 col-md-3";

const Deceased = ({ onChange, data = {} }) => {
  return (
    <Element title="Deceased" className="element" id="8">
      <FormAccordian title="Deceased">
        <div>
          <FormSection>
            {data[Fields.overrideDeceasedDate] !== undefined ? (
              <FormFieldGroup
                label="Death Date"
                fieldType="inline"
                inputType="overrideWithComponent"
                componentType="singleDatePicker"
                colSpan={labelClass}
                btnStyle={{ height: "44px" }}
                getFromToValue={onChange}
                onChange={onChange}
                inputName={Fields.deceasedDate}
                //value={data[Fields.deceasedDate]}
                checkedName={Fields.overrideDeceasedDate}
                checkedValue={data[Fields.overrideDeceasedDate]}
                tooltip={Description.DECEASED_DATE}
                onHide={() => {}}
              />
            ) : (
              <NoStatusChanges entity="Deceased" />
            )}
          </FormSection>
        </div>
      </FormAccordian>
    </Element>
  );
};

export default Deceased;
