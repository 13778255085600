import React from "react";
import QuarterWiseUpcomingPayrollsItems from "./QuarterWiseUpcomingPayrollsItems";
import moment from "moment";

const QuarterWiseUpcomingPayrolls = ({
  data,
  index,
  calendarPreviewData,
  badgeColor,
  isPrintPreview = false
}) => {
  let arr = [0, 1, 2, 3, 4];
  let scheduleCalendarList = data.ScheduledPayrollCalenderList;
  return (
    <div>
      <span
        className="badge nc-payroll-badge"
        style={{ backgroundColor: badgeColor }}
      >
        <span className="payroll-badge-title">
          {data.PayrollCalenderQuarter}
        </span>{" "}
        &nbsp;|&nbsp; <span>{data.PayrollCalenderYear}</span>
      </span>
      <div className="payroll-calendar-summary-container">
        <div className="payroll-calendar-summary-content-section">
          {data.ScheduledPayrollCalenderList.map((item, i) => {
            if (item.MonthlyPayrollRunNumber) {
              let startDate = new Date(item.PaycheckDate);
              startDate = moment(
                new Date(startDate.getFullYear(), startDate.getMonth(), 1)
              ).format("YYYY-MM-DD");

              let endDate = new Date(item.PaycheckDate);
              endDate = moment(
                new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0)
              ).format("YYYY-MM-DD");

              var result = scheduleCalendarList.filter(function(obj) {
                return (
                  moment(obj.PaycheckDate).format("YYYY-MM-DD") >= startDate &&
                  moment(obj.PaycheckDate).format("YYYY-MM-DD") <= endDate
                );
              });

              item.TotalMonthlyRun = result.length;
            } else {
              item.TotalMonthlyRun = "--";
            }

            let infoItemArr = [];
            let isInfoPrint = false;
            if (item.HolidayDates !== null && item.HolidayDates.length > 0) {
              isInfoPrint = true;
              infoItemArr.push(4);
            }
            if (
              item.CalculatedPaycheckDate !== item.AdjustedPaycheckDate ||
              item.CalculatedPaycheckDate !== item.PaycheckDate
            ) {
              isInfoPrint = true;
              infoItemArr.push(1);
            }

            let holidayInfo =
              item.HolidayDates != null &&
              item.HolidayDates.length > 0 &&
              item.HolidayDates[0].HolidayName;

            let paycheckInfo = "";
            if (
              item.CalculatedPaycheckDate === item.AdjustedPaycheckDate &&
              item.CalculatedPaycheckDate !== item.PaycheckDate
            )
              paycheckInfo =
                "PayCheck date has been changed from the payroll dashboard";
            else if (
              item.CalculatedPaycheckDate !== item.AdjustedPaycheckDate &&
              item.AdjustedPaycheckDate === item.PaycheckDate
            )
              paycheckInfo =
                "PayCheck date has been changed from the payroll schedule settings";
            else if (
              item.CalculatedPaycheckDate !== item.AdjustedPaycheckDate &&
              item.AdjustedPaycheckDate !== item.PaycheckDate
            )
              paycheckInfo =
                "PayCheck date has been changed from the payroll dashboard";
            return (
              <div key={i}>
                <QuarterWiseUpcomingPayrollsItems data={item} />
                {isInfoPrint &&
                  isPrintPreview && (
                    <div
                      className="payroll-calendar-summary-item-container print-infobox"
                      style={{ marginBottom: "50px" }}
                    >
                      {arr.map(a => {
                        let info = " ";
                        if (a === 1) {
                          info = paycheckInfo;
                        } else if (a === 4) {
                          info = holidayInfo;
                        }
                        return (
                          <div
                            key={a}
                            style={{
                              marginBottom: "0px",
                              border: "none",
                              wordBreak: "break-word"
                            }}
                            className="payroll-dash-summary-item payroll-calendar-summary-item clearfix"
                          >
                            {infoItemArr &&
                              infoItemArr.indexOf(a) !== -1 && (
                                <i className="fa-information-blue fa fa-info-circle" />
                              )}
                            {info}&nbsp;
                          </div>
                        );
                      })}
                    </div>
                  )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default QuarterWiseUpcomingPayrolls;
