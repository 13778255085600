import React from "react";
import FormAccordian from "component-library/accordians/FormAccordian";
import * as Fields from "./FieldNames";
import * as Description from "../FieldDescriptions";
import { NoStatusChanges } from "./common";

import { FormFieldGroup, FormSection } from "component-library/formSections";
const labelClass = "col-lg-3 col-md-3";
const Transferred = ({ onChange, data = {} }) => {
  return (
    <FormAccordian title="Transferred" id="6">
      <div>
        <FormSection>
          {data[Fields.transferredDate] !== undefined ? (
            <FormFieldGroup
              label="Transferred Date"
              fieldType="inline"
              inputType="overrideWithComponent"
              componentType="singleDatePicker"
              inputName={Fields.transferredDate}
              value={data[Fields.transferredDate]}
              checkedName={Fields.overrideTransferredDate}
              checkedValue={data[Fields.overrideTransferredDate]}
              getFromToValue={onChange}
              onChange={onChange}
              colSpan={labelClass}
              tooltip={Description.TRANSFERRED_DATE}
            />
          ) : (
            <NoStatusChanges entity="Transferred" />
          )}
        </FormSection>
      </div>
    </FormAccordian>
  );
};

export default Transferred;
