import React from "react";

export const ORDER_ASC = "ASC";
export const ORDER_DESC = "DESC";

const iconForOrder = order => {
  switch (order) {
    case ORDER_ASC:
      return "fa-sort-asc";
    case ORDER_DESC:
      return "fa-sort-desc";
    default:
      return "fa-sort";
  }
};

const ColumnTitle = ({
  onClick,
  label = "",
  order,
  col = 5,
  colMd,
  style,
  id,
  showSort = true
}) => (
  <div
    id={id}
    className={`col-lg-${col} col-md-${colMd || col} user-column-header`}
    style={{ cursor: showSort ? "pointer" : "default", ...style }}
    onClick={onClick}
  >
    {label.toUpperCase() + " "}
    {showSort && (
      <i className={`fa ${iconForOrder(order)}`} aria-hidden="true" />
    )}
  </div>
);

export default ColumnTitle;
