import axios from "axios";
import * as types from "./index";
import * as common from "../../../../app/common/index";

export function getCalculationMethodRadioButtonsList() {
  const url = `${common.WEB_API_URL}/v1/system/deductioncalculationbasis`;
  const request = axios.get(url);
  return {
    type: types.FETCH_CALCULATION_METHOD_OPTIONS_LIST,
    payload: request
  };
}

export function getGarnishmentPercentRadioButtonsList() {
  const url = `${common.WEB_API_URL}/v1/system/earninggroup`;
  const request = axios.get(url);
  return {
    type: types.FETCH_EARNING_PERCENT_OPTIONS_LIST,
    payload: request
  };
}

export function getAdvancedPretaxValuesList(selectedeDeductionValue) {
  const url = `${common.WEB_API_URL}/v1/app/ui/appview`;
  const request = axios.get(url);
  return {
    type: types.FETCH_ADVANCED_PRETAX_OPTIONS_LIST,
    payload: request
  };
}

export function getAdvancedRetirementValuesList(selectedeDeductionValue) {
  const url = `${common.WEB_API_URL}/v1/app/ui/appview`;
  const request = axios.get(url);
  return {
    type: types.FETCH_ADVANCED_RETIREMENT_OPTIONS_LIST,
    payload: request
  };
}

// export function getGarnishmentValuesList(selectedeDeductionValue){
//     const url=`${common.WEB_API_URL}/v1/app/ui/appview`;
//     const request=axios.get(url);
//     return {
//         type : types.FETCH_GARNISHMENT_EARNINGS_OPTIONS_LIST,
//         payload : request
//     };
// }

export function getAcaDeductionValuesList() {
  const url = `${common.WEB_API_URL}/v1/system/deductioncalculationbasisoption`;
  const request = axios.get(url);
  return {
    type: types.FETCH_ACA_DEDUCTION_OPTIONS_LIST,
    payload: request
  };
}
