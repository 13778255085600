import { getEmployeeList } from "../../../base/network/index";
import {
  setFilterOptions,
  selectAllFilterOptions
} from "app/common/reducers/filters";
import { editEntityErrors } from "app/common/reducers/entityErrors";
import { showToast } from "app/common/reducers/toast";
import { setLoader } from "app/actions/LoaderActions";
import * as Fields from "component-library/employee-profile/employment-info/workplace-info/FieldNames";
import * as IDFields from "component-library/employee-profile/employment-info/alternate-emp-identification/FieldNames";
import { setFormUpdate } from "app/actions/FormUpdateAction";
import {
  updateWorkplaceInfo,
  checkWorkplaceInfoExists,
  checkAltEmpIdentificationExists,
  updateAltEmpIdentification
} from ".";

export const ERROR_TOAST = "Please review errors below";

export const updateEmployementInformation = data => dispatch => {
  //call the endpoint here
};

export const fetchAndSetDropdownList = (
  filters = {},
  name,
  isSelectAll
) => dispatch => {
  return getEmployeeList().then(res => {
    const { Data } = res.data;
    let dispatchData = [];
    Data.forEach(item =>
      dispatchData.push({
        id: item.EmployeeId,
        value: item.EmployeeId,
        label: item.LastName + ", " + item.FirstName
      })
    );
    dispatch(setFilterOptions(name, dispatchData));
    if (isSelectAll) {
      dispatch(selectAllFilterOptions(name, true));
    }
  });
};

export const validateData = data => {
  let errors = {};
  if (
    data[Fields.workEmailAddress] !== null ||
    data[Fields.workEmailAddress] !== undefined
  ) {
    let emailValid = data[Fields.workEmailAddress].match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );
    if (emailValid === null) {
      errors[Fields.workEmailAddress] = "Email is Invalid.";
    }
  }
  console.log("errors", errors);
  return errors;
};

export const validateAndSaveWorkplaceInfo = (
  data,
  name = "",
  successCallback
) => dispatch => {
  const errors = validateData(data);
  if (Object.keys(errors).length > 0) {
    dispatch(editEntityErrors(errors));
    dispatch(showToast(ERROR_TOAST, "error"));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    dispatch(setLoader(false));
    return;
  }
  let Toast = name + "'s Workplace Contact Info has been successfully saved.";
  dispatch(showToast(Toast));
};

export const validateAndUpdateWorkplaceInfo = (
  data,
  name = "",
  Id,
  successCallback
) => dispatch => {
  const errors = validateData(data);
  if (Object.keys(errors).length > 0) {
    dispatch(editEntityErrors(errors));
    dispatch(showToast(ERROR_TOAST, "error"));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    dispatch(setLoader(false));
    return;
  }
  checkWorkplaceInfoExists(data.name, Id).then(res => {
    const { DataCount, Data, Errors, ErrorCount } = res.data;
    if (DataCount === 1 && Data[0] === false) {
      let request = [
        {
          Operation: "update",
          PathOrKey: Fields.changeDate,
          Value: data[Fields.changeDate]
        },
        {
          Operation: "update",
          PathOrKey: Fields.changeReason,
          Value: data[Fields.changeReason]
        },
        {
          Operation: "update",
          PathOrKey: Fields.changeReasonName,
          Value: data[Fields.changeReasonName]
        },
        {
          Operation: "update",
          PathOrKey: Fields.division,
          Value: data[Fields.division]
        },
        {
          Operation: "update",
          PathOrKey: Fields.businessUnit,
          Value: data[Fields.businessUnit]
        },
        {
          Operation: "update",
          PathOrKey: Fields.department,
          Value: data[Fields.department]
        },
        {
          Operation: "update",
          PathOrKey: Fields.client,
          Value: data[Fields.client]
        },
        {
          Operation: "update",
          PathOrKey: Fields.project,
          Value: data[Fields.project]
        },
        {
          Operation: "update",
          PathOrKey: Fields.location,
          Value: data[Fields.location]
        },
        {
          Operation: "update",
          PathOrKey: Fields.newLocation,
          Value: data[Fields.newLocation]
        },
        {
          Operation: "update",
          PathOrKey: Fields.addressLine1,
          Value: data[Fields.addressLine1]
        },
        {
          Operation: "update",
          PathOrKey: Fields.addressLine2,
          Value: data[Fields.addressLine2]
        },
        {
          Operation: "update",
          PathOrKey: Fields.primaryManager,
          Value: data[Fields.primaryManager]
        },
        {
          Operation: "update",
          PathOrKey: Fields.secondaryManager,
          Value: data[Fields.secondaryManager]
        },
        {
          Operation: "update",
          PathOrKey: Fields.notes,
          Value: data[Fields.notes]
        }
      ];

      updateWorkplaceInfo(Id, request).then(res => {
        const { DataCount, Data, Errors, Id } = res.data;
        if (DataCount === 1 && Data[0] != null) {
          dispatch(setFormUpdate(false));
          successCallback(Id);

          let Toast = name + "'s Workplace change has been successfully saved.";
          dispatch(showToast(Toast));
        } else {
          console.log("Errors", Errors);
        }
      });
    } else if (ErrorCount === 1 && Errors[0].Tag === "ALREADY_EXISTS") {
      let message = "A workplace already exists with this name";
      if (Errors[0].MessageList.length > 0) {
        message = Errors[0].MessageList[0];
      }

      dispatch(
        editEntityErrors({
          name: message
        })
      );
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      // dispatch(showToast(ERROR_TOAST, "error"));
    }
    let Toast = name + "'s Workplace Contact Info has been successfully saved.";
    dispatch(showToast(Toast));
  });
};

export const validateAndSaveAltEmpIdentificationInfo = (
  data,
  errorCallback,
  Id = null,
  name = "",
  successCallback
) => dispatch => {
  checkAltEmpIdentificationExists(data.BadgeNo, Id).then(res => {
    const { DataCount, Data, Errors, ErrorCount } = res.data;
    if (DataCount === 1 && Data[0] === false) {
      let request = [
        {
          Operation: "update",
          PathOrKey: IDFields.primaryEmployeeID,
          Value: data[IDFields.primaryEmployeeID]
        },
        {
          Operation: "update",
          PathOrKey: IDFields.secondaryEmployeeID,
          Value: data[IDFields.secondaryEmployeeID]
        },
        {
          Operation: "update",
          PathOrKey: IDFields.formerEmployeeID,
          Value: data[IDFields.formerEmployeeID]
        },
        {
          Operation: "update",
          PathOrKey: IDFields.badgeNo,
          Value: data[IDFields.badgeNo]
        },
        {
          Operation: "update",
          PathOrKey: IDFields.clockNo,
          Value: data[IDFields.clockNo]
        }
      ];

      updateAltEmpIdentification(Id, request).then(res => {
        const { DataCount, Data, Errors, Id } = res.data;
        if (DataCount === 1 && Data[0] != null) {
          dispatch(setFormUpdate(false));
          successCallback(Id);
          let Toast =
            name + "'s Alternate Employee Identification has been saved.";
          dispatch(showToast(Toast));
        } else {
          console.log("Errors", Errors);
        }
      });
    } else if (ErrorCount === 1 && Errors[0].Tag === "ALREADY_EXISTS") {
      let message = "An employee already exists with this name";
      if (Errors[0].MessageList.length > 0) {
        message = Errors[0].MessageList[0];
      }
      if (message === "A former Employee Id already exists in company") {
        dispatch(
          editEntityErrors({
            [IDFields.formerEmployeeID]: message
          })
        );
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        errorCallback();
      }
      if (message === "Clock no is currently being used") {
        dispatch(
          editEntityErrors({
            [IDFields.clockNo]: message
          })
        );
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        errorCallback();
      }
      dispatch(
        editEntityErrors({
          [IDFields.badgeNo]: message
        })
      );
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      // dispatch(showToast(ERROR_TOAST, "error"));
    }
  });
};
