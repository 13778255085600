import React from "react";
import { Modal } from "react-bootstrap";

import "./EarningsDeleteModal.css";

const EarningsInactivateModal = ({
  show,
  closeModal,
  entity,
  name,
  entityType,
  submit,
  message = "",
  Id
}) => (
  <Modal
    bsClass="list-page-cancel modal"
    animation={false}
    backdrop={"static"}
    keyboard={false}
    show={show}
  >
    <Modal.Header>
      <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <i
          id="system-level-cancel-button-close-icon"
          onClick={closeModal}
          className="fa fa-times-circle-o fa-lg"
        />
      </div>
    </Modal.Header>
    <Modal.Body>
      <div>
        {entityType === "Template" ? (
          <div
            id={Id.modalMessage}
            className="modal-row row col-xs-12 col-sm-12 col-md-12 col-lg-12"
          >
            It looks like this earnings template has been used by a company! We
            will mark it as inactive to preserve history.
          </div>
        ) : (
          <div
            id={Id.modalMessage}
            className="modal-row row col-xs-12 col-sm-12 col-md-12 col-lg-12"
          >
            It looks like this earnings has been used before! We will mark it as
            inactive to preserve payment history. You can reactivate this
            earnings if you change your mind. <br /> <br />
            WARNING: Inactivating this earnings will automatically inactivate it
            for all currently-assigned employees. You can reactivate this
            earnings later, but you will need to manually reactivate it for each
            employee.
          </div>
        )}

        <div className="message-footer col-xs-12 col-sm-12 col-md-12 col-lg-12">
          {message}
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <div className="button-group">
        <button
          type="button"
          id={Id.modalSuccessButton}
          className="nc-button primary-button"
          onClick={submit}
        >
          {"INACTIVATE " + entityType}
        </button>
        <button
          type="button"
          id={Id.modalCancelButton}
          className="secondary-link-button"
          onClick={closeModal}
        >
          Cancel
        </button>
      </div>
    </Modal.Footer>
  </Modal>
);

export default EarningsInactivateModal;
