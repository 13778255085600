import React from "react";
import FormAccordian from "component-library/accordians/FormAccordian";
import * as Fields from "./FieldNames";
import * as Description from "../FieldDescriptions";
import { FormFieldGroup, FormSection } from "component-library/formSections";
import { NoStatusChanges } from "./common";
const labelClass = "col-lg-3 col-md-3";

const Retired = ({ onChange, data = {} }) => {
  return (
    <FormAccordian title="Retired" id="7">
      <div>
        <FormSection>
          {data[Fields.hireDate] !== undefined ? (
            <FormFieldGroup
              label="Retirement Date"
              fieldType="inline"
              inputType="overrideWithComponent"
              componentType="singleDatePicker"
              inputName={Fields.hireDate}
              getFromToValue={onChange}
              onChange={onChange}
              value={data[Fields.hireDate]}
              checkedName={Fields.overrideRetirementDate}
              checkedValue={data[Fields.overrideRetirementDate]}
              colSpan={labelClass}
              tooltip={Description.RETIREMENT_DATE}
            />
          ) : (
            <NoStatusChanges entity="Retired" />
          )}
        </FormSection>
      </div>
    </FormAccordian>
  );
};

export default Retired;
