import React, { Component } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import holidayIcon from "../payroll-holiday-calendar/holidayIcon";
import HolidayItem from "./HolidayItem";

export default class PayrollCalendarHolidayHourItem extends Component {
  render() {
    const { label, lvalue, visible = true, HolidayDates = [] } = this.props;
    const holidayNodes =
      HolidayDates && HolidayDates.length > 0
        ? HolidayDates.map((val, i) => (
            <HolidayItem
              key={val.HolidayName + i}
              id={i}
              name={val.HolidayName}
              picUrl={val.HolidayName}
              imgName={holidayIcon(val.HolidayId)}
              width="20"
              height="20"
              hour={val.HolidayHour}
            />
          ))
        : "Holiday Icon Name Hours";
    const bottomPopover = (
      <Popover
        id="holiday-list-bottom"
        className="payroll-dash-summary-icon-list payroll-dash-summary-icon-list-bgcolor"
      >
        <div className="col-lg-12 col-md-12">
          <div className="col-lg-11 col-md-11" style={{ padding: "5px 0px" }}>
            {holidayNodes}
          </div>

          <div className="col-lg-1 col-md-1">
            <div className="payroll-dash-summary-icon-list-icon  clearfix">
              <i
                className="fa fa-times-circle icon"
                onClick={() => this.refs.overlayHolidayList.hide()}
              />
            </div>
          </div>
        </div>
      </Popover>
    );
    if (visible)
      return (
        <div className="payroll-dash-summary-item clearfix">
          <div className="payroll-dash-summary-item-label">{label}</div>
          <div className="payroll-dash-summary-item-value">{lvalue}</div>
          {HolidayDates &&
            HolidayDates.length > 0 && (
              <div className="payroll-dash-summary-item-icon">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  ref="overlayHolidayList"
                  overlay={bottomPopover}
                  rootClose={true}
                >
                  <i className="fa-information-blue fa fa-info-circle" />
                </OverlayTrigger>
              </div>
            )}
        </div>
      );
    else {
      return null;
    }
  }
}
