import React from "react";
import "./svg.css";
const Hooray = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 229 222"
    style={{ enableBackground: "new 0 0 229 222" }}
    height={height}
    width={width}
  >
    <circle className="st0" cx="114.5" cy="110.5" r="100" />
    <g>
      <circle className="st1" cx="83.4" cy="59.3" r="4.7" />
      <circle className="st1" cx="121.4" cy="53" r="4.7" />
      <path
        className="st1"
        d="M32.5,115.8c0-2.6-2.1-4.7-4.7-4.7c-2.4,0-4.5,2-4.7,4.5c0,2.6,2.1,4.7,4.7,4.7C30.4,120.3,32.5,118.3,32.5,115.8z"
      />
      <circle className="st1" cx="163.7" cy="50.5" r="4.7" />
      <circle className="st1" cx="128.6" cy="75.8" r="4.7" />
      <path
        className="st1"
        d="M29.5,145.1c-2.6,0-4.7,2.1-4.7,4.7s2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7C34.2,147.2,32,145.1,29.5,145.1z"
      />
      <path
        className="st1"
        d="M184.5,116.3c0.5-0.4,0.7-1,0.7-1.5c0.1-0.7-0.1-1.3-0.5-1.8c-0.4-0.5-1-0.7-1.5-0.7c0,0,0,0,0,0c-14.9-1.8-29.3-0.3-42.7,4.2c-2.7-3.3-5.9-6.9-9.8-11.1c-5.9-5.9-12-11-17.9-14.8c0.4-12.6-0.7-21.2-3.2-25.5c-3.3-5.6-7.3-5.9-9.4-5.6c-5.6,1-10,7.9-11.6,14.2c-0.1,0.6,0,1.3,0.4,1.8c0.3,0.5,0.8,0.8,1.4,0.9c0.6,0.1,1.3,0,1.8-0.4c0.5-0.3,0.8-0.8,0.9-1.4c1.3-5.4,4.7-10.3,7.7-10.8c1.7-0.2,3.3,0.9,4.9,3.4c1.1,1.9,2.9,7,2.8,20.6c-5.3-3-9.6-4.4-13-4.4c-2.4,0-4.2,0.7-5.3,2c-0.6,0.6-0.9,1.3-1.3,2h-0.2l-0.1,0.2c0,0-22.7,59.5-25.7,67.3l-0.4,0.8c-6,15.6-8,22.6-4.8,25.4l0.2,0.2c0.9,0.7,2.1,1,3.6,1c3.8,0,10.8-2.2,21.9-6.8c4.1-1.7,24.2-10.5,40.4-17.5c12.2-5.3,22.8-9.9,23.2-10.1l0.3-0.2c1.3-0.4,2.5-0.8,3.3-1.8c3.2-3.2,2.5-9.2-1.8-17.3c-1.4-2.6-3.1-5.2-5.3-8.3c12.8-4.1,26-5.3,39.3-3.6C183.4,116.9,184.1,116.7,184.5,116.3z M138.6,146.4c-15,6.7-51.2,22.5-56.9,24.7l0,0c-17,7-20.3,6.6-20.8,6.4l-0.1-0.1c-0.7-2.4,4.1-15.2,5.9-20l0.5-1.1c1-2.9,4.7-12.4,8.9-23.4c4.8-12.6,10.2-26.7,13.5-35.5c2.5,6.8,8.5,15.5,16.4,24.3c1.3,1.5,2.7,2.9,4,4.1C119.8,135.9,130.5,143.5,138.6,146.4z M127.6,108.6c2.9,2.9,5.6,5.8,8.7,9.5c-8.8,3.6-15.2,7.6-17.6,9.6c-0.8-0.8-1.7-1.5-2.5-2.3c-0.9-0.8-1.8-1.7-2.7-2.5c-0.6-0.6-1.1-1.1-1.6-1.7c-0.5-0.5-1-1-1.5-1.6c0.9-6.7,1.7-14.5,2.4-23.8C117.7,99.4,122.7,103.7,127.6,108.6z M144.8,130.8c3.6,6.9,3.7,10.8,2.7,11.9c-0.5,0.5-1.1,0.6-2.2,0.6c-5.1,0-14-4.9-23.2-12.7c1.7-1.3,7.5-5.2,17.1-8.8C141.5,125.3,143.4,128.3,144.8,130.8z M93.1,88.5c0.5-0.3,1.2-0.7,2.2-0.7c2.9,0,7.7,1.9,12.8,5.1c-0.2,5.4-0.8,12.3-2,22.2C95,102,90.7,91.1,93.1,88.5z"
      />
      <path
        className="st1"
        d="M149.3,92.6c0.3,1.4,0.6,2.8,0.3,3.4l0,0.2c0,1.1,1.1,2.3,2.3,2.3c1.1,0,2.3-0.9,2.3-2.3c0-1.2-0.3-2.6-0.6-4.3l-0.1-0.6c-1.6-6.4-1.8-10.5,1.9-12.7c1.7-1,3,0,6.5,4.8c2.3,3,4.6,6.1,7.8,6.5c1.7,0.2,3-0.3,4.8-1.6c5.7-4.7,2-10.7-0.7-15.1c-1-1.6-2.7-4.7-2.3-5.5c0.3,0,1.2,0.5,2.9,1.5c2.7,1.7,7.8,4.9,12.8,1.1c2.6-2,4.1-4.6,4.5-7.8c0.8-5.5-2-12.1-5.1-16.2c-0.3-0.5-0.7-0.8-1.3-0.9c-0.6-0.1-1.4,0-1.8,0.4c-0.5,0.3-0.8,0.7-0.9,1.3c-0.1,0.6,0,1.4,0.4,1.9c2.4,3.2,4.8,8.5,4.2,12.9c-0.2,2-1,3.5-2.7,4.8c-2.2,1.7-4.3,0.9-7.6-1.3c-1.9-1.2-6.2-4.1-9-0.1c-2.1,3.3,0.3,7.2,2.2,10.3c2.7,4.9,3.9,7.3,1.6,9.3c-0.7,0.5-1.1,0.5-1.2,0.5c-1.2-0.2-2.7-2.2-4.2-4.1l-0.6-0.8c-2.8-3.8-6.7-8.9-12.1-5.7c-7,4.2-5.3,12.1-4.2,17.3L149.3,92.6z"
      />
      <path
        className="st1"
        d="M188.4,123.9c-2.6,0-4.7,2.1-4.7,4.7s2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7S191.1,123.9,188.4,123.9z"
      />
      <path
        className="st1"
        d="M65,82L65,81.7h0L65,82l0.5-0.1c3.7-0.7,7.8-1.5,9.1-0.2c1.8,2-2.1,7.6-4,10.2L70.6,92c-2.1,3-3.8,5.5-3.9,7.6c0,1,0.6,2.2,2.1,2.4h0.2c1.1,0,2-0.8,2.2-2c0.2-1.1,1.3-3.2,3.1-5.7C78,89.1,81.6,83,78,78.6c-2.8-3.2-8.1-2.3-13.3-1.4l-0.6,0.1c-4.7,0.9-9.5,1.8-10.7-0.4c-0.2-0.3-0.2-0.4-0.2-0.5c0,0,0-0.1,0-0.1c0.5-1.1,4-2.3,6.3-3.1l0.6-0.2c4.5-1.5,9.1-3,8.9-7c-0.4-4.4-5.1-7.3-9.8-10L59,55.8c-2-1.1-4.1-2.4-5.6-3.5c-0.9-0.7-0.8-1.3-0.8-1.5c0.3-1.7,3.5-4.6,9.2-7c1.1-0.4,1.6-1.8,1.1-2.9c-0.2-0.5-0.7-1-1.2-1.2c-0.5-0.2-1.2-0.2-1.7,0c-7.1,3.1-11.3,6.8-11.8,10.6c-0.2,1-0.5,3.4,2.4,5.8c1.3,1,2.9,2,4.5,3c0.5,0.3,1.1,0.7,1.6,1c2.7,1.5,7.6,4.2,7.9,6.4c-0.2,0.6-3.2,1.6-4.6,2.1c-0.4,0.1-0.8,0.3-1.1,0.4c-3.7,1.2-8.4,2.8-9.6,6c-0.5,1.4-0.4,2.8,0.4,4.4C52.2,84.5,59,83.2,65,82z"
      />
      <path
        className="st1"
        d="M179.6,162.2c-2.6,0-4.7,2.1-4.7,4.7c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7C184.3,164.3,182.2,162.2,179.6,162.2z"
      />
      <circle className="st1" cx="84.1" cy="135.7" r="4.7" />
      <circle className="st1" cx="165.4" cy="134.8" r="4.7" />
      <circle className="st1" cx="61.4" cy="120.3" r="4.7" />
      <path
        className="st1"
        d="M151.8,153.7c-2.6,0-4.7,2.1-4.7,4.7c0,2.6,2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7C156.5,155.8,154.5,153.7,151.8,153.7z"
      />
    </g>
  </svg>
);
export default Hooray;
