import React, { Component } from "react";
import EntityListFilters from "component-library/entity-list/EntityListFilters";

class PayrollScheduleFilters extends Component {
  componentDidMount() {
    this.props.fetchAndSetPayrollSchedule();
    this.props.fetchAndSetFrequency();
    this.props.fetchAndSetStatus();
  }

  makeOnSelect = name => (option, selected) => {
    this.props.selectOption(name, option, selected);
  };

  makeSelectAll = name => selected => {
    this.props.selectAll(name, selected);
  };

  render() {
    const { options, selectedOptions } = this.props;
    return (
      <div className="payroll-schedule-filters">
        <EntityListFilters
          page="PayrollSchedule"
          options={options}
          selectedOptions={selectedOptions}
          onClose={this.props.searchPosts}
          makeOnSelect={this.makeOnSelect}
          makeSelectAll={this.makeSelectAll}
          width={"86%"}
        />
      </div>
    );
  }
}

export default PayrollScheduleFilters;
