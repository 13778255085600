/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
const CustomPanelBar = ({ children, singleLineFilter }) => {
  return (
    <div className="mobile-filter-select-dropdown no-padding col-sm-12 col-xs-12">
      <div className="mobile-filter-panel-list-section clearfix">
        <div className="mobile-filter-panel-list-panel-bar col-xs-12 col-sm-12">
          <a id="" className="clearfix" style={{ display: "flex" }}>
            {singleLineFilter ? (
              <div className="col-xs-12 col-sm-12 mobile-filter-panel-list-label no-padding">
                {children}
              </div>
            ) : (
              <div className="col-xs-8 col-sm-8 mobile-filter-panel-list-label no-padding">
                {children}
              </div>
            )}
          </a>
        </div>
      </div>
    </div>
  );
};
export default CustomPanelBar;
