import React from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";

const AboutMe = props => (
  <FormSection title="About Me">
    <FormFieldGroup
      inputId="aboutMe"
      label="Tell Us About Yourself"
      inputName="aboutMe"
      inputType="textarea"
      Size={[7, 7, 7, 7]}
      height="80px"
      description="125 characters max"
      optional={true}
      required={true}
      descriptionStyle="italic"
      colSpan="col-md-3"
      labelStyle={{ marginTop: "30px" }}
      block
      value={props.data["aboutMe"]}
      onChange={props.onChange}
    />
  </FormSection>
);
export default AboutMe;
