import React, { Component } from "react";
import UtilitiesItems from "./UtilitiesItems";
import "./utilitiesContent.css";

class UtilitiesContent extends Component {
  render() {
    const { data, title } = this.props;
    return (
      <div className="utility-container row">
        <div
          id={"utility-content-" + data.id}
          className="utility-content col-lg-2 col-md-2 col-sm-12 col-xs-12"
        >
          <i className={data.icon} aria-hidden="true" />
          {title}
        </div>
        <div className="seperator">
          <hr />
        </div>
        <div className="utility-items">
          <UtilitiesItems {...this.props} />
        </div>
      </div>
    );
  }
}
export default UtilitiesContent;
