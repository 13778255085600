import React, { Component } from "react";
import PropTypes from "prop-types";
import JobHistoryTableHeader from "./JobHistoryTableHeader";
import JobHistoryItem from "./JobHistoryItem";

export default class JobHistoryTable extends Component {
  render() {
    const { data, changeSortOrder } = this.props;
    return (
      <div>
        <JobHistoryTableHeader changeSortOrder={changeSortOrder} />
        {data.map(item => (
          <JobHistoryItem key={item.ActivityInstanceChangeLogId} data={item} />
        ))}
      </div>
    );
  }
}

JobHistoryTable.propTypes = {
  data: PropTypes.array,
  changeSortOrder: PropTypes.func
};
