const COUNTRY_STATE_OPTIONS = "app/COUNTRY_STATE_OPTIONS";
const COUNTRY_OPTIONS = "app/COUNTRY_OPTIONS";

export const countryState = (state = [], { type, payload }) => {
  switch (type) {
    case COUNTRY_STATE_OPTIONS:
      const { name, options = [] } = payload;
      return {
        ...state,
        [name]: options
      };
    default:
      return state;
  }
};

export const country = (state = [], { type, payload }) => {
  switch (type) {
    case COUNTRY_OPTIONS:
      const { name, options = [] } = payload;
      return {
        ...state,
        [name]: options
      };
    default:
      return state;
  }
};

export const setCountryStateOptions = (name, options) => ({
  type: COUNTRY_STATE_OPTIONS,
  payload: { name, options }
});

export const setCountryOptions = (name, options) => ({
  type: COUNTRY_OPTIONS,
  payload: { name, options }
});
