import React from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import IncludedInPayroll from "./IncludedInPayroll";
import * as Fields from "../FieldNames";
const ReactivationDetails = ({ onChange, data = {} }) => {
  return (
    <FormSection title={"Reactivation Details"} hr>
      <FormFieldGroup
        inputType="singleDatePicker"
        label="Reactivation Date"
        fieldType="inline"
        inputName={Fields.reactivationDate}
        value={data[Fields.reactivationDate]}
        colSpan="col-lg-3 col-md-3"
        Size={[6, 4, 12, 12]}
        onChange={onChange}
        style={{ marginBottom: "15px" }}
        getToFromValue={onChange}
        showNoDateSection
      />
      <IncludedInPayroll
        label="Should this employee be included in payroll?"
        colSpan="col-lg-3 col-md-3"
        inputName={Fields.reactivationIncludedInPayroll}
        data={data}
        value={data[Fields.reactivationIncludedInPayroll]}
        onChange={onChange}
        middleText="From which paycheck date"
      />
      <FormFieldGroup
        inputType="textarea"
        inputName={Fields.reactivatioNotes}
        label="Notes"
        value={data[Fields.reactivatioNotes]}
        fieldType="inline"
        description="250 characters max"
        rows={6}
        colSpan="col-lg-3 col-md-3"
        Size={[8, 4, 12, 12]}
        onChange={onChange}
        getToFromValue={onChange}
        optional
      />
    </FormSection>
  );
};

export default ReactivationDetails;
