import React from "react";
import DropDown from "../../dropDown";
import Media from "react-media";
import {
  LAST_DAY,
  PAY_CHECK_DATE,
  PAY_CHECK_END_DATE,
  PAY_CHECK_START_MONTH,
  PAY_CHECK_END_MONTH,
  daySuffix
} from "./Constants";

const options = {
  Months: [
    { id: 1, value: 1, label: "January" },
    { id: 2, value: 2, label: "February" },
    { id: 3, value: 3, label: "March" },
    { id: 4, value: 4, label: "April" },
    { id: 5, value: 5, label: "May" },
    { id: 6, value: 6, label: "June" },
    { id: 7, value: 7, label: "July" },
    { id: 8, value: 8, label: "August" },
    { id: 9, value: 9, label: "September" },
    { id: 10, value: 10, label: "October" },
    { id: 11, value: 11, label: "November" },
    { id: 12, value: 12, label: "December" }
  ]
};

const AnuallyPayPeriodItems = props => {
  const { mapData } = props;
  return mapData.map((t, i) => {
    let monthDropDownData = [];
    let endMonthDropDownData = [];
    const dayCount = 31;
    for (let i = 1; i <= dayCount; i++) {
      let suffix = (i - 1) % 10 < 3 ? daySuffix[(i - 1) % 10] : "th";
      if (i > 10 && i < 20) {
        suffix = "th";
      }
      monthDropDownData.push({
        value: i,
        label: i + suffix
      });
      endMonthDropDownData.push({
        value: i,
        label: i + suffix
      });
    }
    monthDropDownData.push({
      value: 99,
      label: LAST_DAY
    });
    endMonthDropDownData.push({
      value: 99,
      label: LAST_DAY
    });
    return (
      <Media query="(min-width:768px)" key={t}>
        {matches =>
          matches ? (
            <div className="pay-period-anually" key={t}>
              {/* <div
                style={{
                  fontWeight: "600",
                  marginBottom: "10px",
                  fontSize: "16px",
                  color: "#009966"
                }}
              >
                Payroll
              </div> */}
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px"
                }}
              >
                <div style={{ fontWeight: "550" }}>
                  {props.quartely
                    ? "The first paycheck date of the year is on"
                    : "The paycheck date is on"}
                </div>
                <div
                  className="drop-down-first"
                  style={{ fontWeight: "400", display: "flex" }}
                >
                  <DropDown
                    placeholder=""
                    options={options.Months}
                    value={props.data[t + PAY_CHECK_START_MONTH]}
                    onSelect={val =>
                      props.handleChangeMonth(t + PAY_CHECK_START_MONTH, val)
                    }
                    id="drop-down-first"
                  />

                  <DropDown
                    placeholder=""
                    options={monthDropDownData}
                    value={props.data[t + PAY_CHECK_DATE]}
                    onSelect={val =>
                      props.handleChangeMonth(t + PAY_CHECK_DATE, val)
                    }
                    id="drop-down-first"
                  />
                </div>
              </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <div>The pay period end date is on</div>
                <div
                  className="drop-down-sec"
                  style={{ fontWeight: "400", display: "flex" }}
                >
                  <DropDown
                    placeholder=""
                    options={options.Months}
                    value={props.data[t + PAY_CHECK_END_MONTH]}
                    onSelect={val =>
                      props.handleChangeMonth(t + PAY_CHECK_END_MONTH, val)
                    }
                    id="drop-down-pay-period-end-month"
                  />

                  <DropDown
                    placeholder=""
                    options={endMonthDropDownData}
                    value={props.data[t + PAY_CHECK_END_DATE]}
                    onSelect={val =>
                      props.handleChangeMonth(t + PAY_CHECK_END_DATE, val)
                    }
                    id="drop-down-pay-period-end-date"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="pay-period-anually" key={t}>
              <div
                className="anually-section"
                style={{
                  marginBottom: "10px"
                }}
              >
                <div className="">The paycheck date is on</div>
                <div
                  className="drop-down-first"
                  style={{ fontWeight: "400", display: "flex" }}
                >
                  <DropDown
                    placeholder=""
                    options={options.Months}
                    value={props.data[t + PAY_CHECK_START_MONTH]}
                    onSelect={val =>
                      props.handleChangeMonth(t + PAY_CHECK_START_MONTH, val)
                    }
                    id="drop-down-first"
                  />

                  <DropDown
                    placeholder=""
                    options={monthDropDownData}
                    value={props.data[t + PAY_CHECK_DATE]}
                    onSelect={val =>
                      props.handleChangeMonth(t + PAY_CHECK_DATE, val)
                    }
                    id="drop-down-first"
                  />
                </div>
              </div>
              <div
                className="anually-section"
                style={{
                  marginBottom: "10px"
                }}
              >
                <div>The pay period end date is on the</div>
                <div
                  className="drop-down-sec"
                  style={{ fontWeight: "400", display: "flex" }}
                >
                  <DropDown
                    placeholder=""
                    options={options.Months}
                    value={props.data[t + PAY_CHECK_END_MONTH]}
                    onSelect={val =>
                      props.handleChangeMonth(t + PAY_CHECK_END_MONTH, val)
                    }
                    id="drop-down-pay-period-end-month"
                  />

                  <DropDown
                    placeholder=""
                    options={endMonthDropDownData}
                    value={props.data[t + PAY_CHECK_END_DATE]}
                    onSelect={val =>
                      props.handleChangeMonth(t + PAY_CHECK_END_DATE, val)
                    }
                    id="drop-down-pay-period-end-date"
                  />
                </div>
              </div>
            </div>
          )
        }
      </Media>
    );
  });
};

export default AnuallyPayPeriodItems;
