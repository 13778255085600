import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AdvanceFilter } from "component-library/employee-profile";
import * as Filters from "component-library/employee-profile/list-view/advance-filter/FieldNames";
import { makeCriterion } from "../utils";
import { onApplyAdvanceFilter } from "../network/actions";

const getSearchParams = state => {
  const { filters = {} } = state.common;
  const { advancedFilterFields } = state.people;
  const defaultSearchParams = {
    Fields: ["*"],
    Criterion: [],
    Sort: [],
    PageSize: 5
  };

  let Criterion = [];
  Criterion.push(
    makeCriterion(Filters.lastName, advancedFilterFields[Filters.lastName])
  );
  Criterion.push(
    makeCriterion(Filters.firstName, advancedFilterFields[Filters.firstName])
  );
  Criterion.push(
    makeCriterion(Filters.birthDate, advancedFilterFields[Filters.birthDate])
  );
  Criterion.push(
    makeCriterion(Filters.ageTo, advancedFilterFields[Filters.ageTo])
  );
  Criterion.push(
    makeCriterion(Filters.ageFrom, advancedFilterFields[Filters.ageFrom])
  );
  Criterion.push(makeCriterion(Filters.gender, filters[Filters.gender]));
  Criterion.push(
    makeCriterion(Filters.employeeStatus, filters[Filters.employeeStatus])
  );
  Criterion.push(
    makeCriterion(Filters.hireDate, advancedFilterFields[Filters.hireDate])
  );
  Criterion.push(
    makeCriterion(
      Filters.terminationDate,
      advancedFilterFields[Filters.terminationDate]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.reTerminationDate,
      advancedFilterFields[Filters.reTerminationDate]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.inactiveStartDate,
      advancedFilterFields[Filters.inactiveStartDate]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.inactiveEndDate,
      advancedFilterFields[Filters.inactiveEndDate]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.leaveStartDate,
      advancedFilterFields[Filters.leaveStartDate]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.leaveEndDate,
      advancedFilterFields[Filters.leaveEndDate]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.transferDate,
      advancedFilterFields[Filters.transferDate]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.retiredDate,
      advancedFilterFields[Filters.retiredDate]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.deceasedDate,
      advancedFilterFields[Filters.deceasedDate]
    )
  );
  Criterion.push(
    makeCriterion(Filters.primaryManager, filters[Filters.primaryManager])
  );
  Criterion.push(
    makeCriterion(Filters.secondaryManager, filters[Filters.secondaryManager])
  );
  Criterion.push(
    makeCriterion(Filters.employeeType, filters[Filters.employeeType])
  );
  Criterion.push(
    makeCriterion(Filters.employeePosition, filters[Filters.employeePosition])
  );
  Criterion.push(
    makeCriterion(Filters.payrollSchedule, filters[Filters.payrollSchedule])
  );
  Criterion.push(
    makeCriterion(Filters.payrollFrequency, filters[Filters.payrollFrequency])
  );
  Criterion.push(
    makeCriterion(Filters.employeeCompany, filters[Filters.employeeCompany])
  );
  Criterion.push(
    makeCriterion(Filters.employeeDivision, filters[Filters.employeeDivision])
  );
  Criterion.push(
    makeCriterion(
      Filters.employeeDepartment,
      filters[Filters.employeeDepartment]
    )
  );
  Criterion.push(
    makeCriterion(Filters.employeeLocation, filters[Filters.employeeLocation])
  );
  Criterion.push(
    makeCriterion(Filters.timeGroup, filters[Filters.deceasedDate])
  );
  Criterion.push(
    makeCriterion(
      Filters.employeeBadgeNumber,
      advancedFilterFields[Filters.employeeBadgeNumber]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.employeeNumber,
      advancedFilterFields[Filters.employeeNumber]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.clockNumber,
      advancedFilterFields[Filters.clockNumber]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.employeeSsn,
      advancedFilterFields[Filters.employeeSsn]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.employeeCode,
      advancedFilterFields[Filters.employeeCode]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.employeeClient,
      advancedFilterFields[Filters.employeeClient]
    )
  );
  Criterion.push(
    makeCriterion(
      Filters.employeeProject,
      advancedFilterFields[Filters.employeeProject]
    )
  );
  Criterion = Criterion.filter(c => c);

  defaultSearchParams.Criterion = Criterion;

  return defaultSearchParams;
};
class AdvancedFiltersContainer extends Component {
  state = { data: {} };

  onChange = e => {
    const edits = { [e.name]: e.value };
    const { props } = this;
    if (e.name === Filters.employeeCompany) {
      props.loadDivisions();
      props.loadLocations();
      props.loadClients();
    }
    if (e.name === Filters.employeeDivision) {
      props.loadBussinessUnits();
    }
    if (e.name === Filters.businessUnit) {
      props.loadDepartments();
    }
    if (e.name === Filters.employeeDepartment) {
    }
    if (e.name === Filters.employeeClient) {
      props.loadProjects();
    }
    this.editData(edits);
    this.props.selectTextfield(e.name, e.value);

    if (e.value !== "") this.props.setShowClearFilters(true);
    else this.props.setShowClearFilters(false);
  };

  makeOnSelect = name => (option, selected) => {
    this.props.selectOption(name, option, selected);
    if (name !== Filters.employeeCompany && name !== Filters.employeeStatus)
      this.props.setShowClearFilters(true);
  };

  makeOnSelectAll = name => selected => {
    this.props.selectAll(name, selected);
    if (name !== Filters.employeeCompany && name !== Filters.employeeStatus)
      this.props.setShowClearFilters(true);
  };

  makeDateRangeSelect = e => {
    this.props.selectTextfield(e.name, e.value);
    this.props.setShowClearFilters(true);
  };

  resetFilters = () => {
    const { clearAdvancedFilters, options } = this.props;
    this.props.clearFiltersFields();
    this.props.setShowClearFilters(false);
    clearAdvancedFilters(options);
  };

  editData(edits) {
    this.setState({ data: { ...this.state.data, ...edits } });
  }

  componentDidMount = () => {
    const { props } = this;
    props.loadPrimaryManager();
    props.loadSecondaryManager();
    props.loadEmployeeType();
    props.loadEmployeeClassification();
    props.loadEmployeePosition();
    props.loadPayrollSchedule();
    props.loadPayrollFrequency();
    props.loadTimeGroup();
    props.loadGender();
  };

  onApply = () => {
    const { onApplyAdvanceFilter, searchParams } = this.props;
    onApplyAdvanceFilter(searchParams, employeeData => {
      if (Object.keys(employeeData).length > 0) {
        this.props.history.push(
          "/people/employees/dashboard/" + employeeData.EmployeeId
        );
      } else {
        this.props.history.push("/people/employees/list-view");
      }
      this.props.onClose();
    });
  };

  render() {
    return (
      <AdvanceFilter
        {...this.props}
        onChange={this.onChange}
        data={this.props.employeeAdvancedFilter}
        onClear={this.resetFilters}
        makeOnSelect={this.makeOnSelect}
        makeOnSelectAll={this.makeOnSelectAll}
        makeDateRangeSelect={this.makeDateRangeSelect}
        onApply={this.onApply}
      />
    );
  }
}

const stateToProps = state => ({
  searchParams: getSearchParams(state)
});

const actionCreators = {
  onApplyAdvanceFilter
};

export default withRouter(
  connect(stateToProps, actionCreators)(AdvancedFiltersContainer)
);
