import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  EntityListPageContainer,
  EntityListHeader
} from "component-library/entity-list";
/**TODO (Kamlesh/Ashish): Remove the mock data after the actual services are available for integration*/
import addressVerificationData from "./addressVerificationData";

import WageTaxValidationTabSection from "component-library/payroll-dashboard/wagetaxvalidation/WageTaxValidationTabSection";
import tabItemsList from "component-library/payroll-dashboard/wagetaxvalidation/WageTaxValidationTabData";
import {
  AddressVerification,
  MinimumWageAlerts,
  TaxVerification
} from "component-library/payroll-dashboard/wagetaxvalidation";

class WageTaxValidation extends Component {
  state = {
    selectedTab: 1
  };

  onWageTaxTabClick = value => {
    this.setState({
      selectedTab: value
    });
  };

  render() {
    return (
      <EntityListPageContainer>
        <EntityListHeader
          title="Wage & Tax Validation"
          page="WageTaxValidation"
          showButtons={false}
        />

        <WageTaxValidationTabSection
          tabItemsList={tabItemsList({})}
          onClick={this.onWageTaxTabClick}
          selectedTab={this.state.selectedTab}
        />

        <div className="entity-page-footer-separator">
          <hr />
        </div>

        <AddressVerification data={addressVerificationData} />
        <div className="content-filler" />
        <MinimumWageAlerts data={addressVerificationData} />
        <div className="content-filler" />
        <TaxVerification data={addressVerificationData} />
        <div className="content-filler" />
      </EntityListPageContainer>
    );
  }
}

const stateToProps = state => ({});

const actionCreators = {};

export default withRouter(
  connect(stateToProps, actionCreators)(WageTaxValidation)
);
