import React from "react";
import "./svg.css";
const ThirdPartyPayeeNoData = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 200 200"
    style={{ enableBackground: "new 0 0 200 200" }}
    width={width}
    height={height}
  >
    <g>
      <g>
        <circle className="st0" cx="100" cy="100" r="100" />
      </g>
    </g>
    <g>
      <path
        className="st2"
        d="M87.2,114.2v5.8h25.6v-5.8c-3.4,3.1-7.9,5-12.8,5C95.1,119.2,90.6,117.3,87.2,114.2z"
      />
      <path
        className="st2"
        d="M100,89.7c-2.8,0-5.1,2.3-5.1,5.1s2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1S102.8,89.7,100,89.7z"
      />
      <g>
        <path
          className="st1"
          d="M104.7,102.2c-1.3,0.9-3,1.4-4.7,1.4c-1.7,0-3.3-0.5-4.7-1.4c-3.8,0.7-7,2.6-9.1,5.1c0.6,1.1,1.3,2.1,2.1,3.1
			c1.8-2.9,5.8-5,10.4-5h2.6c4.6,0,8.6,2.1,10.4,5c0.8-0.9,1.5-2,2.1-3.1C111.7,104.7,108.5,102.9,104.7,102.2z"
        />
        <path
          className="st1"
          d="M101.3,101.8c1.2,0,2.3,0.1,3.3,0.3c2.4-1.5,4-4.2,4-7.3c0-4.8-3.9-8.6-8.6-8.6c-4.8,0-8.6,3.9-8.6,8.6
			c0,3,1.6,5.7,4,7.3c1.1-0.2,2.2-0.3,3.4-0.3H101.3z M94.9,94.9c0-2.8,2.3-5.1,5.1-5.1s5.1,2.3,5.1,5.1s-2.3,5.1-5.1,5.1
			S94.9,97.7,94.9,94.9z"
        />
      </g>
      <path
        className="st1"
        d="M98.7,101.8c-1.2,0-2.3,0.1-3.4,0.3c1.3,0.9,3,1.4,4.7,1.4c1.7,0,3.3-0.5,4.7-1.4c-1.1-0.2-2.2-0.3-3.3-0.3
		H98.7z"
      />
    </g>
    <g>
      <path
        className="st1"
        d="M100,119.2c-10.5,0-19-8.5-19-19c0-10.5,8.5-19,19-19c10.5,0,19,8.5,19,19C119,110.7,110.5,119.2,100,119.2z
		 M100,84.8c-8.5,0-15.5,6.9-15.5,15.5c0,8.5,6.9,15.5,15.5,15.5c8.5,0,15.5-6.9,15.5-15.5C115.5,91.7,108.5,84.8,100,84.8z"
      />
    </g>
    <g>
      <g>
        <path
          className="st1"
          d="M146.6,102.5h-6c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8h6"
        />
      </g>
      <g>
        <path
          className="st1"
          d="M130.6,102.5h-6c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8h6c1,0,1.8,0.8,1.8,1.8S131.6,102.5,130.6,102.5z"
        />
      </g>
    </g>
    <g>
      <path
        className="st1"
        d="M100,68.1c-1,0-1.8,0.8-1.8,1.8v6c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8v-6C101.8,68.9,101,68.1,100,68.1z"
      />
      <path
        className="st1"
        d="M106.2,47.7c0-3.5-2.9-6.4-6.4-6.4s-6.4,2.9-6.4,6.4c0,3,2.1,5.5,4.9,6.2v6c0,1,0.8,1.8,1.8,1.8
		s1.8-0.8,1.8-1.8v-6c0,0,0-0.1,0-0.1C104.3,52.9,106.2,50.5,106.2,47.7z M99.8,50.6c-1.6,0-2.9-1.3-2.9-2.9s1.3-2.9,2.9-2.9
		s2.9,1.3,2.9,2.9S101.4,50.6,99.8,50.6z"
      />
    </g>
    <g>
      <path
        className="st1"
        d="M99.8,133c1,0,1.8-0.8,1.8-1.8v-6c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8v6C98,132.3,98.8,133,99.8,133z"
      />
      <path
        className="st1"
        d="M101.5,145.8v-4.5c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8v4.5c-3.3,0.8-5.7,3.8-5.7,7.3c0,4.1,3.4,7.5,7.5,7.5
		c4.1,0,7.5-3.4,7.5-7.5C107.3,149.5,104.8,146.6,101.5,145.8z M99.8,157c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S102,157,99.8,157z"
      />
    </g>
    <g>
      <path
        className="st1"
        d="M75.2,98.7h-6c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h6c1,0,1.8-0.8,1.8-1.8S76.2,98.7,75.2,98.7z"
      />
      <path
        className="st1"
        d="M59.2,98.7h-5.3c-0.7-2-2.5-3.5-4.8-3.5c-2.8,0-5,2.3-5,5c0,2.8,2.3,5,5,5c2.1,0,3.9-1.3,4.6-3.1h5.5
		c1,0,1.8-0.8,1.8-1.8S60.1,98.7,59.2,98.7z M49.1,101.8c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5
		C50.6,101.1,49.9,101.8,49.1,101.8z"
      />
    </g>
    <g>
      <path
        className="st1"
        d="M150,106.3c-0.7,0-1.4-0.1-2.1-0.4c-1.5-0.6-2.7-1.7-3.4-3.2c-0.7-1.5-0.7-3.1-0.1-4.6
		c0.6-1.5,1.7-2.7,3.2-3.4c1.5-0.7,3.1-0.7,4.6-0.1c1.5,0.6,2.7,1.7,3.4,3.2c0.7,1.5,0.7,3.1,0.1,4.6s-1.7,2.7-3.2,3.4
		C151.7,106.1,150.8,106.3,150,106.3z M150,97.7c-0.4,0-0.7,0.1-1,0.2c-0.6,0.3-1.1,0.8-1.3,1.4s-0.2,1.3,0.1,1.9
		c0.3,0.6,0.8,1.1,1.4,1.3c0.6,0.2,1.3,0.2,1.9-0.1c0.6-0.3,1.1-0.8,1.3-1.4s0.2-1.3-0.1-1.9c-0.3-0.6-0.8-1.1-1.4-1.3
		C150.6,97.8,150.3,97.7,150,97.7z"
      />
    </g>
    <g>
      <path
        className="st1"
        d="M83.7,81.8l-4.2-4.2c-0.7-0.7-1.8-0.7-2.5,0s-0.7,1.8,0,2.5l4.2,4.2c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5
		C84.4,83.6,84.4,82.5,83.7,81.8z"
      />
      <path
        className="st1"
        d="M68.8,66.9c0.1-0.2,0.3-0.5,0.3-0.7c0.6-1.5,0.5-3.1-0.1-4.6c-0.7-1.5-1.9-2.6-3.4-3.2
		c-1.5-0.6-3.2-0.5-4.6,0.1c-1.5,0.7-2.6,1.9-3.2,3.4c-0.6,1.5-0.5,3.2,0.1,4.6c1,2.2,3.2,3.6,5.5,3.6c0.8,0,1.7-0.2,2.5-0.5
		c0.1-0.1,0.2-0.1,0.3-0.2l3.6,3.6c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L68.8,66.9z M61.2,65.1
		c-0.3-0.6-0.3-1.3-0.1-1.9s0.7-1.1,1.3-1.4c0.3-0.2,0.7-0.2,1-0.2c0.3,0,0.6,0.1,0.9,0.2c0.6,0.2,1.1,0.7,1.4,1.3c0,0,0,0,0,0
		c0.3,0.6,0.3,1.3,0.1,1.9s-0.7,1.1-1.3,1.4C63.3,66.9,61.8,66.4,61.2,65.1z"
      />
    </g>
    <g>
      <path
        className="st1"
        d="M120.4,77.7l-4.2,4.2c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5l4.2-4.2
		c0.7-0.7,0.7-1.8,0-2.5C122.2,77,121.1,77,120.4,77.7z"
      />
      <path
        className="st1"
        d="M135.6,60c-2.8,0-5,2.3-5,5c0,0.7,0.2,1.5,0.5,2.1l-3.5,3.5c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5
		s0.9-0.2,1.2-0.5l3.5-3.5c0.6,0.3,1.3,0.5,2.1,0.5c2.8,0,5-2.3,5-5S138.4,60,135.6,60z M135.6,66.6c-0.8,0-1.5-0.7-1.5-1.5
		s0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5S136.4,66.6,135.6,66.6z"
      />
    </g>
    <g>
      <path
        className="st1"
        d="M118.5,116.9c-0.7-0.7-1.8-0.7-2.5,0s-0.7,1.8,0,2.5l4.2,4.2c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5
		c0.7-0.7,0.7-1.8,0-2.5L118.5,116.9z"
      />
      <path
        className="st1"
        d="M135.6,131.4c-0.7,0-1.5,0.2-2.1,0.5l-3.7-3.7c-0.7-0.7-1.8-0.7-2.5,0c-0.7,0.7-0.7,1.8,0,2.5l3.7,3.7
		c-0.3,0.6-0.5,1.3-0.5,2.1c0,2.8,2.3,5,5,5c2.8,0,5-2.3,5-5S138.4,131.4,135.6,131.4z M135.6,138c-0.8,0-1.5-0.7-1.5-1.5
		s0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5S136.4,138,135.6,138z"
      />
    </g>
    <g>
      <path
        className="st1"
        d="M69.8,128.1l-3.7,3.7c-0.5-0.2-1.1-0.3-1.7-0.3c-2.8,0-5,2.3-5,5c0,2.8,2.3,5,5,5c2.8,0,5-2.3,5-5
		c0-0.9-0.3-1.7-0.7-2.5l3.5-3.5c0.7-0.7,0.7-1.8,0-2.5S70.5,127.4,69.8,128.1z M64.4,138c-0.8,0-1.5-0.7-1.5-1.5
		c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5C66,137.3,65.3,138,64.4,138z"
      />
      <path
        className="st1"
        d="M83.6,116.7c-0.7-0.7-1.8-0.7-2.5,0l-4.2,4.2c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5
		l4.2-4.2C84.3,118.5,84.3,117.4,83.6,116.7z"
      />
    </g>
  </svg>
);
export default ThirdPartyPayeeNoData;
