export const SET_PAYROLL_TYPE_DATA = "payroll/dashboard/SET_PAYROLL_TYPE_DATA";
export const CLEAR_PAYROLL_TYPE_DATA =
  "payroll/dashboard/CLEAR_PAYROLL_TYPE_DATA";
export const ADD_TO_PAYROLL_TYPE_DATA =
  "payroll/dashboard/ADD_TO_PAYROLL_TYPE_DATA";
export const SET_EMPLYEE_DATA = "payroll/dashboard/SET_EMPLYEE_DATA";

const initialState = {
  loading: true,
  Data: [],
  DataCount: 0,
  NextUri: null,

  UnapprovedPtos: {
    loading: true,
    Data: [],
    DataCount: 0,
    NextUri: null
  },
  TimecardAlerts: {
    loading: true,
    Data: [],
    DataCount: 0,
    NextUri: null
  },
  EmployeeUpdates: {
    loading: true,
    Data: [],
    DataCount: 0,
    NextUri: null
  },
  AddressVerification: {
    loading: true,
    Data: [],
    DataCount: 0,
    NextUri: null,
    SuggestedData: [],
    AddressNotFoundData: [],
    Id: 0
  },
  MinimumWagesAlerts: {
    loading: true,
    Data: [],
    DataCount: 0,
    NextUri: null,
    SuggestedData: [],
    Id: 0
  },
  TaxVerification: {
    loading: true,
    Data: [],
    DataCount: 0,
    NextUri: null,
    SuggestedData: [],
    Id: 0
  },
  EmployeeData: {
    loading: true,
    Data: [],
    DataCount: 0,
    NextUri: null
  }
};

//Actions
export const setPayrollTypeData = (
  Data,
  DataCount,
  NextUri,
  Type = "",
  SuggestedData = [],
  AddressNotFoundData = [],
  Id = 0
) => ({
  type: SET_PAYROLL_TYPE_DATA,
  payload: {
    Data,
    DataCount,
    NextUri,
    Type,
    SuggestedData,
    AddressNotFoundData,
    Id
  }
});
export const addToPayrollTypeData = (
  Data,
  DataCount,
  NextUri,
  Type = "",
  SuggestedData = [],
  AddressNotFoundData = [],
  Id = 0
) => ({
  type: ADD_TO_PAYROLL_TYPE_DATA,
  payload: {
    Data,
    DataCount,
    NextUri,
    Type,
    SuggestedData,
    AddressNotFoundData,
    Id
  }
});

export const clearPayrollTypeData = () => ({
  type: CLEAR_PAYROLL_TYPE_DATA,
  payload: []
});

export const setEmployeeData = (Data, DataCount, NextUri, Type) => ({
  type: SET_EMPLYEE_DATA,
  payload: { Data, DataCount, NextUri, Type }
});

// Reducers
const payrollTypeData = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_PAYROLL_TYPE_DATA:
      return initialState;
    case SET_PAYROLL_TYPE_DATA: {
      const {
        Data,
        DataCount,
        NextUri,
        Type,
        SuggestedData,
        AddressNotFoundData,
        Id
      } = payload;
      //const newData = [...state.Data, ...Data];
      return {
        ...state,
        [Type]: {
          NextUri,
          Data,
          DataCount: DataCount,
          SuggestedData,
          AddressNotFoundData,
          loading: false,
          Id
        }
      };
    }
    case ADD_TO_PAYROLL_TYPE_DATA: {
      const {
        Data,
        DataCount,
        NextUri,
        Type,
        SuggestedData,
        AddressNotFoundData,
        Id
      } = payload;
      const newData = [...state.Data, ...Data];
      return {
        ...state,
        [Type]: {
          NextUri,
          Data: newData,
          DataCount: DataCount,
          SuggestedData,
          AddressNotFoundData,
          loading: false,
          Id
        }
      };
    }
    case SET_EMPLYEE_DATA: {
      const { Data, DataCount, NextUri, Type } = payload;
      const newData = [...state.Data, ...Data];
      return {
        ...state,
        [Type]: {
          NextUri,
          Data: newData,
          DataCount: DataCount
        }
      };
    }
    default:
      return state;
  }
};

export default payrollTypeData;
