import React from "react";
import "./Usericon.css";
import StringMask from "string-mask";
import PropTypes from "prop-types";
const placeholderPic =
  "https://cdn3.iconfinder.com/data/icons/rcons-user-action/32/boy-512.png";

const UserIcon = ({
  FirstName,
  LastName,
  BusinessUnitCode,
  DepartmentCode,
  DivisionCode,
  EmployeeCode,
  EmployeeProfileUrl,
  onClickEmployee,
  showSSN,
  SsnNumber
}) => {
  let formatter = new StringMask("000-000-0000");
  let formattedSSN = formatter.apply(SsnNumber);
  return (
    <div className="user-icon">
      <div className="user-pic">
        <img src={EmployeeProfileUrl} alt={FirstName} width="40" height="40" />
      </div>
      <div className="user-info">
        <div className="user-name" onClick={onClickEmployee}>
          {LastName + ", " + FirstName}
        </div>
        <div className="user-description">
          <span>{EmployeeCode}</span>
          {showSSN && <span>{formattedSSN}</span>}
        </div>
      </div>
    </div>
  );
};

export default UserIcon;

UserIcon.propTypes = {
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  DivisonCode: PropTypes.number,
  BusinessUnitCode: PropTypes.number,
  DepartmentCode: PropTypes.number,
  EmployeeCode: PropTypes.string,
  EmployeeProfileUrl: PropTypes.node,
  onClickEmployee: PropTypes.func,
  showSSN: PropTypes.bool
};
UserIcon.defraultProps = {
  FirstName: "Employee Name",
  LastName: "Employee Last Name",
  DivisonCode: 0,
  BusinessUnitCode: 0,
  DepartmentCode: 0,
  username: "",
  EmployeeProfileUrl: placeholderPic,
  onClickEmployee: () => {},
  showSSN: true
};
