import React, { Component } from "react";
import EntityListBanner from "../../entity-list/EntityListBanner";
import "./AddPayrollSchedule.css";
import PopUpModal from "./PopUpModal";
import {
  description,
  workingHours,
  maxWorkingHours,
  WEEKLY,
  BIWEEKLY,
  SEMIMONTHLY,
  MONTHLY,
  CURRENT_MONTH,
  QUARTERLY,
  DAILY,
  PREVIOUS_MONTH,
  FREQUENCY,
  PAYROLL_BANNER_NAME,
  DESCRIPTION,
  PAY_PERIOD,
  PAYROLL_CALENDER,
  TOTAL_HOURS,
  BUSINESS_DAYS,
  EDIT_PAYROLL_BANNER_NAME,
  ANNUALLY
  //QUARTERLY
} from "./Constants";
import WeeklyPayPeriod from "./WeeklyPayPeriod";
import SemiMonthlyPayPeriod from "./SemiMonthlyPayPeriod";
import PayrollCalendarFormSection from "./PayrollCalendarFormSection/PayrollCalendarFormSection";
import PreviewPayrollCalendar from "./PreviewPayrollCalendar";
import PayrollScheduleButtons from "./PayrollScheduleButtons";
import DirectDeposit from "./DirectDeposit";
import PayrollDescriptionSection from "./PayrollDescriptionSection";
import { FormSection } from "../../formSections/index";
import { setAndFetchEditPayrollScheduleData } from "./utils";
import DailyPayPeriod from "./DailyPayPeriod";
import _ from "underscore";

class AddPayrollSchedule extends Component {
  state = {
    PayrollScheduleName: "",
    Frequency: WEEKLY,
    TotalHours: workingHours[WEEKLY],
    showAll: false,
    BusinessDays: 0,
    PayPeriodEnd: 0,
    firstSwitch: PREVIOUS_MONTH,
    secondSwitch: CURRENT_MONTH,
    showValidationMsg: false,
    showLastDateValidation: false,
    edit: this.props.match && !!this.props.match.params.id,
    id: this.props.match && this.props.match.params.id,
    disableButton: true,
    billingTypeValidation: false,
    billingTypeId: 0,
    show: false,
    calendarPreviewData: [],
    line1: "",
    line2: "",
    buttonLable: "",
    scheduleDays: []
  };

  componentDidMount() {
    const { getAndSetPayrollScheduleById } = this.props;
    const id = this.props.match && this.props.match.params.id;
    this.props.validationForBillingType();
    if (id !== undefined) {
      getAndSetPayrollScheduleById && getAndSetPayrollScheduleById(id);
    }
  }
  static getDerivedStateFromProps(nextProps, prevProps) {
    const data = nextProps.options.payrollScheduleData;

    if (nextProps.options.payrollScheduleData) {
      const setPayrollScheduleData = setAndFetchEditPayrollScheduleData(data);

      return {
        ...setPayrollScheduleData,
        billingTypeId: nextProps.options.BillingData
      };
    }
    return null;
  }

  getDerivedData;
  handleValidation(val) {
    this.setState({
      showValidationMsg: val
    });
  }

  workingHoursValidation = (name, value) => {
    const { Frequency } = this.state;
    if (name === TOTAL_HOURS && !isNaN(value)) {
      return value <= maxWorkingHours[Frequency];
    }
    return !isNaN(value);
  };

  handleChange = e => {
    var name = e.name || e.target.name;
    var value = e.value === undefined ? e.target.value : e.value;
    if (this.workingHoursValidation(name, value)) {
      let dec = value.toString().indexOf(".") === -1;
      if (dec) {
        this.setState({
          [name]: value,
          disableButton: false
        });
      } else if (this.checkDecimal(value)) {
        this.setState({
          [name]: value,
          disableButton: false
        });
      }
    }
  };

  onMultiChange = e => {
    const value =
      e.value === undefined
        ? parseInt(e.target.value, 10)
        : parseInt(e.value, 10);
    const { scheduleDays } = this.state;
    let arr = scheduleDays;
    if (_.contains(arr, value)) {
      arr = _.without(scheduleDays, value);
    } else {
      scheduleDays.push(value);
    }
    this.setState({
      scheduleDays: arr,
      disableButton: false
    });
  };

  handleRadioChange = e => {
    var name = e.name || e.target.name;
    var value = e.value === undefined ? e.target.value : e.value;
    if (name === FREQUENCY) {
      this.setState({
        [name]: value,
        TotalHours: workingHours[value],
        disableButton: false
      });
    } else if (name === BUSINESS_DAYS) {
      if (parseInt(value, 10) - 1 >= this.state.billingTypeId) {
        this.setState({
          [name]: value,
          disableButton: false,
          billingTypeValidation: false
        });
      } else {
        this.setState({
          [name]: value,
          disableButton: true,
          billingTypeValidation: true
        });
      }
    } else {
      this.setState({
        [name]: value,
        disableButton: false
      });
    }
  };

  handleChangeMonth = (name, value) => {
    if (value > 28) {
      this.setState({
        [name]: value,
        showLastDateValidation: true,
        disableButton: false
      });
    } else {
      this.setState({
        [name]: value,
        disableButton: false,
        showLastDateValidation: false
      });
    }
  };

  renderPayPeriod = () => {
    const frequency = this.state.Frequency;
    switch (frequency) {
      case SEMIMONTHLY:
        return (
          <SemiMonthlyPayPeriod
            semimonthly
            data={this.state}
            handleChange={this.handleChange}
            handleRadioChange={this.handleRadioChange}
            handleChangeMonth={this.handleChangeMonth}
            onClose={() => this.handleValidation(false)}
          />
        );
      case MONTHLY:
        return (
          <SemiMonthlyPayPeriod
            monthly
            data={this.state}
            handleChange={this.handleChange}
            handleRadioChange={this.handleRadioChange}
            handleChangeMonth={this.handleChangeMonth}
            onClose={() => this.handleValidation(false)}
          />
        );
      case ANNUALLY:
        return (
          <SemiMonthlyPayPeriod
            annually
            data={this.state}
            handleChange={this.handleChange}
            handleRadioChange={this.handleRadioChange}
            handleChangeMonth={this.handleChangeMonth}
            onClose={() => this.handleValidation(false)}
          />
        );
      case QUARTERLY:
        return (
          <SemiMonthlyPayPeriod
            quartely
            data={this.state}
            handleChange={this.handleChange}
            handleRadioChange={this.handleRadioChange}
            handleChangeMonth={this.handleChangeMonth}
            onClose={() => this.handleValidation(false)}
          />
        );
      case DAILY:
        return (
          <DailyPayPeriod
            annually
            data={this.state}
            handleChange={this.handleChange}
            handleRadioChange={this.handleRadioChange}
          />
        );
      case BIWEEKLY:
      case WEEKLY:
      default:
        return (
          <WeeklyPayPeriod
            options={this.props.options}
            data={this.state}
            handleChange={this.handleChange}
            handleRadioChange={this.handleRadioChange}
          />
        );
    }
  };

  checkDecimal = val => {
    let points = val.toString().split(".");
    if (points[1].length < 5) {
      return true;
    }
    return false;
  };

  handlePreviewPayroll() {
    const { firstPayCheckDate, secondPayCheckDate, Frequency } = this.state;
    if (Frequency === SEMIMONTHLY && secondPayCheckDate < firstPayCheckDate) {
      this.handleValidation(true);
    } else {
      this.props.getPayrollCalendarData(this.state).then(res => {
        let PayrollCalenderList = [];
        let calendarPreviewData = [];
        let calendarObject = {
          PayrollCalenderQuarter: "",
          PayrollCalenderYear: "",
          ScheduledPayrollCalenderList: []
        };

        var helper = {};
        res.data.Data.reduce(function(keyList, obj) {
          var key = obj.PayrollCalenderQuarter + "-" + obj.PayrollCalenderYear;

          if (!helper[key]) {
            if (keyList.length !== 0) {
              calendarPreviewData.push(calendarObject);
            }
            PayrollCalenderList = [];

            helper[key] = Object.assign({}, obj); // create a copy of o
            keyList.push(helper[key]);

            calendarObject = {
              PayrollCalenderQuarter: obj.PayrollCalenderQuarter,
              PayrollCalenderYear: obj.PayrollCalenderYear,
              ScheduledPayrollCalenderList: []
            };

            PayrollCalenderList.push(helper[key]);
            calendarObject.ScheduledPayrollCalenderList = PayrollCalenderList;
          } else {
            PayrollCalenderList.push(Object.assign({}, obj));
            calendarObject.ScheduledPayrollCalenderList = PayrollCalenderList;
          }

          return keyList;
        }, []);

        calendarPreviewData.push(calendarObject);
        this.setState(
          {
            isEditMode: true,
            calendarPreviewData: calendarPreviewData
          },
          () => this.handleValidation(false)
        );
      });
    }
  }

  cancelLinkClick = () => {
    if (this.state.isFormUpdated) {
      this.setState({
        show: true,
        line1:
          "Are you sure you want to leave this page in the middle of the making edits?",
        line2: "Changes made on this page will not be saved.",
        buttonLable: "LEAVE THIS PAGE"
      });
    } else {
      this.setState({
        show: true,
        line1:
          "Are you sure you want to leave this page in the middle of the making edits?",
        line2: "Changes made on this page will not be saved.",
        buttonLable: "LEAVE THIS PAGE"
      });
    }
  };
  handleShow = () => {
    this.setState({
      showAll: !this.state.showAll
    });
  };
  render() {
    const { show, edit, calendarPreviewData } = this.state;
    const {
      addNewPayrollSchedule = () => {},
      updatePayrollSchedule = () => {},
      cancelPayrollSchedule = () => {},
      options
    } = this.props;
    const frequency = this.state.Frequency;
    const validation =
      frequency !== DAILY && frequency !== WEEKLY && frequency !== BIWEEKLY;
    return (
      <div className="nc-entity-page-container">
        <EntityListBanner
          bannerName={edit ? EDIT_PAYROLL_BANNER_NAME : PAYROLL_BANNER_NAME}
          description={description}
          feature="payrollSchedule"
        />
        <PopUpModal
          show={show}
          line1={this.state.line1}
          line2={this.state.line2}
          primaryButtonLabel={this.state.buttonLable}
          buttonType="secondary"
          closeModal={() => {
            this.setState({
              show: false
            });
          }}
          onPrimaryActionHandler={() => {
            this.state.buttonLable === "LEAVE THIS PAGE"
              ? this.props.history.push("/PayrollSchedules")
              : edit
                ? updatePayrollSchedule(this.state)
                : addNewPayrollSchedule(this.state);
          }}
          onCancel={() => {
            this.setState(
              {
                show: false
              },
              () => cancelPayrollSchedule()
            );
          }}
        />
        <FormSection title={DESCRIPTION}>
          <PayrollDescriptionSection
            handleChange={this.handleChange}
            handleRadioChange={this.handleRadioChange}
            handleShow={this.handleShow}
            onMultiChange={this.onMultiChange}
            data={this.state}
            {...this.props}
          />
        </FormSection>
        <FormSection title={PAY_PERIOD} hr>
          {this.renderPayPeriod()}
        </FormSection>
        <div className="seperator">
          <hr />
        </div>
        <div>
          <DirectDeposit
            options={options}
            monthly={validation}
            data={this.state}
            handleRadioChange={this.handleRadioChange}
          />
        </div>
        <FormSection title={PAYROLL_CALENDER} hr>
          <PayrollCalendarFormSection
            options={options}
            handleRadioChange={this.handleRadioChange}
            data={this.state}
          />
        </FormSection>
        <div>
          <PreviewPayrollCalendar
            {...this.props}
            isEditMode={this.state.isEditMode}
            previewPayroll={() => this.handlePreviewPayroll()}
            onHide={() =>
              this.setState({
                isEditMode: false
              })
            }
            calendarPreviewData={calendarPreviewData}
          />
        </div>
        <div className="seperator">
          <hr />
        </div>
        <PayrollScheduleButtons
          isEditMode={edit}
          cancel={this.cancelLinkClick}
          createPayrollSchedule={() => addNewPayrollSchedule(this.state)}
          updatePayrollSchedule={() => updatePayrollSchedule(this.state)}
          disabled={this.state.disableButton}
        />
      </div>
    );
  }
}
export default AddPayrollSchedule;
