/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import axios from "axios";
import Toaster from "app/common/UIComponents/Toaster";
import * as common from "app/common/index";
import { withRouter } from "react-router-dom";
import { setLoader } from "app/actions/LoaderActions";
import { showToaster } from "app/actions/AppActions";
import { convertUserTimeZone } from "app/navigation/utilities";
import {
  getThirdPartyPayeeSetupList,
  updatedFilteredList,
  setThirdPartyPayeeData,
  clearThirdPartyPayeeSetupList,
  getFilteredThirdPartyPayeeSetupList,
  setSelectedFilters
} from "../../actions/systemlevel/thirdpartypayee/ThirdPartyPayeeSetupListingActions";
import {
  getThirdPartyPayeeList,
  getCategoryList,
  getStatusList
} from "../../actions/systemlevel/thirdpartypayee/AdvanceFilterActions";
import {
  getToasterMessage,
  getButtonTypeForToasterMessage
} from "../../actions/systemlevel/thirdpartypayee/ToasterMessageActions";

import SystemLevelThirdPartyPayeeAdvanceFilter from "./components/SystemLevelThirdPartyPayeeAdvanceFilter";
import SystemThirdPartyPayeeSetupDescription from "./SystemThirdPartyPayeeSetupDescription";
import EntitySnapshotTable from "component-library/entity-snapshot/EntitySnapshotTable";
import EntitySnapshotHeader from "component-library/entity-snapshot/EntitySnapshotHeader";
import {
  getReviewandSaveDataList,
  clearReviewandSaveDataList
} from "../../actions/systemlevel/thirdpartypayee/ReviewandSaveActions";
import NoEntityToSelect from "component-library/entity-list/NoEntityToSelect";
import * as utilities from "app/common/utilities";
import * as Id from "app/common/CommonIds";
import Media from "react-media";

const DeleteButton = "Delete";
const InactivateButton = "Inctivate";

const UICOMPLETIONSTAGE_ACTIVE = 2;
const UICOMPLETIONSTAGE_INACTIVE = 3;

const tooltip_thrash_icon = (
  <Tooltip id={Id.deleteTooltip}>Delete/Inactivate</Tooltip>
);
const tooltip_edit_icon = <Tooltip id={Id.editTooltip}>Edit Payee</Tooltip>;

const tooltip_active_status_column = (
  <Tooltip id={Id.activeStatusTooltip}>
    Third-party payee can be assigned to a deduction.
  </Tooltip>
);

const tooltip_inactive_status_column = (
  <Tooltip id={Id.inactiveStatusTooltip}>
    Third-party payee has been previously assigned to a deduction but is
    currently not active. Third-party payee cannot be assigned to a deduction
    unless made active.
  </Tooltip>
);

class SystemThirdPartyPayeeListingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Id: 0,
      isTableSorted: undefined,
      sortedKey: "",
      opendeletemodal: false,
      isThirdPartyPayeeUsedBycompany: true,
      openInactivateModal: false,
      statusDelete: false,
      statusInactivation: false,
      tableValuesCount: "",
      selectedCategories: [],
      selectedStatus: [],
      selectedThirdPartyPayees: [],
      selectedThirdPartyPayeeName: false,
      selectedThirdPartyPayeeDeleteStatusName: false,
      selectedThirdPartyPayeeInactivateSuccessName: false,
      selectedThirdPartyPayeeId: 0,
      showToaster: false,
      hideProgressBar: false,
      alertClassName: "",
      isInitialLoad: false
    };

    this.close = this.close.bind(this);
    this.showSortedIcon = this.showSortedIcon.bind(this);
    this.renderTableByCategory = this.renderTableByCategory.bind(this);
    this.openmaindeletionmodal = this.openmaindeletionmodal.bind(this); //modal main
    this.closemaindeletionmodal = this.closemaindeletionmodal.bind(this); //modal main
    this.onOpenInactivateModal = this.onOpenInactivateModal.bind(this); //modal inactivate
    this.onCloseInactivateModal = this.onCloseInactivateModal.bind(this); //modal inactivate
    this.openStatusDelete = this.openStatusDelete.bind(this); //modal delete status
    this.closeStatusDelete = this.closeStatusDelete.bind(this); //modal delete status
    this.openStatusInactivate = this.openStatusInactivate.bind(this); //modal inactivate status
    this.closeStatusInactivate = this.closeStatusInactivate.bind(this); //modal inactivate status
    this.setCategories = this.setCategories.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.setThirdPartyPayees = this.setThirdPartyPayees.bind(this);
    this.deleteThirdPartyPayee = this.deleteThirdPartyPayee.bind(this);
    this.reactivateThirdPartyPayee = this.reactivateThirdPartyPayee.bind(this);
    this.redirectToEditPage = this.redirectToEditPage.bind(this);
    this.showToasterMessage = this.showToasterMessage.bind(this);
    this.openSnapShotSummary = this.openSnapShotSummary.bind(this);
  }

  componentWillMount() {
    if (this.props.toasterData) {
      if (this.props.toasterData.show) {
        this.showToasterMessage();
      }
    }

    if (this.props.systemThirdPartyPayeeData.ThirdPartyPayeeId > 0) {
      this.props.setThirdPartyPayeeData("");
    }
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.props.setLoader(true);
    this.props.getThirdPartyPayeeSetupList();
    this.props.getThirdPartyPayeeList();
    this.props.getCategoryList();
    this.props.getStatusList();
    if (this.props.thirdPartyPayeeMainList) {
      this.props.updatedFilteredList(this.props.thirdPartyPayeeMainList);
    }

    setTimeout(
      function () {
        this.props.setLoader(false);
      }.bind(this),
      2000
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.toasterData !== prevProps.toasterData) {
      if (this.props.toasterData.show === false) {
        this.setState({
          showToaster: false
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.showToaster(false, "");
    this.setState({
      showToaster: false
    });
    this.props.clearThirdPartyPayeeSetupList();
  }

  showToasterMessage() {
    this.setState({
      showToaster: true
    });
  }

  setInitialState(isInitialLoad) {
    this.setState({
      isInitialLoad
    });
  }

  setActiveSortOrder = activeSortOrder => this.setState({ activeSortOrder });
  closeToasterMessage = () => {
    this.setState({ showToaster: false });
    this.props.showToaster(false, "");
  };

  redirectToEditPage(thirdPartyPayeeItem) {
    let self = this;
    let Id = window.btoa(thirdPartyPayeeItem.Id);
    self.props.history.push(`/system-third-party-payee-add-page/${Id}`);
  }

  setCategories(selectedCategories) {
    this.setState({
      selectedCategories
    });
  }

  setStatus(selectedStatus) {
    this.setState({
      selectedStatus
    });
  }

  setThirdPartyPayees(selectedThirdPartyPayees) {
    this.setState({
      selectedThirdPartyPayees
    });
  }

  close() {
    this.setState({
      opendeletemodal: false,
      openInactivateModal: false,
      statusDelete: false,
      statusInactivation: false
    });
  }

  openmaindeletionmodal(thirdPartyPayeeItem) {
    let thirdPartyPayeeInUse = false;
    if (thirdPartyPayeeItem.UseCount > 0) {
      thirdPartyPayeeInUse = true;
      this.setState({
        isThirdPartyPayeeUsedBycompany: thirdPartyPayeeInUse,
        selectedThirdPartyPayeeName: thirdPartyPayeeItem.Name,
        selectedThirdPartyPayeeId: thirdPartyPayeeItem.Id,
        openInactivateModal: true,
        opendeletemodal: false
      });
    } else {
      this.setState({
        opendeletemodal: true,
        selectedThirdPartyPayeeName: thirdPartyPayeeItem.Name,
        selectedThirdPartyPayeeId: thirdPartyPayeeItem.Id
      });
    }
  }

  closemaindeletionmodal() {
    this.setState({
      opendeletemodal: false
    });
  }

  onOpenInactivateModal() {
    this.setState({
      openInactivateModal: true,
      opendeletemodal: false
    });
  }

  onCloseInactivateModal() {
    this.setState({
      openInactivateModal: false
    });
  }

  openStatusDelete(thirdPartyPayeeDeleteStatusItem) {
    this.setState({
      statusDelete: true,
      opendeletemodal: false,
      selectedThirdPartyPayeeDeleteStatusName:
        thirdPartyPayeeDeleteStatusItem.Name
    });
  }

  closeStatusDelete() {
    this.setState({
      statusDelete: false
    });
  }

  openStatusInactivate(thirdPartyPayeeInactivationStatusItem) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let item = {
      actionType: InactivateButton,
      toasterState: true
    };

    let self = this;
    self.setState({
      opendeletemodal: false,
      openInactivateModal: false
    });
    self.props.setLoader(true);
    let data = { Id: self.state.selectedThirdPartyPayeeId };
    const url = `${
      common.WEB_API_URL
      }/v1/system/thirdPartyPayee/action/makeinactive`;

    axios
      .post(url, data)
      .then(function (response) {
        self.props.setLoader(false);
        self.setState({
          opendeletemodal: false,
          openInactivateModal: false
        });

        self.props.getButtonTypeForToasterMessage(item);
        self.props.showToaster(
          true,
          `'${self.state.selectedThirdPartyPayeeName}' has been inactivated.`,
          "inactivate"
        );

        self.showToasterMessage();
        self.props.clearThirdPartyPayeeSetupList();
        self.props.getThirdPartyPayeeSetupList();
        self.props.getThirdPartyPayeeList();
        self.props.setLoader(false);
      })
      .catch(function (error) { });
  }

  closeStatusInactivate() {
    this.setState({
      statusInactivation: false
    });
  }

  reactivateThirdPartyPayee(thirdPartyPayeeItem) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let self = this;
    let data = { Id: thirdPartyPayeeItem.Id };
    const url = `${
      common.WEB_API_URL
      }/v1/system/thirdPartyPayee/action/makeactive`;
    axios
      .post(url, data)
      .then(function (response) {
        if (response.data.ErrorCount > 0) {
          response.data.Errors.forEach(item => {
            if (item.Tag === "SAVE_FAILED") {
              alert(item.MessageList[1]);
            }
          });
        } else {
          self.props.setLoader(false);
          self.setState({
            opendeletemodal: false,
            openInactivateModal: false
          });
          self.props.showToaster(false, "");
          self.props.showToaster(
            true,
            `'${thirdPartyPayeeItem.Name}' has been reactivated.`,
            "success"
          );

          self.showToasterMessage();
          self.props.clearThirdPartyPayeeSetupList();
          self.props.getThirdPartyPayeeSetupList();
          self.props.getThirdPartyPayeeList();
          self.props.setLoader(false);
        }
      })
      .catch(function (error) { });
  }

  deleteThirdPartyPayee() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let item = {
      actionType: DeleteButton,
      toasterState: true
    };

    let self = this;
    self.setState({
      opendeletemodal: false
    });
    self.props.setLoader(true);

    let thirdPartyPayeeInUse = false;
    const url = `${common.WEB_API_URL}/v1/system/thirdPartyPayee/${
      this.state.selectedThirdPartyPayeeId
      }`;
    axios
      .delete(url)
      .then(function (response) {
        if (response.data.ErrorCount > 0) {
          response.data.Errors.forEach(item => {
            if (item.Tag === "DEL_INUSE") {
              thirdPartyPayeeInUse = true;
              self.setState({
                isThirdPartyPayeeUsedBycompany: thirdPartyPayeeInUse,
                openInactivateModal: true,
                opendeletemodal: false
              });
            }
          });
          self.props.setLoader(false);
        } else {
          self.setState({
            opendeletemodal: false,
            isInitialLoad: true
          });

          self.props.getButtonTypeForToasterMessage(item);
          self.props.showToaster(false, "");
          self.props.showToaster(
            true,
            `'${self.state.selectedThirdPartyPayeeName}' has been deleted.`,
            "delete"
          );

          self.showToasterMessage();
          self.props.clearThirdPartyPayeeSetupList();
          self.props.getThirdPartyPayeeSetupList();
          self.props.getThirdPartyPayeeList();
          self.props.setLoader(false);
        }
      })
      .catch(function (error) { });
  }

  sortColumnByKey(key) {
    if (this.props.thirdPartyPayeeFilteredList.Data) {
      this.props.thirdPartyPayeeFilteredList.Data = utilities.sortByKeyString(
        this.props.thirdPartyPayeeFilteredList.Data,
        key
      );
      this.setState({
        sortedKey: key
      });

      if (this.state.isTableSorted) {
        this.setState({
          isTableSorted: false
        });
        this.props.thirdPartyPayeeFilteredList.Data = this.props.thirdPartyPayeeFilteredList.Data.reverse();
      }

      if (this.state.isTableSorted === undefined) {
        this.setState({
          isTableSorted: true
        });
      }

      if (this.state.isTableSorted === false) {
        this.setState({
          isTableSorted: true
        });
      }
    }
  }

  showSortedIcon() {
    if (this.state.isTableSorted) {
      return <i className="fa fa-sort-asc" aria-hidden="true" />;
    } else if (this.state.isTableSorted === false) {
      return <i className="fa fa-sort-desc" aria-hidden="true" />;
    } else {
      return <i className="fa fa-sort" aria-hidden="true" />;
    }
  }

  isMobileReactivate(list) {
    if (list.CreateWorkflowStateId === UICOMPLETIONSTAGE_INACTIVE) {
      return (
        <div className="col-xs-12 col-sm-12 reactivate-mobile-button hidden-sm hidden-md hidden-lg">
          <button
            onClick={this.reactivateThirdPartyPayee.bind(this, list)}
            id={Id.reactiveItemButton + list.Id}
            type="button"
            className="special-outline-button"
          >
            REACTIVATE
          </button>
        </div>
      );
    }
  }

  renderTableByCategory() {
    if (!this.props.thirdPartyPayeeFilteredList.Data) {
      return <div />;
    }

    return (
      <div className="">
        <Media query="(min-width:992px)">
          {matches =>
            matches && (
              <div className="col-lg-9 col-md-9 row table-header visible-lg visible-md">
                <div
                  onClick={this.sortColumnByKey.bind(this, "Name")}
                  id={Id.nameColumnHeader}
                  className="col-lg-5 col-md-5 column-header"
                >
                  NAME{" "}
                  {this.state.sortedKey === "Name" ? (
                    this.showSortedIcon()
                  ) : (
                      <i className="fa fa-sort" aria-hidden="true" />
                    )}
                </div>
                <div
                  onClick={this.sortColumnByKey.bind(
                    this,
                    "ThirdPartyPayeeCategoryName"
                  )}
                  id={Id.categoryColumnHeader}
                  className="col-lg-5 col-md-5 column-header"
                >
                  CATEGORY{" "}
                  {this.state.sortedKey === "ThirdPartyPayeeCategoryName" ? (
                    this.showSortedIcon()
                  ) : (
                      <i className="fa fa-sort" aria-hidden="true" />
                    )}
                </div>
                <div
                  onClick={this.sortColumnByKey.bind(
                    this,
                    "CreateWorkflowStateName"
                  )}
                  id={Id.statusColumnHeader}
                  className="col-lg-2 col-md-2 column-header"
                >
                  STATUS{" "}
                  {this.state.sortedKey === "CreateWorkflowStateName" ? (
                    this.showSortedIcon()
                  ) : (
                      <i className="fa fa-sort" aria-hidden="true" />
                    )}
                </div>
              </div>
            )
          }
        </Media>
        {this.renderTableContentRow()}
      </div>
    );
  }

  renderTableContentRow() {
    let isDesktop = false;

    if (window.innerWidth >= 320 && window.innerWidth <= 767) {
      isDesktop = false;
    } else if (window.innerWidth >= 992) {
      isDesktop = true;
    }

    if (this.props.thirdPartyPayeeFilteredList.Data) {
      return this.props.thirdPartyPayeeFilteredList.Data.map((item, index) => {
        return (
          <div
            key={index}
            className="table-row col-lg-12 col-md-12 col-sm-12 col-xs-12"
          >
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
              <div
                onClick={this.openSnapShotSummary.bind(this, item)}
                id={Id.nameColumnItem + item.Id}
                className="col-lg-5 col-md-5 col-sm-12 col-xs-12 table-cell name-column"
              >
                {item.Name}
              </div>
              <div className="category-container col-lg-5 col-md-5 col-sm-12 col-xs-12 table-cell">
                <span className="hidden-lg hidden-md field-header">
                  Category : {"  "}
                </span>
                <span
                  id={Id.categoryColumnItem + item.Id}
                  className="field-value"
                >
                  {item.SystemThirdPartyPayeeCategoryName}
                </span>
              </div>
              <div className="status-container col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <span className="hidden-lg hidden-md field-header">
                  Status :{" "}
                </span>
                {this.showStatusDescription(item)}
              </div>
              <div>{isDesktop ? null : this.isMobileReactivate(item)}</div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-3 col-xs-3 list-page-grid-table-cell-actions">
              <div>
                {isDesktop
                  ? this.showIconOrReactivate(item)
                  : this.showIconOrReactivateWithoutTooltips(item)}
              </div>

              {/* <div>
                {this.state.isThirdPartyPayeeUsedBycompany
                  ? this.onOpenInactivateModal
                  : this.openStatusDelete.bind(this, item)}
              </div> */}
            </div>
          </div>
        );
      });
    }
  }

  showStatusDescription(list) {
    let isDesktop = false;

    if (window.innerWidth >= 320 && window.innerWidth <= 767) {
      isDesktop = false;
    } else if (window.innerWidth >= 992) {
      isDesktop = true;
    }

    if (list.CreateWorkflowStateId === UICOMPLETIONSTAGE_ACTIVE) {
      if (isDesktop) {
        return (
          <OverlayTrigger
            placement="top"
            overlay={tooltip_active_status_column}
          >
            <span
              id={Id.statusColumnItem + list.Id}
              className="field-value status-tag active"
            >
              {list.CreateWorkflowStateName}
            </span>
          </OverlayTrigger>
        );
      } else {
        return (
          <span
            id={Id.statusColumnItem + list.Id}
            className="field-value status-tag active"
          >
            {list.CreateWorkflowStateName}
          </span>
        );
      }
    } else if (list.CreateWorkflowStateId === UICOMPLETIONSTAGE_INACTIVE) {
      if (isDesktop) {
        return (
          <OverlayTrigger
            placement="top"
            overlay={tooltip_inactive_status_column}
          >
            <span
              id={Id.statusColumnItem + list.Id}
              className="field-value status-tag inactive"
            >
              {list.CreateWorkflowStateName}
            </span>
          </OverlayTrigger>
        );
      } else {
        return (
          <span
            id={Id.statusColumnItem + list.Id}
            className="field-value status-tag inactive"
          >
            {list.CreateWorkflowStateName}
          </span>
        );
      }
    }
  }

  showIconOrReactivate(list) {
    if (list.CreateWorkflowStateId === UICOMPLETIONSTAGE_ACTIVE) {
      return (
        <div>
          <div className="list-page-delete-section">
            <OverlayTrigger placement="top" overlay={tooltip_thrash_icon}>
              <a onClick={this.openmaindeletionmodal.bind(this, list)}>
                <i
                  id={Id.deleteItemIcon + list.Id}
                  className="list-page-delete-icon fa fa-trash-o"
                />
              </a>
            </OverlayTrigger>
          </div>
          <div className="list-page-edit-section">
            <OverlayTrigger placement="top" overlay={tooltip_edit_icon}>
              <a onClick={this.redirectToEditPage.bind(this, list)}>
                <i
                  id={Id.editItemIcon + list.Id}
                  className="list-page-edit-icon fa fa-pencil"
                />
              </a>
            </OverlayTrigger>
          </div>
        </div>
      );
    } else if (list.CreateWorkflowStateId === UICOMPLETIONSTAGE_INACTIVE) {
      return (
        <div className="hidden-xs">
          <button
            onClick={this.reactivateThirdPartyPayee.bind(this, list)}
            id={Id.reactiveItemButton + list.Id}
            type="button"
            className="special-outline-button hidden-xs pull-right"
          >
            REACTIVATE
          </button>
        </div>
      );
    }
  }

  showIconOrReactivateWithoutTooltips(list) {
    if (list.CreateWorkflowStateId === UICOMPLETIONSTAGE_ACTIVE) {
      return (
        <div>
          <div className="list-page-delete-section">
            <a onClick={this.openmaindeletionmodal.bind(this, list)}>
              <i
                id={Id.deleteItemIcon + list.Id}
                className="list-page-delete-icon fa fa-trash-o"
              />
            </a>
          </div>
          <div className="list-page-edit-section">
            <a onClick={this.redirectToEditPage.bind(this, list)}>
              <i
                id={Id.editItemIcon + list.Id}
                className="list-page-edit-icon fa fa-pencil"
              />
            </a>
          </div>
        </div>
      );
    } else if (list.CreateWorkflowStateId === UICOMPLETIONSTAGE_INACTIVE) {
      return (
        <div className="hidden-xs">
          <button
            onClick={this.reactivateThirdPartyPayee.bind(this, list)}
            id={`list-page-reactivate-` + list.Id}
            type="button"
            className="special-outline-button hidden-xs pull-right"
          >
            REACTIVATE
          </button>
        </div>
      );
    }
  }

  openSnapShotSummary(item) {
    this.props.getReviewandSaveDataList(item.ActivityInstanceId);

    this.setState({
      snapshotSummaryModal: true
    });
  }

  closeSnapShotSummary = () => {
    this.setState({
      snapshotSummaryModal: false
    });
    this.props.clearReviewandSaveDataList();
  };

  render() {
    let reviewandSaveData = this.props.reviewandSaveData;
    let nodes = [];
    let data = [];
    if (
      reviewandSaveData.Data === undefined ||
      reviewandSaveData.Data.length === 0
    ) {
      nodes = [];
      data = [];
    } else {
      nodes = reviewandSaveData.Data[0].CurrentValueSnapshot
        ? reviewandSaveData.Data[0].CurrentValueSnapshot
        : [];
      data = reviewandSaveData.Data[0];
    }
    return (
      <div className="nc-list-page-container">
        <SystemThirdPartyPayeeSetupDescription isListPage={true} />

        {this.props.toasterData.show ? (
          <div className="toaster-container">
            <Toaster
              id={Id.listPageToaster}
              show={this.props.toasterData.show}
              message={this.props.toasterData.message}
              closeToasterMessage={this.closeToasterMessage}
              alertClassName={this.props.toasterData.alertClassName}
            />
          </div>
        ) : null}

        {this.props.thirdPartyPayeeMainList.DataCount === 0 ? (
          <NoEntityToSelect
            label="Third-Party Payee Templates"
            id="THIRD_PARTY"
          />
        ) : (
            <div className="clearfix">
              <SystemLevelThirdPartyPayeeAdvanceFilter
                onSelectedThirdPartyPayees={selectedThirdPartyPayees =>
                  this.setThirdPartyPayees(selectedThirdPartyPayees)
                }
                onSelectedCategories={selectedCategories =>
                  this.setCategories(selectedCategories)
                }
                onSelectedStatus={selectedStatus =>
                  this.setStatus(selectedStatus)
                }
                isInitialLoad={this.state.isInitialLoad}
                setActiveSortOrder={this.setActiveSortOrder}
                activeSortOrder={this.state.activeSortOrder}
                setInitialState={isInitialLoad =>
                  this.setInitialState(isInitialLoad)
                }
              />

              <div className="list-page-grid-table col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="nc-rowcount-results">
                  {this.props.thirdPartyPayeeFilteredList.length === 0 ? (
                    <span>Loading...</span>
                  ) : this.props.thirdPartyPayeeFilteredList.DataCount === 1 ? (
                    <span id={Id.listCount}>{`We found ${
                      this.props.thirdPartyPayeeFilteredList.DataCount
                      } result.`}</span>
                  ) : (
                        <span id={Id.listCount}>{`We found ${
                          this.props.thirdPartyPayeeFilteredList.DataCount
                          } results.`}</span>
                      )}
                </div>

                {this.renderTableByCategory()}
              </div>
            </div>
          )}
        <Modal
          bsClass="list-page-cancel modal"
          animation={false}
          backdrop={"static"}
          keyboard={false}
          restoreFocus={false}
          show={this.state.opendeletemodal}
          onHide={this.closemaindeletionmodal}
          id="tpp-delete-modal"
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="delete-modal-close-icon"
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div id="delete-modal-massege" className="modal-row">
                Are you sure you want to delete your '
                {this.state.selectedThirdPartyPayeeName}' third-party payee
                template?
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                id="delete-modal-delete-button"
                onClick={this.deleteThirdPartyPayee}
                type="button"
                className="nc-button primary-button"
              >
                DELETE TEMPLATE
              </button>
              <button
                onClick={this.close}
                type="button"
                id="delete-modal-cancel-button"
                className="secondary-link-button"
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          id="deduction-inactivate-modal"
          bsClass="list-page-cancel modal"
          animation={false}
          backdrop={"static"}
          keyboard={false}
          restoreFocus={false}
          show={this.state.openInactivateModal}
          onHide={this.onCloseInactivateModal}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="inactivate-modal-close-icon"
                onClick={this.close}
                className="fa fa-lg fa-times-circle-o"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div id="inactivate-modal-massege" className="modal-row">
                This payee template has been used before. We will mark it as
                inactive to preserve payment history.
                <br />
                WARNING : Inactivating this payee template will automatically
                un-link all associated company payees. You can reactivate this
                payee template later, but changes in template will not propagate
                to the un-linked company payees.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                id="inactivate-modal-inactivate-button"
                onClick={this.openStatusInactivate}
                type="button"
                className="nc-button primary-button"
              >
                INACTIVATE TEMPLATE
              </button>
              <button
                onClick={this.close}
                type="button"
                id="inactivate-modal-cancel-button"
                className="secondary-link-button"
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          id="activity-history"
          animation={false}
          bsSize="large"
          bsClass="modal"
          show={this.state.snapshotSummaryModal}
          restoreFocus={true}
        >
          <EntitySnapshotHeader
            data={data}
            snapshotData={reviewandSaveData.Data}
            onHide={this.closeSnapShotSummary}
            userTimeZoneOffset={this.props.userTimeZoneOffset.data}
            userTimeZoneAbbreviation={this.props.userTimeZoneAbbreviation.data}
            convertUserTimeZone={convertUserTimeZone}
            entity='Third Party Payee'
          />
          <Modal.Body>
            <div className="nc-enity-snapshot-summary">
              <EntitySnapshotTable nodes={nodes} />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    thirdPartyPayeeMainList: state.thirdPartyPayeeMainList,
    thirdPartyPayeeFilteredList: state.thirdPartyPayeeFilteredList,
    systemThirdPartyPayeeData: state.systemThirdPartyPayeeData,
    toasterMessage: state.toasterMessage,
    buttonTypeForToasterMessage: state.buttonTypeForToasterMessage,
    countryStateList: state.countryStateList,
    toasterData: state.toasterData,
    reviewandSaveData: state.systemTppReviewandSaveReducer,
    userTimeZoneOffset: state.navigationReducer.userTimeZoneOffset,
    userTimeZoneAbbreviation: state.navigationReducer.userTimeZoneAbbreviation
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getThirdPartyPayeeSetupList: getThirdPartyPayeeSetupList,
      setThirdPartyPayeeData: setThirdPartyPayeeData,
      updatedFilteredList: updatedFilteredList,
      getToasterMessage: getToasterMessage,
      getButtonTypeForToasterMessage: getButtonTypeForToasterMessage,
      getThirdPartyPayeeList: getThirdPartyPayeeList,
      getCategoryList: getCategoryList,
      getStatusList: getStatusList,
      showToaster: showToaster,
      setLoader: setLoader,
      getReviewandSaveDataList: getReviewandSaveDataList,
      clearReviewandSaveDataList: clearReviewandSaveDataList,
      clearThirdPartyPayeeSetupList: clearThirdPartyPayeeSetupList,
      getFilteredThirdPartyPayeeSetupList,
      setSelectedFilters
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SystemThirdPartyPayeeListingPage)
);
