import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Badge } from "react-bootstrap";
import { Checkbox, CheckboxGroup } from "react-checkbox-group";
import TextInputWithoutLabel from "../../../../app/common/TextInputWithoutLabel";
import SelectInputDropdown from "../../../../app/common/SelectInputDropdown";
import { getCustomEarningsValuesList } from "../../actions/companylevel/CompanyLevelCalculationMethodAction";
import { withRouter } from "react-router-dom";

class CustomEarningGroup extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isFormUpdated: false,
      errors: {},

      selectedCustomEarningsGroup: [],

      customEarningsGroupList: [
        { Id: "paycode1", value: 1, Name: "Earnings Name" },
        { Id: "paycode2", value: 2, Name: "Earnings Name" },
        { Id: "paycode3", value: 3, Name: "Earnings Name" },
        { Id: "paycode4", value: 4, Name: "Earnings Name" },
        { Id: "paycode5", value: 5, Name: "Earnings Name" },
        { Id: "paycode6", value: 6, Name: "Earnings Name" },
        { Id: "paycode7", value: 7, Name: "Earnings Name" },
        { Id: "paycode8", value: 8, Name: "Earnings Name" },
        { Id: "paycode9", value: 9, Name: "Earnings Name" },
        { Id: "paycode10", value: 10, Name: "Earnings Name" },
        { Id: "paycode11", value: 11, Name: "Earnings Name" },
        { Id: "paycode12", value: 12, Name: "Earnings Name" },
        { Id: "paycode13", value: 13, Name: "Earnings Name" },
        { Id: "paycode14", value: 14, Name: "Earnings Name" },
        { Id: "paycode15", value: 15, Name: "Earnings Name" }
      ]
    };

    this.checkCustomEarningsGroup = this.checkCustomEarningsGroup.bind(this);

    this.renderCustomEarningsGroupList = this.renderCustomEarningsGroupList.bind(
      this
    );
    this.renderCustomEarningsGroupListItem = this.renderCustomEarningsGroupListItem.bind(
      this
    );

    this.redirectToCompanyLevelCalculationMethodPage = this.redirectToCompanyLevelCalculationMethodPage.bind(
      this
    );
  }
  componentDidMount() {
    this.props.getCustomEarningsValuesList();
  }

  redirectToCompanyLevelCalculationMethodPage() {
    this.props.onPopOverCustomEarningsGroupCancel();
  }

  renderCustomEarningsGroupList() {
    if (!this.props.companylevelcustomEarningsList) {
      return <div />;
    } else {
      return (
        <div id="company-level-deduction-deduction-checkbox-group">
          <CheckboxGroup
            name="select-category"
            value={this.state.selectedCustomEarningsGroup}
            onChange={this.checkCustomEarningsGroup}
          >
            {this.renderCustomEarningsGroupListItem(
              this.props.companylevelcustomEarningsList
            )}
          </CheckboxGroup>
        </div>
      );
    }
  }

  renderCustomEarningsGroupListItem(companylevelcustomEarningsList) {
    if (this.props.companylevelcustomEarningsList.Data !== undefined) {
      return this.props.companylevelcustomEarningsList.Data.map(item => {
        return (
          <div
            key={item.EarningGroupId}
            className="col-lg-4"
            style={{ paddingRight: "55px", paddingBottom: "10px" }}
          >
            <label id={item.EarningGroupId}>
              <Checkbox value={item.EarningGroupKey} /> {item.EarningGroupName}
            </label>
          </div>
        );
      });
    }
  }

  //     renderTaxOptionsItem(deductionTaxabilityList){
  //     return this.props.deductionTaxabilityList.Data.map((item) => {
  //         return(
  //             <div key={item.Id} className="col-lg-6">
  //                 <label className="company-level-deduction-description-checkbox-label" id={item.Value+"-tax-options"}><Checkbox value={item.Id}/> {item.Value}</label>
  //             </div>
  //         );
  //     });
  // }

  checkCustomEarningsGroup(newValue) {
    this.setState({
      selectedCustomEarningsGroup: newValue
    });
  }

  // checkCustomEarningsGroup(newCategory){
  //     if(newCategory.includes(ALL_CUSTOM_EARNING))
  //     {
  //         var indexOfAllCategory=newCategory.indexOf(ALL_CUSTOM_EARNING);
  //         if(indexOfAllCategory!==0 || this.state.selectedCustomEarningsGroup.length===0)
  //         {
  //             newCategory=[];
  //             for(var i=0; i<this.props.customEarningsGroupList.length; i++)
  //             {
  //                 var DeductionCategoryId = this.props.customEarningsGroupList[i].DeductionCategoryId;
  //                 newCategory.push(DeductionCategoryId);
  //             }
  //             this.setState({
  //                 selectedCustomEarningsGroup: newCategory
  //             });
  //         }
  //         else{
  //             newCategory.splice(0, 1);
  //             this.setState({
  //                 selectedCustomEarningsGroup: newCategory,
  //             });
  //         }
  //     }
  // }
  //     componentDidUpdate(prevProps, prevState) {
  //     if (prevState.selectedCustomEarningsGroup !== this.state.selectedCustomEarningsGroup) {
  //        // this.props.onSelectedCategories(this.state.selectedCustomEarningsGroup);
  //     }
  // }

  render() {
    return (
      <div className="page-container col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="cancel-body-modal-buttons-close-icon">
          <i
            id="delete-success-close-icon"
            onClick={this.redirectToCompanyLevelCalculationMethodPage}
            className="fa fa-times-circle-o"
          />
        </div>

        <div className="deduction-page-header">
          <div className="deduction-page-header-section">
            <div className="deduction-page-header col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h1>Create a custom grouping of earning.</h1>
            </div>
          </div>

          <div className="deduction-page-description-section">
            <div className="deduction-page-description-content col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <p>
                The deduction will be calculated based on the earnings chosen.
                If this is a percent deduction, then the wages associated with
                the earnings chosen will be multipled by the employee
                contribution percentage. If this is an hours-based deduction,
                then the hours associated with the earnings chosen will be
                multipled by the dollar amount assigned to the employee.
              </p>
            </div>
          </div>
        </div>

        {/*<div className="custom-earnings-group-model-heading col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    Create a custom grouping of earning.
                </div>
                <div className="custom-earnings-group-model-heading-subcontent col-xs-9 col-sm-9 col-md-9 col-lg-12">
                   The deduction will be calculated based on the earnings chosen. If this is a percent deduction, then the wages associated with the earnings chosen will be multipled by the employee contribution percentage. If this is an hours-based deduction, then the hours associated with the earnings chosen will be multipled by the dollar amount assigned to the employee.
                    
                </div>*/}

        <div id="deduction-name" className="deduction-page-section">
          <div className="entity-page-section-separater col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <hr />
          </div>

          <div className="deduction-page-section-label">
            Create a name for this new grouping
          </div>

          <div className="deduction-page-section-input">
            <div className="deduction-page-section-input-textbox col-xs-4 col-sm-4 col-md-4 col-lg-4">
              <TextInputWithoutLabel />
            </div>
          </div>
        </div>
        <div className="clearfix" />

        {/*<div className="custom-earnings-group-model-lable-question col-xs-12 col-sm-12 col-md-12 col-lg-12">
                       Create a name for this new grouping
                </div>
                <div className="custom-earnings-group-model-textbox col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <TextInputWithoutLabel/>
                </div>*/}

        {/*<div className="listing-category"> 
                    <div className="list-page-filter-row">
                        <div className="filter-title col-lg-1 col-md-1 col-sm-2 col-xs-2">
                            FILTER:
                        </div>  

                        <div id="select-deduction-dropdown" className="filter-select-dropdown col-lg-3 col-md-3 col-sm-3 col-xs-3" style={{}}>
                            <SelectInputDropdown/>                        
                        </div>

                        <div id="select-category-dropdown" className="filter-select-dropdown col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <SelectInputDropdown/>
                        </div>

                        <div className="list-page-filter-filler col-lg-2 col-md-2 col-sm-1 col-xs-1">
                            <button id="company-level-custom-earnings-group-filter-btn" type="button" className="btn btn-success company-level-custom-earnings-group-filter-btn">Filter</button> */}
        {/*onClick={this.applyFilter.bind(this)}*/}
        {/*</div>
                    </div>
                    
                </div>*/}

        <div
          className="listing-category col-xs-12 col-sm-12 col-md-12 col-lg-12"
          style={{ marginLeft: "0px", marginTop: "20px" }}
        >
          <div className="showing col-xs-2 col-sm-2 col-md-2 col-lg-2">
            FILTER:
          </div>
          <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
            <div
              id="company-level-custom-earnings-group-select-earning-groups-dropdown"
              className="company-level-custom-earnings-group-select-category-dropdown col-xs-6 col-sm-6 col-md-6 col-lg-6"
            >
              <SelectInputDropdown />
            </div>
            <div
              id="company-level-custom-earnings-group-filter-dropdown"
              className="company-level-custom-earnings-group-select-category-dropdown col-xs-6 col-sm-6 col-md-6 col-lg-6"
            >
              <SelectInputDropdown />
            </div>
          </div>
          <div
            id=""
            className="advance-filter-button col-xs-2 col-sm-2 col-md-2 col-lg-2"
          >
            */}
            <button
              id="company-level-custom-earnings-group-filter-btn"
              type="button"
              className="btn btn-success company-level-custom-earnings-group-filter-btn"
            >
              Filter
            </button>
            {/*onClick={this.applyFilter.bind(this)}*/}
          </div>
        </div>

        <div className="list-page-grid-section">
          <div className="list-page-grid-result col-xs-3 col-sm-3 col-md-3 col-lg-3">
            We found {}results.
          </div>

          <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <input type="checkbox" name="f" value="select-all-pay-codes" />select
            all
          </div>

          <div className="custom-earnings-group-all-checkbox-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
            {this.renderCustomEarningsGroupList()}
          </div>
        </div>

        {/*<div className="number-of-results row col-xs-12 col-sm-12 col-md-12 col-lg-12">
                   <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3"> We found {} results.</div>
                   <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                       <input type="checkbox" name="f" value="select-all-pay-codes" />select all
                   </div>
                </div>*/}
        {/*<div className="custom-earnings-group-all-checkbox-group col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    {this.renderCustomEarningsGroupList()}  
                    
                </div>*/}
        <div className="custom-earnings-group-checked-checkbox-group-badge-div col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <hr />
          <Badge className="custom-earnings-group-checked-checkbox-group-badge">
            {this.state.selectedCustomEarningsGroup.length}
          </Badge>
          <span>Earnings selected</span>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <button
            type="button"
            className="btn btn-info custom-earnings-group-model-next-button-calculation-method"
          >
            ADD EARNINGS GROUP
          </button>
          <button
            type="button"
            className="custom-earnings-group-model-default-button"
            onClick={this.redirectToCompanyLevelCalculationMethodPage}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    companylevelcustomEarningsList: state.companylevelcustomEarningsList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getCustomEarningsValuesList: getCustomEarningsValuesList },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomEarningGroup)
);
