/** Actions */
const NOTIFICATION_LIST = "notifications/NOTIFICATION_LIST";
const CLEAR_NOTIFICATION = "notifications/CLEAR_NOTIFICATION";
const SET_NOTIFICATION_LOADING = "notifications/SET_NOTIFICATION_LOADING";

const SET_EXCEPTION_JOBS = "notifications/SET_EXCEPTION_JOBS";
const SELECT_ALL_EXCEPTION_JOBS = "notifications/SELECT_ALL_EXCEPTION_JOBS";
const CLEAR_EXCEPTION_JOBS = "notifications/CLEAR_EXCEPTION_JOBS";

export const initialState = {
  loading: true,
  Data: [],
  DataCount: 0,
  NextUri: null
};

/** Reducer */
export const notifications = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_NOTIFICATION:
      return initialState;
    case NOTIFICATION_LIST: {
      const { Data, DataCount, NextUri } = payload;
      const newData = [...state.Data, ...Data];
      return {
        ...state,
        NextUri,
        Data: newData,
        DataCount: DataCount,
        loading: false
      };
    }
    case SET_NOTIFICATION_LOADING:
      return {
        ...state,
        loading: payload
      };
    default:
      return state;
  }
};

export const expectionJobs = (state = {}, { type, payload }) => {
  switch (type) {
    case CLEAR_EXCEPTION_JOBS:
      return {};
    case SET_EXCEPTION_JOBS:
      return { ...state, ...payload };
    case SELECT_ALL_EXCEPTION_JOBS:
      return payload;
    default:
      return state;
  }
};

//export default notifications;

/** Action creators */
export const notificationList = (Data, DataCount, NextUri) => ({
  type: NOTIFICATION_LIST,
  payload: { Data, DataCount, NextUri }
});

export const clearNotificationList = () => ({
  type: CLEAR_NOTIFICATION
});

export const setNotificationListLoading = loading => ({
  type: SET_NOTIFICATION_LOADING,
  payload: loading
});

export const setJobException = data => ({
  type: SET_EXCEPTION_JOBS,
  payload: data
});

export const selectAllExceptionList = data => ({
  type: SELECT_ALL_EXCEPTION_JOBS,
  payload: data
});

export const clearJobException = () => ({
  type: CLEAR_EXCEPTION_JOBS
});
