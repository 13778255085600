import React from "react";

const NoHoliday = ({ width, height, style }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 244 232"
    style={{ enableBackground: "new 0 0 244 232", ...style }}
    xmlSpace="preserve"
    width={width}
    height={height}
  >
    <style
      type="text/css"
      dangerouslySetInnerHTML={{
        __html: "\n\t.st0{fill:#339966;}\n\t.st1{fill:#FFFFFF;}\n"
      }}
    />
    <circle className="st0" cx={122} cy={116} r={100} />
    <g>
      <path
        className="st1"
        d="M157.2,78.2h-12.2v-4.5c0-1.4-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6v4.5h-15.5v-4.5c0-1.4-1.2-2.6-2.6-2.6
	c-1.4,0-2.6,1.2-2.6,2.6v4.5h-15.5v-4.5c0-1.4-1.2-2.6-2.6-2.6c-1.4,0-2.6,1.2-2.6,2.6v4.5H86.1c-6.4,0-11.5,5.2-11.5,11.5v59.5
	c0,6.4,5.2,11.5,11.5,11.5h71.8c6.4,0,11.5-5.2,11.5-11.5V90C168.9,83.6,163.8,78.2,157.2,78.2z M163.5,149.5
	c0,3.5-2.8,6.1-6.1,6.1H85.6c-3.5,0-6.1-2.8-6.1-6.1V90c0-3.5,2.8-6.1,6.1-6.1h12.2v6.6c0,1.4,1.2,2.6,2.6,2.6
	c1.4,0,2.6-1.2,2.6-2.6v-6.6h15.5v6.6c0,1.4,1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6v-6.6h15.5v6.6c0,1.4,1.2,2.6,2.6,2.6
	c1.4,0,2.6-1.2,2.6-2.6v-6.6h12.2c3.5,0,6.1,2.8,6.1,6.1v59.5H163.5z"
      />
      <rect x="113.6" y="103.9" className="st1" width="15.5" height="9.6" />
      <rect x="113.6" y="120.4" className="st1" width="15.5" height="9.6" />
      <rect x="113.6" y="136.8" className="st1" width="15.5" height="9.6" />
      <rect x="137.2" y="103.9" className="st1" width="15.5" height="9.6" />
      <rect x="137.2" y="120.4" className="st1" width="15.5" height="9.6" />
      <rect x="137.2" y="136.8" className="st1" width="15.5" height="9.6" />
      <rect x="90.1" y="103.9" className="st1" width="15.5" height="9.6" />
      <rect x="90.1" y="120.4" className="st1" width="15.5" height="9.6" />
      <rect x="90.1" y="136.8" className="st1" width="15.5" height="9.6" />
    </g>
  </svg>
);
export default NoHoliday;
