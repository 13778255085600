import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import axios from "axios";
import { withRouter } from "react-router-dom";
import * as common from "../../../app/common/index";

import { showToaster } from "../../../app/actions/AppActions";

import TextInputWithoutLabel from "../../../app/common/TextInputWithoutLabel";

import {
  getEmployerExpenceLiabilityAccountsList,
  getEmployerExpenceLiabilityAccountsFilteredList,
  getEmployerExpenceAccountsList,
  getEmployerLiabilityAccountsList
} from "../actions/generalledger/EmployerExpenceLiabilityAccountsActions";
import { getEmployeeLiabilityAccountsList } from "../actions/generalledger/EmployeeLiabilityAccountsActions";

// import RadioButton from "component-library/radioButton/BasicRadioButton";
import RadioButton from "component-library/radioButton/BasicRadioButton";
import * as validator from "../../../app/common/validations";
import * as utilities from "../../../app/common/utilities";
import NumberFormat from "react-number-format";

let GLAccountTypeList = [
  {
    id: "expense-radio",
    name: "AccountType",
    value: "2",
    label: "Expense Account"
  },
  {
    id: "liability-radio",
    name: "AccountType",
    value: "3",
    label: "Liability Account"
  }
];

class AddGeneralLedger extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isFormUpdated: false,
      showCancelModal: false,
      validationCount: 3,
      errors: {}
    };

    this.redirectToGLEmployerTab = this.redirectToGLEmployerTab.bind(this);
    this.renderGLAccountType = this.renderGLAccountType.bind(this);
    this.onChangeGLAccountType = this.onChangeGLAccountType.bind(this);
    this.onChangeAccountNumber = this.onChangeAccountNumber.bind(this);
    this.onChangeAccountDescription = this.onChangeAccountDescription.bind(
      this
    );
    this.closeCancelModal = this.closeCancelModal.bind(this);
    this.openCancelModal = this.openCancelModal.bind(this);
    this.createGLAccount = this.createGLAccount.bind(this);
  }

  closeCancelModal() {
    this.setState({
      showCancelModal: false
    });
  }

  openCancelModal() {
    if (this.state.IsFormUpdated) {
      this.setState({
        showCancelModal: true
      });
    } else {
      this.redirectToGLEmployerTab();
    }
  }

  redirectToGLEmployerTab() {
    this.props.onPopOverCancel();
  }

  createGLAccount() {
    let self = this;
    let errors = {};
    errors = validator.validateRequiredField(
      this.state.AccountNumber,
      "AccountNumber",
      errors
    );

    errors = validator.validateRequiredField(
      this.state.AccountType,
      "AccountType",
      errors
    );

    errors = validator.validateRequiredField(
      this.state.AccountDescription,
      "AccountDescription",
      errors
    );

    if (
      this.state.AccountDescription !== null &&
      this.state.AccountDescription !== undefined
    ) {
      let newName = this.state.AccountDescription.trim();
      this.setState({
        AccountDescription: newName
      });

      if (newName === "") {
        errors["AccountDescription"] = "Invalid Name.";
      }
    }

    let postItem = {
      GLAccountTypeId: parseInt(this.state.AccountType, 10),
      GLAccountNumber: this.state.AccountNumber,
      GLAccountName: this.state.AccountDescription
    };

    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      const url = `${
        common.WEB_API_URL
      }/v1/subscriber/company/glaccount/action/exists/glaccountnumber`;

      axios
        .post(url, {
          Id: parseInt(self.state.AccountType, 10),
          Name: self.state.AccountNumber
        })
        .then(function(response) {
          if (response.data.Data[0] === true) {
            errors["AccountNumber"] = "Account Number already exists";
            self.setState({
              isLoading: false,
              errors: errors
            });
          } else {
            const url = `${
              common.WEB_API_URL
            }/v1/subscriber/company/glaccount/action/exists/glaccountname`;

            axios
              .post(url, {
                Id: parseInt(self.state.AccountType, 10),
                Name: self.state.AccountDescription
              })
              .then(function(response) {
                if (response.data.Data[0] === true) {
                  errors["AccountDescription"] =
                    "Account Description already exists";
                  self.setState({
                    isLoading: false,
                    errors: errors
                  });
                } else {
                  const url = `${
                    common.WEB_API_URL
                  }/v1/subscriber/company/glaccount`;

                  axios
                    .post(url, postItem)
                    .then(function(response) {
                      if (response.data.ErrorCount > 0) {
                        response.data.Errors.forEach((item, index) => {
                          if (index === 0) {
                            alert(item.Messages);
                          }
                        });
                      } else {
                        self.redirectToGLEmployerTab();
                        self.props.showToaster(
                          true,
                          "You have successfully added a General Ledger Account"
                        );
                        self.props.getEmployerExpenceAccountsList();
                        self.props.getEmployerLiabilityAccountsList();
                        self.props.getEmployeeLiabilityAccountsList();
                      }
                    })
                    .catch(function(error) {});
                }
              })
              .catch(function(error) {});
          }
        })
        .catch(function(error) {});
    }
  }

  onChangeAccountNumber(event) {
    let errors = this.state.errors;
    delete errors["AccountNumber"];
    this.setState({
      errors
    });

    this.setState({
      [event.target.name]: event.target.value,
      IsFormUpdated: true
    });

    if (
      this.state.AccountNumber == null ||
      this.state.AccountNumber === undefined
    ) {
      this.setState({
        validationCount: this.state.validationCount - 1
      });
    }

    if (event.target.value.length !== 0 && !this.state.AccountNumber) {
      this.setState({
        validationCount: this.state.validationCount - 1
      });
    }

    if (event.target.value.length === 0) {
      this.setState({
        validationCount: this.state.validationCount + 1
      });
    }
  }

  onChangeAccountDescription(event) {
    let errors = this.state.errors;
    delete errors["AccountDescription"];
    this.setState({
      errors
    });

    this.setState({
      [event.target.name]: event.target.value,
      IsFormUpdated: true
    });

    if (
      this.state.AccountDescription == null ||
      this.state.AccountDescription === undefined
    ) {
      if (this.state.validationCount > 0) {
        this.setState({
          validationCount: this.state.validationCount - 1
        });
      }
    }

    if (event.target.value.length !== 0 && !this.state.AccountDescription) {
      if (this.state.validationCount > 0) {
        this.setState({
          validationCount: this.state.validationCount - 1
        });
      }
    }

    if (event.target.value.length === 0) {
      this.setState({
        validationCount: this.state.validationCount + 1
      });
    }
  }

  onChangeGLAccountType(event, value = 0) {
    let errors = this.state.errors;
    delete errors["AccountType"];
    this.setState({
      errors
    });
    let update = {};
    update[event.name] = event.value;
    this.setState(update);

    if (
      this.state.AccountType == null ||
      this.state.AccountType === undefined
    ) {
      this.setState({
        validationCount: this.state.validationCount - 1
      });
    }
  }

  renderGLAccountType(list) {
    return list.map(item => {
      return (
        <div
          key={item.id}
          id={`check-payment-button-` + item.id}
          className="inline-radio-button mobile-radio-button col-xs-6"
          style={{ paddingRight: "10px" }}
        >
          <RadioButton
            id={item.id}
            name={item.name}
            value={item.value}
            type="one-line-title"
            label={item.label}
            checked={this.state.AccountType === item.value}
            onChange={this.onChangeGLAccountType}
            width="230px"
          />
        </div>
      );
    });
  }

  render() {
    let AccountNumber = this.state.errors["AccountNumber"];
    return (
      <div className="nc-entity-page-container">
        <div className="modal-row clearfix">
          <h3>
            <span id="add-gl-account-modal-title">
              Add General Ledger Account
            </span>
          </h3>
        </div>

        <div id="deduction-category" className="entity-form-section-secondary">
          <div className="content">
            <div className="nc-field-group clearfix">
              <div className="field-name">
                <span
                  className={utilities.getErrorClassName(
                    this.state.errors["AccountType"]
                  )}
                >
                  GL Account Type
                </span>
              </div>

              <div
                id="deduction-category-button-list"
                className="radio-button-group"
              >
                {this.renderGLAccountType(GLAccountTypeList)}
              </div>
            </div>
          </div>
        </div>

        <div id="deduction-name" className="entity-form-section-secondary">
          <div className="content">
            <div className="nc-field-group">
              <div className="field-name">
                <span
                  id="add-gl-account-account-number-header"
                  className={utilities.getErrorClassName(
                    this.state.errors["AccountNumber"]
                  )}
                >
                  Account Number
                </span>{" "}
              </div>

              <div className="input-field col-lg-5 col-md-6 col-sm-8 col-xs-8">
                <NumberFormat
                  id="add-gl-account-account-number-textbox"
                  className="form-control"
                  value={this.state.AccountNumber}
                  name="AccountNumber"
                  allowNegative={false}
                  onChange={this.onChangeAccountNumber}
                  format="##########"
                />

                {AccountNumber && (
                  <p
                    id="third-party-payee-invalid-routing-number-text"
                    className="error-label"
                  >
                    {AccountNumber}
                  </p>
                )}
              </div>
              <div
                id="company-deduction-name-input-validation"
                className="field-description col-xs-12 col-sm-12 col-md-5 col-lg-5 hidden-xs hidden-sm"
                style={{ paddingLeft: "20px" }}
              >
                10 characters max
              </div>
              <div
                id="company-deduction-name-input-validation"
                className="field-description col-xs-12 col-sm-12 col-md-5 col-lg-5 hidden-lg hidden-md"
              >
                10 characters max
              </div>
            </div>
          </div>
        </div>

        <div className="clearfix" />

        <div
          id="deduction-name"
          className="entity-form-section-secondary clearfix"
        >
          <div className="content">
            <div className="nc-field-group">
              <div className="field-name">
                <span
                  id="add-gl-account-account-description-header"
                  className={utilities.getErrorClassName(
                    this.state.errors["AccountDescription"]
                  )}
                >
                  Account Description
                </span>{" "}
              </div>

              <div className="input-field col-lg-7 col-md-6 col-sm-12 col-xs-12">
                <TextInputWithoutLabel
                  id="add-gl-account-account-description-textbox"
                  value={this.state.AccountDescription}
                  name="AccountDescription"
                  onChange={this.onChangeAccountDescription}
                  disabled={false}
                  error={this.state.errors["AccountDescription"]}
                  maxLength="50"
                />
              </div>
              <div
                id="company-deduction-name-input-validation"
                className="field-description col-xs-12 col-sm-12 col-md-5 col-lg-5 hidden-xs hidden-sm"
                style={{ paddingLeft: "20px" }}
              >
                50 characters max
              </div>
              <div
                id="company-deduction-name-input-validation"
                className="field-description col-xs-12 col-sm-12 col-md-5 col-lg-5 hidden-lg hidden-md"
              >
                50 characters max
              </div>
            </div>
          </div>
        </div>
        <Modal.Footer>
          <div className="button-group">
            <button
              type="button"
              id="create-button"
              className={
                this.state.validationCount === 0
                  ? "nc-button primary-button"
                  : "disabled-button"
              }
              onClick={this.createGLAccount}
              disabled={this.state.validationCount === 0 ? false : true}
            >
              ADD GL ACCOUNT
            </button>

            <button
              type="button"
              id="cancel-buttonlink"
              className="secondary-link-button"
              onClick={this.redirectToGLEmployerTab}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    employerExpenceAccountsList:
      state.generalLedgerReducer.employerExpenceAccountsList,
    employerLiabilityAccountsList:
      state.generalLedgerReducer.employerLiabilityAccountsList,
    employeeLiabilityAccountsList:
      state.generalLedgerReducer.employeeLiabilityAccountsList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showToaster,
      getEmployerExpenceLiabilityAccountsList,
      getEmployerExpenceLiabilityAccountsFilteredList,
      getEmployerExpenceAccountsList,
      getEmployerLiabilityAccountsList,
      getEmployeeLiabilityAccountsList
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddGeneralLedger)
);
