/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ModalHeader from "./../../../component-library/payroll-dashboard/payroll-dashboard-modals/ModalHeader";
import DropContainer from "./DropContainer";
import "./styles.css";
import PropTypes from "prop-types";
import Media from "react-media";
class ProfileUploadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      droppedFiles: [],
      imageLoading: false,
      src: "",
      imgName: "",
      disableSave: true,
      imgType: ""
    };
  }

  handleFileDrop = (item, monitor) => {
    if (monitor) {
      const droppedFiles = monitor.getItem().files;
      if (
        droppedFiles &&
        (droppedFiles[0].type === "image/png" ||
          droppedFiles[0].type === "image/jpeg")
      ) {
        this.setState({
          imgType: droppedFiles[0].type,
          imgName: droppedFiles[0].name,
          disableSave: false
        });
        this.createImage(droppedFiles);
      } else {
        this.setState({
          imgName: ""
        });
        this.props.setImageState("fileTypeError");
      }
    }
  };

  handleFileUpload = e => {
    var x = document.getElementById("image-upload");
    const selectedFiles = x.files;
    if (
      selectedFiles &&
      (selectedFiles[0].type === "image/png" ||
        selectedFiles[0].type === "image/jpeg")
    ) {
      this.setState({
        imgType: selectedFiles[0].type,
        imgName: selectedFiles[0].name,
        disableSave: false
      });
      this.createImage(selectedFiles);
    } else {
      this.setState({
        imgName: ""
      });
      this.props.setImageState("fileTypeError");
    }
  };

  createImage = droppedFiles => {
    if (droppedFiles[0].size <= 4194304) {
      this.setState({ droppedFiles });

      const image = new Image();
      image.src = URL.createObjectURL(droppedFiles[0]);
      //image.src=droppedFiles[0]
      image.onload = () => {
        this.setState({
          src: image.src,
          imageLoading: false
        });
        this.props.setSrc(image.src);
      };
      image.onerror = err => {
        this.setState({
          src: "",
          imageLoading: false
        });
        this.props.setSrc("");
      };
      image.onprogress = (e, request, progress) => {
        console.log("Progress", e, request, progress);
      };
    } else {
      this.props.setImageState("fileSizeError");
    }
  };

  renderDescription = () => {
    const { imageState, isProfileAlreadyUploaded } = this.props;
    switch (imageState) {
      case "uploaded":
        if (isProfileAlreadyUploaded) {
          return "To crop this image, drag or resize the region below.";
        } else {
          return "Crop the uploaded image";
        }
      case "fileTypeError":
        return "Please Upload an image of type .jpg, .jpeg or .png.";
      case "fileSizeError":
        return "Please Upload an image up to 4 MB.";
      default:
        return "Please upload a JPG or PNG file. Max file size is 4MB.";
    }
  };

  disableSaveButton = () => {
    this.setState({
      disableSave: false
    });
  };

  closeModal = () => {
    this.setState({
      droppedFiles: [],
      disableSave: true
    });
    this.props.closeModal();
    this.props.setImageState("");
  };

  render() {
    const {
      show,
      buttonAlign,
      className,
      onSetClick,
      setRef,
      setImageState,
      imageState,
      onImageLoaded,
      isUploaded,
      src = "",
      onRemoveClick,
      isProfileAlreadyUploaded = false,
      Spinner
    } = this.props;

    return (
      <Modal
        bsClass="profile-upload-modal modal"
        animation={false}
        backdrop={"static"}
        keyboard={false}
        show={show}
        onHide={this.closeModal}
        restoreFocus={false}
      >
        <Modal.Header>
          <ModalHeader onClose={this.closeModal} />
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div id="profile-upload-modal-title" className="title">
              {isProfileAlreadyUploaded
                ? "Edit Your Profile Photo"
                : "Upload Your Profile Photo"}
            </div>
            <div
              id="profile-upload-modal-description"
              className="description clearfix"
              style={{
                color:
                  imageState === "fileTypeError" ||
                  imageState === "fileSizeError"
                    ? "red"
                    : ""
              }}
            >
              {" "}
              <Media query="(max-width: 767px)">
                {matches =>
                  matches ? (
                    <span className="clearfix" style={{ fontWeight: "600" }}>
                      {this.renderDescription()}
                    </span>
                  ) : (
                    <span className="" style={{ fontWeight: "600" }}>
                      {this.renderDescription()}
                    </span>
                  )
                }
              </Media>
              {(imageState === "uploaded" || isUploaded) && (
                <div id="profile-upload-modal-links" className="image-links">
                  <label
                    style={{
                      fontWeight: "400",
                      marginRight: "5px",
                      color: "#009999",
                      cursor: "pointer"
                    }}
                  >
                    {/*a tag is converted to div to resolve bug 37937*/}
                    <div>
                      <i id="upload-new-photo-link" className="fa fa-upload" />
                      Upload New Photo
                      <input
                        id="image-upload"
                        style={{ display: "none" }}
                        type="file"
                        onChange={this.handleFileUpload}
                      />
                    </div>
                  </label>
                  <a onClick={() => onRemoveClick()}>
                    <i id="remove-photo-link" className="fa fa-trash" />
                    Remove Photo
                  </a>
                </div>
              )}
            </div>
            <Media query="(max-width: 470px)">
              {matches =>
                matches ? (
                  <DropContainer
                    setRef={setRef}
                    setImageState={setImageState}
                    onSetClick={onSetClick}
                    onImageLoaded={onImageLoaded}
                    handleFileDrop={this.handleFileDrop}
                    handleFileUpload={this.handleFileUpload}
                    src={src}
                    imageLoading={this.state.imageLoading}
                    droppedFiles={this.state.droppedFiles}
                    onChange={this.disableSaveButton}
                    imgHeight={250}
                    imgWidth={250}
                    Spinner={Spinner}
                    imgType={this.state.imgType}
                  />
                ) : (
                  <DropContainer
                    setRef={setRef}
                    setImageState={setImageState}
                    onSetClick={onSetClick}
                    onImageLoaded={onImageLoaded}
                    handleFileDrop={this.handleFileDrop}
                    handleFileUpload={this.handleFileUpload}
                    src={src}
                    imageLoading={this.state.imageLoading}
                    droppedFiles={this.state.droppedFiles}
                    onChange={this.disableSaveButton}
                    Spinner={Spinner}
                    imgType={this.state.imgType}
                  />
                )
              }
            </Media>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group" style={{ clear: "both" }}>
            <div className="seperator">
              <hr style={{ borderTop: "1px solid #ccc" }} />
            </div>
            <Media query="(max-width: 767px)">
              {matches =>
                matches ? (
                  <div
                    id="profile-upload-modal-footer-buttons"
                    style={{}}
                    className={
                      buttonAlign === "left"
                        ? "nc-button-group float-left"
                        : className
                    }
                  >
                    <div
                      id="profile-upload-modal-footer-description"
                      style={{ color: "#999", marginBottom: "20px" }}
                    >
                      <i>
                        Your photo is visible to all administration, managers
                        and employees.
                      </i>
                    </div>
                    <button
                      id="profile-upload-modal-footer-save-button"
                      className={
                        imageState !== "uploaded" || this.state.disableSave
                          ? "disabled-button"
                          : "nc-button primary-button"
                      }
                      onClick={() => onSetClick(this.state.imgName)}
                      disabled={
                        imageState !== "uploaded" || this.state.disableSave
                      }
                    >
                      {isProfileAlreadyUploaded
                        ? "SAVE CHANGES"
                        : "SET AS PROFILE PHOTO"}
                    </button>
                    <button
                      id="profile-upload-modal-footer-cancel-button"
                      className="secondary-link-button"
                      onClick={this.closeModal}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div
                    id="profile-upload-modal-footer-buttons"
                    style={{ display: "flex", alignItems: "center" }}
                    className={
                      buttonAlign === "left"
                        ? "nc-button-group float-left"
                        : className
                    }
                  >
                    <button
                      id="profile-upload-modal-footer-save-button"
                      className={
                        imageState !== "uploaded" || this.state.disableSave
                          ? "disabled-button"
                          : "nc-button primary-button"
                      }
                      onClick={() => onSetClick(this.state.imgName)}
                      disabled={
                        imageState !== "uploaded" || this.state.disableSave
                      }
                    >
                      {isProfileAlreadyUploaded
                        ? "SAVE CHANGES"
                        : "SET AS PROFILE PHOTO"}
                    </button>
                    <button
                      id="profile-upload-modal-footer-cancel-button"
                      className="secondary-link-button"
                      onClick={this.closeModal}
                    >
                      Cancel
                    </button>
                    <span
                      id="profile-upload-modal-footer-description"
                      style={{ color: "#999" }}
                    >
                      <i>
                        Your photo is visible to all administration, managers
                        and employees.
                      </i>
                    </span>
                  </div>
                )
              }
            </Media>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ProfileUploadModal;

ProfileUploadModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  buttonAlign: PropTypes.string,
  className: PropTypes.string,
  onSetClick: PropTypes.func,
  setRef: PropTypes.func,
  setImageState: PropTypes.func,
  imageState: PropTypes.string,
  src: PropTypes.string,
  setSrc: PropTypes.func
};
ProfileUploadModal.defaultProps = {
  show: true,
  closeModal: () => alert("Will close Modal"),
  buttonAlign: "left",
  className: "",
  onSetClick: () => alert("Will set profile pic"),
  setRef: () => {},
  setImageState: () => alert("Will set Image State"),
  imageState: "",
  onUploadNewClick: () => alert("Upload New Pic"),
  onRemoveClick: () => alert("Remove Uploaded Pic"),
  src: "",
  setSrc: () => {}
};
