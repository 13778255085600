import React from "react";
import UserDetails from "../../activity-history/UserDetails";
import TimeCardAlertActions from "./TimeCardAlertActions";
import TimeCardSubitem from "./TimeCardSubitem";
import * as util from "../../util";
import "./UnapprovedPtosItem.css";
import Media from "react-media";
const TimeCardItem = ({
  EmployeeId,
  EmployeeName,
  EmployeePictureUrl,
  TimeCardAlertList,
  ApproverList,
  toggleInfoModal,
  approveAction,
  selected,
  onSelect,
  onContactClick,
  IsEmailSent
}) => (
  <div className="MultiSectionTableItem col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <UserDetails
      id={EmployeeId}
      name={EmployeeName}
      picUrl={
        util.isNullOrEmpty(EmployeePictureUrl)
          ? "https://goo.gl/4AYQmG"
          : EmployeePictureUrl
      }
      className="col-lg-2 col-md-3 col-sm-10 col-xs-10"
      parent="dashBoard"
      selected={selected}
      onSelect={() => onSelect(EmployeeId, !selected)}
      hasCheckbox
    />
    <div className="col-sm-2 col-xs-2 hidden-lg hidden-md">
      <TimeCardAlertActions
        toggleInfoModal={toggleInfoModal}
        approveAction={approveAction}
        onContactClick={onContactClick}
        hideEdit
        noPadding
        IsEmailSent={IsEmailSent}
      />
    </div>

    <div
      className="col-md-8 col-lg-9 sub-item-container"
      style={{ padding: 0 }}
    >
      {TimeCardAlertList.map((item, i) => (
        <TimeCardSubitem key={i} {...item} />
      ))}
    </div>
    <Media query="(min-width:992px)">
      {matches =>
        matches ? (
          <div
            className="col-md-1 col-lg-1 hidden-sm hidden-xs"
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "column"
            }}
          >
            {TimeCardAlertList.map((item, i) => (
              <TimeCardAlertActions
                key={i}
                {...item}
                toggleInfoModal={toggleInfoModal}
                approveAction={approveAction}
                hideEdit
                onContactClick={onContactClick}
                hideApprove
                IsEmailSent={IsEmailSent}
                noPadding
                noMargin
              />
            ))}
          </div>
        ) : null
      }
    </Media>
  </div>
);

export default TimeCardItem;
