import React, { Component } from "react";
import "./AdvanceFilter.css";
import PropTypes from "prop-types";
import AdvanceFilterButtons from "./AdvanceFilterButtons";
import {
  Demographics,
  EmployeeIdentification,
  EmployeeStatus,
  JobDetails,
  TimeAttendance,
  Workplace
} from "./advance-filter";
import * as Field from "./advance-filter/FieldNames";
class AdvanceFilter extends Component {
  render() {
    const {
      onClear,
      onClose,
      onApply,
      onChange,
      data,
      options = {},
      makeOnSelect,
      makeOnSelectAll,
      selectedOptions,
      makeDateRangeSelect
    } = this.props;
    return (
      <div className="adv-filter-container">
        <div className="nc-entity-page-container">
          <div className="adv-header">
            <div className="adv-title">Advanced Filters</div>
            <div className="right-icons">
              <a onClick={onClear}>
                {" "}
                <i className="fa fa-trash" /> Clear All Filters
              </a>
              <span className="close-icon">
                <i
                  id="adv-filter-close-icon"
                  onClick={onClose}
                  className="fa fa-times-circle-o fa-lg"
                />
              </span>
            </div>
          </div>
          <div className="adv-filter-content">
            <div className="filter-column col-lg-3">
              <Demographics
                onChange={onChange}
                data={data}
                options={options}
                selectedOptions={selectedOptions}
                makeOnSelect={makeOnSelect}
                makeOnSelectAll={makeOnSelectAll}
                makeDateRangeSelect={makeDateRangeSelect}
              />
              <EmployeeIdentification
                onChange={onChange}
                data={data}
                options={options}
              />
            </div>
            <div className="filter-column col-lg-3">
              <JobDetails
                onChange={onChange}
                data={data}
                options={options}
                selectedOptions={selectedOptions}
                makeOnSelect={makeOnSelect}
                makeOnSelectAll={makeOnSelectAll}
              />
              <TimeAttendance
                onChange={onChange}
                data={data}
                options={options}
                selectedOptions={selectedOptions}
                makeOnSelect={makeOnSelect}
                makeOnSelectAll={makeOnSelectAll}
              />{" "}
            </div>
            <div className="filter-column col-lg-3">
              <EmployeeStatus
                onChange={onChange}
                data={data}
                options={options}
                selectedOptions={selectedOptions}
                makeOnSelect={makeOnSelect}
                makeOnSelectAll={makeOnSelectAll}
                makeDateRangeSelect={makeDateRangeSelect}
              />
            </div>
            <div className="filter-column col-lg-3">
              <Workplace
                onChange={onChange}
                data={data}
                options={options}
                selectedOptions={selectedOptions}
                makeOnSelect={makeOnSelect}
                makeOnSelectAll={makeOnSelectAll}
              />
            </div>
          </div>
          <AdvanceFilterButtons onApply={onApply} onClose={onClose} />
        </div>
      </div>
    );
  }
}
export default AdvanceFilter;
AdvanceFilter.propTypes = {
  onClear: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.object,
  data: PropTypes.object
};
AdvanceFilter.defaultProps = {
  onClear: () => alert("Specify the action to clear advance filters"),
  onClose: () => alert("Specify the action to close filter"),
  onApply: () => alert("Specify the action for apply Button"),
  onChange: () => alert("Specify the onChange Handler "),
  options: { [Field.primaryManager]: {}, [Field.secondaryManager]: {} },
  data: {}
};
