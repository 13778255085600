import React, { Component } from "react";
import { connect } from "react-redux";
import EntityListFilters from "component-library/entity-list/EntityListFilters";
import {
  makeOptions,
  makeSelectedOptions,
  selectFilterOption,
  selectAllFilterOptions,
  selectDateRangeOption,
  clearAllFilters
} from "app/common/reducers/filters";
import { setSortOrder } from "app/common/reducers/sortOrder";
import { setLoader } from "app/actions/LoaderActions";
import * as FIELDS from "component-library/employee-profile/resume/utils/Constants";

class ResumeEntityFilters extends Component {
  makeOnSelect = name => (option, selected) => {
    this.props.selectOption(name, option, selected);
  };

  makeSelectAll = name => selected => {
    this.props.selectAll(name, selected);
  };

  getFromToValue = (name, fromToValue) => {
    this.props.selectDateRange(name, fromToValue);
  };

  render() {
    const { filters, options, selectedOptions, Id, SectionItemId } = this.props;

    let page = "";
    if (SectionItemId === FIELDS.EMPLOYMENT_HISTORY_ID) {
      page = "EmploymentHistory";
    } else if (SectionItemId === FIELDS.LICENSES_CERTIFICATION_ID) {
      page = "Licenses";
    } else if (SectionItemId === FIELDS.EDUCATION_ID) {
      page = "Education";
    } else if (SectionItemId === FIELDS.SKILLS_ID) {
      page = "Skills";
    }

    return (
      <EntityListFilters
        page={page}
        filters={filters}
        options={options}
        selectedOptions={selectedOptions}
        makeOnSelect={this.makeOnSelect}
        makeSelectAll={this.makeSelectAll}
        clearAll={() => this.resetFilters()}
        getFromToValue={(name, fromToValue) =>
          this.getFromToValue(name, fromToValue)
        }
        //filterCount={getFilterCount(selectedOptions, options)}
        Id={Id}
        width="86%"
      />
    );
  }
}

const stateToProps = state => ({
  state: state,
  options: makeOptions(state.common.filters),
  selectedOptions: makeSelectedOptions(state.common.filters),
  sortOrder: state.common.sortOrder
});

const actionCreators = {
  setLoader,
  clearAllFilters,
  selectOption: selectFilterOption,
  selectAll: selectAllFilterOptions,
  editSortOrder: setSortOrder,
  selectDateRange: selectDateRangeOption
};

export default connect(stateToProps, actionCreators)(ResumeEntityFilters);
