import field, {
  getDataField,
  SET_FIELD_OPTIONS,
  SELECT_ALL_FIELD_OPTIONS,
  SELECT_FIELD_OPTION,
  SELECT_DATERANGE_OPTION
} from "./field";
import formatTimeforDateRagePicker from "component-library/daterangepicker/src/formatTimeforDateRagePicker";
import * as utilities from "../utilities";

export const CLEAR_ALL_FIELDS = "activity-history/CLEAR_ALL_fieldS";

/** Reducer */
const fields = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_FIELD_OPTIONS:
    case SELECT_FIELD_OPTION:
    case SELECT_DATERANGE_OPTION:
      const { name } = payload;
      return {
        ...state,
        [name]: field(state[name], action)
      };
    case SELECT_ALL_FIELD_OPTIONS:
      if (payload.name) {
        return {
          ...state,
          [payload.name]: field(state[payload.name], action)
        };
      } else {
        const newState = {};
        for (const k in state) {
          newState[k] = field(state[k], action);
        }
        return newState;
      }
    case CLEAR_ALL_FIELDS:
      return {};
    default:
      return state;
  }
};

export default fields;

/** Action Creators */
export const setfieldOptions = (name, options) => ({
  type: SET_FIELD_OPTIONS,
  payload: { name, options }
});

export const selectfieldOption = (name, option, value) => ({
  type: SELECT_FIELD_OPTION,
  payload: { name, option, value }
});

export const selectAllfieldOptions = (name, value) => ({
  type: SELECT_ALL_FIELD_OPTIONS,
  payload: { name, value }
});

export const selectDateRangeOption = (name, fromToValue) => ({
  type: SELECT_DATERANGE_OPTION,
  payload: { name, fromToValue }
});

export const clearAllfields = () => ({
  type: CLEAR_ALL_FIELDS
});

/** Selectors */
export const getfieldData = (state, name) => getDataField(state[name]);

const makeValues = ({ options = [], selected = {} }) => {
  const fieldItems = [];
  options.forEach(({ value }) => {
    if (selected[value]) {
      fieldItems.push(value);
    }
  });

  if (options.length === fieldItems.length) {
    return "";
  }

  return fieldItems.join(", ");
};

export const makeCriterion = (fieldName, fieldData = {}) => {
  const Values = makeValues(fieldData);
  if (Values) {
    return {
      Field: fieldName,
      Operator: "in",
      value:
        fieldName === "EntityInstanceState" && Values === "2" ? "0" : Values
    };
  } else {
    return null;
  }
};

export const makeSortCriterion = sortOrder => {
  if (
    utilities.isNullOrEmpty(sortOrder.Field) ||
    utilities.isNullOrEmpty(sortOrder.Order)
  ) {
    return null;
  } else {
    return [
      {
        Field: sortOrder.Field,
        Order: sortOrder.Order
      }
    ];
  }
};

export const makeDateRangeCriterion = (fieldName, operator, fieldData = {}) => {
  const Values = fieldData["selected"];
  if (Values !== undefined) {
    if (
      fieldName === "FromDateTime" &&
      Values.from !== null &&
      Values.from !== undefined
    ) {
      return {
        Field: fieldName,
        Operator: operator,
        value: formatTimeforDateRagePicker(Values.from) + " 00:00:00"
      };
    } else if (
      fieldName === "ToDateTime" &&
      Values.to !== null &&
      Values.to !== undefined
    ) {
      return {
        Field: fieldName,
        Operator: operator,
        value: formatTimeforDateRagePicker(Values.to) + " 23:59:59"
      };
    }
  } else {
    return null;
  }
};

export const makeOptions = fields => {
  const fieldOptions = {};
  Object.keys(fields).forEach(k => (fieldOptions[k] = fields[k].options));
  return fieldOptions;
};

export const makeSelectedOptions = fields => {
  const selectedOptions = {};
  Object.keys(fields).forEach(
    //k => (selectedOptions[k] = fields[k].selected[k])
    k => {
      if (k === "fromCompany") selectedOptions[k] = fields[k].selected[k];
      else selectedOptions[k] = fields[k].selected;
    }
  );
  return selectedOptions;
};
