import React from "react";
import CheckBox from "../checkBoxGroup/CheckBox";
import "./UserDetails.css";

const UserDetails = ({
  id,
  name,
  picUrl,
  hasCheckbox = false,
  parent = "",
  className,
  selected,
  onSelect
}) => (
  <div
    className={
      parent === "dashBoard"
        ? className
        : "col-lg-2 col-md-2 col-sm-12 col-xs-12"
    }
    onClick={onSelect}
  >
    {hasCheckbox ? (
      <CheckBox
        value={selected}
        id={"user-detail-checkbox-" + id}
        classname="payroll-dashboard-checkbox"
      />
    ) : null}
    <div className="user-icon-horizontal">
      <div
        id="user-image-icon"
        className="user-icon-image visible-lg visible-md"
      >
        <img src={picUrl} alt={name} width="37" height="40" />
      </div>
      <div id="user-name-icon" className="user-icon-name table-content">
        {name}{" "}
      </div>
    </div>
  </div>
);

export default UserDetails;
