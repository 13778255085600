import React, { Component } from "react";
import "./utilitiesHeader.css";
import UtilitiesSvg from "./UtilitiesSvg";
class UtilitiesHeader extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="header-conainer row">
        <div
          id="page-title"
          className="utility-header col-lg-2 col-md-2 col-sm-12 col-xs-12"
        >
          <UtilitiesSvg
            height={50}
            width={50}
            style={{ marginRight: "10px" }}
          />
          {data.title}
        </div>
        <div
          id="page-description"
          className="utility-description col-lg-9 col-md-9 col-sm-12 col-xs-12"
        >
          <p>{data.description}</p>
        </div>
      </div>
    );
  }
}
export default UtilitiesHeader;
