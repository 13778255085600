const SET_SIDE_BAR_PANEL = "people-employee-dashboard/SET_SIDE_BAR_PANEL";
const CLEAR_SIDE_BAR_PANEL = "people-employee-dashboard/CLEAR_SIDE_BAR_PANEL";

export const initialState = {
  SideBarId: 0,
  ActivePanelId: 0
};
const activePanel = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SIDE_BAR_PANEL: {
      const { sideBarId, activePanelId } = payload;
      return {
        SideBarId: sideBarId,
        ActivePanelId: activePanelId
      };
    }
    case CLEAR_SIDE_BAR_PANEL: {
      return {
        SideBarId: 0,
        ActivePanelId: 0
      };
    }
    default:
      return state;
  }
};

export default activePanel;

export const setSideBarAndActivePanelId = (sideBarId, activePanelId) => ({
  type: SET_SIDE_BAR_PANEL,
  payload: { sideBarId, activePanelId }
});

export const clearSideBarAndActivePanelId = () => ({
  type: CLEAR_SIDE_BAR_PANEL
});
