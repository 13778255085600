import React, { Component } from "react";
import { PropTypes } from "prop-types";
import {
  FormSection,
  FormFieldGroup
} from "component-library/formSections/index";
import * as FIELDS from "../utils/Constants";
import MonthYear from "component-library/inputFields/MonthYear";
import AddFormFieldGroup from "component-library/inputFields/AddFormFieldGroup";
import { Graduated, CurrentGPAScale } from "./Components";
export default class EducationDetails extends Component {
  render() {
    const {
      data = {},
      onChange,
      options,
      onAddAdditionalFields,
      errors
    } = this.props;
    return (
      <div className="tab-container-add-sub-section">
        <div className="section-items">
          <FormSection title="Education Details">
            <MonthYear
              data={data}
              errors={errors}
              onChange={onChange}
              label={"Start Date"}
              Month={FIELDS.START_MONTH}
              Year={FIELDS.START_YEAR}
              required={true}
            />

            <Graduated data={data} onChange={onChange} />

            {data[FIELDS.GRADUATED] &&
              data[FIELDS.GRADUATED] !== undefined && (
                <MonthYear
                  data={data}
                  errors={errors}
                  onChange={onChange}
                  label={"Graduation Date"}
                  Month={FIELDS.GRADUATION_MONTH}
                  Year={FIELDS.GRADUATION_YEAR}
                  required={true}
                />
              )}

            {!data[FIELDS.GRADUATED] &&
              !data[FIELDS.CURRENTLY_ENROLLED] &&
              data[FIELDS.GRADUATED] !== undefined && (
                <MonthYear
                  data={data}
                  errors={errors}
                  onChange={onChange}
                  label={"End Date"}
                  Month={FIELDS.END_DATE_MONTH}
                  Year={FIELDS.END_DATE_YEAR}
                  optional={true}
                  required={true}
                />
              )}

            {!data[FIELDS.GRADUATED] &&
              data[FIELDS.CURRENTLY_ENROLLED] &&
              data[FIELDS.GRADUATED] !== undefined && (
                <MonthYear
                  data={data}
                  errors={errors}
                  onChange={onChange}
                  label={"Expected Graduation Date"}
                  Month={FIELDS.EXPECTED_GRADUATION_MONTH}
                  Year={FIELDS.EXPECTED_GRADUATION_YEAR}
                  required={true}
                />
              )}

            <FormFieldGroup
              inputType="dropdown"
              options={options[FIELDS.EducationDegreeFilter]}
              inputName={FIELDS.DEGREE}
              value={data[FIELDS.DEGREE]}
              label="Degree"
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              required={true}
              error={errors[FIELDS.DEGREE]}
            />

            <AddFormFieldGroup
              inputType="text"
              inputName={FIELDS.MAJORS}
              value={data[FIELDS.MAJORS] || []}
              label={"Major(s)"}
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              AddLinkText="Add Additional Major"
              onAddAdditionalFields={onAddAdditionalFields}
              required={true}
              error={errors[FIELDS.MAJORS]}
            />

            <AddFormFieldGroup
              inputType="text"
              inputName={FIELDS.MINORS}
              value={data[FIELDS.MINORS] || []}
              label={"Minors(s)"}
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              optional={true}
              colSpan="col-lg-3 col-md-3 col-sm-3"
              AddLinkText="Add Additional Minor"
              onAddAdditionalFields={onAddAdditionalFields}
            />

            <CurrentGPAScale
              data={data}
              onChange={onChange}
              required={true}
              errors={errors}
            />

            <FormFieldGroup
              inputType="textarea"
              inputName={FIELDS.HONORS_AWARDS_ACHIVEMENTS}
              value={data[FIELDS.HONORS_AWARDS_ACHIVEMENTS]}
              label={"Honor / Awards / Achivements"}
              onChange={onChange}
              Size={[7, 7, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              optional={true}
              rows={3}
              description="250 Character max"
              descriptionStyle="italic"
            />

            <FormFieldGroup
              inputType="textarea"
              inputName={FIELDS.RELEVANT_COURSEWORK}
              value={data[FIELDS.RELEVANT_COURSEWORK]}
              label={"Relevant Coursework"}
              onChange={onChange}
              Size={[7, 7, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              optional={true}
              rows={3}
              description="250 Character max"
              descriptionStyle="italic"
            />
          </FormSection>
        </div>
      </div>
    );
  }
}

EducationDetails.propTypes = {
  data: PropTypes.object
};

EducationDetails.defaultProps = {
  data: {}
};
