import React from "react";
import holidayIcon from "../holidayIcon";
import "./snapshot.css";

const EntitySnapshotTable = ({ nodes = [], holidayType }) => (
  <div id="hc-preview-modal-table" className="holiday-calendar-table clearfix">
    {nodes.map(item => (
      <div
        class="clearfix"
        style={
          item.NodeKey === "CommonHoliday"
            ? { paddingTop: "10px" }
            : { paddingTop: "20px" }
        }
      >
        {item.Nodes.map((i, index) => (
          <div className="content node">
            {i.NodeValues.length === 3 && (
              <div
                id="common-holidays-name-section"
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12 nodeItem"
                style={{ marginTop: "5px" }}
              >
                <div
                  id="common-holidays-name-label"
                  className="col-lg-4 col-md-4 col-sm-4 col-xs-6 subTitle"
                >
                  {i.NodeValues[0]}
                </div>
                <div
                  id="common-holidays-name-value"
                  className="col-lg-8 col-md-8 col-sm-8 col-xs-6"
                >
                  <span style={{ paddingRight: "5px" }}>
                    <img
                      src={holidayIcon(parseInt(i.NodeValues[1], 10))}
                      alt={i.NodeValues[2]}
                      width={45}
                      height={45}
                    />
                  </span>
                  {i.NodeValues[2]}
                </div>
              </div>
            )}
            {i.NodeValues.length < 3 && (
              <div
                id={`custom-holidays-holiday-name-section-` + i.NodeValues[0]}
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12 nodeItem"
              >
                <div
                  id={`custom-holidays-holiday-name-label-` + i.NodeValues[0]}
                  className="col-lg-4 col-md-4 col-sm-4 col-xs-6 subTitle"
                >
                  {i.NodeValues[0]}
                </div>
                <div
                  id={`custom-holidays-holiday-name-value-` + i.NodeValues[0]}
                  className="col-lg-8 col-md-8 col-sm-8 col-xs-6 "
                >
                  {i.NodeValues[1]}
                </div>
              </div>
            )}
          </div>
        ))}

        {item.NodeType === "section" && (
          <div className="seperator clearfix">
            <hr />
          </div>
        )}
      </div>
    ))}
  </div>
);

export default EntitySnapshotTable;
