import React from "react";
import DropDown from "component-library/dropDown";
import InputFieldWithSign from "component-library/inputFields/InputFieldWithSign";
import getInputComponent from "component-library/inputFields/getInputComponent";
const DatePicker = getInputComponent("singleDatePicker");
const EarningItem = ({ item = {}, data, onChange }) => {
  return (
    <div className="entity-info-item clearfix">
      <div className="entity-column col-lg-3 col-md-3">{item.enityName}</div>
      <div className="entity-column col-lg-3 col-md-3">
        <DropDown
          inputName="EarningsStatus"
          value={data.EarningsStatus}
          onChange={onChange}
        />
      </div>

      <div className="entity-column col-lg-3 col-md-3">
        <InputFieldWithSign
          sign="$"
          className="input-field"
          inputName="EarningsAmount"
          value={data.EarningsAmount}
          onChange={onChange}
        />
      </div>
      <div className="entity-column col-lg-3 col-md-3">
        <DatePicker
          name="EarningsEndDate"
          onChange={onChange}
          getFromToValue={onChange}
          value={data.EarningsEndDate}
          showNoDateSection
        />
      </div>
    </div>
  );
};
export default EarningItem;
