import React, { Component } from "react";
import PropTypes from "prop-types";
import EntityListTableHeader from "../../entity-list/EntityListTableHeader";
import DependentListItem from "./DependentListItem";

export default class DependentTable extends Component {
  render() {
    const {
      headerColumn = "DependentsList",
      data
      // editSortOrder,
      // sortOrder
    } = this.props;
    return (
      <div className="list-page-grid-table col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <EntityListTableHeader
          headerColumn={headerColumn}
          size={[12, 12, 12, 12]}
        />
        {data.map((item, i) => (
          <DependentListItem
            key={item.Id}
            item={item}
            onClickEmployee={() => {}}
          />
        ))}
      </div>
    );
  }
}

DependentTable.propTypes = {
  headerColumn: PropTypes.string,
  data: PropTypes.array.isRequired,
  editSortOrder: PropTypes.func,
  sortOrder: PropTypes.object
};
