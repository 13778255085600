import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import AddLicensesCertification from "../components/AddLicensesCertification";

export default class LicensesCertification extends Component {
  render() {
    const { onCancelClick, setToaster } = this.props;
    return (
      <div>
        <Switch>
          <Route
            exact
            path="/people/employees/dashboard/:EmployeeId/resume/6/setup"
            render={() => (
              <AddLicensesCertification
                {...this.props}
                onCancelClick={onCancelClick}
                editMode={false}
                setToaster={setToaster}
              />
            )}
          />
          <Route
            exact
            path="/people/employees/dashboard/:EmployeeId/resume/6/:LicenseCertificationId"
            render={() => (
              <AddLicensesCertification
                {...this.props}
                onCancelClick={onCancelClick}
                editMode={true}
                setToaster={setToaster}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}
