import React from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import * as Fields from "./../FieldNames";
import IncludedInPayroll from "./../active/IncludedInPayroll";

const TransferDetails = ({ onChange, data = {} }) => {
  return (
    <FormSection title={"Transfer Details"} hr>
      <FormFieldGroup
        inputType="singleDatePicker"
        label="Transfer Date"
        fieldType="inline"
        inputName={Fields.transferredDate}
        data={data[Fields.transferredDate]}
        colSpan="col-lg-3 col-md-3"
        style={{ marginBottom: "15px" }}
        Size={[6, 4, 12, 12]}
        onChange={onChange}
      />
      <IncludedInPayroll
        inputName={Fields.transferredIncludedInPayroll}
        label="Should this employee be included in payroll ?"
        value={data[Fields.transferredIncludedInPayroll]}
        data={data}
        onChange={onChange}
        middleText="From which paycheck date"
      />
      <FormFieldGroup
        inputType="textarea"
        inputName={Fields.transferredNotes}
        label="Notes"
        value={data[Fields.transferredNotes]}
        fieldType="inline"
        description="250 characters max"
        rows={6}
        descriptionStyle="italic"
        colSpan="col-lg-3 col-md-3"
        Size={[8, 8, 12, 12]}
        onChange={onChange}
        optional
      />
    </FormSection>
  );
};

export default TransferDetails;
