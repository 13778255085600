import React, { Component } from "react";
import SemiMonthlyPayPeriodItems from "./SemiMonthlyPayPeriodItems";
import AnuallyPayPeriodItems from "./AnuallyPayPeriodItems";
import { FormFieldGroup } from "../../formSections/index";
import Toaster from "../../toasters";
import Infobox from "./Infobox";
import {
  semiMonthlyPayCheckCount,
  monthlyPayCheckCount,
  semiMonthlyInfoBoxDescription,
  BUSINESS_DAYS,
  billingTypeValidationMessage,
  annuallyPayCheckCount
} from "./Constants";

class SemiMonthlyPayPeriod extends Component {
  state = {
    payPeriodInfo: false
  };

  render() {
    const {
      showValidationMsg,
      BusinessDays,
      billingTypeValidation,
      showLastDateValidation
    } = this.props.data;

    const { onClose, handleRadioChange } = this.props;
    let error;

    if (billingTypeValidation) {
      error = billingTypeValidationMessage;
    } else {
      error = "";
    }

    return (
      <div>
        {this.props.monthly && (
          <SemiMonthlyPayPeriodItems
            mapData={monthlyPayCheckCount}
            {...this.props}
          />
        )}

        {this.props.semimonthly && (
          <SemiMonthlyPayPeriodItems
            mapData={semiMonthlyPayCheckCount}
            {...this.props}
          />
        )}

        {this.props.annually && (
          <AnuallyPayPeriodItems
            mapData={annuallyPayCheckCount}
            {...this.props}
          />
        )}
        {this.props.quartely && (
          <AnuallyPayPeriodItems
            mapData={annuallyPayCheckCount}
            {...this.props}
          />
        )}

        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Toaster
            show={showValidationMsg}
            message="Second paycheck date must be later than the first paycheck date"
            closeToasterMessage={onClose}
            alertClassName="danger"
            icon=""
          />
        </div>
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Toaster
            show={showLastDateValidation}
            message="For paycheck and/or pay period end dates on the 29th, 30th or 31st that could be larger than the number of days in a given month, the last day of the month will be used."
            alertClassName="warning"
            style={{ textAlign: "left" }}
          />
        </div>
        <div className="">
          {this.state.payPeriodInfo ? (
            <Infobox
              description={semiMonthlyInfoBoxDescription}
              onClose={() => this.setState({ payPeriodInfo: false })}
            />
          ) : null}

          <div>
            <FormFieldGroup
              show={true}
              inputId={BUSINESS_DAYS}
              inputType="text"
              inputName={BUSINESS_DAYS}
              label="When do you want to submit your payroll to Netchex?*"
              description="business days prior to paycheck date"
              Size={[1, 1, 2, 4]}
              required={true}
              tooltip="Payroll must be submitted to Netchex at least [n=billing type +1] business days prior to your check date in order to allow Netchex to debit your account timely for funding."
              value={parseInt(BusinessDays, 10)}
              style={{ color: "#555" }}
              error={error}
              onChange={handleRadioChange}
              hideTopdescription={true}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default SemiMonthlyPayPeriod;
