import React from "react";
import { FormFieldGroup, FormSection } from "../../../formSections";
import * as Fields from "./FieldNames";

const Wages = ({ onChange, data, options = {} }) => {
  return (
    <FormSection hr>
      <div className="column-title">Wages</div>

      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.payrollSchedule}
        value={data[Fields.payrollSchedule]}
        options={options[Fields.payrollSchedule].options}
        placeholder="Select"
        label="Payroll Schedule"
        Size={[12, 12, 7, 12]}
        onChange={onChange}
      />
      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.payrollFrequency}
        value={data[Fields.payrollFrequency]}
        options={options[Fields.payrollFrequency].options}
        label="Payroll Frequency"
        placeholder="Select"
        Size={[12, 12, 7, 12]}
        onChange={onChange}
      />
    </FormSection>
  );
};

export default Wages;
