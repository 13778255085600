import filter, {
  getData,
  SET_FILTER_OPTIONS,
  SELECT_ALL_FILTER_OPTIONS,
  SELECT_FILTER_OPTION,
  SELECT_DATERANGE_OPTION
} from "./filter";
import * as utilities from "../utilities";
import { convertUserTimeZone } from "app/navigation/utilities";
import moment from "moment";
import momentTimezone from "moment-timezone";

export const CLEAR_ALL_FILTERS = "activity-history/CLEAR_ALL_FILTERS";
window.moment = momentTimezone;
/** Reducer */
const filters = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_FILTER_OPTIONS:
    case SELECT_FILTER_OPTION:
    case SELECT_DATERANGE_OPTION:
      const { name } = payload;
      return {
        ...state,
        [name]: filter(state[name], action)
      };
    case SELECT_ALL_FILTER_OPTIONS:
      if (payload.name) {
        return {
          ...state,
          [payload.name]: filter(state[payload.name], action)
        };
      } else {
        const newState = {};
        for (const k in state) {
          newState[k] = filter(state[k], action);
        }
        return newState;
      }
    case CLEAR_ALL_FILTERS:
      return {};
    default:
      return state;
  }
};

export default filters;

/** Action Creators */
export const setFilterOptions = (name, options, status = true) => ({
  type: SET_FILTER_OPTIONS,
  payload: { name, options, status }
});

export const selectFilterOption = (name, option, value) => ({
  type: SELECT_FILTER_OPTION,
  payload: { name, option, value }
});

export const selectAllFilterOptions = (name, value) => ({
  type: SELECT_ALL_FILTER_OPTIONS,
  payload: { name, value }
});

export const selectDateRangeOption = (name, fromToValue) => ({
  type: SELECT_DATERANGE_OPTION,
  payload: { name, fromToValue }
});

export const clearAllFilters = () => ({
  type: CLEAR_ALL_FILTERS
});

/** Selectors */
export const getFilterData = (state, name) => getData(state[name]);

const makeValues = ({ options = [], selected = {} }, passSelectAll = false) => {
  const filterItems = [];
  options.forEach(({ value }) => {
    if (selected[value]) {
      filterItems.push(value);
    }
  });

  if (options.length === filterItems.length && !passSelectAll) {
    return "";
  }

  return filterItems.join(", ");
};

export const makeCriterion = (
  fieldName,
  filterData,
  defaultCriterion = null,
  passSelectAll = false
) => {
  if (filterData) {
    const Values = makeValues(filterData, passSelectAll);
    if (Values) {
      return {
        Field: fieldName,
        Operator: "in",
        value:
          fieldName === "EntityInstanceState" && Values === "2" ? "0" : Values
      };
    } else {
      return null;
    }
  } else {
    return defaultCriterion;
  }
};

export const makeStatusCriterion = (fieldName, filterData = {}) => {
  const Values = makeValues(filterData);
  if (Values) {
    return {
      Field: fieldName,
      Operator: "=",
      value: fieldName === "IsActive" && Values === "TRUE" ? "TRUE" : Values
    };
  } else {
    return null;
  }
};

export const makeSortCriterion = sortOrder => {
  if (
    utilities.isNullOrEmpty(sortOrder.Field) ||
    utilities.isNullOrEmpty(sortOrder.Order)
  ) {
    return null;
  } else {
    return [
      {
        Field: sortOrder.Field,
        Order: sortOrder.Order
      }
    ];
  }
};

export const makeDateRangeCriterion = (
  fieldName,
  operator,
  filterData = {},
  userTimeZoneOffset
) => {
  const Values = filterData["selected"];
  if (Values !== undefined) {
    if (
      fieldName === "FromDateTime" &&
      Values.from !== null &&
      Values.from !== undefined
    ) {
      let temp1 = moment(Values.from).format("YYYY-MM-DD");
      let tempFromDateTime = moment(temp1 + " 00:00:00").format(
        "YYYY-MM-DD HH:mm:ss"
      );
      return {
        Field: fieldName,
        Operator: operator,
        value: convertUserTimeZone(
          // userTimeZoneOffset.data,
          "America/Bahia_Banderas",
          tempFromDateTime,
          "YYYY-M-D HH:mm:ss"
        )
        // value: formatTimeforDateRagePicker(Values.from) + " 00:00:00"
      };
    } else if (
      fieldName === "ToDateTime" &&
      Values.to !== null &&
      Values.to !== undefined
    ) {
      let temp2 = moment(Values.to).format("YYYY-MM-DD");

      let tempToDateTime = moment(temp2 + " 23:59:59").format(
        "YYYY-MM-DD HH:mm:ss"
      );
      return {
        Field: fieldName,
        Operator: operator,
        value: convertUserTimeZone(
          // userTimeZoneOffset.data,
          "America/Bahia_Banderas",
          tempToDateTime,
          "YYYY-M-D HH:mm:ss"
        )
        // value: formatTimeforDateRagePicker(Values.to) + " 23:59:59"
      };
    }
  } else {
    return null;
  }
};

export const makeDateRangeCriterionNotifications = (
  fieldName,
  operator,
  filterData = {}
) => {
  const Values = filterData["selected"];
  if (Values !== undefined) {
    if (
      fieldName === "FromDateTime" &&
      Values.from !== null &&
      Values.from !== undefined
    ) {
      let temp1 = moment(Values.from).format("YYYY-MM-DD");
      let tempFromDateTime = moment(temp1 + " 00:00:00").format(
        "YYYY-MM-DD HH:mm:ss"
      );
      return {
        Field: fieldName,
        Operator: operator,
        value: convertUserTimeZone(
          // userTimeZoneOffset.data,
          "America/Bahia_Banderas",
          tempFromDateTime,
          "YYYY-M-D HH:mm:ss"
        )
      };
    } else if (
      fieldName === "ToDateTime" &&
      Values.to !== null &&
      Values.to !== undefined
    ) {
      let temp2 = moment(Values.to).format("YYYY-MM-DD");
      let tempToDateTime = moment(temp2 + " 23:59:59").format(
        "YYYY-MM-DD HH:mm:ss"
      );
      return {
        Field: fieldName,
        Operator: operator,
        value: convertUserTimeZone(
          // userTimeZoneOffset.data,
          "America/Bahia_Banderas",
          tempToDateTime,
          "YYYY-M-D HH:mm:ss"
        )
        // value: formatTimeforDateRagePicker(Values.to) + " 23:59:59"
      };
    }
  } else {
    if (fieldName === "FromDateTime") {
      return {
        Field: fieldName,
        Operator: operator,
        value: momentTimezone()
          .tz("America/Bahia_Banderas")
          .subtract(30, "days")
          .format("YYYY-M-D HH:mm:ss")
      };
    } else if (fieldName === "ToDateTime") {
      return {
        Field: fieldName,
        Operator: operator,
        value: momentTimezone()
          .tz("America/Bahia_Banderas")
          .format("YYYY-M-D HH:mm:ss")
      };
    }
  }
};

export const makeOptions = filters => {
  const filterOptions = {};
  Object.keys(filters).forEach(k => (filterOptions[k] = filters[k].options));
  return filterOptions;
};

export const makeSelectedOptions = filters => {
  const selectedOptions = {};
  Object.keys(filters).forEach(k => (selectedOptions[k] = filters[k].selected));
  return selectedOptions;
};
