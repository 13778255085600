import axios from "axios";
import * as common from "./common";
import PubSub from "pubsub-js";
import { init } from "hubConnection";

export const loaderStyle = {
  display: "block",
  position: "absolute",
  top: "0px",
  left: "0px",
  bottom: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  zIndex: "1051"
};
export function clearSessionFromLocalStorage() {
  localStorage.removeItem("Authorization");
  localStorage.removeItem("SessionContext");
}
export function clearSessionFromSessionStorage() {
  sessionStorage.removeItem("Authorization");
  sessionStorage.removeItem("SessionContext");
}

export function clearAxiosHeader() {
  axios.defaults.headers.common["Authorization"] = null;
  axios.defaults.headers.common["SessionContext"] = null;
}

export function setupWalkMe() {
  // Insert WalkMe Source in DOM
  var walkmeScriptTag = document.createElement('script');

  walkmeScriptTag.type = 'text/javascript';
  walkmeScriptTag.async = true;
  walkmeScriptTag.src = common.WALKME_SOURCE_URL;

  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(walkmeScriptTag, firstScriptTag);

  window._walkmeConfig = { smartLoad: true };
}

export function redirectToDashboard() {
  window.location.href = common.LEGACY_LOGIN_URL + "/n/NewToLegacy?target=1";
}
export function redirectToLoginPage() { }

export function redirectToLegacyPage(path) {
  window.location.href = common.LEGACY_LOGIN_URL + path;
}

export const startSignalR = userId => {
  init(userId, msg => {
    PubSub.publish("signalRNotify", msg);
  });
};
