import React from "react";
import { Modal } from "react-bootstrap";
import "./SnapshotTable.scss";
import "./snapshot.scss";

import classnames from "classnames/bind";
function getBackgroundColor(DeductionPriorityTypeId) {
  if (DeductionPriorityTypeId === 1) {
    return "#e4eef3";
  } else {
    return "#eef6e7";
  }
}
const renderNode = (node, index) => {
  let DeductionPriorityTypeId;
  node.NodeValues[1] === "Federal Tax" ||
  node.NodeValues[1] === "State Tax" ||
  node.NodeValues[1] === "Local Tax"
    ? (DeductionPriorityTypeId = 0)
    : (DeductionPriorityTypeId = 1);

  let background = getBackgroundColor(DeductionPriorityTypeId);

  return (
    <SnapshotTable.Row
      id={"snpahot-row-" + index}
      key={"snpahot-row-" + index}
      style={{ backgroundColor: background }}
    >
      <SnapshotTable.RowItem className="col-lg-2 col-md-2 col-sm-2 col-xs-2 sr-no">
        {node.NodeValues[0]}
      </SnapshotTable.RowItem>
      <SnapshotTable.RowItem className="col-lg-4 col-md-4 col-sm-10 col-xs-10 type">
        <div>{node.NodeValues[1]}</div>
        <div className="break visible-xs visible-sm">{node.NodeValues[2]}</div>
      </SnapshotTable.RowItem>
      <SnapshotTable.RowItem className="col-lg-4 col-md-4 name hidden-sm hidden-xs">
        {node.NodeValues[2]}
      </SnapshotTable.RowItem>
    </SnapshotTable.Row>
  );
};

const EntitySnapshotTable = ({ nodes = [] }) => (
  <Modal.Body>
    <SnapshotTable>
      <SnapshotTable.Header>
        <SnapshotTable.RowItem className="col-lg-2 col-md-2">
          Priority Order
        </SnapshotTable.RowItem>
        <SnapshotTable.RowItem className="col-lg-4 col-md-4">
          Type
        </SnapshotTable.RowItem>
        <SnapshotTable.RowItem className="col-lg-4 col-md-4">
          Name
        </SnapshotTable.RowItem>
        <SnapshotTable.RowItem
          id="deduction-tax-priority-table-header-icon"
          className="col-lg-2 col-md-2"
        />
      </SnapshotTable.Header>

      {nodes.map(renderNode)}
    </SnapshotTable>
  </Modal.Body>
);

export default EntitySnapshotTable;

export const SnapshotTable = props => (
  <div id={props.id} className={classnames("nc-snapshot-table")}>
    {props.children}
  </div>
);
export const SnapshotTableHeader = props => (
  <div
    id={props.id}
    className={classnames("nc-snapshot-table-header hidden-sm hidden-xs")}
  >
    {props.children}
  </div>
);
export const SnapshotTableRowItem = props => (
  <div
    id={props.id}
    className={classnames("nc-snapshot-table-row-item", props.className)}
  >
    {props.children}
  </div>
);
export const SnapshotTableRow = props => (
  <div
    id={props.id}
    className={classnames("nc-snapshot-table-row")}
    style={props.style}
  >
    {props.children}
  </div>
);

SnapshotTable.Header = SnapshotTableHeader;
SnapshotTable.Row = SnapshotTableRow;
SnapshotTable.RowItem = SnapshotTableRowItem;
