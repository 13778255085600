import React from "react";
import DropDown from "../dropDown";
import PropTypes from "prop-types";
import {
  ENTITY_FILTER_NAME,
  CATEGORY_FILTER_NAME,
  STATUS_FILTER_NAME
} from "./Constants";

const labelMaker = (noLabel, allLabel, someLabel, oneLabel) => (o, s, all) =>
  all
    ? allLabel
    : Object.keys(s).filter(k => s[k]).length === 0
    ? noLabel
    : Object.keys(s).filter(k => s[k]).length === 1
    ? oneLabel
    : someLabel;

const EarningsFiltersDesktop = props => {
  const {
    options = {},
    selectedOptions = {},
    makeOnSelect = () => undefined,
    makeSelectAll = () => undefined,
    copyEarnigFilter,
    Id,
    width
  } = props;
  return (
    <div className="nc-filter-container">
      <div className="showing-label" id="filter-label">
        {" "}
        FILTER:
      </div>
      <div
        className="nc-filter-group"
        id="filter-group"
        style={{ width: width ? width : "100%" }}
      >
        <div className="nc-filter-row" id="filter-row1">
          <div className="nc-filter-dropdown">
            <DropDown
              multiSelect
              showSearch
              showBadge
              options={options[ENTITY_FILTER_NAME]}
              selectedItems={selectedOptions[ENTITY_FILTER_NAME]}
              onSelect={makeOnSelect(ENTITY_FILTER_NAME)}
              selectAll={makeSelectAll(ENTITY_FILTER_NAME)}
              makeLabel={labelMaker(
                "Select Earnings",
                "All Earnings",
                "Earnings",
                "Earnings"
              )}
              selectAllLabel="All Earnings"
              searchBoxId="deduction-auto-suggestion-textbox"
              id={Id.entityFilter}
              overlayWidth={"300px"}
            />
          </div>
          <div className="nc-filter-dropdown">
            <DropDown
              multiSelect
              showBadge
              options={options[CATEGORY_FILTER_NAME]}
              selectedItems={selectedOptions[CATEGORY_FILTER_NAME]}
              onSelect={makeOnSelect(CATEGORY_FILTER_NAME)}
              selectAll={makeSelectAll(CATEGORY_FILTER_NAME)}
              makeLabel={labelMaker(
                "Select Category",
                "All Categories",
                "Categories",
                "Category"
              )}
              selectAllLabel="All Categories"
              searchBoxId="category-auto-suggestion-textbox"
              id={Id.categoryFilter}
            />
          </div>
          {copyEarnigFilter ? (
            ""
          ) : (
            <div className="nc-filter-dropdown">
              <DropDown
                multiSelect
                showBadge
                options={options[STATUS_FILTER_NAME]}
                selectedItems={selectedOptions[STATUS_FILTER_NAME]}
                onSelect={makeOnSelect(STATUS_FILTER_NAME)}
                selectAll={makeSelectAll(STATUS_FILTER_NAME)}
                makeLabel={labelMaker(
                  "Select Status",
                  "All Statuses",
                  "Statuses",
                  "Status"
                )}
                selectAllLabel="All Statuses"
                searchBoxId="status-auto-suggestion-textbox"
                id={Id.statusFilter}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default EarningsFiltersDesktop;
EarningsFiltersDesktop.propTypes = {
  options: PropTypes.object,
  selectedOptions: PropTypes.object,
  makeOnSelect: PropTypes.func,
  makeSelectAll: PropTypes.func,
  copyEarnigFilter: PropTypes.bool
};
EarningsFiltersDesktop.defaultProps = {
  options: {},
  selectedOptions: {},
  makeOnSelect: () => undefined,
  makeSelectAll: () => undefined
};
