import {
  getFmlaReasons,
  getNonFmlaReasons,
  getLeaveTypes,
  getTerminationReasons,
  getTerminationTypes
} from ".";
import { setFilterOptions } from "app/common/reducers/filters";

export const fetchAndSetFmlaReasons = (FILTER_NAME = "Fmla") => dispatch =>
  getFmlaReasons().then(res => {
    const { Data = [] } = res.data;
    const dispatchData = [];
    Data.map(item =>
      dispatchData.push({ id: item.Id, value: item.Id, label: item.Name })
    );
    dispatch(setFilterOptions(FILTER_NAME, dispatchData));
  });

export const fetchAndSetNonFmlaReasons = (
  FILTER_NAME = "NonFmla"
) => dispatch =>
  getNonFmlaReasons().then(res => {
    const { Data = [] } = res.data;
    const dispatchData = [];
    Data.map(item =>
      dispatchData.push({ id: item.Id, value: item.Id, label: item.Name })
    );
    dispatch(setFilterOptions(FILTER_NAME, dispatchData));
  });

export const fetchandSetLeaveTypes = () => dispatch =>
  getLeaveTypes().then(res => console.log(res.data));

export const fetchAndSetTerminationReasons = () => dispatch =>
  getTerminationReasons().then(res => console.log(res.data));

export const fetchandSetTerminationTypes = () => dispatch =>
  getTerminationTypes().then(res => console.log(res.data));
