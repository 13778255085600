export const FETCH_CATEGORY_LIST = "FETCH_CATEGORY_LIST";

export const FETCH_DEDUCTION_LIST = "FETCH_DEDUCTION_LIST";

export const FETCH_STATUS_LIST = "FETCH_STATUS_LIST";

export const FETCH_DEDUCTION_SETUP_LIST = "FETCH_DEDUCTION_SETUP_LIST";

export const SYSTEM_DEDUCTION_MAIN_LIST = "SYSTEM_DEDUCTION_MAIN_LIST";

export const SYSTEM_DEDUCTION_FILTERED_LIST = "SYSTEM_DEDUCTION_FILTERED_LIST";

export const FETCH_DEDUCTION_DELETE_ICON_CLICK_LIST =
  "FETCH_DEDUCTION_DELETE_ICON_CLICK_LIST";

export const FETCH_EXCLUDE_STATES_VALUES_LIST =
  "FETCH_EXCLUDE_STATES_VALUES_LIST";

export const COUNTRY_STATE_LIST = "COUNTRY_STATE_LIST";

export const SYSTEM_THIRD_PARTY_PAYEE_MAIN_LIST =
  "SYSTEM_THIRD_PARTY_PAYEE_MAIN_LIST";

export const SYSTEM_THIRD_PARTY_PAYEE_FILTERED_LIST =
  "SYSTEM_THIRD_PARTY_PAYEE_FILTERED_LIST";

export const FETCH_THIRD_PARTY_PAYEE_DELETE_ICON_CLICK_LIST =
  "FETCH_THIRD_PARTY_PAYEE_DELETE_ICON_CLICK_LIST";

export const FETCH_THIRD_PARTY_PAYEE_CATEGORY_LIST =
  "FETCH_THIRD_PARTY_PAYEE_CATEGORY_LIST";

export const FETCH_THIRD_PARTY_PAYEE_STATUS_LIST =
  "FETCH_THIRD_PARTY_PAYEE_STATUS_LIST";

export const SYSTEM_THIRD_PARTY_PAYEE_DATA = "SYSTEM_THIRD_PARTY_PAYEE_DATA";

export const SYSTEM_THIRD_PARTY_PAYEE_TOASTER_MESSAGE =
  "SYSTEM_THIRD_PARTY_PAYEE_TOASTER_MESSAGE";

export const SYSTEM_THIRD_PARTY_PAYEE_BUTTON_TYPE =
  "SYSTEM_THIRD_PARTY_PAYEE_BUTTON_TYPE";

export const SYSTEM_GET_REVIEW_SAVE_SNAPSHOT =
  "SYSTEM_GET_REVIEW_SAVE_SNAPSHOT";

export const SYSTEM_GET_TPP_REVIEW_SAVE_SNAPSHOT =
  "SYSTEM_GET_TPP_REVIEW_SAVE_SNAPSHOT";

export const FETCH_THIRD_PARTY_PAYEE_PAYMENT_TYPE =
  "FETCH_THIRD_PARTY_PAYEE_PAYMENT_TYPE";

export const FETCH_THIRD_PARTY_PAYEE_ACCOUNT_TYPE =
  "FETCH_THIRD_PARTY_PAYEE_ACCOUNT_TYPE";

export const FETCH_THIRD_PARTY_PAYEE_PRINT_SSN_ON_CHECK_OPTION =
  "FETCH_THIRD_PARTY_PAYEE_PRINT_SSN_ON_CHECK_OPTION";

export const ZIP_CODE = "ZIP_CODE";

export const THIRD_PARTY_PAYEE_DATA = "THIRD_PARTY_PAYEE_DATA";

export const FETCH_CATEGORY_OPTIONS_LIST = "FETCH_CATEGORY_OPTIONS_LIST";

export const FETCH_DEDUCTION_VALUES_LIST = "FETCH_DEDUCTION_VALUES_LIST";

export const FETCH_ENTERPRISE_PAYEE_OPTIONS_LIST =
  "FETCH_ENTERPRISE_PAYEE_OPTIONS_LIST";

export const FETCH_ENTERPRISE_PAYEE_DROPDOWN_LIST =
  "FETCH_ENTERPRISE_PAYEE_DROPDOWN_LIST";
export const FETCH_CALCULATION_METHOD_OPTIONS_LIST =
  "FETCH_CALCULATION_METHOD_OPTIONS_LIST";
export const FETCH_TAXABILITY_VALUES_LIST = "FETCH_TAXABILITY_VALUES_LIST";

export const FETCH_DEDUCTION_AMOUNT_DEBIT_CARD_VALUES_LIST =
  "FETCH_DEDUCTION_AMOUNT_DEBIT_CARD_VALUES_LIST";
export const FETCH_EARNING_PERCENT_OPTIONS_LIST =
  "FETCH_EARNING_PERCENT_OPTIONS_LIST";

export const FETCH_ADVANCED_PRETAX_OPTIONS_LIST =
  "FETCH_ADVANCED_PRETAX_OPTIONS_LIST";

export const FETCH_ADVANCED_RETIREMENT_OPTIONS_LIST =
  "FETCH_ADVANCED_RETIREMENT_OPTIONS_LIST";

export const FETCH_GARNISHMENT_EARNINGS_OPTIONS_LIST =
  "FETCH_GARNISHMENT_EARNINGS_OPTIONS_LIST";

export const FETCH_ACA_DEDUCTION_OPTIONS_LIST =
  "FETCH_ACA_DEDUCTION_OPTIONS_LIST";

export const FETCH_EMPLOYEE_AMOUNT_VALUE_LIST =
  "FETCH_EMPLOYEE_AMOUNT_VALUE_LIST";

export const FETCH_SYMMETRY_TAX_CODE_LIST =
  "systemdeduction/FETCH_SYMMETRY_TAX_CODE_LIST";

export const SYSTEM_DEDUCTION_DATA = "SYSTEM_DEDUCTION_DATA";

export const SYSTEM_DEDUCTION_SAVED_PAGES = "SYSTEM_DEDUCTION_SAVED_PAGES";

export const FETCH_PAYROLL_DEDUCTION_LIST = "FETCH_PAYROLL_DEDUCTION_LIST";

export const FETCH_OTHER_WITHELD_DEDUCTION_LIST =
  "FETCH_OTHER_WITHELD_DEDUCTION_LIST";

export const FETCH_SCHEDULED_PAYROLL_WITHELD_DEDUCTION_LIST =
  "FETCH_SCHEDULED_PAYROLL_WITHELD_DEDUCTION_LIST";

export const FETCH_VENDOR_EMPLOYEE_DEDUCTION_DROPDOWN_LIST =
  "FETCH_VENDOR_EMPLOYEE_DEDUCTION_DROPDOWN_LIST";

export const FETCH_VENDOR_EMPLOYER_DEDUCTION_DROPDOWN_LIST =
  "FETCH_VENDOR_EMPLOYER_DEDUCTION_DROPDOWN_LIST";

export const SYSTEM_DEDUCTION_SELECTED_FILTERS =
  "SYSTEM_DEDUCTION_SELECTED_FILTERS";
