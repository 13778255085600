export const getGlobalNavSetupData = (
  data = [],
  menuId,
  globalNavSetupData = []
) => {
  data.forEach(item => {
    if (item.Id === menuId) {
      globalNavSetupData.push(item);
      getGlobalNavSetupData(data, item.ParentId, globalNavSetupData);
    }
  });
  let tempList = {};
  if (globalNavSetupData.length === 1) {
    let parentName =
      globalNavSetupData[0].ParentName !== null
        ? globalNavSetupData[0].ParentName
        : globalNavSetupData[0].MenuName;
    tempList.selectedProductId = globalNavSetupData[0].Id;
    tempList.parentName = parentName;
    tempList.selectedTopLevelId = 0;
    tempList.selectedSidebarId = 0;
    tempList.selectedSidebarLeafId = 0;
    tempList.Url = globalNavSetupData[0].Url;
    tempList.showSideBar = false;
  } else if (globalNavSetupData.length === 2) {
    let parentName =
      globalNavSetupData[0].ParentName !== null
        ? globalNavSetupData[0].ParentName
        : globalNavSetupData[0].MenuName;
    tempList.selectedProductId = globalNavSetupData[1].Id;
    tempList.selectedTopLevelId = globalNavSetupData[0].Id;
    tempList.parentName = parentName;
    tempList.selectedSidebarId = globalNavSetupData[1].Id;
    tempList.selectedSidebarLeafId = globalNavSetupData[0].Id;
    tempList.Url = globalNavSetupData[0].Url;
    tempList.showSideBar = false;
  } else if (globalNavSetupData.length === 3) {
    tempList.selectedProductId = globalNavSetupData[2].Id;
    tempList.selectedTopLevelId = globalNavSetupData[1].Id;
    tempList.selectedSidebarId = globalNavSetupData[1].Id;
    tempList.selectedSidebarLeafId = globalNavSetupData[0].Id;
    tempList.parentName = globalNavSetupData[1].ParentName;
    tempList.Url = globalNavSetupData[0].Url;
    tempList.showSideBar = true;
  } else if (globalNavSetupData.length === 4) {
    tempList.selectedProductId = globalNavSetupData[3].Id;
    tempList.selectedTopLevelId = globalNavSetupData[2].Id;
    tempList.selectedSidebarId = globalNavSetupData[1].Id;
    tempList.selectedSidebarLeafId = globalNavSetupData[0].Id;
    tempList.parentName = globalNavSetupData[2].ParentName;
    tempList.Url = globalNavSetupData[0].Url;
    tempList.showSideBar = true;
  } else if (globalNavSetupData.length > 4) {
    let length = globalNavSetupData.length;
    tempList.selectedProductId = globalNavSetupData[length - 1].Id;
    tempList.selectedTopLevelId = globalNavSetupData[length - 2].Id;
    tempList.selectedSidebarId = globalNavSetupData[1].Id;
    tempList.selectedSidebarLeafId = globalNavSetupData[0].Id;
    tempList.parentName = globalNavSetupData[length - 2].ParentName;
    tempList.Url = globalNavSetupData[0].Url;
    tempList.showSideBar = true;
  } else {
    tempList.selectedProductId = 0;
    tempList.selectedTopLevelId = 0;
    tempList.selectedSidebarId = 0;
    tempList.selectedSidebarLeafId = 0;
    tempList.Url = "";
    tempList.showSideBar = false;
  }

  return tempList;
};
