import React from "react";

const Location = ({ height, width, style }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 57.5 55.9"
      height={height}
      width={width}
      style={{ enableBackground: "new 0 0 57.5 55.9", ...style }}
      xmlSpace="preserve"
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{ __html: "\n\t.st0{fill:#555555;}\n" }}
      />
      <g>
        <g>
          <path
            className="st0"
            d="M52.1,52l-5.6-18c-0.1-0.4-0.5-0.8-0.9-0.9l-2.9-0.7c-0.5-0.1-1,0.1-1.3,0.5C41,33.2,41,33.6,41.1,34
			s0.5,0.7,0.9,0.8l2.1,0.6c0.3,0.1,0.4,0.3,0.5,0.5L49,49.5c0.1,0.3,0,0.6-0.2,0.8s-0.5,0.3-0.8,0.2l-6.8-1.9
			c-0.2-0.1-0.5-0.1-0.7,0l-10.9,3c-0.1,0-0.3,0-0.4,0l-10.9-3c-0.2-0.1-0.5-0.1-0.7,0l-6.8,1.9c-0.3,0.1-0.6,0-0.8-0.2
			s-0.3-0.5-0.2-0.8l4.3-13.6c0.1-0.2,0.3-0.4,0.5-0.5l2.1-0.6c0.4-0.1,0.7-0.4,0.9-0.8s0.1-0.8-0.2-1.2c-0.3-0.4-0.8-0.6-1.3-0.5
			L13.3,33c-0.4,0.1-0.8,0.4-0.9,0.9l-5.6,18c-0.1,0.5,0,0.9,0.3,1.3c0.3,0.3,0.8,0.5,1.3,0.3l9.4-2.6c0.1,0,0.3,0,0.4,0L29,54
			c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0l10.9-3c0.1,0,0.3,0,0.4,0l9.6,2.6c0.5,0.1,0.9,0,1.2-0.3C52.1,52.9,52.2,52.4,52.1,52z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M14.8,26.8l12,16.3c0.6,0.8,1.5,1.3,2.6,1.3s2-0.5,2.6-1.3l12-16.3h-0.1c1.6-2.6,2.4-5.6,2.4-8.7
			c0-4.7-1.9-9.1-5.3-12.3c-3.6-3.1-8-4.7-12.7-4.5c-8.4,0.5-15.3,7.6-15.7,16c-0.1,3.4,0.7,6.6,2.4,9.4h-0.2V26.8z M41.3,26.7
			l-10,13.8c-0.5,0.6-1.2,1-2,1s-1.5-0.4-2-1l-10-13.8l0,0l-0.2-0.4c-1.5-2.3-2.3-5-2.3-7.9c0-8.1,6.5-14.7,14.4-14.7
			s14.4,6.6,14.4,14.7c0,2.9-0.8,5.6-2.3,7.9V26.7L41.3,26.7z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M29.3,11.5c-3.7,0-6.6,3-6.6,6.6c0,3.7,3,6.6,6.6,6.6s6.6-3,6.6-6.6C36,14.5,33,11.5,29.3,11.5z M33.5,18.1
			c0,2.4-1.8,4.2-4.2,4.2s-4.2-1.8-4.2-4.2s1.8-4.2,4.2-4.2S33.5,15.8,33.5,18.1z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Location;
