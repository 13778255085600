import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import "../systemlevel/deductionsetup/DeductionStyles.css";
import AddGeneralLedger from "./AddGeneralLedger";

class GeneralLedgerDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };

    this.onOpenAddModal = this.onOpenAddModal.bind(this); //modal
    this.onCloseAddModal = this.onCloseAddModal.bind(this); //modal
    this.onClickActivityHistory = this.onClickActivityHistory.bind(this);
  }

  onOpenAddModal() {
    this.setState({ open: true });
  }

  onCloseAddModal() {
    this.setState({ open: false });
  }

  onClickActivityHistory() {
    this.props.onClickActivityHistoryButton(() =>
      this.props.history.push("/gl-account-deductions-activity-history")
    );
  }

  render() {
    const { Id, showHistoryButton } = this.props;
    return (
      <div id="deduction-page-header-container">
        <div className="pageTitle-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div
            id={Id.pageTitle}
            className="pageTitle col-lg-7 col-md-6 col-sm-8 col-xs-12"
          >
            Map General Ledger Accounts - Deductions
          </div>

          <div className="nc-navigation-button-family hidden-sm hidden-xs col-lg-5 col-md-6 col-sm-12 col-xs-12">
            {showHistoryButton && (
              <button
                id={Id.historyButton}
                className="nc-button outline-button"
                onClick={this.onClickActivityHistory}
              >
                <i className="fa fa-history" aria-hidden="true" /> HISTORY
              </button>
            )}

            <button
              id={Id.addButton}
              type="button"
              className="nc-button primary-button pull-right"
              onClick={this.onOpenAddModal}
            >
              <i className="fa fa-plus"> </i>
              ADD GL ACCOUNT
            </button>
            <Modal
              bsClass="entity-page-add-modal-sm modal"
              id="gl-popup-modal"
              show={this.state.open}
              onHide={this.onCloseAddModal}
              backdrop={"static"}
              keyboard={false}
            >
              <Modal.Header style={{ float: "right", borderBottom: "none" }}>
                <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <i
                    id="system-level-cancel-button-close-icon"
                    onClick={this.onCloseAddModal}
                    className="fa fa-times-circle-o fa-lg"
                  />
                </div>
              </Modal.Header>
              <Modal.Body style={{ clear: "both" }}>
                <div className="modal-row">
                  <AddGeneralLedger
                    onPopOverCancel={() => this.onCloseAddModal()}
                  />
                </div>
              </Modal.Body>

              {/* <div className="company-level-listing-page-add-deduction-modal-content modal-content remove-padding-left-right col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <Modal.Body className="netchex-add-deduction-modal remove-padding-left-right col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <AddGeneralLedger
                    onPopOverCancel={() => this.onCloseAddModal()}
                  />
                </Modal.Body>
              </div> */}
            </Modal>
          </div>
        </div>

        <div className="deduction-page-description-container">
          <div
            id={Id.pageDescription}
            className="deduction-page-description-content col-lg-7 col-md-7 col-sm-12 col-xs-12"
          >
            <p>
              Deduction expenses and liabilities can be grouped together on the
              General Ledger Transactions report by associating them with base
              general ledger accounts. Map each of the Deductions below to a
              corresponding expense and liability account.
            </p>
          </div>

          <div className="deduction-page-description-button col-lg-5 col-md-5 col-sm-12 col-xs-12">
            <div className="nc-navigation-button-family col-xs-12 hidden-lg hidden-md">
              <div className="primary-group">
                <button
                  id="add-deduction-button"
                  type="button"
                  className="nc-button primary-button"
                  onClick={this.onOpenAddModal}
                >
                  <i className="fa fa-plus" />
                  <span className="hidden-lg hidden-md ">
                    &nbsp; ADD GL ACCOUNT
                  </span>
                </button>
              </div>
              {showHistoryButton && (
                <div className="secondary-group">
                  <button
                    id="map-gl-accounts-activity-history-button"
                    className="nc-button outline-fa-button"
                    onClick={this.onClickActivityHistory}
                  >
                    <i className="fa fa-history" aria-hidden="true" />
                    <span className="hidden-lg hidden-md hidden-xs">
                      &nbsp; HISTORY
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(GeneralLedgerDescription);
