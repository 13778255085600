import React from "react";
import PropTypes from "prop-types";
const SortFilterButton = ({
  sortHandler,
  filterHandler,
  filterCount,
  Id,
  style
}) => {
  return (
    <div className="nc-sort-filter-container col-sm-12 col-xs-12" style={style}>
      <button id={Id.sortButtonMobile} onClick={sortHandler} className="">
        SORT BY
      </button>
      <button id={Id.filterButtonMobile} onClick={filterHandler} className="">
        FILTERS{" "}
        {filterCount === 0 ? null : (
          <span id={Id.filterCountMobile}>{"(" + filterCount + ")"}</span>
        )}
      </button>
    </div>
  );
};
export default SortFilterButton;
SortFilterButton.propTypes = {
  sortHandler: PropTypes.func,
  filterHandler: PropTypes.func,
  filterCount: PropTypes.number,
  Id: PropTypes.object
};
SortFilterButton.defaultProps = {
  sortHandler: () => {},
  filterHandler: () => {},
  filterCount: 0,
  Id: {}
};
