import React from "react";
import { FormFieldGroup } from "../../../formSections";
import SampleImage from "../../../payroll-holiday-calendar/Images/NoHoliday";

const IdentificationField = props => {
  const { data = {}, onChange } = props;
  return (
    <div className="contact-info">
      <div className="contact-info col-lg-3">
        <SampleImage width={45} height={45} />
        <div
          style={
            props.optional
              ? { paddingLeft: "15px", fontSize: "15px", marginTop: "15px" }
              : { paddingLeft: "15px", fontSize: "15px" }
          }
        >
          <div>{props.label}</div>
          {props.optional && <i style={{ color: "#999" }}>(Optional)</i>}
        </div>
      </div>
      <FormFieldGroup
        value={data[props.inputName]}
        onChange={!props.disabled && onChange}
        show={true}
        inputType={props.inputType}
        inputId={props.inputName}
        inputName={props.inputName}
        required={props.required}
        Size={props.Size}
        className={"alt-emp-identification-item"}
        disabled={props.disabled}
      />
    </div>
  );
};

export default IdentificationField;
