import React from "react";
import getSvg from "../utils/getSvg";

const WageTaxValidationTabs = ({
  label,
  value,
  svgKey,
  badgeNumber,
  onClick = () => {},
  customLabelClass,
  selected
}) => (
  <div className={`wage-tax-icon-horizontal`} onClick={() => onClick(value)}>
    <div
      id="tab-image-icon"
      className="wage-tax-icon-image visible-lg visible-md"
    >
      {getSvg(svgKey)}
    </div>
    <div
      id="tab-name-icon"
      className={`wage-tax-icon-name ${customLabelClass &&
        customLabelClass} ${selected && "wage-tax-selected-tab"}`}
    >
      {label}{" "}
    </div>
  </div>
);

export default WageTaxValidationTabs;
