const SET_PAYROLL_SCHEDULE = "payroll-calendar/SET_PAYROLL_SCHEDULE";

const payrollSchedule = (state = [], { type, payload }) => {
  switch (type) {
    case SET_PAYROLL_SCHEDULE:
      return payload;
    default:
      return state;
  }
};

export default payrollSchedule;

export const setPayrollSchedule = data => ({
  type: SET_PAYROLL_SCHEDULE,
  payload: data
});

export const clearPayrollSchedule = () => setPayrollSchedule([]);
