import React, { Component } from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import {
  secondaryContact,
  USE_SAME_ADDRESS_LABEL,
  emergencyQuestions
} from "../Constants";
import EmergencyContactCheckbox from "./Checkbox";

class SecondaryContact extends Component {
  state = {
    selected: false
  };
  renderSecondaryContact = componentProps => {
    return Object.keys(componentProps).map(k => {
      return (
        <FormFieldGroup
          {...componentProps[k]}
          key={k}
          block
          colSpan="col-md-4"
          required={true}
          value={this.props.data[componentProps[k]["inputName"]]}
          onChange={this.props.onChange}
          error={this.props.data.errors[componentProps[k]["inputName"]]}
        />
      );
    });
  };
  render() {
    const { data } = this.props;
    const relationshipData =
      data.initialData && data.initialData.relationship
        ? data.initialData.relationship
        : [];
    let componentProps = secondaryContact;
    componentProps.secContactRelationship.options = relationshipData;
    componentProps.secContactState.options =
      data.initialData && data.initialData.secContactState
        ? data.initialData.secContactState
        : [];
    const {
      secContactAddress1,
      secContactAddress2,
      secContactZipCode,
      secContactCity,
      secContactState,
      ...restOfAddress
    } = componentProps;

    const {
      secContactName,
      secContactRelationship,
      secContactPersonalEmail,
      secContactHomePhone,
      secContactCellPhoneNumber,
      secContactWorkPhoneNumber,
      ...restOfComponentProps
    } = componentProps;
    return (
      <FormSection title="Secondary Contact" hr>
        <div className="col-md-9" style={{ padding: "0px" }}>
          <div
            style={{
              padding: "10px 15px",
              fontSize: "16px",
              fontWeight: "600"
            }}
          >
            {emergencyQuestions.secondaryContact[0]}
          </div>
          {this.renderSecondaryContact(restOfAddress)}
          <div
            style={{
              padding: "10px 15px",
              fontSize: "16px",
              fontWeight: "600"
            }}
          >
            {emergencyQuestions.secondaryContact[1]}
          </div>
          <EmergencyContactCheckbox
            label={USE_SAME_ADDRESS_LABEL}
            selected={this.state.selected}
            onClick={() => {
              this.setState({
                selected: !this.state.selected
              });
            }}
          />
          {this.renderSecondaryContact(restOfComponentProps)}
        </div>
      </FormSection>
    );
  }
}

export default SecondaryContact;
