import React from "react";
import "./styles.css";
import BussinessComponent from "./BussinessComponent";
import PropTypes from "prop-types";
import getSvg from "./../images/getSvg";
import * as c from "./../images/Constants";
import moment from "moment";

const formatTime = timeStr => {
  if (timeStr === "") {
    return "";
  }
  return moment(new Date(timeStr + "+00:00")).format("DD/MM/YYYY");
};

const Header = ({ item = {}, onEditProfileClick, image, onCopyClick }) => {
  const { Demographics = {}, WorkplaceInfo = {} } = item;
  let divBUDept = "";
  let employeeName = "";

  if (WorkplaceInfo.DivisionCode) {
    divBUDept = `${WorkplaceInfo.DivisionCode}/${
      WorkplaceInfo.BusinessUnitCode
    }/${WorkplaceInfo.DepartmentCode} - ${WorkplaceInfo.LocationName}`;
  }

  if (Demographics.FirstName) {
    employeeName = `${Demographics.FirstName} ${Demographics.LastName}`;
  }

  return (
    <div className="profile-view-header">
      <div className="profile-image">
        <img src={image} alt={employeeName} height="200" />
        <div className="edit-link">
          <a onClick={onEditProfileClick}>
            {" "}
            <i className="fa fa-camera" /> Edit
          </a>
        </div>
      </div>
      <div className="profile-information">
        <div className="personal-information">
          {" "}
          <div className="title">
            {employeeName}
            <button className="nc-button outline-button" onClick={onCopyClick}>
              <i className="fa fa-copy" />COPY
            </button>
          </div>
          <div className="description">
            <div style={{ fontSize: "22px", fontWeight: "600" }}>
              {item.Designation}
            </div>
            <div>
              {" "}
              <span className={`field-value status-tag active`}>
                {item.StatusName}
              </span>
            </div>
            <div>
              <i>{item.AccessLevel}</i>
            </div>
          </div>
        </div>
        <div className="bussiness-information">
          <BussinessComponent
            fieldLabel="Hire Date"
            fieldValue={formatTime(item.EmployeeCurrentStatusDate)}
            image={getSvg(c.HIRE_DATE)}
          />
          <BussinessComponent
            fieldLabel="Employee Id"
            fieldValue={
              item.EmployeeIdentification && item.EmployeeIdentification
            }
            image={getSvg(c.EMPLOYEE_ID)}
          />
          <BussinessComponent
            fieldLabel="Div/Bus/Dept"
            fieldValue={divBUDept}
            image={getSvg(c.DIV_UNIT)}
          />
          <BussinessComponent
            fieldLabel="Payroll Schedule"
            fieldValue={item.PayrollScheduleName}
            image={getSvg(c.PAYROLL_SCHEDULE)}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
Header.propTypes = {
  onEditProfileClick: PropTypes.func,
  onCopyClick: PropTypes.func,
  item: PropTypes.object,
  image: PropTypes.string
};
Header.defaultProps = {
  onEditProfileClick: () => alert("Specify the handler to edit profile pic "),
  onCopyClick: () => alert("Specify the Copy Button Handler"),
  image:
    "https://www.scheller.gatech.edu/thumb/width/600/cropratio/1:1/pix/directory/zhang_laurina_profile.jpg",
  item: {
    Designation: "UI/UX DESIGNER",
    StatusName: "Active",
    AccessLevel: "Full Employee Access",
    EmployeeCurrentStatusDate: "2017-01-07T21:00:00",
    EmployeeId: "E1020",
    PayrollScheduleName: "Bi-Weekly payroll Schedule",
    Demographics: {
      FirstName: "",
      LastName: ""
    },
    WorkplaceInfo: {
      DivisionCode: "",
      BussinessUnitCode: "",
      DepartmentCode: ""
    }
  }
};
