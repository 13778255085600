export const SET_TOGGLE = "@app/common/SET_TOGGLE";
export const CLEAR_TOGGLE = "@app/common/CLEAR_TOGGLE";
export const SET_COMPANY_ALERT_SETUP_ID =
  "@app/common/SET_COMPANY_ALERT_SETUP_ID";
export const CLEAR_COMPANY_ALERT_SETUP_ID =
  "@app/common/CLEAR_COMPANY_ALERT_SETUP_ID";

export const toggleEntity = (state = [], { type, payload }) => {
  switch (type) {
    case SET_TOGGLE:
      return payload;
    case CLEAR_TOGGLE:
      return [];
    default:
      return state;
  }
};

//export default toggleEntity;

export const companySetupId = (state = 0, { type, payload }) => {
  switch (type) {
    case SET_COMPANY_ALERT_SETUP_ID:
      return payload;
    case CLEAR_COMPANY_ALERT_SETUP_ID:
      return 0;
    default:
      return 0;
  }
};

export const setToggleValue = value => ({
  type: SET_TOGGLE,
  payload: value
});

export const clearToggle = () => ({
  type: CLEAR_TOGGLE
});

export const setCompanyAlertSetupId = id => ({
  type: SET_COMPANY_ALERT_SETUP_ID,
  payload: id
});

export const clearCompanyAlertSetupId = () => ({
  type: CLEAR_COMPANY_ALERT_SETUP_ID
});
