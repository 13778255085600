import React from "react";
import Media from "react-media";
import PropTypes from "prop-types";

const NotificationUserItem = ({ id, name, picUrl }) => (
  <Media query="(max-width: 991px)">
    {isMobileTablet => {
      return isMobileTablet ? (
        <div className="notification-user-icon-horizontal">
          <div className="notification-user-image">
            <img
              src={picUrl}
              alt={name}
              width="37"
              height="40"
              id={`user-profile-url-${id}`}
            />
          </div>
          <div className="notification-user-name" id={`user-name-${id}`}>
            {name}
          </div>
        </div>
      ) : (
        <div className="notification-user-icon-horizontal pull-right">
          <div className="notification-user-name" id={`user-name-${id}`}>
            {name}
          </div>
          <div className="notification-user-image">
            <img
              src={picUrl}
              alt={name}
              width="37"
              height="40"
              id={`user-profile-url-${id}`}
            />
          </div>
        </div>
      );
    }}
  </Media>
);

export default NotificationUserItem;

NotificationUserItem.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.func,
    PropTypes.number,
    PropTypes.object,
    PropTypes.string
  ]),
  id: PropTypes.number,
  name: PropTypes.string,
  picUrl: PropTypes.string
};

NotificationUserItem.defaultProps = {
  id: 0,
  name: "User Name",
  picUrl: ""
};
