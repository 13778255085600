export const SET_COMMON_HOLIDAY_LIST = "SET_COMMON_HOLIDAY_LIST";
export const CLEAR_COMMON_HOLIDAY_LIST = "CLEAR_COMMON_HOLIDAY_LIST";

const commonHolidayList = (state = [], { type, payload }) => {
  switch (type) {
    case SET_COMMON_HOLIDAY_LIST:
      return payload;
    case CLEAR_COMMON_HOLIDAY_LIST:
      return [];
    default:
      return state;
  }
};

export default commonHolidayList;

export const setCommonHolidayList = (name, options) => ({
  type: SET_COMMON_HOLIDAY_LIST,
  payload: { name, options }
});

export const clearCommonHolidayList = () => ({
  type: CLEAR_COMMON_HOLIDAY_LIST
});
