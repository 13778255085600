import React, { Component } from "react";
import PayCheckCalendar from "../../component-library/dashboard-datepicker";
import PayrollDashBoardModal from "../../component-library/payroll-dashboard/payroll-dashboard-modals/index";
export default class PayrollDashboardSummaryPaycheckDate extends Component {
  state = {};
  getFromToValue = (name, fromToValue) => {
    this.setState({ show: !this.state.show, openInfoModal: true });
  };

  informationModal = () => {
    return (
      <div className="modal-body-container">
        <div className="modal-body-header">
          You have selected a check date that is not in the current year.
        </div>
        <div className="modal-body-content">
          <div
            style={{ marginBottom: "20px" }}
            className="clearfix content-section"
          >
            Please <span className="primary-link-button">submit a case</span> in
            NetCommunity so that Netchex can begin the rollover process or{" "}
            <span className="primary-link-button">select a new check date</span>.
          </div>
          <div className="clearfix content-section">
            <div className="secondary-header">
              Some of your information from 2017 will automatically be rolled
              over to 2018:
            </div>
            <div className="clearfix list-section">
              <ul>
                <li>
                  All wages for this payroll will be included on 2018's W-2's
                </li>
                <li>
                  Deduction whose annual limits have been met will resume
                  withholding (i.e. retirement plan contributions)
                </li>
                <li>
                  Taxes whose annual limits have been met will resume
                  withholding (i.e. FUTA/SUTA/Social Security)
                </li>
              </ul>
            </div>
          </div>
          <div className="clearfix content-section">
            <span className="primary-link-button">Visit Year End Central</span>{" "}
            for more information on the year end process.
          </div>
        </div>
      </div>
    );
  };

  closeModal = () => {
    this.setState({
      openInfoModal: false
    });
  };

  closeFooterButton = () => {
    const modalButton = [
      {
        label: "Close",
        buttonType: "secondary-button",
        onClick: this.closeModal
      }
    ];

    return modalButton;
  };

  render() {
    const { label, lvalue, visible = true, isAllowEdit = true } = this.props;
    if (visible)
      return (
        <div className="payroll-dash-summary-item clearfix">
          <div className="payroll-dash-summary-item-label">{label}</div>
          <div className="payroll-dash-summary-item-value">{lvalue}</div>
          <div className="payroll-dash-summary-item-icon">
            {isAllowEdit && (
              <PayCheckCalendar
                getFromToValue={(name, fromToValue) =>
                  this.getFromToValue("daterange", fromToValue)
                }
                container={this}
              />
            )}
          </div>

          <PayrollDashBoardModal
            show={this.state.openInfoModal}
            bodyContent={this.informationModal()}
            closeModal={this.closeModal}
            footerButtons={this.closeFooterButton()}
          />
        </div>
      );
    else {
      return null;
    }
  }
}
