/** Actions */
export const ADD_TO_JOB_HISTORY = "job-history/ADD_TO_JOB_HISTORY";
export const CLEAR_JOB_HISTORY = "job-history/CLEAR_JOB_HISTORY";
export const SET_JOB_HISTORY_LOADING = "job-history/SET_JOB_HISTORY_LOADING";

export const initialState = {
  loading: true,
  Data: [],
  DataCount: 0,
  NextUri: null
};

export const filterNewData = (stateData, newData) => {
  const ids = stateData.map(a => a.JobId);
  return newData.filter(d => ids.indexOf(d.JobId) === -1);
};

/** Reducer */
const jobHistoryItems = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_JOB_HISTORY:
      return initialState;
    case ADD_TO_JOB_HISTORY: {
      const { Data, NextUri } = payload;
      const newData = [...state.Data, ...filterNewData(state.Data, Data)];
      return {
        ...state,
        NextUri,
        Data: newData,
        DataCount: newData.length,
        loading: false
      };
    }
    case SET_JOB_HISTORY_LOADING:
      return {
        ...state,
        loading: payload
      };
    default:
      return state;
  }
};

export default jobHistoryItems;

/** Action creators */
export const addToJobHistory = (Data, NextUri) => ({
  type: ADD_TO_JOB_HISTORY,
  payload: { Data, NextUri }
});

export const clearJobHistory = () => ({
  type: CLEAR_JOB_HISTORY
});

export const setJobHistoryLoading = loading => ({
  type: SET_JOB_HISTORY_LOADING,
  payload: loading
});
