import React from "react";

const Infobox = props => {
  const { description, onClose } = props;
  return (
    <div
      id="employer-contribution-info"
      className="open-deduction-based-popover-for-information-extra-small-type col-xs-12 col-sm-12 col-md-12 col-lg-12"
    >
      <button
        id="employer-contribution-info-tooltip-close-icon"
        className="open-deduction-based-popover-button-close"
        onClick={onClose}
      >
        <i
          id="system-level-cancel-button-close-icon"
          className="fa fa-times-circle fa-lg"
        />
      </button>
      <div
        id="employer-contribution-info-popover-data"
        className="deduction-popover-information-data col-xs-12 col-sm-12 col-md-12 col-lg-12"
      >
        {description}
      </div>
    </div>
  );
};

export default Infobox;
