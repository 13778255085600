import React, { Component } from "react";

class UtilitiesItems extends Component {
  render() {
    const { data } = this.props;
    const itemNodes = data.items.map((item, i) => {
      return (
        <div
          className="items col-lg-3 col-md-3 col-sm-12 col-xs-12"
          key={i}
          onClick={() => this.props.history.push(item.route)}
        >
          <div id={"utility-item-" + item.id} className="item-title">
            {item.title}
            <i className="fa fa-chevron-circle-right" aria-hidden="true" />
          </div>
          <p
            id={"utility-item-description-" + item.id}
            className="item-description"
          >
            {item.description}
          </p>
        </div>
      );
    });
    return <div className="utility-Item row">{itemNodes}</div>;
  }
}
export default UtilitiesItems;
