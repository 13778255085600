import React from "react";

const PayrollSchedule = ({ height, width, style }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 54.3 53.2"
      style={{ enableBackground: "new 0 0 54.3 53.2", ...style }}
      height={height}
      width={width}
      xmlSpace="preserve"
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{ __html: "\n\t.st0{fill:#555555;}\n" }}
      />
      <g>
        <path
          className="st0"
          d="M8.4,20.2H36c0.6,0,1-0.4,1-1s-0.4-1-1-1H8.4c-0.6,0-1,0.4-1,1S7.9,20.2,8.4,20.2z"
        />
        <path
          className="st0"
          d="M8.4,26.1H36c0.6,0,1-0.4,1-1s-0.4-1-1-1H8.4c-0.6,0-1,0.4-1,1S7.9,26.1,8.4,26.1z"
        />
        <path
          className="st0"
          d="M8.4,32.1h24.4c0.7-0.7,1.4-1.4,2.2-2H8.4c-0.6,0-1,0.4-1,1S7.9,32.1,8.4,32.1z"
        />
        <path
          className="st0"
          d="M30.4,36.1h-22c-0.6,0-1,0.4-1,1s0.4,1,1,1h21.4C30,37.4,30.2,36.7,30.4,36.1z"
        />
      </g>
      <g>
        <g>
          <path
            className="st0"
            d="M42.6,51.2c-5.8,0-10.6-4.8-10.6-10.6s4.8-10.6,10.6-10.6s10.7,4.8,10.7,10.6S48.4,51.2,42.6,51.2z
			 M42.6,31.8c-4.9,0-8.8,4-8.8,8.8s4,8.8,8.8,8.8s8.9-4,8.9-8.8S47.5,31.8,42.6,31.8z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="st0"
            d="M48.6,41.5h-6.1c-0.5,0-0.9-0.3-0.9-0.9v-6.5c0-0.5,0.3-0.9,0.9-0.9c0.5,0,0.9,0.3,0.9,0.9v5.6h5.2
			c0.5,0,0.9,0.3,0.9,0.9C49.5,41.2,49.1,41.5,48.6,41.5z"
          />
        </g>
      </g>
      <path
        className="st0"
        d="M29.8,42.8H6.1c-1.4,0-2.6-1.2-2.6-2.6V11.3c0-1.4,1.2-2.6,2.6-2.6h4.3V11c0,1.7,1.4,3,3,3c1.6,0,3-1.4,3-3V8.7
	h12V11c0,1.7,1.4,3,3,3c1.7,0,3-1.4,3-3V8.7h4.3c1.4,0,2.6,1.2,2.6,2.6v16.4c0.4,0,0.8-0.1,1.3-0.1c0.2,0,0.5,0,0.7,0V11.3
	c0.1-2.5-2-4.6-4.5-4.6h-4.3V4.3c0-1.7-1.4-3-3-3c-1.7,0-3,1.4-3,3v2.4h-12V4.3c0-1.7-1.4-3-3-3c-1.6,0-3,1.4-3,3v2.4H6.1
	c-2.5,0-4.6,2.1-4.6,4.6v29c0,2.5,2.1,4.6,4.6,4.6h24.2C30.1,44.2,29.9,43.5,29.8,42.8z M30.5,4.3c0-0.6,0.5-1,1-1s1,0.5,1,1v6.8
	c0,0.6-0.5,1-1,1s-1-0.5-1-1V4.3z M12.5,4.3c0-0.6,0.5-1,1-1s1,0.5,1,1v6.8c0,0.6-0.5,1-1,1s-1-0.5-1-1V4.3z"
      />
    </svg>
  );
};

export default PayrollSchedule;
