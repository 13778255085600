import React from "react";
import classnames from "classnames/bind";
const PopopverOption = ({
  label,
  showCheckbox,
  selected,
  onSelect,
  icon,
  statusIcon,
  image,
  showDescription = false,
  description
}) => {
  const optionLabel = label.replace(/-/g, "").toLowerCase();
  return (
    <li
      className={`list-item ${selected ? "selected" : ""}`}
      onClick={onSelect}
    >
      {showCheckbox && (
        <input type="checkbox" checked={selected || false} readOnly />
      )}
      <label id={optionLabel.replace(/ +/g, "-").toLowerCase()}>
        {icon && (
          <i
            className={classnames(
              icon,
              icon === "fa-ellipsis-h"
                ? `fa round-grey-circle-icon`
                : icon === "fa-times-circle-o"
                  ? `fa grey`
                  : `fa green`
            )}
          />
        )}
        {image && (
          <img
            height="20"
            src={image}
            alt={label}
            style={{ marginRight: "5px" }}
          />
        )}
        {statusIcon && <i className={classnames("fa", statusIcon)} />}
        {label}
        {showDescription && description && (
          <div className="ncl-dropdown-option-description">{description}</div>
        )}
      </label>
    </li>
  );
};

export default PopopverOption;
