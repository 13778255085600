import React, { Component } from "react";
import { Overlay, Popover } from "react-bootstrap";
import CustomOverlay from "./CustomOverlay";
import { FormSection, FormFieldGroup } from "../formSections/index";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const tooltip_edit_name_icon = <Tooltip id="tooltip_thrash_icon">Edit</Tooltip>;

const componentProps = {
  show: true,
  inputType: "text",
  inputId: "SectionName",
  inputName: "SectionName",
  label: "Name this section",
  description: "50 characters max",
  descriptionStyle: "italic",
  Size: [8, 7, 6, 12],
  maxLength: "50",
  required: true
};

const Footer = ({ onSaveClick, onCancel }) => (
  <div className="popover-button-group">
    <button
      onClick={onSaveClick}
      type="button"
      id="holiday-calendar-popover-save-button"
      className="nc-button secondary-button "
    >
      Save Changes
    </button>
    <button
      onClick={onCancel}
      type="button"
      id="holiday-calendar-popover-cancel-button"
      className="secondary-link-button "
    >
      Cancel
    </button>
  </div>
);

export default class FieldSectionName extends Component {
  state = {
    onEditClick: false
  };
  render() {
    const {
      name,
      data = {},
      onChange,
      errors = {}
      // onSaveClick
    } = this.props;
    return (
      <div className="details-page-header col-lg-8 col-md-8 col-sm-8 col-xs-8 clearfix">
        <span style={{ fontSize: "10px" }}>SECTION:</span>
        <div>
          <span style={{ color: "#339966", fontSize: "20px" }}>{name}</span>
          <span ref={target => (this.target = target)} className="edit-field">
            <OverlayTrigger placement="top" overlay={tooltip_edit_name_icon}>
              <i
                id="edit-calendar-name"
                className={
                  this.state.onEditClick
                    ? "fa fa-pencil disabled"
                    : "fa fa-pencil"
                }
                onClick={e => {
                  this.setState({
                    onEditClick: !this.state.onEditClick
                  });
                }}
              />
            </OverlayTrigger>
            <Overlay
              show={this.state.onEditClick}
              placement="bottom"
              container={this}
              target={this.target}
              onHide={() =>
                this.setState({
                  onEditClick: false
                })
              }
              rootClose={true}
            >
              <Popover id="popover-positioned-bottom">
                <CustomOverlay>
                  <div className="popover-header">
                    <span className="close-icon">
                      <i
                        id="popover-close-icon"
                        className="fa fa-times-circle"
                        onClick={() => {
                          this.setState({
                            onEditClick: false
                          });
                        }}
                      />
                    </span>
                  </div>
                  <FormSection>
                    {" "}
                    <FormFieldGroup
                      {...componentProps}
                      value={data[componentProps.inputName]}
                      onChange={onChange}
                      class="name-holiday-calendar"
                      error={errors[componentProps.inputName]}
                    />
                  </FormSection>
                  <Footer
                    onSaveClick={() => {
                      this.setState({
                        onEditClick: false
                      });
                      // onSaveClick();
                    }}
                    onCancel={() => {
                      this.setState({
                        onEditClick: false
                      });
                    }}
                  />
                </CustomOverlay>
              </Popover>
            </Overlay>
          </span>
        </div>
      </div>
    );
  }
}
