import React from "react";
import { FormSection, FormFieldGroup } from "../../../../formSections";
import * as Fields from "./../FieldNames";

const Fmla = ({ data = {}, onChange }) => {
  return (
    <FormSection title={"FMLA"} hr>
      <FormFieldGroup
        inputType="radio"
        label="FMLA Leave Reason"
        fieldType="inline"
        radios={[
          {
            id: 1,
            label:
              "The birth of the employees's son or daughter, and to care of the new born child.",
            value: 1
          },
          {
            id: 2,
            label:
              "The placement with the employee of a son or daughter for adoption or foster care, and to care for the newly placed child.",
            value: 2
          },
          {
            id: 3,
            label:
              "To care for the employee's spouse, son, daughter, or parent with a serious health condition.",
            value: 3
          },
          {
            id: 4,
            label:
              "Because of a serious health condition that makes the employee unable to perform one or more of the essential functions of his or her job.",
            value: 4
          },
          {
            id: 5,
            label:
              "Because of any qualifying exigency arising out of the fact that the emploee's spouse, son,daughter, or parentis a military member on covered active duty status (or has been notified of an impending call or order to covered active duty).",
            value: 5
          }
        ]}
        inputName={Fields.fmlaLeaveReason}
        data={data[Fields.fmlaLeaveReason]}
        colSpan="col-lg-3"
        style={{ marginBottom: "15px" }}
        Size={[6, 4, 12, 12]}
        onChange={onChange}
        width="100%"
      />
      <FormFieldGroup
        inputType="singleDatePicker"
        label="Expected Leave Return Date"
        fieldType="inline"
        inputName={Fields.expectedLeaveReturnDate}
        data={data[Fields.expectedLeaveReturnDate]}
        colSpan="col-lg-3"
        Size={[6, 4, 12, 12]}
        onChange={onChange}
        style={{ marginBottom: "15px" }}
        getToFromValue={onChange}
      />
    </FormSection>
  );
};

export default Fmla;
