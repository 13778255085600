import React from "react";
import "./svg.css";

const TimecardAlerts = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 52 52"
    style={{ enableBackground: "new 0 0 52 52" }}
    width={width}
    height={height}
  >
    <g>
      <path
        className="st5"
        d="M22,39.2c-6.7,0-12.2-5.5-12.2-12.2S15.3,14.8,22,14.8S34.3,20.3,34.3,27S28.8,39.2,22,39.2z M22,16.8c-5.6,0-10.2,4.6-10.2,10.2S16.4,37.2,22,37.2S32.3,32.6,32.3,27S27.7,16.8,22,16.8z"
      />
    </g>
    <g>
      <path
        className="st5"
        d="M29,28h-7c-0.6,0-1-0.4-1-1v-7.5c0-0.6,0.4-1,1-1s1,0.4,1,1V26h6c0.6,0,1,0.4,1,1S29.6,28,29,28z"
      />
    </g>
    <g>
      <path
        className="st5"
        d="M42,51.6c-4.9,0-8.9-4-8.9-8.9s4-8.9,8.9-8.9s8.9,4,8.9,8.9S46.9,51.6,42,51.6z M42,35.8c-3.8,0-6.9,3.1-6.9,6.9s3.1,6.9,6.9,6.9s6.9-3.1,6.9-6.9S45.8,35.8,42,35.8z"
      />
    </g>
    <path
      className="st5"
      d="M43.4,42.7l3-3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-3,3l-3-3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l3,3l-3,3c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3l3,3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L43.4,42.7z"
    />
    <path
      className="st5"
      d="M38.4,6.4h-4.3V4c0-1.7-1.4-3-3-3c-1.7,0-3,1.4-3,3v2.4h-12V4c0-1.7-1.4-3-3-3s-3,1.4-3,3v2.4H5.7c-2.5,0-4.6,2.1-4.6,4.6V40c0,2.5,2.1,4.6,4.6,4.6h25.5C31.1,44,31,43.4,31,42.7c0-0.1,0-0.1,0-0.2H5.7c-1.4,0-2.6-1.2-2.6-2.6V11c0-1.4,1.2-2.6,2.6-2.6H10v2.3c0,1.7,1.4,3,3,3s3-1.4,3-3V8.4h12v2.3c0,1.7,1.4,3,3,3c1.7,0,3-1.4,3-3V8.4h4.3c1.4,0,2.6,1.2,2.6,2.6v20.8c0.3,0,0.7-0.1,1-0.1s0.7,0,1,0.1V11C43,8.5,40.9,6.4,38.4,6.4z M14.1,10.8c0,0.6-0.5,1-1,1s-1-0.5-1-1V4c0-0.6,0.5-1,1-1s1,0.5,1,1V10.8z M32.1,10.8c0,0.6-0.5,1-1,1s-1-0.5-1-1V4c0-0.6,0.5-1,1-1s1,0.5,1,1V10.8z"
    />
  </svg>
);
export default TimecardAlerts;
