import axios from "axios";
import * as common from "./common";
import {
  clearAxiosHeader,
  clearSessionFromLocalStorage,
  clearSessionFromSessionStorage
} from "./constants";

const url = `${common.IDENTITY_API_URL}/api/app/signout`;

export const logout = () =>
  axios
    .post(url)
    .then(response => {
      clearAxiosHeader();
      clearSessionFromLocalStorage();
      clearSessionFromSessionStorage();
      localStorage.removeItem("LoggedInLegacy");
      window.location.href = common.LEGACY_LOGIN_URL + "/n/Account/Logout";
    })
    .catch(() => {
      clearAxiosHeader();
      clearSessionFromLocalStorage();
      clearSessionFromSessionStorage();
      localStorage.removeItem("LoggedInLegacy");
    });
