import React, { Component } from "react";
import { Tabs, Tab, Modal } from "react-bootstrap";
import SnapshotTable from "./SnapshotTable";
import "./snapshot.css";

export default class Snapshot extends Component {
  state = {
    currentLink: this.props.beforeLink,
    showValueType: this.props.showValueType,
    holidayType: 1
  };
  toggleBeforeAfter = () => {
    let beforeLink = this.props.beforeLink;
    let afterLink = this.props.afterLink;
    let currentLink =
      this.state.showValueType === "before" ? beforeLink : afterLink;

    let showValueType =
      this.state.showValueType === "current" ? "before" : "current";
    this.setState({ currentLink, showValueType });
  };

  handleSelect = holidayType => {
    this.setState({ holidayType });
  };

  showTabPanel = (nodes = []) => {
    let CommonHolidays = [];
    let CustomHolidays = [];
    nodes.map(i => i.NodeKey === "CommonHoliday" && CommonHolidays.push(i));
    nodes.map(i => i.NodeKey === "CustomHoliday" && CustomHolidays.push(i));
    return nodes.map(
      (item, index) =>
        item.NodeKey === "tab1" ? (
          <Tab
            key={item.NodeValues[0]}
            eventKey={parseInt(item.NodeValues[0], 10)}
            title={item.NodeValues[1]}
            className={"holiday-calendar-snapshot-tab-body"}
          >
            <SnapshotTable nodes={CommonHolidays} holidayType={1} />
          </Tab>
        ) : item.NodeKey === "tab2" ? (
          <Tab
            key={item.NodeValues[0]}
            eventKey={parseInt(item.NodeValues[0], 10)}
            title={item.NodeValues[1]}
            className={"holiday-calendar-snapshot-tab-body"}
          >
            <SnapshotTable nodes={CustomHolidays} holidayType={2} />
          </Tab>
        ) : null
    );
  };

  render() {
    const { nodes = [] } = this.props;

    return (
      <Modal.Body>
        {nodes[0] && nodes[0].NodeKey === "NoHolidays" ? (
          <div
            style={{
              fontSize: "18px",
              paddingTop: "20px",
              borderTop: "1px solid #ccc"
            }}
          >
            {nodes[0].NodeValues[0]}
          </div>
        ) : (
          <Tabs
            activeKey={this.state.holidayType}
            onSelect={this.handleSelect}
            id=""
            className="holiday-calendar-snapshot-tabs nav nav-tabs "
          >
            {this.showTabPanel(nodes)}
          </Tabs>
        )}
      </Modal.Body>
    );
  }
}
