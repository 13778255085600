/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import DropDown from "component-library/dropDown";
import FormTooltip from "component-library/inputFields/FormTooltip";
import classNames from "classnames/bind";
import Toaster from "component-library/toasters";
import * as FIELDS from "../../utils/Constants";
import "./style.css";

const getYearList = () => {
  let yearList = [];
  for (let i = 1980; i <= 2099; i++) {
    yearList.push({
      id: i,
      value: i,
      label: `${i}`
    });
  }
  return yearList;
};

const options = {
  FromMonths: [
    { id: 1, value: 1, label: "January" },
    { id: 2, value: 2, label: "February" },
    { id: 3, value: 3, label: "March" },
    { id: 4, value: 4, label: "April" },
    { id: 5, value: 5, label: "May" },
    { id: 6, value: 6, label: "June" },
    { id: 7, value: 7, label: "July" },
    { id: 8, value: 8, label: "August" },
    { id: 9, value: 9, label: "September	" },
    { id: 10, value: 10, label: "October" },
    { id: 11, value: 11, label: "November" },
    { id: 12, value: 12, label: "December" }
  ],
  FromYear: getYearList(),
  ToMonths: [
    { id: 1, value: 1, label: "January" },
    { id: 2, value: 2, label: "February" },
    { id: 3, value: 3, label: "March" },
    { id: 4, value: 4, label: "April" },
    { id: 5, value: 5, label: "May" },
    { id: 6, value: 6, label: "June" },
    { id: 7, value: 7, label: "July" },
    { id: 8, value: 8, label: "August" },
    { id: 9, value: 9, label: "September	" },
    { id: 10, value: 10, label: "October" },
    { id: 11, value: 11, label: "November" },
    { id: 12, value: 12, label: "December" }
  ],
  ToYear: getYearList()
};

const labelMaker = (noLabel, allLabel, someLabel, oneLabel) => (o, s, all) =>
  all
    ? allLabel
    : Object.keys(s).filter(k => s[k]).length === 0
    ? noLabel
    : Object.keys(s).filter(k => s[k]).length === 1
    ? oneLabel
    : someLabel;

class EmploymentDates extends Component {
  state = { showTooltip: false };
  showTooltipHandler = () => {
    this.setState({
      showTooltip: !this.state.showTooltip
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        showTooltip: false
      });
    }
  }

  render() {
    const { props } = this;
    const { errors } = props;
    let lgClass = "col-lg-4";
    let mdClass = "col-md-6";
    let smClass = "col-sm-12";
    let xsClass = "col-xs-12";
    if (props.Size) {
      if (props.Size.length > 0) {
        lgClass = props.Size[0] && "col-lg-" + props.Size[0];
        mdClass = props.Size[1] && "col-md-" + props.Size[1];
        smClass = props.Size[2] && "col-sm-" + props.Size[2];
        xsClass = props.Size[3] && "col-xs-" + props.Size[3];
      }
    }

    let fromMonths = parseInt(props.data[FIELDS.EMPLOYMENT_FORM_MONTH], 10);
    let fromYear = parseInt(props.data[FIELDS.EMPLOYMENT_FROM_YEAR], 10);
    let toMonths = parseInt(props.data[FIELDS.EMPLOYMENT_TO_MONTH], 10);
    let toYear = parseInt(props.data[FIELDS.EMPLOYMENT_TO_YEAR], 10);

    let wrapperClass;
    if (props.class === undefined) {
      wrapperClass = "nc-field-group clearfix";
    } else {
      wrapperClass = "nc-field-group " + props.class + " clearfix";
    }
    let error = "";
    if (
      props.required &&
      (errors[FIELDS.EMPLOYMENT_FORM_MONTH] ||
        errors[FIELDS.EMPLOYMENT_FROM_YEAR] ||
        errors[FIELDS.EMPLOYMENT_TO_MONTH] ||
        errors[FIELDS.EMPLOYMENT_TO_YEAR])
    ) {
      wrapperClass = wrapperClass + " has-error " + props.inputName;
      error = "This is a required field.";
    }
    const InputFieldClass = classNames(
      props.className,
      lgClass,
      mdClass,
      smClass,
      xsClass
    );

    return (
      <div>
        <div
          className={wrapperClass}
          style={{ clear: "both", display: "flex", alignItems: "center" }}
        >
          {props.Toaster && props.Toaster.show && (
            <Toaster
              show={props.Toaster.show}
              alertClassName={props.Toaster.type}
              message={props.Toaster.content}
            />
          )}
          <div
            id={"label" + props.inputName}
            style={{ paddingRight: "15px" }}
            className={"col-lg-3 col-md-3 col-sm-3 field-name clearfix"}
          >
            {props.label}{" "}
            {props.tooltip && (
              <a onClick={this.showTooltipHandler}>
                <i className="fa fa-info-circle" />
              </a>
            )}
          </div>
          <div
            className="dates-container"
            style={{
              width: "60%",
              marginBottom: "20px"
            }}
          >
            <div
              style={{
                clear: "both",
                display: "flex",
                alignItems: "center"
              }}
            >
              <DropDown
                options={options.FromMonths}
                value={fromMonths}
                id={"dd-from-months"}
                className={InputFieldClass}
                makeLabel={labelMaker(
                  FIELDS.EMPLOYMENT_DATES_DD_LABELS["months"][0],
                  FIELDS.EMPLOYMENT_DATES_DD_LABELS["months"][1],
                  FIELDS.EMPLOYMENT_DATES_DD_LABELS["months"][2],
                  FIELDS.EMPLOYMENT_DATES_DD_LABELS["months"][3]
                )}
                onSelect={e =>
                  props.onChange &&
                  props.onChange({
                    name: FIELDS.EMPLOYMENT_FORM_MONTH,
                    value: e
                  })
                }
              />
              <DropDown
                options={options.FromYear}
                value={fromYear}
                id={"dd-from-year"}
                className={InputFieldClass}
                makeLabel={labelMaker(
                  FIELDS.EMPLOYMENT_DATES_DD_LABELS["year"][0],
                  FIELDS.EMPLOYMENT_DATES_DD_LABELS["year"][1],
                  FIELDS.EMPLOYMENT_DATES_DD_LABELS["year"][2],
                  FIELDS.EMPLOYMENT_DATES_DD_LABELS["year"][3]
                )}
                onSelect={e =>
                  props.onChange &&
                  props.onChange({
                    name: FIELDS.EMPLOYMENT_FROM_YEAR,
                    value: e
                  })
                }
              />
              <div style={{ marginLeft: "15px", color: "#555" }}>to</div>
              <DropDown
                options={options.ToMonths}
                value={toMonths}
                id={"dd-to-months"}
                className={InputFieldClass}
                makeLabel={labelMaker(
                  FIELDS.EMPLOYMENT_DATES_DD_LABELS["months"][0],
                  FIELDS.EMPLOYMENT_DATES_DD_LABELS["months"][1],
                  FIELDS.EMPLOYMENT_DATES_DD_LABELS["months"][2],
                  FIELDS.EMPLOYMENT_DATES_DD_LABELS["months"][3]
                )}
                onSelect={e =>
                  props.onChange &&
                  props.onChange({
                    name: FIELDS.EMPLOYMENT_TO_MONTH,
                    value: e
                  })
                }
              />
              <DropDown
                options={options.ToYear}
                value={toYear}
                id={"dd-to-year"}
                className={InputFieldClass}
                makeLabel={labelMaker(
                  FIELDS.EMPLOYMENT_DATES_DD_LABELS["year"][0],
                  FIELDS.EMPLOYMENT_DATES_DD_LABELS["year"][1],
                  FIELDS.EMPLOYMENT_DATES_DD_LABELS["year"][2],
                  FIELDS.EMPLOYMENT_DATES_DD_LABELS["year"][3]
                )}
                onSelect={e =>
                  props.onChange &&
                  props.onChange({
                    name: FIELDS.EMPLOYMENT_TO_YEAR,
                    value: e
                  })
                }
              />
            </div>
            {error && props.required && (
              <div
                style={{ marginTop: "5px", fontSize: "15px" }}
                className="has-error"
              >
                {error}
              </div>
            )}
          </div>
        </div>
        <div className={"col-lg-offset-3 col-md-offset-3"}>
          {this.state.showTooltip && props.tooltip && (
            <FormTooltip
              onCloseHandler={this.showTooltipHandler}
              content={props.tooltip}
              width={props.tooltipwidth}
            />
          )}
        </div>
      </div>
    );
  }
}

export default EmploymentDates;
EmploymentDates.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string
};

EmploymentDates.defaultProps = {
  descriptionStyle: "",
  hideTopdescription: false,
  colSpan: ""
};
