import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { TabContainerAddButtonSection } from "component-library/employee-profile/entity-view";
import { ResumeEntityList } from "component-library/employee-profile/resume";
import * as FIELDS from "component-library/employee-profile/resume/utils/Constants";
import ResumeEntityFilters from "../containers/ResumeEntityFilters";
import { makeSortCriterion } from "app/common/reducers/filters";

const getSearchParams = state => {
  const defaultSearchParams = {
    Fields: ["*"],
    Criterion: [],
    Sort: [],
    PageSize: 5
  };

  const sortOrder = state.common.sortOrder;
  let Sort = null;
  Sort = makeSortCriterion(sortOrder);
  defaultSearchParams.Sort = Sort === null ? [] : Sort;

  return defaultSearchParams;
};

class ResumeList extends Component {
  componentWillUnmount() {
    this.props.toggleToaster();
  }

  render() {
    const {
      activePanel = {},
      activePanelId,
      entityHeader,
      toggleAddListSection,
      onEditClick,
      onDeleteClick,
      setToaster,
      toggleToaster,
      employeeName,
      changeSortOrder
    } = this.props;
    return (
      <div>
        <TabContainerAddButtonSection
          onAddButtonClick={toggleAddListSection}
          discription={FIELDS[entityHeader]}
        />
        {activePanel.length > 5 && (
          <ResumeEntityFilters
            {...this.props}
            id={"resumeEntityFilter"}
            SectionItemId={activePanelId}
            headerColumn={entityHeader}
          />
        )}
        {activePanel.length !== undefined && (
          <ResumeEntityList
            tabData={activePanel}
            onEditClick={id => onEditClick && onEditClick(id)}
            onDeleteClick={(id, name) =>
              onDeleteClick && onDeleteClick(id, name, activePanelId)
            }
            headerColumn={entityHeader}
            id={activePanelId}
            setToaster={setToaster}
            toggleToaster={toggleToaster}
            employeeName={employeeName}
            editSortOrder={changeSortOrder}
          />
        )}
      </div>
    );
  }
}

const stateToProps = state => {
  return {
    searchParam: getSearchParams(state)
  };
};
const actionCreators = {};
export default withRouter(connect(stateToProps, actionCreators)(ResumeList));
