import React from "react";

const AddressVerificationOn = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 87.8 88.8"
    style={{ enableBackground: "new 0 0 87.8 88.8" }}
    width={width}
    height={height}
  >
    <g>
      <path
        className="st0"
        d="M30.7,53.9V36.6h24.5v10.8l0.3-0.1c0.4-0.1,0.9-0.1,1.3-0.2l0.3,0v-9.4l2.2,2.2c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.3l-6.4-6.4v-8.8c0-1-0.8-1.7-1.8-1.7h-3.4c-1,0-1.8,0.8-1.8,1.7v1.9l-3.7-3.7c-0.2-0.2-0.6-0.3-0.9-0.2c-0.1,0-0.3,0.1-0.4,0.2L25.2,38.7c-0.2,0.2-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3l2.3-2.3v16.1c0,1.9,1.6,3.5,3.5,3.5h15.4l0-0.4c0-0.1,0-0.1,0-0.2c0-0.3,0-0.7,0.1-1l0-0.3H32.2C31.4,55.4,30.7,54.7,30.7,53.9z M52.3,33.1l1.7,1.7H31.7l11.1-11.1L52.3,33.1L52.3,33.1L52.3,33.1z M52.3,30.4l-3.2-3.2v-3.6h3.2V30.4z"
      />
      <path
        className="st0"
        d="M57.2,48.7c-4.4,0-8.1,3.6-8.1,8.1c0,4.4,3.6,8.1,8.1,8.1c4.4,0,8.1-3.6,8.1-8.1C65.3,52.3,61.7,48.7,57.2,48.7z M57.2,62.9c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2c3.4,0,6.2,2.8,6.2,6.2C63.4,60.2,60.6,62.9,57.2,62.9z"
      />
      <path
        className="st0"
        d="M60.4,53.5c-0.3,0-0.5,0.1-0.6,0.3l-3.2,3.9l-1.8-1.8c-0.4-0.4-1-0.4-1.3,0c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7l2.5,2.5c0.2,0.2,0.4,0.3,0.7,0.3h0.2c0.3,0,0.5-0.1,0.7-0.3l3.8-4.6c0.3-0.4,0.3-1-0.1-1.3C60.9,53.6,60.6,53.5,60.4,53.5z"
      />
    </g>
    <g>
      <path
        className="st0"
        d="M45.1,83.4c-22.2,0-40.3-18.1-40.3-40.3c0-22.2,18.1-40.3,40.3-40.3s40.3,18.1,40.3,40.3C85.4,65.3,67.3,83.4,45.1,83.4z M45.1,4.8C24,4.8,6.8,22,6.8,43.1S24,81.4,45.1,81.4c21.1,0,38.3-17.2,38.3-38.3S66.2,4.8,45.1,4.8z"
      />
    </g>
  </svg>
);

export default AddressVerificationOn;
