export const CHECK_USER_IN_SESSION = "CHECK_USER_IN_SESSION";

export const SESSION_CONTEXT = "SESSION_CONTEXT";
export const CLEAR_SESSION_CONTEXT = "CLEAR_SESSION_CONTEXT";

export const SHOW_TOASTER = "SHOW_TOASTER";
export const TAX_PRIORITY_LIST = "TAX_PRIORITY_LIST";

export const SET_LOADER = "SET_LOADER";

export const SET_LOAD_COUNT = "SET_LOAD_COUNT";

export const SET_NOTIFICATION_LOADER = "SET_NOTIFICATION_LOADER";

export const SHOW_TIMEOUT_POPUP = "SHOW_TIMEOUT_POPUP";

export const IS_IN_AUTH_PROCESS = "IS_IN_AUTH_PROCESS";

export const SET_FORM_UPDATED = "SET_FORM_UPDATED";

export const SET_GLOBALNAV_CLICK = "SET_GLOBALNAV_CLICK";

export const CALL_BACK_TO_QUICK_LINK = "CALL_BACK_TO_QUICK_LINK";

export const OVERLAY_CLICKABLE = "OVERLAY_CLICKABLE";

export const SET_ENTITY_NAME = "SET_ENTITY_NAME";
