import React, { Component } from "react";
import PropTypes from "prop-types";
import EditJobDetail from "./EditJobDetail";

export default class JobDetailsNote extends Component {
  state = {
    editMode: false
  };

  toggleEditMode = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  render() {
    const { text, onSave } = this.props;
    if (!text) {
      return null;
    }
    return (
      <div className="table">
        <div className="tbody">
          <div
            className="tr col-sm-12"
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              paddingRight: 12
            }}
          >
            <div style={{ fontStyle: "italic" }} className="td col-xs-10">
              Notes: {text}
            </div>
            <div className="col-xs-2">
              <i
                className="fa fa-pencil float-right"
                style={{ cursor: "pointer" }}
                onClick={this.toggleEditMode}
              />
            </div>
          </div>
          <EditJobDetail
            show={this.state.editMode}
            onCancelClick={() => this.setState({ editMode: false })}
            onSaveClick={onSave}
            type="Notes"
            value={text}
          />
        </div>
      </div>
    );
  }
}

JobDetailsNote.propTypes = {
  text: PropTypes.string,
  onSave: PropTypes.func
};
