import React from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import SubcategoryPanel from "./SubcategoryPanel";
import PropTypes from "prop-types";
import CustomEarningTabBody, {
  CUSTOM_EARNING_CATEGORY_ID
} from "./CustomEarningTabBody";
import "./EarningsAddModal.css";
const Header = ({ closeModal }) => (
  <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <i
      id="add-deduction-popup-close-icon"
      onClick={closeModal}
      className="fa fa-times-circle-o"
    />
  </div>
);

const Footer = ({ onCancel, onSubmit, disableSubmit }) => (
  <div className="button-group">
    <button
      type="button"
      id="deduction-save-and-continue-button"
      className={
        disableSubmit ? "disabled-button" : "nc-button secondary-button"
      }
      onClick={onSubmit}
      disabled={disableSubmit}
    >
      GET STARTED
    </button>
    <button
      type="button"
      id="btn-cancel-modal-bootstrap"
      className="secondary-link-button"
      onClick={onCancel}
    >
      Cancel
    </button>
  </div>
);

const EarningsAddModal = ({
  show,
  closeModal,
  data = [],
  onSelectCategory,
  activeCategory,
  onSelectSubcategory,
  activeSubcategory,
  onSelectEarning,
  activeEarning,
  onSubmit
}) => {
  const renderTabs = data =>
    data
      .map(category => (
        <Tab
          eventKey={category.id}
          title={category.name}
          key={category.id}
          onEntered={() =>
            onSelectEarning(
              category.id === CUSTOM_EARNING_CATEGORY_ID
                ? CUSTOM_EARNING_CATEGORY_ID
                : null
            )
          }
        >
          <div className="add_deduction_popup_tab_body earning-add-modal clearfix">
            {category.id === CUSTOM_EARNING_CATEGORY_ID ? (
              <CustomEarningTabBody />
            ) : (
              category.data.map(subcategory => (
                <SubcategoryPanel
                  {...subcategory}
                  key={subcategory.id}
                  active={activeSubcategory === subcategory.id}
                  onSelect={() =>
                    onSelectSubcategory(
                      activeSubcategory === subcategory.id ? -1 : subcategory.id
                    )
                  }
                  activeEarning={activeEarning}
                  onSelectEarning={onSelectEarning}
                />
              ))
            )}
          </div>
        </Tab>
      ))
      .concat();

  return (
    <Modal show={show} bsClass="deduction-add-modal modal">
      <Modal.Header>
        <Header closeModal={closeModal} />
      </Modal.Header>
      <Modal.Body>
        <div className="modal-row">
          <div className="modal-title">Add Earnings</div>
          <div className="modal-description">
            Choose from our most commonly used earnings templates or create a
            custom earnings.
          </div>
          <Tabs
            className="add_deduction_popup_tab nav nav-tabs"
            id="entity-add-modal"
            activeKey={activeCategory}
            onSelect={onSelectCategory}
          >
            {renderTabs(data)}
          </Tabs>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Footer
          onCancel={closeModal}
          onSubmit={onSubmit}
          disableSubmit={!activeEarning}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default EarningsAddModal;

export { CUSTOM_EARNING_CATEGORY_ID };
EarningsAddModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  data: PropTypes.array,
  onSelectCategory: PropTypes.func,
  activeCategory: PropTypes.number,
  onSelectSubcategory: PropTypes.func,
  activeSubcategory: PropTypes.number,
  onSelectEarning: PropTypes.func,
  activeEarning: PropTypes.number,
  onSubmit: PropTypes.func
};
EarningsAddModal.defaultProps = {
  data: [],
  onSelectCategory: () => {},
  onSelectSubcategory: () => {},
  onSelectEarning: () => {},
  onSubmit: () => {},
  closeModal: () => {}
};
