import React from "react";
import PropTypes from "prop-types";
import "./styles.css";
const imgHeight = "40";
const ProfileCard = ({ title, description, imgSrc, children, type, icon }) => {
  if (type === "Horizontal") {
    return (
      <div className="profile-card-horizontal">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="title">{title}</div>
          <div className="profile-card-icon">
            <img
              src={
                icon === "Notification"
                  ? require("./Notification.png")
                  : imgSrc !== null
                    ? imgSrc
                    : require("./Alert.png")
              }
              alt="icon"
            />
          </div>
        </div>

        <div className="description">{description}</div>
        <div className="profile-card-body">{children}</div>
      </div>
    );
  } else {
    return (
      <div className="profile-card">
        <div className="profile-card-icon">
          <img src={imgSrc} alt="icon" height={imgHeight} />
        </div>
        <div className="title">{title}</div>
        <div className="description">{description}</div>
        <div className="profile-card-body">{children}</div>
      </div>
    );
  }
};

export default ProfileCard;
ProfileCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.oneOf(["Horizontal", "Vertical"]),
  icon: PropTypes.string
};
ProfileCard.defaultProps = {
  title: "Card Title",
  description:
    "Small Description goes right here so user understand what is hidden inside",
  imgSrc: require("./Alert.png")
};
