import React from "react";
import { makeId } from ".";

const GeneralLedger = ({ Nodes, NodeValues, NodeKey = "gl" }) => (
  <div>
    {Nodes.map(({ NodeValues }, i) => (
      <div key={i} id={makeId(NodeKey, i + 2)}>
        {NodeValues[0]}
      </div>
    ))}
  </div>
);

export default GeneralLedger;
