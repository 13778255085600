import React from "react";

const LabelWithIcon = props => (
  <div
    id={props.id}
    name={props.name}
    style={{ color: "#009999", cursor: "pointer" }}
    onClick={props.onSelect}
  >
    <i style={{ fontSize: "16px" }} className={props.labelIcon} />
    {props.value}
  </div>
);
export default LabelWithIcon;
