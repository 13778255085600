import { combineReducers } from "redux";
import * as types from "../../../actions/generalledger/earnings/index";

// employer main list reducer
function employerEarningsAccountsMainList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYER_EARNINGS_ACCOUNTS_MAIN_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

//employer filtered list reducer
function employerEarningsAccountsFilteredList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYER_EARNINGS_FILTERED_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

////for expense account drop down
function employerEarningsExpenceAccountsList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYER_EARNINGS_EXPENCE_ACCOUNTS_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

//for liability account drop down
function employerEarningsLiabilityAccountsList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYER_EARNINGS_LIABILITY_ACCOUNTS_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

//for deduction drop down
function employerEarningsList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYER_EARNINGS_ACCOUNTS_MAIN_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

//for saving data
// function setEmployerLiabilityAccountsDataReducer(state=[], action){
//     switch(action.type){
//         case types.FETCH_EMPLOYER_LIABILITY_ACCOUNTS_DATA :
//             return action.payload;
//     }

//     return state;
// }

// function employeeDeductionFilterList(state=[], action){
//     switch(action.type){
//         case types.FETCH_EMPLOYEE_DEDUCTION_FILTER_LIST :
//             return action.payload.data;
//     }

//     return state;
// }

//EMPLOYEE SECTION

//for main list
function employeeEarningsAccountsMainList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYEE_EARNINGS_ACCOUNTS_MAIN_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

//filtered list
function employeeEarningsAccountsFilteredList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYEE_EARNINGS_FILTERED_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

//liability account
function employeeEarningsLiabilityAccountsList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYEE_EARNINGS_LIABILITY_ACCOUNTS_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

//for deduction drop down
function employeeEarningsFilterList(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYEE_EARNINGS_ACCOUNTS_MAIN_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

function UnMappedCheckBoxStateValue(state = null, action) {
  switch (action.type) {
    case types.UNMAPPED_CHECK_STATE_TYPE:
      return action.payload;
    default:
      return state;
  }
}

function setEmployeeEarningsData(state = [], action) {
  switch (action.type) {
    case types.EMPLOYEE_EARNINGS_DATA:
      return action.payload;
    default:
      return state;
  }
}

function setSaveEmployeeEarningsPage(state = [], action) {
  switch (action.type) {
    case types.EMPLOYEE_EARNINGS_SAVED_PAGES:
      return action.payload;
    default:
      return state;
  }
}

//redux to store data
function EmployerEarningsData(state = [], action) {
  switch (action.type) {
    case types.SET_EMPLOYER_EARNINGS_DATA:
      return action.payload;
    default:
      return state;
  }
}

function setSelectedFiltersReducer(state = [], action) {
  switch (action.type) {
    case types.GENERAL_LEDGER_SELECTED_FILTERS:
      return action.payload;
    default:
      return state;
  }
}

function setSortedFiltersReducer(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYER_EARNINGS_FILTERED_LIST:
      return action.payload.data;
    default:
      return state;
  }
}

function setEmployerEarningsLiabilityAccountsData(state = [], action) {
  switch (action.type) {
    case types.FETCH_EMPLOYER_EARNINGS_LIABILITY_ACCOUNTS_DATA:
      return action.payload;
    default:
      return state;
  }
}

const generalLedgerEarningsReducer = combineReducers({
  employerEarningsAccountsMainList,
  employerEarningsAccountsFilteredList,
  employerEarningsExpenceAccountsList,
  employerEarningsLiabilityAccountsList,
  employerEarningsList,

  employeeEarningsAccountsMainList,
  employeeEarningsAccountsFilteredList,
  employeeEarningsLiabilityAccountsList,
  employeeEarningsFilterList,

  setEmployeeEarningsData,
  setSaveEmployeeEarningsPage,

  UnMappedCheckBoxStateValue,

  EmployerEarningsData,

  setSelectedFiltersReducer,
  setSortedFiltersReducer,

  setEmployerEarningsLiabilityAccountsData
});

export default generalLedgerEarningsReducer;
