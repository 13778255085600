import React from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import * as Fields from "./../FieldNames";
import { INACTIVATE_BENEFITS } from "../Tooltips";
const BenefitsCobra = ({ onChange, data = {} }) => {
  return (
    <FormSection title={"Benefits/COBRA"} hr>
      <FormFieldGroup
        inputType="radio"
        inputName={Fields.inactivateBenefitsPlans}
        label="Do you want to inactivate the employee's benefits plans?"
        fieldType="inline"
        value={data[Fields.inactivateBenefitsPlans]}
        radios={"boolean"}
        class="col-lg-12"
        colSpan="col-lg-3 col-md-3"
        Size={[6, 4, 12, 12]}
        onChange={onChange}
        tooltip={INACTIVATE_BENEFITS}
      />
      <FormFieldGroup
        inputType="singleDatePicker"
        label="Benefit Inactivation Date"
        fieldType="inline"
        inputName={Fields.benefitsInactivationDate}
        value={data[Fields.benefitsInactivationDate]}
        colSpan="col-lg-3 col-md-3"
        Size={[4, 4, 12, 12]}
        onChange={onChange}
        style={{ marginBottom: "15px" }}
        getToFromValue={onChange}
      />

      <FormFieldGroup
        inputType="radio"
        inputName={Fields.recordCobraEvent}
        label="Do you want to record a COBRA event now ?"
        fieldType="inline"
        value={data[Fields.recordCobraEvent]}
        radios={"boolean"}
        class="col-lg-12"
        colSpan="col-lg-3 col-md-3"
        Size={[6, 4, 12, 12]}
        onChange={onChange}
      />
      <FormFieldGroup
        inputType="textarea"
        inputName={Fields.benefitsCobraNotes}
        label="Notes"
        value={data[Fields.benefitsCobraNotes]}
        fieldType="inline"
        description="250 characters max"
        rows={6}
        colSpan="col-lg-3 col-md-3"
        Size={[8, 8, 12, 12]}
        onChange={onChange}
        getToFromValue={onChange}
        optional
      />
    </FormSection>
  );
};

export default BenefitsCobra;
