import React from "react";
const renderBoxes = data => item => {
  const { name, value } = item;
  return (
    <div key={value} id={String(name).replace(/ /g, "-")} className="list-item">
      <i className="fa fa-check" /> {name}
      {value !== "" ? " - " : ""}
      {value}
    </div>
  );
};
const renderAssociates = data => item => {
  const { name, value } = item;
  return (
    <div key={value} id={String(name).replace(/ /g, "-")} className="list-item">
      <i className="fa fa-check" /> {name}
      {value !== "" ? " - " : ""}
      {value}
    </div>
  );
};
const W2SectionTitle = ({ entityName }) => {
  if (entityName === "deduction") {
    return "This deduction code will reduce the following:";
  } else {
    return "This earnings code will be included in the following:";
  }
};

const W2content = ({ w2data = [], associatedData = [], entityName }) => {
  return (
    <div>
      {w2data.length > 0 && (
        <div className="section-name">
          <W2SectionTitle entityName={entityName} />
        </div>
      )}
      {w2data.length > 0 && w2data[0].name === "N/A" ? (
        <div id="box-na" className="list-item">
          N/A
        </div>
      ) : (
        w2data.map(renderBoxes())
      )}
      {associatedData.length > 0 && (
        <div className="section-name">
          Amounts associated with this {entityName} will be reported as:
        </div>
      )}

      {associatedData.map(renderAssociates())}
    </div>
  );
};

export default W2content;
