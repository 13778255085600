import React, { Component } from "react";
import { PropTypes } from "prop-types";
import {
  FormSection,
  FormFieldGroup
} from "component-library/formSections/index";
import * as FIELDS from "../utils/Constants";

export default class FormerEmployer extends Component {
  render() {
    const { stateList = [], data = {}, onChange, errors } = this.props;
    return (
      <div className="tab-container-add-sub-section">
        <div className="section-items">
          <FormSection title="Former Employer">
            <FormFieldGroup
              inputType="text"
              inputName={FIELDS.COMPANY_NAME}
              value={data[FIELDS.COMPANY_NAME]}
              label="Company Name"
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              error={errors[FIELDS.COMPANY_NAME]}
              required={true}
            />

            <FormFieldGroup
              inputType="text"
              inputName={FIELDS.CITY}
              value={data[FIELDS.CITY]}
              label="City"
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              error={errors[FIELDS.CITY]}
              required={true}
            />

            <FormFieldGroup
              inputType="dropdown"
              options={stateList}
              inputName={FIELDS.STATE}
              value={data[FIELDS.STATE]}
              label="State"
              onChange={onChange}
              Size={[3, 3, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              error={errors[FIELDS.STATE]}
              required={true}
            />

            <FormFieldGroup
              inputType="numeric"
              inputName={FIELDS.PHONE_NUMBER}
              value={data[FIELDS.PHONE_NUMBER]}
              label="Phone Number"
              onChange={onChange}
              Size={[3, 3, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              optional={true}
              placeholder={"504-888-0093"}
              format="###-###-####"
            />
          </FormSection>
        </div>
      </div>
    );
  }
}

FormerEmployer.propTypes = {
  stateList: PropTypes.array
};

FormerEmployer.defaultProps = {
  stateList: []
};
