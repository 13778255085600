import React, { Component } from "react";
import PropTypes from "prop-types";
import Media from "react-media";

export default class Caption extends Component {
  static propTypes = {
    date: PropTypes.instanceOf(Date),
    months: PropTypes.arrayOf(PropTypes.string),
    locale: PropTypes.string,
    localeUtils: PropTypes.object,
    onClick: PropTypes.func,
    classNames: PropTypes.shape({
      caption: PropTypes.string.isRequired
    }).isRequired
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.locale !== this.props.locale ||
      nextProps.classNames !== this.props.classNames ||
      nextProps.date.getMonth() !== this.props.date.getMonth() ||
      nextProps.date.getFullYear() !== this.props.date.getFullYear()
    );
  }

  render() {
    const {
      classNames,
      date,
      months,
      locale,
      localeUtils,
      onClick
    } = this.props;
    return (
      <Media query="(min-width:992px)">
        {matches =>
          matches ? (
            <div className={classNames.caption} role="heading">
              <div onClick={onClick}>
                {months
                  ? `${months[date.getMonth()]} ${date.getFullYear()}`
                  : localeUtils.formatMonthTitle(date, locale)}
              </div>
            </div>
          ) : (
            <div className={classNames.caption} role="heading">
              {/* <div onClick={onClick}> */}
              {/* {months
              ? `${months[date.getMonth()]} ${date.getFullYear()}`
              : localeUtils.formatMonthTitle(date, locale)} */}
              {/* </div> */}
            </div>
          )
        }
      </Media>
    );
  }
}
