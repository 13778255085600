export const SET_COMPANY_LIST = "@app/global-navigation/SET_COMPANY_LIST";
export const CLEAR_COMPANY_LIST = "@app/global-navigation/CLEAR_COMPANY_LIST";
export const SET_ROLE_LIST = "@app/global-navigation/SET_ROLE_LIST";
export const CLEAR_ROLE_LIST = "@app/global-navigation/CLEAR_ROLE_LIST";
export const SET_FILTERED_ROLE_LIST =
  "@app/global-navigation/SET_FILTERED_ROLE_LIST";

export const SET_FILTERED_COMPANY_LIST =
  "@app/global-navigation/SET_FILTERED_COMPANY_LIST";

const initialCompanySelectionList = {
  companyList: []
};
const initialFilteredCompanyList = {
  filteredCompanyList: []
};

const initialRoleViewList = {
  roleList: []
};

const initialFilteredRoleViewList = {
  filteredRoleList: []
};

export const initialState = {
  ...initialCompanySelectionList,
  ...initialFilteredCompanyList,
  ...initialRoleViewList,
  ...initialFilteredRoleViewList
};

const globalNavigation = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COMPANY_LIST:
      return {
        ...state,
        companyList: payload
      };
    case CLEAR_COMPANY_LIST:
      return {
        ...state,
        ...initialCompanySelectionList
      };
    case SET_ROLE_LIST:
      return {
        ...state,
        roleList: payload
      };
    case CLEAR_ROLE_LIST:
      return {
        ...state,
        ...initialRoleViewList
      };
    case SET_FILTERED_ROLE_LIST:
      return {
        ...state,
        filteredRoleList: payload
      };
    case SET_FILTERED_COMPANY_LIST:
      return {
        ...state,
        filteredCompanyList: payload
      };
    default:
      return state;
  }
};

export default globalNavigation;

export const setCompanyList = options => ({
  type: SET_COMPANY_LIST,
  payload: options
});

export const clearCompanyList = () => ({
  type: CLEAR_COMPANY_LIST
});

export const setRoleList = options => ({
  type: SET_ROLE_LIST,
  payload: options
});

export const clearRoleList = () => ({
  type: CLEAR_ROLE_LIST
});

export const setFilteredRoleList = options => ({
  type: SET_FILTERED_ROLE_LIST,
  payload: options
});

export const setFilteredCompanyList = options => ({
  type: SET_FILTERED_COMPANY_LIST,
  payload: options
});
