import React from "react";
const ProgressItem = props => (
  <li
    key={props.id}
    id={props.id}
    className={props.className}
    onClick={props.onClick}
  >
    <i
      className="fa fa-check-circle"
      style={{ visibility: props.active ? "inherit" : "hidden" }}
    />
    {props.stepName}
  </li>
);
export default ProgressItem;
