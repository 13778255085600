import { combineReducers } from "redux";
import history from "./history";
import activityInstanceSnapshot from "./activityInstanceSnapshot";

const activityHistory = combineReducers({
  history,
  activityInstanceSnapshot
});

export default activityHistory;

export const getActivityHistory = state => state;
