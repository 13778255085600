import * as types from "../../actions/systemlevel/index";
export default function(state = [], action) {
  switch (action.type) {
    //case types.FETCH_DEDUCTION_LIST :
    case types.SYSTEM_DEDUCTION_MAIN_LIST:
      return action.payload.data;
    default:
      return state;
  }
}
