import axios from "axios";
import * as types from "./index";
import * as common from "../../../../app/common/index";

export function getPayrollDeductionRadioButtonList() {
  const url = `${common.WEB_API_URL}/v1/system/deductioncycle`;
  const request = axios.get(url);
  return {
    type: types.FETCH_PAYROLL_DEDUCTION_LIST,
    payload: request
  };
}

export function getScheduledPayrollsWitheldDeductionOtherRadioButtonList() {
  const url = `${common.WEB_API_URL}/v1/system/deductionsubcycle`;
  const request = axios.get(url);
  return {
    type: types.FETCH_OTHER_WITHELD_DEDUCTION_LIST,
    payload: request
  };
}

export function getScheduledPayrollsWitheldDeductionScheduledPayrollRadioButtonList() {
  const url = `${common.WEB_API_URL}/v1/app/ui/appview`;
  const request = axios.get(url);
  return {
    type: types.FETCH_SCHEDULED_PAYROLL_WITHELD_DEDUCTION_LIST,
    payload: request
  };
}
