import React from "react";
import classNames from "classnames/bind";

const SingleRoundedTab = ({
  tabItem,
  onClick,
  active = false,
  badgeStyle,
  payrollId,
  width,
  opacity
}) => {
  const disabled = tabItem.disabled;
  const activeClass = active && "activeClass";
  const disabledClass = disabled && "disabled";
  const tabClassName = classNames(activeClass, disabledClass);
  return (
    <div className="tab-wrapper" id={"tab-" + payrollId + "-" + tabItem.id}>
      <div className="nc-tab-badge" style={badgeStyle}>
        {tabItem.BadgeCount > 0 ? (
          <span
            className="badge-count badge"
            style={{
              backgroundColor: "#fb6b5b",
              visibility: tabItem.BadgeCount > 0 ? "" : "hidden"
            }}
          >
            {tabItem.BadgeCount}
          </span>
        ) : (
          <span
            style={{
              backgroundColor: "#fff",
              padding: "2px 5px",
              borderRadius: "50%",
              border: "1px solid #339966"
            }}
          >
            <i
              style={{ fontSize: "12px", marginRight: 0 }}
              className="fa fa-check green"
            />
          </span>
        )}
      </div>
      <div
        id={tabItem.Name}
        className={`single-round-tab-content ${tabClassName}`}
        onClick={!disabled && onClick}
        style={{ width: width + "px", opacity }}
      >
        <span className="single-tab-label review-single-tab-label">
          {tabItem.Name}
        </span>
      </div>
    </div>
  );
};

export default SingleRoundedTab;
