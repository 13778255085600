const NAVIGATE_TO_DESTINATION_COMPANY =
  "navigateToDestination/NAVIGATE_TO_DESTINATION_COMPANY";
const CLEAR_NAVIGATETODESTINATION =
  "navigateToDestination/CLEAR_NAVIGATETODESTINATION";

export const initialState = {
  Data: {
    isNavigateToUrl: false,
    UIId: 0,
    AppUIMenuItemId: 0,
    AppUIProductId: 0
  }
};

export const navigateToDestination = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case CLEAR_NAVIGATETODESTINATION:
      return initialState;
    case NAVIGATE_TO_DESTINATION_COMPANY:
      return {
        Data: payload
      };

    default:
      return state;
  }
};

export const navigateToDestinationCompany = data => ({
  type: NAVIGATE_TO_DESTINATION_COMPANY,
  payload: data
});

export const clearnavigationToDestination = () => ({
  type: CLEAR_NAVIGATETODESTINATION
});
