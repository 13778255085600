import {
  FILTER,
  PAYROLL_SCHEDULE
} from "component-library/entity-list/Constants";
import {
  clearHistory,
  addToHistory,
  setHistoryLoading
} from "../reducers/payrollHistory";
import {
  HOLIDAY_CALENDER,
  WEEKDAYS,
  DIRECT_DEPOSIT_OPTIONS,
  PAYROLL_SCHEDULE_DATA,
  BILLING_DATA
} from "component-library/payroll-dashboard/payrollSchedule/Constants";
import {
  setFilterOptions,
  selectAllFilterOptions
} from "app/common/reducers/filters";
import moment from "moment";
import { showToast } from "app/common/reducers/toast";
import { loadEntitySnapshot } from "app/common/reducers/entitySnapshot";
import {
  setAddPayrollScheduleOptions,
  clearAddPayrollScheduleOptions
} from "../reducers/addPayrollScheduleOptions";
import {
  setPayrollError,
  clearPayrollError
} from "../reducers/payrollScheduleError";
import { setEntityList, clearEntityList } from "app/common/reducers/entityList";
import {
  getPayrollSchedule,
  getPayrollFrequency,
  getPayrollStatus,
  addPayrollSchedule,
  getHolidayCalender,
  deletePayrollSchedule,
  getDirectDepositOptions,
  getWeekdays,
  setPayrollScheduleActive,
  setPayrollScheduleInactive,
  getPayrollScheduleById,
  updatePayrollSchedule,
  postPayrollScheduleName,
  searchPayrolls,
  loadMorePayrolls,
  getBillingTypeData,
  getPayrollCalendarPreview,
  getEntitySnapshot
} from ".";
import { setLoader } from "app/actions/LoaderActions";
import { getPayrollPayloadSchema } from "../util/schema";
import {
  setPayrollScheduleById,
  validationBillingType
} from "../reducers/addPayrollScheduleOptions";
const PAYROLL_FILTERS = FILTER[PAYROLL_SCHEDULE];

export function checkIfContainsTrue(list) {
  let count = 0;
  let keyList = Object.keys(list);
  keyList.forEach(item => {
    if (list[item] === true) {
      count = count + 1;
    }
  });
  if (count > 0) {
    return true;
  } else {
    return false;
  }
}
export const fetchAndSetPayrollSchedule = (
  clearExisting = true,
  filtersOnly = true,
  selectAll = true,
  successCallback = () => {},
  filterName = PAYROLL_FILTERS[0]
) => dispatch => {
  if (clearExisting) {
    dispatch(clearEntityList());
  }
  dispatch(setLoader(true));
  return getPayrollSchedule().then(res => {
    const { Data } = res.data;
    const options = Data.map(e => {
      return {
        value: e.CompanyPayrollScheduleDefinitionId,
        label: e.CompanyPayrollScheduleDefinitionName,
        frequecyId: e.PayrollFrequencyId,
        statusId: e.CreateWorkFlowStateId
      };
    });

    dispatch(setFilterOptions(filterName, options));
    if (!filtersOnly) {
      dispatch(setEntityList(Data));
    }
    if (selectAll) {
      dispatch(selectAllFilterOptions(filterName, true));
    }
    dispatch(setLoader(false));
    successCallback();
  });
};

export const fetchAndSetFrequency = (
  selectAll = true,
  filterName = PAYROLL_FILTERS[1]
) => dispatch => {
  return getPayrollFrequency().then(res => {
    const { Data } = res.data;
    const options = Data.map(e => ({
      value: e.PayrollFrequencyId,
      label: e.Name,
      id: e.PayrollFrequencyId
    }));

    dispatch(setFilterOptions(filterName, options));
    if (selectAll) {
      dispatch(selectAllFilterOptions(filterName, true));
    }
  });
};

export const fetchAndSetStatus = (
  selectAll = true,
  filterName = PAYROLL_FILTERS[2]
) => dispatch => {
  return getPayrollStatus().then(res => {
    const { Data } = res.data;
    let statusData = Data.filter(val => val.Id !== 1);
    const options = statusData.map(e => ({
      value: e.Id === 2 ? "TRUE" : "FALSE",
      label: e.Name
    }));

    dispatch(setFilterOptions(filterName, options));
    if (selectAll) {
      dispatch(selectAllFilterOptions(filterName, true));
    }
  });
};

export const postPayrollSchedule = data => dispatch => {
  dispatch(setLoader(true));
  if (data.edit) {
    data = getPayrollPayloadSchema(data);
    let request = [
      {
        Operation: "update",
        PathOrKey: "CompanyPayrollScheduleDefinitionName",
        Value: data.CompanyPayrollScheduleDefinitionName
      },
      {
        Operation: "update",
        PathOrKey: "PayrollFrequencyId",
        Value: data.PayrollFrequencyId
      },
      {
        Operation: "update",
        PathOrKey: "DayOfWeekToPayForWeeklyBiweeklyFrequency",
        Value: data.DayOfWeekToPayForWeeklyBiweeklyFrequency
      },
      {
        Operation: "update",
        PathOrKey: "WeekDaysToPayForDailyFrequency",
        Value: data.WeekDaysToPayForDailyFrequency
      },
      {
        Operation: "update",
        PathOrKey: "PayPeriodEndDaysPriorToPaycheckDate",
        Value: data.PayPeriodEndDaysPriorToPaycheckDate
      },
      {
        Operation: "update",
        PathOrKey: "PayPeriodWorkHours",
        Value: data.PayPeriodWorkHours
      },
      {
        Operation: "update",
        PathOrKey: "FirstPaycheckDayOfMonth",
        Value: data.FirstPaycheckDayOfMonth
      },
      {
        Operation: "update",
        PathOrKey: "FirstPaycheckMonth",
        Value: data.FirstPaycheckMonth
      },
      {
        Operation: "update",
        PathOrKey: "FirstPayPeriodEndDayOfMonth",
        Value: data.FirstPayPeriodEndDayOfMonth
      },
      {
        Operation: "update",
        PathOrKey: "FirstPayPeriodEndMonth",
        Value: data.FirstPayPeriodEndMonth
      },
      {
        Operation: "update",
        PathOrKey: "FirstPayPeriodEndOffset",
        Value: data.FirstPayPeriodEndOffset
      },
      {
        Operation: "update",
        PathOrKey: "SecondPaycheckDayOfMonth",
        Value: data.SecondPaycheckDayOfMonth
      },
      {
        Operation: "update",
        PathOrKey: "SecondPaycheckMonth",
        Value: data.SecondPaycheckMonth
      },
      {
        Operation: "update",
        PathOrKey: "SecondPayPeriodEndDayOfMonth",
        Value: data.SecondPayPeriodEndDayOfMonth
      },
      {
        Operation: "update",
        PathOrKey: "SecondPayPeriodEndMonth",
        Value: data.SecondPayPeriodEndMonth
      },
      {
        Operation: "update",
        PathOrKey: "SecondPayPeriodEndOffset",
        Value: data.SecondPayPeriodEndOffset
      },
      {
        Operation: "update",
        PathOrKey: "PayrollSubmitBusinessDaysPriorToPaycheckDate",
        Value: data.PayrollSubmitBusinessDaysPriorToPaycheckDate
      },
      {
        Operation: "update",
        PathOrKey: "DayOffsetForDirectDepositIfPaycheckDateIsOnHoliday",
        Value: data.DayOffsetForDirectDepositIfPaycheckDateIsOnHoliday
      },
      {
        Operation: "update",
        PathOrKey: "DayOptionForDirectDepositIfPaycheckDateIsOnWeekend",
        Value: data.DayOptionForDirectDepositIfPaycheckDateIsOnWeekend
      },
      {
        Operation: "update",
        PathOrKey:
          "IsAutomaticallyReplaceRegularHoursWithHolidayHoursForRegularEmployees",
        Value:
          data.IsAutomaticallyReplaceRegularHoursWithHolidayHoursForRegularEmployees
      },
      {
        Operation: "update",
        PathOrKey: "IsMatchPaycheckDateAndDirectDepositDate",
        Value: data.IsMatchPaycheckDateAndDirectDepositDate
      },
      {
        Operation: "update",
        PathOrKey: "PayrollScheduleStartDate",
        Value: data.PayrollScheduleStartDate
      },
      {
        Operation: "update",
        PathOrKey: "CompanyHolidayCalendarId",
        Value: data.CompanyHolidayCalendarId
      },
      {
        Operation: "update",
        PathOrKey: "ReasonForChange",
        Value: "This is required field."
      }
    ];

    return updatePayrollSchedule(
      request,
      data.CompanyPayrollScheduleDefinitionId
    ).then(res => {
      dispatch(setLoader(false));
      return { validation: false };
    });
  } else {
    return postPayrollScheduleName(data.PayrollScheduleName).then(res => {
      if (res.data.ErrorCount > 0) {
        dispatch(setPayrollError(res.data.Errors[0].MessageList[0]));
        dispatch(setLoader(false));
        return { validation: true };
      } else {
        dispatch(clearPayrollError());
        const body = getPayrollPayloadSchema(data);
        return addPayrollSchedule(body).then(res => {
          if (res.data.ErrorCount > 0) {
            dispatch(setPayrollError(res.data.Errors[0].MessageList[1]));
            dispatch(setLoader(false));
            return { validation: true };
          } else {
            dispatch(setLoader(false));
            return { validation: false };
          }
        });
      }
    });
  }
};

export const removePayrollSchedule = (id, payrollName) => dispatch => {
  dispatch(setLoader(true));
  return deletePayrollSchedule(id).then(res => {
    const { ErrorCount } = res.data;
    if (ErrorCount === 0) {
      dispatch(searchAndSetPayrolls());
      dispatch(setLoader(false));
      dispatch(
        showToast(
          "Your payroll schedule '" + payrollName + "' has been deleted"
        )
      );
    } else {
      dispatch(setLoader(false));
      let ToastMessage =
        "Something went wrong, unable to delete the selected earning.";
      dispatch(showToast(ToastMessage));
    }
  });
};

export const getAndSetPayrollScheduleById = id => dispatch => {
  dispatch(setLoader(true));
  getPayrollScheduleById(id).then(res => {
    if (res.data.Data !== null && res.data.Data.length > 0) {
      const data = res.data.Data[0];
      dispatch(setLoader(false));
      return dispatch(setPayrollScheduleById(PAYROLL_SCHEDULE_DATA, data));
    }
  });
};

export const fetchHolidayCalenderOptions = () => dispatch => {
  return getHolidayCalender().then(res => {
    dispatch(setAddPayrollScheduleOptions(HOLIDAY_CALENDER, res.data.Data));
  });
};

export const fetchWeekendOptions = () => dispatch =>
  getWeekdays().then(res => {
    dispatch(setAddPayrollScheduleOptions(WEEKDAYS, res.data.Data));
  });

export const fetchDirectDepositOptions = () => dispatch =>
  getDirectDepositOptions().then(res => {
    dispatch(
      setAddPayrollScheduleOptions(DIRECT_DEPOSIT_OPTIONS, res.data.Data)
    );
  });

export const clearAllAddPayrollOption = () => dispatch => {
  dispatch(clearAddPayrollScheduleOptions());
};

export const getPayrollCalendarData = data => dispatch => {
  dispatch(setLoader(true));
  const body = getPayrollPayloadSchema(data);

  body.PayrollScheduleStartDate = moment(new Date()).format("MM/YYYY");

  return getPayrollCalendarPreview(body).then(res => {
    dispatch(setLoader(false));
    return res;
  });
};

export const postPayrollScheduleToActive = id => dipatch => {
  return setPayrollScheduleActive(id);
};

export const postPayrollScheduleToInactive = id => dipatch => {
  return setPayrollScheduleInactive(id);
};

export function DoesAnyFilterHasZeroSelectedItems(filters) {
  let isZeroSelected = false;
  if (Object.keys(filters).length > 0) {
    if (
      filters.entity !== undefined &&
      filters.frequency !== undefined &&
      filters.status !== undefined &&
      (!checkIfContainsTrue(filters.entity["selected"]) ||
        !checkIfContainsTrue(filters.frequency["selected"]) ||
        !checkIfContainsTrue(filters.status["selected"]))
    ) {
      isZeroSelected = true;
    }
  }
  return isZeroSelected;
}

const defaultSearchParams = {
  Fields: ["*"],
  Criterion: [],
  Sort: [],
  PageSize: 5
};

export const searchAndSetPayrolls = (
  params = defaultSearchParams,
  filters = {},
  callback = () => {}
) => dispatch => {
  dispatch(clearHistory());
  if (DoesAnyFilterHasZeroSelectedItems(filters)) {
    dispatch(addToHistory([], 0, ""));
    callback();
    return Promise.resolve();
  } else {
    return searchPayrolls(params).then(res => {
      const { Data, DataCount, NextUri } = res.data;
      dispatch(addToHistory(Data === null ? [] : Data, DataCount, NextUri));
      dispatch(setLoader(false));
      callback();
    });
  }
};

export const loadAndSetMorePayrolls = (
  nextUri = "",
  callback = () => {}
) => dispatch => {
  dispatch(setHistoryLoading(true));
  return loadMorePayrolls(nextUri).then(res => {
    const { Data, DataCount, NextUri } = res.data;
    dispatch(addToHistory(Data, DataCount, NextUri));
    callback();
  });
};

export const validationForBillingType = () => dispatch => {
  getBillingTypeData().then(res => {
    if (res.data.Data !== null && res.data.Data.length > 0) {
      const data = res.data.Data[0].BillingTypeId;
      return dispatch(validationBillingType(BILLING_DATA, data));
    }
  });
};

export const fetchEntitySnapshot = (
  id,
  instance,
  successCallback = () => {}
) => dispatch =>
  getEntitySnapshot(id, instance).then(res => {
    const { Data = [] } = res.data;
    if (Data != null && Data.length > 0) {
      dispatch(loadEntitySnapshot(Data[0]));
      dispatch(setLoader(false));
      successCallback();
    } else {
      dispatch(setLoader(false));
      successCallback();
      //Do Something
    }
  });
