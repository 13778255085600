import React from "react";

export const ORDER_ASC = "asc";
export const ORDER_DESC = "desc";

const iconForOrder = order => {
  switch (order) {
    case ORDER_ASC:
      return "fa-sort-asc";
    case ORDER_DESC:
      return "fa-sort-desc";
    default:
      return "fa-sort";
  }
};

const ActivityHistoryColumnTitle = ({
  onClick,
  label = "",
  order,
  className,
  noSort,
  id,
  style
}) => (
  <div
    className={className + " column-header"}
    style={{ cursor: "pointer", ...style }}
    onClick={onClick}
    id={id}
  >
    {label.toUpperCase() + " "}
    {!noSort && (
      <i className={`fa ${iconForOrder(order)}`} aria-hidden="true" />
    )}
  </div>
);

export default ActivityHistoryColumnTitle;
