import React from "react";
import Slider from "component-library/range-slider/src";

const InputRangeField = props => {
  return (
    <div className={props.className}>
      <Slider
        min={props.minValue}
        max={props.maxValue}
        value={props.value}
        labels={props.horizontalLabels}
        onChange={value => {
          props.onChange({
            name: props.inputName,
            value: value
          });
        }}
        tooltip={false}
      />
      {props.error &&
        !props.value &&
        props.required && (
          <div style={{ marginTop: "5px" }} className="has-error">
            {props.error}
          </div>
        )}
    </div>
  );
};
export default InputRangeField;
