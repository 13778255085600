import React, { Component } from "react";
import { PayrollScheduleTable } from "component-library/payroll-dashboard/payrollSchedule";
import { withRouter } from "react-router-dom";

class PayrollScheduleTableContainer extends Component {
  render() {
    const {
      history,
      payrollHistory,
      postPayrollScheduleToInactive,
      removePayrollSchedule,
      postPayrollScheduleToActive,
      reactivatePayrollScheduleAndContinue,
      inactivatePayrollScheduleAndContinue,
      loadingMore,
      loadingFirstTime
    } = this.props;

    return (
      <PayrollScheduleTable
        data={this.props.tableData}
        onDeleteClick={item => removePayrollSchedule(item.id, item.name)}
        onEditClick={data => {
          history.push("/edit-payroll-schedule/" + data.id);
        }}
        reactivateEntity={(id, name) =>
          postPayrollScheduleToActive(id).then(res =>
            reactivatePayrollScheduleAndContinue(name)
          )
        }
        inactivateEntity={(id, name) =>
          postPayrollScheduleToInactive(id).then(res =>
            inactivatePayrollScheduleAndContinue(name)
          )
        }
        handleCalender={id => {
          history.push("/payroll-calendar/" + id);
        }}
        nextUri={payrollHistory.NextUri}
        loadingMore={loadingMore}
        loadingFirstTime={loadingFirstTime}
        {...this.props}
      />
    );
  }
}
export default withRouter(PayrollScheduleTableContainer);
