/** Actions */
export const SET_FIELD_OPTIONS = "people-employee-profile/SET_FIELD_OPTIONS";
export const CLEAR_FIELD_OPTION = "people-employee-profile/CLEAR_FIELD_OPTION";

const advancedFilterFields = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_FIELD_OPTIONS:
      const { name, value } = payload;
      return { ...state, [name]: value };
    case CLEAR_FIELD_OPTION:
      return {};
    default:
      return state;
  }
};

export default advancedFilterFields;

export const setEmployeeAdvancedFilterOptions = (name, value) => ({
  type: SET_FIELD_OPTIONS,
  payload: { name, value }
});

export const clearEmployeeAdvancedFilterOptions = () => ({
  type: CLEAR_FIELD_OPTION
});
