import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../deductionsetup/DeductionStyles.css";
import "../deductionsetup/newMobile.css";
import { setThirdPartyPayeeData } from "../../actions/systemlevel/thirdpartypayee/ThirdPartyPayeeSetupListingActions";
import * as Id from "app/common/CommonIds";
import Media from "react-media";
class SystemThirdPartyPayeeSetupDescription extends Component {
  state = {};

  redirectToSystemLevelAddThirdPartyPayee = () => {
    const { history, setThirdPartyPayeeData } = this.props;
    setThirdPartyPayeeData([]);
    history.push("/system-third-party-payee-add-page");
  };

  render() {
    return (
      <div id="page-header-container">
        <div className="pageTitle-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div
            id={Id.pageTitle}
            className="pageTitle col-lg-8 col-md-8 col-sm-8 col-xs-12"
          >
            Third-Party Payee Templates
          </div>
          <Media query="(min-width:992px)">
            {matches =>
              matches && this.props.isListPage ? (
                <div className="nc-navigation-button-family hidden-sm hidden-xs col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <button
                    id={Id.historyButton}
                    className="nc-button outline-button-right pull-right"
                    onClick={() =>
                      this.props.history.push(
                        "/system-third-party-payee-activity-history"
                      )
                    }
                  >
                    <i className="fa fa-history" aria-hidden="true" /> HISTORY
                  </button>

                  <button
                    id={Id.addButton}
                    type="button"
                    className="nc-button primary-button pull-right"
                    onClick={this.redirectToSystemLevelAddThirdPartyPayee}
                  >
                    <i className="fa fa-plus"> </i> ADD
                  </button>
                </div>
              ) : null
            }
          </Media>
        </div>

        <div className="deduction-page-description-container">
          <div
            id={Id.pageDescription}
            className="page-description col-lg-7 col-md-7 col-sm-12 col-xs-12"
          >
            Third-Party Payees can be associated with a deduction to generate a
            payment (printed check or direct deposit) to the payee in the amount
            of the employee and employer deduction contributions. Examples of a
            third-party payee might include state disbursement units (child
            support), federal or state agencies (tax levy), charities
            (donations), gyms (membership fees), etc.
          </div>
          <Media query="(max-width:991px)">
            {matches =>
              matches && this.props.isListPage ? (
                <div className="deduction-page-description-button col-lg-5 col-md-5 col-sm-12 col-xs-12">
                  <div className="nc-navigation-button-family col-xs-12 hidden-lg hidden-md">
                    <div className="primary-group">
                      <button
                        id={Id.addButton}
                        type="button"
                        className="nc-button primary-button"
                        onClick={this.redirectToSystemLevelAddThirdPartyPayee}
                      >
                        <i className="fa fa-plus" />
                        <span className="hidden-lg hidden-md ">&nbsp; ADD</span>
                      </button>
                    </div>
                    <div className="secondary-group">
                      <button
                        className="nc-button outline-fa-button"
                        id={Id.historyButton}
                        onClick={() =>
                          this.props.history.push(
                            "/system-third-party-payee-activity-history"
                          )
                        }
                      >
                        <i className="fa fa-history" aria-hidden="true" />
                        <span className="hidden-lg hidden-md">
                          &nbsp; HISTORY
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              ) : null
            }
          </Media>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    systemThirdPartyPayeeData: state.systemThirdPartyPayeeData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setThirdPartyPayeeData
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    SystemThirdPartyPayeeSetupDescription
  )
);
