import Loadable from "react-loadable";
import ChunkLoading from "app/common/UIComponents/ChunkLoading";

export const SystemEarningsListPage = Loadable({
  loader: () =>
    import("./pages" /* webpackChunkName: "earnings" */).then(
      m => m.SystemEarningsListPage
    ),
  loading: ChunkLoading
});

export const CompanyEarningsListPage = Loadable({
  loader: () =>
    import("./pages" /* webpackChunkName: "earnings" */).then(
      m => m.CompanyEarningsListPage
    ),
  loading: ChunkLoading
});

export const SystemEarningsSetupPage = Loadable({
  loader: () =>
    import("./pages" /* webpackChunkName: "earnings" */).then(
      m => m.SystemEarningsSetupPage
    ),
  loading: ChunkLoading
});

export const CompanyEarningsSetupPage = Loadable({
  loader: () =>
    import("./pages" /* webpackChunkName: "earnings" */).then(
      m => m.CompanyEarningsSetupPage
    ),
  loading: ChunkLoading
});
