import React from "react";

const SecondaryManager = ({ width, height, style }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 43.5 46.2"
      style={{ enableBackground: "new 0 0 43.5 46.2" }}
      height={height}
      width={width}
      xmlSpace="preserve"
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{ __html: "\n\t.st0{fill:#555555;}\n" }}
      />
      <path
        className="st0"
        d="M27.5,30.9L21.3,26v-7.2c0-0.6-0.4-1-1-1s-1,0.4-1,1V26l-6.2,4.9c-0.4,0.3-0.5,1-0.2,1.4
	c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2l5.9-4.7l5.9,4.7c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4
	C28,31.9,27.9,31.2,27.5,30.9z"
      />
      <g>
        <polygon
          className="st0"
          points="33.5,36.9 34.4,38.7 36.4,39 35,40.4 35.3,42.4 33.5,41.4 31.8,42.4 32.1,40.4 30.7,39 32.6,38.7 	"
        />
      </g>
      <path
        className="st0"
        d="M35.1,33.8h-1c2.4-0.3,4.2-2.3,4.2-4.7c0-2.7-2.2-4.8-4.8-4.8s-4.8,2.1-4.8,4.8c0,2.4,1.8,4.4,4.2,4.7h-1
	c-4.1,0-7.5,3.4-7.5,7.5v1.3v2.2c0,0.6,0.4,1,1,1h1.4h13.4h1.4c0.6,0,1-0.4,1-1v-2.2v-1.3C42.6,37.2,39.2,33.8,35.1,33.8z
	 M30.7,29.1c0-1.5,1.3-2.8,2.8-2.8s2.8,1.3,2.8,2.8s-1.3,2.8-2.8,2.8S30.7,30.6,30.7,29.1z M40.6,43.8H26.4v-2.5
	c0-3,2.5-5.5,5.5-5.5h3.2c3,0,5.5,2.5,5.5,5.5V43.8z"
      />
      <path
        className="st0"
        d="M8.1,35.1H7.2c1.9-0.1,3.4-1.6,3.4-3.5c0-2-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5c0,1.9,1.5,3.5,3.4,3.5H6.1
	c-2.8,0-5.1,2.3-5.1,5.1v2.1c0,0.6,0.5,1.1,1.1,1.1H12c0.6,0,1.1-0.5,1.1-1.1v-2.1C13.2,37.4,10.9,35.1,8.1,35.1z M5.6,31.6
	c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5S5.6,32.4,5.6,31.6z M10.9,40.2v1H3.3v-1c0-1.5,1.3-2.9,2.9-2.9h2
	C9.6,37.3,10.9,38.6,10.9,40.2z"
      />
      <path
        className="st0"
        d="M21.3,8.2h-0.9c1.9-0.1,3.4-1.6,3.4-3.5s-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5s1.5,3.5,3.4,3.5h-0.9
	c-2.8,0-5.1,2.3-5.1,5.1v2.1c0,0.6,0.5,1.1,1.1,1.1h9.9c0.6,0,1.1-0.5,1.1-1.1v-2.1C26.4,10.4,24.1,8.2,21.3,8.2z M18.8,4.6
	c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5S18.8,5.5,18.8,4.6z M24.1,13.2v1h-7.7v-1c0-1.5,1.3-2.9,2.9-2.9h2
	C22.8,10.4,24.1,11.7,24.1,13.2z"
      />
    </svg>
  );
};

export default SecondaryManager;
