import { connect } from "react-redux";
import AddEmployeeNotes from "../containers/AddEmployeeNotes";
import {
  fetchTypeOfNote,
  addNewNoteType,
  addNewNote
} from "../network/actions";
import { clearAddEmployeeNotesValues } from "../reducers/addEmployeeNotes";

const stateToProps = state => ({
  data: state.Others,
  sessionContext: state.sessionContext
});
const actionCreators = {
  fetchTypeOfNote,
  clearAddEmployeeNotesValues,
  addNewNoteType,
  addNewNote
};
export default connect(stateToProps, actionCreators)(AddEmployeeNotes);
