import React from "react";
import Modal from "react-bootstrap/lib/Modal";

import "./EarningsDeleteModal.css";

const EarningsInactivateModal = ({
  show,
  closeModal,
  submit,
  message = "",
  Id
}) => (
  <Modal
    bsClass="list-page-cancel modal"
    animation={false}
    backdrop={"static"}
    keyboard={false}
    show={show}
  >
    <Modal.Header>
      <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <i
          id="system-level-cancel-button-close-icon"
          onClick={closeModal}
          className="fa fa-times-circle-o fa-lg"
        />
      </div>
    </Modal.Header>
    <Modal.Body>
      <div>
        <div className="modal-row row col-xs-12 col-sm-12 col-md-12 col-lg-12">
          Reactivating the earnings will not reactivate the earnings at employee
          level.
        </div>

        <div className="message-footer col-xs-12 col-sm-12 col-md-12 col-lg-12" />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <div className="button-group">
        <button
          type="button"
          id={Id.modalOkButton}
          className="nc-button primary-button"
          onClick={submit}
        >
          OK
        </button>
      </div>
    </Modal.Footer>
    {/* </div> */}
  </Modal>
);

export default EarningsInactivateModal;
