/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import getInputComponent from "../../../inputFields/getInputComponent";

const DropDown = getInputComponent("dropdown");
const ChangeReasonSection = props => {
  return (
    <div
      className="nc-field-group clearfix"
      style={{ clear: "both", display: "flex", alignItems: "center" }}
    >
      <div
        id={"Label" + props.inputName}
        style={{ paddingRight: "15px" }}
        className={`${props.colSpan} " " ${"col-lg-3 field-name clearfix"}`}
      >
        {props.label}{" "}
      </div>
      <div
        className="col-lg-9"
        style={{ display: "flex", alignItems: "center" }}
      >
        <DropDown
          options={props.options}
          optional
          name={props.inputName}
          value={props.value}
          onChange={props.onChange}
          className="input-field col-lg-4 col-md-6 col-sm-12 col-xs-12"
        />
        <div style={{ marginLeft: "10px", marginBottom: "15px" }}>
          <a onClick={props.onAddLinkClick}>
            <i className="fa fa-plus" /> Add Change Reason
          </a>
        </div>
      </div>
    </div>
  );
};

export default ChangeReasonSection;
