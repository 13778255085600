import Loadable from "react-loadable";
import ChunkLoading from "app/common/UIComponents/ChunkLoading";

const ActivityHistory = Loadable({
  loader: () =>
    import("./ActivityHistory" /* webpackChunkName: "activity-history" */),
  loading: ChunkLoading
});

export { ActivityHistory };
