import React from "react";
import * as payrollConstants from "./PayrollDashboardConstants";
import { InProgress } from "./images";
import PropTypes from "prop-types";
const PayrollDashboardSummaryBanner = ({ data }) => {
  if (data.PayrollStageId === payrollConstants.PAYROLL_IN_PROGRESS) {
    return (
      <div className="payroll-dash-summary-banner-section">
        <div className="payroll-dash-summary-banner-inprogress">
          <InProgress width="20" height="20" style={{ paddingRight: "5px" }} />
          This Payroll is in Progress.
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default PayrollDashboardSummaryBanner;
PayrollDashboardSummaryBanner.propTypes = {
  data: PropTypes.object
};
PayrollDashboardSummaryBanner.defaultProps = {
  data: {}
};
