import React from "react";
import ListItem from "./address-verification/ListItem";
import TableHeader from "./TableHeader";
import Header from "../multi-section-table/MobileHeader";
import { AddressInTitle } from "../images/WageAndTaxValidation";
import "./WageTaxValidation.css";
export function highlighter(newElem, oldElem) {
  var newText = newElem;
  var oldText = oldElem;
  if (newText) {
    return newText.split("").map((value, index) => {
      if (value !== oldText.charAt(index))
        return (
          <span style={{ backgroundColor: "#ccf" }} className="highlight">
            {value}
          </span>
        );
      else return <span>{value}</span>;
    });
  }
  return null;
}
export function addressSpliter(oldAddress = {}, newAddress = {}) {
  const { Line1, Line2, City, State, Zipcode } = newAddress;
  let formattedAddress = {};
  if (Line1) {
    formattedAddress.Line1 = highlighter(Line1, oldAddress.Line1 || "");
  }
  if (Line2) {
    formattedAddress.Line2 = highlighter(Line2, oldAddress.Line2 || "");
  }
  if (City) {
    formattedAddress.City = highlighter(City, oldAddress.City || "");
  }
  if (State) {
    formattedAddress.State = highlighter(State, oldAddress.State || "");
  }
  if (Zipcode) {
    formattedAddress.Zipcode = highlighter(Zipcode, oldAddress.Zipcode || "");
  }

  return formattedAddress;
}
const Columns = [
  {
    label: "Employee",
    field: "Employee",
    className: "col-lg-4 col-md-3"
  },
  {
    label: "CURRENT HOME ADDRESS ",
    field: "TimeCardAlertName",
    className: "col-lg-4 col-md-4",
    noSort: true
  },
  {
    label: "SUGGESTED HOME ADDRESS",
    field: "TimeGroupName",
    className: "col-lg-4 col-md-5",
    noSort: true
  }
];
const AddressVerification = ({
  data = [],
  updateAddressForEmployee,
  editAddress,
  sortOrder,
  changeSortOrder = () => {},
  sectionHr,
  redirectToChecklist
}) => (
  <div className="wage-tax-sub-section">
    <Header
      title="Address Verification"
      icon={<AddressInTitle width={25} height={25} />}
      badgeCount={data.length}
      description="We found a more precise address for the employees below."
      onClick={redirectToChecklist}
    />
    <TableHeader
      columns={Columns}
      changeSortOrder={changeSortOrder}
      sortOrder={sortOrder}
    />
    <div className="wage-table-container">
      {data.map((item, i) => (
        <ListItem
          key={item.Id}
          data={item}
          updateAddress={data => updateAddressForEmployee(data)}
          currentValue={item.EmployeeCurrentHomeAddress}
          suggestedValue={item.EmployeeSuggestedHomeAddress}
          checkListName="Address"
          sectionHr={sectionHr}
          onEditAddressClick={() => editAddress(item)}
        />
      ))}
    </div>
  </div>
);

export default AddressVerification;
AddressVerification.defaultProps = {
  data: [],
  updateAddressForEmployee: () =>
    alert("Should update the address for employees"),
  sortOrder: {},
  changeSortOrder: () => {},
  sectionHr: false,
  redirectToChecklist: () => alert("Should redirect to Checklist page")
};
