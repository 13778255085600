import React from "react";
import { TransferDetails } from "component-library/employee-profile/employment-info/change-status/transferred/index";
import {
  ScheduledDeductions,
  ReplacementManagers,
  ScheduledEarnings,
  SelfService
} from "component-library/employee-profile/employment-info/change-status/common";
import FooterButtons from "../components/FooterButtons";
import { TRANSFER_FOOTNOTE } from "../Constants";

const Tranferred = ({ data = {}, onChange, onCloseOverlay }) => {
  return (
    <div>
      <TransferDetails onChange={onChange} data={data} />
      <ScheduledEarnings onChange={onChange} data={data} />
      <ScheduledDeductions onChange={onChange} data={data} />
      <ReplacementManagers onChange={onChange} data={data} />
      <SelfService onChange={onChange} data={data} />
      <FooterButtons
        buttonLabel="Transfer Employee"
        footNote={TRANSFER_FOOTNOTE}
        onCancelClick={onCloseOverlay}
      />
    </div>
  );
};

export default Tranferred;
