import React, { Component } from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import { verification } from "../Constants";

class DependentVerification extends Component {
  renderVerification = componentProps => {
    return Object.keys(componentProps).map(k => {
      return (
        <FormFieldGroup
          key={k}
          {...componentProps[k]}
          block
          colSpan="col-md-5"
          value={this.props.data[k]}
          onDateChange={this.props.handleDateChange}
          onChange={this.props.onChange}
        />
      );
    });
  };

  render() {
    //const { data } = this.props;
    return (
      <FormSection title="Verification" hr>
        <div className="col-md-7" style={{ padding: "0px" }}>
          {this.renderVerification(verification)}
        </div>
      </FormSection>
    );
  }
}

export default DependentVerification;
