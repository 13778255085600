import axios from "axios";
import * as types from "./index";
import * as common from "../../../../app/common/index";

export function getCalculationMethodRadioButtonList() {
  const url = `${common.WEB_API_URL}/v1/system/deductioncalculationbasis`;
  const request = axios.get(url);
  return {
    type: types.COMPANY_CALCULATION_FETCH_CALCULATION_METHOD_OPTIONS_LIST,
    payload: request
  };
}

//when garnishment is not selected
export function getEarningPercentValuesList() {
  const url = `${
    common.WEB_API_URL
  }/v1/subscriber/company/earninggroup?filter=active`;
  const request = axios.get(url);
  return {
    type: types.COMPANY_CALCULATION_FETCH_EARNING_PERCENT_OPTIONS_LIST,
    payload: request
  };
}

export function getCustomEarningsValuesList() {
  //const url=`${common.WEB_API_MOCK_URL}/v1/subscriber/company/earninggroup/search/1?page=5&_mock_=datasize`;
  const url = `${
    common.WEB_API_URL
  }/v1/subscriber/company/earninggroup?filter=active`;
  const request = axios.get(url);
  return {
    type: types.COMPANY_CALCULATION_FETCH_CUSTOM_EARNINGS_OPTIONS_LIST,
    payload: request
  };
}

// export function getCustomEarningsValuesList(){
//     const url=`${common.WEB_API_MOCK_URL}/v1/subscriber/company/earninggroup?_mock_=datasize`;
//     let dataResponse;
//     dataResponse = axios.post(url,
//         {
//         "EarningGroupId": 0,
//         "EarningGroupKey": "string",
//         "EarningGroupName": "string"
//         }
//     );

//     if(dataResponse){
//         return {
//             type : types.COMPANY_CALCULATION_FETCH_CUSTOM_EARNINGS_OPTIONS_LIST,
//             payload : dataResponse
//         };
//     }
//     else{
//         return {
//             type : types.COMPANY_CALCULATION_FETCH_CUSTOM_EARNINGS_OPTIONS_LIST,
//             payload : []
//         };
//     }

// }

export function getAdvancedPretaxValuesList() {
  const url = `${common.WEB_API_URL}/v1/app/ui/appview`;
  const request = axios.get(url);
  return {
    type: types.COMPANY_CALCULATION_FETCH_ADVANCED_PRETAX_OPTIONS_LIST,
    payload: request
  };
}

export function getAdvancedRetirementValuesList() {
  const url = `${common.WEB_API_URL}/v1/app/ui/appview`;
  const request = axios.get(url);
  return {
    type: types.COMPANY_CALCULATION_FETCH_ADVANCED_RETIREMENT_OPTIONS_LIST,
    payload: request
  };
}

//when garnishment is selected
export function getGarnishmentValuesList() {
  const url = `${common.WEB_API_URL}/v1/app/ui/appview`;
  const request = axios.get(url);
  return {
    type: types.COMPANY_CALCULATION_FETCH_GARNISHMENT_EARNINGS_OPTIONS_LIST,
    payload: request
  };
}

export function getCompanyAcaDeductionRadioButtonValue() {
  const url = `${common.WEB_API_URL}/v1/system/deductioncalculationbasisoption`;
  const request = axios.get(url);
  return {
    type: types.FETCH_COMPANY_ACA_DEDUCTION_OPTION_LIST,
    payload: request
  };
}

export function getCompanyAcaDeductionDropDownList(CompanyDeductionId) {
  const url = `${
    common.WEB_API_URL
  }/v1/subscriber/company/deduction/CompanyDeductionByCalculationBasisId/${CompanyDeductionId}`;

  const request = axios.get(url);
  return {
    type: types.FETCH_COMPANY_ACA_DEDUCTION_OPTION_DROPDOWN_LIST,
    payload: request
  };
}
