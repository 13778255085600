export function isNullOrEmpty(value) {
  if (value === null || value === "") {
    return true;
  } else {
    return false;
  }
}

export function isNullOrZero(value) {
  if (value === null || value === 0) {
    return true;
  } else {
    return false;
  }
}

export function isUndefinedOrZero(value) {
  if (value === undefined || value.length === 0) {
    return true;
  } else {
    return false;
  }
}
