import React from "react";
import SetupStepTitle from "./SetupStepTitle";
import * as DESCRIPTION from "./StepDescription";
const EarningsOptions = props => {
  return (
    <div className="entity-page-content col-lg-9 col-md-8">
      <SetupStepTitle stepNumber={"4"} title="Options" />
      <div className="step-description">{DESCRIPTION.OPTIONS}</div>
      <div className="seperator" style={{ marginTop: "20px" }}>
        <hr />
      </div>
      <div className="description-content">{props.children}</div>
    </div>
  );
};

export default EarningsOptions;
