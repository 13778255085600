import React, { Component } from "react";
import PropTypes from "prop-types";

const AddButton = ({ onClick, label, id }) => (
  <button
    id={id}
    type="button"
    onClick={onClick}
    className="nc-button secondary-button pull-right"
  >
    <i className="fa fa-plus"> </i> {label}
  </button>
);

export default class TabContainerAddButtonSection extends Component {
  render() {
    const { onAddButtonClick, discription } = this.props;
    return (
      <div className="tab-container-section">
        <div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
          {discription}
        </div>
        <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
          <AddButton
            onClick={onAddButtonClick}
            label={"Add"}
            id={"add-entity-button"}
          />
        </div>
      </div>
    );
  }
}

TabContainerAddButtonSection.propTypes = {
  activePanel: PropTypes.object,
  onAddButtonClick: PropTypes.func
};

TabContainerAddButtonSection.defaultProps = {
  activePanel: {
    SectionItemId: 1,
    SectionItemName: "Section Item Name",
    SectionItemLink1: "Employeement History",
    SectionItemLink2: "Files",
    SectionItemLink3: ""
  },
  onAddButtonClick: () => {}
};
