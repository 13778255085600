import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Prompt } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import DropDown from "component-library/dropDown/DropDown";
import * as common from "app/common/index.js";
import axios from "axios";
import { setLoader } from "app/actions/LoaderActions";

import "../../../../content/entitypage.css";
import "../../../../content/listpage.css";
import "../../../../content/style.css";
import "../GeneralLedger.css";
import {
  setGlobalNavClick,
  CallbackToQuickLink,
  setFormUpdate,
  isOverlayClickable
} from "app/actions/FormUpdateAction";

import { showToaster, showToasterByClass } from "app/actions/AppActions";
import Toaster from "app/common/UIComponents/Toaster";

import GeneralLedgerEarningsDescription from "../earnings/GeneralLedgerEarningsDescription";

import EmployerPaidEarningsAdvanceFilter from "../earnings/EmployerPaidEarningsAdvanceFilter";
import {
  getEmployerEarningsAccountsMainList,
  getEmployerEarningsExpenceAccountsList,
  getEmployerEarningsLiabilityAccountsList,
  updatedEarningsFilteredList,
  saveEmployerEarningsExpenceLiabilityAccountsList,
  setEmployerEarningsData,
  getEmployerEarningsAccountsFilteredList,
  setEmployerEarningsLiabilityAccountsData,
  getEarningsList
} from "../../actions/generalledger/earnings/EmployerPaidEarningsActions.js";
import * as Id from "app/common/CommonIds";
import * as message from "app/common/messages";
import NoEntityToSelect from "component-library/entity-list/NoEntityToSelect";
const AppRoleIdForClientAdmin = String(2);
function sortByKey(array, key) {
  return array.sort(function(a, b) {
    let x = a[key].toLowerCase();
    let y = b[key].toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

let isInDidUpdate = false;
class EmployerPaidEarnings extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showBackCancelModal: false,
      isTableSorted: undefined,
      sortedKey: "",
      accountsTableList: [],
      selectedDeductions: [],
      selectedGLCategories: [],
      key: 1,

      showDropdownSection: false,
      showUnMappedBadgeForExpenceDeduction: false,
      showUnmappedBadgeForLiabilityAccount: false,

      showUnMappedBadgeForDeduction2: false,
      showUnmappedBadgeForLiabilityAccount2: false,
      showDropDownsSection2: false,

      isUnmapped: false,
      unmappedCount: 0,
      unmappedList: [],
      isFormUpdated: false,

      showToaster: false,
      messageToaster: "",

      showCancelModalWithUnmapped: false,
      showCancelModalWithoutUnmapped: false,
      unmappedCheckState: false,
      alertClassName: "success",
      disableDropdown: false
    };

    this.saveEmplyerExpenceLiabilityAccounts = this.saveEmplyerExpenceLiabilityAccounts.bind(
      this
    );

    this.showSortedIcon = this.showSortedIcon.bind(this);
    this.renderEmployeeLiabilityTable = this.renderEmployeeLiabilityTable.bind(
      this
    );
    this.handleSelect = this.handleSelect.bind(this);

    this.closeCancelModal = this.closeCancelModal.bind(this);
    this.openCancelModalWithUnmapped = this.openCancelModalWithUnmapped.bind(
      this
    );
    this.openCancelModalWithoutUnmapped = this.openCancelModalWithoutUnmapped.bind(
      this
    );
    this.onModalCancelChangesMethod = this.onModalCancelChangesMethod.bind(
      this
    );
    this.close = this.close.bind(this);
    this.redirectToGlobalNavLink = this.redirectToGlobalNavLink.bind(this);

    //this.buttonSave = this.buttonSave.bind(this);

    this.showToasterMessage = this.showToasterMessage.bind(this);

    // this.props.updatedEarningsFilteredList();
  }

  componentDidMount() {
    this.props.getEmployerEarningsAccountsMainList();
    this.props.getEarningsList();
    this.props.getEmployerEarningsExpenceAccountsList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.employerEarningsAccountsMainList !==
      this.props.employerEarningsAccountsMainList
    ) {
      if (
        this.props.employerEarningsAccountsMainList.Data.length !== 0 &&
        this.props.employerEarningsAccountsMainList.Data[0]
          .IsPayrollInProgressForCompany &&
        this.props.sessionContext.CurrentAppUiViewId === AppRoleIdForClientAdmin
      ) {
        const message =
          "Payroll is currently in progress. General Ledger Accounts mapping cannot be modified at this time.";
        this.setState({
          isPayrollInProgressToaster: true,
          payrollInProgressMessage: message,
          payrollInProgressAlertClassName: "warning",
          disableDropdown: true
        });
      }

      isInDidUpdate = true;

      this.props.updatedEarningsFilteredList(
        this.props.employerEarningsAccountsMainList
      );

      if (prevState.unmappedCount !== this.state.unmappedCount) {
        this.props.unamappedCount(this.state.unmappedCount);
      }
      isInDidUpdate = false;
    }
    if (
      prevProps.employerEarningsAccountsFilteredList !==
      this.props.employerEarningsAccountsFilteredList
    ) {
      isInDidUpdate = true;

      let unmappedList = [];
      if (this.props.employerEarningsAccountsMainList) {
        this.props.employerEarningsAccountsMainList.Data.forEach(item => {
          if (
            item.EmployerContributionExpenseGlAccountId === 0 ||
            item.EmployerContributionExpenseGlAccountId === null
          ) {
            // this.setState({
            //   isFormUpdated: false
            // });
            this.props.setFormUpdate(false);
            unmappedList.push(item.CompanyEarningId);
          }
        });

        this.setState({
          unmappedList,
          unmappedCount: unmappedList.length
          //isFormUpdated: false
        });
      }
      isInDidUpdate = false;
    }

    if (prevProps.toasterData !== this.props.toasterData) {
      isInDidUpdate = true;
      if (this.props.toasterData.show) {
        this.setState({
          messageToaster: this.props.toasterData.message
        });
        this.showToasterMessage();
      }
      isInDidUpdate = false;
    }
    if (
      prevProps.globalNavClick === null &&
      this.props.globalNavClick !== null
    ) {
      if (this.props.globalNavClick.isLinkClick) {
        this.setState({
          showBackCancelModal: true,
          isFormUpdated: false
        });
        this.props.setFormUpdate(false);
      }
    } else {
      if (
        prevProps.globalNavClick !== null &&
        this.props.globalNavClick !== null
      ) {
        if (
          prevProps.globalNavClick.isLinkClick !==
          this.props.globalNavClick.isLinkClick
        ) {
          if (this.props.globalNavClick.isLinkClick) {
            this.setState({
              showBackCancelModal: true,
              isFormUpdated: false
            });
            this.props.setFormUpdate(false);
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.showToaster(false, ``);
    this.setState({
      showToaster: false
    });
    this.props.setFormUpdate(false);
  }

  getFilteredList() {
    if (this.props.employerEarningsAccountsMainList.DataCount > 0) {
      this.props.getEmployerEarningsAccountsFilteredList(
        this.props.employerEarningsAccountsMainList.Data,
        this.state.selectedDeductions,
        this.state.unmappedCheckState
      );
    }
  }

  showToasterMessage() {
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
    }
    this.setState({
      showToaster: true
    });
  }

  redirectToGlobalNavLink() {
    this.setState({
      showBackCancelModal: false
    });
    this.props.setFormUpdate(false);
    this.props.setGlobalNavClick(
      false,
      this.props.globalNavClick.LinkItem,
      this.props.globalNavClick.MenuLink
    );
    this.props.CallbackToQuickLink(true);
    this.props.isOverlayClickable(false);
    window.location.reload();
  }

  closeToasterMessage = () => {
    this.setState({ showToaster: false });
    this.props.showToaster(false, "");
  };

  closeCancelModal() {
    this.setState({
      showCancelModalWithUnmapped: false,
      showCancelModalWithoutUnmapped: false,
      isFormUpdated: true
    });
  }

  openCancelModalWithUnmapped() {
    this.setState({ showCancelModalWithUnmapped: true });
  }

  openCancelModalWithoutUnmapped() {
    this.setState({ showCancelModalWithoutUnmapped: true });
  }

  close() {
    this.setState({
      showCancelModalWithUnmapped: false,
      showCancelModalWithoutUnmapped: false,
      isFormUpdated: true,
      showBackCancelModal: false,
      showActivityHistoryModal: false
    });
    this.props.setFormUpdate(true);
    this.props.setGlobalNavClick(false);
  }

  onModalCancelChangesMethod() {
    this.props.getEmployerEarningsAccountsMainList();
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
    }
    this.setState({
      showCancelModalWithUnmapped: false,
      showCancelModalWithoutUnmapped: false
    });
  }

  showCancelButtonMessageWithUnmappedEarnings(Id) {
    return (
      <div>
        <Modal.Header>
          <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <i
              id={Id.modalCloseIcon}
              onClick={this.close}
              className="fa fa-times-circle-o fa-lg"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div id={Id.modalMessage} className="modal-row">
            <div id="cancel-modal-line1">You have unsaved changes.</div>
            <br />
            <div id="cancel-modal-line2">
              Do you want to continue editing or cancel your changes?
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <button
              type="button"
              Id={"continue-editing-button-" + Id}
              className="nc-button secondary-button"
              onClick={this.closeCancelModal}
            >
              Continue Editing
            </button>

            <button
              type="button"
              Id={"cancle-button-" + Id}
              className="nc-button secondary-link-button"
              onClick={this.onModalCancelChangesMethod}
            >
              Cancel Changes
            </button>
          </div>
        </Modal.Footer>
      </div>
    );
  }

  showCancelButtonMessageWithoutUnmappedEarnings() {
    // const employerListSave = this.props.employerEarningsAccountsFilteredList.Data;
    return (
      <div>
        <Modal.Header>
          <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <i
              id={Id.modalCloseIcon}
              onClick={this.close}
              className="fa fa-times-circle-o fa-lg"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div id={Id.modalMessage} className="modal-row">
            <div id="cancel-modal-line1">You have unsaved changes.</div>
            <br />
            <div id="cancel-modal-line2">Do you want to save your changes?</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <button
              type="button"
              id="map-gl-earnings-save-changes-button"
              className="nc-button secondary-button"
              onClick={this.saveEmplyerExpenceLiabilityAccounts.bind(
                this,
                this.props.employerEarningsAccountsMainList
              )}
            >
              Save Changes
            </button>

            <button
              type="button"
              id={Id.modalSuccessButton}
              className="nc-button secondary-button"
              onClick={this.closeCancelModal}
            >
              Continue Editing
            </button>
            <button
              type="button"
              id={Id.modalCancelButton}
              className="nc-button secondary-link-button"
              onClick={this.onModalCancelChangesMethod}
            >
              Cancel Changes
            </button>
          </div>
        </Modal.Footer>
      </div>
    );
  }

  sortColumnByKey(key) {
    if (this.props.employerEarningsAccountsFilteredList.Data) {
      this.props.employerEarningsAccountsFilteredList.Data = sortByKey(
        this.props.employerEarningsAccountsFilteredList.Data,
        key
      );
      this.setState({
        sortedKey: key
      });
      if (this.state.isTableSorted) {
        this.setState({
          isTableSorted: false
        });
        this.props.employerEarningsAccountsFilteredList.Data = this.props.employerEarningsAccountsFilteredList.Data.reverse();
      }
      if (this.state.isTableSorted === undefined) {
        this.setState({
          isTableSorted: true
        });
      }
      if (this.state.isTableSorted === false) {
        this.setState({
          isTableSorted: true
        });
      }
    }
  }

  showSortedIcon() {
    if (this.state.isTableSorted) {
      return <i className="fa fa-sort-asc" aria-hidden="true" />;
    } else if (this.state.isTableSorted === false) {
      return <i className="fa fa-sort-desc" aria-hidden="true" />;
    } else {
      return <i className="fa fa-sort" aria-hidden="true" />;
    }
  }

  //used for drop down
  showCustomIcon() {
    return <i className="fa fa-angle-down" aria-hidden="true" />;
  }

  setEmployerExpenceAccountContext(item, newValue) {
    item.IsUpdate = true;
    item.EmployerContributionExpenseGlAccountId = newValue;
    let update = {};
    update[item.CompanyEarningId] = newValue;
    this.setState(update);
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
    if (
      item.EmployerContributionExpenseGlAccountId !== 0 ||
      item.EmployerContributionExpenseGlAccountId !== null
    ) {
      // if (isInDidUpdate === false) {
      //   this.setState({
      //     isFormUpdated: true
      //   });
      // }
      let unmappedList = this.state.unmappedList.slice();
      unmappedList = unmappedList.filter(function(removeItem) {
        return removeItem !== item.CompanyEarningId;
      });
      this.setState({
        unmappedList: unmappedList,
        unmappedCount: unmappedList.length
      });
      if (this.state.unmappedCheckState === true) {
        if (
          item.EmployerContributionExpenseGlAccountId !== null ||
          item.EmployerContributionExpenseGlAccountId !== 0
        ) {
          this.getFilteredList();
        }
      }
      //this.getFilteredList();
    }
  }

  setDeductions(selectedDeductions) {
    this.setState({
      selectedDeductions
    });
  }

  setDeductionFilter(selectedDeductions) {
    this.setState({ selectedDeductions });
  }

  saveEmplyerExpenceLiabilityAccounts(employerEarningsAccountsMainList) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.props.setLoader(true);
    this.setState({
      showCancelModalWithoutUnmapped: false,
      isFormUpdated: false
    });
    this.props.setFormUpdate(false);
    let self = this;
    let patchAction = [];
    let data = this.state;
    this.props.employerEarningsAccountsMainList.Data.forEach(item => {
      let patchItem = {
        Operation: "update",
        PathOrKey: "DeductionGLAccountMapping",
        Value: [
          {
            GLAccountKey: "EmployerContributionExpenseGlAccount",
            PkId: item.EmployerContributionExpenseGlAccountPkId,
            CompanyEarningId: item.CompanyEarningId,
            GLAccountId: item.EmployerContributionExpenseGlAccountId
          }
        ]
      };
      item.IsUpdate && patchAction.push(patchItem);
      item.IsUpdate = false;
    });

    const url = `${
      common.WEB_API_URL
    }/v1/subscriber/company/earning/action/mapglaccount`;
    axios
      .post(url, patchAction)
      .then(function(response) {
        self.props.setLoader(false);
        if (response.data.ErrorCount > 0) {
          response.data.Errors.forEach((item, index) => {
            if (index === 0) {
              alert(item.Messages);
            }
          });
        } else {
          self.props.setEmployerEarningsLiabilityAccountsData(data);
          self.setState({
            isFormUpdated: false,
            messageToaster: `General ledger accounts have been updated.`
          });
          self.showToasterMessage();
        }
      })
      .catch(function(error) {});
    //End
  }

  //for tabs at top
  handleSelect(key) {
    this.setState({ key });
  }

  setUnmappedState(unmappedCheckState) {
    this.setState({
      unmappedCheckState
    });
  }

  renderEmployerExpenseContentRow(employerList) {
    if (!employerList) {
      return <div>No Records Found.</div>;
    }
    return employerList.map((item, index) => {
      let isUnmapped = false;

      if (this.state.unmappedList.includes(item.CompanyEarningId)) {
        isUnmapped = true;
      }

      return (
        <div
          key={index}
          className="table-row clearfix"
          style={{ paddingTop: "25px" }}
        >
          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 table-cell name-column"
            style={{ cursor: "default" }}
          >
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              {isUnmapped ? (
                <Badge
                  id={"unmapped-badge-" + item.CompanyEarningId}
                  className="unmapped-badge"
                >
                  Unmapped
                </Badge>
              ) : null}
            </div>
            <div
              id={Id.nameColumnItem + item.CompanyEarningId}
              className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            >
              {item.CompanyEarningName}
            </div>
          </div>

          <div className="nc-field-group">
            <div className="clearfix hidden-lg hidden-md" />
            <div className="field-name" style={{ fontWeight: 600 }}>
              <span className="hidden-lg hidden-md">Expense Account</span>
            </div>
            <div className="input-field employer-contribution-expense-account col-lg-4 col-md-5">
              <DropDown
                id={Id.categoryColumnItem + item.CompanyEarningId}
                placeholder="Select"
                options={this.props.employerExpenceAccountDropDown}
                value={item.EmployerContributionExpenseGlAccountId}
                onSelect={this.setEmployerExpenceAccountContext.bind(
                  this,
                  item
                )}
                className="employer-expence-account"
                disabled={this.state.disableDropdown}
              />
            </div>
          </div>
        </div>
      );
    });
  }

  renderEmployeeLiabilityTable() {
    if (!this.props.employerEarningsAccountsFilteredList.Data) {
      return <div />;
    }

    const employerList = this.props.employerEarningsAccountsFilteredList.Data;
    let isUnmappedAny = false;
    if (this.state.unmappedCount > 0) {
      isUnmappedAny = true;
    }

    return (
      <div>
        <div className="">
          <div className="col-lg-12 col-md-12 row table-header visible-lg visible-md">
            <div
              onClick={this.sortColumnByKey.bind(
                this,
                "CompanyEarningName",
                employerList
              )}
              id={Id.nameColumnHeader}
              className="col-lg-5 col-md-5 user-column-header"
            >
              EARNINGS{" "}
              {this.state.sortedKey === "CompanyEarningName" ? (
                this.showSortedIcon()
              ) : (
                <i className="fa fa-sort" aria-hidden="true" />
              )}
            </div>
            <div
              id={Id.categoryColumnHeader}
              className="col-lg-4 col-md-5 column-header"
              //style={{ textAlign: "center" }}
            >
              EXPENSE ACCOUNT{" "}
            </div>
          </div>
          {this.renderEmployerExpenseContentRow(employerList)}
        </div>

        <div className="seperator">
          <hr />
        </div>
        <div className="footer-button-container">
          {isUnmappedAny ? (
            <div className="unmapped-count-badge-section clearfix">
              <Badge id="unmapped-count-badge" className="unmapped-count-badge">
                {this.state.unmappedCount}
              </Badge>
              <span
                id="unmapped-count-badge-description"
                className="unmapped-count-badge-description"
              >
                Unmapped Earnings remaining. All Earnings need to be mapped
                before continuing.
              </span>
            </div>
          ) : (
            <div id="earnings-footer-section-all-mapped" />
          )}
          <div className="nc-button-group">
            <button
              type="button"
              id="employer-expence-liability-accounts-buttons-save"
              className={
                this.state.isFormUpdated === false || isUnmappedAny === true
                  ? "disabled-button"
                  : "nc-button primary-button"
              }
              onClick={this.saveEmplyerExpenceLiabilityAccounts.bind(
                this,
                employerList
              )}
              disabled={isUnmappedAny || this.state.isFormUpdated === false}
            >
              SAVE CHANGES
            </button>
          </div>
        </div>
      </div>
    );
  }

  showUnMappedBadgeSection() {
    return (
      <div>
        <div>
          <Badge className="unmapped-badge-for-non-selection">Unmapped</Badge>
        </div>
      </div>
    );
  }

  onClickActivityHistoryButton = callback => {
    if (this.state.isFormUpdated) {
      this.setState({
        showActivityHistoryModal: true,
        isFormUpdated: false
      });
    } else {
      this.setState(
        {
          isFormUpdated: false
        },
        () => callback()
      );
    }
  };

  redirectToActivityHistory = () => {
    this.setState({
      isFormUpdated: false,
      showActivityHistoryModal: false
    });
    this.props.history.push("/gl-account-earnings-activity-history");
  };

  render() {
    let isUnmappedAny = false;
    if (this.state.unmappedCount > 0) {
      isUnmappedAny = true;
    }
    const unmappedId2 = "unmapped-2";
    return (
      <div className="nc-list-page-container">
        <Prompt
          when={this.state.isFormUpdated}
          message={message.UNSAVED_CHANGES}
        />
        <GeneralLedgerEarningsDescription
          Id={Id}
          onClickActivityHistoryButton={this.onClickActivityHistoryButton}
          showHistoryButton={true}
        />
        <div className="clearfix" />

        {this.state.showToaster ? (
          <div id="toaster-message" className="toaster-container">
            <Toaster
              show={this.state.showToaster}
              message={this.state.messageToaster}
              closeToasterMessage={this.closeToasterMessage}
              Id={Id.listPageToaster}
              alertClassName={this.state.alertClassName}
            />{" "}
          </div>
        ) : null}

        {this.state.isPayrollInProgressToaster ? (
          <div id={Id.listPageToaster} className="toaster-container">
            <Toaster
              show={this.state.isPayrollInProgressToaster}
              message={this.state.payrollInProgressMessage}
              alertClassName={this.state.payrollInProgressAlertClassName}
            />
          </div>
        ) : null}

        {this.props.employerEarningsAccountsMainList.DataCount === 0 ? (
          <NoEntityToSelect label="Earnings" id="MAP_GL" />
        ) : (
          <div className="clearfix">
            <EmployerPaidEarningsAdvanceFilter
              onSelectedDeductions={selectedDeductions =>
                this.setDeductions(selectedDeductions)
              }
              getUnmappedCheckState={unmappedCheckState =>
                this.setUnmappedState(unmappedCheckState)
              }
              Id={Id}
            />

            <div
              className="list-page-grid-table col-lg-12 col-md-12 col-sm-12 col-xs-12"
              style={{ display: "block" }}
            >
              {this.props.employerEarningsAccountsFilteredList.length === 0 ? (
                <div id={Id.loadingCount} className="nc-rowcount-results">
                  {"Loading..."}
                </div>
              ) : this.props.employerEarningsAccountsFilteredList.DataCount ===
                1 ? (
                <div
                  id={Id.listCount}
                  className="nc-rowcount-results"
                >{`We found ${
                  this.props.employerEarningsAccountsFilteredList.DataCount
                } result.`}</div>
              ) : (
                <div
                  id={Id.listCount}
                  className="nc-rowcount-results"
                >{`We found ${
                  this.props.employerEarningsAccountsFilteredList.DataCount
                } results.`}</div>
              )}

              {this.renderEmployeeLiabilityTable()}
            </div>
          </div>
        )}

        <Modal
          id="map-earning-cancel-modal-1"
          backdrop={"static"}
          keyboard={false}
          bsClass="entity-page-cancel-modal modal"
          show={this.state.showBackCancelModal}
          onHide={() => {
            this.setState({
              showBackCancelModal: !this.state.showBackCancelModal
            });
          }}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="system-level-cancel-button-close-icon"
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="cancel-modal-line1">
                Are you sure you want to leave this page in the middle of making
                edits?
              </div>
              <br />
              <div id="cancel-modal-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              {" "}
              <button
                type="button"
                id="map-earnings-modal-exit-button"
                className="nc-button secondary-button"
                onClick={this.redirectToGlobalNavLink}
              >
                LEAVE THIS PAGE
              </button>
              <button
                type="button"
                id="map-earnings--modal-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.close}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="map-earnings-cancel-modal-2"
          backdrop={"static"}
          keyboard={false}
          show={
            isUnmappedAny
              ? this.state.showCancelModalWithUnmapped
              : this.state.showCancelModalWithoutUnmapped
          }
          onHide={this.closeCancelModal}
        >
          {isUnmappedAny
            ? this.showCancelButtonMessageWithUnmappedEarnings(unmappedId2)
            : this.showCancelButtonMessageWithoutUnmappedEarnings(unmappedId2)}
        </Modal>

        <Modal
          id="map-earning-cancel-modal-1"
          backdrop={"static"}
          keyboard={false}
          bsClass="entity-page-cancel-modal modal"
          show={this.state.showActivityHistoryModal}
          onHide={() => {
            this.setState({
              showActivityHistoryModal: !this.state.showActivityHistoryModal
            });
          }}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="system-level-cancel-button-close-icon"
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="cancel-modal-line1">
                Are you sure you want to leave this page in the middle of making
                edits?
              </div>
              <br />
              <div id="cancel-modal-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              {" "}
              <button
                type="button"
                id="map-earnings-modal-exit-button"
                className="nc-button secondary-button"
                onClick={this.redirectToActivityHistory}
              >
                LEAVE THIS PAGE
              </button>
              <button
                type="button"
                id="map-earnings--modal-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.close}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let employerExpenceAccountDropDown = [];

  if (
    state.generalLedgerEarningsReducer.employerEarningsExpenceAccountsList
      .length > 0 ||
    state.generalLedgerEarningsReducer.employerEarningsExpenceAccountsList
      .DataCount > 0
  ) {
    employerExpenceAccountDropDown = state.generalLedgerEarningsReducer.employerEarningsExpenceAccountsList.Data.map(
      (item, index) => {
        return {
          value: item.GLAccountId,
          label: `${item.GLAccountNumber} - ${item.GLAccountName}`
        };
      }
    );
  }

  return {
    employerEarningsExpenceAccountsList:
      state.generalLedgerEarningsReducer.employerEarningsExpenceAccountsList,
    employerExpenceAccountDropDown: employerExpenceAccountDropDown,
    employerEarningsAccountsMainList:
      state.generalLedgerEarningsReducer.employerEarningsAccountsMainList,
    employerEarningsAccountsFilteredList:
      state.generalLedgerEarningsReducer.employerEarningsAccountsFilteredList,
    UnMappedCheckBoxStateValue:
      state.generalLedgerEarningsReducer.UnMappedCheckBoxStateValue,
    toasterData: state.toasterData,
    globalNavClick: state.globalNavClick,
    sessionContext: state.sessionContext
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEmployerEarningsAccountsMainList,
      getEmployerEarningsExpenceAccountsList,
      getEmployerEarningsLiabilityAccountsList,
      updatedEarningsFilteredList,
      saveEmployerEarningsExpenceLiabilityAccountsList,
      setEmployerEarningsData,
      showToaster: showToaster,
      showToasterByClass: showToasterByClass,
      setLoader: setLoader,
      setFormUpdate: setFormUpdate,
      isOverlayClickable: isOverlayClickable,
      getEmployerEarningsAccountsFilteredList: getEmployerEarningsAccountsFilteredList,
      setEmployerEarningsLiabilityAccountsData,
      setGlobalNavClick: setGlobalNavClick,
      CallbackToQuickLink: CallbackToQuickLink,
      getEarningsList
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EmployerPaidEarnings)
);
