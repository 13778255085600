import React from "react";
const SecondaryButton = (item, i) => (
  <button key={i} onClick={item.onClick} className="nc-button secondary-button">
    {item.label}
  </button>
);
const PrimaryLinkButton = (item, i) => (
  <button key={i} className="primary-link-button" onClick={item.onClick}>
    {item.label}
  </button>
);
const PrimaryButton = (item, i) => (
  <button key={i} className="nc-button primary-button" onClick={item.onClick}>
    {item.label}
  </button>
);
const OutLineButton = (item, i) => (
  <button key={i} className="nc-button outline-button" onClick={item.onClick}>
    <i className={"fa " + item.iconClass} /> {item.label}
  </button>
);
const SecondaryLinkButton = (item, i) => (
  <button key={i} className="secondary-link-button" onClick={item.onClick}>
    {item.label}
  </button>
);

const ModalFooterButtons = ({
  footerButtons = [],
  buttonAlign,
  className = "nc-button-group"
}) => (
  <div className="button-group" style={{ clear: "both" }}>
    <div
      className={
        buttonAlign === "left" ? "nc-button-group float-left" : className
      }
      //style={{ float: buttonAlign ? buttonAlign : "none" }}
    >
      {footerButtons.map((item, i) => {
        switch (item.buttonType) {
          case "primary-button":
            return PrimaryButton(item, i);
          case "secondary-button":
            return SecondaryButton(item, i);
          case "outline-button":
            return OutLineButton(item, i);
          case "primary-link-button":
            return PrimaryLinkButton(item, i);
          case "secondary-link-button":
            return SecondaryLinkButton(item, i);
          default:
            return null;
        }
      })}
    </div>
  </div>
);

export default ModalFooterButtons;
