import React, { Component } from "react";
import FormSection from "../../../formSections/FormSection";
import WorkEmailAddress from "./WorkEmailAddress";
import WorkPhoneNumber from "./WorkPhoneNumber";
import WorkFaxNumber from "./WorkFaxNumber";
import AddressSection from "./AddressSection";
import { FormFieldGroup } from "../../../formSections";
import * as Tooltip from "./Tooltips";

export default class WorkplaceInfoSection extends Component {
  state = {};
  render() {
    const { onChange, data, stateData = {} } = this.props;
    const { WorkplaceInfoSection } = data;
    const labelClass = "col-lg-2";

    return (
      <div className="workplace-info-section-container">
        <FormSection title="Basic Info" bottomHr>
          <FormFieldGroup
            label="Company"
            fieldType="inline"
            inputType="Label"
            inputName="CompanyName"
            value={WorkplaceInfoSection.CompanyName}
            colSpan={labelClass}
          />
          <FormFieldGroup
            label="Division"
            fieldType="inline"
            inputType="Label"
            inputName="Division"
            value={WorkplaceInfoSection.Division}
            colSpan={labelClass}
          />
          <FormFieldGroup
            label="Business Unit"
            fieldType="inline"
            inputType="Label"
            inputName="BusinessUnit"
            value={WorkplaceInfoSection.BusinessUnit}
            colSpan={labelClass}
          />
          <FormFieldGroup
            label="Department"
            fieldType="inline"
            inputType="Label"
            inputName="Department"
            value={WorkplaceInfoSection.Department}
            colSpan={labelClass}
          />
          <FormFieldGroup
            label="Client"
            fieldType="inline"
            inputType="Label"
            inputName="Client"
            value={WorkplaceInfoSection.Client}
            colSpan={labelClass}
          />
          <FormFieldGroup
            label="Project"
            fieldType="inline"
            inputType="Label"
            inputName="Project"
            value={WorkplaceInfoSection.Project}
            colSpan={labelClass}
          />
        </FormSection>
        <FormSection title="Location" bottomHr>
          <FormFieldGroup
            label="Location"
            fieldType="inline"
            inputType="Label"
            inputName="Location"
            value={WorkplaceInfoSection.Location}
            colSpan={labelClass}
            tooltip={Tooltip.LOCATION}
          />
          <AddressSection
            label="Address"
            inputName="Address"
            addressLine1={WorkplaceInfoSection.AddressLine1}
            addressLine2={WorkplaceInfoSection.AddressLine2}
            colSpan={labelClass}
          />
        </FormSection>
        <FormSection title="Management" bottomHr>
          <FormFieldGroup
            label="Primary Manager"
            fieldType="inline"
            inputType="Label"
            inputName="PrimaryManager"
            value={WorkplaceInfoSection.PrimaryManager}
            colSpan={labelClass}
          />
          <FormFieldGroup
            label="Secondary Manager"
            fieldType="inline"
            inputType="Label"
            inputName="SecondaryManager"
            value={WorkplaceInfoSection.SecondaryManager}
            colSpan={labelClass}
          />
        </FormSection>
        <FormSection title="Work Contact Info">
          <WorkEmailAddress data={stateData} onChange={onChange} />
          <WorkPhoneNumber data={stateData} onChange={onChange} />
          <WorkFaxNumber data={stateData} onChange={onChange} />
        </FormSection>
      </div>
    );
  }
}
