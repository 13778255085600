import React from "react";
import classnames from "classnames/bind";

const ModalOption = ({
  label,
  showCheckbox,
  selected,
  onSelect,
  statusIcon,
  icon = statusIcon,
  image,
  description = ""
}) => (
  <li
    className={
      `${showCheckbox ? "list-item" : "select-panel-item"} ` +
      `${!showCheckbox && selected ? "selected" : ""}`
    }
    style={{ wordBreak: "break-word" }}
    onClick={onSelect}
  >
    {showCheckbox && (
      <input type="checkbox" checked={selected || false} readOnly />
    )}
    <label>
      {icon && (
        <i
          className={classnames(
            icon,
            icon === "fa-ellipsis-h"
              ? `fa round-grey-circle-icon`
              : icon === "fa-times-circle-o"
                ? `fa grey`
                : `fa green`
          )}
        />
      )}
      {image && (
        <img
          height="20"
          src={image}
          alt={label}
          style={{ marginRight: "5px" }}
        />
      )}
      {label}
      {description !== "" ? (
        <div
          className="hidden-lg hidden-md tooltip-mobile-filter"
          style={{ marginTop: "0px", paddingBottom: "0px" }}
        >
          {description}
        </div>
      ) : null}
    </label>
  </li>
);

export default ModalOption;
