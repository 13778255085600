import * as types from "../../../EarningsGroup/actions/companylevel/index";
export default function(state = [], action) {
  switch (action.type) {
    case types.FETCH_STATUS_LIST: {
      return action.payload.data;
    }
    default:
      return state;
  }
}
