import React from "react";
import {
  STATUS_COMPLETED_WITH_ERRORS,
  STATUS_COMPLETED,
  STATUS_CANCELLED,
  STATUS_FAILED
} from "../Constants";

const JobTaskItemDesktop = ({ item, onClickCompanyLink, jobTypeName }) => (
  <div
    className="display-toggle-content-item"
    id={`job-task-description-title-${item.JobTaskId}`}
  >
    <span className="display-toggle-content-left">
      {item.CurrentJobTaskStatusId === STATUS_COMPLETED_WITH_ERRORS && (
        <i className={`fa fa-exclamation-triangle orange-icon`} />
      )}
      {item.CurrentJobTaskStatusId === STATUS_COMPLETED && (
        <i className={`fa fa-check-circle green`} />
      )}
      {item.CurrentJobTaskStatusId === STATUS_CANCELLED && (
        <i className={`fa fa-times-circle-o orange-icon`} />
      )}
      {item.CurrentJobTaskStatusId === STATUS_FAILED && (
        <i className={`fa fa-minus-circle orange-icon`} />
      )}
      {item.JobTaskDescription}
      {(item.CurrentJobTaskStatusId === STATUS_COMPLETED_WITH_ERRORS ||
        item.CurrentJobTaskStatusId === STATUS_COMPLETED ||
        item.CurrentJobTaskStatusId === STATUS_CANCELLED ||
        item.CurrentJobTaskStatusId === STATUS_FAILED) && (
        <div className="subscriber-company-name-mobile">
          <a
            className=""
            id={`subscriber-company-name-${item.JobTaskId}`}
            onClick={onClickCompanyLink}
          >
            <span className="display-toggle-content-right-name">
              {" "}
              {item.SubscriberCompanyName}'s {jobTypeName}{" "}
            </span>
            <i className={`fa fa-caret-right`} />
          </a>
        </div>
      )}
    </span>
  </div>
);

export default JobTaskItemDesktop;
