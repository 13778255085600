import React from "react";
import { Switch, Route } from "react-router-dom";
import EmployerPaidEarnings from "../generalledger/earnings/EmployerPaidEarnings";

const GeneralLedgerRoutes = () => (
  <Switch>
    <Route
      path="/map-general-ledger-accounts-earnings"
      component={EmployerPaidEarnings}
    />
  </Switch>
);

export default GeneralLedgerRoutes;
