import React from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import * as Fields from "./../FieldNames";
import { GROSS_MISCONDUCT, COBRA_EVENT_DATE } from "../Tooltips";
import Toaster from "component-library/toasters/index";

const Cobra = ({ data = {}, onChange }) => {
  return (
    <FormSection title={"COBRA"} hr>
      <FormFieldGroup
        inputType="radio"
        inputName={Fields.grossTerminationMisconduct}
        label="Is this termination due to gross misconduct ?"
        fieldType="inline"
        value={data[Fields.grossTerminationMisconduct]}
        radios={[
          { id: 1, value: true, label: "Yes" },
          { id: 2, value: false, label: "No" }
        ]}
        class="col-lg-12"
        colSpan="col-lg-3 col-md-3"
        Size={[6, 4, 12, 12]}
        onChange={onChange}
        tooltip={!data[Fields.grossTerminationMisconduct] && GROSS_MISCONDUCT}
      />

      {data[Fields.grossTerminationMisconduct] === true && (
        <div>
          <div style={{ marginBottom: 20 }}>
            <Toaster
              id=""
              show={true}
              alertClassName="warning"
              message={GROSS_MISCONDUCT}
            />
          </div>

          <FormFieldGroup
            inputType="radio"
            inputName={Fields.recordCOBRAEventNow}
            label="Do you want to record a COBRA event now?"
            fieldType="inline"
            value={data[Fields.recordCOBRAEventNow]}
            radios={[
              { id: 1, value: true, label: "Yes" },
              { id: 2, value: false, label: "No" }
            ]}
            class="col-lg-12"
            colSpan="col-lg-3 col-md-3"
            Size={[6, 4, 12, 12]}
            onChange={onChange}
          />
        </div>
      )}

      {(data[Fields.recordCOBRAEventNow] === true ||
        data[Fields.grossTerminationMisconduct] === false) && (
        <div>
          <FormFieldGroup
            inputType="singleDatePicker"
            label="COBRA Event Date"
            fieldType="inline"
            inputName={Fields.cobraEventDate}
            value={data[Fields.cobraEventDate]}
            colSpan="col-lg-3 col-md-3"
            Size={[4, 4, 12, 12]}
            onChange={onChange}
            style={{ marginBottom: "15px" }}
            getToFromValue={onChange}
            tooltip={COBRA_EVENT_DATE}
          />
          <FormFieldGroup
            inputType="textarea"
            inputName={Fields.benefitsCobraNotes}
            label="Notes"
            value={data[Fields.benefitsCobraNotes]}
            fieldType="inline"
            description="250 characters max"
            rows={6}
            colSpan="col-lg-3 col-md-3"
            Size={[8, 8, 12, 12]}
            onChange={onChange}
            getToFromValue={onChange}
            optional
          />
        </div>
      )}
    </FormSection>
  );
};

export default Cobra;
