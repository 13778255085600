import React from "react";
import RadioButton from "../../component-library/radioButton/BasicRadioButton";
import PropTypes from "prop-types";
export const CUSTOM_EARNING_CATEGORY_ID = -1;

const item = {
  id: String(CUSTOM_EARNING_CATEGORY_ID),
  name: "deductionCustomTypeRadio",
  value: "Custom",
  label: "Custom",
  check: true
};

const CustomEarningTabBody = ({ entityName }) => (
  <div
    className="entity-form-section-primary"
    id={`sub-panel-` + CUSTOM_EARNING_CATEGORY_ID}
  >
    <div className="content">
      <div className="nc-field-group clearfix">
        <div
          className="field-name"
          id={`sub-panel-section-label-` + CUSTOM_EARNING_CATEGORY_ID}
        >
          Don't see the template you are looking for? Create a custom{" "}
          {entityName} from ground up.
        </div>
        <div className="radio-button-group">
          <RadioButton
            id={item.id}
            name={item.name}
            value={item.Value}
            type="two-line-title"
            label={item.label}
            checked={item.check}
            width="230px"
          />
        </div>
      </div>
    </div>
    <div style={{ marginBottom: "10px" }} className="hidden-sm hidden-xs" />
  </div>
);

export default CustomEarningTabBody;
CustomEarningTabBody.propTypes = {
  entityName: PropTypes.string
};

CustomEarningTabBody.defaultProps = {
  entityName: "earnings"
};
