import React, { Component } from "react";
import classNames from "classnames/bind";
import ProgressItem from "./ProgressItem";
import { setStepState, setStepClass } from "./StepActions";
const styles = {
  accessible: "accessible-step", //2
  active: "active-step", //1
  inaccessible: "inaccessible-step" //0
};
export default class SetupProgress extends Component {
  state = {};
  goTopage = step => {
    this.props.switchSteps(step.link, step.id);
  };
  renderSteps = steps => {
    var cx = classNames.bind(styles);
    return steps.map(step => {
      let stepState = setStepState(
        step.id,
        this.props.activeStep,
        this.props.uiDraftId
      );
      let stepClass = setStepClass(stepState, cx);
      return (
        <ProgressItem
          key={step.id}
          id={"trackProgress" + step.id}
          active={this.props.uiDraftId >= step.id}
          className={stepClass}
          onClick={this.goTopage.bind(this, step)}
          stepName={step.name}
        />
      );
    });
  };
  render() {
    const { props } = this;
    return (
      <div className="progress-tracker">
        <div className="title">{props.title}</div>
        <ul className="step-menu">{this.renderSteps(props.steps)}</ul>
      </div>
    );
  }
}
