import React from "react";
import { PropTypes } from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const tooltip_thrash_icon = <Tooltip id="tooltip_thrash_icon">Delete</Tooltip>;
const tooltip_edit_icon = <Tooltip id="tooltip_edit_icon">Edit</Tooltip>;

const getEmploymentDate = data => {
  let formattedDate = "";
  let months = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "June",
    7: "July",
    8: "Aug",
    9: "Sept",
    10: "Oct",
    11: "Nov",
    12: "Dec",
    "": ""
  };
  formattedDate = `${months[data.EmploymentFromMonth]} ${
    data.EmploymentFromYear
  } - ${months[data.EmploymentToMonth]} ${data.EmploymentToYear}`;

  return formattedDate;
};

const EmploymentHistoryList = props => {
  const { item, onEditClick, onDeleteClick } = props;
  return (
    <div
      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 table-cell">
        {item.CompanyName}
        {item.IsVerified && (
          <div className="clearfix">
            <span>
              <i className="fa fa-check-circle-o" />
              Verified
            </span>
          </div>
        )}
      </div>
      <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 table-cell">
        {item.Position}
      </div>
      <div className="col-lg-3 col-md-3 col-sm-12  col-xs-12">
        {getEmploymentDate(item)}
      </div>
      <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
        <div className="tab-container-list-icon-section pull-right">
          <div className="tab-container-list-edit-section">
            <OverlayTrigger placement="top" overlay={tooltip_edit_icon}>
              <a
                href="."
                onClick={e => {
                  e.preventDefault();
                  onEditClick && onEditClick(item.EmploymentHistoryId);
                }}
              >
                <i
                  id={"edit-icon-" + item.EmploymentHistoryId}
                  className="tab-container-list-edit-icon fa fa-pencil"
                />
              </a>
            </OverlayTrigger>
          </div>
          <div className="tab-container-list-delete-section">
            <OverlayTrigger placement="top" overlay={tooltip_thrash_icon}>
              <a
                href="."
                onClick={e => {
                  e.preventDefault();
                  onDeleteClick &&
                    onDeleteClick(item.EmploymentHistoryId, item.CompanyName);
                }}
              >
                <i
                  id={"delete-icon-" + item.EmploymentHistoryId}
                  className="tab-container-list-delete-icon fa fa-trash-o"
                />
              </a>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmploymentHistoryList;

EmploymentHistoryList.propTypes = {
  item: PropTypes.object
};

EmploymentHistoryList.defaultProps = {
  item: {
    SectionItemDescription: "",
    Verified: false,
    SectionItemTitle: "",
    SectionItemDescriptionLine: ""
  }
};
