import React from "react";

const renderOption = (props, Option) => item => {
  const {
    selectedItems,
    multiSelect,
    hasForm = false,
    onSelect,
    onHide,
    name,
    showDescription
  } = props;
  const { value } = item;
  return (
    <Option
      key={value}
      showCheckbox={multiSelect}
      selected={selectedItems[value]}
      showDescription={showDescription}
      {...item}
      onSelect={() => {
        if (multiSelect) {
          if (hasForm) {
            onSelect({
              name,
              value: { ...selectedItems, [value]: !selectedItems[value] }
            });
          } else {
            onSelect(value, !selectedItems[value]);
          }
        } else {
          if (name) {
            onSelect({ name, value });
          } else {
            onSelect(value);
          }
          onHide();
        }
      }}
    />
  );
};

export default renderOption;
