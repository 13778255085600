import { connect } from "react-redux";
import EmployeeFiles from "employee-files/containers/EmployeeFiles";
import {
  searchEmployeeFilesList,
  deleteEmployeeFileAndReload
} from "../network/actions";
import { showToast } from "app/common/reducers/toast";

const stateToProps = state => ({
  employeeFilesList: state.employeeFiles.employeeFilesList,
  toast: state.common.toast
});
const actionCreators = {
  searchEmployeeFilesList,
  deleteEmployeeFileAndReload,
  showToast
};
export default connect(stateToProps, actionCreators)(EmployeeFiles);
