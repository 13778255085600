export const CATEGORY_FILTER_NAME = "category";
export const ENTITY_FILTER_NAME = "entity";
export const STATUS_FILTER_NAME = "status";

export const TableColumns = [
  { label: "Name", field: "name" },
  { label: "Category", field: "category" },
  { label: "Status", field: "status", col: 2 }
];

export const INPROGRESS = 1;
export const ACTIVE = 2;
export const INACTIVE = 3;
