import React, { Component } from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import {
  CURRENT_ADDRESS,
  FORMER_ADDRESS,
  CURRENT_LINK,
  FORMER_LINK,
  currentAddress,
  formerAddress,
  ZIPCODE,
  F_ZIPCODE
} from "../Constants";

class Addresses extends Component {
  renderAddresses = componentProps => {
    return Object.keys(componentProps).map(k => {
      return (
        <FormFieldGroup
          key={k}
          {...componentProps[k]}
          block
          colSpan="col-md-5"
          value={this.props.data[k]}
          onChange={this.props.onChange}
        />
      );
    });
  };

  render() {
    const { data } = this.props;
    const { initialData } = data;
    let toggleCurrentAddressData = currentAddress;
    let toggleFormerAddressData = formerAddress;
    toggleCurrentAddressData.country.options = initialData.countries || [];
    toggleCurrentAddressData.State.options = initialData.currentStates || [];
    toggleFormerAddressData.fCountry.options = initialData.countries || [];
    toggleFormerAddressData.fState.options = initialData.formerStates || [];
    if (!this.props.data[ZIPCODE]) {
      const { city, State, county, ...restOfAddress } = currentAddress;
      toggleCurrentAddressData = restOfAddress;
    }
    if (!this.props.data[F_ZIPCODE]) {
      const { fCity, fState, fCounty, ...restOfAddress } = formerAddress;
      toggleFormerAddressData = restOfAddress;
    }

    return (
      <div className="row m-0">
        <div
          className="col-md-6"
          style={{ borderRight: "2px solid #f3e9e9", padding: "0px" }}
        >
          <FormSection
            title={CURRENT_ADDRESS}
            labelIcon="fa fa-arrow-circle-right"
            confirmed
            link={CURRENT_LINK}
            onClick={this.props.handleLink}
          >
            {this.renderAddresses(toggleCurrentAddressData)}
          </FormSection>
        </div>
        <div className="col-md-6">
          <FormSection
            title={FORMER_ADDRESS}
            labelIcon="fa fa-arrow-circle-left"
            link={FORMER_LINK}
            onClick={this.props.handleLink}
          >
            {this.renderAddresses(toggleFormerAddressData)}
          </FormSection>
        </div>
      </div>
    );
  }
}

export default Addresses;
