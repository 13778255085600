import React from "react";
import moment from "moment";
import * as payrollConstants from "./PayrollDashboardConstants";

const PayrollDashboardSummaryHeaderMessage = ({ data }) => {
  let todaysDate = moment().format();
  let message = "";
  let diff = moment(data.SubmitDate)
    .startOf("day")
    .diff(moment(todaysDate).startOf("day"), "days", true);
  let maxSubmitDateDiff = moment(data.MaxSubmitDate)
    .startOf("day")
    .diff(moment(todaysDate).startOf("day"), "days", true);

  let maxWireDateDiff = moment(data.MaxWireDate)
    .startOf("day")
    .diff(moment(todaysDate).startOf("day"), "days", true);
  let fontColor = "#339966"; //2-3 days left
  if (data.PayrollStageId === payrollConstants.PAYROLL_SUBMITTED) {
    message =
      "This payroll has been submitted to Netchex for Processing. For any corrections, please contact your Netchex representative.";
    fontColor = "#0c4f48";
  } else if (data.PayrollStageId === payrollConstants.PAYROLL_PENDING) {
    message =
      "Your payroll has been received but is on hold until Netchex receives the funds from your bank to finalize processing.";
    fontColor = "#d44646";
  } else {
    if (diff <= 0 && maxSubmitDateDiff > 0) {
      fontColor = "#d44646"; //No days left
      message = `You have until 3:30 PM (CST) on ${moment(
        data.MaxSubmitDate
      ).format("MMMM DD, YYYY")} to submit this payroll.`;
    } else if (maxSubmitDateDiff === 0) {
      fontColor = "#d44646"; //No days left
      message = "You have until 3:30 PM (CST) today to submit this payroll.";
    } else if (diff === 1) {
      return null;
    } else if (diff > 1) {
      return null;
    } else if (maxSubmitDateDiff <= 0) {
      if (maxWireDateDiff >= 0) {
        fontColor = "#d44646"; //No days left
        message = `You have until ${moment(data.MaxWireDate).format(
          "MMMM DD, YYYY"
        )} at 3:00 PM (CST) to intiate a wire transfer of funds. If wiring, continue with your payroll, and we will give you the wire instructions when you submit to Netchex. Alternatively, you can change your Paycheck Date below.`;
      } else if (maxWireDateDiff < 0) {
        fontColor = "#d44646"; //No days left
        message = `You must change your Paycheck Date below to submit this payroll.`;
      }
    }
  }

  return (
    <div
      className="payroll-dash-summary-header-message"
      style={{ color: fontColor }}
    >
      {message}
    </div>
  );
};

export default PayrollDashboardSummaryHeaderMessage;
