import {
  PAYROLL_IN_PROGRESS,
  PAYROLL_PENDING
} from "./../PayrollDashboardConstants";
import InProgress from "./InProgress";
import Pending from "./Pending";
import React from "react";
const PayrollUpdateSection = props => {
  switch (props.PayrollStageId) {
    case PAYROLL_IN_PROGRESS:
      return <InProgress {...props} />;
    case PAYROLL_PENDING:
      return <Pending {...props} />;
    default:
      return null;
  }
};
export default PayrollUpdateSection;
