export const getPayrollPayloadSchema = data => {
  const body = {
    PayrollCalenderList: [
      {
        Id: 0,
        PayrollCalenderId: 0,
        Name: "string",
        PayrollCalenderName: "string",
        PayrollCalenderYear: 0,
        ScheduledPayrollCalenderList: [
          {
            PayrollId: 0,
            Id: 0,
            PayrollStageId: 0,
            PayrollStageName: "string",
            Name: "string",
            SubmitDate: "2018-02-08T13:35:05.235Z",
            MaxSubmitDate: "2018-02-08T13:35:05.235Z",
            PayrollScheduleName: data.PayrollScheduleName,
            PaycheckDate: "2018-02-08T13:35:05.235Z",
            PaycheckDateChangedFrom: "",
            PayPeriodFromDate: "2018-02-08T13:35:05.235Z",
            PayPeriodToDate: "2018-02-08T13:35:05.235Z",
            PayCyclePayrollNumber: 0,
            PayCyclePayrollsPerPayPeriod: 0,
            HolidayHours: 0,
            CountOfEmployeesPaid: 0,
            CountOfNotEmployeesPaid: 0,
            Hours: 0,
            GrossPayAmount: "string",
            NetPayAmount: "string",
            CountOfUnapprovedPtos: 0,
            CountOfTimeCardAlerts: 0,
            CountOfEmployeeUpdates: 0,
            MaxWireDate: "2018-02-08T13:35:05.235Z",
            HolidayDates: [
              {
                HolidayId: 0,
                StartDate: "2018-02-08T13:35:05.235Z",
                EndDate: "2018-02-08T13:35:05.235Z",
                HolidayName: "string",
                HolidayDefinitionId: 0,
                HolidayHour: 0,
                HolidayDefinition: {
                  HolidayDefinitionId: 0,
                  HolidayGroupId: 0,
                  HolidayGroupName: "string",
                  RepeatsEvery: 0,
                  BeginYear: 0,
                  EndYear: 0,
                  HolidayTypeId: 0,
                  HolidayTypeName: "string",
                  StartDate: "2018-02-08T13:35:05.235Z",
                  EndDate: "2018-02-08T13:35:05.235Z",
                  IncludedDayOfWeeks: [0],
                  OccuranceNumber: 0,
                  OccuranceDayOfWeek: 0,
                  OccuranceMonth: 0,
                  OccuranceDay: 0,
                  OccuranceYear: 0,
                  UseObservedHoliday: true,
                  HolidayStartsOtherThanMidnight: true
                },
                HolidayDate: "2018-02-08T13:35:05.235Z"
              }
            ]
          }
        ]
      }
    ],
    WeekDaysToPayForDailyFrequency:
      data.scheduleDays.length > 0 ? data.scheduleDays.toString() : "",
    CompanyPayrollScheduleDefinitionId: data.id1 || 0,
    CompanyPayrollScheduleDefinitionName: data.PayrollScheduleName,
    PayrollFrequencyId:
      data.Frequency === "Weekly"
        ? 1
        : data.Frequency === "Bi-Weekly"
          ? 2
          : data.Frequency === "Semi-Monthly"
            ? 3
            : data.Frequency === "Monthly"
              ? 4
              : data.Frequency === "Quarterly"
                ? 5
                : data.Frequency === "Annually"
                  ? 6
                  : 7,
    PayPeriodWorkHours: data.TotalHours,
    DayOfWeekToPayForWeeklyBiweeklyFrequency: data.WeekDayToPay,
    PayPeriodEndDaysPriorToPaycheckDate: data.PayPeriodEnd,

    FirstPaycheckDayOfMonth: data.firstPayCheckDate
      ? data.firstPayCheckDate
      : 0,
    FirstPayPeriodEndDayOfMonth: data.firstPayCheckEndDate,

    SecondPaycheckDayOfMonth: data.secondPayCheckDate
      ? data.secondPayCheckDate
      : 0,
    SecondPayPeriodEndDayOfMonth: data.secondPayCheckEndDate,

    FirstPayPeriodEndOffset: data.firstSwitch,
    SecondPayPeriodEndOffset: data.secondSwitch,

    //// not requird right now
    // StartMonthForAnnualPeriod: 0,
    // EndMonthForAnnualPeriod: 0,
    // StartDateForAnnualPeriod: 0,
    // EndDateForAnnualPeriod: 0,
    // SecondPaycheckMonth: data.secondPayCheckDate,
    // SecondPayPeriodEndMonth: "",

    FirstPaycheckMonth: data.firstPayCheckStartMonth,
    FirstPayPeriodEndMonth: data.firstPayCheckEndMonth,

    PayrollSubmitBusinessDaysPriorToPaycheckDate: data.BusinessDays || 0,
    DayOptionForDirectDepositIfPaycheckDateIsOnWeekend:
      data.DirectDepositPaymentForWeekend,
    SecondPayPeriodEndMonth: 0,
    SecondPaycheckMonth: 0,

    DayOffsetForDirectDepositIfPaycheckDateIsOnHoliday:
      data.DirectDepositPaymentForBankHolidays,

    IsMatchPaycheckDateAndDirectDepositDate:
      parseInt(data.AdjustPaycheckDate, 10) === 1 ? true : false,
    IsAutomaticallyReplaceRegularHoursWithHolidayHoursForRegularEmployees:
      data.ReduceSalaryAutomatically,
    PayrollScheduleStartDate: data.PayrollScheduleStartDate,
    //CompanyHolidayCalendarId: data.HolidayCalender ? data.HolidayCalender : 0
    CompanyHolidayCalendarId:
      data.ReduceSalaryAutomatically.toString() === "1" ? 2 : null
  };

  return body;
};
