import { showToast } from "app/common/reducers/toast";

export const postPayrollScheduleAndContinue = (history, action) => dispatch => {
  if (!action) {
    dispatch(showToast("You have successfully updated your payroll schedule"));
  } else {
    dispatch(showToast("You have successfully added payroll schedule"));
  }
  history.push("/PayrollSchedules");
};

export const deletePayrollScheduleAndContinue = payrollData => dispatch => {
  dispatch(
    showToast("Your payroll schedule " + payrollData.name + " has been deleted")
  );
};

export const inactivatePayrollScheduleAndContinue = name => dispatch => {
  dispatch(
    showToast("Your payroll schedule " + name + " has been inactivated")
  );
};

export const reactivatePayrollScheduleAndContinue = name => dispatch => {
  dispatch(
    showToast("Your payroll schedule " + name + " has beed reactivated")
  );
};
