import React, { Component } from "react";
import PayrollDashBoardModal from "../payroll-dashboard-modals";
import * as payrollConstants from "../PayrollDashboardConstants";
const flexButtonStyle = {
  display: "flex",
  float: "right",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: 0,
  paddingBottom: 0
};
export default class ContactMissingInfoModal extends Component {
  state = {};

  missingContactInfoModal = () => {
    const empployeeInfo = [
      {
        employeeName: "Summer Fiedler",
        missingItem: "email",
        primaryManager: "Olivia Stevart's",
        secondaryManager: ""
      },
      {
        employeeName: "Chris Michel",
        missingItem: "cellphone",
        primaryManager: "",
        secondaryManager: "Benjamin William's"
      }
    ];
    return (
      <div className="modal-body-container">
        <div className="modal-body-header">
          Missing Info <i className="fa fa-exclamation-circle" />
        </div>
        <div className="modal-body-content">
          <div className="clearfix content-section">
            You have 2 approver's who are missing the correct contact method.
          </div>
          {empployeeInfo.map((item, i) => {
            return (
              <div
                key={i}
                style={{}}
                className="modal-body-gray-section clearfix"
              >
                <div className="secondary-header">
                  {item.missingItem === "email"
                    ? item.employeeName + " does not have a work email address."
                    : item.missingItem === "cellphone"
                      ? item.employeeName +
                        " does not have a cell phone number."
                      : null}
                </div>
                <div>
                  {item.primaryManager
                    ? item.primaryManager + " primary manager"
                    : item.secondaryManager + " secondary manager"}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-5 text-right">
                  <button
                    style={flexButtonStyle}
                    className="nc-button outline-button modal-content-button"
                  >
                    Add{" "}
                    <i
                      style={{ marginLeft: "5px" }}
                      className="fa fa-angle-right"
                    />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  missingAssingmentInfoModal = () => {
    const employeeList = [
      { employeeName: "Susan Chiristopher" },
      { employeeName: "Patrik Ballard" }
    ];
    return (
      <div className="modal-body-container">
        <div className="modal-body-header">
          Missing Info <i className="fa fa-exclamation-circle" />
        </div>
        <div className="modal-body-content">
          {employeeList.map((item, i) => {
            return (
              <div key={i} className="modal-body-gray-section clearfix">
                <div
                  className="col-lg-9 col-md-9 col-sm-7 col-xs-7"
                  style={{ paddingTop: "10px" }}
                >
                  <div className="secondary-header">
                    {item.employeeName + " does not have an assigned manager."}
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-5 col-xs-5 text-right">
                  <button className="nc-button outline-button modal-content-button">
                    Assign <i className="fa fa-angle-right" />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  closeContactMissingInfoModal = () => {
    this.props.closeContactMissingInfoModal();
  };

  closeFooterButton = () => {
    const modalButton = [
      {
        label: "Close",
        buttonType: "secondary-button",
        onClick: this.closeContactMissingInfoModal
      }
    ];

    return modalButton;
  };

  render() {
    const { modeOfOperation } = this.props;
    return (
      <PayrollDashBoardModal
        show={this.props.contactMissingInfoModal}
        bodyContent={
          modeOfOperation === payrollConstants.ADD_CONTACT_INFO
            ? this.missingContactInfoModal()
            : this.missingAssingmentInfoModal()
        }
        closeModal={this.closeContactMissingInfoModal}
        footerButtons={this.closeFooterButton()}
        buttonAlign={
          modeOfOperation === payrollConstants.ADD_CONTACT_INFO
            ? "left"
            : "center"
        }
      />
    );
  }
}
