import React from "react";
import { makeId } from ".";

export const ShowSimpleValueItem = (item = null) => {
  if (item !== null)
    if (item.includes("#br#")) {
      let itemList = item.split("#br#");
      let itemsToDisplay = [];
      if (itemList.length > 0) {
        itemList.forEach((i, index) => {
          itemsToDisplay.push(
            <div>
              {i} {index !== itemList.length && <br />}
            </div>
          );
        });
        return itemsToDisplay;
      }
    } else if (item.includes("#b#") && item.includes("#/b#")) {
      let htmlElementItem = item.replace("#b#", "");
      htmlElementItem = htmlElementItem.replace("#/b#", "");
      return <b>{htmlElementItem}</b>;
    } else {
      return item;
    }
};

const SimpleValue = ({
  NodeValues = [],
  Nodes = [],
  NodeIdPrefix = NodeValues[0]
}) => (
  <div>
    <div id={makeId(NodeValues[0], 1)}>
      {ShowSimpleValueItem(NodeValues[1])}
    </div>
    {Nodes.length > 0 && (
      <div>
        {Nodes.map(({ NodeValues }, i) => (
          <div
            key={i}
            id={makeId(NodeIdPrefix, i + 2)}
            className="row-simple-value"
          >
            {ShowSimpleValueItem(NodeValues[0])}
          </div>
        ))}
      </div>
    )}
  </div>
);

export default SimpleValue;
