import React, { Component } from "react";
import DropDownOptions from "../../dropDown/DropDownOptions";
import PropTypes from "prop-types";
const InputStyle = {
  boxShadow: "none",
  border: "none",
  width: "300px",
  height: "100%",
  padding: "20px",
  opacity: " 1"
};

export default class SearchBox extends Component {
  state = {};
  getFilteredOptions = () => {
    const { searchText } = this.state;
    const { options } = this.props;
    let filteredOptions = [];
    if (searchText) {
      filteredOptions = options.filter(
        ({ label }) =>
          label
            .trim()
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) !== -1
      );

      if (filteredOptions.length > 0) {
        return filteredOptions;
      } else {
        return [{ value: 0, label: "No results found" }];
      }
    }

    return options;
  };

  onSelect = e => {
    this.setState({ showOverlay: false, searchText: "" });
    this.props.onSelect(e);
  };

  render() {
    const { searchBoxFocused, handleBlur } = this.props;
    return (
      <div
        id="search-box"
        className="search-section"
        style={{ position: "relative" }}
        //onBlur={this.handleBlur}
        ref={targetButton => (this.target = targetButton)}
        //onFocus={this.handleFocus}
      >
        <input
          type="text"
          placeholder="&#xF002; Search"
          style={searchBoxFocused ? InputStyle : {}}
          onChange={e => {
            this.setState({ searchText: e.target.value, showOverlay: true });
          }}
          value={this.state.searchText}
        />
        {searchBoxFocused && (
          <i
            className="fa fa-close"
            onClick={() => this.setState({ searchText: "" })}
            onDoubleClick={handleBlur}
          />
        )}

        <DropDownOptions
          style={{ marginTop: "-5px" }}
          multiselect={false}
          showSearch={false}
          container={this}
          selectedItems={{}}
          options={this.getFilteredOptions()}
          show={this.state.showOverlay}
          position="top"
          onSelect={this.onSelect}
          onHide={() => {
            this.setState({ showOverlay: false });
            handleBlur();
          }}
          target={this.target}
          className={"nc-dropdown-overlay bottom-up"}
          shouldUpdatePosition={true}
        />
      </div>
    );
  }
}
SearchBox.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object)
};

SearchBox.defaultProps = {
  options: []
};
