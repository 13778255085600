export const PAYROLL_SCHEDULE = "PayrollSchedule";
export const SYSTEM_TPP = "SystemTPP";

export const TableColumns = {
  CopyDeductions: [
    {
      label: "Name",
      field: "name",
      id: "list-page-col1-header",
      showSort: true
    },
    {
      label: "Category",
      field: "category",
      id: "list-page-col2-header",
      showSort: true
    }
  ],
  Deductions: [
    {
      label: "Name",
      field: "name",
      id: "list-page-col1-header",
      showSort: true
    },
    {
      label: "Category",
      field: "category",
      id: "list-page-col2-header",
      showSort: true
    },
    {
      label: "Status",
      field: "status",
      col: 2,
      id: "list-page-col3-header",
      showSort: true
    }
  ],
  PayrollSchedule: [
    {
      label: "Name",
      field: "name",
      id: "list-page-col1-header",
      showSort: true
    },
    {
      label: "Frequency",
      field: "frequency",
      id: "list-page-col2-header",
      showSort: true
    },
    {
      label: "Status",
      field: "status",
      col: 2,
      id: "list-page-col3-header",
      showSort: true
    }
  ],
  Earnings: [
    {
      label: "Name",
      field: "name",
      col: 4,
      id: "list-page-col1-header",
      showSort: true
    },
    {
      label: "Category",
      field: "category",
      col: 3,
      id: "list-page-col2-header",
      showSort: true
    },
    {
      label: "Calculation Method",
      field: "calculationMethod",
      col: 3,
      id: "list-page-col4-header",
      showSort: false
    },
    {
      label: "Status",
      field: "status",
      col: 2,
      id: "list-page-col3-header",
      showSort: true
    }
  ],
  CopyEarnings: [
    {
      label: "Name",
      field: "name",
      id: "list-page-col1-header",
      showSort: true
    },
    {
      label: "Category",
      field: "category",
      id: "list-page-col2-header",
      showSort: true
    }
  ],
  EmployeeProfileList: [
    {
      label: "Name",
      field: "name",
      col: 3,
      style: { paddingLeft: "60px" },
      colMd: 4,
      showSort: true
    },
    { label: "Status", field: "status", col: 2, showSort: true },
    { label: "DIV/BUS/DEP", field: "div", col: 2, showSort: true },
    {
      label: "Employee Status Date",
      field: "employeeStatusDate",
      col: 3,
      colMd: 2,
      showSort: true
    },
    {
      label: "Time Group",
      field: "timeGroup",
      col: 2,
      showSort: true
    }
  ],
  DependentsList: [
    {
      label: "Name",
      field: "name",
      id: "list-page-col1-header",
      col: 4,
      showSort: true
    },
    {
      label: "Type",
      field: "type",
      id: "list-page-col2-header",
      col: 4,
      showSort: true
    },
    {
      label: "Relation",
      field: "relation",
      col: 2,
      id: "list-page-col3-header",
      showSort: true
    }
  ],
  EmployeeNotesList: [
    {
      label: "TYPE",
      field: "type",
      col: 4,
      id: "list-page-col1-header",
      showSort: true
    },
    {
      label: "DATE",
      field: "date",
      col: 3,
      id: "list-page-col2-header",
      showSort: true
    },
    {
      label: "DESCRIPTION",
      field: "description",
      col: 3,
      id: "list-page-col3-header",
      showSort: true
    }
  ],
  EmploymentHistory: [
    {
      label: "Company Name",
      field: "companyName",
      col: 4,
      id: "list-page-col1-header",
      showSort: true
    },
    {
      label: "Position",
      field: "postion",
      col: 3,
      id: "list-page-col2-header",
      showSort: true
    },
    {
      label: "Employment Dates",
      field: "employmentDates",
      col: 3,
      id: "list-page-col3-header",
      showSort: true
    }
  ],
  Licenses: [
    {
      label: "Name",
      field: "name",
      col: 4,
      id: "list-page-col1-header",
      showSort: true
    },
    {
      label: "Type",
      field: "type",
      col: 3,
      id: "list-page-col2-header",
      showSort: true
    },
    {
      label: "Expiration Date",
      field: "expirationDate",
      col: 3,
      id: "list-page-col3-header",
      showSort: true
    }
  ],
  Education: [
    {
      label: "Degree",
      field: "degree",
      col: 4,
      id: "list-page-col1-header",
      showSort: true
    },
    {
      label: "School",
      field: "school",
      col: 3,
      id: "list-page-col2-header",
      showSort: true
    },
    {
      label: "Graducation Date",
      field: "graducationDate",
      col: 3,
      id: "list-page-col3-header",
      showSort: true
    }
  ],
  Skills: [
    {
      label: "Skill",
      field: "skill",
      col: 4,
      id: "list-page-col1-header",
      showSort: true
    },
    {
      label: "Skill Level",
      field: "skillLevel",
      col: 3,
      id: "list-page-col2-header",
      showSort: true
    },
    {
      label: "Year of Experience",
      field: "employmentDates",
      col: 3,
      id: "list-page-col3-header",
      showSort: true
    }
  ],
  SystemTPP: [
    {
      label: "Name",
      field: "name",
      id: "list-page-col1-header",
      showSort: true
    },
    {
      label: "Category",
      field: "category",
      id: "list-page-col2-header",
      showSort: true
    },
    {
      label: "Status",
      field: "status",
      col: 2,
      id: "list-page-col3-header",
      showSort: true
    }
  ],
  MapGLTaxesTab1: [
    {
      label: "Taxes",
      field: "CompanyTaxName",
      id: "list-page-col1-header",
      colSize: [3, 3, 12],
      col: 3,
      showSort: true
    },
    {
      label: "Include In GL Report",
      field: "IsIncludeInGlReport",
      id: "list-page-col2-header",
      colSize: [3, 3, 12],
      col: 3,
      showSort: false
    },
    {
      label: "Expense Account",
      field: "expenseAccount",
      id: "list-page-col3-header",
      colSize: [3, 3, 12],
      col: 3,
      showSort: false
    },
    {
      label: "Liability Account",
      field: "liabilityAccount",
      id: "list-page-col4-header",
      colSize: [3, 3, 12],
      col: 3,
      showSort: false
    }
  ],
  MapGLTaxesTab2: [
    {
      label: "Taxes",
      field: "CompanyTaxName",
      id: "list-page-col5-header",
      colSize: [5, 5, 12],
      col: 5,
      showSort: true
    },
    {
      label: "Liability Account",
      field: "liabilityAccount",
      id: "list-page-col6-header",
      colSize: [4, 4, 12],
      col: 4,
      showSort: false
    }
  ],

  MapGLDeductionsTab1: [
    {
      label: "Deductions",
      field: "CompanyDeductionName",
      id: "list-page-col1-header",
      colSize: [3, 3, 12],
      col: 3,
      showSort: true
    },
    {
      label: "Include In GL Report",
      field: "IsIncludeInGlReport",
      id: "list-page-col2-header",
      colSize: [3, 3, 12],
      col: 3,
      showSort: false
    },
    {
      label: "Expense Account",
      field: "expenseAccount",
      id: "list-page-col3-header",
      colSize: [3, 3, 12],
      col: 3,
      showSort: false
    },
    {
      label: "Liability Account",
      field: "liabilityAccount",
      id: "list-page-col4-header",
      colSize: [3, 3, 12],
      col: 3,
      showSort: false
    }
  ],
  MapGLDeductionsTab2: [
    {
      label: "Deductions",
      field: "CompanyDeductionName",
      id: "list-page-col5-header",
      colSize: [5, 5, 12],
      col: 5,
      showSort: true
    },
    {
      label: "Liability Account",
      field: "liabilityAccount",
      id: "list-page-col6-header",
      colSize: [4, 4, 12],
      col: 4,
      showSort: false
    }
  ]
};

export const FILTER = {
  Earnings: ["entity", "category", "status"],
  PayrollSchedule: ["entity", "frequency", "status"],
  EmployeeProfileList: ["employee", "company", "status"],
  EmployeeProfileTree: ["company", "status"],
  SystemTPP: ["entity", "category", "status"]
};

export const FILTER_LABELS = {
  Earnings: {
    entity: ["Select Earnings", "All Earnings", "Earnings", "Earnings"],
    category: ["Select Category", "All Categories", "Categories", "Category"],
    status: ["Select Status", "All Statuses", "Statuses", "Status"]
  },
  CopyEarnings: {
    entity: ["Select Earnings", "All Earnings", "Earnings", "Earnings"],
    category: ["Select Category", "All Categories", "Categories", "Category"]
  },
  Deductions: {
    entity: ["Select Deduction", "All Deductions", "Deductions", "Deduction"],
    category: ["Select Category", "All Categories", "Categories", "Category"],
    status: ["Select Status", "All Statuses", "Statuses", "Status"]
  },
  CopyDeductions: {
    entity: ["Select Deduction", "All Deductions", "Deductions", "Deduction"],
    category: ["Select Category", "All Categories", "Categories", "Category"]
  },
  PayrollSchedule: {
    entity: [
      "Select Payroll Schedule",
      "All Payroll Schedule",
      "Payroll Schedules",
      "Payroll Schedule"
    ],
    frequency: [
      "Select Frequency",
      "All Frequencies",
      "Frequencies",
      "Frequency"
    ],
    status: ["Select Status", "All Statuses", "Statuses", "Status"]
  },
  EmployeeProfileList: {
    employee: ["Select Employee", "All Employees", "Employees", "Employee"],
    company: ["Select Company", "All Companies", "Companies", "Company"],
    status: ["Select Status", "All Statuses", "Statuses", "Status"]
  },
  EmployeeProfileTree: {
    company: ["Company", "Company", "Company", "Company"],
    status: ["Select Status", "All Statuses", "Statuses", "Status"]
  },
  EmploymentHistory: {
    employmentCompany: ["Company", "Companies", "Companies", "Company"],
    employmentPosition: [
      "Select Position",
      "All Positions",
      "Positions",
      "Position"
    ],
    employmentDate: [
      "Select Date Range",
      "Date Range",
      "Date Range",
      "Date Range"
    ]
  },
  Licenses: {
    licensesName: ["Name", "All Names", "Names", "Name"],
    licensesType: ["Select Type", "All Types", "Types", "Type"],
    licensesExpirationDate: [
      "Select Date Range",
      "Date Range",
      "Date Range",
      "Date Range"
    ]
  },
  Education: {
    educationDegree: ["Degree", "All Degrees", "Degrees", "Degree"],
    educationSchool: ["Select School", "All Schools", "Schools", "School"]
  },
  Skills: {
    skillsSkill: ["Skill", "All Skills", "Skills", "Skill"],
    skillsSkillLevel: [
      "Select Skill Level",
      "All Skill Level",
      "Skill Level",
      "Skill Level"
    ],
    skillsYearOfExperience: [
      "Year Of Experience",
      "Year Of Experience",
      "Year Of Experience",
      "Year Of Experience"
    ]
  },
  MapGLTaxesTab1: {
    tabOneTaxesList: ["Select Tax", "All Taxes", "Taxes", "Tax"],
    status: [
      "Include in GL Report",
      "Include in GL Report",
      "Include in GL Report",
      "Include in GL Report"
    ],
    unmapped: [
      "Include in GL Report",
      "Include in GL Report",
      "Include in GL Report",
      "Include in GL Report"
    ]
  },

  MapGLTaxesTab2: {
    tabTwoTaxesList: ["Select Tax", "All Taxes", "Taxes", "Tax"],
    unmapped: [
      "Include in GL Report",
      "Include in GL Report",
      "Include in GL Report",
      "Include in GL Report"
    ]
  },

  MapGLDeductionsTab1: {
    tabOneDeductionsList: [
      "Select Deduction",
      "All Deductions",
      "Deductions",
      "Deduction"
    ],
    status: [
      "Include in GL Report",
      "Include in GL Report",
      "Include in GL Report",
      "Include in GL Report"
    ],
    unmapped: [
      "Include in GL Report",
      "Include in GL Report",
      "Include in GL Report",
      "Include in GL Report"
    ]
  },

  MapGLDeductionsTab2: {
    tabTwoDeductionsList: [
      "Select Deduction",
      "All Deductions",
      "Deductions",
      "Deduction"
    ],
    unmapped: [
      "Include in GL Report",
      "Include in GL Report",
      "Include in GL Report",
      "Include in GL Report"
    ]
  },
  SystemTPP: {
    entity: [
      "Select Third Party Payee",
      "All Third Party Payee",
      "Third Party Payees",
      "Third Party Payee"
    ],
    category: ["Select Category", "All Categories", "Categories", "Category"],
    status: ["Select Status", "All Statuses", "Statuses", "Status"]
  }
};
export const description = {
  PayrollSchedule: `Building your payroll schedule is the first step in processing payroll. Your payroll schedule drives when you need to submit payroll to Netchex. It also determines how often and when your employees and/or contractors get paid.`,
  Earnings: `Earnings codes determine how pay is calculated.Earnings codes determine how pay is calculated.Earnings codes determine how pay is calculated.Earnings codes determine how pay is calculated.`,
  WageTaxValidation: `We have identified the correct payroll wages, state and local withholding taxes based off an employee's home and work addresses.`,
  SystemTPP: `Third-Party Payees can be associated with a deduction to generate a payment (printed check or direct deposit) to the payee in the amount of the employee and employer deduction contributions. Examples of a third-party payee might include state disbursement units (child support), federal or state agencies (tax levy), charities (donations), gyms (membership fees), etc.`
};

export const PAGE_FILTERS = {
  Earnings: {
    entity: {
      multiSelect: true,
      showSearch: true,
      showBadge: true,
      selectAllLabel: "All Earnings"
    },
    category: {
      multiSelect: true,
      showBadge: true,
      selectAllLabel: "All Categories"
    },
    status: {
      multiSelect: true,
      showBadge: true,
      selectAllLabel: "All Statuses"
    }
  },
  CopyEarnings: {
    entity: {
      multiSelect: true,
      showSearch: true,
      showBadge: true,
      selectAllLabel: "All Earnings"
    },
    category: {
      multiSelect: true,
      showBadge: true,
      selectAllLabel: "All Categories"
    }
  },
  Deductions: {
    entity: {
      multiSelect: true,
      showSearch: true,
      showBadge: true,
      selectAllLabel: "All Deductions"
    },
    category: {
      multiSelect: true,
      showBadge: true,
      selectAllLabel: "All Categories"
    },
    status: {
      multiSelect: true,
      showBadge: true,
      selectAllLabel: "All Statuses"
    }
  },
  CopyDeductions: {
    entity: {
      multiSelect: true,
      showSearch: true,
      showBadge: true,
      selectAllLabel: "All Deductions"
    },
    category: {
      multiSelect: true,
      showBadge: true,
      selectAllLabel: "All Categories"
    }
  },
  PayrollSchedule: {
    entity: {
      multiSelect: true,
      showSearch: true,
      showBadge: true,
      selectAllLabel: "All Payroll Schedules"
    },
    frequency: {
      multiSelect: true,
      showBadge: true,
      selectAllLabel: "All Frequencies"
    },
    status: {
      multiSelect: true,
      showBadge: true,
      selectAllLabel: "All Statuses"
    }
  },
  EmployeeProfileList: {
    employee: {
      multiSelect: true,
      showSearch: true,
      showBadge: true,
      selectAllLabel: "All Employees",
      width: "30%"
    },
    company: {
      multiSelect: true,
      showSearch: true,
      showBadge: true,
      selectAllLabel: "All Companies",
      width: "30%"
    },
    status: {
      multiSelect: true,
      showBadge: true,
      selectAllLabel: "All Statuses",
      width: "30%"
    }
  },
  EmployeeProfileTree: {
    company: {
      multiSelect: true,
      showSearch: true,
      showBadge: true,
      selectAllLabel: "Companies",
      width: "30%"
    },
    status: {
      multiSelect: true,
      showBadge: true,
      selectAllLabel: "All Statuses",
      width: "30%"
    }
  },
  EmploymentHistory: {
    employmentCompany: {
      multiSelect: true,
      showSearch: true,
      showBadge: true,
      selectAllLabel: "Select All"
    },
    employmentPosition: {
      multiSelect: true,
      showBadge: true,
      showSearch: true,
      selectAllLabel: "Select All"
    },
    employmentDate: {
      multiSelect: false,
      showBadge: true,
      type: "datePicker"
    }
  },
  Licenses: {
    licensesName: {
      multiSelect: true,
      showSearch: true,
      showBadge: true,
      selectAllLabel: "Select All"
    },
    licensesType: {
      multiSelect: true,
      showBadge: true,
      showSearch: true,
      selectAllLabel: "Select All"
    },
    licensesExpirationDate: {
      multiSelect: false,
      showBadge: true,
      type: "datePicker"
    }
  },
  Education: {
    educationDegree: {
      multiSelect: true,
      showSearch: true,
      showBadge: true,
      selectAllLabel: "Select All"
    },
    educationSchool: {
      multiSelect: true,
      showBadge: true,
      showSearch: true,
      selectAllLabel: "Select All"
    }
  },
  Skills: {
    skillsSkill: {
      multiSelect: true,
      showSearch: true,
      showBadge: true,
      selectAllLabel: "Select All"
    },
    skillsSkillLevel: {
      multiSelect: true,
      showBadge: true,
      selectAllLabel: "Select All"
    },
    skillsYearOfExperience: {
      multiSelect: true,
      showBadge: true,
      selectAllLabel: "Select All"
    }
  },
  MapGLTaxesTab1: {
    tabOneTaxesList: {
      multiSelect: true,
      showBadge: true,
      showSearch: true,
      selectAllLabel: "Select All",
      id: "dd-list-page-filter-1"
    },
    status: {
      multiSelect: true,
      showBadge: true,
      showSearch: false,
      selectAllLabel: "Select All",
      showSelectAll: false,
      id: "dd-list-page-filter-2"
    },
    unmapped: {
      isCheckbox: true,
      type: "checkbox"
    }
  },
  MapGLTaxesTab2: {
    tabTwoTaxesList: {
      multiSelect: true,
      showBadge: true,
      showSearch: true,
      selectAllLabel: "Select All",
      id: "dd-list-page-filter-3"
    },
    unmapped: {
      isCheckbox: true,
      type: "checkbox"
    }
  },

  MapGLDeductionsTab1: {
    tabOneDeductionsList: {
      multiSelect: true,
      showBadge: true,
      showSearch: true,
      selectAllLabel: "Select All",
      id: "dd-list-page-filter-1"
    },
    status: {
      multiSelect: true,
      showBadge: true,
      showSearch: false,
      selectAllLabel: "Select All",
      showSelectAll: false,
      id: "dd-list-page-filter-2"
    },
    unmapped: {
      isCheckbox: true,
      type: "checkbox"
    }
  },
  MapGLDeductionsTab2: {
    tabTwoDeductionsList: {
      multiSelect: true,
      showBadge: true,
      showSearch: true,
      selectAllLabel: "Select All",
      id: "dd-list-page-filter-3"
    },
    unmapped: {
      isCheckbox: true,
      type: "checkbox"
    }
  },
  SystemTPP: {
    entity: {
      multiSelect: true,
      showSearch: true,
      showBadge: true,
      selectAllLabel: "All Third Party Payee"
    },
    category: {
      multiSelect: true,
      showBadge: true,
      selectAllLabel: "All Categories"
    },
    status: {
      multiSelect: true,
      showBadge: true,
      selectAllLabel: "All Statuses"
    }
  }
};
