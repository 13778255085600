import React from "react";
const PayrollDashboardSummaryItem = ({
  label,
  lvalue,
  rvalue,
  ldate,
  rdate,
  groupIcon,
  customIcon,
  dollarIcon,
  holidayCalendarIcon,
  info,
  visible = true,
  className,
  isAllowEdit = true,
  width = "20%"
}) => {
  if (visible)
    return (
      <div
        className={`payroll-dash-summary-item ${className} clearfix`}
        style={{ width: width }}
      >
        {label ? (
          <div className="payroll-dash-summary-item-label">{label}</div>
        ) : null}

        <div className="payroll-dash-summary-item-value">
          {dollarIcon && (
            <i
              className="fa fa-usd payroll-dash-summary-item-dollarIcon"
              aria-hidden="true"
            />
          )}
          {lvalue}
        </div>
        {groupIcon && (
          <div>
            <i
              className={`fa fa-${groupIcon} payroll-dash-summary-item-groupIcon`}
              aria-hidden="true"
            />
            <div className="payroll-dash-summary-item-value">{rvalue}</div>
          </div>
        )}

        {customIcon && (
          <div>
            <div className="payroll-dash-summary-item-customIcon">
              {customIcon}
            </div>
            <div className="payroll-dash-summary-item-value">{rvalue}</div>
          </div>
        )}

        {ldate && (
          <div className="holiday-calendar-summary-item-section">
            <div className="payroll-dash-summary-item-value holiday-calendar-summary-item-value">
              {ldate}
            </div>
            <div className="payroll-dash-summary-item-customIcon holiday-calendar-summary-item-holidayCalendarIcon">
              {holidayCalendarIcon}
            </div>
            <div className="payroll-dash-summary-item-value holiday-calendar-summary-item-value">
              {rdate}
            </div>
          </div>
        )}

        {info && (
          <div className="payroll-dash-summary-item-icon">
            <i className="fa-information-blue fa fa-info-circle" />
          </div>
        )}
      </div>
    );
  else {
    return null;
  }
};

export default PayrollDashboardSummaryItem;

PayrollDashboardSummaryItem.defaultProps = {
  label: "Label",
  groupIcon: "",
  customIcon: "",
  dollarIcon: "",
  holidayCalendarIcon: "",
  info: "",
  visible: true,
  className: "",
  isAllowEdit: true
};
