import {
  selectFilterOption,
  selectAllFilterOptions,
  setFilterOptions,
  selectDateRangeOption
} from "app/common/reducers/filters";

import { editEntityData } from "app/common/reducers/entityData";

import { setCopyEntityResult } from "../../entity-copy/reducers/copyEntity";
import {
  clearNotificationList,
  notificationList,
  setNotificationListLoading,
  clearJobException,
  selectAllExceptionList
} from "../reducers/notifications";
import momentTimezone from "moment-timezone";
import { navigateToDestinationCompany } from "../reducers/navigateToDestination";

import { setLoader } from "app/actions/LoaderActions";

import {
  PRODUCT_FILTER_NAME,
  COMPANY_FILTER_NAME,
  STATUS_FILTER_NAME,
  USER_FILTER_NAME,
  USER_TYPES_FILTER_NAME,
  DATE_RANGE_FILTER_NAME,
  STATUS_INQUEUE,
  STATUS_INPROGRESS,
  STATUS_COMPLETED_WITH_ERRORS,
  STATUS_COMPLETED,
  STATUS_CURRENTLY_INQUEUE,
  STATUS_CURRENTLY_INPROGRESS,
  STATUS_CANCELLED,
  STATUS_FAILED,
  USER_TYPES_LIST
} from "component-library/notifications/Constants";

import { getUserType } from 'component-library/notifications/util'

import {
  getProductsList,
  getCompanyList,
  getStatusList,
  getUserList,
  searchPosts,
  cancelJobRequest,
  getNotificationList,
  loadMorePosts,
  getUserAccessForDestinationPage,
  createNewJob,
  getNotificationDetailsById,
  getNotificationById
} from ".";
import { reFormatResubmitJob } from "../util/reformatResubmitJob";

export const fetchAndSetNotificationListData = () => dispatch => {
  dispatch(setLoader(true));

  getNotificationList().then(res => {
    const { Data, DataCount, NextUri } = res.data;
    dispatch(notificationList(Data === null ? [] : Data, DataCount, NextUri));
    dispatch(setLoader(false));
  });
};

export const fetchAndSetProductFilterData = (
  clearExisting = true,
  selectAll = true,
  data,
  filterName = PRODUCT_FILTER_NAME,
  successCallback = () => { }
) => dispatch => {
  //dispatch(setLoader(true));

  getProductsList().then(res => {
    const { Data = [] } = res.data;
    if (Data !== null && Data !== undefined) {
      const products = Data.map(e => ({
        value: e.AppUIProductId,
        label: e.AppUIProductName,
        id: e.AppUIProductId,
        image: e.ImageUrl
      }));

      let options = products.filter(item => {
        return item.id !== 17;
      });
      successCallback();
      dispatch(setFilterOptions(filterName, options));
    }

    if (selectAll) {
      dispatch(selectAllFilterOptions(filterName, true));
    }
    //dispatch(setLoader(false));
  });
};

export const fetchAndSetCompanyFilterData = (
  viewId,
  clearExisting = true,
  selectAll = true,
  selectDefault,
  data,
  filterName = COMPANY_FILTER_NAME,
  successCallback = () => { }
) => dispatch => {
  //dispatch(setLoader(true));

  getCompanyList(viewId).then(res => {
    let { Data = [] } = res.data;
    Data = Data === null ? [] : Data;
    const options = Data.map(e => ({
      value: e.SubscriberCompanyId,
      label: e.SubscriberCompanyName,
      id: e.SubscriberCompanyId
    }));
    successCallback();
    dispatch(setFilterOptions(filterName, options));

    if (selectAll) {
      dispatch(selectAllFilterOptions(filterName, true));
    }
    if (selectDefault) {
      let option = "";
      options.map(op => (op.id === data ? (option = op.id) : {}));
      dispatch(selectFilterOption(filterName, option, true));
    }
    //dispatch(setLoader(false));
  });
};

export const fetchAndSetStatusFilterData = (
  clearExisting = true,
  selectAll = true,
  data,
  filterName = STATUS_FILTER_NAME,
  successCallback = () => { }
) => dispatch => {
  //dispatch(setLoader(true));

  getStatusList().then(res => {
    const { Data = [] } = res.data;
    const options = [];
    Data.forEach(e => {
      if (
        e.JobStatusId === STATUS_INQUEUE ||
        e.JobStatusId === STATUS_CURRENTLY_INQUEUE
      ) {
        options.push({
          value: e.JobStatusId,
          label: e.Name,
          id: e.JobStatusId,
          icon: "fa-ellipsis-h dot-icon"
        });
      } else if (
        e.JobStatusId === STATUS_INPROGRESS ||
        e.JobStatusId === STATUS_CURRENTLY_INPROGRESS
      ) {
        options.push({
          value: e.JobStatusId,
          label: e.Name,
          id: e.JobStatusId,
          icon: "fa-spinner gray-icon"
        });
      } else if (e.JobStatusId === STATUS_COMPLETED_WITH_ERRORS) {
        options.push({
          value: e.JobStatusId,
          label: e.Name,
          id: e.JobStatusId,
          icon: "fa-exclamation-triangle orange-icon"
        });
      } else if (e.JobStatusId === STATUS_COMPLETED) {
        options.push({
          value: e.JobStatusId,
          label: e.Name,
          id: e.JobStatusId,
          icon: "fa-check-circle"
        });
      } else if (e.JobStatusId === STATUS_CANCELLED) {
        options.push({
          value: e.JobStatusId,
          label: e.Name,
          id: e.JobStatusId,
          icon: "fa fa-times-circle-o orange-icon"
        });
      } else if (e.JobStatusId === STATUS_FAILED) {
        options.push({
          value: e.JobStatusId,
          label: e.Name,
          id: e.JobStatusId,
          icon: "fa fa-minus-circle orange-icon"
        });
      }
    });
    successCallback();
    dispatch(setFilterOptions(filterName, options));

    if (selectAll) {
      dispatch(selectAllFilterOptions(filterName, true));
    }
    //dispatch(setLoader(false));
  });
};

export const fetchAndSetUserFilterData = (
  clearExisting = true,
  selectAll = true,
  selectDefault,
  data,
  filterName = USER_FILTER_NAME,
  successCallback = () => { }
) => dispatch => {
  //dispatch(setLoader(true));
  getUserList().then(res => {
    const { Data = [] } = res.data;
    const options = Data.map(e => ({
      value: e.IsNetchexUser ? e.UserId : parseInt(e.UserId, 10),
      label: `${e.DisplayName} ${parseInt(e.UserId, 10) === data ? '(Me)' : ''}`,
      id: parseInt(e.UserId, 10),
      userType: e.UserType,
      description: getUserType(e.UserType),
      listSubscriberCompanyId: e.ListSubscriberCompanyId
    }));
    successCallback();
    dispatch(setFilterOptions(filterName, options));
    dispatch(setFilterOptions(USER_TYPES_FILTER_NAME, USER_TYPES_LIST))
    dispatch(selectAllFilterOptions(USER_TYPES_FILTER_NAME, true));
    if (selectAll) {
      dispatch(selectAllFilterOptions(filterName, true));
    }
    if (selectDefault) {
      let option = "";
      options.map(op => (op.id === data ? (option = op.id) : {}));
      dispatch(selectFilterOption(filterName, option, true));
    }
    //dispatch(setLoader(false));
  });
};

export const setDateRangeFilterData = (
  filterName = DATE_RANGE_FILTER_NAME
) => dispatch => {
  const selected = {
    from: new Date(
      momentTimezone()
        .tz("America/Bahia_Banderas")
        .subtract(30, "days")
        .format()
    ),
    to: new Date(
      momentTimezone()
        .tz("America/Bahia_Banderas")
        .format()
    )
  };
  dispatch(selectDateRangeOption(filterName, selected));
};

export function checkIfContainsTrue(list) {
  let count = 0;
  let keyList = Object.keys(list);
  keyList.forEach(item => {
    if (list[item] === true) {
      count = count + 1;
    }
  });
  if (count > 0) {
    return true;
  } else {
    return false;
  }
}

export function DoesAnyFilterHasZeroSelectedItems(filters) {
  let isZeroSelected = false;
  const { product = {}, company = {}, status = {}, user = {} } = filters;
  if (product.selected !== undefined && Object.keys(filters).length > 0) {
    if (
      !checkIfContainsTrue(product.selected) ||
      !checkIfContainsTrue(company.selected) ||
      !checkIfContainsTrue(status.selected) ||
      !checkIfContainsTrue(user.selected)
    ) {
      isZeroSelected = true;
    }
  }
  return isZeroSelected;
}

const defaultSearchParams = {
  Fields: ["*"],
  Criterion: [],
  Sort: [],
  PageSize: 5
};

export const searchAndSetPosts = (
  searchParams = defaultSearchParams,
  filters = {},
  callback = () => { }
) => dispatch => {
  dispatch(setLoader(true));
  dispatch(clearNotificationList());
  if (DoesAnyFilterHasZeroSelectedItems(filters)) {
    dispatch(notificationList([], 0, ""));
    callback();
    return Promise.resolve();
  } else {
    return searchPosts(searchParams).then(res => {
      const { Data, DataCount, NextUri } = res.data;
      dispatch(notificationList(Data === null ? [] : Data, DataCount, NextUri));
      dispatch(setLoader(false));
      callback();
    });
  }
};

export const loadAndSetMorePosts = (nextUri, callback) => dispatch => {
  dispatch(setNotificationListLoading(true));
  loadMorePosts(nextUri).then(res => {
    const { Data, DataCount, NextUri, ErrorCount } = res.data;
    if (ErrorCount === 0) {
      dispatch(notificationList(Data === null ? [] : Data, DataCount, NextUri));
    }
    callback();
  });
};

export const cancelProductJobRequest = (
  id,
  successCallback = () => { }
) => dispatch => {
  dispatch(setLoader(true));
  cancelJobRequest(id).then(res => {
    const { Errors = [], ErrorCount } = res.data;
    let message = "";
    if (ErrorCount > 0) {
      message = Errors[0].Messages[0];
    }
    successCallback(ErrorCount, message);
    //dispatch(setFilterOptions("CancelJobRequest", Data));
    dispatch(setLoader(false));
  });
};

export const fetchAndSetUserAccessForDestinationPage = (
  companyId,
  menuId,
  callback
) => dispatch => {
  dispatch(setLoader(true));
  getUserAccessForDestinationPage(companyId, menuId).then(res => {
    const { Data, Errors, ErrorCount } = res.data;
    if (ErrorCount > 0) {
      callback(false, Errors[0].Tag);
      dispatch(setLoader(false));
    } else {
      callback(Data[0], "");
    }
  });
};

export const submitAsNewJob = (
  id,
  jobTaskList,
  entityData,
  callback
) => dispatch => {
  dispatch(setLoader(true));
  let data = reFormatResubmitJob(id, jobTaskList, entityData);
  let entity = entityData.entity.toLowerCase();
  createNewJob(data, entity).then(res => {
    const { Status } = res.data;
    if (Status === "Completed") {
      dispatch(clearJobException());
      callback();
    }
    if (Status === "failed") {
      dispatch(setCopyEntityResult("failed"));
      callback();
    }
  });
};

export const selectAllException = data => dispatch => {
  dispatch(clearJobException());
  dispatch(selectAllExceptionList(data));
};

export const onClickDestinationCompanyLink = (
  isNavigateToUrl = false,
  UIId,
  AppUIMenuItemId,
  AppUIProductId
) => dispatch => {
  let data = {
    isNavigateToUrl,
    UIId,
    AppUIMenuItemId,
    AppUIProductId
  };
  dispatch(navigateToDestinationCompany(data));
};

export const onClickDetails = jobStatusNotificationId => dispatch => {
  dispatch(setLoader(true));
  getNotificationDetailsById(jobStatusNotificationId).then(res => {
    const { Data = [], ErrorCount } = res.data;
    if (ErrorCount === 0) {
      dispatch(setLoader(false));
      dispatch(editEntityData({ [jobStatusNotificationId]: Data[0] }));
    }
  });
};

export const fetchAndSetFullJobHistory = (jobId, callback) => dispatch => {
  dispatch(setLoader(true));
  getNotificationById(jobId).then(res => {
    const { Data = [], ErrorCount } = res.data;
    if (ErrorCount === 0) {
      dispatch(editEntityData(Data[0]));
      callback();
    }
  });
};
