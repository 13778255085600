import { combineReducers } from "redux";

import { notifications, expectionJobs } from "./notifications";
import { navigateToDestination } from "./navigateToDestination";

const ViewAllNotifications = combineReducers({
  notifications,
  expectionJobs,
  navigateToDestination
});

export default ViewAllNotifications;
