import React from "react";

export const LEAVE_TYPE = (
  <div>
    <div style={{ paddingBottom: 15 }}>
      FMLA entitles eligible employees who work for covered employers to take
      unpaid, job-protected leave in a defined 12-month period for specified
      family and medical reasons.{" "}
    </div>
    <div style={{ paddingBottom: 15 }}>
      Employees are eligible for leave if they have worked for their employer at
      least 12 months, at least 1,250 hours over the past 12 months, and work at
      a location where the company employs 50 or more employees within 75 miles.
      Whether an employee has worked the minimum 1,250 hours of service is
      determined according to FLSA principles for determining compensable hours
      or work.{" "}
    </div>
    <div style={{ paddingBottom: 15 }}>
      <div style={{ paddingBottom: 10 }}>
        Employers must provide an eligible employee with up to 12 weeks of
        unpaid leave each year for any of the following reasons:
      </div>
      <ul>
        <li>for the birth and care of the newborn child of an employee;</li>
        <li>
          for placement with the employee of a child for adoption or foster
          care;
        </li>
        <li>
          to care for an immediate family member (spouse, child, or parent) with
          a serious health condition
        </li>
        <li>
          to take medical leave when the employee is unable to work because of a
          serious health condition.
        </li>
        <li>
          for any qualifying exigency arising out of the fact that the
          employee's spouse, son, daughter, or parent is a military member on
          covered active duty status (or has been notified of an impending call
          or order to covered active duty).
        </li>
      </ul>
    </div>
    <div>
      Choose FMLA as the leave type if all of the above requirements have been
      met; otherwise hoose Non-FMLA.
    </div>
  </div>
);

export const INACTIVATE_BENEFITS = `Inactivating an employee's benefit plans will remove the employee from benefits reports, allowing you to more easily reconcile with your carrier.
If you have carrier feeds with Netchex, inactivating an employee's benefit plans will automatically remove the employee from future billing and prevent claims processing beyond the benefit inactivation date.`;
export const REPLACEMENT_MANAGER = "Replacement Manager tooltip";
export const COBRA_EVENT_DATE = `This date cannot be more than 30 days prior to the current date. If a date earlier than this needs to be applied, please contact the NetCOBRA department via email at: cobra@netchexonline.com`;

export const GROSS_MISCONDUCT = `When a covered employee is terminated for gross misconduct, COBRA does not have to be offered to either the employee or their dependents. However, as the regulations do not define "gross misconduct", it is best to reserve this option to deny coverage for cases of extreme misconduct, and have your attorney review the situation for compliance.`;
