import * as types from "../../../actions/systemlevel/index";

export default function(state = [], action) {
  switch (action.type) {
    case types.SYSTEM_THIRD_PARTY_PAYEE_DATA:
      return action.payload;
    default:
      return state;
  }
}
