import React from "react";
import { Switch, Route } from "react-router-dom";
import PayrollSchedule from "./pages/PayrollSchedule";

const UserTestPayrollScheduleRoutes = () => (
  <Switch>
    <Route
      path="/usertest/payroll/schedule/:userTestId"
      component={PayrollSchedule}
    />
  </Switch>
);

export default UserTestPayrollScheduleRoutes;
