import Loadable from "react-loadable";
import ChunkLoading from "app/common/UIComponents/ChunkLoading";

export const PayrollSchedule = Loadable({
  loader: () =>
    import("./pages" /* webpackChunkName: "payrollschedule" */).then(
      m => m.PayrollSchedule
    ),
  loading: ChunkLoading
});

export const AddPayrollSchedule = Loadable({
  loader: () =>
    import("./pages" /* webpackChunkName: "payrollschedule" */).then(
      m => m.AddPayrollSchedule
    ),
  loading: ChunkLoading
});
