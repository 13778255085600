/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import * as common from "app/common/index";
import { setLoader } from "app/actions/LoaderActions";
import Toaster from "app/common/UIComponents/Toaster";
import {
  getDeductionSetupList,
  getFilteredDeductionSetupList,
  updatedFilteredList
} from "../../actions/systemlevel/DeductionSetupListingActions";
import { getDeductionDeleteIconClickList } from "../../actions/systemlevel/DeductionSetupListingActions";
import { setSystemDeductionData } from "../../actions/systemlevel/DeductionSetupListingActions";
import SystemLevelAdvanceFilter from "./components/SystemLevelAdvanceFilter";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import DeductionSetupDescription from "./DeductionSetupDescription";
import * as id from "app/common/SystemConstants";
import { showToaster } from "app/actions/AppActions";
import EntitySnapshotTable from "component-library/entity-snapshot/EntitySnapshotTable";
import EntitySnapshotHeader from "component-library/entity-snapshot/EntitySnapshotHeader";
import {
  getReviewandSaveDataList,
  clearReviewandSaveDataList
} from "../../actions/systemlevel/ReviewandSaveActions";

import {
  getDeductionList,
  getCategoryList,
  getStatusList,
  clearDeductionList
} from "../../actions/systemlevel/AdvanceFilterActions";
import * as Id from "app/common/CommonIds";
import NoEntityToSelect from "component-library/entity-list/NoEntityToSelect";
import Media from "react-media";
import { convertUserTimeZone } from "app/navigation/utilities";

function sortByKey(array, key) {
  return array.sort(function(a, b) {
    var x = a[key].toLowerCase();
    var y = b[key].toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

const tooltip_thrash_icon = (
  <Tooltip id={Id.deleteTooltip}>Delete/Inactivate</Tooltip>
);
const tooltip_edit_icon = <Tooltip id={Id.editTooltip}>Edit Deduction</Tooltip>;

const tooltip_garnishment_category_column = (
  <Tooltip id={Id.garnishmentTooltip}>
    Child Support, Bankruptcy, Student Loans, Tax Levy, etc.
  </Tooltip>
);
const tooltip_insurance_category_column = (
  <Tooltip id={Id.insuranceTooltip}>Medical, Dental, Vision, etc.</Tooltip>
);
const tooltip_retirement_category_column = (
  <Tooltip id={Id.retirementTooltip}>401(k), 403(b), SIMPLE IRA, etc.</Tooltip>
);
const tooltip_retirement_loan_column = (
  <Tooltip id={Id.retirementLoanTooltip}>
    401(k) and 403(b) Loan Repayment
  </Tooltip>
);
const tooltip_other_category_column = (
  <Tooltip id={Id.otherTooltip}>
    Qualified Moving Expenses, Adoption Assistance Programs, Miscellaneous
  </Tooltip>
);

const tooltip_inprogress_status_column = (
  <Tooltip id={Id.inProgressStatusTooltip}>
    Deduction creation has started but has not been completed. Deduction cannot
    be assigned to an employee or used in payroll.
  </Tooltip>
);
const tooltip_active_status_column = (
  <Tooltip id={Id.activeStatusTooltip}>
    Deduction can be assigned to an employee and can be used in payroll.
  </Tooltip>
);
const tooltip_inactive_status_column = (
  <Tooltip id={Id.inactiveStatusTooltip}>
    {" "}
    Deduction has been previously assigned to an employee or used in payroll but
    is currently not active. Deduction cannot be assigned to an employee or used
    in payroll unless made active.
  </Tooltip>
);

const UICOMPLETIONSTAGE_IN_PROGRESS = 1;
const UICOMPLETIONSTAGE_ACTIVE = 2;
const UICOMPLETIONSTAGE_INACTIVE = 3;

class SystemLevelDeductionSetupListingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Id: 0,
      isTableSorted: undefined,
      sortedKey: "",
      opendeletemodal: false,
      isDeductionUsedByCompany: false,
      openInactivateModal: false,
      statusDelete: false,
      statusInactivation: false,
      tableValuesCount: "",
      selectedCategories: [],
      selectedStatus: [],
      selectedDeductions: [],
      selectedDeductionName: false,
      selectedDeductionDeleteStatusName: false,
      selectedDeductionInactivateSuccessName: false,
      selectedDeductionId: 0,
      showToaster: false,
      messageToaster: "",
      alertClassName: ""
    };

    this.close = this.close.bind(this);
    this.showToasterMessage = this.showToasterMessage.bind(this);
    this.showSortedIcon = this.showSortedIcon.bind(this);
    this.renderTableByCategory = this.renderTableByCategory.bind(this);
    this.openmaindeletionmodal = this.openmaindeletionmodal.bind(this);
    this.closemaindeletionmodal = this.closemaindeletionmodal.bind(this);
    this.onOpenInactivateModal = this.onOpenInactivateModal.bind(this);
    this.onCloseInactivateModal = this.onCloseInactivateModal.bind(this);
    this.openStatusDelete = this.openStatusDelete.bind(this);
    this.closeStatusDelete = this.closeStatusDelete.bind(this);
    this.openStatusInactivate = this.openStatusInactivate.bind(this);
    this.closeStatusInactivate = this.closeStatusInactivate.bind(this);
    this.setCategories = this.setCategories.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.setDeductions = this.setDeductions.bind(this);
    this.deleteDeduction = this.deleteDeduction.bind(this);
    this.openSnapShotSummary = this.openSnapShotSummary.bind(this);
    this.redirectToSystemLevelDeductionDescription = this.redirectToSystemLevelDeductionDescription.bind(
      this
    );
    this.redirectToReviewAndSavePage = this.redirectToReviewAndSavePage.bind(
      this
    );
    this.reactivateDeduction = this.reactivateDeduction.bind(this);

    this.props.getDeductionSetupList();
  }

  componentWillMount() {
    if (this.props.toasterData) {
      if (this.props.toasterData.show) {
        this.setState({
          messageToaster: this.props.toasterData.message
        });
        this.showToasterMessage();
      }
    }
    this.props.setSystemDeductionData("");

    this.props.getCategoryList();
    this.props.getStatusList();
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.systemDeductionFilteredList !==
      prevProps.systemDeductionFilteredList
    ) {
      this.props.setLoader(false);
    }
  }

  componentWillUnmount() {
    this.props.clearDeductionList();
    this.props.showToaster(false, ``);
    this.setState({
      showToaster: false
    });
  }

  showToasterMessage() {
    this.setState({
      showToaster: true
    });
  }

  closeToasterMessage = () => {
    this.props.showToaster(false, "");
  };

  redirectToSystemLevelDeductionDescription() {
    this.props.history.push("/system-level-deduction-description");
  }

  goToEditPage(UIDraftCompletionStageId, Id) {
    let redirectString = `/system-level-review-and-save/${Id}`;

    if (UIDraftCompletionStageId === 0) {
      redirectString = `/system-level-deduction-description/${Id}`;
    } else if (UIDraftCompletionStageId === 1) {
      redirectString = `/system-level-calculation-method/${Id}`;
    } else if (UIDraftCompletionStageId === 2) {
      redirectString = `/system-level-deduction-cycle/${Id}`;
    } else if (UIDraftCompletionStageId === 3) {
      redirectString = `/system-level-deafult-value/${Id}`;
    } else if (UIDraftCompletionStageId === 4) {
      redirectString = `/system-level-deduction-options/${Id}`;
    } else if (UIDraftCompletionStageId === 5) {
      redirectString = `/system-level-review-and-save/${Id}`;
    }

    this.props.history.push(redirectString);
  }

  redirectToReviewAndSavePage(deductionItem) {
    let self = this;
    let Id = deductionItem.Id;
    self.goToEditPage(deductionItem.UIDraftCompletionStageId, Id);
  }

  setCategories(selectedCategories) {
    this.setState({
      selectedCategories
    });
  }

  setStatus(selectedStatus) {
    this.setState({
      selectedStatus
    });
  }

  setDeductions(selectedDeductions) {
    this.setState({
      selectedDeductions
    });
  }

  close() {
    this.setState({
      opendeletemodal: false,
      openInactivateModal: false,
      statusDelete: false,
      statusInactivation: false
    });
  }

  openmaindeletionmodal(deductionItem) {
    let deductionInUse = false;
    if (deductionItem.UseCount > 0) {
      deductionInUse = true;
      this.setState({
        isDeductionUsedByCompany: deductionInUse,
        openInactivateModal: true,
        opendeletemodal: false,
        selectedDeductionId: deductionItem.Id,
        selectedDeductionName: deductionItem.Name
      });
    } else {
      this.setState({
        opendeletemodal: true,
        selectedDeductionName: deductionItem.Name,
        selectedDeductionId: deductionItem.Id
      });
    }
  }

  closemaindeletionmodal() {
    this.setState({ opendeletemodal: false });
  }

  onOpenInactivateModal() {
    this.setState({ openInactivateModal: true, opendeletemodal: false });
  }

  onCloseInactivateModal() {
    this.setState({ openInactivateModal: false });
  }

  openStatusDelete(deductionDeleteStatusItem) {
    this.setState({
      statusDelete: true,
      opendeletemodal: false,
      selectedDeductionDeleteStatusName: deductionDeleteStatusItem.Name
    });
  }

  closeStatusDelete() {
    this.setState({ statusDelete: false });
  }

  openStatusInactivate() {
    let self = this;
    self.setState({
      opendeletemodal: false,
      openInactivateModal: false
    });
    self.props.setLoader(true);
    let data = { Id: self.state.selectedDeductionId };
    const url = `${common.WEB_API_URL}/v1/system/deduction/action/makeinactive`;
    axios
      .post(url, data)
      .then(function(response) {
        const { ErrorCount } = response.data;
        self.setState({
          opendeletemodal: false,
          openInactivateModal: false
        });
        self.props.setLoader(false);
        if (ErrorCount === 0) {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          self.props.showToaster(
            true,
            `'${self.state.selectedDeductionName}' has been inactivated.`,
            "inactivate"
          );
          self.showToasterMessage();
        }
        self.props.getDeductionSetupList();
      })
      .catch(function(error) {});
  }

  closeStatusInactivate() {
    this.setState({
      statusInactivation: false
    });
  }

  reactivateDeduction(deductionItem) {
    let self = this;
    let data = { Id: deductionItem.Id };
    const url = `${common.WEB_API_URL}/v1/system/deduction/action/makeactive`;
    axios
      .post(url, data)
      .then(function(response) {
        const { ErrorCount } = response.data;
        if (ErrorCount === 0) {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          self.props.showToaster(
            true,
            `'${deductionItem.SystemDeductionName}' has been reactivated.`,
            "success"
          );
          self.showToasterMessage();
        }
        self.props.getDeductionSetupList();
      })
      .catch(function(error) {});
  }

  deleteDeduction() {
    let deductionInUse = false;
    var self = this;
    this.setState({
      opendeletemodal: false
    });
    self.props.setLoader(true);

    const url = `${common.WEB_API_URL}/v1/system/deduction/${
      this.state.selectedDeductionId
    }`;
    axios
      .delete(url)
      .then(function(response) {
        self.props.setLoader(false);
        if (response.data.ErrorCount > 0) {
          response.data.Errors.forEach(item => {
            if (item.Tag === "DEL_INUSE") {
              deductionInUse = true;
              self.setState({
                isDeductionUsedByCompany: deductionInUse,
                openInactivateModal: true,
                opendeletemodal: false
              });
            }
          });
        } else {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          self.setState({
            opendeletemodal: false
          });
          self.props.showToaster(
            true,
            `'${self.state.selectedDeductionName}' has been deleted.`,
            "delete"
          );

          self.showToasterMessage();
          self.props.clearDeductionList();
          self.props.getDeductionSetupList();
        }
      })
      .catch(function(error) {});
  }

  sortColumnByKey(key) {
    if (this.props.systemDeductionFilteredList.Data) {
      this.props.systemDeductionFilteredList.Data = sortByKey(
        this.props.systemDeductionFilteredList.Data,
        key
      );
      this.setState({
        sortedKey: key
      });
      if (this.state.isTableSorted) {
        this.setState({
          isTableSorted: false
        });
        this.props.systemDeductionFilteredList.Data = this.props.systemDeductionFilteredList.Data.reverse();
      }
      if (this.state.isTableSorted === undefined) {
        this.setState({
          isTableSorted: true
        });
      }
      if (this.state.isTableSorted === false) {
        this.setState({
          isTableSorted: true
        });
      }
    }
  }

  showSortedIcon() {
    if (this.state.isTableSorted) {
      return <i className="fa fa-sort-asc" aria-hidden="true" />;
    } else if (this.state.isTableSorted === false) {
      return <i className="fa fa-sort-desc" aria-hidden="true" />;
    } else {
      return <i className="fa fa-sort" aria-hidden="true" />;
    }
  }

  renderTableByCategory() {
    if (!this.props.systemDeductionFilteredList.Data) {
      return <div />;
    }
    return (
      <div className="">
        <Media query="(min-width:992px)">
          {matches =>
            matches && (
              <div className="col-lg-9 col-md-9 row table-header visible-lg visible-md">
                <div
                  id={Id.nameColumnHeader}
                  onClick={this.sortColumnByKey.bind(this, "Name")}
                  className="col-lg-5 col-md-5 user-column-header"
                >
                  NAME{" "}
                  {this.state.sortedKey === "Name" ? (
                    this.showSortedIcon()
                  ) : (
                    <i className="fa fa-sort" aria-hidden="true" />
                  )}
                </div>
                <div
                  id={Id.categoryColumnHeader}
                  onClick={this.sortColumnByKey.bind(
                    this,
                    "DeductionCategoryName"
                  )}
                  className="col-lg-5 col-md-5 column-header"
                >
                  CATEGORY{" "}
                  {this.state.sortedKey === "DeductionCategoryName" ? (
                    this.showSortedIcon()
                  ) : (
                    <i className="fa fa-sort" aria-hidden="true" />
                  )}
                </div>
                <div
                  id={Id.statusColumnHeader}
                  onClick={this.sortColumnByKey.bind(
                    this,
                    "CreateWorkFlowStateName"
                  )}
                  className="col-lg-2 col-md-2 column-header"
                >
                  STATUS{" "}
                  {this.state.sortedKey === "CreateWorkFlowStateName" ? (
                    this.showSortedIcon()
                  ) : (
                    <i className="fa fa-sort" aria-hidden="true" />
                  )}
                </div>
              </div>
            )
          }
        </Media>

        {this.renderTableContentRow()}
      </div>
    );
  }

  renderTableContentRow() {
    let isDesktop = false;
    if (window.innerWidth >= 320 && window.innerWidth <= 767) {
      isDesktop = false;
    } else if (window.innerWidth >= 992) {
      isDesktop = true;
    }

    if (this.props.systemDeductionFilteredList.Data) {
      return this.props.systemDeductionFilteredList.Data.map(item => {
        return (
          <div
            key={item.Name + item.Id}
            className="table-row col-lg-12 col-md-12 col-sm-12 col-xs-12"
          >
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
              <div
                onClick={
                  item.CreateWorkFlowStateId !== UICOMPLETIONSTAGE_IN_PROGRESS
                    ? this.openSnapShotSummary.bind(this, item)
                    : null
                }
                id={Id.nameColumnItem + item.Id}
                className="col-lg-5 col-md-5 col-sm-12 col-xs-12 table-cell name-column"
                style={{
                  cursor:
                    item.CreateWorkFlowStateId ===
                      UICOMPLETIONSTAGE_IN_PROGRESS && "default"
                }}
              >
                {item.Name}
              </div>
              <div
                id={Id.categoryColumnHeader}
                className="category-container col-lg-5 col-md-5 col-sm-12 col-xs-12 table-cell"
              >
                <span className="hidden-lg hidden-md field-header">
                  Category:{" "}
                </span>
                {isDesktop ? (
                  this.showCategoryDescription(item)
                ) : (
                  <span
                    id={Id.categoryColumnItem + item.DeductionCategoryId}
                    className="field-value"
                  >
                    {item.DeductionCategoryName}
                  </span>
                )}
              </div>
              <div
                id={Id.statusColumnHeader}
                className="status-container col-lg-2 col-md-2 col-sm-12 col-xs-12"
              >
                <span className="hidden-lg hidden-md field-header">
                  Status:{" "}
                </span>
                {this.showStatusDescription(item)}
              </div>
              <div>{isDesktop ? null : this.isMobileReactivate(item)}</div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-3 col-xs-3 list-page-grid-table-cell-actions">
              <div>
                {isDesktop
                  ? this.showIconOrReactivate(item)
                  : this.showIconOrReactivateWithoutTooltips(item)}
              </div>
              {/* <div>
                {this.state.isDeductionUsedByCompany
                  ? this.onOpenInactivateModal
                  : this.openStatusDelete.bind(this, item)}
              </div> */}
            </div>
          </div>
        );
      });
    }
  }

  showStatusDescription(list) {
    let isDesktop = false;
    if (window.innerWidth >= 320 && window.innerWidth <= 767) {
      isDesktop = false;
    } else if (window.innerWidth >= 992) {
      isDesktop = true;
    }
    if (list.CreateWorkFlowStateId === UICOMPLETIONSTAGE_IN_PROGRESS) {
      if (isDesktop) {
        return (
          <OverlayTrigger
            placement="top"
            overlay={tooltip_inprogress_status_column}
          >
            <span
              id={Id.inProgressStatusTooltip}
              className="field-value status-tag inprogress"
            >
              {list.CreateWorkFlowStateName}
            </span>
          </OverlayTrigger>
        );
      } else {
        return (
          <span
            id={Id.inProgressStatusTooltip}
            className="field-value status-tag inprogress"
          >
            {list.CreateWorkFlowStateName}
          </span>
        );
      }
    } else if (list.CreateWorkFlowStateId === UICOMPLETIONSTAGE_ACTIVE) {
      if (isDesktop) {
        return (
          <OverlayTrigger
            placement="top"
            overlay={tooltip_active_status_column}
          >
            <span
              id={Id.activeStatusTooltip}
              className="field-value status-tag active"
            >
              {list.CreateWorkFlowStateName}
            </span>
          </OverlayTrigger>
        );
      } else {
        return (
          <span
            id={Id.activeStatusTooltip}
            className="field-value status-tag active"
          >
            {list.CreateWorkFlowStateName}
          </span>
        );
      }
    } else if (list.CreateWorkFlowStateId === UICOMPLETIONSTAGE_INACTIVE) {
      if (isDesktop) {
        return (
          <OverlayTrigger
            placement="top"
            overlay={tooltip_inactive_status_column}
          >
            <span
              id={Id.inactiveStatusTooltip}
              className="field-value status-tag inactive"
            >
              {list.CreateWorkFlowStateName}
            </span>
          </OverlayTrigger>
        );
      } else {
        return (
          <span
            id={Id.inactiveStatusTooltip}
            className="field-value status-tag inactive"
          >
            {list.CreateWorkFlowStateName}
          </span>
        );
      }
    }
  }

  showCategoryDescription(list) {
    if (list.DeductionCategoryId === id.DEDUCTION_GARNISHMENT_ID) {
      return (
        <div>
          <OverlayTrigger
            placement="top"
            overlay={tooltip_garnishment_category_column}
          >
            <span
              id={Id.categoryColumnItem + list.DeductionCategoryId}
              className="field-value"
            >
              {list.DeductionCategoryName}
            </span>
          </OverlayTrigger>
        </div>
      );
    } else if (list.DeductionCategoryId === id.DEDUCTION_INSURANCE_ID) {
      return (
        <div>
          <OverlayTrigger
            placement="top"
            overlay={tooltip_insurance_category_column}
          >
            <span
              id={Id.categoryColumnItem + list.DeductionCategoryId}
              className="field-value"
            >
              {list.DeductionCategoryName}
            </span>
          </OverlayTrigger>
        </div>
      );
    } else if (list.DeductionCategoryId === id.DEDUCTION_RETIREMENT_ID) {
      return (
        <div>
          <OverlayTrigger
            placement="top"
            overlay={tooltip_retirement_category_column}
          >
            <span
              id={Id.categoryColumnItem + list.DeductionCategoryId}
              className="field-value"
            >
              {list.DeductionCategoryName}
            </span>
          </OverlayTrigger>
        </div>
      );
    } else if (list.DeductionCategoryId === id.DEDUCTION_RETIREMENT_LOAN_ID) {
      return (
        <div>
          <OverlayTrigger
            placement="top"
            overlay={tooltip_retirement_loan_column}
          >
            <span
              id={Id.categoryColumnItem + list.DeductionCategoryId}
              className="field-value"
            >
              {list.DeductionCategoryName}
            </span>
          </OverlayTrigger>
        </div>
      );
    } else if (list.DeductionCategoryId === id.DEDUCTION_OTHER_ID) {
      return (
        <div>
          <OverlayTrigger
            placement="top"
            overlay={tooltip_other_category_column}
          >
            <span
              id={Id.categoryColumnItem + list.DeductionCategoryId}
              className="field-value"
            >
              {list.DeductionCategoryName}
            </span>
          </OverlayTrigger>
        </div>
      );
    }
  }

  showIconOrReactivate(list) {
    if (
      list.CreateWorkFlowStateId === UICOMPLETIONSTAGE_IN_PROGRESS ||
      list.CreateWorkFlowStateId === UICOMPLETIONSTAGE_ACTIVE
    ) {
      return (
        <div>
          <div className="list-page-delete-section">
            <OverlayTrigger placement="top" overlay={tooltip_thrash_icon}>
              <a onClick={this.openmaindeletionmodal.bind(this, list)}>
                <i
                  id={Id.deleteItemIcon + list.Id}
                  className="list-page-delete-icon fa fa-trash-o"
                />
              </a>
            </OverlayTrigger>
          </div>

          <div className="list-page-edit-section">
            <OverlayTrigger placement="top" overlay={tooltip_edit_icon}>
              <a onClick={this.redirectToReviewAndSavePage.bind(this, list)}>
                <i
                  id={Id.editItemIcon + list.Id}
                  className="list-page-edit-icon fa fa-pencil"
                />
              </a>
            </OverlayTrigger>
          </div>
        </div>
      );
    } else if (list.CreateWorkFlowStateId === UICOMPLETIONSTAGE_INACTIVE) {
      return (
        <div>
          <button
            onClick={this.reactivateDeduction.bind(this, list)}
            style={{ marginTop: "-5px" }}
            id={Id.reactiveItemButton + list.Id}
            type="button"
            className="special-outline-button hidden-xs pull-right list-page-reactivate-button"
          >
            REACTIVATE
          </button>
        </div>
      );
    }
  }

  showIconOrReactivateWithoutTooltips(list) {
    if (
      list.CreateWorkFlowStateId === UICOMPLETIONSTAGE_IN_PROGRESS ||
      list.CreateWorkFlowStateId === UICOMPLETIONSTAGE_ACTIVE
    ) {
      return (
        <div>
          <div className="list-page-delete-section">
            <a onClick={this.openmaindeletionmodal.bind(this, list)}>
              <i
                id={Id.deleteTooltip + list.Id}
                className="list-page-delete-icon fa fa-trash-o"
              />
            </a>
          </div>

          <div className="list-page-edit-section">
            <a onClick={this.redirectToReviewAndSavePage.bind(this, list)}>
              <i
                id={Id.editTooltip + list.Id}
                className="list-page-edit-icon fa fa-pencil"
              />
            </a>
          </div>
        </div>
      );
    } else if (list.CreateWorkFlowStateId === UICOMPLETIONSTAGE_INACTIVE) {
      return (
        <div className="hidden-xs">
          <button
            onClick={this.reactivateDeduction.bind(this, list)}
            style={{ marginTop: "-5px" }}
            id={Id.reactiveItemButton + list.Id}
            type="button"
            className="special-outline-button hidden-xs pull-right"
          >
            REACTIVATE
          </button>
        </div>
      );
    }
  }

  isMobileReactivate(list) {
    if (list.CreateWorkFlowStateId === UICOMPLETIONSTAGE_INACTIVE) {
      return (
        <div className="col-xs-12 col-sm-12 reactivate-mobile-button hidden-sm hidden-md hidden-lg">
          <button
            onClick={this.reactivateDeduction.bind(this, list)}
            id={`list-page-reactivate-` + list.Id}
            type="button"
            className="special-outline-button"
          >
            REACTIVATE
          </button>
        </div>
      );
    }
  }

  openSnapShotSummary(item) {
    this.props.getReviewandSaveDataList(item.ActivityInstanceId);

    this.setState({
      snapshotSummaryModal: true
    });
  }

  closeSnapShotSummary = () => {
    this.setState({
      snapshotSummaryModal: false
    });
    this.props.clearReviewandSaveDataList();
  };

  render() {
    let reviewandSaveData = this.props.reviewandSaveData;
    let nodes = [];
    let data = [];
    if (
      reviewandSaveData.Data === null ||
      reviewandSaveData.Data === undefined ||
      reviewandSaveData.Data.length === 0
    ) {
      nodes = [];
      data = [];
    } else {
      nodes = reviewandSaveData.Data[0].CurrentValueSnapshot
        ? reviewandSaveData.Data[0].CurrentValueSnapshot
        : [];
      data = reviewandSaveData.Data[0];
    }
    return (
      <div className="nc-list-page-container">
        <DeductionSetupDescription isListPage={true} />
        <div className="clearfix" />

        {this.props.toasterData.show && (
          <div className="toaster-container">
            <Toaster
              id={Id.listPageToaster}
              show={this.props.toasterData.show}
              message={this.props.toasterData.message}
              closeToasterMessage={this.closeToasterMessage}
              alertClassName={this.props.toasterData.alertClassName}
            />
          </div>
        )}

        {this.props.systemDeductionMainList.DataCount === 0 ? (
          <NoEntityToSelect label="Deduction Templates" id="DEDUCTIONS" />
        ) : (
          <div className="clearfix">
            <SystemLevelAdvanceFilter
              onSelectedDeductions={selectedDeductions =>
                this.setDeductions(selectedDeductions)
              }
              onSelectedCategories={selectedCategories =>
                this.setCategories(selectedCategories)
              }
              onSelectedStatus={selectedStatus =>
                this.setStatus(selectedStatus)
              }
            />

            <div className="list-page-grid-table col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div
                id={
                  this.props.systemDeductionFilteredList.length === 0
                    ? Id.loadingCount
                    : Id.listCount
                }
                className="nc-rowcount-results"
              >
                {this.props.systemDeductionFilteredList.length === 0
                  ? "Loading..."
                  : this.props.systemDeductionFilteredList.DataCount === 1
                  ? `We found ${
                      this.props.systemDeductionFilteredList.DataCount
                    } result.`
                  : `We found ${
                      this.props.systemDeductionFilteredList.DataCount
                    } results.`}
              </div>
              {this.renderTableByCategory()}
            </div>
          </div>
        )}

        <Modal
          id="system-deduction-delete-modal"
          bsClass="list-page-cancel modal"
          animation={false}
          backdrop={"static"}
          keyboard={false}
          show={this.state.opendeletemodal}
          onHide={this.closemaindeletionmodal}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id={Id.modalCloseIcon}
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div
                id={Id.modalMessage}
                className="modal-row col-xs-12 col-sm-12 col-md-12 col-lg-12"
              >
                <div>
                  Are you sure you want to delete your '
                  {this.state.selectedDeductionName}' deduction template? It
                  will be gone for now, but you can add it back if you need it.
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                className="nc-button primary-button"
                id={Id.modalSuccessButton}
                onClick={this.deleteDeduction}
              >
                DELETE TEMPLATE
              </button>
              <button
                type="button"
                className="secondary-link-button"
                id={Id.modalCancelButton}
                onClick={this.close}
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          id="system-deduction-inactivate-modal"
          bsClass="list-page-cancel modal"
          animation={false}
          backdrop={"static"}
          keyboard={false}
          show={this.state.openInactivateModal}
          onHide={this.onCloseInactivateModal}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id={this.modalCloseIcon}
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div
                id={Id.modalMessage}
                className="modal-row col-xs-12 col-sm-12 col-md-12 col-lg-12"
              >
                <div>
                  <p>
                    It looks like this deduction template has been used by a
                    company! We will mark it as inactive to preserve history.
                  </p>
                  {/* For bug 51537 */}
                  {/* <p>
                    WARNING: Inactivating this deduction will automatically
                    inactivate it for all currently-assigned employees. You can
                    reactivate this deduction later, but you will need to
                    manually reactivate it for each employee.
                  </p> */}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                className="nc-button primary-button"
                id={Id.modalSuccessButton}
                onClick={this.openStatusInactivate}
              >
                INACTIVATE TEMPLATE
              </button>
              <button
                type="button"
                className="secondary-link-button"
                id={Id.modalCancelButton}
                onClick={this.close}
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          id="activity-history"
          animation={false}
          bsSize="large"
          bsClass="modal"
          show={this.state.snapshotSummaryModal}
          restoreFocus={true}
        >
          <EntitySnapshotHeader
            data={data}
            snapshotData={reviewandSaveData.Data}
            onHide={this.closeSnapShotSummary}
            userTimeZoneOffset={this.props.userTimeZoneOffset.data}
            userTimeZoneAbbreviation={this.props.userTimeZoneAbbreviation.data}
            convertUserTimeZone={convertUserTimeZone}
            entity='Deduction'
          />
          <Modal.Body>
            <div className="nc-enity-snapshot-summary">
              <EntitySnapshotTable nodes={nodes} />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    systemDeductionFilteredList: state.systemDeductionFilteredList,
    systemLevelDeductionDeleteIconClickList:
      state.systemLevelDeductionDeleteIconClickList,
    systemDeductionMainList: state.systemDeductionMainList,
    systemDeductionData: state.systemDeductionData,
    toasterData: state.toasterData,
    reviewandSaveData: state.systemReviewandSaveReducer,
    userTimeZoneOffset: state.navigationReducer.userTimeZoneOffset,
    userTimeZoneAbbreviation: state.navigationReducer.userTimeZoneAbbreviation
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getDeductionSetupList: getDeductionSetupList,
      getDeductionDeleteIconClickList: getDeductionDeleteIconClickList,
      setSystemDeductionData: setSystemDeductionData,
      getFilteredDeductionSetupList: getFilteredDeductionSetupList,
      updatedFilteredList: updatedFilteredList,
      showToaster: showToaster,
      getDeductionList: getDeductionList,
      getCategoryList: getCategoryList,
      getStatusList: getStatusList,
      setLoader: setLoader,
      getReviewandSaveDataList: getReviewandSaveDataList,
      clearReviewandSaveDataList: clearReviewandSaveDataList,
      clearDeductionList: clearDeductionList
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SystemLevelDeductionSetupListingPage)
);
