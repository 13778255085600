export const EmployeeListData = {
  Id: 0,
  Status: "Completed",
  ErrorCount: 0,
  Errors: null,
  DataCount: 3,
  Data: [
    {
      EmployeeId: 1,
      EmployeeName: "A'rdin, Elizabeth O'Neal",
      EmployeeUsername: "eardin10",
      EmployeePictureUrl:
        "http://www.lawyersweekly.com.au/images/LW_Media_Library/LW-603-p28-partner-profile.jpg",
      EmployeeStatusId: 1,
      EmployeeStatusName: "Active",
      EmployeeDivison: "000/0000/00000",
      EmployeeStatusDate: "2017-03-10T21:00:00",
      EmployeeStatusDateName: "Hire Date",
      TimeGroup: "Bi-weekly Hourly"
    },
    {
      EmployeeId: 2,
      EmployeeName: "Adams,Chris",
      EmployeeUsername: "cadams293",
      EmployeePictureUrl:
        "https://i2.wp.com/hsmai-europe.com/wp-content/uploads/2017/05/20160105-Travel-Tripper-0013.1-copy-1.jpg?fit=1200%2C1200",

      EmployeeStatusId: 2,
      EmployeeStatusName: "Terminated",
      EmployeeDivison: "000/0000/00000",
      EmployeeStatusDate: "2014-11-23T21:00:00",
      EmployeeStatusDateName: "Termination Date",
      TimeGroup: "Semi-Monthly"
    },
    {
      EmployeeId: 3,
      EmployeeName: "Adams, Joe John",
      EmployeeUsername: "jjadams27",
      EmployeePictureUrl:
        "http://www.fambiz.org.au/wp-content/uploads/Angela-Harvey.cropped.jpg",

      EmployeeStatusId: 3,
      EmployeeStatusName: "Inactive",
      EmployeeDivison: "000/0000/00000",
      EmployeeStatusDate: "2016-12-03T21:00:00",
      EmployeeStatusDateName: "Hire Date",
      TimeGroup: "Bi-weekly Hourly"
    },
    {
      EmployeeId: 4,
      EmployeeName: "Aldrin, Buzz",
      EmployeeUsername: "buzzzz2222",
      EmployeePictureUrl:
        "http://www.starkimages.com.au/wp-content/uploads/2012/05/JA-EXP-4.5.jpg",

      EmployeeStatusId: 4,
      EmployeeStatusName: "On Leave",
      EmployeeDivison: "000/0000/00000",
      EmployeeStatusDate: "2015-01-01T21:00:00",
      EmployeeStatusDateName: "Leave Inactive Date",
      TimeGroup: "Bi-weekly Hourly"
    },
    {
      EmployeeId: 5,
      EmployeeName: "Bean, Jerry",
      EmployeeUsername: "jbean4now",
      EmployeePictureUrl:
        "https://www.scheller.gatech.edu/thumb/width/600/cropratio/1:1/pix/directory/zhang_laurina_profile.jpg",

      EmployeeStatusId: 5,
      EmployeeStatusName: "Transferred",
      EmployeeDivison: "000/0000/00000",
      EmployeeStatusDate: "2013-12-24T21:00:00",
      EmployeeStatusDateName: "Hire Date",
      TimeGroup: "Bi-weekly Hourly"
    },
    {
      EmployeeId: 6,
      EmployeeName: "Boudreaux, Cherie Ann",
      EmployeeUsername: "cab10",
      EmployeePictureUrl:
        "https://www.cheme.cornell.edu/engineering2/customcf/iws_news/uploads/reis_escobedo.jpg",

      EmployeeStatusId: 4,
      EmployeeStatusName: "Active",
      EmployeeDivison: "000/0000/00000",
      EmployeeStatusDate: "2017-03-10T21:00:00",
      EmployeeStatusDateName: "Leave Inactive Date",
      TimeGroup: "Semi-Monthly"
    },
    {
      EmployeeId: 7,
      EmployeeName: "Brown, Jackson",
      EmployeeUsername: "jacksonbrown1",
      EmployeePictureUrl:
        "http://thefederalist.com/wp-content/uploads/2013/12/McMorris.jpg",

      EmployeeStatusId: 6,
      EmployeeStatusName: "Retired",
      EmployeeDivison: "000/0000/00000",
      EmployeeStatusDate: "2014-11-18T21:00:00",
      EmployeeStatusDateName: "Retirement Date",
      TimeGroup: "Semi-Monthly"
    },
    {
      EmployeeId: 8,
      EmployeeName: "Caske, Johny",
      EmployeeUsername: "cske10",
      EmployeePictureUrl:
        "https://media.licdn.com/mpr/mpr/shrinknp_200_200/p/1/005/01a/187/19508ae.jpg",

      EmployeeStatusId: 6,
      EmployeeStatusName: "Deceased",
      EmployeeDivison: "000/0000/00000",
      EmployeeStatusDate: "2014-03-10T21:00:00",
      EmployeeStatusDateName: "",
      TimeGroup: "Semi-Monthly"
    }
  ],

  NextUri: null,
  PreviousUri: null
};

export const treeViewData = [
  {
    label: "(Demo) AA Supply Company",
    nodeType: "root",
    nodes: [
      {
        label: "Divtest1",
        nodes: [
          {
            label: "Bu Test1",
            nodes: [
              { label: "Sample Item 1" },
              { label: "Sample Item 2" },
              { label: "Sample Item 3" }
            ]
          },
          {
            label: "Grandchild 2"
          }
        ]
      },
      {
        label: "Divtest2",
        nodes: [
          {
            label: "Bu Test1",
            nodes: [
              { label: "Sample Item 1" },
              { label: "Sample Item 2" },
              { label: "Sample Item 3" }
            ]
          },
          {
            label: "Grandchild 2"
          }
        ]
      },
      {
        label: "Main Division",
        nodes: [
          { label: "Sample Item 1" },
          { label: "Sample Item 2" },
          { label: "Sample Item 3" }
        ]
      },
      {
        label: "Northeast Updated",
        nodes: [
          {
            label: "Baltimore",
            nodes: [
              {
                label: "Admin",
                nodes: [
                  {
                    label: "Main Location",
                    nodes: [
                      { label: "Ali,Kenny" },
                      { label: "Ali, Mart" },
                      { label: "api, Jerry" }
                    ]
                  },
                  { label: "Netchex", nodes: [] }
                ]
              }
            ]
          },
          { label: "Sample Item 2" },
          { label: "Sample Item 3" }
        ]
      }
    ]
  }
];
