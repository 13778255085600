import React, { Component } from "react";
import { JobHistoryHeader } from "component-library/job-history";

export default class JobHistoryHeaderContainer extends Component {
  render() {
    const { entityNamePlural } = this.props;
    return (
      <JobHistoryHeader
        sectionTitle={entityNamePlural}
        sectionPath="/"
        filesPath="/files"
      />
    );
  }
}
