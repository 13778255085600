import React from "react";
import * as Fields from "./FieldNames";
import * as Description from "../FieldDescriptions";

import { FormFieldGroup } from "component-library/formSections";
const labelClass = "col-lg-3 col-md-3";

const Termination = ({ onChange, data = {} }) => {
  return (
    <div>
      <FormFieldGroup
        label="Termination Date"
        fieldType="inline"
        inputType="overrideWithComponent"
        componentType="singleDatePicker"
        btnStyle={{ height: "44px" }}
        getFromToValue={onChange}
        onChange={onChange}
        onHide={() => {}}
        inputName={Fields.terminationDate}
        value={data[Fields.terminationDate]}
        checkedName={Fields.overrideTerminationDate}
        checkedValue={data[Fields.overrideTerminationDate]}
        checked={data}
        colSpan={labelClass}
        tooltip={Description.TERMINATION_DATE}
      />
      <FormFieldGroup
        label="Termination Type"
        fieldType="inline"
        inputType="Label"
        inputName={Fields.terminationType}
        value="Voluntary"
        //value={data[Fields.terminationType]}
        colSpan={labelClass}
      />
      <FormFieldGroup
        label="Termination Reason"
        fieldType="inline"
        inputType="Label"
        inputName={Fields.terminationReason}
        //value={data[Fields.terminationReason]}
        value="Quit"
        colSpan={labelClass}
      />
      <FormFieldGroup
        label="Eligible for Rehire?"
        fieldType="inline"
        inputType="overrideWithComponent"
        componentType="radio"
        radios="boolean"
        btnStyle={{ height: "44px" }}
        getFromToValue={onChange}
        onChange={onChange}
        onHide={() => {}}
        inputName={Fields.terminationrehireEligibility}
        value={data[Fields.terminationrehireEligibility]}
        checkedName={Fields.overrideTerminationRehire}
        checkedValue={data[Fields.overrideTerminationRehire]}
        colSpan={labelClass}
        tooltip={Description.ELIGIBLE_FOR_HIRE}
      />
    </div>
  );
};

export default Termination;
