import Loadable from "react-loadable";
import ChunkLoading from "app/common/UIComponents/ChunkLoading";

const PayrollCalendar = Loadable({
  loader: () =>
    import("./PayrollCalendar" /* webpackChunkName: "payroll-calendar" */),
  loading: ChunkLoading
});

const PayrollCalendarPrintPreview = Loadable({
  loader: () =>
    import("../containers/PayrollCalendarPrintPreview" /* webpackChunkName: "payroll-calendar" */),
  loading: ChunkLoading
});

export { PayrollCalendar, PayrollCalendarPrintPreview };
