export const SET_ENTITY_LIST = "@app/common/SET_ENTITY_LIST";
export const CLEAR_ENTITY_LIST = "@app/common/CLEAR_ENTITY_LIST";
export const ADD_TO_ENTITY_LIST = "@app/common/ADD_TO_ENTITY_LIST";
export const SET_ENTITY_LIST_LOADING = "@app/common/SET_ENTITY_LIST_LOADING";
const entityList = (state = [], { type, payload }) => {
  switch (type) {
    case SET_ENTITY_LIST:
    case SET_ENTITY_LIST_LOADING:
      return payload;
    case CLEAR_ENTITY_LIST:
      return [];
    default:
      return state;
  }
};

export default entityList;

export const setEntityList = list => ({
  type: SET_ENTITY_LIST,
  payload: list
});

export const setEntityListLoading = () => ({
  type: SET_ENTITY_LIST_LOADING,
  payload: { loading: true, data: [] }
});

export const addToEntityList = list => ({
  type: ADD_TO_ENTITY_LIST,
  payload: list
});

export const clearEntityList = () => ({
  type: CLEAR_ENTITY_LIST
});
