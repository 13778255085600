import React, { Component } from "react";

class EmergencyContactCheckbox extends Component {
  render() {
    const { onClick, label, selected } = this.props;
    return (
      <div className="grid-option" style={{ padding: "0px" }}>
        <div
          className={`list-item ${selected ? "selected" : ""}`}
          onClick={onClick}
          style={{
            padding: "10px 15px"
          }}
        >
          <input type="checkbox" checked={selected || false} readOnly />
          <label>{label}</label>
        </div>
      </div>
    );
  }
}

export default EmergencyContactCheckbox;
