import React, { Component } from "react";
import EmployeeList from "./EmployeeList";
import EmployeeDashboard from "./EmployeeDashboard";
import EmployeeTree from "./EmployeeTree";
import { Switch, Route } from "react-router-dom";
import { ListPageHeader as PageHeader } from "component-library/employee-profile";
import { EntityListPageContainer as PageContainer } from "component-library/entity-list";
import AdvancedFilters from "../containers/AdvancedFilters";
import { connect } from "react-redux";
import {
  fetchAndSetPrimaryManager,
  fetchAndSetSecondaryManager,
  fetchAndSetEmployeeType,
  fetchAndSetEmployeeClassification,
  fetchAndSetPosition,
  fetchAndSetPayrollSchedule,
  fetchAndSetPayrollFrequency,
  fetchAndSetTimeGroup,
  fetchAndSetGender,
  fetchAndSetEmployeeProject,
  fetchAndSetEmployeeClient,
  fetchAndSetEmployeeLocation,
  fetchAndSetEmployeeDepartment,
  fetchAndSetEmployeeBusinessUnit,
  fetchAndSetEmployeeDivision,
  fetchAndSetCompanyList,
  fetchAndSetStatusList,
  fetchAndSetEmployeeFilteredList,
  clearAdvancedFilters,
  clearAdvancedFiltersAndNavigate
} from "../network/actions";
import {
  selectFilterOption,
  selectAllFilterOptions,
  makeSelectedOptions,
  selectDateRangeOption
} from "app/common/reducers/filters";

import {
  setEmployeeAdvancedFilterOptions,
  clearEmployeeAdvancedFilterOptions
} from "../reducers/advancedFilter";

import { makeOptions } from "../utils";

import { treeViewData } from "../mock-data/TableData";
class Employee extends Component {
  state = { showAdvancedFilters: false, showClearFilters: false };

  componentDidMount = () => {
    const { props } = this;
    // props.history.replace("/people/employees/list-view");
    const { CurrentSubscriberCompanyId } = props.sessionContext;
    props.loadEmployeeFilteredList(true);
    props.loadStatusList(true);
    props.loadCompanyList(true, CurrentSubscriberCompanyId);
  };

  toggleView = (link, view) => {
    this.setState({
      activeView: view
    });
    this.props.history.push(link);
  };

  setShowClearFilters = value => {
    this.setState({
      showClearFilters: value
    });
  };

  render() {
    const { filters, advancedFilter = {} } = this.props;
    if (this.state.showAdvancedFilters) {
      return (
        <AdvancedFilters
          {...this.props}
          onClose={() =>
            this.setState({
              showAdvancedFilters: false
            })
          }
          options={filters}
          clearAdvancedFilters={this.props.clearAdvancedFilters}
          setShowClearFilters={this.setShowClearFilters}
          employeeAdvancedFilter={advancedFilter}
        />
      );
    } else {
      return (
        <Switch>
          <Route
            exact
            path="/people/employees/list-view"
            render={() => (
              <div>
                <PageContainer>
                  <PageHeader
                    title={"Employees"}
                    activeView={"list"}
                    switchView={(link, view) => this.toggleView(link, view)}
                  />
                  <EmployeeList
                    {...this.props}
                    toggleAdvancedFilters={() =>
                      this.setState({
                        showAdvancedFilters: !this.state.showAdvancedFilters
                      })
                    }
                    filters={filters}
                    showClearFilters={this.state.showClearFilters}
                    setShowClearFilters={this.setShowClearFilters}
                    employeeAdvancedFilter={advancedFilter}
                  />
                </PageContainer>
              </div>
            )}
          />
          <Route
            exact
            path="/people/employees/tree-view"
            render={() => (
              <div>
                <PageContainer>
                  <PageHeader
                    title={"Employees"}
                    activeView={"tree"}
                    switchView={(link, view) => this.toggleView(link, view)}
                  />
                  <EmployeeTree
                    filterData={this.props.filters}
                    data={treeViewData}
                    {...this.props}
                  />
                </PageContainer>
              </div>
            )}
          />
          <Route
            path="/people/employees/dashboard/:EmployeeId"
            render={() => (
              <EmployeeDashboard
                filters={filters}
                {...this.props}
                showClearFilters={this.state.showClearFilters}
                setShowClearFilters={this.setShowClearFilters}
              />
            )}
          />
        </Switch>
      );
    }
  }
}
const stateToProps = state => {
  return {
    filters: makeOptions(state.common.filters),
    selectedOptions: makeSelectedOptions(state.common.filters),
    advancedFilter: state.people.advancedFilterFields,
    sessionContext: state.sessionContext
  };
};
const actionCreators = {
  loadPrimaryManager: fetchAndSetPrimaryManager,
  loadSecondaryManager: fetchAndSetSecondaryManager,
  loadEmployeeType: fetchAndSetEmployeeType,
  loadEmployeeClassification: fetchAndSetEmployeeClassification,
  loadEmployeePosition: fetchAndSetPosition,
  loadPayrollSchedule: fetchAndSetPayrollSchedule,
  loadPayrollFrequency: fetchAndSetPayrollFrequency,
  loadTimeGroup: fetchAndSetTimeGroup,
  loadGender: fetchAndSetGender,
  loadProjects: fetchAndSetEmployeeProject,
  loadClients: fetchAndSetEmployeeClient,
  loadLocations: fetchAndSetEmployeeLocation,
  loadDepartments: fetchAndSetEmployeeDepartment,
  loadCompanyList: fetchAndSetCompanyList,
  loadStatusList: fetchAndSetStatusList,
  loadEmployeeFilteredList: fetchAndSetEmployeeFilteredList,
  loadBussinessUnits: fetchAndSetEmployeeBusinessUnit,
  loadDivisions: fetchAndSetEmployeeDivision,
  loadCompany: fetchAndSetCompanyList,
  clearAdvancedFilters,
  clearAdvancedFiltersAndNavigate,
  selectOption: selectFilterOption,
  selectAll: selectAllFilterOptions,
  selectDateRange: selectDateRangeOption,
  selectTextfield: setEmployeeAdvancedFilterOptions,
  clearFiltersFields: clearEmployeeAdvancedFilterOptions
};
export default connect(stateToProps, actionCreators)(Employee);
