import React, { Component } from "react";
import DropContainer from "./DropContainer";
import PropTypes from "prop-types";
import "./styles.css";

export default class DragFileSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      onSetClick,
      setRef,
      setImageState,
      onImageLoaded,
      handleFileDrop,
      handleFileUpload
    } = this.props;
    return (
      <DropContainer
        setRef={setRef}
        setImageState={setImageState}
        onSetClick={onSetClick}
        onImageLoaded={onImageLoaded}
        handleFileDrop={handleFileDrop}
        handleFileUpload={handleFileUpload}
      />
    );
  }
}

DragFileSection.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  buttonAlign: PropTypes.string,
  className: PropTypes.string,
  onSetClick: PropTypes.func,
  setRef: PropTypes.func,
  setImageState: PropTypes.func,
  imageState: PropTypes.string,
  setSrc: PropTypes.func
};
DragFileSection.defaultProps = {
  show: true,
  closeModal: () => alert("Will close Modal"),
  buttonAlign: "left",
  className: "",
  onSetClick: () => alert("Will set profile pic"),
  setRef: () => {},
  setImageState: () => alert("Will set Image State"),
  imageState: "",
  onUploadNewClick: () => alert("Upload New Pic"),
  onRemoveClick: () => alert("Remove Uploaded Pic"),
  src: "",
  setSrc: () => {}
};
