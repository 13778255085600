/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import PayrollDashBoardModal from "../payroll-dashboard-modals";
import Accordian from "../../accordians";

export default class ContactAllApproverModal extends Component {
  state = {};

  contactAllApproversContent = () => {
    const {
      missingContactInfoList = [],
      missingApproverList = []
    } = this.props;

    return (
      <div className="modal-body-container">
        <div className="modal-body-header">
          <img
            src={require("../../payroll-dashboard/images/contact_approver.png")}
            alt="Contact All Approver"
            style={{ height: "20px", paddingRight: "5px" }}
          />
          Contact All Approvers
        </div>
        <div className="modal-body-content">
          <div className="clearfix content-section">
            Primary managers, secondary managers and [approver(s) name - name of
            all approvers for this employee] will receive e-mails and texts.
          </div>
          <br />
          {missingContactInfoList && missingContactInfoList.length > 0 && (
            <div className="clearfix content-section-item alert-infomation-section">
              <div className="clearfix" style={{ paddingBottom: "5px" }}>
                <i className="fa fa-exclamation-circle" />{" "}
                <span className="secondary-header">
                  {missingContactInfoList.length} approvers will not receive
                  e-mail and/or texts.
                </span>
              </div>
              <Accordian title="View" hidetitle="Hide" chevronPosition="left">
                <div className="clearfix">
                  {missingContactInfoList.map((item, i) => {
                    return (
                      <div key={i} className="modal-body-gray-section clearfix">
                        <div className="col-lg-10 col-md-10 col-sm-8 col-xs-8">
                          <div className="secondary-header">
                            {item.missingItem === "email"
                              ? item.employeeName +
                                " does not have a work email address."
                              : item.missingItem === "cellphone"
                              ? item.employeeName +
                                " does not have a cell phone number."
                              : null}
                          </div>
                          <div>
                            {item.primaryManager
                              ? item.primaryManager + " primary manager"
                              : item.secondaryManager + " secondary manager"}
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-4 col-xs-4 text-right">
                          <button className="nc-button outline-button modal-content-button">
                            Add <i className="fa fa-caret-right" />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Accordian>
            </div>
          )}

          {missingApproverList && missingApproverList.length > 0 && (
            <div className="clearfix content-section-item alert-infomation-section">
              <div className="clearfix" style={{ paddingBottom: "5px" }}>
                <i className="fa fa-exclamation-circle" />{" "}
                <span className="secondary-header">
                  {missingApproverList.length} employees do not have approver.
                </span>
              </div>
              <Accordian title="View" hidetitle="Hide" chevronPosition="left">
                <div className="clearfix">
                  {missingApproverList.map((item, i) => {
                    return (
                      <div key={i} className="modal-body-gray-section clearfix">
                        <div className="col-lg-9 col-md-9 col-sm-7 col-xs-7">
                          <div className="secondary-header">
                            {item.employeeName +
                              " does not have an assigned manager."}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-5 col-xs-5 text-right">
                          <button className="nc-button outline-button modal-content-button">
                            Assign <i className="fa fa-caret-right" />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Accordian>
            </div>
          )}

          <div className="clearfix content-section-item alert-infomation-section">
            <Accordian
              title="Review alerts before sending"
              additionalLink={
                <div style={{ float: "right" }}>
                  <a>
                    <i className="fa fa-pencil" /> Edit Alerts{" "}
                    <i className="fa fa-caret-right" />
                  </a>
                </div>
              }
              chevronPosition="left"
            >
              <div className="clearfix">
                <div
                  className="modal-body-gray-section clearfix"
                  style={{ display: "block" }}
                >
                  <div className="content-section clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <span className="secondary-header">E-mail Alerts</span>
                    </div>
                  </div>
                  <div className="content-section clearfix">
                    <span className="secondary-header">Subject: </span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </div>
                  <div className="content-section clearfix">
                    <span className="secondary-header">Body: </span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin vitae ipsum magna. In fringilla rhoncus purus sed
                    semper. Phasellus ultricies hendrerit consequat. Cras
                    varius, massa a suscipit sagittis, neque dolor scelerisque
                    massa, vitae rutrum mi quam nec metus. Sed ornare augue
                    molestie egestas eleifend. Quisque fringilla velit sit amet
                    sapien egestas auctor. Curabitur quis laoreet velit. Fusce
                    condimentum leo diam, ac ornare orci malesuada quis.
                    Praesent at mattis purus.
                    <br />
                    <br /> Vivamus lobortis accumsan odio, sed tristique arcu
                    sodales at. In nec venenatis dui. Vestibulum ante ipsum
                    primis in faucibus orci luctus et ultrices posuere cubilia
                    Curae; Fusce in viverra turpis, id consectetur ligula. Nulla
                    sodales turpis sed lorem ultricies congue. Morbi vestibulum
                    hendrerit massa, ut rhoncus enim tincidunt ut. Cras aliquet
                    malesuada mi. Vestibulum sit amet feugiat mi. Aliquam eros
                    neque, fermentum in sapien vel, volutpat porttitor eros. Ut
                    blandit arcu tellus, non consectetur eros vehicula non. Ut
                    pulvinar sit amet ex efficitur iaculis. Praesent tempus
                    aliquet libero sed euismod. Cras at lectus posuere, varius
                    turpis ac, blandit elit. Aenean ultricies nulla sed lorem
                    lacinia, at finibus sapien consectetur. Quisque vestibulum
                    turpis a condimentum ullamcorper. Pellentesque nec fermentum
                    sapien.
                  </div>
                </div>
                <div
                  className="modal-body-gray-section content-section-item clearfix"
                  style={{ display: "block" }}
                >
                  <div className="content-section clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <span className="secondary-header">Text Alerts</span>
                    </div>
                  </div>
                  <div className="content-section clearfix">
                    <span className="secondary-header">Body: </span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin vitae ipsum magna. In fringilla rhoncus purus sed
                    semper. Phasellus ultricies hendrerit consequat. Cras
                    varius, massa a suscipit sagittis, neque dolor scelerisque
                    massa, vitae rutrum mi quam nec metus. Sed ornare augue
                    molestie egestas eleifend. Quisque fringilla velit sit amet
                    sapien egestas auctor. Curabitur quis laoreet velit. Fusce
                    condimentum leo diam, ac ornare orci malesuada quis.
                    Praesent at mattis purus.
                  </div>
                </div>
              </div>
            </Accordian>
          </div>
        </div>
      </div>
    );
  };

  contactAllApproversButtons = () => {
    const modalButton = [
      {
        label: "Send Alerts",
        buttonType: "secondary-button",
        onClick: this.onSendMessageClick
      },
      {
        label: "Cancel",
        buttonType: "secondary-link-button",
        onClick: this.closeContactAllApproversModal
      }
    ];

    return modalButton;
  };

  onSendMessageClick = EmployeeId => {
    this.props.onSendMessageClick(EmployeeId);
  };

  closeContactAllApproversModal = () => {
    this.props.closeContactAllApproversModal();
  };

  render() {
    return (
      <PayrollDashBoardModal
        show={this.props.contactAllApproversModal}
        bodyContent={this.contactAllApproversContent()}
        closeModal={this.closeContactAllApproversModal}
        footerButtons={this.contactAllApproversButtons()}
        buttonAlign="left"
      />
    );
  }
}
