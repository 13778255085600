import React from "react";
import {
  LeaveDetails,
  Fmla,
  FmlaCalculator,
  AdditionalDetails
} from "component-library/employee-profile/employment-info/change-status/on-leave/index";
import FooterButtons from "../components/FooterButtons";
import * as Fields from "component-library/employee-profile/employment-info/change-status/FieldNames";

const FMLA_CONSTANT = 1;

const OnLeave = ({ data = {}, onChange, onAddLinkClick, onCloseOverlay }) => {
  return (
    <div>
      <LeaveDetails
        data={data}
        onChange={onChange}
        onAddLinkClick={onAddLinkClick}
      />
      {data[Fields.leaveType] === FMLA_CONSTANT && (
        <div>
          <Fmla onChange={onChange} data={data} />
          <FmlaCalculator data={data} onChange={onChange} />
          <AdditionalDetails onChange={onChange} data={data} />
        </div>
      )}
      <FooterButtons
        buttonLabel="Start Employee Leave"
        onCancelClick={onCloseOverlay}
      />
    </div>
  );
};

export default OnLeave;
