import axios from "axios";
import * as types from "./index";
import * as common from "../../../../app/common/index";

export function getEnterprisePayeeOptionsList() {
  const url = `${common.WEB_API_URL}/v1/system/enterprisepayee`;
  const request = axios.get(url);
  return {
    type: types.FETCH_ENTERPRISE_PAYEE_OPTIONS_LIST,
    payload: request
  };
}

export function getVendorEmployeeDeductionList() {
  const url = `${common.WEB_API_URL}/v1/system/employeevendorcategory`;
  const request = axios.get(url);
  return {
    type: types.FETCH_VENDOR_EMPLOYEE_DEDUCTION_DROPDOWN_LIST,
    payload: request
  };
}

export function getVendorEmployerDeductionList() {
  const url = `${common.WEB_API_URL}/v1/system/employervendorcategory`;
  const request = axios.get(url);
  return {
    type: types.FETCH_VENDOR_EMPLOYER_DEDUCTION_DROPDOWN_LIST,
    payload: request
  };
}
