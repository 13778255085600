import React from "react";
import "./svg.css";
const WorkersCompNoData = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 200 200"
    style={{ enableBackground: "new 0 0 200 200" }}
    width={width}
    height={height}
  >
    <g>
      <g>
        <circle className="st0" cx="100" cy="100" r="100" />
      </g>
    </g>
    <g>
      <g>
        <path
          className="st1"
          d="M114.8,103.7H82.3c-0.6,0-1.2-0.3-1.5-0.9s-0.3-1.2,0-1.8L97,72.9c0.3-0.5,0.9-0.9,1.5-0.9s1.2,0.3,1.5,0.9
			l16.3,28.2c0.3,0.5,0.3,1.2,0,1.8S115.4,103.7,114.8,103.7z M85.3,100.2h26.5L98.6,77.2L85.3,100.2z"
        />
      </g>
      <g>
        <path
          className="st1"
          d="M96.4,96.4c0-0.6,0.2-1,0.5-1.4s0.8-0.5,1.3-0.5s1,0.2,1.3,0.5s0.5,0.8,0.5,1.4c0,0.5-0.2,1-0.5,1.4
			s-0.8,0.5-1.3,0.5s-1-0.2-1.3-0.5S96.4,97,96.4,96.4z M96.8,87l-0.1-2.8h3.1L99.7,87l-0.4,6.3h-2L96.8,87z"
        />
      </g>
      <g>
        <path
          className="st1"
          d="M114.8,114.9H82.2c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8h32.7c1,0,1.8,0.8,1.8,1.8S115.8,114.9,114.8,114.9z"
        />
      </g>
      <g>
        <path
          className="st1"
          d="M114.8,127.8H82.2c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8h32.7c1,0,1.8,0.8,1.8,1.8S115.8,127.8,114.8,127.8z"
        />
      </g>
      <g>
        <path
          className="st1"
          d="M147.2,52.8L147.2,52.8c0-0.1,0-0.1,0-0.2v-0.4l0,0c-0.3-3.7-3.4-6.7-7.2-6.7l-79.9,0c-4.8,0-7.2,3.6-7.2,7.2
			S55.3,60,60,60H61v92.5c0,1,0.8,1.9,1.9,1.9h71.7c1,0,1.9-0.8,1.9-1.9V141h10.7V52.8z M132.7,150.7h-68V60l15.4,0l52.6,0V150.7z
			 M133.6,56.3c-90.6,0-66.9,0-73.5,0c-2.6,0-3.5-1.9-3.5-3.5s0.9-3.5,3.5-3.5h73.5C132.4,51.5,132.4,54.1,133.6,56.3z M144,137.7
			h-7.5V84.9l0,0V59.5h3.7c0,0,0,0,0,0c1.7,0,1.7-2.7,0-2.7c0,0,0,0,0,0c-2.6,0-3.7-2-3.7-3.9v-0.2c0-2,1.2-3.9,3.7-3.9c0,0,0,0,0,0
			c2.1,0,3.7,1.8,3.7,4v9.7V137.7z"
        />
      </g>
    </g>
  </svg>
);
export default WorkersCompNoData;
