import React from "react";
// import { Link } from "react-router-dom";
import moment from "moment";
import "./MultiSectionListSubitem.css";
const convertDatetoMonthDayYear = date => {
  return moment(date).format("MMM D YYYY");
};
const convertDateToTime = date => {
  return moment(date).format("LT");
};
const MultiSectionListSubitem = props => (
  <div className="MultiSectionListSubitem">
    <div style={{ fontSize: "13px" }}>
      {props.showCircle && (
        <i
          className="fa fa-circle hidden-sm hidden-xs"
          style={{
            marginLeft: "-15px",
            fontSize: "10px",
            color: "#fb6b5b"
          }}
        />
      )}

      {convertDatetoMonthDayYear(props.EmployeeUpdateTimeStamp)}
      {" at "}
      {convertDateToTime(props.EmployeeUpdateTimeStamp)}
    </div>
    <div style={{ paddingBottom: "10px", fontSize: "13px" }}>
      {props.EmployeeUpdateCategoryName}
    </div>
    <div style={{ fontSize: "15px" }}>
      {props.EmployeeUpdateFieldName} was changed from{" "}
      {props.EmployeeUpdateFieldValueBeforeUpdate} to{" "}
      {props.EmployeeUpdateFieldValueAfterUpdate} by{" "}
      {props.EmployeeUpdateMadeByUserName}
      {"."}
      <a style={{ paddingLeft: "10px" }}>
        View <i className="fa fa-caret-right" aria-hidden="true" />
      </a>
    </div>
  </div>
);

export default MultiSectionListSubitem;
