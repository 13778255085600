import React from "react";
import "./svg.css";
const ContactApproversInactive = ({ width, height, style }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 41 41"
    style={{ enableBackground: "new 0 0 41 41", ...style }}
    width={width}
    height={height}
  >
    <circle className="st5" cx="13" cy="7.3" r="5.9" style={style} />
    <path
      className="st5"
      style={style}
      d="M18.2,26.6c0-1.1,0.3-1.9,0.8-2.4h0c0.5-0.6,1.3-0.9,2.5-0.9h3.9c-0.7-4.9-4.9-8.7-10-8.7h-4.9C5,14.6,0.4,19.1,0.4,24.6V30h17.8V26.6z"
    />
    <path
      className="st5"
      style={style}
      d="M39.9,26.3c-0.4-0.4-1.1-0.6-1.9-0.6H23.3c-0.9,0-1.5,0.2-2,0.7h0c-0.4,0.4-0.7,1.1-0.7,1.9v8.4c0,1.8,0.9,2.6,2.7,2.7H38c1.8,0,2.7-0.9,2.7-2.7v-8.4C40.7,27.5,40.4,26.8,39.9,26.3z M39.1,37.3c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.3-0.1-0.5-0.2l-4.6-5.3l-2.3,1.9c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0-0.5-0.1-0.6-0.2l-2.3-1.9l-4.6,5.3c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.3,0-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0-0.4,0.2-0.5l4.5-5.3l-4.5-3.7C22.1,28.2,22,28,22,27.9c0-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2l7.2,5.9l7.2-5.9c0.2-0.1,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.1,0.2,0.3,0.3,0.5c0,0.2-0.1,0.4-0.3,0.5L34.6,32L39.1,37.3z"
    />
  </svg>
);
export default ContactApproversInactive;
