import { connect } from "react-redux";
import EmployeeNotes from "../containers/EmployeeNotes";
import { fetchEmployeeData } from "../network/actions";
import { clearEmployeeNotesValues } from "../reducers/employeeNotes";

const stateToProps = state => ({
  data: state.Others
});
const actionCreators = {
  fetchEmployeeData,
  clearEmployeeNotesValues
};
export default connect(stateToProps, actionCreators)(EmployeeNotes);
