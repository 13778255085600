import axios from "axios";
import * as types from "./index";

export function getUserInSession() {
  const sessionContext = axios.defaults.headers.common["SessionContext"];
  let request = false;
  if (sessionContext === undefined || sessionContext === null) {
    request = false;
  } else {
    request = true;
  }

  return {
    type: types.CHECK_USER_IN_SESSION,
    payload: request
  };
}

export function setUserSession(value) {
  return {
    type: types.CHECK_USER_IN_SESSION,
    payload: value
  };
}

export function setSessionContext() {
  let sessionContext = window.atob(
    axios.defaults.headers.common["SessionContext"]
  );

  let sessionContextData = sessionContext.split(":");
  let data = {
    SessionContextId: sessionContextData[0],
    UserId: sessionContextData[1],
    CurrentSubscriberId: sessionContextData[2],
    CurrentSubscriberCompanyId: sessionContextData[3],
    CurrentAppUiViewId: sessionContextData[4],
    UserLogin: sessionContextData[5],
    UserCode: sessionContextData[6]
  };

  return {
    type: types.SESSION_CONTEXT,
    payload: data
  };
}

export function clearSessionContext() {
  let data = {
    SessionContextId: null,
    UserId: null,
    CurrentSubscriberId: null,
    CurrentSubscriberCompanyId: null,
    CurrentAppUiViewId: null,
    UserLogin: null,
    UserCode: null
  };

  return {
    type: types.CLEAR_SESSION_CONTEXT,
    payload: data
  };
}

export function showToaster(show, message, alertClassName = "success") {
  let item = {
    show,
    message,
    alertClassName
  };
  if (!show) {
    item = {
      show,
      message,
      linkId: null,
      linkName: null,
      link: null,
      alertClassName: "success"
    };
  }

  return {
    type: types.SHOW_TOASTER,
    payload: item
  };
}

export function showToasterByClass(show, message, alertClassName) {
  let item = {
    show,
    message,
    alertClassName
  };
  if (!show) {
    item = {
      show,
      message,
      alertClassName,
      linkId: null,
      linkName: null,
      link: null
    };
  }

  return {
    type: types.SHOW_TOASTER,
    payload: item
  };
}

export function showToasterWithLink(
  show,
  message,
  linkId,
  linkName,
  link,
  feature
) {
  let item = {
    show,
    message,
    linkId,
    linkName,
    link,
    feature
  };

  return {
    type: types.SHOW_TOASTER,
    payload: item
  };
}

export function showTimeoutPopup() {
  return {
    type: types.SHOW_TIMEOUT_POPUP,
    payload: true
  };
}

export function isInAuthProcess(set = true) {
  return {
    type: types.IS_IN_AUTH_PROCESS,
    payload: set
  };
}
