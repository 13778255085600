import React from "react";
import * as Constants from "./Constants";
import "./customRadioTab.css";
const CustomRadioTab = ({
  name,
  onChange = () => {},
  value,
  options = [],
  childWidth,
  className,
  customMarginLeft = "0px",
  error,
  required
}) => {
  return (
    <div className={className}>
      <div
        className="switch-radio-field select-month"
        style={{ marginLeft: customMarginLeft }}
      >
        {options.map((item, i) => {
          return (
            <div key={i}>
              <input
                type={Constants.RADIO_INPUT}
                id={item.id}
                name={item.id}
                value={value}
                checked={item.value === value}
                onChange={e =>
                  onChange &&
                  onChange({
                    name: name,
                    value: item.value
                  })
                }
              />
              <label htmlFor={item.id} style={{ width: childWidth }}>
                {item.label}
              </label>
            </div>
          );
        })}
      </div>
      {error &&
        value == null &&
        required && (
          <div style={{ marginTop: "5px" }} className="has-error">
            {error}
          </div>
        )}
    </div>
  );
};
export default CustomRadioTab;
