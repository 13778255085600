export const SET_PAYROLL_CHECKLIST_OPTIONS =
  "@payroll-schedule/SET_PAYROLL_CHECKLIST_OPTIONS";
export const CLEAR_PAYROLL_CHECKLIST_OPTIONS =
  "@payroll-schedule/CLEAR_PAYROLL_CHECKLIST_OPTIONS";

const payrollChecklistOptions = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PAYROLL_CHECKLIST_OPTIONS:
      const { name } = payload;
      return {
        ...state,
        [name]: payload.options
      };
    case CLEAR_PAYROLL_CHECKLIST_OPTIONS:
      return (state = {});
    default:
      return state;
  }
};

export default payrollChecklistOptions;

export const setPayrollChecklistOptions = (name, options) => ({
  type: SET_PAYROLL_CHECKLIST_OPTIONS,
  payload: { name, options }
});

export const clearPayrollChecklistOptions = () => ({
  type: CLEAR_PAYROLL_CHECKLIST_OPTIONS
});
