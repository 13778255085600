import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import classNames from "classnames/bind";
export default class FormAccordian extends Component {
  state = { open: this.props.defaultOpen || false };
  onClick = () => {
    this.setState({
      open: !this.state.open
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.HideShowAccordian !== this.props.HideShowAccordian) {
      this.setState({
        open: false
      });
    }
  }

  render() {
    const { props, state } = this;
    let title = state.open ? props.hidetitle : props.title;
    let chevron = classNames(
      "fa",
      state.open ? "fa-angle-up" : "fa-angle-down"
    );
    return (
      <div
        className="nc-accordian form-accordian"
        style={{ paddingBottom: state.open ? "5px" : "15px" }}
      >
        <div
          id={props.id}
          className="form-accordian-title"
          onClick={this.onClick}
        >
          <i className={chevron} />
          {props.hidetitle ? title : props.title}
        </div>
        {props.additionalLink}
        <Collapse in={state.open}>
          <div style={{ paddingLeft: "18px" }}>{props.children}</div>
        </Collapse>
      </div>
    );
  }
}
