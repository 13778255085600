import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import formatTime from "./../FormatTime";
import UserIcon from "./UserIcon";
import PropTypes from "prop-types";
const tooltip_onleave_status_column = (
  <Tooltip id="tooltip_inprogress_status_icon">Employee is on Leave</Tooltip>
);
const tooltip_active_status_column = (
  <Tooltip id="tooltip_active_status_column">Employee is active</Tooltip>
);

const tooltip_inactive_status_column = (
  <Tooltip id="tooltip_inactive_status_column">Employee is inactive</Tooltip>
);
const tooltip_terminated_status_column = (
  <Tooltip id="tooltip_inactive_status_column">Employee is terminated</Tooltip>
);
const tooltip_transferred_status_column = (
  <Tooltip id="tooltip_inactive_status_column">Employee is transferred</Tooltip>
);
const tooltip_retired_status_column = (
  <Tooltip id="tooltip_inactive_status_column">Employee is terminated</Tooltip>
);
const tooltip_deceased_status_column = (
  <Tooltip id="tooltip_inactive_status_column">Employee is transferred</Tooltip>
);
function getStatusTooltip(status) {
  switch (status) {
    case "Active":
      return tooltip_active_status_column;
    case "Inactive":
      return tooltip_inactive_status_column;
    case "On Leave":
      return tooltip_onleave_status_column;
    case "Terminated":
      return tooltip_terminated_status_column;
    case "Transferred":
      return tooltip_transferred_status_column;
    case "Retired":
      return tooltip_retired_status_column;
    case "Deceased":
      return tooltip_deceased_status_column;
    default:
      return null;
  }
}
const EmployeeListItem = ({ item, onClickEmployee, showSSN }) => {
  return (
    <div
      className="table-row col-lg-12 col-md-12 col-sm-12 col-xs-12"
      style={{
        display: "flex",
        alignItems: "center",
        lineHeight:
          item.StatusName === "inactive" && window.innerWidth > 991
            ? "1.5"
            : null
      }}
    >
      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12 name-column table-cell">
        <UserIcon
          {...item}
          onClickEmployee={onClickEmployee}
          showSSN={showSSN}
        />
      </div>
      <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 table-cell">
        <span className="hidden-lg hidden-md field-header">Status : </span>
        <OverlayTrigger
          placement="top"
          overlay={getStatusTooltip(item.StatusName)}
        >
          <span
            id={"entity-status-" + item.StatusId}
            className={
              `field-value status-tag ` +
              item.StatusName.toLowerCase().replace(/\s/g, "")
            }
          >
            {item.StatusName}
          </span>
        </OverlayTrigger>
      </div>
      <div className="status-container col-lg-2 col-md-2 col-sm-12 col-xs-12">
        <span className="hidden-lg hidden-md field-header">DIV/BUS/DEP : </span>
        {item.DivisionCode}/{item.BusinessUnitCode}/{item.DepartmentCode}
      </div>
      <div className="status-container col-lg-3 col-md-2 col-sm-12 col-xs-12">
        <span className="hidden-lg hidden-md field-header">
          Employee Status Date :{" "}
        </span>
        <div>{formatTime(item.EmployeeCurrentStatusDate)}</div>
        <div style={{ color: "#999", fontSize: "14px" }}>
          <i>{item.EmployeeCurrentStatusName + " Date"}</i>
        </div>
      </div>
      <div className="status-container col-lg-2 col-md-2 col-sm-12 col-xs-12">
        <span className="hidden-lg hidden-md field-header">Time Group : </span>
        {item.PayrollScheduleName}
      </div>
    </div>
  );
};

export default EmployeeListItem;

EmployeeListItem.propTypes = {
  item: PropTypes.object,
  onClickEmployee: PropTypes.func,
  showSSN: PropTypes.bool
};
EmployeeListItem.defaultProps = {
  item: {},
  showSSN: true,
  onClickEmployee: () => alert("You clicked Employee")
};
