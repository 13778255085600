import { FormSection, FormFieldGroup } from "component-library/formSections";
import * as Fields from "./../FieldNames";

import React from "react";
import IncludedInPayroll from "./IncludedInPayroll";

const AdditionalDetails = ({ data = {}, onChange }) => {
  return (
    <FormSection title={"Additional Details"} hr>
      <IncludedInPayroll
        inputName={Fields.additionalIncludedInPayroll}
        label="Should this employee be included in payroll ?"
        value={data[Fields.additionalIncludedInPayroll]}
        data={data}
        onChange={onChange}
        link="Until which paycheck date"
        linkName="AdditionalIncludedInPayrollEndDate"
        showLink={data.AdditionalIncludedInPayrollEndDate}
      />

      {data.AdditionalIncludedInPayrollEndDate &&
        data[Fields.additionalIncludedInPayroll] && (
          <FormFieldGroup
            inputType="noEndDateComponent"
            componentType="singleDatePicker"
            label="Until which paycheck date?"
            fieldType="inline"
            inputName={Fields.includedInPayrollEndDate}
            data={data[Fields.includedInPayrollEndDate]}
            colSpan="col-lg-3 col-md-3"
            style={{ marginBottom: "15px" }}
            Size={[4, 4, 12, 12]}
            onChange={onChange}
            checkedName={"AdditionalIncludedInPayrollNoEndDate"}
            checkedValue={data.AdditionalIncludedInPayrollNoEndDate}
          />
        )}

      {data[Fields.additionalIncludedInPayroll] && (
        <FormFieldGroup
          inputType="textarea"
          inputName={Fields.additionalNotes}
          label="Notes"
          value={data[Fields.additionalNotes]}
          fieldType="inline"
          description="250 characters max"
          rows={6}
          descriptionStyle="italic"
          colSpan="col-lg-3 col-md-3"
          Size={[8, 8, 12, 12]}
          onChange={onChange}
          optional
        />
      )}
    </FormSection>
  );
};

export default AdditionalDetails;
