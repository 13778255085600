import React from "react";
import { FormFieldGroup, FormSection } from "../../../formSections";
import * as Fields from "./FieldNames";
const labelMaker = (noLabel, allLabel, someLabel, oneLabel) => (o, s, all) =>
  all
    ? allLabel
    : Object.keys(s).filter(k => s[k]).length === 0
      ? noLabel
      : Object.keys(s).filter(k => s[k]).length === 1
        ? oneLabel
        : someLabel;

const JobDetails = ({
  onChange,
  data,
  options = {},
  makeOnSelect,
  makeOnSelectAll,
  selectedOptions
}) => {
  return (
    <FormSection>
      <div className="column-title">Compensation/Job Details</div>
      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.primaryManager}
        value={data[Fields.primaryManager]}
        options={options[Fields.primaryManager]}
        label="Primary Manager"
        placeholder="Select"
        Size={[12, 12, 7, 12]}
        onChange={makeOnSelect(Fields.primaryManager)}
        selectAll={makeOnSelectAll(Fields.primaryManager)}
        selectedItems={selectedOptions[Fields.primaryManager]}
        multiSelect
        makeLabel={labelMaker(
          "Primary Manager",
          "Primary Manager",
          "Primary Manager",
          "Primary Manager"
        )}
      />
      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.secondaryManager}
        value={data[Fields.secondaryManager]}
        options={options[Fields.secondaryManager]}
        label="Secondary Manager"
        placeholder="Select"
        Size={[12, 12, 7, 12]}
        onChange={makeOnSelect(Fields.secondaryManager)}
        selectAll={makeOnSelectAll(Fields.secondaryManager)}
        selectedItems={selectedOptions[Fields.secondaryManager]}
        multiSelect
        makeLabel={labelMaker(
          "Secondary Manager",
          "Secondary Manager",
          "Secondary Manager",
          "Secondary Manager"
        )}
      />
      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.employeeClassification}
        value={data[Fields.employeeClassification]}
        options={options[Fields.employeeClassification]}
        label="Employee Classification"
        placeholder="Select"
        Size={[12, 12, 7, 12]}
        onChange={makeOnSelect(Fields.employeeClassification)}
        selectAll={makeOnSelectAll(Fields.employeeClassification)}
        selectedItems={selectedOptions[Fields.employeeClassification]}
        multiSelect
        makeLabel={labelMaker(
          "Employee Classification",
          "Employee Classification",
          "Employee Classification",
          "Employee Classification"
        )}
      />
      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.employeeType}
        value={data[Fields.employeeType]}
        options={options[Fields.employeeType]}
        label="Employee Type"
        placeholder="Select"
        Size={[12, 12, 7, 12]}
        onChange={makeOnSelect(Fields.employeeType)}
        selectAll={makeOnSelectAll(Fields.employeeType)}
        selectedItems={selectedOptions[Fields.employeeType]}
        multiSelect
        makeLabel={labelMaker(
          "Employee Type",
          "Employee Type",
          "Employee Type",
          "Employee Type"
        )}
      />
      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.employeePosition}
        value={data[Fields.employeePosition]}
        options={options[Fields.employeePosition]}
        label="Position"
        placeholder="Select"
        Size={[12, 12, 7, 12]}
        onChange={makeOnSelect(Fields.employeePosition)}
        selectAll={makeOnSelectAll(Fields.employeePosition)}
        selectedItems={selectedOptions[Fields.employeePosition]}
        multiSelect
        makeLabel={labelMaker("Position", "Position", "Position", "Position")}
      />
      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.payrollSchedule}
        value={data[Fields.payrollSchedule]}
        options={options[Fields.payrollSchedule]}
        placeholder="Select"
        label="Payroll Schedule"
        Size={[12, 12, 7, 12]}
        onChange={makeOnSelect(Fields.payrollSchedule)}
        selectAll={makeOnSelectAll(Fields.payrollSchedule)}
        selectedItems={selectedOptions[Fields.payrollSchedule]}
        multiSelect
        makeLabel={labelMaker(
          "Payroll Schedule",
          "Payroll Schedule",
          "Payroll Schedule",
          "Payroll Schedule"
        )}
      />
      <FormFieldGroup
        inputType="dropdown"
        inputName={Fields.payrollFrequency}
        value={data[Fields.payrollFrequency]}
        options={options[Fields.payrollFrequency]}
        label="Payroll Frequency"
        placeholder="Select"
        Size={[12, 12, 7, 12]}
        onChange={makeOnSelect(Fields.payrollFrequency)}
        selectAll={makeOnSelectAll(Fields.payrollFrequency)}
        selectedItems={selectedOptions[Fields.payrollFrequency]}
        multiSelect
        makeLabel={labelMaker(
          "Payroll Frequency",
          "Payroll Frequency",
          "Payroll Frequency",
          "Payroll Frequency"
        )}
      />
    </FormSection>
  );
};

export default JobDetails;
JobDetails.defaultProps = {
  onChange: () => {},
  data: {},
  options: {
    [Fields.primaryManager]: {},
    [Fields.secondaryManager]: {},
    [Fields.payrollSchedule]: {},
    [Fields.employeeType]: {}
  }
};
