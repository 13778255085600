import React from "react";
import { Modal } from "react-bootstrap";
import "./mapGLactivityhistorypage.css";
import Media from 'react-media';
import  classnames  from 'classnames/bind';
export const EMPLOYERPAIDEARNINGS = "EmployerPaidEarnings";
export const EMPLOYERPAIDEARNINGS_KEYLABEL = "EARNINGS"

export const EMPLOYEEPAIDDEDUCTIONS = "EmployeePaidDeductions";
export const EMPLOYEEPAIDDEDUCTIONS_KEYLABEL = "DEDUCTION"

export const EMPLOYERPAIDTAXES = "EmployerPaidTaxes";
export const EMPLOYERPAIDTAXES_KEYLABEL = "EmployerPaidTaxes";

export const EMPLOYEEPAIDTAXES = "EmployeePaidTaxes"
export const EMPLOYEEPAIDTAXES_KEYLABEL = "TAXES"

export const EMPLOYERPAIDDEDUCTIONS = "EmployerPaidDeductions"
export function getHeaderFromKey(nodeKey) {
  switch (nodeKey) {
    case EMPLOYERPAIDEARNINGS:
      return EMPLOYERPAIDEARNINGS_KEYLABEL;
    case EMPLOYEEPAIDDEDUCTIONS:
    case EMPLOYERPAIDDEDUCTIONS:
      return EMPLOYEEPAIDDEDUCTIONS_KEYLABEL;
    case EMPLOYERPAIDTAXES:
      return EMPLOYEEPAIDTAXES_KEYLABEL;
    case EMPLOYEEPAIDTAXES:
      return EMPLOYEEPAIDTAXES_KEYLABEL
    default:
      return ""
  }
}
const renderNode = (node, i, showValueType) => {
  if (node !== undefined && node !== null) {
    return (
      <div key={i}>
        {node.NodeType === "section" && <hr />}
        <div className="nc-activity-history-modal-row row">
          {node.NodeKey === "EmployerPaidEarnings" ? null : (
            <div className="col-lg-12 col-md-12 col-sm-12 table-header">
              <div>{node.NodeValues[0]}</div>
              <div className="table-header-name">{node.NodeValues[1]}</div>
              <div className="table-header-name-hr" />
            </div>
          )}
          <div className={classnames("list-page-grid-table",node.NodeKey === "EmployerPaidDeductions" ||
              node.NodeKey === "EmployerPaidTaxes"?"employer-paid-table":"employee-paid-table")}>
            <Media query="(min-width:992px)">
              <div className={classnames("col-lg-12 col-md-12 col-sm-12 column-header")}>
                <span className={classnames(node.NodeKey === "EmployerPaidDeductions" ||
              node.NodeKey === "EmployerPaidTaxes"?"col-lg-3 col-md-3 col-sm-12":"col-lg-6 col-md-6")}>{getHeaderFromKey(node.NodeKey)}</span>

                {[EMPLOYERPAIDDEDUCTIONS, EMPLOYERPAIDTAXES].includes(node.NodeKey) && (
                  <span className="col-lg-3 col-md-3 col-sm-12 second-column">
                    INCLUDE IN GL REPORT
                </span>
                )}

                {[EMPLOYERPAIDDEDUCTIONS, EMPLOYERPAIDTAXES].includes(node.NodeKey) && (
                  <span className="col-lg-3 col-md-3 col-sm-12">
                    EXPENSE ACCOUNT
                </span>
                )}

                {[EMPLOYERPAIDDEDUCTIONS, EMPLOYERPAIDTAXES].includes(node.NodeKey) ? (
                  <span className="col-lg-3 col-md-3 col-sm-12">
                    LIABILITY ACCOUNT
                </span>
                ) : (
                    <span className="col-lg-6 col-md-6 col-sm-7">
                      {node.NodeKey === "EmployeePaidDeductions" ||
                        node.NodeKey === "EmployeePaidTaxes"
                        ? "LIABILITY ACCOUNT"
                        : "EXPENSE ACCOUNT"}
                    </span>
                  )}
              </div>
            </Media>

            {node.NodeKey === "EmployerPaidDeductions" ||
              node.NodeKey === "EmployerPaidTaxes"
              ? node.Nodes.map(renderEmployerNode(node.NodeKey))
              : node.Nodes.map(renderEmployeeNode(node.NodeKey))}
          </div>
        </div>
      </div>
    );
  }
};

const renderEmployerNode = (nodeKey) => (node, i) => {
  if (node !== undefined && node !== null) {
    return (
      <div key={i} className="col-lg-12 col-md-12 col-sm-12 col-xs-12 employer-node-row table-row">
        <span className="employer-node-row-item col-lg-3 col-md-3 col-sm-12 col-xs-12"><span className="hidden-lg hidden-md" style={{ fontWeight: 600, marginRight: 10 }}>{getHeaderFromKey(nodeKey)}:</span>{node.NodeValues[0]}</span>
        <span className="employer-node-row-item col-lg-3 col-md-3 col-sm-12 col-xs-12 second-column">
        <span className="hidden-lg hidden-md" style={{ fontWeight: 600, marginRight: 10 }}>INCLUDE IN GL REPORT:</span>{node.NodeValues[1]}
        </span>
        <span className="employer-node-row-item col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <span className="hidden-lg hidden-md" style={{ fontWeight: 600, marginRight: 10 }}> EXPENSE ACCOUNT:</span>{node.NodeValues[2]}
        </span>
        <span className="employer-node-row-item col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <span className="hidden-lg hidden-md" style={{ fontWeight: 600, marginRight: 10 }}>LIABILITY ACCOUNT:</span> {node.NodeValues[3]}
        </span>
      </div>
    );
  }
};

const renderEmployeeNode =(nodeKey)=> (node, i) => {
  if (node !== undefined && node !== null) {
    return (
      <div key={i} className="col-lg-12 col-md-12 col-sm-12 col-xs-12 employee-node-row table-row">
        <span className="employee-node-row-item col-lg-6 col-md-6 col-sm-12 col-xs-12 other-column"><span className="hidden-lg hidden-md" style={{ fontWeight: 600, marginRight: 10 }}>{getHeaderFromKey(nodeKey)}:</span>{node.NodeValues[0]}</span>
        <span className="employee-node-row-item col-lg-6 col-md-6 col-sm-12 col-xs-12 other-column"><span className="hidden-lg hidden-md" style={{ fontWeight: 600, marginRight: 10 }}>{nodeKey === "EmployeePaidDeductions" ||
        nodeKey === "EmployeePaidTaxes"
                        ? "LIABILITY ACCOUNT"
                        : "EXPENSE ACCOUNT"}:</span>{node.NodeValues[1]}</span>
      </div>
    );
  }
};

const MapGLEntitySnapshotTable = ({ nodes = [] }) => {
  return <Modal.Body>{nodes.map(renderNode)}</Modal.Body>;
};

export default MapGLEntitySnapshotTable;
