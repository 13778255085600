import Loadable from "react-loadable";
import ChunkLoading from "app/common/UIComponents/ChunkLoading";

const PayrollDashboard = Loadable({
  loader: () =>
    import("./PayrollDashboard" /* webpackChunkName: "payroll-dashboard" */),
  loading: ChunkLoading
});
const UnapprovedPto = Loadable({
  loader: () =>
    import("./UnapprovedPto" /* webpackChunkName: "unapproved-pto" */),
  loading: ChunkLoading
});
const TimecardAlerts = Loadable({
  loader: () =>
    import("./TimecardAlerts" /* webpackChunkName: "timecard-alerts" */),
  loading: ChunkLoading
});
const EmployeeUpdates = Loadable({
  loader: () =>
    import("./EmployeeUpdates" /* webpackChunkName: "employee-updated" */),
  loading: ChunkLoading
});

export { PayrollDashboard, UnapprovedPto, TimecardAlerts, EmployeeUpdates };
