import React from "react";
import ActivityHistoryColumnTitle, {
  ORDER_ASC,
  ORDER_DESC
} from "../../activity-history/ActivityHistoryColumnTitle";

const TableHeader = ({
  columns,
  changeSortOrder,
  sortOrder: { Field, Order } = {}
}) => {
  return (
    <div className="row table-header hidden-sm hidden-xs">
      {columns.map(item => (
        <ActivityHistoryColumnTitle
          key={item.field}
          label={item.label}
          className={item.className}
          order={item.field === Field && Order}
          noSort={item.noSort}
          style={item.style}
          onClick={() => {
            if (item.noSort) {
              return;
            }
            if (item.field !== Field) {
              changeSortOrder(item.field, ORDER_ASC);
            } else {
              changeSortOrder(
                item.field,
                Order === ORDER_ASC
                  ? ORDER_DESC
                  : Order === ORDER_DESC
                    ? ORDER_ASC
                    : ""
              );
            }
          }}
        />
      ))}
    </div>
  );
};

export default TableHeader;
