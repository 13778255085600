export const SET_NOTES_DATA = "SET_NOTES_DATA";
export const CLEAR_NOTES_DATA = "CLEAR_NOTES_DATA";

const employeeNotes = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_NOTES_DATA:
      const { name, values } = payload;
      return { ...state, [name]: values };
    default:
      return state;
  }
};

export default employeeNotes;

export const setEmployeeNotesValues = (name, values) => ({
  type: SET_NOTES_DATA,
  payload: { name, values }
});

export const clearEmployeeNotesValues = () => ({
  type: CLEAR_NOTES_DATA
});
