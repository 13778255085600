import React from "react";
import "../svg.css";

const MinimumWageOff = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_15"
    x="0px"
    y="0px"
    viewBox="0 0 80.6 80.6"
    style={{ enableBackground: "new 0 0 80.6 80.6" }}
    width={width}
    height={height}
  >
    <g>
      <path
        className="st5"
        d="M40,34.8c0.3,0.3,0.7,0.3,1,0s0.3-0.7,0-1c-0.6-0.6-1.5-1-2.4-1.1V32c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7v0.7c-1.4,0.3-2.4,1.2-2.5,2.4c-0.1,0.7,0.2,1.4,0.6,1.9c0.5,0.6,1.1,1,1.9,1.2v2.9c-0.6-0.1-1.1-0.3-1.4-0.7c-0.3-0.3-0.7-0.3-1,0s-0.3,0.7,0,1c0.6,0.6,1.5,1,2.4,1.1v0.7c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7v-0.7c1.4-0.3,2.4-1.2,2.5-2.4c0.1-0.7-0.1-1.4-0.6-1.9c-0.5-0.6-1.1-0.9-1.9-1.1v-3C39.1,34.2,39.7,34.5,40,34.8z M39.7,40c0,0.4-0.5,0.8-1.1,1v-2.5c0.3,0.1,0.6,0.3,0.8,0.6S39.7,39.7,39.7,40z M37.1,34.3v2.5c-0.3-0.1-0.6-0.3-0.8-0.6c-0.2-0.3-0.3-0.6-0.3-0.9C36.1,34.9,36.5,34.5,37.1,34.3z"
      />
      <path
        className="st5"
        d="M17.1,49.6V26h41.5v12.4c0.6,0,1.2,0.1,1.8,0.2V25.9c0-0.9-0.7-1.6-1.6-1.6H17c-0.9,0-1.6,0.7-1.6,1.6v23.9c0,0.9,0.7,1.6,1.6,1.6h30.7c-0.1-0.6-0.2-1.2-0.2-1.8H17.1z"
      />{" "}
      <path
        className="st5"
        d="M20.9,42v-8.3c2-0.5,3.5-2,4.1-3.9h25.8c0.6,1.9,2.1,3.4,4.1,3.9V39c0.6-0.2,1.1-0.3,1.7-0.4v-6.3h-0.2c-2.3,0-4.1-1.8-4.1-3.9v-0.2H23.5v0.2c0,2.2-1.9,3.9-4.1,3.9h-0.2v11.2h0.2c2.3,0,4.1,1.8,4.1,3.9v0.2h24.2c0.1-0.6,0.2-1.1,0.4-1.7H25C24.4,44,22.8,42.5,20.9,42z"
      />
      <path
        className="st5"
        d="M58.4,40.5c-4.9,0-8.8,4-8.8,8.8s4,8.8,8.8,8.8s8.8-4,8.8-8.8S63.3,40.5,58.4,40.5z M58.4,56.4c-3.9,0-7-3.2-7-7s3.2-7,7-7s7,3.2,7,7S62.3,56.4,58.4,56.4z"
      />
      <path
        className="st5"
        d="M58.4,51.8c-0.3,0-0.6,0.1-0.8,0.4c-0.2,0.2-0.3,0.5-0.3,0.9c0,0.3,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.8,0.4s0.6-0.1,0.8-0.4c0.2-0.2,0.3-0.5,0.3-0.9s-0.1-0.6-0.3-0.9S58.7,51.8,58.4,51.8z"
      />
      <polygon
        className="st5"
        points="57.8,51 59,51 59.3,47 59.4,45.2 57.4,45.2 57.5,47 	"
      />
    </g>
    <g>
      <path
        className="st5"
        d="M40.3,80.6C18.1,80.6,0,62.5,0,40.3C0,18.1,18.1,0,40.3,0s40.3,18.1,40.3,40.3C80.6,62.5,62.5,80.6,40.3,80.6zM40.3,1.9C19.2,1.9,2,19.1,2,40.2s17.2,38.3,38.3,38.3s38.3-17.2,38.3-38.3S61.4,1.9,40.3,1.9z"
      />
    </g>
  </svg>
);

export default MinimumWageOff;
