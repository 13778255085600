import React from "react";
import SingleCheckBox from "component-library/checkBoxGroup/SingleCheckBox";
const FilterUnmappedOption = ({ onChange, show, value, name, unmappedId }) =>
  show ? (
    <div
      className="nc-filter-dropdown showOnlyUnmapped"
      style={{
        height: "32px",
        display: "flex",
        alignItems: "center",
        width: "auto"
      }}
    >
      <SingleCheckBox
        id={unmappedId}
        onChange={value => onChange(name, value)}
        value={value}
        label={"Show Only Unmapped"}
      />
    </div>
  ) : null;

export default FilterUnmappedOption;
