export const makeCriterion = (fieldName, value) => {
  if (value) {
    return {
      Field: fieldName,
      Operator: "in",
      value
    };
  } else {
    return null;
  }
};

export const makeOptions = filters => {
  const filterOptions = {};
  Object.keys(filters).forEach(k => (filterOptions[k] = filters[k].options));
  //console.log("utils", filters, filterOptions);
  return filterOptions;
};

export const makeSelectedOptions = filters => {
  const selectedOptions = {};
  Object.keys(filters).forEach(k => (selectedOptions[k] = filters[k].selected));
  return selectedOptions;
};

/**The function returns the filter count as a number for all the selected options of all the filters.
 * The count is used for the mobile view of the list page
 */
export function getFilterCount(selectedOptions = {}, options = {}) {
  let count = 0;
  const { category, status, entity } = selectedOptions;
  const c = options.category;
  const s = options.status;
  const e = options.entity;
  let c_count = 0;
  let s_count = 0;
  let e_count = 0;
  if (category && status && entity) {
    c.forEach(({ value }) => {
      if (typeof category[value] === "boolean" && category[value]) c_count++;
    });
    s.forEach(({ value }) => {
      if (typeof status[value] === "boolean" && status[value]) s_count++;
    });
    e.forEach(({ value }) => {
      if (typeof entity[value] === "boolean" && entity[value]) e_count++;
    });
    if (c.length !== c_count || c_count === 0) {
      count += c_count;
    }
    if (s.length !== s_count || s_count === 0) {
      count += s_count;
    }
    if (e.length !== e_count || e_count === 0) {
      count += e_count;
    }
  }
  return count;
}

export const getEmployeeMenuData = employeeMenu => {
  var newEmployeeMenu = [];
  var newEmployeeSubMenu = [];
  var newEmployeeSubSubMenu = [];

  employeeMenu.forEach(item => {
    if (item.ParentCustomFieldGroupId === 1) {
      var booleanValue = employeeMenu.filter(function(obj) {
        return item.CustomFieldGroupId === obj.ParentCustomFieldGroupId;
      });

      booleanValue.forEach(i => {
        newEmployeeSubSubMenu = employeeMenu.filter(function(obj) {
          return i.CustomFieldGroupId === obj.ParentCustomFieldGroupId;
        });

        newEmployeeSubMenu.push({
          CustomFieldGroupId: i.CustomFieldGroupId,
          CustomFieldGroupName: i.CustomFieldGroupName,
          ParentCustomFieldGroupId: i.ParentCustomFieldGroupId,
          SubSubItemList: newEmployeeSubSubMenu
        });
      });

      newEmployeeMenu.push({
        CustomFieldGroupId: item.CustomFieldGroupId,
        CustomFieldGroupName: item.CustomFieldGroupName,
        Discription: item.Discription,
        ImageUrl: item.ImageUrl,
        ParentCustomFieldGroupId: item.ParentCustomFieldGroupId,
        SubItemList: newEmployeeSubMenu
      });

      newEmployeeSubMenu = [];
      newEmployeeSubSubMenu = [];
    }
  });
  return newEmployeeMenu;
};
