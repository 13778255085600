import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  BasicInfo,
  IssuanceInfo,
  FooterSection
} from "component-library/employee-profile/resume/LicensesCertification";
import * as util from "app/common/utilities";
import * as FIELDS from "component-library/employee-profile/resume/utils/Constants";
import { Verification } from "component-library/employee-profile/resume/Components";
import {
  editEntityData,
  clearEntityData
} from "app/common/reducers/entityData";
import {
  clearEntityErrors,
  editEntityErrors
} from "app/common/reducers/entityErrors";
import Toaster from "component-library/toasters/index";
class AddLicensesCertification extends Component {
  state = {
    toasterMessage: "",
    showToaster: false,
    toasterClass: "",
    showCloseIcon: true
  };
  componentDidMount() {
    const { LicenseCertificationId } = this.props.match.params;
    const { resumeEntityData = {} } = this.props;
    if (
      !util.isUndefinedOrZero(LicenseCertificationId) &&
      Object.keys(resumeEntityData).length !== 0
    ) {
      this.props.fetchAndSetLicensesCertification(
        parseInt(LicenseCertificationId, 10),
        resumeEntityData
      );
    }

    this.props.loadStateList();
    this.props.loadCountryList();
    this.props.toggleAddOnMode(true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { LicenseCertificationId } = this.props.match.params;
    const prevLicenseCertificationId =
      prevProps.match.params.LicenseCertificationId;
    if (
      (prevLicenseCertificationId !== LicenseCertificationId ||
        this.props.resumeEntityData !== prevProps.resumeEntityData) &&
      Object.keys(this.props.resumeEntityData).length !== 0 &&
      !util.isUndefinedOrZero(LicenseCertificationId)
    ) {
      this.props.fetchAndSetLicensesCertification(
        parseInt(LicenseCertificationId, 10),
        this.props.resumeEntityData
      );
    }

    if (
      this.props.editMode &&
      this.props.data !== prevProps.data &&
      Object.keys(this.props.data).length > 0
    ) {
      if (this.props.data[FIELDS.IS_VERIFIED]) {
        let typeName = this.props.data.TypeName.toLowerCase();
        let toasterMessage = `You're updating a previously-verified ${typeName} . Please update the verification section if necessary.`;
        //this.props.setToaster(toasterMessage, "warning", false);
        this.setState({
          showToaster: true,
          toasterMessage: toasterMessage,
          toasterClass: "warning",
          showCloseIcon: false
        });
      }
    }
  }

  toggleToaster = () => {
    this.setState({
      showToaster: false
    });
  };

  onChange = e => {
    const { editData, editErrors, filters } = this.props;
    const edits = { [e.name]: e.value };
    const errors = { [e.name]: null };

    if (e.name === FIELDS.IS_VERIFIED) {
      edits[e.name] = e.value === "true" ? true : false;
    }
    if (e.name === FIELDS.BASIC_INFO_TYPE_Id) {
      edits[FIELDS.BASIC_INFO_ID] = null;
      edits[FIELDS.BASIC_INFO_NAME_OTHER] = null;
      edits[FIELDS.BASIC_INFO_NAME] = null;
      this.props.setTypeNameDropdown(e.value);
    }

    if (e.name === FIELDS.BASIC_INFO_ID) {
      var filterData = filters.licensesNames;
      var selectedOption = filterData.filter(item => item.id === e.value);
      edits[FIELDS.BASIC_INFO_NAME] = selectedOption[0].label;
    }

    editData(edits);
    editErrors(errors);
  };

  onAddLicenseCertification = () => {
    const {
      saveLicensCertificationRecordAndReload,
      setToaster,
      employeeName,
      data,
      onCancelClick
    } = this.props;
    saveLicensCertificationRecordAndReload(
      data,
      employeeName,
      toasterMessage => {
        setToaster(toasterMessage, "success");
        onCancelClick();
      }
    );
  };

  update = id => {
    const {
      updateLicensCertificationRecordAndReload,
      setToaster,
      employeeName,
      data,
      onCancelClick
    } = this.props;
    updateLicensCertificationRecordAndReload(
      id,
      data,
      employeeName,
      toasterMessage => {
        setToaster(toasterMessage, "success");
        onCancelClick();
      }
    );
  };

  componentWillUnmount() {
    this.props.clearData();
    this.props.clearErrors();
    this.props.onCancelClick();
    this.props.toggleToaster();
    this.props.toggleAddOnMode(false);
  }

  render() {
    const {
      data = {},
      errors = {},
      onCancelClick,
      editMode,
      stateList = [],
      countryList = [],
      filters
    } = this.props;

    let states = stateList.length > 0 ? stateList : [];
    return (
      <div className="tab-container-add-section">
        <div id="add-licenses" className="title-band-container">
          <div className="title-band">
            {editMode
              ? "Edit Licenses & Certification"
              : "Add Licenses & Certification"}
          </div>
          <Toaster
            id={"skillToaster"}
            show={this.state.showToaster}
            alertClassName={this.state.toasterClass}
            message={this.state.toasterMessage}
            closeToasterMessage={this.toggleToaster}
            showCloseIcon={this.state.showCloseIcon}
          />
          <BasicInfo
            getFromToValue={this.onChange}
            onChange={this.onChange}
            data={data}
            filters={filters}
            errors={errors}
          />
          <IssuanceInfo
            getFromToValue={this.onChange}
            stateList={states}
            countryList={countryList}
            onChange={this.onChange}
            data={data}
            errors={errors}
          />
          <Verification
            data={data}
            onChange={this.onChange}
            getFromToValue={this.onChange}
            errors={errors}
          />
          <FooterSection
            onCancelClick={onCancelClick}
            onAddClick={this.onAddLicenseCertification}
            editMode={editMode}
            update={this.update}
          />
        </div>
      </div>
    );
  }
}

const stateToProps = state => {
  const { entitySetup } = state.common;
  const { data, errors = {} } = entitySetup;
  return {
    data,
    errors,
    stateList: state.common.countryState.States,
    countryList: state.common.country.Countries
  };
};

const actionCreators = {
  editData: editEntityData,
  clearData: clearEntityData,
  clearErrors: clearEntityErrors,
  editErrors: editEntityErrors
};

export default withRouter(
  connect(stateToProps, actionCreators)(AddLicensesCertification)
);
