import React from "react";
import "../svg.css";

const MinimumWageOffInTitle = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 70 45.6"
    style={{ enableBackground: "new 0 0 70 45.6" }}
    width={width}
    height={height}
  >
    <g>
      <g>
        <path
          className="st5"
          d="M47.2,35.3H3.1V3.1h56.1v19.5c0.6,0,1.2,0,1.7,0.2V3.3c0-1-0.8-1.8-1.8-1.8H3.3c-1,0-1.8,0.8-1.8,1.8v31.9c0,1,0.8,1.8,1.8,1.8h44.1C47.3,36.5,47.2,35.9,47.2,35.3z"
        />
        <path
          className="st5"
          d="M13.7,30.3l0-0.1c-0.7-2.5-2.7-4.5-5.4-5.1l-0.1,0V13.4l0.1,0c2.6-0.6,4.7-2.6,5.4-5.1l0-0.1h35l0,0.1c0.7,2.5,2.7,4.5,5.4,5.1l0.1,0v10.3c0.5-0.2,1-0.4,1.6-0.6v-11c-3.1-0.1-5.6-2.5-5.7-5.5H12.3c-0.1,3-2.6,5.4-5.7,5.5v14.3c3.1,0.1,5.6,2.5,5.7,5.5h35.2c0.1-0.5,0.3-1,0.5-1.5L13.7,30.3L13.7,30.3z"
        />
      </g>
      <g>
        <circle className="st1" cx="59.1" cy="34.6" r="8.7" />
        <path
          className="st5"
          d="M59.1,43.9c-5.2,0-9.4-4.2-9.4-9.4s4.2-9.4,9.4-9.4c5.2,0,9.4,4.2,9.4,9.4S64.2,43.9,59.1,43.9z M59.1,26.6c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S63.5,26.6,59.1,26.6z"
        />
      </g>
      <g>
        <path
          className="st5"
          d="M57.8,38.6c0-0.4,0.1-0.7,0.4-1c0.2-0.2,0.6-0.4,0.9-0.4c0.4,0,0.7,0.1,0.9,0.4c0.2,0.2,0.4,0.6,0.4,1c0,0.4-0.1,0.7-0.4,1c-0.2,0.2-0.6,0.4-0.9,0.4c-0.4,0-0.7-0.1-0.9-0.4C57.9,39.3,57.8,39,57.8,38.6z M58.1,32L58,30.1h2.1L60,32l-0.3,4.4h-1.4L58.1,32z"
        />
      </g>
      <g>
        <path
          className="st5"
          d="M18,24.5c-2.2,0-4-2.2-4-4.9s1.8-4.9,4-4.9s4,2.2,4,4.9S20.2,24.5,18,24.5z M18,16.1c-1.4,0-2.6,1.6-2.6,3.5s1.2,3.5,2.6,3.5c1.4,0,2.6-1.6,2.6-3.5S19.4,16.1,18,16.1z"
        />
      </g>
      <g>
        <path
          className="st5"
          d="M45.4,24.5c-2.2,0-4-2.2-4-4.9s1.8-4.9,4-4.9s4,2.2,4,4.9S47.6,24.5,45.4,24.5z M45.4,16.1c-1.4,0-2.6,1.6-2.6,3.5s1.2,3.5,2.6,3.5c1.4,0,2.6-1.6,2.6-3.5S46.8,16.1,45.4,16.1z"
        />
      </g>
      <path
        className="st5"
        d="M32,18.5v-4.3c0.9,0.1,1.7,0.4,2.2,1c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1c-0.8-0.8-2-1.3-3.2-1.4c0,0,0,0-0.1,0v-1.1c0-0.4-0.3-0.8-0.8-0.8c-0.4,0-0.8,0.3-0.8,0.8v1.2c-1.8,0.3-3.2,1.5-3.4,3c-0.1,0.8,0.2,1.7,0.8,2.4c0.6,0.8,1.6,1.3,2.6,1.6V24c-0.9-0.1-1.7-0.4-2.2-1c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1c0.8,0.8,2,1.3,3.2,1.4c0,0,0,0,0.1,0v1.1c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-1.2c1.8-0.3,3.2-1.5,3.4-3c0.1-0.9-0.2-1.7-0.8-2.4C34,19.2,33.1,18.7,32,18.5z M30.4,18.2c-0.6-0.2-1.1-0.5-1.4-1c-0.4-0.4-0.5-0.9-0.5-1.3c0.1-0.8,0.8-1.4,1.9-1.6V18.2zM33.9,22.2C33.8,23,33,23.6,32,23.8V20c0.6,0.2,1.1,0.5,1.4,0.9C33.7,21.3,33.9,21.7,33.9,22.2z"
      />
    </g>
  </svg>
);

export default MinimumWageOffInTitle;
