import React, { Component } from "react";
import { FormSection } from "component-library/formSections";

class FooterButtonSection extends Component {
  render() {
    const {
      onCancelClick,
      onAddClick,
      primaryButtonLabel = "ADD",
      hr = true
    } = this.props;
    return (
      <FormSection hr={hr}>
        <div className="tab-container-add-sub-section">
          <div className="section-items">
            <div className="nc-button-group">
              <button
                type="button"
                onClick={onAddClick}
                className="nc-button primary-button"
              >
                {primaryButtonLabel}
              </button>

              <button
                type="button"
                onClick={onCancelClick}
                className="nc-button secondary-link-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </FormSection>
    );
  }
}

export default FooterButtonSection;
