import React from "react";
import getSvg from "./getSvg";
const NoEntityToSelect = ({ label, id }) => {
  return (
    <div className="no-entity-list-page">
      <div className="clearfix">{getSvg(id)}</div>
      <div className="header">You have no {label}.</div>
    </div>
  );
};

export default NoEntityToSelect;
