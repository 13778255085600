import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DeductionSetupDescription from "./DeductionSetupDescription";
import { Modal } from "react-bootstrap";
import SystemLevelDeductionDescriptionStep from "./SystemLevelDeductionDescriptionStep";
import { withRouter } from "react-router-dom";
import { showToaster } from "../../../../app/actions/AppActions";
import * as common from "../../../../app/common/index";
import axios from "axios";
import { setLoader } from "../../../../app/actions/LoaderActions";
import { setSystemDeductionData } from "../../actions/systemlevel/DeductionSetupListingActions";
import { getReviewandSaveDataList } from "../../actions/systemlevel/ReviewandSaveActions";
import EntitySnapshotTable from "../../../../component-library/entity-snapshot/EntitySnapshotTable";
import Toaster from "app/common/UIComponents/Toaster";

const SAVE_AND_FINISH_LATER = 2;
const SAVE_CHANGES = 5;

class DeductionReviewAndSavePage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isFormUpdated: false,
      showDeleteButtonModal: false,
      SystemDeductionId: this.props.systemDeductionData.SystemDeductionId,
      pageId: 6
    };

    this.onCloseDeleteButtonModal = this.onCloseDeleteButtonModal.bind(this);
    this.onOpenDeleteButtonModal = this.onOpenDeleteButtonModal.bind(this);
    this.redirectToSystemLevelDeductionDescription = this.redirectToSystemLevelDeductionDescription.bind(
      this
    );
    this.redirectToSystemLevelCalculationMethod = this.redirectToSystemLevelCalculationMethod.bind(
      this
    );
    this.redirectToSystemLevelDeductionCycle = this.redirectToSystemLevelDeductionCycle.bind(
      this
    );
    this.redirectToSystemLevelDefaultValue = this.redirectToSystemLevelDefaultValue.bind(
      this
    );
    this.redirectToSystemLevelDeductionOptions = this.redirectToSystemLevelDeductionOptions.bind(
      this
    );
    this.createDeductionandSave = this.createDeductionandSave.bind(this);
    this.deleteDeduction = this.deleteDeduction.bind(this);
    this.saveAndContinueReviewAndSave = this.saveAndContinueReviewAndSave.bind(
      this
    );
    this.redirectToListingPage = this.redirectToListingPage.bind(this);
    this.getDataById();
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.systemDeductionData !== this.props.systemDeductionData) {
      let activityInstanceId = 0;
      if (this.props.systemDeductionData.ActivityInstanceId !== undefined) {
        activityInstanceId = this.props.systemDeductionData.ActivityInstanceId;
      }
      this.props.getReviewandSaveDataList(activityInstanceId);

      this.setState({
        SystemDeductionId: this.props.systemDeductionData.SystemDeductionId,
        UiDraftCompletionStage: this.props.systemDeductionData
          .UiDraftCompletionStage
      });
    }
  }

  getDataById() {
    let self = this;
    let Id = this.props.match.params.SystemDeductionId;
    if (Id !== undefined) {
      // Id = window.atob(Id);
      const url = `${common.WEB_API_URL}/v1/system/deduction/${Id}`;
      axios
        .get(url)
        .then(function(response) {
          if (response.data.ErrorCount > 0) {
            response.data.Errors.forEach(item => {
              if (item.Tag === "") {
              }
            });
          } else {
            let deductionData = response.data.Data[0];
            self.props.setSystemDeductionData(deductionData);
          }
        })
        .catch(function(error) {});
    }
  }

  deleteDeduction() {
    var self = this;
    this.setState({
      showDeleteButtonModal: false
    });
    self.props.setLoader(true);
    const url = `${common.WEB_API_URL}/v1/system/deduction/${
      this.state.SystemDeductionId
    }`;
    axios
      .delete(url)
      .then(function(response) {
        self.props.setLoader(false);
        if (response.data.ErrorCount > 0) {
          response.data.Errors.forEach((item, index) => {
            if (index === 0) {
              alert(item.Messages);
            }
          });
        } else {
          self.props.showToaster(
            true,
            `'${
              self.props.systemDeductionData.SystemDeductionName
            }' has been deleted.`,
            "delete"
          );
          self.props.history.push("/system-level-deduction-setup-listing-page");
        }
      })
      .catch(function(error) {});
  }

  redirectToSystemLevelDeductionDescription() {
    this.props.history.push(
      `/system-level-deduction-description/${
        this.props.match.params.SystemDeductionId
      }`
    );
  }

  redirectToSystemLevelCalculationMethod() {
    this.props.history.push(
      `/system-level-calculation-method/${
        this.props.match.params.SystemDeductionId
      }`
    );
  }

  redirectToSystemLevelDeductionCycle() {
    this.props.history.push(
      `/system-level-deduction-cycle/${
        this.props.match.params.SystemDeductionId
      }`
    );
  }

  redirectToSystemLevelDefaultValue() {
    this.props.history.push(
      `/system-level-deafult-value/${this.props.match.params.SystemDeductionId}`
    );
  }

  redirectToSystemLevelDeductionOptions() {
    let self = this;
    let redirectURL = `/system-level-deduction-options/${
      this.props.match.params.SystemDeductionId
    }`;

    self.setState({ isFormUpdated: false });
    const dataUrl = `${common.WEB_API_URL}/v1/system/deduction/${
      self.state.SystemDeductionId
    }`;
    axios
      .get(dataUrl)
      .then(function(response) {
        if (response.data.ErrorCount > 0) {
          self.setState({ isFormUpdated: true });
          response.data.Errors.forEach((item, index) => {
            if (index === 0) {
              alert(item.Messages);
            }
          });
        } else {
          let deductionData = response.data.Data[0];
          self.props.setSystemDeductionData(deductionData);
          self.props.history.push(redirectURL);
        }
      })
      .catch(function(error) {});
  }

  redirectToListingPage() {
    this.props.history.push("/system-level-deduction-setup-listing-page");
  }

  createDeductionandSave() {
    let self = this;
    this.props.setLoader(true);
    let patchActions = [
      {
        Operation: "create",
        PathOrKey: "UiDraftCompletionStage",
        Value: self.state.pageId
      }
    ];
    const createUrl = `${common.WEB_API_URL}/v1/system/deduction/${
      self.props.systemDeductionData.SystemDeductionId
    }`;
    axios
      .patch(createUrl, patchActions)
      .then(function(response) {
        self.props.setLoader(false);
        self.props.showToaster(
          true,
          `'${
            self.props.systemDeductionData.SystemDeductionName
          }' has been added.`,
          "success"
        );
        self.props.history.push("/system-level-deduction-setup-listing-page");
      })
      .catch(function(error) {});
  }

  onCloseDeleteButtonModal() {
    this.setState({ showDeleteButtonModal: false });
  }

  onOpenDeleteButtonModal() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({ showDeleteButtonModal: true });
  }

  saveAndContinueReviewAndSave(actionType) {
    let redirectPageUrl = "";

    if (actionType === SAVE_CHANGES) {
      redirectPageUrl = "/system-level-deduction-setup-listing-page";
      if (this.state.UiDraftCompletionStage === 6) {
      } else {
        this.props.showToaster(
          true,
          `'${
            this.state.SystemDeductionName
          }' has been saved. We’ve marked this deduction as in progress for you to work on later.`,
          "inprogress"
        );
      }
    } else if (actionType === SAVE_AND_FINISH_LATER) {
      redirectPageUrl = "/system-level-deduction-setup-listing-page";
      this.props.showToaster(
        true,
        `'${
          this.props.systemDeductionData.SystemDeductionName
        }' has been saved. We’ve marked this deduction as in progress for you to work on later.`,
        "inprogress"
      );
    }
    this.props.history.push(redirectPageUrl);
  }

  onOpenFinishLaterButtonModal = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({ showFinishLaterModalWithMandatoryField: true });
  };

  onCloseFinishLaterModal = () => {
    this.setState({
      showFinishLaterModalWithMandatoryField: false
    });
  };

  onClickTrackYourProgress(navLink) {
    this.props.history.push(navLink);
  }

  navigateToUrl = navLink => {
    this.props.history.push(navLink);
  };
  closeToasterMessage = () => {
    this.props.showToaster(false, "");
  };
  componentWillUnmount() {
    if (this.props.systemDeductionData.UiDraftCompletionStage === 6) {
      this.props.showToaster(false, "");
    }
  }
  render() {
    let isReviewSaveEditPage = false;
    if (this.props.systemDeductionData.UiDraftCompletionStage === 6) {
      isReviewSaveEditPage = true;
    }
    let reviewandSaveData = this.props.reviewandSaveData;
    let nodes = [];
    if (
      reviewandSaveData.Data === null ||
      reviewandSaveData.Data === undefined ||
      reviewandSaveData.Data.length === 0
    ) {
      nodes = [];
    } else {
      nodes = reviewandSaveData.Data[0].CurrentValueSnapshot;
    }

    let navigationNode = [
      {
        id: "redirectToCompanyLevelDeductionDescription",
        nodeKey: "DeductionDescriptionSection",
        navigationUrl: `/system-level-deduction-description/${
          this.props.match.params.SystemDeductionId
        }`
      },
      {
        id: "redirectToCompanyLevelCalculationMethod",
        nodeKey: "CalculationMethodSection",
        navigationUrl: `/system-level-calculation-method/${
          this.props.match.params.SystemDeductionId
        }`
      },
      {
        id: "redirectToCompanyLevelDeductionCycle",
        nodeKey: "DeductionCycleSection",
        navigationUrl: `/system-level-deduction-cycle/${
          this.props.match.params.SystemDeductionId
        }`
      },
      {
        id: "redirectToCompanyLevelDefaultValue",
        nodeKey: "DefaultValuesSection",
        navigationUrl: `/system-level-deafult-value/${
          this.props.match.params.SystemDeductionId
        }`
      },
      {
        id: "redirectToCompanyLevelOptions",
        nodeKey: "OptionsSection",
        navigationUrl: `/system-level-deduction-options/${
          this.props.match.params.SystemDeductionId
        }`
      }
    ];
    return (
      <div className="nc-entity-page-container">
        <DeductionSetupDescription />
        <div id="toaster-message" className="toaster-container">
          <Toaster
            show={this.props.toaster.show}
            message={this.props.toaster.message}
            closeToasterMessage={this.closeToasterMessage}
            alertClassName="success"
          />
        </div>
        <div className="clearfix" />

        <div
          id="deduction-description-page-title1"
          className="title-band-container"
        >
          <div className="title-band">Deduction Setup</div>
        </div>

        <div
          id="deduction-track-progress"
          className="progress-tracker-container-mobile hidden-lg hidden-md"
        >
          <SystemLevelDeductionDescriptionStep
            pageId={this.state.pageId}
            onClickTrackYourProgress={(navLink, id) =>
              this.onClickTrackYourProgress(navLink)
            }
          />
        </div>

        <div
          id="deduction-description-page-title2"
          className="entity-page-content col-lg-9 col-md-8"
        >
          <div className="step-title-container clearfix">
            <span className="step-number badge badge-default">6</span>
            <div id="system-level-summary-page" className="step-title">
              {isReviewSaveEditPage ? "Summary" : "Review and Save"}
            </div>
          </div>

          <div
            id="deduction-review-and-save-page-description"
            style={{ marginBottom: "20px" }}
          >
            Let's review what you've set up so far.
          </div>

          <div className="nc-enity-snapshot-summary">
            <EntitySnapshotTable
              nodes={nodes}
              navigationNode={navigationNode}
              navigateToUrl={this.navigateToUrl}
            />
          </div>
        </div>

        <div
          id="deduction-track-progress"
          className="col-lg-3 col-md-4 hidden-sm hidden-xs"
          style={{ marginBottom: "20px" }}
        >
          <SystemLevelDeductionDescriptionStep
            pageId={this.state.pageId}
            onClickTrackYourProgress={(navLink, id) =>
              this.onClickTrackYourProgress(navLink)
            }
          />
        </div>
        <div className="footer-button-container col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <div className="seperator">
            <hr />
          </div>
          {isReviewSaveEditPage ? (
            <div className="nc-button-group">
              <button
                type="button"
                id="system-level-save-and-review-back-button"
                className="nc-button outline-button"
                onClick={this.redirectToListingPage}
              >
                <i className="fa fa-chevron-left"> </i> BACK TO DEDUCTION LIST
              </button>
            </div>
          ) : (
            <div className="nc-button-group">
              <button
                type="button"
                id="system-level-save-and-review-back-button"
                className="nc-button outline-button hidden-xs"
                onClick={this.redirectToSystemLevelDeductionOptions}
              >
                <i className="fa fa-chevron-left"> </i> BACK
              </button>

              <button
                type="button"
                id="deduction-create-deduction-button"
                className="nc-button primary-button"
                onClick={this.createDeductionandSave}
              >
                ADD DEDUCTION
              </button>

              <button
                type="button"
                id="system-level-save-and-review-back-button-mobile"
                className="nc-button outline-button visible-xs"
                onClick={this.redirectToSystemLevelDeductionOptions}
              >
                <i className="fa fa-chevron-left"> </i> BACK
              </button>

              <div className="nc-link-group-mobile">
                <button
                  type="button"
                  id="deduction-save-and-finish-later-buttonlink"
                  className="primary-link-button"
                  onClick={this.onOpenFinishLaterButtonModal.bind(this)}
                >
                  Finish Later
                </button>

                <button
                  type="button"
                  id="deduction-cancel-buttonlink"
                  className="secondary-link-button"
                  onClick={this.onOpenDeleteButtonModal}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="clearfix" />

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="delete-deduction-modal"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showDeleteButtonModal}
          onHide={this.onCloseDeleteButtonModal}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="delete-deduction-modal-close-icon"
                onClick={this.onCloseDeleteButtonModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="delete-deduction-modal-line1">
                Are you sure you want to delete this deduction?
              </div>
              <br />
              <div id="delete-deduction-modal-line2">
                It will be gone for now, but you can add it back if you need it.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="delete-deduction-modal-delete-button"
                className="nc-button secondary-button"
                onClick={this.deleteDeduction}
              >
                DELETE DEDUCTION
              </button>

              <button
                type="button"
                id="delete-deduction-modal-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseDeleteButtonModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="finish-later-modal-2"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showFinishLaterModalWithMandatoryField}
          onHide={this.onCloseFinishLaterModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="finish-later-modal-2-close-icon"
                onClick={this.onCloseFinishLaterModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="finish-later-modal-2-line1">
                Do you want to save your changes before exiting setup?
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="finish-later-modal-1-exit-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueReviewAndSave.bind(
                  this,
                  SAVE_AND_FINISH_LATER,
                  ""
                )}
              >
                SAVE AND EXIT
              </button>

              <button
                type="button"
                id="finish-later-modal-1-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseFinishLaterModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    systemDeductionData: state.systemDeductionData,
    reviewandSaveData: state.systemReviewandSaveReducer,
    toaster: state.toasterData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSystemDeductionData: setSystemDeductionData,
      showToaster: showToaster,
      setLoader: setLoader,
      getReviewandSaveDataList: getReviewandSaveDataList
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeductionReviewAndSavePage)
);
