import Loadable from "react-loadable";
import ChunkLoading from "app/common/UIComponents/ChunkLoading";

export const PayrollChecklist = Loadable({
  loader: () =>
    import("./pages/PayrollChecklist" /* webpackChunkName: "payrollchecklist" */).then(
      m => m
    ),
  loading: ChunkLoading
});
