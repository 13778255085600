export const FieldsId = {
  pageTitleId: "entity-title",
  addButtonId: "entity-add-button",
  activityHistoryButtonId: "entity-history-button",
  columnHeaderNameId: "entity-header-name",
  columnHeaderCategoryId: "entity-header-category",
  columnHeaderStatusId: "entity-header-status",
  filterEntitySearchBoxId: "entity-filter-search-box",
  filterCategorySearchBoxId: "entity-category-filter-search-box",
  filterAllCategoriesId: "all-categories-category",
  filterStatusSearchBoxId: "entity-status-filter-search-box",
  filterAllStatusesId: "all-status-entity",
  filterActiveStatusId: "active-status-entity",
  filterInactiveStatusId: "inactive-status-entity",
  filterInprogressStatusId: "inprogress-status-entity",
  gridEntityNameId: "entity-name",
  gridCategoryNameId: "entity-category",
  gridActiveStatusId: "entity-active",
  gridInactiveStatusId: "entity-inactive",
  gridInprogressStatusId: "entity-inprogress",
  copyEarningButtonId: "copy-earnings-button"
};
