import React, { Component } from "react";
import {
  withRouter
  //NavLink
} from "react-router-dom";

class MyDashboardPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    return (
      <div className="page-container">
        <div className="pageTitle">Dashboard</div>
        {/* <NavLink to="/payroll/utilities/holidaycalendar">
          {" "}
          Holiday Calendar |{" "}
        </NavLink>
        <NavLink to="/payroll-calendar"> Payroll Calendar | </NavLink>
        <NavLink to="/payroll/utilities"> Payroll Utilities | </NavLink>
        <NavLink to="/PayrollSchedules"> Payroll Schedules | </NavLink>
        <NavLink to="/PayrollChecklistConfig">
          {" "}
          Payroll Checklist Configuration |{" "}
        </NavLink>
        <NavLink to="/notifications"> Notifications | </NavLink>
        <NavLink to="/email-text-alerts"> Email Text Alerts | </NavLink>
        <NavLink to="/payroll/wage-and-tax-validation">
          {" "}
          Wage & Tax Validation |
        </NavLink>
        <NavLink to="/people/employees/list-view">People | </NavLink>
        <NavLink to="/payroll"> Payroll Dashboard </NavLink>
        {" | "}{" "}
        <NavLink to="/workplace-info-job-history">
          Workplace Info Job History
        </NavLink>
        {" | "}{" "}
        <NavLink to="/people/utilities/custom-fields">
          Custom Fields
        </NavLink> */}
      </div>
    );
  }
}

export default withRouter(MyDashboardPage);
