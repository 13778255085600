import axios from "axios";
import * as types from "./index";
import * as common from "../../../../app/common/index";

export function getCategoryList() {
  const url = `${common.WEB_API_URL}/v1/system/deductioncategory`;
  const request = axios.get(url);
  return {
    type: types.FETCH_CATEGORY_LIST,
    payload: request
  };
}

export function getStatusList() {
  const url = `${common.WEB_API_URL}/v1/app/ui/createworkflowstatus`;
  const request = axios.get(url);
  return {
    type: types.FETCH_STATUS_LIST,
    payload: request
  };
}

export function getDeductionList() {
  //const url=`${common.WEB_API_MOCK_URL}/v1/system/deduction?_mock_=success&datasize=10`;
  const url = `${common.WEB_API_URL}/v1/system/deduction?filter=ALL`;
  const request = axios.get(url);
  return {
    type: types.SYSTEM_DEDUCTION_MAIN_LIST,
    payload: request
  };
}


export function clearDeductionList() {
  let initialState = {
    data: {}
  };
  return {
    type: types.SYSTEM_DEDUCTION_MAIN_LIST,
    payload: initialState
  };
}

