export const SET_PAYROLL_DASHBOARD_ITEMS =
  "payroll/dashboard/SET_PAYROLL_DASHBOARD_ITEMS";
export const CLEAR_PAYROLL_DASHBOARD_ITEMS =
  "payroll/dashboard/CLEAR_PAYROLL_DASHBOARD_ITEMS";
export const ADD_TO_DASHBOARD_ITEMS =
  "payroll/dashboard/ADD_TO_PAYROLL_DASHBOARD_ITEMS";
const initialState = {
  loading: true,
  Data: [],
  DataCount: 0,
  NextUri: null
};

//Actions
export const setPayrollDashboardItems = (Data, DataCount, NextUri) => ({
  type: SET_PAYROLL_DASHBOARD_ITEMS,
  payload: { Data, DataCount, NextUri }
});
export const addToPayrollDashboardItems = (Data, DataCount, NextUri) => ({
  type: ADD_TO_DASHBOARD_ITEMS,
  payload: { Data, DataCount, NextUri }
});

export const clearPayrollDashboardItems = () => ({
  type: CLEAR_PAYROLL_DASHBOARD_ITEMS,
  payload: []
});

// Reducers
const payrollDashboardItems = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_PAYROLL_DASHBOARD_ITEMS:
      return initialState;
    case SET_PAYROLL_DASHBOARD_ITEMS: {
      const { Data, DataCount, NextUri } = payload;
      //const newData = [...state.Data, ...Data];
      return {
        ...state,
        NextUri,
        Data,
        DataCount: DataCount,
        loading: false
      };
    }
    case ADD_TO_DASHBOARD_ITEMS: {
      const { Data, DataCount, NextUri } = payload;
      const newData = [...state.Data, ...Data];
      return {
        ...state,
        NextUri,
        Data: newData,
        DataCount: DataCount,
        loading: false
      };
    }
    default:
      return state;
  }
};

export default payrollDashboardItems;
