import React from "react";

const Calculator = props => (
  <div id={props.id} className={props.className} style={{ float: "inherit" }}>
    <div className="icon">
      <i className="fa fa-calculator" />
    </div>
    <div
      className="content"
      style={{
        backgroundColor: props.parent === "reviewAndSave" ? "#e9e9e9" : "#FFF"
      }}
    >
      {
        <div>
          <div>
            <div id="formula">{props.formula}</div>
          </div>
        </div>
      }
    </div>
  </div>
);

export default Calculator;
