import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import App from "./app/App";
import { Helmet } from "react-helmet";
import AuthenticationPage from "./app/login/AuthenticationPage";
import LegacyToNew from "./app/login/LegacyToNew";
import DevLogin from "./app/login/DevLogin";
import MyDashboardPage from "./mydashboard/MyDashboardPage";
import RewriteHelloWorldPage from "./mydashboard/RewriteHelloWorldPage";
import PayrollRoutes from "./payroll/payrollRoutes";
import GeneralLedgerRoutes from "./payroll/payrollsetup/generalledger/GeneralLedgerRoutes";
import SystemLevelRoutes from "./payroll/payrollsetup/systemlevel/systemLevelRoutes";
import UserTestPayrollRoutes from "./payrolldashboard/UserTestPayrollRoutes";
//import HolidayCalendarRoutes from "./holidaycalendar/HolidayCalendarRoutes";
import PayrollDashboardRoutes from "./payrolldashboard/PayrollDashboardRoutes";
import PayrollCalendarRoutes from "./payroll-calendar/payrollCalendarRoutes";
import NotificationsRoutes from "./notifications/NotificationsRoutes";
import EmailTextAlertsRoutes from "./emailtextalerts/EmailTextAlertsRoutes";
import UserTestPayrollScheduleRoutes from "./payroll/payrollschedule/UserTestPayrollScheduleRoutes";
import PeopleRoutes from "./people/Routes";
let enableUserTesting = process.env.REACT_APP_ENABLE_USERTESTING === "true";
let enableDevMode =
  process.env.NODE_ENV === "development" ||
  window.location.origin === "https://dev-rewrite-ui.azurewebsites.net" ||
  window.location.origin === "https://staging-rewrite-ui.azurewebsites.net";

const DESCRIPTION =
  "Netchex delivers HR and Payroll services that are intuitive and empowering. Pair the best technology with personalized customer care for a true partnership.";

const RootRouter = () => (
  <BrowserRouter>
    <App>
      <div>
        <Helmet titleTemplate="%s | Netchexonline" defaultTitle="Netchexonline">
          <meta name="description" content={DESCRIPTION} />
        </Helmet>
        <Switch>
          <Route path="/authenticate" component={AuthenticationPage} />
          <Route path="/legacytonew" component={LegacyToNew} />
          {enableDevMode && <Route path="/devlogin" component={DevLogin} />}
          <Route path="/Dashboard" component={MyDashboardPage} />
          <Route
            path="/rewrite-hello-world"
            component={RewriteHelloWorldPage}
          />
        </Switch>
        <PayrollRoutes />
        <GeneralLedgerRoutes />
        <SystemLevelRoutes />
        {/* <HolidayCalendarRoutes /> */}

        <PayrollDashboardRoutes />
        {enableUserTesting && <UserTestPayrollRoutes />}
        <UserTestPayrollScheduleRoutes />
        <PayrollCalendarRoutes />
        <NotificationsRoutes />
        <EmailTextAlertsRoutes />
        <PeopleRoutes />
        {/* <EmployeeFilesRoutes /> */}
      </div>
    </App>
  </BrowserRouter>
);

export default RootRouter;
