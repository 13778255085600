import React from "react";
import Calculator from "./../misc/calculator";
import { makeId } from ".";

export const ShowSimpleValueItem = (item = null) => {
  if (item !== null)
    if (item.includes("#br#")) {
      let itemList = item.split("#br#");
      let itemsToDisplay = [];
      if (itemList.length > 0) {
        itemList.forEach((i, index) =>
          itemsToDisplay.push(
            <div>
              {i} <br />
            </div>
          )
        );
        return itemsToDisplay;
      }
    } else if (item.includes("#b#") && item.includes("#/b#")) {
      let htmlElementItem = item.replace("#b#", "");
      htmlElementItem = htmlElementItem.replace("#/b#", "");
      return <b>{htmlElementItem}</b>;
    } else {
      return item;
    }
};
const CalculationMethod = ({ NodeValues, Nodes, NodeKey }) => {
  if (
    NodeValues[1] === "Other" ||
    NodeValues[1] === "Dollar" ||
    NodeValues[1].includes("Other - ACA Deduction") ||
    NodeValues[1] === "PIECE RATE HOUR" ||
    NodeValues[1] === "DOLLAR AMOUNT" ||
    NodeValues[1] === "Piece Rate Hour" ||
    NodeValues[1] === "Dollar Amount" ||
    NodeValues[1] === "Hourly Rate"
  ) {
    return <div>{ShowSimpleValueItem(NodeValues[1])}</div>;
  }
  return (
    <Calculator
      id={makeId(NodeKey, 0)}
      formula={NodeValues[1]}
      parent="reviewAndSave"
      className="calculator clearfix"
    />
  );
};

export default CalculationMethod;
