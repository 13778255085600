export const DEMOGRAPHIC_HEADER = [
  "Demographics",
  "Profile",
  "Emergency Contacts",
  "Dependents/Beneficiaries"
];

export const dependentSampleList = [
  {
    Id: 1,
    EmployeeName: "Erick Mathers",
    EmployeeType: "Dependent",
    IsEmployerBeneficiary: true,
    EmployerRelationship: "Spouse",
    IsVerified: true
  },
  {
    Id: 2,
    EmployeeName: "John Mathers",
    EmployeeType: "Dependent",
    IsEmployerBeneficiary: false,
    EmployerRelationship: "Child",
    IsVerified: false
  }
];

export const interests = {
  activities: [
    { Id: 1, value: 1, label: "Basketball" },
    { Id: 2, value: 2, label: "Hockey" },
    { Id: 3, value: 3, label: "Softball" }
  ],
  hobbies: [
    { Id: 1, value: 1, label: "Painting" },
    { Id: 2, value: 2, label: "Singing" },
    { Id: 3, value: 3, label: "Dancing" }
  ],
  music: [
    { Id: 1, value: 1, label: "Pop" },
    { Id: 2, value: 2, label: "EDM" },
    { Id: 3, value: 3, label: "Rock" }
  ]
};
