import React from "react";
import { FormFieldGroup, FormSection } from "../../../formSections";
import IncludedInPayroll from "./IncludedInPayroll";
import { Element } from "react-scroll";
import * as Fields from "./FieldNames";
const labelClass = "col-lg-3 col-md-3";
const CurrentStatus = ({ status = "Active", data = {}, onChange }) => {
  return (
    <Element title="Current Status" className="element" id="1">
      <FormSection title="Current Status" bottomHr>
        <div style={{ fontSize: "20px", marginBottom: "20px" }}>
          This employee is:{" "}
          <span style={{ paddingLeft: "20px" }}>{status}</span>
        </div>
        <FormFieldGroup
          label="Seniority Date"
          fieldType="inline"
          inputName={Fields.seniorityDate}
          value={data[Fields.seniorityDate]}
          inputType="singleDatePicker"
          Size={[4, 4, 12, 12]}
          onChange={onChange}
          placeHolder="Select Date"
          getFromToValue={onChange}
          optional
          colSpan={labelClass}
        />
        <FormFieldGroup
          label="Service Time"
          fieldType="inline"
          inputType="Label"
          colSpan={labelClass}
          inputName={Fields.serviceTime}
          value="3 years, 2 months"
          onChange={onChange}
          //value={data[Fields.seniorityDate]}

          disabled
        />
        <IncludedInPayroll
          label="Should this employee be included in payroll ?"
          inputName={Fields.includedInPayroll}
          value={data[Fields.includedInPayroll]}
          data={data}
          onChange={onChange}
          middleText="From which paycheck date"
        />
      </FormSection>
    </Element>
  );
};

export default CurrentStatus;
