import React, { Component } from "react";
import FieldSectionName from "./FieldSectionName";
import ReorderSectionAndFields from "./ReorderSectionAndFields";
import FieldSectionTable from "./FieldSectionTable";
export default class CustomFieldSection extends Component {
  render() {
    const { item = [] } = this.props;
    return item.map(
      i =>
        i.CustomField !== null && (
          <div>
            <div className="custom-field-section col-lg-12 col-md-12 col-sm-12 col-xs-12">
              {console.log(i)}
              <FieldSectionName name={i.CustomFieldGroupName} />
              <ReorderSectionAndFields />
            </div>
            <FieldSectionTable data={i.CustomField} />
          </div>
        )
    );
  }
}
