import * as message from "../messages";
export function validateRequired(inputString) {
  if (inputString.length === 0) {
    return message.IS_REQUIRED;
  } else {
    return "";
  }
}

export function validateRequiredField(inputString, property, errorList) {
  if (
    inputString === undefined ||
    inputString === null ||
    inputString === "null" ||
    inputString === 0 ||
    inputString.length === 0
  ) {
    errorList[property] = message.IS_REQUIRED;
    return errorList;
  } else {
    return errorList;
  }
}

export function validateNumeric(inputString) {
  if (isNaN(inputString) || inputString.length === 0) {
    return message.SHOULD_BE_NUMBER;
  }
}

export function validateMaxCharacter(inputString, maxLength) {
  if (inputString.length > maxLength) {
    return `${maxLength} ${message.CHARACTER_MAX}`;
  } else {
    return "";
  }
}

export function truncketString(inputString, maxLength) {
  if (inputString.length > maxLength) {
    let tempString = inputString.slice(0, maxLength);
    tempString = tempString + "...";
    return tempString;
  } else {
    return inputString;
  }
}

export function truncketStringToCompleteWord(inputString, maxLength) {
  if (inputString.length >= maxLength) {
    let tempString = inputString.slice(0, 32);
    let lastIndex = tempString.lastIndexOf(" ");
    let finalString = tempString.slice(0, lastIndex);
    finalString = finalString + "...";
    return finalString;
  } else {
    return inputString;
  }
}
