import React from "react";
import SetupStepTitle from "./SetupStepTitle";
import * as DESCRIPTION from "./StepDescription";

const EarningsReviewandSave = props => {
  return (
    <div className="entity-page-content col-lg-9 col-md-8">
      <SetupStepTitle stepNumber={props.stepNumber} title={props.stepTitle} />
      <div className="step-description">{DESCRIPTION.REVIEW}</div>
      {props.displayHr && (
        <div className="seperator" style={{ marginTop: "20px" }}>
          <hr />
        </div>
      )}
      <div className="description-content review-and-save">
        {props.children}
      </div>
    </div>
  );
};
export default EarningsReviewandSave;
