import React from "react";
import { Switch, Route } from "react-router-dom";
import Employee from "./employee/base/pages";
import CustomFields from "./utilities/custom-fields/pages/CustomFields";

const Routes = () => (
  <Switch>
    <Route
      exact
      path="/people/utilities/custom-fields"
      component={CustomFields}
    />
    <Route path="/people" component={Employee} />
    <Route path="/people/employees" component={Employee} />
  </Switch>
);

export default Routes;
