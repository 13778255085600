import React from "react";
import EmployeeFilesListItem from "./EmployeeFilesListItem";

const EmployeeFilesListSection = ({
  data = [],
  onDeleteClick,
  onChange,
  onSaveClick,
  errors
}) => (
  <div className="list-page-grid-table col-lg-12 col-md-12 col-sm-12 col-xs-12">
    {data !== null &&
      data.map((row, i) => (
        <EmployeeFilesListItem
          key={i}
          {...row}
          onDeleteClick={onDeleteClick}
          onChange={onChange}
          onSaveClick={onSaveClick}
          errors={errors}
        />
      ))}
  </div>
);

export default EmployeeFilesListSection;
