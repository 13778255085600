const SET_LOGGED_USER = "people-employee-list/SET_LOGGED_USER";

export const initialState = {
  loading: true,
  Data: {},
  DataCount: 0
};

const loggedUser = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOGGED_USER:
      const { Data, DataCount } = payload;
      return {
        ...state,
        loading: false,
        Data: Data,
        DataCount: DataCount
      };
    default:
      return state;
  }
};

export default loggedUser;

export const addLoogedUSer = (Data, DataCount) => ({
  type: SET_LOGGED_USER,
  payload: { Data, DataCount }
});
