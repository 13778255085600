import React from "react";

const Textarea = props => (
  <div className={props.className}>
    <textarea
      id={props.inputId}
      rows={props.rows}
      //cols={props.cols}
      style={{
        height: props.height,
        resize: "none",
        width: "100%",
        backgroundColor: props.backgroundColor,
        border: props.border
      }}
      name={props.name}
      disabled={props.disabled}
      onChange={e =>
        props.onChange &&
        props.onChange({ name: props.name, value: e.target.value })
      }
      value={props.value !== undefined ? props.value : ""}
    >
      {props.value}
    </textarea>
    {props.error && props.required && (
      <div style={{ marginTop: "5px" }} className="has-error">
        {props.error}
      </div>
    )}
  </div>
);

export default Textarea;
