import React, { Component } from "react";
import UtilitiesHeader from "./UtilitiesHeader";
import UtilitiesContent from "./UtilitiesContent";
import { utilityItems, pageData } from "./Constants";

class PayrollUtilities extends Component {
  render() {
    const renderContent = Object.keys(utilityItems).map((k, i) => (
      <UtilitiesContent
        {...this.props}
        key={i}
        data={utilityItems[k]}
        title={k}
      />
    ));
    return (
      <div
        className="nc-entity-page-container"
        style={{ marginBottom: "10rem" }}
      >
        <UtilitiesHeader data={pageData} />
        {renderContent}
      </div>
    );
  }
}
export default PayrollUtilities;
