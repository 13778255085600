import React from "react";

const OptionsSearch = ({
  searchText,
  editSearchText,
  show,
  searchBoxId,
  onSearchClicked = () => {},
  showSearchButton = false
}) =>
  show ? (
    <div style={{ paddingBottom: 0 }} className="form-group">
      <div className="field">
        <input
          id={searchBoxId ? searchBoxId : "auto-suggestion-text-box"}
          type="text"
          name="autoSuggestionTextBox"
          className="form-control"
          value={searchText}
          placeholder="&#xF002; Search"
          onChange={e => editSearchText(e.target.value)}
          onFocus={e => (e.target.placeholder = "")}
          onBlur={e =>
            (e.target.placeholder = String.fromCharCode("0xF002") + " Search")
          }
        />
        {showSearchButton && (
          <button
            className="nc-button primary-button"
            style={{ float: "right", marginTop: "10px" }}
            onClick={onSearchClicked}
          >
            search
          </button>
        )}
      </div>
    </div>
  ) : null;

export default OptionsSearch;
