import React from "react";
import DropDown from "../../dropDown";
import "./AddNewNoteType.css";

const AddNewNoteType = props => (
  <div className="add-new-note" onClick={props.onAdd}>
    <DropDown {...props} />
    <span onClick={props.onAdd}>
      <i class="fa fa-plus icon" aria-hidden="true" />
      {props.linkLabel}
    </span>
  </div>
);
export default AddNewNoteType;
