/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const makeToastClassName = theme => {
  switch (theme) {
    case "danger":
      return "alert alert-danger clearfix";
    case "warning":
      return "alert alert-warning clearfix";
    case "info":
      return "alert alert-info clearfix";
    default:
      return "alert alert-success clearfix";
  }
};
const makeIconClassName = (theme, action) => {
  switch (action) {
    case "greatJob":
      return "fa fa-thumbs-o-up";
    case "info":
      return "fa fa-info-circle";
    case "delete":
      return "fa fa-trash";
    case "inprogress":
      return "fa fa-clock-o";
    case "inactivate":
      return "fa fa-minus-circle";
    case "warning":
      return "fa fa-exclamation-circle";
    default:
      if (theme === "danger") {
        return "";
      } else {
        return "fa fa-check";
      }
  }
};
const ListPageToaster = ({
  show,
  type,
  message,
  clearToast,
  theme,
  linkName = "Map it now",
  link,
  onLinkClick,
  action = "default",
  Id
}) =>
  show && (
    <div className="toaster-container">
      <div className="toaster">
        <div className={makeToastClassName(theme)} style={{ display: "flex" }}>
          <div id={Id} style={{ width: "100%", float: "left" }}>
            {" "}
            <i className={makeIconClassName(theme, action)} />
            {message}{" "}
            {link ? (
              <span id={linkName} onClick={onLinkClick}>
                <a className="active" style={{ whiteSpace: "nowrap" }}>
                  {" "}
                  {linkName}{" "}
                  {<i className="fa fa-caret-right" aria-hidden="true" />}
                </a>
              </span>
            ) : null}
          </div>
          <div
            className="pull-right"
            style={{ cursor: "pointer", marginLeft: "15px" }}
            onClick={clearToast}
          >
            <i className="fa fa-times" />
          </div>
        </div>
      </div>
    </div>
  );

export default ListPageToaster;
