import React from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import * as Fields from "./../FieldNames";
import IncludedInPayroll from "../active/IncludedInPayroll";
const RetirementDetails = ({ onChange, data = {} }) => {
  return (
    <FormSection title={"Retirement Details"} hr>
      <FormFieldGroup
        inputType="singleDatePicker"
        label="Retirement Date"
        fieldType="inline"
        inputName={Fields.retirementDate}
        value={data[Fields.retirementDate]}
        colSpan="col-lg-3 col-md-3"
        Size={[4, 4, 12, 12]}
        onChange={onChange}
        style={{ marginBottom: "15px" }}
        getToFromValue={onChange}
      />
      <IncludedInPayroll
        label="Should this employee be included in payroll?"
        colSpan="col-lg-3 col-md-3"
        inputName={Fields.retirementIncludedInPayroll}
        data={data}
        value={data[Fields.retirementIncludedInPayroll]}
        onChange={onChange}
        middleText="Until which paycheck date"
      />
      <FormFieldGroup
        inputType="textarea"
        inputName={Fields.retirementNotes}
        label="Notes"
        value={data[Fields.retirementNotes]}
        fieldType="inline"
        description="250 characters max"
        rows={6}
        colSpan="col-lg-3 col-md-3"
        Size={[8, 8, 12, 12]}
        onChange={onChange}
        getToFromValue={onChange}
        optional
      />
    </FormSection>
  );
};

export default RetirementDetails;
