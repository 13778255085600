import React from "react";
import FooterButtons from "../components/FooterButtons";

import {
  Benefits,
  InactivationDetails
} from "component-library/employee-profile/employment-info/change-status/inactive/index";
import {
  ScheduledDeductions,
  ReplacementManagers,
  ScheduledEarnings
} from "component-library/employee-profile/employment-info/change-status/common";
const Inactive = ({ data, onChange, onCloseOverlay, onPrimaryClick }) => {
  return (
    <div>
      <InactivationDetails onChange={onChange} data={data} />
      <Benefits onChange={onChange} data={data} />
      <ScheduledEarnings onChange={onChange} data={data} />
      <ScheduledDeductions onChange={onChange} data={data} />
      <ReplacementManagers onChange={onChange} data={data} />
      <FooterButtons
        buttonLabel="Inactivate Employee"
        onCancelClick={onCloseOverlay}
        onPrimaryClick={onPrimaryClick}
      />
    </div>
  );
};

export default Inactive;
