import React from "react";
const CreatePayrollSchedule = (onClick, disabled) => (
  <button
    id="create-payroll-schedule"
    onClick={onClick}
    className={
      disabled ? "nc-button disabled-button" : "nc-button primary-button"
    }
  >
    Create Payroll Schedule
  </button>
);
const UpdatePayrollSchedule = (onClick, disabled) => (
  <button
    id="update-payroll-schedule"
    className={
      disabled ? "nc-button disabled-button" : "nc-button primary-button"
    }
    onClick={onClick}
  >
    Update Payroll Schedule
  </button>
);
const Cancel = onClick => (
  <button
    id="cancel-button"
    className="secondary-link-button"
    onClick={onClick}
  >
    Cancel
  </button>
);
const PayrollScheduleButtons = ({
  isEditMode,
  disabled = false,
  cancel,
  createPayrollSchedule,
  updatePayrollSchedule
}) => (
  <div className="footer-button-container" style={{ clear: "both" }}>
    <div className="nc-button-group">
      {isEditMode
        ? UpdatePayrollSchedule(updatePayrollSchedule, disabled)
        : CreatePayrollSchedule(createPayrollSchedule, disabled)}
      <div className={"nc-link-group-mobile-single-button"}>
        {cancel && Cancel(cancel)}
      </div>
    </div>
  </div>
);

export default PayrollScheduleButtons;
