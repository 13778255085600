import * as payrollConstants from "component-library/payroll-dashboard/PayrollDashboardConstants";
import React from "react";
export function generatePayrollStageMessage(payrollData) {
  let message = "";
  if (payrollData) {
    const data = payrollData.Data;
    const notStarted = data.filter(function(notStarted) {
      return notStarted.PayrollStageId === payrollConstants.PAYROLL_NOT_STARTED;
    });

    const inProgress = data.filter(function(inProgress) {
      return inProgress.PayrollStageId === payrollConstants.PAYROLL_IN_PROGRESS;
    });

    const submitted = data.filter(function(submitted) {
      return submitted.PayrollStageId === payrollConstants.PAYROLL_SUBMITTED;
    });

    const pending = data.filter(function(pending) {
      return pending.PayrollStageId === payrollConstants.PAYROLL_PENDING;
    });

    if (pending.length > 0) {
      if (message) {
        message =
          message +
          ", " +
          getPayrollStageMessage(
            pending.length,
            payrollConstants.PAYROLL_PENDING
          );
      } else {
        message = getPayrollStageMessage(
          pending.length,
          payrollConstants.PAYROLL_PENDING
        );
      }
    }

    if (submitted.length > 0) {
      if (message) {
        message =
          message +
          ", " +
          getPayrollStageMessage(
            submitted.length,
            payrollConstants.PAYROLL_SUBMITTED
          );
      } else {
        message = getPayrollStageMessage(
          submitted.length,
          payrollConstants.PAYROLL_SUBMITTED
        );
      }
    }

    if (inProgress.length > 0) {
      if (message) {
        message =
          message +
          ", " +
          getPayrollStageMessage(
            inProgress.length,
            payrollConstants.PAYROLL_IN_PROGRESS
          );
      } else {
        message = getPayrollStageMessage(
          inProgress.length,
          payrollConstants.PAYROLL_IN_PROGRESS
        );
      }
    }

    if (notStarted.length > 0) {
      if (message) {
        message =
          message +
          ", " +
          getPayrollStageMessage(
            notStarted.length,
            payrollConstants.PAYROLL_NOT_STARTED
          );
      } else {
        message = getPayrollStageMessage(
          notStarted.length,
          payrollConstants.PAYROLL_NOT_STARTED
        );
      }
    }
    message = message.replace(/,(?=[^,]*$)/, " and");
    // var n = message.search("/*and/*");
    if (message === "") {
      message = "";
    } else {
      //var message = document.createElement("")
      message = (
        <span>
          <span style={{ fontWeight: "400" }}>You have&nbsp;{message}.</span>

          {/* <span>{message.substring(0, n - 1)} </span>
            <span style={{ fontWeight: "400" }}>
              {message.substring(n, n + 3)}&nbsp;
            </span>
            <span>{message.substring(n + 5, message.length)}</span>. */}
        </span>
      );
    }

    return message;
  } else {
    return message;
  }
}

export function getPayrollStageMessage(NumberOfPayroll, StageId) {
  switch (StageId) {
    case payrollConstants.PAYROLL_NOT_STARTED:
      if (NumberOfPayroll === 1)
        return `${NumberOfPayroll} payroll ready to be started`;
      else return `${NumberOfPayroll} payrolls ready to be started`;
    case payrollConstants.PAYROLL_IN_PROGRESS:
      if (NumberOfPayroll === 1)
        return `${NumberOfPayroll} payroll in progress`;
      else return `${NumberOfPayroll} payrolls in progress`;
    case payrollConstants.PAYROLL_SUBMITTED:
      if (NumberOfPayroll === 1) return `${NumberOfPayroll} payroll submitted`;
      else return `${NumberOfPayroll} payrolls submitted`;
    case payrollConstants.PAYROLL_PENDING:
      if (NumberOfPayroll === 1) return `${NumberOfPayroll} pending payroll`;
      else return `${NumberOfPayroll} pending payrolls`;
    default:
      return "";
  }
}

export function generatePayrollHeaderTab(payrollData) {
  let tabData = [];
  if (payrollData) {
    const data = payrollData.Data;
    const scheduledPayroll = data.filter(function(scheduledPayroll) {
      return scheduledPayroll.PayrollType === payrollConstants.SCHEDULE_PAYROLL;
    });
    // const scheduledPayroll = data;
    let scheduledPayrollItem = {
      label: "Scheduled Payrolls",
      value: payrollConstants.SCHEDULE_PAYROLL,
      numberOfPayroll: scheduledPayroll.length
    };
    tabData.push(scheduledPayrollItem);

    const unScheduledPayroll = data.filter(function(unScheduledPayroll) {
      return (
        unScheduledPayroll.PayrollType === payrollConstants.UNSCHEDULE_PAYROLL
      );
    });

    let unScheduledPayrollItem = {
      label: "Unscheduled Payrolls",
      value: payrollConstants.UNSCHEDULE_PAYROLL,
      numberOfPayroll: unScheduledPayroll.length
    };

    tabData.push(unScheduledPayrollItem);

    return tabData;
  } else {
    return tabData;
  }
}
