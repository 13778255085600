import React from "react";
import { Modal } from "react-bootstrap";

const ChangeInfoWarningModal = ({
  show,
  closeModal = () => {},
  onPrimaryActionHandler = () => {},
  data = {},
  primaryManagerChanged = false,
  secondaryManagerChanged = false
}) => (
  <Modal
    bsClass="list-page-delete modal"
    animation={false}
    backdrop={"static"}
    keyboard={false}
    show={show}
  >
    <Modal.Header>
      <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <i
          id="change-info-close-icon"
          onClick={closeModal}
          className="fa fa-times-circle-o fa-lg"
        />
      </div>
    </Modal.Header>
    <Modal.Body>
      <div className="modal-row text-center">
        {primaryManagerChanged &&
          secondaryManagerChanged && (
            <div className="modal-line-1">
              Warning: {data.PrimaryManager} is the primary manager for this
              employee.
              <div className="modal-line-2">
                {data.SecondaryManager} is the secondary manager for this
                employee.
              </div>
            </div>
          )}
        {primaryManagerChanged &&
          !secondaryManagerChanged && (
            <div className="modal-line-1">
              Warning: {data.PrimaryManager} is the primary manager for this
              employee.
            </div>
          )}
        {!primaryManagerChanged &&
          secondaryManagerChanged && (
            <div className="modal-line-1">
              Warning: {data.SecondaryManager} is the secondary manager for this
              employee.
            </div>
          )}
        <br />
        <div className="modal-line-3">
          By changing, this manager will lose access to this employee.
        </div>
        <br />
        <div className="modal-line-4">
          Do you want to continue with the change?
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <div className="button-group">
        <button
          type="button"
          id="change-info-warning-modal-cancel"
          className="nc-button secondary-button"
          onClick={onPrimaryActionHandler}
        >
          CHANGE WORKPLACE INFO
        </button>
        <button
          type="button"
          id="system-deduction-delete-modal-cancel"
          className="secondary-link-button"
          onClick={closeModal}
        >
          Continue Editing
        </button>
      </div>
    </Modal.Footer>
  </Modal>
);

export default ChangeInfoWarningModal;
