import React, { Component } from "react";
import { connect } from "react-redux";

class JobHistoryRouter extends Component {
  state = {};
  componentWillMount() {
    let JobId = this.props.match.params.JobId;
    this.props.history.push(`/view-job-history/${JobId}`);
  }
  render() {
    return <div />;
  }
}
const mapStateToProps = state => {
  return {};
};
const actionCreators = {};
export default connect(mapStateToProps, actionCreators)(JobHistoryRouter);
