import React from "react";
import ChecklistSection from "./../payroll-checklist";
import UpdateSection from "component-library/payroll-dashboard/payroll-update-section";
const SummaryFooter = props => {
  if (props.PayrollStageId === 1) {
    return <ChecklistSection {...props} />;
  } else {
    return <UpdateSection {...props} />;
  }
};
export default SummaryFooter;
