export const ACTIVE_FOOTNOTE =
  " Please Note Rehiring this employee will inactivate all direct deposit accounts, benefit plans, scheduled earnings, and scheduled deductions - excluding those deductions setup as automatically applied to newly-hired employees";
export const DECEASED_FOOTNOTE =
  "Please note: Changing this employee to deceased will disable manager self-service access and will inactivate all benefits plans.";
export const RETIRED_FOOTNOTE =
  "Please note: Retiring this employee will disable manager self-service access.";
export const TRANSFER_FOOTNOTE =
  "Please note: Transferring this employee will disable manager self-service access and will inactivate all benefits plans.";
export const RETERMINATE_FOOTNOTE =
  "Please note: Re-terminating this employee will disable manager self-service access and will inactivate all benefits plans.";
export const TERMINATE_FOOTNOTE =
  "Please note: Terminating this employee will disable manager self-service access and will inactivate all benefits plans.";
