export const pageTitle = "list-page-title";
export const pageDescription = "list-page-description";
export const backToPageLink = "list-page-back-to-page-link";
/**Buttons */
export const historyButton = "list-page-history-button";
export const addButton = "list-page-add-button";
export const addButtonLegacy = "list-page-add-button-legacy";
export const copyButton = "list-page-copy-button";
/**Table Headers */
export const nameColumnHeader = "list-page-col1-header";
export const categoryColumnHeader = "list-page-col2-header";
export const statusColumnHeader = "list-page-col3-header";
export const calculationMethodColumnHeader = "list-page-col4-header";
/**Toaster */
export const listPageToaster = "list-page-toaster";
/**Table Items */
export const nameColumnItem = "list-page-col1-";
export const categoryColumnItem = "list-page-col2-";
export const statusColumnItem = "list-page-col3-";
export const calculationMethodColumnItem = "list-page-col4-";
export const reactiveItemButton = "list-page-reactivate-";
/**Entity Item Icons */
export const editItemIcon = "list-page-edit-icon-";
export const editItemLegacyIcon = "list-page-edit-legacy-icon-";
export const deleteItemIcon = "list-page-delete-icon-";
export const lockItemIcon = "list-page-lock-icon-";
export const dragItemIcon = "list-page-drag-icon-";
/**Tooltips */
export const editTooltip = "tooltip_edit_icon";
export const editTooltipLegacy = "tooltip_edit_legacy_icon";
export const deleteTooltip = "tooltip_thrash_icon";
/**Category Tooltips */
export const garnishmentTooltip = "tooltip_garnishment_category_column";
export const insuranceTooltip = "tooltip_insurance_category_column";
export const retirementTooltip = "tooltip_retirement_category_column";
export const retirementLoanTooltip = "tooltip_retirement_loan_category_column";
export const otherTooltip = "tooltip_other_category_column";
export const standardTooltip = "tooltip_standard_category_column";
export const fringeTooltip = "tooltip_fringe_category_column";
export const leaveTooltip = "tooltip_leave_category_column";
export const reimbursementsTooltip = "tooltip_reimbursements_category_column";

//Status
export const activeStatusTooltip = "tooltip_active_status_icon";
export const inactiveStatusTooltip = "tooltip_inactive_status_icon";
export const inProgressStatusTooltip = "tooltip_in_progress_status_icon";
/**Result Count on desktop */
export const listCount = "result-count";
export const loadingCount = "loading-table-count";
/**Filters and Sort  */
export const sortButtonMobile = "list-page-sort-button-mobile";
export const filterButtonMobile = "list-page-filter-button-mobile";
export const filterCountMobile = "list-page-filter-count-mobile";
export const filterApplyMobile = "list-page-filter-apply-mobile";
//Filter Dropdowns
export const entityFilter = "dd-list-page-filter-1";
export const categoryFilter = "dd-list-page-filter-2";
export const statusFilter = "dd-list-page-filter-3";
export const dateFilter = "dd-list-page-filter-4";
/**List Page Modals */
export const modalMessage = "list-page-modal-message";
export const modalCloseIcon = "list-page-modal-close-icon";
export const modalOkButton = "list-page-modal-ok-button";
export const modalSuccessButton = "list-page-modal-success-button";
export const modalCancelButton = "list-page-modal-cancel-button";
//list page accordions
export const accordion = "list-page-accordion";
export const accordionIcon = "list-page-accordion-icon";
export const accordionTitle = "list-page-accordion-title";
export const accordionItemName = "list-page-accordion-item-name-";
export const accordionItemEdit = "list-page-accordion-item-edit-";
export const accordionItemToggle = "list-page-accordion-item-toggle-";
export const accordionItemSetup = "list-page-accordion-item-setup-";
export const accordionDelete = "list-page-accordion-delete-";
