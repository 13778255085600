/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Checkbox } from "react-checkbox-group";
import { Modal } from "react-bootstrap";
import SystemLevelDeductionDescriptionStep from "./SystemLevelDeductionDescriptionStep";
import { withRouter, Prompt } from "react-router-dom";
import * as utilities from "../../../../app/common/utilities";
import { setLoader } from "../../../../app/actions/LoaderActions";
import { setSystemDeductionData } from "../../actions/systemlevel/DeductionSetupListingActions";
import { getPayrollDeductionRadioButtonList } from "../../actions/systemlevel/SystemLevelDeductionCycleActions";
import { getScheduledPayrollsWitheldDeductionOtherRadioButtonList } from "../../actions/systemlevel/SystemLevelDeductionCycleActions";
import { getScheduledPayrollsWitheldDeductionScheduledPayrollRadioButtonList } from "../../actions/systemlevel/SystemLevelDeductionCycleActions";

import DeductionSetupDescription from "./DeductionSetupDescription";
import RadioButton from "component-library/radioButton/BasicRadioButton";

import * as systemDeductionCycleConstantId from "../../../../app/common/SystemConstants";

import * as validator from "../../../../app/common/validations";
import {
  setGlobalNavClick,
  CallbackToQuickLink,
  setFormUpdate,
  isOverlayClickable
} from "../../../../app/actions/FormUpdateAction";

import * as common from "../../../../app/common/index";
import Toaster from "../../../../app/common/UIComponents/Toaster";
import * as message from "../../../../app/common/messages";
import { showToaster } from "../../../../app/actions/AppActions";
import axios from "axios";

let isInDidUpdate = false;

const SAVE_AND_CONTINUE = 1;
const SAVE_AND_FINISH_LATER = 2;
const SAVE_TO_SUMMERY_PAGE = 3;
const SAVE_AND_BACK_PAGE = 4;
const SAVE_CHANGES = 5;
const TRACK_YOUR_PROGRESS = 6;
const GLOBAL_NAV = 7;
var AdditionalChecksList = [
  {
    id: "additional-Checks-yes-radio",
    name: "ApplyToAdditionalChecksInTheSamePayroll",
    value: true,
    label: "Yes"
  },
  {
    id: "additional-Checks-no-radio",
    name: "ApplyToAdditionalChecksInTheSamePayroll",
    value: false,
    label: "No"
  }
];

class DeductionCyclePage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showBackCancelModal: false,
      isFormUpdated: false,
      searchable: false,

      DeductionCycleName: this.props.systemDeductionData.DeductionCycleName,
      DeductionSubCycleName: this.props.systemDeductionData
        .DeductionSubCycleName,
      DeductionCategoryId: this.props.systemDeductionData.DeductionCategoryId,
      DeductionCalculationBasisId: this.props.systemDeductionData
        .DeductionCalculationBasisId,

      IncludeInFirstScheduledPayroll: this.props.systemDeductionData
        .IncludeInFirstScheduledPayroll,
      IncludeInSecondScheduledPayroll: this.props.systemDeductionData
        .IncludeInSecondScheduledPayroll,
      IncludeInThirdScheduledPayroll: this.props.systemDeductionData
        .IncludeInThirdScheduledPayroll,
      IncludeInFourthScheduledPayroll: this.props.systemDeductionData
        .IncludeInFourthScheduledPayroll,
      IncludeInFifthScheduledPayroll: this.props.systemDeductionData
        .IncludeInFifthScheduledPayroll,
      SystemDeductionId: this.props.systemDeductionData.SystemDeductionId,

      DeductionCycleId: this.props.systemDeductionData.DeductionCycleId,
      DeductionSubCycleId: this.props.systemDeductionData.DeductionSubCycleId,
      DeductionSubCycleId_Scheduled: this.props.systemDeductionData
        .DeductionSubCycleId,
      DeductionSubCycleId_Other: this.props.systemDeductionData
        .DeductionSubCycleId,
      ApplyToAdditionalChecksInTheSamePayroll: this.props.systemDeductionData
        .ApplyToAdditionalChecksInTheSamePayroll,

      openDeductionComeOut: false,
      openDeductionWithheldOthre: false,

      openDeductionWithheldInfo: false,
      openAdditionalPaychecksInfo: false,
      openTrackYourProgressModal: false,
      openTrackYourProgressModal2: false,
      showFinishLaterModalWithoutMandatoryField: false,
      showFinishLaterModalWithMandatoryField: false,

      showCancelModal: false,
      openBackButtonModal: false,
      openDeleteButtonModal: false,
      closeLeavingModal: false,

      openPayRollRunMonth: false,

      selectedPayRollRunMonth: [],
      pageId: 3,
      errors: {},
      payRollRunMonthList: [
        { id: 1, name: "payRollRunMonth", value: 1, text: "1" },
        { id: 2, name: "payRollRunMonth", value: 2, text: "2" },
        { id: 3, name: "payRollRunMonth", value: 3, text: "3" },
        { id: 4, name: "payRollRunMonth", value: 4, text: "4" },
        { id: 5, name: "payRollRunMonth", value: 5, text: "5" }
      ],
      showToaster: false,
      messageToaster: "",
      modalNavLink: ""
    };

    this.close = this.close.bind(this);
    this.onOpenTrackYourProgressModal = this.onOpenTrackYourProgressModal.bind(
      this
    );
    this.onCloseTrackYourProgressModal = this.onCloseTrackYourProgressModal.bind(
      this
    );
    this.onOpenDeleteButtonModal = this.onOpenDeleteButtonModal.bind(this);
    this.onCloseDeleteButtonModal = this.onCloseDeleteButtonModal.bind(this);
    this.onOpenBackButtonModal = this.onOpenBackButtonModal.bind(this);
    this.onCloseBackButtonModal = this.onCloseBackButtonModal.bind(this);
    //this.showBackButtonMessage = this.showBackButtonMessage.bind(this);
    //this.showCancelButtonMessage = this.showCancelButtonMessage.bind(this);
    this.leavingCancelModal = this.leavingCancelModal.bind(this);
    this.redirectToCompanyLevelCalculationMethod = this.redirectToCompanyLevelCalculationMethod.bind(
      this
    );
    this.redirectToSummarygPage = this.redirectToSummarygPage.bind(this);
    this.showDeductionComeOut = this.showDeductionComeOut.bind(this);
    this.showPayRollRunMonth = this.showPayRollRunMonth.bind(this);
    this.showDeductionWithheldInfo = this.showDeductionWithheldInfo.bind(this);
    this.showAdditionalPaychecksInfo = this.showAdditionalPaychecksInfo.bind(
      this
    );
    this.onChangePayRollRunMonth = this.onChangePayRollRunMonth.bind(this);
    this.onChangeDeductionComeOut = this.onChangeDeductionComeOut.bind(this);
    this.onChangeDeductionScheduledpayroll = this.onChangeDeductionScheduledpayroll.bind(
      this
    );
    this.onChangeDeductionComeOut_other = this.onChangeDeductionComeOut_other.bind(
      this
    );
    this.onChangeDeductionAdditionalChecks = this.onChangeDeductionAdditionalChecks.bind(
      this
    );
    this.renderDeductionComeOutList = this.renderDeductionComeOutList.bind(
      this
    );
    this.renderDeductionWithheldList = this.renderDeductionWithheldList.bind(
      this
    );
    this.renderAdditionalChecksList = this.renderAdditionalChecksList.bind(
      this
    );
    this.renderDeductionWithheldOthreList = this.renderDeductionWithheldOthreList.bind(
      this
    );
    this.renderPayRollRunMonthItem = this.renderPayRollRunMonthItem.bind(this);
    this.renderPayRollRunMonthItem_List = this.renderPayRollRunMonthItem_List.bind(
      this
    );
    this.checkPayRollRunMonth = this.checkPayRollRunMonth.bind(this);
    this.saveAndContinueDeductionCycle = this.saveAndContinueDeductionCycle.bind(
      this
    );
    this.handleChangeIncludeInFirstScheduledPayroll = this.handleChangeIncludeInFirstScheduledPayroll.bind(
      this
    );
    this.handleChangeIncludeInSecondScheduledPayroll = this.handleChangeIncludeInSecondScheduledPayroll.bind(
      this
    );
    this.handleChangeIncludeInThirdScheduledPayroll = this.handleChangeIncludeInThirdScheduledPayroll.bind(
      this
    );
    this.handleChangeIncludeInFourthScheduledPayroll = this.handleChangeIncludeInFourthScheduledPayroll.bind(
      this
    );
    this.handleChangeIncludeInFifthScheduledPayroll = this.handleChangeIncludeInFifthScheduledPayroll.bind(
      this
    );
    this.deleteDeduction = this.deleteDeduction.bind(this);
    this.deleteDeductionForGlobalNav = this.deleteDeductionForGlobalNav.bind(
      this
    );
    this.checkIsPageBack = this.checkIsPageBack.bind(this);

    this.props.getPayrollDeductionRadioButtonList();
    this.props.getScheduledPayrollsWitheldDeductionOtherRadioButtonList();
    this.props.getScheduledPayrollsWitheldDeductionScheduledPayrollRadioButtonList();
  }

  componentWillMount() {
    this.checkIsPageBack();
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.getDataById();
  }

  componentWillUnmount() {
    this.props.setFormUpdate(false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.systemDeductionData !== this.props.systemDeductionData) {
      isInDidUpdate = true;

      if (this.state.DeductionCycleId !== 0) {
        let event = {
          name: "DeductionCycleId",
          value: String(this.props.systemDeductionData.DeductionCycleId)
        };
        this.onChangeDeductionComeOut(event);

        let subEvent = {
          name: "DeductionSubCycleId",
          value: String(this.props.systemDeductionData.DeductionSubCycleId)
        };

        this.onChangeDeductionScheduledpayroll(subEvent);
      }

      if (
        String(this.state.DeductionCategoryId) ===
          String(
            systemDeductionCycleConstantId.DEDUCTION_DESCRIPTION_RETIREMENT_ID
          ) ||
        String(this.state.DeductionCalculationBasisId) ===
          String(
            systemDeductionCycleConstantId.CALCULATION_METHOD_HOUR_BASED_ID
          ) ||
        String(this.state.DeductionCalculationBasisId) ===
          String(systemDeductionCycleConstantId.CALCULATION_METHOD_PERCENT_ID)
      ) {
        this.setState({
          ApplyToAdditionalChecksInTheSamePayroll: true
        });
      } else {
        this.setState({
          ApplyToAdditionalChecksInTheSamePayroll: false
        });
      }

      if (
        this.state.ApplyToAdditionalChecksInTheSamePayroll !== undefined &&
        this.state.ApplyToAdditionalChecksInTheSamePayroll !== null
      ) {
        let checkEvent = {
          name: "ApplyToAdditionalChecksInTheSamePayroll",
          value: this.props.systemDeductionData
            .ApplyToAdditionalChecksInTheSamePayroll
        };

        this.onChangeDeductionAdditionalChecks(checkEvent);
      }
      isInDidUpdate = false;
    }
    if (
      prevProps.globalNavClick === null &&
      this.props.globalNavClick !== null
    ) {
      if (this.props.globalNavClick.isLinkClick) {
        this.setState({
          showBackCancelModal: true,
          IsFormUpdated: false
        });
        this.props.setFormUpdate(false);
      }
    } else {
      if (
        prevProps.globalNavClick !== null &&
        this.props.globalNavClick !== null
      ) {
        if (
          prevProps.globalNavClick.isLinkClick !==
          this.props.globalNavClick.isLinkClick
        ) {
          if (this.props.globalNavClick.isLinkClick) {
            this.setState({
              showBackCancelModal: true,
              IsFormUpdated: false
            });
            this.props.setFormUpdate(false);
          }
        }
      }
    }
    // this.checkIsPageBack();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.systemDeductionData !== this.props.systemDeductionData) {
      this.setState({
        DeductionCycleName: nextProps.systemDeductionData.DeductionCycleName,
        DeductionSubCycleName:
          nextProps.systemDeductionData.DeductionSubCycleName,
        DeductionCategoryId: nextProps.systemDeductionData.DeductionCategoryId,
        DeductionCalculationBasisId:
          nextProps.systemDeductionData.DeductionCalculationBasisId,

        IncludeInFirstScheduledPayroll:
          nextProps.systemDeductionData.IncludeInFirstScheduledPayroll,
        IncludeInSecondScheduledPayroll:
          nextProps.systemDeductionData.IncludeInSecondScheduledPayroll,
        IncludeInThirdScheduledPayroll:
          nextProps.systemDeductionData.IncludeInThirdScheduledPayroll,
        IncludeInFourthScheduledPayroll:
          nextProps.systemDeductionData.IncludeInFourthScheduledPayroll,
        IncludeInFifthScheduledPayroll:
          nextProps.systemDeductionData.IncludeInFifthScheduledPayroll,
        SystemDeductionId: nextProps.systemDeductionData.SystemDeductionId,

        DeductionCycleId: nextProps.systemDeductionData.DeductionCycleId,
        DeductionSubCycleId: nextProps.systemDeductionData.DeductionSubCycleId,
        DeductionSubCycleId_Scheduled:
          nextProps.systemDeductionData.DeductionSubCycleId,
        DeductionSubCycleId_Other:
          nextProps.systemDeductionData.DeductionSubCycleId,
        ApplyToAdditionalChecksInTheSamePayroll:
          nextProps.systemDeductionData.ApplyToAdditionalChecksInTheSamePayroll
      });
    }
  }

  getDataById() {
    let self = this;
    let Id = this.props.match.params.SystemDeductionId;
    if (Id !== undefined) {
      // Id = window.atob(Id);
      const url = `${common.WEB_API_URL}/v1/system/deduction/${Id}`;
      axios
        .get(url)
        .then(function(response) {
          if (response.data.ErrorCount > 0) {
            response.data.Errors.forEach(item => {
              if (item.Tag === "") {
              }
            });
          } else {
            let deductionData = response.data.Data[0];
            self.props.setSystemDeductionData(deductionData);
          }
        })
        .catch(function(error) {});
    }
  }

  checkIsPageBack() {
    if (this.state.isFormUpdated === false) {
    } else {
    }
  }

  redirectToCompanyLevelCalculationMethod() {
    let self = this;
    let redirectURL = `/system-level-calculation-method/${
      this.props.match.params.SystemDeductionId
    }`;
    self.setState({ isFormUpdated: false });
    this.props.setFormUpdate(true);
    const dataUrl = `${common.WEB_API_URL}/v1/system/deduction/${
      self.state.SystemDeductionId
    }`;
    axios
      .get(dataUrl)
      .then(function(response) {
        if (response.data.ErrorCount > 0) {
          self.setState({ isFormUpdated: true });
          this.props.setFormUpdate(true);
          response.data.Errors.forEach((item, index) => {
            if (index === 0) {
              alert(item.Messages);
            }
          });
        } else {
          let deductionData = response.data.Data[0];
          self.props.setSystemDeductionData(deductionData);
          self.props.history.push(redirectURL);
        }
      })
      .catch(function(error) {});
  }

  redirectToSummarygPage() {
    this.setState({ isFormUpdated: false });
    this.props.showToaster(false);
    this.props.setFormUpdate(false);
    setTimeout(
      function() {
        this.props.history.push(
          `/system-level-review-and-save/${
            this.props.match.params.SystemDeductionId
          }`
        );
      }.bind(this),
      100
    );
  }

  saveAndContinueDeductionCycle(actionType, navLink) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let redirectPageUrl = "";
    let data = this.state;
    let errors = {};

    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: false,
        showFinishLaterModalWithMandatoryField: false,
        openTrackYourProgressModal2: false
      });
      this.props.setFormUpdate(false);
    }

    if (actionType === SAVE_AND_CONTINUE) {
      redirectPageUrl = `/system-level-deafult-value/${
        this.props.match.params.SystemDeductionId
      }`;
    } else if (actionType === SAVE_CHANGES) {
      redirectPageUrl = "/system-level-deduction-setup-listing-page";
      this.props.showToaster(
        true,
        `'${
          this.props.systemDeductionData.SystemDeductionName
        }' has been saved. We’ve marked this deduction as in progress for you to work on later.`,
        "inprogress"
      );
    } else if (actionType === SAVE_AND_FINISH_LATER) {
      redirectPageUrl = "/system-level-deduction-setup-listing-page";
      this.props.showToaster(
        true,
        `'${
          this.props.systemDeductionData.SystemDeductionName
        }' has been saved. We’ve marked this deduction as in progress for you to work on later.`,
        "inprogress"
      );
      // this.props.history.push("/system-level-deduction-setup-listing-page");
    } else if (actionType === SAVE_TO_SUMMERY_PAGE) {
      redirectPageUrl = `/system-level-review-and-save/${
        this.props.match.params.SystemDeductionId
      }`;
      this.props.showToaster(
        true,
        `'${
          this.props.systemDeductionData.SystemDeductionName
        }' has been updated.`
      );
    } else if (actionType === SAVE_AND_BACK_PAGE) {
      redirectPageUrl = `/system-level-calculation-method/${
        this.props.match.params.SystemDeductionId
      }`;
    } else if (actionType === TRACK_YOUR_PROGRESS) {
      redirectPageUrl = navLink;
    }

    let PayrollsRunsInMonthList = [];
    PayrollsRunsInMonthList.push(data.IncludeInFifthScheduledPayroll);
    PayrollsRunsInMonthList.push(data.IncludeInFirstScheduledPayroll);
    PayrollsRunsInMonthList.push(data.IncludeInFourthScheduledPayroll);
    PayrollsRunsInMonthList.push(data.IncludeInSecondScheduledPayroll);
    PayrollsRunsInMonthList.push(data.IncludeInThirdScheduledPayroll);

    if (
      String(data.DeductionCycleId) ===
        String(
          systemDeductionCycleConstantId.DEDUCTION_CYCLE_SCHEDULED_PAYROLLS_ID
        ) &&
      String(data.DeductionSubCycleId_Scheduled) ===
        String(
          systemDeductionCycleConstantId.DEDUCTION_SUB_CYCLE_SPECIFIC_SCHEDULED_ID
        )
    ) {
      let isInclude = PayrollsRunsInMonthList.includes(true);
      if (isInclude === false) {
        errors = validator.validateRequiredField(
          "null",
          "PayrollsRunsInMonth",
          errors
        );
      }
    }

    errors = validator.validateRequiredField(
      data.DeductionCycleId,
      "DeductionCycleId",
      errors
    );
    if (
      data.DeductionCycleId ===
      String(
        systemDeductionCycleConstantId.DEDUCTION_CYCLE_SCHEDULED_PAYROLLS_ID
      )
    ) {
      data.DeductionSubCycleId = data.DeductionSubCycleId_Scheduled;
      errors = validator.validateRequiredField(
        data.DeductionSubCycleId,
        "DeductionSubCycleId_Scheduled",
        errors
      );
    } else if (
      data.DeductionCycleId ===
      String(systemDeductionCycleConstantId.DEDUCTION_CYCLE_OTHER_ID)
    ) {
      data.DeductionSubCycleId = data.DeductionSubCycleId_Other;
      errors = validator.validateRequiredField(
        data.DeductionSubCycleId,
        "DeductionSubCycleId_Other",
        errors
      );
    } else {
      data.DeductionSubCycleId = null;
    }
    this.setState({
      errors: errors,
      openBackButtonModal: false,
      openDeleteButtonModal: false,
      closeLeavingModal: false,
      openTrackYourProgressModal: false,
      openTrackYourProgressModal2: false
    });
    if (Object.keys(errors).length !== 0) {
      this.setState({
        showToaster: true,
        messageToaster: message.ERROR_ON_PAGE,
        isFormUpdated: true
      });
      this.setState({
        IsFormUpdated: true,
        showBackCancelModal: false
      });
      this.props.setFormUpdate(true);
      this.props.setGlobalNavClick(false);
    } else {
      if (actionType === GLOBAL_NAV) {
        this.setState({
          isFormUpdated: false,
          showBackCancelModal: false
        });
        this.props.setFormUpdate(false);
        this.props.setGlobalNavClick(
          false,
          this.props.globalNavClick.LinkItem,
          this.props.globalNavClick.MenuLink
        );
        //this.props.CallbackToQuickLink(true);
        this.setState({
          showBackCancelModal: false
        });
        this.props.isOverlayClickable(false);
        redirectPageUrl = "";
      }
    }

    if (Object.keys(errors).length === 0) {
      this.props.setLoader(true);
      this.setState({
        isFormUpdated: false
      });
      let UIDraftPageId = 0;

      if (
        this.props.systemDeductionData.UiDraftCompletionStage >
        this.state.pageId
      ) {
        UIDraftPageId = this.props.systemDeductionData.UiDraftCompletionStage;
      } else {
        UIDraftPageId = this.state.pageId;
      }

      var self = this;
      let patchActions = [
        {
          Operation: "update",
          PathOrKey: "DeductionCycleId",
          Value: data.DeductionCycleId
        },
        {
          Operation: "update",
          PathOrKey: "DeductionSubCycleId",
          Value: data.DeductionSubCycleId
        },
        {
          Operation: "update",
          PathOrKey: "ApplyToAdditionalChecksInTheSamePayroll",
          Value: data.ApplyToAdditionalChecksInTheSamePayroll
        },
        {
          Operation: "update",
          PathOrKey: "IncludeInFirstScheduledPayroll",
          Value: data.IncludeInFirstScheduledPayroll
        },
        {
          Operation: "update",
          PathOrKey: "IncludeInSecondScheduledPayroll",
          Value: data.IncludeInSecondScheduledPayroll
        },
        {
          Operation: "update",
          PathOrKey: "IncludeInThirdScheduledPayroll",
          Value: data.IncludeInThirdScheduledPayroll
        },
        {
          Operation: "update",
          PathOrKey: "IncludeInFourthScheduledPayroll",
          Value: data.IncludeInFourthScheduledPayroll
        },
        {
          Operation: "update",
          PathOrKey: "IncludeInFifthScheduledPayroll",
          Value: data.IncludeInFifthScheduledPayroll
        },
        {
          Operation: "update",
          PathOrKey: "UiDraftCompletionStage",
          Value: UIDraftPageId
        }
      ];

      const url = `${common.WEB_API_URL}/v1/system/deduction/${
        data.SystemDeductionId
      }`;
      axios
        .patch(url, patchActions)
        .then(function(response) {
          self.props.setLoader(false);
          const dataUrl = `${common.WEB_API_URL}/v1/system/deduction/${
            data.SystemDeductionId
          }`;
          axios
            .get(dataUrl)
            .then(function(response) {
              if (response.data.ErrorCount > 0) {
                response.data.Errors.forEach((item, index) => {
                  if (index === 0) {
                    alert(item.Messages);
                  }
                });
              } else {
                let deductionData = response.data.Data[0];
                self.props.setSystemDeductionData(deductionData);
                if (actionType === GLOBAL_NAV) {
                  self.props.setFormUpdate(false);
                  self.props.CallbackToQuickLink(true);
                } else {
                  self.props.history.push(redirectPageUrl);
                }
              }
            })
            .catch(function(error) {});
        })
        .catch(function(error) {});
    }
  }

  deleteDeduction() {
    var self = this;
    this.setState({
      isFormUpdated: false,
      openDeleteButtonModal: false
    });
    this.props.setFormUpdate(false);
    self.props.setLoader(true);

    const url = `${common.WEB_API_URL}/v1/system/deduction/${
      this.state.SystemDeductionId
    }`;
    axios
      .delete(url)
      .then(function(response) {
        self.props.setLoader(false);
        if (response.data.ErrorCount > 0) {
          self.setState({
            isFormUpdated: true
          });
          this.props.setFormUpdate(true);
          response.data.Errors.forEach((item, index) => {
            if (index === 0) {
              alert(item.Messages);
            }
          });
        } else {
          self.props.showToaster(
            true,
            `'${
              self.props.systemDeductionData.SystemDeductionName
            }' has been deleted.`,
            "delete"
          );
          self.props.history.push("/system-level-deduction-setup-listing-page");
        }
      })
      .catch(function(error) {});
  }

  deleteDeductionForGlobalNav() {
    var self = this;
    this.setState({
      isFormUpdated: false,
      openDeleteButtonModal: false
    });
    this.props.setFormUpdate(false);
    self.props.setLoader(true);

    const url = `${common.WEB_API_URL}/v1/system/deduction/${
      this.state.SystemDeductionId
    }`;
    axios
      .delete(url)
      .then(function(response) {
        self.props.setLoader(false);
        if (response.data.ErrorCount > 0) {
          self.setState({
            isFormUpdated: true
          });
          this.props.setFormUpdate(true);
          response.data.Errors.forEach((item, index) => {
            if (index === 0) {
              alert(item.Messages);
            }
          });
        } else {
          self.props.setFormUpdate(false);
          self.props.setGlobalNavClick(
            false,
            self.props.globalNavClick.LinkItem,
            self.props.globalNavClick.MenuLink
          );
          self.props.CallbackToQuickLink(true);
          self.props.isOverlayClickable(false);
        }
      })
      .catch(function(error) {});
  }

  onOpenFinishLaterButtonModal = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let errorCount = this.validateRequiredField();
    if (errorCount === 0) {
      this.setState({
        showFinishLaterModalWithMandatoryField: true,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
    } else {
      this.setState({
        showFinishLaterModalWithoutMandatoryField: true,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
    }
  };

  onCloseFinishLaterModal = () => {
    this.setState({
      isFormUpdated: true,
      showFinishLaterModalWithoutMandatoryField: false,
      showFinishLaterModalWithMandatoryField: false
    });
    this.props.setFormUpdate(true);
    this.props.setGlobalNavClick(false);
  };

  exitSetup = (actionType, navLink) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (actionType === "EXIT_SETUP") {
      this.setState({
        showFinishLaterModalWithoutMandatoryField: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.history.push("/system-level-deduction-setup-listing-page");
    } else if (actionType === "LEAVE_THIS_PAGE") {
      this.setState({
        openTrackYourProgressModal: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.history.push(navLink);
    } else if (actionType === "GLOBAL_NAV") {
      this.setState({
        showBackCancelModal: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.setGlobalNavClick(
        false,
        this.props.globalNavClick.LinkItem,
        this.props.globalNavClick.MenuLink
      );
      this.props.CallbackToQuickLink(true);
    } else if (actionType === "SAVE_AND_BACK_PAGE") {
      this.setState({
        openBackButtonModal2: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.history.push(
        `/system-level-calculation-method/${
          this.props.match.params.SystemDeductionId
        }`
      );
    }
  };

  validateRequiredField = () => {
    let errors = {};
    let data = this.state;

    let PayrollsRunsInMonthList = [];
    PayrollsRunsInMonthList.push(data.IncludeInFifthScheduledPayroll);
    PayrollsRunsInMonthList.push(data.IncludeInFirstScheduledPayroll);
    PayrollsRunsInMonthList.push(data.IncludeInFourthScheduledPayroll);
    PayrollsRunsInMonthList.push(data.IncludeInSecondScheduledPayroll);
    PayrollsRunsInMonthList.push(data.IncludeInThirdScheduledPayroll);

    if (
      String(data.DeductionCycleId) ===
        String(
          systemDeductionCycleConstantId.DEDUCTION_CYCLE_SCHEDULED_PAYROLLS_ID
        ) &&
      String(data.DeductionSubCycleId_Scheduled) ===
        String(
          systemDeductionCycleConstantId.DEDUCTION_SUB_CYCLE_SPECIFIC_SCHEDULED_ID
        )
    ) {
      let isInclude = PayrollsRunsInMonthList.includes(true);
      if (isInclude === false) {
        errors = validator.validateRequiredField(
          "null",
          "PayrollsRunsInMonth",
          errors
        );
      }
    }

    errors = validator.validateRequiredField(
      data.DeductionCycleId,
      "DeductionCycleId",
      errors
    );
    if (
      data.DeductionCycleId ===
      String(
        systemDeductionCycleConstantId.DEDUCTION_CYCLE_SCHEDULED_PAYROLLS_ID
      )
    ) {
      data.DeductionSubCycleId = data.DeductionSubCycleId_Scheduled;
      errors = validator.validateRequiredField(
        data.DeductionSubCycleId,
        "DeductionSubCycleId_Scheduled",
        errors
      );
    } else if (
      data.DeductionCycleId ===
      String(systemDeductionCycleConstantId.DEDUCTION_CYCLE_OTHER_ID)
    ) {
      data.DeductionSubCycleId = data.DeductionSubCycleId_Other;
      errors = validator.validateRequiredField(
        data.DeductionSubCycleId,
        "DeductionSubCycleId_Other",
        errors
      );
    } else {
      data.DeductionSubCycleId = null;
    }

    return Object.keys(errors).length;
  };

  close() {
    this.setState({
      openBackButtonModal: false,
      openDeleteButtonModal: false,
      showCancelModal: false,
      closeLeavingModal: false,
      isFormUpdated: true,
      showBackCancelModal: false,
      openTrackYourProgressModal: false
    });
    this.props.setFormUpdate(true);
    this.props.setGlobalNavClick(false);
  }

  onOpenTrackYourProgressModal = navLink => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({
      openTrackYourProgressModal: true,
      modalNavLink: navLink,
      isFormUpdated: false
    });
  };

  onOpenTrackYourProgressModal2 = navLink => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({
      openTrackYourProgressModal2: true,
      modalNavLink: navLink,
      isFormUpdated: false
    });
  };

  onCloseTrackYourProgressModal() {
    this.setState({ openTrackYourProgressModal: false });
    this.setState({
      openTrackYourProgressModal: false,
      openTrackYourProgressModal2: false,
      isFormUpdated: true
    });
  }

  onOpenDeleteButtonModal() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({ openDeleteButtonModal: true });
  }

  onCloseDeleteButtonModal() {
    this.setState({ openDeleteButtonModal: false });
  }

  onOpenBackButtonModal() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let errorCount = this.validateRequiredField();
    if (errorCount === 0) {
      this.setState({ openBackButtonModal: true, isFormUpdated: false });
    } else {
      this.setState({ openBackButtonModal2: true, isFormUpdated: false });
    }
    this.props.setFormUpdate(false);
  }

  onCloseBackButtonModal() {
    this.setState({
      openBackButtonModal: false,
      openBackButtonModal2: false,
      isFormUpdated: true
    });
    this.props.setFormUpdate(true);
  }

  leavingCancelModal() {
    this.setState({ closeLeavingModal: false });
  }

  renderDeductionComeOutList(payrollDeductionRadioButtonList) {
    if (
      this.props.payrollDeductionRadioButtonList !== undefined &&
      this.props.payrollDeductionRadioButtonList !== null &&
      this.props.payrollDeductionRadioButtonList.Data !== undefined
    ) {
      return this.props.payrollDeductionRadioButtonList.Data.map(
        (item, index) => {
          return (
            <RadioButton
              key={index}
              id={"cycle-payroll-category-button-" + item.DeductionCycleId}
              name="DeductionCycleId"
              value={String(item.DeductionCycleId)}
              type="longDescription"
              label={item.Name}
              description={validator.truncketStringToCompleteWord(
                item.LongName,
                50
              )}
              checked={
                String(this.state.DeductionCycleId) ===
                String(item.DeductionCycleId)
              }
              onChange={this.onChangeDeductionComeOut}
              width="235px"
            />
          );
        }
      );
    }
  }

  renderDeductionWithheldList(
    scheduledPayrollsWitheldDeductionOtherRadioButtonList
  ) {
    if (
      this.props.scheduledPayrollsWitheldDeductionOtherRadioButtonList !==
        undefined &&
      this.props.scheduledPayrollsWitheldDeductionOtherRadioButtonList !==
        null &&
      this.props.scheduledPayrollsWitheldDeductionOtherRadioButtonList.Data !==
        undefined
    ) {
      return this.props.scheduledPayrollsWitheldDeductionOtherRadioButtonList.Data.map(
        // eslint-disable-next-line
        (item, index) => {
          if (
            item.DeductionCycleId ===
            systemDeductionCycleConstantId.DEDUCTION_CYCLE_SCHEDULED_PAYROLLS_ID
          ) {
            return (
              <RadioButton
                key={index}
                id={
                  "scheduled-payroll-category-button-" +
                  item.DeductionSubCycleId
                }
                name="DeductionSubCycleId_Scheduled"
                value={String(item.DeductionSubCycleId)}
                type="three-line-title"
                label={item.Name}
                checked={
                  String(this.state.DeductionSubCycleId_Scheduled) ===
                  String(item.DeductionSubCycleId)
                }
                onChange={this.onChangeDeductionScheduledpayroll}
                width="235px"
              />
            );
          }
        }
      );
    }
  }

  renderDeductionWithheldOthreList(
    scheduledPayrollsWitheldDeductionOtherRadioButtonList
  ) {
    if (
      this.props.scheduledPayrollsWitheldDeductionOtherRadioButtonList !==
        undefined &&
      this.props.scheduledPayrollsWitheldDeductionOtherRadioButtonList !==
        null &&
      this.props.scheduledPayrollsWitheldDeductionOtherRadioButtonList.Data !==
        undefined
    ) {
      return this.props.scheduledPayrollsWitheldDeductionOtherRadioButtonList.Data.map(
        // eslint-disable-next-line
        (item, index) => {
          if (
            item.DeductionCycleId ===
            systemDeductionCycleConstantId.DEDUCTION_CYCLE_OTHER_ID
          ) {
            return (
              <RadioButton
                key={index}
                id={"other-payroll-category-button-" + item.DeductionSubCycleId}
                name="DeductionSubCycleId_Other"
                value={String(item.DeductionSubCycleId)}
                type="two-line-title"
                label={validator.truncketStringToCompleteWord(
                  item.LongName,
                  45
                )}
                checked={
                  String(this.state.DeductionSubCycleId_Other) ===
                  String(item.DeductionSubCycleId)
                }
                onChange={this.onChangeDeductionComeOut_other}
                width="235px"
              />
            );
          }
        }
      );
    }
  }

  renderAdditionalChecksList(list) {
    return list.map(item => {
      return (
        <div
          key={item.id}
          id={`additional-paychecks-button-` + item.id}
          className={`single-radio-button`}
        >
          <RadioButton
            id={item.Name}
            name={item.name}
            type="title"
            value={item.value}
            label={item.label}
            onChange={this.onChangeDeductionAdditionalChecks}
            checked={
              this.state.ApplyToAdditionalChecksInTheSamePayroll === item.value
            }
            boxWidth="100%"
          />
        </div>
      );
    });
  }

  handleChangeIncludeInFirstScheduledPayroll() {
    let errors = this.state.errors;
    delete errors["PayrollsRunsInMonth"];
    this.setState({
      errors
    });
    let selectedCheckBox = !this.state.IncludeInFirstScheduledPayroll;
    this.setState({
      IncludeInFirstScheduledPayroll: selectedCheckBox,
      isFormUpdated: true
    });
    this.props.setFormUpdate(true);
  }

  handleChangeIncludeInSecondScheduledPayroll() {
    let errors = this.state.errors;
    delete errors["PayrollsRunsInMonth"];
    this.setState({
      errors
    });
    let selectedCheckBox = !this.state.IncludeInSecondScheduledPayroll;
    this.setState({
      IncludeInSecondScheduledPayroll: selectedCheckBox,
      isFormUpdated: true
    });
    this.props.setFormUpdate(true);
  }

  handleChangeIncludeInThirdScheduledPayroll() {
    let errors = this.state.errors;
    delete errors["PayrollsRunsInMonth"];
    this.setState({
      errors
    });
    let selectedCheckBox = !this.state.IncludeInThirdScheduledPayroll;
    this.setState({
      IncludeInThirdScheduledPayroll: selectedCheckBox,
      isFormUpdated: true
    });
    this.props.setFormUpdate(true);
  }

  handleChangeIncludeInFourthScheduledPayroll() {
    let errors = this.state.errors;
    delete errors["PayrollsRunsInMonth"];
    this.setState({
      errors
    });
    let selectedCheckBox = !this.state.IncludeInFourthScheduledPayroll;
    this.setState({
      IncludeInFourthScheduledPayroll: selectedCheckBox,
      isFormUpdated: true
    });
    this.props.setFormUpdate(true);
  }

  handleChangeIncludeInFifthScheduledPayroll() {
    let errors = this.state.errors;
    delete errors["PayrollsRunsInMonth"];
    this.setState({
      errors
    });
    let selectedCheckBox = !this.state.IncludeInFifthScheduledPayroll;
    this.setState({
      IncludeInFifthScheduledPayroll: selectedCheckBox,
      isFormUpdated: true
    });
    this.props.setFormUpdate(true);
  }

  renderPayRollRunMonthItem_List() {
    if (!this.state.payRollRunMonthList) {
      return <div />;
    } else {
      return (
        <div
          id="system-level-deduction-cycle-payrolls-runs-month-checkbox-group"
          className="clearfix"
          style={{ marginBottom: "20px" }}
        >
          <div
            id={`payroll-runs-IncludeInFirstScheduledPayroll`}
            className="ck-button"
          >
            <label className="">
              <input
                type="checkbox"
                className="hidden-checkbox"
                name="IncludeInFirstScheduledPayroll"
                checked={this.state.IncludeInFirstScheduledPayroll}
                onClick={this.handleChangeIncludeInFirstScheduledPayroll}
                value="IncludeInFirstScheduledPayroll"
                error={this.state.errors["PayrollsRunsInMonth"]}
              />
              <span>1</span>
            </label>
          </div>

          <div
            id={`payroll-runs-IncludeInSecondScheduledPayroll`}
            className="ck-button"
          >
            <label className="">
              <input
                type="checkbox"
                className="hidden-checkbox"
                name="IncludeInSecondScheduledPayroll"
                checked={this.state.IncludeInSecondScheduledPayroll}
                onClick={this.handleChangeIncludeInSecondScheduledPayroll}
                value="IncludeInSecondScheduledPayroll"
                error={this.state.errors["PayrollsRunsInMonth"]}
              />
              <span>2</span>
            </label>
          </div>

          <div
            id={`payroll-runs-IncludeInThirdScheduledPayroll`}
            className="ck-button"
          >
            <label className="">
              <input
                type="checkbox"
                className="hidden-checkbox"
                name="IncludeInThirdScheduledPayroll"
                checked={this.state.IncludeInThirdScheduledPayroll}
                onClick={this.handleChangeIncludeInThirdScheduledPayroll}
                value="IncludeInThirdScheduledPayroll"
                error={this.state.errors["PayrollsRunsInMonth"]}
              />
              <span>3</span>
            </label>
          </div>

          <div
            id={`payroll-runs-IncludeInFourthScheduledPayroll`}
            className="ck-button"
          >
            <label className="">
              <input
                type="checkbox"
                className="hidden-checkbox"
                name="IncludeInFourthScheduledPayroll"
                checked={this.state.IncludeInFourthScheduledPayroll}
                onClick={this.handleChangeIncludeInFourthScheduledPayroll}
                value="IncludeInFourthScheduledPayroll"
                error={this.state.errors["PayrollsRunsInMonth"]}
              />
              <span>4</span>
            </label>
          </div>

          <div
            id={`payroll-runs-IncludeInFifthScheduledPayroll`}
            className="ck-button"
          >
            <label className="">
              <input
                type="checkbox"
                className="hidden-checkbox"
                name="IncludeInFifthScheduledPayroll"
                checked={this.state.IncludeInFifthScheduledPayroll}
                onClick={this.handleChangeIncludeInFifthScheduledPayroll}
                value="IncludeInFifthScheduledPayroll"
                error={this.state.errors["PayrollsRunsInMonth"]}
              />
              <span>5</span>
            </label>
          </div>
        </div>
      );
    }
  }

  checkPayRollRunMonth(newValue) {
    this.setState({
      selectedPayRollRunMonth: newValue
    });
  }

  renderPayRollRunMonthItem(list) {
    return list.map(item => {
      return (
        <div id={`payroll-runs-` + item.id} className="ck-button" key={item.id}>
          <label id={item.name}>
            <Checkbox className="hidden-checkbox" value={item.id} />
            <span>{item.text}</span>
          </label>
        </div>
      );
    });
  }

  onChangeDeductionComeOut_other(event, value = 0) {
    let update = {};
    let errors = this.state.errors;
    delete errors["DeductionSubCycleId_Other"];
    this.setState({
      errors
    });

    update[event.name] = event.value;
    this.setState(update);
  }

  onChangeDeductionAdditionalChecks(event, value = 0) {
    let update = {};
    let selectedButton = event.value === "true" || event.value === true;

    if (selectedButton) {
      this.setState({
        //  ApplyToAdditionalChecksInTheSamePayroll: true
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
    } else {
      this.setState({
        //  ApplyToAdditionalChecksInTheSamePayroll: false
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
    }
    update[event.name] = selectedButton;
    this.checkIsPageBack();
    this.setState(update);
  }

  onChangeDeductionScheduledpayroll(event, value = 0) {
    let update = {};

    let errors = this.state.errors;

    delete errors["DeductionSubCycleId_Scheduled"];
    this.setState({
      errors
    });

    if (isInDidUpdate === false) {
      this.setState({
        IncludeInFirstScheduledPayroll: false,
        IncludeInSecondScheduledPayroll: false,
        IncludeInThirdScheduledPayroll: false,
        IncludeInFourthScheduledPayroll: false,
        IncludeInFifthScheduledPayroll: false
      });
    }
    update[event.name] = event.value;
    this.setState(update);

    if (
      event.value ===
      String(
        systemDeductionCycleConstantId.DEDUCTION_SUB_CYCLE_ALL_SCHEDULED_PAYROLLS_ID
      )
    ) {
      this.setState({
        openPayRollRunMonth: false
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
    } else if (
      event.value ===
      String(
        systemDeductionCycleConstantId.DEDUCTION_SUB_CYCLE_EXCLUDING_EXTRA_ID
      )
    ) {
      this.setState({
        openPayRollRunMonth: false
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
    } else if (
      event.value ===
      String(
        systemDeductionCycleConstantId.DEDUCTION_SUB_CYCLE_SPECIFIC_SCHEDULED_ID
      )
    ) {
      this.setState({
        openPayRollRunMonth: true
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
    }
  }

  onChangePayRollRunMonth(event) {
    let update = {};

    update[event.target.name] = event.target.id;

    this.setState(update);
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
  }

  onChangeDeductionComeOut(event, value = 0) {
    let update = {};

    let errors = this.state.errors;
    delete errors["DeductionCycleId"];
    delete errors["DeductionSubCycleId_Other"];
    delete errors["DeductionSubCycleId_Scheduled"];
    this.setState({
      errors,
      openDeductionWithheldInfo: false
    });
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
    if (
      event.value ===
      String(
        systemDeductionCycleConstantId.DEDUCTION_CYCLE_SCHEDULED_PAYROLLS_ID
      )
    ) {
      this.setState({
        openDeductionComeOut: true,
        openDeductionWithheldOthre: false,
        DeductionSubCycleId_Other: null,
        openPayRollRunMonth: false
      });
      this.props.setFormUpdate(true);
    } else if (
      event.value ===
      String(
        systemDeductionCycleConstantId.DEDUCTION_CYCLE_SUPPLEMENTAL_PAYROLLS_ID
      )
    ) {
      this.setState({
        openDeductionComeOut: false,
        openDeductionWithheldOthre: false,
        DeductionSubCycleId_Other: null,
        IncludeInFirstScheduledPayroll: false,
        IncludeInSecondScheduledPayroll: false,
        IncludeInThirdScheduledPayroll: false,
        IncludeInFourthScheduledPayroll: false,
        IncludeInFifthScheduledPayroll: false,
        DeductionSubCycleId_Scheduled: null
      });
      this.props.setFormUpdate(true);
    } else if (
      event.value ===
      String(systemDeductionCycleConstantId.DEDUCTION_CYCLE_OTHER_ID)
    ) {
      this.setState({
        openDeductionComeOut: false,
        openDeductionWithheldOthre: true,
        DeductionSubCycleId_Other: null,
        IncludeInFirstScheduledPayroll: false,
        IncludeInSecondScheduledPayroll: false,
        IncludeInThirdScheduledPayroll: false,
        IncludeInFourthScheduledPayroll: false,
        IncludeInFifthScheduledPayroll: false,
        DeductionSubCycleId_Scheduled: null
      });
      this.props.setFormUpdate(true);
    } else if (
      event.value ===
      String(systemDeductionCycleConstantId.DEDUCTION_CYCLE_ALL_PAYROLLS_ID)
    ) {
      this.setState({
        openDeductionComeOut: false,
        openDeductionWithheldOthre: false,
        DeductionSubCycleId_Other: null,
        IncludeInFirstScheduledPayroll: false,
        IncludeInSecondScheduledPayroll: false,
        IncludeInThirdScheduledPayroll: false,
        IncludeInFourthScheduledPayroll: false,
        IncludeInFifthScheduledPayroll: false,
        DeductionSubCycleId_Scheduled: null
      });
      this.props.setFormUpdate(true);
    }

    update[event.name] = event.value;
    this.checkIsPageBack();
    this.setState(update);
  }

  showPayRollRunMonth() {
    return (
      <div className="nc-field-group clearfix">
        <div className="field-name">
          <span
            className={utilities.getErrorClassName(
              this.state.errors["PayrollsRunsInMonth"]
            )}
          >
            Select payrolls runs in the month
          </span>
        </div>

        <div className="clearfix" />

        <div className="deduction-page-section-input">
          {this.renderPayRollRunMonthItem_List(this.state.payRollRunMonthList)}
        </div>
        {this.state.errors["PayrollsRunsInMonth"] && (
          <p
            className="error-label"
            style={{
              marginTop: "-15px",
              marginBottom: "20px"
            }}
          >
            {this.state.errors["PayrollsRunsInMonth"]}
          </p>
        )}
      </div>
    );
  }

  showDeductionComeOut() {
    return (
      <div id="" className="entity-form-section-primary">
        <div className="content">
          <div id="" className="nc-field-group clearfix">
            <div
              id="deduction-scheduled-payrolls-question"
              className="field-name"
            >
              <span
                className={utilities.getErrorClassName(
                  this.state.errors["DeductionSubCycleId_Scheduled"]
                )}
              >
                From which scheduled payrolls do you want this deduction to be
                withheld? &nbsp;<a
                  id="deduction-scheduled-sub-cycle-info-icon"
                  onClick={() =>
                    this.setState({
                      openDeductionWithheldInfo: !this.state
                        .openDeductionWithheldInfo
                    })
                  }
                >
                  <i className="fa-information-blue fa fa-info-circle" />
                </a>
              </span>
            </div>

            <div className="clearfix" />
            {this.state.openDeductionWithheldInfo
              ? this.showDeductionWithheldInfo()
              : null}
            <div className="clearfix" />

            <div
              className="radio-button-group"
              style={{ marginBottom: "10px" }}
            >
              {this.renderDeductionWithheldList()}
            </div>
            {this.state.errors["DeductionSubCycleId_Scheduled"] && (
              <p
                className="error-label"
                style={{
                  marginTop: "-15px",
                  marginBottom: "20px"
                }}
              >
                {this.state.errors["DeductionSubCycleId_Scheduled"]}
              </p>
            )}
            <div className="clearfix" />
            {this.state.openPayRollRunMonth ? this.showPayRollRunMonth() : null}
          </div>
        </div>
      </div>
    );
  }

  showDeductionWithheldOthre() {
    return (
      <div id="" className="entity-form-section-primary">
        <div className="content">
          <div className="nc-field-group clearfix">
            <div className="field-name">
              <span
                className={utilities.getErrorClassName(
                  this.state.errors["DeductionSubCycleId_Other"]
                )}
              >
                From which other payrolls do you want this deduction to be
                withheld? &nbsp;
                {/* <a
                  id="deduction-with-held-other-info-icon"
                  onClick={() =>
                    this.setState({
                      openDeductionWithheldInfo: !this.state
                        .openDeductionWithheldInfo
                    })}
                >
                  <i className="fa-information-blue fa fa-info-circle" />
                </a> */}
              </span>
            </div>

            <div className="clearfix" />
            {this.state.openDeductionWithheldInfo
              ? this.showDeductionWithheldInfo()
              : null}
            <div className="clearfix" />

            <div
              className="radio-button-group"
              style={{ marginBottom: "10px" }}
            >
              {this.renderDeductionWithheldOthreList()}
            </div>
            {this.state.errors["DeductionSubCycleId_Other"] && (
              <p
                className="error-label"
                style={{
                  marginTop: "-15px",
                  marginBottom: "20px"
                }}
              >
                {this.state.errors["DeductionSubCycleId_Other"]}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  showDeductionWithheldInfo() {
    return (
      <div
        id="open-deduction-cycle-deduction-withheld-info-popover"
        className="open-deduction-based-popover-for-information-extra-small-type col-xs-12 col-sm-12 col-md-12 col-lg-12"
      >
        <a
          id="deduction-with-held-tooltip-close-icon"
          className="open-deduction-based-popover-button-close"
          onClick={() => this.setState({ openDeductionWithheldInfo: false })}
        >
          <i className="fa fa-times-circle fa-lg" />
        </a>
        <div
          id="deduction-with-held-tooltip-popover-data"
          className="deduction-popover-information-data col-xs-12 col-sm-12 col-md-12 col-lg-12"
        >
          <p>
            <p>
              All Scheduled Payrolls: 1st, 2nd, 3rd, 4th, and 5th payrolls of
              the month for weekly frequencies; 1st, 2nd, and 3rd payrolls of
              the month for bi-weekly frequencies; 1st and 2nd payrolls of the
              month for semi-monthly frequencies; etc.
            </p>
            <p>
              All Scheduled, Excluding Extra Monthly Payrolls: Same as All
              Scheduled Payrolls excluding the 5th payroll of the month for
              weekly frequencies and 3rd payroll of the month for bi-weekly
              frequencies.{" "}
            </p>
            <p>
              Specific Scheduled Payrolls: Select from the 1st, 2nd, 3rd, 4th,
              and 5th payrolls of the month.
            </p>
          </p>
        </div>
      </div>
    );
  }

  showAdditionalPaychecksInfo() {
    return (
      <div
        id="open-deduction-cycle-additional-paycheck-info-popover"
        className="open-deduction-based-popover-for-information-extra-small-type col-xs-12 col-sm-12 col-md-12 col-lg-12"
      >
        <a
          id="deduction-additional-paychecks-tooltip-close-icon"
          className="open-deduction-based-popover-button-close"
          onClick={() => this.setState({ openAdditionalPaychecksInfo: false })}
        >
          <i className="fa fa-times-circle fa-lg" />
        </a>
        <div
          id="deduction-additional-pay-checks-popover-tooltip-data"
          className="deduction-popover-information-data col-xs-12 col-sm-12 col-md-12 col-lg-12"
        >
          <p>
            <p>
              Typically, users select "Yes" for percentage and hours-based
              deductions and "No" for all other deductions.
            </p>
            <p>
              Ex: John has a medical insurance deduction of $200 and a 401(k)
              deduction of 5% that come out of his regularly scheduled paycheck.
              John receives a bonus check in addition to his regularly scheduled
              paycheck one payroll. The medical insurance is not a deduction
              that has "apply to additional checks in the same payroll" set, but
              the 401(k) deduction is. The 401(k) deduction will be
              automatically scheduled to come out of the bonus check.
            </p>
          </p>
        </div>
      </div>
    );
  }

  onClickTrackYourProgress(navLink) {
    let errorCount = this.validateRequiredField();
    if (this.state.isFormUpdated) {
      if (errorCount === 0) {
        this.onOpenTrackYourProgressModal2(navLink);
      } else {
        this.onOpenTrackYourProgressModal(navLink);
      }
    } else {
      this.props.history.push(navLink);
    }
  }

  closeToasterMessage = () => {
    this.setState({ showToaster: false });
    this.props.showToaster(false, "");
  };

  render() {
    let isReviewSaveEditPage = false;
    if (this.props.systemDeductionData.UiDraftCompletionStage === 6) {
      isReviewSaveEditPage = true;
    }

    return (
      <div id="deduction-cycle-page" className="nc-entity-page-container">
        <Prompt
          when={this.state.isFormUpdated}
          message={message.UNSAVED_CHANGES}
        />
        <DeductionSetupDescription />
        <div id="toaster-message" className="toaster-container">
          {this.state.showToaster ? (
            <Toaster
              show={this.state.showToaster}
              message={this.state.messageToaster}
              closeToasterMessage={this.closeToasterMessage}
              alertClassName="danger"
            />
          ) : null}
        </div>
        <div className="clearfix" />

        <div
          id="deduction-description-page-title1"
          className="title-band-container"
        >
          <div className="title-band">
            <h3>Deduction Setup</h3>
          </div>
        </div>

        {/* Added for the mobile view */}
        <div
          id="deduction-track-progress"
          className="progress-tracker-container-mobile hidden-lg hidden-md"
        >
          <SystemLevelDeductionDescriptionStep
            pageId={this.state.pageId}
            onClickTrackYourProgress={(navLink, id) =>
              id !== 3 ? this.onClickTrackYourProgress(navLink) : null
            }
          />
        </div>

        <div
          id="deduction-description-page-title2"
          className="entity-page-content col-lg-9 col-md-8"
        >
          <div className="step-title-container clearfix">
            <span className="step-number badge badge-default">3</span>
            <div id="system-level-deduction-cycle-page" className="step-title">
              Deduction Cycle
            </div>
          </div>
          <div className="description-content">
            <div
              className="entity-form-section-primary"
              style={{ marginBottom: "20px" }}
            >
              <div id="deduction-cycle-page-description" className="content">
                <div id="deduction-cycle-page-description-label">
                  The deduction cycle determines how often this comes out of
                  your employee's paychecks.
                </div>
              </div>
            </div>
            <div className="seperator">
              <hr />
            </div>

            <div className="entity-form-section-primary">
              <div className="content">
                <div id="" className="nc-field-group clearfix">
                  <div id="deduction-payrolls-question" className="field-name">
                    <span
                      className={utilities.getErrorClassName(
                        this.state.errors["DeductionCycleId"]
                      )}
                    >
                      Which payrolls do you want this deduction to come out?
                      &nbsp;
                    </span>
                  </div>

                  <div
                    className="radio-button-group"
                    style={{ marginBottom: "10px" }}
                  >
                    {this.renderDeductionComeOutList()}
                  </div>
                  {this.state.errors["DeductionCycleId"] && (
                    <p
                      className="error-label"
                      style={{
                        marginTop: "-15px",
                        marginBottom: "20px"
                      }}
                    >
                      {this.state.errors["DeductionCycleId"]}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="clearfix" />
            {this.state.openDeductionComeOut
              ? this.showDeductionComeOut()
              : null}
            {this.state.openDeductionWithheldOthre
              ? this.showDeductionWithheldOthre()
              : null}

            <div className="clearfix" />

            <div className="seperator">
              <hr />
            </div>

            <div id="" className="entity-form-section-primary">
              <div className="content">
                <div className="nc-field-group clearfix">
                  <div
                    id="deduction-additional-checks-question"
                    className="field-name"
                  >
                    Should this deduction apply to additional checks in the same
                    payroll? &nbsp;<a
                      id="deduction-additional-check-info-icon"
                      onClick={() =>
                        this.setState({
                          openAdditionalPaychecksInfo: !this.state
                            .openAdditionalPaychecksInfo
                        })
                      }
                    >
                      <i className="fa-information-blue fa fa-info-circle" />
                    </a>
                  </div>

                  <div className="clearfix" />
                  {this.state.openAdditionalPaychecksInfo
                    ? this.showAdditionalPaychecksInfo()
                    : null}
                  <div className="clearfix" />

                  <div
                    className="radio-button-group"
                    style={{ marginBottom: "10px" }}
                  >
                    {this.renderAdditionalChecksList(AdditionalChecksList)}
                  </div>
                </div>
              </div>
            </div>

            <div className="clearfix" />
          </div>
        </div>
        <div
          id="deduction-track-progress"
          className="col-lg-3 col-md-4 hidden-sm hidden-xs"
        >
          <SystemLevelDeductionDescriptionStep
            pageId={this.state.pageId}
            onClickTrackYourProgress={(navLink, id) =>
              id !== 3 ? this.onClickTrackYourProgress(navLink) : null
            }
          />
        </div>
        <div className="footer-button-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="seperator">
            <hr />
          </div>
          {isReviewSaveEditPage ? (
            <div className="nc-button-group">
              <button
                type="button"
                id="cycle-page-save-changes-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDeductionCycle.bind(
                  this,
                  SAVE_TO_SUMMERY_PAGE,
                  ""
                )}
              >
                SAVE CHANGES
              </button>
              <div className="nc-link-group-mobile-single-button">
                <button
                  type="button"
                  id="description-page-revert-back-button"
                  className="secondary-link-button"
                  onClick={this.redirectToSummarygPage}
                >
                  Revert back to last saved
                </button>
              </div>
            </div>
          ) : (
            <div className="nc-button-group">
              <button
                type="button"
                id="deduction-cycle-back-button"
                className="nc-button outline-button hidden-xs"
                onClick={
                  !this.state.isFormUpdated
                    ? this.redirectToCompanyLevelCalculationMethod
                    : this.onOpenBackButtonModal
                }
              >
                <i className="fa fa-chevron-left"> </i> BACK
              </button>
              <button
                type="button"
                id="cycle-save-and-continue-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDeductionCycle.bind(
                  this,
                  SAVE_AND_CONTINUE,
                  ""
                )}
              >
                CONTINUE
              </button>
              <button
                type="button"
                id="deduction-cycle-back-button-mobile"
                className="nc-button outline-button visible-xs"
                onClick={
                  !this.state.isFormUpdated
                    ? this.redirectToCompanyLevelCalculationMethod
                    : this.onOpenBackButtonModal
                }
              >
                <i className="fa fa-chevron-left"> </i> BACK
              </button>
              <div className="nc-link-group-mobile">
                <button
                  type="button"
                  id="cycle-save-and-finish-later-buttonlink"
                  className="primary-link-button"
                  onClick={this.onOpenFinishLaterButtonModal.bind(this)}
                >
                  Finish Later
                </button>

                <button
                  type="button"
                  id="cycle-cancel-buttonlink"
                  className="secondary-link-button"
                  onClick={this.onOpenDeleteButtonModal}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="clearfix" />
        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          id="track-progress-modal"
          show={this.state.openTrackYourProgressModal}
          onHide={this.onCloseTrackYourProgressModal}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="track-progress-modal-close-icon"
                onClick={this.onCloseTrackYourProgressModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="track-progress-modal-line1">
                Are you sure you want to leave this page in the middle of making
                edits?
              </div>
              <br />
              <div id="track-progress-modal-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="track-progress-modal-save-button"
                className="nc-button secondary-button"
                onClick={this.exitSetup.bind(
                  this,
                  "LEAVE_THIS_PAGE",
                  this.state.modalNavLink
                )}
              >
                LEAVE THIS PAGE
              </button>

              <button
                type="button"
                id="continue-editing-leaving-modal-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseTrackYourProgressModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          id="track-progress-modal-2"
          show={this.state.openTrackYourProgressModal2}
          onHide={this.onCloseTrackYourProgressModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="track-progress-modal-2-close-icon"
                onClick={this.onCloseTrackYourProgressModal.bind(this)}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="track-progress-modal-2-line1">
                Do you want to save your changes before leaving this page?
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="track-progress-modal-2-save-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDeductionCycle.bind(
                  this,
                  TRACK_YOUR_PROGRESS,
                  this.state.modalNavLink
                )}
              >
                SAVE AND GO
              </button>

              <button
                type="button"
                id="track-progress-modal-2-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseTrackYourProgressModal.bind(this)}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="finish-later-modal-1"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showFinishLaterModalWithoutMandatoryField}
          onHide={this.onCloseFinishLaterModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="finish-later-modal-1-close-icon"
                onClick={this.onCloseFinishLaterModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="finish-later-modal-1-line1">
                Are you sure you want to exit setup in the middle of making
                edits?
              </div>
              <br />
              <div id="finish-later-modal-1-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="finish-later-modal-1-exit-button"
                className="nc-button secondary-button"
                onClick={this.exitSetup.bind(this, "EXIT_SETUP", "")}
              >
                EXIT SETUP
              </button>

              <button
                type="button"
                id="finish-later-modal-1-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseFinishLaterModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="finish-later-modal-2"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showFinishLaterModalWithMandatoryField}
          onHide={this.onCloseFinishLaterModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="finish-later-modal-2-close-icon"
                onClick={this.onCloseFinishLaterModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="finish-later-modal-2-line1">
                Do you want to save your changes before exiting setup?
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="finish-later-modal-1-exit-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDeductionCycle.bind(
                  this,
                  SAVE_AND_FINISH_LATER,
                  ""
                )}
              >
                SAVE AND EXIT
              </button>

              <button
                type="button"
                id="finish-later-modal-1-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseFinishLaterModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="delete-deduction-modal"
          backdrop={"static"}
          keyboard={false}
          show={this.state.openDeleteButtonModal}
          onHide={this.onCloseDeleteButtonModal}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="delete-deduction-modal-close-icon"
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="delete-deduction-modal-line1">
                Are you sure you want to delete this deduction?
              </div>
              <br />
              <div id="delete-deduction-modal-line2">
                It will be gone for now, but you can add it back if you need it.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="delete-deduction-modal-delete-button"
                className="nc-button secondary-button"
                onClick={this.deleteDeduction}
              >
                DELETE DEDUCTION
              </button>

              <button
                type="button"
                id="delete-deduction-modal-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.close}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          id="back-button-modal"
          show={this.state.openBackButtonModal2}
          onHide={this.onCloseBackButtonModal}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="back-button-modal-close-icon"
                onClick={this.onCloseBackButtonModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="back-button-modal-line1">
                Are you sure you want to leave this page in the middle of making
                edits?
              </div>
              <br />
              <div id="back-button-modal-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="back-button-modal-save-button"
                className="nc-button secondary-button"
                onClick={this.exitSetup.bind(this, "SAVE_AND_BACK_PAGE", "")}
              >
                LEAVE THIS PAGE
              </button>

              <button
                type="button"
                id="back-button-modal-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseBackButtonModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          id="back-button-modal-2"
          show={this.state.openBackButtonModal}
          onHide={this.onCloseBackButtonModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="back-button-modal-2-close-icon"
                onClick={this.onCloseBackButtonModal.bind(this)}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="back-button-modal-2-line1">
                Do you want to save your changes before leaving this page?
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="back-button-modal-2-save-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDeductionCycle.bind(
                  this,
                  SAVE_AND_BACK_PAGE,
                  ""
                )}
              >
                SAVE AND GO
              </button>

              <button
                type="button"
                id="track-progress-modal-2-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseBackButtonModal.bind(this)}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showBackCancelModal}
          onHide={() => {
            this.setState({
              showBackCancelModal: !this.state.showBackCancelModal
            });
          }}
          id="deduction-unsaved-modal"
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="deduction-unsaved-modal-close-icon"
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="track-progress-modal-line1">
                Are you sure you want to leave this page in the middle of making
                edits?
              </div>
              <br />
              <div id="track-progress-modal-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="deduction-unsaved-modal-save-button"
                className="nc-button secondary-button"
                onClick={this.exitSetup.bind(this, "GLOBAL_NAV", "")}
              >
                LEAVE THIS PAGE
              </button>

              <button
                type="button"
                id="deduction-unsaved-modal-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.close}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    systemDeductionData: state.systemDeductionData,
    payrollDeductionRadioButtonList: state.payrollDeductionRadioButtonList,
    scheduledPayrollsWitheldDeductionOtherRadioButtonList:
      state.scheduledPayrollsWitheldDeductionOtherRadioButtonList,
    scheduledPayrollsWitheldDeductionScheduledPayrollRadioButtonList:
      state.scheduledPayrollsWitheldDeductionScheduledPayrollRadioButtonList,
    globalNavClick: state.globalNavClick
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSystemDeductionData: setSystemDeductionData,
      showToaster: showToaster,
      getPayrollDeductionRadioButtonList: getPayrollDeductionRadioButtonList,
      getScheduledPayrollsWitheldDeductionOtherRadioButtonList: getScheduledPayrollsWitheldDeductionOtherRadioButtonList,
      getScheduledPayrollsWitheldDeductionScheduledPayrollRadioButtonList: getScheduledPayrollsWitheldDeductionScheduledPayrollRadioButtonList,
      setLoader: setLoader,
      setFormUpdate: setFormUpdate,
      isOverlayClickable: isOverlayClickable,
      setGlobalNavClick: setGlobalNavClick,
      CallbackToQuickLink: CallbackToQuickLink
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeductionCyclePage)
);
