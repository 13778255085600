import React from "react";

const Tab = props => (
  <li
    id={props.id}
    className={props.className}
    onClick={() => props.onSelect(props.id)}
  >
    {props.name}
  </li>
);

export default Tab;
