import React from "react";

export const PAGE_DESCRIPTION =
  "Earnings Groups are used to include specific earnings in the calculation of a deduction. For example, a 401(k) deduction can be setup to calculate as a percentage of the earnings in the Retirement Earnings group.  If Regular and Overtime are meant to be included in the 401(k) calculation but Reimbursement is not, the Retirement Earnings group would include Regular and Overtime but not include Reimbursement.";
export const composeToasterMessage =
  "If an earnings has been used in payroll, it cannot be added to or removed from an Earnings Group that is currently associated with a calculation method of a deduction that has been used in payroll.  Please contact your Netchex customer service representative for assistance.";

export const composeToasterMessage2 =
  "If an earnings has never been used in payroll, it can be added to or removed from an Earnings Group that is currently associated with a calculation method of a deduction that has been used in payroll. Its important to ensure that these changes are accurate. ";
export const UICOMPLETIONSTAGE_ACTIVE = 2;
export const UICOMPLETIONSTAGE_INACTIVE = 3;

export const NET_ADMIN = 1;
export const CLIENT_ADMIN = 2;
export const MANAGER = 3;
export const EMPLOYEE = 4;

export const GROSS_EARNINGS = 1;
export const TAXABLE_EARNINGS = 2;
export const DISPOSABLE_EARNINGS = 3;
export const RETIREMENT_EARNINGS = 4;
export const PERCENTAGE_BASED_EARNINGS = 5;
export const HOURS_BASED_EARNINGS_GROUP_1 = 6;
export const HOURS_BASED_EARNINGS_GROUP_2 = 7;
export const HOURS_BASED_EARNINGS_GROUP_3 = 8;
export const HOURS_BASED_EARNINGS_GROUP_4 = 9;
export const HOURS_BASED_EARNINGS_GROUP_5 = 10;

export const GROSS_MESSAGE =
  "Gross Earnings include all earnings paid to the employee. This specifically excludes expense reimbursements, imputed income and taxable fringe benefits.";
export const TAXABLE_MESSAGE = (
  <span>
    Taxable Earnings are all earnings that appear on a W-2 and exclude
    nontaxable income. For more information, consult the IRS's Publication 525 -
    Taxable and Nontaxable Income.
    <br />
    <a href="https://www.irs.gov/pub/irs-pdf/p525.pdf">
      (https://www.irs.gov/pub/irs-pdf/p525.pdf)
    </a>
  </span>
);
export const DISPOSABLE_MESSAGE =
  "Disposable Earnings are used in the calculation of garnishment deductions. They include all earnings paid to the employee, specifically excluding expense reimbursements, imputed income and taxable fringe benefits. Disposable wages are calculated by subtracting deductions required by law (e.g. federal, state, and local taxes) from Disposable Earnings.";
export const RETIREMENT_MESSAGE =
  "Retirement Earnings are earnings used to calculate percentage-based retirement deductions (e.g. 401(k),  403(b), 457(b), etc.).";
export const PERCENTAGE_BASED_MESSAGE =
  "Percentage-Based Earnings allows you to define specific earnings when calculating a percentage-based deduction that is not a retirement deduction.";
export const HOURS_BASED_EARNINGS_GROUP_1_MESSAGE =
  "Hours-Based Earnings Group 1 allows you to define specific earnings when calculating an hours-based deduction.";
export const HOURS_BASED_EARNINGS_GROUP_2_MESSAGE =
  "Hours-Based Earnings Group 2 allows you to define specific earnings when calculating an hours-based deduction.";
export const HOURS_BASED_EARNINGS_GROUP_3_MESSAGE =
  "Hours-Based Earnings Group 3 allows you to define specific earnings when calculating an hours-based deduction.";
export const HOURS_BASED_EARNINGS_GROUP_4_MESSAGE =
  "Hours-Based Earnings Group 4 allows you to define specific earnings when calculating an hours-based deduction.";
export const HOURS_BASED_EARNINGS_GROUP_5_MESSAGE =
  "Hours-Based Earnings Group 5 allows you to define specific earnings when calculating an hours-based deduction.";

export const GROSS_TOOLTIP_MESSAGE = (
  <span>
    Imputed income is the addition of the fair market value of cash/non-cash
    compensation to an employee's direct salary or wages in order to properly
    withhold income or employment taxes from the wages. For more information,
    consult the IRS's Employer's Tax Guide to Fringe Benefits.
    <br />
    <a href="https://www.irs.gov/pub/irs-pdf/p15b.pdf">
      (https://www.irs.gov/pub/irs-pdf/p15b.pdf)
    </a>
  </span>
);
export const TAXABLE_TOOLTIP_MESSAGE = "";
export const DISPOSABLE_TOOLTIP_MESSAGE = (
  <span>
    For more information, consult the Department of Labor's Fact Sheet #30 - The
    Federal Wage Garnishment Law, Consumer Credit Protection Act's Title III
    (CCPA).
    <br />
    <a href="https://www.dol.gov/whd/regs/compliance/whdfs30.pdf">
      (https://www.dol.gov/whd/regs/compliance/whdfs30.pdf)
    </a>
    )
  </span>
);
export const RETIREMENT_TOOLTIP_MESSAGE =
  "Wages from these earnings are reportable on the 401(k)/403(b) Report.";
export const PERCENTAGE_BASED_TOOLTIP_MESSAGE = "";
export const HOURS_BASED_EARNINGS_GROUP_1_TOOLTIP_MESSAGE =
  "For example, you may want to use Hours-Based Earnings Group 1 to withhold $0.20 per hour from all Regular hours that your employee works and Hours-Based Earnings Group 2 to withhold $0.25 per hour from all Overtime hours.";
export const HOURS_BASED_EARNINGS_GROUP_2_TOOLTIP_MESSAGE =
  "For example, you may want to use Hours-Based Earnings Group 1 to withhold $0.20 per hour from all Regular hours that your employee works and Hours-Based Earnings Group 2 to withhold $0.25 per hour from all Overtime hours.";
export const HOURS_BASED_EARNINGS_GROUP_3_TOOLTIP_MESSAGE =
  "For example, you may want to use Hours-Based Earnings Group 1 to withhold $0.20 per hour from all Regular hours that your employee works and Hours-Based Earnings Group 2 to withhold $0.25 per hour from all Overtime hours.";
export const HOURS_BASED_EARNINGS_GROUP_4_TOOLTIP_MESSAGE =
  "For example, you may want to use Hours-Based Earnings Group 1 to withhold $0.20 per hour from all Regular hours that your employee works and Hours-Based Earnings Group 2 to withhold $0.25 per hour from all Overtime hours.";
export const HOURS_BASED_EARNINGS_GROUP_5_TOOLTIP_MESSAGE =
  "For example, you may want to use Hours-Based Earnings Group 1 to withhold $0.20 per hour from all Regular hours that your employee works and Hours-Based Earnings Group 2 to withhold $0.25 per hour from all Overtime hours.";
