import React from "react";
import MultiSelectFrequencyTypeRadioButton from "./MultiSelectFrequencyTypeRadioButton";
import classNames from "classnames/bind";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { WORK_EMAIL, PERSONAL_EMAIL } from "../Constants";

const tooltip_work_email = (
  <Tooltip id="tooltip_work_email">
    Selecting "Work Email" will notify the corresponding employee using the
    email address found on the employee's Workplace Info page.
  </Tooltip>
);

const tooltip_personal_email = (
  <Tooltip id="tooltip_personal_email">
    Selecting "Personal Email" will notify the corresponding employee using the
    email address found on the employee's Demographics page.
  </Tooltip>
);

const tooltip_text = (
  <Tooltip id="tooltip_text">
    Selecting "Text" will notify the corresponding employee using the cell phone
    number found on the employee's Demographics page.
  </Tooltip>
);
const renderItem = (onChange, data = {}, name) => item => {
  const { optionId, label, buttonIcon } = item;
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        optionId === WORK_EMAIL
          ? tooltip_work_email
          : optionId === PERSONAL_EMAIL
            ? tooltip_personal_email
            : tooltip_text
      }
    >
      <span class="single-frequency-item">
        <MultiSelectFrequencyTypeRadioButton
          key={optionId}
          buttonIcon={buttonIcon}
          id={optionId}
          name={name}
          label={label}
          value={data[optionId]}
          onChange={() =>
            onChange &&
            onChange({
              name,
              value: { ...data, [optionId]: !data[optionId] }
            })
          }
        />
      </span>
    </OverlayTrigger>
  );
};
const MultiSelectFrequencyTypeButton = ({
  title,
  onChange,
  value,
  name,
  options,
  error,
  required,
  noHover
}) => {
  let baseClass = classNames(
    "payroll-frequency",
    "clearfix",
    noHover && "no-hover"
  );

  return (
    <div>
      {title && (
        <div style={{ fontWeight: "600", marginBottom: "10px" }}>{title}</div>
      )}
      <ul className={baseClass}>
        {options !== undefined &&
          options.map(renderItem(onChange, value, name))}
      </ul>
      {error && value && required && <div className="has-error">{error}</div>}
    </div>
  );
};

export default MultiSelectFrequencyTypeButton;
