import React, { Component } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";

export default class PayrollCalendarPayCheckDateItem extends Component {
  render() {
    const {
      label,
      lvalue,
      visible = true,
      //paycheckDateChangedFrom
      paycheckInfo
    } = this.props;
    const paycheckDateCalendar = (
      <Popover
        id="holiday-list-bottom"
        className="payroll-dash-summary-icon-list payroll-dash-summary-icon-list-bgcolor"
      >
        <div style={{ width: "250px" }}>
          <div className="payroll-dash-summary-icon-list-icon clearfix">
            <i
              className="fa fa-times-circle-o icon"
              onClick={() => this.refs.overlayPaycheckDateCalendar.hide()}
            />
            {/* PayCheck date has been changed from the{" "}
          {paycheckDateChangedFrom.replace("_", " ")} */}
          </div>
          {paycheckInfo}
        </div>
      </Popover>
    );
    if (visible)
      return (
        <div className="payroll-dash-summary-item clearfix">
          <div className="payroll-dash-summary-item-label">{label}</div>
          <div className="payroll-dash-summary-item-value">{lvalue}</div>
          {!!paycheckInfo && (
            <div className="payroll-dash-summary-item-icon">
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                ref="overlayPaycheckDateCalendar"
                overlay={paycheckDateCalendar}
                rootClose={true}
              >
                <i className="fa-information-blue fa fa-info-circle" />
              </OverlayTrigger>
            </div>
          )}
        </div>
      );
    else {
      return null;
    }
  }
}
