import axios, { CancelToken } from "axios";
import * as common from "../../app/common/index";
const BASE_URL = common.WEB_API_URL;
export const earningsRequests = CancelToken.source();
const config = {
  headers: { "Content-Type": "application/json" },
  cancelToken: earningsRequests.token
};

const makeGet = path => () =>
  axios.get(BASE_URL + path, { cancelToken: earningsRequests.token });

export const getEarningsByCompId = (subscriberId, companyId) =>
  axios.get(
    BASE_URL +
      "/v1/subscriber/" +
      subscriberId +
      "/company/" +
      companyId +
      "/earning"
  );
const COPY_EARNING = "v1/job/action/copyearning?returnSavedEntity=true";
export const postCopyEarning = params =>
  axios.post(BASE_URL + COPY_EARNING, params, config).catch(function(thrown) {
    if (axios.isCancel(thrown)) {
      console.log("Request canceled", thrown.message);
    } else {
      console.log("BAZINGA");
    }
  });

/** Use on the Earnings List page */
const SYSTEM_EARNINGS_PATH = "/v1/system/earning";
export const getEarnings = (filter = "ALL") =>
  axios.get(BASE_URL + SYSTEM_EARNINGS_PATH + "?filter=" + filter);

export const deleteEarning = id =>
  axios.delete(BASE_URL + SYSTEM_EARNINGS_PATH + "/" + id);

const EARNINGS_INACTIVATE_PATH = "/v1/system/earning/action/makeinactive";
export const inactivateEarning = id =>
  axios.post(BASE_URL + EARNINGS_INACTIVATE_PATH, { Id: id }, config);

const EARNINGS_ACTIVATE_PATH = "/v1/system/earning/action/makeactive";
export const activateEarning = id =>
  axios.post(BASE_URL + EARNINGS_ACTIVATE_PATH, { Id: id }, config);

const CATEGORY_PATH = "/v1/system/earningcategory";
export const getCategories = makeGet(CATEGORY_PATH);

const COMPANY_CATEGORY_PATH = "/v1/system/earning/category";
export const getTemplateCategories = makeGet(COMPANY_CATEGORY_PATH);
const COMPANY_SUBCATEGORY_PATH = "/v1/system/earning/subcategory";
export const getTemplateSubcategories = makeGet(COMPANY_SUBCATEGORY_PATH);
const STATUS_PATH = "/v1/app/ui/createworkflowstatus";
export const getStatuses = makeGet(STATUS_PATH);

/** Used on the Earnings Setup pages */
const SYSTEM_EARNING_EXISTS_PATH = "/v1/system/earning/action/exists";
export const checkEarningExists = (Name, Id = 0) =>
  axios.post(BASE_URL + SYSTEM_EARNING_EXISTS_PATH, { Id, Name }, config);

const COMPANY_EARNINGS_VALIDATE_CALCULATION_METHOD_PATH =
  "/v1/subscriber/company/earning/action/validate/calculationmethod";
export const checkEarningsCalculationMethodChange = Data =>
  axios.post(
    BASE_URL + COMPANY_EARNINGS_VALIDATE_CALCULATION_METHOD_PATH,
    Data,
    config
  );

const CREATE_EARNING_PATH = "/v1/system/earning/true";
export const createEarning = data =>
  axios.post(BASE_URL + CREATE_EARNING_PATH, data);

export const updateEarning = (id, data) =>
  axios.patch(BASE_URL + SYSTEM_EARNINGS_PATH + "/" + id, data, config);

const SUBCATEGORY_PATH = "/v1/system/earningsubcategory";
export const getSubcategories = makeGet(SUBCATEGORY_PATH);

const TAX_OPTIONS_PATH = "/v1/system/earningtaxoption";
export const getTaxOptions = makeGet(TAX_OPTIONS_PATH);

const TAX_OPTIONS_EXCLUDED_PATH = "/v1/system/earningtaxoptionexcluded";
export const getTaxOptionsExcluded = makeGet(TAX_OPTIONS_EXCLUDED_PATH);

const CALCULATION_METHOD_PATH = "/v1/system/earningcalculationbasis";
export const getCalculationMethods = makeGet(CALCULATION_METHOD_PATH);

const CYCLE_PATH = "/v1/system/earningcycle";
export const getCycles = makeGet(CYCLE_PATH);

const SUBCYCLE_PATH = "/v1/system/earningsubcycle";
export const getSubcycles = makeGet(SUBCYCLE_PATH);

export const fetchEarning = id =>
  axios.get(BASE_URL + SYSTEM_EARNINGS_PATH + "/" + id);

/** Used on the Company Earnings listing page */
const COMPANY_EARNINGS_PATH = "/v1/subscriber/company/earning";
export const getCompanyEarnings = () =>
  axios.get(BASE_URL + COMPANY_EARNINGS_PATH + "?filter=All");

export const fetchCompanyEarning = id =>
  axios.get(BASE_URL + COMPANY_EARNINGS_PATH + "/" + id);

const defaultSearchParams = {
  Fields: ["*"],
  Criterion: [],
  Sort: [],
  PageSize: 5
};

const COMPANY_EARNINGS_SEARCH_PATH = "/v1/subscriber/company/earning/search";
export const searchCompanyEarnings = (params = defaultSearchParams) =>
  axios.post(
    BASE_URL + COMPANY_EARNINGS_SEARCH_PATH,
    defaultSearchParams,
    config
  );

export const deleteCompanyEarning = id =>
  axios.delete(BASE_URL + COMPANY_EARNINGS_PATH + "/" + id);

const COMPANY_EARNINGS_INACTIVATE_PATH =
  "/v1/subscriber/company/earning/action/makeinactive";
export const inactivateCompanyEarning = id =>
  axios.post(BASE_URL + COMPANY_EARNINGS_INACTIVATE_PATH, { Id: id }, config);

const COMPANY_EARNINGS_ACTIVATE_PATH =
  "/v1/subscriber/company/earning/action/makeactive";
export const activateCompanyEarning = id =>
  axios.post(BASE_URL + COMPANY_EARNINGS_ACTIVATE_PATH, { Id: id }, config);
const CHECK_IF_USED_PATH = "/v1/subscriber/company/earning/action/validate";
export const checkIfUsedAnywhere = id =>
  axios.post(BASE_URL + CHECK_IF_USED_PATH, id, config);
/** Used on Company Level Setup page */
const COMPANY_EARNINGS_EXISTS_PATH =
  "/v1/subscriber/company/earning/action/exists";

export const checkCompanyEarningsExists = (name, id = 0) =>
  axios.post(
    BASE_URL + COMPANY_EARNINGS_EXISTS_PATH,
    { Id: id, Name: name },
    config
  );

const CREATE_COMPANY_EARNING_PATH = "/v1/subscriber/company/earning/true";
export const createCompanyEarning = data =>
  axios.post(BASE_URL + CREATE_COMPANY_EARNING_PATH, data);
const FETCH_HOURLY_RATE = "/v1/subscriber/company/earning/hourlyrate";
export const getHourlyRate = makeGet(FETCH_HOURLY_RATE);
const CHECK_HOURLY_RATE =
  "/v1/subscriber/company/earning/action/existshourlyrate";
export const checkHourlyRateExists = (name, id = 0) =>
  axios.post(BASE_URL + CHECK_HOURLY_RATE, { Id: id, Name: name }, config);

const ADD_HOURLY_RATE_PATH = "/v1/subscriber/company/earning/hourlyrate";
export const addHourlyRate = data =>
  axios.post(BASE_URL + ADD_HOURLY_RATE_PATH, data);
export const updateCompanyEarning = (id, data) =>
  axios.patch(BASE_URL + COMPANY_EARNINGS_PATH + "/" + id, data, config);

export const EARNING_GROUP_PATH =
  "/v1/subscriber/company/earninggroup?filter=all";
export const getEarningGroups = makeGet(EARNING_GROUP_PATH);
export const getEntitySnapshot = (entityId, activityInstanceId) =>
  axios.get(
    BASE_URL +
      `/v1/app/ui/activityhistory/${entityId}/activityinstance/${activityInstanceId}/snapshot`
  );
export const getCompanyEarningSnapshot = activityInstanceId =>
  axios.get(
    BASE_URL + `/v1/subscriber/company/earning/${activityInstanceId}/snapshot`
  );
/*Usertesting Paths*/
const GET_NOTIFICATIONS_PATH = "/v1/app/ui/jobstatusnotification";

const COPY_EARNING_PATH = "/v1/job/action/copyearning";
const sampleBody = {
  SourceSubcriberId: 0,
  SourceSubcriberName: "string",
  SourceCompanyId: 0,
  SourceCompanyName: "string",
  SourceEarningIdList: [0],
  DestinationSubscriberId: 0,
  DestinationSubscriberName: "string",
  DestinationCompanyId: 0,
  DestinationCompanyName: "string"
};
export const startCopyOperation = () =>
  axios.post(BASE_URL + COPY_EARNING_PATH, sampleBody, config);

export const getNotifications = makeGet(GET_NOTIFICATIONS_PATH);
if (process.env.NODE_ENV === "development") {
  /** Exposing these globally for testing */
  window.earnings = {
    checkEarningExists,
    createEarning,
    fetchEarning,
    updateEarning,
    getEarnings,
    getEarningsByCompId,

    getCategories,
    getSubcategories,
    getTaxOptions,
    getTaxOptionsExcluded,
    getCalculationMethods,
    getCycles,
    getSubcycles,
    checkHourlyRateExists,
    getTemplateCategories,
    getTemplateSubcategories,

    getCompanyEarnings,
    fetchCompanyEarning, // Working (but no 'Active', 'In progress' etc.)
    searchCompanyEarnings, // Not working
    deleteCompanyEarning,
    inactivateCompanyEarning, // Seems to be working
    activateCompanyEarning,

    checkCompanyEarningsExists,
    createCompanyEarning,
    getHourlyRate,
    getEarningGroups,
    addHourlyRate,
    updateCompanyEarning,
    checkIfUsedAnywhere,
    //For user testing
    getNotifications
  };
}
