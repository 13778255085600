import React from "react";
import UserDetails from "../../activity-history/UserDetails";
import UnapprovedPtosSubitem from "./UnapprovedPtosSubitem";
import PtoApproveActions from "./PtoApproveActions";
import * as util from "../../util";
import "./UnapprovedPtosItem.css";
const UnapprovedPtosItem = ({
  EmployeeId,
  EmployeeName,
  EmployeePictureUrl,
  LeaveRequestList,
  ApproverList,
  selected,
  onSelect,
  onApprove,
  onBanOperation,
  onContactClick
}) => (
  <div className="MultiSectionTableItem col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <UserDetails
      id={EmployeeId}
      name={EmployeeName}
      picUrl={
        util.isNullOrEmpty(EmployeePictureUrl)
          ? "https://goo.gl/4AYQmG"
          : EmployeePictureUrl
      }
      hasCheckbox
      selected={selected}
      className="col-lg-2 col-md-2 col-sm-10 col-xs-10 pto-employee"
      parent="dashBoard"
      onSelect={() => onSelect(EmployeeId, !selected)}
    />
    <div className="col-sm-2 col-xs-2 hidden-md hidden-lg">
      <PtoApproveActions
        onApprove={onApprove}
        onBanOperation={onBanOperation}
        onContactClick={onContactClick}
        hideBan
        hideApprove
        noPadding
      />
    </div>
    <div
      className="col-md-9 col-lg-9 sub-item-container unapproved-pto-row"
      style={{ paddingBottom: 0 }}
    >
      {LeaveRequestList.map((item, i) => (
        <UnapprovedPtosSubitem key={i} {...item} />
      ))}
    </div>
    <div
      className="col-md-1 col-lg-1 hidden-sm hidden-xs"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
      }}
    >
      {LeaveRequestList.map((item, i) => (
        <PtoApproveActions
          key={i}
          onApprove={onApprove}
          onBanOperation={onBanOperation}
          onContactClick={onContactClick}
          hideBan
          hideApprove
          noPadding
        />
      ))}
    </div>
  </div>
);

export default UnapprovedPtosItem;
