/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Panel, Overlay } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { Badge } from "react-bootstrap";
import { setSelectedFilters } from "../../actions/generalledger/EmployeeLiabilityAccountsActions.js";
import { getEmployeeDeductionFilterList } from "../../actions/generalledger/EmployeeLiabilityAccountsActions.js";
import { Checkbox, CheckboxGroup } from "react-checkbox-group";
import { Scrollbars } from "react-custom-scrollbars";
import * as utilities from "../../../../app/common/utilities";
import CustomOverlay from "component-library/dropDown/CustomOverlay";
const ALL_DEDUCTIONS = 0;
const DEDUCTION_PANEL_ID = 1;

let isAllUncheckedZero = false;

class GeneralLedgerDeductionDropdownEmployeeTab extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      autoSuggestionDeductionList: [],
      selectedDeductions: props.selectedDeductions,
      selectDeductionLabel: "All Deductions",
      isAllSelected: true,
      autoSuggestionTextBox: ""
    };
    this.checkSelectDeduction = this.checkSelectDeduction.bind(this);
    this.renderDeductionSelectionList = this.renderDeductionSelectionList.bind(
      this
    );
    this.handleChange = this.handleChange.bind(this);
    this.onCloseDeductionDropdown = this.onCloseDeductionDropdown.bind(this);
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  handleChange(event) {
    let strValue = event.target.value;
    let arrayList = [];
    this.setState({ autoSuggestionTextBox: strValue });
    this.props.employeeDeductionFilterList.forEach(item => {
      if (
        item.CompanyDeductionName.toLowerCase().indexOf(
          strValue.toLowerCase()
        ) >= 0
      ) {
        arrayList.push(item);
      }
    });
    this.setState({ autoSuggestionDeductionList: arrayList });
  }

  checkSelectDeduction(newDeduction) {
    let deductionLabel =
      newDeduction.length > 1
        ? "Deductions"
        : newDeduction.length === 0
          ? "Select Deduction"
          : "Deduction";
    if (newDeduction.includes(ALL_DEDUCTIONS)) {
      let indexOfAllDeduction = newDeduction.indexOf(ALL_DEDUCTIONS);
      if (
        indexOfAllDeduction !== 0 ||
        this.state.selectedDeductions.length === 0
      ) {
        newDeduction = [];
        for (
          let i = 0;
          i < this.props.employeeDeductionFilterList.length;
          i++
        ) {
          let CompanyDeductionId = this.props.employeeDeductionFilterList[i]
            .CompanyDeductionId;
          newDeduction.push(CompanyDeductionId);
        }

        this.setState({
          selectedDeductions: newDeduction,
          selectDeductionLabel: "All Deductions",
          isAllSelected: true
        });
      } else {
        newDeduction.splice(0, 1);
        this.setState({
          selectedDeductions: newDeduction,
          selectDeductionLabel: deductionLabel,
          isAllSelected: false
        });
      }
    } else if (this.state.isAllSelected && !newDeduction.includes(0)) {
      this.setState({
        selectedDeductions: [],
        selectDeductionLabel: "Select Deduction",
        isAllSelected: false
      });
    } else {
      if (
        this.props.employeeDeductionFilterList.length - 1 ===
          newDeduction.length &&
        !newDeduction.includes(ALL_DEDUCTIONS)
      ) {
        newDeduction = [];
        for (
          let i = 0;
          i < this.props.employeeDeductionFilterList.length;
          i++
        ) {
          let CompanyDeductionId = this.props.employeeDeductionFilterList[i]
            .CompanyDeductionId;
          newDeduction.push(CompanyDeductionId);
        }
        this.setState({
          selectedDeductions: newDeduction,
          selectDeductionLabel: "All Deductions",
          isAllSelected: true
        });
      } else {
        this.setState({
          selectedDeductions: newDeduction,
          selectDeductionLabel: deductionLabel,
          isAllSelected: false
        });
      }
    }

    if (
      newDeduction.length === 0 ||
      (this.state.isAllSelected === true &&
        newDeduction.includes(ALL_DEDUCTIONS) === false &&
        newDeduction.indexOf(ALL_DEDUCTIONS) === -1 &&
        newDeduction.length ===
          this.props.employeeDeductionFilterList.length - 1)
    ) {
      isAllUncheckedZero = true;
    } else {
      isAllUncheckedZero = false;
    }
    this.setState({ popoverOpen: true });
  }

  componentWillMount() {}

  componentDidMount() {
    this.props.getEmployeeDeductionFilterList();

    let selectedDeductions = [];
    if (
      this.props.setSelectedFiltersReducer !== undefined &&
      this.props.setSelectedFiltersReducer !== null &&
      this.props.setSelectedFiltersReducer.length !== 0
    ) {
      if (this.props.setSelectedFiltersReducer.selectedDeductions.length > 0) {
        this.props.setSelectedFiltersReducer.selectedDeductions.forEach(
          selectedItem => {
            selectedDeductions.push(selectedItem);
          }
        );
      }
    } else {
      if (this.props.employerDeductionsOriginalList.Data) {
        selectedDeductions[0] = 0;
        this.props.employerDeductionsOriginalList.Data.forEach(item => {
          selectedDeductions.push(item.CompanyDeductionId);
        });
      }
    }

    let selectDeductionLabel = "Deductions";
    let isAllSelected = false;

    if (
      this.props.employeeDeductionFilterList.length ===
      selectedDeductions.length
    ) {
      selectDeductionLabel = "All Deductions";
      isAllSelected = true;
    } else if (selectedDeductions.length === 0) {
      selectDeductionLabel = "Select Deductions";
      isAllSelected = false;
    } else if (selectedDeductions.length === 1) {
      selectDeductionLabel = "Deduction";
      isAllSelected = false;
    } else {
      selectDeductionLabel = "Deductions";
      isAllSelected = false;
    }

    let arrayList = [];
    this.props.employeeDeductionFilterList.forEach(item => {
      arrayList.push(item);
    });
    this.setState({
      selectedDeductions,
      autoSuggestionDeductionList: arrayList,
      selectDeductionLabel,
      isAllSelected
    });

    this.props.onSelectedDeductions(selectedDeductions);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.employerDeductionsOriginalList !==
      this.props.employerDeductionsOriginalList
    ) {
      let selectedDeductions = [];
      selectedDeductions[0] = 0;
      if (nextProps.employerDeductionsOriginalList.Data) {
        nextProps.employerDeductionsOriginalList.Data.forEach(item => {
          selectedDeductions.push(item.CompanyDeductionId);
        });
      }

      let selectDeductionLabel = "Deductions";
      let isAllSelected = false;

      if (
        nextProps.employerDeductionsOriginalList.DataCount + 1 ===
        selectedDeductions.length
      ) {
        selectDeductionLabel = "All Deductions";
        isAllSelected = true;
      } else {
        selectDeductionLabel = "Deductions";
        isAllSelected = false;
      }

      let arrayList = [];
      this.props.employeeDeductionFilterList.forEach(item => {
        arrayList.push(item);
      });
      this.setState({
        selectedDeductions,
        autoSuggestionDeductionList: arrayList,
        selectDeductionLabel,
        isAllSelected
      });

      let tempList = [];
      // this.props.employeeDeductionFilterList.forEach(item => {
      //   let valueLabel = {
      //     value: item.CompanyDeductionId,
      //     label: item.CompanyDeductionName
      //   }
      //   tempList.push(valueLabel);
      // });

      tempList = utilities.getValueLabelList(
        this.props.employeeDeductionFilterList,
        "CompanyDeductionId",
        "CompanyDeductionName"
      );

      this.setState({ newList: tempList });
    }

    if (nextProps.isResetToDefault !== this.props.isResetToDefault) {
      if (nextProps.isResetToDefault) {
        let selectedDeductions = [];
        selectedDeductions[0] = 0;
        if (nextProps.employerDeductionsOriginalList.Data) {
          nextProps.employerDeductionsOriginalList.Data.forEach(item => {
            selectedDeductions.push(item.CompanyDeductionId);
          });
        }

        let selectDeductionLabel = "Deductions";
        let isAllSelected = false;

        if (
          nextProps.employerDeductionsOriginalList.DataCount + 1 ===
          selectedDeductions.length
        ) {
          selectDeductionLabel = "All Deductions";
          isAllSelected = true;
        } else {
          selectDeductionLabel = "Deductions";
          isAllSelected = false;
        }

        let arrayList = [];
        this.props.employeeDeductionFilterList.forEach(item => {
          arrayList.push(item);
        });
        this.setState({
          selectedDeductions,
          autoSuggestionDeductionList: arrayList,
          selectDeductionLabel,
          isAllSelected
        });
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedDeductions !== this.state.selectedDeductions) {
      this.props.onSelectedDeductions(this.state.selectedDeductions);
    }
  }

  onCloseDeductionDropdown() {
    this.props.onSelectedDeductions(this.state.selectedDeductions);
  }

  renderDeductionSelectionListMobile() {
    if (!this.props.employeeDeductionFilterList) {
      return <div />;
    } else {
      return (
        <Scrollbars
          autoHeight={true}
          autoHeightMax={320}
          renderThumbHorizontal={props => (
            <div style={{ overflowX: "hidden" }} className="thumb-horizontal" />
          )}
        >
          <CheckboxGroup
            name="select-Deduction"
            value={this.state.selectedDeductions}
            onChange={this.checkSelectDeduction}
            className=""
          >
            <ul className="nc-dropdown-navigation-multiselect">
              {this.renderDeductionSelectionItem(
                this.state.autoSuggestionDeductionList
              )}
            </ul>
          </CheckboxGroup>
        </Scrollbars>
      );
    }
  }

  renderDeductionSelectionList() {
    if (!this.props.employeeDeductionFilterList) {
      return <div />;
    } else {
      return (
        <CustomOverlay width="300px">
          <div className="form-group">
            <div className="field">
              <input
                id="deduction-auto-suggestion-textbox"
                type="text"
                name="autoSuggestionTextBox"
                className="form-control"
                placeholder="&#xF002; Search"
                onChange={this.handleChange}
                value={this.state.autoSuggestionTextBox}
              />
            </div>
          </div>
          <Scrollbars
            autoHeight={true}
            autoHeightMax={320}
            renderThumbHorizontal={props => (
              <div
                style={{ overflowX: "hidden" }}
                className="thumb-horizontal"
              />
            )}
          >
            <CheckboxGroup
              name="select-Deduction"
              value={this.state.selectedDeductions}
              onChange={this.checkSelectDeduction}
              className=""
            >
              <ul className="nc-dropdown-navigation-multiselect">
                {this.renderDeductionSelectionItem(
                  this.state.autoSuggestionDeductionList
                )}
              </ul>
            </CheckboxGroup>
          </Scrollbars>
        </CustomOverlay>
      );
    }
  }

  renderDeductionSelectionItem(deductionDropDownList) {
    return deductionDropDownList.map((item, index) => {
      return (
        <li key={index} className="list-item">
          <Checkbox
            id={item.CompanyDeductionId}
            value={item.CompanyDeductionId}
          />
          <label
            htmlFor={item.CompanyDeductionId}
            id={
              item.CompanyDeductionName.replace(/ /g, "-").toLowerCase() +
              "-deduction"
            }
          >
            {item.CompanyDeductionName}
          </label>
        </li>
      );
    });
  }

  openSubPanelById(id) {
    let update = {};

    if (id === this.state.subPanelId) {
      update["subPanelId"] = 0;
    } else {
      update["subPanelId"] = id;
    }

    this.setState(update);
  }

  render() {
    const deductionSelectionPanel = this.renderDeductionSelectionList();

    if (!this.props.employeeDeductionFilterList) {
      return <div>No Records Found.</div>;
    }
    if (window.innerWidth <= 991) {
      return (
        <div>
          <div className="mobile-filter-panel-list-section">
            <a
              id=""
              onClick={this.openSubPanelById.bind(this, DEDUCTION_PANEL_ID)}
            >
              <div className="mobile-filter-panel-list-panel-bar col-xs-12 col-sm-12">
                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-icon">
                  {this.state.subPanelId === DEDUCTION_PANEL_ID ? (
                    <i
                      className="fa fa-chevron-up add_deduction_popup_panel_link"
                      aria-hidden="true"
                    />
                  ) : (
                    <i
                      className="fa fa-chevron-down add_deduction_popup_panel_link"
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="col-xs-8 col-sm-8 mobile-filter-panel-list-label no-padding">
                  {this.state.selectDeductionLabel}
                </div>
                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-badge no-padding">
                  {isAllUncheckedZero ? null : this.state
                    .isAllSelected ? null : (
                    <Badge className="filter-select-badge">
                      {this.state.selectedDeductions.length}
                    </Badge>
                  )}
                </div>
              </div>
            </a>
            <div className="mobile-filter-panel-list-panel-bar-section">
              <Panel
                className="mobile-filter-panel-list-panel-default no-padding col-xs-12 col-sm-12 col-md-12 col-lg-12 "
                collapsible
                expanded={
                  this.state.subPanelId === DEDUCTION_PANEL_ID ? true : false
                }
              >
                <Panel.Collapse>
                  <Panel.Body>
                    <div className="form-group">
                      <div className="field">
                        <input
                          id="deduction-auto-suggestion-textbox"
                          type="text"
                          name="autoSuggestionTextBox"
                          className="form-control"
                          placeholder="&#xF002; Search"
                          onChange={this.handleChange}
                          value={this.state.autoSuggestionTextBox}
                        />
                      </div>
                    </div>
                    {this.renderDeductionSelectionListMobile()}
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="">
          <div className="nc-select-container hidden-sm hidden-xs">
            <Button
              id={this.props.Id}
              className="filter-select-button"
              onClick={this.toggle}
              ref={button => (this.deductionFilter = button)}
            >
              <div
                style={{
                  width: isAllUncheckedZero
                    ? "90%"
                    : this.state.isAllSelected
                      ? "90%"
                      : "70%"
                }}
                className="filter-select-label col-md-8 col-lg-8"
              >
                {this.state.selectDeductionLabel}
              </div>
              {isAllUncheckedZero ? null : this.state.isAllSelected ? null : (
                <div style={{ width: "20%" }} className="col-md-2 col-lg-2">
                  <Badge className="filter-select-badge">
                    {this.state.selectedDeductions.length}
                  </Badge>
                </div>
              )}

              <div style={{ width: "10%" }} className="col-md-1 col-lg-1">
                <i className="filter-select-dropdown-icon fa fa-chevron-down" />
              </div>
            </Button>
          </div>
          <Overlay
            show={this.state.popoverOpen}
            onHide={() => this.setState({ popoverOpen: false })}
            placement="bottom"
            container={this}
            rootClose={true}
            target={this.deductionFilter}
          >
            {deductionSelectionPanel}
          </Overlay>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  let deductionDropDownList = [
    {
      CompanyDeductionId: 0,
      CompanyDeductionName: "All Deductions"
    }
  ];

  let selectedDeductions = [];
  selectedDeductions[0] = 0;
  if (state.generalLedgerReducer.employeeDeductionFilterList.Data) {
    state.generalLedgerReducer.employeeDeductionFilterList.Data.forEach(
      item => {
        deductionDropDownList.push(item);
        selectedDeductions.push(item.CompanyDeductionId);
      }
    );
  }

  return {
    employeeDeductionFilterList: deductionDropDownList,
    selectedDeductions: selectedDeductions,
    employerDeductionsOriginalList:
      state.generalLedgerReducer.employeeDeductionFilterList,
    setSelectedFiltersReducer:
      state.generalLedgerReducer.setSelectedFiltersReducer
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEmployeeDeductionFilterList: getEmployeeDeductionFilterList,
      setSelectedFilters
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(
  GeneralLedgerDeductionDropdownEmployeeTab
);
