import { combineReducers } from "redux";
import entityData from "./entityData";
import entityErrors from "./entityErrors";

const entitySetup = combineReducers({
  data: entityData,
  errors: entityErrors
});

export default entitySetup;
