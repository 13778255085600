export function setStepState(stepId, activeStep, draftId) {
  let stepState = draftId
    ? draftId + 1 >= stepId
      ? activeStep === stepId
        ? 1
        : 2
      : 0
    : activeStep === stepId
      ? 1
      : 0;
  return stepState;
}
export function setStepClass(stepState, cx) {
  let stepClass = cx(
    "step-menu-item",
    { active: stepState === 1 },
    { accessible: stepState === 2 },
    { inaccessible: stepState === 0 }
  );
  return stepClass;
}
