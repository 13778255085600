import React, { Component } from "react";
import PropTypes from "prop-types";
import JobDetailsItem from "./JobDetailsItem";
import JobDetailsNote from "./JobDetailsNote";
import ToggleExpandButton from "./ToggleExpandButton";
import { COLLAPSED_SIZE } from "./constants";

import "./JobDetails.css";

export default class JobDetails extends Component {
  state = {
    expanded: false
  };

  toggleExpanded = e => {
    e.preventDefault();
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const {
      ChangeLogAsValueNodes: ChangeLog = [],
      ActivityInstanceDescription,
      Notes = ""
    } = this.props.data;
    const items = this.state.expanded
      ? ChangeLog
      : ChangeLog.slice(0, COLLAPSED_SIZE);
    return (
      <div className="col-lg-8 col-md-8 col-sm-12">
        <div className="job-details-title">{ActivityInstanceDescription}</div>
        {items.length > 0 && (
          <div>
            <div className="table nc-activity-history-table">
              <div className="thead hidden-sm hidden-xs">
                <div className="tr">
                  <div className="th col-lg-4 col-md-4">Field</div>
                  <div className="th col-lg-4 col-md-4">Before</div>
                  <div className="th col-lg-4 col-md-4">After</div>
                </div>
              </div>
            </div>
            {items.map((item, i) => (
              <JobDetailsItem key={i} data={item} onSave={() => {}} />
            ))}
            <JobDetailsNote text={Notes} onSave={() => {}} />
            <ToggleExpandButton
              show={ChangeLog.length > COLLAPSED_SIZE}
              expanded={this.state.expanded}
              onClick={this.toggleExpanded}
            />
          </div>
        )}
      </div>
    );
  }
}

JobDetails.propTypes = {
  data: PropTypes.object // TODO (arao): Provide proper shape here
};
