import React from "react";
import PropTypes from "prop-types";
import "./styles.css";
const StatusOverlay = ({ title, bsClass, children, onCloseOverlay }) => {
  return (
    <div className={bsClass}>
      <div className={bsClass + "-header"}>
        {title} <i onClick={onCloseOverlay} className="fa fa-times-circle-o" />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default StatusOverlay;
StatusOverlay.propTypes = {
  title: PropTypes.string
};
StatusOverlay.defaultProps = {
  title: "Change Status",
  bsClass: "status-overlay"
};
