export const SEND_MESSAGE_CONTACT_APPROVER =
  "payroll/dashboard/SEND_MESSAGE_CONTACT_APPROVER";

//Actions
export const setSendMessageToApprover = (messageStatus = false) => ({
  type: SEND_MESSAGE_CONTACT_APPROVER,
  payload: messageStatus
});

const contactApprovers = (state = false, { type, payload }) => {
  switch (type) {
    case SEND_MESSAGE_CONTACT_APPROVER: {
      return payload;
    }
    default:
      return state;
  }
};
export default contactApprovers;
