import React, { Component } from "react";
import {
  FormSection,
  FormFieldGroup
} from "component-library/formSections/index";
import * as FIELDS from "../utils/Constants";
import { DifferentName, HourlyRate, EmploymentDates } from "./Components";

export default class JobDetails extends Component {
  render() {
    const { data = {}, onChange, errors } = this.props;
    return (
      <div className="tab-container-add-sub-section">
        <div className="section-items">
          <FormSection title="Job Details">
            <FormFieldGroup
              inputType="text"
              inputName={FIELDS.POSITION}
              value={data[FIELDS.POSITION]}
              label={"Position"}
              onChange={onChange}
              Size={[4, 4, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              error={errors[FIELDS.STATE]}
              required={true}
            />

            <EmploymentDates
              label={FIELDS.EMPLOYMENT_DATES_LABEL}
              data={data}
              onChange={onChange}
              tooltip={"Tooltip"}
              errors={errors}
              required={true}
            />

            <FormFieldGroup
              inputType="textarea"
              inputName={FIELDS.RESPONSIBILITIES}
              value={data[FIELDS.RESPONSIBILITIES]}
              label={FIELDS.RESPONSIBILITIES_LABEL}
              onChange={onChange}
              Size={[7, 7, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              optional={true}
              rows={3}
              description="250 Character max"
              descriptionStyle="italic"
            />

            <HourlyRate data={data} onChange={onChange} />

            <FormFieldGroup
              inputType="textarea"
              inputName={FIELDS.REASON_FOR_LEAVING}
              value={data[FIELDS.REASON_FOR_LEAVING]}
              label={FIELDS.REASON_FOR_LEAVING_LABEL}
              onChange={onChange}
              Size={[7, 7, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              optional={true}
              rows={3}
              description="250 Character max"
              descriptionStyle="italic"
            />

            <DifferentName data={data} onChange={onChange} />

            {data[FIELDS.DIFFERENT_NAME] && (
              <FormFieldGroup
                inputType="text"
                inputName={FIELDS.PREVIOUS_NAME}
                value={data[FIELDS.PREVIOUS_NAME]}
                label={FIELDS.PREVIOUS_NAME_LABEL}
                onChange={onChange}
                Size={[2, 2, 7, 12]}
                fieldType="inline"
                colSpan="col-lg-3 col-md-3 col-sm-3"
                optional={true}
              />
            )}
          </FormSection>
        </div>
      </div>
    );
  }
}
