import React, { Component } from "react";
import classNames from "classnames/bind";
import InputField from "component-library/inputFields/InputField";
import Toaster from "component-library/toasters";
import * as FIELDS from "../utils/Constants";
import DropDown from "component-library/dropDown";
import "../../styles.css";

const options = [{ id: 1, value: 1, label: "Other" }];

class VerifiedBy extends Component {
  state = { showTooltip: false };
  showTooltipHandler = () => {
    this.setState({
      showTooltip: !this.state.showTooltip
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        showTooltip: false
      });
    }
  }

  render() {
    const { props } = this;
    let lgClass = "col-lg-4";
    let mdClass = "col-md-6";
    let smClass = "col-sm-4";
    let xsClass = "col-xs-12";
    if (props.Size) {
      if (props.Size.length > 0) {
        lgClass = props.Size[0] && "col-lg-" + props.Size[0];
        mdClass = props.Size[1] && "col-md-" + props.Size[1];
        smClass = props.Size[2] && "col-sm-" + props.Size[2];
        xsClass = props.Size[3] && "col-xs-" + props.Size[3];
      }
    }

    let wrapperClass;
    if (props.class === undefined) {
      wrapperClass = "nc-field-group clearfix";
    } else {
      wrapperClass = "nc-field-group " + props.class + " clearfix";
    }
    if (
      (props.value == null || props.value === "") &&
      props.required &&
      props.error
    ) {
      wrapperClass = wrapperClass + " has-error " + props.inputName;
    }
    const InputFieldClass = classNames(
      "input-field",
      props.className,
      lgClass,
      mdClass,
      smClass,
      xsClass
    );
    return (
      <div
        className={wrapperClass}
        style={{ clear: "both", display: "flex", alignItems: "center" }}
      >
        {props.Toaster &&
          props.Toaster.show && (
            <Toaster
              show={props.Toaster.show}
              alertClassName={props.Toaster.type}
              message={props.Toaster.content}
            />
          )}
        <div
          id={"Label" + props.inputName}
          style={{ paddingRight: "15px" }}
          className={"col-lg-3 col-md-3 col-sm-3 field-name clearfix"}
        >
          {props.label}
          {props.optional && <i className="optional-field">(Optional) </i>}{" "}
        </div>
        {props.inputType === "Label" ? (
          <div style={{ marginBottom: "15px" }}>{props.value}</div>
        ) : (
          <div
            className={"col-lg-9 col-md-9 col-sm-9 basic-info-name"}
            style={{ clear: "both", display: "flex" }}
          >
            <DropDown
              options={options}
              //selectedItems={props.data[FIELDS.VERIFIED_BY_ID] || {}}
              value={props.data[FIELDS.VERIFIED_BY_ID]}
              id={"dd-from-months"}
              className={InputFieldClass}
              onSelect={e =>
                props.onChange &&
                props.onChange({
                  name: FIELDS.VERIFIED_BY_ID,
                  value: e
                })
              }
            />
            {props.data[FIELDS.VERIFIED_BY_ID] === 1 && (
              <InputField
                {...props}
                onChange={e =>
                  props.onChange &&
                  props.onChange({
                    name: FIELDS.VERIFIED_BY_NAME,
                    value: e.value
                  })
                }
                onSelect={props.onChange}
                inputName={props.inputName}
                className={InputFieldClass}
                value={props.data[FIELDS.VERIFIED_BY_NAME]}
                id={props.inputName}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default VerifiedBy;
