import React from "react";
import Media from "react-media";
import EntityListFiltersDesktop from "./EntityListFiltersDesktop";
import EntityListFiltersMobile from "./EntityListFiltersMobile";

const EntityListFilters = props => (
  <Media query="(max-width: 991px)">
    {isMobile => {
      const Filters = isMobile
        ? EntityListFiltersMobile
        : EntityListFiltersDesktop;
      return <Filters {...props} />;
    }}
  </Media>
);

export default EntityListFilters;
