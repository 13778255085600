/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import moment from "moment";
import { Overlay, Popover } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomOverlay from "../payroll-holiday-calendar/AddCalendar/CustomOverlay";
import { FormSection, FormFieldGroup } from "../formSections/index";

const tooltip_thrash_icon = <Tooltip id="tooltip_thrash_icon">Delete</Tooltip>;
const tooltip_edit_icon = <Tooltip id="tooltip_edit_icon">Edit</Tooltip>;

const componentProps = {
  show: true,
  inputType: "text",
  inputId: "FileDescription",
  inputName: "FileDescription",
  label: "Name this file",
  description: "100 characters max",
  descriptionStyle: "italic",
  Size: [8, 7, 6, 12],
  maxLength: "100",
  required: true
};

const Footer = ({ onSaveClick, onCancel }) => (
  <div className="popover-button-group">
    <button
      onClick={onSaveClick}
      type="button"
      id="popover-save-button"
      className="nc-button secondary-button "
    >
      Save Changes
    </button>
    <button
      onClick={onCancel}
      type="button"
      id="popover-cancel-button"
      className="secondary-link-button"
      style={{ marginLeft: "30px" }}
    >
      Cancel
    </button>
  </div>
);

export default class EmployeeFilesListItem extends Component {
  state = {
    onEditClick: false
  };

  render() {
    const { onDeleteClick, onChange, onSaveClick, errors = {} } = this.props;
    var fileTypeName = "";
    if (this.props.FileExtension === "pdf") {
      fileTypeName = "file-pdf-o";
    } else if (
      this.props.FileExtension === "txt" ||
      this.props.FileExtension === "docx"
    ) {
      fileTypeName = "file-text-o";
    } else if (
      this.props.FileExtension === "png" ||
      this.props.FileExtension === "jpg" ||
      this.props.FileExtension === "jpeg"
    ) {
      fileTypeName = "file-image-o";
    }

    return (
      <div className="table-row col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 name-column clearfix">
          <a
            className="employe-files-tile"
            href={this.props.FileLocation}
            target="_blank"
            rel="noopener noreferrer"
            download={this.props.FileName}
          >
            <i
              className={`fa fa-${fileTypeName}`}
              style={{ marginRight: "5px" }}
            />{" "}
            {this.props.FileDescription}
          </a>
          <span
            ref={target => (this.target = target)}
            className="edit-calendar"
            style={{ position: "relative" }}
          >
            <OverlayTrigger placement="top" overlay={tooltip_edit_icon}>
              <a style={{ marginLeft: "20px" }}>
                <i
                  style={{ color: "#555" }}
                  id="edit"
                  className="fa fa-pencil"
                  onClick={e => {
                    this.setState({
                      onEditClick: !this.state.onEditClick
                    });
                  }}
                />
              </a>
            </OverlayTrigger>
            <Overlay
              show={this.state.onEditClick}
              placement="bottom"
              container={this}
              target={this.target}
              onHide={() =>
                this.setState({
                  onEditClick: false
                })
              }
              rootClose={true}
            >
              <Popover id="popover-positioned-bottom">
                <CustomOverlay>
                  <div class="employee-file-popover">
                    <div className="popover-header">
                      <span className="close-icon">
                        <i
                          id="popover-close-icon"
                          className="fa fa-times-circle"
                          onClick={() => {
                            this.setState({
                              onEditClick: false
                            });
                          }}
                        />
                      </span>
                    </div>
                    <FormSection>
                      {" "}
                      <FormFieldGroup
                        {...componentProps}
                        value={this.props[componentProps.inputName]}
                        onChange={onChange}
                        class="name-holiday-calendar"
                        error={errors[componentProps.inputName]}
                      />
                    </FormSection>
                    <Footer
                      onSaveClick={() => {
                        this.setState({
                          onEditClick: false
                        });
                        onSaveClick();
                      }}
                      onCancel={() => {
                        this.setState({
                          onEditClick: false
                        });
                      }}
                    />
                  </div>
                </CustomOverlay>
              </Popover>
            </Overlay>
          </span>

          <div className="employe-files-name">{this.props.FileName}</div>
        </div>

        <div className="employee-file-timestamp hidden-xs col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <div style={{ float: "right", paddingTop: "15px" }}>
            uploaded on{" "}
            {moment(this.props.UpdatedDateTime).format(
              "MMM DD, YYYY [at] h:mm a z"
            )}
          </div>
        </div>

        <div className="list-page-grid-table-cell-actions employee-file-action col-lg-1 col-md-1 col-sm-1 col-xs-1">
          <div className="list-page-delete-section" style={{ float: "right" }}>
            <OverlayTrigger placement="top" overlay={tooltip_thrash_icon}>
              <a
                href="."
                onClick={e => {
                  e.preventDefault();
                  onDeleteClick && onDeleteClick(this.props.FileId);
                }}
              >
                <i
                  id="delete"
                  className="list-page-delete-icon fa fa-trash-o"
                />
              </a>
            </OverlayTrigger>
          </div>
        </div>

        <div className="employee-file-timestamp hidden-sm hidden-md hidden-lg col-xs-12">
          <div>
            uploaded on{" "}
            {moment(this.props.UpdatedDateTime).format(
              "MMM DD, YYYY [at] h:mm a z"
            )}
          </div>
        </div>
      </div>
    );
  }
}
