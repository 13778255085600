import React from "react";

const Seperator = () => {
  return (
    <div className="seperator clearfix">
      <hr />
    </div>
  );
};

export default Seperator;
