import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import SelectedItem from "./SelectedItem";
import DropDownOptions from "./DropDownOptions";
import * as Scroll from "react-scroll";
import "./DropDown.css";
import NoDataDropDownSection from "./NoDataDropDownSection";
import classnames from "classnames/bind";

let scroll = Scroll.animateScroll;
export default class DropDown extends Component {
  state = {
    showOptions: false,
    searchText: ""
  };

  editSearchText = searchText =>
    this.setState(
      { searchText },
      () => this.props.onSearch && this.props.onSearch(searchText)
    );

  setHeight = height => {
    const element = document.getElementById("app-main-page");
    if (element && element.style) {
      element.style.height = height;
    }
  };

  toggleOptions = () => {
    const { showOptions } = this.state;
    const { disabled, scrollBy } = this.props;
    if (!disabled) {
      this.setState({ showOptions: !showOptions, searchText: "" }, () => {
        if (scrollBy) {
          let pixelHeight = 300;
          let height = 0;
          const appMainPage = document.getElementById("app-main-page");
          const element = ReactDOM.findDOMNode(this.dropdownButtonValue);
          const clientTop = element && element.getBoundingClientRect().top;
          const elementHeight =
            element && element.getBoundingClientRect().height;
          const clientBottom = window.innerHeight - clientTop - elementHeight;
          const { clientHeight = window.innerHeight } = appMainPage;
          if (clientTop <= window.innerHeight + pixelHeight) {
            scroll.scrollMore(pixelHeight, { duration: 10, delay: 10 });
          }
          if (this.state.showOptions) {
            if (clientTop > window.innerHeight + pixelHeight) {
              height = "inherit";
            } else {
              height = clientHeight + (pixelHeight - clientBottom) + "px";
            }
          } else {
            height = "inherit";
          }

          this.setHeight(height);
        }
      });
    }
  };

  hideOptions = () => {
    this.setState({ showOptions: false, searchText: "" }, () =>
      setTimeout(() => {
        this.setHeight("inherit");
      }, 300)
    );
    setTimeout(
      function () {
        const { onHide } = this.props;

        onHide && onHide();
      }.bind(this),
      100
    );
  };

  getFilteredOptions = () => {
    let { options, showInactiveOptions = true } = this.props;
    const { searchText } = this.state;
    if (!showInactiveOptions) {
      options = options.filter(function (filteredItem) {
        return filteredItem.isActive === true;
      });
    }
    if (searchText) {
      return options.filter(
        ({ label }) =>
          label.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      );
    }
    return options;
  };

  areAllSelected = () => {
    const { multiSelect, selectedItems = {}, options } = this.props;
    if (!multiSelect) return false;

    let all = true;
    options.forEach(({ value }) => (all = all && selectedItems[value]));
    return all;
  };

  areNoneSelected = () => {
    const { multiSelect, selectedItems = {}, options } = this.props;
    if (!multiSelect) return false;

    let any = false;
    options.forEach(({ value }) => (any = any || selectedItems[value]));
    return !any;
  };

  shouldShowBadge = () => {
    const { showBadge, multiSelect, showSelectAll = true } = this.props;
    return (
      showBadge &&
      multiSelect &&
      (!this.areAllSelected() || !showSelectAll) &&
      !this.areNoneSelected()
    );
  };
  onHandlePress(props, e) {
    let options = props.options;
    let inputValue = [e.key.toUpperCase(), e.key.toLowerCase()];
    var selectedOption;
    let machedList = [];
    for (var i = 0; i < options.length; i++) {
      let label = options[i].label;
      if (label.startsWith(inputValue[0]) || label.startsWith(inputValue[1])) {
        machedList.push(options[i]);
      }
    }
    for (var j = 0; j < machedList.length; j++) {
      if (this.state.selectedValue) {
        if (this.state.selectedValue.value === machedList[j].value) {
          if (machedList[j + 1]) {
            selectedOption = machedList[j + 1];
          } else {
            selectedOption = machedList[0];
          }
          break;
        } else if (
          this.state.selectedValue.label[0] !== machedList[j].label[0]
        ) {
          selectedOption = machedList[j];
          break;
        }
      } else {
        selectedOption = machedList[j];
        break;
      }
    }
    if (selectedOption && selectedOption.value) {
      this.setState({
        selectedValue: selectedOption
      });
      props.onSelect(selectedOption.value);
    }
  }

  render() {
    const { props, state } = this;
    let {
      options,
      multiSelect,
      value,
      selectedItems = {},
      showStatusIcon,
      showDescription,
      disabledDropdown = false,
      showSelectAll = true,
      showDesktopDropdownFor768 = false,
      showNoDataPlaceholder = false
    } = props;
    let label = props.value || props.placeholder;
    let statusIcon = "";
    let description = "";
    if (props.makeLabel) {
      label = props.makeLabel(options, selectedItems, this.areAllSelected());
    }
    if (value && showStatusIcon) {
      const filteredOptions = options.filter(item => item.value === value);
      if (filteredOptions.length > 0) {
        statusIcon = filteredOptions[0].statusIcon;
      }
    }
    if (value && showDescription) {
      const filteredOptions = options.filter(item => item.value === value);
      if (filteredOptions.length > 0) {
        description = filteredOptions[0].description;
      }
    }
    if (!multiSelect && value) {
      selectedItems = { [value]: true };
      options
        .filter(op => op.value === value)
        .forEach(op => (label = op.label));
    }
    if (showNoDataPlaceholder && options.length <= 0) {
      return (
        <div
          className={classnames(
            props.ncStyle === "lg"
              ? "ncl-select-container-lg"
              : "ncl-select-container",
            props.className,
            props.theme === "default" && "__default"
          )}
        >
          <NoDataDropDownSection isMobile={this.state.device === "xs"}>
            {props.noDataLabel || "No Data Available"}
          </NoDataDropDownSection>
        </div>
      );
    }
    return (
      <div
        onKeyDown={e => {
          if (!this.props.showSearch && !this.props.multiSelect) {
            this.onHandlePress(this.props, e);
          }
        }}
        className={"nc-select-container " + props.className}
        ref={button => (this.dropdownButtonValue = button)}
      >
        {props.disabled && !disabledDropdown ? (
          value === null || value === 0 ? (
            <SelectedItem
              toggleOptions={disabledDropdown === false && this.toggleOptions}
              label="Select"
              isOpen={state.showOptions}
              showBadge={this.shouldShowBadge()}
              badgeCount={
                Object.keys(selectedItems).filter(k => selectedItems[k]).length
              }
              description={description}
              subTitle={props.subTitle}
              disabled={props.disabled}
              buttonId={props.id}
              dropDownId={props.dropDownId ? props.dropDownId : null}
              statusIcon={statusIcon}
            />
          ) : (
              //label
              <SelectedItem
                toggleOptions={disabledDropdown === false && this.toggleOptions}
                label={label}
                isOpen={state.showOptions}
                showBadge={this.shouldShowBadge()}
                badgeCount={
                  Object.keys(selectedItems).filter(k => selectedItems[k]).length
                }
                description={description}
                subTitle={props.subTitle}
                disabled={props.disabled}
                buttonId={props.id}
                dropDownId={props.dropDownId ? props.dropDownId : null}
                statusIcon={statusIcon}
              />
            )
        ) : (
            <SelectedItem
              toggleOptions={disabledDropdown === false && this.toggleOptions}
              label={label}
              isOpen={state.showOptions}
              showBadge={this.shouldShowBadge()}
              badgeCount={
                Object.keys(selectedItems).filter(k => selectedItems[k]).length
              }
              description={description}
              subTitle={props.subTitle}
              disabled={disabledDropdown}
              buttonId={props.id}
              dropDownId={props.dropDownId ? props.dropDownId : null}
              statusIcon={statusIcon}
            />
          )}
        {props.error &&
          !props.value &&
          props.required && (
            <div style={{ marginTop: "5px" }} className="has-error">
              {props.error}
            </div>
          )}

        <DropDownOptions
          showSearch={props.showSearch}
          template={props.template}
          showSearchButton={props.showSearchButton && props.showSearchButton}
          options={this.getFilteredOptions()}
          container={this}
          show={state.showOptions}
          onHide={this.hideOptions}
          searchText={state.searchText}
          editSearchText={this.editSearchText}
          multiSelect={multiSelect}
          showSelectAll={showSelectAll}
          selectedItems={selectedItems}
          onSelect={props.onSelect}
          areAllSelected={this.areAllSelected}
          selectAll={props.selectAll}
          name={props.inputName}
          selectAllLabel={props.selectAllLabel}
          searchBoxId={props.searchBoxId}
          overlayWidth={props.overlayWidth}
          target={this.dropdownButtonValue}
          onSearchClicked={props.onSearchClicked && props.onSearchClicked}
          style={props.style}
          hasForm={props.hasForm}
          showIcons={props.showIcons}
          badgeCount={
            Object.keys(selectedItems).filter(k => selectedItems[k]).length
          }
          showDesktopDropdownFor768={showDesktopDropdownFor768}
          removeSelectAllOption={props.removeSelectAllOption || false}
          showDescription={props.showDescription}
        />
      </div>
    );
  }
}

DropDown.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  onSelectAll: PropTypes.func,
  multiSelect: PropTypes.bool,
  showSearch: PropTypes.bool,
  value: PropTypes.any,
  selectedItems: PropTypes.objectOf(PropTypes.bool),
  label: PropTypes.string,
  makeLabel: PropTypes.func
};

DropDown.defaultProps = {
  options: [],
  placeholder: "Select",
  onSelect: () => { }
};
