import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
const FormTooltip = props => (
  <div
    style={
      props.width !== undefined
        ? {
            marginBottom: "20px",
            width: props.width
          }
        : {
            marginBottom: "20px",
            ...props.style
          }
    }
    className="formFieldTooltip col-xs-12 col-sm-12 col-md-12 col-lg-12"
  >
    <div
      id="employer-contribution-info-tooltip-close-icon"
      className="close-button"
      onClick={props.onCloseHandler}
    >
      <FontAwesomeIcon
        id="system-level-cancel-button-close-icon"
        icon={faTimes}
      />
    </div>
    <div id="employer-contribution-info-popover-data" className="content">
      {props.content}
    </div>
  </div>
);

export default FormTooltip;
