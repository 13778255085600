import React, { Component } from "react";
import getInputComponent from "../../../inputFields/getInputComponent";
import FormTooltip from "../../../inputFields/FormTooltip";

const DropDown = getInputComponent("dropdown");
export default class ChangeReasonSection extends Component {
  state = { showTooltip: false };
  showTooltipHandler = () => {
    this.setState({
      showTooltip: !this.state.showTooltip
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        showTooltip: false
      });
    }
  }
  render() {
    const { props } = this;
    return (
      <div
        className="nc-field-group clearfix"
        style={
          this.state.showTooltip
            ? { clear: "both", display: "block", alignItems: "center" }
            : { clear: "both", display: "flex", alignItems: "center" }
        }
      >
        <div
          id={"Label" + props.inputName}
          style={{ paddingRight: "15px" }}
          className={`${props.colSpan} " " ${"col-lg-3 field-name clearfix"}`}
        >
          {props.label}{" "}
          {props.tooltip &&
            props.tooltipPosition === "label" && (
              <a
                onClick={this.showTooltipHandler}
                style={{
                  marginBottom: props.checkedValue ? "10px" : "0px",
                  marginRight: "5px"
                }}
              >
                <i className="fa fa-info-circle" />
              </a>
            )}
        </div>
        <div
          className="col-lg-9"
          style={{ display: "flex", alignItems: "center" }}
        >
          <DropDown
            options={props.options}
            optional
            name={props.inputName}
            value={props.value}
            onChange={props.onChange}
            className="input-field col-lg-4 col-md-6 col-sm-12 col-xs-12"
          />
          {props.tooltip &&
            props.tooltipPosition === "field" && (
              <a
                onClick={this.showTooltipHandler}
                style={{ marginBottom: props.checkedValue ? "10px" : "0px" }}
              >
                <i className="fa fa-info-circle" />
              </a>
            )}
          <div style={{ marginLeft: "10px", marginBottom: "15px" }}>
            <a onClick={props.onAddLinkClick}>
              <i className="fa fa-plus" /> Add New Location
            </a>
          </div>
        </div>
        {this.state.showTooltip &&
          props.tooltip && (
            <FormTooltip
              onCloseHandler={this.showTooltipHandler}
              content={props.tooltip}
              width={props.tooltipwidth}
              style={{ marginTop: "0", marginBottom: "15px" }}
            />
          )}
      </div>
    );
  }
}
