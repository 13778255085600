import React from "react";

const renderFilterOption = (props, Option) => item => {
  const { selectedItems, multiSelect, onSelect, onHide } = props;
  const { value } = item;

  return (
    <Option
      key={value}
      showCheckbox={true}
      selected={selectedItems[value]}
      {...item}
      onSelect={() => {
        if (multiSelect) {
          onSelect(value, !selectedItems[value]);
        } else {
          onSelect(value);
          onHide();
        }
      }}
    />
  );
};

export default renderFilterOption;
