import React, { Component } from "react";
import MultiSectionTableHeader from "./MultiSectionTableHeader";
import MultiSectionTableActionLinks from "./MultiSectionTableActionLinks";
import TimeCardItem from "./TimeCardItem";
import InfiniteScroll from "react-infinite-scroller";
import { TIME_CARD_ALERTS } from "../timecard-alerts/Constants";

const TimeCardsColumns = [
  {
    label: "Employee",
    field: "Employee",
    className: "col-lg-12 col-md-12"
  },
  {
    label: "Alert",
    field: "TimeCardAlertName",
    className: "col-lg-3 col-md-3"
  },
  {
    label: "Time Group",
    field: "TimeGroupName",
    className: "col-lg-3 col-md-3",
    noSort: true
  },
  {
    label: "Time Period",
    field: "TimePeriod",
    className: "col-lg-3 col-md-3",
    noSort: true
  },
  {
    label: "Hours",
    field: "Hours",
    className: "col-lg-2 col-md-1",
    noSort: true
  }
];

export default class TimeCardAlertsTable extends Component {
  state = {
    loadingMore: false
  };

  areAllSelected = () => {
    const { selectedOptions = {}, data } = this.props;
    let all = true;
    data.forEach(
      ({ EmployeeId }) => (all = all && selectedOptions[EmployeeId])
    );
    return all;
  };

  shouldShowBulkActions = () => {
    const { selectedOptions = {}, data } = this.props;
    let some = false;
    data.forEach(({ EmployeeId }) => {
      if (selectedOptions[EmployeeId]) {
        some = true;
      }
    });
    return some;
  };

  render() {
    const {
      data,
      sortOrder,
      changeSortOrder,
      UserTestId,
      onContactAllClick = () => {},
      onContactClick = () => {},
      options = {},
      selectedOptions = {},
      makeOnSelect = () => undefined,
      makeSelectAll = () => undefined,
      loadMorePosts,
      toggleInfoModal = () => {},
      approveItem
    } = this.props;
    return (
      <div className="clearfix">
        <MultiSectionTableActionLinks
          onSelectAll={makeSelectAll(TIME_CARD_ALERTS)}
          showBulkActions={this.shouldShowBulkActions()}
          onActionClick={this.props.onActionClick}
          UserTestId={UserTestId}
          areAllSelected={this.areAllSelected()}
          onContactAllClick={() => onContactAllClick()}
        />
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <MultiSectionTableHeader
            columns={TimeCardsColumns}
            sortOrder={sortOrder}
            changeSortOrder={changeSortOrder}
            payrollType={"timecardAlerts"}
          />
          <InfiniteScroll
            pageStart={0}
            loadMore={
              !this.state.loadingMore
                ? loadMorePosts
                : () => console.log("no more items")
            }
            hasMore={false}
            loader={this.state.loadingMore ? <span>Loading...</span> : null}
          >
            {data.map((row, i) => (
              <TimeCardItem
                key={i}
                {...row}
                onSelect={makeOnSelect(TIME_CARD_ALERTS)}
                toggleInfoModal={() => toggleInfoModal()}
                selected={selectedOptions[row.EmployeeId]}
                options={options}
                approveAction={() =>
                  approveItem({
                    Name: row.EmployeeName,
                    Id: row.EmployeeId
                  })
                }
                onContactClick={() => onContactClick(row.EmployeeId)}
                isEmailSent={row.IsEmailSent}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}
