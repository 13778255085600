/** Actions */
const ADD_TO_CUSTOM_FIELDS = "custom-fields/ADD_TO_CUSTOM_FIELDS";
const CLEAR_CUSTOM_FIELDS = "custom-fields/CLEAR_CUSTOM_FIELDS";
const SET_CUSTOM_FIELDS_LOADING = "custom-fields/SET_CUSTOM_FIELDS_LOADING";

export const initialState = {
  loading: true,
  Data: [],
  DataCount: 0,
  NextUri: null
};

const filterNewData = (state, Data) => {
  const ids = state.Data.map(a => a.EmployeeId);
  return Data.filter(d => ids.indexOf(d.EmployeeId) === -1);
};

/** Reducer */
const customFieldsMenu = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_CUSTOM_FIELDS:
      return initialState;
    case ADD_TO_CUSTOM_FIELDS: {
      const { Data, DataCount, NextUri } = payload;
      const newData = [...state.Data, ...filterNewData(state, Data)];
      return {
        ...state,
        NextUri,
        Data: newData,
        DataCount: DataCount,
        loading: false
      };
    }
    case SET_CUSTOM_FIELDS_LOADING:
      return {
        ...state,
        loading: payload
      };
    default:
      return state;
  }
};

export default customFieldsMenu;

/** Action creators */
export const addToCustomFieldsMenu = (Data, DataCount, NextUri) => ({
  type: ADD_TO_CUSTOM_FIELDS,
  payload: { Data, DataCount, NextUri }
});

export const clearCustomFieldsMenu = () => ({
  type: CLEAR_CUSTOM_FIELDS
});

export const setCustomFieldsMenuLoading = loading => ({
  type: SET_CUSTOM_FIELDS_LOADING,
  payload: loading
});
