import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import axios from "axios";
import { withRouter, Prompt } from "react-router-dom";
import { setLoader } from "app/actions/LoaderActions";
import * as common from "app/common/index";
import TextInputWithoutLabel from "app/common/TextInputWithoutLabel";
import * as validator from "app/common/validations";
import * as systemConstantsId from "app/common/SystemConstants";
import SystemThirdPartyPayeeSetupDescription from "./SystemThirdPartyPayeeSetupDescription";
import * as utilities from "app/common/utilities";
import { showToaster } from "app/actions/AppActions";
import { getCategoryList } from "../../actions/systemlevel/thirdpartypayee/AdvanceFilterActions";
import { getPaymentType } from "../../actions/systemlevel/thirdpartypayee/ThirdPartyPayeePaymentTypeActions";
import { getAccountType } from "../../actions/systemlevel/thirdpartypayee/ThirdPartyPayeeAccountTypeActions";
import { getPrintSsnOnCheckOption } from "../../actions/systemlevel/thirdpartypayee/ThirdPartyPayeePrintSsnOnCheckOptionActions";
import { getZipCode } from "../../actions/systemlevel/thirdpartypayee/ZipCodeActions";
import {
  setThirdPartyPayeeData,
  getCountryStateList,
  clearThirdPartyPayeeSetupList
} from "../../actions/systemlevel/thirdpartypayee/ThirdPartyPayeeSetupListingActions";

import {
  getToasterMessage,
  getButtonTypeForToasterMessage
} from "../../actions/systemlevel/thirdpartypayee/ToasterMessageActions";

import {
  setGlobalNavClick,
  CallbackToQuickLink
} from "app/actions/FormUpdateAction";
import {
  setFormUpdate,
  isOverlayClickable
} from "app/actions/FormUpdateAction";
import * as message from "app/common/messages";

import DropDown from "component-library/dropDown/DropDown";
import "./ThirdPartyPayee.css";
import "../../../../content/entitypage.css";
import "../deductionsetup/DeductionStyles.css";
import "../../../payrollsetup/PayrollSetup.css";
import RadioButton from "component-library/radioButton/BasicRadioButton";
import { fetchAndSetEntityData } from "../../actions/systemlevel/thirdpartypayee/actions";

const CREATE_BUTTON = "Create";
const UPDATE_BUTTON = "Update";

let isInDidUpdate = false;

class SystemThirdPartyPayeeAddPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showBackCancelModal: false,
      showCancelModal: false,
      showUpdateModal: false,
      openCancelButtonModal: false,
      IsFormUpdated: false,
      openDirectDepositSection: false,
      openPrintedCheckSelection: false,

      validationCount: 4,
      validationCountPaymentInfo: 1,
      thirdPartyPayeeCategorySelectedValue: 0,
      address: "",
      addressState: "",
      showCityStateSection: false,

      UseCount: this.props.systemThirdPartyPayeeData.UseCount,
      SystemThirdPartyPayeeId: this.props.systemThirdPartyPayeeData
        .SystemThirdPartyPayeeId,
      SystemThirdPartyPayeeName: this.props.systemThirdPartyPayeeData
        .SystemThirdPartyPayeeName,
      ThirdPartyPayeeCategoryId: this.props.systemThirdPartyPayeeData
        .ThirdPartyPayeeCategoryId,
      ThirdPartyPayeeCategoryName: this.props.systemThirdPartyPayeeData
        .ThirdPartyPayeeCategoryName,
      AddressLine1: this.props.systemThirdPartyPayeeData.AddressLine1,
      AddressLine2: this.props.systemThirdPartyPayeeData.AddressLine2,
      AddressCity: this.props.systemThirdPartyPayeeData.AddressCity,
      AddressStateId: this.props.systemThirdPartyPayeeData.AddressStateId,
      AddressStateShortName: this.props.systemThirdPartyPayeeData
        .AddressStateShortName,
      AddressStateName: this.props.systemThirdPartyPayeeData.AddressStateName,
      AddressZipCode: this.props.systemThirdPartyPayeeData.AddressZipCode,
      ThirdPartyPayeePaymentTypeId: this.props.systemThirdPartyPayeeData
        .ThirdPartyPayeePaymentTypeId,
      ThirdPartyPayeePaymentTypeName: this.props.systemThirdPartyPayeeData
        .ThirdPartyPayeePaymentTypeName,
      IsIncludeInACHAddenda: this.props.systemThirdPartyPayeeData
        .IsIncludeInACHAddenda,
      PrintSsnOnCheckOptionId: this.props.systemThirdPartyPayeeData
        .PrintSsnOnCheckOptionId,
      PrintSsnOnCheckOptionName: this.props.systemThirdPartyPayeeData
        .PrintSsnOnCheckOptionName,
      BankName: this.props.systemThirdPartyPayeeData.BankName,
      BankAccountTypeId: this.props.systemThirdPartyPayeeData.BankAccountTypeId,
      BankAccountTypeName: this.props.systemThirdPartyPayeeData
        .BankAccountTypeName,
      BankRoutingNumber: this.props.systemThirdPartyPayeeData.BankRoutingNumber,
      BankAccountNumber: this.props.systemThirdPartyPayeeData.BankAccountNumber,
      errors: {}
    };

    this.redirectToSystemThirdPartyPayeeListingPage = this.redirectToSystemThirdPartyPayeeListingPage.bind(
      this
    );
    this.renderPaymentType = this.renderPaymentType.bind(this);
    this.setThirdPartyPayeeCategoryClickHandler = this.setThirdPartyPayeeCategoryClickHandler.bind(
      this
    );
    this.setThirdPartyPayeeAddressStateClickHandler = this.setThirdPartyPayeeAddressStateClickHandler.bind(
      this
    );
    this.onChangePaymentType = this.onChangePaymentType.bind(this);
    this.showDirectDepositSection = this.showDirectDepositSection.bind(this);
    this.renderAccountType = this.renderAccountType.bind(this);
    this.onChangeAccountType = this.onChangeAccountType.bind(this);

    this.close = this.close.bind(this);
    this.openCancelModal = this.openCancelModal.bind(this);
    this.openUpdatelModal = this.openUpdatelModal.bind(this);
    this.createThirdPartyPayee = this.createThirdPartyPayee.bind(this);
    this.updateThirdPartyPayee = this.updateThirdPartyPayee.bind(this);
    this.zipCodeFormat = this.zipCodeFormat.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.getModBankRoutingNumber = this.getModBankRoutingNumber.bind(this);

    this.handleChangeBankName = this.handleChangeBankName.bind(this);
    this.handleChangeBankRoutingNumber = this.handleChangeBankRoutingNumber.bind(
      this
    );
    this.handleChangeBankAccountNumber = this.handleChangeBankAccountNumber.bind(
      this
    );
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);
    this.handleChangeAddressLine1 = this.handleChangeAddressLine1.bind(this);
    this.handleChangeAddressLine2 = this.handleChangeAddressLine2.bind(this);
    this.handleChangeAddressZipCode = this.handleChangeAddressZipCode.bind(
      this
    );
    this.getAddressByZipcode = this.getAddressByZipcode.bind(this);
    this.redirectToGlobalNavLink = this.redirectToGlobalNavLink.bind(this);
  }

  componentWillMount() {
    this.props.getCategoryList();
    this.props.getPaymentType();
    this.props.getAccountType();
    this.props.getPrintSsnOnCheckOption();
    this.props.getCountryStateList();
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.getDataById();
  }

  componentWillUnmount() {
    this.props.setFormUpdate(false);
    this.props.clearThirdPartyPayeeSetupList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.address !== this.state.address) {
      isInDidUpdate = true;
      let errors = this.state.errors;
      let isStateCodeValidate = this.validateStateCode();

      if (
        (this.state.AddressZipCode.length > 5 &&
          this.state.AddressZipCode.length < 9) ||
        (this.state.AddressZipCode.length >= 1 &&
          this.state.AddressZipCode.length < 5)
      ) {
        errors["AddressZipCode"] = `Invalid zip code.`;
        this.setState({
          showCityStateSection: false,
          AddressCity: "",
          AddressStateId: null,
          address: "",
          errors
        });
      } else {
        if (isStateCodeValidate === false) {
          errors["AddressZipCode"] = `Invalid zip code.`;
          this.setState({
            showCityStateSection: false,
            AddressCity: "",
            AddressStateId: null,
            address: "",
            errors
          });
        } else {
          this.setState({
            showCityStateSection: true
          });
        }
      }
      isInDidUpdate = false;
    }

    if (
      prevProps.globalNavClick === null &&
      this.props.globalNavClick !== null
    ) {
      if (this.props.globalNavClick.isLinkClick) {
        this.setState({
          showBackCancelModal: true,
          IsFormUpdated: false
        });
        this.props.setFormUpdate(false);
      }
    } else {
      if (
        prevProps.globalNavClick !== null &&
        this.props.globalNavClick !== null
      ) {
        if (
          prevProps.globalNavClick.isLinkClick !==
          this.props.globalNavClick.isLinkClick
        ) {
          if (this.props.globalNavClick.isLinkClick) {
            this.setState({
              showBackCancelModal: true,
              IsFormUpdated: false
            });
            this.props.setFormUpdate(false);
          }
        }
      }
    }

    if (prevState.AddressCity !== this.state.AddressCity) {
      if (this.state.showCityStateSection) {
        isInDidUpdate = true;
        let errors = this.state.errors;

        if (this.state.AddressCity !== undefined) {
          if (this.state.AddressCity.length === 0) {
            // errors["AddressCity"] = `Please enter city.`;
          }
        }
        this.setState({
          errors
        });
        isInDidUpdate = false;
      }
    }
  }

  redirectToGlobalNavLink() {
    this.props.setFormUpdate(false);
    this.props.setGlobalNavClick(
      false,
      this.props.globalNavClick.LinkItem,
      this.props.globalNavClick.MenuLink
    );
    this.props.CallbackToQuickLink(true);
    this.props.isOverlayClickable(false);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.systemThirdPartyPayeeData !==
      this.props.systemThirdPartyPayeeData
    ) {
      if (nextProps.systemThirdPartyPayeeData.SystemThirdPartyPayeeId > 0) {
        if (nextProps.systemThirdPartyPayeeData.AddressZipCode !== undefined) {
          this.setState({
            showCityStateSection: true
          });
        }

        if (nextProps.systemThirdPartyPayeeData.SystemThirdPartyPayeeId > 0) {
          this.setState({
            validationCount: 0,
            validationCountPaymentInfo: 0
          });
        }

        if (
          String(
            nextProps.systemThirdPartyPayeeData.ThirdPartyPayeePaymentTypeId
          ) === String(systemConstantsId.THIRD_PARTY_PAYEE_DIRECT_DEPOSIT)
        ) {
          this.setState({
            openDirectDepositSection: true,
            openPrintedCheckSelection: false
          });
        } else if (
          String(
            nextProps.systemThirdPartyPayeeData.ThirdPartyPayeePaymentTypeId
          ) === String(systemConstantsId.THIRD_PARTY_PAYEE_PRINTED_CHECK)
        ) {
          this.setState({
            openDirectDepositSection: false,
            openPrintedCheckSelection: true
          });
        }
      }
    }

    if (
      nextProps.systemThirdPartyPayeeData !==
      this.props.systemThirdPartyPayeeData
    ) {
      this.setState({
        UseCount: nextProps.systemThirdPartyPayeeData.UseCount,
        SystemThirdPartyPayeeId:
          nextProps.systemThirdPartyPayeeData.SystemThirdPartyPayeeId,
        SystemThirdPartyPayeeName:
          nextProps.systemThirdPartyPayeeData.SystemThirdPartyPayeeName,
        ThirdPartyPayeeCategoryId:
          nextProps.systemThirdPartyPayeeData.ThirdPartyPayeeCategoryId,
        ThirdPartyPayeeCategoryName:
          nextProps.systemThirdPartyPayeeData.ThirdPartyPayeeCategoryName,
        AddressLine1: nextProps.systemThirdPartyPayeeData.AddressLine1,
        AddressLine2: nextProps.systemThirdPartyPayeeData.AddressLine2,
        AddressCity: nextProps.systemThirdPartyPayeeData.AddressCity,
        AddressStateId: nextProps.systemThirdPartyPayeeData.AddressStateId,
        AddressStateShortName:
          nextProps.systemThirdPartyPayeeData.AddressStateShortName,
        AddressStateName: nextProps.systemThirdPartyPayeeData.AddressStateName,
        AddressZipCode: nextProps.systemThirdPartyPayeeData.AddressZipCode,
        ThirdPartyPayeePaymentTypeId:
          nextProps.systemThirdPartyPayeeData.ThirdPartyPayeePaymentTypeId,
        ThirdPartyPayeePaymentTypeName:
          nextProps.systemThirdPartyPayeeData.ThirdPartyPayeePaymentTypeName,
        IsIncludeInACHAddenda:
          nextProps.systemThirdPartyPayeeData.IsIncludeInACHAddenda,
        PrintSsnOnCheckOptionId:
          nextProps.systemThirdPartyPayeeData.PrintSsnOnCheckOptionId,
        PrintSsnOnCheckOptionName:
          nextProps.systemThirdPartyPayeeData.PrintSsnOnCheckOptionName,
        BankName: nextProps.systemThirdPartyPayeeData.BankName,
        BankAccountTypeId:
          nextProps.systemThirdPartyPayeeData.BankAccountTypeId,
        BankAccountTypeName:
          nextProps.systemThirdPartyPayeeData.BankAccountTypeName,
        BankRoutingNumber:
          nextProps.systemThirdPartyPayeeData.BankRoutingNumber,
        BankAccountNumber: nextProps.systemThirdPartyPayeeData.BankAccountNumber
      });
    }
  }

  getDataById() {
    let self = this;
    let Id = this.props.match.params.SystemThirdPartyPayeeId;
    if (Id !== undefined) {
      Id = window.atob(Id);
      const url = `${common.WEB_API_URL}/v1/system/thirdpartypayee/${Id}`;
      axios
        .get(url)
        .then(function(response) {
          if (response.data.ErrorCount > 0) {
            response.data.Errors.forEach(item => {
              if (item.Tag === "") {
              }
            });
          } else {
            let thirdPartyPayeeData = response.data.Data[0];
            self.props.setThirdPartyPayeeData(thirdPartyPayeeData);
          }
        })
        .catch(function(error) {});
    }
  }

  showCustomIcon() {
    return <i className="fa fa-chevron-down cstm555icon" aria-hidden="true" />;
  }

  redirectToSystemThirdPartyPayeeListingPage() {
    this.setState({
      IsFormUpdated: false
    });
    this.props.setFormUpdate(false);
    this.props.setThirdPartyPayeeData([]);

    this.props.history.push("/system-third-party-payee-listing-page");
  }

  handleChangeBankName(event) {
    if (event.target.value.length <= 30) {
      this.setState({
        [event.target.name]: event.target.value
        //IsFormUpdated: true
      });
      if (isInDidUpdate === false) {
        this.setState({
          IsFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }

      let errors = this.state.errors;
      delete errors["BankName"];
      this.setState({
        errors
      });

      if (this.state.BankName == null || this.state.BankName === undefined) {
        if (this.state.validationCountPaymentInfo > 0) {
          this.setState({
            validationCountPaymentInfo:
              this.state.validationCountPaymentInfo - 1
          });
        }
      }

      if (event.target.value.length !== 0 && !this.state.BankName) {
        if (this.state.validationCountPaymentInfo > 0) {
          this.setState({
            validationCountPaymentInfo:
              this.state.validationCountPaymentInfo - 1
          });
        }
      }

      if (event.target.value.length === 0) {
        this.setState({
          validationCountPaymentInfo: this.state.validationCountPaymentInfo + 1
        });
      }
    }
  }

  getModBankRoutingNumber(bankRoutingNumber) {
    let routingNumberList = bankRoutingNumber.split("");
    let routingNumber = [];
    routingNumberList.forEach(item => {
      routingNumber.push(parseInt(item, 10));
    });

    let modRoutingNumber =
      [
        3 * (routingNumber[0] + routingNumber[3] + routingNumber[6]) +
          7 * (routingNumber[1] + routingNumber[4] + routingNumber[7]) +
          (routingNumber[2] + routingNumber[5] + routingNumber[8])
      ] % 10;
    return modRoutingNumber;
  }

  handleChangeBankRoutingNumber(event) {
    let errors = this.state.errors;
    delete errors["BankRoutingNumber"];
    this.setState({
      errors
    });

    this.setState({
      BankRoutingNumber: event.target.value
      // IsFormUpdated: true
    });
    if (isInDidUpdate === false) {
      this.setState({
        IsFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }

    if (event.target.value.length === 9) {
      let modRoutingNumber = this.getModBankRoutingNumber(event.target.value);
      if (modRoutingNumber !== 0) {
        errors["BankRoutingNumber"] = "Invalid routing number";
        this.setState({ errors: errors });
      }
    } else {
      let errors = this.state.errors;
      delete errors["BankRoutingNumber"];
      this.setState({
        errors
      });
    }

    if (
      this.state.BankRoutingNumber == null ||
      this.state.BankRoutingNumber === undefined
    ) {
      this.setState({
        validationCountPaymentInfo: this.state.validationCountPaymentInfo - 1
      });
    }

    if (event.target.value.length !== 0 && !this.state.BankRoutingNumber) {
      this.setState({
        validationCountPaymentInfo: this.state.validationCountPaymentInfo - 1
      });
    }

    if (event.target.value.length === 0) {
      this.setState({
        validationCountPaymentInfo: this.state.validationCountPaymentInfo + 1
      });
    }
  }

  handleChangeBankAccountNumber(event) {
    if (event.target.value.length <= 17) {
      let errors = this.state.errors;
      delete errors["BankAccountNumber"];
      this.setState({
        errors
      });
      let value = event.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
      this.setState({
        BankAccountNumber: value
      });

      if (isInDidUpdate === false) {
        this.setState({
          IsFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }

      if (
        this.state.BankAccountNumber == null ||
        this.state.BankAccountNumber === undefined
      ) {
        this.setState({
          validationCountPaymentInfo: this.state.validationCountPaymentInfo - 1
        });
      }

      if (event.target.value.length !== 0 && !this.state.BankAccountNumber) {
        this.setState({
          validationCountPaymentInfo: this.state.validationCountPaymentInfo - 1
        });
      }

      if (event.target.value.length === 0) {
        this.setState({
          validationCountPaymentInfo: this.state.validationCountPaymentInfo + 1
        });
      }
    }
  }

  handleChangeName(event) {
    if (event.target.value.length <= 50) {
      this.setState({
        [event.target.name]: event.target.value
      });

      if (isInDidUpdate === false) {
        this.setState({
          IsFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
      let errors = this.state.errors;
      delete errors["SystemThirdPartyPayeeName"];
      this.setState({
        errors
      });

      if (
        this.state.SystemThirdPartyPayeeName == null ||
        this.state.SystemThirdPartyPayeeName === undefined
      ) {
        if (this.state.validationCount > 0) {
          this.setState({
            validationCount: this.state.validationCount - 1
          });
        }
      }

      if (
        event.target.value.length !== 0 &&
        !this.state.SystemThirdPartyPayeeName
      ) {
        if (this.state.validationCount > 0) {
          this.setState({
            validationCount: this.state.validationCount - 1
          });
        }
      }

      if (event.target.value.length === 0) {
        this.setState({
          validationCount: this.state.validationCount + 1
        });
      }

      if (utilities.isNullOrEmpty(event.target.value)) {
        if (
          utilities.isUndefinedOrEmpty(this.state.AddressLine1) &&
          utilities.isUndefinedOrEmpty(this.state.AddressLine2) &&
          utilities.isUndefinedOrEmpty(this.state.AddressZipCode) &&
          utilities.isUndefinedOrEmpty(this.state.AddressCity) &&
          utilities.isUndefinedOrZero(this.state.AddressStateId) &&
          utilities.isUndefinedOrNull(this.state.ThirdPartyPayeeCategoryId) &&
          utilities.isUndefinedOrNull(this.state.ThirdPartyPayeePaymentTypeId)
        ) {
          this.setState({
            IsFormUpdated: false
          });
          this.props.setFormUpdate(false);
        }
      }
    }
  }

  handleChangeCity(event) {
    if (event.target.value.length <= 20) {
      this.setState({
        [event.target.name]: event.target.value
      });

      if (isInDidUpdate === false) {
        this.setState({
          IsFormUpdated: true
        });
      }

      let errors = this.state.errors;
      delete errors["AddressCity"];
      this.setState({
        errors
      });
    }
  }

  handleChangeAddressLine1(event) {
    if (event.target.value.length <= 30) {
      this.setState({
        [event.target.name]: event.target.value
      });
      if (isInDidUpdate === false) {
        this.setState({
          IsFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
      let errors = this.state.errors;
      delete errors["AddressLine1"];
      this.setState({
        errors
      });

      if (
        this.state.AddressLine1 == null ||
        this.state.AddressLine1 === undefined
      ) {
        if (this.state.validationCount > 0) {
          this.setState({
            validationCount: this.state.validationCount - 1
          });
        }
      }

      if (event.target.value.length !== 0 && !this.state.AddressLine1) {
        if (this.state.validationCount > 0) {
          this.setState({
            validationCount: this.state.validationCount - 1
          });
        }
      }

      if (event.target.value.length === 0) {
        this.setState({
          validationCount: this.state.validationCount + 1
        });
      }

      if (utilities.isNullOrEmpty(event.target.value)) {
        if (
          utilities.isUndefinedOrEmpty(this.state.SystemThirdPartyPayeeName) &&
          utilities.isUndefinedOrEmpty(this.state.AddressLine2) &&
          utilities.isUndefinedOrEmpty(this.state.AddressZipCode) &&
          utilities.isUndefinedOrEmpty(this.state.AddressCity) &&
          utilities.isUndefinedOrZero(this.state.AddressStateId) &&
          utilities.isUndefinedOrNull(this.state.ThirdPartyPayeeCategoryId) &&
          utilities.isUndefinedOrNull(this.state.ThirdPartyPayeePaymentTypeId)
        ) {
          this.setState({
            IsFormUpdated: false
          });
          this.props.setFormUpdate(false);
        }
      }
    }
  }

  handleChangeAddressLine2(event) {
    if (event.target.value.length <= 30) {
      this.setState({
        [event.target.name]: event.target.value
      });
      if (isInDidUpdate === false) {
        this.setState({
          IsFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }

      if (utilities.isNullOrEmpty(event.target.value)) {
        if (
          utilities.isUndefinedOrEmpty(this.state.SystemThirdPartyPayeeName) &&
          utilities.isUndefinedOrEmpty(this.state.AddressLine1) &&
          utilities.isUndefinedOrEmpty(this.state.AddressZipCode) &&
          utilities.isUndefinedOrEmpty(this.state.AddressCity) &&
          utilities.isUndefinedOrZero(this.state.AddressStateId) &&
          utilities.isUndefinedOrNull(this.state.ThirdPartyPayeeCategoryId) &&
          utilities.isUndefinedOrNull(this.state.ThirdPartyPayeePaymentTypeId)
        ) {
          this.setState({
            IsFormUpdated: false
          });
          this.props.setFormUpdate(false);
        }
      }
    }
  }

  handleChangeAddressZipCode(values) {
    const { formattedValue = "", value = "" } = values;
    let errors = this.state.errors;
    this.setState({
      AddressZipCode: value,
      IsFormUpdated: true
    });
    this.props.setFormUpdate(true);
    if (value.length === 5) {
      delete errors["AddressZipCode"];
      delete errors["AddressStateId"];
      delete errors["AddressCity"];
      this.setState({
        AddressZipCode: value,
        errors
      });
      this.getAddressByZipcode(value);
      return;
    } else if (formattedValue.length === 10) {
      delete errors["AddressZipCode"];
      delete errors["AddressStateId"];
      delete errors["AddressCity"];
      this.setState({
        AddressZipCode: formattedValue,
        errors
      });
      this.getAddressByZipcode(formattedValue);
    } else if (value.length >= 1 && value.length < 5) {
      errors["AddressZipCode"] = "Invalid zip code";
      this.setState({
        showCityStateSection: false,
        address: "",
        AddressCity: "",
        AddressStateId: null,
        addressState: null,
        errors
      });
    } else if (formattedValue.length >= 6 && formattedValue.length <= 10) {
      errors["AddressZipCode"] = "Invalid zip code";
      this.setState({
        showCityStateSection: false,
        address: "",
        AddressCity: "",
        AddressStateId: null,
        addressState: null,
        errors
      });
    } else if (value.length === 0) {
      delete errors["AddressZipCode"];
      this.setState({
        AddressZipCode: value,
        showCityStateSection: false,
        address: "",
        AddressCity: "",
        AddressStateId: null,
        addressState: null,
        errors
      });
    }

    if (value.length === 0) {
      this.setState({
        validationCount: this.state.validationCount + 1
      });
    }

    if (
      this.state.AddressZipCode == null ||
      this.state.AddressZipCode === undefined
    ) {
      if (this.state.validationCount > 0) {
        this.setState({
          validationCount: this.state.validationCount - 1
        });
      }
    }

    if (value.length !== 0 && !this.state.AddressZipCode) {
      if (this.state.validationCount > 0) {
        this.setState({
          validationCount: this.state.validationCount - 1
        });
      }
    }

    if (utilities.isNullOrEmpty(value)) {
      if (
        utilities.isUndefinedOrEmpty(this.state.SystemThirdPartyPayeeName) &&
        utilities.isUndefinedOrEmpty(this.state.AddressLine1) &&
        utilities.isUndefinedOrEmpty(this.state.AddressLine2) &&
        utilities.isUndefinedOrNull(this.state.ThirdPartyPayeeCategoryId) &&
        utilities.isUndefinedOrNull(this.state.ThirdPartyPayeePaymentTypeId)
      ) {
        this.setState({
          IsFormUpdated: false
        });
        this.props.setFormUpdate(false);
      }
    }
  }

  getAddressByZipcode(zipcode) {
    let self = this;
    const url = `${common.WEB_API_URL}/v1/system/zipcode/${zipcode}`;

    axios
      .get(url)
      .then(function(response) {
        if (response.data.ErrorCount > 0) {
          response.data.Errors.forEach(item => {
            alert(item.Messages);
          });
        } else {
          self.ShowResponse(
            response.data.Data[0],
            response.data.Data[1],
            zipcode
          );
        }
      })
      .catch(function(error) {});
  }

  ShowResponse(data, AddressState, zipcode) {
    let errors = this.state.errors;
    if (zipcode.length === 5 || zipcode.length === 10) {
      if (AddressState === "") {
        // errors["AddressCity"] = "Please enter city.";
        // errors["AddressStateId"] = "Please select state.";
        this.setState({
          showCityStateSection: true,
          AddressStateId: null,
          errors
        });
      }
      var result;
      result = data.split(",");
      this.setState({
        address: data,
        addressState: AddressState,
        AddressCity: result[0].substring(0, 20)
      });
    } else {
      this.setState({
        address: "",
        addressState: null,
        AddressCity: "",
        AddressStateId: null
      });
    }
  }

  createThirdPartyPayee() {
    let errors = {};
    let data = this.state;

    let newName = "";
    let newAddress1 = "";
    let newAddress2 = "";
    let newBankName = "";
    let newAddressCity = "";

    errors = validator.validateRequiredField(
      data.SystemThirdPartyPayeeName,
      "SystemThirdPartyPayeeName",
      errors
    );
    errors = validator.validateRequiredField(
      data.ThirdPartyPayeeCategoryId,
      "ThirdPartyPayeeCategoryId",
      errors
    );
    errors = validator.validateRequiredField(
      data.AddressLine1,
      "AddressLine1",
      errors
    );
    errors = validator.validateRequiredField(
      data.AddressZipCode,
      "AddressZipCode",
      errors
    );
    errors = validator.validateRequiredField(
      data.AddressCity,
      "AddressCity",
      errors
    );
    errors = validator.validateRequiredField(
      data.AddressStateId,
      "AddressStateId",
      errors
    );
    errors = validator.validateRequiredField(
      data.ThirdPartyPayeePaymentTypeId,
      "ThirdPartyPayeePaymentTypeId",
      errors
    );

    if (
      data.SystemThirdPartyPayeeName !== undefined &&
      data.SystemThirdPartyPayeeName !== null
    ) {
      newName = data.SystemThirdPartyPayeeName.trim();
      data.SystemThirdPartyPayeeName = newName;

      if (newName === "") {
        errors["SystemThirdPartyPayeeName"] = "This is a required field.";
      }
    }

    if (data.AddressLine1 !== undefined && data.AddressLine1 !== null) {
      newAddress1 = data.AddressLine1.trim();
      data.AddressLine1 = newAddress1;

      if (newAddress1 === "") {
        errors["AddressLine1"] = "This is a required field.";
      }
    }

    if (data.AddressLine2 !== null && data.AddressLine2 !== undefined) {
      newAddress2 = data.AddressLine2.trim();
      data.AddressLine2 = newAddress2;
    }

    if (data.AddressZipCode !== undefined) {
      if (data.AddressZipCode.length === 0) {
        errors["AddressZipCode"] = "This is a required field.";
        this.setState({
          showCityStateSection: false,
          AddressCity: "",
          AddressStateId: null,
          address: ""
        });
      } else if (
        data.AddressZipCode.length === 5 ||
        data.AddressZipCode.length === 10
      ) {
        data.AddressCity = this.state.AddressCity;
        data.AddressStateShortName = this.state.addressState;
      } else if (data.AddressZipCode.length < 5) {
        errors["AddressZipCode"] = "Invalid zip code.";
        this.setState({
          showCityStateSection: false,
          AddressCity: "",
          AddressStateId: null,
          address: ""
        });
      } else if (
        data.AddressZipCode.length > 5 &&
        data.AddressZipCode.length < 9
      ) {
        errors["AddressZipCode"] = "Invalid zip code.";
        this.setState({
          showCityStateSection: false,
          AddressCity: "",
          AddressStateId: null,
          address: ""
        });
      }
    }

    if (
      this.state.AddressCity !== undefined &&
      this.state.AddressCity !== null
    ) {
      newAddressCity = data.AddressCity.trim();
      data.AddressCity = newAddressCity;

      if (newAddressCity === "") {
        errors["AddressCity"] = "This is a required field.";
      }
    }

    if (
      String(data.ThirdPartyPayeePaymentTypeId) ===
      String(systemConstantsId.THIRD_PARTY_PAYEE_DIRECT_DEPOSIT)
    ) {
      errors = validator.validateRequiredField(
        data.BankName,
        "BankName",
        errors
      );

      if (data.BankName !== undefined && data.BankName !== null) {
        newBankName = data.BankName.trim();
        data.BankName = newBankName;

        if (newBankName === "") {
          errors["BankName"] = "This is a required field.";
        }
      }

      errors = validator.validateRequiredField(
        data.BankRoutingNumber,
        "BankRoutingNumber",
        errors
      );

      if (data.BankRoutingNumber.length === 0) {
        errors["BankRoutingNumber"] = "This is a required field.";
      } else if (data.BankRoutingNumber.length !== 9) {
        errors["BankRoutingNumber"] = "Invalid routing number";
      } else {
        let modRoutingNumber = this.getModBankRoutingNumber(
          data.BankRoutingNumber
        );
        if (modRoutingNumber !== 0) {
          errors["BankRoutingNumber"] = "Invalid routing number";
        }
      }

      let backAccountNumberLength = parseInt(
        data.BankAccountNumber.trim().length,
        10
      );

      if (backAccountNumberLength === 0) {
        errors["BankAccountNumber"] = "This is a required field.";
      } else if (backAccountNumberLength < 5) {
        errors["BankAccountNumber"] = "Invalid Bank Account Number";
      }

      errors = validator.validateRequiredField(
        data.BankAccountTypeId,
        "BankAccountTypeId",
        errors
      );
    }

    this.setState({
      errors
    });

    if (Object.keys(errors).length === 0) {
      this.props.setLoader(true);
      if (
        data.SystemThirdPartyPayeeId === 0 ||
        data.SystemThirdPartyPayeeId === undefined
      ) {
        let self = this;
        const url = `${
          common.WEB_API_URL
        }/v1/system/thirdpartypayee/action/exists`;

        axios
          .post(url, { Id: 0, Name: data.SystemThirdPartyPayeeName })
          .then(function(response) {
            if (response.data.ErrorCount > 0) {
              self.props.setLoader(false);
              self.setState({
                isLoading: false
              });
              response.data.Errors.forEach(item => {
                if (item.Tag === "NAME_INUSE") {
                  errors["SystemThirdPartyPayeeName"] = item.Messages;
                  self.setState({ errors: errors });
                }
              });
            } else {
              const saveUrl = `${common.WEB_API_URL}/v1/system/thirdpartypayee`;
              axios
                .post(saveUrl, data)
                .then(function(response) {
                  self.props.setLoader(false);
                  if (response.data.ErrorCount > 0) {
                    self.setState({
                      isLoading: false
                    });
                    response.data.Errors.forEach((item, index) => {
                      if (index === 0) {
                        alert(item.Messages);
                      }
                    });
                  } else {
                    const dataUrl = `${
                      common.WEB_API_URL
                    }/v1/system/thirdpartypayee/${
                      response.data.Data[0].SystemThirdPartyPayeeId
                    }`;
                    axios
                      .get(dataUrl)
                      .then(function(response) {
                        if (response.data.ErrorCount > 0) {
                          response.data.Errors.forEach(item => {
                            if (item.Tag === "") {
                            }
                          });
                        } else {
                          let thirdPartyPayeeData = response.data.Data[0];
                          self.props.setThirdPartyPayeeData(
                            thirdPartyPayeeData
                          );

                          let toasterItem = {
                            actionType: CREATE_BUTTON,
                            toasterState: true
                          };

                          self.props.getButtonTypeForToasterMessage(
                            toasterItem
                          );
                          self.props.showToaster(
                            true,
                            `'${data.SystemThirdPartyPayeeName.trim()}' has been added.`
                          );

                          self.setState({ IsFormUpdated: false });
                          self.props.setFormUpdate(false);
                          self.props.history.push(
                            "/system-third-party-payee-listing-page"
                          );
                        }
                      })
                      .catch(function(error) {});
                  }
                })
                .catch(function(error) {});
            }
          })
          .catch(function(error) {});
        //End
      }
    } else {
      console.log("errors : ", errors);
    }
  }

  updateThirdPartyPayee() {
    let item = {
      actionType: UPDATE_BUTTON,
      toasterState: true
    };

    let errors = {};
    let data = this.state;
    this.close();

    let newName = "";
    let newAddress1 = "";
    let newAddress2 = "";
    let newBankName = "";
    let newAddressCity = "";

    data.AddressCity = this.state.AddressCity;
    data.AddressStateId = this.state.AddressStateId;
    data.AddressStateShortName = this.state.addressState;

    if (
      data.SystemThirdPartyPayeeName !== undefined &&
      data.SystemThirdPartyPayeeName !== null
    ) {
      newName = data.SystemThirdPartyPayeeName.trim();
    }

    if (data.AddressLine1 !== undefined && data.AddressLine1 !== null) {
      newAddress1 = data.AddressLine1.trim();
    }

    if (data.AddressLine2 !== null && data.AddressLine2 !== undefined) {
      newAddress2 = data.AddressLine2.trim();
    }

    if (data.AddressCity !== undefined && data.AddressCity !== null) {
      newAddressCity = data.AddressCity.trim();
      data.AddressCity = newAddressCity;
    }

    if (
      String(data.ThirdPartyPayeePaymentTypeId) ===
      String(systemConstantsId.THIRD_PARTY_PAYEE_DIRECT_DEPOSIT)
    ) {
      if (data.BankName !== undefined && data.BankName !== null) {
        newBankName = data.BankName.trim();
      }
    }

    let patchActions = [
      {
        Operation: "update",
        PathOrKey: "SystemThirdPartyPayeeName",
        Value: newName
      },
      {
        Operation: "update",
        PathOrKey: "ThirdPartyPayeeCategoryId",
        Value: data.ThirdPartyPayeeCategoryId
      },
      {
        Operation: "update",
        PathOrKey: "AddressLine1",
        Value: newAddress1
      },
      {
        Operation: "update",
        PathOrKey: "AddressLine2",
        Value: newAddress2
      },
      {
        Operation: "update",
        PathOrKey: "AddressZipCode",
        Value: data.AddressZipCode
      },
      {
        Operation: "update",
        PathOrKey: "AddressCity",
        Value: data.AddressCity
      },
      {
        Operation: "update",
        PathOrKey: "AddressStateId",
        Value: data.AddressStateId
      },
      {
        Operation: "update",
        PathOrKey: "ThirdPartyPayeePaymentTypeId",
        Value: data.ThirdPartyPayeePaymentTypeId
      },
      {
        Operation: "update",
        PathOrKey: "IsIncludeInACHAddenda",
        Value: data.IsIncludeInACHAddenda
      },
      {
        Operation: "update",
        PathOrKey: "PrintSsnOnCheckOptionId",
        Value: data.PrintSsnOnCheckOptionId
      },
      {
        Operation: "update",
        PathOrKey: "BankName",
        Value: newBankName
      },
      {
        Operation: "update",
        PathOrKey: "BankAccountTypeId",
        Value: data.BankAccountTypeId
      },
      {
        Operation: "update",
        PathOrKey: "BankRoutingNumber",
        Value: data.BankRoutingNumber
      },
      {
        Operation: "update",
        PathOrKey: "BankAccountNumber",
        Value: data.BankAccountNumber
      }
    ];

    this.props.setLoader(true);
    const url = `${common.WEB_API_URL}/v1/system/thirdpartypayee/action/exists`;
    let self = this;
    axios
      .post(url, {
        Id: data.SystemThirdPartyPayeeId,
        Name: data.SystemThirdPartyPayeeName
      })
      .then(function(response) {
        if (response.data.ErrorCount > 0) {
          self.props.setLoader(false);
          response.data.Errors.forEach(item => {
            if (item.Tag === "NAME_INUSE") {
              errors["SystemThirdPartyPayeeName"] = `${
                data.SystemThirdPartyPayeeName
              } is already in use.`;
              self.setState({ errors: errors });
            }
          });
        } else {
          const patchUrl = `${common.WEB_API_URL}/v1/system/thirdpartypayee/${
            data.SystemThirdPartyPayeeId
          }`;
          axios
            .patch(patchUrl, patchActions)
            .then(function(response) {
              self.props.setLoader(false);
              if (response.data.ErrorCount > 0) {
                response.data.Errors.forEach((item, index) => {
                  if (index === 0) {
                    alert(item.Messages);
                  }
                });
              } else {
                self.props.getButtonTypeForToasterMessage(item);

                self.props.showToaster(
                  true,
                  `'${data.SystemThirdPartyPayeeName.trim()}' has been updated.`
                );
                if (isInDidUpdate === false) {
                  self.setState({
                    IsFormUpdated: false
                  });
                  self.props.setFormUpdate(false);
                }
                self.props.history.push(
                  "/system-third-party-payee-listing-page"
                );
              }
            })
            .catch(function(error) {});
        }
      })
      .catch(function(error) {});
  }

  setThirdPartyPayeeCategoryClickHandler(newValue) {
    let errors = this.state.errors;
    delete errors["ThirdPartyPayeeCategoryId"];

    this.setState({
      errors,
      // IsFormUpdated: true,
      ThirdPartyPayeeCategoryId: newValue
    });

    if (isInDidUpdate === false) {
      this.setState({
        IsFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }

    if (
      this.state.ThirdPartyPayeeCategoryId == null ||
      this.state.ThirdPartyPayeeCategoryId === undefined
    ) {
      if (this.state.validationCount > 0) {
        this.setState({
          validationCount: this.state.validationCount - 1
        });
      }
    }
  }

  setThirdPartyPayeeAddressStateClickHandler(newValue) {
    let errors = this.state.errors;
    delete errors["AddressStateId"];

    this.setState({
      errors,
      // IsFormUpdated: true,
      AddressStateId: newValue
    });

    if (isInDidUpdate === false) {
      this.setState({
        IsFormUpdated: true
      });
    }
  }

  setthirdPartyPayeeCategorySelectedValue(newValue) {
    this.setState({
      thirdPartyPayeeCategorySelectedValue: newValue
    });
  }

  onChangePaymentType(event, value = 0) {
    this.setState({
      BankName: "",
      BankAccountTypeId: null,
      BankAccountTypeName: "",
      BankRoutingNumber: "",
      BankAccountNumber: ""
    });

    let errors = this.state.errors;
    delete errors["ThirdPartyPayeePaymentTypeId"];

    this.setState({
      errors
    });

    let update = {};
    update[event.name] = event.value;
    this.setState(update);

    if (
      this.state.ThirdPartyPayeePaymentTypeId === null ||
      this.state.ThirdPartyPayeePaymentTypeId === undefined
    ) {
      this.setState({
        validationCountPaymentInfo: this.state.validationCountPaymentInfo - 1
      });
    }

    if (
      event.value === String(systemConstantsId.THIRD_PARTY_PAYEE_DIRECT_DEPOSIT)
    ) {
      this.setState({
        openDirectDepositSection: true,
        openPrintedCheckSelection: false
      });

      if (isInDidUpdate === false) {
        this.setState({
          IsFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }

      if (this.state.validationCountPaymentInfo === 0) {
        this.setState({
          validationCountPaymentInfo: this.state.validationCountPaymentInfo + 4
        });
      } else {
        this.setState({
          validationCountPaymentInfo: this.state.validationCountPaymentInfo + 3
        });
      }
    } else if (
      event.value === String(systemConstantsId.THIRD_PARTY_PAYEE_PRINTED_CHECK)
    ) {
      delete errors["BankName"];
      delete errors["BankRoutingNumber"];
      delete errors["BankAccountNumber"];
      delete errors["BankAccountTypeId"];

      this.setState({
        openDirectDepositSection: false,
        openPrintedCheckSelection: true
      });

      if (isInDidUpdate === false) {
        this.setState({
          IsFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }

      this.setState({
        validationCountPaymentInfo: 0
      });
    }
  }

  onChangeAccountType(event, value = 0) {
    let errors = this.state.errors;
    delete errors["BankAccountTypeId"];
    this.setState({
      errors
      // IsFormUpdated: true
    });

    if (isInDidUpdate === false) {
      this.setState({
        IsFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }

    let update = {};
    update[event.name] = event.value;
    this.setState(update);

    if (
      this.state.BankAccountTypeId == null ||
      this.state.BankAccountTypeId === undefined
    ) {
      this.setState({
        validationCountPaymentInfo: this.state.validationCountPaymentInfo - 1
      });
    }
  }

  renderPaymentType(thirdPartyPayeePaymentType) {
    let hasErrorClass = "";
    if (
      this.state.errors["ThirdPartyPayeePaymentTypeId"] &&
      this.state.errors["ThirdPartyPayeePaymentTypeId"].length > 0
    ) {
      hasErrorClass = "has-error-radio-button-section";
    }

    if (
      this.props.thirdPartyPayeePaymentType.Data !== null &&
      this.props.thirdPartyPayeePaymentType.Data !== undefined
    ) {
      return this.props.thirdPartyPayeePaymentType.Data.map(item => {
        let Value = String(item.ThirdPartyPayeePaymentTypeId);
        return (
          <div
            key={item.ThirdPartyPayeePaymentTypeId}
            id={`payment-info-button-` + item.ThirdPartyPayeePaymentTypeId}
            className={`single-radio-button  ` + hasErrorClass}
          >
            <RadioButton
              id={item.Name}
              name="ThirdPartyPayeePaymentTypeId"
              type="title"
              value={Value}
              label={item.Name}
              onChange={this.onChangePaymentType}
              checked={
                String(this.state.ThirdPartyPayeePaymentTypeId) ===
                String(item.ThirdPartyPayeePaymentTypeId)
              }
            />
          </div>
        );
      });
    }
  }

  renderAccountType(thirdPartyPayeeAccountType) {
    let hasErrorClass = "";
    if (
      this.state.errors["BankAccountTypeId"] &&
      this.state.errors["BankAccountTypeId"].length > 0
    ) {
      hasErrorClass = "has-error-radio-button-section";
    }

    if (this.props.thirdPartyPayeeAccountType.Data !== undefined) {
      return this.props.thirdPartyPayeeAccountType.Data.map(item => {
        let Value = String(item.BankAccountTypeId);
        return (
          <div
            key={item.BankAccountTypeId}
            id={`account-type-button-` + item.BankAccountTypeId}
            className={`single-radio-button ` + hasErrorClass}
          >
            <RadioButton
              id={item.Name}
              name="BankAccountTypeId"
              type="title"
              value={Value}
              label={item.Name}
              checked={
                String(this.state.BankAccountTypeId) ===
                String(item.BankAccountTypeId)
              }
              onChange={this.onChangeAccountType}
            />
          </div>
        );
      });
    }
  }

  close() {
    this.setState({
      openBackButtonModal: false,
      openCancelButtonModal: false,
      showCancelModal: false,
      IsFormUpdated: true,
      showBackCancelModal: false,
      showUpdateModal: false
    });
    this.props.setFormUpdate(true);
    this.props.setGlobalNavClick(false);
  }

  openCancelModal() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (this.state.IsFormUpdated) {
      this.setState({
        showCancelModal: true,
        IsFormUpdated: false
      });
      this.props.setFormUpdate(false);
    } else {
      this.props.history.push("/system-third-party-payee-listing-page");
    }
  }

  openUpdatelModal() {
    this.setState({
      showUpdateModal: true
    });
  }

  showCancelButtonMessageWithMandatoryFields() {
    return (
      <div className="modal-content col-xs-12 col-sm-12 col-md-12 col-lg-12" />
    );
  }

  checkPageValidations = () => {
    let errors = {};
    let data = this.state;

    let newName = "";
    let newAddress1 = "";
    let newBankName = "";
    let newAddressCity = "";

    data.AddressCity = this.state.AddressCity;
    data.AddressStateId = this.state.AddressStateId;
    data.AddressStateShortName = this.state.addressState;

    errors = validator.validateRequiredField(
      data.SystemThirdPartyPayeeName,
      "SystemThirdPartyPayeeName",
      errors
    );
    errors = validator.validateRequiredField(
      data.ThirdPartyPayeeCategoryId,
      "ThirdPartyPayeeCategoryId",
      errors
    );
    errors = validator.validateRequiredField(
      data.AddressLine1,
      "AddressLine1",
      errors
    );
    errors = validator.validateRequiredField(
      data.AddressZipCode,
      "AddressZipCode",
      errors
    );
    errors = validator.validateRequiredField(
      data.AddressStateId,
      "AddressStateId",
      errors
    );
    errors = validator.validateRequiredField(
      data.ThirdPartyPayeePaymentTypeId,
      "ThirdPartyPayeePaymentTypeId",
      errors
    );

    if (
      data.SystemThirdPartyPayeeName !== undefined &&
      data.SystemThirdPartyPayeeName !== null
    ) {
      newName = data.SystemThirdPartyPayeeName.trim();

      if (newName === "") {
        errors["SystemThirdPartyPayeeName"] = "This is a required field.";
      }
    }

    if (data.AddressLine1 !== undefined && data.AddressLine1 !== null) {
      newAddress1 = data.AddressLine1.trim();

      if (newAddress1 === "") {
        errors["AddressLine1"] = "This is a required field.";
      }
    }

    if (data.AddressZipCode !== undefined) {
      if (data.AddressZipCode.length === 0) {
        errors["AddressZipCode"] = "This is a required field.";
        this.setState({
          showCityStateSection: false,
          AddressCity: "",
          AddressStateId: null,
          address: ""
        });
      } else if (data.AddressZipCode.length < 5) {
        errors["AddressZipCode"] = "Invalid zip code.";
        this.setState({
          showCityStateSection: false,
          AddressCity: "",
          AddressStateId: null,
          address: ""
        });
      } else if (
        data.AddressZipCode.length > 5 &&
        data.AddressZipCode.length < 9
      ) {
        errors["AddressZipCode"] = "Invalid zip code.";
        this.setState({
          showCityStateSection: false,
          AddressCity: "",
          AddressStateId: null,
          address: ""
        });
      }
    }

    if (data.AddressCity !== undefined && data.AddressCity !== null) {
      newAddressCity = data.AddressCity.trim();
      data.AddressCity = newAddressCity;
      if (newAddressCity === "") {
        errors["AddressCity"] = "This is a required field.";
      }
    }

    errors = validator.validateRequiredField(
      data.ThirdPartyPayeePaymentTypeId,
      "ThirdPartyPayeePaymentTypeId",
      errors
    );

    if (
      String(data.ThirdPartyPayeePaymentTypeId) ===
      String(systemConstantsId.THIRD_PARTY_PAYEE_DIRECT_DEPOSIT)
    ) {
      errors = validator.validateRequiredField(
        data.BankName,
        "BankName",
        errors
      );

      if (data.BankName !== undefined && data.BankName !== null) {
        newBankName = data.BankName.trim();

        if (newBankName === "") {
          errors["BankName"] = "This is a required field.";
        }
      }

      errors = validator.validateRequiredField(
        data.BankRoutingNumber,
        "BankRoutingNumber",
        errors
      );

      if (data.BankRoutingNumber.length === 0) {
        errors["BankRoutingNumber"] = "This is a required field.";
      } else if (data.BankRoutingNumber.length !== 9) {
        errors["BankRoutingNumber"] = "Invalid routing number";
      } else {
        let modRoutingNumber = this.getModBankRoutingNumber(
          data.BankRoutingNumber
        );
        if (modRoutingNumber !== 0) {
          errors["BankRoutingNumber"] = "Invalid routing number";
        }
      }

      let backAccountNumberLength = parseInt(
        data.BankAccountNumber.trim().length,
        10
      );

      if (backAccountNumberLength === 0) {
        errors["BankAccountNumber"] = "This is a required field.";
      } else if (backAccountNumberLength < 5) {
        errors["BankAccountNumber"] = "Invalid Bank Account Number";
      }

      errors = validator.validateRequiredField(
        data.BankAccountTypeId,
        "BankAccountTypeId",
        errors
      );
    }

    this.setState({
      errors,
      showUpdateModal: false
    });

    if (Object.keys(errors).length === 0) {
      if (this.props.systemThirdPartyPayeeData.UseCount > 0) {
        this.openUpdatelModal();
      } else {
        this.updateThirdPartyPayee();
      }
    }
  };

  renderButtons() {
    if (this.props.systemThirdPartyPayeeData.SystemThirdPartyPayeeId > 0) {
      return (
        <div className="nc-button-group">
          <button
            type="button"
            id="third-party-payee-update-button"
            className="nc-button primary-button"
            // className={
            //   this.state.IsFormUpdated
            //     ? this.state.validationCount === 0 &&
            //       this.state.validationCountPaymentInfo === 0 &&
            //       Object.keys(this.state.errors).length === 0
            //       ? "nc-button secondary-button"
            //       : "disabled-button"
            //     : "disabled-button"
            // }
            // disabled={
            //   this.state.IsFormUpdated
            //     ? this.state.validationCount === 0 &&
            //       this.state.validationCountPaymentInfo === 0 &&
            //       Object.keys(this.state.errors).length === 0
            //       ? false
            //       : true
            //     : true
            // }
            onClick={() => this.checkPageValidations()}
          >
            SAVE CHANGES
          </button>
          <div className="nc-link-group-mobile contains-single-link">
            <button
              type="button"
              id="third-party-payee-cancel-buttonlink"
              className="secondary-link-button"
              onClick={this.openCancelModal}
            >
              Cancel
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="nc-button-group">
          <button
            type="button"
            id="third-party-payee-create-button"
            className="nc-button primary-button"
            // className={
            //   this.state.validationCount === 0 &&
            //   this.state.validationCountPaymentInfo === 0 &&
            //   Object.keys(this.state.errors).length === 0
            //     ? "nc-button secondary-button"
            //     : "disabled-button"
            // }
            // disabled={
            //   this.state.validationCount === 0 &&
            //   this.state.validationCountPaymentInfo === 0 &&
            //   Object.keys(this.state.errors).length === 0
            //     ? false
            //     : true
            // }
            onClick={this.createThirdPartyPayee}
          >
            ADD THIRD-PARTY PAYEE
          </button>
          <div className="nc-link-group-mobile contains-single-link">
            <button
              type="button"
              id="third-party-payee-cancel-buttonlink"
              className="secondary-link-button"
              onClick={this.openCancelModal}
            >
              Cancel
            </button>
          </div>
        </div>
      );
    }
  }

  showDirectDepositSection() {
    let BankRoutingNumber = this.state.errors["BankRoutingNumber"];
    let BankAccountTypeId = this.state.errors["BankAccountTypeId"];
    return (
      <div>
        <div id="direct-deposit-section" className="nc-field-group clearfix">
          <div className="field-name">
            <span
              className={utilities.getErrorClassName(
                this.state.errors["BankName"]
              )}
            >
              Bank Name
            </span>
          </div>

          <div className="input-field col-lg-5 col-md-6">
            <TextInputWithoutLabel
              value={this.state.BankName}
              name="BankName"
              onChange={this.handleChangeBankName}
              disabled={false}
              error={this.state.errors["BankName"]}
              id="third-party-payee-bank-name-textbox"
              maxLength="30"
            />
          </div>
        </div>

        <div className="nc-field-group">
          <div className="field-name">
            <span
              className={utilities.getErrorClassName(
                this.state.errors["BankAccountTypeId"]
              )}
            >
              Account Type
            </span>
          </div>
          <div className="radio-button-group">{this.renderAccountType()}</div>
          {BankAccountTypeId && (
            <p
              id="third-party-payee-invalid-routing-number-text"
              className="error-label"
            >
              {BankAccountTypeId}
            </p>
          )}
        </div>

        <div className="nc-inline-fields-group clearfix">
          <div className="nc-field-group col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <div
              className={
                !BankRoutingNumber ? "field-name" : "field-name error-label"
              }
            >
              <span
                className={utilities.getErrorClassName(
                  this.state.errors["BankRoutingNumber"]
                )}
              >
                Bank Routing Number
              </span>
            </div>
            <div className="input-field">
              <div
                className={
                  !BankRoutingNumber
                    ? "deduction-page-section-input"
                    : `deduction-page-section-input has-error`
                }
              >
                <NumberFormat
                  id="third-party-payee-bank-routing-number-textbox"
                  className="form-control"
                  value={this.state.BankRoutingNumber}
                  allowNegative={false}
                  onChange={this.handleChangeBankRoutingNumber}
                  format="#########"
                />
                {BankRoutingNumber && (
                  <p
                    id="third-party-payee-invalid-routing-number-text"
                    className="error-label"
                  >
                    {BankRoutingNumber}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="nc-field-group col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <div className="field-name">
              <span
                className={utilities.getErrorClassName(
                  this.state.errors["BankAccountNumber"]
                )}
              >
                Bank Account Number
              </span>
            </div>
            <div className="input-field">
              <TextInputWithoutLabel
                id="third-party-payee-bank-account-number-textbox"
                className="form-control"
                value={this.state.BankAccountNumber}
                allowNegative={false}
                onChange={this.handleChangeBankAccountNumber}
                name="BankAccountNumber"
                disabled={false}
                error={this.state.errors["BankAccountNumber"]}
                maxLength="17"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  zipCodeFormat(val) {
    let zipCode5 = val.substring(0, 5);
    let zipCode9 = val.substring(5, 9);
    return zipCode5 + (zipCode9.length ? "-" + zipCode9 : "");
  }

  validateStateCode() {
    let isStateCodeValidate = false;

    let stateCode = this.state.addressState;
    if (
      this.props.countryStateList !== undefined &&
      this.props.countryStateList !== null
    ) {
      let isStatePresent = this.props.countryStateList.Data.filter(function(
        item
      ) {
        return item.ShortName === stateCode;
      });

      if (isStatePresent.length > 0) {
        this.setState({
          AddressStateId: isStatePresent[0].CountryStateId
        });
        isStateCodeValidate = true;
      }
    }
    return isStateCodeValidate;
  }

  render() {
    if (!this.props.thirdPartyPayeeCategory) {
      return <div>No Records Found.</div>;
    }
    const thirdPartyPayeeCategory = this.props.thirdPartyPayeeCategory;
    const countryStateListForDropdown = this.props.countryStateListForDropdown;

    let AddressZipCode = this.state.errors["AddressZipCode"];
    let AddressStateId = this.state.errors["AddressStateId"];
    let ThirdPartyPayeePaymentTypeId = this.state.errors[
      "ThirdPartyPayeePaymentTypeId"
    ];
    let ThirdPartyPayeeCategoryId = this.state.errors[
      "ThirdPartyPayeeCategoryId"
    ];

    return (
      <div id="thirdparty-payee-add-page" className="nc-entity-page-container">
        <Prompt
          when={this.state.IsFormUpdated}
          message={message.UNSAVED_CHANGES}
        />

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showBackCancelModal}
          onHide={this.close}
          id="third-party-payee-cancel-modal"
        >
          {/* {this.showUpdateCancelButtonMessage()} */}
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="system-level-cancel-button-close-icon"
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div
              id="third-party-cancel-modal-text"
              className="modal-row text-center"
            >
              <div>
                Are you sure you want to leave this page in the middle of making
                edits?
              </div>
              <br />
              <div>Changes made on this page will not be saved.</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="cancel-modal-leave-button"
                className="nc-button secondary-button"
                onClick={this.redirectToGlobalNavLink}
              >
                LEAVE THIS PAGE
              </button>
              <button
                type="button"
                id="cancel-modal-continue-editing"
                className="secondary-link-button "
                onClick={this.close}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <SystemThirdPartyPayeeSetupDescription />
        <div
          id="thirdparty-payee-add-page-title1"
          className="title-band-container"
        >
          {this.props.systemThirdPartyPayeeData.SystemThirdPartyPayeeId > 0 ? (
            <div className="title-band" id="thirdparty-payee-edit-title">
              Edit a Third-Party Payee
            </div>
          ) : (
            <div className="title-band" id="thirdparty-payee-add-title">
              Add a Third-Party Payee
            </div>
          )}
        </div>
        <div
          id="thirdparty-payee-description-section"
          className="entity-page-content"
        >
          <div
            id="thirdparty-payee-add-page-title2"
            className="step-title-container clearfix"
          >
            <div className="step-title">Third-Party Payee Details</div>
          </div>

          <div className="seperator">
            <hr />
          </div>

          <div className="description-content">
            <div className="entity-form-section-secondary">
              <div className="title" id="thirdparty-payee-description">
                Description
              </div>
              <div className="content">
                <div className="nc-field-group clearfix">
                  <div className="field-name">
                    <span
                      className={utilities.getErrorClassName(
                        this.state.errors["SystemThirdPartyPayeeName"]
                      )}
                    >
                      Name of the third-party payee
                    </span>
                    <div className="field-description col-xs-12 col-sm-12 hidden-md hidden-lg">
                      <i> 50 characters max</i>
                    </div>
                  </div>
                  <div className="input-field col-lg-4 col-md-6">
                    <TextInputWithoutLabel
                      id="thirdparty-payee-name-textbox"
                      value={this.state.SystemThirdPartyPayeeName}
                      name="SystemThirdPartyPayeeName"
                      onChange={this.handleChangeName}
                      disabled={false}
                      error={this.state.errors["SystemThirdPartyPayeeName"]}
                      maxLength="50"
                    />
                  </div>
                  <div className="inline-field-description hidden-sm hidden-xs col-md-5 col-lg-5">
                    <i> &nbsp; 50 characters max</i>
                  </div>
                </div>

                <div className="nc-field-group clearfix">
                  <div className="field-name">
                    <span
                      className={utilities.getErrorClassName(
                        this.state.errors["ThirdPartyPayeeCategoryId"]
                      )}
                    >
                      What type of third-party payee is this?
                    </span>
                  </div>
                  <div className="input-field col-lg-4 col-md-5">
                    <DropDown
                      id="select-third-payee-type-dropdown"
                      placeholder="Select"
                      options={thirdPartyPayeeCategory}
                      value={this.state.ThirdPartyPayeeCategoryId}
                      onSelect={this.setThirdPartyPayeeCategoryClickHandler}
                      error={this.state.errors["ThirdPartyPayeeCategoryId"]}
                    />
                    {ThirdPartyPayeeCategoryId && (
                      <p
                        id="third-party-payee-invalid-zipcode-number-text"
                        className="error-label"
                      >
                        {ThirdPartyPayeeCategoryId}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="seperator">
              <hr />
            </div>

            <div
              className="entity-form-section-secondary"
              id="third-party-payee-mailing-address-section"
            >
              <div className="title" id="third-party-payee-mailing-address">
                Mailing Address
              </div>
              <div className="content">
                <div className="nc-field-group clearfix">
                  <span
                    className={utilities.getErrorClassName(
                      this.state.errors["SystemThirdPartyPayeeName"]
                    )}
                  />
                  <div className="field-name">
                    <span
                      className={utilities.getErrorClassName(
                        this.state.errors["AddressLine1"]
                      )}
                    >
                      Address{" "}
                      <span className="third-party-input-info-message">
                        (Line 1)
                      </span>
                    </span>
                  </div>
                  <div className="input-field col-lg-4 col-md-5">
                    <TextInputWithoutLabel
                      value={this.state.AddressLine1}
                      name="AddressLine1"
                      onChange={this.handleChangeAddressLine1}
                      disabled={false}
                      error={this.state.errors["AddressLine1"]}
                      id="third-party-payee-mailing-address1-textbox"
                      maxLength="30"
                    />
                  </div>
                </div>

                <div className="nc-field-group clearfix">
                  <div className="field-name">
                    <span
                      className={utilities.getErrorClassName(
                        this.state.errors["AddressLine2"]
                      )}
                    >
                      Address{" "}
                      <span className="third-party-input-info-message">
                        (Line 2 - Optional)
                      </span>
                    </span>
                  </div>
                  <div className="input-field col-lg-4 col-md-5">
                    <TextInputWithoutLabel
                      value={this.state.AddressLine2}
                      name="AddressLine2"
                      onChange={this.handleChangeAddressLine2}
                      disabled={false}
                      id="third-party-payee-mailing-address2-textbox"
                      maxLength="30"
                    />
                  </div>
                </div>

                {/*zipcode section*/}
                <div className="nc-inline-fields-group clearfix">
                  <div className="nc-field-group col-xs-12 col-sm-12 col-md-3 col-lg-2">
                    <div className="field-name">
                      <span
                        className={utilities.getErrorClassName(
                          this.state.errors["AddressZipCode"]
                        )}
                      >
                        Zip Code
                      </span>
                    </div>
                    <div className="input-field">
                      <div className="">
                        <NumberFormat
                          id="third-party-payee-zipcode-textbox"
                          className="form-control"
                          value={this.state.AddressZipCode}
                          name="AddressZipCode"
                          format={this.zipCodeFormat}
                          placeholder="70122"
                          onValueChange={this.handleChangeAddressZipCode}
                        />
                        {AddressZipCode && (
                          <p
                            id="third-party-payee-invalid-zipcode-number-text"
                            className="error-label"
                          >
                            {AddressZipCode}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  {this.state.showCityStateSection ? (
                    <div>
                      <div className="nc-field-group col-xs-12 col-sm-12 col-md-3 col-lg-2">
                        <div className="field-name">
                          <span
                            className={utilities.getErrorClassName(
                              this.state.errors["AddressCity"]
                            )}
                          >
                            City
                          </span>
                        </div>
                        <div className="input-field">
                          <TextInputWithoutLabel
                            id="thirdparty-payee-city-textbox"
                            value={this.state.AddressCity}
                            name="AddressCity"
                            onChange={this.handleChangeCity}
                            disabled={false}
                            error={this.state.errors["AddressCity"]}
                            maxLength="20"
                          />
                        </div>
                      </div>

                      <div className="nc-field-group col-xs-12 col-sm-12 col-md-3 col-lg-2">
                        <div className="field-name">
                          <span
                            className={utilities.getErrorClassName(
                              this.state.errors["AddressStateId"]
                            )}
                          >
                            State
                          </span>
                        </div>
                        <div className="input-field">
                          <DropDown
                            id="select-third-payee-type-dropdown"
                            placeholder="Select"
                            options={countryStateListForDropdown}
                            value={this.state.AddressStateId}
                            onSelect={
                              this.setThirdPartyPayeeAddressStateClickHandler
                            }
                            error={this.state.errors["AddressStateId"]}
                          />
                          {AddressStateId && (
                            <p
                              id="third-party-payee-invalid-zipcode-number-text"
                              className="error-label"
                            >
                              {AddressStateId}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="seperator">
              <hr />
            </div>

            <div
              className="entity-form-section-secondary"
              id="third-party-payee-payment-information-section"
            >
              <div className="title" id="third-party-payee-payment-information">
                Payment Information
              </div>
              <div className="content">
                <div className="nc-field-group clearfix">
                  <span
                    className={
                      utilities.getErrorClassName(
                        this.state.errors["ThirdPartyPayeePaymentTypeId"]
                      ) + " field-name"
                    }
                    style={{ display: "block" }}
                  >
                    How do you want payments to be generated?
                  </span>
                  <div className="radio-button-group">
                    {this.renderPaymentType()}
                  </div>
                  {this.state.openDirectDepositSection && (
                    <div>{this.showDirectDepositSection()}</div>
                  )}
                  {ThirdPartyPayeePaymentTypeId && (
                    <p
                      id="third-party-payee-invalid-zipcode-number-text"
                      className="error-label"
                    >
                      {ThirdPartyPayeePaymentTypeId}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="seperator">
            <hr />
          </div>

          <div className="footer-button-container">{this.renderButtons()}</div>

          <div className="clearfix" />
        </div>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showUpdateModal}
          onHide={this.close}
          id="third-party-payee-cancel-button-unsaved-modal"
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="system-level-cancel-button-close-icon"
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div
              id="third-party-save-change-modal-text"
              className="modal-row row col-xs-12 col-sm-12 col-md-12 col-lg-12"
            >
              Note that any changes made to a payee template - excluding payment generation method
              - will be propagated to all third-party payees that were created from the template. 
              Changes to payment generation method will only be propagated to third-party payees
              that have not yet been paid. Are you sure you wish to make these changes?  
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="third-party-payee-cancel-button-unsaved-changes-warning-continue-editing"
                className="nc-button secondary-button"
                onClick={this.updateThirdPartyPayee}
              >
                Yes
              </button>
              <button
                type="button"
                id="third-party-payee-cancel-button-cancels-changes"
                className="secondary-link-button "
                onClick={this.close}
              >
                No
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showCancelModal}
          onHide={this.close}
          id="third-party-payee-cancel-modal-2"
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="system-level-cancel-button-close-icon"
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div>
                Are you sure you want to leave this page in the middle of making
                edits?
              </div>
              <br />
              <div>Changes made on this page will not be saved.</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="third-party-payee-leave-this-page-button"
                className="nc-button secondary-button"
                onClick={this.redirectToSystemThirdPartyPayeeListingPage}
              >
                LEAVE THIS PAGE
              </button>
              <button
                type="button"
                id="third-party-payee-continue-editing-button"
                className="nc-button secondary-link-button"
                onClick={this.close}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let thirdPartyPayeeCategory = [];

  if (
    state.thirdPartyPayeeCategoryList.length > 0 ||
    state.thirdPartyPayeeCategoryList.DataCount > 0
  ) {
    thirdPartyPayeeCategory = state.thirdPartyPayeeCategoryList.Data.map(
      (item, index) => {
        return {
          value: item.ThirdPartyPayeeCategoryId,
          label: item.Name
        };
      }
    );
  }

  let countryStateListForDropdown = [];

  if (
    state.countryStateList.length > 0 ||
    state.countryStateList.DataCount > 0
  ) {
    countryStateListForDropdown = state.countryStateList.Data.map(
      (item, index) => {
        return {
          value: item.AddressStateId,
          label: item.Name
        };
      }
    );
  }

  return {
    thirdPartyPayeeCategoryList: state.thirdPartyPayeeCategoryList,
    thirdPartyPayeeCategory: thirdPartyPayeeCategory,
    thirdPartyPayeePaymentType: state.thirdPartyPayeePaymentType,
    thirdPartyPayeeAccountType: state.thirdPartyPayeeAccountType,
    thirdPartyPayeePrintSsnOnCheckOption:
      state.thirdPartyPayeePrintSsnOnCheckOption,
    zipCode: state.zipCode,
    systemThirdPartyPayeeData: state.systemThirdPartyPayeeData,
    toasterMessage: state.toasterMessage,
    buttonTypeForToasterMessage: state.buttonTypeForToasterMessage,
    countryStateList: state.countryStateList,
    globalNavClick: state.globalNavClick,
    countryStateListForDropdown: countryStateListForDropdown,
    clearThirdPartyPayeeSetupList: clearThirdPartyPayeeSetupList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCategoryList: getCategoryList,
      getPaymentType: getPaymentType,
      getAccountType: getAccountType,
      getPrintSsnOnCheckOption: getPrintSsnOnCheckOption,
      getZipCode: getZipCode,
      setThirdPartyPayeeData: setThirdPartyPayeeData,
      getToasterMessage: getToasterMessage,
      getButtonTypeForToasterMessage: getButtonTypeForToasterMessage,
      setLoader: setLoader,
      showToaster: showToaster,
      getCountryStateList: getCountryStateList,
      setFormUpdate: setFormUpdate,
      fetchAndSetEntityData: fetchAndSetEntityData,
      isOverlayClickable: isOverlayClickable,
      setGlobalNavClick: setGlobalNavClick,
      CallbackToQuickLink: CallbackToQuickLink
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SystemThirdPartyPayeeAddPage)
);
