import React from "react";
import Media from "react-media";
import EarningsFiltersDesktop from "./EarningsFiltersDesktop";
import EarningsFiltersMobile from "./EarningsFiltersMobile";

const EarningsFilters = props => (
  <Media query="(max-width: 991px)">
    {isMobile => {
      const Filters = isMobile ? EarningsFiltersMobile : EarningsFiltersDesktop;
      return <Filters {...props} />;
    }}
  </Media>
);

export default EarningsFilters;
