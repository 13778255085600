import React, { Component } from "react";
import { TabContainer } from "component-library/employee-profile/entity-view";
import PrimaryContact from "component-library/employee-profile/emergencyContact/PrimaryContact";
import SecondaryContact from "component-library/employee-profile/emergencyContact/SecondaryContact";
import DisasterLocation from "component-library/employee-profile/emergencyContact/DisasterLocation";
import { Element } from "react-scroll";
import { DEMOGRAPHIC_HEADER } from "../Constants";
import { validateForPhone, validateEmail } from "../utils";
import { getEmergencyStateSchema } from "../utils/schema";

class EmergencyContact extends Component {
  state = {
    activePanel: {},
    errors: {},
    employeeId: this.props.match.params.EmployeeId,
    showToaster: false,
    toasterClass: "",
    toasterMessage: "",
    isAddModeOn: false
  };
  componentDidMount() {
    this.props.fetchAndRelationShip();
    this.props.fetchAndSetEmergencyStates(1, "primaryContactState");
    this.props.fetchAndSetEmergencyStates(1, "secContactState");
    this.props.fetchAndSetEmergencyStates(1, "disasterState");
  }
  static getDerivedStateFromProps(props) {
    if (props.data.Data.length > 0) {
      let employeeData = props.data.Data[0];
      let stateData = getEmergencyStateSchema(employeeData);
      return {
        ...stateData,
        initialData: props.EmergencyContactData
      };
    } else {
      return {
        initialData: props.EmergencyContactData
      };
    }
  }

  toggleToaster = () => {
    this.setState({
      showToaster: false
    });
  };

  setToaster = (toasterMessage, toasterClass) => {
    this.setState({
      toasterClass: toasterClass,
      toasterMessage: toasterMessage,
      showToaster: true
    });
  };

  validation = () => {
    let values = this.state;
    let errors = {};
    if (
      values.primary_contact_personalEmail &&
      !validateEmail(values.primary_contact_personalEmail)
    ) {
      errors["primary_contact_personalEmail"] = "*Please input correct email.";
    }
    if (
      values.primary_contact_homePhone &&
      !validateForPhone(values.primary_contact_homePhone)
    ) {
      errors["primary_contact_homePhone"] =
        "*Please Enter the value in correct Format eg. 333-454-3344";
    }
    if (
      values.primary_contact_cellPhoneNumber &&
      !validateForPhone(values.primary_contact_cellPhoneNumber)
    ) {
      errors["primary_contact_cellPhoneNumber"] =
        "*Please Enter the value in correct Format eg. 333-454-3344";
    }
    if (
      values.primary_contact_workPhoneNumber &&
      !validateForPhone(values.primary_contact_workPhoneNumber)
    ) {
      errors["primary_contact_workPhoneNumber"] =
        "*Please Enter the value in correct Format eg. 333-454-3344";
    }
    if (
      values.sec_contact_personalEmail &&
      !validateEmail(values.sec_contact_personalEmail)
    ) {
      errors["sec_contact_personalEmail"] = "*Please input correct email.";
    }
    if (
      values.sec_contact_homePhone &&
      !validateForPhone(values.sec_contact_homePhone)
    ) {
      errors["sec_contact_homePhone"] =
        "*Please Enter the value in correct Format eg. 333-454-3344";
    }
    if (
      values.sec_contact_cellPhoneNumber &&
      !validateForPhone(values.sec_contact_cellPhoneNumber)
    ) {
      errors["sec_contact_cellPhoneNumber"] =
        "*Please Enter the value in correct Format eg. 333-454-3344";
    }
    if (
      values.sec_contact_workPhoneNumber &&
      !validateForPhone(values.sec_contact_workPhoneNumber)
    ) {
      errors["sec_contact_workPhoneNumber"] =
        "*Please Enter the value in correct Format eg. 333-454-3344";
    }
    if (
      values.disasterLocationPhone &&
      !validateForPhone(values.disasterLocationPhone)
    ) {
      errors["disasterLocationPhone"] =
        "*Please Enter the value in correct Format eg. 333-454-3344";
    }
    if (Object.keys(errors).length === 0) {
      this.props.updateEmployeeEmergencyData(this.state, this.state.employeeId);
    } else {
      this.setState({ errors });
    }
  };

  onChange = e => {
    let name = e.target ? e.target.name : e.name;
    let value = e.target ? e.target.value : e.value;
    this.setState({
      [name]: value
    });
  };

  render() {
    // const { ActivePanelId } = this.props.match.params;
    // let activePanelId = parseInt(ActivePanelId, 10);

    return (
      <div>
        <TabContainer
          activePanelHeader={DEMOGRAPHIC_HEADER[2]}
          isAddModeOn={this.state.isAddModeOn}
          showToaster={this.state.showToaster}
          toasterClass={this.state.toasterClass}
          toasterMessage={this.state.toasterMessage}
          toggleToaster={this.toggleToaster}
        >
          <Element name="Primary Contact" className="element" id="element1">
            <PrimaryContact data={this.state} onChange={this.onChange} />
          </Element>
          <Element name="Secondary Contact" className="element" id="element1">
            <SecondaryContact data={this.state} onChange={this.onChange} />
          </Element>
          <Element name="Disaster Location" className="element" id="element1">
            <DisasterLocation
              data={this.state}
              onChange={this.onChange}
              onSave={() => {
                this.validation();
              }}
            />
          </Element>
        </TabContainer>
      </div>
    );
  }
}

export default EmergencyContact;
