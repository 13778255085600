/** Actions */
const ADD_TO_EMPLOYEE_TREE_VIEW =
  "people-employee-tree/ADD_TO_EMPLOYEE_TREE_VIEW";
const CLEAR_EMPLOYEE_TREE_VIEW =
  "people-employee-tree/CLEAR_EMPLOYEE_TREE_VIEW";
const SET_EMPLOYEE_TREE_VIEW_LOADING =
  "people-employee-tree/SET_EMPLOYEE_TREE_VIEW_LOADING";

export const initialState = {
  loading: true,
  Data: [],
  DataCount: 0,
  NextUri: null
};

/** Reducer */
const employeeTreeView = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_EMPLOYEE_TREE_VIEW:
      return initialState;
    case ADD_TO_EMPLOYEE_TREE_VIEW: {
      const { Data, DataCount, NextUri } = payload;
      return {
        ...state,
        NextUri,
        Data: Data,
        DataCount: DataCount,
        loading: false
      };
    }
    case SET_EMPLOYEE_TREE_VIEW_LOADING:
      return {
        ...state,
        loading: payload
      };
    default:
      return state;
  }
};

export default employeeTreeView;

/** Action creators */
export const addToEmployeeTreeView = (Data, DataCount, NextUri) => ({
  type: ADD_TO_EMPLOYEE_TREE_VIEW,
  payload: { Data, DataCount, NextUri }
});

export const clearEmployeeTreeView = () => ({
  type: CLEAR_EMPLOYEE_TREE_VIEW
});

export const setEmployeeTreeViewLoading = loading => ({
  type: SET_EMPLOYEE_TREE_VIEW_LOADING,
  payload: loading
});
