import * as types from "../../actions/companylevel/index";
export default function(state = [], action) {
  switch (action.type) {
    case types.COMPANY_FETCH_DEDUCTION_VALUES_LIST:
      return action.payload.data;

    default:
      return state;
  }
}
