import React from "react";
import DayPicker from "./src/DayPicker";
import DateUtils from "./src/DateUtils";
import moment from "moment";
import Select from "react-select";
import Media from "react-media";
import "./src/style.css";
import { Overlay, Modal } from "react-bootstrap";
import SingleCheckBox from "../../checkBoxGroup/SingleCheckBox";
const initialState = {
  from: null,
  to: null,
  enteredTo: null, // Keep track of the last day for mouseEnter.
  selectedDay: null
};

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const fromMonth = new Date(currentYear, currentMonth);
const toMonth = new Date(currentYear + 50, 11);

function isSelectingFirstDay(from, to, day) {
  const firstDayIsNotSelected = !from;
  const selectedDayIsBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
  const rangeIsSelected = from && to;
  return (
    firstDayIsNotSelected || selectedDayIsBeforeFirstDay || rangeIsSelected
  );
}

// Component will receive date, locale and localeUtils props
function YearMonthForm({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const logChange = function logChange(val) {
    let value = JSON.stringify(val.value);
    onChange(new Date(value, currentMonth));
  };
  function showCustomIcon() {
    return <i className="fa fa-chevron-down" aria-hidden="true" />;
  }
  let select_options = [];
  years.map((year, i) => select_options.push({ value: year, label: year }));

  return (
    <form className="DayPicker-Caption">
      {/* <select name="month" onChange={handleChange} value={date.getMonth()} disabled>
        {months.map((month, i) => <option key={i} value={i}>{month}</option>)}
      </select> */}
      <span className="month-name">{months[date.getMonth()]}</span>
      {/* <select className="year-selector" name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map((year, i) =>
          <option key={i} value={year}>
            {year}
          </option>
        )}
      </select> */}
      <Select
        name="year"
        className="year-selector"
        value={date.getFullYear()}
        options={select_options}
        onChange={logChange}
        arrowRenderer={showCustomIcon}
        clearable={false}
        searchable={false}
      />
    </form>
  );
}

export default class SingleDatePicker extends React.Component {
  state = {
    month: fromMonth,
    from: null,
    to: null,
    enteredTo: null,
    selectedDay: this.props.value
  };

  componentWillUpdate(nextProps, nextState) {
    if (this.props !== nextProps) {
      if (nextProps.value === undefined) {
        this.setState({
          selectedDay: null
        });
      } else {
        this.setState({
          selectedDay: nextProps.value
        });
      }
    }
  }

  handleYearMonthChange = month => {
    this.setState({ month });
  };

  handleDayClick = (day, { selected }) => {
    let selectedDay = selected ? undefined : day;
    this.props.getFromToValue(
      { name: this.props.name, value: selectedDay },
      () => {
        this.setState({
          selectedDay,
          show: false
        });
      }
    );

    this.toggle();
    this.props.onDateChange && this.props.onDateChange(this.props.name, day);
  };

  handleDayMouseEnter = day => {
    const { from, to } = this.state;

    if (!isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day
      });
    }
  };

  reset = () => {
    this.setState(initialState);
    this.props.getFromToValue({ name: this.props.name, value: null });
  };
  toggle = () => {
    this.setState({ show: !this.state.show });
  };

  onHideOverlay = () => {};

  render() {
    const { props } = this;
    const { from, enteredTo, selectedDay } = this.state;
    const modifiers = { start: from, end: enteredTo };
    const selectedDays = [from, { from, to: enteredTo }];
    const placeHolder = this.props.placeHolder
      ? this.props.placeHolder
      : "Select";
    const DayPickerButton = ({ style, ref }) => (
      <button ref={ref} className="" onClick={this.toggle} style={style}>
        <i className="fa fa-calendar calendar-icon" aria-hidden="true" />

        <span style={{ fontSize: "13.5px" }}>
          {selectedDay ? moment(selectedDay).format("MM/DD/YYYY") : placeHolder}
        </span>
      </button>
    );
    return (
      <Media query="(min-width:992px)">
        {matches =>
          matches ? (
            <div
              className={"nc-select-container " + this.props.className}
              id="filter-activity-darange-overlay"
              style={{ position: "relative", ...this.props.style }}
              ref={button => (this.target = button)}
            >
              <DayPickerButton style={this.props.btnStyle} />

              <Overlay
                show={this.state.show}
                onHide={() => {
                  this.setState({ show: false });
                  this.onHideOverlay();
                  this.props.onHide();
                }}
                placement="bottom"
                container={this}
                target={this.target}
                rootClose={true}
              >
                <div className="YearNavigation">
                  <DayPicker
                    enableOutsideDays
                    selectedDays={this.state.selectedDay}
                    month={this.state.month}
                    fromMonth={fromMonth}
                    toMonth={toMonth}
                    onDayClick={this.handleDayClick}
                    captionElement={
                      <YearMonthForm onChange={this.handleYearMonthChange} />
                    }
                  />
                  {/* <div className="calendar-button-group-wrapper">
                  </div> */}
                  {props.showNoDateSection && (
                    <div className="calendar-button-group-wrapper">
                      <div
                        className="calendar-button-group"
                        style={{ borderTop: "1px solid #ccc", padding: "15px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <span onClick={this.reset}>
                            <SingleCheckBox
                              onChange={() => {}}
                              value={!selectedDay ? true : false}
                              label="No end date"
                              labelStyle="italic"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Overlay>
              {props.error &&
                props.required && (
                  <div
                    style={{ marginTop: "5px", fontSize: "15px" }}
                    className="has-error"
                  >
                    {props.error}
                  </div>
                )}
            </div>
          ) : (
            <div className="nc-select-container" id="filter-activity-entity">
              <DayPickerButton />
              <Modal
                bsClass="calendar modal"
                animation={false}
                backdrop={"static"}
                keyboard={false}
                show={this.state.show}
                onHide={() => {
                  this.setState({ show: false });
                  this.onHideOverlay();
                }}
              >
                <Modal.Header>
                  <div className="hidden-md hidden-lg date-range-label">
                    <div
                      className="close-icon"
                      style={{ float: "left", fontSize: "22px" }}
                    >
                      <i
                        onClick={() => {
                          this.setState({ show: false });
                          this.onHideOverlay();
                        }}
                        className="fa fa-times-circle-o"
                      />
                    </div>
                    <div
                      className="date-picker-header"
                      style={{ fontSize: "20px" }}
                    >
                      Select Date
                    </div>
                  </div>
                </Modal.Header>
                <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="YearNavigation">
                    <DayPicker
                      enableOutsideDays
                      month={this.state.month}
                      fromMonth={fromMonth}
                      toMonth={toMonth}
                      onDayClick={this.handleDayClick}
                      onDayMouseEnter={this.handleDayMouseEnter}
                      modifiers={modifiers}
                      selectedDays={selectedDays}
                    />
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <div className="calendar-button-group-wrapper">
                    <div className="calendar-button-group">
                      <button
                        className="nc-button primary-button"
                        onClick={() => {
                          this.setState({ show: false });
                          this.onHideOverlay();
                          // this.props.onHide();
                        }}
                      >
                        Apply
                      </button>
                      <button
                        className="secondary-link-button pull-right"
                        onClick={this.reset}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
          )
        }
      </Media>
    );
  }
}
SingleDatePicker.defaultProps = {
  onHide: () => {}
};
