import React, { Component } from "react";
import { connect } from "react-redux";
import { JobHistoryTable } from "component-library/job-history";
import { searchAndSetJobHistory } from "../network/actions";

class JobHistoryTableContainer extends Component {
  componentDidMount() {
    this.props.loadData(this.props.activityEntityId);
  }

  render() {
    return (
      <JobHistoryTable data={this.props.data} changeSortOrder={() => {}} />
    );
  }
}

const stateToProps = state => ({
  data: state.jobHistory.jobHistoryItems.Data
});

const actionsCreators = {
  loadData: searchAndSetJobHistory
};

export default connect(stateToProps, actionsCreators)(JobHistoryTableContainer);
