import React from "react";
import MultiSectionListHeader from "./MultiSectionListHeader";
import MultiSectionListItem from "./MultiSectionListItem";

const MultiSectionList = ({ data, sortOrder, changeSortOrder }) => (
  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <MultiSectionListHeader
      sortOrder={sortOrder}
      changeSortOrder={changeSortOrder}
    />
    <div>{data.map((row, i) => <MultiSectionListItem key={i} {...row} />)}</div>
  </div>
);

export default MultiSectionList;
