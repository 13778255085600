export const SET_EMPLOYEE_FILES_LIST =
  "people/employees/files/SET_EMPLOYEE_FILES_LIST";
export const CLEAR_EMPLOYEE_FILES_LIST =
  "people/employees/files/CLEAR_EMPLOYEE_FILES_LIST";

const employeeFilesList = (state = [], { type, payload }) => {
  switch (type) {
    case SET_EMPLOYEE_FILES_LIST:
      return payload;
    case CLEAR_EMPLOYEE_FILES_LIST:
      return [];
    default:
      return state;
  }
};

export default employeeFilesList;

export const setEmployeeFilesList = list => ({
  type: SET_EMPLOYEE_FILES_LIST,
  payload: list
});

export const clearEmployeeFilesList = () => ({
  type: CLEAR_EMPLOYEE_FILES_LIST
});
