import React from "react";
import PayrollDashboardSummaryItem from "./PayrollDashboardSummaryItem";
import PayrollDashboardSummaryHolidayHours from "./PayrollDashboardSummaryHolidayHours";
import PayrollDashboardSummaryEmployeeNotPaid from "./PayrollDashboardSummaryEmployeeNotPaid";
import PayrollDashboardSummaryPaycheckDate from "./PayrollDashboardSummaryPaycheckDate";
import * as payrollConstants from "./PayrollDashboardConstants";
import moment from "moment";
const convertDatetoMonthDay = date => {
  return moment(date).format("MMM D");
};

const PayrollDashboardSummaryItems = ({ data }) => {
  const isNotStarted = !(
    data.PayrollStageId === payrollConstants.PAYROLL_NOT_STARTED
  );

  const isPending = data.PayrollStageId === payrollConstants.PAYROLL_PENDING;
  return (
    <div className="payroll-dash-summary-content-section">
      <div className="payroll-dash-summary-item-container clearfix">
        <PayrollDashboardSummaryItem
          label="submit date"
          lvalue={convertDatetoMonthDay(data.SubmitDate)}
          isAllowEdit={!isPending}
        />
        <PayrollDashboardSummaryPaycheckDate
          label="paycheck date"
          lvalue={convertDatetoMonthDay(data.PaycheckDate)}
          isAllowEdit={!isPending}
        />
        <PayrollDashboardSummaryItem
          label="pay period"
          lvalue={convertDatetoMonthDay(data.PayPeriodFromDate)}
          rvalue={convertDatetoMonthDay(data.PayPeriodToDate)}
          groupIcon="long-arrow-right"
        />
        <PayrollDashboardSummaryItem
          label="pay cycle"
          lvalue={data.PayCyclePayrollNumber}
          rvalue={data.PayCyclePayrollsPerPayPeriod}
          customIcon="/"
        />
        <PayrollDashboardSummaryHolidayHours
          label="holiday hours"
          lvalue={data.HolidayHours}
          HolidayDates={data.HolidayDates}
        />

        <PayrollDashboardSummaryItem
          label="Employees Paid"
          lvalue={data.CountOfEmployeesPaid}
          visible={isNotStarted}
        />

        <PayrollDashboardSummaryEmployeeNotPaid
          label="Employees not Paid"
          lvalue={data.CountOfNotEmployeesPaid}
          EmployeesNotPaidList={data.EmployeesNotPaidList}
          visible={isNotStarted}
        />

        <PayrollDashboardSummaryItem
          label="hours"
          lvalue={data.Hours}
          visible={isNotStarted}
        />

        <PayrollDashboardSummaryItem
          label="gross pay"
          lvalue={data.GrossPayAmount}
          dollarIcon={true}
          visible={isNotStarted}
        />
      </div>
    </div>
  );
};

export default PayrollDashboardSummaryItems;
