import React, { Component } from "react";
import { connect } from "react-redux";
import { JobHistoryFilters } from "component-library/job-history";
import { fetchAndSetFilterOptions } from "../network/actions";
import {
  selectFilterOption,
  selectAllFilterOptions,
  selectDateRangeOption,
  clearAllFilters,
  makeOptions,
  makeSelectedOptions
} from "app/common/reducers/filters";
import { userPath, fieldPath, eventTypePath } from "../network";
import {
  mapEventTypeData,
  mapUserData
} from "component-library/job-history/util";

export const mapFieldData = f => ({
  value: f.Id,
  label: f.Name
});

class JobHistoryFiltersContainer extends Component {
  loadOptions = (type, path, mapper) => {
    const { fetchAndSetFilterOptions, activityEntityId } = this.props;
    fetchAndSetFilterOptions(activityEntityId, type, path, mapper, {
      selectAll: true
    });
  };

  componentDidMount() {
    this.loadOptions("user", userPath, mapUserData);
    this.loadOptions("field", fieldPath, mapFieldData);
    this.loadOptions("eventType", eventTypePath, mapEventTypeData);
  }

  makeOnSelect = name => (option, selected) => {
    this.props.selectOption(name, option, selected);
  };

  makeSelectAll = name => selected => {
    this.props.selectAll(name, selected);
  };

  render() {
    const { options, selectedOptions } = this.props;
    return (
      <JobHistoryFilters
        options={options}
        selectedOptions={selectedOptions}
        makeOnSelect={this.makeOnSelect}
        makeSelectAll={this.makeSelectAll}
        hasSecondRow
      />
    );
  }
}

const stateToProps = state => ({
  options: makeOptions(state.common.filters),
  selectedOptions: makeSelectedOptions(state.common.filters)
});

const actionCreators = {
  fetchAndSetFilterOptions,
  selectOption: selectFilterOption,
  selectAll: selectAllFilterOptions,
  selectDateRange: selectDateRangeOption,
  clearAllFilters: clearAllFilters
};

export default connect(stateToProps, actionCreators)(
  JobHistoryFiltersContainer
);
