import * as STATE from "../Constants";
export default function generateTrackerData(data) {
  return [
    {
      stepId: 1,
      stepName: "IN-QUEUE",
      timeStamp: data.TimeStampInqueue,
      state: [
        STATE.STATUS_COMPLETED,
        STATE.STATUS_CURRENTLY_INQUEUE,
        STATE.STATUS_INQUEUE,
        STATE.STATUS_COMPLETED_WITH_ERRORS,
        STATE.STATUS_CURRENTLY_INPROGRESS,
        STATE.STATUS_INPROGRESS,
        STATE.STATUS_FAILED,
        STATE.STATUS_CANCELLED
      ].includes(data.JobStatusId)
        ? true
        : false
    },
    {
      stepId: 2,
      stepName:
        data.JobStatusId === STATE.STATUS_CANCELLED
          ? "CANCELLED"
          : "IN-PROGRESS",
      timeStamp:
        data.JobStatusId === STATE.STATUS_CANCELLED
          ? data.TimeStampCompleted
          : data.TimeStampInprogress,
      state: [
        STATE.STATUS_CURRENTLY_INPROGRESS,
        STATE.STATUS_COMPLETED,
        STATE.STATUS_COMPLETED_WITH_ERRORS,
        STATE.STATUS_INPROGRESS,
        STATE.STATUS_FAILED,
        STATE.STATUS_CANCELLED
      ].includes(data.JobStatusId)
        ? true
        : false
    },
    {
      stepId: 3,
      stepName: data.JobStatusId === 8 ? "FAILED" : "COMPLETED",
      timeStamp: data.TimeStampCompleted,
      state: [
        STATE.STATUS_COMPLETED_WITH_ERRORS,
        STATE.STATUS_COMPLETED,
        STATE.STATUS_FAILED
      ].includes(data.JobStatusId)
        ? true
        : false,

      errorCount: data.ErrorCount
    }
  ];
}
