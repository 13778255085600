import React, { Component } from "react";
import JobHistoryHeaderContainer from "../containers/JobHistoryHeaderContainer";
import JobHistoryFiltersContainer from "../containers/JobHistoryFiltersContainer";
import JobHistoryActionsContainer from "../containers/JobHistoryActionsContainer";
import JobHistoryTableContainer from "../containers/JobHistoryTableContainer";

export default class JobHistoryPage extends Component {
  render() {
    const { activityEntityId, entityNamePlural } = this.props;
    return (
      <div className="nc-activity-history-page-container">
        <JobHistoryHeaderContainer entityNamePlural={entityNamePlural} />
        <JobHistoryFiltersContainer activityEntityId={activityEntityId} />
        <JobHistoryActionsContainer />
        <JobHistoryTableContainer activityEntityId={activityEntityId} />
      </div>
    );
  }
}
