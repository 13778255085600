import React from "react";
import FormAccordian from "component-library/accordians/FormAccordian";
import * as Fields from "./FieldNames";
import * as Description from "../FieldDescriptions";
import { FormFieldGroup, FormSection } from "component-library/formSections";
import { NoStatusChanges } from "./common";
const labelClass = "col-lg-3 col-md-3";

const Inactive = ({ onChange, data }) => {
  return (
    <FormAccordian title="Inactive" id="5">
      <div>
        <FormSection>
          {data[Fields.inactivationDate] !== undefined ? (
            <FormFieldGroup
              label="Inactivation Date"
              fieldType="inline"
              inputType="overrideWithComponent"
              componentType="singleDatePicker"
              colSpan={labelClass}
              btnStyle={{ height: "44px" }}
              getFromToValue={onChange}
              onChange={onChange}
              inputName={Fields.inactivationDate}
              value={data[Fields.inactivationDate]}
              checkedName={Fields.overrideInactivationDate}
              checkedValue={data[Fields.overrideInactivationDate]}
              tooltip={Description.INACTIVE_DATE}
              onHide={() => {}}
            />
          ) : (
            <NoStatusChanges entity="Inactive" />
          )}
        </FormSection>
      </div>
    </FormAccordian>
  );
};

export default Inactive;
