export const NUMERIC_INPUT = "numeric";
export const TEXT_INPUT = "text";
export const FREQUENCY_INPUT = "frequency";
export const TEXTAREA_INPUT = "textarea";
export const RADIO_INPUT = "radio";
export const DROPDOWN = "dropdown";
export const HOURLY_RATE = "hourlyRate";
export const CHECKBOX = "checkbox";
export const ICON_CHECKBOX = "iconcheckbox";
export const RADIO_WITH_ICON = "radiowithicon";
export const RADIO_BUTTON_WITH_ADD_LINK = "radioButtonWithAddLink";
export const SINGLE_DATE_PICKER = "singleDatePicker";
export const DATE_RANGE_PICKER = "dateRangePicker";
export const LABEL = "Label";
export const WORK_EMAIL_COMPONENT = "email";
export const RAISED_RADIO_BUTTONS = "raisedRadio";
export const LABEL_WITH_ICON = "labelWithIcon";
export const FLAT_RADIO_BUTTONS = "flatRadioButtons";
export const TEXT_WITH_FLAT_RADIO_BUTTONS = "inputWithRadioButtons";
export const TEXT_WITH_SIGN_INPUT = "textWithSign";
export const OVERRIDE_WITH_COMPONENT = "overrideWithComponent";
export const CUSTOM_RADIO_TAB = "customRadioTab";
export const CIRCULAR_CHECKBOX = "circularCheckbox";
export const DROPDOWN_WITH_LINK = "dropdownWithLink";
export const INPUT_RANGE = "inputRange";
export const MonthYear = {
  months: ["Month", "Month", "Month", "Month"],
  year: ["Year", "Year", "Year", "Year"]
};
export const NO_END_DATE = "noEndDateComponent";
