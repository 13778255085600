export const SET_ERROR = "@payroll-schedule/SET_ERROR";
export const CLEAR_ERROR = "@payroll-schedule/CLEAR_ERROR";

export const initialState = {
  error: ""
};

const payrollScheduleError = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ERROR:
      const { value } = payload;
      return {
        ...state,
        error: value
      };
    case CLEAR_ERROR:
      return (state = initialState);
    default:
      return state;
  }
};

export default payrollScheduleError;

export const setPayrollError = value => ({
  type: SET_ERROR,
  payload: { value }
});

export const clearPayrollError = () => ({
  type: CLEAR_ERROR
});
