/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Media from "react-media";
import "./MobileHeader.css";
const MobileHeader = ({
  onClick = () => {},
  title,
  icon = null,
  description,
  redirectToChecklist = () => {},
  badgeCount
}) => {
  return (
    <div className="tab-content-header-wrapper">
      <div className="tab-content-header">
        <div className="title">
          <Media query="(min-width:768px)">{icon}</Media>
          <div className="tab-content-header-title">
            {title}{" "}
            {badgeCount > 0 && (
              <Media query="(min-width:992px)">
                <span
                  style={{ marginLeft: "10px" }}
                  className="badge badge-count"
                >
                  {badgeCount}
                </span>
              </Media>
            )}
          </div>
        </div>
        <div className="customize-link">
          <a onClick={redirectToChecklist}>
            <i className="fa fa-cog" /> Customize Alerts
          </a>
        </div>
      </div>
      {description && <div style={{ marginTop: "10px" }}>{description}</div>}
      <Media query="(max-width:767px)">
        {
          <button className="inpage-back-list" id="back" onClick={onClick}>
            <i className="fa fa-angle-left" aria-hidden="true" />
            Back to Payroll Dashboard
          </button>
        }
      </Media>
    </div>
  );
};

export default MobileHeader;
MobileHeader.defaultProps = {
  onClick: () => {},
  title: "Tab Header",
  icon: null,
  //description: "Specify some tab description here",
  redirectToChecklist: () => {},
  badgeCount: 0
};
