import React from "react";

const EmployeeId = ({ height, width, style }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      height={height}
      width={width}
      viewBox="0 0 34.4 54.5"
      style={{ enableBackground: "new 0 0 34.4 54.5", ...style }}
      xmlSpace="preserve"
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{ __html: "\n\t.st0{fill:#555555;}\n" }}
      />
      <path
        className="st0"
        d="M30.4,6.2h-8.2V2.9c0-1.2-1-2.2-2.2-2.2h-4.4c-1.2,0-2.2,1-2.2,2.2v3.3H4.2c-1.8,0-3.3,1.5-3.3,3.3v40.3
	c0,1.8,1.5,3.3,3.3,3.3h26.2c1.8,0,3.3-1.5,3.3-3.3V9.5C33.6,7.7,32.2,6.2,30.4,6.2z M15.6,2.9H20v7.6h-4.4V2.9z M31.5,49.8
	c0,0.6-0.5,1.1-1.1,1.1H4.2c-0.6,0-1.1-0.5-1.1-1.1V9.5c0-0.6,0.5-1.1,1.1-1.1h9.2v2.2c0,1.2,1,2.2,2.2,2.2H20c1.2,0,2.2-1,2.2-2.2
	V8.4h8.2c0.6,0,1.1,0.5,1.1,1.1C31.5,9.5,31.5,49.8,31.5,49.8z M10.7,40h13.1v2.2H10.7V40z M26,40h2.2v2.2H26V40z M6.4,40h2.2v2.2
	H6.4V40z M10.7,45.4h13.1v2.2H10.7V45.4z M26,45.4h2.2v2.2H26V45.4z M6.4,45.4h2.2v2.2H6.4V45.4z"
      />
      <path
        className="st0"
        d="M19.1,25.2h-1c2.4-0.3,4.2-2.3,4.2-4.7c0-2.7-2.2-4.8-4.8-4.8s-4.8,2.1-4.8,4.8c0,2.4,1.8,4.4,4.2,4.7h-1
	c-4.1,0-7.5,3.4-7.5,7.5V34v2.2c0,0.6,0.4,1,1,1h1.4h13.4h1.4c0.6,0,1-0.4,1-1V34v-1.3C26.6,28.6,23.2,25.2,19.1,25.2z M14.7,20.5
	c0-1.5,1.3-2.8,2.8-2.8s2.8,1.3,2.8,2.8s-1.3,2.8-2.8,2.8S14.7,22,14.7,20.5z M24.6,35.2H10.4v-2.5c0-3,2.5-5.5,5.5-5.5h3.2
	c3,0,5.5,2.5,5.5,5.5V35.2z"
      />
    </svg>
  );
};

export default EmployeeId;
