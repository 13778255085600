import React, { Component } from "react";
import FormSection from "../../../formSections/FormSection";
import IdentificationField from "./IdentificationField";
import "./AlternateEmpIdentification.css";

export default class EmpIdentificationSection extends Component {
  state = {};
  render() {
    const { onChange, data, stateData = {} } = this.props;
    const { InfoSection } = data;
    const { AccessPermission = false } = InfoSection;
    const labelClass = "col-lg-2";

    return (
      <FormSection hr>
        <IdentificationField
          label={"Employee Code"}
          data={InfoSection}
          inputName={"EmployeeCode"}
          inputType={"Label"}
          onChange={onChange}
          required={true}
          labelClass={labelClass}
        />
        <IdentificationField
          label={"Primary Employee ID"}
          data={stateData}
          inputName={"PrimaryEmployeeID"}
          inputType={"text"}
          onChange={onChange}
          labelClass={labelClass}
          optional={true}
          disabled={AccessPermission}
        />
        <IdentificationField
          label={"Secondary Employee ID"}
          data={stateData}
          inputName={"SecondaryEmployeeID"}
          inputType={"text"}
          onChange={onChange}
          labelClass={labelClass}
          optional={true}
          disabled={AccessPermission}
        />
        <IdentificationField
          label={"Former Employee ID"}
          data={stateData}
          inputName={"FormerEmployeeID"}
          inputType={"text"}
          onChange={onChange}
          labelClass={labelClass}
          optional={true}
          disabled={AccessPermission}
        />
        <IdentificationField
          label={"Badge Number"}
          data={stateData}
          inputName={"BadgeNo"}
          inputType={"numeric"}
          onChange={onChange}
          labelClass={labelClass}
          optional={true}
          disabled={AccessPermission}
          required={true}
        />
        <IdentificationField
          label={"Clock Number"}
          data={stateData}
          inputName={"ClockNo"}
          inputType={"numeric"}
          onChange={onChange}
          labelClass={labelClass}
          optional={true}
          disabled={AccessPermission}
        />
      </FormSection>
    );
  }
}
