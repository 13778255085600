/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Panel } from "react-bootstrap";
import DeductionSetupDescription from "./DeductionSetupDescription";
import { Modal } from "react-bootstrap";
import SystemLevelDeductionDescriptionStep from "./SystemLevelDeductionDescriptionStep";
import * as common from "../../../../app/common/index";
import * as systemConstantsCalculationMethodPageId from "../../../../app/common/SystemConstants";
import * as validator from "../../../../app/common/validations";
import axios from "axios";
import { withRouter, Prompt } from "react-router-dom";
import Toaster from "../../../../app/common/UIComponents/Toaster";
import * as message from "../../../../app/common/messages";
import { showToaster } from "../../../../app/actions/AppActions";
import * as utilities from "../../../../app/common/utilities";
import RadioButton from "component-library/radioButton/BasicRadioButton";
import { setLoader } from "../../../../app/actions/LoaderActions";
import { getCalculationMethodRadioButtonsList } from "../../actions/systemlevel/SystemLevelCalculationMethodActions";
import { getGarnishmentPercentRadioButtonsList } from "../../actions/systemlevel/SystemLevelCalculationMethodActions";
import { getAdvancedPretaxValuesList } from "../../actions/systemlevel/SystemLevelCalculationMethodActions";
import { getAdvancedRetirementValuesList } from "../../actions/systemlevel/SystemLevelCalculationMethodActions";
import { getAcaDeductionValuesList } from "../../actions/systemlevel/SystemLevelCalculationMethodActions";
import { setSystemDeductionData } from "../../actions/systemlevel/DeductionSetupListingActions";
import {
  setGlobalNavClick,
  CallbackToQuickLink,
  setFormUpdate,
  isOverlayClickable
} from "../../../../app/actions/FormUpdateAction";

var advancedPreTaxInsuranceList = [
  {
    id: "pre-tax-reduce-yes-radio",
    name: "ReduceBySection125Amounts",
    value: true,
    label: "Yes"
  },
  {
    id: "pre-tax-reduce-no-radio",
    name: "ReduceBySection125Amounts",
    value: false,
    label: "No"
  }
];

var advancedRetirementList = [
  {
    id: "retirement-yes-radio",
    name: "ReduceByRetirementAmounts",
    value: true,
    label: "Yes"
  },
  {
    id: "retirement-no-radio",
    name: "ReduceByRetirementAmounts",
    value: false,
    label: "No"
  }
];

let isInDidUpdate = false;

const SAVE_AND_CONTINUE = 1;
const SAVE_AND_FINISH_LATER = 2;
const SAVE_TO_SUMMERY_PAGE = 3;
const SAVE_AND_BACK_PAGE = 4;
const SAVE_CHANGES = 5;
const TRACK_YOUR_PROGRESS = 6;
const GLOBAL_NAV = 7;

class CalculationsMethodPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showBackCancelModal: false,
      DeductionCalculationBasisName: this.props.systemDeductionData
        .DeductionCalculationBasisName,
      DeductionCategoryId: this.props.systemDeductionData.DeductionCategoryId,
      DeductionSubCategoryId: this.props.systemDeductionData
        .DeductionSubCategoryId,

      DeductionCalculationBasisId: this.props.systemDeductionData
        .DeductionCalculationBasisId,
      ReduceBySection125Amounts: this.props.systemDeductionData
        .ReduceBySection125Amounts,
      ReduceByRetirementAmounts: this.props.systemDeductionData
        .ReduceByRetirementAmounts,
      // SystemEarningGroupName: this.props.systemDeductionData.SystemDeductionSystemEarningGroup.SystemEarningGroupName,
      SystemEarningGroupId: this.props.systemDeductionData.SystemEarningGroupId,
      EarningGroupId_Without_Garnishment: this.props.systemDeductionData
        .SystemEarningGroupId,
      EarningGroupId_With_Garnishment: this.props.systemDeductionData
        .SystemEarningGroupId,
      SystemDeductionId: this.props.systemDeductionData.SystemDeductionId,
      UiDraftCompletionStage: this.props.systemDeductionData
        .UiDraftCompletionStage,

      DeductionCalculationBasisOptionId: this.props.systemDeductionData
        .DeductionCalculationBasisOptionId,
      systemLevelEarningPercentList: [],
      openAdvanceOptions: false,
      openAdvanceOptionsLink: false,
      openAcaDeductionLink: false,
      openACALink: false,
      openOtherCalculation: false,
      openEarningsLinkPercentSelection: false,
      openEarningsLinkHourSelection: false,
      openPercentGarnishmentOptionsLink: false,
      openHourGarnishmentOptionsLink: false,
      openBackButtonModal: false,
      showDeleteButtonModal: false,
      openTrackYourProgressModal: false,
      openTrackYourProgressModal2: false,
      showFinishLaterModalWithoutMandatoryField: false,
      showFinishLaterModalWithMandatoryField: false,
      isFormUpdated: false,
      showCancelModal: false,
      openPercentageDeductionInfo: false,
      openOtherCalculationInfo: false,
      closeLeavingModal: false,
      pageId: 2,
      errors: {},
      showToaster: false,
      messageToaster: "",
      modalNavLink: "",
      advancedRetirementListItem: false
    };

    this.onChangeCalculationMethod = this.onChangeCalculationMethod.bind(this);
    this.onChangeWithoutGarnishmentMethod = this.onChangeWithoutGarnishmentMethod.bind(
      this
    );
    this.onChangeGarnishmentMethod = this.onChangeGarnishmentMethod.bind(this);
    this.onChangeAdvancedPreTaxOptions = this.onChangeAdvancedPreTaxOptions.bind(
      this
    );
    this.onChangeAdvancedRetirementOptions = this.onChangeAdvancedRetirementOptions.bind(
      this
    );
    this.onChangeOtherCalculationMethod = this.onChangeOtherCalculationMethod.bind(
      this
    );
    this.onChangeOtherCalculationUncollectedAmountMethod = this.onChangeOtherCalculationUncollectedAmountMethod.bind(
      this
    );
    this.onOpenBackButtonModal = this.onOpenBackButtonModal.bind(this);
    this.onCloseBackButtonModal = this.onCloseBackButtonModal.bind(this);
    //this.showBackButtonMessage = this.showBackButtonMessage.bind(this);
    this.close = this.close.bind(this);
    this.onOpenDeleteButtonModal = this.onOpenDeleteButtonModal.bind(this);
    this.onCloseDeleteButtonModal = this.onCloseDeleteButtonModal.bind(this);
    this.leavingCancelModal = this.leavingCancelModal.bind(this);
    this.onOpenTrackYourProgressModal = this.onOpenTrackYourProgressModal.bind(
      this
    );
    this.onCloseTrackYourProgressModal = this.onCloseTrackYourProgressModal.bind(
      this
    );
    this.renderCalculationMethodListItem = this.renderCalculationMethodListItem.bind(
      this
    );
    this.renderadvancedPreTaxInsuranceListItem = this.renderadvancedPreTaxInsuranceListItem.bind(
      this
    );
    this.renderadvancedRetirementListItem = this.renderadvancedRetirementListItem.bind(
      this
    );
    this.rendergarnishmentEarningsListItem = this.rendergarnishmentEarningsListItem.bind(
      this
    );
    this.renderacaDeductionListItem = this.renderacaDeductionListItem.bind(
      this
    );
    this.redirectToSystemLevelDeductionDescription = this.redirectToSystemLevelDeductionDescription.bind(
      this
    );
    this.redirectToSummarygPage = this.redirectToSummarygPage.bind(this);
    this.deleteDeduction = this.deleteDeduction.bind(this);
    this.checkIsPageBack = this.checkIsPageBack.bind(this);
    this.saveAndContinueDeductionCalculation = this.saveAndContinueDeductionCalculation.bind(
      this
    );
    this.deleteDeductionForGlobalNav = this.deleteDeductionForGlobalNav.bind(
      this
    );
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.props.getCalculationMethodRadioButtonsList();
    this.props.getGarnishmentPercentRadioButtonsList();
    this.props.getAdvancedPretaxValuesList();
    this.props.getAdvancedRetirementValuesList();
    //this.props.getGarnishmentValuesList();
    this.props.getAcaDeductionValuesList();
    this.getDataById();
    // this.props.setSystemDeductionData(this.state);
  }

  componentWillUnmount() {
    this.props.setFormUpdate(false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.systemDeductionData !== this.props.systemDeductionData) {
      isInDidUpdate = true;

      if (this.state.DeductionCalculationBasisId !== 0) {
        let event = {
          name: "DeductionCalculationBasisId",
          value: String(
            this.props.systemDeductionData.DeductionCalculationBasisId
          )
        };
        this.onChangeCalculationMethod(event);

        if (
          this.state.DeductionCalculationBasisId ===
            systemConstantsCalculationMethodPageId.CALCULATION_METHOD_HOUR_BASED_ID &&
          this.props.systemDeductionData.DeductionCategoryId ===
            systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_GARNISHMENT_ID
        ) {
          let subEvent = {
            name: "EarningGroupId_With_Garnishment",
            value: String(this.props.systemDeductionData.SystemEarningGroupId)
          };
          this.onChangeGarnishmentMethod(subEvent);
        } else if (
          this.state.DeductionCalculationBasisId ===
            systemConstantsCalculationMethodPageId.CALCULATION_METHOD_HOUR_BASED_ID &&
          this.props.systemDeductionData.DeductionCategoryId !==
            systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_GARNISHMENT_ID
        ) {
          let subEvent = {
            name: "EarningGroupId_Without_Garnishment",
            value: String(this.props.systemDeductionData.SystemEarningGroupId)
          };
          this.onChangeWithoutGarnishmentMethod(subEvent);
        } else if (
          this.state.DeductionCalculationBasisId ===
            systemConstantsCalculationMethodPageId.CALCULATION_METHOD_PERCENT_ID &&
          this.props.systemDeductionData.DeductionCategoryId !==
            systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_GARNISHMENT_ID
        ) {
          let subEvent = {
            name: "EarningGroupId_Without_Garnishment",
            value: String(this.props.systemDeductionData.SystemEarningGroupId)
          };
          this.onChangeWithoutGarnishmentMethod(subEvent);

          if (this.state.ReduceBySection125Amounts === null) {
            this.setState({
              ReduceBySection125Amounts: false
            });
          } else if (this.state.ReduceBySection125Amounts === true) {
            this.setState({
              advancedRetirementListItem: true
            });
          }

          if (this.state.ReduceByRetirementAmounts === null) {
            this.setState({
              ReduceByRetirementAmounts: false
            });
          }
        } else if (
          this.state.DeductionCalculationBasisId ===
            systemConstantsCalculationMethodPageId.CALCULATION_METHOD_PERCENT_ID &&
          this.props.systemDeductionData.DeductionCategoryId ===
            systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_GARNISHMENT_ID
        ) {
          let subEvent = {
            name: "EarningGroupId_With_Garnishment",
            value: String(this.props.systemDeductionData.SystemEarningGroupId)
          };
          this.onChangeGarnishmentMethod(subEvent);

          if (this.state.ReduceBySection125Amounts === null) {
            this.setState({
              ReduceBySection125Amounts: false
            });
          }

          if (this.state.ReduceByRetirementAmounts === null) {
            this.setState({
              ReduceByRetirementAmounts: false
            });
          }
        }

        if (
          this.state.DeductionCalculationBasisId ===
          systemConstantsCalculationMethodPageId.CALCULATION_METHOD_OTHER_ID
        ) {
          this.setState({
            DeductionCalculationBasisOptionId: this.props.systemDeductionData
              .DeductionCalculationBasisOptionId
          });
        }

        if (
          parseInt(this.state.DeductionCalculationBasisId, 10) ===
          systemConstantsCalculationMethodPageId.CALCULATION_METHOD_PERCENT_ID
        ) {
          if (
            (this.props.systemDeductionData.DeductionCategoryId ===
              systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_INSURANCE_ID &&
              this.props.systemDeductionData.Taxability === false) ||
            (this.props.systemDeductionData.DeductionCategoryId ===
              systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_OTHER_ID 
            && this.props.systemDeductionData.Taxability === false) ||
            this.props.systemDeductionData.DeductionCategoryId ===
              systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_GARNISHMENT_ID ||
            this.props.systemDeductionData.DeductionCategoryId ===
              systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_RETIREMENT_ID ||
            this.props.systemDeductionData.DeductionCategoryId ===
              systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_RETIREMENT_LOAN_ID
          ) {
            if (
              this.props.systemDeductionData.EarningGroupId ===
                systemConstantsCalculationMethodPageId.CALCULATION_METHOD_DISPOSABLE_EARNINGS_ID ||
              this.props.systemDeductionData.EarningGroupId ===
                systemConstantsCalculationMethodPageId.CALCULATION_METHOD_RETIREMENT_EARNINGS_ID
            ) {
              this.setState({
                openAdvanceOptionsLink: false,
                ReduceBySection125Amounts: null,
                ReduceByRetirementAmounts: null
              });
            } else {
              if (
                this.props.systemDeductionData.EarningGroupId ===
                systemConstantsCalculationMethodPageId.CALCULATION_METHOD_TAXABLE_EARNINGS_ID
              ) {
                if (this.props.systemDeductionData.DeductionCategoryId ===
                  systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_RETIREMENT_ID ||
                  (this.props.systemDeductionData.DeductionCategoryId ===
                    systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_OTHER_ID &&
                    this.props.systemDeductionData.Taxability === true)) {
                      this.setState({
                        advancedRetirementListItem: false,
                        ReduceByRetirementAmounts: null
                      });
                } 
                else {
                  this.setState({
                    openAdvanceOptionsLink: true
                  });
                  if (
                    this.props.systemDeductionData.ReduceBySection125Amounts ===
                    true
                  ) {
                    this.setState({
                      ReduceBySection125Amounts: true,
                      advancedRetirementListItem: true
                    });
                    if (
                      this.props.systemDeductionData.ReduceByRetirementAmounts ===
                      true
                    ) {
                      this.setState({
                        ReduceByRetirementAmounts: true
                      });
                    } else {
                      this.setState({
                        ReduceByRetirementAmounts: false
                      });
                    }
                  } else {
                    this.setState({
                      ReduceBySection125Amounts: false
                    });
                  }
                }
              } else if (
                this.props.systemDeductionData.EarningGroupId !==
                systemConstantsCalculationMethodPageId.CALCULATION_METHOD_TAXABLE_EARNINGS_ID
              ) {
                this.setState({
                  openAdvanceOptionsLink: true,
                  advancedRetirementListItem: false
                });
                if (
                  this.props.systemDeductionData.ReduceBySection125Amounts ===
                  true
                ) {
                  this.setState({
                    ReduceBySection125Amounts: true
                  });
                } else {
                  this.setState({
                    ReduceBySection125Amounts: false
                  });
                }
              }
            }
          }
        }
      }
      if (
        this.props.GarnishmentPercentRadioButtonsList !== undefined &&
        this.props.GarnishmentPercentRadioButtonsList !== null
      ) {
        let DeductionCalculationBasisId = this.state
          .DeductionCalculationBasisId;
        if (this.props.GarnishmentPercentRadioButtonsList.Data !== undefined) {
          let systemLevelEarningList = this.props.GarnishmentPercentRadioButtonsList.Data.filter(
            systemLevelEarning => {
              return (
                String(systemLevelEarning.DeductionCalculationBasisId) ===
                String(DeductionCalculationBasisId)
              );
            }
          );
          this.setState({
            systemLevelEarningPercentList: systemLevelEarningList
          });
        }
      }
      isInDidUpdate = false;
    }
    if (
      prevProps.globalNavClick === null &&
      this.props.globalNavClick !== null
    ) {
      if (this.props.globalNavClick.isLinkClick) {
        this.setState({
          showBackCancelModal: true,
          IsFormUpdated: false
        });
        this.props.setFormUpdate(false);
      }
    } else {
      if (
        prevProps.globalNavClick !== null &&
        this.props.globalNavClick !== null
      ) {
        if (
          prevProps.globalNavClick.isLinkClick !==
          this.props.globalNavClick.isLinkClick
        ) {
          if (this.props.globalNavClick.isLinkClick) {
            this.setState({
              showBackCancelModal: true,
              IsFormUpdated: false
            });
            this.props.setFormUpdate(false);
          }
        }
      }
    }
    if (
      String(prevState.DeductionCalculationBasisId) !==
      String(this.state.DeductionCalculationBasisId)
    ) {
      if (
        this.props.GarnishmentPercentRadioButtonsList !== undefined &&
        this.props.GarnishmentPercentRadioButtonsList !== null
      ) {
        let DeductionCalculationBasisId = this.state
          .DeductionCalculationBasisId;
        if (this.props.GarnishmentPercentRadioButtonsList.Data !== undefined) {
          let systemLevelEarningList = this.props.GarnishmentPercentRadioButtonsList.Data.filter(
            systemLevelEarning => {
              return (
                String(systemLevelEarning.DeductionCalculationBasisId) ===
                String(DeductionCalculationBasisId)
              );
            }
          );
          this.setState({
            systemLevelEarningPercentList: systemLevelEarningList
          });
        }
      }
    }
    // this.checkIsPageBack();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.systemDeductionData !== this.props.systemDeductionData) {
      this.setState({
        DeductionCalculationBasisName:
          nextProps.systemDeductionData.DeductionCalculationBasisName,
        DeductionCategoryId: nextProps.systemDeductionData.DeductionCategoryId,
        DeductionSubCategoryId:
          nextProps.systemDeductionData.DeductionSubCategoryId,
        DeductionCalculationBasisId:
          nextProps.systemDeductionData.DeductionCalculationBasisId,
        ReduceBySection125Amounts:
          nextProps.systemDeductionData.ReduceBySection125Amounts,
        ReduceByRetirementAmounts:
          nextProps.systemDeductionData.ReduceByRetirementAmounts,
        // SystemEarningGroupName: this.props.systemDeductionData.SystemDeductionSystemEarningGroup.SystemEarningGroupName,
        SystemEarningGroupId:
          nextProps.systemDeductionData.SystemEarningGroupId,
        EarningGroupId_Without_Garnishment:
          nextProps.systemDeductionData.SystemEarningGroupId,
        EarningGroupId_With_Garnishment:
          nextProps.systemDeductionData.SystemEarningGroupId,
        SystemDeductionId: nextProps.systemDeductionData.SystemDeductionId,
        UiDraftCompletionStage:
          nextProps.systemDeductionData.UiDraftCompletionStage,
        DeductionCalculationBasisOptionId:
          nextProps.systemDeductionData.DeductionCalculationBasisOptionId
      });
    }
  }

  getDataById() {
    let self = this;
    let Id = this.props.match.params.SystemDeductionId;
    if (Id !== undefined) {
      // Id = window.atob(Id);
      const url = `${common.WEB_API_URL}/v1/system/deduction/${Id}`;
      axios
        .get(url)
        .then(function(response) {
          if (response.data.ErrorCount > 0) {
            response.data.Errors.forEach(item => {
              if (item.Tag === "") {
              }
            });
          } else {
            let deductionData = response.data.Data[0];
            self.props.setSystemDeductionData(deductionData);
          }
        })
        .catch(function(error) {});
    }
  }

  deleteDeductionForGlobalNav() {
    var self = this;
    this.setState({
      isFormUpdated: false,
      showDeleteButtonModal: false
    });
    this.props.setFormUpdate(false);
    self.props.setLoader(true);
    const url = `${common.WEB_API_URL}/v1/system/deduction/${
      this.state.SystemDeductionId
    }`;
    axios
      .delete(url)
      .then(function(response) {
        self.props.setLoader(false);
        if (response.data.ErrorCount > 0) {
          self.setState({
            isFormUpdated: true
          });
          this.props.setFormUpdate(true);
          response.data.Errors.forEach((item, index) => {
            if (index === 0) {
              alert(item.Messages);
            }
          });
        } else {
          self.props.setFormUpdate(false);
          self.props.setGlobalNavClick(
            false,
            self.props.globalNavClick.LinkItem,
            self.props.globalNavClick.MenuLink
          );
          self.props.CallbackToQuickLink(true);
          self.props.isOverlayClickable(false);
        }
      })
      .catch(function(error) {});
  }

  deleteDeduction() {
    var self = this;
    this.setState({
      isFormUpdated: false,
      showDeleteButtonModal: false
    });
    this.props.setFormUpdate(false);
    self.props.setLoader(true);
    const url = `${common.WEB_API_URL}/v1/system/deduction/${
      this.state.SystemDeductionId
    }`;
    axios
      .delete(url)
      .then(function(response) {
        self.props.setLoader(false);
        if (response.data.ErrorCount > 0) {
          self.setState({
            isFormUpdated: true
          });
          this.props.setFormUpdate(true);
          response.data.Errors.forEach((item, index) => {
            if (index === 0) {
              alert(item.Messages);
            }
          });
        } else {
          self.props.showToaster(
            true,
            `'${
              self.props.systemDeductionData.SystemDeductionName
            }' has been deleted.`,
            "delete"
          );
          self.props.history.push("/system-level-deduction-setup-listing-page");
        }
      })
      .catch(function(error) {});
  }

  redirectToSystemLevelDeductionDescription() {
    let self = this;
    self.setState({
      isFormUpdated: false
    });
    self.props.setFormUpdate(false);
    const dataUrl = `${common.WEB_API_URL}/v1/system/deduction/${
      self.props.systemDeductionData.SystemDeductionId
    }`;
    axios
      .get(dataUrl)
      .then(function(response) {
        if (response.data.ErrorCount > 0) {
          self.setState({ isFormUpdated: true });
          this.props.setFormUpdate(true);
          response.data.Errors.forEach((item, index) => {
            if (index === 0) {
              alert(item.Messages);
            }
          });
        } else {
          let deductionData = response.data.Data[0];
          self.props.setSystemDeductionData(deductionData);
          self.props.history.push(
            `/system-level-deduction-description/${
              self.props.match.params.SystemDeductionId
            }`
          );
        }
      })
      .catch(function(error) {});
  }

  redirectToSummarygPage() {
    this.setState({ isFormUpdated: false });
    this.props.setFormUpdate(false);
    this.props.showToaster(false);
    setTimeout(
      function() {
        this.props.history.push(
          `/system-level-review-and-save/${
            this.props.match.params.SystemDeductionId
          }`
        );
      }.bind(this),
      100
    );
  }

  checkIsPageBack() {
    if (this.state.isFormUpdated === false) {
    } else {
    }
  }

  saveAndContinueDeductionCalculation(actionType, navLink) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let redirectPageUrl = "";
    let data = this.state;

    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: false,
        showFinishLaterModalWithMandatoryField: false,
        openTrackYourProgressModal2: false
      });
      this.props.setFormUpdate(false);
    }

    if (actionType === SAVE_AND_CONTINUE) {
      redirectPageUrl = `/system-level-deduction-cycle/${
        this.props.match.params.SystemDeductionId
      }`;
    } else if (actionType === SAVE_CHANGES) {
      redirectPageUrl = "/system-level-deduction-setup-listing-page";
      this.props.showToaster(
        true,
        `'${
          this.props.systemDeductionData.SystemDeductionName
        }' has been saved. We’ve marked this deduction as in progress for you to work on later.`,
        "inprogress"
      );
    } else if (actionType === SAVE_AND_FINISH_LATER) {
      redirectPageUrl = "/system-level-deduction-setup-listing-page";
      this.props.showToaster(
        true,
        `'${
          this.props.systemDeductionData.SystemDeductionName
        }' has been saved. We’ve marked this deduction as in progress for you to work on later.`,
        "inprogress"
      );
    } else if (actionType === SAVE_TO_SUMMERY_PAGE) {
      redirectPageUrl = `/system-level-review-and-save/${
        this.props.match.params.SystemDeductionId
      }`;
      this.props.showToaster(
        true,
        `'${
          this.props.systemDeductionData.SystemDeductionName
        }' has been updated.`
      );
    } else if (actionType === SAVE_AND_BACK_PAGE) {
      redirectPageUrl = `/system-level-deduction-description/${
        this.props.match.params.SystemDeductionId
      }`;
    } else if (actionType === TRACK_YOUR_PROGRESS) {
      redirectPageUrl = navLink;
    }

    let errors = {};

    errors = validator.validateRequiredField(
      data.DeductionCalculationBasisId,
      "DeductionCalculationBasisId",
      errors
    );

    if (
      data.DeductionCalculationBasisId ===
        String(
          systemConstantsCalculationMethodPageId.CALCULATION_METHOD_PERCENT_ID
        ) ||
      data.DeductionCalculationBasisId ===
        String(
          systemConstantsCalculationMethodPageId.CALCULATION_METHOD_HOUR_BASED_ID
        )
    ) {
      if (
        data.DeductionCategoryId ===
        systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_GARNISHMENT_ID
      ) {
        data.SystemEarningGroupId = data.EarningGroupId_With_Garnishment;
        errors = validator.validateRequiredField(
          data.SystemEarningGroupId,
          "EarningGroupId_With_Garnishment",
          errors
        );
      } else if (
        data.DeductionCategoryId !==
          String(
            systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_GARNISHMENT_ID
          ) &&
        (data.openEarningsLinkHourSelection === true ||
          data.openHourGarnishmentOptionsLink === true ||
          data.openEarningsLinkPercentSelection === true ||
          data.openPercentGarnishmentOptionsLink === true)
      ) {
        data.SystemEarningGroupId = data.EarningGroupId_Without_Garnishment;
        errors = validator.validateRequiredField(
          data.SystemEarningGroupId,
          "EarningGroupId_Without_Garnishment",
          errors
        );
      }
    }

    if (
      data.DeductionCalculationBasisId ===
      String(systemConstantsCalculationMethodPageId.CALCULATION_METHOD_OTHER_ID)
    ) {
      errors = validator.validateRequiredField(
        data.DeductionCalculationBasisOptionId,
        "DeductionCalculationBasisOptionId",
        errors
      );
    }

    this.setState({
      errors: errors,
      openBackButtonModal: false,
      showDeleteButtonModal: false,
      closeLeavingModal: false,
      openTrackYourProgressModal: false,
      openTrackYourProgressModal2: false
    });

    if (Object.keys(errors).length !== 0) {
      this.setState({
        showToaster: true,
        messageToaster: message.ERROR_ON_PAGE,
        isFormUpdated: true
      });
    }

    if (Object.keys(errors).length > 0) {
      this.setState({
        isFormUpdated: true
      });
      this.setState({
        IsFormUpdated: true,
        showBackCancelModal: false
      });
      this.props.setFormUpdate(true);
      this.props.setGlobalNavClick(false);
    } else {
      if (actionType === GLOBAL_NAV) {
        this.props.setFormUpdate(false);
        this.props.setGlobalNavClick(
          false,
          this.props.globalNavClick.LinkItem,
          this.props.globalNavClick.MenuLink
        );
        //this.props.CallbackToQuickLink(true);
        this.setState({
          showBackCancelModal: false
        });
        this.props.isOverlayClickable(false);
        redirectPageUrl = "";
      }
    }

    if (Object.keys(errors).length === 0) {
      this.props.setLoader(true);
      this.setState({
        isFormUpdated: false
      });
      let UIDraftPageId = 0;

      if (
        this.props.systemDeductionData.UiDraftCompletionStage >
        this.state.pageId
      ) {
        UIDraftPageId = this.props.systemDeductionData.UiDraftCompletionStage;
      } else {
        UIDraftPageId = this.state.pageId;
      }

      var self = this;

      let patchActions = [
        {
          Operation: "update",
          PathOrKey: "DeductionCalculationBasisId",
          Value: data.DeductionCalculationBasisId
        },
        {
          Operation: "update",
          PathOrKey: "SystemEarningGroupId",
          Value: data.SystemEarningGroupId
        },
        {
          Operation: "update",
          PathOrKey: "EarningGroupId_With_Garnishment",
          Value: data.EarningGroupId_With_Garnishment
        },
        {
          Operation: "update",
          PathOrKey: "DeductionCalculationBasisOptionId",
          Value: data.DeductionCalculationBasisOptionId
        },
        {
          Operation: "update",
          PathOrKey: "ReduceBySection125Amounts",
          Value: data.ReduceBySection125Amounts
        },
        {
          Operation: "update",
          PathOrKey: "ReduceByRetirementAmounts",
          Value: data.ReduceByRetirementAmounts
        },
        {
          Operation: "update",
          PathOrKey: "UiDraftCompletionStage",
          Value: UIDraftPageId
        }
      ];

      const url = `${common.WEB_API_URL}/v1/system/deduction/${
        data.SystemDeductionId
      }`;
      axios
        .patch(url, patchActions)
        .then(function(response) {
          self.props.setLoader(false);
          const dataUrl = `${common.WEB_API_URL}/v1/system/deduction/${
            data.SystemDeductionId
          }`;
          axios
            .get(dataUrl)
            .then(function(response) {
              if (response.data.ErrorCount > 0) {
                response.data.Errors.forEach((item, index) => {
                  if (index === 0) {
                    alert(item.Messages);
                  }
                });
              } else {
                let deductionData = response.data.Data[0];
                self.props.setSystemDeductionData(deductionData);
                if (actionType === GLOBAL_NAV) {
                  self.props.setFormUpdate(false);
                  self.props.CallbackToQuickLink(true);
                } else {
                  self.props.history.push(redirectPageUrl);
                }
              }
            })
            .catch(function(error) {});
        })
        .catch(function(error) {});
    }
  }

  onOpenFinishLaterButtonModal = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let errorCount = this.validateRequiredField();
    if (errorCount === 0) {
      this.setState({
        showFinishLaterModalWithMandatoryField: true,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
    } else {
      this.setState({
        showFinishLaterModalWithoutMandatoryField: true,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
    }
  };

  onCloseFinishLaterModal = () => {
    this.setState({
      isFormUpdated: true,
      showFinishLaterModalWithoutMandatoryField: false,
      showFinishLaterModalWithMandatoryField: false
    });
    this.props.setFormUpdate(true);
    this.props.setGlobalNavClick(false);
  };

  exitSetup = (actionType, navLink) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (actionType === "EXIT_SETUP") {
      this.setState({
        showFinishLaterModalWithoutMandatoryField: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.history.push("/system-level-deduction-setup-listing-page");
      this.props.showToaster(
        true,
        `'${
          this.props.systemDeductionData.SystemDeductionName
        }' has been saved. We’ve marked this deduction as in progress for you to work on later.`,
        "inprogress"
      );
    } else if (actionType === "LEAVE_THIS_PAGE") {
      this.setState({
        openTrackYourProgressModal: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.history.push(navLink);
    } else if (actionType === "GLOBAL_NAV") {
      this.setState({
        showBackCancelModal: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.setGlobalNavClick(
        false,
        this.props.globalNavClick.LinkItem,
        this.props.globalNavClick.MenuLink
      );
      this.props.CallbackToQuickLink(true);
    } else if (actionType === "SAVE_AND_BACK_PAGE") {
      this.setState({
        openBackButtonModal2: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.history.push(
        `/system-level-deduction-description/${
          this.props.match.params.SystemDeductionId
        }`
      );
    }
  };

  validateRequiredField = () => {
    let errors = {};
    let data = this.state;

    errors = validator.validateRequiredField(
      data.DeductionCalculationBasisId,
      "DeductionCalculationBasisId",
      errors
    );

    if (
      data.DeductionCalculationBasisId ===
        String(
          systemConstantsCalculationMethodPageId.CALCULATION_METHOD_PERCENT_ID
        ) ||
      data.DeductionCalculationBasisId ===
        String(
          systemConstantsCalculationMethodPageId.CALCULATION_METHOD_HOUR_BASED_ID
        )
    ) {
      if (
        data.DeductionCategoryId ===
        systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_GARNISHMENT_ID
      ) {
        data.SystemEarningGroupId = data.EarningGroupId_With_Garnishment;
        errors = validator.validateRequiredField(
          data.SystemEarningGroupId,
          "EarningGroupId_With_Garnishment",
          errors
        );
      } else if (
        data.DeductionCategoryId !==
          String(
            systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_GARNISHMENT_ID
          ) &&
        (data.openEarningsLinkHourSelection === true ||
          data.openHourGarnishmentOptionsLink === true ||
          data.openEarningsLinkPercentSelection === true ||
          data.openPercentGarnishmentOptionsLink === true)
      ) {
        data.SystemEarningGroupId = data.EarningGroupId_Without_Garnishment;
        errors = validator.validateRequiredField(
          data.SystemEarningGroupId,
          "EarningGroupId_Without_Garnishment",
          errors
        );
      }
    }

    if (
      data.DeductionCalculationBasisId ===
      String(systemConstantsCalculationMethodPageId.CALCULATION_METHOD_OTHER_ID)
    ) {
      errors = validator.validateRequiredField(
        data.DeductionCalculationBasisOptionId,
        "DeductionCalculationBasisOptionId",
        errors
      );
    }

    return Object.keys(errors).length;
  };

  onChangeCalculationMethod(event, value = 0) {
    let update = {};

    let errors = this.state.errors;
    delete errors["DeductionCalculationBasisId"];
    delete errors["EarningGroupId_With_Garnishment"];
    delete errors["EarningGroupId_Without_Garnishment"];
    this.setState({
      errors
    });
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
    this.setState({
      openPercentageDeductionInfo: false,
      openOtherCalculationInfo: false
    });
    if (
      event.value ===
      String(
        systemConstantsCalculationMethodPageId.CALCULATION_METHOD_DOLLAR_ID
      )
    ) {
      this.setState({
        openOtherCalculation: false,
        openEarningsLinkPercentSelection: false,
        openAdvanceOptionsLink: false,
        openPercentGarnishmentOptionsLink: false,
        openEarningsLinkHourSelection: false,
        openHourGarnishmentOptionsLink: false,
        DeductionCalculationBasisOptionId: null,
        SystemEarningGroupId: null,
        EarningGroupId_With_Garnishment: null,
        EarningGroupId_Without_Garnishment: null,
        ReduceBySection125Amounts: null,
        ReduceByRetirementAmounts: null,
        advancedRetirementListItem: false
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
    } else if (
      event.value ===
      String(
        systemConstantsCalculationMethodPageId.CALCULATION_METHOD_PERCENT_ID
      )
    ) {
      this.setState({
        openOtherCalculation: false,
        openEarningsLinkHourSelection: false,
        openHourGarnishmentOptionsLink: false,
        DeductionCalculationBasisOptionId: null,
        advancedRetirementListItem: false
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
      if (
        this.props.systemDeductionData.DeductionCategoryId ===
        systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_GARNISHMENT_ID
      ) {
        this.setState({
          openEarningsLinkPercentSelection: false,
          openAdvanceOptionsLink: false,
          openPercentGarnishmentOptionsLink: true,
          EarningGroupId_With_Garnishment: "",
          DeductionCalculationBasisOptionId: null,
          advancedRetirementListItem: false
        });
      } else {
        this.setState({
          openEarningsLinkPercentSelection: true,
          openAdvanceOptionsLink: false,
          openPercentGarnishmentOptionsLink: false,
          EarningGroupId_Without_Garnishment: "",
          DeductionCalculationBasisOptionId: null,
          advancedRetirementListItem: false
        });
      }

      if (this.state.ReduceBySection125Amounts === null) {
        this.setState({
          ReduceBySection125Amounts: false,
          advancedRetirementListItem: false
        });
      }

      if (this.state.ReduceByRetirementAmounts === null) {
        this.setState({
          ReduceByRetirementAmounts: false
        });
      }
    } else if (
      event.value ===
      String(
        systemConstantsCalculationMethodPageId.CALCULATION_METHOD_HOUR_BASED_ID
      )
    ) {
      this.setState({
        openOtherCalculation: false,
        openEarningsLinkPercentSelection: false,
        openAdvanceOptionsLink: false,
        openPercentGarnishmentOptionsLink: false,
        DeductionCalculationBasisOptionId: null,
        ReduceBySection125Amounts: null,
        ReduceByRetirementAmounts: null,
        advancedRetirementListItem: false
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }

      if (
        this.props.systemDeductionData.DeductionCategoryId ===
        systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_GARNISHMENT_ID
      ) {
        this.setState({
          openEarningsLinkHourSelection: false,
          openHourGarnishmentOptionsLink: true,
          EarningGroupId_With_Garnishment: "",
          DeductionCalculationBasisOptionId: null,
          advancedRetirementListItem: false
        });
      } else {
        this.setState({
          openEarningsLinkHourSelection: true,
          openHourGarnishmentOptionsLink: false,
          EarningGroupId_Without_Garnishment: "",
          DeductionCalculationBasisOptionId: null,
          advancedRetirementListItem: false
        });
      }
    } else if (
      event.value ===
      String(systemConstantsCalculationMethodPageId.CALCULATION_METHOD_OTHER_ID)
    ) {
      this.setState({
        openOtherCalculation: true,
        openEarningsLinkPercentSelection: false,
        openAdvanceOptionsLink: false,
        openPercentGarnishmentOptionsLink: false,
        openHourGarnishmentOptionsLink: false,
        openEarningsLinkHourSelection: false,
        SystemEarningGroupId: null,
        EarningGroupId_With_Garnishment: null,
        EarningGroupId_Without_Garnishment: null,
        ReduceBySection125Amounts: null,
        ReduceByRetirementAmounts: null,
        advancedRetirementListItem: false
      });

      this.setState({
        DeductionCalculationBasisOptionId: 1
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
    }
    this.checkIsPageBack();
    update[event.name] = event.value;

    this.setState(update);
  }

  onChangeWithoutGarnishmentMethod(event, value = 0) {
    let update = {};

    let errors = this.state.errors;
    delete errors["EarningGroupId_Without_Garnishment"];
    this.setState({
      errors
    });
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
    if (
      event.value ===
        String(
          systemConstantsCalculationMethodPageId.CALCULATION_METHOD_DISPOSABLE_EARNINGS_ID
        ) ||
      event.value ===
        String(
          systemConstantsCalculationMethodPageId.CALCULATION_METHOD_RETIREMENT_EARNINGS_ID
        )
    ) {
      this.setState({
        openAdvanceOptionsLink: false,
        openAdvanceOptions: false,
        ReduceBySection125Amounts: null,
        ReduceByRetirementAmounts: null,
        advancedRetirementListItem: false
      });
    }
    if((this.props.systemDeductionData.DeductionCategoryId ===
      systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_INSURANCE_ID &&
      this.props.systemDeductionData.Taxability === true) ||
      (this.props.systemDeductionData.DeductionCategoryId ===
        systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_OTHER_ID &&
        this.props.systemDeductionData.Taxability === true )) {
        this.setState({
          visibleAdvanceOptionLink: false,
          openAdvanceOptions: false,
          ReduceByRetirementAmounts: null,
          ReduceBySection125Amounts: null,
          advancedRetirementListItem: false
        });
      }
    else if (
      (this.props.systemDeductionData.DeductionCategoryId ===
        systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_INSURANCE_ID &&
        this.props.systemDeductionData.Taxability === false) ||
      (this.props.systemDeductionData.DeductionCategoryId ===
        systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_OTHER_ID
      && this.props.systemDeductionData.Taxability === false) ||
      this.props.systemDeductionData.DeductionCategoryId ===
        systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_GARNISHMENT_ID ||
      this.props.systemDeductionData.DeductionCategoryId ===
        systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_RETIREMENT_ID ||
      this.props.systemDeductionData.DeductionCategoryId ===
        systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_RETIREMENT_LOAN_ID
    ) {
      if (
        event.value ===
          String(
            systemConstantsCalculationMethodPageId.CALCULATION_METHOD_DISPOSABLE_EARNINGS_ID
          ) ||
        event.value ===
          String(
            systemConstantsCalculationMethodPageId.CALCULATION_METHOD_RETIREMENT_EARNINGS_ID
          )
      ) {
        this.setState({
          openAdvanceOptionsLink: false,
          openAdvanceOptions: false,
          ReduceBySection125Amounts: null,
          ReduceByRetirementAmounts: null,
          advancedRetirementListItem: false
        });
      } else {
        this.setState({
          openAdvanceOptionsLink: true,
          openAdvanceOptions: false,
          ReduceBySection125Amounts: false,
          advancedRetirementListItem: false,
          ReduceByRetirementAmounts: null
        });
      }
    }
    this.checkIsPageBack();
    update[event.name] = event.value;
    update["EarningGroupId_With_Garnishment"] = event.value;
    this.setState(update);
  }

  onChangeAdvancedPreTaxOptions(event, value = 0) {
    let update = {};
    update[event.name] = event.value === "true";
    if (String(event.value) === "true") {
      if (isInDidUpdate === false) {
        if (
          String(this.state.EarningGroupId_Without_Garnishment) ===
            String(
              systemConstantsCalculationMethodPageId.CALCULATION_METHOD_TAXABLE_EARNINGS_ID
            ) &&
          String(this.state.EarningGroupId_With_Garnishment) ===
            String(
              systemConstantsCalculationMethodPageId.CALCULATION_METHOD_TAXABLE_EARNINGS_ID
            )
        ) {
          if (this.props.systemDeductionData.DeductionCategoryId ===
            systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_OTHER_ID &&
            this.props.systemDeductionData.Taxability === true) {
              this.setState({
                isFormUpdated: true,
                advancedRetirementListItem: false,
                ReduceByRetirementAmounts: null
              });
            } 
          else {
            this.setState({
              isFormUpdated: true,
              advancedRetirementListItem: true,
              ReduceByRetirementAmounts: false
            });
            }
        } else {
          this.setState({
            isFormUpdated: true,
            advancedRetirementListItem: false,
            ReduceByRetirementAmounts: null
          });
        }
      }
    } else {
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true,
          advancedRetirementListItem: false,
          ReduceByRetirementAmounts: null
        });
        this.props.setFormUpdate(true);
      }
    }

    this.setState(update);
  }

  onChangeAdvancedRetirementOptions(event, value = 0) {
    let update = {};

    update[event.name] = event.value === "true";
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
    this.setState(update);
  }

  onChangeOtherCalculationMethod(event, value = 0) {
    let update = {};

    let errors = this.state.errors;
    delete errors["DeductionCalculationBasisOptionId"];
    this.setState({
      errors
    });

    update[event.name] = event.value;

    this.setState(update);
    if (this.state.openAcaDeductionLink) {
      update[event.name] = "";
      this.setState({
        update,
        DeductionCalculationBasisOptionId: 1,
        openAcaDeductionLink: true
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: false
        });
        this.props.setFormUpdate(true);
      }
    } else {
      this.setState({
        update,
        DeductionCalculationBasisOptionId: null,
        openAcaDeductionLink: true
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
    }
  }

  onChangeGarnishmentMethod(event, value = 0) {
    let update = {};

    let errors = this.state.errors;
    delete errors["EarningGroupId_With_Garnishment"];
    this.setState({
      errors
    });
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
    if (
      event.value ===
        String(
          systemConstantsCalculationMethodPageId.CALCULATION_METHOD_DISPOSABLE_EARNINGS_ID
        ) ||
      event.value ===
        String(
          systemConstantsCalculationMethodPageId.CALCULATION_METHOD_RETIREMENT_EARNINGS_ID
        )
    ) {
      this.setState({
        openAdvanceOptionsLink: false,
        openAdvanceOptions: false,
        ReduceBySection125Amounts: null,
        ReduceByRetirementAmounts: null,
        advancedRetirementListItem: false
      });
    }
    if (
      (this.props.systemDeductionData.DeductionCategoryId ===
        systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_INSURANCE_ID &&
        this.props.systemDeductionData.Taxability === false) ||
      (this.props.systemDeductionData.DeductionCategoryId ===
        systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_OTHER_ID
         && this.props.systemDeductionData.Taxability === false
      ) ||
      this.props.systemDeductionData.DeductionCategoryId ===
        systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_GARNISHMENT_ID ||
      this.props.systemDeductionData.DeductionCategoryId ===
        systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_RETIREMENT_ID ||
      this.props.systemDeductionData.DeductionCategoryId ===
        systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_RETIREMENT_LOAN_ID
    ) {
      if (
        event.value ===
          String(
            systemConstantsCalculationMethodPageId.CALCULATION_METHOD_DISPOSABLE_EARNINGS_ID
          ) ||
        event.value ===
          String(
            systemConstantsCalculationMethodPageId.CALCULATION_METHOD_RETIREMENT_EARNINGS_ID
          )
      ) {
        this.setState({
          openAdvanceOptionsLink: false,
          openAdvanceOptions: false,
          ReduceBySection125Amounts: null,
          ReduceByRetirementAmounts: null,
          advancedRetirementListItem: false
        });
      } else {
        this.setState({
          openAdvanceOptionsLink: true,
          openAdvanceOptions: false,
          ReduceBySection125Amounts: false,
          advancedRetirementListItem: false,
          ReduceByRetirementAmounts: null
        });
      }
    }
    this.checkIsPageBack();
    update[event.name] = event.value;
    update["EarningGroupId_Without_Garnishment"] = event.value;
    this.setState(update);
  }

  onChangeOtherCalculationUncollectedAmountMethod(event) {
    let update = {};

    update[event.target.name] = event.target.value;
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
    this.setState(update);
  }

  showPercentageEarnings() {
    return (
      <div
        id="deduction-cycle-page-description"
        className="entity-form-section-primary"
      >
        <div id="deduction-cycle-page-description" className="content">
          {/* <div id="deduction-name" className="deduction-page-section"> */}
          <div className="seperator">
            <hr />
          </div>
          <div className="nc-field-group clearfix">
            <div className="field-name">
              <span
                className={utilities.getErrorClassName(
                  this.state.errors["EarningGroupId_Without_Garnishment"]
                )}
              >
                What is this a percentage of?{" "}
                <a
                  id="deduction-percent-info-icon"
                  onClick={() =>
                    this.setState({
                      openPercentageDeductionInfo: !this.state
                        .openPercentageDeductionInfo
                    })
                  }
                >
                  <i className="fa-information-blue fa fa-info-circle" />
                </a>
              </span>
            </div>

            <div className="clearfix" />
            {this.state.openPercentageDeductionInfo
              ? this.showPercentageDeductionInfo()
              : null}
            <div className="clearfix" />

            <div className="deduction-page-section-input">
              <div
                className="radio-button-group"
                style={{ marginBottom: "10px" }}
              >
                {this.renderPercentageCalculationListItem()}
              </div>
              {this.state.errors["EarningGroupId_Without_Garnishment"] && (
                <p
                  className="error-label"
                  style={{
                    marginTop: "-15px",
                    marginBottom: "20px"
                  }}
                >
                  {this.state.errors["EarningGroupId_Without_Garnishment"]}
                </p>
              )}
              {this.state.openAdvanceOptionsLink
                ? this.props.systemDeductionData.DeductionCategoryId ===
                    systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_RETIREMENT_ID ||
                  (this.props.systemDeductionData.DeductionCategoryId ===
                    systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_INSURANCE_ID &&
                    this.props.systemDeductionData.Taxability === false) ||
                  (this.props.systemDeductionData.DeductionCategoryId ===
                    systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_OTHER_ID &&
                    this.props.systemDeductionData.Taxability === false) ||
                  this.props.systemDeductionData.DeductionCategoryId ===
                    systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_GARNISHMENT_ID ||
                  this.props.systemDeductionData.DeductionCategoryId ===
                    systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_RETIREMENT_LOAN_ID
                  ? this.showAdvanvedOptions()
                  : null
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  showPercentageEarningsForHourBasedSelection() {
    return (
      <div
        id="deduction-cycle-page-description"
        className="entity-form-section-primary"
      >
        <div id="deduction-cycle-page-description" className="content">
          {/* <div id="deduction-name" className="deduction-page-section"> */}
          <div className="seperator">
            <hr />
          </div>
          <div className="nc-field-group clearfix">
            <div className="field-name">
              <span
                className={utilities.getErrorClassName(
                  this.state.errors["EarningGroupId_Without_Garnishment"]
                )}
              >
                Which hours should be included in the calculation?{" "}
                <a
                  id="deduction-percent-hours-info-icon"
                  onClick={() =>
                    this.setState({
                      openPercentageDeductionInfo: !this.state
                        .openPercentageDeductionInfo
                    })
                  }
                >
                  <i className="fa-information-blue fa fa-info-circle" />
                </a>
              </span>
            </div>

            <div className="clearfix" />
            {this.state.openPercentageDeductionInfo
              ? this.showPercentageDeductionInfo()
              : null}
            <div className="clearfix" />

            <div className="deduction-page-section-input">
              <div
                className="radio-button-group"
                style={{ marginBottom: "10px" }}
              >
                {this.renderHoursCalculationListItem()}
              </div>
              {this.state.errors["EarningGroupId_Without_Garnishment"] && (
                <p
                  className="error-label"
                  style={{
                    marginTop: "-15px",
                    marginBottom: "20px"
                  }}
                >
                  {this.state.errors["EarningGroupId_Without_Garnishment"]}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  showPercentGarnishmentEarnings() {
    return (
      <div
        id="deduction-cycle-page-description"
        className="entity-form-section-primary"
      >
        <div id="deduction-cycle-page-description" className="content">
          {/* <div id="deduction-name" className="deduction-page-section"> */}
          <div className="seperator">
            <hr />
          </div>
          <div className="nc-field-group clearfix">
            <div className="field-name">
              <span
                className={utilities.getErrorClassName(
                  this.state.errors["EarningGroupId_With_Garnishment"]
                )}
              >
                What is this a percentage of?{" "}
                <a
                  id="deduction-percent-garnishment-info-icon"
                  onClick={() =>
                    this.setState({
                      openPercentageDeductionInfo: !this.state
                        .openPercentageDeductionInfo
                    })
                  }
                >
                  <i className="fa-information-blue fa fa-info-circle" />
                </a>
              </span>
            </div>

            <div className="clearfix" />
            {this.state.openPercentageDeductionInfo
              ? this.showPercentageDeductionInfo()
              : null}
            <div className="clearfix" />

            <div className="deduction-page-section-input">
              {this.rendergarnishmentEarningsListItem()}
              {this.state.openAdvanceOptionsLink
                ? this.showAdvanvedOptions()
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  showHourGarnishmentEarnings() {
    return (
      <div
        id="deduction-cycle-page-description"
        className="entity-form-section-primary"
      >
        <div id="deduction-cycle-page-description" className="content">
          {/* <div id="deduction-name" className="deduction-page-section"> */}
          <div className="seperator">
            <hr />
          </div>

          <div className="nc-field-group clearfix">
            <div className="field-name">
              <span
                className={utilities.getErrorClassName(
                  this.state.errors["EarningGroupId_With_Garnishment"]
                )}
              >
                Which hours should be included in the calculation?{" "}
                <a
                  id="deduction-percent-hours-garnishment-info-icon"
                  onClick={() =>
                    this.setState({
                      openPercentageDeductionInfo: !this.state
                        .openPercentageDeductionInfo
                    })
                  }
                >
                  <i className="fa-information-blue fa fa-info-circle" />
                </a>
              </span>
            </div>

            <div className="clearfix" />
            {this.state.openPercentageDeductionInfo
              ? this.showPercentageDeductionInfo()
              : null}
            <div className="clearfix" />

            <div className="deduction-page-section-input">
              {this.rendergarnishmentEarningsListItem()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  showOtherCalculationSection() {
    return (
      <div
        id="deduction-cycle-page-description"
        className="entity-form-section-primary"
        style={{ marginBottom: "10px" }}
      >
        <div id="deduction-cycle-page-description" className="content">
          <div className="seperator">
            <hr />
          </div>
          <div className="nc-field-group clearfix">
            <div id="deduction-Other-Calculation-Method" className="field-name">
              Other Calculation Method{" "}
              <a
                id="deduction-other-info-icon"
                onClick={() =>
                  this.setState({
                    openOtherCalculationInfo: !this.state
                      .openOtherCalculationInfo
                  })
                }
              >
                <i className="fa-information-blue fa fa-info-circle" />
              </a>
            </div>

            <div
              id="deduction-Other-Calculation-Method-Note"
              className="field-information"
              style={{ marginBottom: "15px" }}
            >
              <b>NOTE: </b>This calculation method should <b>ONLY</b> be used if
              using NetBenefits and the plan is setup with a maximum employee
              amount.
            </div>

            {this.state.openOtherCalculationInfo
              ? this.showOtherCalculationSectionPopover()
              : null}
            <div
              className="radio-button-group"
              style={{ marginBottom: "10px" }}
            >
              {this.renderacaDeductionListItem()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  showAdvanvedOptions() {
    return (
      <div>
        <div className="seperator">
          <hr />
        </div>
        <div
          style={{
            marginBottom: this.state.openAdvanceOptions ? "0px" : "20px"
          }}
        >
          <a
            id="deduction-advance-option-link"
            className="text-link"
            onClick={() =>
              this.setState({
                openAdvanceOptions: !this.state.openAdvanceOptions
              })
            }
          >
            {" "}
            {this.state.openAdvanceOptions ? (
              <span>
                <i className="fa fa-chevron-up" /> Hide Advanced Options
              </span>
            ) : (
              <span>
                <i className="fa fa-chevron-down" /> Show Advanced Options
              </span>
            )}{" "}
          </a>
        </div>
        <Panel
          className="hide-show-collapsible-panel"
          collapsible
          expanded={this.state.openAdvanceOptions}
        >
          <Panel.Collapse>
            <Panel.Body>
              <div className="nc-field-group clearfix">
                <div
                  className="field-name"
                  id="reduce-wages-insurance-question"
                >
                  Do you want to reduce the wages above by pre-tax insurance
                  deduction amounts (i.e. Section 125) prior to multiplying by
                  the deduction percentage?
                </div>

                <div
                  className="radio-button-group"
                  style={{ marginBottom: "10px" }}
                >
                  {this.renderadvancedPreTaxInsuranceListItem(
                    advancedPreTaxInsuranceList
                  )}
                </div>
              </div>
              {this.state.advancedRetirementListItem
                ? this.props.systemDeductionData.DeductionCategoryId !==
                    systemConstantsCalculationMethodPageId.DEDUCTION_RETIREMENT_ID &&
                  (String(this.state.EarningGroupId_With_Garnishment) ===
                    String(
                      systemConstantsCalculationMethodPageId.CALCULATION_METHOD_TAXABLE_EARNINGS_ID
                    ) ||
                    String(this.state.EarningGroupId_Without_Garnishment) ===
                      String(
                        systemConstantsCalculationMethodPageId.CALCULATION_METHOD_TAXABLE_EARNINGS_ID
                      )) && (
                    <div className="nc-field-group clearfix">
                      <div
                        className="field-name"
                        id="reduce-wages-retirement-question"
                      >
                        Do you want to reduce the wages above by retirement
                        deduction amounts prior to multiplying by the deduction
                        percentage?
                      </div>

                      <div
                        className="radio-button-group"
                        style={{ marginBottom: "10px" }}
                      >
                        {this.renderadvancedRetirementListItem(
                          advancedRetirementList
                        )}
                      </div>
                    </div>
                  )
                : null}
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </div>
    );
  }

  onOpenBackButtonModal() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let errorCount = this.validateRequiredField();
    if (errorCount === 0) {
      this.setState({ openBackButtonModal: true, isFormUpdated: false });
    } else {
      this.setState({ openBackButtonModal2: true, isFormUpdated: false });
    }
    this.props.setFormUpdate(false);
  }

  onCloseBackButtonModal() {
    this.setState({
      openBackButtonModal: false,
      openBackButtonModal2: false,
      isFormUpdated: true
    });
    this.props.setFormUpdate(true);
  }

  close() {
    this.setState({
      openBackButtonModal: false,
      showDeleteButtonModal: false,
      showCancelModal: false,
      closeLeavingModal: false,
      openTrackYourProgressModal: false,
      openTrackYourProgressModal2: false,
      isFormUpdated: true,
      showBackCancelModal: false
    });
    this.props.setFormUpdate(true);
    this.props.setGlobalNavClick(false);
  }

  onOpenTrackYourProgressModal = navLink => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({
      openTrackYourProgressModal: true,
      modalNavLink: navLink,
      isFormUpdated: false
    });
    this.props.setFormUpdate(false);
  };

  onOpenTrackYourProgressModal2 = navLink => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({
      openTrackYourProgressModal2: true,
      modalNavLink: navLink,
      isFormUpdated: false
    });
    this.props.setFormUpdate(false);
  };

  onCloseTrackYourProgressModal() {
    this.setState({ openTrackYourProgressModal: false });
    this.setState({
      openTrackYourProgressModal: false,
      openTrackYourProgressModal2: false,
      isFormUpdated: true
    });
    this.props.setFormUpdate(true);
  }

  onOpenDeleteButtonModal() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({ showDeleteButtonModal: true });
  }

  onCloseDeleteButtonModal() {
    this.setState({ showDeleteButtonModal: false });
  }

  leavingCancelModal() {
    this.setState({ closeLeavingModal: false });
  }

  renderCalculationMethodListItem(calculationMethodRadioButtonList) {
    if (
      this.props.calculationMethodRadioButtonList !== undefined &&
      this.props.calculationMethodRadioButtonList !== null &&
      this.props.calculationMethodRadioButtonList.Data !== undefined
    ) {
      return this.props.calculationMethodRadioButtonList.Data.map(item => {
        var Value = String(item.DeductionCalculationBasisId);
        return (
          <RadioButton
            key={item.Name}
            id={
              "deduction-calculation-basis-" + item.DeductionCalculationBasisId
            }
            name="DeductionCalculationBasisId"
            value={Value}
            label={item.Name}
            checked={
              String(this.state.DeductionCalculationBasisId) ===
              String(item.DeductionCalculationBasisId)
            }
            onChange={this.onChangeCalculationMethod}
            width="180px"
          />
        );
      });
    }
  }

  renderPercentageCalculationListItem = () => {
    let SystemEarningGroupId = this.state.EarningGroupId_Without_Garnishment;
    return this.state.systemLevelEarningPercentList.map((item, index) => {
      if (
        item.SystemEarningGroupId !==
          systemConstantsCalculationMethodPageId.CALCULATION_METHOD_DISPOSABLE_EARNINGS_ID ||
        (this.state.DeductionCategoryId ===
          systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_OTHER_ID &&
          this.state.DeductionSubCategoryId ===
            systemConstantsCalculationMethodPageId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_MISCELLANEOUS)
      ) {
        return (
          <RadioButton
            key={index}
            id={
              "calculation-method-radio-button-earning-group-" +
              item.SystemEarningGroupId
            }
            name="EarningGroupId_Without_Garnishment"
            value={String(item.SystemEarningGroupId)}
            type="two-line-title"
            label={item.SystemEarningGroupName}
            checked={
              String(SystemEarningGroupId) === String(item.SystemEarningGroupId)
            }
            onChange={this.onChangeWithoutGarnishmentMethod}
            width="235px"
          />
        );
      } else return null;
    });
  };

  rendergarnishmentEarningsListItem = () => {
    let SystemEarningGroupId = this.state.EarningGroupId_With_Garnishment;
    return this.state.systemLevelEarningPercentList.map((item, index) => {
      return (
        <RadioButton
          key={index}
          id={
            "calculation-method-radio-button-garnishment-" +
            item.SystemEarningGroupId
          }
          name="EarningGroupId_With_Garnishment"
          value={String(item.SystemEarningGroupId)}
          type="two-line-title"
          label={item.SystemEarningGroupName}
          checked={
            String(SystemEarningGroupId) === String(item.SystemEarningGroupId)
          }
          onChange={this.onChangeGarnishmentMethod}
          width="235px"
        />
      );
    });
  };

  renderHoursCalculationListItem = () => {
    return this.state.systemLevelEarningPercentList.map((item, index) => {
      return (
        <div key={index} className="single-radio-button">
          <RadioButton
            id={
              "calculation-method-radio-button-garnishment-" +
              item.SystemEarningGroupId
            }
            name="EarningGroupId_Without_Garnishment"
            value={String(item.SystemEarningGroupId)}
            type="two-line-title"
            label={item.SystemEarningGroupName}
            checked={
              String(this.state.EarningGroupId_Without_Garnishment) ===
              String(item.SystemEarningGroupId)
            }
            onChange={this.onChangeGarnishmentMethod}
            width="235px"
          />
        </div>
      );
    });
  };

  renderadvancedPreTaxInsuranceListItem(list) {
    return list.map(item => {
      return (
        <div
          key={item.id}
          id={`calculation-method-radio-button-section-125amounts-` + item.id}
          className={`single-radio-button`}
        >
          <RadioButton
            id={item.id}
            name={item.name}
            value={item.value}
            type="title"
            label={item.label}
            checked={this.state.ReduceBySection125Amounts === item.value}
            onChange={this.onChangeAdvancedPreTaxOptions}
            boxWidth="100%"
          />
        </div>
      );
    });
  }

  renderadvancedRetirementListItem(list) {
    return list.map(item => {
      return (
        <div
          key={item.id}
          id={`calculation-method-radio-button-section-125amounts-` + item.id}
          className={`single-radio-button`}
        >
          <RadioButton
            id={item.id}
            name={item.name}
            value={item.value}
            type="title"
            label={item.label}
            checked={this.state.ReduceByRetirementAmounts === item.value}
            onChange={this.onChangeAdvancedRetirementOptions}
            boxWidth="100%"
          />
        </div>
      );
    });
  }

  renderacaDeductionListItem(acaDeductionValuesList) {
    let checkedTrue = true;
    if (
      this.props.acaDeductionValuesList !== undefined &&
      this.props.acaDeductionValuesList !== null &&
      this.props.acaDeductionValuesList.Data !== undefined
    ) {
      return this.props.acaDeductionValuesList.Data.map((item, index) => {
        return (
          <RadioButton
            key={index}
            id={
              `aca-deduction-radio-button-` +
              item.DeductionCalculationBasisOptionId
            }
            name="DeductionCalculationBasisOptionId"
            value={String(item.DeductionCalculationBasisOptionId)}
            type="basic"
            label={item.Name}
            checked={checkedTrue}
            onChange={this.onChangeOtherCalculationMethod}
            width="180px"
          />
        );
      });
    }
  }

  showPercentageDeductionInfo() {
    return (
      <div
        id="open-calculation-method-percentage-info-popover"
        className="open-deduction-based-popover-for-information-extra-small-type col-xs-12 col-sm-12 col-md-12 col-lg-12"
      >
        <a
          id="deduction-percent-tooltip-close-icon"
          className="open-deduction-based-popover-button-close"
          onClick={() => this.setState({ openPercentageDeductionInfo: false })}
        >
          <i className="fa fa-times-circle fa-lg" />
        </a>
        <div
          id="deduction-percent-tooltip-data"
          className="deduction-popover-information-data col-xs-12 col-sm-12 col-md-12 col-lg-12"
        >
          <p>
            Gross Earnings include all earnings paid to the employee. This
            specifically excludes expense reimbursements, imputed income and
            taxable fringe benefits. Imputed income is the addition of the fair
            market value of cash/non-cash compensation to an employee's direct
            salary or wages in order to properly withhold income or employment
            taxes from the wages. For more information, consult the IRS's
            Employer's Tax Guide to Fringe Benefits.
          </p>
          <p>
            <a
              href="https://www.irs.gov/pub/irs-pdf/p15b.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="system-level-calculation-method-taxability-modal-link">
                (https://www.irs.gov/pub/irs-pdf/p15b.pdf)
              </i>
            </a>
          </p>
          <p>
            Taxable Earnings are all earnings that appear on a W-2 and exclude
            nontaxable income. For more information, consult the IRS's
            Publication 525 - Taxable and Nontaxable Income.
          </p>
          <p>
            <a
              href="https://www.irs.gov/pub/irs-pdf/p525.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i>(https://www.irs.gov/pub/irs-pdf/p525.pdf)</i>
            </a>
          </p>
          <p>
            Retirement Earnings are earnings used to calculate percentage-based
            retirement deductions (e.g. 401(k), 403(b), 457(b), etc.). Wages
            from these earnings are reportable on the 401(k)/403(b) Report.
          </p>

          <p>
            Disposable Earnings are used in the calculation of garnishment
            deductions. They include all earnings paid to the employee,
            specifically excluding expense reimbursements, imputed income and
            taxable fringe benefits. Disposable wages are calculated by
            subtracting deductions required by law (e.g. federal, state, and
            local taxes) from Disposable Earnings. For more information, consult
            the Department of Labor's Fact Sheet #30 - The Federal Wage
            Garnishment Law, Consumer Credit Protection Act's Title III (CCPA).{" "}
          </p>
          <p>
            <a
              href="https://www.dol.gov/whd/regs/compliance/whdfs30.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="system-level-calculation-method-taxability-modal-link">
                (https://www.dol.gov/whd/regs/compliance/whdfs30.pdf)
              </i>
            </a>
          </p>
          <p>
            Percentage-Based Earnings allows you to define specific earnings
            when calculating a percentage-based deduction that is not a
            retirement deduction.
          </p>
        </div>
      </div>
    );
  }

  showOtherCalculationSectionPopover() {
    return (
      <div
        id="open-calculation-method-other-info-popover"
        className="open-deduction-based-popover-for-information-extra-small-type col-xs-12 col-sm-12 col-md-12 col-lg-12"
      >
        <a
          id="deduction-other-tooltip-close-icon"
          className="open-deduction-based-popover-button-close"
          onClick={() => this.setState({ openOtherCalculationInfo: false })}
        >
          <i className="fa fa-times-circle fa-lg" />
        </a>
        <div
          id="deduction-other-tooltip-data"
          className="deduction-popover-information-data col-xs-12 col-sm-12 col-md-12 col-lg-12"
        >
          <p>
            This calculation method applies to certain benefit deductions and
            allows for the adjustment of the employee contribution in situations
            where the maximum premium amount assigned to the employee-only
            coverage level of the associated benefit plan is greater than a
            pre-defined percentage of the employee's federal wages. In this
            situation, the employee contribution is reduced by the overage
            amount and the employer contribution is increased by the same. In
            order for the deduction to calculate correctly, the company must be
            on NetBenefits and the employee must be enrolled in the benefit plan
            associated with the deduction. The maximum premium amount must also
            be assigned to the employee-only coverage level of the associated
            benefit plan.
          </p>
        </div>
      </div>
    );
  }

  onClickTrackYourProgress(navLink) {
    let errorCount = this.validateRequiredField();
    if (this.state.isFormUpdated) {
      if (errorCount === 0) {
        this.onOpenTrackYourProgressModal2(navLink);
      } else {
        this.onOpenTrackYourProgressModal(navLink);
      }
    } else {
      this.props.history.push(navLink);
    }
  }

  closeToasterMessage = () => {
    this.setState({ showToaster: false });
    this.props.showToaster(false, "");
  };

  render() {
    let isReviewSaveEditPage = false;
    if (this.props.systemDeductionData.UiDraftCompletionStage === 6) {
      isReviewSaveEditPage = true;
    }

    return (
      <div id="calculation-method-page" className="nc-entity-page-container">
        <Prompt
          when={this.state.isFormUpdated}
          message={message.UNSAVED_CHANGES}
        />
        <DeductionSetupDescription />
        <div id="toaster-message" className="toaster-container">
          {this.state.showToaster ? (
            <Toaster
              show={this.state.showToaster}
              message={this.state.messageToaster}
              closeToasterMessage={this.closeToasterMessage}
              alertClassName="danger"
            />
          ) : null}
        </div>
        <div className="clearfix" />

        <div
          id="deduction-description-page-title1"
          className="title-band-container"
        >
          <div className="title-band">
            <h3>Deduction Setup</h3>
          </div>
        </div>

        {/* Added for the mobile view */}
        <div
          id="deduction-track-progress"
          className="progress-tracker-container-mobile hidden-lg hidden-md"
        >
          <SystemLevelDeductionDescriptionStep
            pageId={this.state.pageId}
            onClickTrackYourProgress={(navLink, id) =>
              id !== 2 ? this.onClickTrackYourProgress(navLink) : null
            }
          />
        </div>

        <div
          id="deduction-description-page-title2"
          className="entity-page-content col-lg-9 col-md-8"
        >
          <div className="step-title-container clearfix">
            <span className="step-number badge badge-default">2</span>
            <div
              id="system-level-calculations-method-page"
              className="step-title"
            >
              Calculation Method
            </div>
          </div>

          <div className="description-content">
            <div
              id="deduction-cycle-page-description"
              className="entity-form-section-primary"
            >
              <div id="deduction-name" className="content">
                <div className="nc-field-group clearfix">
                  <div
                    id="deduction-calculations-method-page-description"
                    className="field-information"
                  >
                    How would you like this deduction to calculate?
                  </div>

                  <div
                    id="deduction-calculations-method-page-sub-description"
                    className="field-information"
                    style={{ marginBottom: "20px" }}
                  >
                    <ul className="">
                      <li>Withhold a set dollar amount (e.g. $50, $200) </li>
                      <li>Withhold a % of the employee's wages (e.g. 3%) </li>
                      <li>
                        Withhold a set amount for each hour an employee works
                        (e.g. $0.50 for every hour worked){" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="seperator">
              <hr />
            </div>

            <div
              id="deduction-cycle-page-description"
              className="entity-form-section-primary"
            >
              <div id="deduction-cycle-page-description" className="content">
                <div id="deduction-name" className="nc-field-group clearfix">
                  <div className="field-name">
                    <span
                      className={utilities.getErrorClassName(
                        this.state.errors["DeductionCalculationBasisId"]
                      )}
                    >
                      Select Calculation Method
                    </span>
                  </div>
                  <div
                    className="radio-button-group"
                    style={{ marginBottom: "10px" }}
                  >
                    {this.renderCalculationMethodListItem()}
                  </div>
                  {this.state.errors["DeductionCalculationBasisId"] && (
                    <p
                      className="error-label"
                      style={{
                        marginTop: "-15px",
                        marginBottom: "20px"
                      }}
                    >
                      {this.state.errors["DeductionCalculationBasisId"]}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="clearfix" />

            {this.state.openOtherCalculation
              ? this.showOtherCalculationSection()
              : null}
            {this.state.openEarningsLinkPercentSelection
              ? this.showPercentageEarnings()
              : null}
            {this.state.openPercentGarnishmentOptionsLink
              ? this.showPercentGarnishmentEarnings()
              : null}
            {this.state.openEarningsLinkHourSelection
              ? this.showPercentageEarningsForHourBasedSelection()
              : null}
            {this.state.openHourGarnishmentOptionsLink
              ? this.showHourGarnishmentEarnings()
              : null}

            <div className="clearfix" />
          </div>
        </div>

        <div
          id="deduction-track-progress"
          className="col-lg-3 col-md-4 hidden-sm hidden-xs"
          style={{ marginBottom: "20px" }}
        >
          <SystemLevelDeductionDescriptionStep
            pageId={this.state.pageId}
            onClickTrackYourProgress={(navLink, id) =>
              id !== 2 ? this.onClickTrackYourProgress(navLink) : null
            }
          />
        </div>
        <div
          className="footer-button-container col-lg-12 col-md-12 col-sm-12 col-xs-12"
          style={{ clear: "both" }}
        >
          <div className="seperator">
            <hr />
          </div>
          {isReviewSaveEditPage ? (
            <div className="nc-button-group">
              <button
                type="button"
                id="calculation-page-save-changes-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDeductionCalculation.bind(
                  this,
                  SAVE_TO_SUMMERY_PAGE,
                  ""
                )}
              >
                SAVE CHANGES
              </button>

              <div className="nc-link-group-mobile-single-button">
                <button
                  type="button"
                  id="description-page-revert-back-button"
                  className="secondary-link-button"
                  onClick={this.redirectToSummarygPage}
                >
                  Revert back to last saved
                </button>
              </div>
            </div>
          ) : (
            <div className="nc-button-group">
              <button
                type="button"
                id="default-value-back-button"
                className="nc-button outline-button hidden-xs"
                onClick={
                  !this.state.isFormUpdated
                    ? this.redirectToSystemLevelDeductionDescription
                    : this.onOpenBackButtonModal
                }
              >
                <i className="fa fa-chevron-left"> </i> BACK
              </button>

              <button
                type="button"
                id="deduction-save-and-continue-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDeductionCalculation.bind(
                  this,
                  SAVE_AND_CONTINUE,
                  ""
                )}
              >
                CONTINUE
              </button>
              <button
                type="button"
                id="default-value-back-button-mobile"
                className="nc-button outline-button visible-xs"
                onClick={
                  !this.state.isFormUpdated
                    ? this.redirectToSystemLevelDeductionDescription
                    : this.onOpenBackButtonModal
                }
              >
                <i className="fa fa-chevron-left"> </i> BACK
              </button>
              <div className="nc-link-group-mobile">
                <button
                  type="button"
                  id="deduction-save-and-finish-later-buttonlink"
                  className="primary-link-button"
                  onClick={this.onOpenFinishLaterButtonModal.bind(this)}
                >
                  Finish Later
                </button>

                <button
                  type="button"
                  id="deduction-cancel-buttonlink"
                  className="secondary-link-button"
                  onClick={this.onOpenDeleteButtonModal}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="clearfix" />

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          id="track-progress-modal"
          show={this.state.openTrackYourProgressModal}
          onHide={this.onCloseTrackYourProgressModal}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="track-progress-modal-close-icon"
                onClick={this.onCloseTrackYourProgressModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="track-progress-modal-line1">
                Are you sure you want to leave this page in the middle of making
                edits?
              </div>
              <br />
              <div id="track-progress-modal-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="track-progress-modal-save-button"
                className="nc-button secondary-button"
                onClick={this.exitSetup.bind(
                  this,
                  "LEAVE_THIS_PAGE",
                  this.state.modalNavLink
                )}
              >
                LEAVE THIS PAGE
              </button>

              <button
                type="button"
                id="continue-editing-leaving-modal-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseTrackYourProgressModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          id="track-progress-modal-2"
          show={this.state.openTrackYourProgressModal2}
          onHide={this.onCloseTrackYourProgressModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="track-progress-modal-2-close-icon"
                onClick={this.onCloseTrackYourProgressModal.bind(this)}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="track-progress-modal-2-line1">
                Do you want to save your changes before leaving this page?
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="track-progress-modal-2-save-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDeductionCalculation.bind(
                  this,
                  TRACK_YOUR_PROGRESS,
                  this.state.modalNavLink
                )}
              >
                SAVE AND GO
              </button>

              <button
                type="button"
                id="track-progress-modal-2-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseTrackYourProgressModal.bind(this)}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="finish-later-modal-1"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showFinishLaterModalWithoutMandatoryField}
          onHide={this.onCloseFinishLaterModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="finish-later-modal-1-close-icon"
                onClick={this.onCloseFinishLaterModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="finish-later-modal-1-line1">
                Are you sure you want to exit setup in the middle of making
                edits?
              </div>
              <br />
              <div id="finish-later-modal-1-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="finish-later-modal-1-exit-button"
                className="nc-button secondary-button"
                onClick={this.exitSetup.bind(this, "EXIT_SETUP", "")}
              >
                EXIT SETUP
              </button>

              <button
                type="button"
                id="finish-later-modal-1-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseFinishLaterModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="finish-later-modal-2"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showFinishLaterModalWithMandatoryField}
          onHide={this.onCloseFinishLaterModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="finish-later-modal-2-close-icon"
                onClick={this.onCloseFinishLaterModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="finish-later-modal-2-line1">
                Do you want to save your changes before exiting setup?
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="finish-later-modal-1-exit-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDeductionCalculation.bind(
                  this,
                  SAVE_AND_FINISH_LATER,
                  ""
                )}
              >
                SAVE AND EXIT
              </button>

              <button
                type="button"
                id="finish-later-modal-1-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseFinishLaterModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="delete-deduction-modal"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showDeleteButtonModal}
          onHide={this.onCloseDeleteButtonModal}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="delete-deduction-modal-close-icon"
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="delete-deduction-modal-line1">
                Are you sure you want to delete this deduction?
              </div>
              <br />
              <div id="delete-deduction-modal-line2">
                It will be gone for now, but you can add it back if you need it.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="delete-deduction-modal-delete-button"
                className="nc-button secondary-button"
                onClick={this.deleteDeduction}
              >
                DELETE DEDUCTION
              </button>

              <button
                type="button"
                id="delete-deduction-modal-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.close}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          id="back-button-modal"
          show={this.state.openBackButtonModal2}
          onHide={this.onCloseBackButtonModal}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="back-button-modal-close-icon"
                onClick={this.onCloseBackButtonModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="back-button-modal-line1">
                Are you sure you want to leave this page in the middle of making
                edits?
              </div>
              <br />
              <div id="back-button-modal-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="back-button-modal-save-button"
                className="nc-button secondary-button"
                onClick={this.exitSetup.bind(this, "SAVE_AND_BACK_PAGE", "")}
              >
                LEAVE THIS PAGE
              </button>

              <button
                type="button"
                id="back-button-modal-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseBackButtonModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          id="back-button-modal-2"
          show={this.state.openBackButtonModal}
          onHide={this.onCloseBackButtonModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="back-button-modal-2-close-icon"
                onClick={this.onCloseBackButtonModal.bind(this)}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="back-button-modal-2-line1">
                Do you want to save your changes before leaving this page?
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="back-button-modal-2-save-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDeductionCalculation.bind(
                  this,
                  SAVE_AND_BACK_PAGE,
                  ""
                )}
              >
                SAVE AND GO
              </button>

              <button
                type="button"
                id="track-progress-modal-2-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseBackButtonModal.bind(this)}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showBackCancelModal}
          onHide={() => {
            this.setState({
              showBackCancelModal: !this.state.showBackCancelModal
            });
          }}
          id="deduction-unsaved-modal"
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="deduction-unsaved-modal-close-icon"
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="finish-later-modal-1-line1">
                Are you sure you want to leave this page in the middle of making
                edits?
              </div>
              <br />
              <div id="finish-later-modal-1-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="finish-later-modal-1-exit-button"
                className="nc-button secondary-button"
                onClick={this.exitSetup.bind(this, "GLOBAL_NAV", "")}
              >
                LEAVE THIS PAGE
              </button>

              <button
                type="button"
                id="deduction-unsaved-modal-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.close}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    calculationMethodRadioButtonList: state.calculationMethodRadioButtonList,
    GarnishmentPercentRadioButtonsList:
      state.GarnishmentPercentRadioButtonsList,
    advancedPreTaxValuesList: state.advancedPreTaxValuesList,
    advancedRetirementValuesList: state.advancedRetirementValuesList,
    // garnishmentValuesList: state.garnishmentValuesList,
    acaDeductionValuesList: state.acaDeductionValuesList,
    systemDeductionData: state.systemDeductionData,
    savedSysDeductionPages: state.savedSysDeductionPages,
    globalNavClick: state.globalNavClick
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCalculationMethodRadioButtonsList: getCalculationMethodRadioButtonsList,
      getGarnishmentPercentRadioButtonsList: getGarnishmentPercentRadioButtonsList,
      showToaster: showToaster,
      getAdvancedPretaxValuesList: getAdvancedPretaxValuesList,
      getAdvancedRetirementValuesList: getAdvancedRetirementValuesList,
      getAcaDeductionValuesList: getAcaDeductionValuesList,
      setSystemDeductionData: setSystemDeductionData,
      setLoader: setLoader,
      setFormUpdate: setFormUpdate,
      isOverlayClickable: isOverlayClickable,
      setGlobalNavClick: setGlobalNavClick,
      CallbackToQuickLink: CallbackToQuickLink
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CalculationsMethodPage)
);
