import React from "react";

const FormTitleWithIcon = props => (
  <div className="title row m-0">
    <div
      className={`title ${props.checkbox ? "col-md-2" : "col-md-5"}`}
      style={{ padding: "0px" }}
    >
      {props.title}
      {props.confirmed && <i className="fa fa-check-circle" />}
    </div>
    {props.checkbox ? (
      <div className="grid-option col-md-6" style={{ padding: "0px" }}>
        <div
          className={`list-item ${props.selected ? "selected" : ""}`}
          onClick={props.onClick}
          style={{
            color: "#555",
            fontSize: "14px",
            paddingLeft: "45px",
            paddingTop: "5px"
          }}
        >
          <input type="checkbox" checked={props.selected || false} readOnly />
          <label>
            {props.icon && <i className={`fa ${props.icon}`} />}
            {props.label}
          </label>
        </div>
      </div>
    ) : (
      <div
        className="col-md-6 title-label"
        onClick={() => props.onClick(props.title)}
      >
        {props.link}
        <i className={props.labelIcon} />
      </div>
    )}
  </div>
);

export default FormTitleWithIcon;
