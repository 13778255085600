import * as types from "../../../actions/systemlevel/index";

export const initialState = {
  Data: [],
  DataCount: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SYSTEM_THIRD_PARTY_PAYEE_MAIN_LIST:
      return action.payload.data;
    default:
      return state;
  }
}
