import React from "react";

const TaxVerificationOn = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 2880 2655"
    style={{ enableBackground: "new 0 0 2880 2655" }}
    width={width}
    height={height}
  >
    <g>
      <path
        className="st0"
        d="M2632.6,1693.7c-22.2,0-40.3-18.1-40.3-40.3c0-22.2,18.1-40.3,40.3-40.3s40.3,18.1,40.3,40.3C2672.9,1675.6,2654.8,1693.7,2632.6,1693.7z M2632.6,1615.1c-21.1,0-38.3,17.2-38.3,38.3s17.2,38.3,38.3,38.3c21.1,0,38.3-17.2,38.3-38.3S2653.7,1615.1,2632.6,1615.1z"
      />
    </g>
    <g>
      <g>
        <path
          className="st0"
          d="M2620.5,1641.8h17c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-17c-0.5,0-0.9,0.4-0.9,0.9C2619.6,1641.4,2620,1641.8,2620.5,1641.8z"
        />
        <path
          className="st0"
          d="M2620.5,1647.3h17c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-17c-0.5,0-0.9,0.4-0.9,0.9C2619.6,1646.8,2620,1647.3,2620.5,1647.3z"
        />
        <path
          className="st0"
          d="M2633,1661.6h-12.4c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h12C2632.6,1662.9,2632.7,1662.2,2633,1661.6z"
        />
        <path
          className="st0"
          d="M2620.5,1652.7h17c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-17c-0.5,0-0.9,0.4-0.9,0.9C2619.6,1652.3,2620,1652.7,2620.5,1652.7z"
        />
        <path
          className="st0"
          d="M2620.5,1658.1h14.6c0.7-0.7,1.6-1.4,2.5-1.9c0,0-0.1,0-0.1,0h-17c-0.5,0-0.9,0.4-0.9,0.9S2620,1658.1,2620.5,1658.1z"
        />
      </g>
      <g>
        <g>
          <path
            className="st0"
            d="M2642.2,1673c-4.4,0-8.1-3.6-8.1-8.1s3.6-8.1,8.1-8.1s8.1,3.6,8.1,8.1S2646.7,1673,2642.2,1673zM2642.2,1658.8c-3.4,0-6.2,2.8-6.2,6.2s2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2S2645.6,1658.8,2642.2,1658.8z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="st0"
            d="M2641.6,1668.2h-0.2c-0.3,0-0.5-0.1-0.7-0.3l-2.5-2.5c-0.4-0.4-0.4-1,0-1.3c0.4-0.4,1-0.4,1.3,0l1.8,1.8l3.2-3.9c0.3-0.4,0.9-0.5,1.3-0.1c0.4,0.3,0.5,0.9,0.1,1.3l-3.8,4.6C2642.2,1668,2641.9,1668.2,2641.6,1668.2z"
          />
        </g>
      </g>
      <path
        className="st0"
        d="M2632.8,1667.7h-13.7c-1.3,0-2.3-1-2.3-2.3V1638c0-1.3,1-2.3,2.3-2.3h19.9c1.3,0,2.3,1,2.3,2.3v17.1c0.3,0,0.6,0,0.9,0s0.6,0,0.9,0V1638c0-2.3-1.9-4.2-4.2-4.2h-19.9c-2.3,0-4.2,1.9-4.2,4.2v27.4c0,2.3,1.9,4.2,4.2,4.2h14.5C2633.3,1669,2633,1668.4,2632.8,1667.7z"
      />
    </g>
  </svg>
);

export default TaxVerificationOn;
