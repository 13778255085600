import React, { Component } from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import {
  additionalInfo,
  ADDITIONAL_INFO,
  DISABILITY,
  DISABILITY_ACC,
  SAVE_DEMOGRAPHIC
} from "../Constants";

class AdditionalInfo extends Component {
  render() {
    const { data } = this.props;
    const { initialData } = data;
    let componentProps = additionalInfo;
    componentProps.race.options = initialData.race || [];
    componentProps.gender.radio = initialData.gender || [];
    componentProps.martialStatus.radio = initialData.martialStatus
      ? initialData.martialStatus
      : [];
    let mapKeys = Object.keys(componentProps);
    if (
      !this.props.data[DISABILITY] ||
      this.props.data[DISABILITY] === "false"
    ) {
      mapKeys = mapKeys.filter(k => k !== DISABILITY_ACC);
    }
    const additionalInfoNodes = mapKeys.map(k => {
      return (
        <FormFieldGroup
          key={k}
          {...componentProps[k]}
          block
          colSpan="col-md-3"
          value={this.props.data[k]}
          onChange={this.props.onChange}
          labelStyle={{ marginRight: "30px" }}
        />
      );
    });
    return (
      <FormSection title={ADDITIONAL_INFO} hr>
        <div className="col-md-12" style={{ padding: "0px" }}>
          {additionalInfoNodes}
        </div>
        <div className="col-md-12">
          <button
            id={SAVE_DEMOGRAPHIC}
            onClick={this.props.onSave}
            style={{ float: "right" }}
            className="nc-button primary-button"
          >
            Save
          </button>
        </div>
      </FormSection>
    );
  }
}

export default AdditionalInfo;
