/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Icon from "./icons";
import "./styles.css";
import {
  STATUS_COMPLETED_WITH_ERRORS,
  STATUS_FAILED,
  STATUS_CURRENTLY_INQUEUE,
  STATUS_CANCELLED
} from "../Constants";
import generateTrackerData from "./generateTrackerData";
import VerticalLine from "./VerticalLine";

const ProgressItem = (
  data,
  onClickCancelRequest,
  userTimeZoneOffset="America/Chicago",
  userTimeZoneAbbreviation,
  convertUserTimeZone
) => item => {
  let showVerticalLine = true;
  if (
    item.stepId === 3 ||
    (item.stepId === 2 && item.stepName === "CANCELLED")
  ) {
    showVerticalLine = false;
  }
  return (
    <div key={item.stepId} className="progress-item">
      <div className="icon-bar" id={`icon-bar-${item.stepId}`}>
        <Icon
          state={item.state}
          stepId={item.stepId}
          errorCount={item.errorCount}
          stageId={data.JobStatusId}
        />
        {showVerticalLine && (
          <VerticalLine
            stepId={item.stepId}
            state={item.state}
            stageId={data.JobStatusId}
            className="solid-line"
          />
        )}
      </div>
      <div className="status-item">
        <div className="status-name" id={`status-name-${item.stepId}`}>
          {item.stepName}
          {[STATUS_COMPLETED_WITH_ERRORS, STATUS_FAILED].includes(
            data.JobStatusId
          ) &&
          item.stepId === 3 &&
          data.ErrorCount > 0 ? (
            <span>
              &nbsp; WITH <span className="red"> {data.ErrorCount} ERRORS</span>
            </span>
          ) : null}
        </div>
        {item.timeStamp !== undefined && item.timeStamp !== null && (
          <div className="date-time" id={`date-time-${item.stepId}`}>
            {convertUserTimeZone(
              userTimeZoneOffset,
              new Date(item.timeStamp),
              "MMM DD, YYYY [at] h:mm A z"
            )}
            {userTimeZoneAbbreviation}
          </div>
        )}

        {item.stepId === STATUS_CURRENTLY_INQUEUE &&
          (data.JobStatusId === STATUS_CURRENTLY_INQUEUE && (
            <div className="link">
              <a
                onClick={() => onClickCancelRequest(data.JobId)}
                id="cancel-request-link"
              >
                <i className="fa fa-times-circle-o" />
                Cancel Request
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};

const ProgressTrackerContainer = ({
  data = {},
  onClickCancelRequest,
  userTimeZoneOffset,
  userTimeZoneAbbreviation,
  convertUserTimeZone
}) => {
  let TrackerData = generateTrackerData(data);
  if (data.JobStatusId === STATUS_CANCELLED) {
    TrackerData = TrackerData.filter(item => {
      return item.stepId !== 3;
    });
  }
  return (
    <div className="notifications-progress-tracker">
      {TrackerData.map(
        ProgressItem(
          data,
          onClickCancelRequest,
          userTimeZoneOffset,
          userTimeZoneAbbreviation,
          convertUserTimeZone
        )
      )}
    </div>
  );
};

export default ProgressTrackerContainer;
