import React, { Component } from "react";
import PayrollDashBoardModal from "../payroll-dashboard-modals";
// import Accordian from "../../accordians";
import * as payrollConstants from "../PayrollDashboardConstants";

export default class ContactApproverModal extends Component {
  state = {
    contactApproversModal: false,
    contactAllApproversModal: false
  };

  contactApproversContent = () => {
    let sourceModal = "";
    if (this.props.source === payrollConstants.UNAPPROVED_PTO) {
      sourceModal = "unapproved paid time off.";
    } else if (this.props.source === payrollConstants.TIMECARD_ALERTS) {
      sourceModal = "timecard alert.";
    }
    return (
      <div className="modal-body-container">
        <div className="modal-body-header">Contact Approver</div>
        <div className="modal-body-content">
          <div className="clearfix content-section">
            You are about to contact approver(s) for{" "}
            <span className="secondary-header">[Employee Name]</span> regarding{" "}
            <span className="secondary-header">{sourceModal}</span>
          </div>
          <br />
          <div
            className="clearfix content-section"
            style={{ marginBottom: "20px" }}
          >
            [approver(s) name - name of all approvers for this employee] will
            receive [e-mails or texts - either or both depending on what is set
            for approvers]
          </div>
        </div>
      </div>
    );
  };

  contactApproversButtons = () => {
    const modalButton = [
      {
        label: "Send Alerts",
        buttonType: "secondary-button",
        onClick: this.onSendMessageClick
      },
      {
        label: "Cancel",
        buttonType: "secondary-link-button",
        onClick: this.closeContactApproversModal
      }
    ];

    return modalButton;
  };

  onSendMessageClick = EmployeeId => {
    this.props.onSendMessageClick(EmployeeId);
  };

  closeContactApproversModal = () => {
    this.props.closeContactApproversModal();
  };

  render() {
    return (
      <PayrollDashBoardModal
        show={this.props.contactApproversModal}
        bodyContent={this.contactApproversContent()}
        closeModal={this.closeContactApproversModal}
        footerButtons={this.contactApproversButtons()}
        buttonAlign="left"
      />
    );
  }
}
