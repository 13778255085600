import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import * as common from "../common/index";
import Random from "random-js";
import { setUserSession, setSessionContext } from "app/actions/AppActions";
import { setLoader } from "app/actions/LoaderActions";
//import { debug } from "util";
class LegacyToNew extends Component {
  state = {};

  componentDidMount() {
    let target = this.getParameter("target", window.location.href);
    let deepLink = this.getParameter("deepLink", window.location.href);
    let parameter = this.getParameter("parameter", window.location.href);

    axios.defaults.headers.common["Authorization"] = null;
    axios.defaults.headers.common["SessionContext"] = null;
    sessionStorage.removeItem("Authorization");
    sessionStorage.removeItem("SessionContext");
    if (deepLink === undefined) {
      localStorage.removeItem("Authorization");
      localStorage.removeItem("SessionContext");
    } else if (deepLink !== undefined) {
      if (window.atob(deepLink) !== 2) {
        localStorage.removeItem("Authorization");
        localStorage.removeItem("SessionContext");
      }
    }

    this.props.setSessionContext();
    this.props.setUserSession(false);
    this.props.setLoader(true);

    if (target !== undefined) {
      this.gotoB2C();
      sessionStorage.setItem("RedirectItem", target);
    }
    if (deepLink !== undefined) {
      this.gotoB2C();
      sessionStorage.setItem("DeepLinkItem", window.atob(deepLink));
      sessionStorage.setItem("Parameter", window.atob(parameter));
    }
  }

  gotoB2C = () => {
    var random = new Random(Random.engines.mt19937().autoSeed());
    var defaultNonce = random.integer(1, 100);
    var link = `https://${
      common.TENANT
      }.b2clogin.com/${
      common.TENANT
    }.onmicrosoft.com/oauth2/v2.0/authorize?p=${common.SignUpSignInPolicy}&client_id=${
      common.CLIENT_ID
    }&nonce=${defaultNonce}&redirect_uri=${
      common.UI_APP_URL
    }authenticate&scope=openid&response_type=id_token`;
    window.location.href = link;
  };

  getParameter = (name, url) => {
    var results = new RegExp("[\\?&]" + name + "=([^&#]*)").exec(url);
    if (!results) {
      return undefined;
    }
    return results[1] || undefined;
  };

  render() {
    return <div />;
  }
}

const stateToProps = state => ({});
const actionCreators = {
  setUserSession,
  setSessionContext,
  setLoader
};
export default connect(
  stateToProps,
  actionCreators
)(LegacyToNew);
