import React from "react";
import RadioButton from "./BasicRadioButton";
const BooleanRadios = [
  { id: 1, label: "Yes", value: true },
  { id: 0, label: "No", value: false }
];
const renderRadioButtons = props => {
  let { radios = [] } = props;
  radios = radios === "boolean" ? BooleanRadios : radios;
  return (
    <div
      className="button-type-container clearfix"
      style={{ marginBottom: "10px", ...props.containerStyle }}
    >
      {radios.map(item => (
        <RadioButton
          {...item}
          id={props.name + "-" + item.id}
          key={item.id}
          onChange={props.onChange}
          name={props.name}
          width={props.width}
          type={props.type}
          checked={item.value === props.value}
          disabled={props.disabled || item.disabled}
          inlineStyles={props.inlineStyles}
          radioStyles={item.radioStyles}
        />
      ))}
      {props.error &&
        props.required && (
          <div
            style={{ marginTop: "-5px", marginBottom: "10px" }}
            className="has-error"
          >
            {props.error}
          </div>
        )}
    </div>
  );
};

export default renderRadioButtons;
