import React, { Component } from "react";
import renderTab from "./renderTab";
import Tab from "./Tab";
export default class NavTab extends Component {
  scrolleft = () => {
    var element = document.getElementById("scroll-container");
    element.scrollLeft -= 50;
  };
  scrollRight = () => {
    var element = document.getElementById("scroll-container");
    element.scrollLeft += 50;
  };
  render() {
    const { props } = this;
    return (
      <div className="clearfix">
        <div
          id="scroll-container"
          className="nc-navtab-container"
          style={{ width: props.width }}
        >
          <ul className="nc-nav nc-nav-tabs clearfix">
            {props.data.map(renderTab(props, Tab))}
          </ul>
        </div>
        {props.scrollable && (
          <div className="scroll-buttons">
            <div className="back" onClick={this.scrolleft}>
              <i className="fa fa-chevron-left" />
            </div>
            <div className="next" onClick={this.scrollRight}>
              <i className="fa fa-chevron-right" />
            </div>
          </div>
        )}
      </div>
    );
  }
}
