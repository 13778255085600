import React, { Component } from "react";

export default class FooterSection extends Component {
  render() {
    const { onCancelClick, onAddClick, editMode, update } = this.props;
    return (
      <div className="tab-container-add-sub-section">
        <div className="section-items">
          <div className="nc-button-group">
            <button
              id={""}
              type="button"
              onClick={!editMode ? onAddClick : update}
              className="nc-button primary-button"
            >
              {!editMode ? "ADD Employment History" : "Save Changes"}
            </button>

            <button
              id={""}
              type="button"
              onClick={onCancelClick}
              className="nc-button secondary-link-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}
