export const NOTIFICATIONS_FILTER_NAME = "notifications";

export const PRODUCT_FILTER_NAME = "product";

export const COMPANY_FILTER_NAME = "company";

export const STATUS_FILTER_NAME = "status";

export const USER_FILTER_NAME = "user";

export const USER_TYPES_FILTER_NAME = "userType";

export const DATE_RANGE_FILTER_NAME = "daterange";

export const STATUS_CURRENTLY_INQUEUE = 1;

export const STATUS_INQUEUE = 2;

export const STATUS_CURRENTLY_INPROGRESS = 3;

export const STATUS_INPROGRESS = 4;

export const STATUS_CANCELLED = 5;

export const STATUS_COMPLETED_WITH_ERRORS = 6;

export const STATUS_COMPLETED = 7;

export const STATUS_FAILED = 8;

export const STATUS_COMPLETED_WITH_EXCEPTION = 9;

export const STATUS_COMPLETED_WITH_SUGGESTIONS_TAXES = 10;

export const STATUS_COMPLETED_WITH_SUGGESTIONS_MIN_WAGE = 11;

export const STATUS_COMPLETED_WITH_SUGGESTIONS_WAGE_AND_TAXES = 12;

export const USER_TYPES_LIST = [
  {
    value: 4,
    label: 'Admin'
  },
  {
    value: 2,
    label: 'Manager'
  },
  {
    value: 1,
    label: 'Employee'
  }
]