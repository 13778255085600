/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Panel } from "react-bootstrap";
import axios from "axios";
import { withRouter, Prompt } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Toaster from "app/common/UIComponents/Toaster";
import * as message from "app/common/messages";
import { showToaster } from "app/actions/AppActions";
import NumberFormat from "react-number-format";
import * as utilities from "app/common/utilities";
import Media from "react-media";
import { setLoader } from "app/actions/LoaderActions";
import * as common from "app/common/index";
import * as validator from "app/common/validations";
import * as systemConstantsId from "app/common/SystemConstants";
import "../../../../content/entitypage.css";
import "../../../../content/style.css";
import DeductionSetupDescription from "./DeductionSetupDescription";
import TextInputWithoutLabel from "app/common/TextInputWithoutLabel";
import DropDown from "component-library/dropDown/DropDown";
import RadioButton from "component-library/radioButton/BasicRadioButton";
import * as companyConstants from "app/common/CompanyConstants";

import {
  getCategoryDeductionList,
  getSubCategoryDeductionList,
  getTaxabilityValuesList,
  getExcludeStatesValuesList,
  getDeductionAmountOnDebitCard,
  getSymmetryTaxCodeList
} from "../../actions/systemlevel/SystemLevelDeductionDescriptionActions";

import {
  setGlobalNavClick,
  CallbackToQuickLink,
  setFormUpdate,
  isOverlayClickable
} from "app/actions/FormUpdateAction";

import {
  setSystemDeductionData,
  setSaveSysDeductionPage
} from "../../actions/systemlevel/DeductionSetupListingActions";

import SystemLevelDeductionDescriptionStep from "./SystemLevelDeductionDescriptionStep";
import { getTaxabilityDefaultValue } from "./GetTaxabilityDefaultValues";
import W2Box from "component-library/misc/w2Summary";

import {
  getReportedTaxTypeList,
  getReportedWagesList
} from "payroll/payrollsetup/actions/companylevel/CompanyLevelDeductionDescriptionActions.js";

var taxabilityList = [
  { id: "yes-pre-tax-radio", name: "TaxabilityId", value: true, label: "Yes" },
  { id: "no-pre-tax-radio", name: "TaxabilityId", value: false, label: "No" }
];

var taxReportableOnW2list = [
  {
    id: "apply-deduction-yes-radio",
    name: "TaxReportableOnW2",
    value: true,
    label: "Yes"
  },
  {
    id: "apply-deduction-no-radio",
    name: "TaxReportableOnW2",
    value: false,
    label: "No"
  }
];

let isInDidUpdate = false;

const SAVE_AND_CONTINUE = 1;
const SAVE_AND_FINISH_LATER = 2;
const SAVE_TO_SUMMERY_PAGE = 3;
const SAVE_CHANGES = 5;

const TRACK_YOUR_PROGRESS = 6;
const GLOBAL_NAV = 7;

const PENNSYLVANIA_ID = 52;
const NEWJERSEY_ID = 39;

class DeductionDescriptionPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showBackCancelModal: false,
      SystemDeductionName: this.props.systemDeductionData.SystemDeductionName,
      DeductionCategoryName: this.props.systemDeductionData
        .DeductionCategoryName,
      DeductionSubCategoryName: this.props.systemDeductionData
        .DeductionSubCategoryName,
      Taxability: this.props.systemDeductionData.Taxability,
      LoanNumber: this.props.systemDeductionData.LoanNumber,
      AdvancedTaxOption: this.props.systemDeductionData.AdvancedTaxOption,
      AppUIRoleId: this.props.sessionContext.CurrentAppUiViewId,
      ExcludeStates: this.props.systemDeductionData.ExcludeStates,
      IsDefaultTaxChange: false,
      pennsylvaniaState: false,
      newJerseyState: false,
      taxOptionEdit: false,
      SystemDeductionCode: this.props.systemDeductionData.SystemDeductionCode,
      DeductionCalculationBasisId: this.props.systemDeductionData
        .DeductionCalculationBasisId,
      EarningGroupId: this.props.systemDeductionData.EarningGroupId,

      SystemDeductionId: this.props.systemDeductionData.SystemDeductionId,
      DeductionCategoryId: this.props.systemDeductionData.DeductionCategoryId,
      DeductionAmountToBeDepositedOnDebitCard: this.props.systemDeductionData
        .DeductionAmountToBeDepositedOnDebitCard,
      TaxabilityId: this.props.systemDeductionData.Taxability,

      ReduceFUTAWages: this.props.systemDeductionData.ReduceFUTAWages,
      ReduceFederalWages: this.props.systemDeductionData.ReduceFederalWages,
      ReduceLocalWages: this.props.systemDeductionData.ReduceLocalWages,
      ReduceMedicareWages: this.props.systemDeductionData.ReduceMedicareWages,
      ReduceSDIWages: this.props.systemDeductionData.ReduceSDIWages,
      ReduceSUTAWages: this.props.systemDeductionData.ReduceSUTAWages,
      ReduceSocialSecurityWages: this.props.systemDeductionData
        .ReduceSocialSecurityWages,
      ReduceStateWages: this.props.systemDeductionData.ReduceStateWages,

      readonlyNewJerseyState: false,
      readonlyPennsylvaniaState: false,

      errors: {},
      searchable: false,
      DeductionSubCategoryId: this.props.systemDeductionData
        .DeductionSubCategoryId,
      openAdvanceTaxOptions: false,
      openAdvanceLink: false,
      openTaxabilitySection: false,
      openStateSelectionList: false,
      opendeductionamountdebitcard: false,
      selectedTaxOtionsObject: [],
      selectedTaxOtions: [],
      selectedExcludeStateOtions: [],
      selectedDeductionTypeLabel: "",
      selectedDeductionTypeValue: "",
      filteredDeductionDropdownList: null,
      validateAllSelectedAdvancedTaxOption: false,
      ReasonForChange: "",
      openTrackYourProgressModal: false,
      openTrackYourProgressModal2: false,
      showFinishLaterModalWithoutMandatoryField: false,
      showFinishLaterModalWithMandatoryField: false,
      showDeleteModal: false,
      closeLeavingModal: false,
      openInsuranceTaxabilitySection: false,
      openOtherTaxabilitySection: false,
      opendeductionnametaxtooltip: false,
      opencategorynametaxtooltip: false,
      checkmandatoryfieldsentered: false,
      openCancelButtonModal: false,
      openloannumbertext: false,
      openCategoryDropdownSection: false,
      openOnlyTaxabilityLinkForRetirementCategory: false,
      pageId: 1,
      isFormUpdated: false,
      showToaster: false,
      messageToaster: "",
      modalNavLink: "",
      showNewJerseyState: true,

      openTaxReportableOnW2Section: false,
      TaxReportableOnW2: false,
      openReportedTaxAndWagesSection: false,
      W2LocalTax_Ind: false,
      W2StateTax_Ind: false,
      WageBaseCode: "0",
      ReportedTaxType: "0",
      SymmetryTaxCodeId: this.props.systemDeductionData.SymmetryTaxCodeId,
      openSymmetryTaxCodeSection: false,

      deductionCodesReduceMapList: [
        {
          key: "ReduceFederalWages",
          name: "Box 1",
          value: "Wages, Tips, Other Compensation",
          displayOrder: 1
        },
        {
          key: "ReduceSocialSecurityWages",
          name: "Box 3",
          value: "Social Security Wages",
          displayOrder: 2
        },
        {
          key: "ReduceMedicareWages",
          name: "Box 5",
          value: "Medicate Wages and Tips",
          displayOrder: 3
        },
        {
          key: "ReduceStateWages",
          name: "Box 16",
          value: "State Wages, Tips, etc.",
          displayOrder: 4
        },
        {
          key: "ReduceLocalWages",
          name: "Box 18",
          value: "Local Wages, Tips, etc.",
          displayOrder: 5
        }
      ],
      deductionCodesAssociatedMapList: [
        {
          TaxabilityId: true,
          DeductionSubCategoryId: 8,
          name: "Box 12",
          value: "Code W",
          DeductionAmountToBeDepositedOnDebitCard: true
        },
        {
          TaxabilityId: true,
          DeductionSubCategoryId: 12,
          name: "Box 12",
          value: "Code R Employer Contribution Only"
        },
        {
          TaxabilityId: false,
          DeductionSubCategoryId: 8,
          name: "Box 12",
          value: "Code W Employer Contribution Only",
          DeductionAmountToBeDepositedOnDebitCard: true
        },
        {
          TaxabilityId: false,
          DeductionSubCategoryId: 12,
          name: "Box 12",
          value: "Code R Employer Contribution Only"
        },
        {
          TaxabilityId: true,
          DeductionSubCategoryId: 15,
          name: "Box 12",
          value: "Code D"
        },
        {
          TaxabilityId: true,
          DeductionSubCategoryId: 16,
          name: "Box 12",
          value: "Code D"
        },
        {
          TaxabilityId: true,
          DeductionSubCategoryId: 17,
          name: "Box 12",
          value: "Code E"
        },
        {
          TaxabilityId: true,
          DeductionSubCategoryId: 18,
          name: "Box 12",
          value: "Code E"
        },
        {
          TaxabilityId: true,
          DeductionSubCategoryId: 19,
          name: "Box 12",
          value: "Code F"
        },
        {
          TaxabilityId: true,
          DeductionSubCategoryId: 20,
          name: "Box 12",
          value: "Code S"
        },
        {
          TaxabilityId: true,
          DeductionSubCategoryId: 21,
          name: "Box 12",
          value: "Code Y"
        },
        {
          TaxabilityId: true,
          DeductionSubCategoryId: 22,
          name: "Box 12",
          value: "Code G"
        },
        {
          TaxabilityId: true,
          DeductionSubCategoryId: 23,
          name: "Box 12",
          value: "Code G"
        },
        {
          TaxabilityId: true,
          DeductionSubCategoryId: 24,
          name: "Box 12",
          value: "Code H"
        },
        {
          TaxabilityId: true,
          DeductionSubCategoryId: 25,
          name: "Box 12",
          value: "Code D"
        },
        {
          TaxabilityId: true,
          DeductionSubCategoryId: 26,
          name: "Box 12",
          value: "Code D"
        },
        {
          TaxabilityId: false,
          DeductionSubCategoryId: 27,
          name: "Box 12",
          value: "Code AA"
        },
        {
          TaxabilityId: false,
          DeductionSubCategoryId: 28,
          name: "Box 12",
          value: "Code AA"
        },
        {
          TaxabilityId: false,
          DeductionSubCategoryId: 29,
          name: "Box 12",
          value: "Code BB"
        },
        {
          TaxabilityId: false,
          DeductionSubCategoryId: 30,
          name: "Box 12",
          value: "Code BB"
        },
        {
          TaxabilityId: false,
          DeductionSubCategoryId: 31,
          name: "Box 12",
          value: "Code EE"
        },
        {
          TaxabilityId: false,
          DeductionSubCategoryId: 32,
          name: "Box 12",
          value: "Code EE"
        },
        {
          TaxabilityId: false,
          DeductionSubCategoryId: 44,
          name: "Box 12",
          value: "Code P"
        },
        {
          TaxabilityId: true,
          DeductionSubCategoryId: 46,
          name: "Box 12",
          value: "Code T"
        },
        {
          TaxabilityId: false,
          DeductionSubCategoryId: 46,
          name: "Box 12",
          value: "Code T"
        },
        {
          TaxabilityId: true,
          DeductionSubCategoryId: 54,
          name: "Box 12",
          value: "Code FF"
        },
        {
          TaxabilityId: false,
          DeductionSubCategoryId: 54,
          name: "Box 12",
          value: "Code FF"
        }
      ]
    };

    this.onChangeDeduction = this.onChangeDeduction.bind(this);
    this.onChangeTaxability = this.onChangeTaxability.bind(this);
    this.checkTaxOptions = this.checkTaxOptions.bind(this);
    this.renderTaxOptionsList = this.renderTaxOptionsList.bind(this);
    this.checkExcludeStateOptions = this.checkExcludeStateOptions.bind(this);
    this.renderExcludeStateOptionsList = this.renderExcludeStateOptionsList.bind(
      this
    );
    this.setDeductionValueContext = this.setDeductionValueContext.bind(this);
    this.close = this.close.bind(this);
    this.onCloseDeleteModal = this.onCloseDeleteModal.bind(this);
    this.onOpenDeleteModal = this.onOpenDeleteModal.bind(this);
    this.leavingCancelModal = this.leavingCancelModal.bind(this);
    this.showDeductionNamePopover = this.showDeductionNamePopover.bind(this);
    this.showTaxabilitySectionPopover = this.showTaxabilitySectionPopover.bind(
      this
    );
    this.closeTooltipDeductionName = this.closeTooltipDeductionName.bind(this);
    this.closeTooltipCategoryName = this.closeTooltipCategoryName.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeLoanNumber = this.handleChangeLoanNumber.bind(this);
    this.saveAndContinueDeductionDescription = this.saveAndContinueDeductionDescription.bind(
      this
    );
    this.redirectToSummarygPage = this.redirectToSummarygPage.bind(this);
    this.handleChangeReduceFUTAWages = this.handleChangeReduceFUTAWages.bind(
      this
    );
    this.handleChangeReduceFederalWages = this.handleChangeReduceFederalWages.bind(
      this
    );
    this.handleChangeReduceLocalWages = this.handleChangeReduceLocalWages.bind(
      this
    );
    this.handleChangeReduceMedicareWages = this.handleChangeReduceMedicareWages.bind(
      this
    );
    this.handleChangeReduceSDIWages = this.handleChangeReduceSDIWages.bind(
      this
    );
    this.handleChangeReduceSUTAWages = this.handleChangeReduceSUTAWages.bind(
      this
    );
    this.handleChangeReduceSocialSecurityWages = this.handleChangeReduceSocialSecurityWages.bind(
      this
    );
    this.handleChangeReduceStateWages = this.handleChangeReduceStateWages.bind(
      this
    );
    this.handleChangePennsylvania = this.handleChangePennsylvania.bind(this);
    this.handleChangeNewJersey = this.handleChangeNewJersey.bind(this);
    this.deleteDeduction = this.deleteDeduction.bind(this);
    this.deleteDeductionForGlobalNav = this.deleteDeductionForGlobalNav.bind(
      this
    );
    this.onOpenTrackYourProgressModal = this.onOpenTrackYourProgressModal.bind(
      this
    );
    this.onCloseTrackYourProgressModal = this.onCloseTrackYourProgressModal.bind(
      this
    );
    this.checkDeductionCategoryAndSubCategoryOption = this.checkDeductionCategoryAndSubCategoryOption.bind(
      this
    );
    this.renderTaxReportableOnW2 = this.renderTaxReportableOnW2.bind(this);
    this.onChangeTaxReportableOnW2 = this.onChangeTaxReportableOnW2.bind(this);
    this.onChangeReportedTax = this.onChangeReportedTax.bind(this);
    this.onChangeReportedWages = this.onChangeReportedWages.bind(this);

    this.setSymmetryTaxCodeContext = this.setSymmetryTaxCodeContext.bind(this);

    this.props.getCategoryDeductionList();
    this.props.getSubCategoryDeductionList();
    this.props.getTaxabilityValuesList();
    this.props.getExcludeStatesValuesList();
    this.props.getDeductionAmountOnDebitCard();
    this.props.getReportedTaxTypeList();
    this.props.getReportedWagesList();
    this.props.getSymmetryTaxCodeList();
  }

  componentWillUnmount() {
    this.props.setFormUpdate(false);
  }

  componentWillMount() {
    isInDidUpdate = true;
    if (this.state.DeductionCategoryId > 0) {
      if (this.state.SystemDeductionId > 0) {
        this.setState({
          openCategoryDropdownSection: true
        });
      }

      let event = {
        title: this.props.systemDeductionData.DeductionCategoryName,
        value: this.props.systemDeductionData.DeductionCategoryId
      };

      this.onChangeDeduction(event);
      this.setDeductionValueContext(
        this.props.systemDeductionData.DeductionSubCategoryId
      );

      this.setState({
        LoanNumber: this.props.systemDeductionData.LoanNumber
      });

      if (
        this.props.systemDeductionData !== undefined &&
        this.props.systemDeductionData !== null
      ) {
        if (
          this.props.systemDeductionData.DeductionCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_INSURANCE_ID &&
          this.props.systemDeductionData.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_HEALTH_SAVING_ACCOUNT
        ) {
          this.setState({
            opendeductionamountdebitcard: true
          });
        }
        if (
          this.props.systemDeductionData.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K_LOAN_REPAYMENT ||
          this.props.systemDeductionData.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B_LOAN_REPAYMENT
        ) {
          this.setState({
            openloannumbertext: true
          });
          let LoanEvent = {
            target: {
              name: "LoanNumber",
              value: this.props.systemDeductionData.LoanNumber
            }
          };

          this.handleChangeLoanNumber(LoanEvent);
        }

        if (
          this.props.systemDeductionData.DeductionSubCategoryId ===
          systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_INCOME_TAX_WITHHOLDING
        ) {
          this.setSymmetryTaxCodeContext(
            this.props.systemDeductionData.SymmetryTaxCodeId
          );
        }

        if (
          this.props.systemDeductionData.AdvancedTaxOption !== undefined &&
          this.props.systemDeductionData.AdvancedTaxOption !== null
        ) {
          if (
            Object.keys(this.props.systemDeductionData.AdvancedTaxOption)
              .length > 0
          ) {
            this.setState({
              TaxabilityId: true,
              openAdvanceLink: true,
              openAdvanceTaxOptions: true,
              openStateSelectionList: true
            });

            let taxabilityEvent = {
              name: "TaxabilityId",
              value: "true"
            };

            this.onChangeTaxability(taxabilityEvent);

            const AdvancedTaxOption = this.props.systemDeductionData
              .AdvancedTaxOption;

            if (
              (AdvancedTaxOption["ReduceFUTAWages"] === false ||
                AdvancedTaxOption["ReduceFUTAWages"] === undefined) &&
              (AdvancedTaxOption["ReduceFederalWages"] === false ||
                AdvancedTaxOption["ReduceFederalWages"] === undefined) &&
              (AdvancedTaxOption["ReduceLocalWages"] === false ||
                AdvancedTaxOption["ReduceLocalWages"] === undefined) &&
              (AdvancedTaxOption["ReduceMedicareWages"] === false ||
                AdvancedTaxOption["ReduceMedicareWages"] === undefined) &&
              (AdvancedTaxOption["ReduceSDIWages"] === false ||
                AdvancedTaxOption["ReduceSDIWages"] === undefined) &&
              (AdvancedTaxOption["ReduceSUTAWages"] === false ||
                AdvancedTaxOption["ReduceSUTAWages"] === undefined) &&
              (AdvancedTaxOption["ReduceSocialSecurityWages"] === false ||
                AdvancedTaxOption["ReduceSocialSecurityWages"] === undefined) &&
              (AdvancedTaxOption["ReduceStateWages"] === false ||
                AdvancedTaxOption["ReduceStateWages"] === undefined)
            ) {
              let taxabilityEvent = {
                name: "TaxabilityId",
                value: "false"
              };
              this.onChangeTaxability(taxabilityEvent);
            }

            let keyList = Object.keys(
              this.props.systemDeductionData.AdvancedTaxOption
            );
            for (let key in keyList) {
              let option = keyList[key];
              this.setState({
                [option]: this.props.systemDeductionData.AdvancedTaxOption[
                  option
                ]
              });
            }

            if (this.props.systemDeductionData.ExcludeStates) {
              this.props.systemDeductionData.ExcludeStates.forEach(item => {
                if (item.Id === PENNSYLVANIA_ID) {
                  this.setState({
                    pennsylvaniaState: true
                  });
                } else if (item.Id === NEWJERSEY_ID) {
                  this.setState({
                    newJerseyState: true
                  });
                }
              });
            }
          }
        }
      }
    }
    isInDidUpdate = false;
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.getDataById();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.systemDeductionData !== this.props.systemDeductionData) {
      isInDidUpdate = true;
      if (this.state.DeductionCategoryId !== 0) {
        if (this.state.SystemDeductionId !== 0) {
          this.setState({
            openCategoryDropdownSection: true
          });
        }

        let event = {
          title: this.props.systemDeductionData.DeductionCategoryName,
          value: this.props.systemDeductionData.DeductionCategoryId
        };

        this.onChangeDeduction(event);

        this.setDeductionValueContext(
          this.props.systemDeductionData.DeductionSubCategoryId
        );

        this.setState({
          LoanNumber: this.props.systemDeductionData.LoanNumber
        });

        if (
          this.props.systemDeductionData !== undefined &&
          this.props.systemDeductionData !== null
        ) {
          if (
            this.props.systemDeductionData.DeductionCategoryId ===
              systemConstantsId.DEDUCTION_DESCRIPTION_INSURANCE_ID &&
            this.props.systemDeductionData.DeductionSubCategoryId ===
              systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_HEALTH_SAVING_ACCOUNT
          ) {
            this.setState({
              opendeductionamountdebitcard: true
            });
          }
          if (
            this.props.systemDeductionData.DeductionSubCategoryId ===
              systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K_LOAN_REPAYMENT ||
            this.props.systemDeductionData.DeductionSubCategoryId ===
              systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B_LOAN_REPAYMENT
          ) {
            this.setState({
              openloannumbertext: true
            });
            let LoanEvent = {
              target: {
                name: "LoanNumber",
                value: this.props.systemDeductionData.LoanNumber
              }
            };

            this.handleChangeLoanNumber(LoanEvent);
          }

          if (
            this.props.systemDeductionData.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_INCOME_TAX_WITHHOLDING
          ) {
            this.setState({
              openSymmetryTaxCodeSection: true
            });
            this.setSymmetryTaxCodeContext(
              this.props.systemDeductionData.SymmetryTaxCodeId
            );
          }

          if (
            this.props.systemDeductionData.AdvancedTaxOption !== undefined &&
            this.props.systemDeductionData.AdvancedTaxOption !== null
          ) {
            if (
              Object.keys(this.props.systemDeductionData.AdvancedTaxOption)
                .length > 0
            ) {
              this.setState({
                TaxabilityId: true,
                openAdvanceLink: true,
                openAdvanceTaxOptions: false,
                openStateSelectionList: true
              });

              let taxabilityEvent = {
                name: "TaxabilityId",
                value: "true"
              };

              this.onChangeTaxability(taxabilityEvent);

              const AdvancedTaxOption = this.props.systemDeductionData
                .AdvancedTaxOption;

              if (
                (AdvancedTaxOption["ReduceFUTAWages"] === false ||
                  AdvancedTaxOption["ReduceFUTAWages"] === undefined) &&
                (AdvancedTaxOption["ReduceFederalWages"] === false ||
                  AdvancedTaxOption["ReduceFederalWages"] === undefined) &&
                (AdvancedTaxOption["ReduceLocalWages"] === false ||
                  AdvancedTaxOption["ReduceLocalWages"] === undefined) &&
                (AdvancedTaxOption["ReduceMedicareWages"] === false ||
                  AdvancedTaxOption["ReduceMedicareWages"] === undefined) &&
                (AdvancedTaxOption["ReduceSDIWages"] === false ||
                  AdvancedTaxOption["ReduceSDIWages"] === undefined) &&
                (AdvancedTaxOption["ReduceSUTAWages"] === false ||
                  AdvancedTaxOption["ReduceSUTAWages"] === undefined) &&
                (AdvancedTaxOption["ReduceSocialSecurityWages"] === false ||
                  AdvancedTaxOption["ReduceSocialSecurityWages"] ===
                    undefined) &&
                (AdvancedTaxOption["ReduceStateWages"] === false ||
                  AdvancedTaxOption["ReduceStateWages"] === undefined)
              ) {
                let taxabilityEvent = {
                  name: "TaxabilityId",
                  value: "false"
                };
                this.onChangeTaxability(taxabilityEvent);
              }

              let keyList = Object.keys(
                this.props.systemDeductionData.AdvancedTaxOption
              );
              for (let key in keyList) {
                let option = keyList[key];
                this.setState({
                  [option]: this.props.systemDeductionData.AdvancedTaxOption[
                    option
                  ]
                });
              }

              if (this.props.systemDeductionData.ExcludeStates) {
                this.props.systemDeductionData.ExcludeStates.forEach(item => {
                  if (item.Id === PENNSYLVANIA_ID) {
                    this.setState({
                      pennsylvaniaState: true
                    });
                  } else if (item.Id === NEWJERSEY_ID) {
                    this.setState({
                      newJerseyState: true
                    });
                  }
                });
              }
            }
          }
        }
      }

      isInDidUpdate = false;
      this.setState({
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
    }

    if (
      prevProps.globalNavClick === null &&
      this.props.globalNavClick !== null
    ) {
      if (this.props.globalNavClick.isLinkClick) {
        this.setState({
          showBackCancelModal: true,
          isFormUpdated: false
        });
        this.props.setFormUpdate(false);
      }
    } else {
      if (
        prevProps.globalNavClick !== null &&
        this.props.globalNavClick !== null
      ) {
        if (
          prevProps.globalNavClick.isLinkClick !==
          this.props.globalNavClick.isLinkClick
        ) {
          if (this.props.globalNavClick.isLinkClick) {
            this.setState({
              showBackCancelModal: true,
              isFormUpdated: false
            });
            this.props.setFormUpdate(false);
          }
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.systemDeductionData !== this.props.systemDeductionData) {
      this.setState({
        SystemDeductionName: nextProps.systemDeductionData.SystemDeductionName,
        DeductionCategoryName:
          nextProps.systemDeductionData.DeductionCategoryName,
        DeductionSubCategoryName:
          nextProps.systemDeductionData.DeductionSubCategoryName,
        Taxability: nextProps.systemDeductionData.Taxability,
        LoanNumber: nextProps.systemDeductionData.LoanNumber,
        AdvancedTaxOption: nextProps.systemDeductionData.AdvancedTaxOption,
        AppUIRoleId: nextProps.sessionContext.CurrentAppUiViewId,
        ExcludeStates: nextProps.systemDeductionData.ExcludeStates,
        SystemDeductionId: nextProps.systemDeductionData.SystemDeductionId,
        DeductionCategoryId: nextProps.systemDeductionData.DeductionCategoryId,
        DeductionAmountToBeDepositedOnDebitCard:
          nextProps.systemDeductionData.DeductionAmountToBeDepositedOnDebitCard,
        TaxabilityId: nextProps.systemDeductionData.Taxability,
        filteredDeductionDropdownList: null,
        openCategoryDropdownSection: true,

        ReduceFUTAWages: nextProps.systemDeductionData.ReduceFUTAWages,
        ReduceFederalWages: nextProps.systemDeductionData.ReduceFederalWages,
        ReduceLocalWages: nextProps.systemDeductionData.ReduceLocalWages,
        ReduceMedicareWages: nextProps.systemDeductionData.ReduceMedicareWages,
        ReduceSDIWages: nextProps.systemDeductionData.ReduceSDIWages,
        ReduceSUTAWages: nextProps.systemDeductionData.ReduceSUTAWages,
        ReduceSocialSecurityWages:
          nextProps.systemDeductionData.ReduceSocialSecurityWages,
        ReduceStateWages: nextProps.systemDeductionData.ReduceStateWages,

        DeductionSubCategoryId:
          nextProps.systemDeductionData.DeductionSubCategoryId,

        SymmetryTaxCodeId: nextProps.systemDeductionData.SymmetryTaxCodeId,

        SystemDeductionCode: nextProps.systemDeductionData.SystemDeductionCode,
        WageBaseCode:
          nextProps.systemDeductionData.WageBaseCode === undefined ||
          nextProps.systemDeductionData.WageBaseCode === null
            ? "0"
            : String(nextProps.systemDeductionData.WageBaseCode),

        W2StateTax_Ind:
          nextProps.systemDeductionData.W2StateTax_Ind === undefined
            ? false
            : nextProps.systemDeductionData.W2StateTax_Ind,

        W2LocalTax_Ind:
          nextProps.systemDeductionData.W2LocalTax_Ind === undefined
            ? false
            : nextProps.systemDeductionData.W2LocalTax_Ind
      });

      if (nextProps.systemDeductionData.SyatemDeductionId !== 0) {
        if (nextProps.systemDeductionData.WageBaseCode > 0) {
          this.setState({
            TaxReportableOnW2: true,
            openTaxReportableOnW2Section: true,
            openReportedTaxAndWagesSection: true,
            WageBaseCode: String(nextProps.systemDeductionData.WageBaseCode)
          });
        }

        if (
          nextProps.systemDeductionData.W2StateTax_Ind &&
          !nextProps.systemDeductionData.W2LocalTax_Ind
        ) {
          this.setState({
            ReportedTaxType: systemConstantsId.State_Income_Tax
          });
        } else if (
          !nextProps.systemDeductionData.W2StateTax_Ind &&
          nextProps.systemDeductionData.W2LocalTax_Ind
        ) {
          this.setState({
            ReportedTaxType: systemConstantsId.Local_Income_Tax
          });
        }
      }
    }
  }

  getDataById() {
    let self = this;
    let Id = this.props.match.params.SystemDeductionId;
    if (Id !== undefined) {
      const url = `${common.WEB_API_URL}/v1/system/deduction/${Id}`;
      axios
        .get(url)
        .then(function(response) {
          if (response.data.ErrorCount > 0) {
            response.data.Errors.forEach(item => {
              if (item.Tag === "") {
              }
            });
          } else {
            let deductionData = response.data.Data[0];
            self.props.setSystemDeductionData(deductionData);
          }
        })
        .catch(function(error) {});
    }
  }

  redirectToSummarygPage() {
    this.setState({ isFormUpdated: false });
    this.props.setFormUpdate(false);
    this.props.showToaster(false);
    setTimeout(
      function() {
        this.props.history.push(
          `/system-level-review-and-save/${
            this.props.systemDeductionData.SystemDeductionId
          }`
        );
      }.bind(this),
      100
    );
  }

  showW2ReducedDeduction() {
    let excludeStates = this.state.ExcludeStates;
    if (
      this.state.AdvancedTaxOption !== undefined &&
      this.state.AdvancedTaxOption !== null
    ) {
      let keyList = Object.keys(this.state.AdvancedTaxOption);
      let deductionCodesReduceMapList = this.state.deductionCodesReduceMapList;
      let w2MappingList = [];
      let newAdvanceTaxOptionList = {
        ReduceFederalWages: this.state.ReduceFederalWages,
        ReduceFUTAWages: this.state.ReduceFUTAWages,
        ReduceLocalWages: this.state.ReduceLocalWages,
        ReduceMedicareWages: this.state.ReduceMedicareWages,
        ReduceSDIWages: this.state.ReduceSDIWages,
        ReduceSUTAWages: this.state.ReduceSUTAWages,
        ReduceSocialSecurityWages: this.state.ReduceSocialSecurityWages,
        ReduceStateWages: this.state.ReduceStateWages
      };

      keyList.forEach(item => {
        if (newAdvanceTaxOptionList[item] === true) {
          deductionCodesReduceMapList.forEach(mapItem => {
            if (mapItem.key === item) {
              w2MappingList.push(mapItem);
              if (mapItem.key === "ReduceStateWages") {
                mapItem.value = "State Wages, Tips, etc.";
                if (excludeStates !== undefined && excludeStates !== null) {
                  if (excludeStates.length === 2) {
                    mapItem.value =
                      mapItem.value +
                      " (excluding Pennsylvania and New Jersey)";
                  } else if (excludeStates.length === 1) {
                    mapItem.value =
                      mapItem.value +
                      " (excluding " +
                      excludeStates[0].Name +
                      ")";
                  }
                }
              }
            }
          });
        }
      });

      if (w2MappingList !== null) {
        w2MappingList = utilities.sortByKey(w2MappingList, "displayOrder");
      }

      // eslint-disable-next-line
      return w2MappingList;
    }
  }

  renderAmountAssociatedDeductionLabel(item, index) {
    return (
      <div key={index} className="list-item">
        <i
          id="w2-summary-fa-check"
          className="fa fa-check"
          aria-hidden="true"
        />{" "}
        {item.Label}
      </div>
    );
  }

  showW2AssociatedDeduction() {
    const {
      deductionCodesAssociatedMapList,
      DeductionSubCategoryId,
      TaxabilityId
    } = this.state;
    let AssociatedAmountsList = [];
    deductionCodesAssociatedMapList.forEach(item => {
      if (
        item.DeductionSubCategoryId === DeductionSubCategoryId &&
        (item.TaxabilityId === TaxabilityId ||
          item.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K_ROTH ||
          item.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K_ROTH_CATCH_UP ||
          item.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B_ROTH ||
          item.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B_ROTH_CATCH_UP ||
          item.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_457B_ROTH ||
          item.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_457B_ROTH_CATCH_UP ||
          item.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_MOVING_EXPENSES)
      ) {
        if (item.DeductionSubCategoryId === 8) {
          if (
            this.state.DeductionAmountToBeDepositedOnDebitCard !== undefined &&
            item.DeductionAmountToBeDepositedOnDebitCard ===
              this.state.DeductionAmountToBeDepositedOnDebitCard
          ) {
            AssociatedAmountsList.push(item);
          }
        } else {
          AssociatedAmountsList.push(item);
        }
      }
    });

    if (this.state.TaxReportableOnW2) {
      if (
        String(this.state.ReportedTaxType) ===
        String(systemConstantsId.State_Income_Tax)
      ) {
        AssociatedAmountsList.push({
          displayOrder: 2,
          key: "W2StateTax_Ind",
          name: "Box 17",
          value: "State Income Tax"
        });
      } else if (
        String(this.state.ReportedTaxType) ===
        String(systemConstantsId.Local_Income_Tax)
      ) {
        AssociatedAmountsList.push({
          displayOrder: 2,
          key: "W2LocalTax_Ind",
          name: "Box 19",
          value: "Local Income Tax"
        });
      } else if (AssociatedAmountsList.length === 0) {
        AssociatedAmountsList.push({
          displayOrder: 1,
          key: "N/A",
          name: "N/A",
          value: ""
        });
      }
    }

    return AssociatedAmountsList;
  }

  isAdvanceTaxChanged(oldList, newList) {
    let isTaxUpdated = false;
    if (
      this.props.systemDeductionData !== undefined &&
      this.props.systemDeductionData !== "" &&
      this.props.systemDeductionData.length !== 0
    ) {
      const AdvancedTaxOption = this.props.systemDeductionData
        .AdvancedTaxOption;
      if (
        this.state.ReduceFUTAWages !== AdvancedTaxOption["ReduceFUTAWages"] ||
        this.state.ReduceFederalWages !==
          AdvancedTaxOption["ReduceFederalWages"] ||
        this.state.ReduceLocalWages !== AdvancedTaxOption["ReduceLocalWages"] ||
        this.state.ReduceMedicareWages !==
          AdvancedTaxOption["ReduceMedicareWages"] ||
        this.state.ReduceSDIWages !== AdvancedTaxOption["ReduceSDIWages"] ||
        this.state.ReduceSUTAWages !== AdvancedTaxOption["ReduceSUTAWages"] ||
        this.state.ReduceSocialSecurityWages !==
          AdvancedTaxOption["ReduceSocialSecurityWages"] ||
        this.state.ReduceStateWages !== AdvancedTaxOption["ReduceStateWages"]
      ) {
        isTaxUpdated = true;
      }
      const ExcludeStates = this.props.systemDeductionData.ExcludeStates;
      let pennsylvaniaState = false;
      let newJerseyState = false;
      if (ExcludeStates !== null && ExcludeStates !== undefined) {
        ExcludeStates.forEach(item => {
          if (item.Id === NEWJERSEY_ID) {
            newJerseyState = true;
          } else if (item.Id === PENNSYLVANIA_ID) {
            pennsylvaniaState = true;
          }
        });
      }
      if (
        this.state.pennsylvaniaState !== pennsylvaniaState ||
        this.state.newJerseyState !== newJerseyState
      ) {
        isTaxUpdated = true;
      }
    } else {
      let ExcludeStates = this.state.ExcludeStates;
      if (ExcludeStates !== null) {
        let pennsylvaniaState = false;
        let newJerseyState = false;
        if (
          this.state.ExcludeStates !== null &&
          this.state.ExcludeStates !== undefined
        ) {
          ExcludeStates.forEach(item => {
            if (item.Id === NEWJERSEY_ID) {
              newJerseyState = true;
            } else if (item.Id === PENNSYLVANIA_ID) {
              pennsylvaniaState = true;
            }
          });
        }

        if (
          this.state.pennsylvaniaState !== pennsylvaniaState ||
          this.state.newJerseyState !== newJerseyState
        ) {
          isTaxUpdated = true;
        }
      }
    }
    if (
      (this.props.systemDeductionData === "" ||
        this.props.systemDeductionData.length === 0) &&
      this.state.IsDefaultTaxChange
    ) {
      isTaxUpdated = true;
    }

    return isTaxUpdated;
  }

  saveAndContinueDeductionDescription(actionType, navLink) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: false,
        showFinishLaterModalWithMandatoryField: false,
        openTrackYourProgressModal2: false
      });
      this.props.setFormUpdate(false);
    }

    let redirectPageUrl = "";
    let data = { ...this.state };
    let self = this;
    let newName = "";
    let errors = {};
    data.isDraft = true;
    data.UiDraftCompletionStage = data.pageId;
    if (
      data.SystemDeductionCode !== null &&
      data.SystemDeductionCode !== "" &&
      data.SystemDeductionCode !== undefined
    ) {
      data.SystemDeductionCode = data.SystemDeductionCode.trim();
    }
    errors = validator.validateRequiredField(
      data.SystemDeductionName,
      "SystemDeductionName",
      errors
    );
    errors = validator.validateRequiredField(
      data.DeductionCategoryId,
      "DeductionCategoryId",
      errors
    );
    errors = validator.validateRequiredField(
      data.DeductionSubCategoryId,
      "DeductionSubCategoryId",
      errors
    );

    if (data.SystemDeductionName !== undefined) {
      newName = data.SystemDeductionName.trim();
      data.SystemDeductionName = newName;

      if (newName === "") {
        errors["SystemDeductionName"] = "This is a required field.";
      }
    }

    if (
      data.openTaxabilitySection === true ||
      data.openOnlyTaxabilityLinkForRetirementCategory === true
    ) {
      if (data.TaxabilityId === null || data.TaxabilityId === undefined) {
        errors = validator.validateRequiredField(
          data.TaxabilityId,
          "TaxabilityId",
          errors
        );
      }
      if (data.TaxabilityId === true) {
        if (
          this.state.ReduceFUTAWages === false &&
          this.state.ReduceFederalWages === false &&
          this.state.ReduceLocalWages === false &&
          this.state.ReduceMedicareWages === false &&
          this.state.ReduceSDIWages === false &&
          this.state.ReduceSUTAWages === false &&
          this.state.ReduceSocialSecurityWages === false &&
          this.state.ReduceStateWages === false
        ) {
          this.setState({ validateAllSelectedAdvancedTaxOption: true });
          errors["validateAllSelectedAdvancedTaxOption"] =
            "At least one of these boxes should be checked";
        }

        if (this.state.AppUIRoleId === String(1)) {
          let isAdvanceTaxListChanged = this.isAdvanceTaxChanged(
            this.props.systemDeductionData.AdvancedTaxOption,
            this.state.AdvancedTaxOption
          );
          if (
            isAdvanceTaxListChanged &&
            this.state.ReasonForChange.length === 0 &&
            this.state.taxOptionEdit
          ) {
            errors["ReasonForChange"] = "Required";
          }
        }
      }
    }

    if (
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_401K_LOAN_REPAYMENT ||
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_403B_LOAN_REPAYMENT
    ) {
      errors = validator.validateRequiredField(
        data.LoanNumber,
        "LoanNumber",
        errors
      );
      if (data.LoanNumber !== "" && data.LoanNumber !== null) {
        let loanNumber = parseInt(data.LoanNumber, 10);
        if (!(loanNumber >= 1 && loanNumber <= 12)) {
          errors["LoanNumber"] =
            "Loan Number must be a number between 1 to 12.";
        }
      }
    }

    if (
      data.DeductionSubCategoryId ===
      systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_INCOME_TAX_WITHHOLDING
    ) {
      if (
        data.TaxReportableOnW2 &&
        !data.W2LocalTax_Ind &&
        !data.W2StateTax_Ind &&
        !data.SymmetryTaxCodeId
      )
        errors["ReportedTax"] = "This is a required field.";

      if (data.TaxReportableOnW2 && data.WageBaseCode === "0") {
        errors["WageBaseCode"] = "This is a required field.";
      }
      if (data.TaxReportableOnW2 && data.WageBaseCode !== "0") {
        data.WageBaseCode = parseInt(data.WageBaseCode, 10);
      }
      if (data.SymmetryTaxCodeId === null) {
        errors["SymmetryTaxCodeId"] = "This is a required field.";
      }
    }

    if (this.state.TaxabilityId === false) {
      data.ExcludeStates = [];
    }

    if (this.checkDeductionCategoryAndSubCategoryOption(data)) {
      this.props.setLoader(false);
      let calculationMethod = "";
      if (
        data.DeductionCalculationBasisId ===
        systemConstantsId.CALCULATION_METHOD_PERCENT_ID
      ) {
        calculationMethod = "Percent";
      } else if (
        data.DeductionCalculationBasisId ===
        systemConstantsId.CALCULATION_METHOD_HOUR_BASED_ID
      ) {
        calculationMethod = "Hours";
      }
      if (
        parseInt(data.DeductionCategoryId, 10) ===
          systemConstantsId.DEDUCTION_DESCRIPTION_OTHER_ID &&
        parseInt(data.DeductionSubCategoryId, 10) !==
          systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_MISCELLANEOUS
      ) {
        errors[
          "DeductionSubCategoryId"
        ] = `The calculation method currently selected (${calculationMethod} * Disposable Earnings) is only supported for garnishment and miscellaneous deductions.`;
      } else if (
        parseInt(data.DeductionCategoryId, 10) !==
          systemConstantsId.DEDUCTION_DESCRIPTION_OTHER_ID &&
        parseInt(data.DeductionSubCategoryId, 10) !==
          systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_MISCELLANEOUS
      ) {
        errors[
          "DeductionCategoryId"
        ] = `The calculation method currently selected (${calculationMethod} * Disposable Earnings) is only supported for garnishment and miscellaneous deductions.`;
      }
      self.setState({ errors: errors });
    }

    this.setState({
      errors,
      showDeleteModal: false,
      openTrackYourProgressModal: false
    });

    let isNameChanged =
      data.SystemDeductionName !==
      this.props.systemDeductionData.SystemDeductionName;

    let newAdvanceTaxOptionList = {
      ReduceFederalWages: data.ReduceFederalWages,
      ReduceFUTAWages: data.ReduceFUTAWages,
      ReduceLocalWages: data.ReduceLocalWages,
      ReduceMedicareWages: data.ReduceMedicareWages,
      ReduceSDIWages: data.ReduceSDIWages,
      ReduceSUTAWages: data.ReduceSUTAWages,
      ReduceSocialSecurityWages: data.ReduceSocialSecurityWages,
      ReduceStateWages: data.ReduceStateWages
    };

    if (Object.keys(errors).length !== 0) {
      this.setState({
        showToaster: true,
        messageToaster: message.ERROR_ON_PAGE,
        isFormUpdated: true
      });
      this.setState({
        showBackCancelModal: false
      });
      this.props.setFormUpdate(true);
      this.props.setGlobalNavClick(false);
    } else {
      if (actionType === SAVE_AND_CONTINUE) {
        if (isInDidUpdate === false) {
          this.setState({
            isFormUpdated: false
          });
          this.props.setFormUpdate(false);
        }
        redirectPageUrl = `/system-level-calculation-method/${
          this.props.systemDeductionData.SystemDeductionId
        }`;
      } else if (actionType === SAVE_CHANGES) {
        if (isInDidUpdate === false) {
          this.setState({
            isFormUpdated: false
          });
          this.props.setFormUpdate(false);
        }
        redirectPageUrl = "/system-level-deduction-setup-listing-page";
        if (Object.keys(errors).length === 0) {
          this.props.showToaster(
            true,
            `'${
              this.state.SystemDeductionName
            }' has been saved. We’ve marked this deduction as in progress for you to work on later.`,
            "inprogress"
          );
        }
      } else if (actionType === SAVE_AND_FINISH_LATER) {
        if (isInDidUpdate === false) {
          this.setState({
            isFormUpdated: false
          });
          this.props.setFormUpdate(false);
        }
        redirectPageUrl = "/system-level-deduction-setup-listing-page";
        if (Object.keys(errors).length === 0) {
          this.props.showToaster(
            true,
            `'${
              this.state.SystemDeductionName
            }' has been saved. We’ve marked this deduction as in progress for you to work on later.`,
            "inprogress"
          );
        }
      } else if (actionType === SAVE_TO_SUMMERY_PAGE) {
        if (this.state.isFormUpdated === false) {
          redirectPageUrl = `/system-level-review-and-save/${
            this.props.match.params.SystemDeductionId
          }`;
        } else {
          redirectPageUrl = `/system-level-review-and-save/${
            this.props.match.params.SystemDeductionId
          }`;
          this.props.showToaster(
            true,
            `'${this.state.SystemDeductionName}' has been updated.`
          );
        }
        if (isInDidUpdate === false) {
          this.setState({
            isFormUpdated: false
          });
          this.props.setFormUpdate(false);
        }
      } else if (actionType === TRACK_YOUR_PROGRESS) {
        if (isInDidUpdate === false) {
          this.setState({
            isFormUpdated: false
          });
          this.props.setFormUpdate(false);
        }
        redirectPageUrl = navLink;
        if (this.props.systemDeductionData.UiDraftCompletionStage === 6) {
          this.props.showToaster(
            true,
            `'${this.state.SystemDeductionName}' has been updated.`
          );
        }
      }

      if (actionType === GLOBAL_NAV) {
        this.props.setFormUpdate(false);
        this.props.setGlobalNavClick(
          false,
          this.props.globalNavClick.LinkItem,
          this.props.globalNavClick.MenuLink
        );
        this.setState({
          showBackCancelModal: false
        });
        this.props.CallbackToQuickLink(true);
        this.props.isOverlayClickable(false);
        redirectPageUrl = "";
      }
    }

    if (Object.keys(errors).length === 0) {
      this.props.setLoader(true);
      this.setState({
        isFormUpdated: false
      });
      if (
        data.SystemDeductionId === 0 ||
        data.SystemDeductionId === undefined
      ) {
        const url = `${common.WEB_API_URL}/v1/system/deduction/action/exists`;
        axios
          .post(url, {
            Id: 0,
            Name: data.SystemDeductionName
          })
          .then(function(response) {
            if (response.data.ErrorCount > 0) {
              self.props.setLoader(false);
              response.data.Errors.forEach(item => {
                if (item.Tag === "DATA_INUSE") {
                  errors["SystemDeductionName"] = item.MessageList[0];
                  self.setState({
                    errors: errors,
                    showToaster: true,
                    messageToaster: message.ERROR_ON_PAGE,
                    isFormUpdated: true
                  });
                }
              });
            } else {
              const saveUrl = `${common.WEB_API_URL}/v1/system/deduction`;
              axios
                .post(saveUrl, data)
                .then(function(response) {
                  self.props.setLoader(false);
                  if (response.data.ErrorCount > 0) {
                    response.data.Errors.forEach((item, index) => {
                      if (item.Tag === "LOANNUMBER_INUSE ") {
                        errors["LoanNumber"] = item.MessageList[0];
                        self.setState({
                          errors: errors,
                          showToaster: true,
                          messageToaster: message.ERROR_ON_PAGE,
                          isFormUpdated: true
                        });
                      } else if (item.Tag === "DEDUCTION_CODE_EXIST") {
                        errors["SystemDeductionCode"] = item.MessageList[0];
                        self.setState({
                          errors: errors,
                          isFormUpdated: true,
                          showToaster: true,
                          messageToaster: message.ERROR_ON_PAGE
                        });
                      } else if (index === 0) {
                        alert(item.Messages);
                      }
                    });
                  } else {
                    const dataUrl = `${
                      common.WEB_API_URL
                    }/v1/system/deduction/${
                      response.data.Data[0].SystemDeductionId
                    }`;
                    axios
                      .get(dataUrl)
                      .then(function(response) {
                        if (response.data.ErrorCount > 0) {
                          response.data.Errors.forEach(item => {
                            if (item.Tag === "") {
                            }
                          });
                        } else {
                          let deductionData = response.data.Data[0];
                          self.props.setSystemDeductionData(deductionData);
                          if (actionType === SAVE_AND_CONTINUE) {
                            redirectPageUrl = `/system-level-calculation-method/${
                              response.data.Data[0].SystemDeductionId
                            }`;
                          }
                          if (redirectPageUrl !== "") {
                            self.props.history.replace(
                              `/system-level-deduction-description/${
                                response.data.Data[0].SystemDeductionId
                              }`
                            );
                            self.props.history.push(redirectPageUrl);
                          }
                        }
                      })
                      .catch(function(error) {});
                  }
                })
                .catch(function(error) {});
            }
          })
          .catch(function(error) {});
      } else {
        let UIDraftPageId = 0;

        if (
          this.props.systemDeductionData.UiDraftCompletionStage >
          this.state.pageId
        ) {
          UIDraftPageId = this.props.systemDeductionData.UiDraftCompletionStage;
        } else {
          UIDraftPageId = this.state.pageId;
        }

        let patchActions = [
          {
            Operation: "update",
            PathOrKey: "SystemDeductionName",
            Value: newName
          },
          {
            Operation: "update",
            PathOrKey: "DeductionCategoryId",
            Value: data.DeductionCategoryId
          },
          {
            Operation: "update",
            PathOrKey: "DeductionSubCategoryId",
            Value: data.DeductionSubCategoryId
          },
          {
            Operation: "update",
            PathOrKey: "DeductionSubCategoryName",
            Value: data.DeductionSubCategoryName
          },
          {
            Operation: "update",
            PathOrKey: "TaxabilityId",
            Value: data.TaxabilityId
          },
          {
            Operation: "update",
            PathOrKey: "LoanNumber",
            Value: data.LoanNumber
          },

          {
            Operation: "update",
            PathOrKey: "AdvancedTaxOption",
            Value: newAdvanceTaxOptionList
          },
          {
            Operation: "update",
            PathOrKey: "ExcludeStates",
            Value: data.ExcludeStates
          },
          {
            Operation: "update",
            PathOrKey: "ReasonForChange",
            Value: data.ReasonForChange
          },
          {
            Operation: "update",
            PathOrKey: "ReduceFUTAWages",
            Value: data.ReduceFUTAWages
          },
          {
            Operation: "update",
            PathOrKey: "ReduceFederalWages",
            Value: data.ReduceFederalWages
          },
          {
            Operation: "update",
            PathOrKey: "ReduceLocalWages",
            Value: data.ReduceLocalWages
          },
          {
            Operation: "update",
            PathOrKey: "ReduceMedicareWages",
            Value: data.ReduceMedicareWages
          },
          {
            Operation: "update",
            PathOrKey: "ReduceSDIWages",
            Value: data.ReduceSDIWages
          },
          {
            Operation: "update",
            PathOrKey: "ReduceSUTAWages",
            Value: data.ReduceSUTAWages
          },
          {
            Operation: "update",
            PathOrKey: "ReduceSocialSecurityWages",
            Value: data.ReduceSocialSecurityWages
          },
          {
            Operation: "update",
            PathOrKey: "ReduceStateWages",
            Value: data.ReduceStateWages
          },
          {
            Operation: "update",
            PathOrKey: "DeductionSubCategoryName",
            Value: data.DeductionSubCategoryName
          },
          {
            Operation: "update",
            PathOrKey: "UiDraftCompletionStage",
            Value: UIDraftPageId
          },
          {
            Operation: "update",
            PathOrKey: "DeductionCategoryName",
            Value: data.DeductionCategoryName
          },
          {
            Operation: "update",
            PathOrKey: "W2LocalTax_Ind",
            Value: data.W2LocalTax_Ind
          },
          {
            Operation: "update",
            PathOrKey: "W2StateTax_Ind",
            Value: data.W2StateTax_Ind
          },
          {
            Operation: "update",
            PathOrKey: "WageBaseCode",
            Value: parseInt(data.WageBaseCode, 10)
          },
          {
            Operation: "update",
            PathOrKey: "SymmetryTaxCodeId",
            Value: parseInt(data.SymmetryTaxCodeId, 10)
          }
        ];
        const url = `${common.WEB_API_URL}/v1/system/deduction/action/exists`;

        axios
          .post(url, {
            Id: data.SystemDeductionId,
            Name: data.SystemDeductionName
          })
          .then(function(response) {
            if (response.data.ErrorCount > 0 && isNameChanged) {
              self.props.setLoader(false);
              response.data.Errors.forEach(item => {
                if (item.Tag === "DATA_INUSE") {
                  errors["SystemDeductionName"] = item.MessageList[0];
                  self.setState({
                    errors: errors,
                    showToaster: true,
                    messageToaster: message.ERROR_ON_PAGE,
                    isFormUpdated: true
                  });
                }
              });
            } else {
              const patchUrl = `${common.WEB_API_URL}/v1/system/deduction/${
                data.SystemDeductionId
              }`;
              axios
                .patch(patchUrl, patchActions)
                .then(function(response) {
                  self.props.setLoader(false);
                  if (response.data.ErrorCount > 0) {
                    response.data.Errors.forEach((item, index) => {
                      if (item.Tag === "LOANNUMBER_INUSE ") {
                        errors["LoanNumber"] = item.MessageList[1];
                        self.setState({
                          errors: errors,
                          showToaster: true,
                          messageToaster: message.ERROR_ON_PAGE,
                          isFormUpdated: true
                        });
                      } else if (index === 0) {
                        alert(item.Messages);
                      }
                    });
                  } else {
                    let deductionData = response.data.Data[0];
                    self.props.setSystemDeductionData(deductionData);
                    if (actionType === GLOBAL_NAV) {
                      self.props.setFormUpdate(false);
                      self.props.CallbackToQuickLink(true);
                    } else {
                      self.props.history.push(redirectPageUrl);
                    }
                  }
                })
                .catch(function(error) {});
            }
          })
          .catch(function(error) {});
      }
    }
  }

  onOpenFinishLaterButtonModal = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let errorCount = this.validateRequiredField();
    if (errorCount === 0) {
      this.setState({
        showFinishLaterModalWithMandatoryField: true,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
    } else {
      this.setState({
        showFinishLaterModalWithoutMandatoryField: true,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
    }
  };

  exitSetup = (actionType, navLink) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (actionType === "EXIT_SETUP") {
      this.setState({
        showFinishLaterModalWithoutMandatoryField: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.history.push("/system-level-deduction-setup-listing-page");
    } else if (actionType === "LEAVE_THIS_PAGE") {
      this.setState({
        openTrackYourProgressModal: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.history.push(navLink);
    } else if (actionType === "GLOBAL_NAV") {
      this.setState({
        showBackCancelModal: false,
        isFormUpdated: false
      });
      this.props.setFormUpdate(false);
      this.props.setGlobalNavClick(
        false,
        this.props.globalNavClick.LinkItem,
        this.props.globalNavClick.MenuLink
      );
      this.props.CallbackToQuickLink(true);
    }
  };

  onCloseFinishLaterModal = () => {
    this.setState({
      isFormUpdated: true,
      showFinishLaterModalWithoutMandatoryField: false,
      showFinishLaterModalWithMandatoryField: false
    });
    this.props.setFormUpdate(true);
    this.props.setGlobalNavClick(false);
  };

  validateRequiredField = () => {
    let errors = {};
    let data = this.state;
    let newName = "";

    errors = validator.validateRequiredField(
      data.SystemDeductionName,
      "SystemDeductionName",
      errors
    );
    errors = validator.validateRequiredField(
      data.DeductionCategoryId,
      "DeductionCategoryId",
      errors
    );
    errors = validator.validateRequiredField(
      data.DeductionSubCategoryId,
      "DeductionSubCategoryId",
      errors
    );

    if (data.SystemDeductionName !== undefined) {
      newName = data.SystemDeductionName.trim();
      data.SystemDeductionName = newName;

      if (newName === "") {
        errors["SystemDeductionName"] = "This is a required field.";
      }
    }

    if (data.openTaxabilitySection === true) {
      if (data.TaxabilityId === null || data.TaxabilityId === undefined) {
        errors = validator.validateRequiredField(
          data.TaxabilityId,
          "TaxabilityId",
          errors
        );
      }
      if (data.TaxabilityId === true) {
        if (
          this.state.ReduceFUTAWages === false &&
          this.state.ReduceFederalWages === false &&
          this.state.ReduceLocalWages === false &&
          this.state.ReduceMedicareWages === false &&
          this.state.ReduceSDIWages === false &&
          this.state.ReduceSUTAWages === false &&
          this.state.ReduceSocialSecurityWages === false &&
          this.state.ReduceStateWages === false
        ) {
          errors["validateAllSelectedAdvancedTaxOption"] =
            "At least one of these boxes should be checked";
        }

        if (this.state.AppUIRoleId === String(1)) {
          let isAdvanceTaxListChanged = this.isAdvanceTaxChanged(
            this.props.systemDeductionData.AdvancedTaxOption,
            this.state.AdvancedTaxOption
          );

          if (
            isAdvanceTaxListChanged &&
            this.state.IsDefaultTaxChange &&
            this.state.ReasonForChange.length === 0
          ) {
            errors["ReasonForChange"] = "Required";
          }
        }
      }
    }

    if (
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_401K_LOAN_REPAYMENT ||
      data.DeductionSubCategoryId ===
        systemConstantsId.DEDUCTION_DESCRIPTION_403B_LOAN_REPAYMENT
    ) {
      errors = validator.validateRequiredField(
        data.LoanNumber,
        "LoanNumber",
        errors
      );
      if (isNaN(data.LoanNumber)) {
        errors["LoanNumber"] = "Loan Number must be a number between 1 to 12.";
      } else {
        let loanNumber = parseInt(data.LoanNumber, 10);
        if (!(loanNumber >= 1 && loanNumber <= 12)) {
          errors["LoanNumber"] =
            "Loan Number must be a number between 1 to 12.";
        }
      }
    }

    if (
      data.DeductionSubCategoryId ===
      systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_INCOME_TAX_WITHHOLDING
    ) {
      if (
        data.TaxReportableOnW2 &&
        !data.W2LocalTax_Ind &&
        !data.W2StateTax_Ind
      )
        errors["ReportedTax"] = "This is a required field.";

      if (data.TaxReportableOnW2 && data.WageBaseCode === "0") {
        errors["WageBaseCode"] = "This is a required field.";
      }
    }

    if (this.checkDeductionCategoryAndSubCategoryOption(data)) {
      this.props.setLoader(false);
      let calculationMethod = "";
      if (
        data.DeductionCalculationBasisId ===
        systemConstantsId.CALCULATION_METHOD_PERCENT_ID
      ) {
        calculationMethod = "Percent";
      } else if (
        data.DeductionCalculationBasisId ===
        systemConstantsId.CALCULATION_METHOD_HOUR_BASED_ID
      ) {
        calculationMethod = "Hours";
      }
      if (
        parseInt(data.DeductionCategoryId, 10) ===
          systemConstantsId.DEDUCTION_DESCRIPTION_OTHER_ID &&
        parseInt(data.DeductionSubCategoryId, 10) !==
          systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_MISCELLANEOUS
      ) {
        errors[
          "DeductionSubCategoryId"
        ] = `The calculation method currently selected (${calculationMethod} * Disposable Earnings) is only supported for garnishment and miscellaneous deductions.`;
      } else if (
        parseInt(data.DeductionCategoryId, 10) !==
          systemConstantsId.DEDUCTION_DESCRIPTION_OTHER_ID &&
        parseInt(data.DeductionSubCategoryId, 10) !==
          systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_MISCELLANEOUS
      ) {
        errors[
          "DeductionCategoryId"
        ] = `The calculation method currently selected (${calculationMethod} * Disposable Earnings) is only supported for garnishment and miscellaneous deductions.`;
      }
    }

    return Object.keys(errors).length;
  };

  checkDeductionCategoryAndSubCategoryOption(data) {
    let deductionCategoryId = parseInt(data.DeductionCategoryId, 10);
    let deductionCalculationBasisId = parseInt(
      data.DeductionCalculationBasisId,
      10
    );
    let EarningGroupId = parseInt(data.EarningGroupId, 10);
    if (
      deductionCategoryId !==
      systemConstantsId.DEDUCTION_DESCRIPTION_GARNISHMENT_ID
    ) {
      if (
        (deductionCalculationBasisId ===
          systemConstantsId.CALCULATION_METHOD_HOUR_BASED_ID ||
          deductionCalculationBasisId ===
            systemConstantsId.CALCULATION_METHOD_PERCENT_ID) &&
        EarningGroupId ===
          systemConstantsId.CALCULATION_METHOD_DISPOSABLE_EARNINGS_ID
      ) {
        return true;
      }
    }

    return false;
  }

  deleteDeduction() {
    var self = this;
    this.setState({
      isFormUpdated: false,
      showDeleteModal: false
    });
    this.props.setFormUpdate(false);
    self.props.setLoader(true);
    if (this.state.SystemDeductionId === undefined) {
      self.props.setLoader(false);
      this.props.showToaster(false, "");
      this.props.history.push("/system-level-deduction-setup-listing-page");
    } else {
      const url = `${common.WEB_API_URL}/v1/system/deduction/${
        this.props.systemDeductionData.SystemDeductionId
      }`;
      axios
        .delete(url)
        .then(function(response) {
          self.props.setLoader(false);
          if (response.data.ErrorCount > 0) {
            self.setState({
              isFormUpdated: true
            });
            this.props.setFormUpdate(true);
            response.data.Errors.forEach((item, index) => {
              if (index === 0) {
                alert(item.Messages);
              }
            });
          } else {
            self.props.showToaster(
              true,
              `'${
                self.props.systemDeductionData.SystemDeductionName
              }' has been deleted.`,
              "delete"
            );
            self.props.history.push(
              "/system-level-deduction-setup-listing-page"
            );
          }
        })
        .catch(function(error) {});
    }
  }

  deleteDeductionForGlobalNav() {
    var self = this;
    this.setState({
      isFormUpdated: false,
      showDeleteModal: false
    });
    this.props.setFormUpdate(false);
    self.props.setLoader(true);
    if (this.state.SystemDeductionId === undefined) {
      self.props.setLoader(false);
      self.props.showToaster(false, "");

      self.props.setGlobalNavClick(
        false,
        self.props.globalNavClick.LinkItem,
        self.props.globalNavClick.MenuLink
      );
      self.props.CallbackToQuickLink(true);
      self.props.isOverlayClickable(false);
    } else {
      self.setState({
        isFormUpdated: false
      });
      const url = `${common.WEB_API_URL}/v1/system/deduction/${
        this.props.systemDeductionData.SystemDeductionId
      }`;
      axios
        .delete(url)
        .then(function(response) {
          self.props.setLoader(false);
          if (response.data.ErrorCount > 0) {
            self.setState({
              isFormUpdated: true
            });
            this.props.setFormUpdate(true);
            response.data.Errors.forEach((item, index) => {
              if (index === 0) {
                alert(item.Messages);
              }
            });
          } else {
            self.props.setFormUpdate(false);
            self.props.setGlobalNavClick(
              false,
              self.props.globalNavClick.LinkItem,
              self.props.globalNavClick.MenuLink
            );
            self.props.CallbackToQuickLink(true);
            self.props.isOverlayClickable(false);
          }
        })
        .catch(function(error) {});
    }
  }

  handleChangeName(event) {
    if (event.target.value.length <= 35) {
      this.setState({
        [event.target.name]: event.target.value
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
      let errors = this.state.errors;
      delete errors["SystemDeductionName"];
      this.setState({
        errors
      });
    }
  }

  handleChangeLoanNumber(event) {
    const re = /^[0-9\b]+$/;
    let errors = this.state.errors;
    delete errors["LoanNumber"];
    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
    this.setState({
      errors
    });
    if (event.target.value == null || re.test(event.target.value)) {
      this.setState({
        LoanNumber: event.target.value
      });
    } else {
      this.setState({
        LoanNumber: ""
      });
    }
  }

  getSubCategoryFilteredList(filterValue) {
    let filteredDeductionDropdownList = [];
    if (
      this.props.subCategoryDeductionList !== null &&
      this.props.subCategoryDeductionList !== undefined &&
      this.props.subCategoryDeductionList.Data !== undefined
    ) {
      this.props.subCategoryDeductionList.Data.forEach(item => {
        if (
          String(item.DeductionCategoryId) === String(filterValue) &&
          item.IsActive === true
        ) {
          filteredDeductionDropdownList.push({
            value: item.DeductionSubCategoryId,
            label: item.LongName
          });
        }
      });
    }

    return filteredDeductionDropdownList;
  }

  onChangeDeduction(event, value = 0) {
    let selectedDeductionCategoryName = "";
    let update = {};
    let filteredDeductionDropdownList = [];

    let errors = this.state.errors;
    delete errors["DeductionCategoryId"];
    delete errors["DeductionSubCategoryId"];
    delete errors["LoanNumber"];
    delete errors["validateAllSelectedAdvancedTaxOption"];
    delete errors["ReasonForChange"];
    selectedDeductionCategoryName = event.title;

    this.setState({
      DeductionCategoryName: selectedDeductionCategoryName,
      errors,
      LoanNumber: null,
      IsDefaultTaxChange: false,
      validateAllSelectedAdvancedTaxOption: false,
      taxOptionEdit: false
    });

    if (isInDidUpdate === false) {
      this.setState({
        DeductionAmountToBeDepositedOnDebitCard: null
      });
    }

    this.setState({
      selectedDeductionTypeLabel: event.title,
      selectedDeductionTypeValue: event.value
    });

    this.setState({
      DeductionSubCategoryId: null
    });

    if (this.props.systemDeductionData.SystemDeductionId !== 0) {
      if (this.props.systemDeductionData.DeductionCategoryId !== event.value) {
        this.setState({
          openTaxReportableOnW2Section: false,
          openReportedTaxAndWagesSection: false,
          TaxReportableOnW2: false,
          W2LocalTax_Ind: false,
          W2StateTax_Ind: false,
          WageBaseCode: "0",
          ReportedTaxType: "0",
          openSymmetryTaxCodeSection: false,
          SymmetryTaxCodeId: null
        });
      } else {
        if (this.state.TaxReportableOnW2) {
          this.setState({
            openTaxReportableOnW2Section: true,
            openReportedTaxAndWagesSection: true,
            openSymmetryTaxCodeSection: false,
            SymmetryTaxCodeId: null
          });
        } else {
          this.setState({
            openTaxReportableOnW2Section: false,
            openReportedTaxAndWagesSection: false,
            TaxReportableOnW2: false,
            W2LocalTax_Ind: false,
            W2StateTax_Ind: false,
            WageBaseCode: "0",
            ReportedTaxType: "0",
            openSymmetryTaxCodeSection: false,
            SymmetryTaxCodeId: null
          });
        }
      }
    } else {
      this.setState({
        openTaxReportableOnW2Section: false,
        openReportedTaxAndWagesSection: false,
        TaxReportableOnW2: false,
        W2LocalTax_Ind: false,
        W2StateTax_Ind: false,
        WageBaseCode: "0",
        ReportedTaxType: "0",
        openSymmetryTaxCodeSection: false,
        SymmetryTaxCodeId: null
      });
    }

    update[event.name] = event.value;

    this.setState(update);

    filteredDeductionDropdownList = this.getSubCategoryFilteredList(
      event.value
    );

    this.setState({
      filteredDeductionDropdownList: filteredDeductionDropdownList,
      showNewJerseyState: true
    });

    if (event.value === String(systemConstantsId.DEDUCTION_INSURANCE_ID)) {
      let AdvancedTaxOption = {
        ReduceFUTAWages: true,
        ReduceFederalWages: true,
        ReduceLocalWages: true,
        ReduceMedicareWages: true,
        ReduceSDIWages: true,
        ReduceSUTAWages: true,
        ReduceSocialSecurityWages: true,
        ReduceStateWages: true
      };
      this.setState({
        openTaxabilitySection: false,
        openStateSelectionList: true,
        AdvancedTaxOption,
        taxabilitypretaxradio: false,
        openloannumbertext: false,
        openCategoryDropdownSection: true,
        openOnlyTaxabilityLinkForRetirementCategory: false,
        readonlyNewJerseyState: false,
        newJerseyState: false,
        readonlyPennsylvaniaState: false,
        pennsylvaniaState: false
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
    } else if (
      event.value ===
      String(systemConstantsId.DEDUCTION_DESCRIPTION_RETIREMENT_ID)
    ) {
      let AdvancedTaxOption = {
        ReduceFUTAWages: false,
        ReduceFederalWages: true,
        ReduceLocalWages: true,
        ReduceMedicareWages: false,
        ReduceSDIWages: false,
        ReduceSUTAWages: false,
        ReduceSocialSecurityWages: false,
        ReduceStateWages: true
      };
      this.setState({
        openTaxabilitySection: false,
        openStateSelectionList: true,
        AdvancedTaxOption,
        opendeductionamountdebitcard: false,
        openloannumbertext: false,
        openCategoryDropdownSection: true,
        openOnlyTaxabilityLinkForRetirementCategory: false,
        showNewJerseyState: false
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
    } else if (
      event.value ===
      String(systemConstantsId.DEDUCTION_DESCRIPTION_RETIREMENT_LOAN_ID)
    ) {
      let AdvancedTaxOption = {
        ReduceFUTAWages: false,
        ReduceFederalWages: false,
        ReduceLocalWages: false,
        ReduceMedicareWages: false,
        ReduceSDIWages: false,
        ReduceSUTAWages: false,
        ReduceSocialSecurityWages: false,
        ReduceStateWages: false
      };

      this.setState({
        openTaxabilitySection: false,
        openStateSelectionList: false,
        opendeductionamountdebitcard: false,
        openOnlyTaxabilityLinkForRetirementCategory: false,
        openCategoryDropdownSection: true,

        TaxabilityId: false,
        ReduceFUTAWages: false,
        ReduceFederalWages: false,
        ReduceLocalWages: false,
        ReduceMedicareWages: false,
        ReduceSDIWages: false,
        ReduceSUTAWages: false,
        ReduceSocialSecurityWages: false,
        ReduceStateWages: false,
        readonlyNewJerseyState: false,
        newJerseyState: false,
        readonlyPennsylvaniaState: false,
        pennsylvaniaState: false,
        AdvancedTaxOption
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
    } else if (
      event.value ===
      String(systemConstantsId.DEDUCTION_DESCRIPTION_GARNISHMENT_ID)
    ) {
      let AdvancedTaxOption = {
        ReduceFUTAWages: false,
        ReduceFederalWages: false,
        ReduceLocalWages: false,
        ReduceMedicareWages: false,
        ReduceSDIWages: false,
        ReduceSUTAWages: false,
        ReduceSocialSecurityWages: false,
        ReduceStateWages: false
      };

      this.setState({
        openTaxabilitySection: false,
        openStateSelectionList: false,
        opendeductionamountdebitcard: false,
        openloannumbertext: false,
        openCategoryDropdownSection: true,
        openOnlyTaxabilityLinkForRetirementCategory: false,

        TaxabilityId: false,
        ReduceFUTAWages: false,
        ReduceFederalWages: false,
        ReduceLocalWages: false,
        ReduceMedicareWages: false,
        ReduceSDIWages: false,
        ReduceSUTAWages: false,
        ReduceSocialSecurityWages: false,
        ReduceStateWages: false,
        readonlyNewJerseyState: false,
        newJerseyState: false,
        readonlyPennsylvaniaState: false,
        pennsylvaniaState: false,
        AdvancedTaxOption
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
    } else if (
      event.value === String(systemConstantsId.DEDUCTION_DESCRIPTION_OTHER_ID)
    ) {
      let AdvancedTaxOption = {
        ReduceFUTAWages: true,
        ReduceFederalWages: true,
        ReduceLocalWages: true,
        ReduceMedicareWages: true,
        ReduceSDIWages: true,
        ReduceSUTAWages: true,
        ReduceSocialSecurityWages: true,
        ReduceStateWages: true
      };
      this.setState({
        ReduceFUTAWages: true,
        ReduceFederalWages: true,
        ReduceLocalWages: true,
        ReduceMedicareWages: true,
        ReduceSDIWages: true,
        ReduceSUTAWages: true,
        ReduceSocialSecurityWages: true,
        ReduceStateWages: true,
        openTaxabilitySection: false,
        openAdvanceLink: false,
        openStateSelectionList: false,
        AdvancedTaxOption,
        opendeductionamountdebitcard: false,
        openloannumbertext: false,
        openCategoryDropdownSection: true,
        openOnlyTaxabilityLinkForRetirementCategory: false
      });
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
    }
  }

  onChangeTaxability(event, value = 0) {
    let update = {};
    let selectedButton = event.value === "true";
    update[event.name] = selectedButton;
    let errors = this.state.errors;
    let ExcludeStates = [];
    delete errors["TaxabilityId"];
    delete errors["ReasonForChange"];
    this.setState({
      errors
    });

    this.setState(update);

    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }

    if (selectedButton) {
      if (
        String(this.state.DeductionCategoryId) ===
        String(systemConstantsId.DEDUCTION_DESCRIPTION_INSURANCE_ID)
      ) {
        this.setState({
          ReduceFUTAWages: true,
          ReduceFederalWages: true,
          ReduceLocalWages: true,
          ReduceMedicareWages: true,
          ReduceSDIWages: true,
          ReduceSUTAWages: true,
          ReduceSocialSecurityWages: true,
          ReduceStateWages: true,
          readonlyNewJerseyState: false,
          openAdvanceTaxOptions: false
        });

        if (
          this.props.systemDeductionData === undefined ||
          this.props.systemDeductionData === ""
        ) {
          this.setState({
            newJerseyState: true,
            pennsylvaniaState: false
          });

          ExcludeStates = [
            {
              Id: NEWJERSEY_ID,
              Name: "New Jersey"
            }
          ];
        } else {
          if (
            this.props.systemDeductionData.DeductionCategoryId ===
              this.state.DeductionCategoryId &&
            this.props.systemDeductionData.DeductionSubCategoryId ===
              this.state.DeductionSubCategoryId &&
            this.props.systemDeductionData.Taxability === true &&
            isInDidUpdate
          ) {
            ExcludeStates = this.props.systemDeductionData.ExcludeStates;
          } else {
            this.setState({
              newJerseyState: true,
              pennsylvaniaState: false
            });
            ExcludeStates = [
              {
                Id: NEWJERSEY_ID,
                Name: "New Jersey"
              }
            ];
          }
        }

        if (
          ExcludeStates === null &&
          selectedButton === true &&
          !isInDidUpdate
        ) {
          this.setState({
            newJerseyState: true,
            pennsylvaniaState: false
          });
          ExcludeStates = [
            {
              Id: NEWJERSEY_ID,
              Name: "New Jersey"
            }
          ];
        }

        let AdvancedTaxOption = {
          ReduceFUTAWages: true,
          ReduceFederalWages: true,
          ReduceLocalWages: true,
          ReduceMedicareWages: true,
          ReduceSDIWages: true,
          ReduceSUTAWages: true,
          ReduceSocialSecurityWages: true,
          ReduceStateWages: true
        };

        this.setState({
          AdvancedTaxOption,
          ExcludeStates,
          openTaxabilitySection: true,
          openAdvanceLink: true,
          openStateSelectionList: true
        });

        if (
          String(this.state.DeductionSubCategoryId) ===
            String(
              systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_DEPENDANT_CARE
            ) ||
          String(this.state.DeductionSubCategoryId) ===
            String(
              systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_DEPENDANT_CARE_FLEXIBLE_SPENDING_ACCOUNT_FSA
            )
        ) {
          let ExcludeStates = [
            {
              Id: PENNSYLVANIA_ID,
              Name: "Pennsylvania"
            },
            {
              Id: NEWJERSEY_ID,
              Name: "New Jersey"
            }
          ];
          this.setState({
            readonlyPennsylvaniaState: false,
            pennsylvaniaState: true,
            readonlyNewJerseyState: false,
            newJerseyState: true,
            ExcludeStates,
            openTaxabilitySection: true,
            openAdvanceLink: true,
            openStateSelectionList: true,
            openAdvanceTaxOptions: false
          });
        }
      } else if (
        String(this.state.DeductionCategoryId) ===
        String(systemConstantsId.DEDUCTION_DESCRIPTION_RETIREMENT_ID)
      ) {
        this.setState({
          ReduceFederalWages: true,
          ReduceLocalWages: true,
          ReduceMedicareWages: false,
          ReduceSDIWages: false,
          ReduceSUTAWages: false,
          ReduceSocialSecurityWages: false,
          ReduceStateWages: true,
          readonlyNewJerseyState: false,
          newJerseyState: false,
          readonlyPennsylvaniaState: true,
          pennsylvaniaState: true
        });

        let AdvancedTaxOption = {
          ReduceFederalWages: true,
          ReduceLocalWages: true,
          ReduceStateWages: true
        };

        this.setState({
          AdvancedTaxOption,
          openTaxabilitySection: false,
          openOnlyTaxabilityLinkForRetirementCategory: true,
          openAdvanceLink: true,
          openStateSelectionList: true,
          openAdvanceTaxOptions: false,
          opendeductionamountdebitcard: false,
          openloannumbertext: false,
          openCategoryDropdownSection: true,
          showNewJerseyState: false
        });

        if (
          String(this.state.DeductionSubCategoryId) ===
            String(
              systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_DEFERRED_COMPENSATION
            ) ||
          String(this.state.DeductionSubCategoryId) ===
            String(
              systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_STATE_EMPLOYEE_RETIREMENT_SYSTEM_WITHOLDING
            )
        ) {
          this.setState({
            ReduceFederalWages: true,
            ReduceLocalWages: true,
            ReduceMedicareWages: false,
            ReduceSDIWages: false,
            ReduceSUTAWages: false,
            ReduceSocialSecurityWages: false,
            ReduceStateWages: true,
            readonlyNewJerseyState: false,
            newJerseyState: false,
            readonlyPennsylvaniaState: true,
            pennsylvaniaState: true
          });
          let AdvancedTaxOption = {
            ReduceFederalWages: true,
            ReduceLocalWages: true,
            ReduceStateWages: true
          };

          this.setState({
            AdvancedTaxOption,
            openTaxabilitySection: true,
            openOnlyTaxabilityLinkForRetirementCategory: false,
            openAdvanceLink: true,
            openStateSelectionList: true,
            openAdvanceTaxOptions: false,
            showNewJerseyState: false
          });
        }

        if (
          this.state.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K_ROTH ||
          this.state.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K_ROTH_CATCH_UP ||
          this.state.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B_ROTH ||
          this.state.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B_ROTH_CATCH_UP ||
          this.state.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_457B_ROTH ||
          this.state.DeductionSubCategoryId ===
            systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_457B_ROTH_CATCH_UP
        ) {
          this.setState({
            openOnlyTaxabilityLinkForRetirementCategory: false
          });
        }
      } else if (
        String(this.state.DeductionCategoryId) ===
        String(systemConstantsId.DEDUCTION_DESCRIPTION_OTHER_ID)
      ) {
        this.setState({
          ReduceFUTAWages: true,
          ReduceFederalWages: true,
          ReduceLocalWages: true,
          ReduceMedicareWages: true,
          ReduceSDIWages: true,
          ReduceSUTAWages: true,
          ReduceSocialSecurityWages: true,
          ReduceStateWages: true,
          readonlyNewJerseyState: false,
          readonlyPennsylvaniaState: false,
          openAdvanceTaxOptions: false
        });

        let ExcludeStatesForOther = [];
        if (
          this.state.SystemDeductionId === undefined ||
          this.state.SystemDeductionId === 0
        ) {
          this.setState({
            newJerseyState: true,
            pennsylvaniaState: false
          });

          ExcludeStatesForOther = [
            {
              Id: NEWJERSEY_ID,
              Name: "New Jersey"
            }
          ];
        } else {
          if (
            this.props.systemDeductionData.DeductionCategoryId ===
              systemConstantsId.DEDUCTION_DESCRIPTION_OTHER_ID &&
            this.props.systemDeductionData.Taxability === true &&
            isInDidUpdate
          ) {
            ExcludeStatesForOther = this.props.systemDeductionData
              .ExcludeStates;
          } else {
            this.setState({
              newJerseyState: true,
              pennsylvaniaState: false
            });
            ExcludeStatesForOther = [
              {
                Id: NEWJERSEY_ID,
                Name: "New Jersey"
              }
            ];
          }
        }

        let AdvancedTaxOption = {
          ReduceFUTAWages: true,
          ReduceFederalWages: true,
          ReduceLocalWages: true,
          ReduceMedicareWages: true,
          ReduceSDIWages: true,
          ReduceSUTAWages: true,
          ReduceSocialSecurityWages: true,
          ReduceStateWages: true
        };

        this.setState({
          AdvancedTaxOption,
          openTaxabilitySection: true,
          openAdvanceLink: true,
          openStateSelectionList: true,
          openAdvanceTaxOptions: false,
          ExcludeStates: ExcludeStatesForOther
        });

        if (
          this.state.DeductionSubCategoryId ===
          systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_MOVING_EXPENSES
        ) {
          this.setState({
            openTaxabilitySection: false
          });
        } else if (
          this.state.DeductionSubCategoryId ===
          systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_INCOME_TAX_WITHHOLDING
        ) {
          this.setState({
            openTaxabilitySection: false
          });
        }
      }
    } else {
      let AdvancedTaxOption = {
        ReduceFUTAWages: false,
        ReduceFederalWages: false,
        ReduceLocalWages: false,
        ReduceMedicareWages: false,
        ReduceSDIWages: false,
        ReduceSUTAWages: false,
        ReduceSocialSecurityWages: false,
        ReduceStateWages: false
      };
      this.setState({
        ReduceFUTAWages: false,
        ReduceFederalWages: false,
        ReduceLocalWages: false,
        ReduceMedicareWages: false,
        ReduceSDIWages: false,
        ReduceSUTAWages: false,
        ReduceSocialSecurityWages: false,
        ReduceStateWages: false,
        readonlyNewJerseyState: false,
        newJerseyState: false,
        readonlyPennsylvaniaState: false,
        pennsylvaniaState: false,
        AdvancedTaxOption,
        openAdvanceLink: false,
        openAdvanceTaxOptions: false,
        openStateSelectionList: false,
        taxOptionEdit: false
      });
    }
  }

  setDeductionValueContext(newValue) {
    let selectedSubCategoryName = "";
    let errors = this.state.errors;
    delete errors["DeductionSubCategoryId"];
    delete errors["validateAllSelectedAdvancedTaxOption"];
    delete errors["ReasonForChange"];
    this.setState({
      errors,
      validateAllSelectedAdvancedTaxOption: false,
      IsDefaultTaxChange: false,
      taxOptionEdit: false
    });

    this.setState({
      openOnlyTaxabilityLinkForRetirementCategory: false,
      openAdvanceTaxOptions: false,
      openSymmetryTaxCodeSection: false,
      SymmetryTaxCodeId: null
    });

    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true,
        LoanNumber: null,
        DeductionAmountToBeDepositedOnDebitCard: null
      });
      this.props.setFormUpdate(true);
    }

    if (
      this.props.subCategoryDeductionList !== undefined &&
      this.props.subCategoryDeductionList !== null &&
      this.props.subCategoryDeductionList.Data !== undefined
    ) {
      this.props.subCategoryDeductionList.Data.forEach(item => {
        if (item.DeductionSubCategoryId === newValue) {
          selectedSubCategoryName = item.Name;
          this.setState({
            DeductionSubCategoryName: selectedSubCategoryName
          });
        }
      });
    }

    this.setState({ DeductionSubCategoryId: newValue });
    if (
      newValue ===
      systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_HEALTH_SAVING_ACCOUNT
    ) {
      let ExcludeStatesHealthSavinigAccount = [
        {
          Id: NEWJERSEY_ID,
          Name: "New Jersey"
        }
      ];
      this.setState({
        opendeductionamountdebitcard: true,
        openTaxabilitySection: true,
        openOnlyTaxabilityLinkForRetirementCategory: false,
        TaxabilityId: null,
        openAdvanceLink: false,
        openAdvanceTaxOptions: false,
        ExcludeStates: ExcludeStatesHealthSavinigAccount
      });
    } else if (
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_ARCHER_MEDICAL_SAVINGS_ACCOUNT_MSA ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_DENTAL ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_DEPENDANT_CARE ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_DEPENDANT_CARE_FLEXIBLE_SPENDING_ACCOUNT_FSA ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_HEALTH_CARE_FLEXIBLE_SPENDING_ACCOUNT_FSA ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_HEALTH_REIMBURSEMENT_ARRANGEMENT_HRA ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_QSEHRA ||
      newValue === systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_LIFE ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_LONG_TERM_DISABILITY ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_MEDICAL ||
      newValue === systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_OTHER ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_SHORT_TERM_DISABILITY ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_SUPPLEMENTAL_INSURANCE ||
      newValue === systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_VISION
    ) {
      let AdvancedTaxOption = {
        ReduceFUTAWages: true,
        ReduceFederalWages: true,
        ReduceLocalWages: true,
        ReduceMedicareWages: true,
        ReduceSDIWages: true,
        ReduceSUTAWages: true,
        ReduceSocialSecurityWages: true,
        ReduceStateWages: true
      };
      this.setState({
        openTaxabilitySection: true,
        opendeductionamountdebitcard: false,
        openOnlyTaxabilityLinkForRetirementCategory: false,
        TaxabilityId: null,
        openAdvanceLink: false,
        AdvancedTaxOption,
        openAdvanceTaxOptions: false
      });
      if (
        newValue ===
          systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_DEPENDANT_CARE ||
        newValue ===
          systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_DEPENDANT_CARE_FLEXIBLE_SPENDING_ACCOUNT_FSA
      ) {
        let ExcludeStates = [
          {
            Id: PENNSYLVANIA_ID,
            Name: "Pennsylvania"
          },
          {
            Id: NEWJERSEY_ID,
            Name: "New Jersey"
          }
        ];
        this.setState({
          readonlyPennsylvaniaState: true,
          pennsylvaniaState: true,
          readonlyNewJerseyState: true,
          newJerseyState: true,
          ExcludeStates,
          openStateSelectionList: true
        });
      } else {
        this.setState({
          readonlyPennsylvaniaState: false,
          pennsylvaniaState: false
        });
      }
    } else if (
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_DEFERRED_COMPENSATION ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_STATE_EMPLOYEE_RETIREMENT_SYSTEM_WITHOLDING
    ) {
      let ExcludeStates = [
        {
          Id: PENNSYLVANIA_ID,
          Name: "Pennsylvania"
        }
      ];
      let AdvancedTaxOption = {
        ReduceFUTAWages: false,
        ReduceFederalWages: true,
        ReduceLocalWages: true,
        ReduceMedicareWages: false,
        ReduceSDIWages: false,
        ReduceSUTAWages: false,
        ReduceSocialSecurityWages: false,
        ReduceStateWages: true
      };
      this.setState({
        openTaxabilitySection: true,
        opendeductionamountdebitcard: false,
        openOnlyTaxabilityLinkForRetirementCategory: false,
        TaxabilityId: true,
        openAdvanceLink: true,
        AdvancedTaxOption,
        openAdvanceTaxOptions: false,
        ReduceFUTAWages: false,
        ReduceFederalWages: true,
        ReduceLocalWages: true,
        ReduceMedicareWages: false,
        ReduceSDIWages: false,
        ReduceSUTAWages: false,
        ReduceSocialSecurityWages: false,
        ReduceStateWages: true,
        readonlyPennsylvaniaState: true,
        pennsylvaniaState: true,
        openStateSelectionList: true,
        ExcludeStates
      });
    } else if (
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K_LOAN_REPAYMENT ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B_LOAN_REPAYMENT
    ) {
      this.setState({
        openloannumbertext: true,
        openOnlyTaxabilityLinkForRetirementCategory: false
      });
    } else if (
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_ADOPTION_ASSISTANCE_PROGRAMS ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_MISCELLANEOUS
    ) {
      let AdvancedTaxOption = {
        ReduceFUTAWages: true,
        ReduceFederalWages: true,
        ReduceLocalWages: true,
        ReduceMedicareWages: true,
        ReduceSDIWages: true,
        ReduceSUTAWages: true,
        ReduceSocialSecurityWages: true,
        ReduceStateWages: true
      };
      this.setState({
        ReduceFUTAWages: true,
        ReduceFederalWages: true,
        ReduceLocalWages: true,
        ReduceMedicareWages: true,
        ReduceSDIWages: true,
        ReduceSUTAWages: true,
        ReduceSocialSecurityWages: true,
        ReduceStateWages: true,
        AdvancedTaxOption,
        openTaxabilitySection: true,
        TaxabilityId: null,
        openAdvanceLink: false,
        openStateSelectionList: false,
        taxabilitypretaxradio: false,
        openOnlyTaxabilityLinkForRetirementCategory: false
      });
    } else if (
      newValue === systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K_CATCH_UP ||
      newValue === systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B_CATCH_UP ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_408K_6 ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_408P_SIMPLE ||
      newValue === systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_409A ||
      newValue === systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_457B ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_457B_CATCH_UP ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_501C_18_D ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_SIMPLE_IRA_SIMPLE_401K ||
      newValue ===
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_SIMPLE_IRA_SIMPLE_401K_CATCH_UP
    ) {
      let ExcludeStates = [
        {
          Id: PENNSYLVANIA_ID,
          Name: "Pennsylvania"
        }
      ];
      this.setState({
        openAdvanceLink: true,
        openTaxabilitySection: false,
        openOnlyTaxabilityLinkForRetirementCategory: true,
        ReduceFUTAWages: false,
        ReduceFederalWages: true,
        ReduceLocalWages: true,
        ReduceMedicareWages: false,
        ReduceSDIWages: false,
        ReduceSUTAWages: false,
        ReduceSocialSecurityWages: false,
        ReduceStateWages: true,
        readonlyNewJerseyState: false,
        newJerseyState: false,
        readonlyPennsylvaniaState: true,
        pennsylvaniaState: true,
        openStateSelectionList: true,
        TaxabilityId: true,
        ExcludeStates
      });
    } else if (
      newValue !==
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_ADOPTION_ASSISTANCE_PROGRAMS ||
      (newValue !==
        systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_MISCELLANEOUS &&
        this.props.systemDeductionData.DeductionCategoryId ===
          String(systemConstantsId.DEDUCTION_DESCRIPTION_OTHER_ID))
    ) {
      let AdvancedTaxOption = {
        ReduceFUTAWages: false,
        ReduceFederalWages: false,
        ReduceLocalWages: false,
        ReduceMedicareWages: false,
        ReduceSDIWages: false,
        ReduceSUTAWages: false,
        ReduceSocialSecurityWages: false,
        ReduceStateWages: false
      };
      this.setState({
        TaxabilityId: null,
        ReduceFUTAWages: false,
        ReduceFederalWages: false,
        ReduceLocalWages: false,
        ReduceMedicareWages: false,
        ReduceSDIWages: false,
        ReduceSUTAWages: false,
        ReduceSocialSecurityWages: false,
        ReduceStateWages: false,
        readonlyNewJerseyState: false,
        newJerseyState: false,
        readonlyPennsylvaniaState: false,
        pennsylvaniaState: false,
        AdvancedTaxOption,
        openOnlyTaxabilityLinkForRetirementCategory: false,
        openTaxabilitySection: false,
        openAdvanceLink: false,
        openStateSelectionList: false,
        opendeductionamountdebitcard: false,
        openloannumbertext: false
      });
    } else {
      let AdvancedTaxOption = {
        ReduceFUTAWages: false,
        ReduceFederalWages: false,
        ReduceLocalWages: false,
        ReduceMedicareWages: false,
        ReduceSDIWages: false,
        ReduceSUTAWages: false,
        ReduceSocialSecurityWages: false,
        ReduceStateWages: false
      };

      this.setState({
        opendeductionamountdebitcard: false,
        openloannumbertext: false,
        openTaxabilitySection: false,
        openOnlyTaxabilityLinkForRetirementCategory: false,
        openAdvanceLink: false,
        openStateSelectionList: false,
        TaxabilityId: null,
        AdvancedTaxOption
      });
    }

    if (
      newValue ===
      systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_INCOME_TAX_WITHHOLDING
    ) {
      this.setState({
        openTaxReportableOnW2Section: true,
        ExcludeStates: [],
        openSymmetryTaxCodeSection: true
      });
    } else {
      this.setState({
        openTaxReportableOnW2Section: false,
        openReportedTaxAndWagesSection: false,
        TaxReportableOnW2: false,
        W2LocalTax_Ind: false,
        W2StateTax_Ind: false,
        WageBaseCode: "0",
        ReportedTaxType: "0",
        openSymmetryTaxCodeSection: false,
        SymmetryTaxCodeId: null
      });
    }
  }

  handleChangeReduceFUTAWages() {
    let defaultValue = getTaxabilityDefaultValue(this.state);
    let selectedCheckBox = !this.state.ReduceFUTAWages;
    if (selectedCheckBox === true) {
      let errors = this.state.errors;
      delete errors["validateAllSelectedAdvancedTaxOption"];
      this.setState({
        validateAllSelectedAdvancedTaxOption: false,
        errors
      });
    }
    this.setState({
      ReduceFUTAWages: selectedCheckBox,
      IsDefaultTaxChange: true,
      isFormUpdated: true,
      taxOptionEdit: true
    });
    this.props.setFormUpdate(true);

    let AdvancedTaxOption = this.state.AdvancedTaxOption;
    AdvancedTaxOption.ReduceFUTAWages = selectedCheckBox;
    this.setState({
      AdvancedTaxOption
    });

    if (
      selectedCheckBox === defaultValue.ReduceFUTAWages &&
      AdvancedTaxOption.ReduceLocalWages === defaultValue.ReduceLocalWages &&
      AdvancedTaxOption.ReduceMedicareWages ===
        defaultValue.ReduceMedicareWages &&
      AdvancedTaxOption.ReduceSDIWages === defaultValue.ReduceSDIWages &&
      AdvancedTaxOption.ReduceSUTAWages === defaultValue.ReduceSUTAWages &&
      AdvancedTaxOption.ReduceStateWages === defaultValue.ReduceStateWages &&
      AdvancedTaxOption.ReduceFederalWages ===
        defaultValue.ReduceFederalWages &&
      AdvancedTaxOption.ReduceSocialSecurityWages ===
        defaultValue.ReduceSocialSecurityWages
    ) {
      this.setState({
        IsDefaultTaxChange: false
      });
    }
  }

  handleChangeReduceFederalWages() {
    let defaultValue = getTaxabilityDefaultValue(this.state);
    let selectedCheckBox = !this.state.ReduceFederalWages;
    if (selectedCheckBox === true) {
      let errors = this.state.errors;
      delete errors["validateAllSelectedAdvancedTaxOption"];
      this.setState({
        validateAllSelectedAdvancedTaxOption: false,
        errors
      });

      this.setState({ validateAllSelectedAdvancedTaxOption: false });
    }
    this.setState({
      ReduceFederalWages: selectedCheckBox,
      IsDefaultTaxChange: true,
      isFormUpdated: true,
      taxOptionEdit: true
    });
    this.props.setFormUpdate(true);

    let AdvancedTaxOption = this.state.AdvancedTaxOption;
    AdvancedTaxOption.ReduceFederalWages = selectedCheckBox;
    this.setState({
      AdvancedTaxOption
    });

    if (
      selectedCheckBox === defaultValue.ReduceFederalWages &&
      AdvancedTaxOption.ReduceLocalWages === defaultValue.ReduceLocalWages &&
      AdvancedTaxOption.ReduceMedicareWages ===
        defaultValue.ReduceMedicareWages &&
      AdvancedTaxOption.ReduceSDIWages === defaultValue.ReduceSDIWages &&
      AdvancedTaxOption.ReduceSUTAWages === defaultValue.ReduceSUTAWages &&
      AdvancedTaxOption.ReduceStateWages === defaultValue.ReduceStateWages &&
      AdvancedTaxOption.ReduceFUTAWages === defaultValue.ReduceFUTAWages &&
      AdvancedTaxOption.ReduceSocialSecurityWages ===
        defaultValue.ReduceSocialSecurityWages
    ) {
      this.setState({
        IsDefaultTaxChange: false
      });
    }
  }

  handleChangeReduceLocalWages() {
    let defaultValue = getTaxabilityDefaultValue(this.state);
    let selectedCheckBox = !this.state.ReduceLocalWages;
    if (selectedCheckBox === true) {
      let errors = this.state.errors;
      delete errors["validateAllSelectedAdvancedTaxOption"];
      this.setState({
        validateAllSelectedAdvancedTaxOption: false,
        errors
      });

      this.setState({ validateAllSelectedAdvancedTaxOption: false });
    }
    this.setState({
      ReduceLocalWages: selectedCheckBox,
      IsDefaultTaxChange: true,
      isFormUpdated: true,
      taxOptionEdit: true
    });
    this.props.setFormUpdate(true);

    let AdvancedTaxOption = this.state.AdvancedTaxOption;
    AdvancedTaxOption.ReduceLocalWages = selectedCheckBox;
    this.setState({
      AdvancedTaxOption
    });

    if (
      selectedCheckBox === defaultValue.ReduceLocalWages &&
      AdvancedTaxOption.ReduceFederalWages ===
        defaultValue.ReduceFederalWages &&
      AdvancedTaxOption.ReduceMedicareWages ===
        defaultValue.ReduceMedicareWages &&
      AdvancedTaxOption.ReduceSDIWages === defaultValue.ReduceSDIWages &&
      AdvancedTaxOption.ReduceSUTAWages === defaultValue.ReduceSUTAWages &&
      AdvancedTaxOption.ReduceStateWages === defaultValue.ReduceStateWages &&
      AdvancedTaxOption.ReduceFUTAWages === defaultValue.ReduceFUTAWages &&
      AdvancedTaxOption.ReduceSocialSecurityWages ===
        defaultValue.ReduceSocialSecurityWages
    ) {
      this.setState({
        IsDefaultTaxChange: false
      });
    }
  }

  handleChangeReduceMedicareWages() {
    let defaultValue = getTaxabilityDefaultValue(this.state);
    let selectedCheckBox = !this.state.ReduceMedicareWages;
    if (selectedCheckBox === true) {
      let errors = this.state.errors;
      delete errors["validateAllSelectedAdvancedTaxOption"];
      this.setState({
        validateAllSelectedAdvancedTaxOption: false,
        errors
      });

      this.setState({ validateAllSelectedAdvancedTaxOption: false });
    }
    this.setState({
      ReduceMedicareWages: selectedCheckBox,
      IsDefaultTaxChange: true,
      isFormUpdated: true,
      taxOptionEdit: true
    });
    this.props.setFormUpdate(true);

    let AdvancedTaxOption = this.state.AdvancedTaxOption;
    AdvancedTaxOption.ReduceMedicareWages = selectedCheckBox;
    this.setState({
      AdvancedTaxOption
    });

    if (
      selectedCheckBox === defaultValue.ReduceMedicareWages &&
      AdvancedTaxOption.ReduceFederalWages ===
        defaultValue.ReduceFederalWages &&
      AdvancedTaxOption.ReduceLocalWages === defaultValue.ReduceLocalWages &&
      AdvancedTaxOption.ReduceSDIWages === defaultValue.ReduceSDIWages &&
      AdvancedTaxOption.ReduceSUTAWages === defaultValue.ReduceSUTAWages &&
      AdvancedTaxOption.ReduceStateWages === defaultValue.ReduceStateWages &&
      AdvancedTaxOption.ReduceFUTAWages === defaultValue.ReduceFUTAWages &&
      AdvancedTaxOption.ReduceSocialSecurityWages ===
        defaultValue.ReduceSocialSecurityWages
    ) {
      this.setState({
        IsDefaultTaxChange: false
      });
    }
  }

  handleChangeReduceSDIWages() {
    let defaultValue = getTaxabilityDefaultValue(this.state);
    let selectedCheckBox = !this.state.ReduceSDIWages;
    if (selectedCheckBox === true) {
      let errors = this.state.errors;
      delete errors["validateAllSelectedAdvancedTaxOption"];
      this.setState({
        validateAllSelectedAdvancedTaxOption: false,
        errors
      });

      this.setState({ validateAllSelectedAdvancedTaxOption: false });
    }
    this.setState({
      ReduceSDIWages: selectedCheckBox,
      IsDefaultTaxChange: true,
      isFormUpdated: true,
      taxOptionEdit: true
    });
    this.props.setFormUpdate(true);

    let AdvancedTaxOption = this.state.AdvancedTaxOption;
    AdvancedTaxOption.ReduceSDIWages = selectedCheckBox;
    this.setState({
      AdvancedTaxOption
    });

    if (
      selectedCheckBox === defaultValue.ReduceSDIWages &&
      AdvancedTaxOption.ReduceFederalWages ===
        defaultValue.ReduceFederalWages &&
      AdvancedTaxOption.ReduceLocalWages === defaultValue.ReduceLocalWages &&
      AdvancedTaxOption.ReduceMedicareWages ===
        defaultValue.ReduceMedicareWages &&
      AdvancedTaxOption.ReduceSUTAWages === defaultValue.ReduceSUTAWages &&
      AdvancedTaxOption.ReduceStateWages === defaultValue.ReduceStateWages &&
      AdvancedTaxOption.ReduceFUTAWages === defaultValue.ReduceFUTAWages &&
      AdvancedTaxOption.ReduceSocialSecurityWages ===
        defaultValue.ReduceSocialSecurityWages
    ) {
      this.setState({
        IsDefaultTaxChange: false
      });
    }
  }

  handleChangeReduceSUTAWages() {
    let defaultValue = getTaxabilityDefaultValue(this.state);
    let selectedCheckBox = !this.state.ReduceSUTAWages;
    if (selectedCheckBox === true) {
      let errors = this.state.errors;
      delete errors["validateAllSelectedAdvancedTaxOption"];
      this.setState({
        validateAllSelectedAdvancedTaxOption: false,
        errors
      });

      this.setState({ validateAllSelectedAdvancedTaxOption: false });
    }
    this.setState({
      ReduceSUTAWages: selectedCheckBox,
      IsDefaultTaxChange: true,
      isFormUpdated: true,
      taxOptionEdit: true
    });
    this.props.setFormUpdate(true);

    let AdvancedTaxOption = this.state.AdvancedTaxOption;
    AdvancedTaxOption.ReduceSUTAWages = selectedCheckBox;
    this.setState({
      AdvancedTaxOption
    });

    if (
      selectedCheckBox === defaultValue.ReduceSUTAWages &&
      AdvancedTaxOption.ReduceFederalWages ===
        defaultValue.ReduceFederalWages &&
      AdvancedTaxOption.ReduceLocalWages === defaultValue.ReduceLocalWages &&
      AdvancedTaxOption.ReduceMedicareWages ===
        defaultValue.ReduceMedicareWages &&
      AdvancedTaxOption.ReduceSDIWages === defaultValue.ReduceSDIWages &&
      AdvancedTaxOption.ReduceStateWages === defaultValue.ReduceStateWages &&
      AdvancedTaxOption.ReduceFUTAWages === defaultValue.ReduceFUTAWages &&
      AdvancedTaxOption.ReduceSocialSecurityWages ===
        defaultValue.ReduceSocialSecurityWages
    ) {
      this.setState({
        IsDefaultTaxChange: false
      });
    }
  }

  handleChangeReduceSocialSecurityWages() {
    let defaultValue = getTaxabilityDefaultValue(this.state);
    let selectedCheckBox = !this.state.ReduceSocialSecurityWages;
    if (selectedCheckBox === true) {
      let errors = this.state.errors;
      delete errors["validateAllSelectedAdvancedTaxOption"];
      this.setState({
        validateAllSelectedAdvancedTaxOption: false,
        errors
      });

      this.setState({ validateAllSelectedAdvancedTaxOption: false });
    }
    this.setState({
      ReduceSocialSecurityWages: selectedCheckBox,
      IsDefaultTaxChange: true,
      isFormUpdated: true,
      taxOptionEdit: true
    });
    this.props.setFormUpdate(true);

    let AdvancedTaxOption = this.state.AdvancedTaxOption;
    AdvancedTaxOption.ReduceSocialSecurityWages = selectedCheckBox;
    this.setState({
      AdvancedTaxOption
    });

    if (
      selectedCheckBox === defaultValue.ReduceSocialSecurityWages &&
      AdvancedTaxOption.ReduceFederalWages ===
        defaultValue.ReduceFederalWages &&
      AdvancedTaxOption.ReduceLocalWages === defaultValue.ReduceLocalWages &&
      AdvancedTaxOption.ReduceMedicareWages ===
        defaultValue.ReduceMedicareWages &&
      AdvancedTaxOption.ReduceSDIWages === defaultValue.ReduceSDIWages &&
      AdvancedTaxOption.ReduceStateWages === defaultValue.ReduceStateWages &&
      AdvancedTaxOption.ReduceFUTAWages === defaultValue.ReduceFUTAWages &&
      AdvancedTaxOption.ReduceSUTAWages === defaultValue.ReduceSUTAWages
    ) {
      this.setState({
        IsDefaultTaxChange: false
      });
    }
  }

  handleChangeReduceStateWages() {
    let defaultValue = getTaxabilityDefaultValue(this.state);
    let selectedCheckBox = !this.state.ReduceStateWages;
    if (selectedCheckBox === true) {
      let errors = this.state.errors;
      delete errors["validateAllSelectedAdvancedTaxOption"];
      this.setState({
        validateAllSelectedAdvancedTaxOption: false,
        errors
      });

      this.setState({ validateAllSelectedAdvancedTaxOption: false });
    }
    this.setState({
      ReduceStateWages: selectedCheckBox,
      IsDefaultTaxChange: true,
      isFormUpdated: true,
      taxOptionEdit: true
    });
    this.props.setFormUpdate(true);

    let AdvancedTaxOption = this.state.AdvancedTaxOption;
    AdvancedTaxOption.ReduceStateWages = selectedCheckBox;
    this.setState({
      AdvancedTaxOption
    });

    if (
      selectedCheckBox === defaultValue.ReduceStateWages &&
      AdvancedTaxOption.ReduceFederalWages ===
        defaultValue.ReduceFederalWages &&
      AdvancedTaxOption.ReduceLocalWages === defaultValue.ReduceLocalWages &&
      AdvancedTaxOption.ReduceMedicareWages ===
        defaultValue.ReduceMedicareWages &&
      AdvancedTaxOption.ReduceSDIWages === defaultValue.ReduceSDIWages &&
      AdvancedTaxOption.ReduceSocialSecurityWages ===
        defaultValue.ReduceSocialSecurityWages &&
      AdvancedTaxOption.ReduceFUTAWages === defaultValue.ReduceFUTAWages &&
      AdvancedTaxOption.ReduceSUTAWages === defaultValue.ReduceSUTAWages
    ) {
      this.setState({
        IsDefaultTaxChange: false
      });
    }
  }

  handleChangePennsylvania(e) {
    let selectedCheckBox = !this.state.pennsylvaniaState;
    this.setState({
      pennsylvaniaState: selectedCheckBox,
      isFormUpdated: true,
      taxOptionEdit: true
    });
    this.props.setFormUpdate(true);

    let targetValue = parseInt(e.target.value, 10);

    let ExcludeStates = [];
    ExcludeStates = [...this.state.ExcludeStates];
    if (ExcludeStates === undefined || ExcludeStates === null) {
      ExcludeStates = [];
    }

    if (
      ExcludeStates !== null &&
      ExcludeStates.length !== null &&
      ExcludeStates.length !== undefined
    ) {
      if (selectedCheckBox) {
        if (ExcludeStates.length === 0) {
          ExcludeStates.push({
            Id: targetValue,
            Name: e.target.name
          });
        } else {
          ExcludeStates.forEach(item => {
            if (item.Id === targetValue) {
              ExcludeStates = ExcludeStates.filter(function(excludeItem) {
                return excludeItem.Id !== targetValue;
              });
            } else {
              if (selectedCheckBox) {
                ExcludeStates.push({
                  Id: targetValue,
                  Name: e.target.name
                });
              }
            }
          });
        }
      } else {
        ExcludeStates.forEach(item => {
          if (item.Id === targetValue) {
            ExcludeStates = ExcludeStates.filter(function(excludeItem) {
              return excludeItem.Id !== targetValue;
            });
          } else {
            if (selectedCheckBox) {
              ExcludeStates.push({
                Id: targetValue,
                Name: e.target.name
              });
            }
          }
        });
      }
    }

    this.setState({
      ExcludeStates,
      IsDefaultTaxChange: true
    });
  }

  handleChangeNewJersey(e) {
    let selectedCheckBox = !this.state.newJerseyState;
    this.setState({
      newJerseyState: selectedCheckBox,
      isFormUpdated: true,
      taxOptionEdit: true
    });
    this.props.setFormUpdate(true);

    let targetValue = parseInt(e.target.value, 10);

    let ExcludeStates = [];
    ExcludeStates = [...this.state.ExcludeStates];
    if (ExcludeStates === undefined || ExcludeStates === null) {
      ExcludeStates = [];
    }

    if (ExcludeStates !== null) {
      if (selectedCheckBox) {
        if (ExcludeStates.length === 0) {
          ExcludeStates.push({
            Id: targetValue,
            Name: e.target.name
          });
        } else {
          ExcludeStates.forEach(item => {
            if (item.Id === targetValue) {
              ExcludeStates = ExcludeStates.filter(function(excludeItem) {
                return excludeItem.Id !== targetValue;
              });
            } else {
              if (selectedCheckBox) {
                ExcludeStates.push({
                  Id: targetValue,
                  Name: e.target.name
                });
              }
            }
          });
        }
      } else {
        ExcludeStates.forEach(item => {
          if (item.Id === targetValue) {
            ExcludeStates = ExcludeStates.filter(function(excludeItem) {
              return excludeItem.Id !== targetValue;
            });
          } else {
            if (selectedCheckBox) {
              ExcludeStates.push({
                Id: targetValue,
                Name: e.target.name
              });
            }
          }
        });
      }
    }

    this.setState({
      ExcludeStates,
      IsDefaultTaxChange: true
    });
  }

  checkTaxOptions(newValue) {
    this.setState({
      selectedTaxOtions: newValue
    });
  }

  checkExcludeStateOptions(newValue) {
    this.setState({
      selectedExcludeStateOtions: newValue
    });
  }

  handleChangeAdvanceNote = event => {
    let errors = this.state.errors;
    delete errors["ReasonForChange"];
    let description = event.target.value.trim();
    if (description === "") {
      errors["ReasonForChange"] = "";
      this.setState({
        ReasonForChange: "",
        errors
      });
    } else {
      this.setState({
        ReasonForChange: event.target.value,
        errors
      });
    }
  };

  handleChangeCode = event => {
    if (event.target.value.length <= 10) {
      if (isInDidUpdate === false) {
        this.setState({
          isFormUpdated: true
        });
        this.props.setFormUpdate(true);
      }
      let value = event.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
      this.setState({
        [event.target.name]: value
      });
      let errors = this.state.errors;
      delete errors[event.target.name];
      this.setState({
        errors
      });
    }
  };

  renderAdvanceTaxNote = () => {
    return (
      <div style={{ paddingBottom: "15px" }}>
        <div className="deducion-taxability-options-description deduction-exclude-states-group-section col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <span
            className={utilities.getErrorClassName(
              this.state.errors["ReasonForChange"]
            )}
          >
            Please note a reason for the changes that were made
          </span>
        </div>
        <div id="">
          <Media query="(min-width:320px) and (max-width:480px)">
            {matches =>
              matches ? (
                <textarea
                  id="ReasonForChange"
                  rows="5"
                  name="body"
                  onChange={this.handleChangeAdvanceNote}
                  style={{ width: "95%" }}
                />
              ) : (
                <textarea
                  id="ReasonForChange"
                  rows="5"
                  cols="70"
                  name="body"
                  onChange={this.handleChangeAdvanceNote}
                />
              )
            }
          </Media>
          {this.state.errors["ReasonForChange"] && (
            <div
              className={utilities.getErrorClassName(
                this.state.errors["ReasonForChange"]
              )}
            >
              This is a required field.
            </div>
          )}
        </div>
      </div>
    );
  };

  renderTaxOptionsList() {
    if (!this.props.taxabilityValuesList) {
      return <div />;
    } else {
      return (
        <div id="">
          <input
            id="Tax-Option-Federal"
            type="checkbox"
            name="ReduceFederalWages"
            checked={this.state.ReduceFederalWages}
            onClick={this.handleChangeReduceFederalWages}
            value="ReduceFederalWages"
            onChange={() => {}}
          />
          <label
            htmlFor="Tax-Option-Federal"
            className={`system-level-deduction-description-checkbox-label ${this
              .state.ReduceFederalWages && "nc-checkbox-checked"}`}
          >
            Federal (Box 1)
          </label>

          <input
            id="Tax-Option-Social-Security"
            type="checkbox"
            name="ReduceSocialSecurityWages"
            checked={this.state.ReduceSocialSecurityWages}
            onClick={this.handleChangeReduceSocialSecurityWages}
            value="ReduceSocialSecurityWages"
            onChange={() => {}}
          />
          <label
            htmlFor="Tax-Option-Social-Security"
            className={`system-level-deduction-description-checkbox-label ${this
              .state.ReduceSocialSecurityWages && "nc-checkbox-checked"}`}
          >
            Social Security (Box 3)
          </label>

          <input
            id="Tax-Option-Medicare-Wages"
            type="checkbox"
            name="ReduceMedicareWages"
            checked={this.state.ReduceMedicareWages}
            onClick={this.handleChangeReduceMedicareWages}
            value="ReduceMedicareWages"
            onChange={() => {}}
          />
          <label
            htmlFor="Tax-Option-Medicare-Wages"
            className={`system-level-deduction-description-checkbox-label ${this
              .state.ReduceMedicareWages && "nc-checkbox-checked"}`}
          >
            Medicare Wages (Box 5)
          </label>

          <input
            id="Tax-Option-State-Wages"
            type="checkbox"
            name="ReduceStateWages"
            checked={this.state.ReduceStateWages}
            onClick={this.handleChangeReduceStateWages}
            value="ReduceStateWages"
            onChange={() => {}}
          />
          <label
            htmlFor="Tax-Option-State-Wages"
            className={`system-level-deduction-description-checkbox-label ${this
              .state.ReduceStateWages && "nc-checkbox-checked"}`}
          >
            State Wages (Box 16)
          </label>

          <input
            id="Tax-Option-Local-Wages"
            type="checkbox"
            name="ReduceLocalWages"
            checked={this.state.ReduceLocalWages}
            onClick={this.handleChangeReduceLocalWages}
            value="ReduceLocalWages"
            onChange={() => {}}
          />
          <label
            htmlFor="Tax-Option-Local-Wages"
            className={`system-level-deduction-description-checkbox-label ${this
              .state.ReduceLocalWages && "nc-checkbox-checked"}`}
          >
            Local Wages (Box 18)
          </label>

          <input
            id="Tax-Option-SUTA"
            type="checkbox"
            name="ReduceSUTAWages"
            checked={this.state.ReduceSUTAWages}
            onClick={this.handleChangeReduceSUTAWages}
            value="ReduceSUTAWages"
            onChange={() => {}}
          />
          <label
            htmlFor="Tax-Option-SUTA"
            className={`system-level-deduction-description-checkbox-label ${this
              .state.ReduceSUTAWages && "nc-checkbox-checked"}`}
          >
            SUTA
          </label>

          <input
            id="Tax-Option-FUTA"
            type="checkbox"
            name="ReduceFUTAWages"
            checked={this.state.ReduceFUTAWages}
            onClick={this.handleChangeReduceFUTAWages}
            value="ReduceFUTAWages"
            onChange={() => {}}
          />
          <label
            htmlFor="Tax-Option-FUTA"
            className={`system-level-deduction-description-checkbox-label ${this
              .state.ReduceFUTAWages && "nc-checkbox-checked"}`}
          >
            FUTA
          </label>

          <input
            id="Tax-Option-SDI"
            type="checkbox"
            name="ReduceSDIWages"
            checked={this.state.ReduceSDIWages}
            onClick={this.handleChangeReduceSDIWages}
            value="ReduceSDIWages"
            onChange={() => {}}
          />
          <label
            htmlFor="Tax-Option-SDI"
            className={`system-level-deduction-description-checkbox-label  ${this
              .state.ReduceSDIWages && "nc-checkbox-checked"}`}
          >
            SDI
          </label>
        </div>
      );
    }
  }

  renderExcludeStateOptionsList() {
    if (!this.props.excludeStatesValuesList) {
      return <div />;
    } else {
      return (
        <div>
          <div className="deducion-taxability-options-description deduction-exclude-states-group-section col-xs-12 col-sm-12 col-md-12 col-lg-12">
            Do not reduce state wages from the following:
          </div>
          <div className="clearfix" />
          <div id="deduction-exclude-states-checkbox-group">
            <span />
            <input
              id="Exclude-State-Option-Pennsylvania"
              type="checkbox"
              name="Pennsylvania"
              checked={this.state.pennsylvaniaState}
              onClick={this.handleChangePennsylvania}
              disabled={this.state.readonlyPennsylvaniaState}
              value={PENNSYLVANIA_ID}
              onChange={() => {}}
            />
            <label
              htmlFor="Exclude-State-Option-Pennsylvania"
              className={`system-level-deduction-description-checkbox-label ${this
                .state.pennsylvaniaState && "nc-checkbox-checked"}`}
            >
              Pennsylvania
            </label>

            {this.state.showNewJerseyState && (
              <React.Fragment>
                <input
                  id="Exclude-State-Option-NewJersey"
                  type="checkbox"
                  name="New Jersey"
                  checked={this.state.newJerseyState}
                  onClick={this.handleChangeNewJersey}
                  disabled={this.state.readonlyNewJerseyState}
                  value={NEWJERSEY_ID}
                  onChange={() => {}}
                />
                <label
                  htmlFor="Exclude-State-Option-NewJersey"
                  className={`system-level-deduction-description-checkbox-label ${this
                    .state.newJerseyState && "nc-checkbox-checked"}`}
                >
                  New Jersey
                </label>
              </React.Fragment>
            )}
          </div>
        </div>
      );
    }
  }

  renderDeductionListItem() {
    let deductionList = [];
    if (
      this.props.categoryDeductionList.Data !== undefined &&
      this.props.subCategoryDeductionList.Data !== undefined
    ) {
      let categoryDeductionList = this.props.categoryDeductionList.Data;
      for (var i = 0; i < categoryDeductionList.length; i++) {
        deductionList.push({
          DeductionCategoryId: categoryDeductionList[i].DeductionCategoryId,
          Value: String(categoryDeductionList[i].DeductionCategoryId),
          Name: categoryDeductionList[i].Name,
          Description: categoryDeductionList[i].LongName
        });
      }
      return deductionList.map(item => {
        return (
          <RadioButton
            key={item.Name}
            id={`deduction-category-button-` + item.DeductionCategoryId}
            name="DeductionCategoryId"
            value={item.Value}
            type="description"
            label={item.Name}
            description={item.Description}
            checked={
              String(this.state.DeductionCategoryId) ===
              String(item.DeductionCategoryId)
            }
            onChange={this.onChangeDeduction}
            width="191px"
          />
        );
      });
    }
  }

  renderTaxabilityListItem(list) {
    return list.map(item => {
      return (
        <div
          key={item.id}
          id={`deduction-taxability-button-` + item.id}
          className={`single-radio-button`}
        >
          <RadioButton
            id={item.Name}
            name={item.name}
            type="title"
            value={item.value}
            label={item.label}
            onChange={this.onChangeTaxability}
            checked={this.state.TaxabilityId === item.value}
            boxWidth="100%"
          />
        </div>
      );
    });
  }

  showAdvanvedTaxOptions() {
    return (
      <div
        className="deducion-taxability-options"
        style={{ marginBottom: "20px" }}
      >
        <a
          id="deduction-tax-option-link"
          className="text-link"
          onClick={() =>
            this.setState({
              openAdvanceTaxOptions: !this.state.openAdvanceTaxOptions
            })
          }
        >
          {" "}
          {this.state.openAdvanceTaxOptions ? (
            <span>
              <i className="fa fa-chevron-up" /> Hide Advanced Tax Options
            </span>
          ) : (
            <span>
              <i className="fa fa-chevron-down" /> Open Advanced Tax Options
            </span>
          )}{" "}
        </a>
        {this.state.validateAllSelectedAdvancedTaxOption ? (
          <div
            className="text-color-red"
            style={{ paddingTop: "15px", paddingBottom: "10px" }}
          >
            At least one of these boxes should be checked
          </div>
        ) : null}
        <Panel
          id="taxability-options-collapsible-panel"
          className="deducion-taxability-options-collapsible-panel"
          expanded={this.state.openAdvanceTaxOptions}
          onToggle={() => {}}
        >
          <Panel.Collapse>
            <div
              className="deducion-taxability-options-description col-xs-12 col-sm-12 col-md-12 col-lg-9"
              style={{
                paddingTop:
                  !this.state.validateAllSelectedAdvancedTaxOption && "15px",
                paddingBottom: "10px"
              }}
            >
              Deductions created from this deduction template will reduce the
              wages that are checked below. Overriding these options is allowed,
              but not common.
            </div>
            <div className="clearfix" />
            <div className="deducion-taxability-options-list">
              {this.renderTaxOptionsList()}
            </div>

            <div className="deducion-taxability-options-states col-xs-12 col-sm-12 col-md-12 col-lg-12">
              {this.state.openStateSelectionList
                ? this.renderExcludeStateOptionsList()
                : null}
            </div>

            <div className="deducion-taxability-options-states col-xs-12 col-sm-12 col-md-12 col-lg-12">
              {this.state.AppUIRoleId === String(1)
                ? this.renderAdvanceTaxNote()
                : null}
            </div>
          </Panel.Collapse>
        </Panel>
      </div>
    );
  }

  showTaxabilitySection() {
    return (
      <div
        id="deduction-taxability-section"
        className="entity-form-section-secondary"
      >
        <div className="title">Taxability</div>
        <div className="content">
          <div className="nc-field-group clearfix">
            <div className="field-name">
              <span
                className={utilities.getErrorClassName(
                  this.state.errors["TaxabilityId"]
                )}
              >
                Is this deduction pre-tax (i.e. a cafeteria plan / Section 125
                deduction)?{" "}
                <a
                  id="system-deduction-taxability-info-icon"
                  onClick={() =>
                    this.setState({
                      openInsuranceTaxabilitySection: !this.state
                        .openInsuranceTaxabilitySection
                    })
                  }
                >
                  <i className="fa-information-blue fa fa-info-circle" />
                </a>
              </span>
            </div>

            <div className="clearfix" />
            {this.state.openInsuranceTaxabilitySection
              ? this.showTaxabilitySectionPopover()
              : null}

            <div
              className="radio-button-group col-lg-12 col-md-12 col-sm-12 col-xs-12"
              style={{ marginBottom: "10px" }}
            >
              {this.renderTaxabilityListItem(taxabilityList)}
            </div>
            {this.state.errors["TaxabilityId"] && (
              <p
                className="error-label"
                style={{
                  marginTop: "-15px",
                  marginBottom: "20px"
                }}
              >
                {this.state.errors["TaxabilityId"]}
              </p>
            )}
            {this.state.openAdvanceLink ? this.showAdvanvedTaxOptions() : null}
          </div>
        </div>
      </div>
    );
  }

  showTaxabilitySectionOnRetirementSelection() {
    return (
      <div
        id="deduction-taxability-section"
        className="entity-form-section-secondary"
      >
        <div className="title">Taxability</div>
        <div className="content">
          {this.state.openAdvanceLink ? this.showAdvanvedTaxOptions() : null}
        </div>
      </div>
    );
  }

  showCustomIcon() {
    return <i className="fa fa-chevron-down cstm555icon" aria-hidden="true" />;
  }

  onChangeTaxReportableOnW2(event, value = 0) {
    let update = {};
    let selectedButton = event.value === "true";
    let errors = this.state.errors;
    delete errors["ReportedTax"];
    delete errors["WageBaseCode"];
    this.setState({
      errors
    });
    update[event.name] = selectedButton;
    this.setState(update);
    if (selectedButton) {
      this.setState({
        openReportedTaxAndWagesSection: true
      });
    } else {
      this.setState({
        openReportedTaxAndWagesSection: false,
        W2LocalTax_Ind: false,
        W2StateTax_Ind: false,
        WageBaseCode: "0",
        ReportedTaxType: "0"
      });
    }

    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
  }

  onChangeReportedTax(event, value = 0) {
    let errors = this.state.errors;
    delete errors["ReportedTax"];

    this.setState({
      ReportedTaxType: event.value
    });

    if (event.name === "Local Income Tax") {
      this.setState({
        W2StateTax_Ind: false,
        W2LocalTax_Ind: true
      });
    } else {
      this.setState({
        W2StateTax_Ind: true,
        W2LocalTax_Ind: false
      });
    }

    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
  }

  onChangeReportedWages(event, value = 0) {
    let errors = this.state.errors;
    delete errors["WageBaseCode"];

    this.setState({
      WageBaseCode: event.value
    });

    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
  }

  renderTaxReportableOnW2(list) {
    return list.map((item, index) => {
      return (
        <div
          key={item.id + index}
          id={`deduction-description-Tax-Reportable-radio-buttons` + item.id}
          className={`single-radio-button`}
        >
          <RadioButton
            id={item.id}
            name={item.name}
            value={item.value}
            type="title"
            label={item.label}
            checked={this.state.TaxReportableOnW2 === item.value}
            onChange={this.onChangeTaxReportableOnW2}
          />
        </div>
      );
    });
  }

  renderReportedTaxType(w2Indicatorlist) {
    let list = [];
    if (w2Indicatorlist.Data !== undefined && w2Indicatorlist.Data.length > 0) {
      for (var i = 0; i < w2Indicatorlist.Data.length; i++) {
        list.push({
          Id: w2Indicatorlist.Data[i].Id,
          Value: String(w2Indicatorlist.Data[i].Id),
          Name: w2Indicatorlist.Data[i].Name,
          Description: w2Indicatorlist.Data[i].LongName
        });
      }
    }
    return list.map((item, index) => {
      return (
        <RadioButton
          key={item.Name}
          id={"deduction-description-Reported-Tax-" + item.Id}
          name={item.Name}
          value={item.Value}
          type="two-line-title"
          label={item.Name}
          checked={String(this.state.ReportedTaxType) === String(item.Value)}
          onChange={this.onChangeReportedTax}
          width="235px"
        />
      );
    });
  }

  renderReportedWageslist(wageBaseCodeList) {
    let list = [];
    if (
      wageBaseCodeList.Data !== undefined &&
      wageBaseCodeList.Data.length > 0
    ) {
      for (var i = 0; i < wageBaseCodeList.Data.length; i++) {
        list.push({
          Id: wageBaseCodeList.Data[i].Id,
          Value: String(wageBaseCodeList.Data[i].Id),
          Name: wageBaseCodeList.Data[i].Name,
          Description: wageBaseCodeList.Data[i].LongName
        });
      }
    }
    return list.map((item, index) => {
      return (
        <RadioButton
          id={"deduction-description-Reported-Wages-" + item.Id}
          name="WageBaseCode"
          value={item.Value}
          type="descriptionTitle"
          description={item.Description}
          label={item.Name}
          checked={this.state.WageBaseCode === item.Value}
          onChange={this.onChangeReportedWages}
          width="215px"
        />
      );
    });
  }

  setSymmetryTaxCodeContext(newValue) {
    let errors = this.state.errors;
    delete errors["SymmetryTaxCodeId"];

    if (isInDidUpdate === false) {
      this.setState({
        isFormUpdated: true
      });
      this.props.setFormUpdate(true);
    }
    this.setState({ SymmetryTaxCodeId: newValue });
  }

  showCategoryDropdownSection() {
    return (
      <div id="deduction-subcategory">
        <div className="entity-form-section-primary">
          <div className="content">
            <div className="nc-field-group clearfix">
              <div className="field-name">
                <span
                  className={utilities.getErrorClassName(
                    this.state.errors["DeductionSubCategoryId"]
                  )}
                >
                  What type of {this.state.selectedDeductionTypeLabel} deduction
                  is this?
                </span>
              </div>

              <div
                id="sub-deduction-category-dropdown"
                className="input-field col-lg-6 col-md-8"
              >
                <DropDown
                  id="deduction-selection-dropdown"
                  placeholder="Select"
                  options={this.state.filteredDeductionDropdownList}
                  value={this.state.DeductionSubCategoryId}
                  onSelect={this.setDeductionValueContext}
                  arrowRenderer={this.showCustomIcon}
                  error={this.state.errors["DeductionSubCategoryId"]}
                  scrollBy={true}
                />
              </div>
              <div className="clearfix col-lg-12 col-md-12">
                {this.state.errors["DeductionSubCategoryId"] && (
                  <p
                    className="error-label"
                    style={{
                      marginTop: "-15px",
                      marginBottom: "20px",
                      float: "left"
                    }}
                  >
                    {this.state.errors["DeductionSubCategoryId"]}
                  </p>
                )}
              </div>
            </div>

            {this.state.openSymmetryTaxCodeSection &&
              this.props.symmetryTaxCodeList &&
              this.props.symmetryTaxCodeList.data &&
              this.props.symmetryTaxCodeList.data.Data && (
                <div className="nc-field-group clearfix">
                  <div className="field-name">
                    <span
                      className={utilities.getErrorClassName(
                        this.state.errors["SymmetryTaxCodeId"]
                      )}
                    >
                      What type of Income Tax Withholding is this?
                    </span>
                  </div>

                  <div
                    id="sub-deduction-symmetry-dropdown"
                    className="input-field col-lg-6 col-md-8"
                  >
                    <DropDown
                      id="deduction-symmetry-dropdown"
                      placeholder="Select"
                      options={this.props.symmetryTaxCodeList.data.Data}
                      value={this.state.SymmetryTaxCodeId}
                      onSelect={this.setSymmetryTaxCodeContext}
                      arrowRenderer={this.showCustomIcon}
                      error={this.state.errors["SymmetryTaxCodeId"]}
                      scrollBy={true}
                    />
                  </div>
                  <div className="clearfix col-lg-12 col-md-12">
                    {this.state.errors["SymmetryTaxCodeId"] && (
                      <p
                        className="error-label"
                        style={{
                          marginTop: "-15px",
                          marginBottom: "20px",
                          float: "left"
                        }}
                      >
                        {this.state.errors["SymmetryTaxCodeId"]}
                      </p>
                    )}
                  </div>
                </div>
              )}

            {this.state.openTaxReportableOnW2Section && (
              <div className="nc-field-group clearfix">
                <div id="deduction-type-question" className="field-name">
                  <span
                    className={utilities.getErrorClassName(
                      this.state.errors["TaxReportableOnW2"]
                    )}
                  >
                    Is this tax reportable on a W-2?
                  </span>
                </div>
                <React.Fragment>
                  <div
                    className="button-type-container col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style={{ marginBottom: "10px" }}
                  >
                    {this.renderTaxReportableOnW2(taxReportableOnW2list)}
                  </div>
                  {this.state.errors["TaxReportableOnW2"] && (
                    <p
                      className="error-label"
                      style={{
                        marginTop: "-15px",
                        marginBottom: "20px",
                        float: "left"
                      }}
                    >
                      {this.state.errors["TaxReportableOnW2"]}
                    </p>
                  )}
                </React.Fragment>
              </div>
            )}

            {this.state.openReportedTaxAndWagesSection && (
              <div>
                <div className="nc-field-group clearfix">
                  <div id="deduction-type-question" className="field-name">
                    <span
                      className={utilities.getErrorClassName(
                        this.state.errors["ReportedTax"]
                      )}
                    >
                      How should this tax be reported?
                    </span>
                  </div>
                  <React.Fragment>
                    <div
                      id="deduction-category-button-list"
                      className="radio-button-group"
                      style={{ marginBottom: "10px" }}
                    >
                      {this.renderReportedTaxType(
                        this.props.deductionReportedTaxList
                      )}
                    </div>
                    {this.state.errors["ReportedTax"] && (
                      <p
                        className="error-label"
                        style={{
                          marginTop: "-15px",
                          marginBottom: "20px",
                          float: "left"
                        }}
                      >
                        {this.state.errors["ReportedTax"]}
                      </p>
                    )}
                  </React.Fragment>
                </div>

                <div className="nc-field-group clearfix">
                  <div id="deduction-type-question" className="field-name">
                    <span
                      className={utilities.getErrorClassName(
                        this.state.errors["WageBaseCode"]
                      )}
                    >
                      What wages should be reported?
                    </span>
                  </div>
                  <React.Fragment>
                    <div
                      id="deduction-category-button-list"
                      className="radio-button-group"
                      style={{ marginBottom: "10px" }}
                    >
                      {this.renderReportedWageslist(
                        this.props.deductionReportedWagesList
                      )}
                    </div>
                    {this.state.errors["WageBaseCode"] && (
                      <p
                        className="error-label"
                        style={{
                          marginTop: "-15px",
                          marginBottom: "0px",
                          float: "left"
                        }}
                      >
                        {this.state.errors["WageBaseCode"]}
                      </p>
                    )}
                  </React.Fragment>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  close() {
    this.setState({
      openBackButtonModal: false,
      openCancelButtonModal: false,
      showDeleteModal: false,
      closeLeavingModal: false,
      isFormUpdated: true,
      openTrackYourProgressModal: false,
      showBackCancelModal: false
    });
    this.props.setFormUpdate(true);
    this.props.setGlobalNavClick(false);
  }

  onOpenTrackYourProgressModal(navLink) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({
      openTrackYourProgressModal: true,
      modalNavLink: navLink,
      isFormUpdated: false
    });
  }

  onOpenTrackYourProgressModal2 = navLink => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({
      openTrackYourProgressModal2: true,
      modalNavLink: navLink,
      isFormUpdated: false
    });
  };

  onCloseTrackYourProgressModal() {
    this.setState({
      openTrackYourProgressModal: false,
      openTrackYourProgressModal2: false,
      isFormUpdated: true
    });
  }

  closeTooltipDeductionName() {
    this.refs.openDeductionOverlay.hide();
  }

  closeTooltipCategoryName() {
    this.refs.openCategoryOverlay.hide();
  }

  showDeductionNamePopover() {
    return (
      <div
        id="open-deduction-description-new-info-popover"
        className="open-deduction-based-popover-for-information-extra-small-type col-xs-12 col-sm-12 col-md-12 col-lg-12"
      >
        <a
          id="deduction-name-tax-tooltip-close-icon"
          className="open-deduction-based-popover-button-close"
          onClick={() => this.setState({ opendeductionnametaxtooltip: false })}
        >
          <i className="fa fa-times-circle fa-lg" />
        </a>
        <div
          id="open-deduction-description-info-popover-data"
          className="deduction-popover-information-data col-xs-12 col-sm-12 col-md-12 col-lg-12"
        >
          <div>
            {" "}
            This is how the deduction will appear in payroll and on the
            employee's paycheck stub.
          </div>
          <div> Ex: Medical Pre-Tax or Roth 401k</div>
        </div>
      </div>
    );
  }

  showTaxabilitySectionPopover() {
    return (
      <div
        id="open-deduction-description-Taxability-info-popover"
        className="open-deduction-based-popover-for-information-extra-small-type col-xs-12 col-sm-12 col-md-12 col-lg-12"
      >
        <a
          id="deduction-taxability-tooltip-close-icon"
          className="open-deduction-based-popover-button-close"
          onClick={() =>
            this.setState({ openInsuranceTaxabilitySection: false })
          }
        >
          <i className="fa fa-times-circle fa-lg" />
        </a>
        <div
          id="deduction-taxability-tooltip-data"
          className="deduction-popover-information-data col-xs-12 col-sm-12 col-md-12 col-lg-12"
        >
          <p>
            {" "}
            A cafeteria plan is a separate written plan maintained by an
            employer for employees that meet the specific requirements and
            regulations of section 125 of the Internal Revenue Code. It provides
            participants an opportunity to receive certain benefits on a pretax
            basis. Participants in a cafeteria plan must be permitted to choose
            among at least one taxable benefit (such as cash) and one qualified
            benefit.
          </p>
          <p>
            A qualified benefit is a benefit that does not defer compensation
            and is excludable from an employee’s gross income under a specific
            provision of the Code, without being subject to the principles of
            constructive receipt. Qualified benefits include the following:
          </p>
          <p>
            1) Accident and health benefits (but not Archer medical savings
            accounts or long-term care insurance)
          </p>
          <p>2) Adoption assistance</p>
          <p>3) Dependent care assistance</p>
          <p>4) Group-term life insurance coverage</p>
          <p>
            5) Health savings accounts, including distributions to pay long-term
            care services
          </p>
          <p>
            The written plan must specifically describe all benefits and
            establish rules for eligibility and elections.
          </p>
          <p>
            A section 125 plan is the only means by which an employer can offer
            employees a choice between taxable and nontaxable benefits without
            the choice causing the benefits to become taxable. A plan offering
            only a choice between taxable benefits is not a section 125 plan.
          </p>
          <p>
            <a
              href="https://www.irs.gov/government-entities/federal-state-local-governments/faqs-for-government-entities-regarding-cafeteria-plans"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i>
                https://www.irs.gov/government-entities/federal-state-local-governments/faqs-for-government-entities-regarding-cafeteria-plans
              </i>
            </a>
          </p>
        </div>
      </div>
    );
  }

  onCloseDeleteModal() {
    this.setState({ showDeleteModal: false, isFormUpdated: true });
    this.props.setFormUpdate(true);
  }

  leavingCancelModal() {
    this.setState({ closeLeavingModal: false, isFormUpdated: true });
    this.props.setFormUpdate(true);
  }

  onOpenDeleteModal() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let pageParamId = this.props.match.params.SystemDeductionId;
    if (this.state.isFormUpdated === false && pageParamId === undefined) {
      this.props.history.push("/system-level-deduction-setup-listing-page");
    } else {
      this.setState({ showDeleteModal: true, isFormUpdated: false });
      this.props.setFormUpdate(false);
    }
  }

  onOpenLoanNumberText() {
    let LoanNumber = this.state.errors["LoanNumber"];
    return (
      <div id="deduction-loan-number" className="content">
        <div className="seperator">
          <hr />
        </div>
        <div className="nc-field-group">
          <div className="field-name">
            <span
              className={utilities.getErrorClassName(
                this.state.errors["LoanNumber"]
              )}
            >
              What is the Loan Number?
            </span>
          </div>

          <div className="input-field col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <NumberFormat
              id="deduction-loan-number-input"
              className="form-control"
              name="LoanNumber"
              value={
                this.state.LoanNumber !== null ? this.state.LoanNumber : ""
              }
              decimalPrecision={0}
              allowNegative={false}
              onChange={this.handleChangeLoanNumber}
              maxLength="2"
            />
            <div className="remove-padding-left-right col-xs-12 col-sm-12 col-md-12 col-lg-12">
              {LoanNumber && (
                <p
                  className="error-label"
                  style={{ marginTop: "5px", marginBottom: "0px" }}
                >
                  {LoanNumber}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  onClickTrackYourProgress(navLink) {
    let errorCount = this.validateRequiredField();
    if (this.state.isFormUpdated) {
      if (errorCount === 0) {
        this.onOpenTrackYourProgressModal2(navLink);
      } else {
        this.onOpenTrackYourProgressModal(navLink);
      }
    } else {
      this.props.history.push(navLink);
    }
  }

  closeToasterMessage = () => {
    this.setState({ showToaster: false });
    this.props.showToaster(false, "");
  };

  render() {
    let isReviewSaveEditPage = false;
    let w2BoxContent = this.showW2ReducedDeduction();
    let associatedAmounts = this.showW2AssociatedDeduction();
    if (this.props.systemDeductionData.UiDraftCompletionStage === 6) {
      isReviewSaveEditPage = true;
    }
    return (
      <div id="deduction-description-page" className="nc-entity-page-container">
        <Prompt
          when={this.state.isFormUpdated}
          message={message.UNSAVED_CHANGES}
        />
        <DeductionSetupDescription />
        <div id="toaster-message" className="toaster-container">
          {this.state.showToaster ? (
            <Toaster
              show={this.state.showToaster}
              message={this.state.messageToaster}
              closeToasterMessage={this.closeToasterMessage}
              alertClassName="danger"
            />
          ) : null}
        </div>
        <div
          id="deduction-description-page-title1"
          className="title-band-container"
        >
          <div className="title-band">
            <h3>Deduction Setup</h3>
          </div>
        </div>

        <div
          id="deduction-track-progress"
          className="progress-tracker-container-mobile hidden-lg hidden-md"
        >
          <SystemLevelDeductionDescriptionStep
            pageId={this.state.pageId}
            onClickTrackYourProgress={(navLink, id) =>
              id !== 1 ? this.onClickTrackYourProgress(navLink) : null
            }
          />
        </div>

        <div
          id="deduction-description-page-title2"
          className="entity-page-content col-lg-9 col-md-8"
        >
          <div className="step-title-container clearfix">
            <span className="step-number badge badge-default">1</span>
            <div
              id="system-level-deduction-description-page"
              className="step-title"
            >
              Description
            </div>
          </div>

          <div className="seperator">
            <hr />
          </div>
          <div className="description-content">
            <div className="entity-form-section-primary">
              <div className="content">
                <div className="nc-field-group clearfix">
                  <div className="field-name">
                    <span
                      className={utilities.getErrorClassName(
                        this.state.errors["SystemDeductionName"]
                      )}
                    >
                      Give this new deduction a name{" "}
                      <a
                        id="system-deduction-name-info-icon"
                        onClick={() =>
                          this.setState({
                            opendeductionnametaxtooltip: !this.state
                              .opendeductionnametaxtooltip
                          })
                        }
                      >
                        {" "}
                        <i className="fa-information-blue fa fa-info-circle" />
                      </a>
                    </span>
                    <div
                      className="inline-field-description col-xs-12 hidden-lg hidden-md hidden-sm"
                      style={{ color: "#999", padding: "5px 0" }}
                    >
                      <i>35 characters max</i>
                    </div>
                  </div>

                  <div className="clearfix" />

                  {this.state.opendeductionnametaxtooltip
                    ? this.showDeductionNamePopover()
                    : null}
                  <div className="clearfix" />

                  <div className="input-field col-lg-6 col-md-8 col-sm-7">
                    <TextInputWithoutLabel
                      id="system-deduction-name-input"
                      name="SystemDeductionName"
                      value={this.state.SystemDeductionName}
                      onChange={this.handleChangeName}
                      disabled={false}
                      error={this.state.errors["SystemDeductionName"]}
                      maxLength="35"
                    />
                  </div>
                  <div className="inline-field-description col-xs-12 col-sm-3 col-md-3 col-lg-5 hidden-xs">
                    &nbsp;<i>35 characters max</i>
                  </div>
                </div>
              </div>
              <div className="seperator">
                <hr />
              </div>
            </div>
            {this.props.userRole === companyConstants.NET_ADMIN &&
            (this.state.SystemDeductionId === undefined ||
              this.state.SystemDeductionId === 0) ? (
              <div id="deduction-code" className="entity-form-section-primary">
                <div className="content">
                  <div className="nc-field-group clearfix">
                    <div className="field-name">
                      <span
                        className={utilities.getErrorClassName(
                          this.state.errors["SystemDeductionCode"]
                        )}
                      >
                        Deduction Code
                      </span>
                      <div
                        className="inline-field-description col-xs-12 hidden-lg hidden-md hidden-sm"
                        style={{ color: "#999", padding: "5px 0" }}
                      >
                        <i>10 characters max</i>
                      </div>
                    </div>

                    <div className="clearfix" />

                    <div className="input-field col-lg-6 col-md-8 col-sm-7">
                      <TextInputWithoutLabel
                        id="system-deduction-code-input"
                        name="SystemDeductionCode"
                        value={this.state.SystemDeductionCode}
                        onChange={this.handleChangeCode}
                        disabled={false}
                        error={this.state.errors["SystemDeductionCode"]}
                        maxLength="10"
                      />
                    </div>
                    <div
                      id="system-deduction-code-input-validation"
                      className="inline-field-description col-xs-12 col-sm-3 col-md-3 col-lg-4 hidden-xs"
                    >
                      <i>&nbsp;10 characters max</i>
                    </div>
                  </div>
                </div>
              </div>
            ) : this.state.SystemDeductionId !== undefined &&
              this.state.SystemDeductionId !== 0 ? (
              <div id="deduction-code" className="entity-form-section-primary">
                <div className="content">
                  <div className="nc-field-group clearfix">
                    <div className="field-name">
                      Deduction Code
                      <div
                        className="inline-field-description col-xs-12 hidden-lg hidden-md hidden-sm"
                        style={{ color: "#999", padding: "5px 0" }}
                      >
                        <i>10 characters max</i>
                      </div>
                    </div>

                    <div className="clearfix" />

                    <div className="input-field col-lg-6 col-md-8 col-sm-7">
                      <TextInputWithoutLabel
                        id="system-deduction-code-input"
                        name="SystemDeductionCode"
                        value={this.state.SystemDeductionCode}
                        onChange={this.handleChangeCode}
                        disabled={true}
                        error={this.state.errors["SystemDeductionCode"]}
                        maxLength="10"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="seperator">
              <hr />
            </div>

            <div className="entity-form-section-primary">
              <div className="content">
                <div className="nc-field-group clearfix">
                  <div className="field-name">
                    <span
                      className={utilities.getErrorClassName(
                        this.state.errors["DeductionCategoryId"]
                      )}
                    >
                      What type of deduction is this?
                    </span>
                  </div>

                  <div
                    id="deduction-category-button-list"
                    className="radio-button-group"
                  >
                    {this.renderDeductionListItem()}
                  </div>
                  <p className="error-label">
                    {this.state.errors["DeductionCategoryId"]}
                  </p>
                </div>
              </div>
            </div>

            <div className="clearfix" />

            {this.state.filteredDeductionDropdownList !== null
              ? this.state.openCategoryDropdownSection
                ? this.showCategoryDropdownSection()
                : null
              : null}

            <div className="clearfix" />

            <div className="entity-form-section-primary">
              {this.state.openloannumbertext
                ? this.onOpenLoanNumberText()
                : null}
            </div>

            <div className="clearfix" />

            {this.state.openTaxabilitySection
              ? this.showTaxabilitySection()
              : null}

            <div className="clearfix" />

            {this.state.openOnlyTaxabilityLinkForRetirementCategory
              ? this.showTaxabilitySectionOnRetirementSelection()
              : null}

            <div className="clearfix" />

            {(this.state.TaxabilityId !== null ||
              this.state.DeductionSubCategoryId ===
                systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K_ROTH ||
              this.state.DeductionSubCategoryId ===
                systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K_ROTH_CATCH_UP ||
              this.state.DeductionSubCategoryId ===
                systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B_ROTH ||
              this.state.DeductionSubCategoryId ===
                systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B_ROTH_CATCH_UP ||
              this.state.DeductionSubCategoryId ===
                systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_457B_ROTH ||
              this.state.DeductionSubCategoryId ===
                systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_457B_ROTH_CATCH_UP ||
              this.state.DeductionSubCategoryId ===
                systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_MOVING_EXPENSES ||
              this.state.DeductionSubCategoryId ===
                systemConstantsId.DEDUCTION_DESCRIPTION_SUB_CATEGORY_INCOME_TAX_WITHHOLDING) &&
              this.state.DeductionSubCategoryId &&
              (w2BoxContent.length > 0 || associatedAmounts.length > 0) && (
                <div
                  id="deduction-taxability-section"
                  className="deduction-page-section"
                  style={{ marginBottom: "20px" }}
                >
                  <W2Box
                    entityName="deduction"
                    w2BoxContent={w2BoxContent}
                    associatedAmounts={associatedAmounts}
                    xsWidth="100%"
                    smWidth="100%"
                    mdWidth="100%"
                    lgWidth="100%"
                    shadow
                  />{" "}
                </div>
              )}
          </div>
        </div>

        <div
          id="deduction-track-progress"
          className="col-lg-3 col-md-4 hidden-sm hidden-xs"
          style={{ marginBottom: "20px" }}
        >
          <SystemLevelDeductionDescriptionStep
            pageId={this.state.pageId}
            onClickTrackYourProgress={(navLink, id) =>
              id !== 1 ? this.onClickTrackYourProgress(navLink) : null
            }
          />
        </div>
        <div className="footer-button-container">
          <div className="seperator">
            <hr />
          </div>
          {isReviewSaveEditPage ? (
            <div className="nc-button-group">
              <button
                type="button"
                id="description-page-save-changes-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDeductionDescription.bind(
                  this,
                  SAVE_TO_SUMMERY_PAGE,
                  ""
                )}
              >
                SAVE CHANGES
              </button>

              <div className="nc-link-group-mobile-single-button">
                <button
                  type="button"
                  id="description-page-revert-back-button"
                  className="secondary-link-button"
                  onClick={this.redirectToSummarygPage}
                >
                  Revert back to last saved
                </button>
              </div>
            </div>
          ) : (
            <div className="nc-button-group">
              <button
                id="deduction-save-and-continue-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDeductionDescription.bind(
                  this,
                  SAVE_AND_CONTINUE,
                  ""
                )}
              >
                CONTINUE
              </button>
              <div className="nc-link-group-mobile">
                <button
                  id="deduction-save-and-finish-later-buttonlink"
                  className="primary-link-button"
                  onClick={this.onOpenFinishLaterButtonModal.bind(this)}
                >
                  Finish Later
                </button>

                <button
                  id="deduction-cancel-buttonlink"
                  className="secondary-link-button"
                  onClick={this.onOpenDeleteModal}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="clearfix" />

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          id="track-progress-modal"
          show={this.state.openTrackYourProgressModal}
          onHide={this.onCloseTrackYourProgressModal}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="track-progress-modal-close-icon"
                onClick={this.onCloseTrackYourProgressModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="track-progress-modal-line1">
                Are you sure you want to leave this page in the middle of making
                edits?
              </div>
              <br />
              <div id="track-progress-modal-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="track-progress-modal-save-button"
                className="nc-button secondary-button"
                onClick={this.exitSetup.bind(
                  this,
                  "LEAVE_THIS_PAGE",
                  this.state.modalNavLink
                )}
              >
                LEAVE THIS PAGE
              </button>

              <button
                type="button"
                id="continue-editing-leaving-modal-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseTrackYourProgressModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          id="track-progress-modal-2"
          show={this.state.openTrackYourProgressModal2}
          onHide={this.onCloseTrackYourProgressModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="track-progress-modal-2-close-icon"
                onClick={this.onCloseTrackYourProgressModal.bind(this)}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="track-progress-modal-2-line1">
                Do you want to save your changes before leaving this page?
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="track-progress-modal-2-save-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDeductionDescription.bind(
                  this,
                  TRACK_YOUR_PROGRESS,
                  this.state.modalNavLink
                )}
              >
                SAVE AND GO
              </button>

              <button
                type="button"
                id="track-progress-modal-2-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseTrackYourProgressModal.bind(this)}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="finish-later-modal-1"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showFinishLaterModalWithoutMandatoryField}
          onHide={this.onCloseFinishLaterModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="finish-later-modal-1-close-icon"
                onClick={this.onCloseFinishLaterModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="finish-later-modal-1-line1">
                Are you sure you want to exit setup in the middle of making
                edits?
              </div>
              <br />
              <div id="finish-later-modal-1-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="finish-later-modal-1-exit-button"
                className="nc-button secondary-button"
                onClick={this.exitSetup.bind(this, "EXIT_SETUP", "")}
              >
                EXIT SETUP
              </button>

              <button
                type="button"
                id="finish-later-modal-1-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseFinishLaterModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="finish-later-modal-2"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showFinishLaterModalWithMandatoryField}
          onHide={this.onCloseFinishLaterModal.bind(this)}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="finish-later-modal-2-close-icon"
                onClick={this.onCloseFinishLaterModal}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="finish-later-modal-2-line1">
                Do you want to save your changes before exiting setup?
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="finish-later-modal-1-exit-button"
                className="nc-button secondary-button"
                onClick={this.saveAndContinueDeductionDescription.bind(
                  this,
                  SAVE_AND_FINISH_LATER,
                  ""
                )}
              >
                SAVE AND EXIT
              </button>

              <button
                type="button"
                id="finish-later-modal-1-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.onCloseFinishLaterModal}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          id="delete-deduction-modal"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showDeleteModal}
          onHide={this.onCloseDeleteModal}
          restoreFocus={false}
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="delete-deduction-modal-close-icon"
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="delete-deduction-modal-line1">
                Are you sure you want to delete this deduction?
              </div>
              <br />
              <div id="delete-deduction-modal-line2">
                It will be gone for now, but you can add it back if you need it.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="delete-deduction-modal-delete-button"
                className="nc-button secondary-button"
                onClick={this.deleteDeduction}
              >
                DELETE DEDUCTION
              </button>

              <button
                type="button"
                id="delete-deduction-modal-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.close}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          bsClass="entity-page-cancel-modal modal"
          backdrop={"static"}
          keyboard={false}
          show={this.state.showBackCancelModal}
          onHide={() => {
            this.setState({
              showBackCancelModal: !this.state.showBackCancelModal
            });
          }}
          id="deduction-unsaved-modal"
        >
          <Modal.Header>
            <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <i
                id="deduction-unsaved-modal-close-icon"
                onClick={this.close}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-row text-center">
              <div id="finish-later-modal-1-line1">
                Are you sure you want to leave this page in the middle of making
                edits?
              </div>
              <br />
              <div id="finish-later-modal-1-line2">
                Changes made on this page will not be saved.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button
                type="button"
                id="finish-later-modal-1-exit-button"
                className="nc-button secondary-button"
                onClick={this.exitSetup.bind(this, "GLOBAL_NAV", "")}
              >
                LEAVE THIS PAGE
              </button>

              <button
                type="button"
                id="deduction-unsaved-modal-continue-button"
                className="nc-button secondary-link-button"
                onClick={this.close}
              >
                Continue Editing
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categoryDeductionList: state.categoryDeductionList,
    subCategoryDeductionList: state.subCategoryDeductionList,
    taxabilityValuesList: state.taxabilityValuesList,
    excludeStatesValuesList: state.excludeStatesValuesList,
    deductionAmountDebitCard: state.deductionAmountDebitCard,
    systemDeductionData: state.systemDeductionData,
    savedSysDeductionPages: state.savedSysDeductionPages,
    sessionContext: state.sessionContext,
    globalNavClick: state.globalNavClick,
    deductionReportedTaxList: state.deductionReportedTaxList,
    userRole: parseInt(state.sessionContext.CurrentAppUiViewId, 10),
    deductionReportedWagesList: state.deductionReportedWagesList,
    symmetryTaxCodeList: state.symmetryTaxCodeList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCategoryDeductionList: getCategoryDeductionList,
      showToaster: showToaster,
      getSubCategoryDeductionList: getSubCategoryDeductionList,
      getTaxabilityValuesList: getTaxabilityValuesList,
      getExcludeStatesValuesList: getExcludeStatesValuesList,
      getDeductionAmountOnDebitCard: getDeductionAmountOnDebitCard,
      setSystemDeductionData: setSystemDeductionData,
      setSaveSysDeductionPage: setSaveSysDeductionPage,
      setLoader: setLoader,
      setFormUpdate: setFormUpdate,
      isOverlayClickable: isOverlayClickable,
      setGlobalNavClick: setGlobalNavClick,
      CallbackToQuickLink: CallbackToQuickLink,
      getReportedTaxTypeList: getReportedTaxTypeList,
      getReportedWagesList: getReportedWagesList,
      getSymmetryTaxCodeList: getSymmetryTaxCodeList
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DeductionDescriptionPage)
);
