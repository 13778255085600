export const PROGRESS_STEP = "@app/common/SET_CURRENT_STEP";
const INITIAL_STATE = {};
const currentStep = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case PROGRESS_STEP:
      return payload;
    default:
      return state;
  }
};
export default currentStep;
/** Action Creators */

export const setcurrentStep = payload => ({
  type: PROGRESS_STEP,
  payload
});
