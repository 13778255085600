import React from "react";
import UserDetails from "../../activity-history/UserDetails";
import MultiSectionListSubitem from "./MultiSectionListSubitem";
import * as util from "../../util";
import "./MultiSectionListItem.css";

const MultiSectionListItem = ({
  EmployeeName,
  EmployeePictureUrl,
  EmployeeUpdateList
}) => (
  <div className="MultiSectionListItem col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <UserDetails
      name={EmployeeName}
      picUrl={
        util.isNullOrEmpty(EmployeePictureUrl)
          ? "https://goo.gl/4AYQmG"
          : EmployeePictureUrl
      }
    />
    <div className="multi-section-list-content col-lg-10 col-md-10 col-sm-12">
      {EmployeeUpdateList.map((item, i) => (
        <MultiSectionListSubitem
          key={i}
          {...item}
          showCircle={EmployeeName !== "Joe Walker"}
        />
      ))}
    </div>
  </div>
);

export default MultiSectionListItem;
