import React from "react";
import Media from "react-media";
import FiltersDesktop from "./FiltersDektop";
import FiltersMobile from "./FiltersMobile";
const Filters = props => (
  <Media query="(max-width: 991px)">
    {isMobile => {
      const Filters = isMobile ? FiltersMobile : FiltersDesktop;
      return <Filters {...props} />;
    }}
  </Media>
);

export default Filters;
