import React from "react";

const Martin = ({ width, height, style }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 85 85"
      style={{ enableBackground: "new 0 0 85 85", ...style }}
      xmlSpace="preserve"
      height={height}
      width={width}
    >
      <style type="text/css" />
      <g>
        <circle className="st9" cx="42.5" cy="42.5" r="42.5" />
      </g>
      <path
        className="st1"
        d="M63,47.1c-0.8-1.7-2.4-2.6-4.2-2.3c0-0.1,0.1-0.3,0.2-0.6c0.3-0.5,1.9-3.4,2-5.3c0.2-2.4-5.9-12.2-10.4-16.9
	L48,19.3l0.8,3.6c0,0.1,2.6,11.5,2,15.3c-0.4,2.7-1.4,2.7-1.8,2.7c-0.9,0-2.7-0.6-5.9-3.7l-0.7-0.7c-2.3-2.2-7-6.7-10.1-8.2
	l-2.3-1.1l-1.1-0.8l0.4,0.5l-2.5-1.1l4.3,4.2c0.1,0.1,5.4,5.4,6.4,8.8l-0.9,2c-1.4,0.6-3.8,0.9-6.9,0.9c-2.2,0-3.9-0.2-4-0.2l-6-0.5
	l2.5,1.1l-0.6,0l1.3,0.3l2.3,1.1c3.1,1.4,9.6,2.1,12.7,2.4l1,0.1c5.6,0.6,6.7,2,6.9,2.5c0.3,0.8-0.4,1.9-1.1,2.7
	c-2.1,2.4-10.1,6.9-13,8.4l-3.3,1.7l3.7,0.3c0.7,0,1.5,0.1,2.3,0.1c6.2,0,15.8-1.5,17.4-3c1.5-1.4,2.6-4.6,2.7-4.9
	c0,0,0.7-1.4,1.9-1.4c1.3,0,3.5,2.1,4.3,4.3l1.6,4l0.4-4.3c0-0.4,0.4-3.7-0.2-5.7C63.1,49.8,63.7,48.5,63,47.1z M60.6,50.8
	c0.2,0.4,0.3,1.1,0.3,1.9c-1.2-1.4-2.8-2.5-4.4-2.5c-2.6,0.1-3.8,2.6-3.8,2.8c-0.3,0.9-1.3,3.3-2.2,4.2c-0.7,0.7-7.7,2.1-14.1,2.4
	c3.5-2,8.3-4.9,10-7c1.5-1.8,2-3.4,1.4-4.8c-0.8-2-3.6-3.3-8.6-3.8l-1-0.1c-0.9-0.1-2.2-0.2-3.6-0.4c1.4-0.2,2.5-0.5,3.3-1l0.3-0.2
	l1.6-3.3l-0.1-0.3c-0.2-1-0.7-2-1.4-3.1c1,0.9,1.9,1.8,2.6,2.5l0.7,0.7C44.7,41.6,47,43,49,43c1.4,0,3.3-0.8,3.9-4.4
	c0.4-2.7-0.4-8.3-1.2-12.2c3.8,4.9,7.3,11.3,7.3,12.3c-0.1,1.3-1.3,3.7-1.8,4.5c-0.5,1.1-0.7,2-0.4,2.7c0.2,0.6,0.7,0.9,1.1,1.1
	l0.3,0.1l0.3-0.1c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,1,0,1.6,1.2c0.4,0.8-0.4,1.7-0.4,1.7l-0.4,0.5L60.6,50.8z"
      />
    </svg>
  );
};

export default Martin;
