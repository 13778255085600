import axios from "axios";
import * as types from "./index";
import * as common from "../../../../app/common/index";

export function getCategoryDeductionList() {
  const url = `${common.WEB_API_URL}/v1/system/deductioncategory`;
  const request = axios.get(url);
  return {
    type: types.COMPANY_FETCH_CATEGORY_OPTIONS_LIST,
    payload: request
  };
}

export function getDeductionValuesList() {
  const url = `${common.WEB_API_URL}/v1/system/deductionsubcategory`;
  const request = axios.get(url);
  return {
    type: types.COMPANY_FETCH_DEDUCTION_VALUES_LIST,
    payload: request
  };
}

export function getTaxabilityValuesList() {
  const url = `${common.WEB_API_URL}/v1/system/deductiontaxoption`;
  const request = axios.get(url);
  return {
    type: types.COMPANY_FETCH_TAXABILITY_VALUES_LIST,
    payload: request
  };
}

export function getW2box14ValuesList() {
  const url = `${common.WEB_API_URL}/v1/app/ui/appview`;
  const request = axios.get(url);
  return {
    type: types.COMPANY_FETCH_W2BOX14_VALUES_LIST,
    payload: request
  };
}

export function getContributionValuesList() {
  const url = `${common.WEB_API_URL}/v1/app/ui/appview`;
  const request = axios.get(url);
  return {
    type: types.COMPANY_FETCH_CONTRIBUTION_VALUES_LIST,
    payload: request
  };
}

export function getExcludeStatesValuesList() {
  const url = `${common.WEB_API_URL}/v1/system/state`;
  const request = axios.get(url);
  return {
    type: types.FETCH_EXCLUDE_STATES_VALUES_LIST,
    payload: request
  };
}

export function getReportedTaxTypeList() {
  const url = `${common.WEB_API_URL}/v1/system/w2Indicator`;
  const request = axios.get(url);
  return {
    type: types.FETCH_REPORTED_TAX_TYPE_LIST,
    payload: request
  };
}

export function getReportedWagesList() {
  const url = `${common.WEB_API_URL}/v1/system/wagebasecode`;
  const request = axios.get(url);
  return {
    type: types.FETCH_REPORTED_WAGES_LIST,
    payload: request
  };
}
