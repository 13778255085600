export const TITLE_BAND = "Alert Setup";

export const pageData = [
  {
    id: 1,
    title: "Employee Hire/Re-Hire",
    titleImage: require("../../../component-library/email-text-alerts/images/icon-5.png"),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim aesse cillum dolore eu fugiat."
  },
  {
    id: 7,
    title: "Missed Punch Report",
    titleImage: require("../../../component-library/email-text-alerts/images/icon-1.png"),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim aesse cillum dolore eu fugiat."
  },
  {
    id: 2,
    title: "Payroll Submit Remainder",
    titleImage: require("../../../component-library/email-text-alerts/images/icon-6.png"),
    description:
      "To help you stay on track, automated email remainders are sent the morning your payroll is scheduled to process."
  },
  {
    id: 3,
    title: "Employee Benefits Eligible",
    titleImage: require("../../../component-library/email-text-alerts/images/icon-7.png"),
    description:
      "To help you stay on track, automated email remainders are sent the morning your payroll is scheduled to process."
  },
  {
    id: 4,
    title: "Employee Salary Change",
    titleImage: require("../../../component-library/email-text-alerts/images/icon-10.png"),
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim aesse cillum dolore eu fugiat."
  }
];

export const typeData = [
  {
    optionId: "IsSendToWorkEmail",
    name: "Work Email",
    buttonIcon: "fa fa-envelope-o",
    label: "Work Email",
    value: 1
  },
  {
    optionId: "IsSendToPersonalEmail",
    name: "Personal Email",
    buttonIcon: "fa fa-envelope-o",
    label: "Personal Email",
    value: 2
  },
  {
    optionId: "IsSendText",
    name: "Text",
    buttonIcon: "fa fa-mobile",
    label: "Text",
    value: 3
  }
];

export const EMPLOYEE_SALARY_CHANGE_ID = 102;
export const REMAINDER_ALERT_ONE_ID = 203;
export const REMAINDER_ALERT_TWO_ID = 203;
export const EMPLOYEE_APPROACHING_OVERTIME_ID = 301;
export const EMPLOYEE_BENEFIT_ELIGIBLE_ID = 401;

//People
export const EMPLOYEE_HIRE_RE_HIRE = "101";
export const EMPLOYEE_SALARY_CHANGE = "102";
export const REQUESTED_TIME_OFF = "103";
export const REQUESTED_TIME_OFF_APPROVED = "104";
export const REQUESTED_TIME_OFF_DENIED = "105";
export const EMPLOYEE_TERMINATION = "106";
export const STORED_VALUE_CARD_ACTIVATION = "107";
//Payroll
export const UN_APPROVED_PTO = "201";
export const TIMECARD_ALERTS = "202";
export const REMAINDER_ALERT_ONE = "203";
export const REMAINDER_ALERT_TWO = "204";
export const PAYROLL_SUBMITTED = "205";
export const WIRE_TRANSFER_INITIATED = "206";
export const WIRE_TRANSFER_RECEIVED = "207";
export const PAYROLL_INVOICED = "208";
//Time & Attendance
export const EMPLOYEE_APPROACHING_OVERTIME = "301";
export const EMPLOYEE_IN_OVERTIME = "302";
export const MISSED_PUNCH_REPORT = "303";
//NetBenefits
export const EMPLOYEE_BENEFIT_ELIGIBLE = "401";
export const BENEFITS_PLAN_RENEWAL = "402";
export const EMPLOYEE_OPEN_ENROLLMENT_NOTIFICATION = "403";
export const EMPLOYEE_COMPLETED_OPEN_INITIAL_ENROLLMENT = "404";
export const EMPLOYEE_CONFIRMED_OPEN_INITIAL_ENROLLMENT = "405";
//Maintenance
export const MAINTENANCE = "500";
export const SHIPPING_CONTACT_W2_ADD = "501";
export const SHIPPING_CONTACT_W2_CHANGE = "502";
export const SHIPPING_CONTACT_W2_DELETE = "503";
//NetHire
export const BACKGROUND_CHECK_RECEIVED = "601";
export const SSN_TRACE_RECEIVED = "602";

//admins
export const NET_ADMIN = "1";
export const CLIENT_ADMIN = "2";

//email-types
export const WORK_EMAIL = 1;
export const PERSONAL_EMAIL = 2;
export const TEXT = 3;

export const EMPLOYEE_RECIPIENT_ROLE_ID = 1;
export const PRIMARY_RECIPIENT_ROLE_ID = 2;
export const SECONDARY_RECIPIENT_ROLE_ID = 3;
export const HIRING_RECIPIENT_ROLE_ID = 4;
export const ADMINISTRATOR_RECIPIENT_ROLE_ID = 5;
export const OTHER_RECIPIENT_ROLE_ID = 6;

//
export const EMPLOYEESTYPE = {
  HOURLY: {
    title: "Hourly Employee",
    discription:
      "Alert when an employee's hourly rate increases or decreases more or less than",
    placeholder: "$",
    sign: "$",
    prefix: "$",
    suffix: "",
    checkBoxId: "HourlyEmployeeCheckbox"
  },
  SALARIED: {
    title: "Salaried Employee",
    discription:
      "Alert when an employee's annual salary increases or decreases more or less than",
    placeholder: "$",
    sign: "$",
    prefix: "$",
    suffix: "",
    checkBoxId: "SalariedEmployeeCheckbox"
  },
  ALL: {
    title: "All Employee",
    discription:
      "Alert when an employee's pay increases or decreases more or less than",
    placeholder: "%",
    sign: "%",
    prefix: "",
    suffix: "%",
    checkBoxId: "AllEmployeeCheckbox"
  }
};

export const emailOptions = [
  {
    id: 1,
    value: 1,
    label: "Employees View"
  },
  {
    id: 2,
    value: 2,
    label: "Managers/Others View"
  }
];

export const textOptions = [
  {
    id: 3,
    value: 1,
    label: "Employees View"
  },
  {
    id: 4,
    value: 2,
    label: "Managers/Others View"
  }
];
