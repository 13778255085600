import axios from "axios";
import * as common from "app/common";
const BASE_WEB_URL = common.WEB_API_URL;
const BASE_MOCK_URL = common.WEB_API_MOCK_URL;

const config = { headers: { "Content-Type": "application/json" } };

const STATE_API_PATH = "/v1/system/state";
export const getStatesList = () =>
  axios.get(BASE_WEB_URL + STATE_API_PATH, config);

const COUNTRY_API_PATH = "/v1/system/country";
export const getCountryList = () =>
  axios.get(BASE_WEB_URL + COUNTRY_API_PATH, config);

const Degree_API_PATH = "/v1/";
export const getDegreeList = () =>
  axios.get(BASE_WEB_URL + Degree_API_PATH, config);

const EMPLOYMENT_HISTORY_PATH = "";
export const deleteEmployementHistory = id =>
  axios.delete(BASE_WEB_URL + EMPLOYMENT_HISTORY_PATH + "/" + id);

const LICENSES_CERTIFICATION_PATH = "";
export const deleteLicensesCertification = id =>
  axios.delete(BASE_WEB_URL + LICENSES_CERTIFICATION_PATH + "/" + id);

const EDUCATION_PATH = "";
export const deleteEducation = id =>
  axios.delete(BASE_WEB_URL + EDUCATION_PATH + "/" + id);

const SKILL_PATH = "";
export const deleteSkill = id =>
  axios.delete(BASE_WEB_URL + SKILL_PATH + "/" + id);

const RESUME_ENTITY_DATA_PATH = "/v1/";
export const fetchResumeEntityData = id =>
  axios.get(BASE_MOCK_URL + RESUME_ENTITY_DATA_PATH + "/" + id);

export const fetchEmploymentHistory = id =>
  axios.get(BASE_WEB_URL + RESUME_ENTITY_DATA_PATH + "/" + id);

const SAVE_EMPLOYMENT_HISTORY_PATH = "";
export const saveEmploymentHistoryData = data =>
  axios.post(BASE_WEB_URL + SAVE_EMPLOYMENT_HISTORY_PATH, data);

const SAVE_LICENSES_CERTIFICATION_PATH = "";
export const saveLicensesCertificationData = data =>
  axios.post(BASE_WEB_URL + SAVE_LICENSES_CERTIFICATION_PATH, data);

const SAVE_EDUCATION_PATH = "";
export const saveEducationData = data =>
  axios.post(BASE_WEB_URL + SAVE_EDUCATION_PATH, data);

const UPDATE_EDUCATION_PATH = "";
export const updateEducationRecord = (id, data) =>
  axios.patch(BASE_WEB_URL + UPDATE_EDUCATION_PATH + "/" + id, data, config);

const UPDATE_SKILL_PATH = "";
export const updateSkillRecord = (id, data) =>
  axios.patch(BASE_WEB_URL + UPDATE_SKILL_PATH + "/" + id, data, config);

const UPDATE_EMPLOYMENT_HISTORY_PATH = "";
export const updateEmploymentHistoryRecord = (id, data) =>
  axios.patch(
    BASE_WEB_URL + UPDATE_EMPLOYMENT_HISTORY_PATH + "/" + id,
    data,
    config
  );

const UPDATE_LICENSES_CERTIFICATION_PATH = "";
export const updateLicensesAndCertificationRecord = (id, data) =>
  axios.patch(
    BASE_WEB_URL + UPDATE_LICENSES_CERTIFICATION_PATH + "/" + id,
    data,
    config
  );

const SAVE_SKILLS_PATH = "";
export const saveSkillsData = data =>
  axios.post(BASE_WEB_URL + SAVE_SKILLS_PATH, data);

const EMPLOYMENT_HISTORY_COMPANY_PATH = "";
export const getEmploymentHistoryCompanyList = () =>
  axios.post(BASE_MOCK_URL + EMPLOYMENT_HISTORY_COMPANY_PATH, config);

const EMPLOYMENT_HISTORY_POSITION_PATH = "";
export const getEmploymentHistoryPositionList = () =>
  axios.post(BASE_MOCK_URL + EMPLOYMENT_HISTORY_POSITION_PATH, config);

const LICENSES_CERTIFICATION_NAME_PATH =
  "/v1/system/licensesandcertificationsname";
export const getLicensesCertificationNameList = () =>
  axios.get(BASE_MOCK_URL + LICENSES_CERTIFICATION_NAME_PATH, config);

const LICENSES_CERTIFICATION_TYPE_PATH =
  "/v1/system/licensesandcertificationstype";
export const getLicensesCertificationTypeList = () =>
  axios.get(BASE_MOCK_URL + LICENSES_CERTIFICATION_TYPE_PATH, config);

const SKILLS_SKILL_PATH = "";
export const getSkillsSkillList = () =>
  axios.post(BASE_MOCK_URL + SKILLS_SKILL_PATH, config);

const SKILLS_YEAR_OF_EXPERIENCE_PATH = "";
export const getSkillsYearOfExperienceList = () =>
  axios.post(BASE_MOCK_URL + SKILLS_YEAR_OF_EXPERIENCE_PATH, config);

const SKILLS_SKILL_LEVEL_PATH = "";
export const getSkillsSkillLevelsList = () =>
  axios.post(BASE_MOCK_URL + SKILLS_SKILL_LEVEL_PATH, config);

const EDUCATION_DEGREE_PATH = "";
export const getEducationDegreeList = () =>
  axios.post(BASE_MOCK_URL + EDUCATION_DEGREE_PATH, config);

const EDUCATION_SCHOOL_PATH = "";
export const getEducationSchoolList = () =>
  axios.post(BASE_MOCK_URL + EDUCATION_SCHOOL_PATH, config);

const CREATE_SKILL_PATH = "";
export const createNewSkill = data =>
  axios.post(BASE_WEB_URL + CREATE_SKILL_PATH, data);
