import axios from "axios";

export const DEFAULT_SEARCH_PARAMS = {
  Fields: ["*"],
  Criterion: [],
  Sort: [],
  PageSize: 100
};

const POST_CONFIG = { headers: { "Content-Type": "application/json" } };

export const postWithParams = (url, params) =>
  axios.post(url, JSON.stringify(params), POST_CONFIG);
