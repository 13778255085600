import React, { Component } from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import {
  spouseBenefitsInfo,
  childBenefitsInfo,
  BenefitsInfoCase
} from "../Constants";

class DependentBenefitsInfo extends Component {
  renderBenefits = componentProps => {
    return Object.keys(componentProps).map(k => {
      return (
        <FormFieldGroup
          key={k}
          {...componentProps[k]}
          block
          colSpan="col-md-5"
          value={this.props.data[k]}
          onDateChange={this.props.handleDateChange}
          onChange={this.props.onChange}
        />
      );
    });
  };

  handleMapData(val) {
    switch (val) {
      case BenefitsInfoCase.spouse:
        return spouseBenefitsInfo;
      case BenefitsInfoCase.child:
        return childBenefitsInfo;
      default:
        return childBenefitsInfo;
    }
  }

  render() {
    const { data } = this.props;
    const mapdata = this.handleMapData(data.relation);
    return (
      <FormSection title="Benefits Info" hr>
        <div className="col-md-7" style={{ padding: "0px" }}>
          {this.renderBenefits(mapdata)}
        </div>
      </FormSection>
    );
  }
}

export default DependentBenefitsInfo;
