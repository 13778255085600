import React from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import * as Fields from "./../FieldNames";
import TerminationReason from "./TerminationReason";
import IncludedInPayroll from "./../active/IncludedInPayroll";
const TerminationDetails = ({ onChange, data = {}, onAddLinkClick }) => {
  return (
    <FormSection title={"Termination Details"} hr>
      <FormFieldGroup
        inputType="singleDatePicker"
        label="Termination Date"
        fieldType="inline"
        inputName={Fields.terminationDate}
        value={data[Fields.terminationDate]}
        colSpan="col-lg-3 col-md-3"
        style={{ marginBottom: "15px" }}
        Size={[4, 4, 12, 12]}
        onChange={onChange}
      />
      <FormFieldGroup
        inputType="radio"
        inputName={Fields.terminationType}
        label="What is the Termination type?"
        fieldType="inline"
        value={data[Fields.terminationType]}
        radios={[
          { id: 1, value: 1, label: "Vountary" },
          { id: 2, value: 2, label: "Involuntary" }
        ]}
        colSpan="col-lg-3 col-md-3"
        Size={[6, 4, 12, 12]}
        onChange={onChange}
      />
      <TerminationReason
        inputName={Fields.terminationReason}
        label="Termination Reason"
        value={data[Fields.terminationReason]}
        onChange={onChange}
        data={data}
        fieldType="inline"
        onAddLinkClick={onAddLinkClick}
        optional
      />
      <FormFieldGroup
        inputType="radio"
        inputName={Fields.rehireEligibility}
        label="Is this employee eligible for rehire?"
        fieldType="inline"
        value={data[Fields.rehireEligibility]}
        radios={[
          { id: 1, value: true, label: "Yes" },
          { id: 2, value: false, label: "No" }
        ]}
        class="col-lg-12"
        colSpan="col-lg-3 col-md-3"
        Size={[6, 4, 12, 12]}
        onChange={onChange}
      />
      <IncludedInPayroll
        inputName={Fields.terminationIncludedInPayroll}
        label="Should this employee be included in payroll ?"
        value={data[Fields.terminationIncludedInPayroll]}
        data={data}
        onChange={onChange}
        middleText="Until which paycheck date"
      />
      <FormFieldGroup
        inputType="textarea"
        inputName={Fields.terminationNotes}
        label="Notes"
        value={data[Fields.terminationNotes]}
        fieldType="inline"
        description="250 characters max"
        rows={6}
        descriptionStyle="italic"
        colSpan="col-lg-3 col-md-3"
        Size={[8, 8, 12, 12]}
        onChange={onChange}
        optional
      />
    </FormSection>
  );
};

export default TerminationDetails;
