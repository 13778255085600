import React from "react";
import { Modal } from "react-bootstrap";
import ModalHeader from "./../../../EmployeeProfileModal/ModalHeader";
import "./../../OverideModal.css";

const EmployeeCopiedModal = ({ show, onCancel, onCreate }) => (
  <Modal
    id="copied-employee-modal"
    bsClass="copied-employee-modal entity-page-cancel-modal modal"
    animation={false}
    backdrop={"static"}
    keyboard={false}
    show={show}
    onHide={onCancel}
    restoreFocus={false}
  >
    <ModalHeader onClose={onCancel} />
    <Modal.Body>
      <div id="copied-employee-modal-body" className="modal-row">
        <div className="modal-header">
          Have you copied this employee to their new company ?
        </div>

        <div style={{ marginBottom: "20px" }}>
          Changing the employee's status to transferred may have an effect on
          the items to be copied such as benefits, earnings,deductions, etc.
        </div>
        <div style={{ fontWeight: 600 }}>
          Please ensure you've copied the employee to the destination company
          prior to making this change!
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <div
        id="cancel-modal-footer"
        className="button-group"
        style={{ textAlign: "left" }}
      >
        <button
          type="button"
          id="save-modal-button"
          className="nc-button primary-button"
          onClick={onCreate}
        >
          Take Me To Employee Copy
        </button>

        <button
          type="button"
          id="save-modal-button"
          className="nc-button secondary-button"
          onClick={onCancel}
        >
          Continue with Status Change
        </button>
      </div>
    </Modal.Footer>
  </Modal>
);
export default EmployeeCopiedModal;
