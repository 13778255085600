import * as types from "../../../actions/companylevel/index";
export default function(state = [], action) {
  switch (action.type) {
    case types.GET_TPP_REVIEW_SAVE_SNAPSHOT:
      return action.payload.data;

    default:
      return state;
  }
}
