import React from "react";
import PropTypes from "prop-types";
import DeductionProrityNoData from "../payroll-dashboard/images/DeductionProrityNoData";
import DeductionsNoData from "../payroll-dashboard/images/DeductionsNoData";
import EarningsGroupsNoData from "../payroll-dashboard/images/EarningsGroupsNoData";
import EarningsNoData from "../payroll-dashboard/images/EarningsNoData";
import MapGLNoData from "../payroll-dashboard/images/MapGLNoData";
import TaxAndTaxCodesNoData from "../payroll-dashboard/images/TaxAndTaxCodesNoData";
import ThirdPartyPayeeNoData from "../payroll-dashboard/images/ThirdPartyPayeeNoData";
import WorkersCompNoData from "../payroll-dashboard/images/WorkersCompNoData";
import * as c from "./SvgConstants";

const HEIGHT = "50px";
const WIDTH = "50px";

const getSvg = id => {
  switch (id) {
    case c.DEDUCTION_PRORITY:
      return <DeductionProrityNoData height={HEIGHT} width={WIDTH} />;
    case c.THIRD_PARTY:
      return <ThirdPartyPayeeNoData height={HEIGHT} width={WIDTH} />;
    case c.TAX_AND_CODES:
      return <TaxAndTaxCodesNoData height={HEIGHT} width={WIDTH} />;
    case c.MAP_GL:
      return <MapGLNoData height={HEIGHT} width={WIDTH} />;
    case c.EARNINGS_GROUPS:
      return <EarningsGroupsNoData height={HEIGHT} width={WIDTH} />;
    case c.EARNINGS:
      return <EarningsNoData height={HEIGHT} width={WIDTH} />;
    case c.DEDUCTIONS:
      return <DeductionsNoData height={HEIGHT} width={WIDTH} />;
    case c.WORKERS_COMP:
      return <WorkersCompNoData height={HEIGHT} width={WIDTH} />;
    default:
      return null;
  }
};

export default getSvg;
getSvg.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
};
getSvg.defaultProps = {
  height: "25px",
  width: "25px"
};
