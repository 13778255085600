import React from "react";
import classnames from "classnames/bind";
import "./InputFieldWithSign.css";
const InputFieldWithSign = props => (
  <div className={props.className}>
    <div style={{ display: "flex" }}>
      {props.showSign && !props.isSuffix && (
        <div className="input-text-sign">
          <span>{props.sign}</span>
        </div>
      )}

      <input
        type="text"
        id={props.inputId}
        name={props.inputName}
        className="form-control"
        placeholder={props.placeholder}
        value={props.value}
        pattern="[0-9]{3}"
        onChange={values => {
          props.onChange &&
            props.onChange({
              name: props.inputName,
              value: values.target.value
            });
        }}
        disabled={props.disabled}
      />
      {props.isSuffix && props.showSign && (
        <div className={classnames("input-text-sign", "__isSuffix")}>
          <span>{props.sign}</span>
        </div>
      )}
    </div>

    {props.error && props.required && (
      <div style={{ marginTop: "5px", fontSize: "15px" }} className="has-error">
        {props.error}
      </div>
    )}
  </div>
);
export default InputFieldWithSign;
InputFieldWithSign.defaultProps = {
  isSuffix: false
};
