/** Actions */
export const SET_FILTER_OPTIONS = "activity-history/SET_FILTER_OPTIONS";

export const SELECT_FILTER_OPTION = "activity-history/SELECT_FILTER_OPTION";
export const SELECT_DATERANGE_OPTION =
  "activity-history/SELECT_DATERANGE_OPTION";
export const SELECT_ALL_FILTER_OPTIONS =
  "activity-history/SELECT_ALL_FILTER_OPTIONS";

export const initialState = {
  options: [],
  selected: {}
};

/** Reducer */
export const filter = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FILTER_OPTIONS: {
      const { options, status } = payload;
      return {
        ...state,
        options,
        status
      };
    }
    case SELECT_FILTER_OPTION: {
      const { option, value } = payload;
      const newSelected = {
        ...state.selected,
        [option]: value
      };
      return {
        ...state,
        selected: newSelected
      };
    }
    case SELECT_ALL_FILTER_OPTIONS: {
      const newSelected = {};
      state.options.forEach(
        ({ value }) => (newSelected[value] = payload.value)
      );
      return {
        ...state,
        selected: newSelected
      };
    }

    case SELECT_DATERANGE_OPTION: {
      const { fromToValue } = payload;
      return {
        ...state,
        selected: fromToValue
      };
    }
    default:
      return state;
  }
};

export default filter;

/** Selector */
export const getData = state => state || initialState;
