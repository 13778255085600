import { combineReducers } from "redux";
import demographicData from "./demographicData";
import emergencyContactData from "./emergencyContact";
import profileData from "./profile";

const personalInfo = combineReducers({
  emergencyContactData,
  demographicData,
  profileData
});

export default personalInfo;
