//call actions related to time card alerts here
import React from "react";
import { connect } from "react-redux";
import TimecardAlertFilters from "../containers/TimecardAlertFilters";
import TimecardAlertTableContainer from "../containers/TimecardAlertTableContainer";
import {
  searchAndSetPostsTimecardAlerts,
  loadAndSetMorePosts,
  fetchTimecardAlerts,
  loadMoreTimecardAlerts,
  approveTimecardAction,
  sendMessageToContactApprover,
  fetchAndSetEmployees,
  fetchAndSetTimeGroups,
  fetchAndSetTimePeriods
} from "../network/actions";
import {
  makeOptions,
  makeSelectedOptions,
  selectAllFilterOptions,
  makeCriterion,
  makeSortCriterion
} from "app/common/reducers/filters";
import MobileHeader from "component-library/payroll-dashboard/multi-section-table/MobileHeader";
import { UnapprovedPto as UnapprovedIcon } from "component-library/payroll-dashboard/images";
import Toaster from "component-library/toasters";

export const getSearchParams = state => {
  const defaultSearchParams = {
    Fields: ["*"],
    Criterion: [],
    Sort: [],
    PageSize: 5
  };
  const filters = state.common.filters;

  let Criterion = [];
  Criterion.push(makeCriterion("Employees", filters.Employees));
  Criterion.push(makeCriterion("TimeGroups", filters.TimeGroups));
  Criterion.push(makeCriterion("TimePeriods", filters.TimePeriods));

  Criterion = Criterion.filter(c => c);

  defaultSearchParams.Criterion = Criterion;
  const sortOrder = state.common.sortOrder;
  let Sort = null;
  Sort = makeSortCriterion(sortOrder);
  defaultSearchParams.Sort = Sort === null ? [] : Sort;

  return defaultSearchParams;
};

const TimecardAlerts = props => (
  <div className="transition-item">
    <MobileHeader
      title="Timecard Alerts"
      onClick={() => {
        props.history.replace("/payroll");
        props.clearPayrollActiveTab();
      }}
      redirectToChecklist={props.redirectToChecklist}
      icon={<UnapprovedIcon width={25} height={25} />}
      badgeCount={props.payrollTypeData.CountOfTimeCardAlerts}
    />
    <div className="toaster-container">
      <Toaster
        show={true}
        message={
          "Some employees have unapproved time cards with missed punches. Missed punches must be resolved first before their time card can be approved."
        }
        alertClassName="warning"
      />
    </div>

    <TimecardAlertFilters {...props} />
    <TimecardAlertTableContainer
      onContactClick={props.onContactClick}
      onContactAllClick={props.onContactAllClick}
    />
  </div>
);

const stateToProps = state => ({
  searchParams: getSearchParams(state),
  payrollDashboardItems: state.payrollDashboard.payrollDashboardItems, //table
  payrollTypeData: state.payrollDashboard.payrollTypeData, //table
  filterData: makeOptions(state.common.filters),
  selectedOptions: makeSelectedOptions(state.common.filters)
});
const actionCreators = {
  searchAndSetPostsTimecardAlerts,
  fetchTimecardAlerts,
  loadMoreTimecardAlerts,
  approveTimecardAction,
  sendMessageToContactApprover,
  loadAndSetMorePosts,
  fetchAndSetEmployees,
  fetchAndSetTimeGroups,
  fetchAndSetTimePeriods,
  selectAll: selectAllFilterOptions
};

export default connect(stateToProps, actionCreators)(TimecardAlerts);
