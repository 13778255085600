import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './NoSnapshotData.css'

//eslint-disable-next-line
const reformatDate = (
  dateStr = "",
  userTimeZoneOffset = "America/Chicago",
  userTimeZoneAbbreviation = "",
  convertUserTimeZone = () => { }
) => {
  let tempTime = convertUserTimeZone(
    userTimeZoneOffset,
    new Date(dateStr),
    "MMM DD, YYYY [at] h:mm A z"
  );
  return tempTime + " " + userTimeZoneAbbreviation;
};

const NoSnapshotData = ({ entity = 'Deduction', onHide }) =>
  <div className='no-snapshot-modal'>
    <div className='no-snapshot-modal-header'>The snapshot data is currently unavailable.</div>
    <div className='no-snapshot-modal-description'>
      {`If this problem continues, please edit the ${entity} and save to generate a new snapshot.`}
    </div>
    <div>
      <Button className="nc-button secondary-button" onClick={onHide}>CLOSE</Button>
    </div>
  </div>

const EntitySnapshotHeader = ({
  data,
  snapshotData,
  onHide,
  onToggleBeforeAfter,
  linkText,
  showValueType,
  userTimeZoneOffset = "",
  userTimeZoneAbbreviation = "",
  convertUserTimeZone = () => { },
  entity
}) =>
  <Modal.Header>
    <div className="close-icon col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <FontAwesomeIcon
        icon={["far", "times-circle"]}
        id="system-level-cancel-button-close-icon"
        onClick={onHide}
      />
    </div>
    {data.length !== 0 ? <div>
      <Modal.Title > {data.EntityInstanceName}</Modal.Title>
      {data.ActivityActionId === 3 && onToggleBeforeAfter && (
        <div className="modal-title-description">
          <div className="before-after-link">
            <button
              className="inpage-back-list"
              onClick={onToggleBeforeAfter}
            >
              {linkText}
            </button>
          </div>
        </div>
      )
      }
    </div>
      :
      snapshotData ? <NoSnapshotData onHide={onHide} entity={entity} /> : <div>Loading...</div>
    }
  </Modal.Header>

export default EntitySnapshotHeader;
