/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import "./Rightbar.css";
import moment from "moment";

const formatTime = timeStr => {
  if (timeStr === "") {
    return "";
  }
  return moment(new Date(timeStr + "+00:00")).format(
    "MMM DD, YYYY [at] h:mm a z"
  );
};
const Sidebar = ({ data, onUploadClick }) => {
  return (
    <div
      className="right-bar-container"
      style={{ padding: "30px", borderLeft: "1px solid #ccc" }}
    >
      <div className="header">
        Employee Files{" "}
        <a style={{ float: "right" }} onClick={onUploadClick}>
          <i className="fa fa-upload" /> Upload
        </a>
      </div>
      <ul className="file-list">
        {data.map(item => (
          <li key={item.id} className="file-list-item">
            <div className="file-name">
              {" "}
              <a>
                <i className={`fa fa-file-${item.type || "alt"}-o`} />
                {item.name}
              </a>
            </div>
            <div className="file-description">
              <i>Upload on {formatTime(item.timeStamp)}</i>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
Sidebar.propTypes = {
  data: PropTypes.array,
  onUploadClick: PropTypes.func
};
Sidebar.defaultProps = {
  onUploadClick: () => alert("onUploadClick"),
  data: []
};
