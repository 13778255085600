import axios from "axios";
import * as types from "./index";
import * as common from "../../../../app/common/index";

export function getGeneralLedgerAccountTypeList() {
  const url = `${common.WEB_API_URL}/v1/app/ui/appview`;
  let request = axios.get(url);
  return {
    type: types.FETCH_GL_ACCOUNT_TYPE_LIST,
    payload: request
  };
}

export function saveGeneralLedgerForm(deductionDescription) {
  const url = `${
    common.WEB_API_URL
  }/v1/System/Deduction/AddDeductionDescription`;
  axios
    .post(url, deductionDescription)
    .then(function(response) {})
    .catch(function(error) {});

  return {
    type: types.SAVE_GENERAL_LEDGER_ACCOUNT
  };
}
