import React from "react";
import "./RaisedRadioButton.css";
import _ from "underscore";

const RaisedRadioButton = props => {
  return (
    <div className="raised-radio-switch">
      {props.radio.map(
        (d, i) =>
          props.multiple ? (
            <span key={d.Id}>
              <input
                type="radio"
                id={d.Name}
                name={d.Name}
                value={d.Id}
                checked={
                  props.value &&
                  props.value.length > 0 &&
                  _.contains(props.value, d.Id)
                }
                onClick={e =>
                  props.onChange && props.onChange(e, props.inputName)
                }
                readOnly
              />
              <label htmlFor={d.Name}>{d.Name}</label>
            </span>
          ) : (
            <span key={d.Id}>
              <input
                type="radio"
                id={d.Name}
                name={props.inputName}
                value={d.Id}
                checked={props.value === d.Id.toString()}
                onChange={e => props.onChange && props.onChange(e)}
              />
              <label htmlFor={d.Name}>{d.Name}</label>
            </span>
          )
      )}
    </div>
  );
};
export default RaisedRadioButton;
