import * as types from "./index";

export function selectCompany(selectedCompany) {
  return {
    type: types.COMPANY_SELECTED,
    payload: selectedCompany
  };
}

export function getCompanyList(data) {
  return {
    type: types.FETCH_COMPANY_LIST,
    payload: data
  };
}
