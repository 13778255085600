import {
  postDemographicData,
  patchDemographicData,
  getDemographicData,
  getEmpData,
  getCountry,
  getState,
  getHobbiesData
} from ".";
import { setLoader } from "app/actions/LoaderActions";
import { editDemographicValues } from "../reducers/demographicData";
import { getDemographicSchema, getEmergencyDataSchema } from "../utils/schema";
import { setEmergencyValues } from "../reducers/emergencyContact";
import { setHobbiesList } from "../reducers/profile";
export const fetchAndSetDemographicData = id => dispatch =>
  getDemographicData(id).then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.length > 0 && Data[0];
    dispatch(editDemographicValues("demographic", dispatchData));
    return dispatchData;
  });

export const setEmployeeData = data => dispatch => {
  let payload = getDemographicSchema(data);
  return postDemographicData(payload).then(res => {});
};

export const updateEmployeeData = (data, id) => dispatch => {
  let payload = getDemographicSchema(data);
  return patchDemographicData(payload, id).then(res => {});
};

export const updateEmployeeEmergencyData = (data, id) => dispatch => {
  let payload = getEmergencyDataSchema(data);
  return patchDemographicData(payload, id).then(res => {});
};

export const fetchAndSetNameTitle = () => dispatch =>
  getEmpData("nametitle").then(res => {
    const {
      data: { Data = [] }
    } = res;
    dispatch(editDemographicValues("nameTitle", Data));
  });

export const fetchAndSetSuffix = () => dispatch =>
  getEmpData("namesuffix").then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      id: item.Id,
      value: item.Id,
      label: item.Name
    }));
    dispatch(editDemographicValues("nameSuffix", dispatchData));
  });

export const fetchAndSetMartialStatus = () => dispatch =>
  getEmpData("maritalstatus").then(res => {
    const {
      data: { Data = [] }
    } = res;
    dispatch(editDemographicValues("martialStatus", Data));
  });

export const fetchAndSetRace = () => dispatch =>
  getEmpData("race").then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      id: item.Id,
      value: item.Id,
      label: item.Name
    }));
    dispatch(editDemographicValues("race", dispatchData));
  });

export const fetchAndSetGender = () => dispatch =>
  getEmpData("gender").then(res => {
    const {
      data: { Data = [] }
    } = res;
    dispatch(editDemographicValues("gender", Data));
  });

export const fetchAndSetCellPhoneCarrier = () => dispatch =>
  getEmpData("cellphonecarrier").then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      id: item.Id,
      value: item.Id,
      label: item.Name
    }));
    dispatch(editDemographicValues("cellphoneCarrier", dispatchData));
  });

export const fetchAndSetCountry = () => dispatch =>
  getCountry().then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      id: item.CountryId,
      value: item.CountryId,
      label: item.Name
    }));
    dispatch(editDemographicValues("countries", dispatchData));
  });

export const fetchAndSetState = (countryID, label) => dispatch =>
  getState(countryID).then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      countryId: item.CountryId,
      id: item.CountryStateId,
      value: item.CountryStateId,
      label: item.Name
    }));
    dispatch(setLoader(false));
    dispatch(editDemographicValues(label, dispatchData));
  });

export const fetchAndSetEmergencyStates = (countryID, label) => dispatch =>
  getState(countryID).then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      countryId: item.CountryId,
      id: item.CountryStateId,
      value: item.CountryStateId,
      label: item.Name
    }));
    dispatch(setLoader(false));
    dispatch(setEmergencyValues(label, dispatchData));
  });

export const fetchAndRelationShip = () => dispatch =>
  getEmpData("relationship").then(res => {
    const {
      data: { Data = [] }
    } = res;
    const dispatchData = Data.map(item => ({
      id: item.Id,
      value: item.Id,
      label: item.Name
    }));
    dispatch(setEmergencyValues("relationship", dispatchData));
  });

export const fetcheAndSetHobbies = id => dispatch =>
  getHobbiesData()
    .then(res => {
      const {
        data: { Data = [] }
      } = res;
      dispatch(setHobbiesList("Hobbies", Data));
    })
    .catch(err => {
      console.log(err);
    });
