export const ENTITY_SNAPSHOT = "@app/common/EDIT_ENTITY_SNAPSHOT";
export const CLEAR_ENTITY_SNAPSHOT = "@app/common/CLEAR_ENTITY_SNAPSHOT";

const entitySnapshot = (state = {}, { type, payload }) => {
  switch (type) {
    case ENTITY_SNAPSHOT:
      return {
        ...state,
        ...payload
      };
    case CLEAR_ENTITY_SNAPSHOT:
      return {};
    default:
      return state;
  }
};

export default entitySnapshot;

export const loadEntitySnapshot = edits => ({
  type: ENTITY_SNAPSHOT,
  payload: edits
});

export const clearEntitySnapshot = () => ({
  type: CLEAR_ENTITY_SNAPSHOT
});
