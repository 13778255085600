import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import OptionsSearch from "./OptionsSearch";
import ModalOption from "./ModalOption";
import renderOption from "./renderOption";
import OptionsModalFooter from "./OptionsModalFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

export default class OptionsModal extends Component {
  renderOptions = options => {
    const {
      multiSelect,
      areAllSelected,
      selectAll,
      selectAllLabel,
      showSelectAll,
      searchText
    } = this.props;
    const allSelected = multiSelect && areAllSelected();
    return (
      <Scrollbars style={{ height: window.innerHeight - 70 }}>
        <ul
          className={
            multiSelect
              ? "nc-dropdown-navigation-multiselect"
              : "nc-dropdown-navigation"
          }
        >
          {multiSelect &&
            showSelectAll &&
            !searchText && (
              <ModalOption
                label={selectAllLabel ? selectAllLabel : "Select All"}
                showCheckbox={true}
                selected={allSelected}
                onSelect={() => selectAll(!allSelected)}
              />
            )}
          {options.map(renderOption(this.props, ModalOption))}
        </ul>
      </Scrollbars>
    );
  };

  renderHeader = () => {
    const { onHide, multiSelect, badgeCount } = this.props;
    let label = "Select One";
    if (multiSelect) {
      if (badgeCount === 0) {
        label = "Select";
      } else {
        label = `Selected (${badgeCount})`;
      }
    }
    return (
      <div className="header-container col-sm-12 col-xs-12 clearfix">
        <div className="close-icon col-sm-3 col-xs-3">
          <FontAwesomeIcon
            icon={faTimesCircle}
            id="system-level-cancel-button-close-icon"
            onClick={onHide}
          />
        </div>
        <div className="sort-label col-sm-6 col-xs-6">{label}</div>
      </div>
    );
  };

  renderFooter = () => {
    const { multiSelect, onHide } = this.props;
    if (!multiSelect) {
      return null;
    }
    return <OptionsModalFooter onHide={onHide} />;
  };

  render() {
    const { props } = this;
    return (
      <Modal
        show={props.show}
        onHide={props.onHide}
        animation={false}
        backdrop={"static"}
        keyboard={false}
        id="dropdown"
      >
        <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
          {this.renderHeader()}
          <hr />
          <OptionsSearch
            searchText={props.searchText}
            editSearchText={props.editSearchText}
            show={props.showSearch}
            onSearchClicked={props.onSearchClicked && props.onSearchClicked}
            showSearchButton={props.showSearchButton && props.showSearchButton}
          />
          {this.renderOptions(props.options)}
          {this.renderFooter()}
        </Modal.Body>
      </Modal>
    );
  }
}
