import React, { Component } from "react";
//import { TabContainer } from "component-library/employee-profile/entity-view";
import DependentBasicInfo from "component-library/employee-profile/dependents/DependentBasicInfo";
import DependentAddress from "component-library/employee-profile/dependents/DependentAddress";
import DependentContactInfo from "component-library/employee-profile/dependents/DependentContactInfo";
import DependentBenefitsInfo from "component-library/employee-profile/dependents/DependentBenefitsInfo";
import DependentVerification from "component-library/employee-profile/dependents/DependentVerification";
import FooterButtonSection from "component-library/employee-profile/FooterButtonSection";
import EntityListBanner from "component-library/entity-list/EntityListBanner";
//import { DEMOGRAPHIC_HEADER } from "../Constants";

class AddDependentsContainer extends Component {
  state = {
    activePanel: {},
    initialData: this.props.data && this.props.data,
    errors: {},
    employeeId: this.props.match.params.EmployeeId,
    SSN: "Show Social Security Number",
    DOB: "Show Birth Date & Age",
    relation: "1"
  };
  componentDidMount() {
    this.props.fetchAndSetCountry();
    this.props.fetchAndSetNameTitle();
    this.props.fetchAndSetSuffix();
    this.props.fetchAndSetGender();
    this.props.fetchAndSetCellPhoneCarrier();
  }

  componentWillUnmount() {
    this.props.clearDemographicValues();
  }

  static getDerivedStateFromProps(props) {
    return {
      initialData: props.data
    };
  }

  onChange = e => {
    let name = e.target ? e.target.name : e.name;
    let value = e.target ? e.target.value : e.value;
    if (name === "country") {
      this.props.fetchAndSetState(value, "currentStates");
    }
    this.setState({
      [name]: value
    });
  };

  handleDateChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  render() {
    return (
      <div>
        <div
          className="tab-container nc-entity-page-container col-lg-9"
          id="tab-container"
        >
          <EntityListBanner
            title="Dependents/Beneficiaries"
            bannerName="Add a Dependent/Beneficiary"
          />
          <DependentBasicInfo data={this.state} onChange={this.onChange} />
          <DependentAddress data={this.state} onChange={this.onChange} />
          <DependentContactInfo data={this.state} onChange={this.onChange} />
          <DependentBenefitsInfo
            data={this.state}
            onChange={this.onChange}
            handleDateChange={this.handleDateChange}
          />
          <DependentVerification
            data={this.state}
            onChange={this.onChange}
            handleDateChange={this.handleDateChange}
          />
          <FooterButtonSection
            primaryButtonLabel="ADD DEPENDENT/BENEFICIARY"
            onCancelClick={() => {}}
            onAddClick={() => {}}
          />
        </div>
      </div>
    );
  }
}

export default AddDependentsContainer;
