import React from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import IncludedInPayroll from "./IncludedInPayroll";
import * as Fields from "../FieldNames";
const RehireDetails = ({ onChange, data = {} }) => {
  return (
    <FormSection title={"Rehire Details"} hr>
      <FormFieldGroup
        inputType="singleDatePicker"
        label="Rehire Date"
        fieldType="inline"
        inputName={Fields.rehireDate}
        value={data[Fields.rehireDate]}
        colSpan="col-lg-3 col-md-3"
        Size={[6, 4, 12, 12]}
        onChange={onChange}
        style={{ marginBottom: "15px" }}
        getToFromValue={onChange}
      />
      <IncludedInPayroll
        label="Should this employee be included in payroll?"
        colSpan="col-lg-3 col-md-3"
        inputName={Fields.rehireIncludedInPayroll}
        data={data}
        value={data[Fields.rehireIncludedInPayroll]}
        onChange={onChange}
        middleText="From which paycheck date"
      />
      <FormFieldGroup
        inputType="textarea"
        inputName={Fields.rehireNotes}
        label="Notes"
        value={data[Fields.rehireNotes]}
        fieldType="inline"
        description="250 characters max"
        rows={6}
        colSpan="col-lg-3 col-md-3"
        Size={[8, 8, 12, 12]}
        onChange={onChange}
        getToFromValue={onChange}
        optional
      />
    </FormSection>
  );
};

export default RehireDetails;
