import W2Summary from "./W2Summary";
import CalculationMethod from "./CalculationMethod";
import DeductionCycle from "./DeductionCycle";
import GeneralLedger from "./GeneralLedger";
import SimpleValue from "./SimpleValue";

const componentForKey = key => {
  switch (key) {
    case "W2Summary":
      return W2Summary;
    case "CalculationMethodSection":
      return CalculationMethod;
    case "DeductionCycleFrequency":
    case "CycleFrequency":
      return DeductionCycle;
    case "GeneralLedger":
      return GeneralLedger;
    default:
      return SimpleValue;
  }
};

export default componentForKey;
