import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import "./InformationGroup.css";
const baseClass = "information-group";
const id = "information-group";
const InformationGroup = props => {
  let className = classNames(props.hr && "hr", baseClass);
  let titleClass = classNames(
    props.hideOnDesktop && "hidden-lg hidden-md",
    baseClass
  );

  return (
    <div id={id} className={className}>
      {props.title && (
        <div
          style={{
            fontStyle: props.titleStyle,
            fontWeight: props.titleStyle === "bold" ? 600 : "",
            marginBottom: "5px"
          }}
          className={titleClass + "-title"}
        >
          {" "}
          {props.title}:
        </div>
      )}
      <div
        style={{ fontWeight: props.contentStyle === "bold" ? "600" : "" }}
        className={className + "-content"}
      >
        {" "}
        {props.children}
      </div>
    </div>
  );
};

export default InformationGroup;
InformationGroup.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  contentStyle: PropTypes.string,
  titleStyle: PropTypes.string,
  hideOnDesktop: PropTypes.bool
};
InformationGroup.defaultProps = {
  content: "",
  children: (
    <div>
      1234 Hwy. 190. <br /> Line 2
    </div>
  ),
  contentStyle: "normal",
  titleStyle: "italic"
};
