import * as types from "../actions/index";
export default function(state = [], action) {
  switch (action.type) {
    case types.SET_MENU_STATE:
      return action.payload;

    default:
      return state;
  }
}
