import React from "react";
import { Modal } from "react-bootstrap";
import ModalHeader from "./../EmployeeProfileModal/ModalHeader";
import "./OverideModal.css";
const PreJobHistoryModal = ({
  show,
  onClose,
  onSave,
  onDiscard,
  buttonLabel = "date"
}) => (
  <Modal
    id="override-termination-modal"
    bsClass="override-termination-modal entity-page-cancel-modal modal"
    animation={false}
    backdrop={"static"}
    keyboard={false}
    show={show}
    onHide={onClose}
    restoreFocus={false}
  >
    <ModalHeader onClose={onClose} />
    <Modal.Body>
      <div id="override-termination-modal-body" className="modal-row">
        <div className="modal-header">
          We noticed you have made other changes on this page.
        </div>
        <div style={{ marginBottom: "20px" }}>
          Before going to Job History, would you like to save these changes or
          discard them ?
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <div
        id="cancel-modal-footer"
        className="button-group"
        style={{ textAlign: "left" }}
      >
        <button
          type="button"
          id="save-modal-button"
          className="nc-button primary-button"
          onClick={onSave}
        >
          Yes, Save Changes
        </button>

        <button
          type="button"
          id="save-modal-button"
          className="nc-button secondary-button"
          onClick={onDiscard}
        >
          No, Discard Changes
        </button>
      </div>
    </Modal.Footer>
  </Modal>
);
export default PreJobHistoryModal;
