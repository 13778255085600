import React from "react";

const SetupStepTitle = ({ stepNumber, title }) => (
  <div className="step-title-container clearfix">
    <span className="step-number badge badge-default">{stepNumber}</span>
    <div className="step-title">{title}</div>
  </div>
);

export default SetupStepTitle;
