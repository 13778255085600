import InputField from "./InputField";
import NumberField from "./NumberField";
import HourlyRate from "./../radioButton/HourlyRate";
import renderRadioButtons from "./../radioButton/renderRadioButtons";
import DropDown from "./../dropDown/DropDown";
import Textarea from "./Textarea";
import CheckboxGroup from "./../checkBoxGroup/index";
import payrollFrequency from "./../misc/payrollFrequency";
import RadioButtonWithLink from "./../radioButton/RadioButtonWithLink";
import IconCheckboxGroup from "../payroll-holiday-calendar/IconCheckboxGroup";
import RadioButtonWithAddLink from "./../radioButton/RadioButtonWithAddLink";
import SingleDatePicker from "../payroll-holiday-calendar/SingleDatePicker";
import DateRangePicker from "../daterangepicker";
import RaisedRadioButton from "../employee-profile/RaisedRadioButton";
import InputWithRaisedRadioButtons from "../employee-profile/InputWithRaisedRadioButtons";
import LabelWithIcon from "../employee-profile/LabelWithIcon";
import MultiSelectFrequencyTypeButton from "../email-text-alerts/edit-page-email-text-alerts/multi-select-radio-buttons/index";
import OverrideWithComponent from "./OverrideWithComponent";
import * as Constants from "./Constants";
import InputFieldWithSign from "./InputFieldWithSign";
import CustomRadioTab from "./CustomRadioTab";
import DependentCircularCheckbox from "../employee-profile/dependents/DependentCircularCheckbox";
import NoEndDateComponent from "./NoEndDateComponent";
import AddNewNoteType from "../employee-profile/employee-note/AddNewNoteType";
import InputRangeField from "./InputRangeField";

const getInputComponent = key => {
  switch (key) {
    case Constants.TEXT_INPUT:
      return InputField;
    case Constants.NUMERIC_INPUT:
      return NumberField;
    case Constants.TEXTAREA_INPUT:
      return Textarea;
    case Constants.RADIO_INPUT:
      return renderRadioButtons;
    case Constants.DROPDOWN:
      return DropDown;
    case Constants.HOURLY_RATE:
      return HourlyRate;
    case Constants.CHECKBOX:
      return CheckboxGroup;
    case Constants.RADIO_WITH_ICON:
      return RadioButtonWithLink;
    case Constants.FREQUENCY_INPUT:
      return payrollFrequency;
    case Constants.ICON_CHECKBOX:
      return IconCheckboxGroup;
    case Constants.RADIO_BUTTON_WITH_ADD_LINK:
      return RadioButtonWithAddLink;
    case Constants.SINGLE_DATE_PICKER:
      return SingleDatePicker;
    case Constants.DATE_RANGE_PICKER:
      return DateRangePicker;
    case Constants.WORK_EMAIL_COMPONENT:
      return MultiSelectFrequencyTypeButton;
    case Constants.RAISED_RADIO_BUTTONS:
      return RaisedRadioButton;
    case Constants.LABEL_WITH_ICON:
      return LabelWithIcon;
    case Constants.TEXT_WITH_FLAT_RADIO_BUTTONS:
      return InputWithRaisedRadioButtons;
    case Constants.TEXT_WITH_SIGN_INPUT:
      return InputFieldWithSign;
    case Constants.OVERRIDE_WITH_COMPONENT:
      return OverrideWithComponent;
    case Constants.CUSTOM_RADIO_TAB:
      return CustomRadioTab;
    case Constants.CIRCULAR_CHECKBOX:
      return DependentCircularCheckbox;
    case Constants.DROPDOWN_WITH_LINK:
      return AddNewNoteType;
    case Constants.INPUT_RANGE:
      return InputRangeField;
    case Constants.NO_END_DATE:
      return NoEndDateComponent;
    default:
      return InputField;
  }
};

export default getInputComponent;
