export const SET_MENU_STATE = "SET_MENU_STATE";
export const CLEAR_MENU_STATE = "@app/global-navigation/CLEAR_MENU_STATE";

const setGlobalSetup = (state = [], { type, payload }) => {
  switch (type) {
    case SET_MENU_STATE:
      return payload;
    case CLEAR_MENU_STATE:
      return [];
    default:
      return state;
  }
};

export default setGlobalSetup;

export const setMenuState = options => ({
  type: SET_MENU_STATE,
  payload: options
});
