import React from "react";
//import formatTime from "./formatTime";

const ActivityTimestamp = ({
  timestamp,
  userTimeZoneOffset = "America/Chicago",
  convertUserTimeZone,
  userTimeZoneAbbreviation = "",
  id
}) => (
  <div
    id="timestamp-value"
    className="timestamp-column-value col-lg-2 col-md-2 col-sm-12 col-xs-12"
  >
    <div>
      <span className="field-header visible-sm-inline visible-xs-inline">
        Timestamp:
      </span>
      <span id={"time-stamp-value-" + id}>
        {convertUserTimeZone(
          userTimeZoneOffset,
          new Date(timestamp),
          "MMM DD, YYYY [at] h:mm A z"
        )}{" "}
        {userTimeZoneAbbreviation}
      </span>
    </div>
  </div>
);

export default ActivityTimestamp;
