import { combineReducers } from "redux";
import * as types from "../actions/index";

function isDisplaySideMenuPanel(state = null, action) {
  switch (action.type) {
    case types.SIDE_MENU_PANEL:
      return action.payload;

    default:
      return state;
  }
}

function isDisplaySideMenuBar(state = null, action) {
  switch (action.type) {
    case types.SIDE_MENU_BAR:
      return action.payload;

    default:
      return state;
  }
}

function isDisplayMobileProductSuite(state = null, action) {
  switch (action.type) {
    case types.MOBILE_PRODUCT_SUITE:
      return action.payload;

    default:
      return state;
  }
}

function isDisplayMainPageOverlayPanel(state = null, action) {
  switch (action.type) {
    case types.MAIN_PAGE_OVERLAY:
      return action.payload;

    default:
      return state;
  }
}

function menuParentName(state = null, action) {
  switch (action.type) {
    case types.MENU_PARENT_NAME:
      return action.payload;

    default:
      return state;
  }
}

function currentMenuName(state = null, action) {
  switch (action.type) {
    case types.CURRENT_MENU_NAME:
      return action.payload;

    default:
      return state;
  }
}

const menuReducer = combineReducers({
  isDisplaySideMenuPanel,
  isDisplaySideMenuBar,
  isDisplayMainPageOverlayPanel,
  isDisplayMobileProductSuite,
  menuParentName,
  currentMenuName
});

export default menuReducer;
