import React from "react";
import { Switch, Route } from "react-router-dom";

//System Level Deductions
import SystemLevelDeductionSetup from "./deductionsetup/DeductionSetup";
import DeductionDescriptionPage from "./deductionsetup/DeductionDescriptionPage";
import CalculationsMethodPage from "./deductionsetup/CalculationsMethodPage";
import DeductionCyclePage from "./deductionsetup/DeductionCyclePage";
import OptionPage from "./deductionsetup/OptionPage";
import DeductionReviewAndSavePage from "./deductionsetup/DeductionReviewAndSavePage";
import DefaultValuePage from "./deductionsetup/DefaultValuePage";

const SystemLevelRoutes = () => (
  <Switch>
    <Route
      path="/system-level-deduction-setup-listing-page"
      component={SystemLevelDeductionSetup}
    />

    <Route
      path="/system-level-deduction-description/:SystemDeductionId"
      component={DeductionDescriptionPage}
    />

    <Route
      path="/system-level-deduction-description"
      component={DeductionDescriptionPage}
    />

    <Route
      path="/system-level-calculation-method/:SystemDeductionId"
      component={CalculationsMethodPage}
    />

    <Route
      path="/system-level-calculation-method"
      component={CalculationsMethodPage}
    />

    <Route
      path="/system-level-deduction-cycle/:SystemDeductionId"
      component={DeductionCyclePage}
    />

    <Route
      path="/system-level-deduction-cycle"
      component={DeductionCyclePage}
    />

    <Route
      path="/system-level-deduction-options/:SystemDeductionId"
      component={OptionPage}
    />

    <Route path="/system-level-deduction-options" component={OptionPage} />

    <Route
      path="/system-level-review-and-save/:SystemDeductionId"
      component={DeductionReviewAndSavePage}
    />

    <Route
      path="/system-level-review-and-save"
      component={DeductionReviewAndSavePage}
    />

    <Route
      path="/system-level-deafult-value/:SystemDeductionId"
      component={DefaultValuePage}
    />

    <Route path="/system-level-deafult-value" component={DefaultValuePage} />
  </Switch>
);

export default SystemLevelRoutes;
