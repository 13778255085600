import {
  ENTITY_FILTER_NAME,
  CATEGORY_FILTER_NAME,
  STATUS_FILTER_NAME
} from "component-library/earnings-list";
import { sortComparator } from "app/common/reducers/sortOrder";
import { Fields } from "component-library/earnings-setup/fieldNames";
import React from "react";
import * as Constants from "../pages/Constants";
import { PAY_CODES } from "./../pages/Constants";
import { store } from "index";
import { setDataFetchLoader } from "app/reducers/LoaderReducer";
import {
  SAVE_GO,
  LEAVE_PAGE,
  SAVE_EXIT,
  EXIT,
  toggleBackMessage,
  finishLaterMessage,
  DELETE_EARNINGS,
  deleteMessage,
  CALCULATION_METHOD_TRACK_DOLLARS_TOASTER,
  CALCULATION_METHOD_TRACK_HOURS_TOASTER,
  CALCULATION_METHOD_TRACK_HOURS_TOASTER_CLIENT_ADMIN
} from "./../pages/company-level/CompanyConstants";
import { CLIENT_ADMIN } from "component-library/employee-profile/resume/utils/Constants";
/** The function checks whether the value is selected or not*/
const isSelected = (selected = {}, key) => selected[key];
/**The function returns the object in a format specified for the system earning.
 *Usage: List page filter
 */
const mapEarning = (item, entityName) => ({
  id: item.Id,
  name: item.Name,
  category: item.EarningCategoryName,
  categoryId: item.EarningCategoryId,
  statusId: item.CreateWorkFlowStateId,
  status: item.CreateWorkFlowStateName,
  systemEarningId: item.SystemEarningId,
  useCount: item.UseCount,
  activityInstanceId: item.ActivityInstanceId,
  redirectionStep: item.UIDraftCompletionStage,
  canEdit: true,
  canDelete: true
});
/**The function returns the object in a format specified for the company earning
 *Usage: List page filter
 */
const mapCompanyEarning = (item, isClientAdmin) => ({
  id: item.Id,
  name: item.Name,
  category: item.EarningCategoryName,
  categoryId: item.EarningCategoryId,
  statusId: item.CreateWorkFlowStateId,
  status: item.CreateWorkFlowStateName,
  companyEarningId: item.CompanyEarningId,
  useCount: item.UseCount,
  activityInstanceId: item.ActivityInstanceId,
  redirectionStep: item.UIDraftCompletionStage,
  canEdit: !(
    item.PayrollIsInprogress &&
    item.CreateWorkFlowStateId === Constants.CALCULATION_STEP &&
    isClientAdmin
  ),
  isSystemSetupEarning:
    isClientAdmin &&
    PAY_CODES.includes(String(item.CompanyEarningCode).toUpperCase()),
  canDelete:
    !(
      item.PayrollIsInprogress &&
      item.CreateWorkFlowStateId === Constants.CALCULATION_STEP &&
      isClientAdmin
    ) &&
    !(
      isClientAdmin &&
      PAY_CODES.includes(String(item.CompanyEarningCode).toUpperCase())
    ),
  code: item.CompanyEarningCode,
  calculationMethod: item.CalculationMethod
});
/**The function returns the object in a format specified for the company earning
 *Usage: Add Modal Company Earnings
 */
const mapCompanyEarningById = (item, entityName) => ({
  value: item.Id,
  label: item.Name,
  category: item.EarningCategoryName,
  categoryId: item.EarningCategoryId,
  statusId: item.CreateWorkFlowStateId,
  status: item.CreateWorkFlowStateName,
  companyEarningId: item.CompanyEarningId,
  useCount: item.UseCount,
  redirectionStep: item.UIDraftCompletionStage
});
/**The function "getFilteredEarnings" returns the filtered list of system earnings
 * based on the filter options
 */
export const getFilteredEarnings = state => {
  const {
    common: {
      filters,
      sortOrder,
      entityList: { data = [] }
    }
  } = state;
  const allData = (data || []).map(mapEarning);
  const filteredData = allData.filter(
    item =>
      isSelected((filters[ENTITY_FILTER_NAME] || {}).selected, item.id) &&
      isSelected(
        (filters[CATEGORY_FILTER_NAME] || {}).selected,
        item.categoryId
      ) &&
      isSelected((filters[STATUS_FILTER_NAME] || {}).selected, item.statusId)
  );
  if (sortOrder.Order) {
    return filteredData.sort(sortComparator(sortOrder));
  } else {
    return filteredData;
  }
};
/**The function "getFilteredCompanyEarnings" returns the filtered list of company earnings
 * based on the filter options
 */

export const getFilteredCompanyEarnings = state => {
  const {
    common: {
      filters,
      sortOrder,
      entityList: { data = [] }
    }
  } = state;
  let allData = [];
  data.map(item =>
    allData.push(
      mapCompanyEarning(
        item,
        parseInt(state.sessionContext.CurrentAppUiViewId, 10) ===
        Constants.CLIENT_ADMIN
      )
    )
  );
  const filteredData = allData.filter(
    item =>
      isSelected(
        (filters[ENTITY_FILTER_NAME] || {}).selected,
        item.companyEarningId
      ) &&
      isSelected(
        (filters[CATEGORY_FILTER_NAME] || {}).selected,
        item.categoryId
      ) &&
      isSelected((filters[STATUS_FILTER_NAME] || {}).selected, item.statusId)
  );
  if (sortOrder.Order) {
    return filteredData.sort(sortComparator(sortOrder));
  } else {
    return filteredData;
  }
};
/** The function "getFilteredCompanyEarningsById" returns the active Earnings
 *  and is used for the add Modal on Company Earnings List Page
 */
export const getFilteredCompanyEarningsById = state => {
  const {
    common: { filters, sortOrder, entityList }
  } = state;
  const allData = (entityList || []).map(mapCompanyEarningById);
  const filteredData = allData.filter(
    item =>
      isSelected(
        (filters[ENTITY_FILTER_NAME] || {}).selected,
        item.companyEarningId
      ) &&
      isSelected(
        (filters[CATEGORY_FILTER_NAME] || {}).selected,
        item.categoryId
      )
  );
  const filterActiveEarning = filteredData.filter(
    val => val.statusId === Constants.CALCULATION_STEP
  );
  if (sortOrder.Order) {
    return filterActiveEarning.sort(sortComparator(sortOrder));
  } else {
    return filterActiveEarning;
  }
};
/**The function "composeToasterMessage" is used for composing the toaster message
 * for the entities with some subcategories which has taxability or PaidToEmployee fields read-only
 */
export function composeToasterMessage(Toastername, data = {}) {
  var message = <span />;
  if (Toastername === Fields.paidToEmployee) {
    let value = data[Fields.paidToEmployee] ? "" : " not";
    let article =
      data[Fields.subcategoryId] === Constants.OTHER_PAID_FRINGE_BENEFIT ||
        data[Fields.subcategoryId] === Constants.OTHER_NON_PAID_FRINGE_BENEFIT
        ? "an"
        : "a";
    message = (
      <span>
        You have selected&nbsp;{article}&nbsp;{data[Fields.subcategoryName]}
        &nbsp;earnings. By default, this earnings is<b>{value}</b>&nbsp;paid.
      </span>
    );
  } else if (Toastername === Fields.earningsTrack) {
    if (data[Fields.calculationMethodId] === 1) {
      return CALCULATION_METHOD_TRACK_DOLLARS_TOASTER;
    } else {
      if (data.userRole === CLIENT_ADMIN) {
        return CALCULATION_METHOD_TRACK_HOURS_TOASTER_CLIENT_ADMIN;
      }
      return CALCULATION_METHOD_TRACK_HOURS_TOASTER;
    }
  } else {
    let value = data[Fields.earningsTaxable] ? "" : " not";
    let article =
      data[Fields.subcategoryId] === Constants.OTHER_PAID_FRINGE_BENEFIT ||
        data[Fields.subcategoryId] === Constants.OTHER_NON_PAID_FRINGE_BENEFIT
        ? "an"
        : "a";
    message = (
      <span>
        You have selected&nbsp;{article}&nbsp;{data[Fields.subcategoryName]}
        &nbsp;earnings. By default, this earnings is<b>{value}</b>&nbsp;taxable.
      </span>
    );
  }
  if (Toastername === Fields.earningGroups) {
    message = (
      <span>
        If an earnings has been used in payroll, it cannot be added to or
        removed from an Earnings Group that is currently associated with a
        calculation method of a deduction that has been used in payroll. Please
        contact your Netchex customer service representative for assistance.
      </span>
    );
  }
  return message;
}
/**The function "getadvancedTaxOptions" is used for generating the array for showing the
 * Advanced tax options data in W2 Box Summary on Description Pages
 */
export function getadvancedTaxOptions(data) {
  let TaxOptions = [];
  let value = data[Fields.increaseFUTAWages] ? "Yes" : "No";
  TaxOptions.push(["Included in FUTA Wages: ", value]);
  value = data[Fields.increaseSUTAWages] ? "Yes" : "No";
  TaxOptions.push(["Included in SUTA Wages: ", value]);
  value = data[Fields.increaseSDIWages] ? "Yes" : "No";
  TaxOptions.push(["Included in SDI Wages: ", value]);
  value =
    data[Fields.increaseFederalWages] && data[Fields.excludeFromFederalTax]
      ? "Yes"
      : "No";
  TaxOptions.push(["Excluded from Federal Wages: ", value]);
  value =
    data[Fields.increaseLocalWages] && data[Fields.excludeFromLocalTax]
      ? "Yes"
      : "No";
  TaxOptions.push(["Excluded from Local Wages: ", value]);
  value =
    data[Fields.increaseStateWages] && data[Fields.excludeFromStateTax]
      ? "Yes"
      : "No";
  TaxOptions.push(["Excluded from State Wages: ", value]);
  return TaxOptions;
}
/**The function inputs the Earning Groups as an object in `data`
 * -converts it to the array of numbers.
 * -splice the elements from the arrays which were having the value as `false`.
 * -creates an array of {id,label} for elements having the value as `true`
 */
export function getEarningGroups(options = [], data = {}) {
  let Groups = [];
  Groups = Object.keys(data);
  Groups = Groups.map(Number);
  Groups.forEach((element, index) => {
    if (!data[element]) {
      Groups.splice(index, 1);
    }
  });
  let Response = [];
  Groups.forEach(element => {
    const { label } =
      options.find(option => "" + option.optionId === "" + element) || {};
    Response.push({ id: element, label });
  });
  return Response;
}
/**The function returns the filter count as a number for all the selected options of all the filters.
 * The count is used for the mobile view of the list page
 */
export function getFilterCount(selectedOptions = {}, options = {}) {
  let count = 0;
  const { category, status, entity } = selectedOptions;
  const c = options.category;
  const s = options.status;
  const e = options.entity;
  let c_count = 0;
  let s_count = 0;
  let e_count = 0;
  if (category && status && entity) {
    c.forEach(({ value }) => {
      if (typeof category[value] === "boolean" && category[value]) c_count++;
    });
    s.forEach(({ value }) => {
      if (typeof status[value] === "boolean" && status[value]) s_count++;
    });
    e.forEach(({ value }) => {
      if (typeof entity[value] === "boolean" && entity[value]) e_count++;
    });
    if (c.length !== c_count || c_count === 0) {
      count += c_count;
    }
    if (s.length !== s_count || s_count === 0) {
      count += s_count;
    }
    if (e.length !== e_count || e_count === 0) {
      count += e_count;
    }
  }
  return count;
}

export const checkIfCanCopy = state => {
  const {
    navigationReducer: { companySelectionList = [] }
  } = state;

  let hasActiveEntites = false;
  let companyData = companySelectionList;

  if (companyData.length !== 0) {
    if (companyData.Data[0].CompanySelectionList.length > 1) {
      const {
        entityList: { data = [] }
      } = state.common;
      hasActiveEntites = data.find(item => item.CreateWorkFlowStateId === 2);
    }
  }
  return hasActiveEntites;
};

export const getStatusDescription = Id => {
  let description = "";
  if (Id === 1)
    description =
      "Earnings creation has started but has not been completed. Earnings cannot be assigned to an employee or used in payroll.";
  else if (Id === 2)
    description =
      "Earnings can be assigned to an employee and can be used in payroll.";
  else if (Id === 3)
    description =
      "Earnings has been previously assigned to an employee or used in payroll but is currently not active.  Earnings cannot be assigned to an employee or used in payroll unless made active.";
  return description;
};

export function toggleLoader(loading) {
  const state = store.getState();
  const { dataFetchLoader } = state;

  if (!loading && dataFetchLoader) {
    store.dispatch(setDataFetchLoader(false));
  }
  if (loading && !dataFetchLoader) {
    store.dispatch(setDataFetchLoader(true));
  }

  return loading;
}

export const makeModal = (ACTION, showCancelModal, data = {}) => {
  switch (ACTION) {
    case "DELETE":
      return {
        line1: deleteMessage["line1"],
        line2: deleteMessage["line2"],
        buttonLabel: DELETE_EARNINGS,
        showCancelModal,
        ...data
      };
    case "CANCEL":
      return { showCancelModal, ...data };
    case "LEAVE_THIS_PAGE":
      return {
        line1: toggleBackMessage["line3"],
        line2: toggleBackMessage["line4"],
        buttonLabel: LEAVE_PAGE,
        showCancelModal,
        ...data
      };
    case "SAVE_AND_GO":
      return {
        line1: toggleBackMessage["line1"],
        buttonLabel: SAVE_GO,
        line2: toggleBackMessage["line2"],
        showCancelModal,
        ...data
      };
    case "SAVE_AND_EXIT":
      return {
        line1: finishLaterMessage["line1"],
        buttonLabel: SAVE_EXIT,
        line2: finishLaterMessage["line2"],
        showCancelModal,
        ...data
      };
    case "EXIT_SETUP":
      return {
        line1: finishLaterMessage["line3"],
        line2: finishLaterMessage["line4"],
        buttonLabel: EXIT,
        showCancelModal,
        ...data
      };
    default:
      return { showCancelModal: false, ...data };
  }
};

export function numberWithCommas(x = "") {
  x = String(x);
  var parts = x.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function removeCommas(x = "") {
  x = String(x);

  return x.replace(/,/g, "");
}

export function resetToPrevious(field, data = {}) {
  return data[field];
}
