import { combineReducers } from "redux";
import employeeNotes from "./employeeNotes";
import addEmployeeNotes from "./addEmployeeNotes";

const Others = combineReducers({
  employeeNotes,
  addEmployeeNotes
});

export default Others;
