import {
  EasterGrey,
  GoodFridayGrey,
  CustomGrey,
  IndependenceGrey,
  ChristmasGrey,
  MemorialGrey,
  ThanksgivingGrey,
  NewYearsDayGrey,
  VeteransGrey,
  MartinLutherGrey,
  LaborGrey,
  MardiGrasGrey,
  ColumbusGrey,
  PresidentsGrey
} from "./Images/GreyscaleImages";

const greyHolidayIcon = id => {
  switch (id) {
    case 101:
      return NewYearsDayGrey;
    case 102:
      return MartinLutherGrey;
    case 103:
      return PresidentsGrey;
    case 104:
      return MemorialGrey;
    case 105:
      return IndependenceGrey;
    case 106:
      return LaborGrey;
    case 107:
      return ColumbusGrey;
    case 108:
      return VeteransGrey;
    case 109:
      return ThanksgivingGrey;
    case 110:
      return ChristmasGrey;
    case 201:
      return NewYearsDayGrey;
    case 202:
      return MartinLutherGrey;
    case 203:
      return PresidentsGrey;
    case 204:
      return MemorialGrey;
    case 205:
      return IndependenceGrey;
    case 206:
      return LaborGrey;
    case 207:
      return ColumbusGrey;
    case 208:
      return VeteransGrey;
    case 209:
      return ThanksgivingGrey;
    case 210:
      return ChristmasGrey;
    case 211:
      return EasterGrey;
    case 212:
      return GoodFridayGrey;
    case 213:
      return MardiGrasGrey;
    default:
      return CustomGrey;
  }
};

export default greyHolidayIcon;
