import { connect } from "react-redux";
import Profile from "../containers/Profile";
import { fetcheAndSetHobbies } from "../network/actions";
import {
  selectFilterOption,
  setFilterOptions,
  makeSelectedOptions,
  makeOptions
} from "app/common/reducers/filters";
import { clearHobbiesList } from "../reducers/profile";
const stateToProps = state => ({
  filters: makeOptions(state.common.filters),
  selectedOptions: makeSelectedOptions(state.common.filters),
  profileData: state.personalInfo.profileData
});
const actionCreators = {
  selectOption: selectFilterOption,
  setOptions: setFilterOptions,
  fetcheAndSetHobbies,
  clearHobbiesList
};
export default connect(stateToProps, actionCreators)(Profile);
