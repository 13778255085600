import React from "react";
import * as Constants from "./Constants";

const InputField = props => (
  <div className={props.className}>
    <input
      id={props.inputId}
      type={Constants.TEXT_INPUT}
      name={props.inputName}
      className="form-control"
      placeholder={props.placeholder}
      value={props.value || ""}
      onChange={e => props.onChange && props.onChange(e.target)}
      onKeyUp={e => {
        if (e.keyCode === 13) {
          e.stopPropagation();
          e.target.blur();
        }
      }}
      maxLength={props.maxLength}
      pattern={props.pattern}
      disabled={props.disabled}
    />
    {props.error &&
      props.required && (
        <div
          style={{ marginTop: "5px", fontSize: "15px" }}
          className="has-error"
        >
          {props.error}
        </div>
      )}
  </div>
);
export default InputField;
