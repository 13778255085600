import React from "react";
import ColumnTitle, {
  ORDER_ASC,
  ORDER_DESC
} from "../activity-history/ActivityHistoryColumnTitle";

const FieldSectionHeader = ({
  columns = [],
  sortOrder: { Field, Order } = {},
  changeSortOrder
}) => (
  <div className="row table-header visible-lg visible-md">
    <div className="col-lg-12 col-md-12 default-calender-table-header">
      {columns
        .slice(0)
        .map(({ label, field, className, noSort, id, style }) => (
          <ColumnTitle
            key={field}
            label={label}
            className={className}
            order={field === Field && Order}
            noSort={noSort}
            onClick={() => {
              if (noSort) {
                return;
              }
              if (field !== Field) {
                changeSortOrder(field, ORDER_ASC);
              } else {
                changeSortOrder(
                  field,
                  Order === ORDER_ASC
                    ? ORDER_DESC
                    : Order === ORDER_DESC
                      ? ORDER_ASC
                      : ""
                );
              }
            }}
            id={id}
            style={style}
          />
        ))}
    </div>
  </div>
);

export default FieldSectionHeader;
