import React from "react";
import { Badge } from "react-bootstrap";
import classnames from "classnames/bind";
const SelectedItem = ({
  toggleOptions,
  label,
  isOpen,
  badgeCount,
  showBadge,
  subTitle,
  disabled,
  buttonId,
  statusIcon,
  description
}) =>
  !description ? (
    <button
      className={classnames(disabled && " __disabled-dropdown")}
      id={buttonId ? buttonId : "dropdown-button-id"}
      onClick={toggleOptions}
    >
      <span className="selected-value">
        {statusIcon && <i className={`fa ${statusIcon}`} />}
        {label}
        {subTitle && (
          <div className="previous-login-text">
            <i>{subTitle}</i>
          </div>
        )}
      </span>
      <i
        className={`fa fa-chevron-${isOpen ? "up" : "down"} pull-right`}
        aria-hidden="true"
      />
      {showBadge && (
        <Badge className="filter-select-badge pull-right">{badgeCount}</Badge>
      )}
    </button>
  ) : (
    <button
      className={classnames(disabled && " __disabled-dropdown")}
      id={buttonId ? buttonId : "dropdown-button-id"}
      onClick={toggleOptions}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "50px"
      }}
    >
      <span className="selected-value" style={{ height: "auto" }}>
        {statusIcon && <i className={`fa ${statusIcon}`} />}
        {label}
        {description && (
          <div style={{ color: "#999", fontSize: "14px" }}>{description}</div>
        )}
      </span>
      <i
        className={`fa fa-chevron-${isOpen ? "up" : "down"} pull-right`}
        aria-hidden="true"
      />
      {showBadge && (
        <Badge className="filter-select-badge pull-right">{badgeCount}</Badge>
      )}
      {subTitle && (
        <div className="previous-login-text">
          <i>{subTitle}</i>
        </div>
      )}
    </button>
  );

export default SelectedItem;
