import axios from "axios";
import * as types from "./index";
import * as common from "../../../../app/common/index";

export function getReviewandSaveDataList(activityInstanceId) {
  const url = `${
    common.WEB_API_URL
  }/v1/app/ui/activityhistory/3/activityinstance/${activityInstanceId}/snapshot`;
  const request = axios.get(url);
  return {
    type: types.SYSTEM_GET_REVIEW_SAVE_SNAPSHOT,
    payload: request
  };
}

export function clearReviewandSaveDataList() {
  let payload = {
    data: []
  };
  return {
    type: types.SYSTEM_GET_REVIEW_SAVE_SNAPSHOT,
    payload: payload
  };
}
