/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FormFieldGroup } from "../../../../formSections";
import * as Fields from "../FieldNames";
import "./FMLACalculator.css";
import Frequency from "component-library/misc/payrollFrequency";
import EligibilityPeriod from "./EligibilityPeriod";

const baseClass = "fmla-calculator";
const baseId = "fmla-calculator";
const FmlaCalculator = ({ onChange, data = {} }) => {
  return (
    <div id={baseId} className={baseClass + " clearfix"}>
      <div id={baseId + "-header"} className={baseClass + "-header"}>
        <i className="fa fa-calculator" />{" "}
        <span id={baseId + "-title"} className="title">
          FMLA Leave Calculator
        </span>{" "}
        <i>(Optional)</i>
      </div>
      <div id="fmla-criteria" style={{ marginBottom: "15px" }}>
        Employees are eligible for FMLA leave if they meet the following
        criteria:
      </div>
      <div id="fmla-criteria-list-container">
        <ul id="fmla-criteria-list">
          <li id="fmla-criteria-list-item">
            Worked for the emplopyer for atleast one year.
          </li>
          <li id="fmla-criteria-list-item">
            Has atleast 1,250 hours of service for the employer during the
            one-year period immediately preceding the leave
          </li>
          <li id="fmla-criteria-list-item">
            Works at a location where the employer has atlest 50 employees
            within 75 miles.
          </li>
        </ul>
      </div>
      <div>
        <EligibilityPeriod />
        <FormFieldGroup
          inputType="radio"
          inputName={Fields.fmlConsistentSchedule}
          label="Does this employee work a consistent schedule each week?"
          fieldType="inline"
          value={data[Fields.fmlConsistentSchedule]}
          radios="boolean"
          colSpan="col-lg-3 col-md-3"
          Size={[6, 4, 12, 12]}
          onChange={onChange}
        />
        <div style={{ marginBottom: "15px" }}>
          <div className="col-lg-3 col-md-3">Days Worked Per Week</div>
          <div classname="col-lg-9 col-md-9">
            <Frequency name="weekDays" type="weekDays" />
          </div>
        </div>
        <div
          className="col-lg-9 col-md-9 col-lg-offset-3 col-md-offset-3"
          style={{ marginBottom: "15px" }}
        >
          <button className="nc-button secondary-button">
            Calculate Fmla Leave
          </button>
        </div>
        <div className="fmla-table col-lg-12 col-md-12">
          <div className="fmla-table-header col-lg-12 col-md-12">
            {" "}
            <div className="col-lg-3 col-md-3">Recent FMLA Leave</div>
            <div className="col-lg-3 col-md-3">LEAVE START DATE</div>
            <div className="col-lg-3 col-md-3">LEAVE RETURN DATE</div>
            <div className="col-lg-3 col-md-3">WORKWEEKS</div>
          </div>
          <div className="fmla-table-item col-lg-9 col-md-9 col-md-offset-3 col-lg-offset-3">
            <div className="col-lg-4 col-md-4">Jan 2, 2017</div>
            <div className="col-lg-4 col-md-4">Jan 2, 2017</div>
            <div className="col-lg-4 col-md-4">4 weeks 0 days</div>
          </div>
          <div className="fmla-table-item col-lg-9 col-md-9 col-md-offset-3 col-lg-offset-3">
            <div className="col-lg-4 col-md-4">Jan 2, 2017</div>
            <div className="col-lg-4 col-md-4">Jan 2, 2017</div>
            <div className="col-lg-4 col-md-4">4 weeks 0 days</div>
          </div>
          <div className="fmla-table-item col-lg-9 col-md-9 col-md-offset-3 col-lg-offset-3">
            <div className="col-lg-4 col-md-4">Jan 2, 2017</div>
            <div className="col-lg-4 col-md-4">Jan 2, 2017</div>
            <div className="col-lg-4 col-md-4">4 weeks 0 days</div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12" style={{ padding: "10px 15px" }}>
          <div
            className="col-lg-3 col-md-5 col-lg-offset-6 col-md-offset-4"
            style={{
              textAlign: "right",
              paddingRight: "10px",
              fontWeight: "600"
            }}
          >
            Used Workweeks:
          </div>
          <div className="col-lg-3 col-md-3">11 weeks 1 day</div>
        </div>
        <div className="col-lg-12 col-md-12" style={{ padding: "10px 15px" }}>
          <div
            className="col-lg-3 col-md-5 col-lg-offset-6 col-md-offset-4"
            style={{
              textAlign: "right",
              paddingRight: "10px",
              fontWeight: "600"
            }}
          >
            Available as of [Leave Start Date]:{" "}
            <a onClick={this.showTooltipHandler}>
              <i className="fa fa-info-circle" />
            </a>
          </div>
          <div className="col-lg-3 col-md-3">--</div>
        </div>
        <div className="fmla-table col-lg-12 col-md-12">
          <div className="fmla-table-header col-lg-12 col-md-12">
            {" "}
            <div className="col-lg-3 col-md-3">Current FMLA Request</div>
            <div className="col-lg-3 col-md-3">LEAVE START DATE</div>
            <div className="col-lg-3 col-md-3">EXPECTED LEAVE RETURN DATE</div>
            <div className="col-lg-3 col-md-3">WORKWEEKS</div>
          </div>
          <div className="fmla-table-item col-lg-9  col-md-9 col-lg-offset-3 col-md-offset-3">
            <div className="col-lg-4 col-md-4">--</div>
            <div className="col-lg-4 col-md-4">--</div>
            <div className="col-lg-4 col-md-4">--</div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12" style={{ padding: "10px 15px" }}>
          <div
            className="col-lg-5 col-md-7 col-lg-offset-4 col-md-offset-2"
            style={{
              textAlign: "right",
              paddingRight: "10px",
              fontWeight: "600"
            }}
          >
            <i className="fa fa-check-circle nc-green" />
            Available as of [Expected Leave Return Date]:{" "}
            <a onClick={this.showTooltipHandler}>
              <i className="fa fa-info-circle" />
            </a>
          </div>
          <div className="col-lg-3 col-md-3">
            <span className="nc-red">(0 weeks 0 days)</span>
          </div>
        </div>
        <div className="clearfix" style={{ textAlign: "center" }}>
          <i className="fa fa-check-circle nc-green" />
          <i>
            The employee's total FMLA over the eligibility Determmination Period
            does not exceed 12 weeks.
          </i>
        </div>
        <div className="clearfix nc-red" style={{ textAlign: "center" }}>
          <i className="fa fa-check-circle nc-red" />
          <i>
            The employee's total FMLA over the eligibility Determmination Period
            does not exceed 12 weeks.
          </i>
        </div>
      </div>
    </div>
  );
};

export default FmlaCalculator;
