import React, { Component } from "react";
import getInputComponent from "./getInputComponent";
import SingleCheckbox from "../checkBoxGroup/SingleCheckBox";

export default class NoEndDateComponent extends Component {
  state = {};
  showTooltipHandler = () => {};
  onChange = () => {
    this.props.onChange({
      name: this.props.checkedName,
      value: !this.props.checkedValue
    });
  };
  render() {
    const { checkedValue, componentType } = this.props;
    const Component = getInputComponent(componentType);
    return (
      <div
        className={"col-xs-9"}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Component {...this.props} />
        <div
          style={{
            marginLeft: "20px",
            marginBottom: "15px"
          }}
        >
          <SingleCheckbox
            onChange={this.onChange}
            value={checkedValue}
            label="No end date"
            labelStyle="italic"
          />
        </div>
      </div>
    );
  }
}
