import { UnapprovedPto, TimecardAlerts, EmployeeUpdates } from "./../images";
import {
  AddressVerificationOff,
  AddressVerificationOn,
  MinimumWageOff,
  MinimumWageOn,
  TaxVerificationOff,
  TaxVerificationOn
} from "./../images/WageAndTaxValidation";
import React from "react";

import "../images/svg.css";
const getSvg = key => {
  switch (key) {
    case "UnAppoved PTO":
      return <UnapprovedPto width="30" height="30" />;
    case "Timecard Alerts":
      return <TimecardAlerts width="30" height="30" />;
    case "Employee Updates":
      return <EmployeeUpdates width="30" height="30" />;
    case "Address Verification":
      return <AddressVerificationOff width="50" height="50" />;
    case "Address Verification On":
      return <AddressVerificationOn width="50" height="50" />;
    case "Minimum Wage":
      return <MinimumWageOff width="50" height="50" />;
    case "Minimum Wage On":
      return <MinimumWageOn width="50" height="50" />;
    case "Tax Verification":
      return <TaxVerificationOff width="50" height="50" />;
    case "Tax Verification On":
      return <TaxVerificationOn width="50" height="50" />;
    default:
      return null;
  }
};
export default getSvg;
