import React from "react";
import "./svg.css";
const DeductionsNoData = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 200 200"
    style={{ enableBackground: "new 0 0 200 200" }}
    width={width}
    height={height}
  >
    <g>
      <g>
        <circle className="st0" cx="100" cy="100" r="100" />
      </g>
    </g>
    <path
      className="st1"
      d="M136.4,148.8H63.6c-3.4,0-6.1-2.7-6.1-6.1V57.3c0-3.4,2.7-6.1,6.1-6.1h72.7c3.4,0,6.1,2.7,6.1,6.1v85.3
	C142.5,146,139.7,148.8,136.4,148.8z M63.6,54.7c-1.4,0-2.6,1.2-2.6,2.6v85.3c0,1.4,1.2,2.6,2.6,2.6h72.7c1.4,0,2.6-1.2,2.6-2.6
	V57.3c0-1.4-1.2-2.6-2.6-2.6H63.6z M130.1,90.2H69.9c-2.1,0-3.8-1.7-3.8-3.8V64.2c0-2.1,1.7-3.8,3.8-3.8h60.2c2.1,0,3.8,1.7,3.8,3.8
	v22.1C133.9,88.5,132.2,90.2,130.1,90.2z M69.9,63.9c-0.2,0-0.3,0.1-0.3,0.3v22.1c0,0.2,0.1,0.3,0.3,0.3h60.2c0.2,0,0.3-0.1,0.3-0.3
	V64.2c0-0.2-0.1-0.3-0.3-0.3H69.9z M129.6,93.3H70.4c-2.4,0-4.3,1.9-4.3,4.3v36.3c0,2.4,1.9,4.3,4.3,4.3h59.2c2.4,0,4.3-1.9,4.3-4.3
	V97.6C133.9,95.2,132,93.3,129.6,93.3z M130.4,97.6V114h-28.7V96.8h27.8C130,96.8,130.4,97.1,130.4,97.6z M70.4,96.8h27.8V114H69.6
	V97.6C69.6,97.1,70,96.8,70.4,96.8z M69.6,133.9v-16.4h28.7v17.2H70.4C70,134.7,69.6,134.3,69.6,133.9z M129.6,134.7h-27.8v-17.2
	h28.7v16.4C130.4,134.3,130,134.7,129.6,134.7z M122.7,105.7c0-1-0.8-1.8-1.8-1.8h-9.9c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h9.9
	C122,107.5,122.7,106.7,122.7,105.7z M122.7,123.3c0-1-0.8-1.8-1.8-1.8h-9.9c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h9.9
	C122,125,122.7,124.2,122.7,123.3z M122.7,128.2c0-1-0.8-1.8-1.8-1.8h-9.9c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h9.9
	C122,129.9,122.7,129.1,122.7,128.2z M113,76c0-1-0.8-1.8-1.8-1.8h-4.5c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h4.5
	C112.2,77.7,113,76.9,113,76z M125.4,69.9c0-1.8-1.5-3.3-3.3-3.3h-3.7c-1.8,0-3.3,1.5-3.3,3.3v4.7h3.5v-4.4h3.2v4.4h3.5V69.9z
	 M125.4,80.6V76h-3.5v4.4h-3.2V76h-3.5v4.7c0,1.8,1.5,3.3,3.3,3.3h3.7C124,83.9,125.4,82.4,125.4,80.6z M89.7,104h-3.2v-3.2
	c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8v3.2h-3.2c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8H83v3.2c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8
	v-3.2h3.2c1,0,1.8-0.8,1.8-1.8S90.7,104,89.7,104z M87.2,125.7l2.3-2.3c0.7-0.7,0.7-1.8,0-2.5s-1.8-0.7-2.5,0l-2.3,2.3l-2.3-2.3
	c-0.7-0.7-1.8-0.7-2.5,0s-0.7,1.8,0,2.5l2.3,2.3L80,128c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5l2.3-2.3
	l2.3,2.3c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L87.2,125.7z"
    />
  </svg>
);
export default DeductionsNoData;
