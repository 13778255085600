import React, { Component } from "react";
import Modal from "react-bootstrap/lib/Modal";
import { TableColumns } from "./Constants";
import { ORDER_ASC, ORDER_DESC } from "../filters/ColumnTitle";

const SortRow = ({ label, order, onClick }) => (
  <div
    className="mobile-sort-panel-item row remove-margin-left-right"
    onClick={onClick}
  >
    <div className="col-sm-12 col-xs-12">
      {label} : {order}
    </div>
  </div>
);

export default class EarningsSortModal extends Component {
  render() {
    const { show, editSortOrder, closePanel } = this.props;
    const rows = [];
    TableColumns.forEach(col => {
      rows.push(
        <SortRow
          label={col.label}
          order="A to Z"
          key={col.label + " asc"}
          onClick={() => {
            editSortOrder(col.field, ORDER_ASC);
            closePanel();
          }}
        />,
        <SortRow
          label={col.label}
          order="Z to A"
          key={col.label + " desc"}
          onClick={() => {
            editSortOrder(col.field, ORDER_DESC);
            closePanel();
          }}
        />
      );
    });
    return (
      <Modal
        id="mobile-sort"
        animation={false}
        backdrop={"static"}
        keyboard={false}
        show={show}
      >
        <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="header-container col-sm-12 col-xs-12 clearfix">
            <div className="close-icon col-sm-3 col-xs-3">
              <i
                id="system-level-cancel-button-close-icon"
                onClick={closePanel}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
            <div className="filter-label col-sm-6 col-xs-6">{" Sort By "}</div>
          </div>
          <hr />
          <div>{rows}</div>
        </Modal.Body>
      </Modal>
    );
  }
}
