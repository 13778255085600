import React from "react";
import * as Fields from "./FieldNames";
import * as Description from "../FieldDescriptions";
import { FormFieldGroup } from "component-library/formSections";
const labelClass = "col-lg-3 col-md-3";

const Retermination = ({ onChange, data = {} }) => {
  return (
    <div>
      <FormFieldGroup
        label="Re-termination Date"
        fieldType="inline"
        inputType="overrideWithComponent"
        componentType="singleDatePicker"
        btnStyle={{ height: "44px" }}
        getFromToValue={onChange}
        onChange={onChange}
        onHide={() => {}}
        inputName={Fields.reterminationDate}
        value={data[Fields.reterminationDate]}
        checkedName={Fields.overrideReterminationDate}
        checkedValue={data[Fields.overrideReterminationDate]}
        checked={data}
        colSpan={labelClass}
        tooltip={Description.TERMINATION_DATE}
      />
      <FormFieldGroup
        label="Re-termination Type"
        fieldType="inline"
        inputType="Label"
        inputName={Fields.reterminationType}
        value="Voluntary"
        //value={data[Fields.reterminationType]}
        colSpan={labelClass}
      />
      <FormFieldGroup
        label="Re-termination Reason"
        fieldType="inline"
        inputType="Label"
        inputName={Fields.reterminationReason}
        //value={data[Fields.reterminationReason]}
        value="Quit"
        colSpan={labelClass}
      />
      <FormFieldGroup
        label="Eligible for Rehire?"
        fieldType="inline"
        inputType="overrideWithComponent"
        componentType="radio"
        radios="boolean"
        btnStyle={{ height: "44px" }}
        getFromToValue={onChange}
        onChange={onChange}
        onHide={() => {}}
        inputName={Fields.reterminationrehireEligibility}
        value={data[Fields.reterminationrehireEligibility]}
        checkedName={Fields.overrideReterminationRehire}
        checkedValue={data[Fields.overrideReterminationRehire]}
        colSpan={labelClass}
        tooltip={Description.ELIGIBLE_FOR_HIRE}
      />
    </div>
  );
};

export default Retermination;
