export const WEB_API_URL = "https://webapi.netchexonline.net";
export const WEB_API_MOCK_URL = "https://webapi.netchexonline.net";
export const IDENTITY_API_URL = "https://id.netchexonline.net";
export const UI_APP_URL = "https://ui.netchexonline.net/";
export const CLIENT_ID = "a2414e66-4e60-462a-b4ce-0d884195146a";
export const TENANT = "primaryauth";
export const SignUpSignInPolicy = "B2C_1A_UnknownDevicesMFA_signup_signin";
export const SIGNALR_API_HUB =
  "https://netchex-usertest-webapi-mock.azurewebsites.net";
export const SelectedProductId = 0;
export const SelectedMenuLevel1Id = 0;
export const SelectedMenuLevel2Id = 0;
export const SelectedMenuLevel3Id = 0;
export const LEGACY_LOGIN_URL = "https://na3.netchexonline.net";
export const WALKME_SOURCE_URL =
  "https://cdn.walkme.com/users/96445bbf6d264dd38d99b757c257c3ee/walkme_96445bbf6d264dd38d99b757c257c3ee_https.js"; // Live
