import axios from "axios";
import { WEB_API_URL, WEB_API_MOCK_URL } from "../../app/common/index";
import { postWithParams } from "../util";

/**
 * TODO: Fix the paths once the mock/service endpoints are implemented
 */

export const searchJobHistoryPath = entityTypeId =>
  `/v1/subscriber/company/employee/jobhistory/${entityTypeId}/search`;
export const searchJobHistory = (entityTypeId, params) =>
  postWithParams(WEB_API_MOCK_URL + searchJobHistoryPath(entityTypeId), params);
export const loadMoreJobHistory = NextUri => axios.get(NextUri);

export const editJobDetailPath = (entityTypeId, jobId) =>
  `/v1/subscriber/company/employee/jobhistory/${entityTypeId}/edit/${jobId}`;
export const editJobDetail = (entityTypeId, jobId, params) =>
  postWithParams(WEB_API_URL + editJobDetailPath(entityTypeId, jobId), params);

export const userPath = entityTypeId =>
  `/v1/subscriber/company/employee/jobhistory/${entityTypeId}/user`;
export const fieldPath = activityEntityId =>
  `/v1/subscriber/company/employee/jobhistory/${activityEntityId}/field`;
export const eventTypePath = activityEntityId =>
  `/v1/subscriber/company/employee/jobhistory/${activityEntityId}/eventype`;

export const getFilterOptions = (entityTypeId, pathConstructor) =>
  axios.get(WEB_API_MOCK_URL + pathConstructor(entityTypeId));
