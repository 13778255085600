import React from "react";

const style = {
  fontSize: "15px",
  height: "32px",
  background: "#f6f6f6",
  fontStyle: "italic",
  alignItems: "center",
  color: "#555",
  opacity: 0.9,
  display: "flex",
  justifyContent: "center",
  width: "37%",
  marginBottom: 20
};

const NoStatusChanges = ({ entity = "" }) => {
  return <div style={style}>No {entity} Status Changes</div>;
};

export default NoStatusChanges;
