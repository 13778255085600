import React from "react";
import getInputComponent from "../../../../inputFields/getInputComponent";
import * as Fields from "./../FieldNames";

const RadioButton = getInputComponent("radio");
const DatePicker = getInputComponent("singleDatePicker");
const IncludedInPayroll = props => {
  return (
    <div
      className="nc-field-group clearfix"
      style={{ clear: "both", display: "flex", alignItems: "center" }}
    >
      <div
        id={"Label" + props.inputName}
        style={{ paddingRight: "15px" }}
        className={`${
          props.colSpan
        } " " ${"col-lg-3 col-md-3 field-name clearfix"}`}
      >
        {props.label}{" "}
      </div>
      <div
        className="col-lg-9 col-md-9"
        style={{ display: "flex", alignItems: "center" }}
      >
        <RadioButton
          inputType="radio"
          radios="boolean"
          optional
          name={props.inputName}
          value={props.value}
          onChange={props.onChange}
        />
        {props.value && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px"
            }}
          >
            <div style={{ marginRight: "10px", color: "#009999" }}>
              {props.middleText}
            </div>
            <DatePicker
              style={{}}
              btnStyle={{ height: "44px" }}
              getFromToValue={props.onChange}
              onHide={() => {}}
              name={Fields.includedInPayrollStartDate}
              value={props.data[Fields.includedInPayrollStartDate]}
              showNoDateSection
              onChange={props.onChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default IncludedInPayroll;
