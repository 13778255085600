import React from "react";

const Position = ({ height, width, style }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 49 45.1"
      style={{ enableBackground: "new 0 0 49 45.1", ...style }}
      height={height}
      width={width}
      xmlSpace="preserve"
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{ __html: "\n\t.st0{fill:#555555;}\n" }}
      />
      <path
        className="st0"
        d="M48.3,11.6C48,10.1,47,8.9,45.6,8.3c-0.6-0.2-1.2-0.4-1.9-0.4h-6.6l0,0V3.2c0-1.2-0.9-2.1-2.1-2.1H14.9
	c-1.2,0-2.1,0.9-2.1,2.1v4.6v0.1H5.9C5.3,7.9,4.7,8,4.2,8.3c-1.8,0.7-3,2.3-3,4.3v26.3c0,2.6,2.1,4.7,4.7,4.7h37.9
	c2.6,0,4.7-2.1,4.7-4.7V12.6C48.4,12.3,48.4,11.9,48.3,11.6z M37.6,11.1v7.5h-1.4v-7.5H37.6z M14,11.1v7.5h-1.4v-7.5H14z M14.8,3.2
	l20.4-0.1c0,0,0.1,0,0.1,0.1v4.6c0,0,0,0.1-0.1,0.1H14.9c0,0-0.1,0-0.1-0.1C14.8,7.8,14.8,3.2,14.8,3.2z M43.8,41.6H5.9
	c-0.5,0-1-0.2-1.5-0.4c-0.6-0.4-0.9-1.1-0.9-1.9V12.4c0-1.3,1.1-2.4,2.4-2.4h4.9c-0.1,0.1-0.2,0.4-0.2,0.6v8.5
	c0,0.8,0.7,1.5,1.5,1.5h2.4c0.8,0,1.5-0.7,1.5-1.5v-8.5c0-0.2-0.1-0.5-0.2-0.7h18.6c-0.1,0.2-0.2,0.4-0.2,0.7v8.5
	c0,0.8,0.7,1.5,1.5,1.5h2.4c0.8,0,1.5-0.7,1.5-1.5v-8.5c0-0.2-0.1-0.5-0.2-0.7h4.3c1.4,0,2.5,1.1,2.6,2.4v14.2l0,0v12.4
	C46.4,40.4,45.2,41.6,43.8,41.6z"
      />
    </svg>
  );
};

export default Position;
