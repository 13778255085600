/** Actions */
const ADD_TO_HISTORY = "activity-history/ADD_TO_HISTORY";
const CLEAR_HISTORY = "activity-history/CLEAR_HISTORY";
const SET_HISTORY_LOADING = "activity-history/SET_HISTORY_LOADING";

export const initialState = {
  loading: true,
  Data: [],
  DataCount: 0,
  NextUri: null
};

const filterNewData = (state, Data) => {
  const ids = state.Data.map(a => a.ActivityInstanceId);
  return Data.filter(d => ids.indexOf(d.ActivityInstanceId) === -1);
};

/** Reducer */
const history = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_HISTORY:
      return initialState;
    case ADD_TO_HISTORY: {
      const { Data, DataCount, NextUri } = payload;
      const newData = [...state.Data, ...filterNewData(state, Data)];
      return {
        ...state,
        NextUri,
        Data: newData,
        DataCount: DataCount,
        loading: false
      };
    }
    case SET_HISTORY_LOADING:
      return {
        ...state,
        loading: payload
      };
    default:
      return state;
  }
};

export default history;

/** Action creators */
export const addToHistory = (Data, DataCount, NextUri) => ({
  type: ADD_TO_HISTORY,
  payload: { Data, DataCount, NextUri }
});

export const clearHistory = () => ({
  type: CLEAR_HISTORY
});

export const setHistoryLoading = loading => ({
  type: SET_HISTORY_LOADING,
  payload: loading
});
