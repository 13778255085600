import axios from "axios";
import * as types from "../index";
import * as common from "../../../../../app/common/index";

export function getPrintSsnOnCheckOption() {
  const url = `${
    common.WEB_API_URL
  }/v1/subscriber/company/printssnoncheckoption`;
  const request = axios.get(url);
  return {
    type: types.FETCH_THIRD_PARTY_PAYEE_PRINT_SSN_ON_CHECK_OPTION,
    payload: request
  };
}
