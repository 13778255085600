import React, { Component } from "react";
import {
  FormSection,
  FormFieldGroup
} from "component-library/formSections/index";
import * as FIELDS from "../utils/Constants";
import SKills from "./Components/Skills";

export default class Skills extends Component {
  render() {
    const {
      data = {},
      onChange,
      filters,
      errors,
      onCreateNewSkillLink,
      showLink,
      horizontalLabels
    } = this.props;
    return (
      <div className="tab-container-add-sub-section licenses-basic-info">
        <div className="section-items" style={{ padding: "20px 15px" }}>
          <FormSection>
            <SKills
              data={data}
              label="Skill"
              onChange={onChange}
              options={filters[FIELDS.SkillsSkillFilter]}
              errors={errors}
              required={true}
              LinkText="Create a New Skill"
              showLink={showLink}
              onCreateNewSkillLink={onCreateNewSkillLink}
            />

            <FormFieldGroup
              inputType="inputRange"
              inputName={FIELDS.SKILL_LEVEL_ID}
              value={data[FIELDS.SKILL_LEVEL_ID]}
              label="Skill Level"
              onChange={onChange}
              Size={[8, 10, 12, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              horizontalLabels={horizontalLabels}
              minValue={0}
              maxValue={4}
              error={errors[FIELDS.SKILL_LEVEL]}
              required={true}
            />

            <FormFieldGroup
              inputType="numeric"
              inputName={FIELDS.YEARS_EXPERIENCE}
              value={data[FIELDS.YEARS_EXPERIENCE]}
              label="Years Experience"
              onChange={onChange}
              Size={[2, 2, 2, 2]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              error={errors[FIELDS.YEARS_EXPERIENCE]}
              required={true}
              format="##"
            />

            <FormFieldGroup
              inputType="textarea"
              inputName={FIELDS.NOTES}
              value={data[FIELDS.NOTES]}
              label={"Notes"}
              onChange={onChange}
              Size={[7, 7, 7, 12]}
              fieldType="inline"
              colSpan="col-lg-3 col-md-3 col-sm-3"
              optional={true}
              rows={3}
              description="250 Character max"
              descriptionStyle="italic"
            />
          </FormSection>
        </div>
      </div>
    );
  }
}
