import React from "react";
import { Modal } from "react-bootstrap";
const ModalFooter = props => (
  <Modal.Footer>
    <div id="cancel-modal-footer" className="button-group">
      {props.buttonType.toUpperCase() === "LEAVE" && (
        <button
          type="button"
          id="save-modal-button"
          className="nc-button secondary-button"
          onClick={props.leaveThisPage}
        >
          {props.buttonLabel}
        </button>
      )}
      {props.buttonType.toUpperCase() === "SAVE CHANGES" && (
        <button
          type="button"
          id="save-modal-button"
          className="nc-button secondary-button"
          onClick={props.onSaveChanges}
        >
          {props.buttonLabel}
        </button>
      )}
      {props.buttonType.toUpperCase() === "DELETE" && (
        <button
          type="button"
          id="save-modal-button"
          className="nc-button primary-button"
          onClick={props.onDelete}
        >
          {props.buttonLabel}
        </button>
      )}

      <button
        type="button"
        id="close-modal-button"
        className="nc-button secondary-link-button"
        onClick={props.onClose}
      >
        {props.cancel ? "Cancel" : "Continue Editing"}
      </button>
    </div>
  </Modal.Footer>
);
export default ModalFooter;
