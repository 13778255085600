import React from "react";
import { Switch, Route } from "react-router-dom";

import {
  PayrollCalendar,
  PayrollCalendarPrintPreview
} from "../payroll-calendar/pages";

const PayrollCalendarRoutes = () => (
  <Switch>
    <Route path="/payroll-calendar/:id" component={PayrollCalendar} />
    <Route path="/print" component={PayrollCalendarPrintPreview} />
  </Switch>
);

export default PayrollCalendarRoutes;
