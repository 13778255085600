import React from "react";
import { FormSection, FormFieldGroup } from "component-library/formSections";
import * as Fields from "./../FieldNames";
import LeaveReason from "./LeaveReason";
import IncludedInPayroll from "./IncludedInPayroll";
import { LEAVE_TYPE } from "../Tooltips";

const NON_FMLA_CONSTANT = 2;

const LeaveDetails = ({ onChange, data = {}, onAddLinkClick }) => {
  return (
    <FormSection title={"Leave Details"} hr>
      <FormFieldGroup
        inputType="singleDatePicker"
        label="Leave Start Date"
        fieldType="inline"
        inputName={Fields.leaveStartDate}
        data={data[Fields.leaveStartDate]}
        colSpan="col-lg-3 col-md-3"
        style={{ marginBottom: "15px" }}
        Size={[4, 4, 12, 12]}
        onChange={onChange}
      />
      <FormFieldGroup
        inputType="radio"
        inputName={Fields.leaveType}
        label="What is the leave type?"
        fieldType="inline"
        value={data[Fields.leaveType]}
        radios={[
          { id: 1, value: 1, label: "FMLA" },
          { id: 2, value: 2, label: "Non-FMLA" }
        ]}
        colSpan="col-lg-3 col-md-3"
        Size={[6, 4, 12, 12]}
        onChange={onChange}
        tooltip={LEAVE_TYPE}
      />
      {data[Fields.leaveType] === NON_FMLA_CONSTANT && (
        <div>
          <LeaveReason
            inputName={Fields.leaveReason}
            label="Leave Reason"
            value={data[Fields.leaveReason]}
            onChange={onChange}
            data={data}
            fieldType="inline"
            onAddLinkClick={onAddLinkClick}
            optional
          />

          <FormFieldGroup
            inputType="singleDatePicker"
            label="Expected Leave Return Date"
            fieldType="inline"
            inputName={Fields.expectedLeaveReturnDate}
            data={data[Fields.expectedLeaveReturnDate]}
            colSpan="col-lg-3 col-md-3"
            style={{ marginBottom: "15px" }}
            Size={[4, 4, 12, 12]}
            onChange={onChange}
          />

          <IncludedInPayroll
            inputName={Fields.leaveIncludedInPayroll}
            label="Should this employee be included in payroll ?"
            value={data[Fields.leaveIncludedInPayroll]}
            data={data}
            onChange={onChange}
            link="Until which paycheck date"
            linkName="LeaveIncludedInPayrollEndDate"
            showLink={data.LeaveIncludedInPayrollEndDate}
          />

          {data.LeaveIncludedInPayrollEndDate &&
            data[Fields.leaveIncludedInPayroll] && (
              <FormFieldGroup
                inputType="noEndDateComponent"
                componentType="singleDatePicker"
                label="Until which paycheck date?"
                fieldType="inline"
                inputName={Fields.includedInPayrollEndDate}
                data={data[Fields.includedInPayrollEndDate]}
                colSpan="col-lg-3 col-md-3"
                style={{ marginBottom: "15px" }}
                Size={[4, 4, 12, 12]}
                onChange={onChange}
                checkedName={"LeaveIncludedInPayrollNoEndDate"}
                checkedValue={data.LeaveIncludedInPayrollNoEndDate}
              />
            )}

          <FormFieldGroup
            inputType="textarea"
            inputName={Fields.leaveReturnNotes}
            label="Notes"
            value={data[Fields.leaveReturnNotes]}
            fieldType="inline"
            description="250 characters max"
            rows={6}
            descriptionStyle="italic"
            colSpan="col-lg-3 col-md-3"
            Size={[8, 8, 12, 12]}
            onChange={onChange}
            optional
          />
        </div>
      )}
    </FormSection>
  );
};

export default LeaveDetails;
