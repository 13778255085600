import React, { Component } from "react";
import { connect } from "react-redux";
import Filters from "component-library/payroll-dashboard/timecard-alerts/Filters";
import {
  makeOptions,
  makeSelectedOptions,
  selectFilterOption,
  selectAllFilterOptions,
  clearAllFilters
} from "app/common/reducers/filters";
import {
  fetchAndSetEmployees,
  fetchAndSetTimeGroups,
  fetchAndSetTimePeriods,
  searchAndSetPostsTimecardAlerts,
  fetchTimecardAlerts
} from "../network/actions";
import { setSortOrder } from "app/common/reducers/sortOrder";
import { setLoader } from "app/actions/LoaderActions";
import { getFilterCount } from "./../util";

class TimecardAlertFilters extends Component {
  state = {
    loadingFirstTime: false
  };

  searchPostsTimecardAlerts = () => {
    const {
      searchAndSetPostsTimecardAlerts,
      searchParams,
      filters
    } = this.props;
    this.setState({
      loadingFirstTime: true
    });
    searchAndSetPostsTimecardAlerts(searchParams, "1", filters, () =>
      this.setState({ loadingFirstTime: false })
    );
    this.props.fetchTimecardAlerts(1, false);
  };

  componentDidMount() {
    this.props.clearAllFilters();
    // this.props.fetchAndSetEmployees(true, true, true, "Employees", 1 , convertDatetoMonthDay(this.props.data.PayPeriodToDate), convertDatetoMonthDay(this.props.data.PayPeriodFromDate));
    this.props.fetchAndSetEmployees(
      true,
      true,
      true,
      "Employees",
      1,
      "1/12/2018",
      "2/12/2018"
    );
    this.props.fetchAndSetTimeGroups();
    this.props.fetchAndSetTimePeriods();
  }

  componentWillUnmount() {
    this.props.clearAllFilters();
  }

  makeOnSelect = name => (option, selected) => {
    this.props.selectOption(name, option, selected);
  };

  makeSelectAll = name => selected => {
    this.props.selectAll(name, selected);
  };

  render() {
    const { selectedOptions, sortOrder, editSortOrder, selectAll } = this.props;
    return (
      <Filters
        options={this.props.filterData}
        selectedOptions={selectedOptions}
        makeOnSelect={this.makeOnSelect}
        makeSelectAll={this.makeSelectAll}
        sortOrder={sortOrder}
        editSortOrder={editSortOrder}
        clearAll={() => selectAll(undefined, false)}
        filterCount={getFilterCount(
          selectedOptions,
          this.props.filterData,
          this.props.payrollTypeActiveTab
        )}
        onCloseFilter={this.searchPostsTimecardAlerts}
      />
    );
  }
}

const stateToProps = state => ({
  filterData: makeOptions(state.common.filters),
  selectedOptions: makeSelectedOptions(state.common.filters)
});

const actionCreators = {
  clearAllFilters,
  selectOption: selectFilterOption,
  selectAll: selectAllFilterOptions,
  fetchAndSetEmployees,
  fetchAndSetTimeGroups,
  fetchAndSetTimePeriods,
  editSortOrder: setSortOrder,
  setLoader,
  searchAndSetPostsTimecardAlerts,
  fetchTimecardAlerts
};

export default connect(stateToProps, actionCreators)(TimecardAlertFilters);
