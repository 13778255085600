export const DEDUCTION_GARNISHMENT_ID = 4;

export const DEDUCTION_INSURANCE_ID = 1;

export const DEDUCTION_RETIREMENT_ID = 2;

export const DEDUCTION_RETIREMENT_LOAN_ID = 3;

export const DEDUCTION_OTHER_ID = 5;

// DEDUCTION DESCRIPTION PAGE

export const DEDUCTION_DESCRIPTION_INSURANCE_ID = 1;

export const DEDUCTION_DESCRIPTION_RETIREMENT_ID = 2;

export const DEDUCTION_DESCRIPTION_RETIREMENT_LOAN_ID = 3;

export const DEDUCTION_DESCRIPTION_GARNISHMENT_ID = 4;

export const DEDUCTION_DESCRIPTION_OTHER_ID = 5;

//DEDUCTION DESCRIPTION SUB-CATEGORY DROPDOWN CONSTANTS

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_HEALTH_SAVING_ACCOUNT = 8;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K_LOAN_REPAYMENT = 35;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B_LOAN_REPAYMENT = 36;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_INCOME_TAX_WITHHOLDING = 45;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_ADOPTION_ASSISTANCE_PROGRAMS = 46;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_MISCELLANEOUS = 47;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_ARCHER_MEDICAL_SAVINGS_ACCOUNT_MSA = 12;

//insurance category

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_DENTAL = 2;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_DEPENDANT_CARE = 7;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_DEPENDANT_CARE_FLEXIBLE_SPENDING_ACCOUNT_FSA = 10;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_HEALTH_CARE_FLEXIBLE_SPENDING_ACCOUNT_FSA = 9;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_HEALTH_REIMBURSEMENT_ARRANGEMENT_HRA = 11;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_HEALTH_SAVINGS_ACCOUNT_HSA = 8;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_LIFE = 6;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_LONG_TERM_DISABILITY = 4;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_MEDICAL = 1;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_OTHER = 14;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_SHORT_TERM_DISABILITY = 5;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_SUPPLEMENTAL_INSURANCE = 13;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_VISION = 3;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_QSEHRA = 54;

//retirement category

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_DEFERRED_COMPENSATION = 34;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_STATE_EMPLOYEE_RETIREMENT_SYSTEM_WITHOLDING = 33;

//

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K = 15;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K_CATCH_UP = 16;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B = 17;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B_CATCH_UP = 18;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_408K_6 = 19;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_408P_SIMPLE = 20;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_409A = 21;

export const DEFERRED_COMPENSATION = 34;

export const DEDUCTION_DESCRIPTION_401K_LOAN_REPAYMENT = 35;

export const DEDUCTION_DESCRIPTION_403B_LOAN_REPAYMENT = 36;

export const STATE_EMPLOYEE_RETIREMENT_SYSTEM_WITHOLDING = 33;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_457B = 22;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_457B_CATCH_UP = 23;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_501C_18_D = 24;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_SIMPLE_IRA_SIMPLE_401K = 25;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_SIMPLE_IRA_SIMPLE_401K_CATCH_UP = 26;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K_ROTH = 27;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_401K_ROTH_CATCH_UP = 28;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B_ROTH = 29;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_403B_ROTH_CATCH_UP = 30;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_457B_ROTH = 31;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_457B_ROTH_CATCH_UP = 32;

export const DEDUCTION_DESCRIPTION_SUB_CATEGORY_MOVING_EXPENSES = 44;

//CALCULATION METHOD

export const CALCULATION_METHOD_DOLLAR_ID = 1;

export const CALCULATION_METHOD_HOUR_BASED_ID = 3;

export const CALCULATION_METHOD_OTHER_ID = 4;

export const CALCULATION_METHOD_PERCENT_ID = 2;

//CALCULATION METHOD EARNING GROUP

export const CALCULATION_METHOD_GROSS_EARNINGS_ID = 1;

export const CALCULATION_METHOD_TAXABLE_EARNINGS_ID = 2;

export const CALCULATION_METHOD_RETIREMENT_EARNINGS_ID = 4;

export const CALCULATION_METHOD_DISPOSABLE_EARNINGS_ID = 3;

export const CALCULATION_METHOD_STANDARD_WORKERS_EARNINGS_ID = 5;

export const CALCULATION_METHOD_PERCENTAGE_BASED_EARNINGS_ID = 6;

//deduction cycle

export const DEDUCTION_CYCLE_SCHEDULED_PAYROLLS_ID = 1;

export const DEDUCTION_CYCLE_SUPPLEMENTAL_PAYROLLS_ID = 2;

export const DEDUCTION_CYCLE_OTHER_ID = 3;

export const DEDUCTION_CYCLE_ALL_PAYROLLS_ID = 4;

//DEDUCTION SUB-CYCLE
export const DEDUCTION_SUB_CYCLE_ALL_SCHEDULED_PAYROLLS_ID = 1;

export const DEDUCTION_SUB_CYCLE_EXCLUDING_EXTRA_ID = 2;

export const DEDUCTION_SUB_CYCLE_SPECIFIC_SCHEDULED_ID = 3;

//Third-Party PAYEE ADD PAGE

export const THIRD_PARTY_PAYEE_DIRECT_DEPOSIT = 1;

export const THIRD_PARTY_PAYEE_PRINTED_CHECK = 2;

export const Local_Income_Tax = 2;

export const State_Income_Tax = 1;
