import React from "react";
import "./svg.css";
const TaxAndTaxCodesNoData = ({ width, height }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 200 200"
    style={{ enableBackground: "new 0 0 200 200" }}
    width={width}
    height={height}
  >
    <g>
      <g>
        <circle className="st0" cx="100" cy="100" r="100" />
      </g>
    </g>
    <path
      className="st1"
      d="M143.8,143.5h-6.7v-4.6c0-1-0.8-1.8-1.8-1.8h-6.7c0-0.1,0.1-0.3,0.1-0.4v-21.1h12.4c1,0,1.8-0.8,1.8-1.8
	s-0.8-1.8-1.8-1.8h-12.4v-9.9h5.9c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8h-5.9c-0.1-12.9-10-23.5-22.6-24.7v-6.7
	c0-2.6-1.8-4.8-4.3-5.3V48.7c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8V62c-2.4,0.6-4.2,2.7-4.2,5.3V74c-12.6,1.2-22.5,11.9-22.6,24.7
	h-5.9c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h5.9v9.9H59.1c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h12.7v21.1c0,0.1,0,0.3,0.1,0.4h-7.1
	c-1,0-1.8,0.8-1.8,1.8v4.6h-6.7c-1,0-1.8,0.8-1.8,1.8v8.3c0,1,0.8,1.8,1.8,1.8h87.5c1,0,1.8-0.8,1.8-1.8v-8.3
	C145.5,144.3,144.7,143.5,143.8,143.5z M99.5,65.4h1.1c1.1,0,2,0.9,2,2v6.6h-5v-6.6C97.6,66.2,98.4,65.4,99.5,65.4z M95.5,77.4
	c0.1,0,0.2,0,0.3,0h8.5c0.1,0,0.2,0,0.3,0C116,78,125,87.3,125.2,98.7H75C75.1,87.3,84.1,78,95.5,77.4z M125.2,102.2v8.3H75v-8.3
	H125.2z M125.2,137.2h-5.5c0-0.1,0.1-0.3,0.1-0.4v-21.1h5.4v21.1C125.2,136.9,125.2,137,125.2,137.2z M84.2,115.6h5.4v21.1
	c0,0.1,0,0.3,0.1,0.4h-5.5c0-0.1,0.1-0.3,0.1-0.4V115.6z M93.1,136.7v-21.1h5.4v21.1c0,0.1,0,0.3,0.1,0.4H93
	C93,137,93.1,136.9,93.1,136.7z M102,136.7v-21.1h5.4v21.1c0,0.1,0,0.3,0.1,0.4h-5.5C101.9,137,102,136.9,102,136.7z M110.9,136.7
	v-21.1h5.4v21.1c0,0.1,0,0.3,0.1,0.4h-5.5C110.8,137,110.9,136.9,110.9,136.7z M75.3,136.7v-21.1h5.4v21.1c0,0.1,0,0.3,0.1,0.4h-5.5
	C75.2,137,75.3,136.9,75.3,136.7z M142,151.8H58V147h6.7c1,0,1.8-0.8,1.8-1.8v-4.6h67.1v4.6c0,1,0.8,1.8,1.8,1.8h6.7V151.8z"
    />
  </svg>
);
export default TaxAndTaxCodesNoData;
