import React from "react";
import PropTypes from "prop-types";
const CustomOverlay = props => {
  return (
    <div
      className={props.className}
      style={{ width: props.width, ...props.style }}
    >
      {props.children}
    </div>
  );
};

export default CustomOverlay;
CustomOverlay.propTypes = {
  className: PropTypes.string
};
CustomOverlay.defaultProps = {
  className: "nc-dropdown-overlay"
};
