import React from "react";
import {
  Cobra,
  TerminationDetails,
  ReterminationDetails
} from "component-library/employee-profile/employment-info/change-status/termination/index";
import {
  ScheduledDeductions,
  ReplacementManagers,
  ScheduledEarnings
} from "component-library/employee-profile/employment-info/change-status/common";
import FooterButtons from "../components/FooterButtons";
import { RETERMINATE_FOOTNOTE } from "../Constants";

const REHIRE_CONSTANT = 8;

const Terminated = ({
  data = {},
  onChange,
  onAddLinkClick,
  onCloseOverlay
}) => {
  return (
    <div>
      {data.EmployeeCurrentStatus === REHIRE_CONSTANT ? (
        <ReterminationDetails
          onChange={onChange}
          data={data}
          onAddLinkClick={onAddLinkClick}
        />
      ) : (
        <TerminationDetails
          onChange={onChange}
          data={data}
          onAddLinkClick={onAddLinkClick}
        />
      )}

      <Cobra onChange={onChange} data={data} />
      <ScheduledEarnings onChange={onChange} data={data} />
      <ScheduledDeductions onChange={onChange} data={data} />
      <ReplacementManagers onChange={onChange} data={data} />

      <FooterButtons
        buttonLabel="Terminate Employee"
        footNote={RETERMINATE_FOOTNOTE}
        onCancelClick={onCloseOverlay}
      />
    </div>
  );
};

export default Terminated;
