/** Actions */
const CLEAR_RESUME_ENTITY_DATA =
  "people-employee-dashboard/CLEAR_RESUME_ENTITY_DATA";
const SET_RESUME_ENTITY_DATA =
  "people-employee-dashboard/SET_RESUME_ENTITY_DATA";

export const initialState = {
  loading: true,
  Data: {},
  DataCount: 0
};

/** Reducer */
const resumeEntityData = (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_RESUME_ENTITY_DATA:
      return initialState;
    case SET_RESUME_ENTITY_DATA: {
      const { Data, DataCount } = payload;
      return {
        ...state,
        Data: Data,
        DataCount: DataCount,
        loading: false
      };
    }
    default:
      return state;
  }
};

export default resumeEntityData;

/** Action creators */
export const clearResumeEntityData = () => ({
  type: CLEAR_RESUME_ENTITY_DATA
});

export const setResumeEntityData = (Data, DataCount) => ({
  type: SET_RESUME_ENTITY_DATA,
  payload: { Data, DataCount }
});
