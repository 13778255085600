import React from "react";
import PropTypes from "prop-types";
const FooterButtons = ({ onApply, onClose }) => {
  return (
    <div className="footer-button-container" style={{ clear: "both" }}>
      <div className="seperator">
        <hr />
      </div>
      <div className="nc-button-group">
        <button
          id="save-modified-button"
          onClick={onApply}
          className="nc-button secondary-button"
        >
          APPLY FILTERS
        </button>
        <div
          className={
            onClose
              ? "nc-link-group-mobile-single-button"
              : "nc-link-group-mobile"
          }
        >
          <button
            id="cancel-button"
            className="secondary-link-button"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default FooterButtons;
FooterButtons.propTypes = {
  onApply: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
