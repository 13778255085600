import EntitySnapshot from "./EntitySnapshot";

export default EntitySnapshot;

export const makeId = (key = "", index = 0) =>
  index > 0
    ? String(key)
        .toLowerCase()
        .replace(/ /g, "-") +
      "-value-" +
      index
    : String(key)
        .toLowerCase()
        .replace(/ /g, "-") + "-value";
