/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { bindActionCreators } from "redux";
import GeneralLedgerEarningsDropdown from "./components/GeneralLedgerEarningsDropdown";
import { withRouter } from "react-router-dom";
import Media from "react-media";
import {
  getEmployerEarningsAccountsFilteredList,
  sortFilteredList,
  getEmployerEarningsAccountsMainList,
  setSelectedFilters
} from "../../actions/generalledger/earnings/EmployerPaidEarningsActions.js";
import * as utilities from "../../../../app/common/utilities";
import { setLoader } from "app/actions/LoaderActions";

class EmployerPaidEarningsAdvanceFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGLCategories: [],
      selectedDeductions: [],
      unmappedCheckState: false,
      sortItemList: [
        {
          id: 1,
          label: "Name : A to Z",
          sortTypeId: 1,
          sortKey: "CompanyEarningName"
        },
        {
          id: 2,
          label: "Name : Z to A",
          sortTypeId: 2,
          sortKey: "CompanyEarningName"
        }
      ],
      isResetToDefault: false,
      mobileFilterPanel: false,
      mobileSortPanel: false
    };

    //this.setCategories = this.setCategories.bind(this);

    this.setDeductions = this.setDeductions.bind(this);

    this.handleChangeUnmappedCheckState = this.handleChangeUnmappedCheckState.bind(
      this
    );

    this.applyFilter = this.applyFilter.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
  }
  closeMobileFilterPanel = () => {
    this.setState({ mobileFilterPanel: false });
  };
  openMobileFilterPanel = () => {
    this.setState({ mobileFilterPanel: true });
  };

  closeMobileSortPanel = () => {
    this.setState({ mobileSortPanel: false });
  };
  openMobileSortPanel = () => {
    this.setState({ mobileSortPanel: true });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedDeductions !== this.state.selectedDeductions ||
      prevState.unmappedCheckState !== this.state.unmappedCheckState
    ) {
      this.getFilteredList();
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize = () => {
    this.props.setLoader(true);
    setTimeout(() => {
      this.props.setLoader(false);
    }, 300);
  };

  getFilteredList() {
    if (this.props.employerEarningsAccountsMainList.DataCount > 0) {
      this.setState({
        isResetToDefault: false
      });
      //  if (this.props.employerEarningsAccountsMainList.DataCount > 0) {
      this.props.setSelectedFilters(this.state.selectedDeductions, []);
      this.props.getEmployerEarningsAccountsFilteredList(
        this.props.employerEarningsAccountsMainList.Data,
        this.state.selectedDeductions,
        this.state.unmappedCheckState
      );
      // }
    }
  }

  applyFilter() {
    this.getFilteredList();
    this.closeMobileFilterPanel();
  }

  setDeductions(selectedDeductions) {
    this.setState({
      selectedDeductions
    });

    this.props.onSelectedDeductions(selectedDeductions);
  }

  handleChangeUnmappedCheckState() {
    let selectedCheckBox = !this.state.unmappedCheckState;

    this.setState({
      unmappedCheckState: selectedCheckBox
    });

    this.props.getUnmappedCheckState(selectedCheckBox);
  }

  closeMobileFilterPanel() {
    this.refs.overlayMobileFilterPanel.hide();
  }

  closeMobileSortPanel() {
    this.refs.overlayMobileSortPanel.hide();
  }

  clearAllFilters() {
    this.setState({
      isResetToDefault: true,
      unmappedCheckState: false
    });
  }

  sortColumnByKey(item) {
    if (this.props.employerEarningsAccountsFilteredList.Data) {
      let sortedFilteredList = utilities.sortByKey(
        this.props.employerEarningsAccountsFilteredList.Data,
        item.sortKey
      );
      // this.props.employerEarningsAccountsFilteredList.Data = utilities.sortByKey(this.props.employerEarningsAccountsFilteredList.Data, item.sortKey);
      if (item.sortTypeId === 2) {
        sortedFilteredList = sortedFilteredList.reverse();
      }

      let request = {
        data: {
          Data: sortedFilteredList,
          DataCount: sortedFilteredList.length
        }
      };

      this.props.sortFilteredList(request);
    }

    this.closeMobileSortPanel();
  }

  showSortListItem() {
    return this.state.sortItemList.map((item, index) => {
      return (
        <div
          key={index}
          className="mobile-sort-panel-item row remove-margin-left-right"
          onClick={this.sortColumnByKey.bind(this, item)}
        >
          <div className="col-sm-12 col-xs-12">{item.label}</div>
        </div>
      );
    });
  }

  render() {
    const Id = this.props.Id;
    const mobileFilterPanelNew = (
      <Modal
        id="mobile-filter"
        //bsClass="mobile-filter-modal"
        animation={false}
        backdrop={"static"}
        keyboard={false}
        show={this.state.mobileFilterPanel}
        onHide={this.closeMobileFilterPanel}
      >
        {/* <div className="modal-content "> */}
        <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="header-container col-sm-12 col-xs-12 clearfix">
            <div className="close-icon col-sm-3 col-xs-3">
              <i
                id="system-level-cancel-button-close-icon"
                onClick={this.closeMobileFilterPanel.bind(this)}
                className="fa fa-times-circle-o fa-lg"
              />
            </div>
            <div className="filter-label col-sm-6 col-xs-6"> All Filters </div>
            <div className="clear-all-link col-sm-3 col-xs-3">
              <a onClick={this.clearAllFilters}>Clear All</a>
            </div>
          </div>

          <div
            id="mobile-select-deduction-dropdown"
            className="mobile-filter-select-dropdown no-padding col-sm-12 col-xs-12"
          >
            <GeneralLedgerEarningsDropdown
              onSelectedDeductions={selectedDeductions =>
                this.setDeductions(selectedDeductions)
              }
              isResetToDefault={this.state.isResetToDefault}
            />
          </div>
          <div
            id="mobile-select-category-dropdown"
            className="mobile-filter-select-dropdown no-padding col-sm-12 col-xs-12"
          >
            <div className="mobile-filter-panel-list-section">
              <div className="mobile-filter-panel-list-panel-bar col-xs-12 col-sm-12">
                <input
                  type="checkbox"
                  name="unmappedCheckState"
                  checked={this.state.unmappedCheckState}
                  onClick={this.handleChangeUnmappedCheckState}
                  value="unmappedCheckState"
                  id="show-only-unmapped-box"
                />
                <label
                  className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                  htmlFor="show-only-unmapped-box"
                >
                  &nbsp; Show Only Unmapped
                </label>
                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-icon">
                  {/* {this.state.subPanelId === DEDUCTION_PANEL_ID ? (
                    <i
                      className="fa fa-chevron-up add_deduction_popup_panel_link"
                      aria-hidden="true"
                    />
                  ) : (
                      <i
                        className="fa fa-chevron-down add_deduction_popup_panel_link"
                        aria-hidden="true"
                      />
                    )} */}
                </div>

                <div className="col-xs-8 col-sm-8 mobile-filter-panel-list-label no-padding">
                  {/* {this.state.selectDeductionLabel} */}
                </div>

                <div className="col-xs-2 col-sm-2 mobile-filter-panel-list-badge no-padding">
                  {/* {isAllUncheckedZero ? null : this.state
                    .isAllSelected ? null : (
                      <Badge className="filter-select-badge">
                        {this.state.selectedDeductions.length}
                      </Badge>
                    )} */}
                </div>
              </div>

              <div className="mobile-filter-panel-list-panel-bar-section" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="mobile-filter-panel-button-section">
            <button
              type="button"
              id={Id.filterApplyMobile}
              className="apply-filter-button"
              onClick={this.applyFilter}
            >
              APPLY FILTERS
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
    const mobileSortPanelNew = (
      <Modal
        id="mobile-sort"
        animation={false}
        backdrop={"static"}
        keyboard={false}
        show={this.state.mobileSortPanel}
        onHide={this.closeMobileSortPanel}
      >
        <Modal.Body className="modal-mobile-body col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="header-container col-sm-12 col-xs-12 clearfix">
            <div className="close-icon col-sm-3 col-xs-3">
              <i
                id="system-level-cancel-button-close-icon"
                onClick={this.closeMobileSortPanel.bind(this)}
                className="fa fa-times-circle-o"
              />
            </div>
            <div className="sort-label col-sm-6 col-xs-6"> Sort By </div>
          </div>
          <hr />
          {this.showSortListItem()}
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    );
    let filterCount = 0;

    if (this.props.employerEarningsAccountsMainList) {
      if (
        this.props.employerEarningsAccountsMainList.DataCount ===
        this.state.selectedDeductions.length - 1
      ) {
        filterCount = filterCount + 0;
      } else {
        filterCount = filterCount + this.state.selectedDeductions.length;
      }
    }

    return (
      <Media query="(min-width:320px) and (max-width:991px)">
        {isMobile =>
          isMobile ? (
            <div>
              <div className="nc-sort-filter-container col-sm-12 col-xs-12">
                <button
                  id={Id.sortButtonMobile}
                  onClick={this.openMobileSortPanel.bind()}
                  className=""
                >
                  SORT BY
                </button>
                <button
                  id={Id.filterButtonMobile}
                  onClick={this.openMobileFilterPanel.bind()}
                  className=""
                >
                  FILTERS{" "}
                  {filterCount !== 0 && (
                    <span id={Id.filterCountMobile}>({filterCount})</span>
                  )}
                </button>
                {mobileFilterPanelNew}
                {mobileSortPanelNew}
              </div>
            </div>
          ) : (
            <div className="nc-filter-container third-party-listing-page-filter">
              <div className="showing-label">FILTER:</div>
              <div className="nc-filter-group">
                <div className="nc-filter-row">
                  <div
                    id="select-thirdparty-dropdown"
                    className="nc-filter-dropdown"
                  >
                    <GeneralLedgerEarningsDropdown
                      onSelectedDeductions={selectedDeductions =>
                        this.setDeductions(selectedDeductions)
                      }
                      isResetToDefault={this.state.isResetToDefault}
                      Id={Id}
                    />
                  </div>
                  <div
                    id="select-category-dropdown"
                    className="nc-filter-dropdown showOnlyUnmapped"
                    style={{
                      height: "32px",
                      display: "flex",
                      alignItems: "center",
                      width: "auto"
                    }}
                  >
                    <input
                      type="checkbox"
                      name="unmappedCheckState"
                      checked={this.state.unmappedCheckState}
                      onClick={this.handleChangeUnmappedCheckState}
                      value="unmappedCheckState"
                      id="show-unmapped-box"
                    />
                    <label
                      className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      htmlFor="show-unmapped-box"
                    >
                      Show Only Unmapped
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Media>
    );
  }
}

function mapStateToProps(state) {
  return {
    // categoryList: state.categoryList,
    employerEarningsAccountsFilteredList:
      state.generalLedgerEarningsReducer.employerEarningsAccountsFilteredList,
    employerEarningsAccountsMainList:
      state.generalLedgerEarningsReducer.employerEarningsAccountsMainList,
    UnMappedCheckBoxStateValue:
      state.generalLedgerEarningsReducer.UnMappedCheckBoxStateValue
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEmployerEarningsAccountsMainList: getEmployerEarningsAccountsMainList,
      getEmployerEarningsAccountsFilteredList: getEmployerEarningsAccountsFilteredList,
      setSelectedFilters: setSelectedFilters,
      setLoader: setLoader,
      sortFilteredList: sortFilteredList
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EmployerPaidEarningsAdvanceFilter)
);
