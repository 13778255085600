import React from "react";
import SetupStepTitle from "./SetupStepTitle";
import * as DESCRIPTION from "./StepDescription";
const EarningsPayCycle = props => {
  return (
    <div className="entity-page-content col-lg-9 col-md-8">
      <SetupStepTitle stepNumber={3} title="Pay Cycle" />
      <div className="step-description">{DESCRIPTION.PAY_CYCLE}</div>
      <div className="seperator" style={{ marginTop: "20px" }}>
        <hr />
      </div>
      <div className="description-content">{props.children}</div>
    </div>
  );
};
export default EarningsPayCycle;
