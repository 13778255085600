import React from "react";
import moment from "moment";
import PtoApproveActions from "./PtoApproveActions";
// const formatTime = timeStr => {

//   return moment(new Date(timeStr + "+00:00")).format("MMM DD");

// };
const unApprovedPtosTypeStyle = {
  fontWeight: 600
};
const convertDatetoMonthDay = date => {
  return moment(date).format("MMM D");
};

const UnapprovedPtosSubitem = ({
  LeaveTypeId,
  LeaveTypeName,
  LeaveFromDate,
  LeaveToDate,
  PtoRequestedHours,
  PtoAvailableHours,
  PtoFutureApprovedHours,
  AvailableHoursAdjusted
}) => (
  <div
    className="row payroll-dashboard-row"
    style={{
      backgroundColor: "rgb(233,233,233)"
    }}
  >
    <div className="column-item leave-type col-md-2 col-lg-2 clearfix">
      <span className="field-value  hidden-sm hidden-xs">{LeaveTypeName}</span>
      <span
        style={unApprovedPtosTypeStyle}
        className="field-value hidden-lg hidden-md"
      >
        {LeaveTypeName}
      </span>
      <span className="hidden-lg hidden-md">
        <PtoApproveActions hideContact noPadding />
      </span>
    </div>
    <div className="column-item col-md-2 col-lg-2 clearfix">
      <span className="field-name hidden-md hidden-lg">Date Range:&nbsp;</span>
      <span className="field-value">
        {convertDatetoMonthDay(LeaveFromDate)} -{" "}
        {convertDatetoMonthDay(LeaveToDate)}
      </span>
    </div>
    <div className="column-item col-md-2 col-lg-2 clearfix">
      <span className="field-name hidden-md hidden-lg">
        Time Requested:&nbsp;
      </span>
      <span className="field-value">{PtoRequestedHours}</span>
    </div>
    <div className="column-item pto-available col-md-2 col-lg-2 clearfix">
      <span className="field-name hidden-md hidden-lg">Available:&nbsp;</span>
      <span className="field-value">{PtoAvailableHours}</span>
    </div>
    <div className="column-item pto-future-approved col-md-1 col-lg-1 clearfix">
      <span className="field-name hidden-md hidden-lg">
        Futured Approved:&nbsp;
      </span>
      <span className="field-value">{PtoFutureApprovedHours}</span>
    </div>
    <div className="column-item pto-adjusted-available col-md-1 col-lg-1 clearfix">
      <span className="field-name hidden-md hidden-lg">
        Adjusted Available:&nbsp;
      </span>
      <span className="field-value">{AvailableHoursAdjusted}</span>
    </div>
    <div className="column-item hidden-sm hidden-xs col-md-2 col-lg-2 clearfix pto-action">
      <PtoApproveActions hideContact noPadding noMargin />
    </div>
  </div>
);

export default UnapprovedPtosSubitem;
