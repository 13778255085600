export const SET_HOLIDAY_LIST = "SET_HOLIDAY_LIST";
export const CLEAR_HOLIDAY_LIST = "CLEAR_HOLIDAY_LIST";

const systemHolidayList = (state = [], { type, payload }) => {
  switch (type) {
    case SET_HOLIDAY_LIST:
      return payload;
    case CLEAR_HOLIDAY_LIST:
      return [];
    default:
      return state;
  }
};

export default systemHolidayList;

export const setSystemHolidayList = list => ({
  type: SET_HOLIDAY_LIST,
  payload: list
});

export const clearSystemHolidayList = () => ({
  type: CLEAR_HOLIDAY_LIST
});
