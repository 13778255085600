import axios from "axios";
import * as types from "./index";
import * as common from "../../../../../app/common/index";

//main table list
export function getEmployerEarningsAccountsMainList() {
  const url = `${common.WEB_API_URL}/v1/subscriber/company/earning/glaccount`;
  let request = axios.get(url);
  return {
    type: types.FETCH_EMPLOYER_EARNINGS_ACCOUNTS_MAIN_LIST,
    payload: request
  };
}

export function getEmployerEarningsAccountsFilteredList(
  data,
  selectedDeductions,
  unmappedCheckState
) {
  let filterdByEntityList = [];
  let filterdList = [];

  if (data !== undefined) {
    if (selectedDeductions === undefined || selectedDeductions.length === 0) {
      filterdByEntityList = [];
    } else {
      data.forEach(item => {
        if (selectedDeductions.includes(item.CompanyEarningId)) {
          filterdByEntityList.push(item);
        }
      });
    }

    if (unmappedCheckState === true) {
      filterdList = [];
      filterdByEntityList.forEach(item => {
        if (
          item.EmployerContributionExpenseGlAccountId === 0 ||
          item.EmployerContributionExpenseGlAccountId === null
        ) {
          filterdList.push(item);
        }
      });
    } else {
      filterdList = filterdByEntityList;
    }
  }

  let request = {
    data: {
      Data: filterdList,
      DataCount: filterdList.length
    }
  };

  return {
    type: types.FETCH_EMPLOYER_EARNINGS_FILTERED_LIST,
    payload: request
  };
}

export function setEmployerEarningsData(employerList) {
  return {
    type: types.SET_EMPLOYER_EARNINGS_DATA,
    payload: employerList
  };
}

//for expense account drop down
export function getEmployerEarningsExpenceAccountsList() {
  const url = `${common.WEB_API_URL}/v1/subscriber/company/glaccount/4`;
  let request = axios.get(url);
  return {
    type: types.FETCH_EMPLOYER_EARNINGS_EXPENCE_ACCOUNTS_LIST,
    payload: request
  };
}

//for liability account drop down
export function getEmployerEarningsLiabilityAccountsList() {
  const url = `${common.WEB_API_URL}/v1/subscriber/company/glaccount/3`;
  let request = axios.get(url);
  return {
    type: types.FETCH_EMPLOYER_EARNINGS_LIABILITY_ACCOUNTS_LIST,
    payload: request
  };
}

export function saveEmployerEarningsExpenceLiabilityAccountsList(
  employerExpenceLiabilityAccounts
) {
  const url = `${
    common.WEB_API_URL
  }/v1/System/Deduction/AddDeductionDescription`;
  axios
    .post(url, employerExpenceLiabilityAccounts)
    .then(function(response) {})
    .catch(function(error) {});

  return {
    type: types.SAVE_EMPLOYER_EARNINGS_EXPENCE_LIABILITY_ACCOUNTS
  };
}

//for deduction filter drop down -- should not use this
export function getEarningsList() {
  const url = `${common.WEB_API_URL}/v1/subscriber/company/earning/glaccount`;
  let request = axios.get(url);
  return {
    type: types.FETCH_EMPLOYER_EARNINGS_ACCOUNTS_MAIN_LIST,
    payload: request
  };
}

// export function getIncludeGLReportList() {
//     // const url=`${common.WEB_API_URL}/v1/System/Deduction/CategoryList`;
//     const url = `${common.WEB_API_URL}/v1/system/deductioncategory`;
//     let request = axios.get(url);
//     return {
//         type: types.FETCH_GL_REPORT_FILTER_LIST,
//         payload: request
//     };
// }

// export function getEmployeeLiabilityAccountsGridList(){
//     const url=`${common.WEB_API_URL}/v1/app/ui/company`;
//     let request=axios.get(url);
//     return {
//         type : types.FETCH_EMPLOYEE_LIABILITY_ACCOUNTS_GRID_LIST,
//         payload : request
//     };
// }

//for saving data
export function setEmployerEarningsLiabilityAccountsData(data) {
  if (data === undefined) {
    data = [];
  }

  return {
    type: types.FETCH_EMPLOYER_EARNINGS_LIABILITY_ACCOUNTS_DATA,
    payload: data
  };
}

export function updatedEarningsFilteredList(data) {
  let request = {
    data: data
  };
  return {
    type: types.FETCH_EMPLOYER_EARNINGS_FILTERED_LIST,
    payload: request
  };
}

export function getUnmappedCheckState(item) {
  return {
    type: types.UNMAPPED_CHECK_STATE_TYPE,
    payload: item
  };
}

export function sortFilteredList(request) {
  return {
    type: types.FETCH_EMPLOYER_EARNINGS_FILTERED_LIST,
    payload: request
  };
}

export function setSelectedFilters(selectedDeductions, selectedCategories) {
  let deductions = [];
  let categories = [];
  selectedDeductions.forEach(dItem => {
    deductions.push(dItem);
  });

  selectedCategories.forEach(dItem => {
    categories.push(dItem);
  });

  // selectedStatus.map( dItem =>{
  //     status.push(dItem);
  // });

  let data = {
    selectedDeductions: deductions,
    selectedGLCategories: categories
  };

  return {
    type: "GENERAL_LEDGER_SELECTED_FILTERS",
    payload: data
  };
}
