import { WEB_API_URL } from "./app/common/index.js";
// const { $ } = window;
import { hubConnection } from "signalr-no-jquery";

var connection = hubConnection(WEB_API_URL);
var notify = connection.createHubProxy("NotificationHub");

export const init = (userId, callback) => {
  notify.on("notifyComplete", msg => {
    callback(msg);
  });

  connection
    .start()
    .done(() => {
      console.log("Now connected, connection ID=" + connection.id);
      subscribeNotification(userId);
    })
    .fail(a => {
      console.log("Could not connect" + a);
    });
};
export const subscribeNotification = (userId = 0) => {
  notify
    .invoke("Subscribe", userId)
    .done(() => {
      console.log("Invocation of Subscribe succeeded");
    })
    .fail(error => {
      console.log("Invocation of Subscribe failed. Error: " + error);
    });
};
