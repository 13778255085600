import React, { Component } from "react";
import { DragDropContext, DragDropContextProvider } from "react-dnd";
import HTML5Backend, { NativeTypes } from "react-dnd-html5-backend";
import TargetBox from "./DropTarget";
// import { ProgressBar } from "react-bootstrap";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      droppedFiles: [],
      crop: {
        x: 20,
        y: 10,
        width: 30,
        height: 10,
        aspect: 1 / 1
      },
      imageLoading: true,
      src: ""
    };
  }

  render() {
    const { FILE } = NativeTypes;
    const { handleFileDrop, handleFileUpload } = this.props;
    return (
      <DragDropContextProvider backend={HTML5Backend}>
        <div
          style={{
            marginLeft: "27%"
          }}
        >
          <TargetBox
            accepts={[FILE]}
            onDrop={handleFileDrop}
            onManualUpload={handleFileUpload}
          />
        </div>
      </DragDropContextProvider>
    );
  }
}
export default DragDropContext(HTML5Backend)(Container);

Container.defaultProps = {
  beforeSend: request => request,
  onProgress: (e, request, progress) =>
    console.log("Progress", e, request, progress),
  onLoad: (e, request) => {},
  onError: (e, request) => {},
  onAbort: (e, request) => {}
};
