import Loadable from "react-loadable";
import ChunkLoading from "app/common/UIComponents/ChunkLoading";
export const HolidayCalendar = Loadable({
  loader: () =>
    import("./pages" /* webpackChunkName: "holiday-calendar" */).then(
      m => m.HolidayCalendar
    ),
  loading: ChunkLoading
});
export const HolidayCalendarAddPage = Loadable({
  loader: () =>
    import("./pages" /* webpackChunkName: "holiday-calendar" */).then(
      m => m.HolidayCalendarAddPage
    ),
  loading: ChunkLoading
});
