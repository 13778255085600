import React from "react";
import { Modal } from "react-bootstrap";

const OptionsModalFooter = ({ onHide }) => (
  <Modal.Footer>
    <div className="mobile-filter-panel-button-section">
      <button
        type="button"
        id="mobile-apply-sort"
        className="nc-button apply-filter-button"
        onClick={onHide}
      >
        APPLY
      </button>
    </div>
  </Modal.Footer>
);

export default OptionsModalFooter;
