import React from "react";
import { Switch, Route } from "react-router-dom";
import { Notifications } from "../notifications/pages";
import ViewFullJobHistory from "../notifications/pages/NotificationsViewFullJobHistory";
import JobHistoryRouter from "../notifications/pages/JobHistoryRouter";

const NotificationsRoutes = () => (
  <Switch>
    <Route path="/notifications" component={Notifications} />
    <Route path="/view-job-history/:JobId" component={ViewFullJobHistory} />
    <Route path="/go-to-job-history/:JobId" component={JobHistoryRouter} />
  </Switch>
);

export default NotificationsRoutes;
