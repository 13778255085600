import $ from "jquery";
export function disableScroll() {
  if (!$("html").hasClass("html-modal-open")) {
    $("html").addClass("html-modal-open");
  }
  if (!$("body").hasClass("modal-open")) {
    $("body").addClass("modal-open");
  }
}

export function restoreScroll() {
  if ($("html").hasClass("html-modal-open")) {
    $("html").removeClass("html-modal-open");
  }
  if ($("body").hasClass("modal-open")) {
    $("body").removeClass("modal-open");
  }
}

export function sortByKeyString(array, key) {
  return array.sort(function(a, b) {
    let x = a[key].toLowerCase();
    let y = b[key].toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  });
}
export function sortByKey(array, key) {
  return array.sort(function(a, b) {
    let x = a[key];
    let y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export function getUniqueListById(array, key) {
  let sortedList = [];
  let uniqueList = [];
  sortedList = array.sort(function(a, b) {
    let x = a[key];
    let y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });

  let lastAddedItem = null;
  sortedList.forEach(item => {
    if (lastAddedItem !== item[key]) {
      uniqueList.push(item);
      lastAddedItem = item[key];
    }
  });

  return uniqueList;
}

export function isMobileView() {
  if (window.innerWidth >= 320 && window.innerWidth <= 767) {
    return true;
  } else {
    return false;
  }
}

export function isTabletView() {
  if (window.innerWidth >= 768 && window.innerWidth <= 991) {
    return true;
  } else {
    return false;
  }
}

export function isDesktopView() {
  if (window.innerWidth >= 992) {
    return true;
  } else {
    return false;
  }
}

export function getErrorClassName(inputValue) {
  if (inputValue && inputValue.length > 0) {
    return "error-label";
  } else {
    return "";
  }
}

export function getValueLabelList(list, valueItemString, labelItemString) {
  let valueLabelList = [];
  list.forEach(item => {
    let valueLabel = {
      value: item[valueItemString],
      label: item[labelItemString]
    };
    valueLabelList.push(valueLabel);
  });

  return valueLabelList;
}

export function getSelectValueList(list, valueItemString, labelItemString) {
  let valueLabelList = [
    {
      value: 0,
      label: "Select"
    }
  ];
  list.forEach(item => {
    let valueLabel = {
      value: item[valueItemString],
      label: item[labelItemString]
    };
    valueLabelList.push(valueLabel);
  });
  return valueLabelList;
}

export function isNullOrEmpty(value) {
  if (value === null || value === "") {
    return true;
  } else {
    return false;
  }
}

export function isNullOrZero(value) {
  if (value === null || value === 0) {
    return true;
  } else {
    return false;
  }
}

export function isUndefinedOrZero(value) {
  if (value === undefined || value.length === 0) {
    return true;
  } else {
    return false;
  }
}

export function isUndefinedOrNull(value) {
  if (value === undefined || value === null) {
    return true;
  } else {
    return false;
  }
}

export function convertSessionContextAtoB(sessionContext) {
  let convertedSessionContext = "";
  convertedSessionContext = window.atob(sessionContext);
  return convertedSessionContext;
}

export function getSessionItem(sessionContext) {
  let item = "";

  return item;
}

export function isUndefinedOrEmpty(value) {
  if (
    value === undefined ||
    value === null ||
    value === 0 ||
    value.trim() === ""
  ) {
    return true;
  } else {
    return false;
  }
}
