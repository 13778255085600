import React from "react";

const PrimaryManager = ({ height, width, ...style }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 37.6 49.6"
      style={{ enableBackground: "new 0 0 37.6 49.6", ...style }}
      height={height}
      width={width}
      xmlSpace="preserve"
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{ __html: "\n\t.st0{fill:#555555;}\n" }}
      />
      <path
        className="st0"
        d="M26,36.4l-6.2-4.9v-7.2c0-0.6-0.4-1-1-1s-1,0.4-1,1v7.2l-6.2,4.9c-0.4,0.3-0.5,1-0.2,1.4
	c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2l5.9-4.7l5.9,4.7c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4
	C26.5,37.4,26.4,36.8,26,36.4z"
      />
      <g>
        <polygon
          className="st0"
          points="18.7,13.2 19.6,15 21.5,15.3 20.1,16.7 20.4,18.6 18.7,17.7 16.9,18.6 17.2,16.7 15.8,15.3 17.8,15 	
		"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M20.3,10h-1c2.4-0.3,4.2-2.3,4.2-4.7c0-2.7-2.2-4.8-4.8-4.8s-4.8,2.1-4.8,4.8c0,2.4,1.8,4.4,4.2,4.7h-1
		c-4.1,0-7.5,3.4-7.5,7.5v1.3V21c0,0.6,0.4,1,1,1H12h13.4h1.4c0.6,0,1-0.4,1-1v-2.2v-1.3C27.8,13.4,24.4,10,20.3,10z M15.9,5.3
		c0-1.5,1.3-2.8,2.8-2.8s2.8,1.3,2.8,2.8s-1.3,2.8-2.8,2.8S15.9,6.8,15.9,5.3z M25.8,20H11.6v-2.5c0-3,2.5-5.5,5.5-5.5h3.2
		c3,0,5.5,2.5,5.5,5.5C25.8,17.5,25.8,20,25.8,20z"
        />
      </g>
      <path
        className="st0"
        d="M31.9,40.6H31c1.9-0.1,3.4-1.6,3.4-3.5c0-2-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5s1.5,3.5,3.4,3.5h-0.9
	c-2.8,0-5.1,2.3-5.1,5.1v2.1c0,0.6,0.5,1.1,1.1,1.1h9.9c0.6,0,1.1-0.5,1.1-1.1v-2.1C37,42.9,34.7,40.6,31.9,40.6z M29.4,37.1
	c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5S29.4,37.9,29.4,37.1z M34.8,45.7v1h-7.7v-1c0-1.5,1.3-2.9,2.9-2.9h2
	C33.4,42.8,34.8,44.1,34.8,45.7z"
      />
      <path
        className="st0"
        d="M7.7,40.6H6.8c1.9-0.1,3.4-1.6,3.4-3.5c0-2-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5s1.5,3.5,3.4,3.5H5.7
	c-2.8,0-5.1,2.3-5.1,5.1v2.1c0,0.6,0.5,1.1,1.1,1.1h9.9c0.6,0,1.1-0.5,1.1-1.1v-2.1C12.8,42.9,10.5,40.6,7.7,40.6z M5.2,37.1
	c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5S5.2,37.9,5.2,37.1z M10.6,45.7v1H2.9v-1c0-1.5,1.3-2.9,2.9-2.9h2
	C9.2,42.8,10.6,44.1,10.6,45.7z"
      />
    </svg>
  );
};

export default PrimaryManager;
