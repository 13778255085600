import * as types from "../actions/index";
export default function(state = null, action) {
  switch (action.type) {
    case types.CHECK_USER_IN_SESSION:
      return action.payload;

    // no default
  }

  return state;
}
