import React from "react";
import WageTaxValidationTabs from "./WageTaxValidationTabs";
import "./WageTaxValidation.css";

const WageTaxValidationTabSection = ({
  tabItemsList,
  onClick,
  selectedTab
}) => (
  <div className="wage-tax-tabs-section">
    {tabItemsList.map((item, i) => (
      <WageTaxValidationTabs
        key={i}
        label={item.label}
        value={item.value}
        svgKey={item.svgKey + (selectedTab === item.value ? " On" : "")}
        onClick={onClick}
        selected={selectedTab === item.value ? true : false}
      />
    ))}
  </div>
);

export default WageTaxValidationTabSection;
