import * as types from "../../../actions/systemlevel/index";

export default function(state = null, action) {
  switch (action.type) {
    case types.SYSTEM_THIRD_PARTY_PAYEE_BUTTON_TYPE:
      return action.payload;
    default:
      return state;
  }
}
