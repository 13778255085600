import axios from "axios";
import * as types from "./index";
import * as common from "../../common/index";
import { fetchAllMenus } from "./network";
import { setAllMenuList } from "../reducers/allMenuList";
import { setMenuState } from "../reducers/setGlobalSetup";
import * as utilities from "../../common/utilities";

export function getTopLevelMenus(topLevelParentId, menuList) {
  // Change this logic and instead load this from the store
  if (menuList.Data !== undefined) {
    const request = getChildMenuList(topLevelParentId, menuList.Data);
    return {
      type: types.FETCH_TOP_LEVEL_MENUS,
      payload: request
    };
  } else {
    return {
      type: types.FETCH_TOP_LEVEL_MENUS,
      payload: []
    };
  }
}

export function getSideMenus(parentId, menuList) {
  // Change this logic and instead load this from the store
  if (menuList.Data !== undefined) {
    const request = getChildMenuList(parentId, menuList.Data);
    return {
      type: types.FETCH_SIDE_MENUS,
      payload: request
    };
  } else {
    return {
      type: types.FETCH_SIDE_MENUS,
      payload: []
    };
  }
}

function getChildMenuList(parentId, menuList) {
  const childMenuList = [];
  if (menuList !== null && menuList.length > 0) {
    let sortedMenuList = utilities.sortByKey(menuList, "DisplayOrder");
    sortedMenuList.forEach(menuItem => {
      if (menuItem.ParentId === parentId) {
        childMenuList.push(menuItem);
      }
    });
  }

  return childMenuList;
}


function clone(obj) {
  if (null == obj || "object" !== typeof obj) return obj;
  let copy = obj.constructor();
  for (let attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}

export function setMenuParentName(parenName) {
  return {
    type: types.MENU_PARENT_NAME,
    payload: parenName
  };
}

export function selectQuickLink(
  isLeafNode,
  quickLinksParentId,
  currentMenuState,
  lastMenuState
) {
  let data = [];
  if (currentMenuState === undefined) {
    currentMenuState = [];
  }

  if (lastMenuState === undefined) {
    // lastMenuState = [1,0,0];
    lastMenuState = {
      SelectedProductId: 1,
      SelectedMenuLevel1Id: 0,
      SelectedMenuLevel2Id: 0,
      SelectedMenuLevel3Id: 0
    };
  }

  // currentMenuState[0]=quickLinksParentId;
  // currentMenuState[1]=0;
  // currentMenuState[2]=0;
  currentMenuState = {
    SelectedProductId: quickLinksParentId,
    SelectedMenuLevel1Id: 0,
    SelectedMenuLevel2Id: 0,
    SelectedMenuLevel3Id: 0
  };

  if (isLeafNode) {
    // lastMenuState = currentMenuState.slice();
    lastMenuState = clone(currentMenuState);
  }

  data[0] = currentMenuState;
  data[1] = lastMenuState;

  sessionStorage.setItem("MenuState", JSON.stringify(data));

  return {
    type: types.SET_MENU_STATE,
    payload: data
  };
}

export function selectTopLevelMenu(
  isLeafOnTop,
  topMenuParentId,
  currentMenuState,
  lastMenuState,
  isGlobalNavSetup = false
) {
  let data = [];

  if (isGlobalNavSetup === false) {
    if (currentMenuState === undefined) {
      currentMenuState = [];
    }

    if (lastMenuState === undefined) {
      // lastMenuState = [1,0,0];
      lastMenuState = {
        SelectedProductId: 1,
        SelectedMenuLevel1Id: 0,
        SelectedMenuLevel2Id: 0,
        SelectedMenuLevel3Id: 0
      };
    }

    // currentMenuState[1]=topMenuParentId;
    // currentMenuState[2]=0;
    currentMenuState = {
      SelectedProductId: currentMenuState.SelectedProductId,
      SelectedMenuLevel1Id: topMenuParentId,
      SelectedMenuLevel2Id: topMenuParentId,
      SelectedMenuLevel3Id: 0
    };
  }

  if (isLeafOnTop) {
    // lastMenuState = currentMenuState.slice();
    lastMenuState = clone(currentMenuState);
  }

  data[0] = currentMenuState;
  data[1] = lastMenuState;

  sessionStorage.setItem("MenuState", JSON.stringify(data));

  return {
    type: types.SET_MENU_STATE,
    payload: data
  };
}

export function selectSideNavigationLevelMenu(
  isLeafOnSide,
  sideNavigationMenuParentId,
  isFirstNode,
  currentMenuState,
  lastMenuState
) {
  let data = [];
  if (currentMenuState === undefined) {
    currentMenuState = [];
  }

  if (lastMenuState === undefined) {
    lastMenuState = [];
  }

  // if(isFirstNode){
  //     // currentMenuState[2]=sideNavigationMenuParentId;

  // }
  currentMenuState = {
    SelectedProductId: currentMenuState.SelectedProductId,
    SelectedMenuLevel1Id: currentMenuState.SelectedMenuLevel1Id,
    SelectedMenuLevel2Id: sideNavigationMenuParentId,
    SelectedMenuLevel3Id: 0
  };

  if (isLeafOnSide) {
    if (lastMenuState.length === 0) {
      // lastMenuState[0]=1;
      // lastMenuState[1]=0;
      // lastMenuState[2]=0;
      lastMenuState = {
        SelectedProductId: 1,
        SelectedMenuLevel1Id: 0,
        SelectedMenuLevel2Id: 0,
        SelectedMenuLevel3Id: 0
      };
    } else {
      // lastMenuState = currentMenuState.slice();
      lastMenuState = clone(currentMenuState);
    }
  }

  data[0] = currentMenuState;
  data[1] = lastMenuState;

  sessionStorage.setItem("MenuState", JSON.stringify(data));

  return {
    type: types.SET_MENU_STATE,
    payload: data
  };
}

export function selectLeafSideNavigationLevelMenu(
  isLeafOnSide,
  sideNavigationMenuParentId,
  isFirstNode,
  currentMenuState,
  lastMenuState
) {
  let data = [];
  if (currentMenuState === undefined) {
    currentMenuState = [];
  }

  if (lastMenuState === undefined) {
    lastMenuState = [];
  }

  // if (isFirstNode) {
  //   // currentMenuState[2]=sideNavigationMenuParentId;
  //   currentMenuState = {
  //     SelectedProductId: currentMenuState.SelectedProductId,
  //     SelectedMenuLevel1Id: currentMenuState.SelectedMenuLevel1Id,
  //     SelectedMenuLevel2Id: currentMenuState.SelectedMenuLevel2Id,
  //     SelectedMenuLevel3Id: sideNavigationMenuParentId
  //   };
  // }

  currentMenuState = {
    SelectedProductId: currentMenuState.SelectedProductId,
    SelectedMenuLevel1Id: currentMenuState.SelectedMenuLevel1Id,
    SelectedMenuLevel2Id: currentMenuState.SelectedMenuLevel2Id,
    SelectedMenuLevel3Id: sideNavigationMenuParentId
  };

  if (isLeafOnSide) {
    if (lastMenuState.length === 0) {
      lastMenuState = {
        SelectedProductId: 1,
        SelectedMenuLevel1Id: 0,
        SelectedMenuLevel2Id: 0,
        SelectedMenuLevel3Id: 0
      };
    } else {
      lastMenuState = clone(currentMenuState);
    }
  }

  data[0] = currentMenuState;
  data[1] = lastMenuState;

  sessionStorage.setItem("MenuState", JSON.stringify(data));

  return {
    type: types.SET_MENU_STATE,
    payload: data
  };
}

export function setMenuStateFromSession() {
  let data = JSON.parse(sessionStorage.getItem("MenuState"));
  return {
    type: types.SET_MENU_STATE,
    payload: data
  };
}

export function reshuffleMenus(menuState, menuList) {
  let data = [];
  if (menuState !== undefined) {
    data[0] = menuState[1];
    data[1] = menuState[1];
  }

  return {
    type: types.SET_MENU_STATE,
    payload: data
  };
}

export function setDisplaySideMenuPanel(isDisplay) {
  if (isDisplay) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    document.querySelector("body").style.overflow = "hidden";
  } else {
    document.querySelector("body").style.overflow = "initial";
  }

  return {
    type: types.SIDE_MENU_PANEL,
    payload: isDisplay
  };
}

export function setDisplaySideMenuBar(isDisplay) {
  return {
    type: types.SIDE_MENU_BAR,
    payload: isDisplay
  };
}

export function setDisplayMainPageOverlay(isDisplay) {
  return {
    type: types.MAIN_PAGE_OVERLAY,
    payload: isDisplay
  };
}

export const getAllMenus = callback => dispatch => {
  fetchAllMenus().then(res => {
    dispatch(setAllMenuList(res));
    callback(res.data);
  });
};

export const setGlobalNavSetup = setupList => dispatch => {
  let data = [];
  let lastMenuState = {
    SelectedProductId: setupList.selectedProductId,
    SelectedMenuLevel1Id: setupList.selectedTopLevelId,
    SelectedMenuLevel2Id: setupList.selectedSidebarId,
    SelectedMenuLevel3Id: setupList.selectedSidebarLeafId
  };

  let currentMenuState = {
    SelectedProductId: setupList.selectedProductId,
    SelectedMenuLevel1Id: setupList.selectedTopLevelId,
    SelectedMenuLevel2Id: setupList.selectedSidebarId,
    SelectedMenuLevel3Id: setupList.selectedSidebarLeafId
  };

  data[0] = currentMenuState;
  data[1] = lastMenuState;

  sessionStorage.setItem("MenuState", JSON.stringify(data));
  dispatch(setMenuState(data));
};
