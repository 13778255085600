import React from "react";
import NcTab from "../tabs/NcTab";
import PropTypes from "prop-types";
const PayrollDashboardTabs = ({ payrollTabsData, selectedTab, onClick }) => {
  return (
    <div className="payroll-dash-header-tab-container">
      {payrollTabsData.map((item, index) => {
        return (
          <NcTab
            key={index}
            id={`payroll-tab-${item.value}`}
            label={item.label}
            value={item.value}
            badgeNumber={item.numberOfPayroll}
            active={item.value === selectedTab}
            tabType="payroll-dashboard"
            onClick={onClick}
          />
        );
      })}
    </div>
  );
};

export default PayrollDashboardTabs;
PayrollDashboardTabs.defaultProps = {
  payrollTabsData: [],
  onClick: () => alert("Should switch tab")
};
PayrollDashboardTabs.propTypes = {
  payrollTabsData: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired
};
