import React from "react";
import * as payrollConstants from "./../PayrollDashboardConstants";
import tabItemsList from "./../MultiMenuSectionTabsData";
import TabSection from "./../../navTabs/rounded/Section";
const Header = ({
  data,
  yearEndRollOver = false,
  activeTab,
  sectionTabClickHandler,
  startPayroll
}) => (
  <div className="payroll-dash-summary-checklist-section clearfix">
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="checklist-header">
        <div className="checklist-header-content" style={{ flexGrow: 1 }}>
          <div className="checklist-header-label" id="checklist-header">
            My Payroll Checklist{" "}
            <span style={{ marginLeft: "10px" }} className="badge badge-count">
              {data.CountOfUnapprovedPtos +
                data.CountOfTimeCardAlerts +
                data.CountOfEmployeeUpdates +
                data.CountOfAddressVerifications +
                data.CountOfTaxVerification +
                data.CountOfMinimumWageAlert}
            </span>
          </div>
          <div
            id="checklist-message"
            className="col-lg-10 hidden-xs col-md-9 col-sm-12 col-xs-12 checklist-message"
          >
            {payrollConstants.PAYROLL_CHECKLIST_DESCRIPTION}
          </div>
        </div>

        <div className="button-container">
          <button className="nc-button primary-button" onClick={startPayroll}>
            {" "}
            Start Payroll{" "}
            <i className="fa fa-angle-right" style={{ marginRight: 0 }} />
          </button>
        </div>
      </div>
    </div>
    <div
      id="checklist-message"
      className="col-lg-10 col-md-9 col-sm-12 col-xs-12 checklist-message hidden-sm hidden-md hidden-lg"
    >
      {payrollConstants.PAYROLL_CHECKLIST_DESCRIPTION}
    </div>
    <TabSection
      tabItemsList={tabItemsList(data)}
      data={data}
      onClick={sectionTabClickHandler}
      activeId={activeTab}
    />
  </div>
);
export default Header;
