import React from "react";
import { DeceasedDetails } from "component-library/employee-profile/employment-info/change-status/deceased/index";
import {
  ScheduledDeductions,
  ReplacementManagers,
  ScheduledEarnings
} from "component-library/employee-profile/employment-info/change-status/common";
import FooterButtons from "../components/FooterButtons";

const Deceased = ({ onChange, data = {}, onCloseOverlay }) => {
  return (
    <div>
      <DeceasedDetails onChange={onChange} data={data} />
      <ScheduledEarnings onChange={onChange} data={data} />
      <ScheduledDeductions onChange={onChange} data={data} />
      <ReplacementManagers onChange={onChange} data={data} />
      <FooterButtons
        buttonLabel="Change Status"
        onCancelClick={onCloseOverlay}
      />
    </div>
  );
};

export default Deceased;
