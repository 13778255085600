import React from "react";
import NumberFormat from "react-number-format";
const NumberField = props => (
  <div className={props.className}>
    <NumberFormat
      id={props.inputId}
      name={props.inputName}
      className="form-control"
      prefix={props.prefix}
      suffix={props.suffix}
      placeholder={props.placeholder}
      value={props.value}
      onChange={values => {
        props.onChange &&
          props.onChange({ name: props.inputName, value: values.target.value });
      }}
      format={props.format}
      disabled={props.disabled}
    />
    {props.error && props.required && (
      <div style={{ marginTop: "5px", fontSize: "15px" }} className="has-error">
        {props.error}
      </div>
    )}
  </div>
);
export default NumberField;
