/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { ProfileCard } from "component-library/employee-profile/profile-view";

class EmployeeProfileCard extends Component {
  render() {
    const { NewEmployeeMenu } = this.props;
    const { AlertSectionList = [] } = this.props.data;
    const EmployeeId = this.props.match.params.EmployeeId;

    return (
      <div className="profile-view-page-content">
        {AlertSectionList !== null ? (
          <div
            className="card-wrapper"
            style={{
              display: "flex",
              paddingBottom: "20px",
              borderBottom: "1px solid #ccc",
              marginBottom: "20px"
            }}
          >
            {AlertSectionList.map((item, i) => {
              return (
                <ProfileCard
                  key={i}
                  title={item.SectionName}
                  type={item.SectionType}
                  description={item.SectionDescription}
                  imgSrc={item.ImageUrl}
                  icon={item.SectionName}
                >
                  {item.SectionItemList.map((sectionItem, i) => {
                    return (
                      <ul key={i} className="list">
                        <li className="card-list-item">
                          <a
                            onClick={() =>
                              this.props.history.push(
                                `/people/employees/dashboard/${EmployeeId}/${item.SectionName.replace(
                                  / /g,
                                  ""
                                )}`
                              )
                            }
                          >
                            {sectionItem.SectionItemName}{" "}
                            <i className="fa fa-caret-right" />
                          </a>
                        </li>
                      </ul>
                    );
                  })}
                </ProfileCard>
              );
            })}
          </div>
        ) : null}

        {NewEmployeeMenu !== null ? (
          <div
            className="card-wrapper"
            style={{
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            {" "}
            {NewEmployeeMenu.map((item, i) => {
              return (
                <ProfileCard
                  key={i}
                  title={item.CustomFieldGroupName}
                  type={item.SectionType}
                  description={item.Discription}
                  icon={item.CustomFieldGroupName}
                  imgSrc={`https://kshirbhate-dev-ui.azurewebsites.net/images/product-images/${
                    item.ImageUrl
                  }.png`}
                >
                  {item.SubItemList.map((sectionItem, i) => {
                    return (
                      <ul key={i} className="list">
                        <li className="card-list-item">
                          <a
                            onClick={() =>
                              this.props.onClick(
                                item,
                                sectionItem,
                                sectionItem.CustomFieldGroupId
                              )
                            }
                          >
                            {sectionItem.CustomFieldGroupName}
                          </a>
                        </li>
                      </ul>
                    );
                  })}
                </ProfileCard>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(EmployeeProfileCard);

EmployeeProfileCard.propTypes = {
  data: PropTypes.object,
  AlertSectionList: PropTypes.object
};
EmployeeProfileCard.defaultProps = {
  data: {
    AlertSectionList: [],
    SectionList: []
  }
};
